/* global document */
import React from "react";
import ReactDOM from "react-dom";

import App from "./app/App";
import "jquery";
import "../assets/libs/jquery.slimscroll.min.js";
import "metismenu";
import "./../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../../../node_modules/animate.css/animate.min.css";
import "./styles/base.scss";


ReactDOM.render(<App />, document.getElementById("root"));
