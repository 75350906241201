import React, { Component } from "react";
import Button from "../atoms/Button";
import { extractHostname } from "../../utils/Helpers";
import { doGetClients, UtilActions } from "../../actions";

interface Props {
    item: Mongo.INotification;
    fileUpload?: File[] | null;
    uploadedFile?: boolean;
    thumbnail?: string | undefined;
    customThumbnailUrl?: string;
    customThumbnailFile?: File | undefined;
    customCardHeaderCss?: React.CSSProperties;
    customCardCss?: React.CSSProperties;
    hidePush?: boolean;
    hideWeeklyEmail?: boolean;
    hideTitle?: boolean;
}

interface State {
    open: boolean;
    videoPlaying: boolean;
    firstImgSelected?: string | null;
    defaultThumbnail?: string | null;
    primaryColor: string;
}

export default class NotificationPreview extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open || false,
            primaryColor: "#152733",
            videoPlaying: false,
            defaultThumbnail: props.thumbnail || null,
            firstImgSelected: null
        };
        if (props.fileUpload) this._getBase64(props.fileUpload[0]);
    }

    async componentDidMount() {
        // get the config name to pull back whitelabel settings
        let configName = "none";
        const clients = await doGetClients();
        if (clients && clients.length > 0) {
            if (clients.length == 1) {
                // regular admin should have one client record
                configName = clients[0].configName;
            } else {
                // dev admin case
                const cid = this.props.item.cid ? this.props.item.cid : "";
                const client = clients.filter(v => v.cid == cid);
                if (client && client.length == 1) {
                    configName = client[0].configName;
                }
            }
        }
        const branding = await UtilActions.doGetWhiteLabelSettings(configName);
        // stash the primary colour in state to use in render
        if (branding) {
            const primaryColor = branding.primaryColor;
            this.setState({ primaryColor });
        }
    }

    _getBase64 = file => {
        const reader = new FileReader();
        reader.onloadend = () => this.setState({ firstImgSelected: reader.result as string });
        reader.readAsDataURL(file);
    };
    _renderCard = () => {
        const {
            Title,
            Link,
            Image,
            currentLang,
            comment,
            video,
            commentURL,
            videoUrl,
            style,
            type,
            Images,
            bottomHashtags,
            bottomMentions,
            ecommTitle,
            ecommThumbnail,
            ecommFrequency
        } = this.props.item;
        const { uploadedFile, fileUpload, customThumbnailUrl, customThumbnailFile } = this.props;
        const { defaultThumbnail, firstImgSelected } = this.state;
        const { videoPlaying } = this.state;
        // TODO: remove this hack when the typing is fixing
        let { sharingPermissions, Terms, Hashtags, Mentions } = this.props.item;
        sharingPermissions = sharingPermissions as {
            social: boolean;
            email: boolean;
            sms: boolean;
            socialNetworkPermissions: {
                facebook: boolean;
                twitter: boolean;
                instagram: boolean;
                linkedin: boolean;
            };
        }
        Terms = Terms as string[];
        Hashtags = Hashtags as string[];
        Mentions = Mentions as string[];

        const artImg =
            customThumbnailUrl && customThumbnailUrl.length
                ? customThumbnailUrl
                : customThumbnailFile
                ? URL.createObjectURL(customThumbnailFile)
                : defaultThumbnail
                ? defaultThumbnail
                : Images && Images.length
                ? Images[0]
                : firstImgSelected
                ? firstImgSelected
                : ecommThumbnail
                ? ecommThumbnail
                : Image;
        const commentURLPlaceholder = commentURL ? " https://e.gv6.co/l3gfH4" : "";
        // Removing existing hashtags/mentions since we're appying it for preview
        const filteredComment = (comment || "").replace(`${bottomHashtags || ""}${bottomMentions || ""}`.trim(), "");
        const commentDisplay = `${filteredComment}${commentURLPlaceholder}${
            Hashtags ? ` ${Hashtags.map(i => `#${i}`).join(" ")}` : ""
        }${Mentions ? ` ${Mentions.map(i => `@${i}`).join(" ")}` : ""}`;
        let share_btn = "";
        let customize_btn = "";

        if (currentLang == "en") {
            if (type === "Email Template") {
                share_btn = ecommFrequency === "onetime" ? "Schedule" : "Subscribe";
            } else {
                share_btn = sharingPermissions.social
                    ? "Share this week"
                    : sharingPermissions.email
                    ? "Share by Email"
                    : sharingPermissions.sms
                    ? "Share by SMS"
                    : "View";
            }
            customize_btn = type !== "Email Template" ? "Edit & share" : "";
        }
        if (currentLang == "fr") {
            if (type === "Email Template") {
                share_btn = ecommFrequency === "onetime" ? "Programmer" : "Souscrire";
            } else {
                share_btn = sharingPermissions.social
                    ? "Programmer"
                    : sharingPermissions.email
                    ? "Partager par courriel"
                    : sharingPermissions.sms
                    ? "Partager par SMS"
                    : "Voir";
            }
            customize_btn = type !== "Email Template" ? "Personnaliser" : "";
        }
        const btn_style =
            share_btn == "View" || share_btn == "Voir" || type === "Email Template"
                ? {
                      width: "100%",
                      padding: "10px",
                      color: "#152733"
                  }
                : {
                      width: "49%",
                      borderRight: "1px solid #ddd",
                      padding: "10px",
                      color: "#152733"
                  };
        return (
            <div className="card-preview">
                <div className="card-close" style={{ overflowWrap: "break-word" }}>
                    <div style={{ display: "float", paddingBottom: "15px" }}>
                        {!(style == "modal" && (type == "Article" || type == "Media" || type === "Email Template")) && (
                            <div style={{ float: "left" }}>
                                <i
                                    className="icon ion-close-circled icon-article-close"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px", color: "#757373", marginTop: "3px" }}
                                />
                            </div>
                        )}
                        {style == "modal" && (type == "Article" || type == "Media") ? (
                            <div style={{ margin: "5px 0 0 0", float: "right" }}>
                                <span className="d-inline-block m-0 p-2 pr-3" style={{ position: "relative", right: "6px", bottom: "1px" }}>Notifications</span>
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </div>
                        ) : (
                            ""
                        )}
                        {Images && Images.length > 1 && (
                            <div
                                style={{
                                    width: "22px",
                                    height: "22px",
                                    float: "left",
                                    textAlign: "center",
                                    marginLeft: "5px"
                                }}
                            >
                                <i
                                    className="icon ion-images brandPrimary--color"
                                    style={{ fontSize: "15px", margin: "3px 0 0 0" }}
                                ></i>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-content">
                    {style == "modal" && (
                        <>
                            <br />
                            {commentDisplay}
                        </>
                    )}
                    <div className="card-image">
                        {Title && Title.length && Link && Link.length && (
                            <div className="card-title-url">
                                <span style={{ fontSize: "14px" }}>{Title}</span>
                                <br />
                                <span style={{ fontSize: "12px" }}>
                                    {extractHostname(Link)} -{" "}
                                    {new Date().toLocaleDateString(currentLang, {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric"
                                    })}
                                </span>
                            </div>
                        )}
                        {ecommTitle && (
                            <div className="card-title-url">
                                <span style={{ fontSize: "14px" }}>{ecommTitle}</span>
                            </div>
                        )}
                        <div id="art_media" style={{ position: "relative", textAlign: "center" }}>
                            {!videoPlaying && ((uploadedFile && fileUpload![0].type.includes("video")) || video) && (
                                <div
                                    className="icon-play-circle"
                                    onClick={() => {
                                        this.setState({ videoPlaying: true }, () => {
                                            (document.getElementById("art_video") as HTMLMediaElement).play();
                                        });
                                    }}
                                >
                                    <i className="icon ion-play"></i>
                                </div>
                            )}
                            {!videoPlaying && (
                                <img
                                    id="art_img"
                                    src={artImg}
                                    style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "auto",
                                        maxHeight: "150px",
                                        padding: "5px"
                                    }}
                                />
                            )}
                            {videoPlaying && (
                                <video
                                    id="art_video"
                                    controls
                                    controlsList="nodownload"
                                    style={{ width: "100%", height: "auto", maxHeight: "150px", padding: "5px" }}
                                >
                                    <source
                                        src={uploadedFile ? URL.createObjectURL(fileUpload![0]) : videoUrl}
                                        type="video/mp4"
                                    />
                                    This video was unable to load.
                                </video>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: "left", padding: "5px", fontSize: "12px" }}>
                    {Terms ? Terms.join(", ") : ""}
                </div>
                <div className="divider" />
                <div className="card-buttons" style={{ fontSize: "16px" }}>
                    <div style={btn_style}>
                        {type === "Email Template" && (
                            <i
                                className="icon ion-ios-email-outline"
                                style={{ paddingRight: "5px", fontSize: "20px", verticalAlign: "middle" }}
                            />
                        )}
                        {share_btn}
                    </div>
                    {!(
                        share_btn == "View" ||
                        share_btn == "Voir" ||
                        share_btn === "Schedule" ||
                        share_btn == "Subscribe" ||
                        share_btn === "Programmer" ||
                        share_btn === "Souscrire"
                    ) && (
                        <div className="card-customize-btn">
                            <i className="icon ion-compose pr-1" style={{ paddingRight: "5px" }} />
                            {customize_btn}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    _renderPushNotification = () => {
        const { type, title, body, comment, Title, ecommTitle, ecommDescription } = this.props.item;
        const _title =
            type == "Text" ? title : type === "Email Template" ? ecommTitle : Title || "";
        const _body =
            type == "Article" || type == "Media"
                ? comment || ""
                : type == "Text"
                ? body
                : type === "Email Template"
                ? ecommDescription
                : "";
        return (
            <>
                {!this.props.hideTitle && (
                    <span>
                        <strong>Push Notification</strong>
                    </span>
                )}
                <div className="push-notification">
                    <div className="notification-header">
                        <div><img src="/img/seismic.png" style={{ width: "18px" }} /> <span>LiveSocial</span></div>
                        <div>now</div>
                    </div>
                    {((_title && _title.length) || (_body && _body.length)) && (
                        <div style={{ margin: "10px", overflowWrap: "break-word" }}>
                            <strong>{_title}</strong>
                            <br />
                            {_body ? `${_body.substring(0, 300)}...` : ""}
                        </div>
                    )}
                </div>
            </>
        );
    };

    _renderModalNotification = () => {
        const { type, title, body, buttonLabel, comment, commentURL, ecommDescription } = this.props.item;
        const _title = type == "Article" || type == "Media" ? "" : title;
        const _body =
            type == "Article" || type == "Media"
                ? comment || ""
                : type == "Text"
                ? body
                : type == "Email Template"
                ? ecommDescription
                : "";
        const commentURLPlaceholder = commentURL ? " https://e.gv6.co/l3gfH4" : "";
        const _button = buttonLabel || "";

        return (
            <>
                {!this.props.hideTitle && (
                    <span>
                        <strong>Full Screen</strong>
                    </span>
                )}
                <div className="modal-notification">
                    <div style={{ margin: "10px auto" }}>
                        <i
                            className="icon ion-ios-bell brandPrimary--color"
                            style={{ fontSize: "30px", margin: "10px" }}
                        />
                        <br />
                        <span style={{ fontSize: "16px" }}>{_title}</span>
                        <br />
                        <p style={{ fontSize: "14px", height: "100%", overflowWrap: "break-word" }}>
                            {_body}
                            {commentURLPlaceholder}
                        </p>
                    </div>
                    {(type == "Article" || type == "Media" || type == "Email Template") && (
                        <>
                            <br />
                            <br />
                            {this._renderCard()}
                        </>
                    )}
                    <br />
                    <br />
                    {type == "Text" && <Button style={{ margin: "5px", minWidth: "200px" }}>{_button}</Button>}
                    <br />
                    <span className="brandPrimary--color" style={{ margin: "0 auto" }}>
                        {this.props.item.currentLang == "en" ? "Dismiss" : "Fermer"}
                    </span>
                </div>
            </>
        );
    };

    _renderFirstCardNotification = () => {
        const { type, title, body, buttonLabel } = this.props.item;
        const _title = type == "Text" ? title : "";
        const _body = type == "Article" || type == "Media" ? "" : type == "Text" ? body : "";
        const _button = buttonLabel || "";
        const firstCardText = (
            <div className="first-card-text">
                <div style={{ margin: "10px auto" }}>
                    <div
                        style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "center",
                            margin: "auto"
                        }}
                    >
                        <i
                            className="icon ion-ios-bell brandPrimary--color"
                            style={{ fontSize: "20px", margin: "4px 0 0 0" }}
                        />
                    </div>
                    <br />
                    {_title}
                    <br />
                    {_body}
                </div>
                <br />
                <br />
                <Button style={{ margin: "5px", width: "200px" }}>{_button}</Button>
                <br />
                <span className="brandPrimary--color" style={{ margin: "0 auto" }}>
                    {this.props.item.currentLang == "en" ? "Dismiss" : "Fermer"}
                </span>
            </div>
        );
        return (
            <>
                {!this.props.hideTitle && (
                    <span>
                        <strong>First Card</strong>
                    </span>
                )}
                <div className="first-card-notification" style={this.props.customCardCss || {}}>
                    <div className="first-card-header" style={this.props.customCardHeaderCss || {}} />
                    <div className="first-card-body">
                        {(type == "Article" || type == "Media") && this._renderCard()}
                        {type == "Text" && firstCardText}
                    </div>
                </div>
            </>
        );
    };

    _renderBannerNotification = () => {
        const labelObj = {
            en: {
                share: "Share",
                seeMore: "See more"
            },
            fr: {
                share: "Partager",
                seeMore: "Voir plus"
            }
        };
        const { type, title, body, buttonLabel, currentLang, sharingPermissions } = this.props.item;
        const _title = title;
        const _body = body;
        const articleLabel =
            labelObj[`${currentLang}`][
                Object.values(sharingPermissions || {}).some(perm => perm) && type !== "Email Template"
                    ? "share"
                    : "seeMore"
            ];
        const _button = type === "Text" ? buttonLabel : articleLabel;
        const firstCardText = (
            <div style={{ padding: "20px 5px 5px 5px" }}>
                <div className="first-card-text" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <div>
                        <div
                            style={{
                                display: "flex"
                            }}
                        >
                            <i className="icon ion-ios-bell brandPrimary--color" style={{ fontSize: "20px", margin: "4px 0 0 0" }} />
                            <span className="brandPrimaryText" style={{ margin: "7px 0 0 5px", fontWeight: "bold" }}>
                                {_title}
                            </span>
                        </div>
                        <div
                            style={{
                                float: "left",
                                margin: "0 0 0 20px",
                                overflowWrap: "break-word",
                                maxWidth: "300px"
                            }}
                        >
                            {_body}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                            flexWrap: "wrap"
                        }}
                    >
                        <Button style={{ margin: "5px", minWidth: "100px" }}>{_button}</Button>
                        <a
                            className="btn brandPrimaryText brandPrimaryBorder"
                            style={{ margin: "5px", minWidth: "100px" }}
                        >
                            {currentLang === "en" ? "Dismiss" : "Fermer"}
                        </a>
                    </div>
                </div>
            </div>
        );
        return (
            <>
                {!this.props.hideTitle && (
                    <span>
                        <strong>Banner</strong>
                    </span>
                )}
                <div className="first-card-notification" style={this.props.customCardCss || {}}>
                    {firstCardText}
                </div>
            </>
        );
    };

    _renderWeeklyEmail = () => {
        const {
            Title,
            Image,
            comment,
            title,
            body,
            buttonLabel,
            currentLang,
            video,
            videoUrl,
            type,
            Images,
            ecommThumbnail,
            ecommDescription,
            ecommTitle
        } = this.props.item;
        const { uploadedFile, fileUpload, customThumbnailUrl, customThumbnailFile } = this.props;
        const { defaultThumbnail, firstImgSelected } = this.state;
        const { videoPlaying } = this.state;
        const artImg =
            customThumbnailUrl && customThumbnailUrl.length
                ? customThumbnailUrl
                : customThumbnailFile
                ? URL.createObjectURL(customThumbnailFile)
                : defaultThumbnail
                ? defaultThumbnail
                : Images && Images.length
                ? Images[0]
                : firstImgSelected
                ? firstImgSelected
                : type === "Email Template"
                ? ecommThumbnail
                : Image;
        const primaryColor = this.state.primaryColor;

        // prep header, body, button label
        let _title = type == "Text" ? title || "" : "";
        let _body = type == "Text" ? body || "" : "";
        let _button = type == "Text" ? buttonLabel || "" : "";
        if (type == "Article" || type == "Media") {
            _title = Title || "";
            _body = comment || "";
            let { sharingPermissions } = this.props.item;
            sharingPermissions = sharingPermissions as {
                social: boolean;
                email: boolean;
                sms: boolean,
                socialNetworkPermissions: {
                    facebook: boolean,
                    twitter: boolean,
                    instagram: boolean,
                    linkedin: boolean
                };
            }
            const viewOnly = !sharingPermissions.social && !sharingPermissions.email && !sharingPermissions.sms;
            _button = viewOnly ? "View" : "Edit & Share";
            if (currentLang == "fr") {
                _button = viewOnly ? "Voir" : "Personnaliser";
            }
        }
        if (type === "Email Template") {
            _title = ecommTitle || "";
            _body = ecommDescription || "";
            _button = "Edit & Share";
            if (currentLang == "fr") {
                _button = "Personnaliser";
            }
        }
        const weeklyEmailArticle = (
            <div className="card-content">
                <div className="card-image">
                    <div id="art_media" style={{ position: "relative", textAlign: "center" }}>
                        {!videoPlaying && ((uploadedFile && fileUpload![0].type.includes("video")) || video) && (
                            <div
                                className="icon-play-circle"
                                onClick={() => {
                                    this.setState({ videoPlaying: true }, () => {
                                        (document.getElementById("art_video") as HTMLMediaElement).play();
                                    });
                                }}
                            >
                                <i className="icon ion-play"></i>
                            </div>
                        )}
                        {!videoPlaying && (
                            <img
                                id="art_img"
                                src={artImg}
                                style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "150px",
                                    padding: "5px"
                                }}
                            />
                        )}
                        {videoPlaying && (
                            <video
                                id="art_video"
                                controls
                                controlsList="nodownload"
                                style={{ width: "100%", height: "auto", maxHeight: "150px", padding: "5px" }}
                            >
                                <source
                                    src={uploadedFile ? URL.createObjectURL(fileUpload![0]) : videoUrl}
                                    type="video/mp4"
                                />
                                This video was unable to load.
                            </video>
                        )}
                    </div>
                </div>
                <div style={{ margin: "10px auto" }}>{_body}</div>
            </div>
        );
        const weeklyEmailText = (
            <div className="first-card-text">
                <div style={{ margin: "10px auto" }}>{_body}</div>
            </div>
        );
        return (
            <>
                <br />
                {!this.props.hideTitle && (
                    <span>
                        <strong>Weekly Email</strong>
                    </span>
                )}
                <div className="weekly-email-notification">
                    <div style={{ margin: "10px auto 5px" }}>
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Notification</span>
                    </div>
                    <div style={{ margin: "0px auto 10px" }}>
                        {(type == "Article" || type == "Text" || type === "Email Template") && <span style={{ fontSize: "14px" }}>{_title}</span>}
                    </div>
                    {(type == "Article" || type == "Media" || type === "Email Template") && weeklyEmailArticle}
                    {type == "Text" && weeklyEmailText}
                    <div>
                        <span
                            style={{
                                display: "inline-block",
                                textDecoration: "none",
                                WebkitTextSizeAdjust: "none",
                                textAlign: "center",
                                borderColor: `${primaryColor}`,
                                color: `${primaryColor}`,
                                border: "solid 1px",
                                borderRadius: "20px",
                                WebkitBorderRadius: "20px",
                                MozBorderRadius: "20px",
                                width: "auto",
                                padding: "10px 20px",
                                fontSize: "14px!important"
                            }}
                        >
                            <span style={{ lineHeight: "14px" }}>{_button}</span>
                        </span>
                    </div>
                </div>
            </>
        );
    };

    render() {
        const { style, isPush, isWeeklyEmail } = this.props.item;
        return (
            <div>
                {isPush && !this.props.hidePush && this._renderPushNotification()}
                {style === "firstCard"
                    ? this._renderFirstCardNotification()
                    : style === "banner"
                    ? this._renderBannerNotification()
                    : this._renderModalNotification()}
                {isWeeklyEmail && !this.props.hideWeeklyEmail && this._renderWeeklyEmail()}
            </div>
        );
    }
}
