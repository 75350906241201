import React, { Component } from "react";
import { connect } from "react-redux";
import { doGetStreams, doGetStream, doGetClient } from "../../../actions/";
import { Search, Client } from "../../../utils/Models";
import Spinner from "../../atoms/Spinner";
import DefaultStream from "./types/DefaultStream";
import CuratedStream from "./types/CuratedStream";
import CorporateStream from "./types/CorporateStream";
import CompanyStream from "./types/CompanyStream";
import TrendingStream from "./types/TrendingStream";
import FeedStream from "./types/FeedStream";
import RecommendedStream from "./types/RecommendedStream";
import HelpStream from "./types/HelpStream";

import { Wrapper, Row } from "../../atoms/Layout/";
import { RouteComponentProps } from 'react-router-dom';

interface IStreamEditProps extends RouteComponentProps<{ cid: string; sid: string; type?: string;}> {
    clients: dynamic<Mongo.client>;
    user: Mongo.clientAdmin;
}

interface IStreamEditState {
    client: Mongo.client;
    stream: ServerTypes.Console.IStream;
    streams: ServerTypes.Console.IStream[];
    insert: boolean;
    countries: Array<{ code: string; name: string }>;
    loading: boolean;
}

class Edit extends Component<IStreamEditProps, IStreamEditState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;
    constructor(props: IStreamEditProps) {
        super(props);

        this.renderSection = this.renderSection.bind(this);

        const sid = props.match.params.sid ? props.match.params.sid : "";
        const cid = props.match.params.cid ? props.match.params.cid : "";
        let type: ServerTypes.Console.IStream["type"] = "DEFAULT";
        let searchType: ServerTypes.Console.IStream["searchType"] = "";

        if (props.match.params.type) {
            switch (props.match.params.type.toUpperCase()) {
                case "CORP":
                    searchType = "CORP";
                    type = "CORPORATE";
                    break;
                case "CURATED":
                    type = "CURATED";
                    break;
                case "RECOMMENDED":
                    type = "RECOMMENDED";
                    searchType = "RECO";
                    break;
                case "TRENDING":
                    type = "TRENDING";
                    break;
                case "FEED":
                    type = "FEED";
                    break;
                case "COMPANY":
                    type = "COMPANY";
                    break;
                case "PERS":
                    searchType = "PERS";
                    type = "DEFAULT";
                    break;
                case "PROF":
                    searchType = "PROF";
                    type = "DEFAULT";
                    break;
                case "HELP":
                    type = "HELP"
                    break
                default:
                    type = "DEFAULT";
                    break;
            }
        }
        this.state = {
            client: Client({ cid }) as Mongo.client,
            streams: [],
            stream: Search({
                sid,
                type,
                searchType,
                isApprovalRequired: type == "CURATED" || type == "FEED" || type == "TRENDING"
            }),
            insert: !(cid && sid),
            countries: [],
            loading: true
        };
    }

    async componentDidMount() {
        const { client, stream } = this.state;
        const { cid } = client;
        const { sid } = stream;
        const newState: dynamic = { stream };
        if (cid) {
            newState.client = await doGetClient(cid);
        }
        if (cid && sid) {
            newState.stream = await doGetStream(cid, sid);
        }
        newState.countries = newState.streams = (await doGetStreams(true, cid)).items;
        this.setState({ ...newState, loading: false } as IStreamEditState);
    }

    renderSection() {
        const { stream, client } = this.state;
        const options = { client, stream, clients: this.props.clients, admin: this.props.user, location: this.props.location };
        switch (stream.type) {
            case "CORPORATE":
                return <CorporateStream {...options} />;
            case "CURATED":
                return <CuratedStream {...options} />;
            case "TRENDING":
                return <TrendingStream {...options} />;
            case "FEED":
                return <FeedStream {...options} />;
            case "RECOMMENDED":
                return <RecommendedStream {...options} />;
            case "COMPANY":
                return <CompanyStream {...options} />;
            case "HELP":
                return <HelpStream {...options} />;
            default:
                return <DefaultStream {...options} />;
        }
    }

    render() {
        const { loading } = this.state;
        if (loading) {
            return (
                <Wrapper>
                    <Row>
                        <div className="col-sm-12">
                            <div className="ibox">
                                <div className="ibox-content">
                                    <Spinner />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Wrapper>
            );
        }
        return this.renderSection();
    }
}

const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(Edit);
