import React, { Component } from "react";
import Table from "./Table";
import SortableTable from "./SortableTable";
import { doGetClients } from "../../actions/";
// tslint:disable-next-line:no-submodule-imports
import debounce from "lodash/debounce";
import Button from "../atoms/Button";
import Pagination from "./Pagination";
import DatePickerV2 from "../atoms/DatePickerV2";
import Modal from "./modals/Modal";
import DownloadButton from "../atoms/DownloadButton";
import EventCalendar, { EventCalendarProps } from "./EventCalendar";
import { createAlert, IAlertType } from "../../actions/utils";
import Link from "../atoms/Link";
import Ellipsis from "../atoms/Ellipsis";
export interface Filter {
    search: string;
    cid: string;
    [key: string]: string;
}
export interface PaginationData {
    sort?: { [key: string]: number };
    limit: number;
    skip: number;
}
interface ElementListBoxProperties<T extends {}> {
    title: string;
    filterCid?: boolean;
    filterSearch?: boolean;
    minified?: boolean;
    listCount?: number;

    refresh: (filter: Filter, paginationData: PaginationData) => Promise<{ items: T[]; total: number }>;

    forceRefresh?: boolean;
    resetRefresh?: () => void;

    mutateItem: (
        item: T,
        index: number,
        array: Array<
            T & {
                actions: JSX.Element[];
            }
        >,
        originalItem: T,
        context: ElementListBox<T>
    ) => dynamic<JSX.Element | string>;
    download?: (filter: Filter, paginationData: PaginationData & { total: number }) => void;

    keys: { [key: string]: string | Array<string | React.ReactElement> };
    buttons?: (filter: Filter, paginationData: PaginationData) => JSX.Element[];
    searchLabel?: string;
    createLink?: string;
    allowCreateWithoutClient?: boolean;
    editLink?: string;
    dropdownOptions?: string[];
    onDropdownSelect?: (option: string, item: T) => void;
    importLink?: string;
    confirmDelete?: boolean;

    delete?: (item: T, filters: dynamic) => Promise<boolean>;
    undo?: (item: T, filters: dynamic) => Promise<boolean>;
    actions?: Array<{ link: string; text: string; icon: string }>;
    buttonActions?: Array<{ onClick: (item: T, filter: Filter) => Promise<void>; text: string; icon: string }>;
    buttonElements?: Array<(item: T, filter: Filter) => JSX.Element>;
    createRow?: dynamic<JSX.Element>;
    noRecordsMessage?: string;

    movable?: string;
    update?: (filters: Filter, item: T, sort?: boolean) => Promise<void>;

    sortableKeys?: string[];
    defaultSort?: { [key: string]: number };
    defaultFilter?: Partial<Filter>;
    filters?: {
        [key: string]: { title: string; defaultDate?: string; options?: Array<{ key: string; value: string }> };
    };
    details?: {
        [key: string]: string;
    };
    detailContent?: (item: T) => JSX.Element;
    calendarProps?: Omit<EventCalendarProps<T>, "refresh">;
    defaultView?: string;
    includeConsumers?: boolean;
    pagination?: boolean;
    isSocialInteractions?: boolean;
}
interface ElementListBoxState<T extends {}> {
    title: string;
    loading: boolean;
    items: T[];
    clients: Mongo.IClient[];
    keys: { [key: string]: string | Array<string | React.ReactElement> };
    allowCreateWithoutClient: boolean;
    sort: { [key: string]: number };
    defaultFilter: Filter;
    filter: Filter;
    count: number;
    skip: number;
    page: number;
    totalItems: number;
    filters?: {
        [key: string]: { title: string; defaultDate?: string; options?: Array<{ key: string; value: string }> };
    };
    details?: {
        [key: string]: string;
    };
    moreInfoOpen: number;
    deleteOpen: number;
    view: string;
    pagination: boolean;
}
export default class ElementListBox<T extends {}> extends Component<ElementListBoxProperties<T>, ElementListBoxState<T>> {
    constructor(props: ElementListBoxProperties<T>) {
        super(props);
        let keys = props.keys || {};
        if (!props.filterCid) {
            keys = Object.assign({}, ...Object.keys(keys).map(k => ({ ...(k !== "cid" ? { [k]: keys[k] } : null) })));
        }

        this._refresh = debounce(this._refresh, 1000);
        this._createAlert = debounce(this._createAlert, 500);

        const defaultFilter = {
            search: "",
            cid: "",
            ...props.defaultFilter
        };

        this.state = {
            title: props.title,
            loading: false,
            items: [],
            clients: [],
            defaultFilter,
            filter: { ...defaultFilter },
            filters: props.filters || {},
            details: props.details || {},
            keys,
            allowCreateWithoutClient: "allowCreateWithoutClient" in props ? props.allowCreateWithoutClient! : true,

            sort: props.defaultSort || {},
            count: props.listCount || 20,
            skip: 0,
            page: 0,

            totalItems: 1,
            moreInfoOpen: -1,
            deleteOpen: -1,
            view: props.defaultView || "list",
            pagination: props.pagination === false ? false : true
        };
    }
    componentWillReceiveProps(newProps: ElementListBoxProperties<T>) {
        let newState = {};
        if (this.state.keys !== newProps.keys) {
            let keys = newProps.keys || {};
            if (!newProps.filterCid) {
                keys = Object.assign(
                    {},
                    ...Object.keys(keys).map(k => ({ ...(k !== "cid" ? { [k]: keys[k] } : null) }))
                );
            }
            newState = { ...newState, keys };
        }
        if (this.state.filters !== newProps.filters) {
            newState = { ...newState, filters: newProps.filters };
        }
        if (this.state.details !== newProps.details) {
            newState = { ...newState, details: newProps.details };
        }

        if (newProps.forceRefresh && newProps.forceRefresh !== this.props.forceRefresh) {
            this.refresh();
            if (this.props.resetRefresh) {
                this.props.resetRefresh();
            }
        }
        this.setState({ ...newState });
    }

    componentWillMount() {
        this._getClients();
        this.refresh();
    }

    async _getClients() {
        if (this.props.filterCid) {
            this.setState({ clients: await doGetClients(["name", "cid"]) });
        }
    }

    async refresh(newPage?: number, clear?: boolean) {
        const page = newPage !== undefined ? newPage : this.state.page;

        const limit = clear ? (this.state.page + 1) * this.state.count : this.state.count;
        const skip = (page || 0) * this.state.count;

        this.setState({ loading: true, deleteOpen: -1 }, async () => {
            const filter = {...this.state.filter, search: this.state.filter.search.trim() };
            const { items, total } = await this.props.refresh(filter, {
                limit,
                skip,
                ...(this.state.sort ? { sort: this.state.sort } : null)
            });
            this.setState({
                loading: false,
                items,
                totalItems: total,
                page,
                skip
            });
        });
    }

    async _refresh(newPage?: number, clear?: boolean) {
        return this.refresh(newPage, clear);
    }

    _createAlert(message: string, type: IAlertType) {
        createAlert(message, type);
    }

    renderDetailContent(item: T) {
        const { detailContent } = this.props;
        if (detailContent === undefined) {
            return;
        }
        return detailContent(item);
    }
    _renderDetails(item: dynamic<string | JSX.Element>) {
        const { details } = this.state;

        return Object.keys(details || {}).map(k => (
            <div style={{ flex: "1", flexBasis: "50%", padding: "5px 0" }}>
                <span style={{ fontWeight: "bold" }}>{`${details![k]}`}: </span>
                {item[k]}
            </div>
        ));
    }
    _renderMoreInfo(item: dynamic<string | JSX.Element>, index: number, unmutatedItem: T) {
        const { moreInfoOpen } = this.state;
        return [
            <Modal open={moreInfoOpen == index} title="Details" closeAction={() => this.setState({ moreInfoOpen: -1 })}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {this._renderDetails(item)}
                    {this.renderDetailContent(unmutatedItem)}
                </div>
            </Modal>
        ];
    }

    _renderDelete(index: number, unmutatedItem: T) {
        const { deleteOpen } = this.state;
        return [
            <Modal
                open={deleteOpen == index}
                title="Confirm Delete"
                closeAction={() => this.setState({ deleteOpen: -1 })}
                footer={
                    <div>
                        <Button onClick={() => this._delete(unmutatedItem)}>Yes</Button>
                        <Button onClick={() => this.setState({ deleteOpen: -1 })}>No</Button>
                    </div>
                }
            >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {this._renderDetails(
                        Object.assign({}, ...Object.keys(unmutatedItem).map(k => ({ [k]: `${unmutatedItem[k]}` })))
                    )}
                </div>
            </Modal>
        ];
    }

    _hasItems() {
        const { items } = this.state;
        return items.length > 0;
    }

    getSortedItems() {
        const { movable } = this.props;
        const { items } = this.state;
        let _items = items ? [...items] : [];
        if (movable) {
            _items = _items.sort((a, b) => a[movable] - b[movable]);
        }
        return _items;
    }
    _getItems() {
        const { keys, filter, details } = this.state;
        const { detailContent, minified, confirmDelete, createRow } = this.props;
        const { cid, ...filterWithoutCid } = filter;

        const items = this.getSortedItems();
        const _items = [
            ...(createRow ? [createRow] : []),
            ...items
                .map((item, i) => ({
                    ...item,
                    actions: [
                        ...((details && Object.keys(details).length) || detailContent !== undefined
                            ? [
                                <Button
                                    key={`more-info--${i}`}
                                    className="btn--sm"
                                    onClick={() => this.setState({ moreInfoOpen: i })}
                                >
                                    <i className={`fa fa-info`} /> {minified ? "" : "Details"}
                                </Button>
                            ]
                            : []),
                        ...(this.props.buttonActions || []).map(i => (
                            <Button
                                key={`${this.linkReplacement(`${i.text}`, item)}--${i}`}
                                className="btn--sm"
                                onClick={() => i.onClick(item, filter)}
                            >
                                <i className={`fa ${i.icon}`} /> {minified ? "" : i.text}
                            </Button>
                        )),
                        ...(this.props.buttonElements || []).map(i => i(item, filter)),
                        ...(this.props.actions || []).map(i => (
                            <Button
                                key={`${this.linkReplacement(`${i.link}`, item)}--${i}`}
                                className="btn--sm"
                                to={`${this.linkReplacement(`${i.link}`, {
                                    ...item,
                                    ...(filter.cid == "" ? filterWithoutCid : filter)
                                })}`}
                            >
                                <i className={`fa ${i.icon}`} /> {minified ? "" : i.text}
                            </Button>
                        )),
                        ...(this.props.editLink && !this.props.dropdownOptions
                            ? [
                                <Button
                                    key={`link-edit--${i}`}
                                    className="btn--sm"
                                    to={`${this.linkReplacement(`${this.props.editLink}`, {
                                        ...item,
                                        ...(filter.cid == "" ? filterWithoutCid : filter)
                                    })}`}
                                    onClick={() => {
                                        createAlert(`Navigating to the edit page`, 'success');
                                    }}
                                >
                                    <i className="fa fa-edit" /> {minified ? "" : "Edit"}
                                </Button>
                            ]
                            : []),
                            ...(this.props.editLink && this.props.dropdownOptions && this.props.onDropdownSelect ? [
                                <div className="elementListBoxEditButtons">
                                    <Button
                                        key={`link-edit--${i}`}
                                        className="btn--sm"
                                        to={`${this.linkReplacement(`${this.props.editLink}`, {
                                            ...item,
                                            ...(filter.cid == "" ? filterWithoutCid : filter)
                                        })}`}
                                        onClick={() => {
                                            createAlert(`Navigating to the edit page`, 'success');
                                        }}
                                    >
                                        <i className="fa fa-edit" /> {minified ? "" : "Edit"}
                                    </Button>
                                    <Ellipsis
                                        dropdownOptions={this.props.dropdownOptions}
                                        onSelect={(opt) => this.props.onDropdownSelect!(opt, item)}
                                        className="elementListBox"
                                    />
                                </div>
                            ] : []),
                        ...(this.props.delete
                            ? [
                                <Button
                                    key={`link-delete--${i}`}
                                    className="btn--sm"
                                    onClick={() => {
                                        if (confirmDelete) {
                                            this.setState({ deleteOpen: i });
                                        } else {
                                            this._delete(item);
                                        }
                                    }}
                                >
                                    <i className="fa fa-trash" /> {minified ? "" : "Delete"}
                                </Button>
                            ]
                            : []),
                        ...(this.props.undo
                            ? [
                                <Button
                                    key={`link-undo--${i}`}
                                    className="btn--sm"
                                    onClick={() => {
                                        this._undo(item);
                                    }}
                                >
                                    <i className="fa fa-repeat" /> {minified ? "" : "Undo"}
                                </Button>
                            ]
                            : [])
                    ]
                }))
                .map((item, index, array) => ({
                    ...(this.props.filterCid && "cid" in item
                        ? { cid: this.getClientName(((item as unknown) as { cid: string }).cid) }
                        : null),
                    ...this.props.mutateItem(item, index, array, items[index], this),
                    actions: [
                        ...((details && Object.keys(details).length) || detailContent !== undefined
                            ? this._renderMoreInfo(
                                this.props.mutateItem(item, index, array, items[index], this),
                                index,
                                item
                            )
                            : []),
                        ...(confirmDelete ? this._renderDelete(index, item) : []),
                        ...item.actions
                    ]
                }))
        ];

        return _items.map(item => Object.assign({}, ...Object.keys(keys).map(k => ({ [k]: item[k] })))) as dynamic;
    }

    getClientName(cid: string) {
        if (!cid) {
            return "None";
        }
        if (!this.state.clients.length) {
            return "Loading";
        }
        return (this.state.clients.find(client => client.cid === cid) || { name: "Unknown" }).name;
    }
    async sortItems(oldIndex: number, newIndex: number) {
        const { movable, update } = this.props;

        if (!movable || !update) {
            return;
        }
        this.setState({ loading: true });

        let newItems = this.getSortedItems().slice();
        const item: T = newItems.splice(oldIndex, 1)[0];
        newItems.splice(newIndex, 0, item);
        newItems = newItems.map((item, index) => ({ ...item, [movable]: index + (this.state.page * this.state.count) }));
        const updatedItems = newItems.filter(
            item => this.getSortedItems().find(data => JSON.stringify(data) == JSON.stringify(item)) === undefined
        );
        for (const item of updatedItems) {
            await update(this.state.filter, item, true);
        }
        this.setState({ items: newItems, loading: false });
    }

    async _download() {
        if (!this._hasItems()) {
            createAlert(`No data available.`, `error`);
            return;
        }
        if (this.props.download) {
            await this.props.download(this.state.filter, {
                limit: this.state.count,
                skip: this.state.skip,
                total: this.state.totalItems,
                ...(this.state.sort ? { sort: this.state.sort } : null)
            });
        }
    }

    async _delete(item: T) {
        if (this.props.delete) {
            if (await this.props.delete(item, this.state.filter)) {
                this.refresh();
            }
        }
    }

    async _undo(item: T) {
        if (this.props.undo) {
            if (await this.props.undo(item, this.state.filter)) {
                this.refresh();
            }
        }
    }

    _clearFilters() {
        this.setState(
          { filter: { ...this.state.defaultFilter, search: "", hasActivated: "", rid: "", groups: "" } },
          () => {
            this._refresh(0);
            createAlert('Filters have been cleared', 'success');
          }
        );
    }

    linkReplacement(link: string, item: dynamic) {
        if (!link) {
            return link;
        }
        const items = link.match(/:(\w+)/gi) || [];
        for (const linkPart of items) {
            const key = linkPart.replace(":", "");
            if (!(key in item)) {
                continue;
            }
            link = link.replace(linkPart, item[key]);
        }
        return link;
    }

    getKey(key: string) {
        for (const k in this.state.keys) {
            if (this.state.keys[k] === key) {
                return k;
            }
        }
        return key;
    }

    renderChildrenFilters() {
        if (this.props.children) {
            return this.props.children;
        }
        return null;
    }
    renderFilters() {
        if (!this.props.filterSearch && !this.props.filterCid && !(this.state.filters &&
            Object.keys(this.state.filters)) && !this.props.children) {
            return;
        }
        return <>
            <div className="table__filters">
                {(("filterSearch" in this.props && this.props.filterSearch) || !("filterSearch" in this.props)) && this.state.view === "list" && (
                    <div className="table__filters__option">
                        <label htmlFor="search">{this.props.searchLabel || "Search"}:</label>
                        <div className="search">
                            <input
                                id="search"
                                className="admins__search"
                                type="text"
                                value={this.state.filter.search}
                                onChange={e =>
                                    this.setState(
                                      { filter: { ...this.state.filter, search: e.target.value } },
                                      () => {
                                        this._refresh(0);
                                        this._createAlert('Search filter updated', 'success');
                                      }
                                    )
                                  }
                            />
                            <i className="fa fa-search" />
                        </div>
                    </div>
                )}
                {this.props.filterCid && (
                    <div id="cid" className="table__filters__option">
                        <label htmlFor="clients">Filter By Client</label>
                        <div className="select__wrapper">
                            <select
                                id="clients"
                                value={this.state.filter.cid}
                                onChange={e =>
                                    this.setState(
                                        { filter: { ...this.state.defaultFilter, ...this.state.filter, cid: e.target.value } },
                                        () => this._refresh(0)
                                    )
                                }
                            >
                                <option value="">All</option>
                                {this.props.includeConsumers && <option value="consumers">All Consumers</option>}
                                <optgroup label="Clients">
                                    {this.state.clients &&
                                        this.state.clients.map(client => {
                                            if (!client.cid) {
                                                return;
                                            }
                                            return (
                                                <option key={client.cid} value={client.cid}>
                                                    {client.name}
                                                </option>
                                            );
                                        })}
                                </optgroup>
                            </select>
                        </div>
                    </div>
                )}
                {this.state.filters && this.state.view === "list" &&
                    Object.keys(this.state.filters).map(i => {
                        if (!this.state.filters || !this.state.filters[i]) {
                            return;
                        }
                        const filter = this.state.filters[i];
                        if (filter.defaultDate) {
                            return (
                                <div className="table__filters__option date__wrapper" key={`filter--${i}`}>
                                    <label htmlFor="startDate">{filter.title}</label>
                                    <DatePickerV2
                                        id="startDate"
                                        dateFormat="yyyy/MM/dd"
                                        value={this.state.filter[i] || filter.defaultDate}
                                        onChange={(value, formattedValue) =>
                                            this.setState({ filter: { ...this.state.filter, [i]: value } }, () =>
                                                this._refresh(0)
                                            )
                                        }
                                        onBlur={() =>
                                            this.setState({ filter: { ...this.state.filter, [i]: "" } }, () =>
                                                this._refresh(0)
                                            )
                                        }
                                        enableTabLoop={false}
                                    />
                                </div>
                            );
                        }
                        if (filter.options) {
                            return (
                                <div className="table__filters__option" key={`filter--${i}`}>
                                    <label htmlFor={filter.title.replace(" ", "")}>{filter.title}</label>
                                    <div className="select__wrapper">
                                        <select
                                            id={filter.title.replace(" ", "")}
                                            onChange={e => {
                                                this.setState(
                                                    { filter: { ...this.state.filter, [i]: e.target.value } },
                                                    () => this._refresh(0)
                                                )
                                            }}
                                            value={this.state.filter[i]}
                                        >
                                            {filter.options.map(option => (
                                                <option key={option.key} value={option.value}>
                                                    {option.key}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="table__filters__option" key={`filter--${i}`}>
                                    <label htmlFor={filter.title.replace(" ", "")}>{filter.title}</label>
                                    <input
                                        id={filter.title.replace(" ", "")}
                                        type="text"
                                        onChange={e =>
                                            this.setState(
                                                { filter: { ...this.state.filter, [i]: e.target.value } },
                                                () => this._refresh(0)
                                            )
                                        }
                                        value={this.state.filter[i]}
                                    />
                                </div>
                            );
                        }
                    })}
                <div style={{ paddingLeft: "15px", paddingTop: "12px" }}>
                    <label className="" />
                    <div style={{ display: "flex", cursor: "pointer" }}>
                        <Link style={{ textDecoration: "underline", color: "#152733" }}
                            onClick={() => this._clearFilters()}
                        >Clear Filter</Link>
                    </div>
                </div>
                {this.renderChildrenFilters()}
            </div>
            <hr />
        </>;
    }

    renderContent() {
        const { noRecordsMessage, calendarProps, refresh, isSocialInteractions } = this.props;
        if (noRecordsMessage && !this._hasItems()) {
            return (
                <div className="ibox-content">
                    <h3>{noRecordsMessage}</h3>
                </div>
            );
        }
        return (
            <div>
                {isSocialInteractions &&
                    <div style={{ backgroundColor: "#cb8a3a", color: "#fff", padding: "10px", marginLeft: "20px", marginRight: "20px" }}><i className="fa fa-warning"></i> Only the top 20 Social Interactions for the selected period are shown on the Social Interactions dashboard. Click on the ‘Download’ button to get the full report</div>
                }
                <div className="ibox-content">
                    {calendarProps && <div className="table__filters__option">
                        <label htmlFor="displayview">Display View</label>
                        <div className="select__wrapper">
                            <select
                                id="displayview"
                                value={this.state.view}
                                onChange={e =>
                                    this.setState(
                                        { view: e.target.value }
                                    )
                                }
                            >
                                <option value="list">List</option>
                                <option value="calendar">Calendar</option>
                            </select>
                        </div>
                    </div>}
                    {this.renderFilters()}
                    {this.state.view === "list" && this.renderTable()}
                    {this.state.view === "list" && this.state.pagination && <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <Pagination
                            page={this.state.page}
                            totalItems={this.state.totalItems}
                            numPerPage={this.state.count}
                            changePage={newPage => this.refresh(newPage)}
                            disabled={this.state.loading}
                            showTotal={true}
                        />
                    </div>}
                </div>
                {this.state.view === "calendar" &&
                    <EventCalendar {...{ ...calendarProps!, cid: this.state.filter.cid, refresh: () => refresh({ search: "", cid: this.state.filter.cid, active: "true", startDate: "1970-01-01T00:00:00.000Z" }, { skip: 0, limit: 2147483648 }) }} />}
            </div>
        );
    }
    renderTable() {
        const originalItems = this.state.items;
        const items = this._getItems().map(i => {
            return Object.values(i)
        });
        const keys = Object.keys(this.state.keys).map(k => {
            if (typeof this.state.keys[k] === "object") return { [k]: this.state.keys[k][0] };
            return { [k]: this.state.keys[k] };
        });

        if (this.props.movable) {
            return (
                <SortableTable
                    name={this.state.title}
                    keys={Object.values(this.state.keys)}
                    items={items}
                    loading={this.state.loading}
                    onSortEnd={async (oldIndex, newIndex) => {
                        await this.sortItems(oldIndex, newIndex);
                    }}
                />
            );
        }
        return (
            <Table
                name={this.state.title}
                keys={Object.values(this.state.keys)}
                items={items}
                loading={this.state.loading}
                canSort={key =>
                    (this.props.sortableKeys || []).indexOf(key) >= 0
                }
                onSort={(sort, direction) => {
                    this.setState({ sort: { [this.getKey(sort)]: direction } }, () => this.refresh(0, true));
                }}
                defaultSort={{
                    [keys[Object.keys(this.state.sort)[0]]]: Object.values(this.state.sort)[0]
                }}
                // the items props above may contain create rows, this flag indicates if there are any actual items to display
                showNoRecords={(!originalItems || originalItems.length == 0)}
            />
        );
    }
    render() {
        const { minified, calendarProps } = this.props;
        return (
            <div className={`elementListBox ibox float-e-margins ${minified ? "elementListBox--minified" : ""}`}>
                <div className="ibox-title">
                    {this.props.title && <h5>{this.props.title}</h5>}
                    {(this.props.createLink ||
                        (this._hasItems() && this.props.download) ||
                        this.props.importLink ||
                        this.props.buttons) && (
                            <div className="ibox-tools">
                                {this.props.createLink !== undefined &&
                                    (this.state.allowCreateWithoutClient ||
                                        (!this.state.allowCreateWithoutClient && this.state.filter.cid)) && (
                                        <Button
                                            className="btn--sm"
                                            to={`${this.linkReplacement(this.props.createLink, {
                                                cid: this.state.filter.cid
                                            })}${calendarProps ? `?view=${this.state.view}` : ""}`}
                                        >
                                            <i className="fa fa-plus-square" /> Create
                                        </Button>
                                    )}
                                {this.props.download !== undefined && this.state.view === "list" && (
                                    <DownloadButton className="btn--sm" onClick={() => this._download()}>
                                        <i className="fa fa-download" /> Download
                                    </DownloadButton>
                                )}
                                {this.props.importLink !== undefined && (
                                    <Button
                                        className="btn--sm"
                                        to={`${this.linkReplacement(this.props.importLink, {
                                            cid: this.state.filter.cid
                                        })}`}
                                    >
                                        <i className="fa fa-upload" /> Import
                                    </Button>
                                )}
                                {this.props.buttons !== undefined && this.props.buttons(this.state.filter, {
                                    limit: this.state.count,
                                    skip: this.state.skip,
                                    ...(this.state.sort ? { sort: this.state.sort } : null)
                                })}
                            </div>
                        )}
                </div>
                {this.renderContent()}
            </div>
        );
    }
}
