export const MESSAGE_STATUS = [
    { name: "Draft", value: "draft" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
];
export const EDITABLE_TEMPLATE_TYPE = [
    { name: "Provided", value: "fixed" },
    { name: "Customized", value: "customizable" }
];
export const MESSAGE_FREQUENCY = [
    { name: "One Time", value: "onetime" },
    { name: "Varies", value: "varies" },
    { name: "Weekly", value: "weekly" },
    { name: "Biweekly", value: "biweekly" },
    { name: "Monthly", value: "monthly" },
    { name: "Quarterly", value: "quarterly" },
    { name: "Annually", value: "annually" }
];
export const MESSAGE_TYPE = [
    { name: "Article/Media Template", value: "article" },
    { name: "Custom Template", value: "standard" }
];

export const SUBSCRIBABLE_STATUS = [{ name: "Active", value: "active" }, { name: "Inactive", value: "inactive" }];
export const SUBSCRIBABLE_FREQUENCY = [
    { name: "Varies", value: "varies" },
    { name: "Weekly", value: "weekly" },
    { name: "Biweekly", value: "biweekly" },
    { name: "Monthly", value: "monthly" },
    { name: "Quarterly", value: "quarterly" },
    { name: "Annually", value: "annually" }
];

export const CATEGORY_STATUS = [{ name: "Active", value: "active" }, { name: "Inactive", value: "inactive" }];
export const EMAIL_TEMPLATE_CATEGORY = [{ name: "Active", value: "active" }, { name: "Inactive", value: "inactive" }];

export const containValue = (list, value) => {
    for (const item of list) {
        if (item.value == value) {
            return true;
        }
    }
    return false;
};
export const getNamebyValue = (list, value) => {
    for (const item of list) {
        if (item.value == value) {
            return item.name;
        }
    }
    return "";
};
