import React, { Component } from "react";
import Spinner from "../../atoms/Spinner";
import DatePickerV2 from "../../atoms/DatePickerV2";

import { apiRequest, getAdminDate } from "../../../utils/Helpers";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import Pagination from "../../molecules/Pagination";
import { Wrapper, Row } from "../../atoms/Layout";

interface IDomainTrendingState {
    endDate: string;
    total: number;
    currPage: number;
    showPage: number;
    start: number;
    rows: number;
    lang: string;
    domainTrending: "loading" | Array<{
        domain: string;
        w1: number;
        w2: number;
        w3: number;
        w4: number;
        w5: number;
        average: number;
        percentage: number;
    }>;
}
class DomainTrending extends Component<{}, IDomainTrendingState> {
    static allowSuper = true;
    static allowAPIAdmin = true;
    static allowSuperClientAdmin = false;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);
        this.refreshData = this.refreshData.bind(this);
        this._onFieldChange = this._onFieldChange.bind(this);
        this._onFilterChange = this._onFilterChange.bind(this);
        this.renderDomainTrending = this.renderDomainTrending.bind(this);

        this.state = {
            endDate: new Date().toISOString(),
            total: 0,
            currPage: 0,
            showPage: 0,
            start: 0,
            rows: 50,
            lang: "en",
            domainTrending: "loading"
        };
    }
    componentDidMount() {
        this.refreshData();
    }

    _onFilterChange(field: keyof IDomainTrendingState, event) {
        this.setState(
            {
                [field]: event.target ? event.target.value : event
            } as IDomainTrendingState,
            this.refreshData
        );
    }

    refreshData() {
        this.setState({ domainTrending: "loading" });
        apiRequest(
            `${appConfig.API_URL}/getDomainTrending?start=${this.state.currPage * this.state.rows}&rows=${this.state.rows
            }&endDate=${this.state.endDate}&lang=${this.state.lang}`
        ).then(data => {
            if ("valid" in data && !data.valid) {
                createAlert(data.error, `error`);
                return;
            }
            this.setState({ domainTrending: data.items, total: data.numFound });
        })
    }

    _onFieldChange(field: keyof IDomainTrendingState, val) {
        const value = val && val.target ? val.target.value : val;
        if (field == "start") {
            this.setState(
                {
                    [field]: value
                },
                this.refreshData
            );
        } else {
            this.setState({ start: 0, [field]: value } as IDomainTrendingState, this.refreshData);
        }
    }

    renderDomainTrending() {
        const { domainTrending } = this.state;

        // endDate is rounded to the end of the last week on the core team side
        // technically subtracting (week5.getUTCDay() + 1) to get the saturday should be more accurate,
        // but following the convention from the articlecount report where it displays the sunday
        const week5 = new Date(this.state.endDate);
        week5.setDate(week5.getDate() - week5.getUTCDay());
        const week4 = new Date(week5);
        week4.setDate(week4.getDate() - 7);
        const week3 = new Date(week4);
        week3.setDate(week3.getDate() - 7);
        const week2 = new Date(week3);
        week2.setDate(week2.getDate() - 7);
        const week1 = new Date(week2);
        week1.setDate(week1.getDate() - 7);

        return (
            <Row>
                <div className="col-lg-12">
                    <div className="ibox float-e-margins">
                        <div className="ibox-content">
                            <div className="table__filters">
                                <div className="table__filters__option">
                                    <label className="control-label">End Date</label>
                                    <div className="date__wrapper">
                                        <DatePickerV2
                                            dateFormat="yyyy/MM/dd"
                                            value={this.state.endDate}
                                            onChange={(value, formattedValue) => this._onFieldChange("endDate", value)}
                                            enableTabLoop={false}
                                        />
                                    </div>
                                </div>
                                <div className="table__filters__option">
                                    <label
                                        className="control-label"
                                        style={{
                                            marginLeft: "10px"
                                        }}
                                    >
                                        Language
                                    </label>
                                    <div className="select__wrapper">
                                        <select
                                            value={this.state.lang}
                                            onChange={event => this._onFieldChange("lang", event)}
                                        >
                                            <option value="en">English</option>
                                            <option value="fr">French</option>
                                            <option value="es">Spanish</option>
                                            <option value="de">German</option>
                                            <option value="pt">Portuguese</option>
                                            <option value="it">Italian</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {domainTrending === "loading" && <Spinner />}
                            {Array.isArray(domainTrending) && !domainTrending.length && <h3
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                Could not find any reporting data
                            </h3>}
                            {Array.isArray(domainTrending) && domainTrending.length && <div className="table-responsive">
                                <div
                                    style={{
                                        float: "right"
                                    }}
                                >
                                    <Pagination
                                        page={this.state.currPage}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.rows}
                                        currItems={(domainTrending || []).length}
                                        changePage={newPage => this._onFieldChange("currPage", newPage)}
                                        disabled={!domainTrending || domainTrending.length == 0}
                                        showTotal={true}
                                    />
                                </div>
                                <table className="table table-striped responsive" data-type="responsive">
                                    <thead>
                                        <tr>
                                            <th tabIndex={0}>Domain</th>
                                            <th tabIndex={0}>{getAdminDate(week1, false)}</th>
                                            <th tabIndex={0}>{getAdminDate(week2, false)}</th>
                                            <th tabIndex={0}>{getAdminDate(week3, false)}</th>
                                            <th tabIndex={0}>{getAdminDate(week4, false)}</th>
                                            <th tabIndex={0}>{getAdminDate(week5, false)}</th>
                                            <th tabIndex={0}>Avg</th>
                                            <th tabIndex={0}>%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {domainTrending &&
                                            domainTrending.length > 0 &&
                                            domainTrending.map(function (domainTrending, i) {
                                                return (
                                                    <tr key={domainTrending.domain + i}>
                                                        <td tabIndex={0}>{domainTrending.domain || "N/A"}</td>
                                                        <td tabIndex={0}>{domainTrending.w1 || "N/A"}</td>
                                                        <td tabIndex={0}>{domainTrending.w2 || "N/A"}</td>
                                                        <td tabIndex={0}>{domainTrending.w3 || "N/A"}</td>
                                                        <td tabIndex={0}>{domainTrending.w4 || "N/A"}</td>
                                                        <td tabIndex={0}>{domainTrending.w5 || "N/A"}</td>
                                                        <td tabIndex={0}>{domainTrending.average || "N/A"}</td>
                                                        <td tabIndex={0}>{domainTrending.percentage || "N/A"}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <div
                                    style={{
                                        float: "right"
                                    }}
                                >
                                    <Pagination
                                        page={this.state.currPage}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.rows}
                                        currItems={(domainTrending || []).length}
                                        changePage={newPage => this._onFieldChange("currPage", newPage)}
                                        disabled={!domainTrending || domainTrending.length == 0}
                                        showTotal={false}
                                    />
                                </div>
                                <div style={{ clear: "both", height: "6px" }} />
                            </div>}
                        </div>
                    </div>
                </div>
            </Row>
        );
    }

    render() {
        return (
            <Wrapper>
                <h1>Domain Trending</h1>
                {this.renderDomainTrending()}
            </Wrapper>
        );
    }
}
export default DomainTrending;
