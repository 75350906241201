import BaseAction from "../utils/BaseAction";

export type ISmsActions = ClassInstance<typeof _SmsActions>;

class _SmsActions extends BaseAction {

    async doExportCSV(cid: string) {
        const data = await this.apiRequest("/sms/csv/export", "GET", { cid });
        if (data) {
            return this.downloadFile("sms-export", data);
        }
    }
}

export const SmsActions = new _SmsActions();
