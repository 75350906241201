import React, { Component } from "react";
import { connect } from "react-redux";
import CSVUpload from "../../molecules/CSVUpload";

class UploadUsers extends Component {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);
    }

    render() {
        return <CSVUpload
            inc={500}
            itemName="User"
            routeName="clientuser"
            note={<span>
                <p style={{ paddingLeft: "50px", fontWeight: "bold" }}>Client Admins and above may update a user's email address using the "New Email Address" column.</p>
                <p style={{ paddingLeft: "50px", fontWeight: "bold" }}>Attempting to update a user's email address using the "Email" column will create a new user.</p>
                <p style={{ paddingLeft: "50px", fontWeight: "bold" }}>If a feature is disabled at the client level, permissions for that feature will be ignored when importing users.</p>
                <p style={{ paddingLeft: "50px", fontWeight: "bold" }}>Please ensure the column "Has Access" is provided and completed for each user when Importing Users.</p>
            </span>}
        />
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(UploadUsers);
