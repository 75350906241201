import React, { Component } from "react";
import { connect } from "react-redux";
import { doGetStreams, doDeleteStream, doUpsertStream, doGetClient, createAlert } from "../../../actions/";
import { getFriendlyDate, translateLanguage } from "../../../utils/Helpers";
import { SortableHandle } from "react-sortable-hoc";
import ElementListBox, { Filter, PaginationData } from "../../molecules/ElementListBox";
import Button from "../../atoms/Button";
import CreateStreamModal from "../../molecules/modals/CreateStreamModal";
import { Wrapper, Row } from "../../atoms/Layout/";

interface IStreamListProps {
    match: { params: { cid: string } };
    user: Mongo.clientAdmin;
    clients: dynamic<Mongo.IClient>;
    active?: boolean;
}
interface IStreamListState {
    cid: string;
    client: ServerTypes.Console.IClient | null;
    filters: {
        active: boolean;
    };
    refresh: boolean;
    createModal: boolean;
}

class List extends Component<IStreamListProps, IStreamListState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowSuperClientAdmin = true;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props: IStreamListProps) {
        super(props);

        let cid = props.match.params.cid;
        if (!props.user.isSuper) {
            cid = props.user.cid!;
        }
        this.state = {
            cid,
            client: null,
            filters: {
                active: "active" in props ? props.active! : true
            },
            refresh: false,
            createModal: false
        };
    }

    async componentDidMount() {
        this.setState({ client: await doGetClient(this.state.cid) });
    }

    async _refresh(filters: Filter, paginationData: PaginationData) {
        const { items, count } = await doGetStreams(
            `${filters.active}`.toLowerCase() === "true",
            filters.cid,
            filters.search,
            paginationData.sort,
            paginationData.limit,
            paginationData.skip
        );
        return { items, total: count };
    }

    componentWillReceiveProps(newProps: IStreamListProps) {
        if (this.props.active != newProps.active) {
            this.setState({
                filters: { ...this.state.filters, active: "active" in newProps ? newProps.active! : true }
            });
        }
    }

    async _deleteSearch(cid: string, sid: string) {
        await doDeleteStream(cid, sid);
    }

    getStreamLabel(stream: ServerTypes.Console.IStream) {
        if (stream.type) {
            switch (stream.type as string) {
                case "FEED":
                    return "RSS/SOCIAL FEED";
                case "CORP": // WHY?
                case "CORPORATE":
                        return "CORPORATE";
                case "CURATED":
                    return `CURATED ${stream.searchType}`;
                case "TRENDING":
                    return "TRENDING";
                case "RECOMMENDED":
                    return "RECOMMENDED";
                case "COMPANY":
                    return "COMPANY";
                case "HELP":
                    return "HELP";
                default:
                case "DEFAULT":
                    return `DEFAULT ${stream.searchType}`;
            }
        }
        if (stream.contentSearch && stream.isCustom && (stream.terms || []).length) {
            if (
                (stream.feeds || []).length || (stream.seismicFeeds || []).length ||
                (stream.facebookFeeds && stream.facebookFeeds.pages && stream.facebookFeeds.pages.length) ||
                (stream.twitterFeeds && stream.twitterFeeds.screenNames && stream.twitterFeeds.screenNames.length)
            ) {
                return "RSS/SOCIAL FEED";
            } else {
                return `CURATED ${stream.searchType}`;
            }
        } else if (stream.searchType == "CORP") {
            return "CORPORATE";
        } else if (
            (stream.feeds || []).length || (stream.seismicFeeds || []).length ||
            (stream.facebookFeeds && stream.facebookFeeds.pages && stream.facebookFeeds.pages.length) ||
            (stream.twitterFeeds && stream.twitterFeeds.screenNames && stream.twitterFeeds.screenNames.length)
        ) {
            return "RSS/SOCIAL FEED";
        } else if (!stream.contentSearch) {
            return `DEFAULT ${stream.searchType}`;
        } else {
            return `CURATED ${stream.searchType}`;
        }
    }


    render() {
        const DragHandle = SortableHandle(() => <i className="fa fa-bars" />);
        const { client, createModal } = this.state;
        return (
            <Wrapper id="configurationPage">
                {client && (
                    <CreateStreamModal
                        client={client}
                        open={createModal}
                        closeAction={() => this.setState({ createModal: false })}
                    />
                )}
                <Row>
                    <ElementListBox<ServerTypes.Console.IStream>
                        title="Stream Configuration"
                        keys={{
                            streamOrder: "",
                            streamType: "Stream Type",
                            title: "Title",
                            lang: "Language",
                            ...(client && client.allowSectionView
                                ? { sectionCount: "Rows on Home Page" }
                                : { count: "Consecutive Cards" }),
                            lastUpdatedOn: "Updated",
                            actions: "Actions"
                        }}
                        defaultSort={{ streamOrder: 1 }}
                        defaultFilter={{
                            ...(this.state.cid ? { cid: this.state.cid } : null),
                            active: `${this.state.filters.active}`
                        }}
                        filterCid={false}
                        refresh={({ search, cid, active }, paginationData) =>
                            this._refresh({ search, cid, active }, paginationData)
                        }
                        movable="streamOrder"
                        listCount={1000}
                        update={async ({ cid }, stream, sort = false) => {
                            try {
                                return await doUpsertStream(cid, {...stream, allowSectionView: client && client.allowSectionView ? client.allowSectionView : false}, sort)
                            } catch (error: any) {
                                const errorMsg = `${error} - ${stream.title}`;
                                createAlert(errorMsg, "error");
                            };
                        }}
                        resetRefresh={() => this.setState({ refresh: false })}
                        forceRefresh={this.state.refresh}
                        mutateItem={(stream, index, items, baseStream, context) => ({
                            streamOrder: <DragHandle />,
                            streamType: this.getStreamLabel(stream),
                            title: stream.title,
                            lang: translateLanguage(stream.lang),
                            ...( !client?.allowSectionView ? {
                                count: (
                                    <div className="select__wrapper">
                                        <select
                                            onChange={async event => {
                                                const count = Number(event.target.value);
                                                await doUpsertStream(this.state.cid, {
                                                    ...baseStream,
                                                    count,
                                                    allowSectionView: client && client.allowSectionView ? client.allowSectionView : false
                                                });
                                                this.setState({ refresh: true });
                                            }}
                                            value={stream.count}
                                        >
                                            {[...Array(5).keys()].map(val => {
                                                val++;
                                                return (
                                                    <option key={val} value={val}>
                                                        {val}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )
                            }: {
                                sectionCount: (
                                    <div className="select__wrapper">
                                        <select
                                            onChange={async event => {
                                                const sectionCount = Number(event.target.value);
                                                await doUpsertStream(this.state.cid, {
                                                    ...baseStream,
                                                    sectionCount
                                                });
                                                this.setState({ refresh: true });
                                            }}
                                            value={stream.sectionCount || 1}
                                            aria-label={`${stream.sectionCount || 1}`}
                                        >
                                            {[...Array(2).keys()].map(val => {
                                                val++;
                                                return (
                                                    <option key={val} value={val}>
                                                        {val}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )
                            }),
                            lastUpdatedOn: stream.lastUpdatedOn ? getFriendlyDate(stream.lastUpdatedOn)! : "N/A"
                        })}
                        editLink={`/clients/s/:cid/e/:sid`}
                        buttons={() => [
                            <Button className="btn--sm" onClick={() => this.setState({ createModal: true })}>
                                <i className="fa fa-plus-square" /> Create
                            </Button>
                        ]}
                        filters={{
                            active: {
                                title: "Status",
                                options: [{ key: "Active", value: "true" }, { key: "Inactive", value: "false" }]
                            }
                        }}
                        delete={({ sid }, { cid }) => {
                            if (confirm("Are you sure you want to delete this stream?")) {
                                return doDeleteStream(cid, sid)
                            }
                            return Promise.resolve()
                        }}
                    />
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    user: state.session.admin
});

export default connect(mapStateToProps)(List);
