import React from "react";
import Modal from "react-bootstrap-modal";
import { connect } from "react-redux";

import { apiRequest, apiFormDataPost } from "../../../utils/Helpers";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import Spinner from "../../atoms/Spinner";
import EditableInput from "../../atoms/EditableInput";
import Tooltip from "../../atoms/Tooltip";
import CopyLink from "../../atoms/CopyLink";
import Link from "../../atoms/Link";
import Button from "../../atoms/Button";
import { UtilActions } from "../../../actions";

import { SUBSCRIBABLE_STATUS, SUBSCRIBABLE_FREQUENCY, containValue } from "../../../utils/ECommunicationHelpers";

class AddECommunicationSubscribable extends React.Component {
    constructor(props) {
        super(props);
        this._onFieldChange = this._onFieldChange.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._submit = this._submit.bind(this);
        this._renderDetailsPanel = this._renderDetailsPanel.bind(this);
        this._deleteSubscribable = this._deleteSubscribable.bind(this);
        this._getRenderedImageUpload = this._getRenderedImageUpload.bind(this);
        this._openFileUpload = this._openFileUpload.bind(this);
        this._fileUpload = this._fileUpload.bind(this);
        this._createEcommLanguageOptions = this._createEcommLanguageOptions.bind(this);

        let lang = "", multiLangSupport = false;
        if (props.client && props.client.lang) {
            lang = props.client.lang;
        }
        if(props.client && props.client.eCommunication && props.client.eCommunication?.langSupport) {
            multiLangSupport = props.client.eCommunication.langSupport.length === 2;
        }
        this.state = {
            open: true,
            panel: 0,
            url_loader: "",
            whiteLabelURL: "",
            sURLDomain: "",
            submit_loading: false,
            save_protect: false,
            modal_title: "Add Subscription Category",
            modal_button: "Create Subscription Category",
            type: "CREATE",
            title: "",
            description: "",
            lang: lang,
            multiLangSupport,
            thumbnail: "",
            CreatedOn: new Date().toISOString(),
            cid: props.cid,
            subscribableid: "",
            status: "active",
            frequency: "varies"
        };

        if (this.props.message) {
            this.state.panel = 1;
            this.state.type = "UPDATE";
            this.state.modal_title = "Edit Subscription Category";
            this.state.modal_button = "Update Subscription Category";
            this.state = Object.assign(this.state, this.props.message);
        }
        if (this.props.modal_button_label) {
            this.state.modal_button = this.props.modal_button_label;
            this.state.type = "CREATE";
        }
    }
    _onFieldChange(field, value) {
        let newState = {
            [field]: value && value.target ? value.target.value : value
        };

        if (this.state.type == "CREATE") {
            newState["CreatedOn"] = new Date().toISOString();
        }
        if (field == "thumbnail") {
            newState["uploadedFile"] = false;
            if (document) {
                $("#imageUpload").val("");
            }
            if (value) {
                newState["addedImage"] = true;
            } else {
                // delete the flag if the user erases the field
                newState["addedImage"] = undefined;
            }
        }
        this.setState(newState);
    }

    async componentDidMount() {
        const { client } = this.props;
        if (client && client.configName){
            const whiteLabelURLs = await UtilActions.doGetWhiteLabelURL(client.configName || "none");
            this.setState({
                whiteLabelURL: whiteLabelURLs ? whiteLabelURLs.url : "",
                sURLDomain: whiteLabelURLs ? whiteLabelURLs.SURLDomain : ""
            });
        }
        return;
    }

    _deleteSubscribable() {
        if (this.state.artid && confirm("Are you sure you want to delete this Subscription Category?")) {
            apiRequest(
                `${appConfig.API_URL}/e-communication/subscribable/delete?subscribableid=${this.state.subscribableid}`
            ).then(
                function(data) {
                    if (this.props.deleteAction) {
                        this.props.deleteAction();
                    }
                    this._closeModal();
                }.bind(this)
            );
        }
    }

    _submit(event) {
        if (event) {
            event.preventDefault();
        }
        if (this.state.submit_loading) {
            return;
        }
        if (this.state.save_protect) {
            return;
        }
        let config = this.state;

        if (config.thumbnail && !config.thumbnail.match(/\.(jpg|jpeg|png|gif|tiff)((\?|%3F).+)?$/i)) {
            createAlert("Please enter a valid image url.");
            return;
        }

        let postObj = {
            cid: config.cid,
            title: config.title,
            description: config.description,
            thumbnail: config.thumbnail,
            CreatedOn: config.CreatedOn,
            lang: config.lang,
            frequency: config.frequency,
            status: config.status,
            addedImage: config.addedImage
        };
        var url = "e-communication/subscribable/upsert";
        var action = "created";
        if (config.type == "UPDATE") {
            postObj.subscribableid = config.subscribableid;
            action = "updated";
        }

        // validate input
        if (!postObj.title || postObj.title.length == 0) {
            createAlert("Please enter a title.");
            return;
        } else if (!postObj.description || postObj.description.length == 0) {
            createAlert("Please enter a description.");
            return;
        } else if (!postObj.status || !containValue(SUBSCRIBABLE_STATUS, postObj.status)) {
            createAlert("Please select a status.");
            return;
        } else if (!postObj.frequency || !containValue(SUBSCRIBABLE_FREQUENCY, postObj.frequency)) {
            createAlert("Please select a frequency.");
            return;
        } else {
            var formData = new FormData();
            if (this.fileUpload && this.fileUpload.files && this.fileUpload.files.length > 0) {
                formData.append("file", this.fileUpload.files[0]);
                delete postObj["thumbnail"];
            }
            Object.keys(postObj).forEach(function(key) {
                if (postObj[key] != null && postObj[key] != "") {
                    formData.append(key, postObj[key]);
                }
            });

            this.setState(
                { save_protect: true, submit_loading: true },
                function() {
                    apiFormDataPost(`${appConfig.API_URL}/${url}`, formData)
                        .then(
                            function(data) {
                                if (data.valid) {
                                    createAlert(`Subscription Category has been ${action}`, `success`);
                                    if (this.props.updateAction) {
                                        this.props.updateAction(config.type == "UPDATE");
                                    }
                                } else {
                                    createAlert(data.error, `error`);
                                }
                                return data;
                            }.bind(this)
                        )
                        this.setState({ save_protect: false, submit_loading: false, });
                        this._closeModal();
                }.bind(this)
            );
        }
    }
    _closeModal() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
        createAlert('The modal for adding a new communication subscribable has been successfully closed', 'success');
    }
    _renderDivider(type) {
        switch (type) {
            case "vertical":
                return (
                    <div
                        style={{
                            flexBasis: "10%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative"
                        }}
                    >
                        <span
                            style={{
                                background: "#fff",
                                zIndex: "2",
                                padding: "5px"
                            }}
                        >
                            OR
                        </span>
                        <hr
                            style={{
                                border: 0,
                                borderLeft: "1px solid #e5e5e5",
                                position: "absolute",
                                top: "0",
                                left: "50%",
                                margin: 0,
                                height: "100%",
                                zIndex: "1"
                            }}
                        />
                    </div>
                );
            default:
                return (
                    <div
                        style={{
                            flexBasis: "10%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative"
                        }}
                    >
                        <span
                            style={{
                                background: "#fff",
                                zIndex: "2",
                                padding: "5px"
                            }}
                        >
                            OR
                        </span>
                        <hr
                            style={{
                                border: 0,
                                borderTop: "1px solid #e5e5e5",
                                position: "absolute",
                                top: "50%",
                                left: "0",
                                margin: 0,
                                width: "100%",
                                zIndex: "1"
                            }}
                        />
                    </div>
                );
        }
    }
    _renderAlt() {
        if (this.state.submit_loading) {
            return (
                <div>
                    <h3
                        style={{
                            textAlign: "center"
                        }}
                    >
                        Submitting request...
                    </h3>
                    <Spinner />
                </div>
            );
        }
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flexBasis: "45%",
                    position: "relative",
                    justifyContent: "center",
                    zIndex: 0
                }}
            >
                <Button onClick={() => this.setState({ panel: 1, source: "user" })}>New Subscription Category</Button>
            </div>
        );
    }
    _openFileUpload(event) {
        if (document) {
            event.preventDefault();
            $("#imageUpload").trigger("click");
        }
    }
    _getRenderedImageUpload() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row"
                }}
            >
                <Button onClick={this._openFileUpload} className="btn">
                    Upload a Thumb
                </Button>
                {this._renderDivider("vertical")}
                <textarea
                    className="form__value"
                    onChange={event => this._onFieldChange("thumbnail", event.target.value.trim())}
                    value={this.state.thumbnail}
                />
            </div>
        );
    }

    _fileUpload(event) {
        this.setState({ thumbnail: this.fileUpload.files[0].name, uploadedFile: true, addedImage: true });
    }
    _createStatusOptions() {
        let items = [];
        for (let item of SUBSCRIBABLE_STATUS) {
            items.push(
                <option key={item.value} value={item.value}>
                    {item.name}
                </option>
            );
        }
        return items;
    }
    _createFrequencyOptions() {
        let items = [];
        for (let item of SUBSCRIBABLE_FREQUENCY) {
            items.push(
                <option key={item.value} value={item.value}>
                    {item.name}
                </option>
            );
        }
        return items;
    }
    _createEcommLanguageOptions() {
        let items = [];
        const langs = {en: "English", fr: "French"};
        for (let item in langs) {
            items.push(
                <option key={item} value={item}>
                    {langs[item]}
                </option>
            );
        }
        return items;
    }

    _renderDetailsPanel() {
        const { subscribableid , whiteLabelURL, status, lang, multiLangSupport } = this.state;
        const shareableLink = subscribableid && whiteLabelURL ? `${whiteLabelURL}?ecommSub=${subscribableid}` : "";
        return (
            <form onSubmit={event => this._submit(event)}>
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label htmlFor="title" className="form__label">Title:&nbsp;
                            <Tooltip>The title of the email shown to the end user for their subscription management</Tooltip>
                        </label>
                        <input
                            id="title"
                            placeholder="Title"
                            type="text"
                            maxLength="160"
                            className="form__value"
                            onChange={event => this._onFieldChange("title", event.target.value)}
                            value={this.state.title}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label htmlFor="description" className="form__label">Description:&nbsp;
                            <Tooltip>The description of the email shown to the end user for their subscription management</Tooltip>
                        </label>
                        <textarea
                            id="description"
                            placeholder="Description"
                            maxLength="450"
                            className="form__value"
                            onChange={event => this._onFieldChange("description", event.target.value)}
                            style={{ minHeight: "50px" }}
                            value={this.state.description}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label htmlFor="imageUpload" className="form__label">Thumbnail:&nbsp;
                            <Tooltip>The thumbnail image of the email shown to the end user for their subscription management</Tooltip>
                        </label>
                        <input
                            id="imageUpload"
                            visibility="hidden"
                            style={{
                                display: "none"
                            }}
                            ref={input => {
                                this.fileUpload = input;
                            }}
                            type="file"
                            onChange={this._fileUpload}
                        />{" "}
                        {this.props.client && this.props.client.allowCustomEdit && (
                            <EditableInput
                                editing={this.state.uploadedFile ? false : null}
                                value={this.state.thumbnail}
                                input={this._getRenderedImageUpload()}
                                formatted={
                                    this.state.uploadedFile ? (
                                        this.state.thumbnail
                                    ) : (
                                        <Link href={this.state.thumbnail} target="_blank">
                                            {this.state.thumbnail}
                                        </Link>
                                    )
                                }
                            />
                        )}
                        {(!this.props.client || (this.props.client && !this.props.client.allowCustomEdit)) && (
                            <EditableInput
                                value={this.state.thumbnail}
                                onChange={event => this._onFieldChange("thumbnail", event.target.value)}
                                formatted={
                                    <Link href={this.state.thumbnail} target="_blank">
                                        {this.state.thumbnail}
                                    </Link>
                                }
                            />
                        )}
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__group">
                        {
                            multiLangSupport &&
                            <>
                                <label htmlFor="language" className="form__label">Language:&nbsp;
                                    <Tooltip>Allows author to specify the language for the subscription.</Tooltip>
                                </label>
                                <div className="select__wrapper">
                                    <select value={lang} id="language" onChange={event => this._onFieldChange("lang", event)}>
                                        {this._createEcommLanguageOptions()}
                                    </select>
                                </div>
                            </>
                        }
                        <br />
                        <label htmlFor="status" className="form__label">Status:&nbsp;
                            <Tooltip>The status must be set to "Active" in order to visible to the end user for subscription management.</Tooltip>
                        </label>
                        <div className="select__wrapper">
                            <select value={this.state.status} id="status" onChange={event => this._onFieldChange("status", event)}>
                                {this._createStatusOptions()}
                            </select>
                        </div>
                        <br />
                        <label htmlFor="frequency" className="form__label">Frequency:&nbsp;
                            <Tooltip>The frequency provides categorization to subscription types. <br/>
                            This provides filtering at the console level only.</Tooltip>
                        </label>
                        <div className="select__wrapper">
                            <select
                                id="frequency"
                                value={this.state.frequency}
                                onChange={event => this._onFieldChange("frequency", event)}
                            >
                                {this._createFrequencyOptions()}
                            </select>
                        </div>
                        </div>
                </div>
                { status == "active" && shareableLink != "" && whiteLabelURL && (
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label htmlFor="copyLink" className="form__label">Shareable link:&nbsp;
                            <Tooltip>
                                This shareable link will open the email subscription card within the app
                            </Tooltip>
                        </label>
                        <CopyLink textToCopy={shareableLink} />
                    </div>
                </div>)}
                <div className="form__row">
                    <div
                        className="form__group form__group--full"
                        style={{
                            marginTop: "10px",
                            flexDirection: "row"
                        }}
                    >
                        <Link onClick={this._closeModal} style={{ textDecoration: "underline" }}>
                            Cancel
                        </Link>
                        <Button
                            style={{ marginLeft: this.state.artid ? "10px" : "auto" }}
                            type="submit"
                            disabled={this.state.save_protect}
                            value={this.state.modal_button}
                        />
                    </div>
                </div>
            </form>
        );
    }
    render() {
        const { panel, open, modal_title } = this.state;
        return (
            <Modal show={open} onHide={this._closeModal} aria-labelledby="ModalHeader">
                <Modal.Header closeButton={true}>
                    <Modal.Title id="ModalHeader">{modal_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this._renderDetailsPanel()}</Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(AddECommunicationSubscribable);
