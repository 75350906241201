import React, { Component } from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import Button from "../atoms/Button";
import { createAlert } from "../../actions/utils";

const DragHandle = SortableHandle(() => <i className="fa fa-bars" />);

const SortableItem = SortableElement<dynamic>(({ itemIndex, value, _onNetworkFieldUpdate, _onNetworkDelete }) => {
    // using ${value.network}-${value.label} as the key means this breaks when the label changes
    // however, changing the network here never seems to break anything
    // react is basically sorcery
    return (
        <tr key={`${value.network}`}>
            <td>
                <DragHandle />
            </td>
            <td>
                <input
                    type="text"
                    value={value.network}
                    placeholder="Please enter a network name"
                    onChange={event => _onNetworkFieldUpdate(itemIndex, ["network"], [event.target.value])}
                />
            </td>
            <td>
                <input
                    type="text"
                    value={value.label}
                    placeholder="Please enter a network label"
                    onChange={event => _onNetworkFieldUpdate(itemIndex, ["label"], [event.target.value])}
                />
            </td>
            <td>
                <Button
                    className="btn--sm"
                    onClick={() => {
                        _onNetworkDelete(itemIndex, "REMOVE");
                    }}
                >
                    <i className="fa fa-minus" />
                </Button>
            </td>
        </tr>
    );
});

const SortableList = SortableContainer<dynamic & {items: Mongo.IExtraNetwork[]}>(({ items, _onNetworkFieldUpdate, _onNetworkDelete }) => {
    return (
        <tbody>
            {items.map((value, index) => {
                return (
                    <SortableItem
                        key={value.network}
                        index={index}
                        itemIndex={index}
                        value={value}
                        _onNetworkFieldUpdate={_onNetworkFieldUpdate}
                        _onNetworkDelete={_onNetworkDelete}
                    />
                );
            })}
            {!!items.length && <tr>
                <td colSpan={4}>
                    <hr style={{ borderTop: "none"}}/>
                </td>
            </tr>}
            <tr>
                <td />
                <td>
                    <input id="networkInput" type="text" placeholder="Please enter a network name" />
                </td>
                <td>
                    <input id="labelInput" type="text" placeholder="Please enter a network label" />
                </td>
                <td>
                    <Button
                        className="btn--sm"
                        onClick={() => {
                            let index = items.length;
                            _onNetworkFieldUpdate(
                                index,
                                ["network", "label"],
                                [(document.getElementById("networkInput") as HTMLInputElement).value, (document.getElementById("labelInput") as HTMLInputElement).value]
                            );
                            (document.getElementById("networkInput") as HTMLInputElement).value = "";
                            (document.getElementById("labelInput") as HTMLInputElement).value = "";
                        }}
                    >
                        Add
                        <i className="fa fa-plus" style={{ marginLeft: "2px" }} />
                    </Button>
                </td>
            </tr>
        </tbody>
    );
});

interface IExtraNetworkListProps {
    items: Mongo.IExtraNetwork[];
    _update: Function;
}

class ExtraNetworkList extends Component<IExtraNetworkListProps> {
    _onNetworkFieldUpdate(index: number, fieldNames: string[], values: string[]) {
        let extraNetworks = this.props.items.slice(0);
        if (!fieldNames.length || fieldNames.length !== values.length) {
            return;
        }
        if (values.length === 2 && extraNetworks.find(network => network.network === values[0] && network.label === values[1])) {
            createAlert("Network has already been added", "error");
            return;
        }
        for (let i = 0; i < fieldNames.length; i++) {
            const fieldName = fieldNames[i];
            const value = values[i];

            if (!value || value.trim().length == 0) {
                return;
            }
            if (fieldName == "label" && value.length > 25) {
                createAlert("Label exceeds maximum limit of 25 characters", "error");
                return;
            }
            if (!extraNetworks[index]) {
                extraNetworks[index] = {
                    network: "",
                    label: "",
                    order: index
                };
            }
            extraNetworks[index][fieldName] = value;
        }
        this.props._update("extraNetworks", extraNetworks)
    }

    _onNetworkOrderUpdate(oldPos: number, newPos: number) {
        let extraNetworks = this.props.items.slice(0);
        if (oldPos != newPos) {
            extraNetworks[oldPos].order = newPos;
            if (oldPos > newPos) {
                for (let i = newPos; i < oldPos; i++) {
                    extraNetworks[i].order = i + 1;
                }
            } else if (oldPos < newPos) {
                for (let i = newPos; i > oldPos; i--) {
                    extraNetworks[i].order = i - 1;
                }
            }
        }
        extraNetworks.sort((a, b) => a.order > b.order ? 1 : b.order > a.order ? -1 : 0);
        this.props._update("extraNetworks", extraNetworks);
    }

    _onNetworkDelete(index: number) {
        let extraNetworks = this.props.items.slice(0);
        extraNetworks.splice(index, 1);
        this.props._update("extraNetworks", extraNetworks);
    }

    render() {
        return (
            <SortableList
                items={this.props.items}
                onSortEnd={({ oldIndex, newIndex }) => this._onNetworkOrderUpdate(oldIndex, newIndex)}
                _onNetworkFieldUpdate={this._onNetworkFieldUpdate.bind(this)}
                _onNetworkDelete={this._onNetworkDelete.bind(this)}
                axis="y"
                useDragHandle={true}
            />
        );
    }
}
export default ExtraNetworkList;
