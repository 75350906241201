import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import Button from "../../atoms/Button";
import { createAlert } from "../../../actions";

export default class RoleSelectionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            rid: ""
        };
    }

    selectRole() {
        if (!this.state.rid) {
            alert("Please select a role");
            return;
        }
        if (this.props.selectRole) {
            this.props.selectRole(this.state.rid);
        }
        this.closeModal();
    }

    closeModal() {
        if (this.props.closeModal) {
            this.props.closeModal();
        }
        this.setState({ open: false });
        createAlert('The modal for role selection has been successfully closed.', 'success');
    }
    render() {
        const { open } = this.state;
        return (
            <Modal show={open} onHide={() => this.closeModal()}>
                <Modal.Body>
                    <p>You have chosen to reassign {this.props.userCount} users to a new Role.</p>
                    <p>Please select the role you wish to move users into</p>
                    <br />
                    <p>Select Role...</p>
                    <div className="select__wrapper">
                        <select onChange={e => this.setState({ rid: e.target.value })}>
                            <option value="">Select Role</option>
                            {this.props.roles &&
                                Object.keys(this.props.roles).length &&
                                Object.keys(this.props.roles).map(rid => (
                                    <option value={rid}>{this.props.roles[rid].name}</option>
                                ))}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: "flex" }}>
                    <Button onClick={e => this.closeModal()}>Cancel</Button>
                    <Button style={{ marginLeft: "auto" }} onClick={e => this.selectRole()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
