import React, { Component } from "react";

import Modal from "./Modal";
import ArticleCard from "../ArticleCard";
import Spinner from "../../atoms/Spinner";
import Link from "../../atoms/Link";
import Button from "../../atoms/Button";

interface IStreamPreviewProps {
    addFeed?: () => void;
    getArticles: (startDate?: Date) => Promise<ServerTypes.Console.IArticlesPreview>;
    onClose: () => void;
    sourceUrl?: string;
    open: boolean;
    client: Mongo.client;
    stream: ServerTypes.Console.IStream;
    sectionView?: boolean;
}
interface IStreamPreviewState {
    open: boolean;
    articles: ServerTypes.Console.IArticlesPreview;
    loading: boolean;
    startDate: string;
    dateFilters: Array<{ value: string; label: string }>;
    sectionView?: boolean;
    showNoArticleMsg?: boolean;
}
export default class StreamPreview extends Component<IStreamPreviewProps, IStreamPreviewState> {
    constructor(props: IStreamPreviewProps) {
        super(props);
        const dateFilters = this.getDateFilters();
        this.state = {
            open: props.open || false,
            articles: [],
            loading: false,
            startDate: dateFilters[2].value,
            dateFilters,
            showNoArticleMsg: false
        };
    }

    async componentDidMount() {
        this.getArticles();
    }
    async getArticles() {
        const { startDate, open } = this.state;
        if (!open) {
            return;
        }
        this.setState({ loading: true });
        const articles = await this.props.getArticles(new Date(startDate));
        this.setState({ articles, loading: false, showNoArticleMsg: true });
    }

    componentWillReceiveProps(newProps: IStreamPreviewProps) {
        if (newProps.open !== this.props.open) {
            this.setState({ open: newProps.open! }, () => this.getArticles());
        }
    }

    close() {
        this.setState({ open: false }, () => this.props.onClose());
    }

    async addFeed() {
        if (this.props.addFeed) {
            this.props.addFeed();
        }
        await this.close();
    }

    isLibraryResponse() {
        const { articles } = this.state;
        return !Array.isArray(articles) && typeof articles === 'object' && !articles.error;
    }

    renderLibraryViewSections(stream: ServerTypes.Console.IStream) {
        const { articles } = this.state;
        const { client } = this.props;
        if (
            stream.active &&
            ((stream.contentSearch && stream.isCustom) ||
                !stream.contentSearch)
        ) {
            if (this.isLibraryResponse() && articles[stream.sid]) {
                return (
                    <div>
                        <div style={{ backgroundColor: "white", padding: "10px 30px", marginTop: "20px" }}>
                            <h4>{stream.title}</h4>
                            <div className="articleList">
                                {articles[stream.sid]!.map(article => (
                                    <ArticleCard
                                        key={article.Link || article.Title}
                                        article={article}
                                        stream={stream}
                                        client={client}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return "";
        }
        return "";
    }

    renderLibraryView() {
        const { articles } = this.state;
        const { client, stream } = this.props;
        if (this.isLibraryResponse() && articles["All"]?.length) {
            return (
                <div className="articleList bgcGrey" style={{ padding: "10px 30px" }}>
                    {articles["All"].map(article => (
                        <ArticleCard
                            key={article.Link || article.Title}
                            article={article}
                            stream={stream[article.sid]}
                            client={client}
                        />
                    ))}
                </div>
            );
        } else {
            return "";
        }
    }

    renderContent() {
        const { client, stream, sourceUrl } = this.props;
        const { articles, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        if (stream.type == "FEED" && !sourceUrl && Array.isArray(articles) && !articles.length) {
            return (
                <div className="alert alert-danger" style={{ display: "block" }}>
                    <i className="fa fa-exclamation-circle" /> Invalid feed
                </div>
            );
        }
        if (Array.isArray(articles) && !articles.length) {
            return (
                <div className="alert alert-warning" style={{ display: "block" }}>
                    Could not find any articles
                </div>
            );
        }
        if (!Array.isArray(articles) && articles.error) {
            return (
                <div className="alert alert-danger" style={{ display: "block" }}>
                    <i className="fa fa-exclamation-circle" /> {articles.error}
                </div>
            );
        }
        return (
            <div className="articleList">
                {Array.isArray(articles) && articles.map(article => (
                    <ArticleCard
                        key={article.Link || article.Title!}
                        article={article}
                        stream={stream}
                        client={client}
                    />
                ))}
            </div>
        );
    }

    renderFooter() {
        const { articles, loading } = this.state;
        if (loading || (Array.isArray(articles) && !articles.length)) {
            return <div />;
        }
        return <Button onClick={() => this.addFeed()}>Add Feed</Button>;
    }

    getDateFilters() {
        const date = new Date();
        return [
            { value: `${new Date(new Date().setDate(date.getDate() - 1)).toISOString()}`, label: "1 day" },
            { value: `${new Date(new Date().setDate(date.getDate() - 3)).toISOString()}`, label: "3 days" },
            { value: `${new Date(new Date().setDate(date.getDate() - 7)).toISOString()}`, label: "last week" },
            { value: `${new Date(new Date().setDate(date.getDate() - 14)).toISOString()}`, label: "last 2 week" },
            { value: `${new Date(new Date().setDate(date.getDate() - 30)).toISOString()}`, label: "last month" },
            { value: `${new Date(new Date().setDate(date.getDate() - 60)).toISOString()}`, label: "last 2 months" },
            { value: `${new Date(new Date().setDate(date.getDate() - 90)).toISOString()}`, label: "last 3 months" }
        ];
    }
    render() {
        const { sourceUrl, stream, sectionView } = this.props;
        const { open, startDate, dateFilters, loading, articles, showNoArticleMsg } = this.state;
        const footer = { ...(this.props.addFeed ? { footer: this.renderFooter() } : null) };
        return (
            <Modal
                className="articlePreview"
                open={open}
                closeAction={() => this.close()}
                title={
                    <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <h4>{sectionView == undefined ? "Articles Preview" : "Library Preview"}</h4>
                        {sourceUrl ? (
                            <Link to={sourceUrl}> - {sourceUrl}</Link>
                        ) : sectionView != true ? (
                            <div className="select__wrapper" style={{ marginLeft: "20px" }}>
                                <select
                                    onChange={event =>
                                        this.setState({ startDate: event.target.value }, () => this.getArticles())
                                    }
                                    value={startDate}
                                >
                                    {dateFilters.map(filter => (
                                        <option key={filter.label} value={filter.value}>
                                            {filter.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : null}
                    </span>
                }
                {...footer}
            >
                {sectionView != undefined ? <h4>{"Note: this preview does not display Professional or Personal streams"}</h4> : null}
                {sectionView != undefined && loading ? <Spinner /> : null}
                {sectionView && !loading && Object.keys(articles).length > 0 && (
                    <div className="bgcGrey" style={{ padding: "10px 30px" }}>
                        {Object.keys(stream).length > 0 &&
                            Object.keys(stream)
                                .sort((a, b) => stream[a].streamOrder - stream[b].streamOrder)
                                .map(s => (
                                    this.renderLibraryViewSections(stream[s])
                                ))}
                    </div>
                )}
                {!sectionView && !loading && (this.renderLibraryView())}
                {sectionView != undefined && !loading && showNoArticleMsg && Object.keys(articles).length == 0 && <div className="alert alert-warning" style={{ display: "block" }}>
                    {"Could not find any articles"}
                </div>}
                {sectionView == undefined && (this.renderContent())}
            </Modal>
        );
    }
}
