import React, { useState, useEffect } from "react";
import { getAdminDate } from "../../../../utils/Helpers";
import { Wrapper, Row } from "../../../atoms/Layout";
import { IReportFilter } from "../Index";
import ElementListBox, { Filter, PaginationData } from "../../../molecules/ElementListBox";
import { SSOLogActions } from "../../../../actions/SSOLogActions";

export interface ISSOLogFilter {
    startDate: Date;
    endDate: Date;
    cid: string | null;
    nameId?: string;
    sort?: string;
    sortDirection?: number;
    skip?: number;
    limit?: number;
}


interface ISSOLogsProps {
    filters: IReportFilter;
    client?: Mongo.client;
    user: Mongo.clientAdmin;
    onLoaded: () => void;
    showSearchResults: boolean;
}

export const SSOLogsSection = (props: ISSOLogsProps) => {
    const { user } = props;
    const [filters, setFilters] = useState<IReportFilter>(props.filters);
    const [refresh, setRefresh] = useState(false);
    const showLogs = user.isSuper || user.isSuperClientAdmin;
    const [logsLoaded, setLogsLoaded] = useState(!showLogs);
    const logsLimit = 20;

    useEffect(() => {
        if (props.showSearchResults && showLogs) {
            setRefresh(true);
            setFilters(props.filters);
        }
    }, [props.filters, props.showSearchResults])


    // if state changes are done loading, let the index page know we've loaded them and allow changing of filters
    useEffect(() => {
        if (logsLoaded) {
            props.onLoaded();
        }
    }, [logsLoaded]);

    return <Wrapper id="ssoLogs">
        {showLogs ? (<>
        {props.showSearchResults ? <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <ElementListBox<ServerTypes.Console.ISSOLog>
                    title="SSO Logs"
                    filterSearch={false}
                    filterCid={false}
                    forceRefresh={refresh}
                    resetRefresh={() => setRefresh(false)}
                    sortableKeys={["CID", "SSO ID", "Date/Time"]}
                    defaultSort={{"date": -1}}
                    listCount={logsLimit}
                    refresh={async (_filters: Filter, pagination: PaginationData) => {
                        setLogsLoaded(false);
                        const data = await SSOLogActions.doList({
                            ...filters,
                            ...(pagination.limit ? { limit: pagination.limit } : {}),
                            ...(pagination.skip ? { skip: pagination.skip } : {}),
                            ...(Object.keys(pagination.sort || {}).length ? { sort: Object.keys(pagination.sort || {})[0], sortDirection: Object.values(pagination.sort || {})[0] } : {})
                        });
                        setLogsLoaded(true);
                        return data;
                    }}
                    mutateItem={item => ({
                        clientCode: item.cid ? item.cid : "Unknown",
                        nameId: item.nameId,
                        date: getAdminDate(item.date),
                        issuer: item.issuer || "Unknown",
                        log: item.log
                    })}
                    keys={{
                        ...(user.isSuper ? { clientCode: "CID" } : null),
                        nameId: "SSO ID",
                        date: "Date/Time",
                        log: "Log",
                        issuer: "Issuer"
                    }}
                />
            </Row> : <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5 style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center"
                        }}>
                            SSO Logs
                        </h5>
                    </div>
                    <div className="ibox-content" style={{
                        minHeight: "300px"
                    }}>
                        <div>
                            <i>To run this report, please adjust the filters above and click "Search"</i>
                        </div>

                    </div>

                </div>
            </Row>}
        </>)
         : <Row>
             <div className="ibox float-e-margins">
                 <div className="ibox-title">
                     <h5>No Data</h5>
                 </div>
                 <div className="ibox-content">
                     <i>Could not find any SSO logs</i>
                 </div>
             </div>
         </Row>
        }
    </Wrapper>


}