import React, { Component } from "react";
import ModalStack from "../ModalStack";
import AddArticleOrMediaModal from "../AddArticleOrMediaModal";
import FindArticleModal from "../FindArticleModal";
import { history } from "../../../../utils/store";

interface AddArticleStackProps {
    client: Mongo.IClient;
    stream: Mongo.IStream;
    open: boolean;
    onClose: () => void;
}
interface AddArticleStackState {
    article: Partial<ServerTypes.Console.IArticle> | null;
    open?: number;
}

export default class AddArticleStack extends Component<AddArticleStackProps, AddArticleStackState> {
    constructor(props: AddArticleStackProps) {
        super(props);
        this.state = { article: null, open: undefined };
    }

    componentDidUpdate(oldProps: AddArticleStackProps) {
        if (oldProps.open !== this.props.open && this.props.open) {
            this.setState({ open: 0 });
        }
    }

    loadArticle(article: Partial<ServerTypes.Console.IArticle>) {
        this.setState({ article, open: 1 });
    }

    close() {
        this.setState({ open: undefined }, this.props.onClose ? () => this.props.onClose!() : undefined);
    }

    render() {
        const { client, stream } = this.props;
        return (
            client.allowCustomEdit ? (
                <ModalStack
                    modals={[
                        {
                            title: "Create Content",
                            render: (
                                <AddArticleOrMediaModal
                                    cid={client.cid}
                                    submit={() => {
                                        this.setState({ open: 1 });
                                    }}
                                />
                            ),
                            showButtons: false
                        },
                        {
                            title: "Create Content",
                            render: (
                                <FindArticleModal
                                    client={client}
                                    allowCustomEdit={client.allowCustomEdit}
                                    stream={stream}
                                    submit={article => {
                                        history.push({
                                            pathname: `/content/articles/c/${client.cid}`,
                                            state: { ...article  }
                                        });
                                    }}
                                />
                            ),
                            showButtons: false
                        }
                    ]}
                    open={this.state.open}
                    onClose={() => this.close()}
                />
            ):
            (
                <ModalStack
                    modals={[
                        {
                            title: "Create Content",
                            render: (
                                <FindArticleModal
                                    client={client}
                                    allowCustomEdit={client.allowCustomEdit}
                                    stream={stream}
                                    submit={article => {
                                        history.push({
                                            pathname: `/content/articles/c/${client.cid}`,
                                            state: { ...article  }
                                        });
                                    }}
                                />
                            ),
                            showButtons: false
                        }
                    ]}
                    open={this.state.open}
                    onClose={() => this.close()}
                />
            )
        );
    }
}
