import React, { Component } from "react";
import Spinner from "../atoms/Spinner";

import { getChartColours } from "../../utils/Helpers";
import { Doughnut, Line, Bar, HorizontalBar } from "react-chartjs-2";
import DownloadButton from "../atoms/DownloadButton";
import Tooltip from "../atoms/Tooltip";
import { isNumber } from "lodash";

export default class Chart extends Component<
    {
        /** title of the chart */
        title: string;
        /** whether to render a spinner instead of the chart */
        loading: boolean;
        /** the data. acceptable formats include:
         * - a list of numbers (single dataset)
         * - a list of lists of numbers (stacked datasets)
         * - a list of objects*/
        data: dynamic;
        /** type of chart */
        type: "bar" | "pie" | "horizontal-bar" | "line";
        /** the labels */
        labels: Array<string | string[]>;
        /** providing this adds a download button that will call the provided function */
        downloadAction?: Function;
        /** text to display on the download button. defaults to "Download" */
        downloadText?: string;
        /** tooltip to be rendered on top right */
        tooltip?: JSX.Element;
        /** height of the chart. 400 by default */
        height?: number;
        /** custom tooltip behaviour. see https://www.chartjs.org/docs/3.4.0/configuration/tooltip.html */
        customChartTooltips?: dynamic;
        /** maximum value to be displayed on axis */
        max?: number;
        /** step size on axis */
        stepSize?: number;
        /** whether to display the legend for a (horizontal) bar chart. other charts will display the legend by default */
        displayBarLegend?: boolean;
        /** whether to display the results or a message instructing admins how to use the reports. NOTE: undefined is treated as true */
        showSearchResults?: boolean;
    },
    {
        title: string;
        loading: boolean;
        data: dynamic;
        description: string;
        type: "bar" | "pie" | "horizontal-bar" | "line";
        labels: Array<string | string[]>;
        downloadAction: Function;
        tooltip: JSX.Element;
        height: number;
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title || "",
            loading: props.loading || true,
            data: props.data,
            type: props.type || "bar",
            description: props.description || "",
            labels: props.labels,
            downloadAction: props.downloadAction,
            tooltip: props.tooltip || "",
            height: props.height || 400
        };
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            ...(newProps.title !== this.state.title ? { title: newProps.title } : null),
            ...(newProps.loading !== this.state.loading ? { loading: newProps.loading } : null),
            ...(newProps.data !== this.state.data ? { data: newProps.data } : null),
            ...(newProps.type !== this.state.type ? { type: newProps.type } : null),
            ...(newProps.labels !== this.state.labels ? { labels: newProps.labels } : null),
            ...(newProps.description !== this.state.description ? { description: newProps.description } : null),
            ...(newProps.downloadAction !== this.state.downloadAction
                ? { downloadAction: newProps.downloadAction }
                : null),
            ...(newProps.tooltip !== this.state.tooltip ? { tooltip: newProps.tooltip } : null),
            ...(newProps.height && newProps.height !== this.state.height ? { height: newProps.height } : null)
        } as object);
    }
    render() {
        const showDownload = this.state.downloadAction && this.props.showSearchResults !== false && this.hasData(this.state.data);
        return (
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5
                        style={
                            this.state.downloadAction || this.state.tooltip
                                ? {
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    float: "none"
                                }
                                : {}
                        }
                        tabIndex={0}
                    >
                        {this.state.title}{" "}
                    </h5>
                    {showDownload && (
                        <DownloadButton
                            style={{
                                marginLeft: "auto",
                                marginBottom: "0",
                                width: "auto",
                                textAlign: "center"
                            }}
                            className="btn--sm"
                            onClick={() => this.state.downloadAction()}
                        >
                            <i className="fa fa-download" /> {this.props.downloadText || "Download"}
                        </DownloadButton>
                    )}
                    {this.state.tooltip && (
                        <div style={showDownload ? { marginLeft: "10px" } : { marginLeft: "auto" }}>
                            <Tooltip place="left">{this.state.tooltip}</Tooltip>
                        </div>
                    )}
                </div>
                <div className="ibox-content">
                    <div
                        style={{
                            minHeight: this.state.height
                        }}
                    >
                        {this._renderData()}
                    </div>
                </div>
            </div>
        );
    }

    hasData(data) {
        if (!data || !Array.isArray(data) || !data.length) {
            return false;
        }
        if (
            Array.isArray(data) &&
            // filter items to get array of items that do have data, if there are none, we got none and should return false
            data.filter(
                item =>
                    // we have a multi dataset graph, so each element is an object in itself with a dataset
                    (item && typeof item == "object" && "data" in item && this.hasData(item.data)) ||
                    (item && Array.isArray(item) && this.hasData(item)) ||
                    // single dataset graph
                    (isNumber(item) && item > 0)
            ).length == 0
        ) {
            return false;
        }
        return true;
    }
    _renderData() {
        if (this.props.showSearchResults === false) {
            return this._renderInstructions();
        }
        if (this.state.loading) {
            return this._renderLoading();
        }
        if (!this.hasData(this.state.data)) {
            return this._renderEmpty();
        }
        switch (this.state.type) {
            case "horizontal-bar":
                return this._renderHorizontalBar();
            case "pie":
                return this._renderPie();
            case "line":
                return this._renderLine();
            default:
            case "bar":
                return this._renderBar();
        }
    }
    _renderLoading() {
        return (
            <div className="ibox-content" style={{ minHeight: this.state.height }}>
                <Spinner />
            </div>
        );
    }
    _renderEmpty() {
        return <i>Could not find any data for this report</i>;
    }
    _renderInstructions() {
        return <i>To run this report, please adjust the filters above and click "Search"</i>;
    }
    _renderBar() {
        const colours = getChartColours();
        const opacity = 0.5;
        // possible formats:
        // - list of numbers (normal bar graph)
        // - list of list of numbers (stacked bar graph)
        // - list of objects (bar graph with multiple bars per label)
        const datasets = this.state.data[0] && typeof (this.state.data[0]) === "object" ? this.state.data : [this.state.data];
        const data = {
            labels: this.state.labels,
            datasets: datasets.map((dataset, i) => ({
                label: dataset.label || `${i}`, // stacked graphs break without unique labels
                type: dataset.type || "bar",
                fill: false,
                data: Array.isArray(dataset) ? dataset : dataset.data,
                ...(Array.isArray(dataset) || !!dataset.stacked ? { stack: "stack" } : {}),
                ...(Array.isArray(dataset) ?
                    getChartColours(false, false, true, opacity + 0.2 * i) : // this doesn't really work at >3 stacked datasets but what the hell are you even doing at that point
                    colours[i]
                ),
                //...(dataset.type && dataset.type !== "bar" ? this._getOptions(dataset.type) : {})
            }))
        };
        return <Bar data={data} options={this._getOptions()} />;
    }
    _renderHorizontalBar() {
        const colours = getChartColours();
        const opacity = 0.5;
        // possible formats:
        // - list of numbers (normal bar graph)
        // - list of list of numbers (stacked bar graph)
        // - list of objects (bar graph with multiple bars per label)
        const datasets = this.state.data[0] && typeof (this.state.data[0]) === "object" ? this.state.data : [this.state.data];
        const data = {
            labels: this.state.labels,
            datasets: datasets.map((dataset, i) => ({
                label: dataset.label || `${i}`, // stacked graphs break without unique labels
                data: Array.isArray(dataset) ? dataset : dataset.data,
                ...(Array.isArray(dataset) ? { stack: "stack" } : {}),
                ...(Array.isArray(dataset) ?
                    getChartColours(false, false, true, opacity + 0.2 * i) : // this doesn't really work at >3 stacked datasets but what the hell are you even doing at that point
                    colours[i]
                )
            }))
        };
        return <HorizontalBar data={data} options={this._getOptions()} />;
    }
    _renderLine() {
        const colours = getChartColours(false, false, false, 0.03);
        const data = {
            labels: this.state.labels,
            datasets: this.state.data.map((data, index) => {
                return { ...data, ...colours[index] };
            })
        };
        return <Line data={data} options={this._getOptions()} />;
    }
    _renderPie() {
        const sum = (this.state.data as number[]).reduce((a, v) => a + v, 0);
        if (sum) {
            const data = {
                labels: this.state.labels,
                datasets: [
                    {
                        data: this.state.data,
                        ...getChartColours(false, false, true)
                    }
                ]
            };

            return <Doughnut data={data} options={this._getOptions()} />;
        } else {
            return this._renderEmpty();
        }
    }

    _getOptions(type?: string) {
        const { max, stepSize, displayBarLegend } = this.props;
        switch (type || this.state.type) {
            case "line":
                return {
                    maintainAspectRatio: false,
                    responsive: true,
                    height: this.state.height,
                    legend: {
                        position: "bottom"
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false,
                        callbacks: {
                            label: (t, d) => {
                                return t.yLabel.toLocaleString() + " " + d.datasets[t.datasetIndex].label.toString();
                            }
                        }
                    },
                    elements: {
                        line: {
                            fill: false
                        }
                    }
                    // animation: {
                    //     duration: 1,
                    //     onProgress: animation => {
                    //         const ctx = animation.chart.ctx;
                    //         ctx.textAlign = "center";
                    //         ctx.textBaseline = "bottom";
                    //         ctx.fillStyle = "#000000";
                    //         animation.chart.data.datasets.forEach(dataset => {
                    //             if (!dataset._meta[Object.keys(dataset._meta)[0]].hidden) {
                    //                 dataset._meta[Object.keys(dataset._meta)[0]].data.forEach(point => {
                    //                     const x = point._model.x;
                    //                     const y = point._model.y - 5;
                    //                     const tooltipX = animation.chart.tooltip._model.x;
                    //                     const tooltipY = animation.chart.tooltip._model.y;
                    //                     if (
                    //                         animation.chart.tooltip._model.opacity < 1 ||
                    //                         x < tooltipX ||
                    //                         x > tooltipX + animation.chart.tooltip._model.width ||
                    //                         y < tooltipY ||
                    //                         y > tooltipY + animation.chart.tooltip._model.height + 5
                    //                     ) {
                    //                         ctx.fillText(dataset.data[point._index].toLocaleString(), x, y);
                    //                     }
                    //                 });
                    //             }
                    //         });
                    //     }
                    // }
                };
            case "horizontal-bar":
                return {
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        display: !!displayBarLegend,
                        position: "bottom"
                    },
                    tooltips: {
                        xAlign: "right",
                        callbacks: {
                            title: function (tooltipItem) {
                                const title = tooltipItem[0].yLabel;
                                return title.constructor === Array ? title.join(' ') : title;
                            },
                            label: (tooltipItem, data) => {
                                const tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return tooltipValue?.toLocaleString();
                            }
                        },
                        ...(this.props.customChartTooltips || {})
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                position: "bottom",
                                ticks: {
                                    beginAtZero: true,
                                    fontColor: "#666",
                                    fontFamily: ["Lato", "arial", "sans-serif"],
                                    ...(max ? { max } : {}),
                                    ...(stepSize ? { stepSize } : {})
                                },
                                gridLines: {
                                    drawBorder: false,
                                    drawOnChartArea: true,
                                    drawTicks: false
                                }
                            }
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    fontColor: "#666",
                                    fontFamily: ["Lato", "arial", "sans-serif"]
                                },
                                gridLines: {
                                    drawBorder: true,
                                    drawOnChartArea: false,
                                    drawTicks: true
                                }
                            }
                        ]
                    },
                    layout: {
                        padding: {
                            right: 20
                        }
                    }
                };
            case "bar":
                return {
                    maintainAspectRatio: false,
                    scaleShowValues: true,
                    legend: {
                        display: !!displayBarLegend,
                        position: "bottom"
                    },
                    tooltips: {
                        callbacks: {
                            title: function (tooltipItem) {
                                const title = tooltipItem[0].xLabel;
                                return title.constructor === Array ? title.join(' ') : title;
                            },
                            label(tooltipItem, data) {
                                const tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return tooltipValue?.toLocaleString();
                            }
                        },
                        ...(this.props.customChartTooltips || {})
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    fontColor: "#666",
                                    fontFamily: ["Lato", "arial", "sans-serif"],
                                    ...(max ? { max } : {}),
                                    ...(stepSize ? { stepSize } : {})
                                },
                                gridLines: {
                                    drawBorder: false,
                                    drawOnChartArea: true,
                                    drawTicks: false,
                                }
                            }
                        ],
                        xAxes: [
                            {
                                ticks: {
                                    fontColor: "#666",
                                    fontFamily: ["Lato", "arial", "sans-serif"],
                                    autoSkip: false,
                                    maxRotation: 90
                                },
                                gridLines: {
                                    drawBorder: true,
                                    drawOnChartArea: false,
                                    drawTicks: true
                                },
                                afterCalculateTickRotation: axis => {
                                    // setting minRotation to 45 would force everything to rotate
                                    // doing it this way ensures the minimum rotation is only 45
                                    // if the labels need to be rotated in the first place
                                    if (axis.labelRotation > 0 && axis.labelRotation < 45) {
                                        axis.labelRotation = 45;
                                    }
                                }
                            }
                        ]
                    },
                    plugins: {
                        labels: {
                            render: () => {
                                return "";
                            }
                        }
                    },
                    layout: {
                        padding: {
                            top: 20,
                            right: 20
                        }
                    }
                };
            case "pie":
            default:
                return {
                    maintainAspectRatio: false,
                    responsive: true,
                    height: this.state.height,
                    legend: {
                        position: "bottom"
                    },
                    tooltips: {
                        bodyFontStyle: "bold",
                        footerFontStyle: "normal",
                        callbacks: {
                            title: function (tooltipItem) {
                                const title = tooltipItem[0].xLabel;
                                return title.constructor === Array ? title.join(' ') : title;
                            },
                            label(tooltipItem, data) {
                                return data.labels[tooltipItem.index];
                            },
                            footer(tooltipItems, data) {
                                const tooltipItem = tooltipItems[0];
                                const total = Object.values(data.datasets[tooltipItem.datasetIndex].data).reduce(
                                    (a, b) => (a as number) + (b as number)
                                ) as number;
                                const tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return [`${((tooltipValue / total) * 100).toFixed(1)}%`, tooltipValue.toLocaleString()];
                            }
                        },
                        ...(this.props.customChartTooltips || {})
                    }
                };
        }
    }
}
