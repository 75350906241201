import { ReactNode } from "react";

interface IConditionalWrapperProps {
    condition: boolean;
    wrapper: (children:ReactNode) => any;
    children: ReactNode;
}

export const ConditionalWrapper = (props: IConditionalWrapperProps) =>
    props.condition ? props.wrapper(props.children) : props.children;

export default ConditionalWrapper;
