import React, { Component } from "react";
import appConfig from "../../../../../config/config.dev";
import { createAlert } from "../../../../actions/utils";
import { apiRequest, getLocaleDate, downloadFile as fileDownload, upperCaseFirstLetter, hasEmvPermission } from "../../../../utils/Helpers";
import { connect } from "react-redux";
import Chart from "../../../molecules/Chart";
import { Wrapper, Row } from "../../../atoms/Layout";
import * as json2csv from "json2csv";
import { IReportFilter } from "../Index";

interface IInsightsProps {
    filters: IReportFilter;
    client?: Mongo.client;
    user: Mongo.clientAdmin;
    onLoaded: () => void;
    showSearchResults: boolean;
}

interface IInsightsState {
    topTerms: "loading" | Array<{
        Term: string;
        Shares: number;
        Clicks: number;
        "Social Interactions": number;
        "Engagement Ratio": number;
    }>;
    topDomains: "loading" | Array<{
        Domain: string;
        Shares: number;
        Clicks: number;
        "Social Interactions": number;
        "Engagement Ratio": number;
    }>;
    sharesByContentType: "loading" | dynamic<{
        shares: number;
        clicks: number;
        likes: number;
        comments: number;
    }>;
    earnedMediaValue: "loading" | dynamic<{
        LIClicks: number;
        FBClicks: number;
        TWClicks: number;
        LILikes: number;
        FBLikes: number;
        TWLikes: number;
        IGLikes: number;
        LIComments: number;
        FBComments: number;
        TWComments: number;
        IGComments: number;
    }>;
    filters?: dynamic;
}

class InsightsView extends Component<IInsightsProps, IInsightsState> {
    constructor(props: IInsightsProps) {
        super(props);

        this.state = {
            topDomains: "loading",
            topTerms: "loading",
            sharesByContentType: "loading",
            earnedMediaValue: "loading"
        };
    }

    componentDidMount() {
        if (!this.props.filters || !this.props.showSearchResults) return;
        if (
            this.state.topDomains == "loading" ||
            this.state.topTerms == "loading"
        ) {
            this._fetchAll(this.props);
        }
    }

    componentDidUpdate(prevProps: IInsightsProps) {
        if ((prevProps.filters != this.props.filters) || (this.props.showSearchResults && !prevProps.showSearchResults)) {
            this._fetchAll(this.props);
        }
    }

    _fetchAll(props: IInsightsProps) {
        const doContentByShareType = this._sharesByContentTypePermission();
        this.setState(
            {
                topDomains: "loading",
                topTerms: "loading",
                sharesByContentType: "loading",
                earnedMediaValue: "loading",
                filters: props.filters
            },
            () => {
                const defaults = {
                    topDomains: [],
                    topTerms: [],
                    sharesByContentType: {},
                    earnedMediaValue: {}
                };
                Promise.all([
                    apiRequest(`${appConfig.API_URL}/reports/insights/summary`, "Post", props.filters),
                    ...(doContentByShareType ? [apiRequest(`${appConfig.API_URL}/reports/insights/sharesByContentType`, "Post", props.filters)] : []),
                    ...(hasEmvPermission(props.user) ? [apiRequest(`${appConfig.API_URL}/reports/insights/earnedMediaValue`, "Post", props.filters)] : [])
                ])
                    .then(results => {
                        const [summary, contentData, emvData] = results;
                        if (summary?.error && summary?.valid === false) {
                            throw summary.error
                        }
                        if (contentData?.error && contentData?.valid === false) {
                            throw contentData.error
                        }

                        this.setState({
                            topDomains: summary.topDomains || defaults.topDomains,
                            topTerms: summary.topTerms || defaults.topTerms,
                            sharesByContentType: contentData || defaults.sharesByContentType,
                            earnedMediaValue: emvData || defaults.earnedMediaValue
                        });
                    })
                    .catch(error => {
                        this.setState(defaults);
                        createAlert(error, `error`);
                    })
                    .finally(props.onLoaded);
            }
        );
    }

    _fetchData(type: string, props: IInsightsProps, format = "json") {
        return apiRequest(`${appConfig.API_URL}/reports/insights/${type}/${format}`, "Post", props.filters)
            .then(
                data => {
                    if (typeof data === "object" && (data.error || ("valid" in data && !data.valid))) {
                        throw data.error;
                    }
                    if (format == "csv") {
                        fileDownload(data, `${type}-${getLocaleDate(new Date())}.csv`);
                    }
                    return data;
                }
            )
            .catch(
                error => {
                    createAlert(error, `error`);
                    return [];
                }
            );
    }

    _renderTopDomains() {
        const { topDomains } = this.state;
        const labels: string[] = [];
        const datasets: dynamic[] = [
            {
                label: "Shares",
                data: []
            },
            {
                label: "Clicks",
                data: []
            },
            {
                label: "Social Interactions",
                data: []
            },
            {
                type: "line",
                label: "Engagement Ratio",
                data: []
            }
        ];
        if (Array.isArray(topDomains)) {
            topDomains.forEach(item => {
                const regex = /(?:www\.)?([a-z0-9\-]+\.[a-z\.]+[\/]?).*/i;
                const domain = item["Domain"].match(regex) || ["", item["Domain"]];
                labels.push(domain[1]);
                datasets[0].data.push(item["Shares"]);
                datasets[1].data.push(item["Clicks"]);
                datasets[2].data.push(item["Social Interactions"]);
                datasets[3].data.push(item["Engagement Ratio"] || 0);
            });
        }
        return (
            <div className="col-xs-12 col-lg-6">
                <Chart
                    title="Top Domains"
                    type="bar"
                    labels={labels}
                    data={datasets}
                    loading={typeof topDomains === "string"}
                    showSearchResults={this.props.showSearchResults}
                    downloadAction={e => this._fetchData("topDomains", this.props, "csv")}
                    displayBarLegend={true}
                />
            </div>
        );
    }

    _renderTopTerms() {
        const { topTerms } = this.state;
        const labels: string[] = [];
        const datasets: dynamic[] = [
            {
                label: "Shares",
                data: []
            },
            {
                label: "Clicks",
                data: []
            },
            {
                label: "Social Interactions",
                data: []
            },
            {
                type: "line",
                label: "Engagement Ratio",
                data: []
            }
        ];

        if (Array.isArray(topTerms)) {
            topTerms.forEach(function(item, i) {
                labels.push(item["Term"]);
                datasets[0].data.push(item["Shares"]);
                datasets[1].data.push(item["Clicks"]);
                datasets[2].data.push(item["Social Interactions"]);
                datasets[3].data.push(item["Engagement Ratio"] || 0);
            });
        }
        return (
            <div className="col-xs-12 col-lg-6">
                <Chart
                    title="Top Terms"
                    type="bar"
                    labels={labels}
                    data={datasets}
                    loading={typeof topTerms === "string"}
                    showSearchResults={this.props.showSearchResults}
                    downloadAction={e => this._fetchData("topTerms", this.props, "csv")}
                    displayBarLegend={true}
                />
            </div>
        );
    }

    _sharesByContentTypePermission() {
        const {
            //pdf
            allowPdfCuration,
            allowFrontendPdfCompose,
            //video
            allowVideoCuration,
            allowFrontendVideoCompose,
            // image
            allowFrontendImageOnly,
            // general
            allowCustomEdit,
            allowFrontendCompose,
        } = this.props.client || {};

        // permission check
        if (
            !this.props.user.isSuper &&
            !this.props.user.isSuperClientAdmin &&
            !this.props.user.isClientAdmin &&
            !this.props.user.isReports
        ) {
            return false;
        }

        // client setting
        if (
            this.props.client &&
            ((!allowCustomEdit && !allowFrontendImageOnly && !allowFrontendVideoCompose && !allowFrontendPdfCompose)  ||
             (!allowPdfCuration && !allowFrontendCompose && !allowVideoCuration))
        ) {
            return false;
        }
        return true;
    }

    _renderSharesByContentType() {
        if (!this._sharesByContentTypePermission()) {
            return;
        }
        const {
            allowPdfCuration,
            allowFrontendPdfCompose,
            allowCustomEdit,
            allowVideoCuration,
            allowFrontendImageOnly,
            allowFrontendVideoCompose,
            allowFrontendCompose
        } = this.props.client || {};

        const { sharesByContentType } = this.state;

        const data = typeof sharesByContentType == 'string' ? [] : Object.keys(sharesByContentType).reduce((value: dynamic[], key) => {
            if (
                (key == "pdf" && this.props.client && !allowFrontendPdfCompose && !allowPdfCuration) ||
                (key == "image" && this.props.client && !allowCustomEdit && !allowFrontendImageOnly) ||
                (key == "video" && this.props.client && !allowVideoCuration && !allowFrontendVideoCompose) ||
                (key == "text" && this.props.client && !allowCustomEdit && !allowFrontendCompose)
            ) {
                return value;
            }

            const item = sharesByContentType[key]!;
            value.push({
                shares: item.shares,
                clicks: item.clicks,
                interactions: item.likes + item.comments,
                title: key == "pdf" ? "PDF" : upperCaseFirstLetter(key)
            });
            return value;
        }, []);

        let shares = data.map(item => item.shares);
        let totalShare = shares.reduce((value, item) => value + item, 0);

        let title = `Shares By Content Type`;
        let labels = data.map(item => item.title);
        return (
            <div className="col-xs-12 col-lg-6">
                <Chart
                    title={title}
                    loading={sharesByContentType == 'loading'}
                    showSearchResults={this.props.showSearchResults}
                    data={shares}
                    labels={labels}
                    type="pie"
                    tooltip={
                        <div>
                            <p>This report shows the proportion of shares for each content type and their overall performance.</p>
                            <p>Clicks: the total number of clicks on shares of the respective type. Not applicable for Image, Video or Text shares</p>
                            <p>Social Interactions: the total number of likes and comments on shares of the respective type</p>
                            <p>Engagement Ratio: The sum of clicks and interactions divided by the number of shares of the respective type</p>
                        </div>
                    }
                    customChartTooltips={
                        {
                            custom: function (tooltip) {
                                if (!tooltip) return;
                                tooltip.displayColors = false;
                            },
                            callbacks: {
                                label: function (tooltipItem) {
                                    if (tooltipItem.datasetIndex) {
                                        return;
                                    }
                                    const column = tooltipItem.index;
                                    const values = data[column];
                                    return [
                                        values.title,
                                        `${Math.round(values.shares / totalShare * 100)}% of shares`,
                                        `Shares: ${values.shares}`,
                                        `${values.title === "Video" ? "Views": "Clicks"}: ${(["Article", "PDF", "Video"].indexOf(values.title) > -1) ? values.clicks : "N/A"}`,
                                        `Interactions: ${values.interactions}`,
                                        `Engagement Ratio: ${Math.round((values.clicks + values.interactions) / values.shares * 10) / 10}`,
                                    ];
                                }
                            },
                        }
                    }
                    downloadAction={() => this._downloadSharesByContentTypeCSV(data)}
                />
            </div>
        );
    }

    // Renders EMV report to page upon loading
    _renderEarnedMediaValue() {
        const { earnedMediaValue } = this.state;
        const { client, user } = this.props;

        // Checks if user has permission to view report before rendering
        if (!hasEmvPermission(user)) {
            return;
        }


        const indexLabels = ["Clicks", "Likes", "Comments"]; // define once outside callbacks

        // Need this information for information shown on bar tooltips
        const availableNetworks = {
            LIAvailable: client?.socialNetworks.LinkedIn.available,
            FBAvailable: client?.socialNetworks.Facebook.available,
            TWAvailable: client?.socialNetworks.Twitter.available,
            TWSocialMetrics: client?.enableTwitterSocialMetrics,
            IGAvailable: client?.socialNetworks.IG?.available
        }

        // Need this information for information shown on bar tooltips
        const cpFigures = {
            linkedInCPClick: client?.linkedInCPClick ? client.linkedInCPClick : 5.3,
            facebookCPClick: client?.facebookCPClick ? client.facebookCPClick : 0.97,
            twitterCPClick: client?.twitterCPClick ? client.twitterCPClick : 0.38,
            linkedInCPLike: client?.linkedInCPLike ? client.linkedInCPLike : 5.4,
            facebookCPLike: client?.facebookCPLike ? client.facebookCPLike : 1.03,
            twitterCPLike: client?.twitterCPLike ? client.twitterCPLike : 0.4,
            instagramCPLike: client?.instagramCPLike ? client.instagramCPLike : 1.1,
            linkedInCPComment: client?.linkedInCPComment ? client.linkedInCPComment : 5.7,
            facebookCPComment: client?.facebookCPComment ? client.facebookCPComment : 1.07,
            twitterCPComment: client?.twitterCPComment ? client.twitterCPComment : 0.42,
            instagramCPComment: client?.instagramCPComment ? client.instagramCPComment : 1.16
        }

        // Does calculations based on provided information and determines if there is data to display in chart or not
        let data: dynamic[] = [];
        const {calculations, results} = this._performEMVCalculations(availableNetworks, cpFigures);
        if (Object.values(results).reduce((a, b) => a + b, 0) > 0) {
            if (availableNetworks.LIAvailable) {
                data.push({data: [calculations.LIClicksEMV, calculations.LILikesEMV, calculations.LICommentsEMV], label: "LinkedIn", stacked: true});
            }
            if (availableNetworks.FBAvailable) {
                data.push({data: [calculations.FBClicksEMV, calculations.FBLikesEMV, calculations.FBCommentsEMV], label: "Facebook", stacked: true});
            }
            if (availableNetworks.TWAvailable && !availableNetworks.TWSocialMetrics) {
                data.push({data: [calculations.TWClicksEMV, 0, 0], label: "X (Twitter)", stacked: true});
            } else if (availableNetworks.TWAvailable && availableNetworks.TWSocialMetrics) {
                data.push({data: [calculations.TWClicksEMV, calculations.TWLikesEMV, calculations.TWCommentsEMV], label: "X (Twitter)", stacked: true});
            }
            if (availableNetworks.IGAvailable) {
                data.push({data: [0, calculations.IGLikesEMV, calculations.IGCommentsEMV], label: "Instagram", stacked: true});
            }
        }

        return (
            <div className="col-xs-12 col-lg-6">
                <Chart
                    title="Earned Media Value (EMV) ($)"
                    type="bar"
                    labels={indexLabels}
                    displayBarLegend={true}
                    data={data}
                    loading={typeof earnedMediaValue === "string"}
                    showSearchResults={this.props.showSearchResults}
                    downloadAction={() => this._downloadEarnedMediaValueCSV(calculations, results)}
                    tooltip={
                        <div>
                            {"This report higlights how much it would cost to generate the attention the organization"
                             + " is receiving through their\nsocial media interactions.\n\nClicks: the clicks on shares on each of"
                             + " LinkedIn, X (Twitter) and Facebook multiplied by their respective cost-per-click.\n\nLikes: the likes"
                             + " on shares on each of LinkedIn, X (Twitter), Facebook and Instagram multiplied by their respective\n"
                             + "cost-per-like.\n\nComments: the comments on shares on each of LinkedIn, X (Twitter), Facebook and Instagram"
                             + " multiplied by their\nrespective cost-per-comment.\n\n"
                             + "The \"cost-per\" figures can be adjusted to your organization's needs by contacting your Seismic representative."}
                        </div>
                    }

                    customChartTooltips = {{
                        mode: 'index',
                        callbacks: {
                            title: function(tooltipItems) {
                                const tooltipItem = tooltipItems[0];
                                const index = tooltipItem.index;

                                return indexLabels[index];
                            },
                           label: function(tooltipItem, data) {
                                const dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = dataset.data[tooltipItem.index];
                                const label = dataset.label;
                                const type = tooltipItem.label;

                                if ((label === "Instagram" && type === "Clicks")
                                    || (label === "LinkedIn" && !availableNetworks.LIAvailable)
                                    || (label === "Facebook" && !availableNetworks.FBAvailable)
                                    || (label === "X (Twitter)" && (!availableNetworks.TWAvailable || (!availableNetworks.TWSocialMetrics && type !== "Clicks")))
                                    || (label === "Instagram" && !availableNetworks.IGAvailable)
                                ) {
                                    return "";
                                } else {
                                    value = parseFloat(value).toFixed(2); // format value to 2 decimal places
                                    return `${label}: $${value}`;
                                }
                            },
                            beforeBody: function(tooltipItems, data) {
                                const tooltipItem = tooltipItems[0];
                                const index = tooltipItem.index;
                                let total: any = 0;

                                data.datasets.forEach(dataset => {
                                    total += dataset.data[index];
                                });

                                total = parseFloat(total).toFixed(2); // convert total to a number with 2 decimal places

                                return `Total: $${total}`;
                            },
                        }
                    }}

                />
            </div>
        );
    }

    // Performs calculations on all the various EMV statistics
    _performEMVCalculations(availableNetworks, cpFigures) {
        const { earnedMediaValue } = this.state;

        const calculations = {
            LIClicksEMV: 0,
            FBClicksEMV: 0,
            TWClicksEMV: 0,
            LILikesEMV: 0,
            FBLikesEMV: 0,
            TWLikesEMV: 0,
            IGLikesEMV: 0,
            LICommentsEMV: 0,
            FBCommentsEMV: 0,
            TWCommentsEMV: 0,
            IGCommentsEMV: 0
        }

        const results = {
            clicksEMV: 0,
            likesEMV: 0,
            commentsEMV: 0,
            totalEMV: 0
        }

        if (typeof(earnedMediaValue) === "string") {
            return {calculations, results};
        }

        const stats = [
            "LIClicks",
            "FBClicks",
            "TWClicks",
            "LILikes",
            "FBLikes",
            "TWLikes",
            "IGLikes",
            "LIComments",
            "FBComments",
            "TWComments",
            "IGComments"
        ];

        stats.forEach(stat => {
            switch(stat) {
                case "LIClicks":
                    !!availableNetworks.LIAvailable && (calculations["LIClicksEMV"] += +earnedMediaValue[stat]! * cpFigures.linkedInCPClick);
                    break;
                case "FBClicks":
                    !!availableNetworks.FBAvailable && (calculations["FBClicksEMV"] += +earnedMediaValue[stat]! * cpFigures.facebookCPClick);
                    break;
                case "TWClicks":
                    !!availableNetworks.TWAvailable && (calculations["TWClicksEMV"] += +earnedMediaValue[stat]! * cpFigures.twitterCPClick);
                    break;
                case "LILikes":
                    !!availableNetworks.LIAvailable && (calculations["LILikesEMV"] += +earnedMediaValue[stat]! * cpFigures.linkedInCPLike);
                    break;
                case "FBLikes":
                    !!availableNetworks.FBAvailable && (calculations["FBLikesEMV"] += +earnedMediaValue[stat]! * cpFigures.facebookCPLike);
                    break;
                case "TWLikes":
                    !!availableNetworks.TWAvailable && !!availableNetworks.TWSocialMetrics && (calculations["TWLikesEMV"] += +earnedMediaValue[stat]! * cpFigures.twitterCPLike);
                    break;
                case "IGLikes":
                    !!availableNetworks.IGAvailable && (calculations["IGLikesEMV"] += +earnedMediaValue[stat]! * cpFigures.instagramCPLike);
                    break;
                case "LIComments":
                    !!availableNetworks.LIAvailable && (calculations["LICommentsEMV"] += +earnedMediaValue[stat]! * cpFigures.linkedInCPComment);
                    break;
                case "FBComments":
                    !!availableNetworks.FBAvailable && (calculations["FBCommentsEMV"] += +earnedMediaValue[stat]! * cpFigures.facebookCPComment);
                    break;
                case "TWComments":
                    !!availableNetworks.TWAvailable && !!availableNetworks.TWSocialMetrics && (calculations["TWCommentsEMV"] += +earnedMediaValue[stat]! * cpFigures.twitterCPComment);
                    break;
                case "IGComments":
                    !!availableNetworks.IGAvailable && (calculations["IGCommentsEMV"] += +earnedMediaValue[stat]! * cpFigures.instagramCPComment);
                    break;
            }
        });

        for (let key of Object.keys(calculations)) {
            if (key.includes("Clicks")) {
                results["clicksEMV"] += calculations[key];
            }
            if (key.includes("Likes")) {
                results["likesEMV"] += calculations[key];
            }
            if (key.includes("Comments")) {
                results["commentsEMV"] += calculations[key];
            }
        }

        results["totalEMV"] = results["clicksEMV"] + results["likesEMV"] + results["commentsEMV"]

        return {calculations, results};
    }

    _downloadEarnedMediaValueCSV(calculations, results) {
        const data = {
            "Total Earned Media Value": results.totalEMV.toFixed(2),
            "Total Clicks Value": results.clicksEMV.toFixed(2),
            "Total Likes Value": results.likesEMV.toFixed(2),
            "Total Comments Value": results.commentsEMV.toFixed(2),
            "LinkedIn Clicks Value": calculations.LIClicksEMV.toFixed(2),
            "Facebook Clicks Value": calculations.FBClicksEMV.toFixed(2),
            "X (Twitter) Clicks Value": calculations.TWClicksEMV.toFixed(2),
            "LinkedIn Likes Value": calculations.LILikesEMV.toFixed(2),
            "Facebook Likes Value": calculations.FBLikesEMV.toFixed(2),
            "X (Twitter) Likes Value": calculations.TWLikesEMV.toFixed(2),
            "Instagram Likes Value": calculations.IGLikesEMV.toFixed(2),
            "LinkedIn Comments Value": calculations.LICommentsEMV.toFixed(2),
            "Facebook Comments Value": calculations.FBCommentsEMV.toFixed(2),
            "X (Twitter) Comments Value": calculations.TWCommentsEMV.toFixed(2),
            "Instagram Comments Value": calculations.IGCommentsEMV.toFixed(2)
        }
        fileDownload(json2csv.parse(data), "earnedMediaValue.csv")
    }

    _downloadSharesByContentTypeCSV(data: dynamic[]) {
        if (!data?.length) {
            return;
        }
        const formatted = data.reduce((value, item) => {
            return {
                ...value,
                [item.title + " Shares"]: item.shares,
                [item.title + " Clicks"]: (item.title == "Article" || item.title == "PDF") ? item.clicks : "N/A",
                [item.title + " Social Interactions"]: item.interactions,
            };
        }, {});
        fileDownload(json2csv.parse(formatted), "sharesByContentType.csv")
    }

    render() {
        return (
            <Wrapper id="insights">
                <Row>
                    {this._renderTopTerms()}
                    {this._renderTopDomains()}
                    {this._renderSharesByContentType()}
                    {this._renderEarnedMediaValue()}
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });
export default connect(mapStateToProps)(InsightsView);
