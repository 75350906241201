import React from "react";
import { Provider } from "react-redux";
import { history, store } from "../utils/store";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";
import WrapperContainer from "../containers/WrapperContainer";
require("es6-object-assign").polyfill();

export default () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Route component={WrapperContainer} path="/" />
        </ConnectedRouter>
    </Provider>
);
