import React, { Component } from "react";
import Spinner from "../../../atoms/Spinner";

import { apiRequest, getLocaleDate, downloadFile as fileDownload } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";

import { createAlert } from "../../../../actions/utils";
import DownloadButton from "../../../atoms/DownloadButton";
import Pagination from "../../../molecules/Pagination";
import { Wrapper, Row } from "../../../atoms/Layout";
import { IReportFilter } from "../../reports/Index";

interface IsmsPerformance {
    Name: string;
    Email: string;
    SmsSent: number;
    SmsReceived: number;
    SystemMessages: number;
    ContactsTexted: number;
    ContactsReplied: number;
    ResponseRate: number;
    SmsShares: number;
    SmsClicks: number;
    ClickRate: number;
}

interface ISmsPerformancProps {
    filters: IReportFilter;
    onLoaded: () => void;
    client?: Mongo.client;
    showSearchResults: boolean;
}

interface ISmsPerformancState {
    smsPerformance: "loading" | IsmsPerformance[];
    smsPerformanceSort: string;
    smsPerformanceDirection: number;
    smsPerformanceLimit: number;
    smsPerformancePage: number;
    smsPerformanceTotal: number;
}

class SmsPerformanceReport extends Component<ISmsPerformancProps, ISmsPerformancState> {
    constructor(props: ISmsPerformancProps) {
        super(props);
        this._request = this._request.bind(this);
        this._fetchAll = this._fetchAll.bind(this);

        this._renderMedia = this._renderMedia.bind(this);

        this.state = {
            smsPerformance: "loading",
            smsPerformanceSort: "Name",
            smsPerformanceDirection: 1,
            smsPerformanceLimit: 20,
            smsPerformancePage: 0,
            smsPerformanceTotal: 0
        };
    }

    componentDidMount() {
        if (!this.props.filters) return;
        if (
            this.state.smsPerformance == "loading"
        ) {
            this._fetchAll(this.props);
        }
        //this._fetchAll(this.props);
    }

    componentWillReceiveProps(newProps: ISmsPerformancProps) {
        if ((newProps.filters != this.props.filters)) {
            this.setState({ smsPerformancePage: 0 }, () => this._fetchAll(newProps));
        }
    }

    _fetchAll(props: ISmsPerformancProps) {
        this._request().finally(props.onLoaded);
    }

    _request() {
        return new Promise<void>(resolve =>
            this.setState({ smsPerformance: "loading" }, () =>
                this._fetchData("smsperformance").then(data => {
                    this.setState({ smsPerformance: data.items, smsPerformanceTotal: data.count });
                }).finally(resolve)
            )
        );
    }

    _refresh(newPage = 0) {
        this.setState({ smsPerformancePage: newPage }, () => {
            this._fetchAll(this.props);
        });
    }
    _fetchData(type: "smsperformance", format = "json") {
        const sorting = {
            ...this.props.filters,
            sort: this.state.smsPerformanceSort,
            sortDirection: this.state.smsPerformanceDirection,
            limit: this.state.smsPerformanceLimit,
            skip: this.state.smsPerformanceLimit * this.state.smsPerformancePage
        };
        return apiRequest(
            `${appConfig.API_URL}/reports/sms/${type}/${format}`,
            "Post",
            sorting
        )
            .then(data => {
                if (typeof data === "object" && (data.error || data.valid === false)) {
                    throw data.error;
                }
                if (format == "csv") {
                    fileDownload(data, `${type}-${getLocaleDate(new Date())}.csv`);
                }
                return data;
            })
            .catch(error => {
                createAlert(error, `error`);
                return [];
            });
    }

    _sort(sortColumn: string, sortDirection: number) {
        this.setState({ smsPerformancePage: 0, smsPerformanceSort: sortColumn, smsPerformanceDirection: sortDirection }, () => {
            this._fetchAll(this.props);
        });
    }

    _renderSortIcons(column: string, defaultDirection = 1) {
        const sort = this.state.smsPerformanceSort;
        const sortDirection = this.state.smsPerformanceDirection;
        if (sort == column) {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={e => this._sort(column, sortDirection == 1 ? -1 : 1)}
                    onKeyDown={e => {
                        if (e.which === 13 || e.which === 32) {
                            this._sort(column, sortDirection == 1 ? -1 : 1)
                        }
                    }}
                >
                    {column}
                    <i
                        className={`fa ${sortDirection == 1 ? "fa-sort-up" : "fa-sort-down"}`}
                        tabIndex={0}
                        role="img"
                        aria-label={`Sort ${sortDirection == 1 ? "ascending" : "descending"}`}
                    />
                </span>
            );
        }
        return (
            <span
                style={{ cursor: "pointer" }}
                onClick={e => this._sort(column, defaultDirection)}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        this._sort(column, defaultDirection)
                    }
                }}
            >
                {column}
                <i
                    className="fa fa-sort"
                    tabIndex={0}
                    role="img"
                    aria-label="Sort ascending or descending"
                />
            </span>
        );
    }

    _renderLoadingCard(content: string | JSX.Element, loadingClass: string) {
        return (
            <div className={loadingClass}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            SMS Performance
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderMedia() {
        const { smsPerformance, smsPerformanceLimit, smsPerformancePage, smsPerformanceTotal } = this.state;
        if (smsPerformance === "loading") {
            return this._renderLoadingCard(<Spinner />, "col-lg-12");
        }

        return (
            <div className="col-lg-12">
                <div className="ibox float-e-margins">
                    {this.renderHead("SMS Performance")}
                    <div className="ibox-content">
                        <div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Pagination
                                    page={smsPerformancePage}
                                    totalItems={smsPerformanceTotal}
                                    numPerPage={smsPerformanceLimit}
                                    changePage={newPage => this._refresh(newPage)}
                                    disabled={false}
                                    showTotal={false}
                                />
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped responsive" data-type="responsive">
                                    <thead>
                                        <tr>
                                            <th tabIndex={0}>{this._renderSortIcons("Name", 1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Email", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Sms Sent", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Sms Received", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("System Messages", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Contacts Texted", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Contacts Replied", -1)}</th>
                                            <th tabIndex={0}>Response Rate</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Content Shared", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Content Clicked", -1)}</th>
                                            <th tabIndex={0}>Click Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {smsPerformance &&
                                            smsPerformance.length > 0 &&
                                            smsPerformance instanceof Array &&
                                            smsPerformance.map((art, i) => {
                                                return (
                                                <tr key={art.Email} tabIndex={0}>
                                                    <td tabIndex={0}>{art.Name || "N/A"}</td>
                                                    <td tabIndex={0}>{art.Email || "N/A"}</td>
                                                    <td tabIndex={0}>{art.SmsSent || "N/A"}</td>
                                                    <td tabIndex={0}>{art.SmsReceived || "N/A"}</td>
                                                    <td tabIndex={0}>{art.SystemMessages || "N/A"}</td>
                                                    <td tabIndex={0}>{art.ContactsTexted || "N/A"}</td>
                                                    <td tabIndex={0}>{art.ContactsReplied || "N/A"}</td>
                                                    <td tabIndex={0}>{art.ResponseRate || "N/A"}</td>
                                                    <td tabIndex={0}>{art.SmsShares || "N/A"}</td>
                                                    <td tabIndex={0}>{art.SmsClicks || "N/A"}</td>
                                                    <td tabIndex={0}>{art.ClickRate || "N/A"}</td>
                                                </tr>
                                            );
                                        })}
                                    {!smsPerformance?.length &&
                                        <tr>
                                            <td tabIndex={0} colSpan={42}>No records found</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Pagination
                                    currItems={smsPerformance ? smsPerformance.length : 0}
                                    page={smsPerformancePage}
                                    totalItems={smsPerformanceTotal}
                                    numPerPage={smsPerformanceLimit}
                                    changePage={newPage => this._refresh(newPage)}
                                    disabled={false}
                                    showTotal={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderHead(title: string) {
        return (
            <div className="ibox-title"
                style={{
                    paddingTop: "7px",
                    display: "flex"
                }}>
                <h5 style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center"
                }}>
                    {title}
                    <span style={{ marginLeft: "auto" }}>

                        <DownloadButton
                            style={{
                                marginLeft: "5px",
                                marginBottom: "0",
                                width: "auto"
                            }}
                            className="btn--sm"
                            onClick={e => this._fetchData("smsperformance", "csv")}
                        >
                            <i className="fa fa-download" /> Download
                        </DownloadButton>
                    </span>
                </h5>
            </div>
        );
    }

    render() {
        return (
            <Wrapper id="media">
                <Row>{this._renderMedia()}</Row>
            </Wrapper>
        );
    }
}

export default SmsPerformanceReport;
