import BaseAction from "../utils/BaseAction";

export type IClientActions = ClassInstance<typeof _ClientActions>;

export interface IMemberCount {
    all : number;
    mobile : number;
}

export interface IReadOnlyContentCount {
    count : number;
}

class _ClientActions extends BaseAction {
    doGet(cid: string) {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.IClient>(`/client/get`, "POST", { cid });
    }

    async doList<K extends keyof ServerTypes.Console.IClient = keyof ServerTypes.Console.IClient>(
        query: {
            groups?: string[];
            search?: string;

            sort?: { [key: string]: number };
            skip?: number;
            limit?: number;
        },
        projectedFields?: K[]
    ) {
        const data = await this.apiRequestWithErrorAlert<{ items: Pick<ServerTypes.Console.IClient, K>[]; count: number }>(
            `/client/list`,
            `POST`,
            { ...query, ...(projectedFields ? { projectedFields } : null) }
        );
        return data && data.items && data.items.length
            ? { items: data.items, total: data.count }
            : { items: [], total: 0 };
    }

    async doUpsert(client: ServerTypes.Console.IClient) {
        return this.apiRequestWithErrorAlert<{ cid: string }>(`/client/upsert`, "post", client, undefined, true).catch(
            err => {
                throw new Error("Upsert failed");
            }
        );
    }

    async doRemove(cid: string) {
        this.apiRequestWithErrorAlert(`/client/delete`, "POST", { cid }, undefined, true);
    }

    async doGetMemberCount(cid: string, isEcomm?: boolean){
        const data = await this.apiRequestWithErrorAlert(`/client/member-size`, `POST`, { cid, isEcomm }) as IMemberCount;
        return data ? data : null;
    }

    async doGetReadOnlyContentCount(cid: string) {
        const data = await this.apiRequestWithErrorAlert(`/client/readonly-content-size`, `POST`, { cid }) as IReadOnlyContentCount;
        return data ? data.count : null;
    }

    async doRemoveReadOnlyContent(cid: string) {
        this.apiRequestWithErrorAlert(`/client/delete-readonly-content`, "POST", { cid }, undefined, true);
    }

    async doSeismicUserSync(cid: string){
        this.apiRequestWithErrorAlert(
            `/client/seismicUserSync`,
            "POST",
            { cid }
        );
    }
}

export const ClientActions = new _ClientActions();
