import React, { Component, CSSProperties, ReactElement } from "react";
import ReactTooltip from "react-tooltip";
import { uuid } from "../../utils/Helpers";
import ReactDOMServer from "react-dom/server";

export default class Tooltip extends Component<
    {
        type?: string;
        effect?: string;
        place?: string;
        icon?: string;
        label?: string | ReactElement<any>;
        offset?: CSSProperties;
        style?: CSSProperties;
        className?: string;
    },
    {
        uuid: string;
        type: string;
        effect: string;
        place: string;
        icon: string;
        label?: string;
        offset?: CSSProperties;
        style: CSSProperties;
        className?: string;
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            uuid: uuid(),
            type: props.type || "dark",
            effect: props.effect || "solid",
            place: props.place || "bottom",
            offset: props.offset || {},
            icon: props.icon || "fa-info-circle",
            label: props.label
                ? typeof props.label === "string"
                    ? props.label
                    : ReactDOMServer.renderToString(props.label)
                : null,
            className: props.className || "",
            style: props.style || {
                fontWeight: "normal"
            }
        };
    }
    render() {
        return (
            <div className={`tooltip__wrapper ${this.state.className}`} style={this.state.style}>
                {this.state.label ? (
                    <div
                        data-tip=""
                        data-for={this.state.uuid}
                        dangerouslySetInnerHTML={{
                            __html: this.state.label
                        }}
                        tabIndex={0} onFocus={(e) => ReactTooltip.show(e.target)} onBlur={(e) => ReactTooltip.hide(e.target)}
                    />
                ) : (
                    <i data-tip="" data-for={this.state.uuid} className={`fa ${this.state.icon}`} tabIndex={0} onFocus={(e) => ReactTooltip.show(e.target)} onBlur={(e) => ReactTooltip.hide(e.target)} />
                )}
                <ReactTooltip
                    id={this.state.uuid}
                    type={this.state.type}
                    effect={this.state.effect}
                    place={this.state.place}
                    offset={this.state.offset}
                >
                    {this.props.children}
                </ReactTooltip>
            </div>
        );
    }
}
