import { apiRequest, apiFormDataPost, apiRequestWithErrorAlert } from "../utils/Helpers";
import appConfig from "../../config/config.dev";

export function doGetECommunicationMessage(cid: string, ecommid: string) {
    const data = {
        ...(ecommid ? { ecommid } : null)
    };

    return apiRequest(`${appConfig.API_URL}/e-communication/message/get`, `POST`, data);
}

export function doUpsertECommunicationMessage(message: FormData, insert: boolean) {
    return apiFormDataPost(`${appConfig.API_URL}/e-communication/message/${insert ? "insert" : "update"}`, message);
}

export function doGetECommunicationMessages(
    cid: string,
    type: string | null = null,
    status: string | null = null,
    frequency: string | null = null,
    start = 0,
    limit = 1000,
    searchTerm: string | null = null,
    startDate: string | null = null,
    endDate: string | null = null,
    currentlyActive = false,
    tags: string[] | null = null,
    categoryid: string | null = null,
    lang: 'en' | 'fr'
) {
    const data = {
        cid,
        skip: start,
        categoryid,
        lang,
        sort: { lastContentUpdatedOn: -1, lastUpdatedOn: -1 },
        ...(limit ? { limit } : null),
        ...(startDate ? { startDate } : null),
        ...(endDate ? { endDate } : null),
        ...(type ? { type } : null),
        ...(status ? { status } : null),
        ...(frequency ? { frequency } : null),
        ...(searchTerm ? { search: searchTerm } : null),
        ...(tags ? { tags } : null),
        ...(currentlyActive ? { currentlyActive } : null),
    };

    return apiRequest(`${appConfig.API_URL}/e-communication/message/list`, `POST`, data);
}

export function doDuplicateECommunicationMessage(cid: string, ecommid: string) {
    const data = { cid, ecommid };
    return apiRequest(`${appConfig.API_URL}/e-communication/message/duplicate`, `POST`, data);
}


// TODO: unused, should maybe remove
export function doGetECommunicationSubscribable(cid: string, subscribableid: string) {
    const data = {
        ...(subscribableid ? { subscribableid } : null)
    };

    return apiRequest(`${appConfig.API_URL}/e-communication/subscribable/get`, `POST`, data);
}

export function doGetECommunicationSubscribables(
    cid: string | null = null,
    status: string | null = null,
    frequency: string | null = null,
    start = 0,
    limit = 1000,
    searchTerm: string | null = null,
    lang = '',
) {
    const data = {
        ...(cid !== null ? { cid } : null),
        skip: start,
        lang,
        sort: { title: 1 },
        ...(limit ? { limit } : null),
        ...(status ? { status } : null),
        ...(frequency ? { frequency } : null),
        ...(searchTerm ? { search: searchTerm } : null)
    };

    return apiRequest(`${appConfig.API_URL}/e-communication/subscribable/list`, `POST`, data);
}

export function doGetECommunicationCategory(cid: string, categoryid: string) {
    const data = {
        ...(categoryid ? { categoryid } : null)
    };

    return apiRequest(`${appConfig.API_URL}/e-communication/category/get`, `POST`, data);
}

export function doGetECommunicationCategories(
    cid: string | null = null,
    status: string | null = null,
    start = 0,
    limit = 1000,
    searchTerm: string | null = null,
    lang = '',
) {
    const data = {
        ...(cid !== null ? { cid } : null),
        skip: start,
        lang,
        sort: { title: 1 },
        ...(limit ? { limit } : null),
        ...(status ? { status } : null),
        ...(searchTerm ? { search: searchTerm } : null)
    };

    return apiRequest(`${appConfig.API_URL}/e-communication/category/list`, `POST`, data);
}

export function doGetECommunicationSubscriptionMessages(cid: string, subscribableid: string, scheduledDeliveryDate: Date, status: string, start = 0, limit = 1000) {
    const data = {
        cid,
        ...(scheduledDeliveryDate ? { scheduledDeliveryDate } : null),
        ...(status ? { status } : null),
        skip: start,
        sort: { activeDate: -1 },
        ...(subscribableid ? { subscribableid } : null),
        ...(limit ? { limit } : null)
    };

    return apiRequestWithErrorAlert<{ valid: boolean; results: dynamic[] }>(
        `${appConfig.API_URL}/e-communication/message/list`,
        `POST`,
        data
    );
}

export function doGetBeeEditorToken(cid: string) {
    const data = { cid };
    return apiRequest(`${appConfig.API_URL}/e-communication/message/bee/apiauth`, `POST`, data);
}
export function doGetBeeEditorCustomRows(cid: string) {
    const data = { cid };
    return apiRequest(`${appConfig.API_URL}/e-communication/message/bee/custom-rows/get`, `POST`, data);
}
export function doSetBeeEditorCustomRow(cid: string, json: string) {
    const row = JSON.parse(json);
    const name = row.metadata.name;
    const data = { cid, json, name };
    return apiRequest(`${appConfig.API_URL}/e-communication/message/bee/custom-row/upsert`, `POST`, data);
}

export function doUpsertUserECommunicationProfile(cid: string, uid: string, json: string) {
    const data = { cid, uid, json };
    return apiRequestWithErrorAlert<{ success: boolean }>(
        `${appConfig.API_URL}/e-communication/profile/upsert`,
        `POST`,
        data
    );
}
