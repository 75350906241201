import React, { MouseEvent, ChangeEvent, useState, useEffect } from "react";

interface ICheckboxProps {
    checked?: boolean;
    disabled?: boolean;
    _onChange: (event: MouseEvent | ChangeEvent) => boolean | void;
    classes?: string;
    children?: React.ReactNode;
    name?: string;
}
export default (props: ICheckboxProps) => {
    const [checked, setChecked] = useState(props.checked || false);
    useEffect(() => {
        setChecked(props.checked!);
    }, [props.checked]);

    const onChange = (event: MouseEvent | ChangeEvent) => {
        if (props._onChange && !props.disabled) {
            if (props._onChange(event)) {
                setChecked(!checked);
            }
        }
    };
    return (
        <div
            className={`${props.classes ? props.classes : "form__checkbox"} ${props.disabled ? "disabled" : ""}`}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <label htmlFor={props.name ? `${props.name}-checkbox` : "checkbox"} style={{display: "none"}}></label>
            <input
                id={props.name ? `${props.name}-checkbox` : "checkbox"}
                aria-label={props.name ? `${props.name}-checkbox` : "checkbox"}
                type="checkbox"
                {...("disabled" in props && props.disabled ? { disabled: true } : null)}
                onChange={onChange}
                checked={checked}
                style={{
                    width: "auto"
                }}
            />
            {props.children && <span onClick={onChange}>{props.children}</span>}
        </div>
    );
};
