import React from "react";
import { smoothlyMenu, getFriendlyDate } from "../../utils/Helpers";
import Link from "../atoms/Link";
import { doLogout } from "../../actions/session";
import { doGetNonCompliantArticles } from "../../actions/lists";
import { connect } from "react-redux";
import { DelegateActions } from "../../actions/";
import Button from "../atoms/Button";

class TopHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delegateUsers: [],
            articles: [],
            isNavigationExpanded: false
        };
        this._onLogout = this._onLogout.bind(this);
        this.toggleNavigation = this.toggleNavigation.bind(this);
    }

    toggleNavigation(e) {
        e.preventDefault();
        $("body").toggleClass("mini-navbar");
        smoothlyMenu();
        this.setState(prevState => ({
            isNavigationExpanded: !prevState.isNavigationExpanded
        }));
    }

    componentDidMount() {
        if (
            this.props.user &&
            (this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin || this.props.user.isCurator)
        ) {
            doGetNonCompliantArticles(this.props.user.cid, ["suggested"]).then(articles => {
                this.setState({ articles });
            });

            if (
                (this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin) &&
                (this.props.clients[this.props.user.cid] || { allowDelegation: false }).allowDelegation
            ) {
                DelegateActions.doGetPending(this.props.user.cid, "", 5, 0).then(data => {
                    let state = {};
                    state.loading = false;
                    state.client = this.props.clients[this.props.user.cid];
                    state.delegateUsers = data.items;
                    this.setState(state);
                });
            }
        }
    }

    componentWillReceiveProps(newProps) {
        if (!this.state.client && newProps.user.cid) {
            if (
                (this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin) &&
                (this.props.clients[this.props.user.cid] || { allowDelegation: false }).allowDelegation
            ) {
                DelegateActions.doGetPending(this.props.user.cid, "", 5, 0).then(data => {
                    let state = {};
                    state.client = newProps.clients[newProps.user.cid];
                    state.loading = false;
                    state.delegateUsers = data.items;
                    this.setState(state);
                });
            }
        }
    }

    async _onLogout(event) {
        await doLogout();
    }
    render() {
        const { user } = this.props;
        const { delegateUsers, articles, isNavigationExpanded } = this.state;
        return (
            <div className="row border-bottom">
                <nav
                    className="navbar navbar-static-top brandPrimary--bg"
                    role="navigation"
                    style={{
                        marginBottom: 0
                    }}
                >
                    <div className="navbar-header">
                        <Button
                            className="navbar-minimalize"
                            onClick={this.toggleNavigation}
                            ariaLabel="Main menu"
                            ariaExpanded={isNavigationExpanded}
                        >
                            <i className="fa fa-bars" />
                        </Button>
                    </div>
                    <ul className="nav navbar-top-links navbar-right">
                        <li>
                            <span className="m-r-sm text-white welcome-message">Welcome {user.name}!</span>
                        </li>
                        {(user.isSuperClientAdmin || user.isSuper) && (
                            <li>
                                <Link to="/audit" className="brandPrimary--bg">
                                    <i className="fa fa-exclamation" />
                                    <span>Audit Log</span>
                                </Link>
                            </li>
                        )}
                        {(user.isSuperClientAdmin || user.isClientAdmin || user.isCurator) &&
                            ((articles && articles.length > 0) || (delegateUsers && delegateUsers.length > 0)) && (
                                <li className="dropdown">
                                    <a
                                        className="dropdown-toggle count-info brandPrimary--bg"
                                        data-toggle="dropdown"
                                        href="#"
                                        aria-expanded="false"
                                        style={{ backgroundColor: '#996935', color: '#ffffff' }}
                                    >
                                        <i className="fa fa-bell" />{" "}
                                        {(articles || delegateUsers) && (
                                            <span className="label label-warning" style={{ backgroundColor: '#996935', color: '#ffffff' }}>
                                                {articles.length + delegateUsers.length}
                                            </span>
                                        )}
                                    </a>

                                    <ul className="dropdown-menu dropdown-messages">
                                        {articles &&
                                            articles.map(function (article, i) {
                                                return (
                                                    <span key={`${article.artid}-${i}`}>
                                                        <li>
                                                            <Link
                                                                to={`/content/l/e/${article.artid}`}
                                                                style={{
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    minHeight: 0
                                                                }}
                                                            >
                                                                <div className="dropdown-messages-box">
                                                                    <div className="media-body">
                                                                        <small className="pull-right">
                                                                            {getFriendlyDate(article.lastUpdatedOn)}
                                                                        </small>
                                                                        <strong>{article.Title}</strong>
                                                                        &nbsp;was marked as&nbsp;
                                                                        <strong>{article.status}</strong>.
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li className="divider" />
                                                    </span>
                                                );
                                            })}
                                        {delegateUsers &&
                                            this.state.client &&
                                            this.state.client.allowDelegation &&
                                            delegateUsers.map(function (delegate, i) {
                                                return (
                                                    <span key={`${delegate.email}-${i}`}>
                                                        <li>
                                                            <Link
                                                                to={`/delegation/pending`}
                                                                style={{
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    minHeight: 0,
                                                                    wordBreak: "break-all"
                                                                }}
                                                            >
                                                                <div className="dropdown-messages-box">
                                                                    <div className="media-body" style={{ wordBreak: "normal" }}>
                                                                        <small className="pull-right">
                                                                            {getFriendlyDate(delegate.requestedOn)}
                                                                        </small>
                                                                        <strong>{delegate.email}</strong>
                                                                        &nbsp;was requested as a delegate!&nbsp;
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li className="divider" />
                                                    </span>
                                                );
                                            })}
                                    </ul>
                                </li>
                            )}
                        <li>
                            <Button onClick={this._onLogout} className="brandPrimary--bg">
                                <i className="fa fa-sign-out" />
                                Log out
                            </Button>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.session.admin,
    clients: state.lists.clients,
    articles: state.lists.complianceArticles
});

export default connect(mapStateToProps)(TopHeader);
