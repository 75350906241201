import React, { Component } from "react";
import { connect } from "react-redux";
import { doUpsertIndustry } from "../../../actions/inserts";
import { doGetIndustry } from "../../../actions/lists";
import Multiselect from "react-bootstrap-multiselect";
import { preventDefault, getMultiSelectValues, getIndustries } from "../../../utils/Helpers";
import { Industry } from "../../../utils/Models";
import { UtilActions } from "../../../actions";
import { createAlert, doSetFooterFunctions } from "../../../actions/utils";
import Checkbox from "../../atoms/Checkbox";
import ActionableList from "../../molecules/ActionableList";
import PageHeading from "../../molecules/PageHeading";
import Tooltip from "../../atoms/Tooltip";
import Button from "../../atoms/Button";
import PermissionSet from "../../molecules/PermissionSet";
import { Wrapper, Row } from "../../atoms/Layout/";

class EditIndustry extends Component {
    constructor(props) {
        super(props);

        this._onFieldChange = this._onFieldChange.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        let iid = "";
        let insert = true;
        if (props.match.params.iid) {
            iid = props.match.params.iid;
        }
        let industry = "";
        if (props.match.params.name) {
            industry = decodeURIComponent(props.match.params.name);
        }

        this.state = {
            ...Industry(),
            iid,
            industry,
            insert: insert,
            save_protect: false
        };
        if (props.industries[this.state.iid]) {
            this.state = props.industries[this.state.iid];
            this.state.insert = false;
        }
        if (this.state.insert && !industry) {
            this.state.industry = getIndustries()[0].value;
        }
        this.state.filterLang = "en";
    }
    async componentDidMount() {
        if (this.state.iid) {
            doGetIndustry(this.state.iid);
        }
        doSetFooterFunctions(this._onSubmit, null, "Update");
    }
    componentWillReceiveProps(newProps) {
        if (newProps.industries !== this.props.industries) {
            if (newProps.industries[this.state.iid]) {
                this.setState(newProps.industries[this.state.iid]);
            }
        }
    }

    componentWillUnmount() {
        doSetFooterFunctions(null, null);
    }

    async _onFieldChange(key, event) {
        let val = event.target ? event.target.value : event;
        if (key == "specialties") {
            var newState = { [key]: { [this.state.filterLang]: val } };
        } else {
            var newState = { [key]: val };
        }
        this.setState(newState);
    }

    async _onSubFieldChange(key, subKey, event) {
        let val = event.target ? event.target.value : event;
        const subIndustries = this.state.subIndustries || [];
        let updated = false;
        for (let i = 0; i < subIndustries.length; i++) {
            if (subIndustries[i].name == key) {
                const item = subIndustries[i];
                if (subKey == "specialties") {
                    item[subKey][this.state.filterLang] = val;
                } else {
                    item[subKey] = val;
                }
                subIndustries[i] = item;
                updated = true;
                break;
            }
        }

        if (!updated) {
            const obj = { name: key, handles: { twitter: [] }, specialties: {} };
            if (subKey == "specialties") {
                var specialties = {};
                specialties[this.state.filterLang] = val;
                subIndustries.push({ ...obj, specialties });
            } else {
                subIndustries.push({ ...obj, [subKey]: val });
            }
        }
        this.setState({ subIndustries });
    }

    _onSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        let industry = Industry(this.state);

        for (var key in industry.specialties) {
            industry.specialties[key] = (this.state.specialties[key] || []).map(i => i.trim()).filter(i => i.length);
        }

        for (let i = 0; i < industry.subIndustries.length; i++) {
            for (var key in industry.subIndustries[i].specialties) {
                industry.subIndustries[i].specialties[key] = (industry.subIndustries[i].specialties[key] || [])
                    .map(i => i.trim())
                    .filter(i => i.length);
            }
        }
        return doUpsertIndustry(industry);
    }
    getAvailableSubIndustries(selectedIndustry) {
        selectedIndustry = selectedIndustry || this.state.industry;
        const industry = getIndustries().filter(v => v.value == selectedIndustry);
        if (industry && industry.length && industry[0].subIndustries) {
            return industry[0].subIndustries;
        }
        return [];
    }

    getSubIndustry(name) {
        const subIndustries = this.state.subIndustries || [];
        for (let i = 0; i < subIndustries.length; i++) {
            if (subIndustries[i].name == name) {
                const item = subIndustries[i];
                return item;
            }
        }
        return null;
    }
    renderSubIndustries() {
        const subIndustries = this.getAvailableSubIndustries(this.state.industry);
        if (subIndustries.length == 0) {
            return;
        }
        return subIndustries.map(i => {
            const subIndustry = this.getSubIndustry(i);
            return [
                <Row key={i}>
                    <div className="col-sm-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>
                                    {getIndustries().filter(i => i.value == this.state.industry)[0].name} - {i}
                                </h5>
                            </div>
                            <div className="ibox-content">
                                <div className="form__row">
                                    <div className="form__group form__group--full">
                                        <label htmlFor="language1" className="form__label">Language:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="language1"
                                                onChange={e => this._onFieldChange("filterLang", e)}
                                                value={this.state.filterLang}
                                            >
                                                <option value="">Please select a language</option>
                                                <option value="en">English</option>
                                                <option value="fr">French</option>
                                                <option value="es">Spanish</option>
                                                <option value="de">German</option>
                                                <option value="pt">Portuguese</option>
                                                <option value="it">Italian</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__group form__group--full">
                                        <label className="form__label">Terms:</label>

                                        <ActionableList
                                            placeholder="Please enter an industry term"
                                            items={
                                                subIndustry && subIndustry.specialties[this.state.filterLang]
                                                    ? subIndustry.specialties[this.state.filterLang]
                                                    : []
                                            }
                                            disabled={true}
                                            inactive={!this.state.filterLang}
                                            updateAction={(items, remove) => {
                                                const specialties = (items || [])
                                                    .map(i => i.trim())
                                                    .filter(i => i.length);
                                                this._onSubFieldChange(i, "specialties", specialties);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__group form__group--full">
                                        <label className="form__label">X (Twitter) Influencers:</label>

                                        <ActionableList
                                            placeholder="Please enter a X (twitter) handle"
                                            preElement={<span>@</span>}
                                            disabled={true}
                                            items={(subIndustry || { handles: { twitter: [] } }).handles.twitter.map(
                                                i => i.name
                                            )}
                                            updateAction={(items, remove) => {
                                                const handles = (
                                                    subIndustry || {
                                                        handles: { twitter: [] }
                                                    }
                                                ).handles;
                                                handles.twitter = (items || []).map(item => ({ name: item }));
                                                this._onSubFieldChange(i, "handles", handles);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            ];
        });
    }
    render() {
        const { name } = this.state;
        return (
            <Wrapper>
                <PageHeading
                    title={`Edit Industry`}
                    buttons={[
                        {
                            title: "Back to industry list",
                            link: "/industries/"
                        }
                    ]}
                />
                <form
                    className="wrapper wrapper-content"
                    onSubmit={event => this._onSubmit(event)}
                    onKeyPress={preventDefault}
                >
                    <Row>
                        <div className="col-sm-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h3>{getIndustries().filter(i => i.value == this.state.industry)[0].name}</h3>
                                </div>
                                <div className="ibox-content">
                                    <div className="form__row">
                                        <div className="form__group form__group--full">
                                            <label htmlFor="language2" className="form__label">Language:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="language2"
                                                    onChange={e => this._onFieldChange("filterLang", e)}
                                                    value={this.state.filterLang}
                                                >
                                                    <option value="">Please select a language</option>
                                                    <option value="en">English</option>
                                                    <option value="fr">French</option>
                                                    <option value="es">Spanish</option>
                                                    <option value="de">German</option>
                                                    <option value="pt">Portuguese</option>
                                                    <option value="it">Italian</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__group form__group--full">
                                            <label className="form__label">Industry Terms:</label>

                                            <ActionableList
                                                placeholder="Please enter an industry term"
                                                disabled={true}
                                                inactive={!this.state.filterLang}
                                                items={
                                                    this.state.specialties[this.state.filterLang]
                                                        ? this.state.specialties[this.state.filterLang]
                                                        : []
                                                }
                                                updateAction={(items, remove) => {
                                                    const specialtiesItems = (items || [])
                                                        .map(i => i.trim())
                                                        .filter(i => i.length);
                                                    this._onFieldChange("specialties", specialtiesItems);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__group form__group--full">
                                            <label className="form__label">X (Twitter) Influencers:</label>

                                            <ActionableList
                                                placeholder="Please enter a X (Twitter) handle"
                                                preElement={<span>@</span>}
                                                disabled={true}
                                                items={(this.state.handles || { twitter: [] }).twitter.map(i => i.name)}
                                                updateAction={(items, remove) => {
                                                    const handles = this.state.handles || { twitter: [] };
                                                    handles.twitter = (items || []).map(item => ({ name: item }));
                                                    this._onFieldChange("handles", handles);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {this.renderSubIndustries()}
                </form>
            </Wrapper>
        );
    }
}
EditIndustry.allowSuper = true;
EditIndustry.allowApiAdmin = true;
EditIndustry.allowClientAdmin = false;
EditIndustry.allowAdmin = false;
EditIndustry.allowCurator = false;
EditIndustry.allowReports = false;
const mapStateToProps = state => ({
    industries: state.lists.industries,
    user: state.session.admin
});

export default connect(mapStateToProps)(EditIndustry);
