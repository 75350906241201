import BaseAction from "../utils/BaseAction";

export interface ICustomBrandActions {
    doGet(brandName: string): Promise<ServerTypes.Console.IBrand | null>;
    doGetClientsUsingBrand(brandName: string);
    doList(query: {
        sort?: { [key: string]: number };
        skip?: number;
        limit?: number;
        search?: string;
    }): Promise<{ items: ServerTypes.Console.IBrand[]; total: number }>;
    doUpsert(customBrand: ServerTypes.Console.IBrand): Promise<void>;
    doDeleteBrand(brandName: string): Promise<void>;
}

class _CustomBrandActions extends BaseAction implements ICustomBrandActions {
    async doGet(brandName: string) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; item: ServerTypes.Console.IBrand }>(
            `/custombrand/get`,
            "POST",
            { brandName }
        );
        if (data && data.item) {
            return data.item;
        }
        return null;
    }

    async doGetClientsUsingBrand(brandName: string) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; items: string[] }>(
            `/custombrand/getClientsUsingBrand`,
            `POST`,
            { brandName }
        ).catch(() => null);
        return { items: data?.items || [] };
    }

    async doList(query: {
        search?: string;
        sort?: { [key: string]: number };
        skip?: number;
        limit?: number;
    }) {
        const data = await this.apiRequestWithErrorAlert<{ items: ServerTypes.Console.IBrand[]; count: number }>(
            `/custombrand/list`,
            `POST`,
            { ...query },
            undefined,
            true
        ).catch(() => null);
        return { items: data?.items || [], total: data?.count || 0 };
    }

    async doUpsert(customBrand: ServerTypes.Console.IBrand, files: File[] | undefined = undefined) {
        const formData = new FormData();
        if (files) {
            files.forEach(file => formData.append("file", file));
            // @ts-expect-error
            delete customBrand["appIcon"];
        }
        Object.keys(customBrand).forEach(key => {
            // prevent null _id from being sent to backend during creation
            if (key !== "_id" || customBrand[key] !== null) {
                formData.append(key, customBrand[key]);
            }
        });
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean }>(
            `/custombrand/upsert`,
            "POST",
            formData,
            undefined,
            true
        );
        if (!data || !data.valid) {
            throw new Error("Upsert failed");
        }
    }

    async doDeleteBrand(brandName: string) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean }>(
            `/custombrand/delete`,
            "POST",
            { brandName },
            undefined, true
        );
        if (!data || !data.valid) {
            throw new Error("Delete brand failed");
        }
    }
}

export const CustomBrandActions = new _CustomBrandActions();
