import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import { connect } from "react-redux";

import { apiRequest, apiFormDataPost } from "../../../utils/Helpers";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import Spinner from "../../atoms/Spinner";
import Tooltip from "../../atoms/Tooltip";
import Link from "../../atoms/Link";
import Button from "../../atoms/Button";

import { CATEGORY_STATUS, containValue } from "../../../utils/ECommunicationHelpers";

interface IAddECommunicationCategoryProps {
    user: Mongo.clientAdmin;
    cid: string;
    client?: Mongo.client;
    item?: Mongo.clientECommunicationCategories
    updateAction?: () => void;
    deleteAction?: () => void;
    closeAction?: () => void;
}

interface IAddECommunicationCategoryState extends Mongo.IClientECommunicationCategory {
    open: boolean;
    panel: number;
    url_loader: string;
    submit_loading: boolean;
    save_protect: boolean;
    modal_title: string;
    modal_button: string;
    type: string;
    multiLangSupport: boolean;
    artid?: string;
    source?: string;
}

class AddECommunicationCategory extends Component<IAddECommunicationCategoryProps, IAddECommunicationCategoryState> {
    constructor(props: IAddECommunicationCategoryProps) {
        super(props);
        this._closeModal = this._closeModal.bind(this);
        this._submit = this._submit.bind(this);
        this._deleteCategory = this._deleteCategory.bind(this);
        let lang = "en" as "en" | "fr";
        let multiLangSupport = false;

        if (props.client && props.client.lang) {
            lang = props.client.lang as "en" | "fr";
        }
        if (props.client && props.client.eCommunication && props.client.eCommunication?.langSupport) {
            multiLangSupport = props.client.eCommunication.langSupport.length > 1;
        }
        this.state = {
            open: true,
            panel: props.item ? 1 : 0,
            url_loader: "",
            submit_loading: false,
            save_protect: false,
            modal_title: `${props.item ? "Edit" : "Add"} Message Category`,
            modal_button: `${props.item ? "Update" : "Create"} Message Category`,
            type: props.item ? "UPDATE" : "CREATE",
            title: "",
            description: "",
            lang,
            CreatedOn: new Date(),
            cid: props.cid,
            categoryid: "",
            status: "active",
            multiLangSupport
        };

        if (this.props.item) {
            this.state = Object.assign(this.state, this.props.item);
        }
    }

    _onFieldChange(field: keyof IAddECommunicationCategoryState, value) {
        let newState = {
            [field]: value && value.target ? value.target.value : value
        } as IAddECommunicationCategoryState;
        if (this.state.type == "CREATE") {
            newState.CreatedOn = new Date();
        }
        this.setState(newState);
    }

    componentDidMount() {
        return;
    }

    _deleteCategory() {
        if (this.state.artid && confirm("Are you sure you want to delete this Message Category?")) {
            apiRequest(
                `${appConfig.API_URL}/e-communication/category/delete?categoryid=${this.state.categoryid}`
            ).then(
                () => {
                    if (this.props.deleteAction) {
                        this.props.deleteAction();
                    }
                    this._closeModal();
                }
            );
        }
    }

    _submit(event: React.FormEvent) {
        event.preventDefault();
        if (this.state.submit_loading) {
            return;
        }
        if (this.state.save_protect) {
            return;
        }
        let config = this.state;

        let postObj: dynamic = {
            cid: config.cid,
            title: config.title,
            description: config.description,
            CreatedOn: config.CreatedOn,
            lang: config.lang,
            status: config.status,
        };

        var url = "e-communication/category/upsert";
        var action = "created";
        if (config.type == "UPDATE") {
            postObj.categoryid = config.categoryid;
            action = "updated";
        }

        // validate input
        if (!postObj.title || postObj.title.length == 0) {
            createAlert("Please enter a title.");
        } else if (!postObj.description || postObj.description.length == 0) {
            createAlert("Please enter a description.");
        } else if (!postObj.status || !containValue(CATEGORY_STATUS, postObj.status)) {
            createAlert("Please select a status.");
        } else {
            var formData = new FormData();
            Object.keys(postObj).forEach(function(key) {
                if (postObj[key] != null && postObj[key] != "") {
                    formData.append(key, postObj[key]);
                }
            });
            this.setState(
                { save_protect: true, submit_loading: true },
                () => {
                    apiFormDataPost(`${appConfig.API_URL}/${url}`, formData)
                        .then(
                            data => {
                                if (data.valid) {
                                    createAlert(`Message Category has been ${action}`, `success`);
                                    if (this.props.updateAction) {
                                        this.props.updateAction();
                                    }
                                } else {
                                    createAlert(data.error, `error`);
                                }
                                return data;
                            }
                        )
                        this.setState({ submit_loading: false, save_protect: false });
                        this._closeModal();
                }
            );
        }
    }

    _closeModal() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
        createAlert('The modal for adding a new communication category has been successfully closed', 'success');
    }

    _renderDivider(type: string) {
        switch (type) {
            case "vertical":
                return (
                    <div
                        style={{
                            flexBasis: "10%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative"
                        }}
                    >
                        <span
                            style={{
                                background: "#fff",
                                zIndex: 2,
                                padding: "5px"
                            }}
                        >
                            OR
                        </span>
                        <hr
                            style={{
                                border: 0,
                                borderLeft: "1px solid #e5e5e5",
                                position: "absolute",
                                top: "0",
                                left: "50%",
                                margin: 0,
                                height: "100%",
                                zIndex: 1
                            }}
                        />
                    </div>
                );
            default:
                return (
                    <div
                        style={{
                            flexBasis: "10%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative"
                        }}
                    >
                        <span
                            style={{
                                background: "#fff",
                                zIndex: 2,
                                padding: "5px"
                            }}
                        >
                            OR
                        </span>
                        <hr
                            style={{
                                border: 0,
                                borderTop: "1px solid #e5e5e5",
                                position: "absolute",
                                top: "50%",
                                left: "0",
                                margin: 0,
                                width: "100%",
                                zIndex: 1
                            }}
                        />
                    </div>
                );
        }
    }

    _renderAlt() {
        if (this.state.submit_loading) {
            return (
                <div>
                    <h3
                        style={{
                            textAlign: "center"
                        }}
                    >
                        Submitting request...
                    </h3>
                    <Spinner />
                </div>
            );
        }
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flexBasis: "45%",
                    position: "relative",
                    justifyContent: "center",
                    zIndex: 0
                }}
            >
                <Button onClick={() => this.setState({ panel: 1, source: "user" })}>New Message Category</Button>
            </div>
        );
    }

    _createStatusOptions() {
        let items: JSX.Element[] = [];
        for (let item of CATEGORY_STATUS) {
            items.push(
                <option key={item.value} value={item.value}>
                    {item.name}
                </option>
            );
        }
        return items;
    }

    _createLanguageOptions() {
        let items: JSX.Element[] = [];
        const langs = {en: "English", fr: "French"};
        for (let item in langs) {
            items.push(
                <option key={item} value={item}>
                    {langs[item]}
                </option>
            );
        }
        return items;
    }

    _renderDetailsPanel() {
        return (
            <form onSubmit={this._submit}>
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label className="form__label">Title:&nbsp;
                            <Tooltip>The title of the email category shown to the end user to filter the message visibility.</Tooltip>
                        </label>
                        <input
                            placeholder="Title"
                            type="text"
                            className="form__value"
                            onChange={event => this._onFieldChange("title", event.target.value)}
                            maxLength={35}
                            value={this.state.title}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label className="form__label">Description:&nbsp;
                            <Tooltip>The description of the email category. Mainly use for the console user to search in category listing.</Tooltip>
                        </label>
                        <textarea
                            placeholder="Description"
                            className="form__value"
                            onChange={event => this._onFieldChange("description", event.target.value)}
                            style={{ minHeight: "50px" }}
                            value={this.state.description}
                        />
                    </div>
                </div>
                {
                    this.state.multiLangSupport &&
                    <div className="form__row">
                        <div className="form__group">
                            <label className="form__label">Language:&nbsp;
                                <Tooltip>Allows author to specify the language for the message category.</Tooltip>
                            </label>
                            <div className="select__wrapper">
                                <select value={this.state.lang} onChange={event => this._onFieldChange("lang", event)}>
                                    {this._createLanguageOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                }
                <div className="form__row">
                    <div className="form__group">
                        <label className="form__label">Status:&nbsp;
                            <Tooltip>The status must be set to "Active" in order to visible to the end user for message management.</Tooltip>
                        </label>
                        <div className="select__wrapper">
                            <select value={this.state.status} onChange={event => this._onFieldChange("status", event)}>
                                {this._createStatusOptions()}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form__row">
                    <div
                        className="form__group form__group--full"
                        style={{
                            marginTop: "10px",
                            flexDirection: "row"
                        }}
                    >
                        <Link onClick={this._closeModal} style={{ textDecoration: "underline" }}>
                            Cancel
                        </Link>
                        <Button
                            style={{ marginLeft: this.state.artid ? "10px" : "auto" }}
                            type="submit"
                            disabled={this.state.save_protect}
                            value={this.state.modal_button}
                        />
                    </div>
                </div>
            </form>
        );
    }

    render() {
        const { open, modal_title } = this.state;
        return (
            <Modal show={open} onHide={this._closeModal} aria-labelledby="ModalHeader">
                <Modal.Header closeButton={true}>
                    <Modal.Title id="ModalHeader">{modal_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this._renderDetailsPanel()}</Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(AddECommunicationCategory);
