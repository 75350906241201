import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeading from "../../molecules/PageHeading";
import DomainFilter from "../../molecules/DomainFilterOld";
import { Wrapper, Row } from "../../atoms/Layout/";
import { doGetClient, createAlert } from "../../../actions";
import CreatableInput from "../../atoms/CreatableInput";
import Tooltip from "../../atoms/Tooltip";
import { apiRequest } from "../../../utils/Helpers";
import appConfig from "../../../../config/config.dev";

class DomainFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cid: props.user.cid,
            client: {}
        };

        doGetClient(this.state.cid).then(client => {
            if (client) {
                this.setState({ client });
            }
        });
    }

    setAllowedComposeTerms = (terms) => {
        apiRequest(
            `${appConfig.API_URL}/setAllowedComposeTerms`,
            "POST",
            { cid: this.state.cid, allowedComposeTerms: terms })
        .then(data => {
            if ("valid" in data && !data.valid) {
                createAlert(data.error, `error`);
                return;
            }
            createAlert(`Successfully updated the list of allowed terms for composed articles.`, `success`);
            doGetClient(this.state.cid).then(client => {
                if (client) {
                    this.setState({ client });
                }
            });
        });
    }

    render() {
        const { client } = this.state;
        return (
            <Wrapper id="filterPage">
                <PageHeading title={`Global Domain Filters`} />
                <DomainFilter filterType="PROF" cid={this.state.cid} />
                <DomainFilter filterType="PERS" cid={this.state.cid} />
                {client?.composeBlockedTerms && (
                    <Row>
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5 aria-hidden="true">
                                        {"Allowed Terms for Composed Articles"}{" "}
                                        <Tooltip icon="fa-question-circle">
                                            If you have "Apply Global Blocked Terms to Composed Posts" enabled in your client settings, you can add terms here that will be exempt from the rule and be allowed in composed articles.
                                        </Tooltip>
                                    </h5>
                                </div>
                                <div className="ibox-content">
                                    <CreatableInput
                                        id="allowedComposeTerms"
                                        placeholder={"Please enter some terms that should be allowed in composed articles"}
                                        value={client.allowedComposeTerms || []}
                                        onUpdate={items => this.setAllowedComposeTerms(items)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                )}
            </Wrapper>
        );
    }
}

DomainFilters.allowSuper = true;
DomainFilters.allowApiAdmin = true;
DomainFilters.allowClientAdmin = true;
DomainFilters.allowAdmin = false;
DomainFilters.allowCurator = false;
DomainFilters.allowReports = false;

const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients });

export default connect(mapStateToProps)(DomainFilters);
