import React, { Component } from "react";
import { connect } from "react-redux";
import { Creatable } from "react-select";
import { UtilActions } from "../../../actions";
import { doSearchArticles, doGetReduxStreams } from "../../../actions/lists";
import { doInsertStream, doUpdateStream } from "../../../actions/inserts";
import { getLanguages, apiRequest, getMultiSelectValues } from "../../../utils/Helpers";
import ArticleCard from "../../molecules/ArticleCard";
import Spinner from "../../atoms/Spinner";
import Industries from "../../atoms/Industries";
import PageHeading from "../../molecules/PageHeading";
import { Search as pageSearch } from "../../../utils/Models";
import DomainFilter from "../../molecules/DomainFilterOld";
import Button from "../../atoms/Button";
import { Wrapper, Row } from "../../atoms/Layout/";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import CreatableInput from "../../atoms/CreatableInput";
import Link from "../../atoms/Link";
import SearchPlaceHolder from "../../atoms/SearchPlaceHolder";
import { withLastLocation } from 'react-router-last-location';

class Search extends Component {
    constructor(props) {
        super(props);
        this._onFieldChange = this._onFieldChange.bind(this);
        this._search = this._search.bind(this);
        this._onFieldObjChange = this._onFieldObjChange.bind(this);
        this.updateSID = this.updateSID.bind(this);
        this._delete = this._delete.bind(this);

        let client = null;
        if (
            props.user.cid &&
            (props.clients || {})[
                (
                    props.user || {
                        cid: ""
                    }
                ).cid
            ]
        ) {
            client = props.clients[props.user.cid];
        }
        let sid = "";

        if (props.match.params.sid) {
            sid = props.match.params.sid;
        }
        this.state = {
            multiTerms: [],
            terms: [],
            articles: [],
            articlesLoading: false,
            advancedOptions: false,
            history: false,
            selectedSearch: "",

            client: client,
            sid: sid,

            categories: [],
            countries: [],
            title: "",
            searchType: "PROF",
            lang: "en",
            location: "",
            defaultStream: "",
            compliant: false,
            industry: "internet",
            filterType: "NONE",
            filters: {},
            globalFilters: true,
            start: 0,
            since: 7,
            disabled: false,
            type: "",
            sharingPerm: "",
            streamWithGlblFilter: false,
            showSearchResults: false
        };
        this.baseState = { ...this.state };
    }
    componentDidMount() {
        const self = this;
        const previousState = localStorage.getItem("contentSearchPage");
        if (previousState) {
            self.setState({ ...JSON.parse(previousState) }, () => {
                // always refresh streams in case user added new stream
                doGetReduxStreams(self.state.client.cid).then(streams => {
                    self.setState({ streams });
                });
            });
            return;
        }
        const { sid, client, lang } = this.state;
        this.updateSID(sid, client);
        UtilActions.doGetCountries(lang).then(countries => {
            this.setState({ countries });
        });
    }

    componentWillUnmount() {
        // handing to other section
        if (this.props.history?.location?.pathname?.indexOf("content") === -1) {
            localStorage.removeItem("contentSearchPage");
        } else {
            localStorage.setItem("contentSearchPage", JSON.stringify(this.state));
        }
    }

    async updateSID(sid, client) {
        if (client && client.cid) {
            let self = this;
            self.setState({ articlesLoading: true });
            await doGetReduxStreams(this.state.client.cid).then(streams => {
                let contentSearch = {};
                let state = {
                    streams: streams
                };
                if (sid && streams[sid]) {
                    let contentSearch = streams[sid];
                    let terms = [];
                    contentSearch.terms.forEach(term => {
                        if (term.checked) {
                            terms.push(term);
                        }
                    });
                    state.sid = sid;
                    state.filterType =
                        contentSearch.filters && Object.keys(contentSearch.filters).length > 0
                            ? "CUSTOM"
                            : contentSearch.globalFilters
                                ? "GLOBAL"
                                : "NONE";
                    UtilActions.doGetCountries(contentSearch.lang).then(countries => {
                        this.setState({ countries });
                    });
                    state.articlesLoading = false;
                    state.filters = contentSearch.filters;
                    state.terms = terms;
                    state.start = 0;
                    state.since = contentSearch.since;
                    state.defaultStream = contentSearch.defaultStream;
                    state.location = contentSearch.location;
                    state.disabled = true;
                    state.streamWithGlblFilter = false;
                    if (sid && state.filterType == "GLOBAL" && streams[sid].isCustom) {
                        state.streamWithGlblFilter = true;
                        state.advancedOptions = false;
                    }
                    state.articles = [];
                    state.showSearchResults = false;
                    state.articles.length = 0;
                    self.setState(Object.assign(contentSearch, state));
                } else {
                    state.sid = "";
                    state.articlesLoading = false;
                    state.terms = [];
                    state.title = "";
                    state.searchType = "PROF";
                    state.lang = "en";
                    state.location = "";
                    state.defaultStream = "";
                    state.compliant = false;
                    state.industry = "internet";
                    state.filterType = "GLOBAL";
                    state.isCustom = false;
                    state.filters = {};
                    state.start = 0;
                    state.since = 7;
                    state.disabled = false;
                    state.articles = [];
                    state.streamWithGlblFilter = false;
                    self.setState(state);
                }
            });
        }
    }

    _delete(cid, sid) {
        if (cid && sid) {
            apiRequest(`${appConfig.API_URL}/deleteStream?cid=${cid}&sid=${sid}`).then(data => {
                createAlert(`Sucessfully removed search`, `success`);
                this.updateSID("", this.state.client);
            });
        }
    }

    _save(event) {
        const { client } = this.state;
        let terms = [];
        this.state.terms.forEach(t => {
          if (typeof t == "object" && "term" in t) {
            terms.push(t);
          } else {
            terms.push({ term: t, checked: true });
          }
        });
        if (this.state.sid) {
          let s = new pageSearch(
            Object.assign(this.state, {
              active: true,
              terms: terms
            })
          );
          doUpdateStream(client.cid, s).then(() => {
            this._search();
            createAlert('Search updated successfully', 'success');
          }).catch(() => {
            createAlert('Failed to update search', 'error');
          });
        } else {
          var title = prompt("Please enter a title for this search so you remember it later");

          if (title != null) {
            let s = new pageSearch(
              Object.assign(this.state, {
                active: true,
                terms: terms,
                contentSearch: true,
                title: title
              })
            );
            doInsertStream(client.cid, s).then(d => {
              if (d.valid && d.sid) {
                this.updateSID(d.sid, client).then(() => {
                  this._search();
                  createAlert('Search saved successfully', 'success');
                }).catch(() => {
                  createAlert('Failed to save search', 'error');
                });
              } else {
                this._search();
                createAlert('Failed to save search', 'error');
              }
            }).catch(() => {
              createAlert('Failed to save search', 'error');
            });
          }
        }
    }

    _search() {
        const {
            client,
            searchType,
            lang,
            location,
            industry,
            terms,
            tcChecked,
            compliant,
            filterType,
            filters,
            start,
            since,
            type,
            sharingPerm,
            categories
        } = this.state;
        let self = this;
        this.setState({ articlesLoading: true, showSearchResults: true });
        doSearchArticles(
            client.cid,
            searchType,
            lang,
            type,
            sharingPerm,
            location,
            industry,
            terms,
            client.tcChecked,
            compliant,
            filterType,
            filters || {},
            start,
            since,
            categories
        ).then(data => {
            self.setState({ articles: data, articlesLoading: false }, () => localStorage.setItem("contentSearchPage", JSON.stringify(this.state)));
        });
        createAlert('Search filter updated', 'success');
    }
    async _onFieldChange(field, event, callback = () => {}) {
        let val = event && event.target ? event.target.value : event;
        let state = { [field]: val };
        if (field == "sid") {
            callback = (async () => await this.updateSID(val, this.state.client))();
        } else if (field == "lang") {
            await UtilActions.doGetCountries(val).then(countries => {
                this.setState({ countries });
            });
        }
        state.articles = [];
        state.showSearchResults = false;
        state.articles.length = 0;
        this.setState(state, () => callback);
    }

    _onFieldObjChange(fieldObj) {
        this.setState(fieldObj);
    }
    _clearFilters() {
        if (localStorage.hasOwnProperty("contentSearchPage")) {
            localStorage.removeItem("contentSearchPage");
        };
        this.setState(this.baseState, () => {
            UtilActions.doGetCountries("en").then(countries => {
                this.setState({ countries });
            });
        });
        createAlert('Filters have been cleared', 'success')
    }
    _renderArticles() {
        const { sid, streams, client, articles, articlesLoading, defaultStream, showSearchResults } = this.state;
        let stream = {};
        if (streams) {
            stream = streams[sid];
            if (defaultStream) {
                stream = streams[defaultStream];
            }
        }

        // no articles to show before hitting search button
        if (
            (!articles || !articles.length) &&
            !showSearchResults
        ) {
            return <SearchPlaceHolder message={`To view this information, please adjust the filters above and click "Search"`} />;
        }

        return (
            <div className="article-card__wrapper">
                {articlesLoading && <Spinner />}
                {!articlesLoading &&
                    articles &&
                    articles.length > 0 &&
                    articles.map((article, index) => (
                        <ArticleCard
                            key={article.Link}
                            stream={stream}
                            article={article}
                            client={client}
                            canApprove={true}
                            canEdit={true}
                            editAction={() => this._search()}
                            modal_button_label={`Approve Article`}
                        />
                    ))}
            </div>
        );
    }

    _renderAdvancedOptions() {
        if(this.state.location == undefined){
            this.state.location = "";
        }
        const { advancedOptions, countries, location } = this.state;
        if (!advancedOptions) {
            return;
        }
        if (this.state.searchType == "COMPANY") {
            return [
                <div key="filter_options_7" className="table__filters__option">
                    <label htmlFor="type">Type:</label>
                    <div className="select__wrapper">
                        <select
                            id="type"
                            className="filters__search"
                            value={this.state.type}
                            onChange={event => this._onFieldChange("type", event)}
                        >
                            <option value="">All</option>
                            <option value="HTML">Article</option>
                            <option value="PDF">PDF</option>
                            <option value="IMAGE">Image/GIF</option>
                            <option value="VIDEO">Video</option>
                            <option value="TEXT">Text</option>
                        </select>
                    </div>
                </div>,
                <div key="filter_options_8" className="table__filters__option">
                    <label htmlFor="sharingPerm">Sharing Permissions:</label>
                    <div className="select__wrapper">
                        <select
                            id="sharingPerm"
                            className="filters__search"
                            value={this.state.sharingPerm}
                            onChange={event => this._onFieldChange("sharingPerm", event)}
                        >
                            <option value="">All</option>
                            <option value="social">Social</option>
                            <option value="email">Email</option>
                            <option value="sms">SMS</option>
                            <option value="readOnly">Read Only</option>
                        </select>
                    </div>
                </div>,
            ];
        } else if (!this.state.isCustom) {
            return [
                <div key="filter_options_1" className="table__filters__option">
                    <label htmlFor="defaultStream">Default Approved Stream:</label>
                    <div className="select__wrapper">
                        <select
                            id="defaultStream"
                            className="filters__search"
                            value={this.state.defaultStream}
                            onChange={event => this._onFieldChange("defaultStream", event)}
                        >
                            <option value="">None</option>
                            {this.state.streams &&
                                Object.keys(this.state.streams).sort((a, b) => {
                                    return this.state.streams[a].title.localeCompare(this.state.streams[b].title)
                                    }).map(sid => {
                                    let s = this.state.streams[sid];
                                    if (
                                        s.active &&
                                        ((s.contentSearch && s.isCustom) ||
                                            (!s.contentSearch && s.isApprovalRequired && s.searchType != "CORP"))
                                    ) {
                                        return (
                                            <option key={s.sid} value={s.sid}>
                                                {s.title}
                                            </option>
                                        );
                                    }
                                })}
                        </select>
                    </div>
                </div>,
                <div key="filter_options_3" className="table__filters__option">
                    <label htmlFor="location">Location:</label>
                    <div className="select__wrapper">
                        <select
                            id="location"
                            onChange={event => {
                                this._onFieldChange("location", event.target.value);
                            }}
                            value={location}
                        >
                            <option value="">All</option>
                            {countries.map(countries => {
                                return (
                                    <option key={countries.code} value={countries.code}>
                                        {countries.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>,
                <div key="filter_options_4" className="table__filters__option">
                    <label htmlFor="categories">Publication Categories:</label>
                    <select
                        id="categories"
                        multiple="multiple"
                        value={this.state.categories}
                        onChange={event => {
                            var options = event.target.options;
                            var value = [];
                            for (var i = 0, l = options.length; i < l; i++) {
                                if (options[i].selected) {
                                    value.push(options[i].value);
                                }
                            }
                            this._onFieldChange("categories", value);
                        }}
                    >
                        <option value="Arts Entertainment">Arts Entertainment</option>
                        <option value="Business">Business</option>
                        <option value="Computer Internet">Computer Internet</option>
                        <option value="Health">Health</option>
                        <option value="Law">Law</option>
                        <option value="Lifestyle">Lifestyle</option>
                        <option value="Marketing">Marketing</option>
                        <option value="News Media">News Media</option>
                        <option value="Politics">Politics</option>
                        <option value="Recreation Sports">Recreation Sports</option>
                        <option value="Science Technology">Science Technology</option>
                        <option value="Society Culture">Society Culture</option>
                    </select>
                </div>,
                <div key="filter_options_5" className="table__filters__option">
                    <label htmlFor="industry">Industry:</label>
                    <div className="select__wrapper">
                        <Industries id="industry" value={this.state.industry} change={e => this._onFieldChange("industry", e)} />
                    </div>
                </div>,
                <div key="filter_options_6">
                        <div className="table__filters__option">
                            <label htmlFor="filters">Filters:</label>
                            <div className="select__wrapper">
                                <select
                                    id="filters"
                                    value={
                                        this.state.globalFilters ? "GLOBAL" : this.state.filters == null ? "NONE" : "CUSTOM"
                                    }
                                    onChange={e => {
                                        let obj = {};
                                        const value = getMultiSelectValues(e.target);
                                        if (!value.length) {
                                            return;
                                        }
                                        switch (value[0]) {
                                            case "GLOBAL":
                                                obj.globalFilters = true;
                                                obj.filters = null;
                                                break;
                                            case "CUSTOM":
                                                obj.globalFilters = false;
                                                obj.filters = {};
                                                break;
                                            case "NONE":
                                                obj.globalFilters = false;
                                                obj.filters = null;
                                                break;
                                        }

                                        obj.filterType = value[0];

                                        obj.articles = [];
                                        obj.showSearchResults = false;
                                        obj.articles.length = 0;

                                        this._onFieldObjChange(obj);
                                    }}
                                    aria-label="Filter selection"
                                >
                                    <option value="GLOBAL">Global</option>
                                    <option value="CUSTOM">Custom</option>
                                    <option value="NONE">None</option>
                                </select>
                            </div>
                        </div>
                </div>
            ];
        }
    }
    render() {
        let termOptions = [];
        if (this.state.terms) {
            this.state.terms.forEach(term => {
                termOptions.push({ value: term.term, label: term.term });
            });
        }

        return (
            <Wrapper id="searchPage">
                <PageHeading title="Search" />
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <div className="table__filters">
                                    {this.state.streams && (
                                        <div className="table__filters__option">
                                            <label htmlFor="savedSearchStream">Saved Search/Stream:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="savedSearchStream"
                                                    className="filters__search"
                                                    value={this.state.sid}
                                                    onChange={event => this._onFieldChange("sid", event)}
                                                >
                                                    <option value="">None</option>
                                                    <optgroup label="Saved Streams">
                                                        {Object.keys(this.state.streams).sort((a, b) => {
                                                        return this.state.streams[a].title.localeCompare(this.state.streams[b].title);
                                                        }).map(sid => {
                                                            let s = this.state.streams[sid];
                                                            if (
                                                                s.isCustom &&
                                                                s.contentSearch &&
                                                                s.terms &&
                                                                s.terms.length > 0
                                                            ) {
                                                                return (
                                                                    <option key={s.sid} value={s.sid}>
                                                                        {s.title}
                                                                    </option>
                                                                );
                                                            }
                                                        })}
                                                    </optgroup>
                                                    <optgroup label="Saved Searches">
                                                        {Object.keys(this.state.streams).sort((a, b) => {
                                                        return this.state.streams[a].title.localeCompare(this.state.streams[b].title);
                                                        }).map(sid => {
                                                            let s = this.state.streams[sid];
                                                            if (
                                                                !s.isCustom &&
                                                                s.contentSearch &&
                                                                s.terms &&
                                                                s.terms.length > 0
                                                            ) {
                                                                return (
                                                                    <option key={s.sid} value={s.sid}>
                                                                        {s.title}
                                                                    </option>
                                                                );
                                                            }
                                                        })}
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.searchType != "COMPANY" && <div className="table__filters__option">
                                        <label htmlFor="dateRange">Date Range:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="dateRange"
                                                className="filters__search"
                                                value={this.state.since}
                                                onChange={event => this._onFieldChange("since", event)}
                                            >
                                                <option value="7">1 Week</option>
                                                <option value="21">3 Weeks</option>
                                                <option value="30">1 Month</option>
                                                <option value="90">3 Months</option>
                                                <option value="180">6 Months</option>
                                                <option value="365">1 year</option>
                                            </select>
                                        </div>
                                    </div>}
                                    <div className="table__filters__option">
                                        <label htmlFor="terms">Terms:</label>
                                        <Creatable
                                            id="terms"
                                            aria-label="terms"
                                            classNamePrefix="select"
                                            isMulti={true}
                                            onChange={val => {
                                                let terms = [];
                                                if (val.length > 10 && val.length >= this.state.terms.length) {
                                                    createAlert(
                                                        "You have reached the limit of 10 terms maximum",
                                                        "error"
                                                    );
                                                    return;
                                                }
                                                if (val) {
                                                    val.forEach(v => {
                                                        if (v.value) {
                                                            terms.push({ term: v.value, checked: true });
                                                        }
                                                    });
                                                }
                                                if (terms) {
                                                    this._onFieldChange("terms", terms, () => {});
                                                }
                                            }}
                                            value={termOptions}
                                            onInputKeyDown={event => {
                                                switch (event.keyCode) {
                                                    case 13:
                                                        if (event.target) {
                                                            $(event.target).blur();
                                                        }
                                                        event.preventDefault();
                                                        break;
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="table__filters__option">
                                        <label htmlFor="language">Language:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="language"
                                                className="filters__search"
                                                value={this.state.lang}
                                                onChange={event => this._onFieldChange("lang", event)}
                                            >
                                                {getLanguages("al").map(function(val, i) {
                                                    return (
                                                        <option key={val.code} value={val.code}>
                                                            {val.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div key="filter_options_0" className="table__filters__option">
                                        <label htmlFor="searchType">Search Type:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="searchType"
                                                className="filters__search"
                                                value={this.state.searchType}
                                                onChange={event => this._onFieldChange("searchType", event)}
                                            >
                                                <option value="PROF">Professional</option>
                                                <option value="PERS">Personal</option>
                                                {this.state.client.indexedLibrary && this.state.client.indexedLibrary.enabled &&  <option value="COMPANY">Company</option>}
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{ paddingLeft: "15px", paddingTop: "12px" }}>
                                        <label className="" />
                                        <div style={{ display: "flex" }}>
                                            <Link style={{ textDecoration: "underline", color: "#152733" }}
                                                onClick={()=> this._clearFilters()}
                                            >Clear Filter</Link>
                                        </div>
                                    </div>
                                    <div
                                        className="table__filters__option"
                                        style={{
                                            marginLeft: "auto"
                                        }}
                                    >
                                        <h5>&nbsp;</h5>

                                        <Button
                                            style={{
                                                marginBottom: "0"
                                            }}
                                            onClick={() => this._search()}
                                        >
                                            Search
                                        </Button>
                                        {!this.state.isCustom && (
                                            <Button
                                                style={{
                                                    background: "#78ca78",
                                                    marginBottom: "0",
                                                    marginLeft: "10px"
                                                }}
                                                onClick={event => this._save(event)}
                                            >
                                                {this.state.sid ? "Update" : "Save"}
                                            </Button>
                                        )}
                                        {this.state.sid && !this.state.isCustom && (
                                            <Button
                                                style={{
                                                    background: "#b94646",
                                                    marginBottom: "0",
                                                    marginLeft: "10px"
                                                }}
                                                onClick={event => this._delete(this.state.client.cid, this.state.sid)}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                {this.state.client && !this.state.streamWithGlblFilter && ( <div>
                                    <div className="table__filters">
                                    <Link
                                        style={{
                                            paddingTop: "15px",
                                            textDecoration: "underline"
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                advancedOptions: !this.state.advancedOptions
                                            })
                                        }
                                    >Advanced <span className={this.state.advancedOptions ? "icon-up" : "icon-down" }></span></Link>
                                    </div>
                                </div>)}
                                {this.state.advancedOptions && (
                                    <hr
                                        style={{
                                            margin: "10px 0"
                                        }}
                                    />
                                )}
                                <div className="table__filters" style={{ paddingTop: "15px" }}>
                                        {this.state.advancedOptions && this._renderAdvancedOptions()}
                                </div>
                                {((this.state.advancedOptions && this.state.isCustom && !this.state.streamWithGlblFilter) || (this.state.advancedOptions &&
                                    !this.state.globalFilters &&
                                    this.state.filters != null &&
                                    this.state.searchType != "COMPANY")) && (
                                        <div className="table__filters">
                                            <div className="table__filters__option">
                                                <div>
                                                    <h5>Custom Filters:</h5>
                                                    <DomainFilter
                                                        saveFilters={val => this._onFieldChange("filters", val)}
                                                        filterType={this.state.searchType}
                                                        cid={this.state.client.cid}
                                                        filterDisplay={true}
                                                        lang={this.state.lang}
                                                        filters={this.state.filters}
                                                        disabled={this.state.filters && this.state.filters.tags && this.state.filters.tags.length > 0 }
                                                    />
                                                </div>
                                                <div>
                                                    <h5>Tags:</h5>
                                                    <CreatableInput
                                                        className="form__value"
                                                        onUpdate={(items) => {
                                                            if (items) {
                                                                this.setState({
                                                                    filters: {
                                                                        ...this.state.filters,
                                                                        tags: items
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        value={this.state.filters && this.state.filters.tags}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <hr
                                    style={{
                                        margin: "20px 0"
                                    }}
                                />{" "}
                                {this._renderArticles()}
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
}

Search.allowSuper = true;
Search.allowApiAdmin = false;
Search.allowClientAdmin = true;
Search.allowAdmin = false;
Search.allowCurator = true;
Search.allowReports = false;
const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients });

export default connect(mapStateToProps)(withLastLocation(Search));
