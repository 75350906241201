import React, { Component } from "react";
import AutoSuggest from "./AutoSuggest";
import Button from "../atoms/Button";
import CreatableInput from "../atoms/CreatableInput";
import Tooltip from "../atoms/Tooltip";
import { createAlert, doGetDomains, doGetDomainCategories } from "../../actions/";
import CopyButton from "../atoms/CopyButton";

interface IDomainFilterProps {
    stream: ServerTypes.Console.IStream | null;
    client: Mongo.IClient;
    user: Mongo.clientAdmin;
    lang: string | null;
    onSave: (included: boolean, filters: Mongo.IFilterV2[], tags: string[], bTerms: string[]) => void;
}
interface IDomainFilterState {
    stream: ServerTypes.Console.IStream | null;
    client: Mongo.IClient;
    domain: string;
    category: string;
    included: boolean;
    allowlistOrBlocklist: boolean;
    autoFillDomains: string[];
    categories: string[];
    tags: string[];
    filters: Mongo.IFilterV2[];
    bTerms: string[];
    canIncludeDomain: boolean;
    canExcludeDomain: boolean;
    lang: string | null;
}
export default class DomainFilter extends Component<IDomainFilterProps, IDomainFilterState> {
    constructor(props: IDomainFilterProps) {
        super(props);
        this.state = {
            stream: props.stream,
            client: props.client,
            lang: props.lang || (props.stream || { lang: "en" }).lang,
            domain: "",
            category: "",
            allowlistOrBlocklist: true,
            included: true,
            autoFillDomains: [],
            tags: [],
            bTerms: [],
            categories: [],
            canIncludeDomain: true,
            canExcludeDomain: true,
            ...(this._parseFilters(props.stream) || { filters: [] })
        };
    }
    componentWillReceiveProps(newProps: IDomainFilterProps) {
        const update = {
            ...(newProps.stream !== this.state.stream
                ? {
                    stream: newProps.stream,
                    ...this._parseFilters(newProps.stream || this.state.stream)
                }
                : null),
            ...(newProps.client !== this.state.client
                ? {
                    client: newProps.client
                }
                : null)
        };
        if (Object.keys(update).length) {
            this.setState(update as IDomainFilterState);
        }
    }

    async getDomains(domain: string) {
        if (!domain || domain.length < 3) {
            return;
        }
        //checking the character limit since core-api supports only max 50 characters
        if (domain.length > 50) {
            createAlert(`Maximum allowed character limit is 50`, "error");
            return [];
        } else {
        const response = await doGetDomains(domain, this.state.lang || "en");
        if (response instanceof Array) {
            this.setState({ autoFillDomains: response });
        }
            if (response === null) {
                return [];
            }
        return response;
    }
    }
    async getDomainCategories(domain: string) {
        if (!domain || domain.length < 3) {
            return;
        }
        const response = await doGetDomainCategories(domain, this.state.lang || "en");
        if (response instanceof Array) {
            this.setState({ categories: response });
        }
        return response;
    }

    _parseFilters(stream: ServerTypes.Console.IStream | null) {
        if (!stream || !stream.filters) {
            return { filters: [] };
        }
        return {
            filters: this._mutateFilters(stream.filters),
            bTerms: stream.filters.bTerms || [],
            allowlistOrBlocklist: stream.filters.included !== undefined ? stream.filters.included : true,
            ...(stream.filters.tags ? { tags: stream.filters.tags } : null),
            ...((!stream.filters.wDomains || stream.filters.wDomains.length == 0) &&
                stream.filters.bDomains &&
                stream.filters.bDomains.length
                ? { allowlistOrBlocklist: false }
                : null)
        };
    }

    _mutateFilters(filters: Mongo.IFilter) {
        const { wDomains, bDomains, domains } = filters;
        /*
         * Pull in the old allowlist/blocklist domains and convert them to the new v2 format
         * Then pull in the v2 format domains and sort them by [...[included, domain name], ...[excluded, domain name]]
         */
        return [
            ...(wDomains && wDomains.length ? wDomains.map(domain => ({ domain, tags: [], included: true })) : []),
            ...(bDomains && bDomains.length ? bDomains.map(domain => ({ domain, tags: [], included: false })) : []),
            ...(domains && domains.length ? domains : [])
        ].sort((a, b) => {
            const domainCompare = a.domain
                .toLowerCase()
                .trim()
                .localeCompare(b.domain.toLowerCase().trim());
            let tagCompare = 0;
            if (!a.tags!.length) tagCompare = -1;
            else if (!b.tags!.length) tagCompare = 1;
            else if (a.tags!.length && b.tags!.length) {
                tagCompare = a
                    .tags![0].toLowerCase()
                    .trim()
                    .localeCompare(b.tags![0].toLowerCase().trim());
            }
            return a.included == b.included ? (domainCompare == 0 ? tagCompare : domainCompare) : a.included ? -1 : 1;
        }) as Mongo.IFilterV2[];
    }

    _addFilter() {
        const { domain, category, included, filters } = this.state;
        if (
            filters.filter(
                item =>
                    item.domain.toLowerCase().indexOf(domain) !== -1 &&
                    item.tags!.map(i => i.toLowerCase()).indexOf(category.toLowerCase()) !== -1
            ).length
        ) {
            this.setState({ domain: "", category: "", categories: [], included: true });
            return;
        }
        if (domain) {
            const notSupportedFilters = domain.match(/^\/\/|^.*?:(\/\/)?/);
            if (notSupportedFilters && notSupportedFilters.length) {
                createAlert(`You cannot include protocols such as ${notSupportedFilters[0]} in your filter`, "error");
                return;
            }
            if (domain[domain.length - 1] == "/") {
                createAlert(`You cannot include a domain ending in '/'.`, "error");
                return;
            }
        }
        filters.push({ domain, tags: [category], included });
        this.setState(
            {
                filters,
                domain: "",
                category: "",
                categories: [],
                included: true,
                canIncludeDomain: true,
                canExcludeDomain: true
            },
            () => this._saveFilters()
        );
    }

    _removeFilter(i: number) {
        const { filters } = this.state;
        filters.splice(i, 1);
        this.setState({ filters }, () => {
          this._saveFilters();
          this._updateIncludes();
          createAlert('Filter removed', 'success');
        });
      }

    _saveFilters() {
        if (this.props.onSave) {
            this.props.onSave(
                this.state.allowlistOrBlocklist,
                this.state.filters.map(f => ({
                    ...f,
                    ...(f.tags && f.tags.length == 1 && f.tags[0] == "" ? { tags: [] } : null)
                })),
                this.state.tags,
                this.state.bTerms
            );
        }
    }

    _updateIncludes() {
        let { canIncludeDomain, canExcludeDomain } = this.state;
        canIncludeDomain = true;
        canExcludeDomain = true;
        if (this.state.domain) {
            if (!this.state.allowlistOrBlocklist) {
                canIncludeDomain = false;
                canExcludeDomain = true;
            }
            // We already have the exact filter in the list
            else if (
                this.state.filters.filter(
                    item =>
                        item.domain.toLowerCase() == this.state.domain.toLowerCase() &&
                        ((((item.tags || []).length == 0 || item.tags![0] == "") && !this.state.category) ||
                            ((item.tags || [])!.length && item.tags![0] == this.state.category))
                ).length
            ) {
                canIncludeDomain = false;
                canExcludeDomain = false;
            }
            // include all from a domain but allow exclusion of subcategories
            else if (
                this.state.category &&
                this.state.filters.filter(
                    item =>
                        item.domain.toLowerCase() == this.state.domain.toLowerCase() &&
                        item.included &&
                        ((item.tags || []).length == 0 || item.tags![0] == "")
                ).length
            ) {
                canIncludeDomain = false;
                canExcludeDomain = true;
            }
            // we are already including a specific sub-category, so lets not allow excluding other subcategories
            else if (
                this.state.filters.filter(
                    item =>
                        item.domain.toLowerCase() == this.state.domain.toLowerCase() &&
                        item.included &&
                        (item.tags || []).length
                ).length
            ) {
                canIncludeDomain = true;
                canExcludeDomain = false;
            }
            // we dont have the domain at all and include other domains, cant exclude it
            else if (
                !this.state.filters.filter(item => item.domain.toLowerCase() == this.state.domain.toLowerCase())
                    .length &&
                this.state.filters.filter(
                    item =>
                        item.domain.toLowerCase() != this.state.domain.toLowerCase() &&
                        ((item.tags || []).length == 0 || item.tags![0] == "") &&
                        item.included
                ).length
            ) {
                canIncludeDomain = true;
                canExcludeDomain = false;
            }
        }
        this.setState({ canIncludeDomain, canExcludeDomain, included: canIncludeDomain });
    }

    render() {
        return (
            <div className="ibox">
                <div className="ibox-title">
                    <h5
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}
                    >
                        Content Filters
                        <Tooltip style={{ marginLeft: "auto" }}>
                            Filtering content by tags will not allow you to use domain filtering
                        </Tooltip>
                    </h5>
                </div>
                <div className="ibox-content">
                    <div className="form domain__filters">
                        <div className="form__row form__headers">
                            <div className="form__group domain__filters--tags" style={{ borderRight: 0 }}>
                                <label htmlFor="blocklistTerms" className="form__label">Block List Terms:</label>
                                <div
                                    className="form__value"
                                    style={{ display: "flex" }}>
                                    <div style={{ width: "100%", marginRight: "20px" }}>
                                        <CreatableInput
                                            id="blocklistTerms"
                                            onUpdate={items => this.setState({ bTerms: items }, () => this._saveFilters())}
                                            value={this.state.bTerms}
                                            placeholder={"Please enter some block list terms"}
                                        />
                                    </div>

                                    <CopyButton value={(this.state.bTerms || []).join(", ")} />
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: "10px",
                                backgroundColor: "#fefefe",
                                marginTop: "20px",
                                borderRadius: "5px",
                                border: "1px solid #ccc"
                            }}
                        >
                            <div className="form__row form__headers">
                                <div className="form__group domain__filters--tags">
                                    <label htmlFor="tags" className="form__label">Tags</label>
                                    <CreatableInput
                                        id="tags"
                                        className="form__value"
                                        onUpdate={items => this.setState({ tags: items }, () => this._saveFilters())}
                                        placeholder={"Please talk to your system administrator"}
                                        value={this.state.tags}
                                        disabled={!this.props.user.isSuper && !this.props.user.isSuperClientAdmin}
                                    />
                                </div>
                            </div>
                            <div className="domain__filters--sep">
                                <hr />
                                <span>OR</span>
                            </div>

                            <div className="form domain__filters">
                                <div className="form__row">
                                    <div className="form__group" style={{ flexBasis: "75%", border: 0 }}>
                                        &nbsp;
                                    </div>
                                    <div className="form__group" style={{ marginLeft: "auto", flexBasis: "25%" }}>
                                        <div className="select__wrapper">
                                            <select
                                                aria-label="Select Domain List"
                                                onChange={event =>
                                                    this.setState(
                                                        {
                                                            allowlistOrBlocklist: $(event.target).val() == "allowlist",
                                                            filters: []
                                                        },
                                                        () => this._saveFilters()
                                                    )
                                                }
                                                value={this.state.allowlistOrBlocklist ? "allowlist" : "blocklist"}
                                            >
                                                <option value="allowlist">Allow List Domains</option>
                                                <option value="blocklist">Block List Domains</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {this._renderHeaders()}
                                {this._renderAddNewFilter()}
                                {this._renderSavedFilters()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderHeaders() {
        return (
            <div className="form__row form__headers">
                <div className="form__group">
                    <label className="form__label">Domain</label>
                </div>
                <div className="form__group">
                    <label className="form__label">Sub-categories</label>
                </div>
                <div className="form__group">
                    <label className="form__label">Include/Exclude</label>
                </div>
                <div className="form__group">
                    <label className="form__label">&nbsp;</label>
                </div>
            </div>
        );
    }

    _renderSavedFilters() {
        const { filters, tags } = this.state;
        if (!filters || (tags && tags.length)) {
            return;
        }
        return filters.map((row, i) => (
            <div className="form__row" key={`${row}-${i}`}>
                <div className="form__group">
                    <input type="text" disabled={true} value={row.domain} />
                </div>
                <div className="form__group">
                    <select disabled={true} value={(row.tags || [""])[0]}>
                        <option value="" />
                        {(row.tags || []).map(i => (
                            <option key={i} value={i}>
                                {i}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form__group">
                    <select disabled={true} value={row.included ? "true" : "false"} >
                        <option value="true">Include</option>
                        <option value="false">Exclude</option>
                    </select>
                </div>
                <div className="form__group">
                    <Button onClick={() => this._removeFilter(i)}>Remove</Button>
                </div>
            </div>
        ));
    }

    /**
     * Return list of categories that dont match categories that we have already selected
     */
    getValidCategories() {
        return this.state.categories.filter(
            i =>
                !this.state.filters.filter(
                    ii =>
                        ii.domain.toLowerCase() == this.state.domain.toLowerCase() &&
                        (ii.tags || []).map(item => item.toLowerCase()).indexOf(i.toLowerCase()) > -1
                ).length
        );
    }

    /**
     * Only allow adding an empty category if we dont already have domains included
     */
    canAddEmptyCategory() {
        return !this.state.filters.filter(i => i.domain.toLowerCase() == this.state.domain.toLowerCase() && i.included)
            .length;
    }

    _renderAddNewFilter() {
        const validCategories = this.getValidCategories();
        const canAddEmptyCategory = this.canAddEmptyCategory();

        return (
            <div className="form__row brandPrimary__light__important--bg">
                <div className="form__group">
                    <AutoSuggest
                        id={"prefill_domains"}
                        placeholder="Please enter a domain. i.e: domain.com"
                        requestSuggestions={val => this.getDomains(val)}
                        onSuggestionSelected={(id, value) =>
                            this.setState({ domain: value }, async () => {
                                await this.getDomainCategories(value); //
                                const validCategories = this.getValidCategories();
                                if (validCategories.length && !this.canAddEmptyCategory()) {
                                    this.setState({ category: validCategories[0] }, () => this._updateIncludes());
                                } else {
                                    this._updateIncludes();
                                }
                            })
                        }
                        disabled={this.state.tags && this.state.tags.length ? true : false}
                        value={this.state.domain}
                    />
                </div>
                <div className="form__group">
                    <div className="select__wrapper">
                        <select
                            disabled={
                                this.state.domain.length == 0 ||
                                (validCategories.length == 0 && !canAddEmptyCategory) ||
                                !this.state.allowlistOrBlocklist
                            }
                            onChange={e => this.setState({ category: e.target.value }, () => this._updateIncludes())}
                        >
                            {canAddEmptyCategory && <option value="" />}
                            {validCategories.map((i, ii) => (
                                <option key={`${i}--${ii}`} value={i}>
                                    {i}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form__group">
                    <div className="select__wrapper">
                        <select
                            disabled={
                                this.state.domain.length == 0 ||
                                !this.state.category ||
                                !this.state.allowlistOrBlocklist
                            }
                            onChange={e => this.setState({ included: e.target.value == "true" })}
                            value={this.state.included ? "true" : "false"}
                        >
                            {this.state.canIncludeDomain && <option value="true">Include</option>}
                            {this.state.canExcludeDomain && <option value="false">Exclude</option>}
                        </select>
                    </div>
                </div>
                <div className="form__group">
                    <Button
                        disabled={!this.state.domain || (!canAddEmptyCategory && !this.state.category)}
                        onClick={() => this._addFilter()}
                    >
                        Add
                    </Button>
                </div>
            </div>
        );
    }
}
