import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
import { createAlert } from "../../../actions/utils";
import { doGetReduxClient } from "../../../actions/lists";
import { doUpdateBlocklistedUrls } from "../../../actions/inserts";
import { isURL } from "../../../utils/Helpers";

interface IBlockedContentModalProps {
    onClose: () => void;
    open: boolean;
    client: Mongo.client | null;
}

interface IBlockedContentModalState {
    open: boolean;
    blocklistedUrls: Array<{ url: string; date: Date }>;
    loading: boolean;
    addUrl: string;
    client: Mongo.client | null;
}

export default class BlockedContentModal extends Component<IBlockedContentModalProps, IBlockedContentModalState> {
    urlInput: HTMLInputElement | null;
    constructor(props: IBlockedContentModalProps) {
        super(props);
        this.state = {
            open: props.open || false,
            loading: false,
            addUrl: "",
            client: props.client ? props.client : null,
            blocklistedUrls: []
        };
        this.urlInput = null;
    }

    async componentDidMount() {
        this.getBlocklistedContent();
    }

    async getBlocklistedContent() {
        const { open } = this.state;
        if (!open) {
            return;
        }
        await doGetReduxClient(this.props.client!.cid).then(() => {
            let newState = {};
            newState["addUrl"] = "";
            newState["client"] = this.props.client!;
            newState["open"] = this.props.open;
            if (this.props.client!.blocklistedUrls && this.props.client!.blocklistedUrls.length > 0) {
                newState["blocklistedUrls"] = this.props.client!.blocklistedUrls;
            }
            newState["loading"] = false;
            this.setState(newState);
        });
    }

    componentWillReceiveProps(newProps: IBlockedContentModalProps) {
        if (newProps.open !== this.props.open) {
            this.setState({ open: newProps.open }, () => this.getBlocklistedContent());
        }
    }

    close() {
        this.setState({ open: false }, () => this.props.onClose());
    }

    renderContent() {
        const { blocklistedUrls } = this.state;
        const { client } = this.props;
        if (client) {
            return (
                <div className="table-responsive">
                    <table className="table table-striped responsive" data-type="responsive">
                        <tbody>
                            <tr>
                                <td key={`row--input`}>
                                    <input
                                        id="url-input"
                                        type="text"
                                        ref={input => {
                                            this.urlInput = input;
                                        }}
                                        onChange={event =>
                                            this.setState({ addUrl: event.target.value })
                                        }
                                        placeholder="Add URL"
                                    />
                                </td>
                                <td>
                                    <Button
                                        id="newtag-btn"
                                        onClick={event => this._onUrlChange()}
                                        disabled={!this.state.addUrl || !this.state.addUrl.length}
                                    >
                                        Add
                                    </Button>
                                </td>
                            </tr>
                            {blocklistedUrls.map(
                                (bUrl, index) => {
                                    const dateTime = bUrl.date ? new Date(bUrl.date).toDateString() : "";
                                    return (
                                        <tr>
                                            <td>
                                                <span style={{ display: "flex" }} id={`url-input-${bUrl.url}`}>{bUrl.url ? bUrl.url : ""}
                                                </span>
                                            </td>
                                            <td>
                                                <span style={{ display: "flex" }}
                                                    id={`url-input-${bUrl.date}`}>{dateTime ? dateTime : ""}
                                                </span>
                                            </td>
                                            <td>
                                                <Button id={`url-remove-${dateTime}`}
                                                    onClick={event =>
                                                        this._removeUrl(event!, bUrl)
                                                    }
                                                >Remove{" "}</Button>
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>

            );
        } else return null;
    }

    _onUrlChange() {
        const { blocklistedUrls, addUrl, client } = this.state;
        let blocklistUrl = addUrl.trim();
        if (!isURL(blocklistUrl)) {
            createAlert("Please enter a valid url", "error");
            return;
        }
        // Max limit set to 200 for now
        if (blocklistedUrls.length >= 200) {
            createAlert(`Blocklisted Urls can not be more than 200`, `error`);
            return;
        }
        // check existing urls
        const isExisting = blocklistedUrls.some(articleUrl => articleUrl.url === blocklistUrl);
        // // if dup found, alert the user
        if (isExisting) {
            createAlert(`Url already exists.`, `error`);
            return;
        } else {
            blocklistedUrls.push({
                url: blocklistUrl,
                date: new Date()
            });
            this.urlInput!.value = "";
            this.urlInput?.focus();
            this.setState({ addUrl: "", blocklistedUrls });

            client!.blocklistedUrls = blocklistedUrls;
            doUpdateBlocklistedUrls(client!, true);
        }
    }

    _removeUrl(event, bUrl) {
        const { client } = this.state;
        if (event) {
          event.preventDefault();
        }
        let { blocklistedUrls } = this.state;
        const blocklistedUrlsArray = blocklistedUrls.filter((list) => list.url !== bUrl.url);
        this.setState({ blocklistedUrls: blocklistedUrlsArray }, () => {
          createAlert('URL removed from blocklist', 'success');
    });
        client!.blocklistedUrls = blocklistedUrlsArray;
        doUpdateBlocklistedUrls(client!, true);
    }

    render() {
        const { open } = this.state;
        return (
            <Modal
                className="articlePreview"
                open={open}
                closeAction={() => this.close()}
                title="Blocked Content by URL"
            >
                {this.renderContent()}
            </Modal>
        );
    }

}
