import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";
import SignIn from "../components/pages/session/SignIn";
import SSOSignIn from "../components/pages/session/SSOSignIn";
import SignOut from "../components/pages/session/SignOut";
import RecoverAccount from "../components/pages/session/RecoverAccount";
import ResetPassword from "../components/pages/session/ResetPassword";

export default class SignInContainer extends Component {
    static isPrivate = false;
    render() {
        return (
            <Switch>
                <Route path="/login/sso/:token" component={SSOSignIn} />
                <Route path="/login/sso" component={SSOSignIn} />
                <Route exact={true} path="/login/recover-account" component={RecoverAccount} />
                <Route path="/login/reset-password/:token/:email" component={ResetPassword} />
                <Route exact={true} path="/login" component={SignIn} />
                <Route exact={true} path="/logout" component={SignOut} />
            </Switch>
        );
    }
}
