import React, { Component } from "react";
import Spinner from "../atoms/Spinner";
import Button from "../atoms/Button";

interface ITableProps {
    keys: Array<string | Array<string | React.ReactElement>>;
    name: string;
    descriptions?: string[] | JSX.Element[];
    labels?: string[];
    items: dynamic;
    canSort?: (column: string) => boolean;
    onSort?: (sort: string, direction: number) => void;
    sort?: string;
    direction?: number;
    defaultSort?: dynamic<number>;
    loading?: boolean;
    canLoadMore?: boolean;
    requestLoadMore?: () => void;
    movable?: string;
    //the items props above may contain create rows, this flag indicates if there are any actual items to display
    //if leave undefined, component will still display NoRecords based on items props
    showNoRecords?: boolean;
}

interface ITableState {
    visibleDescriptions: dynamic;
    sort: string | null;
    direction: number;
}

export default class Table extends Component<ITableProps, ITableState> {
    constructor(props: ITableProps) {
        super(props);
        this._reflow = this._reflow.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this._toggleVisible = this._toggleVisible.bind(this);
        this._isVisible = this._isVisible.bind(this);

        const sort = Object.keys(props.defaultSort || {})[0] || null;
        const direction = (sort && props.defaultSort && props.defaultSort[sort]) || 1;
        this.state = {
            visibleDescriptions: {},
            sort,
            direction
        };
    }
    _reflow() {
        if (window) {
            setTimeout(() => {
                window.dispatchEvent(new Event("table_reflow"));
            }, 0);
        }
    }
    componentDidMount() {
        this._reflow();
    }
    componentWillReceiveProps(newProps: ITableProps) {
        this._reflow();
        if (newProps && newProps.descriptions && newProps.descriptions.length > 0) {
            const visible = {};
            newProps.items.forEach((item, index) => {
                if (newProps.descriptions![index] && newProps.descriptions![index] != null) {
                    visible[index] = false;
                }
            });
            this.setState({ visibleDescriptions: visible });
        }
        if (newProps && (newProps.sort !== this.props.sort || newProps.direction !== this.props.direction)) {
            this.setState({ sort: newProps.sort!, direction: newProps.direction! });
        }
    }
    _toggleVisible(index: number) {
        this._reflow();
        const { visibleDescriptions } = this.state;
        visibleDescriptions[index] = !visibleDescriptions[index];
        this.setState({ visibleDescriptions });
    }
    _isVisible(index: number) {
        return this.state.visibleDescriptions[index];
    }

    render() {
        return (
            <div className="table-responsive">
                <table className="table table-striped responsive" data-type="responsive">
                    {this.renderHeader()}
                    {this.renderBody()}
                </table>
            </div>
        );
    }

    renderSortIcons(column: string, defaultDirection = 1) {
        if (!this.props.canSort || !this.props.canSort(column)) {
            return column;
        }
        const { sort, direction } = this.state;
        if (sort == column) {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={e => this._sort(column, direction == 1 ? -1 : 1)}
                    onKeyDown={e => {
                        if (e.which === 13 || e.which === 32) {
                            this._sort(column, direction == 1 ? -1 : 1)
                        }
                    }}>
                    {column} <i
                        className={`fa ${direction == 1 ? "fa-sort-up" : "fa-sort-down"}`}
                        tabIndex={0}
                        role="img"
                        aria-label={`Sort ${direction == 1 ? "ascending" : "descending"}`}
                    />
                </span>
            );
        }
        return (
            <span
                style={{ cursor: "pointer" }}
                onClick={e => this._sort(column, defaultDirection)}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        this._sort(column, defaultDirection)
                    }
                }}>
                {column} <i
                    className="fa fa-sort"
                    tabIndex={0}
                    role="img"
                    aria-label="Sort ascending or descending"
                />
            </span>
        );
    }

    _sort(sort, direction) {
        this.setState({ sort, direction }, () => {
            if (this.props.onSort) {
                this.props.onSort(sort, direction);
            }
        });
    }

    renderHeader() {
        const { keys, descriptions } = this.props;
        if (!keys) {
            return;
        }
        return (
            <thead>
                <tr>
                    {descriptions && descriptions.length > 0 && (
                        <th tabIndex={0}>
                            <span data-type="responsive">Show More Information</span>
                        </th>
                    )}
                    {
                        keys.map(key => {
                            // Keys with tooltip will be inside an array where first item is the label and second, the React tooltip element
                            if (typeof key === "object") {
                                return (
                                    <th key={`${key[0]}`} className={key[0] as string} tabIndex={0}>
                                        {this.renderSortIcons(key[0] as string)} {key[1]}
                                    </th>
                                );
                            }
                            return (
                                <th key={`${key}`} className={key} tabIndex={0}>
                                    {this.renderSortIcons(key)}
                                </th>
                            );
                        })
                    }
                </tr>
            </thead>
        );
    }
    renderBody() {
        const { items, descriptions, loading, canLoadMore, requestLoadMore, showNoRecords } = this.props;
        if (loading && !canLoadMore) {
            return <tbody>{this.renderLoading()}</tbody>;
        }
        const values: Array<JSX.Element | string> = [];
        (items || []).forEach((row, i) => {
            values.push(row);
            if (descriptions && descriptions.length && descriptions[i] && descriptions[i] != null) {
                values.push(descriptions[i]);
            }
        });
        return (
            <tbody>
                {this.renderRows(values)}
                {canLoadMore && loading && this.renderLoading()}
                {canLoadMore && !loading && (
                    <tr>
                        <td colSpan={42} tabIndex={0}>
                            <Button onClick={requestLoadMore}>Load More</Button>
                        </td>
                    </tr>
                )}
                {(!items || items.length === 0 || showNoRecords) && (
                    <tr>
                        <td colSpan={42} id="noRecordsFound" tabIndex={0}>No records found</td>
                    </tr>
                )}
            </tbody>
        );
    }

    renderRows(values: Array<JSX.Element | string>) {
        const { descriptions, keys: k, name } = this.props;
        // Since keys with tooltip will be inside an array, extract the first element for key.
        const keys = k.map(key => {
            if (typeof key === "object") return key[0];
            return key;
        }) as string[];
        return values.map((row, i) => (
            <tr key={`row--${i}`}>
                {descriptions && descriptions.length && i % 2 === 0 && (
                    <td tabIndex={0}>
                        <Button className="btn--sm" onClick={() => this._toggleVisible(i / 2)}>
                            <i className={`fa fa-${this.state.visibleDescriptions[i / 2] ? "minus" : "plus"}`} />
                        </Button>
                    </td>
                )}
                {this.renderValue(keys, row, name, i)}
            </tr>
        ));
    }
    renderValue(keys: string[], row: Array<JSX.Element | string> | JSX.Element | string, name: string, index: number) {
        if (Array.isArray(row) && row.length > 1) {
            return row.map((item, ii) => {
                const content = item || "N/A";
                return (
                    <td className={`${keys[ii].replace(/\ /gi, "")}`} key={`row--${ii}`} tabIndex={0}>
                        <span style={{ display: "flex" }}>{content}</span>
                    </td>
                );
            });
        } else {
            if (!this.state.visibleDescriptions[(index - 1) / 2]) {
                return;
            }
            const content = row || "N/A";
            return (
                <td className="description" colSpan={42} key={`table-row-value-${name ? name : ""}-${index}-0`} tabIndex={0}>
                    {content}
                </td>
            );
        }
    }
    renderLoading() {
        return (
            <tr>
                <td colSpan={42}>
                    <Spinner />
                </td>
            </tr>
        );
    }
}
