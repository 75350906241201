import React, { Component } from "react";
import Modal from "./Modal";
import { createAlert } from "../../../actions";
import { CreatableLibrary, ICreatableLibraryValue } from "../../atoms/CreatableLibrary";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";

export default class HashtagMentionModal extends Component<
    {
        title: string;
        open?: boolean;
        client: Mongo.IClient;
        oldHashtags: string[];
        allHashtags: string[];
        oldMentions: string[];
        allMentions: string[];
        limit: number;
        update: (hashtags: string[], mentions: string[]) => void;
        close: () => void;
    },
    {
        open: boolean;
        selectedHashtags: ICreatableLibraryValue[];
        hashtagLibrary: ICreatableLibraryValue[];
        hashtagToAdd: string;
        selectedMentions: ICreatableLibraryValue[];
        mentionLibrary: ICreatableLibraryValue[];
    }
> {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);

        this.state = {
            open: props.open || false,
            selectedHashtags: [],
            hashtagLibrary: [],
            hashtagToAdd: "",
            selectedMentions: [],
            mentionLibrary: []
        };
    }
    componentDidMount() {
        this._updateHashtagsLibrary(this.props.allHashtags, this.props.oldHashtags || []);
        this._updateMentionsLibrary(this.props.allMentions, this.props.oldMentions || []);
    }
    componentWillReceiveProps(newProps) {
        if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open });
        }
        this._updateHashtagsLibrary(newProps.allHashtags, newProps.oldHashtags || []);
        this._updateMentionsLibrary(newProps.allMentions, newProps.oldMentions || []);
    }

    close() {
        this.setState({ open: false });
        if (this.props.close) {
            this.props.close();
        }
        createAlert('The modal for hashtag mention has been successfully closed.', 'success');
    }
    _updateHashtagsLibrary(allHashtags: string[], hashtags?: string[]) {
        const hashtagOptions: ICreatableLibraryValue[] = [];
        const selectedHashtags: ICreatableLibraryValue[] = [];
        allHashtags.forEach(hashtag => {
            hashtagOptions.push({
                value: `#${hashtag}`,
                label: `#${hashtag}`,
                checked: true,
                className: "list-of-tags-positive",
                added: false
            });
        });
        if (hashtags) {
            hashtags.forEach(hashtag => {
                if (Object.keys(hashtagOptions).some(key => hashtagOptions[key].value == `#${hashtag}`)) {
                    Object.keys(hashtagOptions).some(key => {
                        if (hashtagOptions[key].value == `#${hashtag}`) {
                            hashtagOptions[key] = {
                                value: `#${hashtag}`,
                                label: `#${hashtag}`,
                                checked: true,
                                className: "select-hashtag",
                                added: true
                            };
                            return true;
                        }
                        return false;
                    });
                }
                selectedHashtags.push({
                    value: `#${hashtag}`,
                    label: `#${hashtag}`,
                    checked: true,
                    className: "select-hashtag",
                    added: true
                });
            });
        }
        hashtagOptions.sort((a, b) => a.value.localeCompare(b.value));
        this.setState({ hashtagLibrary: hashtagOptions, selectedHashtags });
    }
    _updateMentionsLibrary(allMentions: string[], mentions?: string[]) {
        const mentionOptions: ICreatableLibraryValue[] = [];
        const selectedMentions: ICreatableLibraryValue[] = [];
        allMentions.forEach(mention => {
            mentionOptions.push({
                value: `@${mention}`,
                label: `@${mention}`,
                checked: true,
                className: "list-of-tags-positive select-mention-positive",
                added: false
            });
        });
        if (mentions) {
            mentions.forEach(mention => {
                if (Object.keys(mentionOptions).some(key => mentionOptions[key].value == `@${mention}`)) {
                    Object.keys(mentionOptions).some(key => {
                        if (mentionOptions[key].value == `@${mention}`) {
                            mentionOptions[key] = {
                                value: `@${mention}`,
                                label: `@${mention}`,
                                checked: true,
                                className: "select-mention",
                                added: true
                            };
                            return true;
                        }
                        return false;
                    });
                }
                selectedMentions.push({
                    value: `@${mention}`,
                    label: `@${mention}`,
                    checked: true,
                    className: "select-mention",
                    added: true
                });
            });
        }
        mentionOptions.sort((a, b) => a.value.localeCompare(b.value));
        this.setState({ mentionLibrary: mentionOptions, selectedMentions });
    }
    _updateHashtags(hashtags: ICreatableLibraryValue[]) {
        const val = hashtags;
        const hashtagLibrary = this.state.hashtagLibrary;
        const changedHashtag = this.state.hashtagLibrary.filter(v1 => !val.some(v2 => v2.label === v1.label))[0];
        let selectedHashtags = this.state.selectedHashtags;
        let selectedMentions = this.state.selectedMentions;
        if (changedHashtag) {
            if (changedHashtag.added) {
                selectedHashtags = selectedHashtags.filter(el => {
                    return el.value != changedHashtag.value;
                });
                Object.keys(hashtagLibrary).some(key => {
                    if (hashtagLibrary[key].value == changedHashtag.value) {
                        hashtagLibrary[key] = {
                            value: changedHashtag.value,
                            label: changedHashtag.value,
                            checked: true,
                            className: "list-of-tags-positive",
                            added: false
                        };
                        return true;
                    }
                    return false;
                });
            } else {
                if ((selectedHashtags.length + selectedMentions.length) < this.props.limit) {
                    selectedHashtags.push({
                        value: changedHashtag.value,
                        label: changedHashtag.value,
                        checked: true,
                        className: "select-hashtag",
                        added: true
                    });
                    Object.keys(hashtagLibrary).some(key => {
                        if (hashtagLibrary[key].value == changedHashtag.value) {
                            hashtagLibrary[key] = {
                                value: changedHashtag.value,
                                label: changedHashtag.value,
                                checked: true,
                                className: "select-hashtag",
                                added: true
                            };
                            return true;
                        }
                        return false;
                    });
                }
            }
            this.setState({ hashtagLibrary, selectedHashtags });
        }
    }
    _updateMentions(mentions: ICreatableLibraryValue[]) {
        const val = mentions;
        const mentionLibrary = this.state.mentionLibrary;
        const changedMention = this.state.mentionLibrary.filter(v1 => !val.some(v2 => v2.label === v1.label))[0];
        let selectedMentions = this.state.selectedMentions;
        let selectedHashtags = this.state.selectedHashtags;
        if (changedMention) {
            if (changedMention.added) {
                selectedMentions = selectedMentions.filter(el => {
                    return el.value != changedMention.value;
                });
                Object.keys(mentionLibrary).some(key => {
                    if (mentionLibrary[key].value == changedMention.value) {
                        mentionLibrary[key] = {
                            value: changedMention.value,
                            label: changedMention.value,
                            checked: true,
                            className: "list-of-tags-positive select-mention-positive",
                            added: false
                        };
                        return true;
                    }
                    return false;
                });
            } else {
                if ((selectedHashtags.length + selectedMentions.length) < this.props.limit) {
                    selectedMentions.push({
                        value: changedMention.value,
                        label: changedMention.value,
                        checked: true,
                        className: "select-mention",
                        added: true
                    });
                    Object.keys(mentionLibrary).some(key => {
                        if (mentionLibrary[key].value == changedMention.value) {
                            mentionLibrary[key] = {
                                value: changedMention.value,
                                label: changedMention.value,
                                checked: true,
                                className: "select-mention",
                                added: true
                            };
                            return true;
                        }
                        return false;
                    });
                }
            }
            this.setState({ mentionLibrary, selectedMentions });
        }
    }
    _submit(){
        if (this.props.update) {
            const hashtags = this.state.selectedHashtags.map(item => {
                return item["value"].substring(1);
            });
            const mentions = this.state.selectedMentions.map(item => {
                return item["value"].substring(1);
            });
            this.props.update(hashtags, mentions);
        }
        this.close();
    }
    renderHashtagsList() {
        const {selectedHashtags, selectedMentions, hashtagLibrary, mentionLibrary} = this.state;
        const {title, limit} = this.props;
        return (
            <div className="form__row">
                <div className="form__group form__group--full">
                    <label htmlFor="hashtagsAndMentionsLibrary" className="form__label">
                        {title} library
                        <span style={{ float: "right", color: selectedHashtags.concat(selectedMentions).length == limit ? "red" : ""}}>
                            ({selectedHashtags.concat(selectedMentions).length}/{limit})
                        </span>
                    </label>{" "}
                    <CreatableLibrary
                        id="hashtagsAndMentionsLibrary"
                        placeholder="Add a hashtag or mention..."
                        onChange={val => {
                            const hashtags: ICreatableLibraryValue[] = [];
                            const mentions: ICreatableLibraryValue[] = [];
                            if (val) {
                                val.forEach(v => {
                                    if (v.label.indexOf("#") === 0) {
                                        hashtags.push({
                                            value: v.value,
                                            label: v.value,
                                            checked: true,
                                            className: v.className,
                                            added: v.added
                                        });
                                    } else {
                                        mentions.push({
                                            value: v.value,
                                            label: v.value,
                                            checked: true,
                                            className: v.className,
                                            added: v.added
                                        });
                                    }
                                });
                            }
                            if (hashtags) {
                                this._updateHashtags(hashtags);
                            }
                            if (mentions) {
                                this._updateMentions(mentions);
                            }
                        }}
                        value={hashtagLibrary.concat(mentionLibrary)}
                    />
                </div>
            </div>
        );
    }
    render() {
        const {open} = this.state;
        return (
            <Modal
                className="modal-modal-background modal-header-none"
                title=""
                open={open}
                closeAction={this.close}
            >
                <div className="form__row">
                    <div
                        className="form__group form__group--full"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row"
                        }}
                    >
                        <Link onClick={() => this.close()} style={{ textDecoration: "underline" }}>
                            Cancel
                        </Link>
                        <Button style={{ marginLeft: "auto" }} onClick={() => this._submit()}  value="Save">Save</Button>
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__group form__group--full" />
                </div>
                {this.renderHashtagsList()}
            </Modal>
        );
    }
}
