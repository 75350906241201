import React, { Component } from "react";
import Modal from "./Modal";
import { Creatable, components } from "react-select";
import { createAlert } from "../../../actions";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";

export default class LibraryModal extends Component<
    {
        title: string;
        open?: boolean;
        client: Mongo.IClient;
        oldTerms: string[];
        allTerms: string[];
        limit: number;
        update: (terms: string[]) => void;
        close: () => void;
    },
    {
        selectedTerms: dynamic[];
        termsLibrary: dynamic[];
        termToAdd: string;
        open: boolean;
    }
> {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);

        this.state = {
            selectedTerms: [],
            termsLibrary: [],
            termToAdd: "",
            open: props.open || false
        };
    }

    ValueContainer({ children, getValue, ...props }) {
        var updatedChildren = children;
        if(updatedChildren && updatedChildren.length && updatedChildren[0] && updatedChildren[0].entries){
            for (let [i] of updatedChildren[0].entries()) {
                updatedChildren[0][i].props.components.Container = (v) => {
                    v.innerProps.className = `${v.innerProps.className} ${v.data.className}`;
                    return <components.MultiValueContainer {...v} ></components.MultiValueContainer>;
                };
            }
        }
        return (
            <components.ValueContainer {...props}>{updatedChildren}</components.ValueContainer>
        );
    };

    componentDidMount() {
        this._updateTermsLibrary(this.props.allTerms, this.props.oldTerms || []);
    }

    componentWillReceiveProps(newProps) {
        if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open });
        }

        this._updateTermsLibrary(newProps.allTerms, newProps.oldTerms || []);
    }

    close() {
        this.setState({ open: false });
        if (this.props.close) {
            this.props.close();
        }
        createAlert('The modal for the library has been successfully closed.', 'success');
    }

    _updateTermsLibrary(allTerms: string[], terms?: string[]) {
        const termOptions: dynamic[] = [];
        const selectedTerms: dynamic[] = [];
        allTerms.forEach(term => {
            termOptions.push({
                value: term,
                label: term,
                checked: true,
                className: "list-of-tags-positive",
                added: false
            });
        });
        if (terms) {
            terms.forEach(term => {
                if (Object.keys(termOptions).some(key => termOptions[key].value == term)) {
                    Object.keys(termOptions).some(key => {
                        if (termOptions[key].value == term) {
                            termOptions[key] = {
                                value: term,
                                label: term,
                                checked: true,
                                className: "",
                                added: true
                            };
                            return true;
                        }
                        return false;
                    });
                }
                selectedTerms.push({ value: term, label: term, checked: true, className: "", added: true });
            });
        }
        termOptions.sort((a, b) => b.added - a.added || a.value.localeCompare(b.value));
        this.setState({ termsLibrary: termOptions, selectedTerms });
    }

    _onFilterChange(event) {
        const val = event.target ? event.target.value : event;
        const termsLibrary = this.state.termsLibrary;
        const changedTerm = this.state.termsLibrary.filter(v1 => !val.some(v2 => v2.value === v1.value))[0];
        let selectedTerms = this.state.selectedTerms;

        if (changedTerm.added) {
            selectedTerms = selectedTerms.filter(el => {
                return el.value != changedTerm.value;
            });
            Object.keys(termsLibrary).some(key => {
                if (termsLibrary[key].value == changedTerm.value) {
                    termsLibrary[key] = {
                        value: changedTerm.value,
                        label: changedTerm.value,
                        checked: true,
                        className: "list-of-tags-positive",
                        added: false
                    };
                    return true;
                }
                return false;
            });
        } else {
            if (this.state.selectedTerms.length < this.props.limit) {
                selectedTerms.push({
                    value: changedTerm.value,
                    label: changedTerm.value,
                    checked: true,
                    className: "",
                    added: true
                });
                Object.keys(termsLibrary).some(key => {
                    if (termsLibrary[key].value == changedTerm.value) {
                        termsLibrary[key] = {
                            value: changedTerm.value,
                            label: changedTerm.value,
                            checked: true,
                            className: "",
                            added: true
                        };
                        return true;
                    }
                    return false;
                });
            }
        }
        this.setState({ termsLibrary, selectedTerms });
    }

    _submit(){
        if (this.props.update) {
            const terms = this.state.selectedTerms.map(item => {
                return item["value"];
            });
            this.props.update(terms);
        }
        this.close();
    }

    renderTermsList() {
        const {selectedTerms, termsLibrary} = this.state;
        const {title, limit} = this.props;
        return (
            <div className="form__row">
                <div className="form__group form__group--full">
                    <label htmlFor="termsLibrary" className="form__label">
                        {title} library
                        <span style={{ float: "right", color: selectedTerms.length == limit ? "red" : ""}}>
                            ({selectedTerms.length}/{limit})
                        </span>
                    </label>{" "}
                    <Creatable
                        id="termsLibrary"
                        isClearable={false}
                        components={{
                            DropdownIndicator:() => null,
                            IndicatorSeparator:() => null,
                            Menu: () => null,
                            ValueContainer: this.ValueContainer
                        }}
                        classNamePrefix="select"
                        placeholder="Add a term..."
                        className="list-of-tags"
                        searchable={false}
                        isMulti={true}
                        onChange={val => {
                            const terms: object[] = [];
                            if (val) {
                                val.forEach(v => {
                                    if (v.value) {
                                        terms.push({
                                            value: v.value,
                                            label: v.value,
                                            checked: true,
                                            className: v.className,
                                            added: v.added
                                        });
                                    }
                                });
                            }
                            if (terms) {
                                this._onFilterChange(terms);
                            }
                        }}
                        value={termsLibrary}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {open} = this.state;
        return (
            <Modal
                className="modal-modal-background modal-header-none"
                title=""
                open={open}
                closeAction={this.close}
            >
                <div className="form__row">
                    <div
                        className="form__group form__group--full"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row"
                        }}
                    >
                        <Link onClick={() => this.close()} style={{ textDecoration: "underline" }}>
                            Cancel
                        </Link>
                        <Button style={{ marginLeft: "auto" }} onClick={() => this._submit()}  value="Save">Save</Button>
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__group form__group--full" />
                </div>
                {this.renderTermsList()}
            </Modal>
        );
    }
}
