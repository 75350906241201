import React, { Component } from "react";
import { Link as _Link } from "react-router-dom";

export default class Link extends Component<{
    disabled?: boolean;
    to?: string;
    href?: string;
    ["data-toggle"]?: string;
    onClick?: (event: any) => void;
    className?: string;
    style?: object;
    target?: string;
    id?: string;
}> {
    render() {
        const { to, href, onClick, style, target, disabled, id } = this.props;
        let { className } = this.props;
        if (!className) {
            className = "";
        }
        if(disabled){
            className = `${className} disabled`;
        }
        if (this.props["data-toggle"]) {
            return (
                <a
                    id={id}
                    style={style}
                    data-toggle={this.props["data-toggle"]}
                    className={`brandPrimary--color ${className}`}
                    target={target}
                    tabIndex={0}
                >
                    {this.props.children}
                </a>
            );
        }
        if (href) {
            return (
                <a id={id} style={style} href={href || ""} className={`brandPrimary--color ${className}`} target={target} tabIndex={0}>
                    {this.props.children}
                </a>
            );
        } else if (onClick) {
            return (
                <button id={id} style={style} type="button" onClick={disabled ? ()=> null : onClick} className={`brandPrimary--color ${className}`} tabIndex={0}>
                    {this.props.children}
                </button>
            );
        }
        return (
            <_Link id={id} style={style} to={to || ""} className={`brandPrimary--color ${className}`} tabIndex={0}>
                {this.props.children}
            </_Link>
        );
    }
}
