import React, { Component } from "react";
import { getFriendlyTimestamp, stripGV6Query } from "../../utils/Helpers";
import Link from "../atoms/Link";
import ResizeImg from "./ResizeImg";


interface Props {
    article: Mongo.IClientArticle & { _id: ObjectID };
}

interface State {
    article: Mongo.IClientArticle & { _id: ObjectID, showVideo?: boolean; };
}

class ArticleContent extends Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
            article: props.article
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.article != this.state.article) {
            this.setState({ article: newProps.article });
        }
    }

    _handleVideoClick = (id: string) => {
        const { article } = this.state;
        const articleSource = ["twitter","facebook","instagram","linkedin"];
        if (!(article.video || article.gif)) return;

        (article.video || article.gif) && article.videoUrl && articleSource.includes(article.source) ? window.open(stripGV6Query(article.videoUrl)) :
            this.setState({ article: { ...article, showVideo: true } }, () => {
                const isInViewport = elem => {
                    const bounding = elem.getBoundingClientRect();
                    return (
                        bounding.top >= 0 &&
                        bounding.left >= 0 &&
                        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
                    );
                };
                const videos = document.getElementsByTagName("video");
                const video = document.getElementById(`vid_${id}`) as HTMLMediaElement;
                window.onscroll = () => {
                    for (let i = 0; i < videos.length; i++) {
                        const v = videos.item(i);
                        if (v && !isInViewport(v)) {
                            v.pause();
                        }
                    }

                };
                video.onpause = () => {
                    video.pause();
                };
                video.onplay = () => {
                    for (let i = 0; i < videos.length; i++) {
                        const v = videos.item(i);
                        if (v && v.id != `vid_${id}`) {
                            v.pause();
                        }
                    }
                };
                if (video) {
                    video.play();
                }
            });
    };

    render() {
        const { article } = this.state;
        // to check if it's a media/text post
        const isImageOrVideoLink = (article.Image|| article.video) && !article.Link;
        return (
            <div className="article-card__content">
                <div className="article-card__details">
                    <h4 className="article-card__title" style={isImageOrVideoLink ? { fontStyle: 'italic' } : {}}>
                        {article.Link && (
                            <Link href={stripGV6Query(article.Link)} target="_blank">
                                {article.Title}
                            </Link>
                        )}

                        {!article.Link && <span>{article.Title}</span>}
                    </h4>
                    <div className="article-card__info">
                        <div className="article-card__source-url">
                            {article.Link && (
                                <Link href={stripGV6Query(article.Link)} target="_blank">
                                    {article.Domain && article.Domain.length > 30
                                        ? `${article.Domain.substring(0, 30)}...`
                                        : article.Domain}
                                </Link>
                            )}
                            {!article.Link && article.Domain
                                ? article.Domain.length > 30
                                    ? `${article.Domain.substring(0, 30)}...`
                                    : article.Domain
                                : ""}
                        </div>
                        <span>-</span>
                        <div className="article-card__date" tabIndex={0}>{getFriendlyTimestamp(new Date(article.CreatedOn).getTime())}</div>
                    </div>
                    {article.Image && (
                        <div
                            className="article-card__image__wrapper"
                            style={{ position: "relative", textAlign: "center" }}
                            onClick={() => this._handleVideoClick(article._id.toString())}
                        >
                            {(article.video || article.gif) && !article.showVideo && (
                                <div className="icon-play-circle">
                                    <i className="icon ion-play" />
                                </div>
                            )}
                            {!article.showVideo && (
                                article.Image.includes("gif")
                                    // Don't resize gifs since they might be animated (Tashi)
                                    ? <img
                                        className="article-card__image"
                                        src={article.Image}
                                        tabIndex={0}
                                        alt="Article image"
                                    />
                                    : <ResizeImg
                                        className="article-card__image"
                                        src={article.Image}
                                    />
                            )}
                            {article.video && article.showVideo && (
                                <div>
                                    <video
                                        id={`vid_${article._id.toString()}`}
                                        controls
                                        disablePictureInPicture
                                        controlsList="nodownload"
                                    >
                                        <source
                                            src={article.videoUrl}
                                            type="video/mp4"
                                            style={{ maxHeight: "350px !important" }}
                                        />
                                        This video was unable to load.
                                    </video>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
export default ArticleContent;
