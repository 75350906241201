import React, { Component } from "react";
import Modal from "./Modal";
import EditableInput from "../../atoms/EditableInput";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import * as twttrtxt from "twitter-text";
import LIMentionTypeaheadOptionsList from "../../molecules/LIMentionTypeaheadOptionsList";
import LIMentionedList from "../../molecules/LIMentionedList";
import debounce from "lodash/debounce";
import { doGetMentionsTypeahead } from "../../../actions";
import { validateMention, getCommentWithFields } from "../../pages/articles/sharedArticle";

export default class NetworkCommentsModal extends Component<
    {
        className?: string;
        disableSuggestions?: boolean;
        url: string;
        lang: string;
        open?: boolean;
        client?: Mongo.IClient;
        facebookMaxLength: number;
        linkedInMaxLength: number;
        twitterMaxLength: number;
        instaMaxLength: number;
        close: () => void;
        comment: string;
        quotes: string[];
        addedImage?: boolean;
        image?: string;
        sURLDomain: string;
        commentURL: string;
        commentURLLength?: number;
        hashtagsAndMentions: string[];
        networkSuggestedComments?:{
            LinkedIn: string;
            Facebook: string;
            Twitter:  string;
            IG: string;
            WebShare: string;
        }| string
        socialUpdate: (networkSuggestedComments?:{
            LinkedIn: string;
            Facebook: string;
            Twitter:  string;
            IG: string;
            WebShare: string;
        }| string, liMentions?: Mongo.ILinkedinMention[]) => void;
        linkedinMentions?: Mongo.ILinkedinMention[];
    },
    {
        open: boolean;
        quotes: string[]
        networkSuggestedComments?:{
            LinkedIn: string;
            Facebook: string;
            Twitter:  string;
            IG: string;
            WebShare: string;
        } | string
        liMentions: Mongo.ILinkedinMention[];
        currentMentionToFill?: string;
        typeaheadOptions?: dynamic[];
    }
> {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.state = {
            open: props.open || false,
            quotes: this.props.quotes,
            networkSuggestedComments: this.props.networkSuggestedComments? this.props.networkSuggestedComments : "",
            liMentions: this.props.linkedinMentions || [],
        };
     }

    componentWillReceiveProps(newProps) {
        if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open, quotes: newProps.quotes });
        }
    }

    close() {
        this.setState({ open: false });
        if (this.props.close) {
            this.props.close();
        }
    }

    selectMention = (option: dynamic, index?: number, networkName?: string) => {
        const mention = {
            mention: option.name,
            id: option.member.split(":").slice(-1)[0],
            ...(option.notCompany ? { notCompany : true } : {}),
            ...(option.vanityName ? { vanityName : option.vanityName } : {})
        }
        const { currentMentionToFill, networkSuggestedComments } = this.state;
        const comment = networkSuggestedComments![networkName!] || "";
        if (comment.includes(currentMentionToFill + " ")) {
            this.updateNetworkComments(networkName!, comment.replace(currentMentionToFill, `@${mention.mention}`));
        } else {
            this.updateNetworkComments(networkName!, comment.replace(currentMentionToFill, `@${mention.mention} `));
        }
        this.setState({currentMentionToFill: "", typeaheadOptions: [], liMentions: [...(this.state.liMentions || []), mention]});
    }

    async updateNetworkComments(networkName: string, comment: string, event?: React.ChangeEvent<HTMLTextAreaElement>) {
        const { networkSuggestedComments, liMentions } = this.state;
        const { client } = this.props;
        if (client?.socialNetworks.LinkedIn.available && client?.enableLinkedInMentions && networkName === "LinkedIn") {
            let mentionsToAdd = liMentions ?? [];
            const oldMentionsToAdd = mentionsToAdd;
            mentionsToAdd = (mentionsToAdd ?? []).filter(mention => {
                return comment.includes(`@${mention.mention}`);
            });
            if (mentionsToAdd?.length !== oldMentionsToAdd?.length) {
                this.setState({ liMentions: mentionsToAdd });
            }
            const textarea = event && event.target as HTMLTextAreaElement;
            if (textarea) {
                let lastTypedCharIndex = textarea.selectionStart === 0 ? textarea.selectionStart : textarea.selectionStart - 1;
                let textSelection = "";
                if (lastTypedCharIndex >= 0 && lastTypedCharIndex < textarea.value.length) {
                    textSelection = `${textarea.value[lastTypedCharIndex]}`;
                }
                while (lastTypedCharIndex > 0 && textSelection.split(" ").length <= 2) {
                    lastTypedCharIndex--;
                    textSelection = `${textarea.value[lastTypedCharIndex]}${textSelection}`;
                    if (textSelection[0] === '@') {
                        break;
                    }
                }
                if (textSelection[0] === '@') {
                    const slicedSelection = textSelection.slice(1);
                    if (slicedSelection.match(/[a-zA-Z.`-]+\s?[a-zA-Z.`-]+/) && validateMention(slicedSelection)){
                        const debouncedMentionsTypeahead = debounce(async () => {
                            await doGetMentionsTypeahead(slicedSelection).then(result => {
                                if (result?.length) {
                                    this.setState({ typeaheadOptions: result, currentMentionToFill: textSelection });
                                } else {
                                    this.setState({ typeaheadOptions: [], currentMentionToFill: "" });
                                }
                            })
                        }, 500);
                        await debouncedMentionsTypeahead();
                    } else {
                        this.setState({ typeaheadOptions: [], currentMentionToFill: "" });
                    }
                }
            }
        }
        networkSuggestedComments![networkName] = comment;
        this.setState({ networkSuggestedComments });
    }

    renderNetworkSuggestedComments(networkName, value, index){
        const { quotes, liMentions, typeaheadOptions, currentMentionToFill } = this.state;
        const { client,facebookMaxLength, linkedInMaxLength, instaMaxLength, addedImage, image, sURLDomain, lang,url, disableSuggestions, commentURL, hashtagsAndMentions } = this.props;
        let maxLength; let length;
        const commentIncludingFields = getCommentWithFields(value, commentURL, hashtagsAndMentions);
        switch(networkName){
            case "LinkedIn":
                maxLength = linkedInMaxLength - (addedImage && image && url ? (sURLDomain.length + 7) : 0);
                length = commentIncludingFields.length;
            break;
            case "Twitter":
                maxLength = 280;
                length = twttrtxt.parseTweet(commentIncludingFields).weightedLength;
            break;
            case "Facebook":
                maxLength = facebookMaxLength;
                length = commentIncludingFields.length;
            break;
            case "IG":
                maxLength = instaMaxLength;
                length = commentIncludingFields.length;
            break;
            case "WebShare":
                maxLength = 100;
                length = commentIncludingFields.length;
            break;
            default:
                 return
        }


        return (
            <div className="form__row">
                            <div className="form__group form__group--full">
                            <label htmlFor="commentSuggestion" className="form__label">{networkName === 'Twitter' ? 'X (Twitter)' : networkName} Comment</label>{" "}
                                {client && client.aiGeneratedAdminSuggestions && lang === "en" && !disableSuggestions && (
                                    <div className="select__wrapper" style={{ marginBottom: "10px" }}>
                                        <select
                                            id="commentSuggestion"
                                            onChange={event => {
                                                const value = (event.target.value || "").trim();
                                                if (value === "starters") {
                                                    this.updateNetworkComments(networkName, "");
                                                } else if (value !== "suggested") {
                                                    this.updateNetworkComments(networkName, value);
                                                }
                                            }}
                                            value="starters"
                                        >
                                            <option value="starters">Starters</option>
                                            {quotes && quotes.length > 0 ? quotes.map((suggestedComment, i) => (
                                                <optgroup label={`${i == 0 ? "Suggested Comments" : "---"}`}>
                                                    <option value={suggestedComment}>
                                                        {suggestedComment.slice(0, 70)}
                                                        {suggestedComment.length > 70 ? "..." : ""}
                                                    </option>
                                                </optgroup>
                                            )) : <optgroup label="...we found no Suggested Comments" />}
                                        </select>
                                    </div>
                                )}
                                <EditableInput
                                    id={`ai_comment_${index}`}
                                    value={value}
                                    onChange={async event => await this.updateNetworkComments(networkName, event.target.value, event)}
                                    style={{ height: "100px", overflowY: "scroll" }}
                                    onOutsideClick={(e: dynamic<any>, id: string) => {
                                        if (!e.target.closest(".option-item") && (typeaheadOptions?.length || currentMentionToFill?.length)) {
                                            this.setState({ typeaheadOptions: [], currentMentionToFill: "" });
                                        }
                                    }}
                                />
                                <div>
                                    {(networkName === "LinkedIn" && liMentions.length) ? (
                                        <LIMentionedList mentions={liMentions || []} />
                                    ) : ""}
                                    {(networkName === "LinkedIn" && typeaheadOptions?.length) ? (
                                        <LIMentionTypeaheadOptionsList options={typeaheadOptions || []} selectMention={this.selectMention} networkName={networkName} />
                                    ) : ""}
                                    <div style={{ display: "flex" }}>
                                        <Link
                                            style={{ marginLeft: "10px", textDecoration: "underline" }}
                                            onClick={() => this.updateNetworkComments(networkName, "")}
                                        >
                                            Clear
                                        </Link>
                                        {this.renderSocialNetworkLimits(networkName,maxLength,length)}
                                    </div>
                                </div>
                            </div>
                        </div>
        );

    }

   renderSocialNetworkLimits(social:string,maxLength: number, length: number){
    return [
        <span style={{ marginLeft: "auto"}}>
            {
                social==="WebShare"?"" :
                <span>
                 {social === 'Twitter' ? 'X (Twitter)': social} (<span>{maxLength - length}</span>)
                </span>
            }
        </span>
    ];
   }

    render() {
        const { networkSuggestedComments, liMentions } = this.state;
        const arrComments = Object.keys(networkSuggestedComments!);
          return(
            <Modal
            open={this.state.open}
            className={this.props.className || ""}
            title={`Comment suggestions`}
            closeAction={this.close}
            >
            {
                <div>
                   <div>
                      {
                        arrComments && arrComments.map((key,index) => this.renderNetworkSuggestedComments(key,networkSuggestedComments![key],index))

                      }
                 <div className="form__row">
                    <div
                        className="form__group form__group--full"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }}
                    >
                        <Button
                            id="network_comments"
                            style={{ marginLeft: "auto" }}
                            onClick={() => {
                                if (this.props.socialUpdate) {
                                    this.props.socialUpdate(networkSuggestedComments, liMentions.length ? liMentions : []);
                                }
                                this.close();
                            }}
                        >
                            Update
                        </Button>
                     </div>
                    </div>
                  </div>
                </div>
            }

        </Modal>
          );
     }
}
