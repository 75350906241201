import React, { ComponentClass } from "react";
import { Route, Switch } from "react-router-dom";
import RoleRestrictedRoute from "../utils/RoleRestrictedRoute";

import ReportsRouter from "../components/pages/reports/_router";
import ClientsRouter from "../components/pages/clients/_router";
import CompanyRouter from "../components/pages/companies/_router";
import IndustryRouter from "../components/pages/industries/_router";
import RoleRouter from "../components/pages/roles/_router";
import CampaignRouter from "../components/pages/campaigns/_router";
import StreamsRouter from "../components/pages/streams/_router";
import ArticlesRouter from "../components/pages/articles/_router";
import AdminsRouter from "../components/pages/admins/_router";
import UsersRouter from "../components/pages/users/_router";
import DocsRouter from "../components/pages/docs/_router";
import DelegationRouter from "../components/pages/delegation/_router";
import ContentRouter from "../components/pages/content/_router";
import FeedsRouter from "../components/pages/feeds/_router";
import FeedsRequestsRouter from "../components/pages/feeds-requests/_router";
import ContentIndexRouter from "../components/pages/content-index/_router";
import CustomBrandsRouter from "../components/pages/custom-branding/_router";
import SMSRouter from "../components/pages/sms/_router";

import GroupsList from "../components/pages/Groups";
import ReleaseNotes from "../components/pages/docs/ReleaseNotes";

import Audit from "../components/pages/Audit";
import Settings from "../components/pages/Settings";

import NotFoundView from "../components/pages/NotFound";

interface IRoute {
    component: ComponentClass | ((props: any) => JSX.Element);
    path: string;
    exact?: boolean;
    render?: (props) => JSX.Element;
    type?: "route";
}

const routes: IRoute[] = [
    ...ReportsRouter,
    ...ClientsRouter,
    ...CompanyRouter,
    ...IndustryRouter,
    ...RoleRouter,
    ...CampaignRouter,
    ...StreamsRouter,
    ...ArticlesRouter,
    ...AdminsRouter,
    ...UsersRouter,
    ...DocsRouter,
    ...DelegationRouter,
    ...ContentRouter,
    ...FeedsRouter,
    ...FeedsRequestsRouter,
    ...ContentIndexRouter,
    ...CustomBrandsRouter,
    ...SMSRouter
];

export default () => {
    // If user is signed in (state/session/admin exist) and switches pages, call pendo initialize function
    const state = localStorage.getItem("state");
    if (state) {
        const parsedState = JSON.parse(state);
        if (parsedState.session
            && parsedState.lists
            && parsedState.session.admin
            && parsedState.lists.clients
            && Object.keys(parsedState.lists.clients).length
            && localStorage.getItem("pendoInitializedConsole") !== window.location.href) {
                // pendo.initialize({
                //     visitor: {
                //         id: parsedState.session.admin.aid,
                //         name: parsedState.session.admin.name,
                //         email: parsedState.session.admin.email
                //     },
                //     account: {
                //         id: parsedState.session.admin.cid,
                //         name: Object.values(parsedState.lists.clients)[0].name
                //     }
                // });
                localStorage.setItem("pendoInitializedConsole", window.location.href);
            }
    }
    const renderRoute = (item: IRoute, index: number) =>
        item.type && item.type === "route" ? (
            <Route
                key={index}
                component={item.component}
                exact={item.exact || false}
                path={item.path}
                render={item.render}
            />
        ) : (
            <RoleRestrictedRoute
                key={index}
                component={item.component}
                exact={item.exact || false}
                path={item.path}
                render={item.render}
            />
        );
    return (
        <Switch>
            {routes.map(renderRoute)}
            <RoleRestrictedRoute path="/groups" component={GroupsList} />
            <RoleRestrictedRoute path="/releases" component={ReleaseNotes} />
            <RoleRestrictedRoute path="/audit" component={Audit} />
            <RoleRestrictedRoute path="/settings" component={Settings} />
            <Route path="*" component={NotFoundView} />
        </Switch>
    );
};
