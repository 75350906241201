import React from "react";
import ClientsSearchList from "./List";
import ClientsSearchEdit from "./Edit";

export default [
    { exact: true, path: "/clients/s/:cid", component: ClientsSearchList },
    { exact: true, path: "clients/s/:cid/c", component: ClientsSearchEdit },
    { path: "/clients/s/:cid/e/:sid", component: ClientsSearchEdit },
    {
        path: "/clients/s/:cid/c/:type",
        component: ClientsSearchEdit
    },
    { exact: true, path: "/content/c", component: ClientsSearchList },
    {
        path: "/content/s/:cid/i",
        component: ClientsSearchList,
        render: props => <ClientsSearchList {...props} activeState={false} />
    },
    { path: "/content/s/:cid/c", component: ClientsSearchEdit },
    { path: "/content/s/:cid/e/:sid", component: ClientsSearchEdit }
];
