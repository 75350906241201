import React, { Component } from "react";
import Tooltip from "../atoms/Tooltip";

interface LIMentionedListProps {
    mentions: dynamic[];
}

export default class LIMentionedList extends Component<LIMentionedListProps> {
    constructor(props: LIMentionedListProps) {
        super(props);
    }

    render() {
        const { mentions } = this.props;
        return (
            <div className="selected-mentions mb-2">
                <p className="selected-mentions-text" style={{fontWeight: 700}}>Mentioned
                <Tooltip
                    style={{
                        marginLeft: "5px",
                        fontWeight: "normal"
                    }}
                >
                    Mentions will only be displayed as links on LinkedIn
                </Tooltip> : </p>
                <div style={{display: "contents"}}>
                    {mentions.map((mention, index) => {
                        if (index === mentions.length - 1 && !!mention.vanityName) {
                            return <p><a key={mention.id} href={`https://www.linkedin.com/in/${mention.vanityName}`} target="_blank" className="link text-bold">{mention.mention}</a></p>
                        }
                        if (mentions.length - index > 1 && !!mention.vanityName) {
                            return <p><a key={mention.id} href={`https://www.linkedin.com/in/${mention.vanityName}`} target="_blank" className="link text-bold">{mention.mention}, </a></p>
                        }
                        if (index === mentions.length - 1 && !mention.vanityName) {
                            return <p key={mention.id} className="text-bold">{mention.mention}</p>
                        }
                        return <p key={mention.id} className="text-bold">{mention.mention}, </p>
                    })}
                </div>
            </div>
        );
    }
}