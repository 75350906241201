import React, { useState, useEffect } from "react";
import Tooltip from "../atoms/Tooltip";
import Toggle from "../atoms/Toggle";
import Select from "../atoms/Select";
import CreatableInput from "../atoms/CreatableInput";
import { CopyLink } from "../atoms/CopyLink";
import { BackgroundImage } from "../atoms/BackgroundImage";
import EditableInput from "../atoms/EditableInput";
import ConditionalWrapper from "../atoms/Layout/ConditionalWrapper";
import { stripGV6Query } from "../../utils/Helpers";
import Link from "../atoms/Link";
import DatePickerV2 from "../atoms/DatePickerV2";

interface IPermissionProps {
    name: string;
    type?: IPermissionType;
    label: string | JSX.Element;
    description: string | JSX.Element | null;
    value: string[] | string | boolean | Date | number;
    max?: number;
    min?: number;
    multi?: boolean;
    creatable?: boolean;
    hidden?: boolean;
    disabled?: boolean;
    placeholder?: string;
    onChange: (permission: string, value: string | string[] | boolean | Date) => void;
    allowedValues: Array<{ label: string; value: string; disabled?: boolean, disallowed?: boolean }>;
    customClasses?: string;
    formatted?: JSX.Element;
    onOutsideClick?: (event: dynamic, id: string) => Promise<void>;
    onBlur?: () => Promise<void>;
    renderAbove?: JSX.Element;
    renderBelow?: JSX.Element;
    renderRight?: JSX.Element;
    renderLeft?: JSX.Element;
    maxDate?: string;
    minDate?: string;
    components?: dynamic;
    promptTextCreator?: (userInput: string) => string;
    options?: Array<{ label: string; value: string; className?: string }>;
    noResultsText?: string;
    onInputKeyDown?: (event: dynamic) => void;
    canUpdate?: (item: string, terms: string[]) => boolean;
    formatValue?: (value: string) => { label: string; value: string; className?: string };
    required?: boolean;
    clearField?: JSX.Element;
    displayValue?: string;
    hideTotals?: boolean;
    hasSubmitted?: boolean;
    onValidUpload?: (file: uploadFile) => void;
    fileUploaded?: File[] | null | undefined;
    validateInput?: (inputValue: string) => boolean | string;
    customTopLevelClasses?: string;
}

export type IPermissionType =
    | "textarea"
    | "text"
    | "email"
    | "number"
    | "toggle"
    | "select"
    | "multi-select"
    | "creatable"
    | "datetime"
    | "copyLink"
    | "EditableInput"
    | "plainText"
    | "link"
    | "image"
    | "custom-link";

export interface IPermission {
    key?: string;
    label: string | JSX.Element;
    description?: string | JSX.Element;
    value: string[] | boolean | string | Date | number;
    dropdown?: JSX.Element;
    multi?: boolean;
    hidden?: boolean;
    type?: IPermissionType;
    creatable?: boolean;
    max?: number;
    min?: number;
    disabled?: boolean;
    placeholder?: string;
    onChange?: (value: string[] | string | boolean | Date) => void;
    values?: Array<{ label: string; value: string; disabled?: boolean, disallowed?: boolean }>;
    canChange?: () => boolean;
    customClasses?: string;
    formatted?: JSX.Element;
    onOutsideClick?: (event: dynamic<any>, id: string) => Promise<void>;
    onBlur?: () => Promise<void>;
    renderAbove?: JSX.Element;
    renderBelow?: JSX.Element;
    renderRight?: JSX.Element;
    renderLeft?: JSX.Element;
    minDate?: string;
    maxDate?: string;
    components?: dynamic;
    promptTextCreator?: (userInput: string) => string;
    options?: Array<{ label: string; value: string; className?: string }>;
    noResultsText?: string;
    onInputKeyDown?: (event: dynamic<any>) => void;
    canUpdate?: (item: string, terms: string[]) => boolean;
    formatValue?: (value: string) => { label: string; value: string; className?: string };
    required?: boolean;
    clearField?: JSX.Element;
    displayValue?: string;
    hideTotals?: boolean;
    onValidUpload?: (file: uploadFile) => void;
    fileUploaded?: File[] | null | undefined;
    validateInput?: (inputValue: string) => boolean | string;
    customTopLevelClasses?: string;
}

interface uploadFile {
    uploadedImageURL?: ServerTypes.Console.IArticle["Image"];
    fileUpload?: File[] | null;
    addedImage?: ServerTypes.Console.IArticle["addedImage"];
    video?: ServerTypes.Console.IArticle["video"];
    uploadedFile?: boolean;
    Link?: ServerTypes.Console.IArticle["Link"];
    thumbnail?: string;
    customPdfFile?: File | null | undefined;
}
interface IPermissionSetProps {
    permissions: dynamic<IPermission>;
    _onChange: (key: string, value: string[] | string | boolean | Date) => void;
    setNumber?: string;
    hasSubmitted?: boolean;
    onValidUpload?: (file: uploadFile) => void;
    fileUploaded?: File[] | null | undefined;
    style?: React.CSSProperties;
}

/**
 * Functional component used to represent a list of permissions in a single column.
 * @param props
 */
const PermissionSet = (props: IPermissionSetProps) => {
    const states: dynamic<{ value: IPermission; setValue: React.Dispatch<React.SetStateAction<IPermission>> }> = {};

    if (Object.keys(props.permissions).length && !Object.keys(states).length) {
        for (const [key, permission] of Object.entries(props.permissions)) {
            const [value, setValue] = useState<IPermission>(permission!);
            states[key] = { value, setValue: val => setValue(val) };
        }
    }

    // everytime component is mounted, lets check the permissions against what we started with and if theyre new, updated them
    for (const key of Object.keys(props.permissions)) {
        if (states[key]) {
            const { value, setValue } = states[key]!;
            useEffect(() => {
                const permission = props.permissions[key]!;
                if (value !== permission) {
                    setValue(permission);
                }
            }, [props.permissions[key]]);
        }
    }

    /**
     * Anytime we change a permission, lets update state and push it back into the parent component
     * @param permission
     * @param value
     */
    const onPermissionChange = (permission: string, _value: string | string[] | boolean | Date) => {
        const { value, setValue } = states[permission]!;
        const { onChange, canChange } = value;
        if (!canChange || (canChange && canChange())) {
            setValue({ ...value, value: _value });

            if (onChange) {
                onChange(_value);
            } else {
                props._onChange(permission, _value);
            }
        }
    };

    /**
     * Convert the output into a valid object and pass that to the permission changed
     * @param permission
     * @param _value
     */
    const parseValue = (permission: string, _value: string | string[] | boolean | Date) => {
        const { value } = states[permission]!;
        const parsedValue: string[] | string | boolean | Date =
            value && value.type === "toggle" && !("values" in value) && !value.creatable
                ? typeof(value.value) === "string"
                    ? !JSON.parse(value.value)
                    : !value.value
                : _value;
        onPermissionChange(permission, parsedValue);
    };

    // we dont have anything, lets not render anything
    if (!states || Object.keys(states).length == 0) {
        return <div />;
    }
    return (
        <div className="permissions" id={`toggleGroup${props.setNumber}`} {...(props.style ? { style: props.style } : {})}>
            {Object.entries(states)
                .map(([permission, state]) => ({ permission, value: state!.value }))
                .filter(({ value }) => !("hidden" in value) || !value.hidden)
                .map(({ permission, value }) => (
                    <React.Fragment key={permission}>
                        <Permission
                            name={permission}
                            label={value.label || permission}
                            description={value.description || null}
                            value={value.value}
                            multi={value.multi}
                            {...(value.creatable ? { creatable: value.creatable } : null)}
                            {...(value.type ? { type: value.type } : null)}
                            {...(value.disabled ? { disabled: value.disabled } : null)}
                            {...(value.placeholder ? { placeholder: value.placeholder } : null)}
                            {...(value.max ? { max: value.max } : null)}
                            {...(value.min ? { min: value.min } : null)}
                            {...(value.formatted ? { formatted: value.formatted } : null)}
                            {...(value.onOutsideClick ? { onOutsideClick: value.onOutsideClick } : null)}
                            {...(value.onBlur ? { onBlur: value.onBlur } : null)}
                            {...(value.renderAbove ? { renderAbove: value.renderAbove } : null)}
                            {...(value.renderBelow ? { renderBelow: value.renderBelow } : null)}
                            {...(value.renderRight ? { renderRight: value.renderRight } : null)}
                            {...(value.renderLeft ? { renderLeft: value.renderLeft } : null)}
                            {...(value.maxDate ? { maxDate: value.maxDate } : null)}
                            {...(value.minDate ? { minDate: value.minDate } : null)}
                            {...(value.components ? { components: value.components } : null)}
                            {...(value.promptTextCreator ? { promptTextCreator: value.promptTextCreator } : null)}
                            {...(value.options ? { options: value.options } : null)}
                            {...(value.noResultsText ? { noResultsText: value.noResultsText } : null)}
                            {...(value.onInputKeyDown ? { onInputKeyDown: value.onInputKeyDown } : null)}
                            {...(value.canUpdate ? { canUpdate: value.canUpdate } : null)}
                            {...(value.formatValue ? { formatValue: value.formatValue } : null)}
                            {...(value.required ? { required: value.required } : null)}
                            {...(value.clearField ? { clearField: value.clearField } : null)}
                            {...(value.displayValue ? { displayValue: value.displayValue } : null)}
                            {...(value.hideTotals ? { hideTotals: value.hideTotals } : null)}
                            {...(props.hasSubmitted ? { hasSubmitted: props.hasSubmitted } : null)}
                            {...(props.fileUploaded ? { fileUploaded: props.fileUploaded } : null)}
                            {...(value.validateInput ? { validateInput: value.validateInput } : null)}
                            onValidUpload={props.onValidUpload}
                            onChange={parseValue}
                            allowedValues={value.values || []}
                            customClasses={value.customClasses}
                            customTopLevelClasses={value.customTopLevelClasses}
                        />
                        {value.dropdown && (
                            <PermissionDropdown toggled={!!value.value}>{value.dropdown!}</PermissionDropdown>
                        )}
                    </React.Fragment>
                ))}
        </div>
    );
};

const PermissionDropdown = (props: { toggled: boolean; children: JSX.Element }) => {
    return (
        <div className={`permission__dropdown ${props.toggled ? "open" : ""}`}>
            <div className="permission__dropdown__wrapper">{props.children}</div>
        </div>
    );
};

const Permission = (props: IPermissionProps) => {
    const {
        label,
        required,
        description,
        value,
        allowedValues,
        multi,
        creatable,
        type,
        renderAbove,
        renderBelow,
        renderRight,
        renderLeft,
        customTopLevelClasses
    } = props;
    const isMultiOrCreatable = !(allowedValues.length == 0 && !(multi || creatable));
    return (
        <div
            className={`permission ${(isMultiOrCreatable || type === "select") ? "array" : type || "toggle"} ${customTopLevelClasses || ""}`}
        >
            <span className="permission__label" aria-label={typeof label === "string" ? (label + (required ? " Required field" : "")) : undefined} style={type === "custom-link" ? { display: "inline" } : {}} tabIndex={0}>
                {label} {required ? "*" : ""}
                {description && (
                    <Tooltip
                        style={{
                            marginLeft: "5px",
                            marginRight: "5px"
                        }}
                    >
                        {description}
                    </Tooltip>
                )}
                {["text", "textarea", "EditableInput"].indexOf(props.type || "") > -1 &&
                    props.max &&
                    !props.hideTotals && (
                        <small style={{ marginLeft: "auto" }}>
                            {((value as string) || "").length}/{props.max}
                        </small>
                    )}
            </span>
            <ConditionalWrapper
                condition={isMultiOrCreatable}
                wrapper={children => <div className={`permission__switch ${multi ? "multi" : ""}`}>{children}</div>}
            >
                <ConditionalWrapper
                    condition={!!(renderAbove || renderBelow) && type !== "custom-link"}
                    wrapper={children => (
                        <div className="permission__column">
                            {renderAbove}
                            {children}
                            {renderBelow}
                        </div>
                    )}
                >
                    <ConditionalWrapper
                        condition={!!(renderAbove || renderBelow) && type == "custom-link"}
                        wrapper={children => (
                            <span>
                                {renderAbove}
                                {children}
                                {renderBelow}
                            </span>
                        )}
                    >
                        <ConditionalWrapper
                            condition={!!(renderLeft || renderRight)}
                            wrapper={children => (
                                <div className="permission__row">
                                    {renderLeft}
                                    {children}
                                    {renderRight}
                                </div>
                            )}
                        >
                            <PermissionInput {...props} />
                        </ConditionalWrapper>
                    </ConditionalWrapper>
                </ConditionalWrapper>
            </ConditionalWrapper>
        </div>
    );
};

const PermissionInput = (props: IPermissionProps) => {
    const {
        name,
        label,
        onChange,
        type,
        disabled,
        customClasses,
        max,
        min,
        value,
        displayValue,
        formatted,
        onOutsideClick,
        onBlur,
        minDate,
        maxDate,
        clearField,
        placeholder,
        hasSubmitted,
        required,
        fileUploaded,
        onValidUpload,
        multi,
        components,
        promptTextCreator,
        options,
        noResultsText,
        onInputKeyDown,
        canUpdate,
        formatValue,
        allowedValues,
        validateInput,
    } = props;
    let fieldError = false;
    // only validate non-required fields if they aren't blank
    if (validateInput && !(!required && value == "")) {
        const checkValid = validateInput(value as string);
        if (typeof checkValid == "string") {
            fieldError = true;
        }
    }
    const showRedOutline =
        hasSubmitted && (fieldError || (required && (!value || (value instanceof Array && !value.length))));

    switch (type) {
        case "creatable":
            return (
                <CreatableInput
                    id={name}
                    value={value as string[]}
                    onUpdate={(items: string[]) => {
                        onChange(name, items);
                    }}
                    className={(customClasses || "") + (showRedOutline ? " red-outline-children" : "")} // use red-outline-children because the select is wrapped in an element with a square border
                    {...(disabled ? { disabled } : null)}
                    {...(placeholder ? { placeholder } : null)}
                    {...(components ? { components } : null)}
                    {...(promptTextCreator ? { promptTextCreator } : null)}
                    {...(options ? { options } : null)}
                    {...(noResultsText ? { noResultsText } : null)}
                    {...(onInputKeyDown ? { onInputKeyDown } : null)}
                    {...(canUpdate ? { canUpdate } : null)}
                    {...(formatValue ? { formatValue } : null)}
                />
            );
        case "select":
        case "multi-select":
            return (
                <Select<string>
                    menuPortalTarget={document.body}
                    id={name}
                    options={(allowedValues || []).map(item => ({
                        ...item,
                        ...(value &&
                        ((multi &&
                            (value as string[]).length &&
                            (value as string[]).map(v => `${v}`).indexOf(`${item.value}`) !== -1) || // multi select, match based on string value
                            (!multi && `${value}` == `${item.value}`)) // not using a multi select, lets match based on string value
                            ? { selected: true }
                            : null),
                        ...(item.disabled ? { optionDisabled: true } : null)
                    }))}
                    onChange={(value: string | string[] | null) => {
                        onChange(name, multi ? value || [] : value || "");
                    }}
                    className={(customClasses || "") + (showRedOutline ? " red-outline-children" : "")} // use red-outline-children because the select is wrapped in an element with a square border
                    {...(disabled ? { disabled } : null)}
                    {...(placeholder ? { placeholder } : null)}
                    {...(multi ? { multi } : null)}
                />
            );
        case "EditableInput":
            return (
                <EditableInput
                    id={`${label}--input`}
                    key={`${label}--input`}
                    {...(disabled ? { disabled } : null)}
                    onChange={event => onChange(name, event.target.value)}
                    value={(value as string) || ""}
                    className={(customClasses || "") + (showRedOutline ? " red-outline" : "")}
                    {...(max ? { maxLength: max } : null)}
                    {...(min ? { minLength: min } : null)}
                    {...(formatted ? { formatted } : null)}
                    {...(onOutsideClick ? { onOutsideClick } : null)}
                    {...(onBlur ? { onBlur } : null)}
                    {...(clearField ? { clearField } : null)}
                    {...(placeholder ? { placeholder } : null)}
                />
            );
        case "text":
        case "email":
            return (
                <input
                    id={`${label}--input`}
                    key={`${label}--input`}
                    type={type}
                    {...(disabled ? { disabled } : null)}
                    onChange={event => onChange(name, event.target.value)}
                    value={(value as string) || ""}
                    className={(customClasses || "") + (showRedOutline ? " red-outline" : "")}
                    {...(max ? { maxLength: max } : null)}
                    {...(min ? { minLength: min } : null)}
                    placeholder={placeholder}
                    aria-label={typeof label === "string" ? label : "Text input"} // Added aria-label for accessibility
                    {...(onBlur ? { onBlur } : null)}
                />
            );
        case "textarea":
            return (
            <textarea
                id={`${label}--input`}
                key={`${label}--input`}
                {...(disabled ? { disabled } : null)}
                onChange={event => onChange(name, event.target.value)}
                value={(value as string) || ""}
                className={(customClasses || "") + (showRedOutline ? " red-outline" : "")}
                placeholder={placeholder}
                {...(max ? { maxLength: max } : null)}
                {...(min ? { minLength: min } : null)}
                {...(onBlur ? { onBlur } : null)}
                aria-label={typeof label === "string" ? label : "Textarea input"} // Added aria-label for accessibility
            />
            );
        case "number":
            return (
                <input
                    id={`${label}--input`}
                    key={`${label}--input`}
                    type="number"
                    {...(disabled ? { disabled } : null)}
                    onChange={event => onChange(name, event.target.value)}
                    value={Number(value || "")}
                    {...(onBlur ? { onBlur } : null)}
                />
            );
        case "datetime":
            return (
                <DatePickerV2
                    {...(disabled ? { disabled } : null)}
                    dateFormat="yyyy/MM/dd h:mm a"
                    timeFormat="h:mm a"
                    showTimeSelect={true}
                    timeIntervals={15}
                    onChange={value => onChange(name, new Date(value))}
                    {...(minDate ? { minDate } : null)}
                    {...(maxDate ? { maxDate } : null)}
                    value={value as Date}
                    calendarPlacement={"top"}
                    className={(customClasses || "") + (showRedOutline ? " red-outline-children" : "")} // use red-outline-children because the select is wrapped in an element with a square border
                    enableTabLoop={false}
                />
            );
        case "copyLink":
            return <CopyLink textToCopy={value as string} />;
        case "plainText":
            return (
                <div className={`plainTextDiv ${customClasses + (showRedOutline ? " red-outline" : "")}`}>
                    {(displayValue || (value as string)).length > 30
                        ? `${(displayValue || (value as string)).substring(0, 30)}...`
                        : displayValue || value}
                </div>
            );
        case "link":
            return (
                <Link
                    className="plainTextDiv"
                    style={{ textDecoration: "underline" }}
                    href={stripGV6Query(value as string)}
                    target="_blank"
                >
                    {(value as string).length > 30 ? `${(value as string).substring(0, 30)}...` : value}
                </Link>
            );
        case "custom-link":
            if (typeof value === "string" && value.includes("grapevinesix.s3")) {
                return (
                    <div className="editable__input" style={{ display: "inline-block" }}>
                        <Link href={stripGV6Query(value as string || "")} target="_blank" style={{ fontWeight: "normal" }}>
                            {value}
                        </Link>
                    </div>
                );
            } else {
                return <input
                    type="text" value={(value as string) || ""}
                    style={{ fontWeight: "normal" }}
                    disabled
                />;
            }
        case "image":
            return (
                <BackgroundImage
                    name={name}
                    onValidUpload={onValidUpload}
                    onChange={onChange}
                    fileUploaded={fileUploaded || (value as string)}
                />
            );
        case "toggle":
        default:
            return (
                <Toggle
                    key={`${label}--input`}
                    name={name}
                    {...(disabled ? { disabled } : null)}
                    onToggle={() => onChange(name, !(`${value || "false"}` == "true"))}
                    value={`${value || "false"}` === "true"}
                 />
            );
    }
};

export default PermissionSet;
