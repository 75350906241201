import React, { Component } from "react";
import { connect } from "react-redux";

import Link from "../../atoms/Link";
import ElementListBox from "../../molecules/ElementListBox";
import Button from "../../atoms/Button";
import { CustomBrandActions, createAlert } from "../../../actions";
import { getFriendlyDate } from "../../../utils/Helpers";
import { Wrapper, Row } from "../../atoms/Layout/";

interface ICustomBrandsViewProps {
    customBrands: Mongo.IBrand[];
}
interface ICustomBrandsState {
    customBrands: Mongo.IBrand[];
    total: number;
    forceRefresh: boolean;
}
class CustomBrandsView extends Component<ICustomBrandsViewProps, ICustomBrandsState> {
    static allowSuper = true;
    static allowApiAdmin = true;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props: ICustomBrandsViewProps) {
        super(props);
        this.state = {
            customBrands: props.customBrands,
            total: 0,
            forceRefresh: false
        }

        this._removeBrand = this._removeBrand.bind(this);
        this._refresh = this._refresh.bind(this);
    }

    async _removeBrand(customBrand: ServerTypes.Console.IBrand) {
        const clientsUsingBrand = await CustomBrandActions.doGetClientsUsingBrand(customBrand.brandName);

        if (clientsUsingBrand.items && clientsUsingBrand.items.length > 0) {
            alert(`This branding scheme cannot be deleted, as it is currently being used by the following clients:
            \n${clientsUsingBrand.items.join(", ")}
            \nTo delete this brand, the clients above must be updated to a different branding scheme`);
            return false;
        } else {
            if (confirm(`Are you sure you would like to remove ${customBrand.brandName}?`)) {
                try {
                    await CustomBrandActions.doDeleteBrand(customBrand.brandName);
                    this.setState({forceRefresh: true})
                    createAlert(`Successfully removed ${customBrand.brandName}`, "success");
                    // reset force refresh in case we're deleting multiple brands
                    this.setState({forceRefresh: false})
                    return true;
                } catch (error) {
                    return false;
                }
            }
            return false;
        }
    }

    async _refresh(search = "", paginationData = {}) {
        return CustomBrandActions.doList({
            ...(search ? { search } : null),
            ...paginationData
        });
    }

    render() {
        return (
            <Wrapper id="customBrands">
                <Row>
                    <ElementListBox<ServerTypes.Console.IBrand>
                        title="Custom Branding"
                        keys={{
                            brandName: "Name",
                            lastUpdatedOn: "Last Updated",
                            actions: "Actions"
                        }}
                        sortableKeys={["Name", "Last Updated"]}
                        defaultSort={{ lastUpdatedOn: -1 }}
                        filterSearch
                        refresh={({ search }, paginationData) => this._refresh(search, paginationData)}
                        mutateItem={item => ({
                            brandName: <Link to={`/custombrand/e/${item.brandName}`}>{item.brandName}</Link>,
                            lastUpdatedOn: item.lastUpdatedOn ? getFriendlyDate(item.lastUpdatedOn)! : "N/A"
                        })}
                        forceRefresh={this.state.forceRefresh}
                        buttonElements={[
                            (item) => (<Button
                                key={`link-edit--${item.brandName}`}
                                className="btn--sm"
                                to={`/custombrand/e/${item.brandName}`}
                                onClick={() => {
                                    createAlert(`Navigating to the edit page for ${item.brandName}`,'success');
                                }}
                            >
                                <i className="fa fa-edit" /> Edit
                            </Button>),
                            (item) => (<Button
                                    key={`link-remove--${item.brandName}`}
                                    className="btn--sm"
                                    onClick={async () => await this._removeBrand(item)}
                                >
                                    <i className="fa fa-trash"/> Delete
                            </Button>)
                        ]}
                        createLink={`/custombrand/c/:brandName`}
                    />
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    customBrands: state.lists.customBrands,
});

export default connect(mapStateToProps)(CustomBrandsView);
