import BaseAction from "../utils/BaseAction";
import { CoreAPI } from "../../../../types/coreapi";

export interface IFeedActions {
    doList(params: IFeedListParams): Promise<{ items: CoreAPI.IFeed[]; total: number }>;
    doGet(id: number): Promise<CoreAPI.IFeed | null>;
    doRemove(id: number): Promise<{ valid: boolean } | null>;
    doUpsert(feed: CoreAPI.IFeed): Promise<void>;
    doValidate(url: string, type: string): Promise<{ valid: boolean } | null>;
    doExportCSV(params: IFeedListParams): Promise<void>;
}

export interface IFeedListParams {
    active?: boolean;
    articleDomain?: string;
    region?: number;
    category?: string;
    subCategory?: string;
    location?: string;
    eventType?: string;
    type?: string;
    lang?: string;
    start: number;
    rows: number;
}

class _FeedActions extends BaseAction implements IFeedActions {
    async doList(params: IFeedListParams) {
        try {
            const data = await this.apiRequestWithErrorAlert<{ items: CoreAPI.IFeed[]; numFound: number }>(
                `/feed/list/json`,
                "GET",
                params
            );
            if (data && data.items && data.items.length) {
                return { items: data.items, total: data.numFound };
            }
        } catch (error) {
            //
        }
        return { items: [], total: 0 } as { items: CoreAPI.IFeed[]; total: number };
    }

    async doGet(id: number) {
        const data = await this.apiRequestWithErrorAlert<CoreAPI.IFeed>(`/feed/get`, "GET", {
            id
        });
        if (data) {
            return data;
        }
        return null;
    }

    async doRemove(id: number) {
        return this.apiRequestWithErrorAlert<{ valid: boolean }>(`/feed/delete`, "GET", { id }, undefined, true);
    }

    async doUpsert(feed: dynamic) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean }>(
            `/feed/upsert`,
            "POST",
            feed,
            undefined,
            true
        );
        if (!data || !data.valid) {
            throw new Error("Upsert failed");
        }
    }

    async doValidate(url: string, type: string) {
        return this.apiRequest<{ valid: boolean }>(`/feed/validate`, "POST", { url, type });
    }

    async doExportCSV(query: IFeedListParams) {
        const data = await this.apiRequest("/feed/list/csv", "GET", { ...query });
        if (data) {
            return this.downloadFile("feeds", data);
        }
    }

    async doGetDomainInfo(url: string) {
        const data = await this.apiRequestWithErrorAlert<{ domain: string }>(`/domainInfo`, "POST", {
            url
        });
        return (data || { domain: "" }).domain;
    }
}

export const FeedActions = new _FeedActions();
