import React from "react";
import Link from "../../atoms/Link";
import { stripGV6Query, sanitizeHtml } from "../../../utils/Helpers";
// import { doGetQuotes, createAlert, doGetMentionsTypeahead } from "../../../actions";
import { createAlert, doGetMentionsTypeahead } from "../../../actions";
import ArticleFileUpload from "../../molecules/ArticleFileUpload";
import LIMentionTypeaheadOptionsList from "../../molecules/LIMentionTypeaheadOptionsList";
import LIMentionedList from "../../molecules/LIMentionedList";
import ArticleImageModal from "../../molecules/modals/ArticleImageModal";
import LibraryModal from "../../molecules/modals/LibraryModal";
import HashtagMentionModal from "../../molecules/modals/HashtagMentionModal";
import { components } from "react-select";
import SocialLimits from "../../atoms/SocialLimits";
import MultipleCommentsModal from "../../molecules/modals/MultipleCommentsModal";
import NetworkCommentsModal from "../../molecules/modals/NetworkCommentsModal";
// import SuggestedComments from "../../molecules/SuggestedComments";
import Button from "../../atoms/Button";
import ThumbnailModal from "../../molecules/modals/ThumbnailModal";
import { Document, Thumbnail } from "react-pdf";
import GenerateAIComments from "../../molecules/GenerateAIComments";
import debounce from "lodash/debounce";
import Tooltip from "../../atoms/Tooltip";
import { getClientNetworks } from "../../common";
import * as twitter from "twitter-text";
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

interface ISharedArticleProps {
    getState: () => any;
    setState: (item: dynamic) => void;
    getStateManual: () => ServerTypes.Console.IArticle | Mongo.INotification | null;
    setStateManual: (entries: Partial<ServerTypes.Console.IArticle>) => void;
    item: any;
    client?: Mongo.IClient;
    uploadedFile: boolean;
    imageModal: boolean;
    totals: {
        hashtags: string[];
        mentions: string[];
        terms: string[];
    };
    termsModal: boolean;
    hashtagsModal: boolean;
    sharePermissions: string[];
    quotes: string[];
    multipleCommentsModal: boolean;
    networkCommentsModal: boolean;
    changePref?: boolean;
    generateAICommentsModal: boolean;
    facebookMaxLength: number;
    linkedInMaxLength: number;
    twitterMaxLength: number;
    instaMaxLength: number;
    isMedia: boolean | undefined;
    hidden?: boolean;
    isNotification?: boolean;
    notificationPreviewModal?: boolean;
    thumbnail?: string | undefined;
    thumbnailModal?: boolean;
    customThumbnailUrl?: string | undefined;
    customThumbnailFile?: File | null | undefined;
    fileUpload?: File[] | null;
    sURLDomain?: string;
    videoDuration?: number | null;
    videoWidth?: number | null;
    videoHeight?: number | null;
    customPdfFile?: File | null | undefined;
    readOnly?: boolean;
    aiCommentPref?: Mongo.ClientAdmin["aiCommentPref"] | null;
}
const SharedArticle = (props: ISharedArticleProps) => {
    const { getState, setState, readOnly, getStateManual, setStateManual, item, client, videoDuration, uploadedFile, imageModal, totals, termsModal, hashtagsModal, sharePermissions, quotes, multipleCommentsModal, networkCommentsModal, generateAICommentsModal, changePref, facebookMaxLength, linkedInMaxLength, twitterMaxLength, instaMaxLength, isMedia, hidden, isNotification, thumbnail, thumbnailModal, customThumbnailUrl, customThumbnailFile, fileUpload, sURLDomain, customPdfFile, videoWidth, videoHeight, aiCommentPref } = props;
    const isDisabled = !item.sid && !isNotification;
    const commentURLlength = 30;
    const displayThumbnail =
        isMedia &&
        !item.video &&
        (
            (fileUpload && fileUpload.length > 0) ||
            (item.Images && item.Images.length > 0)
        );
    const socialNetworksUploadLimit = [
        { network: 'LinkedIn', maxDuration: 600 },
        { network: 'Twitter', maxDuration: 140 }, // 140 = 2:20s
        { network: 'Instagram', maxDuration: 600, maxWidth: 1920 }, // IG 60s / 1920px max width - https://developers.facebook.com/docs/instagram-api/reference/ig-user/media/
        { network: 'Facebook', maxDuration: 600 }, //600 = 10mins
    ];
    // Check if autopost or sequential posting is enabled
    const isAutoPost = item.sid ? client?.streams[item.sid].allowAutopost : false;
    const isSequence = item.sid ? client?.streams[item.sid].sequentialContent : false;

    // default values
    let networkSuggestedComments = {
        LinkedIn: "",
        Facebook: "",
        Twitter: "",
        IG: "",
        WebShare: "",
    };
    if (item.networkSuggestedComments) {
        networkSuggestedComments = item.networkSuggestedComments;
    }
    // Removing Webshare Comments
    if (!client?.hasExtraNetworks) {
        // @ts-expect-error
        delete networkSuggestedComments.WebShare
    }
    // filtering comments based on available social networks
    if (client?.socialNetworks && client?.allowNetworkSuggestedComments) {
        const socialNetworkArray = Object.entries(client?.socialNetworks);
        const commentsArray = Object.keys(networkSuggestedComments);
        for (let i = 0; i < socialNetworkArray.length; i++) {
            for (let j = 0; j < commentsArray.length; j++) {
                if (socialNetworkArray[i][0] === commentsArray[j]) {
                    if (!socialNetworkArray[i][1]!.available) {
                        delete networkSuggestedComments[socialNetworkArray[i][0]];
                        break;
                    }
                }
            }
        }
    }

    const renderSocialNetworkVideoUploadList = () => {
        return <div style={{ width: "100%", padding: "5px 0px 5px 0px" }}>
            <ol style={{ display: "flex", flexDirection: "row", padding: "1px 2px", listStyle: 'none', justifyContent: "space-between", alignContent: "center", flexWrap: 'wrap' }}>
                {socialNetworksUploadLimit && socialNetworksUploadLimit.map((obj, idx) => {
                    let liElement = <li key={idx} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between ' }}>{obj.network} <i
                        className="icon ion-checkmark-circled"
                        style={{
                            margin: "0 2px",
                            color: "#55DE36",
                            fontSize: "17px"
                        }}
                    /></li>
                    const videoW = videoWidth || item?.videoWidth;
                    const videoH = videoHeight || item?.videoHeight;
                    const aspectRatio = videoH / videoW;
                    if ((obj.network === 'LinkedIn' || obj.network === 'Twitter' || obj.network === 'Facebook')
                    && (videoDuration || item?.videoDuration) < obj.maxDuration
                    && aspectRatio <= (16 / 9)
                    && aspectRatio >= (9 / 16)) {
                        return liElement;
                    } else if (obj.network === 'Instagram'
                    && (videoDuration || item?.videoDuration) < obj.maxDuration
                    && videoW <= obj.maxWidth!
                    && aspectRatio <= (5 / 4)
                    && aspectRatio >= (9 / 16)) {
                        return liElement;
                    } else {
                        return <li key={idx} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between ' }}>{obj.network} <i
                            className="icon ion-close-round"
                            style={{
                                margin: "0 2px",
                                color: "#EA3109",
                                fontSize: "17px"
                            }}
                        /></li>
                    };
                })}
            </ol>
        </div>
    };

    const state = getState();
    const selectedCommentOption = state?.selectedCommentOption || (Object.values(networkSuggestedComments).some(ntwrk => ntwrk.length) ? "network" : (isAutoPost || item.suggestedComments?.some(comment => comment.length)) ? "multiple" : "");

    const handleNetworkCommentsClick = () => {
        setState({ networkCommentsModal: true });
    };

    const handleMultipleCommentsClick = () => {
        setState({ multipleCommentsModal: true });
    };

    const selectMention = (option: dynamic) => {
        const mention = {
            mention: option.name,
            id: option.member.split(":").slice(-1)[0],
            ...(option.notCompany ? { notCompany : true } : {}),
            ...(option.vanityName ? { vanityName : option.vanityName } : {})
        }
        const { currentMentionToFill } = getState();
        const comment = getStateManual()?.comment || "";
        if (comment.includes(currentMentionToFill + " ")) {
            setStateManual({ comment: comment.replace(currentMentionToFill, `@${mention.mention}`) });
        } else {
            setStateManual({ comment: comment.replace(currentMentionToFill, `@${mention.mention} `) });
        }
        setState({currentMentionToFill: "", typeaheadOptions: [], mentionsToAdd: [...getState().mentionsToAdd, mention]});
    }

    if (!client) {
        return {
            comment: {
                disabled: isDisabled,
                hidden,
                label: "Comments",
                type: "EditableInput" as const,
                max: item.commentURL && item.commentURL.length ? facebookMaxLength - commentURLlength : facebookMaxLength,
                customClasses: "editable-textarea",
                placeholder: " ",
                hideTotals: true,
                renderBelow:
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Link
                            disabled={isDisabled}
                            style={{ textDecoration: "underline", padding: "5px 0px" }}
                            onClick={() => setStateManual({ comment: "" })}
                        >
                            Clear
                        </Link>
                        <SocialLimits
                            client={client}
                            facebookMaxLength={facebookMaxLength}
                            linkedInMaxLength={linkedInMaxLength}
                            twitterMaxLength={twitterMaxLength}
                            url={item.Link || ""}
                            comment={getCommentWithFields(item.comment, item.commentURL, item.HashtagsAndMentions)}
                            addedImage={item.addedImage}
                            image={item.Image || ""}
                            sURLDomain={sURLDomain || ""}
                        />
                    </div>
            },
            commentURL: {
                label: "URL in Comment",
                hidden: true,
            },
            Title: {
                ...(isMedia && {
                    description: "The title will not be visible in the application, unless the content is a video. Video titles are also displayed on LinkedIn."
                }),
                hidden,
                disabled: isDisabled,
                label: isMedia ? "Title" : "Article Title",
                required: true,
                type: "EditableInput" as const,
                max: 200,
                placeholder: " ",
                hideTotals: true
            },
            Link: {
                label: item.cid === "ALL_CONSUMERS" ? "Article/PDF" : "Article URL",
                ...(item.cid === "ALL_CONSUMERS" ? { description: `Please insert one of the 2 options:\n\nArticle URL: A URL to an article can be added in the text field on the right.\n\nPDF: A single PDF can be added by file upload or by URL. If uploading, the file must be .pdf and maximum 60MB.\nIf a PDF is added, a custom article image is required.` } : null),
                required: true,
                ...(item.cid === "ALL_CONSUMERS" && !customPdfFile ? { customClasses: "white-space-normal" } : null),
                placeholder: item.cid === "ALL_CONSUMERS" ? "Insert any web link to an article" : " ",
                type: "EditableInput" as const,
                disabled: isDisabled,
                hidden: hidden || isMedia || props.item.type == "Text" || props.item.type == "Email Template",
                ...(
                    item.cid === "ALL_CONSUMERS" ? {
                        ...(
                            !customPdfFile && !item.Link ? {
                                renderLeft:
                                    <>
                                        <ArticleFileUpload
                                            style={{ flexBasis: "auto" }}
                                            title="Upload File"
                                            isMedia
                                            isPdf={true}
                                            onValidUpload={entries => {
                                                const { customPdfFile } = entries;
                                                if (customPdfFile) {
                                                    setState({ customPdfFile });
                                                    setStateManual({ Link: customPdfFile.name });
                                                }
                                            }}
                                            article={item}
                                            disabled={isDisabled}
                                        />
                                        <h4 style={{ flexBasis: "auto", textAlign: "center", margin: "5px" }}>OR:</h4>
                                    </>
                            } : {
                                ...(item.Link ? {
                                    clearField:
                                        <Button
                                            style={{ backgroundColor: '#efefef', height: '100%' }}
                                            onClick={() => {
                                                setStateManual({ Link: "" });
                                                setState({ customPdfFile: null });
                                            }}
                                        ><i className={`fa fa-times`} style={{ color: '#676a6c' }}></i></Button>,
                                    formatted:
                                        <Link href={stripGV6Query(item.Link || "")} target="_blank">
                                            {item.Link}
                                        </Link>,
                                } : null)
                            }
                        )
                    } : {
                        clearField:
                            <Button
                                style={{ backgroundColor: '#efefef', height: '100%' }}
                                onClick={() => {
                                    setStateManual({ Link: "" });
                                    setState({ customPdfFile: null });
                                }}
                            ><i className={`fa fa-times`} style={{ color: '#676a6c' }}></i></Button>,
                        formatted:
                            <Link href={stripGV6Query(item.Link || "")} target="_blank">
                                {item.Link}
                            </Link>,
                    }
                ),
                mutateChange: async (key, value) => {
                    setState({
                        Link: `${value}`,
                    });
                    return { [key]: value as string };
                },
            },
            ...(isMedia ?
                (!item.video && (!item.Image || !item.Image.match(/.(jpg|jpeg|png|gif|mp4|m4v)/i))) ? {
                    Image: {
                        label: `${item.cid === "ALL_CONSUMERS" ? "Image/Video" : "Image"}`,
                        hidden: hidden || item.cid !== "ALL_CONSUMERS",
                        type: "EditableInput" as const,
                        description: `Please insert one of the ${item.cid === "ALL_CONSUMERS" ? "three" : "two"} options:\n\nImage: A single image can be added by URL. Up to 4 images can be added by file upload.\n\t File must be .png or .jpg and maximum 5MB.\n\nGIF: File must be .gif and under 5 MB${item.cid === "ALL_CONSUMERS" ? "\n\nVideo: All video files must be .mp4/m4v, and must be at least 3 seconds, and;\nInstagram: Length cannot exceed 10 minutes, max file size of 100MB,\n\t max width of 1920px, and must have an aspect ratio between 16:9 and 4:5\nX (Twitter): Length cannot exceed 2:20 minutes, max file size of 512MB,\n\t and must have an aspect ratio between 16:9 and 9:16\nOther Social Networks: Length cannot exceed 10 minutes,\n\t max file size of 650MB, and must have an aspect ratio between 16:9 and 9:16.\nOnly 1 video may be added." : ""}\n\nTo insert a PDF, go to Create Article`,
                        disabled: isDisabled,
                        placeholder: `${item.cid === "ALL_CONSUMERS"}` ? "Image/Video URL" : " ",
                        mutateChange: async (key, value) => {
                            setState({ Image: `${value}` });
                            return { [key]: value as string };
                        },
                        renderLeft:
                            <>
                                <ArticleFileUpload
                                    disabled={isDisabled}
                                    style={{ flexBasis: "20%" }}
                                    title="Upload File"
                                    allowVideoCuration={item.cid === "ALL_CONSUMERS"}
                                    isMedia
                                    onValidUpload={entries => {
                                        const { uploadedFile, fileUpload, uploadedImageURL, thumbnail, ...article } = entries;
                                        if (fileUpload?.length) {
                                            const { type: fileType } = fileUpload![0];
                                            setState({ uploadedFile, fileUpload, thumbnail });
                                            setStateManual({
                                                [fileType.includes("video") ? "videoUrl" : "Image"]: uploadedImageURL,
                                                [fileType.includes("video") ? "video" : "addedImage"]: true,
                                                ...article
                                            });
                                        }
                                    }}
                                    article={item}
                                    setStateManual={setStateManual}
                                />
                                <h4 style={{ flexBasis: "20%", textAlign: "center", margin: "0" }}>OR:</h4>
                            </>,
                    }
                } : {
                    Image: {
                        label: `${item.cid === "ALL_CONSUMERS" ? "Image/Video" : "Image"}`,
                        value: displayThumbnail ? " " : item.videoUrl || item.Image || "",
                        disabled: isDisabled,
                        hidden,
                        ...(item.video || !isMedia ? { customClasses: "white-space-normal" } : null),
                        ...(displayThumbnail ? { customClasses: "flex-0" } : null),
                        placeholder: `${item.cid === "ALL_CONSUMERS"}` ? "Image/Video URL" : " ",
                        type: displayThumbnail ? "plainText" as const : "EditableInput" as const,
                        description: `Please insert one of the ${item.cid === "ALL_CONSUMERS" ? "three" : "two"} options:\n\nImage: A single image can be added by URL. Up to 4 images can be added by file upload.\n\t File must be .png or .jpg and maximum 5MB.\n\nGIF: File must be .gif and under 5 MB${item.cid === "ALL_CONSUMERS" ? "\n\nVideo: All video files must be .mp4/m4v, and must be at least 3 seconds, and;\nInstagram: Length cannot exceed 10 minutes, max file size of 100MB,\n\t max width of 1920px, and must have an aspect ratio between 16:9 and 4:5\nX (Twitter): Length cannot exceed 2:20 minutes, max file size of 512MB,\n\t and must have an aspect ratio between 16:9 and 9:16\nOther Social Networks: Length cannot exceed 10 minutes,\n\t max file size of 650MB, and must have an aspect ratio between 16:9 and 9:16.\nOnly 1 video may be added." : ""}`,
                        mutateChange: async (key, value) => {
                            setState({ Image: `${value}` });
                            return { [key]: value as string };
                        },
                        ...((isMedia && !item.video && displayThumbnail) ? {
                            renderLeft:
                                <>
                                    <ArticleFileUpload
                                        disabled={isDisabled}
                                        style={{ flexBasis: "20%" }}
                                        title="Upload File"
                                        allowVideoCuration={item.cid === "ALL_CONSUMERS"}
                                        isMedia
                                        onValidUpload={entries => {
                                            const { uploadedFile, fileUpload, uploadedImageURL, thumbnail, ...article } = entries;
                                            setState({ uploadedFile, fileUpload, thumbnail });
                                            if (fileUpload?.length) {
                                                const { type: fileType } = fileUpload![0];
                                                setStateManual({
                                                    [fileType.includes("video") ? "videoUrl" : "Image"]: uploadedImageURL,
                                                    [fileType.includes("video") ? "video" : "addedImage"]: true,
                                                    ...article
                                                });
                                            }
                                        }}
                                        article={item}
                                        setStateManual={setStateManual}
                                    />
                                </>
                        } : null),
                        ...(item.video || !isMedia ? {
                            renderRight:
                                <Link
                                    disabled={isDisabled}
                                    style={{ textDecoration: "underline", flexBasis: "20%", textAlign: "right" }}
                                    onClick={() => {
                                        setStateManual({
                                            addedImage: false,
                                            video: false,
                                            Image: ""
                                        });
                                        setState({ uploadedFile: false, fileUpload: null, customThumbnailUrl: "", customThumbnailFile: null });
                                    }}
                                >
                                    Clear
                                </Link>
                        } : null)
                    }
                } : {
                    Image: {
                        label: "Article Image",
                        hidden,
                        type: "EditableInput" as const,
                        placeholder: " ",
                        disabled: true,
                        mutateChange: async (key, value) => {
                            setState({ Image: `${value}` });
                            return { [key]: value as string };
                        },
                        description: `Please upload article/PDF first and then upload one of the two options:\n\nImage: File must be .png or .jpg and under 5MB. If the URL field is not blank, the uploaded image will replace the article image.\n\nGIF: File must be .gif and under 6 MB. If a GIF is added, the URL field must be blank.\n\nNote: Articles require a rectangular image, working best with a 1.91:1 aspect ratio.`,
                        ...(!uploadedFile && {
                            formatted:
                                <Link href={stripGV6Query(item.Image || "")} target="_blank">
                                    {item.Image}
                                </Link>
                        }),
                        ...({
                            renderAbove:
                                <div id="pdf_thumbnail" style={{ alignSelf: "center", margin: "20px" }}>
                                    {(item.Link?.includes(".pdf") || customPdfFile) && item.Image && <img src={item.Image.includes("grapevinesix") ? item.Image : fileUpload?.length ? URL.createObjectURL(fileUpload![0]) : null} style={{ maxWidth: "75px", maxHeight: "75px", padding: "5px", marginLeft: "35px" }} />}
                                    <br />
                                    <Link
                                        disabled={(item.Link || customPdfFile) ? isDisabled : true}
                                        style={{ textDecoration: "underline", marginLeft: "auto", padding: "5px 0px" }}
                                        onClick={() => setState({ imageModal: true })}
                                    >
                                        Replace with custom image
                                    </Link>
                                </div>
                        }),
                        renderBelow:
                            <ArticleImageModal
                                closeAction={() => setState({ imageModal: false })}
                                updateImageURL={entries => {
                                    const { uploadedFile, fileUpload, ...article } = entries;
                                    setState({ uploadedFile, fileUpload });
                                    setStateManual({ ...article, Images: [] });
                                }}
                                open={imageModal}
                                imageURL={item.Image}
                                allowVideoCuration={false}
                                addedImage={item.addedImage}
                                video={item.video}
                                Link={item.Link}
                                uploadedFile={uploadedFile}
                            />
                    }
                }),
            Terms: {
                label: "Terms",
                hidden: true,
            },
            HashtagsAndMentions: {
                label: "Hashtags And Mentions",
                hidden: true,
            },
            displayAdvisorNote: {
                label: "Display notes",
                hidden: true,
            },
            notes: {
                label: "Notes",
                hidden: true,
            },
            sharingPermissions: {
                label: "Sharing Permissions",
                hidden: true
            },
            Thumbnail: {
                label: "Video Thumbnail",
                hidden: true,
            }
        };
    }
    const isSharingPermissionHidden = hidden || !client.allowContentSpecificPermissions || item.sid == "" || (item.sid && client.streams[item.sid].type == "HELP")
    const isSocialNetworkPermissionHidden = isSharingPermissionHidden || !client.allowNetworkRestrictions || !item.sharingPermissions.social
    return {
        comment: {
            disabled: isDisabled,
            hidden,
            label: "Comments",
            type: "EditableInput" as const,
            max: item.commentURL && item.commentURL.length ? facebookMaxLength - commentURLlength : facebookMaxLength,
            customClasses: "editable-textarea comment-textarea",
            placeholder: " ",
            hideTotals: true,
            onOutsideClick: async (e: dynamic<any>, id: string) => {
                if (!e.target.closest(".option-item") && (getState()?.typeaheadOptions?.length || getState()?.currentMentionToFill?.length)) {
                    await setState({ currentMentionToFill: "", typeaheadOptions: [] });
                }
            },
            mutateChange: async (key, value) => {
                if (client.socialNetworks.LinkedIn.available && client.enableLinkedInMentions) {
                    let { mentionsToAdd } = getState();
                    const oldMentionsToAdd = mentionsToAdd;
                    mentionsToAdd = (mentionsToAdd ?? []).filter(mention => {
                        return value.includes(`@${mention.mention}`);
                    });
                    if (mentionsToAdd?.length !== oldMentionsToAdd?.length) {
                        setState({ mentionsToAdd });
                    }
                    const textarea = document.querySelector(".comment-textarea")?.firstChild as HTMLTextAreaElement;
                    if (textarea) {
                        let lastTypedCharIndex = textarea.selectionStart === 0 ? textarea.selectionStart : textarea.selectionStart - 1;
                        let textSelection = "";
                        if (lastTypedCharIndex >= 0 && lastTypedCharIndex < textarea.value.length) {
                            textSelection = `${textarea.value[lastTypedCharIndex]}`;
                        }
                        while (lastTypedCharIndex > 0 && textSelection.split(" ").length <= 2) {
                            lastTypedCharIndex--;
                            textSelection = `${textarea.value[lastTypedCharIndex]}${textSelection}`;
                            if (textSelection[0] === '@') {
                                break;
                            }
                        }
                        if (textSelection[0] === '@') {
                            const slicedSelection = textSelection.slice(1);
                            if (slicedSelection.match(/[a-zA-Z.`-]+\s?[a-zA-Z.`-]+/) && validateMention(slicedSelection)){
                                const debouncedMentionsTypeahead = debounce(async () => {
                                    await doGetMentionsTypeahead(slicedSelection).then(result => {
                                        if (result?.length) {
                                            setState({currentMentionToFill: textSelection, typeaheadOptions: result});
                                        } else {
                                            setState({currentMentionToFill: "", typeaheadOptions: []});
                                        }
                                    })
                                }, 500);
                                await debouncedMentionsTypeahead();
                            } else {
                                setState({currentMentionToFill: "", typeaheadOptions: []});
                            }
                        }
                    }
                }
                // update the first suggested comment to match the main comment
                let suggestedComments = typeof item.suggestedComments == "object" && item.suggestedComments.length ? item.suggestedComments : null;
                if (suggestedComments) suggestedComments[0] = value;
                return { [key]: value as string, ...(suggestedComments ? { suggestedComments } : null) };
            },
            renderAbove:
                <div className="permission__column">
                    { client?.allowNetworkSuggestedComments && (
                            <div>
                                <div style={{ padding: "5px 0px", marginLeft: "65%" }}>
                                    <Link
                                        disabled={isDisabled || selectedCommentOption === 'multiple'}
                                        style={{ textDecoration: "underline", marginRight: "3px" }}
                                        onClick={handleNetworkCommentsClick}
                                    >
                                        Network Comments
                                    </Link>
                                    <Tooltip>This option will be disabled if your chosen stream allows for autopost or if you have filled in values within the link for "Multiple Comments". Setting values here will overwrite anything you currently have within the "Comments" field.</Tooltip>
                                </div>
                                <MultipleCommentsModal
                                    disableSuggestions={isMedia || !client.aiGeneratedAdminSuggestions}
                                    className="modal-modal-background"
                                    open={multipleCommentsModal}
                                    client={client}
                                    url={item.Link || ""}
                                    lang={client && client.streams[item.sid] ? client.streams[item.sid].lang : "en"}
                                    facebookMaxLength={facebookMaxLength}
                                    linkedInMaxLength={linkedInMaxLength}
                                    twitterMaxLength={twitterMaxLength}
                                    instaMaxLength={instaMaxLength}
                                    suggestedComments={(item.suggestedComments as string[]) || []}
                                    comment={item.comment || ""}
                                    commentURL={item.commentURL}
                                    commentURLLength={commentURLlength}
                                    update={(suggestedComments, liMentions) => {
                                        const suggested = suggestedComments.filter(item => item.comment.length);
                                        const mentions = liMentions.filter(item => item.mentionsToAdd?.length && suggested[0]?.index === item.commentIndex)[0].mentionsToAdd || [];
                                        const suggestedMentions = liMentions.map(mention => {
                                            return { index: mention.commentIndex!, mentions: mention.mentionsToAdd || [] };
                                        });
                                        setState({ mentionsToAdd: mentions, selectedCommentOption: suggested?.length ? "multiple" : "" });
                                        setStateManual({
                                            suggestedComments: suggested.map(item => item.comment),
                                            suggestedCommentMentions: suggestedMentions,
                                            comment: suggested && suggested.length ? suggested[0].comment : "",
                                            networkSuggestedComments: {LinkedIn: "",
                                                Facebook: "",
                                                Twitter: "",
                                                IG: "",
                                                WebShare: ""}
                                        });
                                    }}
                                    close={() => setState({ multipleCommentsModal: false })}
                                    quotes={quotes}
                                    addedImage={item.addedImage}
                                    image={item.Image || ""}
                                    sURLDomain={sURLDomain || ""}
                                    suggestedCommentMentions={item.suggestedCommentMentions && item.suggestedCommentMentions}
                                    mentionsToAdd={getState()?.mentionsToAdd && getState().mentionsToAdd}
                                    hashtagsAndMentions={item.HashtagsAndMentions}
                                />
                                <NetworkCommentsModal
                                    disableSuggestions={isMedia || !client.aiGeneratedAdminSuggestions}
                                    className="modal-modal-background"
                                    open={networkCommentsModal}
                                    client={client}
                                    url={item.Link || ""}
                                    lang={client && client.streams[item.sid] ? client.streams[item.sid].lang : "en"}
                                    facebookMaxLength={facebookMaxLength}
                                    linkedInMaxLength={linkedInMaxLength}
                                    twitterMaxLength={twitterMaxLength}
                                    instaMaxLength={instaMaxLength}
                                    comment={item.comment || ""}
                                    commentURL={item.commentURL}
                                    commentURLLength={commentURLlength}
                                    close={() => setState({ networkCommentsModal: false })}
                                    quotes={quotes}
                                    addedImage={item.addedImage}
                                    image={item.Image || ""}
                                    sURLDomain={sURLDomain || ""}
                                    networkSuggestedComments={client?.allowNetworkSuggestedComments ? networkSuggestedComments : ""}
                                    socialUpdate={(ntwrksuggestedComments, liMentions) => {
                                        setStateManual({ networkSuggestedComments: ntwrksuggestedComments,
                                            suggestedComments: [],
                                            comment: ""
                                        });
                                        setState({ selectedCommentOption: (typeof ntwrksuggestedComments === "object" && Object.values(ntwrksuggestedComments).some(ntwrk => ntwrk.length)) ? "network" : "", mentionsToAdd: liMentions, ntwrkSuggestedWithMentions: !!liMentions?.length });
                                    }}
                                    hashtagsAndMentions={item.HashtagsAndMentions}
                                    linkedinMentions={item.linkedinMentions || []}
                                />
                            </div>
                            )}
                            <div>
                            {/* {client.aiGeneratedAdminSuggestions && client.aiGeneratedAdminSuggestionsWithChatGPT && (<div style={{ padding: "5px 0px", marginLeft: "67%" }} >
                                    <Link
                                        disabled={isDisabled}
                                        style={{ textDecoration: "underline" }}
                                        onClick={
                                            () => {setState({ generateAICommentsModal: true })}
                                        }
                                    >
                                        Generate AI Comment
                                    </Link>
                                </div>)} */}
                                <div style={{ padding: "5px 0px", marginLeft: "65%" }}>
                                    <Link
                                        disabled={isDisabled || selectedCommentOption === 'network'}
                                        style={{ textDecoration: "underline", marginRight: "5px" }}
                                        onClick={handleMultipleCommentsClick}
                                    >
                                        Multiple Comments
                                    </Link>
                                    <Tooltip>This option will be disabled if you have filled in values within the link for "Network Comments". Setting values here will overwrite anything you currently have within the "Comments" field.</Tooltip>
                                </div>
                                {!isMedia && client.aiGeneratedAdminSuggestions && <GenerateAIComments
                                    disabled={isDisabled}
                                    className="modal-modal-background"
                                    open={generateAICommentsModal}
                                    changePref={changePref}
                                    close={()=>setState({generateAICommentsModal: false, changePref: false })}
                                    client={client}
                                    link={item.Link}
                                    sid={item.sid}
                                    setQuote={(quotes) => setState({ quotes })}
                                    aiCommentPref={aiCommentPref ? aiCommentPref : null}
                                    setComment={(cmnt) => setStateManual({ comment: cmnt } )}
                                    comment={""}
                                    selectedCmnt={undefined}
                                    hasComment={!!item.comment}
                                    confirmReplace={false}
                                    noCommentAvail={false}
                                />}
                                {/* {!isMedia && client.aiGeneratedAdminSuggestions && <SuggestedComments
                                    disabled={isDisabled}
                                    quotes={quotes}
                                    setQuote={(comment: string) => setStateManual({ comment })}
                                />} */}
                                <MultipleCommentsModal
                                    disableSuggestions={isMedia || !client.aiGeneratedAdminSuggestions}
                                    className="modal-modal-background"
                                    open={multipleCommentsModal}
                                    client={client}
                                    url={item.Link || ""}
                                    lang={client && client.streams[item.sid] ? client.streams[item.sid].lang : "en"}
                                    facebookMaxLength={facebookMaxLength}
                                    linkedInMaxLength={linkedInMaxLength}
                                    twitterMaxLength={twitterMaxLength}
                                    instaMaxLength={instaMaxLength}
                                    suggestedComments={(item.suggestedComments as string[]) || []}
                                    comment={item.comment || ""}
                                    commentURL={item.commentURL}
                                    commentURLLength={commentURLlength}
                                    update={(suggestedComments, liMentions) => {
                                        const suggested = suggestedComments.filter(item => item.comment.length);
                                        const mentions = liMentions.filter(item => item.mentionsToAdd?.length && suggested[0]?.index === item.commentIndex)[0]?.mentionsToAdd || [];
                                        const suggestedMentions = liMentions.map(mention => {
                                            return { index: mention.commentIndex!, mentions: mention.mentionsToAdd || [] };
                                        });
                                        setState({ mentionsToAdd: mentions, selectedCommentOption: suggested?.length ? "multiple" : "" });
                                        setStateManual({
                                            suggestedComments: suggested.map(item => item.comment),
                                            comment: suggested && suggested.length ? suggested[0].comment : "",
                                            suggestedCommentMentions: suggestedMentions,
                                            networkSuggestedComments: {LinkedIn: "",
                                                Facebook: "",
                                                Twitter: "",
                                                IG: "",
                                                WebShare: ""}
                                        });
                                    }}
                                    close={() => setState({ multipleCommentsModal: false })}
                                    quotes={quotes}
                                    addedImage={item.addedImage}
                                    image={item.Image || ""}
                                    sURLDomain={sURLDomain || ""}
                                    suggestedCommentMentions={item.suggestedCommentMentions && item.suggestedCommentMentions}
                                    mentionsToAdd={getState()?.mentionsToAdd && getState().mentionsToAdd}
                                    hashtagsAndMentions={item.HashtagsAndMentions}
                                />
                            </div>


                </div>,
            renderBelow:
            <div>
                {getState().mentionsToAdd?.length && !getState().ntwrkSuggestedWithMentions ? (
                    <LIMentionedList mentions={getState().mentionsToAdd} />
                ) : ""}
                {getState().typeaheadOptions?.length ? (
                    <LIMentionTypeaheadOptionsList options={getState().typeaheadOptions} selectMention={selectMention} />
                ) : ""}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Link
                        disabled={isDisabled}
                        style={{ textDecoration: "underline", padding: "5px 0px" }}
                        onClick={() => setStateManual({ comment: "" })}
                    >
                        Clear
                    </Link>
                    <SocialLimits
                        client={client}
                        facebookMaxLength={facebookMaxLength}
                        linkedInMaxLength={linkedInMaxLength}
                        twitterMaxLength={twitterMaxLength}
                        url={item.Link || ""}
                        comment={getCommentWithFields(item.comment, item.commentURL, item.HashtagsAndMentions)}
                        addedImage={item.addedImage}
                        image={item.Image || ""}
                        sURLDomain={sURLDomain || ""}
                    />
                </div>
                <div>
                    {client.aiGeneratedAdminSuggestions && client.aiGeneratedAdminSuggestionsWithChatGPT && !isMedia && item.Link && (<div style={{ padding: "5px 0px", marginLeft: aiCommentPref ? "35%" : "67%" }} >
                        <span>
                            <img
                                className="filterDarkBlue"
                                style={{ display: "inline-block", width: "20px" }}
                                src="/img/stars.png"
                            ></img>
                            <Link
                                disabled={isDisabled}
                                className="darkBlue"
                                style={{ cursor: "pointer" }}
                                onClick={() => { setState({ generateAICommentsModal: true, changePref: aiCommentPref ? false : true })}}
                            >
                                Generate comment
                            </Link>
                        </span>
                        {aiCommentPref && (<span style={{ borderLeft: "1px solid lightgrey", fontWeight: "bold", paddingLeft: "5px", cursor: "pointer" }}
                            onClick={() => { setState({ generateAICommentsModal: true, changePref: true })}}>
                            Change preferences
                        </span>)}
                    </div>)}
                </div>
            </div>
        },
        commentURL: {
            label: "URL in Comment",
            type: "EditableInput" as const,
            hidden: isMedia ? false : true,
            description: `This link will be converted to a trackable URL and appended to the end of the comment.\n Only available for media content. Note that if a URL in Comment is included,\n the share will be reported under the type "article" and the Title will be visible in the app.`,
            placeholder: " ",
        },
        Title: {
            ...(isMedia && {
                description: "The title will not be visible in the application, unless the content is a video.\n Video titles are also displayed on LinkedIn."
            }),
            hidden,
            disabled: isDisabled || !client.allowCustomEdit,
            label: isMedia ? "Title" : "Article Title",
            required: true,
            type: "EditableInput" as const,
            max: 200,
            placeholder: " ",
            hideTotals: true
        },
        Link: {
            label: client.allowPdfCuration ? "Article/PDF" : "Article URL",
            ...(client.allowPdfCuration ? { description: `Please insert one of the 2 options:\n\nArticle URL: A URL to an article can be added in the text field on the right.\n\nPDF: A single PDF can be added by file upload or by URL. If uploading, the file must be .pdf and maximum 60MB.\nIf a PDF is added, a custom article image is required.` } : null),
            required: true,
            ...(client.allowPdfCuration && !customPdfFile ? { customClasses: "white-space-normal" } : null),
            placeholder: " ",
            type: "EditableInput" as const,
            hidden: !client.allowCustomEdit || isMedia || props.item.type == "Text" || props.item.type == "Email Template",
            disabled: isDisabled,
            ...(
                client.allowPdfCuration ? {
                    ...(
                        !customPdfFile && !item.Link ? {
                            renderLeft:
                                <>
                                    <ArticleFileUpload
                                        style={{ flexBasis: "auto" }}
                                        title="Upload File"
                                        isMedia
                                        isPdf={true}
                                        onValidUpload={entries => {
                                            const { customPdfFile } = entries;
                                            if (customPdfFile) {
                                                setState({ customPdfFile });
                                                setStateManual({ Link: customPdfFile.name });
                                            }
                                        }}
                                        article={item}
                                        disabled={isDisabled}
                                    />
                                    <h4 style={{ flexBasis: "auto", textAlign: "center", margin: "5px" }}>OR:</h4>
                                </>
                        } : {
                            ...(item.Link ? {
                                clearField:
                                    <Button
                                        style={{ backgroundColor: '#efefef', height: '100%' }}
                                        onClick={() => {
                                            setStateManual({
                                                Link: "",
                                                Image: ""
                                            });
                                            setState({ customPdfFile: null });
                                        }}
                                    ><i className={`fa fa-times`} style={{ color: '#676a6c' }}></i></Button>,
                                formatted:
                                    <Link href={stripGV6Query(item.Link || "")} target="_blank">
                                        {item.Link}
                                    </Link>,
                            } : null)
                        }
                    )
                } : {
                    clearField:
                        <Button
                            style={{ backgroundColor: '#efefef', height: '100%' }}
                            onClick={() => {
                                setStateManual({ Link: "" });
                                setState({ customPdfFile: null });
                            }}
                        ><i className={`fa fa-times`} style={{ color: '#676a6c' }}></i></Button>,
                    formatted:
                        <Link href={stripGV6Query(item.Link || "")} target="_blank">
                            {item.Link}
                        </Link>,
                }
            ),
            mutateChange: async (key, value) => {
                setState({
                    Link: `${value}`
                    // quotes: await _loadSuggestedComments(client, { ...item, Link: `${value}` })
                });
                return { [key]: value as string };
            },
        },
        ...(isMedia ?
            (!item.video && (!item.Image || !item.Image.match(/.(jpg|jpeg|png|gif|mp4)/i))) ? {
                Image: {
                    label: `${client.allowVideoCuration ? "Image/Video" : "Image"}`,
                    hidden: hidden || !client.allowCustomEdit,
                    disabled: isDisabled,
                    type: "EditableInput" as const,
                    description: `Please insert one of the ${client.allowVideoCuration ? "three" : "two"} options:\n\nImage: A single image can be added by URL. Up to 4 images can be added by file upload.\n\t File must be .png or .jpg and maximum 5MB.\n\n GIF: File must be .gif and under 5MB${client.allowVideoCuration ? "\n\n Video: All video files must be .mp4/m4v, and must be at least 3 seconds, and;\n Instagram: Length cannot exceed 10 minutes, max file size of 100MB,\n\t max width of 1920px, and must have an aspect ratio between 16:9 and 4:5\nX (Twitter): Length cannot exceed 2:20 minutes, max file size of 512MB,\n\t and must have an aspect ratio between 16:9 and 9:16\n Other Social Networks: Length cannot exceed 10 minutes,\n\t max file size of 650MB, and must have an aspect ratio between 16:9 and 9:16.\nOnly 1 video may be added." : ""}\n\nTo insert a PDF, go to Create Article`,
                    placeholder: `${client.allowVideoCuration ? "Image/Video" : "Image"} URL`,
                    mutateChange: async (key, value) => {
                        setState({ Image: `${value}` });
                        return { [key]: value as string };
                    },
                    renderLeft:
                        <>
                            <ArticleFileUpload
                                style={{ flexBasis: "20%" }}
                                title="Upload File"
                                allowVideoCuration={client.allowVideoCuration}
                                isMedia
                                onValidUpload={entries => {
                                    const { uploadedFile, fileUpload, uploadedImageURL, thumbnail, videoDuration, ...article } = entries;
                                    setState({ uploadedFile, fileUpload, thumbnail, videoDuration });
                                    if (fileUpload?.length) {
                                        const { type: fileType } = fileUpload![0];
                                        setStateManual({
                                            [fileType.includes("video") ? "videoUrl" : "Image"]: uploadedImageURL,
                                            [fileType.includes("video") ? "video" : "addedImage"]: true,
                                            ...article
                                        });
                                    };
                                }}
                                article={item}
                                setStateManual={setStateManual}
                                disabled={isDisabled}
                                hasInstagram={client.socialNetworks.IG?.available}
                            />
                            <h4 style={{ flexBasis: "20%", textAlign: "center", margin: "0" }}>OR:</h4>
                        </>,
                    ...(((videoDuration && uploadedFile) || parseInt(item.videoDuration)) ? {
                        renderBelow: <>
                            {renderSocialNetworkVideoUploadList()}
                        </>
                    } : {})
                }
            } : {
                Image: {
                    label: `${client.allowVideoCuration ? "Image/Video" : "Image"}`,
                    value: displayThumbnail ? " " : item.videoUrl || item.Image || "",
                    hidden,
                    disabled: isDisabled,
                    ...(item.video || !isMedia ? { customClasses: "white-space-normal" } : null),
                    ...(displayThumbnail ? { customClasses: "flex-0" } : null),
                    placeholder: `${client.allowVideoCuration ? "Image/Video" : "Image"} URL`,
                    type: displayThumbnail ? "plainText" as const : "EditableInput" as const,
                    description: `Please insert one of the ${client.allowVideoCuration ? "three" : "two"} options:\n\nImage: A single image can be added by URL. Up to 4 images can be added by file upload. File must be .png or .jpg and maximum 5MB.\n\nGIF: File must be .gif and under 5 MB${client.allowVideoCuration ? "\n\nVideo: All video files must be .mp4/m4v, and must be at least 3 seconds, and;\n\tInstagram: Length cannot exceed 10 minutes, max file size of 100MB, max width of 1920px, and must have an aspect ratio between 16:9 and 4:5\n\tX (Twitter): Length cannot exceed 2:20 minutes, max file size of 512MB, and must have an aspect ratio between 16:9 and 9:16\n\tOther Social Networks: Length cannot exceed 10 minutes, max file size of 650MB, and must have an aspect ratio between 16:9 and 9:16.\nOnly 1 video may be added." : ""}`,
                    mutateChange: async (key, value) => {
                        setState({ Image: `${value}` });
                        return { [key]: value as string };
                    },
                    ...((isMedia && !item.video && displayThumbnail) ? {
                        renderLeft:
                            <>
                                <ArticleFileUpload
                                    disabled={isDisabled || !client.allowCustomEdit}
                                    style={{ flexBasis: "20%" }}
                                    title="Upload File"
                                    allowVideoCuration={client.allowVideoCuration}
                                    isMedia
                                    onValidUpload={entries => {
                                        const { uploadedFile, fileUpload, videoDuration, uploadedImageURL, thumbnail, ...article } = entries;
                                        setState({ uploadedFile, fileUpload, thumbnail, videoDuration });
                                        if (fileUpload?.length) {
                                            const { type: fileType } = fileUpload![0];
                                            setStateManual({
                                                videoDuration,
                                                [fileType.includes("video") ? "videoUrl" : "Image"]: uploadedImageURL,
                                                [fileType.includes("video") ? "video" : "addedImage"]: true,
                                                ...article
                                            });
                                        }
                                    }}
                                    article={item}
                                    setStateManual={setStateManual}
                                    hasInstagram={client.socialNetworks.IG?.available}
                                />
                            </>
                    } : null),
                    ...(item.video || !isMedia ? {
                        renderRight:
                            <Link
                                disabled={isDisabled || !client.allowCustomEdit}
                                style={{ textDecoration: "underline", flexBasis: "20%", textAlign: "right" }}
                                onClick={() => {
                                    setStateManual({
                                        addedImage: false,
                                        video: false,
                                        Image: ""
                                    });
                                    setState({ uploadedFile: false, fileUpload: null, customThumbnailUrl: "", customThumbnailFile: null });
                                }}
                            >
                                Clear
                            </Link>,
                        ...(((videoDuration && uploadedFile) || parseInt(item?.videoDuration)) ? {
                            renderBelow: <>
                                {renderSocialNetworkVideoUploadList()}
                            </>
                        } : {})
                    } : null)
                }
            } : {
                Image: {
                    label: "Article Image",
                    hidden,
                    type: "EditableInput" as const,
                    placeholder: " ",
                    disabled: true,
                    mutateChange: async (key, value) => {
                        setState({ Image: `${value}` });
                        return { [key]: value as string };
                    },
                    description: `Please upload article/PDF first and then upload one of the two options:\n\nImage: File must be .png or .jpg and under 5MB. If the URL field is not blank, the uploaded image will replace the article image.\n\nGIF: File must be .gif and under 6 MB. If a GIF is added, the URL field must be blank.\n\nNote: Articles require a rectangular image, working best with a 1.91:1 aspect ratio.`,
                    ...(!uploadedFile && {
                        formatted:
                            <Link href={stripGV6Query(item.Image || "")} target="_blank">
                                {item.Image}
                            </Link>
                    }),
                    ...(client.allowCustomEdit && {
                        renderAbove:
                            <div id="pdf_thumbnail" style={{ alignSelf: "center", margin: "20px" }}>
                                    {(item.Link?.includes(".pdf") || customPdfFile) &&
                                    (item.Image
                                        ? <div>
                                            <img src={item.Image.includes("grapevinesix") ? item.Image : fileUpload?.length ? URL.createObjectURL(fileUpload![0]) : null} style={{ maxWidth: "75px", maxHeight: "75px", padding: "5px", marginLeft: "35px" }} />
                                            <br />
                                        </div>
                                        : <div style={{ padding: "5px", marginLeft: "35px", maxHeight: "55px", overflow: "hidden"}}>
                                            <Document file={customPdfFile}>
                                                <Thumbnail pageNumber={1} scale={0.15}/>
                                                {/* Thumbnail element not efficient, hard to style further. See ticket #14614 */}
                                            </Document>
                                        </div>)}
                                <Link
                                    disabled={(item.Link || customPdfFile) ? isDisabled : true}
                                    style={{ textDecoration: "underline", marginLeft: "auto", padding: "5px 0px" }}
                                    onClick={() => setState({ imageModal: true })}
                                >
                                    Replace with custom image
                                </Link>
                            </div>
                    }),
                    ...(client.allowCustomEdit && {
                        clearField:
                            <Button
                                style={{ backgroundColor: '#efefef', height: '100%' }}
                                onClick={() => {
                                    setStateManual({
                                        addedImage: false,
                                        Image: "",
                                        ...(item.Images && item.Images.length > 0 ? { Images: item.Images.slice(1) } : null)
                                    });
                                    setState({ uploadedFile: false, fileUpload: null, Image: null });
                                }}
                            ><i className={`fa fa-times`} style={{ color: '#676a6c' }}></i></Button>
                    }),
                    renderBelow:
                        <ArticleImageModal
                            closeAction={() => setState({ imageModal: false })}
                            updateImageURL={entries => {
                                const { uploadedFile, fileUpload, ...article } = entries;
                                setState({ uploadedFile, fileUpload });
                                setStateManual({ ...article, Images: [] });
                            }}
                            open={imageModal}
                            imageURL={item.Image}
                            allowVideoCuration={false}
                            addedImage={item.addedImage}
                            video={item.video}
                            Link={item.Link}
                            uploadedFile={uploadedFile}
                            hasInstagram={!!client.socialNetworks.IG?.available}
                        />
                }
            }),
        Thumbnail: {
            label: "Video Thumbnail",
            description: "Thumbnails are only shown within the app for videos, as social network shares are embedded videos",
            type: "EditableInput" as const,
            placeholder: " ",
            hidden: !item.video,
            customClasses: "Select-arrow-zone",
            renderAbove:

                <div id="video_thumbnail" style={{ alignSelf: "center", margin: "20px" }}>
                    {((thumbnail && thumbnail.length) || (item.video && item.videoUrl && item.Image)) && <img src={customThumbnailUrl && customThumbnailUrl.length ? customThumbnailUrl : customThumbnailFile ? URL.createObjectURL(customThumbnailFile) : item.videoUrl && item.Image ? item.Image : thumbnail} style={{ maxWidth: "75px", maxHeight: "75px", padding: "5px", marginLeft: "35px" }} />}
                    <br /><Link onClick={() => setState({ thumbnailModal: true })} style={{ textDecoration: "underline" }}>Change video thumbnail</Link>
                    <ThumbnailModal
                        className="modal-modal-background"
                        open={thumbnailModal}
                        defaultThumbnail={item.video && item.videoUrl ? item.Image : thumbnail}
                        customThumbnailUrl={customThumbnailUrl}
                        customThumbnailFile={customThumbnailFile}
                        update={entries => {
                            const { thumbnailUrl, thumbnailFile } = entries;
                            setState({
                                customThumbnailUrl: thumbnailUrl,
                                customThumbnailFile: thumbnailFile
                            });
                        }}
                        close={() => setState({ thumbnailModal: false })}
                        item={item}
                        removeCustomThumbnail={() => setStateManual({ Image: item.defaultThumbnail })}
                    />
                </div>
        },
        Terms: {
            label: `Terms (${(item.Terms || []).length}/10)`,
            hidden,
            type: "creatable" as const,
            placeholder: "Please enter some terms",
            disabled: isDisabled || !client.allowCustomEdit,
            components: { ValueContainer: _ValueContainer },
            customClasses: "form__value list-of-tags select-auto-suggest",
            promptTextCreator: userInput => `Create term "${userInput}"`,
            options: totals.terms.map(x => ({ value: x, label: x })),
            noResultsText: "This term has already been created",
            onInputKeyDown: e => {
                if (e.which == 13 || e.which == 9) {
                    if (
                        item.Terms &&
                        (item.Terms as string[]).filter(
                            term =>
                                term.toLowerCase() ===
                                (e.target as EventTarget & HTMLInputElement).value.toLowerCase()
                        )
                    ) {
                        createAlert("This term has already been added", "error");
                    }
                }
            },
            canUpdate: (userTerm, terms) => {
                if (!userTerm) {
                    return false;
                }
                if (terms && terms.length >= 10) {
                    createAlert(
                        "You have reached the maximum amount of terms. Please delete some terms in order to add more",
                        "error"
                    );
                    return false;
                }
                if (`${userTerm}`.length > 50) {
                    createAlert("This term is too long.", "error");
                    return false;
                }
                if (terms.filter(term => term.toLowerCase() === userTerm.toLowerCase()).length) {
                    createAlert("This term has already been added", "error");
                    return false;
                }
                return true;
            },
            ...(totals.terms && totals.terms.length && {
                renderAbove:
                    <div className="permission__column">
                        <Link
                            disabled={!item.sid && !isNotification}
                            style={{ textDecoration: "underline", marginLeft: "auto", padding: "5px 0px" }}
                            onClick={() => item.sid != "" && setState({ termsModal: true })}
                        >
                            Terms library
                        </Link>
                        <LibraryModal
                            title={"Terms"}
                            open={termsModal}
                            client={client}
                            oldTerms={
                                item.Terms
                                    ? (item.Terms as string[])
                                    : (item.Terms as string).split(",")
                            }
                            allTerms={totals.terms}
                            limit={10}
                            update={Terms => {
                                setStateManual({ Terms });
                            }}
                            close={() => setState({ termsModal: false })}
                        />
                    </div>
            })
        },
        HashtagsAndMentions: {
            label: `Hashtags & Legacy Mentions (${(item.HashtagsAndMentions || []).length}/10)`,
            hidden,
            description: "Stream Hashtags and Mentions will be pre-populated. Mentions added in the comment will not appear in this list or dropdown menu.",
            type: "creatable" as const,
            placeholder: "Please start typing with a # or @",
            disabled: isDisabled || !client.allowCustomEdit,
            components: { ValueContainer: _ValueContainer },
            customClasses: "form__value list-of-tags select-auto-suggest",
            promptTextCreator: userInput => `Create hashtag/mention ${userInput}`,
            noResultsText: "This item has already been created",
            formatValue: value => ({
                value,
                label: value,
                className: `select-${value.indexOf("#") == 0 ? "hashtag" : "mention"}`
            }),
            mutateChange: async (key, value) => ({
                [key]: value,
                Hashtags: (value as string[]).filter(i => i.indexOf('#') == 0).map(x => x.replace(/^#/, "")),
                Mentions: (value as string[]).filter(i => i.indexOf('@') == 0).map(x => x.replace(/^@/, ""))
            }),
            options: ([] as Array<{ label: string; value: string; className?: string; }>).concat(
                ...totals.hashtags.map(x => ({
                    value: `#${x}`,
                    label: `#${x}`,
                    className: "select-hashtag"
                })),
                ...totals.mentions.map(x => ({
                    value: `@${x}`,
                    label: `@${x}`,
                    className: "select-mentions"
                }))
            ),
            onInputKeyDown: e => {
                if (e.which == 13 || e.which == 9) {
                    if (
                        item.HashtagsAndMentions &&
                        item.HashtagsAndMentions.filter(
                            hashtag =>
                                hashtag.toLowerCase() ===
                                (e.target as EventTarget & HTMLInputElement).value.toLowerCase()
                        )
                    ) {
                        createAlert(
                            `This ${(e.target as EventTarget & HTMLInputElement).value.indexOf("#") === -1
                                ? "mention"
                                : "hashtag"
                            } has already been added`,
                            "error"
                        );
                    }
                }
            },
            canUpdate: (userTerm, _values) => {
                const sanitizedTerm = sanitizeHtml(userTerm);
                if (!sanitizedTerm) {
                    return false;
                }
                if (_values.length >= 10) {
                    createAlert(
                        "You have reached the maximum amount of hashtags & mentions. Please delete some hashtags or mentions in order to add more",
                        "error"
                    );
                    return false;
                }
                if (sanitizedTerm.indexOf("#") === -1 && sanitizedTerm.indexOf("@") === -1) {
                    createAlert(`You must start your hashtag/mention with a '#' or '@'`, "error");
                    return false;
                }
                // +1 for # or @
                if (sanitizedTerm.length > 33) {
                    createAlert(
                        `The ${sanitizedTerm.indexOf("#") == 0 ? "hashtag" : "mention"
                        } ${sanitizedTerm} is too long. Please limit them to 32 characters`,
                        "error"
                    );
                    return false;
                }

                if (_hasMentionOrHashtag(_formatHashtagOrMention(sanitizedTerm), item.HashtagsAndMentions)) {
                    createAlert(
                        `The ${sanitizedTerm.indexOf("#") ? "hashtag" : "mention"
                        } ${sanitizedTerm} has already been added`,
                        "error"
                    );
                    return false;
                }
                return true;
            },
            ...(((totals.hashtags && totals.hashtags.length) || (totals.mentions && totals.mentions.length)) && {
                renderAbove:
                    <div className="permission__column">
                        <Link
                            disabled={!item.sid && !isNotification}
                            style={{ textDecoration: "underline", marginLeft: "auto", padding: "5px 0px" }}
                            onClick={() => item.sid != "" && setState({ hashtagsModal: true })}
                        >
                            Hashtags & Mentions library
                        </Link>
                        <HashtagMentionModal
                            title={"Hashtags & Mentions"}
                            open={hashtagsModal}
                            client={client}
                            oldHashtags={item.Hashtags as string[] || []}
                            allHashtags={totals.hashtags}
                            oldMentions={item.Mentions as string[] || []}
                            allMentions={totals.mentions}
                            limit={10}
                            update={(Hashtags, Mentions) => {
                                setStateManual({
                                    Hashtags,
                                    Mentions,
                                    HashtagsAndMentions: Hashtags.map(i => `#${i}`).concat(Mentions.map(i => `@${i}`))
                                });
                            }}
                            close={() => setState({ hashtagsModal: false })}
                        />
                    </div>
            })
        },
        LockHashtagsAndMentions: {
            label: "Lock Hashtags & Legacy Mentions",
            description: "Make Hashtags & Mentions not editable. This will be disabled if the toggle is switched off under stream settings. Does not lock mentions in the comment.",
            customClasses: "lockhashtagToggle",
            value: item.sid && client?.streams[item.sid].LockHashtagsAndMentions ? true : item.LockHashtagsAndMentions,
            disabled: !item.sid || item.sid && client.streams[item.sid].LockHashtagsAndMentions,
        },
        displayAdvisorNote: {
            label: "Display notes",
            customClasses: "lockhashtagToggle",
            value: !!item.displayAdvisorNote,
            description: `This toggle is used to control the display of advisor notes to the app.`
        },
        notes: {
            disabled: isDisabled,
            hidden,
            label: "Notes",
            type: "EditableInput" as const,
            placeholder: " ",
            customClasses: "editable-textarea",
            max: 1000,
            description: `This is an optional field, anything that is saved within this field will not be displayed to users.${client.compliance?.vendor === "ng" ? "\nThese notes will be added to compliance reviews." : ""
                }`
        },
        sharingPermissions: {
            label: "Sharing Permissions",
            type: "multi-select" as const,
            description: "If this field is left empty, the piece of content will be read only.",
            disabled: readOnly,
            hidden: isSharingPermissionHidden,
            values: sharePermissions.map(perm => ({
                label: perm,
                value: perm.toLowerCase(),
                selected: item?.sharingPermissions && item.sharingPermissions[perm.toLowerCase()]
            })),
            mutateChange: async (_, value) => {
                const newPermissions: {
                    social: boolean;
                    email: boolean;
                    sms: boolean;
                    socialNetworkPermissions?: {
                        facebook: boolean;
                        twitter: boolean;
                        instagram: boolean;
                        linkedin: boolean;
                    }
                } = {
                    ...item.sharingPermissions,
                    social: value.includes('social'),
                    email: value.includes('email'),
                    sms: value.includes('sms'),
                }
                if (!item.sharingPermissions.social && newPermissions.social) {
                    newPermissions.socialNetworkPermissions = {
                        facebook: true,
                        twitter: true,
                        linkedin: true,
                        instagram: true
                    }
                }
                return {
                    sharingPermissions: newPermissions
                }
            }
        },
        socialNetworkPermissions: {
            label: 'Social Network Permissions',
            type: 'multi-select' as const,
            description: 'Options selected will be the only social networks that can be shared to. \n\nEach social network has unique media format restrictions, which may lead to incompatibility with this content. \n\nNot customizable when Stream Subscription or Sequential Content is enabled.',
            disabled: readOnly || isAutoPost || isSequence,
            hidden: isSocialNetworkPermissionHidden,
            values: getClientNetworks(client, isMedia === true)
                .map(perm => {
                    const { valid, message } = isNetworkValid(
                        perm,
                        item.comment,
                        videoDuration ?? 0,
                        isMedia ?? false,
                        Boolean((fileUpload && fileUpload.length > 0) || (item.Images && item.Images.length > 0) || item.Image))
                    return {
                        label: perm === 'Twitter' ? 'X (Twitter)' : perm,
                        value: perm.toLowerCase(),
                        disallowed: !valid,
                        showTooltip: !valid,
                        selected: !item.sharingPermissions.socialNetworkPermissions || (item.sharingPermissions.socialNetworkPermissions && item.sharingPermissions.socialNetworkPermissions[perm.toLowerCase()]),
                        tooltip: message
                    }
                }),
            mutateChange: async(_, value) => {
                const newPermissions = { ...item.sharingPermissions }
                newPermissions.socialNetworkPermissions = {
                    facebook: value.includes('facebook'),
                    twitter: value.includes('twitter'),
                    instagram: value.includes('instagram'),
                    linkedin: value.includes('linkedin')
                }
                return {
                    sharingPermissions: newPermissions
                }
            }
        }
    };
};
export default SharedArticle;
//#region helpers
// const _loadSuggestedComments = async (client: Mongo.IClient, article: ServerTypes.Console.IArticle) => {
//     if (!client || !client.aiGeneratedAdminSuggestions || !article.Link) {
//         return [];
//     }
//     const suggestedQuotes = await doGetQuotes(
//         article.Link,
//         article.sid && client.streams && client.streams[article.sid] ? client.streams[article.sid].lang : "en",
//         client.aiGeneratedAdminSuggestionsWithChatGPT,
//         client.cid
//     );
//     return suggestedQuotes && !("valid" in suggestedQuotes) && suggestedQuotes.numFound
//         ? suggestedQuotes.items.map(i => i.quote)
//         : [];
// };

export const _hasMentionOrHashtag = (val: string, HashtagsAndMentions: ServerTypes.Console.IArticle["HashtagsAndMentions"]) => {
    return (HashtagsAndMentions || []).filter(v => v.toLowerCase() === val.toLowerCase()).length;
};

export const _formatHashtagOrMention = (v: string) => {
    const isHashtag = v.indexOf("#") === 0;
    return `${isHashtag ? "#" : "@"}${v.replace(/[^0-9a-z_\u00C0-\u017F]/gi, "").substr(0, 30)}`;
};

export const _ValueContainer = ({ children, getValue, ...props }) => {
    const updatedChildren = children;
    if (updatedChildren && updatedChildren.length && updatedChildren[0] && updatedChildren[0].entries) {
        for (const [i] of updatedChildren[0].entries()) {
            updatedChildren[0][i].props.components.Container = (v) => {
                v.innerProps.className = `${v.innerProps.className} ${v.data.className}`;
                return <components.MultiValueContainer {...v} ></components.MultiValueContainer>;
            };
        }
    }
    return (
        <components.ValueContainer {...props}>{updatedChildren}</components.ValueContainer>
    );
};

export const validateMention = (mention: string) => {
    if (mention.split(" ").length <= 2 && mention.length >= 3) {
        let specialChar = false;
        const splitMention = mention.split("");
        for (const char of splitMention) {
            if (char.match(/[`. -]/) && !!specialChar) {
                return false;
            } else if (char.match(/[`. -]/)) {
                specialChar = true;
            } else {
                specialChar = false;
            }
        }
        if (splitMention.some(char => char.match(/[`. -]/))) {
            if (mention.length >= 6) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
    return false;
};

const getHashtagsAndMentionsNotInComment = (comment: string, hashtagsAndMentions: string[]): string[] => {
    return hashtagsAndMentions.filter((hashTagOrMention: string) => !comment.includes(hashTagOrMention));
}

const commentIncludesURL = (comment: string, commentURL: string) => {
    // This is not very robust, it will accept regardless the url is actually valid
    // if url = https://arstechnica.com/cars and comment = https://arstechnica.com/carshttps://arstechnica.com/cars
    // it will return true even though the url in the comment is not valid
    // if commentURL does not start with http, it will be added
    if (!commentURL.startsWith("http")) {
        return comment.includes(`http://${commentURL}`) || comment.includes(`https://${commentURL}`);
    }
    return comment.includes(commentURL)
}

export const getCommentWithFields = (comment: string, commentURL: string, hashtagsAndMentions: string[]) => {
    const missingHashTagsAndMentions = getHashtagsAndMentionsNotInComment(comment, hashtagsAndMentions)
    if (!commentIncludesURL(comment, commentURL) && commentURL.length) {
        comment += `\n${commentURL}`
    }
    if (missingHashTagsAndMentions.length) {
        comment += `\n${missingHashTagsAndMentions.join(" ")}`
    }
    return comment;
}

export const isNetworkValid = (network: string, comment: string, videoLength: number, isMedia: boolean, hasImage: boolean): {
    valid: boolean,
    message: string
} => {
    const messages: string[] = [];

    const validationRules: { [key: string]: Array<{ check: () => boolean, message: string }> } = {
        'Instagram': [
            { check: () => comment.length > 3000, message: "Comment length must be less than 3000." },
            { check: () => videoLength >= 600, message: "Video length must be less than 600 seconds." },
            { check: () => !isMedia, message: "Content must be media." },
            { check: () => !hasImage, message: "An image must be included." },
        ],
        'LinkedIn': [
            { check: () => comment.length > 3000, message: "Comment length must be less than 3000." },
            { check: () => videoLength >= 600, message: "Video length must be less than 600 seconds." },
        ],
        'Facebook': [
            { check: () => comment.length > 3000, message: "Comment length must be less than 3000." },
            { check: () => videoLength >= 600, message: "Video length must be less than 600 seconds." },
        ],
        'Twitter': [
            { check: () => twitter.parseTweet(comment).weightedLength > 280, message: "Comment length must be less than 280 characters." },
            { check: () => videoLength >= 140, message: "Video length must be less than 140 seconds." },
        ],
    };

    const rules = validationRules[network];

    if (rules) {
        rules.forEach(rule => {
            if (rule.check()) {
                messages.push(`${network === 'Twitter' ? 'X (Twitter)': network}: ${rule.message}`);
            }
        });
    } else {
        return { valid: false, message: "Invalid network." };
    }

    return {
        valid: messages.length === 0,
        message: messages.join("\n").trim()
    };
}

//#endregion

