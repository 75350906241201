import BaseAction from "../utils/BaseAction";
import { buildFormData } from "../utils";
import { getLocaleDate, downloadFile as fileDownload } from "../../client/utils/Helpers";

export type ICampaignNotificationActions = ClassInstance<typeof _CampaignNotificationActions>;

export interface INotificationListParams {
    search?: string;
    cid?: string;
    active?: boolean;
    groups?: string[];
    type?: string;
    style?: string;
    startDate?: string;
    endDate?: string;
    limit: number;
    skip: number;
    sort?: dynamic<number>;
    allowEcomm?: boolean;
}

export interface INotificationExistingContentParams {
    cid?: string;
    Link?: string;
    Image?: string;
    videoUrl?: string;
    consumer?: boolean;
}

class _CampaignNotificationActions extends BaseAction {
    async doGet(nid: string) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; item: Mongo.INotification }>(
            `/notification/get`,
            "POST",
            { nid }
        );
        if (data && data.item) {
            return data.item;
        }
        return null;
    }

    async doGetExistingContent(params: INotificationExistingContentParams) {
        try {
            const data = await this.apiRequestWithErrorAlert<{ items: Mongo.INotification[]; count: number }>(
                `/notification/getExistingContent`,
                "POST",
                params
            );
            if (data && data.items && data.items.length) {
                return { items: data.items, total: data.count };
            }
        } catch (error) {
            //
        }
        return { items: [], total: 0 } as { items: Mongo.INotification[]; total: number };
    }

    async doList(params: INotificationListParams) {
        try {
            const data = await this.apiRequestWithErrorAlert<{ items: Mongo.INotification[]; count: number }>(
                `/notification/list`,
                "POST",
                params,
                undefined,
                true
            );
            if (data && data.items && data.items.length) {
                return { items: data.items, total: data.count };
            }
        } catch (error) {
            //
        }
        return { items: [], total: 0 } as { items: Mongo.INotification[]; total: number };
    }

    async doUpsert(notification: Mongo.INotification, files?: File[] | undefined, thumbnailFile: File | undefined = undefined, pdfFile?: File | null | undefined) {
        let uploadedFiles: File[] = [];
        if (files){
            uploadedFiles = files;
        }
        if (pdfFile){
            uploadedFiles.unshift(pdfFile);
        }
        return this.apiRequestWithErrorAlert<{ valid: boolean, error?: string }>(
            `/notification/upsert`,
            "post",
            buildFormData<Mongo.INotification>(notification, [], uploadedFiles, thumbnailFile),
            undefined,
            true
        );
    }

    async doGetActiveNotifications(cid: string, excludeNid: string, approvedOnly: boolean, periodStart?: Date | null, periodEnd?: Date | null, isWeeklyEmail?: boolean){
        try {
            const data = await this.apiRequestWithErrorAlert<{ items: Mongo.INotification[]; count: number }>(
            `/notification/active-notifications`,
            `POST`,
            { cid, excludeNid, approvedOnly, periodStart, periodEnd, isWeeklyEmail }
            );
            if (data && data.items && data.items.length) {
                return { items: data.items, total: data.count };
            }
        } catch (error) {
            //
        }
        return { items: [], total: 0 } as { items: Mongo.INotification[]; total: number };
    }

    async doExportCSV(params: INotificationListParams) {
        return this.apiRequest(
            '/notification/csv/export',
            `GET`,
            params
        ).then(data => {
            if (data) {
                fileDownload(data, `notificationPerformance-${getLocaleDate(new Date())}.csv`);
            }
            return data;
        }).catch(error => {
            this.createAlert(error, 'error');
            return [];
        });
    }
}

export const CampaignNotificationActions = new _CampaignNotificationActions();
