import React, { Component } from "react";
import Spinner from "../../../atoms/Spinner";

import { apiRequest, getLocaleDate, downloadFile as fileDownload, hasEmvPermission } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";

import { createAlert } from "../../../../actions/utils";
import DownloadButton from "../../../atoms/DownloadButton";
import Link from "../../../atoms/Link";
import Pagination from "../../../molecules/Pagination";
import { Wrapper, Row } from "../../../atoms/Layout";
import { IReportFilter } from "../Index";
interface ITopMedia {
    Title: string;
    "Content ID": string,
    Content: string;
    Type: string;
    Composed: boolean;
    Terms: string;
    Shares: number;
    Likes: number;
    notes?: string;
    Comments: number;
    Interactions: number;
    "Video Views": number;
}

interface IMediaProps {
    filters: IReportFilter;
    onLoaded: () => void;
    client?: Mongo.client;
    user: Mongo.clientAdmin;
    showSearchResults: boolean;
}

interface IMediaState {
    topMedia: "loading" | ITopMedia[];
    topMediaSort: string;
    topMediaDirection: number;
    topMediaLimit: number;
    topMediaPage: number;
    topMediaTotal: number;
}

class MediaSection extends Component<IMediaProps, IMediaState> {
    constructor(props: IMediaProps) {
        super(props);
        this._request = this._request.bind(this);
        this._fetchAll = this._fetchAll.bind(this);

        this._renderMedia = this._renderMedia.bind(this);

        this.state = {
            topMedia: "loading",
            topMediaSort: "Shares",
            topMediaDirection: -1,
            topMediaLimit: 20,
            topMediaPage: 0,
            topMediaTotal: 0
        };
    }

    componentDidMount() {
        if (!this.props.showSearchResults) return;
        this._fetchAll(this.props);
    }

    componentWillReceiveProps(newProps: IMediaProps) {
        if ((newProps.filters != this.props.filters) || (!this.props.showSearchResults && newProps.showSearchResults)) {
            this.setState({ topMediaPage: 0 }, () => this._fetchAll(newProps));
        }
    }

    _fetchAll(props: IMediaProps) {
        this._request().finally(props.onLoaded);
    }

    _request() {
        return new Promise<void>(resolve =>
            this.setState({ topMedia: "loading" }, () =>
                this._fetchData("topMedia").then(data => {
                    this.setState({ topMedia: data.items, topMediaTotal: data.count });
                }).finally(resolve)
            )
        );
    }

    _refresh(newPage = 0) {
        this.setState({ topMediaPage: newPage }, () => {
            this._fetchAll(this.props);
        });
    }
    _fetchData(type: "topMedia" | "sharesByMonth", format = "json") {
        const sorting = {
            ...this.props.filters,
            sort: this.state.topMediaSort,
            sortDirection: this.state.topMediaDirection,
            limit: this.state.topMediaLimit,
            skip: this.state.topMediaLimit * this.state.topMediaPage
        };
        return apiRequest(
            `${appConfig.API_URL}/reports/articles/${type}/${format}`,
            "Post",
            sorting
        )
            .then(data => {
                if (typeof data === "object" && (data.error || data.valid === false)) {
                    throw data.error;
                }
                if (format == "csv") {
                    fileDownload(data, `${type === "topMedia" ? "media" : type}-${getLocaleDate(new Date())}.csv`);
                }
                return data;
            })
            .catch(error => {
                createAlert(error, `error`);
                return [];
            });
    }

    _sort(sortColumn: string, sortDirection: number) {
        this.setState({ topMediaPage: 0, topMediaSort: sortColumn, topMediaDirection: sortDirection }, () => {
            this._fetchAll(this.props);
        });
    }

    _renderSortIcons(column: string, defaultDirection = 1) {
        const sort = this.state.topMediaSort;
        const sortDirection = this.state.topMediaDirection;
        if (sort == column) {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={e => this._sort(column, sortDirection == 1 ? -1 : 1)}
                    onKeyDown={e => {
                        if (e.which === 13 || e.which === 32) {
                            this._sort(column, sortDirection == 1 ? -1 : 1)
                        }
                    }}
                >
                    {column}
                    <i
                        className={`fa ${sortDirection == 1 ? "fa-sort-up" : "fa-sort-down"}`}
                        tabIndex={0}
                        role="img"
                        aria-label={`Sort ${sortDirection == 1 ? "ascending" : "descending"}`}
                    />
                </span>
            );
        }
        return (
            <span
                style={{ cursor: "pointer" }}
                onClick={e => this._sort(column, defaultDirection)}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        this._sort(column, defaultDirection)
                    }
                }}
            >
                {column}
                <i
                    className="fa fa-sort"
                    tabIndex={0}
                    role="img"
                    aria-label="Sort ascending or descending"
                />
            </span>
        );
    }

    _renderLoadingCard(content: string | JSX.Element, loadingClass: string) {
        return (
            <div className={loadingClass}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            Media By {this.state.topMediaSort}
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderMedia() {
        const { topMedia, topMediaSort, topMediaLimit, topMediaPage, topMediaTotal } = this.state;
        if (!this.props.showSearchResults) {
            return this._renderLoadingCard(<i>To run this report, please adjust the filters above and click "Search"</i>, "col-lg-12");
        } else if (topMedia === "loading") {
            return this._renderLoadingCard(<Spinner />, "col-lg-12");
        }

        return (
            <div className="col-lg-12">
                <div className="ibox float-e-margins">
                    {this.renderHead("Media By " + topMediaSort)}
                    <div className="ibox-content">
                        <div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Pagination
                                    page={topMediaPage}
                                    totalItems={topMediaTotal}
                                    numPerPage={topMediaLimit}
                                    changePage={newPage => this._refresh(newPage)}
                                    disabled={false}
                                    showTotal={false}
                                />
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped responsive" data-type="responsive">
                                    <thead>
                                        <tr>
                                            <th tabIndex={0}>Rank</th>
                                            <th tabIndex={0}>Title</th>
                                            <th tabIndex={0}>Content ID</th>
                                            <th tabIndex={0}>Content</th>
                                            <th tabIndex={0} style={{ minWidth: "60px" }}>Type</th>
                                            <th tabIndex={0}>Terms</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Shares", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("Interactions", -1)}</th>
                                            {hasEmvPermission(this.props.user) && <th tabIndex={0}>Earned Media Value</th>}
                                            <th tabIndex={0}>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {topMedia && topMedia.length > 0 && topMedia instanceof Array ? (
                                            topMedia.map((art, i) => (
                                                <tr key={art.Content} tabIndex={0}>
                                                    <td tabIndex={0}>{i + 1 + topMediaLimit * topMediaPage}</td>
                                                    <td tabIndex={0}>{art.Title || "N/A"}</td>
                                                    <td tabIndex={0}>{art["Content ID"] || "N/A"}</td>
                                                    <td tabIndex={0}>
                                                        {art.Type === "Text" ? (
                                                            art.Content || "N/A"
                                                        ) : (
                                                            art.Content.split("|").map((content, index) => (
                                                                <div key={index}>
                                                                    <Link href={content} target="_blank">
                                                                        {content}
                                                                    </Link>
                                                                </div>
                                                            ))
                                                        )}
                                                    </td>
                                                    <td tabIndex={0}>{art.Type || "N/A"}</td>
                                                    <td tabIndex={0}>{art.Terms || "N/A"}</td>
                                                    <td tabIndex={0}>{art.Shares || "N/A"}</td>
                                                    <td tabIndex={0}>{art.Interactions || "N/A"}</td>
                                                    {hasEmvPermission(this.props.user) && <td tabIndex={0}>${art["Earned Media Value"] || "N/A"}</td>}
                                                    <td tabIndex={0}>{art.notes || "N/A"}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr tabIndex={0}>
                                                <td colSpan={42} tabIndex={0}>No records found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Pagination
                                    currItems={topMedia ? topMedia.length : 0}
                                    page={topMediaPage}
                                    totalItems={topMediaTotal}
                                    numPerPage={topMediaLimit}
                                    changePage={newPage => this._refresh(newPage)}
                                    disabled={false}
                                    showTotal={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderHead(title: string) {
        return (
            <div className="ibox-title"
                style={{
                    paddingTop: "7px",
                    display: "flex"
                }}>
                <h5 style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center"
                }}>
                    {title}
                </h5>
                <span style={{ marginLeft: "auto" }}>
                    { // commenting this out because it doesn't work for media right now (see GV6-10947)
                        /*this.props.filters.clientCode.indexOf("ALL_") != 0 &&
                            this.props.filters.sid.indexOf("ALL_") != 0 && (
                                <DownloadButton
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "0",
                                        width: "auto"
                                    }}
                                    className="btn--sm"
                                    onClick={e => this._fetchData("sharesByMonth", "csv")}
                                >
                                    <i className="fa fa-download" /> Download shares by month
                                </DownloadButton>
                                )*/}
                    <DownloadButton
                        style={{
                            marginLeft: "5px",
                            marginBottom: "0",
                            width: "auto"
                        }}
                        className="btn--sm"
                        onClick={e => this._fetchData("topMedia", "csv")}
                    >
                        <i className="fa fa-download" /> Download
                    </DownloadButton>
                </span>
            </div>
        );
    }

    render() {
        return (
            <Wrapper id="media">
                <Row>{this._renderMedia()}</Row>
            </Wrapper>
        );
    }
}

export default MediaSection;
