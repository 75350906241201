import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
import NotificationPreview from "../NotificationPreview";

interface Props {
    item: Mongo.INotification;
    close: () => void;
    open: boolean | undefined;
    fileUpload?: File[] | null;
    uploadedFile?: boolean;
    thumbnail?: string | undefined;
    customThumbnailUrl?: string;
    customThumbnailFile?: File | undefined;
}

interface State {
    open: boolean;
    videoPlaying: boolean;
}

export default class NotificationPreviewModal extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open || false,
            videoPlaying: false
        };
    }

    _close = () => {
        this.setState({ videoPlaying: false, open: false });
        if (this.props.close) {
            this.props.close();
        }
    }
    render() {
        if (this.props.open) {
            return (
                <Modal title="Notification Preview" open={true} closeAction={this._close} >
                    <NotificationPreview {...this.props}/>
                    <br />
                    <Button
                        style={{ marginLeft: "auto", float: "right" }}
                        onClick={() => this._close()}
                    >
                        Done
                    </Button>
                    <br />
                    <br />
                </Modal>
            );
        } else {
            return (
                <></>
            );
        }
    }
}