import React from "react";
import { Wrapper, Row } from "../atoms/Layout/";

interface INotFoundProps {}
const NotFound = (props: INotFoundProps) => {
    return (
        <Wrapper>
            <Row>
                <div className="col-lg-12">
                    <div className="ibox float-e-margins">
                        <div
                            className="ibox-content"
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <h1 className="page__title">404. Page Not Found</h1>
                        </div>
                    </div>
                </div>
            </Row>
        </Wrapper>
    );
};

export default NotFound;
