import React, { Component } from "react";
import { connect } from "react-redux";

import ElementEditCard from "../../molecules/ElementEditCard";

import { createAlert, doGetClients, AdminActions } from "../../../actions";
import { Admin } from "../../../utils/Models";
import { history } from "../../../utils/store";
import Spinner from "../../atoms/Spinner";
import { RouteComponentProps } from "react-router-dom";

interface IEditAdminProps extends RouteComponentProps<{ cid: string; aid: string }> {
    user: Mongo.clientAdmin;
}
interface IEditAdminState {
    aid: string;
    cid: string;
    readOnly: boolean;
    clients: Mongo.IClient[];
    existingAdmin: Mongo.clientAdmin | null;
}
class EditAdmin extends Component<IEditAdminProps, IEditAdminState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;
    editStartTime = new Date();
    constructor(props: IEditAdminProps) {
        super(props);

        const cid = props.user.isSuper ? props.match.params.cid : props.user.cid || "";
        let aid = "";

        if (props.match.params.aid) {
            aid = props.match.params.aid;
        }

        this.state = {
            aid,
            cid,
            clients: [],
            readOnly: false,
            existingAdmin: null,
        };
    }

    async componentDidMount() {
        const clients = await doGetClients();
        const existingAdmin = this.state.aid ? await AdminActions.doGet(this.state.aid) : null;
        this.setState({ clients, existingAdmin });
    }

    __validateEmail(email: string) {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(email);
    }

    async _upsertAdmin(item: Mongo.ClientAdmin, admin: Mongo.ClientAdmin) {
        try {
            await AdminActions.doUpsert({ ...admin, isInitial: !admin.aid && !admin.ssoId });
            const action = item.aid ? "updated" : "created";
            createAlert(`Successfully ${action} ${admin.name}`, `success`);

            // Redirect to homepage of admins
            setTimeout(() => {
                history.push("/admins");
            }, 0);
        } catch (error) {
            //
        }
    }

    async _onSubmit(item: Mongo.ClientAdmin) {
        if (item.email && !this.__validateEmail(item.email)) {
            createAlert("Email format is invalid, please provide a proper email");
            return;
        }
        if (
            !item.isSuper &&
            !item.isApiAdmin &&
            !item.isSuperClientAdmin &&
            !item.isAdmin &&
            !item.isClientAdmin &&
            !item.isCurator &&
            !item.isReports
        ) {
            createAlert("Please assign at least one role to the admin");
            return;
        }
        if (item.cid && (item.isApiAdmin || item.isSuper)) {
            createAlert(`${item.isApiAdmin ? "Feed" : "Super"} Admins can't have a Client, please remove Client`);
            return;
        }

        if (this.state.existingAdmin) {
            const existingAdmin = this.state.aid ? await AdminActions.doGet(this.state.aid) : null;
            this.setState({ existingAdmin });
            if (new Date(this.state.existingAdmin.lastUpdatedOn) > this.editStartTime) {
                createAlert("This admin's information has been updated since you started editing it. Please make note of your changes and refresh the page to try again.", "error");
                return;
            }
        }

        const admin = Admin(item);
        if (admin.groups.length == 1 && admin.groups[0] == "") {
            admin.groups = [];
        }
        if (admin.tags.length == 1 && admin.tags[0] == "") {
            admin.tags = [];
        }
        // @ts-expect-error
        if (admin.isSuperClientAdmin && admin?.tags?.length) delete admin.tags;
        admin.readOnly = item.readOnly;

        const clientObj = this.state.clients.find(client => client.cid == item.cid);

        if (clientObj?.adminSSO?.method) {
            if (!item.ssoId && !(admin.isSuper || admin.isSuperClientAdmin || admin.isClientAdmin)) {
                createAlert("Please enter an SSO ID");
                return;
            }
            // Check for 3 SSOid cases that require confirm messages
            if (!this.state.existingAdmin && !item.ssoId) {
                // creating a new Admin and leaving SSOid blank
                if (confirm("Warning: This client is SSO enabled and the admin user you are attempting to create does not have an SSO identifier. Therefore, they will not be able to login using the single sign-on method and instead must login using their email and password. Do you wish to continue?")) {
                    this._upsertAdmin(item, admin);
                } else return;
            } else if (this.state.existingAdmin?.ssoId && !item.ssoId) {
                // removing SSOid when previously filled
                if (confirm("Warning: This client is SSO enabled and the admin user you are attempting to save no longer has an SSO identifier. Therefore, they will no longer be able to login using the single sign-on method and instead must login using their email and password. We will be sending this admin an email with their new password details. Do you wish to continue?")) {
                    this._upsertAdmin(item, admin);
                } else return;
            } else if (this.state.existingAdmin && !this.state.existingAdmin.ssoId && item.ssoId) {
                // adding SSOid when previously blank
                if (confirm("Warning: This client is SSO enabled and the admin user you are attempting to save has been updated to include an SSO identifier. Therefore, they will no longer be able to login using their email and password and must instead login using the single sign-on method. We will be sending the admin a new onboarding email with the SSO link they must use. Do you wish to continue?")) {
                    this._upsertAdmin(item, admin);
                } else return;
            } else {
                // editing other admin fields and leaving SSOid as is
                this._upsertAdmin(item, admin);
            }
        } else {
            // not SSO enabled
            this._upsertAdmin(item, admin);
        }
    }

    getAvailablePermissionLevels(
        currentPermission?:
            | "unknown"
            | "isSuper"
            | "isApiAdmin"
            | "isSuperClientAdmin"
            | "isClientAdmin"
            | "isCuratorAdmin"
            | "isCuratorReportsAdmin"
            | "isCurator"
            | "isAdmin"
            | "isReports",
        itemPermissions?:
            | "unknown"
            | "isSuper"
            | "isApiAdmin"
            | "isSuperClientAdmin"
            | "isClientAdmin"
            | "isCuratorAdmin"
            | "isCuratorReportsAdmin"
            | "isCurator"
            | "isAdmin"
            | "isReports"
    ) {
        if (currentPermission === "unknown") {
            return [];
        }
        const permissionSchema = {
            isSuper: "Super Admin",
            isApiAdmin: "Feed Admin",
            isSuperClientAdmin: "Super Client Admin",
            isClientAdmin: "Client Admin",
            isCuratorAdmin: "Curator + User Admin",
            isCuratorReportsAdmin: "Curator + Reports Admin",
            isCurator: "Curator Admin",
            isAdmin: "User Admin",
            isReports: "Reports Admin"
        };

        if (itemPermissions && currentPermission == itemPermissions) {
            return [{ label: permissionSchema[itemPermissions], value: itemPermissions }];
        }

        let userPermissions: dynamic<string> = {
            ...permissionSchema
        };

        switch (currentPermission) {
            case "isSuper":
                delete userPermissions.isSuper;
                break;
            case "isSuperClientAdmin":
                delete userPermissions.isSuper;
                delete userPermissions.isApiAdmin;
                break;
            case "isClientAdmin":
                delete userPermissions.isSuper;
                delete userPermissions.isApiAdmin;
                delete userPermissions.isSuperClientAdmin;
                break;
            case "isCuratorReportsAdmin":
                delete userPermissions.isSuper;
                delete userPermissions.isApiAdmin;
                delete userPermissions.isSuperClientAdmin;
                delete userPermissions.isClientAdmin;
                break;
            default:
                userPermissions = {};
                break;
        }
        if (itemPermissions && !Object.keys(userPermissions).find(i => i == itemPermissions)) {
            return [{ label: permissionSchema[itemPermissions], value: itemPermissions }];
        }
        return Object.keys(userPermissions).map(key => ({ label: `${userPermissions[key]}`, value: key }));
    }

    render() {
        const { user } = this.props;
        const { cid, clients, readOnly, existingAdmin } = this.state;
        if ((clients || []).length == 0) {
            return <Spinner />;
        }

        return (
            <ElementEditCard<Mongo.ClientAdmin>
                    getItem={async () => Admin({ cid, ...existingAdmin })}
                    canUpdate={() => Promise.resolve(true)}
                    update={item => this._onSubmit(item)}
                    breadcrumbs={[{ name: "Admins", link: "/admins" }]}
                    elementName="Admin"
                    rows={[{
                        columns: [
                            { label: "", items: ["name", "externalAccountId"], key: "EditAdmin_R1C1" },
                            { label: "", items: ["email", "ssoId"], key: "EditAdmin_R1C2" }
                        ],
                        key: "EditAdmin_Row1"
                    }, {
                        fullWidth: true,
                        columns: [
                            {
                                label: "",
                                items: ["cid", "groups", "tags", "permission", "readOnly", "description", "delegateRequestNotification"],
                                key: "EditAdmin_R2C1"
                            }
                        ],
                        key: "EditAdmin_Row2"
                    }]}
                    idField="aid"
                    editableKeys={item => {
                        const client = clients.find(_item => _item.cid === item.cid);
                        const permissionLevel = item.isSuper
                            ? "isSuper"
                            : item.isApiAdmin
                            ? "isApiAdmin"
                            : item.isSuperClientAdmin
                            ? "isSuperClientAdmin"
                            : item.isClientAdmin
                            ? "isClientAdmin"
                            : item.isCurator && item.isAdmin
                            ? "isCuratorAdmin"
                            : item.isCurator && item.isReports
                            ? "isCuratorReportsAdmin"
                            : item.isCurator
                            ? "isCurator"
                            : item.isAdmin
                            ? "isAdmin"
                            : item.isReports
                            ? "isReports"
                            : undefined;

                        const userPermissionLevel = !user
                            ? "unknown"
                            : user.isSuper
                            ? "isSuper"
                            : user.isApiAdmin
                            ? "isApiAdmin"
                            : user.isSuperClientAdmin
                            ? "isSuperClientAdmin"
                            : user.isClientAdmin
                            ? "isClientAdmin"
                            : user.isCurator && user.isAdmin
                            ? "isCuratorAdmin"
                            : user.isCurator && user.isReports
                            ? "isCuratorReportsAdmin"
                            : user.isCurator
                            ? "isCurator"
                            : user.isAdmin
                            ? "isAdmin"
                            : "isReports";
                        return {
                            name: {
                                fullWidth: true,
                                label: "Name",
                                type: "text",
                                required: true
                            },
                            email: {
                                fullWidth: true,
                                label: "Email",
                                type: "text",
                                required: true
                            },
                            externalAccountId: {
                                fullWidth: true,
                                label: "External Account ID",
                                type: "text"
                            },
                            ssoId: {
                                fullWidth: true,
                                label: "SSO ID",
                                type: "text"
                            },
                            cid: {
                                label: "Client",
                                type: "select",
                                disabled: !(user && user.isSuper),
                                hidden: item.isApiAdmin || item.isSuper,
                                values: (clients || []).map(c => ({ label: c.name, value: c.cid }))
                            },
                            groups: {
                                label: "Groups",
                                type: "multi-select",
                                hidden: client == null,
                                values: Object.keys((client || { groups: {} }).groups).map(gid => ({
                                    label: client!.groups[gid]!.name,
                                    value: gid
                                }))
                            },
                            tags: {
                                label: "Content Tags",
                                type: "multi-select",
                                hidden: client == null || item.isSuperClientAdmin,
                                values: [{ label: "All untagged streams", value: "untagged" }].concat(
                                    Object.keys((client || { tags: {} }).tags || {}).map(tid => ({
                                        label: client!.tags![tid].name,
                                        value: tid
                                    }))
                                )
                            },
                            permission: {
                                label: "Admin Type",
                                value: permissionLevel,
                                type: "select",
                                values: [...this.getAvailablePermissionLevels(userPermissionLevel, permissionLevel)],
                                mutateChange: async (key, value) => ({
                                    isSuper: false,
                                    isApiAdmin: false,
                                    isSuperClientAdmin: false,
                                    isClientAdmin: false,
                                    // @ts-expect-error
                                    isAdmin: false,
                                    // @ts-expect-error
                                    isCurator: false,
                                    isReports: false,

                                    ...(value === "isCuratorAdmin" ? { isCurator: true, isAdmin: true } : (value === "isCuratorReportsAdmin") ? { isCurator: true, isReports: true } : { [value as string]: true }),

                                    ...(["isSuper", "isApiAdmin"].indexOf(value as string) > -1 ? { cid: "" } : {}),

                                })
                            },
                            readOnly: {
                                label: "Enable Read-Only Settings",
                                type: "toggle",
                                description: "Giving this permission will allow the admin to see a read-only version of the client settings.",
                                hidden: (!user || (!user.isSuper && !user.isSuperClientAdmin) || !(item && item.isClientAdmin)),
                                value: readOnly
                            },
                            description : {
                                label: "Description",
                                type: "textarea",
                                max: 500,
                                placeholder: " ",
                            },
                            delegateRequestNotification: {
                                type: "toggle",
                                label: "Delegate Request Notification",
                                hidden: !(item?.isAdmin || item?.isClientAdmin),
                                value: item.delegateRequestNotification === undefined ? true : item.delegateRequestNotification,
                                description: "Controls whether admin receives email notifications about delegate requests."
                            },
                        };
                    }}
            />
        );
    }
}
const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(EditAdmin);
