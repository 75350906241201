import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeading from "../../../molecules/PageHeading";
import { CreatableLibrary } from "../../../atoms/CreatableLibrary";
import _Select from "../../../atoms/Select";
import { createAlert } from "../../../../actions/utils";
import Spinner from "../../../atoms/Spinner";
import { doGetReduxClients, doGetReduxClient } from "../../../../actions/lists";
import { doUpdateHashtags, doUpdateMentions, doUpdateTypeaheadCompanyPages } from "../../../../actions/inserts";
import { doGetConnectedAccounts, doGetLinkedInCompanies, doGetAdmins } from "../../../../actions"

import { getLanguages } from "../../../../utils/Helpers";
import Button from "../../../atoms/Button";
import { Wrapper, Row } from "../../../atoms/Layout/";
import Tooltip from "../../../atoms/Tooltip"

class Hashtag extends Component {
    constructor(props) {
        super(props);
        this._onFilterChange = this._onFilterChange.bind(this);
        this._getClientName = this._getClientName.bind(this);
        this.authLinkedIn = this.authLinkedIn.bind(this);
        this.MAX_TYPEAHEAD_COMPANY_PAGES = 3;

        this.state = {
            loading: true,
            client: null,
            lang: "en",
            type: "Hashtags",
            disabled: "disabled",
            hashtagToAdd: "",
            hashtags: [],
            mentions: [],
            linkedInCompanies: [],
            typeaheadCompanyPages: [],
            connectedAccounts: { twitter: false, facebook: false, linkedIn: false },
            connectedLiProfile: {firstName: "", lastName: ""},
            liConnectAuthError: false,
            justSignInLi: false,
        };
    }
    _getClientName(cid) {
        const { clients } = this.props;
        if (cid) {
            if (clients && clients[cid]) {
                return clients[cid].name;
            }
        }
        return "";
    }
    async componentDidMount() {
        const { oauth, isSuper, aid } = this.props.user;
        if (oauth?.linkedIn) {
            await this.getLiProfile(aid);
            await this.fetchLinkedInCompanies();
        }
        else {
            const connectedAccounts = await doGetConnectedAccounts();
            if (connectedAccounts) {
                this.setState({ connectedAccounts });
                if (connectedAccounts.linkedIn) {
                    await this.getLiProfile(aid);
                    await this.fetchLinkedInCompanies();
                }
            }
        }

        if (isSuper) {
            doGetReduxClients(true);
        } else {
            await doGetReduxClient(this.props.user.cid);

            let newState = {};
            newState["disabled"] = "";
            newState["client"] = this.props.clients[this.props.user.cid];
            newState["lang"] = this.props.clients[this.props.user.cid].lang;
            if (
                this.props.clients[this.props.user.cid].hashtags &&
                this.props.clients[this.props.user.cid].hashtags.constructor === Object &&
                Object.keys(this.props.clients[this.props.user.cid].hashtags).length > 0
            ) {
                newState["hashtags"] = this.props.clients[this.props.user.cid].hashtags[
                    this.props.clients[this.props.user.cid].lang
                ];
            }
            if (
                this.props.clients[this.props.user.cid].mentions &&
                this.props.clients[this.props.user.cid].mentions.constructor === Object &&
                Object.keys(this.props.clients[this.props.user.cid].mentions).length > 0
            ) {
                newState["mentions"] = this.props.clients[this.props.user.cid].mentions[
                    this.props.clients[this.props.user.cid].lang
                ];
            }
            if (
                this.props.clients[this.props.user.cid].typeaheadCompanyPages &&
                this.props.clients[this.props.user.cid].typeaheadCompanyPages.constructor === Object &&
                Object.keys(this.props.clients[this.props.user.cid].typeaheadCompanyPages).length > 0
            ) {
                newState["typeaheadCompanyPages"] = this.props.clients[this.props.user.cid].typeaheadCompanyPages[
                    this.props.clients[this.props.user.cid].lang
                ];
            }
            newState["loading"] = false;
            this.setState(newState);
        }

        this.loadHashtagSettings();
        const { connectedAccounts, liConnectAuthError, justSignInLi} = this.state;
        if (connectedAccounts.linkedIn && !liConnectAuthError && justSignInLi) {
            this._addLinkedinMentions();
        }
        if (justSignInLi) {
            this.setState({justSignInLi: false})
        }
    }
    componentDidUpdate() {
        if (this.props.user.isSuper && this.state.loading) {
            if (Object.keys(this.props.clients).length) {
                let newState = {};
                newState["loading"] = false;
                this.setState(newState);
            }
        }
    }
    saveHashtagSettings() {
        // setting justSignInLi to true only after siging in to automatically add LI company mentions after signing in a LI account
        localStorage.setItem("hashtagSettings", JSON.stringify({justSignInLi: true}));
    }
    loadHashtagSettings() {
        const settings = localStorage.getItem("hashtagSettings");
        if (settings) {
            const parsedSettings = JSON.parse(settings)
            if (parsedSettings.justSignInLi) {
                this.setState(parsedSettings)
            }
            localStorage.removeItem("hashtagSettings")
        }
    }
    _makeHashtag(v) {
        //Not altering Mentions else it wont work in case of LI Company Pages
        if (v.startsWith("@")) {
            while (v.startsWith("@")) {
                v = v.replace("@", "");
            }
            return v;
        } else {
            if (v.endsWith("#")) {
                v = v.slice(0, -1); // Remove the trailing "#" at the end of hashtag
            }
            if (v.length <= 32) {
                return v.replace(/[^0-9a-z_\u00C0-\u017F]/gi, "");
            } else {
                return v.replace(/[^0-9a-z_\u00C0-\u017F]/gi, "").substr(0, 32);
            }
        }

    }
    _updateClient(type) {
        if (this.state.client && this.state.lang && this.state.hashtags && type.toLowerCase() == "hashtags") {
            const client = this.state.client;
            !client.hashtags && (client.hashtags = {});
            client.hashtags[this.state.lang] = this.state.hashtags;
            doUpdateHashtags(client, false);
        }
        if (this.state.client && this.state.lang && this.state.mentions && type.toLowerCase() == "mentions") {
            const client = this.state.client;
            !client.mentions && (client.mentions = {});
            client.mentions[this.state.lang] = this.state.mentions;
            doUpdateMentions(client, false);
        }

        if (this.state.client && this.state.lang && this.state.typeaheadCompanyPages && type.toLowerCase() == "typeaheadcompanypages") {
            const client = this.state.client;
            !client.typeaheadCompanyPages && (client.typeaheadCompanyPages = {});
            client.typeaheadCompanyPages[this.state.lang] = this.state.typeaheadCompanyPages;
            doUpdateTypeaheadCompanyPages(client, false);
        }
    }
    _onFilterChange(fieldName, event) {
        let newState = {};
        let val = event.target ? event.target.value : event;
        if (fieldName == "cid") {
            fieldName = "client";
            val = (this.props.clients || {})[val];
            val ? (newState["disabled"] = "") : (newState["disabled"] = "disabled");
            !val.hashtags && (val.hashtags = {});
            !val.hashtags[this.state.lang] && (val.hashtags[this.state.lang] = []);
            newState["hashtags"] = val.hashtags[this.state.lang];
            !val.mentions && (val.mentions = {});
            !val.mentions[this.state.lang] && (val.mentions[this.state.lang] = []);
            newState["mentions"] = val.mentions[this.state.lang];
        }
        if (fieldName == "lang") {
            !this.state.client.hashtags && (this.state.client.hashtags = {});
            newState["hashtags"] = this.state.client.hashtags[val] ? this.state.client.hashtags[val] : [];
            !this.state.client.mentions && (this.state.client.mentions = {});
            newState["mentions"] = this.state.client.mentions[val] ? this.state.client.mentions[val] : [];
        }
        if (fieldName == "hashtagToAdd") {
            val = this._makeHashtag(val);
        }
        newState[fieldName] = val;
        if (Object.keys(newState).length > 0) {
            this.setState(newState, () => {
                (fieldName == "hashtags" || fieldName == "mentions" || fieldName == "typeaheadCompanyPages") && this._updateClient(fieldName);
            });
        }
    }
    _addToHashtags() {
        if (this._makeHashtag(this.state.hashtagToAdd)) {
            let newState = {};
            if (this.state.hashtags || this.state.mentions) {
                if (this.state.hashtags.length + this.state.mentions.length >= 50) {
                    createAlert(
                        "You have reached the maximum amount of hashtags & mentions. Please delete some hashtags or mentions in order to add more",
                        `error`
                    );

                } else {
                    const hashtagToAdd = this._makeHashtag(this.state.hashtagToAdd);
                    if (hashtagToAdd.endsWith("#")) {
                        createAlert("Hashtags cannot end with '#' symbol", "error");
                    } else if (
                        this.state.type == "Hashtags" &&
                        this.state.hashtags.some(
                            obj =>
                                obj.hashtag.toLowerCase() === this._makeHashtag(this.state.hashtagToAdd).toLowerCase()
                        )
                    ) {
                        createAlert("You already have this hashtag in your library", `error`);
                    } else if (
                        this.state.type == "Mentions" &&
                        this.state.mentions.some(
                            obj =>
                                obj.mention.toLowerCase() === this._makeHashtag(this.state.hashtagToAdd).toLowerCase()
                        )
                    ) {
                        createAlert("You already have this mention in your library", `error`);
                    } else {
                        if (this.state.type == "Hashtags") {
                            newState.hashtags = this.state.hashtags;
                            newState.hashtags.push({
                                hashtag: this._makeHashtag(this.state.hashtagToAdd),
                                checked: true
                            });
                        } else {
                            newState.mentions = this.state.mentions;
                            newState.mentions.push({
                                mention: this._makeHashtag(this.state.hashtagToAdd),
                                checked: true
                            });
                        }
                        newState.hashtagToAdd = "";
                    }
                }
            } else {
                if (this.state.type == "Hashtags") {
                    newState.hashtags = [{ hashtag: this._makeHashtag(this.state.hashtagToAdd), checked: true }];
                } else {
                    newState.mentions = [{ mention: this._makeHashtag(this.state.hashtagToAdd), checked: true }];
                }
                newState.hashtagToAdd = "";
            }
            if (Object.keys(newState).length > 0) {
                this.setState(newState, () => this._updateClient(this.state.type));
            }
        }
    }

    _addLinkedinMentions() {
        const { linkedInCompanies, hashtags, mentions, connectedAccounts } = this.state;
        let newState = {};

        if (!connectedAccounts.linkedIn && linkedInCompanies.length === 0) {
            if (window) {
                window.location.href = `/auth/linkedin?rp=${`${encodeURIComponent(window.location.href)}?fetchli=t`}&src=console&aid=${this.props.user.aid}`;
            }
        } else {
            if (hashtags.length + mentions.length >= 50) {
                createAlert(
                    "You have reached the maximum amount of hashtags & mentions. Please delete some hashtags or mentions in order to add more",
                    `error`
                );
            } else {
                // check to see linkedin companies exist or not
                if (linkedInCompanies.length) {
                    const companies = linkedInCompanies.filter(company => company != null);
                    //filtering out mentions which currently doesnt exist the mentions library
                    let liCompanies = companies.filter(o1 => !mentions.some(o2 => o1.id == o2.id));
                    newState.mentions = mentions;
                    // adding new mentions into the new state & updating the state
                    if (liCompanies && liCompanies.length) {
                        for (let i = 0; i < liCompanies.length; i++) {
                            //check to see if li page addition breaks the max length of 50
                            let tagsCount = hashtags.length + mentions.length + (i + 1);
                            if (tagsCount >= 50) {
                                createAlert(
                                    "You have reached the maximum amount of hashtags & mentions. Please delete some hashtags or mentions in order to add more",
                                    `error`
                                );
                                break;
                            }
                            if (liCompanies[i]) {
                                newState.mentions.push({
                                    mention: liCompanies[i].name,
                                    id: liCompanies[i].id.toString(),
                                    checked: true
                                });
                                createAlert(`@${liCompanies[i].name} Added`, `success`);
                            }

                        }
                    }
                    if (Object.keys(newState).length > 0) {
                        this.setState(newState, () => this._updateClient("mentions"));
                    }
                } else {
                    createAlert(
                        "No Company Pages were found connected to your Profile",
                        `error`
                    );
                }
            }
        }


    }

    ValueContainer({ children, getValue, ...props }) {
        var updatedChildren = children;
        if (updatedChildren && updatedChildren.length && updatedChildren[0] && updatedChildren[0].entries) {
            for (let [i] of updatedChildren[0].entries()) {
                updatedChildren[0][i].props.components.Container = (v) => {
                    v.innerProps.className = `${v.innerProps.className} ${v.data.className}`;
                    return <components.MultiValueContainer {...v} ></components.MultiValueContainer>;
                };
            }
        }
        return (
            <components.ValueContainer {...props}>{updatedChildren}</components.ValueContainer>
        );
    };

    async getLiProfile(aid) {
        const admins = await doGetAdmins({ cid: this.props.user.cid });
        const admin = admins.items.find(a => a.aid == aid);

        if (admin?.linkedInProfile) {
            this.setState({
                connectedLiProfile: {
                    firstName: admin.linkedInProfile.firstName || "",
                    lastName: admin.linkedInProfile.lastName || ""
                }
            })
        }
    }

    async fetchLinkedInCompanies() {
        const linkedInCompanies = await doGetLinkedInCompanies();
        const connectedAccounts = await doGetConnectedAccounts();
        if (connectedAccounts) {
            this.setState({ connectedAccounts });
        }
        if (Array.isArray(linkedInCompanies) && linkedInCompanies?.length) {
            this.setState({ linkedInCompanies });
        } else if (linkedInCompanies == "Invalid LinkedIn token") {
            this.setState({
                liConnectAuthError: true
            })
        }
    }

    async authLinkedIn(event) {
        this.saveHashtagSettings();
        event.preventDefault();
        if (window) {
            window.location.href = `/auth/linkedin?rp=${`${encodeURIComponent(window.location.href)}?fetchli=t`}&src=console&aid=${this.props.user.aid}`;
        }
    }

    render() {
        const { hashtagToAdd, client, lang, disabled, hashtags, mentions, loading, type, connectedAccounts, liConnectAuthError, connectedLiProfile, linkedInCompanies, typeaheadCompanyPages } = this.state;
        const sessionAdminHasLiOauth = connectedAccounts.linkedIn && !liConnectAuthError

        let hashtagOptions = [];
        let hashtagsSorted = [];
        let mentionsSorted = [];
        let typeaheadSelectOptions = linkedInCompanies.map(company => ({
            value: { mention: company.name, id: company.id.toString(), aid: this.props.user.aid },
            label: company.name,
            selected: typeaheadCompanyPages.some(page => page.id == company.id)
        }));
        typeaheadSelectOptions = [...typeaheadSelectOptions, ...typeaheadCompanyPages.map(page => ({
            value: { mention: page.mention, id: page.id.toString(), aid: this.props.user.aid },
            label: page.mention,
            selected: true
        })).filter(page => !typeaheadSelectOptions.some(option => option.value.id == page.value.id))];

        if (hashtags) {
            hashtags.forEach(hashtag => {
                hashtagsSorted.push({
                    value: "#" + hashtag.hashtag,
                    label: "#" + hashtag.hashtag,
                    className: "select-hashtag"
                });
            });
            hashtagsSorted.sort((a, b) => a.value.localeCompare(b.value));
        }
        if (mentions) {
            mentions.forEach(mention => {
                mentionsSorted.push({
                    value: "@" + mention.mention,
                    label: "@" + mention.mention,
                    className: "select-mention",
                    id: mention.id ? mention.id : null
                });
            });
            mentionsSorted.sort((a, b) => a.value.localeCompare(b.value));
        }
        hashtagOptions = hashtagsSorted.concat(mentionsSorted);
        if (loading) {
            return (
                <Wrapper id="hashtagPage">
                    <div className="row page-heading">
                        <Spinner />
                    </div>
                </Wrapper>
            );
        } else {
            return (
                <Wrapper id="hashtagPage">
                    <PageHeading title={`Hashtag & Mentions Library`} />
                    <Row>
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-content">
                                    <div className="table__filters">
                                        {this.props.user.isSuper && (
                                            <div className="table__filters__option">
                                                <label htmlFor="clients">Client:</label>
                                                <div className="select__wrapper">
                                                    <select
                                                        id="clients"
                                                        className="filters__search"
                                                        value={(client || {}).cid}
                                                        onChange={event => this._onFilterChange("cid", event)}
                                                    >
                                                        <option value="">Select...</option>
                                                        {Object.keys(this.props.clients).map((key, i) => {
                                                            let c = this.props.clients[key];
                                                            return (
                                                                <option key={c.cid} value={c.cid}>
                                                                    {c.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        <div className="table__filters__option">
                                            <label htmlFor="langs">Language:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="langs"
                                                    className={disabled}
                                                    value={lang || "en"}
                                                    onChange={event => this._onFilterChange("lang", event)}
                                                    disabled={disabled}
                                                >
                                                    {getLanguages("al").map((val, i) => {
                                                        return (
                                                            <option key={val.code} value={val.code}>
                                                                {val.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table__filters__option">
                                            <label htmlFor="types">Type:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="types"
                                                    className={disabled}
                                                    value={type || "Hashtags"}
                                                    onChange={event => this._onFilterChange("type", event)}
                                                    disabled={disabled}
                                                >
                                                    <option key={"Hashtags"} value={"Hashtags"}>
                                                        {"Hashtags"}
                                                    </option>
                                                    <option key={"Mentions"} value={"Mentions"}>
                                                        {"Mentions"}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table__filters__option">
                                            <label htmlFor="add">Add {type}:</label>
                                            <div className={`search ${type}-field`}>
                                                <input
                                                    id="add"
                                                    className={`filters__search ${disabled}`}
                                                    ref={input => {
                                                        this.userInput = input;
                                                    }}
                                                    type="text"
                                                    placeholder=""
                                                    maxLength="32"
                                                    onChange={event =>
                                                        this._onFilterChange("hashtagToAdd", event, true)
                                                    }
                                                    value={hashtagToAdd}
                                                    disabled={disabled}
                                                    onKeyDown={e => {
                                                        e.key === "Enter" && this._addToHashtags();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="table__filters__option">
                                            <label className="control-label" style={{ width: "100%" }}>
                                                &nbsp;
                                            </label>
                                            <Button
                                                id="submit"
                                                onClick={() => this._addToHashtags()}
                                                className={disabled}
                                                disabled={disabled}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                    <label htmlFor="hashtagCreatable">Selected Hashtags &amp; Legacy Mentions:</label>
                                    <CreatableLibrary
                                        id="hashtagCreatable"
                                        placeholder="Add a hashtag or mention..."
                                        disabled={disabled ? true : false}
                                        onChange={val => {
                                            let hashtags = [];
                                            let mentions = [];
                                            if (val) {
                                                val.forEach(v => {
                                                    if (v.value.indexOf("#") === 0) {
                                                        hashtags.push({
                                                            hashtag: this._makeHashtag(v.value),
                                                            checked: true
                                                        });
                                                    } else if (v.value) {
                                                        mentions.push({
                                                            mention: this._makeHashtag(v.value),
                                                            checked: true,
                                                            id: v.id ? v.id : null
                                                        });
                                                    }
                                                });
                                            }
                                            if (hashtags) {
                                                this._onFilterChange("hashtags", hashtags, () => { });
                                            }
                                            if (mentions) {
                                                this._onFilterChange("mentions", mentions, () => { });
                                            }
                                        }}
                                        value={hashtagOptions}
                                    />
                                    <div style={{ padding: "15px 0px 0px 0px" }}>
                                        <Button className="btn--sm" style={{ marginRight: "10px" }} onClick={this.authLinkedIn} >
                                            Sign In With {sessionAdminHasLiOauth ? "Another " : " "}LinkedIn&nbsp;
                                            <Tooltip style={{ cursor: "pointer" }}>
                                                {
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        {!sessionAdminHasLiOauth ? (
                                                            "Sign into a LinkedIn account to automatically add connected LinkedIn Company Pages."
                                                        ) : (
                                                            "If you want to connect another LinkedIn Company Page, visit linkedin.com" +
                                                            "\nand ensure you are signed into an account with the correct permissions to connect."
                                                        )}
                                                    </div>
                                                }
                                            </Tooltip>
                                        </Button>
                                        {sessionAdminHasLiOauth && !liConnectAuthError && (
                                            <Button className="btn--sm" onClick={() => this._addLinkedinMentions()}>
                                                Add connected LinkedIn Company Pages
                                            </Button>
                                        )}
                                    </div>
                                    {sessionAdminHasLiOauth && !liConnectAuthError && (
                                        <div style={{ display: "flex", fontSize: "12px", paddingTop: "5px" }}>
                                            <span>
                                                LinkedIn Profile Currently Connected: {`${connectedLiProfile.firstName} ${connectedLiProfile.lastName}`}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>
                                        {"Company Pages for LinkedIn Follower Typeahead Search"}{" "}
                                        <Tooltip icon="fa-question-circle">
                                            {`Connect up to ${this.MAX_TYPEAHEAD_COMPANY_PAGES} LinkedIn company pages to allow users to search for followers from within the app.\n\n` +
                                             `There will be no options in the menu if you do not have a LinkedIn account connected with company pages added.\n\n` +
                                             `If you remove a company page that is not yours, you will not be able to add it back.`}
                                        </Tooltip>
                                    </h5>
                                </div>
                                <div className="ibox-content">
                                    <label htmlFor="typeaheadSelect">{`Selected Company Pages (${typeaheadCompanyPages.length}/${this.MAX_TYPEAHEAD_COMPANY_PAGES}):`}</label>
                                    <_Select
                                        id="typeaheadSelect"
                                        options={typeaheadSelectOptions}
                                        multi={true}
                                        isSearchable={true}
                                        onChange={val => {
                                            if (val.length > this.MAX_TYPEAHEAD_COMPANY_PAGES) {
                                                createAlert(
                                                    `You can only select up to ${this.MAX_TYPEAHEAD_COMPANY_PAGES} company pages.`,
                                                    "error"
                                                );
                                                return;
                                            }
                                            this._onFilterChange("typeaheadCompanyPages", val);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Wrapper>
            );
        }
    }
}
Hashtag.allowSuper = true;
Hashtag.allowApiAdmin = false;
Hashtag.allowClientAdmin = true;
Hashtag.allowSuperClientAdmin = true;
Hashtag.allowAdmin = false;
Hashtag.allowCurator = false;
Hashtag.allowReports = false;
const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(Hashtag);
