import AdminsList from "./List";
import AdminsEdit from "./Edit";
import AdminsUpload from "./Upload";
export default [
    { exact: true, path: "/admins", component: AdminsList },
    { path: "/admins/c", component: AdminsEdit },
    { path: "/admins/c/:cid", component: AdminsEdit },
    { path: "/admins/e/:cid/:aid", component: AdminsEdit },
    { path: "/admins/e/:aid", component: AdminsEdit },
    { path: "/admins/u", component: AdminsUpload }
];
