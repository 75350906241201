import React, { Component } from "react";

interface LIMentionTypeaheadOptionsListProps {
    options: dynamic[];
    index?: number;
    networkName?: string;
    selectMention: (mention: dynamic, index?: number, networkName?: string) => void;
}

export default class LIMentionTypeaheadOptionsList extends Component<LIMentionTypeaheadOptionsListProps> {
    constructor(props: LIMentionTypeaheadOptionsListProps) {
        super(props);
    }

    render() {
        const { options, selectMention, index, networkName } = this.props;
        return (
            <div className="options-container" style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                {options.length && options.map(option => {
                    return (
                        <div className="option-item" key={option.id} onClick={() => selectMention(option, index && index, networkName && networkName)}>
                            <div className="profile-container">
                                <img src={option.profilePicture || '/img/LINoImg.jpg'} className="profile-picture" />
                                <div className="li-profile-info">
                                    <div className="upperText">{option.name}</div>
                                    <div className="lowerText">{option.headline}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="linkedin-container">
                    <img src="/img/LinkedIn.svg" alt="LinkedIn Image" />
                    <span>Results from LinkedIn</span>
                </div>
            </div>
        );
    }
}