import React, { Component } from "react";
import { apiRequest, getChartColours, downloadFile as fileDownload, getPercent } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";
import { createAlert } from "../../../../actions/utils";
import { connect } from "react-redux";
import Spinner from "../../../atoms/Spinner";
import PercentCircle from "../../../atoms/PercentCircle";
import NumberChart from "../../../molecules/NumberChart";
import { Wrapper, Row } from "../../../atoms/Layout";

class ContactsSection extends Component {
    constructor(props) {
        super(props);
        this._fetchAll = this._fetchAll.bind(this);

        this._renderLoadingCard = this._renderLoadingCard.bind(this);
        this._evenPercent = this._evenPercent.bind(this);
        this._renderContactDetails = this._renderContactDetails.bind(this);

        this.state = {
            contacts: "loading",
            chartColors: getChartColours(false, false, true),
            increaseLabel: true
        };
    }

    componentDidMount() {
        if (!this.props.filters) return;
        if (
            this.state.contacts == "loading"
        ) {
            this._fetchAll(this.props);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters != this.props.filters) {
            this._fetchAll(this.props);
        }
    }

    _fetchAll(props) {
        this.setState(
            {
                contacts: "loading",
                filters: props.filters
            },
            () => {
                const defaults = {
                    contacts: {
                        contactsTexted: 0,
                        contactsReplied: 0
                    }
                };
                Promise.all([
                    apiRequest(`${appConfig.API_URL}/reports/sms/contacts`, "Post", props.filters)
                        .then(data => {
                            if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                                throw data.error;
                            }
                            this.setState({
                                contacts: {contactsTexted: data.sent, contactsReplied: data.replies} || defaults.contacts
                            });
                        })
                        .catch(error => {
                            this.setState(defaults);
                            createAlert(error, `error`);
                        })
                ]).finally(props.onLoaded);
            }
        );
    }

    _evenPercent(values) {
        const total = Object.values(values).reduce((a, b) => a + b, 0);
        let remainder =
            100 -
            Object.values(values)
                .map(a => Math.floor((a / total) * 100))
                .reduce((a, b) => a + b);
        const percentages = Object.keys(values).map((key, i) => [key, Math.floor((values[key] / total) * 100)]);
        percentages.sort((a, b) => b[1] - a[1]);
        return Object.assign(
            ...percentages.map(item => ({ [item[0]]: Number(item[1]) + Number(remainder-- > 0 ? 1 : 0) }))
        );
    }

    _renderLoadingCard(title, loadingClass) {
        return (
            <div className={loadingClass}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            {title}
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            <Spinner />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderContactDetails() {
        return (
            <>
                {(this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin) && (
                    <NumberChart
                        title="Contacts"
                        tooltip={
                            <span>
                                <p>This card is an indicator of the performance of LiveSocial Text, measured </p>
                                <p>by the percentage of contacts that have replied to your users</p>
                                <p>This card does not include texts from users excluded from reporting.</p><br></br>
                                <p>Contacts texted: the number of contacts your users have texted using Livesocial Text</p>
                                <p>Contacts replied: the number of contacts that have replied to your users</p>
                                <p>Ratio: Contacts replied divided by Contacts texted as a percentage</p>
                            </span>
                        }
                        data={{
                            "Contacts texted": {
                                label: ["Contacts texted"],
                                value:
                                this.state.contacts && this.state.contacts.contactsTexted
                                    ? this.state.contacts.contactsTexted
                                    : 0
                            },
                            "Contacts replied": {
                                label: ["Contacts replied"],
                                value:
                                this.state.contacts && this.state.contacts.contactsReplied
                                    ? this.state.contacts.contactsReplied
                                    : 0
                            },
                            Ratio: {
                                label: ["Ratio"],
                                value: (
                                    <PercentCircle
                                        percent={
                                            this.state.contacts.contactsTexted > 0
                                                ? getPercent(
                                                      this.state.contacts.contactsTexted,
                                                      this.state.contacts.contactsReplied,
                                                      0
                                                  )
                                                : 0
                                        }
                                    />
                                )
                            }
                        }}
                        loading={
                            !this.state.contacts ||
                            (typeof this.state.contacts === "string" && this.state.contacts.indexOf("loading") !== -1)
                        }
                    />
                )}
            </>
        );
    }

    render() {
        return (
            <Wrapper id="contacts">
                <div className="col-xs-12 col-lg-6">
                    {this._renderContactDetails()}
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });
export default connect(mapStateToProps)(ContactsSection);
