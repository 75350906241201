import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Article } from "../../../utils/Models";
import ElementEditCard from "../../molecules/ElementEditCard";
import { ArticleActions } from "../../../actions/ArticleActions";
import Spinner from "../../atoms/Spinner";
import { doGetClients, createAlert, UtilActions, doGetQuotes, doGetReduxStreams, AuditLogActions, AdminActions, doGetArticles } from "../../../actions";
import { history } from "../../../utils/store";
import Button from "../../atoms/Button";
import Tooltip from "../../atoms/Tooltip";
import SharedArticle, { isNetworkValid } from "./sharedArticle";
import { canSubmitArticle } from "./submit";
import { isURL, extractHostname, downloadFile } from "../../../utils/Helpers";
import { parsePeriod, validatePeriods } from "../../molecules/Periods";
import Select from "../../atoms/Select";
import NewContentEmailModal from "../../molecules/modals/NewContentEmailModal";
import * as twttrtxt from "twitter-text";
import { getClientNetworks, getClientSharePermissions } from '../../common'

interface IEditArticleProps extends RouteComponentProps<{ cid: string; artid?: string; }, {}, Partial<ServerTypes.Console.IArticle>> {
    user: Mongo.clientAdmin;
    clients: dynamic<Mongo.client>;
    streams: Mongo.IStream[];
    isMedia?: boolean;
}
interface IEditArticleState {
    artid: ServerTypes.Console.IArticle["artid"];
    cid: string;
    client?: Mongo.IClient | null;
    article: Partial<ServerTypes.Console.IArticle> | null;
    whiteLabelURL: string;
    deepLinkUrl: string;
    sURLDomain: string;
    quotes: string[];
    imageModal: boolean;
    multipleCommentsModal: boolean;
    networkCommentsModal: boolean;
    generateAICommentsModal: boolean;
    termsModal: boolean;
    sid: string | undefined;
    hashtagsModal: boolean;
    logs: any[];
    uploadedFile: boolean;
    fileUpload: File[] | null;
    totals: {
        hashtags: string[];
        mentions: string[];
        terms: string[];
    };
    renderForceSchedule: boolean;
    loading: boolean;
    loadAuditTrail: boolean;
    loadingQuotes: boolean;
    thumbnail: string | undefined;
    thumbnailModal: boolean;
    showAuditTrail: boolean;
    auditTrailLoaded: boolean;
    customThumbnailUrl: string | undefined;
    customThumbnailFile: File | null | undefined;
    customPdfFile: File | null | undefined;
    expiryDateValue: string;
    isDuplicate: boolean;
    videoDuration: number | null;
    videoWidth: number | null;
    videoHeight: number | null;
    currentMentionToFill: string;
    typeaheadOptions: dynamic[];
    mentionsToAdd: {
        mention: string,
        id: string,
        notCompany?: boolean,
        vanityName?: string
    }[];
    aiCommentPref: Mongo.ClientAdmin["aiCommentPref"] | null;
    changePref?: boolean;
    isSequential?: boolean;
    isSequentialSet?: boolean;
    isContentEmailModalOpen: boolean;
    ntwrkSuggestedWithMentions?: boolean;
}
class EditArticle extends Component<IEditArticleProps, IEditArticleState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = true;
    static allowReports = false;
    facebookMaxLength = 3000;
    linkedInMaxLength = 3000;
    twitterMaxLength = 280;
    instaMaxLength = 3000;
    editStartTime = new Date();

    constructor(props: IEditArticleProps) {
        super(props);
        this._getState = this._getState.bind(this);
        this._setState = this._setState.bind(this);
        this.state = {
            artid: props.match.params.artid || "",
            cid: props.match.params.cid,
            article: null,
            whiteLabelURL: "",
            deepLinkUrl: "",
            sURLDomain: "",
            quotes: [],
            imageModal: false,
            multipleCommentsModal: false,
            networkCommentsModal: false,
            generateAICommentsModal: false,
            termsModal: false,
            hashtagsModal: false,
            uploadedFile: false,
            fileUpload: null,
            totals: { hashtags: [], mentions: [], terms: [] },
            renderForceSchedule: false,
            loading: false,
            loadAuditTrail: false,
            loadingQuotes: false,
            thumbnail: "",
            showAuditTrail: false,
            auditTrailLoaded: false,
            sid: "",
            thumbnailModal: false,
            videoDuration: null,
            videoWidth: null,
            videoHeight: null,
            customThumbnailUrl: "",
            customThumbnailFile: null,
            logs: [],
            customPdfFile: null,
            expiryDateValue: props.match.params.artid ? "choose" : "year",
            isDuplicate: props.location?.state?.isDuplicate ? props.location?.state?.isDuplicate : false,
            currentMentionToFill: "",
            typeaheadOptions: [],
            mentionsToAdd: [],
            aiCommentPref: null,
            changePref: false,
            isSequential: false,
            isSequentialSet: false,
            isContentEmailModalOpen: false,
        };
    }

    async fetchAuditLogDetails(): Promise<void> {
        if (!this.state.artid || !(this.props.user.isSuper || this.props.user.isSuperClientAdmin)) {
            console.log("No artid or user is not super admin", !(this.props.user.isSuper || this.props.user.isSuperClientAdmin));
            return;
        }

        try {
            // Set loading state before fetching data
            this.setState({ loadAuditTrail: true });

            const logDetails = await AuditLogActions.doSearch({ action: 'UPDATE', object: "ClientArticle", cid: this.state.client?.cid }, {});

            if (logDetails?.items) {
                const logs = logDetails.items.filter((item: any) => {
                    return item.initial && item.updated && item.initial.artid === this.state.artid;
                });

                // Set logs and loading state based on the result
                this.setState({ logs, loadAuditTrail: false });
            } else {
                // Set logs as empty and loading state to false if no items found
                this.setState({ logs: [], loadAuditTrail: false });
            }
        } catch (error) {
            // Handle error by setting logs as empty and loading state to false
            this.setState({ logs: [], loadAuditTrail: false });
        }
    }
    // fetch logs here by calling fetchAuditLogDetails
     handleToggleAuditTrail = () => {
        this.setState(prevState => ({
            showAuditTrail: !prevState.showAuditTrail,
            auditTrailLoaded: prevState.auditTrailLoaded || !prevState.showAuditTrail,
        }));
        if (!this.state.auditTrailLoaded) {
            this.fetchAuditLogDetails();
        }
    };

    async _downloadUnscheduledArticleAffectedUsersCsv(artid: string, cid: string) {
        const filters = { artid, cid };
        const results = await ArticleActions.doGetUnscheduledArticleAffectedUsers(filters).catch(error => {
            createAlert(error, `error`);
        });
        if (results) {
            downloadFile(results, `unscheduledArticleAffectedUsers-${artid}.csv`);
        }
    }

    async componentDidMount() {
        const { cid } = this.state;
        const clients = await doGetClients();
        const client = cid && clients && clients.length ? clients.filter(c => c.cid == cid)[0] || null : null;
        const whiteLabelURLs = await UtilActions.doGetWhiteLabelURL(client && client.configName || "none");
        const article = await this._makeArticle(this.state.artid, cid);
        const aiCommentPref = client?.aiGeneratedAdminSuggestionsWithChatGPT ? await AdminActions.doGetAICommentPref() : null;
        this.setState({ mentionsToAdd: article.linkedinMentions || [], ntwrkSuggestedWithMentions: (typeof article.networkSuggestedComments === "object" && article.networkSuggestedComments?.LinkedIn?.length && article.linkedinMentions?.length) ? true : false } as IEditArticleState);
        // Set to whiteLabelUrls if it exists, otherwise set to default
        const incomingUrl = `${whiteLabelURLs?.url || "https://livesocial.seismic.com/"}?ats=${article.Link}&artid=${this.state.artid}`;
        const deepLinkUrl = client!.isEmbeddedClient && await UtilActions.doGetDeepLinkUrl(client!.embeddedClientAppId!, client!.embeddedClientExtensionUniqueId!, client!.embeddedClientURL!, incomingUrl);
        // refresh avaliable streams from backend
        doGetReduxStreams(cid);

        this.setState({
            client,
            article,
            whiteLabelURL: whiteLabelURLs ? whiteLabelURLs.url : "",
            deepLinkUrl: deepLinkUrl ? deepLinkUrl : "",
            sURLDomain: whiteLabelURLs ? whiteLabelURLs.SURLDomain : "",
            aiCommentPref: aiCommentPref ? aiCommentPref : null,
            ...(client?.articleExpiryDefault ? { expiryDateValue: "choose" } : {}),
        } as IEditArticleState);
    }

    async _onSubmit(item: ServerTypes.Console.IArticle) {
        const { isMedia, user } = this.props;
        const { client, fileUpload, customThumbnailUrl, customThumbnailFile, customPdfFile, renderForceSchedule, expiryDateValue, mentionsToAdd } = this.state;
        const valid = await canSubmitArticle(item, isMedia, fileUpload, client, customPdfFile != null);
        if (!valid) return;
        const { activeDate, inActiveDate, CreatedOn, cid, sid, suggestedComments, comment, artid, HashtagsAndMentions, displayAdvisorNote, LockHashtagsAndMentions, Images, Title, Domain, forceScheduleDate, suggestedCommentMentions } = item;
        let { Link, status, Terms, duplicate, commentURL, videoDuration, videoWidth, videoHeight, sharingPermissions } = item;
        const Hashtags = (HashtagsAndMentions || []).filter(item => item.indexOf('#') == 0).map(x => x.replace(/^#/, ""));
        const Mentions = (HashtagsAndMentions || []).filter(item => item.indexOf('@') == 0).map(x => x.replace(/^@/, ""));
        const hasAdminComment = !!((comment || "").length || (suggestedComments || []).length);
        const oldArticle = artid ? await this._makeArticle(artid, cid) : null;
        if (oldArticle && typeof oldArticle?.sharingPermissions == "object" && (oldArticle.sharingPermissions.social == true || oldArticle.sharingPermissions.email == true || oldArticle.sharingPermissions.sms == true) && typeof item.sharingPermissions == "object" && item.sharingPermissions.social == false && item.sharingPermissions.email == false && item.sharingPermissions.sms == false) {
            if (
                !confirm(
                    `This piece of content has been set to read only. Therefore, all currently scheduled shares for this piece of content will be unscheduled. Note this will not remove shares that have already been posted on social networks.\n\nDo you wish to continue?`
                )
            ) {
                return;
            }
        }
        if (oldArticle && oldArticle?.inActiveDate !== item?.inActiveDate) {
            if (!confirm('The expiry date will be changed. After confirming to update this content, any previously scheduled shares will still occur. \nIf the scheduled date is after the new expiry date, it will be rescheduled to before the updated expiry date. \nIf your intention is to unschedule scheduled shares, please use the "Unschedule" button instead.')) {
                return
            }
        }
        if (oldArticle && typeof oldArticle?.sharingPermissions == "object" && typeof item.sharingPermissions == "object") {
            if (!Object.values(oldArticle.sharingPermissions).some(perm => perm) && Object.values(item.sharingPermissions).some(perm => perm)) {
                const newPermissionsArr = Object.keys(item.sharingPermissions).filter(key => item.sharingPermissions![key]);
                const newPermissions = newPermissionsArr.map(key => {
                    if (key === "sms") {
                        return key.toUpperCase();
                    } else {
                        return key.charAt(0).toUpperCase() + key.slice(1);
                    }
                }).join(', ');
                if (!confirm(`This piece of content was previously set to read-only.\n\nAre you sure you want to change the Sharing Permissions to ${newPermissions}?`)) {
                    return;
                }
            }
        }

        if (oldArticle && oldArticle.lastUpdatedOn && new Date(oldArticle.lastUpdatedOn) > this.editStartTime) {
            createAlert("This article has been updated since you started editing it. Please make note of your changes and refresh the page to try again.", "error");
            return;
        }
        if (!client) {
            createAlert("Please select a client for this article", "error");
            return;
        }

        if (typeof item.sharingPermissions === "object") {
            const { sharingPermissions } = item;

            const checkNetworkPermissions = client?.allowNetworkRestrictions && sharingPermissions.social;
            if (checkNetworkPermissions) {
                // Populate default social network permissions if they don't exist
                if (!sharingPermissions.socialNetworkPermissions) {
                    sharingPermissions.socialNetworkPermissions = {
                        facebook: true,
                        twitter: true,
                        instagram: true,
                        linkedin: true
                    }
                }

                const hasMedia = Boolean((fileUpload && fileUpload.length > 0) || item.Image || (item.Images && item.Images.length > 0))
                const anyInvalid = getClientNetworks(client, isMedia === true).some(network => {
                    const result = isNetworkValid(network, comment ?? '', videoDuration ? Number(videoDuration) : 0, isMedia ?? false, hasMedia)
                    const key = network.toLowerCase()
                    if (!result.valid) {
                        if (checkNetworkPermissions) {
                            if (sharingPermissions?.socialNetworkPermissions?.[key]) {
                                createAlert(result.message, 'error');
                                return true;
                            }
                        } else {
                            if (sharingPermissions?.socialNetworkPermissions?.[key]) {
                                sharingPermissions.socialNetworkPermissions[key] = false
                            }
                        }
                    }
                    return false
                })
                if (anyInvalid) {
                    return false
                }

                // Make sure at least one social network is selected
                const isAnyNetworksSelected = Object.keys(sharingPermissions.socialNetworkPermissions)
                    .some(key => sharingPermissions.socialNetworkPermissions![key]);
                if (!isAnyNetworksSelected) {
                    createAlert(`You must select at least one social network`, `error`);
                    return
                }
            }
        }

        if (!sid || !client.streams[sid]) {
            createAlert("Please select a stream for this article to be approved in", "error");
            return;
        }

        if (sid && client.streams[sid] && client.streams[sid].type === "HELP") {
            sharingPermissions = {
                social: false,
                email: false,
                sms: false,
                socialNetworkPermissions: {
                    facebook: false,
                    twitter: false,
                    instagram: false,
                    linkedin: false
                }
            }
        }

        let clientMentions = client.mentions?.[client.streams[sid].lang] || client.mentions?.en;
        let arrayLinkedinMentions: {
            mention: string,
            id?: string,
            notCompany?: boolean,
            vanityName?: string
        }[] = [...mentionsToAdd];

        if (clientMentions && Mentions) {
            for (let i = 0; i < clientMentions?.length; i++) {
                for (let j = 0; j < Mentions.length; j++) {
                    let newMentions = { ...clientMentions[i] };
                    if (Mentions[j] === clientMentions[i].mention && clientMentions[i].id!) {
                        delete newMentions.checked;
                        arrayLinkedinMentions?.push(newMentions);
                    }
                }

            }
        }

        arrayLinkedinMentions = arrayLinkedinMentions.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);

        let url = "";
        if (commentURL) {
            commentURL = commentURL.trim();
            if (commentURL.indexOf("http") === -1) {
                commentURL = "http://" + commentURL;
            }

            if (!isURL(commentURL)) {
                createAlert("Please enter a proper url", "error");
                return;
            }
            url = commentURL;
        }
        if (Link && !customPdfFile) {
            Link = Link.trim();

            if (Link.indexOf("http") === -1) {
                Link = "http://" + Link;
            }

            if (!isURL(Link)) {
                createAlert("Please enter a proper url", "error");
                return;
            }
            url = Link;
        }

        if (!customPdfFile && !artid && url) {
            try {
                await ArticleActions.doLoad(url, client.streams[sid].lang, false);
            } catch (err) {
                if (err === "DUPLICATE_ARTICLE_LINK") {
                    if (
                        !confirm(
                            "This article already exists in your library, are you sure you want to re-add it?"
                        )
                    ) {
                        return;
                    } else {
                        duplicate = true;
                    }
                }
            }
        }

        const periods = sid && client.streams[sid].subscriptionPeriods && client.streams[sid].subscriptionPeriods!.length ? client.streams[sid].subscriptionPeriods!.map(p => parsePeriod(p as unknown as ServerTypes.Console.ISubscriptionPeriod)) : null;

        let sequentialOrder = 0;
        const stream = sid && client.streams[sid] || null;
        const isSequential = stream?.sequentialContent || false;
        if (!!isSequential && new Date(activeDate) >= new Date(stream?.sequentialContentEnabledDate!)) {
            const articlesFromStream = await doGetArticles(client.cid, stream?.sid!, "approved", 0, 1000, null, null, null, null, null, null, true, false, 1);
            if (articlesFromStream?.items?.length) {
                sequentialOrder = Math.max(...(articlesFromStream.items.map(a => a.sequentialOrder || 0))) + 1;
            } else {
                sequentialOrder = 1;
            }
        };

        const _createdOn = typeof CreatedOn === "string" ? new Date(CreatedOn) : CreatedOn;

        const now = new Date();

        const _activeDate = (activeDate && (new Date(activeDate) > now) || activeDate === oldArticle?.activeDate!)
        ? new Date(activeDate)
        : now;

        let validActiveDate: boolean[];
        if (_activeDate && expiryDateValue !== "now") {
            if (this._isAutoPost(item) && periods) {
                validActiveDate = periods.map(period => {
                    const end = new Date(period.endDate);
                    end.setDate(end.getDate() + 1);
                    return moment(_activeDate!).isSameOrAfter(new Date(period.startDate)) && moment(_activeDate!).isSameOrBefore(end);
                });
                if (!validActiveDate.includes(true)) {
                    return createAlert("Approval date must be within a stream subscription period that is not expired", "error");
                }
            }
        }

        if (!inActiveDate) {
            return createAlert("Please set an expiry date");
        }

        const _inActiveDate = new Date(inActiveDate);

        const exceedsTwitterCommentLength = comment && twttrtxt.parseTweet(comment).weightedLength > this.twitterMaxLength
        const isTwitterSelected = typeof item.sharingPermissions === 'object' && (!('socialNetworkPermissions' in item.sharingPermissions) || item.sharingPermissions?.socialNetworkPermissions?.twitter)

        if (moment(_createdOn.toDateString()).isAfter(_inActiveDate.toDateString())) {
            return createAlert("Scheduled date can not be set after expiry date", "error");
        }
        if (activeDate && forceScheduleDate && new Date(activeDate).getTime() > new Date(forceScheduleDate).getTime()) {
            return createAlert("Approved date cannot be set past force schedule date", "error");
        }
        if (forceScheduleDate && new Date(forceScheduleDate).getTime() > _inActiveDate.getTime()) {
            return createAlert("Force scheduled date cannot be set past expiry date", "error");
        }
        if (forceScheduleDate && comment && exceedsTwitterCommentLength && isTwitterSelected) {
            createAlert(`This article will not be posted to X (Twitter) as the comment is too long. Please reduce the comment length to ${this.twitterMaxLength} characters or less.`, `warning`);
        }
        if ( comment && exceedsTwitterCommentLength && client.socialNetworks.Twitter.available && isTwitterSelected) {
            createAlert(`Character limit has been exceeded for X (Twitter), and will not be a valid network for this share`, `warning`);
        }

        if (status == "suggested") status = "approved";

        if (Terms && typeof Terms === "string") {
            Terms = (Terms as string).split(",").map(item => item.trim());
        }
        delete item.Content;
        if (item.docId == undefined) delete item.docId;

        let artSpecificSharePerms = false;
        const streamSharePerms = client.streams[sid].defaultSharingPermissions;
        if (streamSharePerms && typeof streamSharePerms == "object" && sharingPermissions && typeof sharingPermissions == "object") {
            if (
                streamSharePerms.social !== sharingPermissions.social ||
                streamSharePerms.email !== sharingPermissions.email ||
                streamSharePerms.sms !== sharingPermissions.sms
            ) {
                artSpecificSharePerms = true;
            }
        }

        const socialNetworkSuggestedComments = item.networkSuggestedComments;
        const articleSource = ["twitter", "facebook", "instagram", "linkedIn"];

        arrayLinkedinMentions = arrayLinkedinMentions.filter((v,i,a)=>a.findIndex(v2=>(v2.id == v.id))===i);

        const submitArticle: ServerTypes.Console.IArticle = {
            ...item,
            Link,
            activeDate: _activeDate ? _activeDate.toISOString() : now.toISOString(),
            inActiveDate: _inActiveDate.toISOString(),
            status,
            Terms: JSON.stringify(Terms),
            Hashtags: JSON.stringify(Hashtags),
            LockHashtagsAndMentions,
            Mentions: JSON.stringify(Mentions),
            suggestedComments: JSON.stringify(suggestedComments),
            ...(suggestedCommentMentions ? { suggestedCommentMentions: JSON.stringify(suggestedCommentMentions) } : null),
            Title,
            displayAdvisorNote,
            Domain: Domain || extractHostname(Link) || extractHostname(commentURL || null),
            hasAdminComment,
            CreatedOn: _createdOn ? _createdOn.toISOString() : now.toISOString(),
            ...(user ? { email: user.email, firstName: user.name } : null),
            ...(client.streams && client.streams[sid]
                ? { searchType: client.streams[sid].searchType, streamCode: client.streams[sid].code, lang: client.streams[sid].lang }
                : null),
            ...(duplicate ? { duplicate } : null),
            ...(artid ? { artid } : null),
            shareFormat: customPdfFile || (Link && Link.match(/.(pdf)$/i)) ? "pdf" : isMedia ? "media" : "article",
            isUpload: customPdfFile ? true : false,
            lastUpdatedOn: now,
            ...(sharingPermissions ? { sharingPermissions: JSON.stringify(sharingPermissions) } : null),
            ...(customThumbnailUrl ? { customThumbnailUrl } : null),
            ...(Images ? { Images: JSON.stringify(Images) } : null),
            commentURL: commentURL && commentURL !== "" ? commentURL : null,
            source: articleSource.includes(item.source) ? item.source : (isMedia || customPdfFile) ? "user" : "",
            ...(videoDuration ? { videoDuration } : null),
            ...(videoWidth ? { videoWidth } : null),
            ...(videoHeight ? { videoHeight } : null),
            ...(socialNetworkSuggestedComments ? { networkSuggestedComments: JSON.stringify(socialNetworkSuggestedComments) } : null),
            ...(arrayLinkedinMentions ? { linkedinMentions: JSON.stringify(arrayLinkedinMentions) } : null),
            ...((!!isSequential && sequentialOrder) ? { sequentialOrder } : null),
        };
        if (submitArticle.sharingPermissions === "{}" || (!artSpecificSharePerms &&
            (!oldArticle || typeof oldArticle.sharingPermissions !== "object") && typeof (submitArticle.sharingPermissions) !== "string")) {
            delete submitArticle.sharingPermissions;
        }

        if (renderForceSchedule && item.forceScheduleDate) {
            const date = this._getForceScheduleDefaultDate(item.activeDate);
            if (new Date(item.forceScheduleDate).getTime() < date.getTime()) {
                createAlert(
                    `Please choose a scheduled time after ${moment(date).tz(moment.tz.guess()).format("YYYY/MM/DD h:mm a z")}`,
                    `error`
                );
                return;
            }
        }
        try {
            this.setState({ loading: true });
            const thumbnailFile = customThumbnailFile || undefined;
            await ArticleActions.doUpsert(submitArticle, fileUpload as File[], thumbnailFile, customPdfFile);
            if (_inActiveDate.getTime() <= Date.now() && artid) {
                createAlert(`Content successfully set as expired using current time.`, `success`);
            } else {
                createAlert(`Successfully ${item.artid ? "updated" : "created"} content${item.artid && !(_activeDate.toISOString() > now.toISOString()) ? ", with current approval time" : ""}`, `success`);
            }
            this.setState({ loading: false });
            // Redirect to library
            setTimeout(() => {
                history.push("/content/l");
            }, 0);
        } catch (error) {
            // @ts-expect-error
            createAlert(error);
            this.setState({ loading: false });
        }
    }

    async _onRemove(item: ServerTypes.Console.IArticle) {
        try {
            await ArticleActions.doRemove(item.artid, item.cid);
            createAlert(`Successfully deleted content`, `success`);

            // Redirect to library
            setTimeout(() => {
                history.push("/content/l");
            }, 0);
        } catch (error) {
            // @ts-expect-error
            createAlert(error);
        }
    }

    async _loadSuggestedComments(client: Mongo.IClient, article: ServerTypes.Console.IArticle) {
        if (!client || !client.aiGeneratedAdminSuggestions || !article.Link) {
            return [];
        }
        this.setState({ loadingQuotes: true });

        const suggestedQuotes = await doGetQuotes(
            article.Link,
            article.sid && client.streams && client.streams[article.sid] ? client.streams[article.sid].lang : "en",
            client.aiGeneratedAdminSuggestionsWithChatGPT,
            client.cid
        );
        this.setState({ loadingQuotes: false });
        return suggestedQuotes && !("valid" in suggestedQuotes) && suggestedQuotes.numFound
            ? suggestedQuotes.items.map(i => i.quote)
            : [];
    }

    async _onLoad(article: ServerTypes.Console.IArticle) {
        this.setState({ loading: true });
        const { client } = this.state;
        if (client) {
            const renderForceSchedule = !article.hasScheduled && article.forceScheduleDate && new Date(article.forceScheduleDate) > new Date() ? true : false;
            const quotes = (client?.aiGeneratedAdminSuggestions && client.aiGeneratedAdminSuggestionsWithChatGPT) ? [] : await this._loadSuggestedComments(client, article);
            this.setState({ quotes, renderForceSchedule, loading: false });
        }
    }

    _getForceScheduleDefaultDate(activeDate: Date | string) {
        const minDateTime = new Date();
        minDateTime.setHours(minDateTime.getHours() + (minDateTime.getMinutes() >= 40 ? 3 : 2), 0, 0, 0);
        const date = activeDate ? new Date(activeDate) : new Date();

        // activeDate is today, lets set the default force schedule time to closest hour, if were past 40 minutes (when the cron runs, make it 2)
        if (date.getFullYear() <= minDateTime.getFullYear() && date.getMonth() <= minDateTime.getMonth() && date.getDate() <= minDateTime.getDate()) {
            return minDateTime;
        }
        return date;
    }

    /**
     * Some streams use custom article source options, we only want to pull in streams that are sourced from the library here
     */
    _getValidStreams(selectedStream: Mongo.IStream) {
        const { streams } = this.props;
        return (Object.values(streams) || []).filter(stream => {
            return (
                stream.active && // active stream
                (stream.isApprovalRequired || selectedStream && !selectedStream.isApprovalRequired) && // we are sourced from the library and if editing a stream we'll allow to display the auto curated stream but will disable the stream dropdown
                (!stream.contentSearch || // all streams that aren't searches
                    (stream.contentSearch && stream.isCustom)) && // isCustom means we are a stream and not a search
                stream.type !== "TRENDING" // dont include trending stream
            );
        }).sort((a, b) => {
            return a.title.localeCompare(b.title);
        });
    }

    _getTotals(client: Mongo.IClient, lang: string) {
        return {
            hashtags: client.hashtags && client.hashtags[lang] ? client.hashtags[lang].map(item => item.hashtag) : [],
            mentions: client.mentions && client.mentions[lang] ? client.mentions[lang].map(item => item.mention) : [],
            terms: client.terms && client.terms[lang] ? client.terms[lang].map(item => item.term) : []
        };
    }

    async _makeArticle(artid: ServerTypes.Console.IArticle["artid"], cid: ServerTypes.Console.IArticle["cid"]) {
        const { client } = this.state;
        const propsArticle = this.props.location?.state;
        const article = propsArticle || (artid ? await ArticleActions.doGet(artid, cid) : null);
        const Hashtags = (article?.Hashtags as string[] || []).map(i => '#' + i);
        const Mentions = (article?.Mentions as string[] || []).map(i => '@' + i);
        if (article?.sid && client?.allowContentSpecificPermissions) {
            article.sharingPermissions = article.sharingPermissions || client.streams[article.sid].defaultSharingPermissions || {
                social: true,
                email: true,
                sms: true,
                socialNetworkPermissions: {
                    facebook: true,
                    twitter: true,
                    instagram: true,
                    linkedin: true
            }};
        }
        const inActiveDate = new Date();
        client?.articleExpiryDefault ? inActiveDate.setDate(inActiveDate.getDate() + client.articleExpiryDefault) : inActiveDate.setFullYear(inActiveDate.getFullYear() + 1);
        return {
            ...article,
            ...(propsArticle?.origLink && propsArticle?.isDuplicate && !propsArticle?.artid ? { Link: propsArticle.origLink, origLink: null } : null),
            HashtagsAndMentions: [...new Set((Hashtags).concat(Mentions))],
            ...(artid && article?.inActiveDate ? null : { inActiveDate })
        };
    }

    _isAutoPost(article: ServerTypes.Console.IArticle) {
        const { client } = this.state;
        return (
            client &&
            client.allowAutopost &&
            article.sid &&
            client.streams[article.sid] &&
            client.streams[article.sid].allowAutopost
        );
    }

    _dateHasPassed(article: ServerTypes.Console.IArticle) {
        const today = new Date();
        return !!(this._isAutoPost(article) && article.activeDate && moment(new Date(article.activeDate).toDateString()).isBefore(moment(today.toDateString())));
    }

    _setState(item) {
        this.setState(item);
    }

    _getState() {
        return this.state;
    }

    async _onUnschedule(item: ServerTypes.Console.IArticle) {
        try {
            const scheduledShares = await ArticleActions.doGetFutureSharesByArtid(item.artid ? item.artid : "", item.cid);
            const postedShares = await ArticleActions.doGetPostedSharesByArtid(item.artid ? item.artid : "", item.cid)
            if (confirm(`Are you sure you want to remove all scheduled shares of this article and remove it from view from all users? It has been scheduled by ${scheduledShares === 1 ? `${scheduledShares} user` : `${scheduledShares} users`}, and already been shared by ${postedShares === 1 ? `${postedShares} user` : `${postedShares} users`}. If needed, the admin should notify users that this article has been removed from view and cannot be scheduled to share.`)) {
                try {
                    if (scheduledShares > 0 || postedShares > 0) {
                        if (confirm("Would you like to download a copy of all the users affected by unscheduling this article?")) {
                            await this._downloadUnscheduledArticleAffectedUsersCsv(item.artid ? item.artid : "", item.cid);
                        }
                    }
                    // unschedule all schedules for the article and remove the article
                    await ArticleActions.doUnscheduleAndRemove(item.sid, item.artid, item.Link, item.cid);
                    createAlert(`Unscheduling article. This may take sometime to complete.`, `success`);
                    this.setState({ loading: false });
                    // Redirect to library
                    setTimeout(() => {
                        history.push("/content/l");
                    }, 0);
                } catch (error) {
                    // @ts-expect-error
                    createAlert(error);
                }
            }
        } catch (error) {
            // @ts-expect-error
            createAlert(error);
        }
    }

    render() {
        const { isMedia, user, clients, streams } = this.props;
        const {  artid, cid, sid, client, article, isContentEmailModalOpen, whiteLabelURL, deepLinkUrl, quotes, videoDuration, imageModal, multipleCommentsModal, networkCommentsModal, generateAICommentsModal, termsModal, hashtagsModal, uploadedFile, renderForceSchedule, loading, thumbnail, thumbnailModal, customThumbnailUrl, customThumbnailFile, fileUpload, sURLDomain, customPdfFile, expiryDateValue, videoWidth, videoHeight, aiCommentPref, changePref } = this.state;
        let { totals } = this.state;
        let readOnly = false;
        const emails = client?.customEmails?.["activationEmail"] ?? {};
        const singleClientAdmin = user.isAdmin || user.isClientAdmin || user.isSuperClientAdmin;
        const hasShareableLink =
            article &&
            artid && whiteLabelURL &&
            article.sid && client?.streams[article.sid].canATSCopyLink &&
            article.status && ["approved"].indexOf(article.status) !== -1 &&
            typeof article.sharingPermissions === 'object' &&
            (article.sharingPermissions.social ||
                article.sharingPermissions.email ||
                article.sharingPermissions.sms);

        let sharePermissions: string[] = [];
        if (client) {
            sharePermissions = getClientSharePermissions(client);

            if (sid) {
                const streamSharePerms = client.streams[sid].defaultSharingPermissions;
                if (streamSharePerms && typeof streamSharePerms == "object") {
                    if (!streamSharePerms.social && !streamSharePerms.email && !streamSharePerms.sms) {
                        readOnly = true;
                    }
                }
            }

        }

        return (
            (!client || !streams || !Object.values(streams).length) ? (
                <Spinner />
            ) : (
                <ElementEditCard<ServerTypes.Console.IArticle>
                    loading={loading}
                    loadAuditTrail = {this.state.loadAuditTrail}
                    instructions="https://docs.seismic.com/bundle/livesocial/page/create_livesocial_articles.html"
                    onLoad={async item => this._onLoad(item)}
                    getItem={async () => Article({ cid, ...(await this._makeArticle(artid, cid)) }, this.state.isDuplicate)}
                    canUpdate={() => Promise.resolve(true)}
                    update={item => this._onSubmit(item)}
                    canRemove={artid ? () => Promise.resolve(true) : undefined}
                    remove={artid ? item => this._onRemove(item) : undefined}
                    canUnschedule={() => Promise.resolve(true)}
                    auditTrail = {
                        (this.props.user.isSuper || this.props.user.isSuperClientAdmin) && (
                            <div className="audit-trail">
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={this.handleToggleAuditTrail}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        {this.state.showAuditTrail ? "Hide" : "Show"} Audit Trail
                                    </Button>
                                    {/* Is Duplicated? */}
                                    {this.state.logs.length > 0 && this.state.showAuditTrail && (
                                        <span style={{ marginLeft: "10px" }}>
                                            Duplicated: <strong>{this.state.logs[0].initial.duplicate ? "Yes" : "No"}</strong>
                                        </span>
                                    )}
                                </div>
                                {this.state.showAuditTrail && (
                                    <div>
                                        {this.state.auditTrailLoaded && (
                                            <>
                                                {this.state.loadAuditTrail ? (
                                                    <div className="loading-spinner">
                                                        {/* Show spinner while loading */}
                                                    </div>
                                                ) : (
                                                    <>
                                                        {this.state.logs.length > 0 ? (
                                                            <div className="audit-trail-table" style={{ marginTop: '10px' }}>
                                                                <table style={{ width: '100%' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ textAlign: 'left', width: '10%' }}>Updated On</th>
                                                                            <th style={{ textAlign: 'left'}}>New Update</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.logs.map((log, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <tr>
                                                                                    <td style = {{verticalAlign: 'baseline'}}>{moment(log.updated.lastUpdatedOn).format("YYYY/MM/DD h:mm a z")}</td>
                                                                                    <td style= {{ textAlign: 'left'}}>
                                                                                        {Object.keys(log.updated)
                                                                                            .filter(key => key !== 'lastUpdatedOn')
                                                                                            .map((key, idx) => (
                                                                                                <span key={key}>
                                                                                                    <strong>{key}</strong>: {JSON.stringify(log.updated[key])}
                                                                                                    {idx !== Object.keys(log.updated).length - 2 && ', '}
                                                                                                </span>
                                                                                            ))}
                                                                                    </td>
                                                                                </tr>
                                                                                {index <= this.state.logs.length - 2 && (
                                                                                    <br></br>
                                                                                )}

                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <p>{this.state.auditTrailLoaded ? 'No Updates Found' : 'Loading...'}</p>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) || undefined
                    }
                    unschedule={item => this._onUnschedule(item)}
                    breadcrumbs={[{ name: "Articles", link: "/content/l" }]}
                    elementName={isMedia ? "Media/Text" : "Article"}
                    idField="artid"
                    rows={[
                        {
                            columns: [
                                {
                                    items: ["sid", "Title", "Link", "Image", "Thumbnail", "activeDate", "inActiveDate", "forceScheduleDate", "sharingPermissions", "socialNetworkPermissions"],
                                    key: "ArticleEdit_R1C1"
                                },
                                {
                                    items: ["comment", "commentURL", "Terms", "HashtagsAndMentions", "ATSLink", "SeismicLink", "LockHashtagsAndMentions", "displayAdvisorNote", "notes"],
                                    key: "ArticleEdit_R1C2"
                                }
                            ],
                            key: "ArticleEdit_Row1"
                        }
                    ]}
                    buttons={item =>
                        [
                            {
                                text: "Send New Content Email",
                                disabled: !hasShareableLink || !singleClientAdmin,
                                onClick: () => this.setState({ isContentEmailModalOpen: true }),
                                tooltip: "Email notifications for this article will be available once it has been created. If Article Specific Link Sharing is enabled on the stream, you can return to this page after the article is created and send notifications to users."
                            }
                        ]
                    }
                    mandatoryFieldNote="* = mandatory"
                    editableKeys={(item: ServerTypes.Console.IArticle, getStateManual, setStateManual) => {
                        const stream = client && client.streams[item.sid];
                        if (!this.state.isSequentialSet) {
                            this.setState({ isSequential: stream?.sequentialContent || false, isSequentialSet: true });
                        }
                        const periods: ServerTypes.Console.ISubscriptionPeriod[] | null = item.sid && stream.allowAutopost && stream.subscriptionPeriods && stream.subscriptionPeriods.length ? stream.subscriptionPeriods.map(p => parsePeriod(p as unknown as ServerTypes.Console.ISubscriptionPeriod)) : null;
                        let minDate = new Date();
                        let now = new Date();
                        let yesterday = now.setDate(now.getDate() -1);
                        let start: Date | null = null;
                        let end: Date | null = null;
                        if (periods && periods.length) {
                            for (const { startDate, endDate } of periods) {
                                if (!start || new Date(startDate).getTime() < start.getTime()) {
                                    start = new Date(startDate);
                                }
                                if (!end || new Date(endDate).getTime() > end.getTime()) {
                                    end = new Date(endDate);
                                }
                            }
                            minDate = start!;
                        }
                        let value = new Date(item.activeDate);
                        if (value.getTime() < minDate.getTime()) {
                            value = minDate;
                        }

                        if (client) {
                            totals = this._getTotals(client, (stream ? stream.lang : "en"));
                        }
                        return {
                            sid: {
                                label: "Stream",
                                required: true,
                                type: "select",
                                disabled: stream && !stream.isApprovalRequired,
                                values: this._getValidStreams(stream).map(c => ({ label: c.title, value: c.sid })),
                                mutateChange: async (key, sid) => {
                                    const { client } = this.state;
                                    const stream = client?.streams[sid as string];
                                    const tags = {
                                        Hashtags: [] as string[],
                                        Mentions: [] as string[],
                                        HashtagsAndMentions: [] as string[],
                                        LockHashtagsAndMentions: false,
                                    };
                                    if (!artid && stream?.HashtagsAndMentions?.length) {
                                        tags.Hashtags = (stream?.Hashtags as string[] || []).map(i => '#' + i);
                                        tags.Mentions = (stream?.Mentions as string[] || []).map(i => '@' + i);
                                        tags.HashtagsAndMentions = [...new Set((tags.Hashtags).concat(tags.Mentions))];
                                        if (stream.LockHashtagsAndMentions) {
                                            tags.LockHashtagsAndMentions = stream.LockHashtagsAndMentions
                                        }
                                    }
                                    this._setState({ sid, renderForceSchedule: false, isSequential: stream?.sequentialContent || false });

                                    const defaultPermission = { sharingPermissions: client?.streams[sid as string].defaultSharingPermissions ||
                                        {
                                            social: true,
                                            email: true,
                                            sms: true,
                                            socialNetworkPermissions: {
                                                linkedin: true,
                                                facebook: true,
                                                twitter: true,
                                                instagram: true
                                            }
                                        }
                                    }

                                    if (client?.allowContentSpecificPermissions && typeof defaultPermission.sharingPermissions.socialNetworkPermissions === 'undefined') {
                                        defaultPermission.sharingPermissions.socialNetworkPermissions = {
                                            linkedin: true,
                                            facebook: true,
                                            twitter: true,
                                            instagram: true
                                        }
                                    }

                                    if (isMedia !== true && client?.allowContentSpecificPermissions && defaultPermission.sharingPermissions?.socialNetworkPermissions) {
                                        defaultPermission.sharingPermissions.socialNetworkPermissions.instagram = false
                                    }

                                    return ({
                                        [key]: sid as string,
                                        totals,
                                        forceScheduleDate: null,
                                        ...(tags.HashtagsAndMentions.length ? tags : null),
                                        ...(client?.allowContentSpecificPermissions ? defaultPermission : null),
                                    });
                                },
                                renderAbove: (hasShareableLink && singleClientAdmin ?
                                    <NewContentEmailModal
                                        uid={''}
                                        user={user}
                                        client={client}
                                        clients={clients}
                                        userEmail={user.email}
                                        emails={emails}
                                        article={item}
                                        title={item.Title || ""}
                                        whiteLabelURL={whiteLabelURL}
                                        open={isContentEmailModalOpen}
                                        closeAction={() => this.setState({ isContentEmailModalOpen: false })}
                                    />
                                    : <></>
                                )
                            },
                            generateComment: {
                                disabled: !item.sid,
                                label: "generateAIComment",
                                type: "link",
                                titile: "testing",
                                description: "settings",
                                value: "test.com",
                            },
                            ...SharedArticle({
                                getState: this._getState, setState: this._setState, getStateManual, setStateManual, item, client, uploadedFile, imageModal,
                                totals, termsModal, hashtagsModal, sharePermissions, quotes, multipleCommentsModal, networkCommentsModal, generateAICommentsModal, readOnly,
                                facebookMaxLength: this.facebookMaxLength, linkedInMaxLength: this.linkedInMaxLength, instaMaxLength: this.instaMaxLength,
                                twitterMaxLength: this.twitterMaxLength, isMedia, thumbnail, thumbnailModal, customThumbnailUrl, customThumbnailFile, fileUpload, sURLDomain, customPdfFile, videoDuration, videoWidth, videoHeight, aiCommentPref, changePref
                            }),
                            activeDate: {
                                disabled: !item.sid || this.state.isSequential,
                                label: `Approved Date (${moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()})`,
                                required: true,
                                type: "datetime",
                                timeFormat: "h:mm a z",
                                description: "Set the date you want your articles to start appearing in the app.\n\nIf the selected stream has stream subscription enabled, only dates within the periods of the subscription will be selectable.",
                                renderAbove: <div className="permission__column"></div>,
                                minDate: minDate.toISOString(),
                                ...(start ? {
                                    value
                                } : null),
                                validation: (currentDate: moment.Moment) => {
                                    const minTime = new Date(minDate);
                                    minTime.setUTCHours(0, 0, 0, 0);
                                    if (!periods || !this._isAutoPost(item)) {
                                        return currentDate.isAfter(moment(minTime));
                                    }
                                    return validatePeriods(currentDate, periods);
                                },
                                onChange: (value: string) => {
                                    const renderForceSchedule = !item.hasScheduled && item.forceScheduleDate && new Date(item.forceScheduleDate) > new Date() ? true : false;
                                    const state = {};
                                    if (renderForceSchedule && moment(new Date(value).toDateString()).isAfter(moment(new Date(item.forceScheduleDate!).toDateString()))) {
                                        const forceScheduleDate = this._getForceScheduleDefaultDate(new Date(value));
                                        state["forceScheduleDate"] = forceScheduleDate;
                                    }
                                    if (start) {
                                        state['activeDate'] = value;
                                    }
                                    if (Object.keys(state).length) {
                                        setStateManual(state);
                                    }
                                }
                            },
                            inActiveDate: {
                                disabled: !item.sid || expiryDateValue !== "choose" || this.state.isSequential,
                                required: true,
                                label: `Expiry Date (${moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()})`,
                                type: "datetime",
                                timeFormat: "h:mm a z",
                                description: "Set the date you want your articles to stop appearing in the app",
                                // Setting mindate back a day to allow for social feed articles that come in to be expired today
                                minDate: item.activeDate ? new Date(new Date(item.activeDate).setDate(new Date(item.activeDate).getDate() - 1)).toISOString() : new Date(yesterday).toISOString(),
                                renderAbove: <Select
                                    disabled={!item.sid || this.state.isSequential}
                                    options={[
                                        { label: "1 year from now", value: "year", selected: expiryDateValue === "year" },
                                        { label: "1 quarter from now", value: "quarter", selected: expiryDateValue === "quarter" },
                                        { label: "1 month from now", value: "month", selected: expiryDateValue === "month" },
                                        { label: "2 weeks from now", value: "2weeks", selected: expiryDateValue === "2weeks" },
                                        { label: "1 week from now", value: "week", selected: expiryDateValue === "week" },
                                        { label: "Immediately", value: "now", selected: expiryDateValue === "now" },
                                        { label: "Choose a date", value: "choose", selected: expiryDateValue === "choose" }
                                    ]}
                                    onChange={value => {
                                        if (value === "choose") {
                                            this.setState({ expiryDateValue: value });
                                            setStateManual({ inActiveDate: undefined });
                                        } else {
                                            const date = new Date();
                                            // set to midnight local time
                                            if (value != 'now') {
                                                date.setHours(0, 0, 0, 0);
                                            }
                                            switch (value) {
                                                case "year":
                                                    date.setFullYear(date.getFullYear() + 1);
                                                    break;
                                                case "quarter":
                                                    date.setMonth(date.getMonth() + 3);
                                                    break;
                                                case "month":
                                                    date.setMonth(date.getMonth() + 1);
                                                    break;
                                                case "2weeks":
                                                    date.setDate(date.getDate() + 14);
                                                    break;
                                                case "now":
                                                    date;
                                                    break;
                                                case "week":
                                                default:
                                                    date.setDate(date.getDate() + 7);
                                                    break;
                                            }
                                            this.setState({ expiryDateValue: value as string });
                                            setStateManual({ inActiveDate: date });
                                        }
                                    }}
                                />
                            },
                            ATSLink: {
                                label: "Shareable link",
                                type: "copyLink",
                                hidden: !!(
                                    !(artid && whiteLabelURL) ||
                                    (item.sid && !client.streams[item.sid].canATSCopyLink) ||
                                    ["approved"].indexOf(item.status) == -1 ||
                                    typeof item.sharingPermissions === "object" &&
                                    (!item.sharingPermissions.social &&
                                        !item.sharingPermissions.email &&
                                        !item.sharingPermissions.sms)),
                                description: "This shareable link will open a curated article in a customize modal within the app",
                                value: `${whiteLabelURL}?ats=${ item.Link ? encodeURIComponent(item.Link) : "" }&artid=${encodeURIComponent(artid || "")}`
                            },
                            SeismicLink: {
                                label: "Embedded Shareable link",
                                type: "copyLink",
                                hidden: !!(!(artid && whiteLabelURL) || !(client.isEmbeddedClient) || (item.sid && !client.streams[item.sid].canATSCopyLink) || ["approved"].indexOf(item.status) == -1 ||
                                    typeof item.sharingPermissions === "object" && !item.sharingPermissions.social && !item.sharingPermissions.email && !item.sharingPermissions.sms),
                                description: "This shareable link will open a curated article in a customize modal within the Embedded app",
                                value: `${deepLinkUrl}`
                            },
                            forceScheduleDate: {
                                ...(!renderForceSchedule && !this._dateHasPassed(item) && !item.hasScheduled && {
                                    renderAbove:
                                        <div className="permission__column">
                                            <Button
                                                disabled={!item.sid || this.state.isSequential}
                                                onClick={() => {
                                                    const date = this._getForceScheduleDefaultDate(item.activeDate!);
                                                    this.setState({ renderForceSchedule: true });
                                                    if (date) {
                                                        setStateManual({
                                                            forceScheduleDate: periods && periods[0].startDate && moment(new Date(periods[0].startDate)).isSameOrAfter(moment(new Date(date.toISOString()))) ? new Date(periods[0].startDate) : new Date(date.toISOString())
                                                        });
                                                    }
                                                }}
                                            >
                                                {this._isAutoPost(item) ? "Force Schedule" : "Set Scheduled Date"}
                                                <Tooltip style={{ paddingLeft: "5px" }}>
                                                    {this._isAutoPost(item) ? "Set the date you want to schedule this post\nto go out to all users at" : "Set the date when this post will be scheduled\nfor users who choose to share it"}
                                                </Tooltip>
                                            </Button>
                                        </div>
                                }),
                                label: !renderForceSchedule && !item.hasScheduled ? " " : "Default schedule date",
                                description: !renderForceSchedule && !item.hasScheduled ? "" : this._isAutoPost(item) ? "Set the date you want to schedule this post\nto go out to all users at" : "Set the date when this post will be scheduled\nfor users who choose to share it",
                                type: "datetime",
                                timeFormat: true,
                                hidden: !!((client.allowContentSpecificPermissions && item.sharingPermissions &&
                                    typeof item.sharingPermissions === "object" && !item.sharingPermissions.social)) || (item.hasScheduled && !item.forceScheduleDate),
                                disabled: !item.sid || this.state.isSequential,
                                minDate: start && moment(start).isSameOrAfter(moment(new Date())) ?
                                    start.toISOString() :
                                    item.activeDate && moment(new Date(item.activeDate)).isSameOrBefore(moment(new Date()))
                                        ? new Date(item.activeDate).toISOString()
                                        : new Date().toISOString(),
                                ...(item.inActiveDate
                                    ? { maxDate: new Date(item.inActiveDate).toISOString() }
                                    : null),
                                timeConstraints: { minutes: { step: 15 } },
                                validation: (current: moment.Moment) => {
                                    const date = this._getForceScheduleDefaultDate(item.activeDate);
                                    if (!start && !end) {
                                        return (
                                            current.isSameOrAfter(moment(new Date())) &&
                                            current.isSameOrAfter(moment(date.toDateString())) &&
                                            (!item.inActiveDate ||
                                                current.isBefore(moment(new Date(item.inActiveDate).toDateString())))
                                        );
                                    }
                                    return validatePeriods(current, periods) && (
                                        current.isSameOrAfter(moment(new Date())) &&
                                        current.isSameOrAfter(moment(date.toDateString())) &&
                                        (!item.inActiveDate ||
                                            current.isBefore(moment(new Date(item.inActiveDate).toDateString())))
                                    );
                                },
                                renderInput: (props, openCalendar) => {
                                    // props.value will be passed in as an empty string here if the value is null because the library we use is stupid as fuck
                                    // don't call a date constructor on props.value unless you're sure it's valid input
                                    if (!renderForceSchedule || item.hasScheduled) {
                                        return (
                                            props.value ?
                                                <div className="permission__column"
                                                    style={!this._dateHasPassed(item) ? { border: "2px solid #FF0000", width: "135%", position: "absolute", left: "-35%", marginTop: "2.5%", padding: "3%", justifyContent: "center" }
                                                        : { border: "2px solid #FF0000", width: "143%", position: "absolute", marginTop: "0.75%", marginRight: "1.5%", marginLeft: "1.5%", left: "-42%", padding: "3%", justifyContent: "center" }}>
                                                    <span style={{ textAlign: "center", color: "#FF0000" }}>Scheduled / Force Scheduled Date: {moment(props.value).tz(moment.tz.guess()).format("YYYY/MM/DD h:mm a z")}</span>
                                                </div>
                                                : <div></div>
                                        );
                                    } else {
                                        return (
                                            <div>
                                                <Button className="btn__width_90" onClick={openCalendar}>
                                                    {props.value ? moment(props.value).tz(moment.tz.guess()).format("YYYY/MM/DD h:mm a z") : "Select a date"}
                                                </Button>
                                                <Button
                                                    className="btn__width_auto"
                                                    style={{
                                                        marginLeft: "-5px",
                                                        borderLeft: "1px solid white",
                                                        borderBottomLeftRadius: "0",
                                                        borderTopLeftRadius: "0"
                                                    }}
                                                    onClick={() => {
                                                        this.setState({ renderForceSchedule: false });
                                                        setStateManual({ forceScheduleDate: null });
                                                    }}
                                                >
                                                    <span>x</span>
                                                </Button>
                                            </div>
                                        );
                                    }
                                }
                            }
                        };
                    }}
                />
            )
        );
    }
}
const mapStateToProps = state => ({
    user: state.session.admin,
    clients: state.lists.clients,
    streams: state.lists.streams,
} as IEditArticleProps); // need to cast here because the typing handles the streams field weirdly

export default connect(mapStateToProps)(EditArticle);