import { apiRequest, apiRequestWithErrorAlert } from "../utils/Helpers";
import appConfig from "../../config/config.dev";

export async function doGetStreams(
    active: boolean,
    cid: string,
    search?: string,
    sort?: dynamic<number>,
    limit?: number,
    skip?: number
) {
    const data = await apiRequestWithErrorAlert<{ items: ServerTypes.Console.IStream[]; count: number }>(
        `${appConfig.API_URL}/client/stream/list`,
        "POST",
        { cid, search, isCustom: true, active, sort, limit, skip }
    );
    return data || ({ count: 0, items: [] } as { items: ServerTypes.Console.IStream[]; count: number });
}

export async function doGetStreamsOfType(cid: string, searchType: string, code: string, lang?: string) {
    const data = await apiRequestWithErrorAlert<{ items: ServerTypes.Console.IStream[]; count: number }>(
        `${appConfig.API_URL}/client/stream/list`,
        "POST",
        { cid, searchType, isCustom: true, code, ...(lang ? { lang } : null) }
    );
    return data || ({ count: 0, items: [] } as { items: ServerTypes.Console.IStream[]; count: number });
}

export async function doGetAutopostStreams(cid: string | null, limit = 20, skip = 0) {
    const response = await apiRequestWithErrorAlert<{
        items: Array<ServerTypes.Console.IStream & { cid: string, enrolled?: number, enrolledThisPeriod?: number }>;
        count: number
    }>(
        `${appConfig.API_URL}/client/stream/list`,
        "POST",
        {
            cid,
            active: true,
            isApprovalRequired: true,
            allowAutopost: true,
            limit,
            skip
        }
    );
    if (!response) return { items: [], count: 0 };
    return response;
}
export async function doGetStream(cid: string, sid: string) {
    return apiRequest(`${appConfig.API_URL}/client/stream/get`, "POST", { cid, sid });
}

export async function doDeleteStream(cid: string, sid: string) {
    return apiRequest(`${appConfig.API_URL}/client/stream/delete`, "POST", { cid, sid });
}

export async function doUpsertStream(
    cid: string,
    stream: ServerTypes.Console.IStream & { allowSectionView?: boolean },
    sort = false
) {
    return apiRequest(`${appConfig.API_URL}/client/stream/upsert`, "POST", {
        cid,
        ...stream,
        ...(sort ? { sort } : null)
    });
}

export async function doPreviewStream(cid: string, stream: ServerTypes.Console.IStream, since?: Date) {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return apiRequest<{ valid: boolean; articles: ServerTypes.Console.IArticle[] }>(
        `${appConfig.API_URL}/client/stream/preview`,
        "POST",
        { cid, ...stream, count: 1, since: since ? since.toISOString() : date.toISOString(), limit: 50 }
    );
}

export async function doPreviewLibrary(
    cid: string,
    streams: dynamic<ServerTypes.Console.IStream>,
    since?: Date
) {
    return apiRequest<{ valid: boolean; articles: dynamic<ServerTypes.Console.IArticle[]>}>(
        `${appConfig.API_URL}/client/stream/previewAll`,
        "POST",
        { cid, ...streams, since, limit: 6 }
    );
}

export async function doGetAutopostEnrolledUserCount(sids: string[]) {
    return apiRequest<{ _id: string; enrolled: number; enrolledThisPeriod: number }[]>(
        `${appConfig.API_URL}/client/stream/getAutopostEnrolledUserCount`,
        "POST",
        { sids }
    );
}
