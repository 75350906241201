import React, { useState, useEffect, ReactNode, CSSProperties } from "react";
import { isIE11, updateIEWidth } from "../../../utils/BrowserHelpers";
interface IRowProps {
    style?: CSSProperties;
    children: ReactNode;
}
export const Row = (props: IRowProps) => {
    const [ieWidth, setIeWidth] = useState(isIE11() ? updateIEWidth() : "");
    const _updateIEWidth = () => {
        setIeWidth(isIE11() ? updateIEWidth() : "");
    };

    useEffect(() => {
        window.addEventListener("resize", _updateIEWidth);
        return () => {
            window.removeEventListener("resize", _updateIEWidth);
        };
    });

    return (
        <div className="row" style={{ ...props.style, ...(isIE11() ? { width: ieWidth } : "") }}>
            {props.children}
        </div>
    );
};
export default Row;
