import BaseAction from "../utils/BaseAction";

export interface IComplianceActions {
    getComplianceSettings(cid: string): Promise<Mongo.IComplianceSetting | null>;
    doNGAuthorize(cid: string): Promise<void>;
    removeCompliance(cid: string): Promise<void>;
}

export interface IComplianceUserReportFilters { sd?: string; sdtz?: string; ed?: string; edtz?: string; clientCode?: string; grp?: string[]; rid?: string; }
class _ComplianceActions extends BaseAction implements IComplianceActions {
    async getComplianceSettings(cid: string) {
        return this.apiRequest<Mongo.IComplianceSetting>(`/compliance/getComplianceSettings`, "GET", { cid });
    }

    async doNGAuthorize(cid: string) {
        this.apiRequest<string>(`/compliance/ng/auth`, "GET", { cid }).then(url => {
            window.location.href = url as string;
        });
    }

    async removeCompliance(cid: string) {
        this.apiRequest(`/compliance/remove`, "DELETE", { cid });
    }

    async getComplianceVendor(cid: string) {
        return this.apiRequest<string>(`/compliance/getComplianceVendor`, "GET", { cid });
    }

    async doGetStatsReport(filters: IComplianceUserReportFilters) {
        return this.apiRequest(`/reports/user/complianceStats/csv`, "POST", filters).catch(err => "");
    }
}

export const ComplianceActions = new _ComplianceActions();
