import React, { Component } from "react";
import { connect } from "react-redux";

import Spinner from "../../atoms/Spinner";
import { apiRequest, apiFormDataPost } from "../../../utils/Helpers";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import Button from "../../atoms/Button";
import { Wrapper, Row } from "../../atoms/Layout/";

class ReleaseNotes extends Component {
    constructor(props) {
        super(props);
        this._refresh = this._refresh.bind(this);
        this._onUpdateReleaseNote = this._onUpdateReleaseNote.bind(this);
        this._onFilterChange = this._onFilterChange.bind(this);
        this._deleteReleaseNote = this._deleteReleaseNote.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this.state = {
            releaseNotes: [],
            changedNotes: [],
            loading: false
        };
    }

    componentDidMount() {
        this._refresh();
    }

    _refresh() {
        const { user } = this.props;
        this.setState({ loading: true, releaseNotes: [] });
        apiRequest(`${appConfig.API_URL}/releaseNote/list`).then(
            function(data) {
                if (data == null) {
                    data = [];
                }
                this.setState({ loading: false, releaseNotes: data });
            }.bind(this)
        );
    }
    _deleteReleaseNote(releaseId, index) {
        apiRequest(`${appConfig.API_URL}/releaseNote/delete`, `POST`, { releaseId }).then(
            function(data) {
                if (data.valid) {
                    let { releaseNotes } = this.state;
                    delete releaseNotes[index];
                    this.setState({ releaseNotes: releaseNotes });
                    createAlert("Release Note has been deleted", `success`);
                } else {
                    createAlert(data.error, `error`);
                }
            }.bind(this)
        );
    }
    _publishReleaseNote(gid, index) {
        apiRequest(`${appConfig.API_URL}/releaseNote/publish`, `POST`, { releaseId: gid }).then(
            function(data) {
                if (data.valid) {
                    this._refresh();
                    createAlert("Release Note has been published", `success`);
                } else {
                    createAlert(data.error, `error`);
                }
            }.bind(this)
        );
    }

    _onFilterChange(key, val) {
        let { releaseNotes, changedNotes } = this.state;
        if (!releaseNotes[key]) {
            releaseNotes[key] = {};
        }
        if (releaseNotes[key].releaseVersion != val) {
            releaseNotes[key] = {
                releaseVersion: val,
                releaseUrl: ""
            };
            if (changedNotes.indexOf(key) == -1) {
                changedNotes.push(key);
            }
            this.setState({ releaseNotes: releaseNotes, changedNotes: changedNotes });
        }
    }
    _onBlur(key) {
        if (this.state.changedNotes.indexOf(key) > -1) {
            let changedNotes = this.state.changedNotes;
            changedNotes.splice(changedNotes.indexOf(key), 1);
            this.setState(
                {
                    changedNotes: changedNotes
                },
                () => this._onUpdateReleaseNote(key, false)
            );
        }
    }
    _onUpdateReleaseNote(releaseId, insert = false) {
        if (insert && !this.state.releaseNotes[releaseId]) {
            createAlert("You must specify a version", `error`);
            return;
        }
        var formData = new FormData();
        if (!insert) {
            formData.append("releaseId", releaseId);
        }
        var releaseVersion = this.state.releaseNotes[releaseId].releaseVersion;
        if (!releaseVersion) {
            return;
        }
        if (insert) {
            formData.append("file", this.fileUpload.files[0]);
        } else {
            formData.append("releaseUrl", this.state.releaseNotes[releaseId].releaseNotes);
        }
        formData.append("releaseVersion", releaseVersion);
        apiFormDataPost(`${appConfig.API_URL}/releaseNote/insert`, formData)
            .then(
                function(data) {
                    if ("valid" in data) {
                        if (!data.valid) {
                            this.setState({ errors: data.error, loading: false });
                            createAlert("You have errors in your upload, please fix them and re-upload", `error`);
                            return;
                        } else {
                            this.setState({ errors: "", loading: false });

                            this.newInput.value = "";
                            this.newInput.focus();
                            this.fileUpload.value = "";
                            this._refresh();
                            createAlert(`You have added a new release note for v${version}`, `success`);
                            return;
                        }
                    }
                }.bind(this)
            )
            .catch(function(error) {
                let err = ((error || {}).response || {}).data || {};
                throw err.error || "An unexpected error occured while communicating with the server.";
            });
    }
    render() {
        const { releaseNotes, loading } = this.state;
        return (
            <Wrapper>
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5 aria-hidden="true">Release Notes</h5>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped responsive" data-type="responsive">
                                        <thead>
                                            <tr>
                                                <th>Version</th>
                                                <th>File</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading && (
                                                <tr>
                                                    <td>
                                                        <span>
                                                            <input
                                                                ref={input => {
                                                                    this.newInput = input;
                                                                }}
                                                                type="text"
                                                                onChange={event =>
                                                                    this._onFilterChange(
                                                                        "group--new",
                                                                        event.target.value
                                                                    )
                                                                }
                                                                placeholder="Create a new group"
                                                            />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="file"
                                                            ref={input => {
                                                                this.fileUpload = input;
                                                            }}
                                                        />
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <Button
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                            onClick={() =>
                                                                this._onUpdateReleaseNote("group--new", true)
                                                            }
                                                        >
                                                            Create
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                            {!loading &&
                                                releaseNotes &&
                                                Object.keys(releaseNotes).length > 0 &&
                                                Object.keys(releaseNotes).map(
                                                    function(key, i) {
                                                        let releaseNote = releaseNotes[key];
                                                        let style = {};
                                                        if (releaseNote.published) {
                                                            style.backgroundColor = "#c6ffc6";
                                                        }
                                                        if (key !== "group--new") {
                                                            return (
                                                                <tr key={releaseNote.releaseVersion} style={style}>
                                                                    <td>
                                                                        <span>{releaseNote.releaseVersion}</span>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            href={releaseNote.releaseUrl}
                                                                            target="_blank"
                                                                        >
                                                                            {releaseNote.releaseUrl}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        {!releaseNote.published && (
                                                                            <Button
                                                                                style={{
                                                                                    width: "100%"
                                                                                }}
                                                                                onClick={() =>
                                                                                    this._publishReleaseNote(
                                                                                        releaseNote.releaseId,
                                                                                        key
                                                                                    )
                                                                                }
                                                                            >
                                                                                Publish
                                                                            </Button>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            style={{
                                                                                width: "100%"
                                                                            }}
                                                                            onClick={() =>
                                                                                this._deleteReleaseNote(
                                                                                    releaseNote.releaseId,
                                                                                    key
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    }.bind(this)
                                                )}
                                            {loading && (
                                                <tr>
                                                    <td colSpan="42">
                                                        <Spinner />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
}

ReleaseNotes.allowSuper = true;
ReleaseNotes.allowApiAdmin = true;
ReleaseNotes.allowClientAdmin = false;
ReleaseNotes.allowAdmin = false;
ReleaseNotes.allowCurator = false;
ReleaseNotes.allowReports = false;

const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients });

export default connect(mapStateToProps)(ReleaseNotes);
