import OfferList from "./offer/List";
import OfferCampaignEdit from "./offer/Edit";
import NotificationCampaignList from "./notification/List";
import EditNotification from "./notification/Edit";
import emailDripList from "./emailDrip/List";
import emailDripCampaignEdit from "./emailDrip/Edit";
import streamSubscriptionList from "./streamSubscription/List";

export default [
    { path: "/campaign/offer/e/:cid/:ocid/", component: OfferCampaignEdit },
    { exact: true, path: "/campaign/offer/c/", component: OfferCampaignEdit },
    { path: "/campaign/offer/c/:cid", component: OfferCampaignEdit },
    { path: "/campaign/offers/", component: OfferList },

    { exact: true, path: "/campaign/emailDrip", component: emailDripList },
    { path: "/campaign/emailDrip/e/:eid/", component: emailDripCampaignEdit },
    { path: "/campaign/emailDrip/c/", component: emailDripCampaignEdit },


    { exact: true, path: "/campaign/streamSubscription", component: streamSubscriptionList },

    // out of campaign routes
    { exact: true, path: "/notifications", component: NotificationCampaignList },
    { path: "/notification/e/:nid/", component: EditNotification },
    { path: "/notifications/c/", component: EditNotification },
];
