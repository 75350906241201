import React, { Component } from "react";

import Autosuggest from "react-autosuggest";
import Button from "../atoms/Button";

function escapeSuggestion(value) {
    return value.trim();
}

function getSuggestionValue(suggestion) {
    return suggestion;
}

function renderSuggestion(suggestion) {
    return <span>{suggestion}</span>;
}

export default class AutoSuggest extends Component<
    {
        onChange?: Function;
        onBlur?: Function;
        disabled?: boolean;
        requestSuggestions?: Function;
        onSuggestionSelected?: Function;
        id: string;
        placeholder?: string;
        buttonLabel?: string;
        value?: string;
    },
    { value: string; suggestions: string[]; selected: number; error: string; input: any }
> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
            suggestions: [],
            selected: -1,
            error: "",
            input: null
        };
        this._onChange = this._onChange.bind(this);
        this._onSuggestionsFetchRequested = this._onSuggestionsFetchRequested.bind(this);
        this._onSuggestionsClearRequested = this._onSuggestionsClearRequested.bind(this);
        this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
        this._onSuggestionBlur = this._onSuggestionBlur.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (this.props.value !== newProps.value) {
            this.setState({ value: newProps.value });
        }
    }
    _storeInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.setState({ input: autosuggest.input });
        }
    };

    _onChange(_, { newValue }) {
        const { id, onChange } = this.props;
        if(newValue.length <51){
        this.setState({ value: newValue });
        }
        if (onChange) {
            onChange(id, newValue);
        }
    }

    async _onSuggestionsFetchRequested({ value }) {
        const { suggestions } = this.state;
        const { requestSuggestions } = this.props;
        if (suggestions.indexOf(value) > -1) {
            return;
        }
        if (requestSuggestions) {
            const data = await requestSuggestions(escapeSuggestion(value));
            this.setState({ suggestions: data });
        }
    }

    _onSuggestionSelected(_, { suggestionValue, suggestionIndex }) {
        const { id, onSuggestionSelected } = this.props;
        if (onSuggestionSelected) onSuggestionSelected(id, suggestionValue);
        this.setState({ selected: suggestionIndex, suggestions: [] });
    }

    _onSuggestionBlur(event) {
        const { id, onSuggestionSelected } = this.props;

        const value = (event.target.value || "").trim();
        if (value.length == 0) {
            return;
        }
        if (onSuggestionSelected) onSuggestionSelected(id, value);
        this.setState({ suggestions: [] });
    }

    _onSuggestionsClearRequested() {
        return;
    }

    _shouldRenderSuggestions(value) {
        return value.trim().length >= 3;
    }

    render() {
        const { id, placeholder } = this.props;
        const { value, suggestions, error } = this.state;
        const inputProps = {
            placeholder,
            value,
            onChange: this._onChange,
            onBlur: this.props.onBlur || this._onSuggestionBlur,
            ...("disabled" in this.props ? { disabled: this.props.disabled } : null)
        };
        if (error) {
            return <input type="text" className="error" disabled={true} value={error} />;
        }
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Autosuggest
                    style={{ flexBasis: "80%" }}
                    id={id}
                    ref={this._storeInputReference}
                    suggestions={suggestions}
                    shouldRenderSuggestions={this._shouldRenderSuggestions}
                    onSuggestionSelected={this._onSuggestionSelected}
                    onSuggestionsFetchRequested={this._onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this._onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />

                {this.props.buttonLabel && <Button style={{ flexBasis: "20%" }}>{this.props.buttonLabel}</Button>}
            </div>
        );
    }
}
