import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";

export default class SessionExpiryWarning extends Component<
    {
        title: string;
        closeAction: () => void;
    },
    { title: string; open: boolean }
> {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title || "",
            open: true,
        };
    }

    render() {
        const { title, open } = this.state;
        return (
            <Modal title={title} open={open} closeAction={this.props.closeAction}>
                <div style={{fontSize:"16px", paddingBottom:16}}>It looks like you've been inactive for a while. Click the button below or close this pop-up if you are still here.</div>
                <Button style={{width:"100%"}} onClick={() => {
                    this.setState({open: false});
                    this.props.closeAction();
                }}>I'm here!</Button>
            </Modal>
        );
    }
}