import {
    ADMINS_LIST,
    ADMINS_REQUEST,
    COMPANY_LIST,
    COMPANY_REQUEST,
    CLIENTS_LIST,
    CLIENT_UPDATE,
    CLIENTS_REQUEST,
    SEARCHES_REQUEST,
    SEARCHES_LIST,
    SEARCH_ARTICLE_LIST,
    SEARCH_ARTICLE_REQUEST,
    SEARCH_ARTICLE_LIST_MORE,
    SEARCH_ARTICLE_MORE_REQUEST,
    SEARCH_ARTICLE_FINAL_PAGE,
    ARTICLE_LIST,
    ARTICLE_REQUEST,
    COMPLIANT_ARTICLE_LIST,
    COMPLIANT_ARTICLE_REQUEST,
    CLEAR,
    INDUSTRY_LIST,
    INDUSTRY_REQUEST,
    OFFER_CAMPAIGN_LIST,
    OFFER_CAMPAIGN_REQUEST,
    NOTIFICATION_LIST,
    NOTIFICATION_REQUEST
} from "../actions/lists";
import { reduce } from "../utils/Helpers";

interface IListReducerState {
    admins: dynamic[];
    adminsLoading: boolean;
    companies: {
        [companyId: string]: Mongo.company;
    };
    companyLoading: boolean;
    industries: {
        [iid: string]: Mongo.clientIndustries;
    };
    industryLoading: boolean;
    offerCampaigns: {
        [ocid: string]: Mongo.IOfferCampaign;
    };
    offerCampaignsLoading: boolean;
    notifications: {
        [nid: string]: Mongo.INotification;
    };
    notificationsLoading: boolean;
    clients: {
        [cid: string]: Mongo.client;
    };
    clientsLoading: boolean;
    streams: Mongo.IStream[];
    streamsLoading: boolean;
    articles: Mongo.ICachedArticle[];
    articlesLoading: boolean;
    complianceArticles: Mongo.ICachedArticle[];
    complianceArticlesLoading: boolean;
    finalArticlesPage: boolean;
}
const initialState: IListReducerState = {
    admins: [],
    adminsLoading: true,
    companies: {},
    companyLoading: true,
    industries: {},
    industryLoading: true,
    offerCampaigns: {},
    offerCampaignsLoading: true,
    notifications: {},
    notificationsLoading: true,
    clients: {},
    clientsLoading: true,
    streams: [],
    streamsLoading: true,
    articles: [],
    articlesLoading: true,
    complianceArticles: [],
    complianceArticlesLoading: true,
    finalArticlesPage: false
};

const handlers = {
    [ADMINS_LIST]: (_: IListReducerState, action: dynamic) => ({
        admins: action.admins,
        adminsLoading: false
    }),
    [ADMINS_REQUEST]: (_: IListReducerState, action: dynamic) => ({ adminsLoading: true, admins: [] }),
    [COMPANY_LIST]: (_: IListReducerState, action: dynamic) => ({
        companies: action.companies,
        companyLoading: false
    }),
    [COMPANY_REQUEST]: (_: IListReducerState, action: dynamic) => ({ companies: {}, companyLoading: true }),
    [INDUSTRY_LIST]: (_: IListReducerState, action: dynamic) => ({
        industries: action.industries,
        industryLoading: false
    }),
    [INDUSTRY_REQUEST]: (_: IListReducerState, action: dynamic) => ({ industries: {}, industryLoading: true }),
    [OFFER_CAMPAIGN_LIST]: (_: IListReducerState, action: dynamic) => ({
        offerCampaigns: action.offerCampaigns,
        offerCampaignsLoading: false
    }),
    [OFFER_CAMPAIGN_REQUEST]: (_: IListReducerState, action: dynamic) => ({
        offerCampaigns: {},
        offerCampaignsLoading: true
    }),
    [NOTIFICATION_LIST]: (_: IListReducerState, action: dynamic) => ({
        notifications: action.notifications,
        notificationsLoading: false
    }),
    [NOTIFICATION_REQUEST]: (_: IListReducerState, action: dynamic) => ({
        notifications: {},
        notificationsLoading: true
    }),
    [CLIENTS_REQUEST]: (_: IListReducerState, action: dynamic) => ({ clientsLoading: true, clients: {} }),
    [CLIENT_UPDATE]: (_: IListReducerState, action: dynamic) => ({
        clients: { ..._.clients, [action.client.cid]: action.client },
        clientsLoading: false
    }),
    [CLIENTS_LIST]: (_: IListReducerState, action: dynamic) => ({
        clients: action.clients,
        clientsLoading: false
    }),
    [SEARCHES_REQUEST]: (_: IListReducerState, action: dynamic) => ({ streams: [], streamsLoading: true }),
    [SEARCHES_LIST]: (_: IListReducerState, action: dynamic) => ({
        streams: action.streams,
        streamsLoading: false
    }),
    [SEARCH_ARTICLE_LIST]: (_: IListReducerState, action: dynamic) => ({
        articles: action.articles,
        articlesLoading: false
    }),
    [SEARCH_ARTICLE_REQUEST]: (_: IListReducerState, action: dynamic) => ({
        articles: [],
        articlesLoading: true,
        finalArticlesPage: false
    }),
    [SEARCH_ARTICLE_MORE_REQUEST]: (_: IListReducerState, action: dynamic) => ({ articlesLoading: true }),
    [SEARCH_ARTICLE_LIST_MORE]: (_: IListReducerState, action: dynamic) => ({
        articles: _.articles ? _.articles.concat(action.articles) : action.articles,
        articlesLoading: false
    }),
    [SEARCH_ARTICLE_FINAL_PAGE]: (_: IListReducerState, action: dynamic) => ({ finalArticlesPage: true }),
    [ARTICLE_LIST]: (_: IListReducerState, action: dynamic) => ({
        articles: action.articles,
        articlesLoading: false
    }),
    [ARTICLE_REQUEST]: (_: IListReducerState, action: dynamic) => ({ articles: [], articlesLoading: true }),
    [COMPLIANT_ARTICLE_LIST]: (_: IListReducerState, action: dynamic) => ({
        complianceArticles: action.articles,
        complianceArticlesLoading: false
    }),
    [COMPLIANT_ARTICLE_REQUEST]: (_: IListReducerState, action: dynamic) => ({
        complianceArticles: [],
        complianceArticlesLoading: true
    }),
    [CLEAR]: (_: IListReducerState, action: dynamic) => initialState
};

export default (state = initialState, action) => reduce<IListReducerState>(handlers, state, action);
