import React, { ChangeEvent } from "react";
import { getIndustries } from "../../utils/Helpers";
interface IIndustriesProps {
    id: string;
    value: string;
    change: (event: ChangeEvent<HTMLSelectElement>) => void;
}
export default (props: IIndustriesProps) => {
    const { id, value, change } = props;

    return (
        <select id={id} value={value} onChange={change}>
            <option value="">All</option>
            {getIndustries().map(({ value, name }) => (
                <option key={value} value={value}>
                    {name}
                </option>
            ))}
        </select>
    );
};
