import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import Button from "../atoms/Button";

export class AlertModal extends Component<
    {
        title: string;
        onClose?: () => void;
        button?: string;
        open?: boolean;
    },
    { open: boolean }
> {
    constructor(props) {
        super(props);
        this.state = { open: props.open || true };
    }
    componentWillReceiveProps(newProps) {
        if (newProps.open !== this.state.open) {
            this.setState({ open: newProps.open });
        }
    }
    _closeModal() {
        this.setState({ open: false }, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        });
    }
    render() {
        const { title, button } = this.props;
        return (
            <Modal show={this.state.open} onHide={this._closeModal} aria-labelledby="ModalHeader">
                <Modal.Header closeButton={true}>
                    <Modal.Title id="ModalHeader">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.children}</Modal.Body>
                {button && (
                    <Modal.Footer>
                        <Button onClick={() => this._closeModal()}>{button}</Button>
                    </Modal.Footer>
                )}
            </Modal>
        );
    }
}

export class ConfirmModal extends Component<
    {
        title: string;
        onConfirm?: () => void;
        onCancel?: () => void;
        force: boolean;
        button?: string;
        open?: boolean;
        cancelButton?: string;
    },
    { open: boolean; force: boolean }
> {
    constructor(props) {
        super(props);
        this.state = { open: props.open || true, force: props.force };
    }
    componentWillReceiveProps(newProps) {
        if (newProps.open !== this.state.open) {
            this.setState({ open: newProps.open });
        }
    }
    _closeModal(confirm: boolean) {
        if ((this.state.force && confirm) || !this.state.force) {
            this.setState({ open: false }, () => {
                if (confirm && this.props.onConfirm) {
                    this.props.onConfirm();
                } else if (!confirm && this.props.onCancel) {
                    this.props.onCancel();
                }
            });
        }
    }
    render() {
        const { title, button, cancelButton, onCancel } = this.props;
        return (
            <Modal show={this.state.open} onHide={() => this._closeModal(false)} aria-labelledby="ModalHeader">
                <Modal.Header closeButton={true}>
                    <Modal.Title id="ModalHeader">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.children}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this._closeModal(true)}>{button || "Confirm"}</Button>
                    {cancelButton && (
                        <Button onClick={() => this.setState({ open: false }, () => onCancel && onCancel())}>
                            {cancelButton || "No"}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}
