import React, { Component } from "react";
import Spinner from "../../../atoms/Spinner";

import { apiRequest, getLocaleDate, getAdminDate, downloadFile as fileDownload } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";

import { createAlert } from "../../../../actions/utils";
import DownloadButton from "../../../atoms/DownloadButton";
import Pagination from "../../../molecules/Pagination";
import { Wrapper, Row } from "../../../atoms/Layout";
import { IReportFilter } from "../Index";

interface IEmailsViewProps {
    filters: IReportFilter;
    client?: Mongo.client
    onLoaded: () => void;
    showSearchResults: boolean;
}

interface IEmailsViewState {
    sentEmails: "loading" | Array<{
        name: string;
        address: string;
        type: string;
        date: Date;
        groups: string;
        client?: string;
        category: string;
        isAdmin?: boolean;
        requestedName?: string;
    }>;
    sentEmailsSort?: string;
    sentEmailsDirection: number;
    sentEmailsLimit: number;
    sentEmailsPage: number;
    sentEmailsTotal: number;
}

class EmailsView extends Component<IEmailsViewProps, IEmailsViewState> {
    constructor(props) {
        super(props);
        this._request = this._request.bind(this);
        this._fetchAll = this._fetchAll.bind(this);

        this._renderEmails = this._renderEmails.bind(this);

        this.state = {
            sentEmails: "loading",
            sentEmailsDirection: -1,
            sentEmailsLimit: 20,
            sentEmailsPage: 0,
            sentEmailsTotal: 0
        };
    }

    componentDidMount() {
        if (!this.props.showSearchResults) return;
        this._fetchAll(this.props);
    }

    componentWillReceiveProps(newProps: IEmailsViewProps) {
        if ((newProps.filters != this.props.filters) || (!this.props.showSearchResults && newProps.showSearchResults)) {
            this.setState({ sentEmailsPage: 0 }, () => this._fetchAll(newProps));
        }
    }

    _fetchAll(props: IEmailsViewProps) {
        this._request("sentEmails").finally(props.onLoaded);
    }

    _request(type: string) {
        return new Promise<void>(resolve => {
            this.setState({ [type]: "loading" } as unknown as IEmailsViewState, () =>
                this._fetchData(type).then(data => {
                    this.setState({ [type]: data.items, [type + "Total"]: data.count } as unknown as IEmailsViewState);
                    resolve();
                }).finally(resolve)
            );
        });
    }

    _refresh(newPage = 0) {
        this.setState({ sentEmailsPage: newPage }, () => {
            this._fetchAll(this.props);
        });
    }

    _fetchData(type: string, format = "json") {
        const sorting = {
            ...this.props.filters,
            sort: this.state[type + "Sort"],
            sortDirection: this.state[type + "Direction"],
            limit: this.state[type + "Limit"],
            skip: this.state[type + "Limit"] * this.state[type + "Page"]
        };
        return apiRequest(`${appConfig.API_URL}/reports/emails/${type}/${format}`, "Post", sorting)
            .then(data => {
                if (typeof data === "object" && (data.error || data.valid === false)) {
                    throw data.error;
                }
                if (format == "csv") {
                    fileDownload(data, `${type}-${getLocaleDate(new Date())}.csv`);
                }
                return data;
            })
            .catch(error => {
                createAlert(error, `error`);
                return [];
            });
    }

    _sort(type: string, sortColumn: string, sortDirection: number) {
        this.setState({ [`${type}Page`]: 0, [`${type}Sort`]: sortColumn, [`${type}Direction`]: sortDirection } as unknown as IEmailsViewState, () => {
            this._request(type);
        });
    }

    _renderSortIcons(type: string, column: string, defaultDirection = 1) {
        const sort = this.state[type + "Sort"];
        const sortDirection = this.state[type + "Direction"];
        if (sort == column) {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={e => this._sort(type, column, sortDirection == 1 ? -1 : 1)}
                    onKeyDown={e => {
                        if (e.which === 13 || e.which === 32) {
                            this._sort(type, column, sortDirection == 1 ? -1 : 1)
                        }
                    }}
                >
                    {column}
                    <i
                        className={`fa ${sortDirection == 1 ? "fa-sort-up" : "fa-sort-down"}`}
                        tabIndex={0}
                        role="img"
                        aria-label={`Sort ${sortDirection == 1 ? "ascending" : "descending"}`}
                    />
                </span>
            );
        }
        return (
            <span
                style={{ cursor: "pointer" }}
                onClick={e => this._sort(type, column, defaultDirection)}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        this._sort(type, column, defaultDirection)
                    }
                }}
            >
                {column}
                <i
                    className="fa fa-sort"
                    tabIndex={0}
                    role="img"
                    aria-label="Sort ascending or descending"
                />
            </span>
        );
    }
    _renderLoadingCard(content: string | JSX.Element, loadingClass: string) {
        return (
            <div className={loadingClass}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            Sent Communications
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderEmails() {
        const { sentEmails, sentEmailsLimit, sentEmailsPage, sentEmailsTotal } = this.state;
        if (!this.props.showSearchResults) {
            return this._renderLoadingCard(<i>To run this report, please adjust the filters above and click "Search"</i>, "col-lg-12");
        } else if (sentEmails === "loading") {
            return this._renderLoadingCard(<Spinner />, "col-lg-12");
        }
        return (
            <div className="col-lg-12">
                <div className="ibox float-e-margins">
                    <div
                        className="ibox-title"
                        style={{
                            paddingTop: "7px",
                            display: "flex"
                        }}
                    >
                        <h5 style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center"
                        }}>
                            Sent Communications
                        </h5>
                        <DownloadButton
                            style={{
                                marginLeft: "auto",
                                marginBottom: "0",
                                width: "auto"
                            }}
                            className="btn--sm"
                            onClick={e => this._fetchData("sentEmails", "csv")}
                        >
                            <i className="fa fa-download" /> Download
                        </DownloadButton>
                    </div>
                    <div className="ibox-content">
                        <div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Pagination
                                    page={sentEmailsPage}
                                    totalItems={sentEmailsTotal}
                                    numPerPage={sentEmailsLimit}
                                    changePage={newPage => this._refresh(newPage)}
                                />
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped responsive" data-type="responsive">
                                    <thead>
                                        <tr>
                                            <th tabIndex={0}>{this._renderSortIcons("sentEmails", "Name", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("sentEmails", "Email", -1)}</th>
                                            {this.props.filters.clientCode &&
                                                this.props.filters.clientCode == "ALL_CLIENTS" && <th tabIndex={0}>Client</th>}
                                            <th tabIndex={0}>Group</th>
                                            <th tabIndex={0}>{this._renderSortIcons("sentEmails", "Category", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("sentEmails", "Type", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("sentEmails", "Date Sent", -1)}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sentEmails &&
                                            sentEmails.length > 0 &&
                                            sentEmails.map(email => {
                                                return (
                                                    <tr
                                                        key={`${email.address}${email.type}${new Date(
                                                            email.date
                                                        ).getTime()}`}
                                                    >
                                                        <td tabIndex={0}>{email.name || email.requestedName || "N/A"}</td>
                                                        <td tabIndex={0}>{email.address || "N/A"}</td>
                                                        {this.props.filters.clientCode &&
                                                            this.props.filters.clientCode == "ALL_CLIENTS" && (
                                                                <td tabIndex={0}>{email.client || "N/A"}</td>
                                                            )}
                                                        <td tabIndex={0}>{email.groups || "N/A"}</td>
                                                        <td tabIndex={0}>{email.category || "N/A"}</td>
                                                        <td tabIndex={0}>{email.type || "N/A"}</td>
                                                        <td tabIndex={0}>{getAdminDate(email.date) || "N/A"}</td>
                                                    </tr>
                                                );
                                            })}
                                        {!sentEmails?.length &&
                                            <tr>
                                                <td tabIndex={0} colSpan={42}>Could not find any reporting data</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Pagination
                                    currItems={sentEmails ? sentEmails.length : 0}
                                    page={sentEmailsPage}
                                    totalItems={sentEmailsTotal}
                                    numPerPage={sentEmailsLimit}
                                    changePage={newPage => this._refresh(newPage)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Wrapper id="notifications">
                <Row>{this._renderEmails()}</Row>
            </Wrapper>
        );
    }
}

export default EmailsView;
