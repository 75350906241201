import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import AddECommunicationMessage from "./AddECommunicationMessage";
import BeeEditorConfirmModal from "./BeeEditorConfirmModal";

interface ModalProps {
    parent: AddECommunicationMessage;
    cid: string;
    ecommid: string;
    open: boolean;
    update: () => void;
    close: () => void;
}
interface ModalState {
    open: boolean;
}
export default class BeeEditorTakeoverModal extends Component<ModalProps, ModalState>
{
    constructor(props) {
        super(props);
        this._closeModal = this._closeModal.bind(this);
        this._closeAction = this._closeAction.bind(this);
        this.state = {
            open: props.open || false,
        };
    }

    componentWillReceiveProps(newProps) {
        if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open });
        }
    }

    _closeModal() {
        if (this.props.parent.state.bodyContentChanged){
            this.props.parent.setState({
                modals: { ...this.props.parent.state.modals, contentEditorRowNameConfirm: true }
            });
            this.props.parent.promiseConfirm = new Promise<boolean>((resolve, reject) => {
                this.props.parent.resolveConfirm = resolve;
            });
            this.props.parent.promiseConfirm.then((update) => {
                if (update){
                    this._closeAction();
                }
                return false;
            });
            return false;
        } else {
            this._closeAction();
        }
        return true;
    }

    _closeAction() {
        this.setState({ open: false });
        if (this.props.close) {
            this.props.close();
        }
    }

    render() {
        return (
            <Modal
                className="modal-modal-background modal-bee-editor"
                backdrop='static'
                show={this.state.open}
                onHide={this._closeModal}>
                <Modal.Header closeButton={true}>
                    <Modal.Title id="ModalHeader">Email Content Editor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="bee-plugin-container" style={{ height:750 }}></div>
                    <BeeEditorConfirmModal
                        className="modal-modal-background"
                        parent={this.props.parent}
                        message="Changes detected. Closing the editor will lose all changes made."
                        prompt="Do you really want to close the editor?"
                        save="Yes"
                        cancel="Cancel"
                        open={this.props.parent.state.modals.contentEditorRowNameConfirm}
                        close={() => { this.props.parent.setState({ modals: { ...this.props.parent.state.modals, contentEditorRowNameConfirm: false } }); }}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}
