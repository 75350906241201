import React from "react";
import ArticleEdit from "./Edit";
import CreateOptions from "./createOptions";

export default [
    { path: "/content/articles/c/:cid/options", component: CreateOptions },
    { path: "/content/articles/c/:cid", component: ArticleEdit },
    { path: "/content/articles/e/:cid/:artid", component: ArticleEdit },
    { path: "/content/media/c/:cid", component: ArticleEdit, render: props => <ArticleEdit {...props} isMedia={true} />},
    { path: "/content/media/e/:cid/:artid", component: ArticleEdit, render: props => <ArticleEdit {...props} isMedia={true} /> },
];
