import React from "react";

import ReportsIndex from "./Index";
export default [
    {
        type: "route",
        exact: true,
        path: "/reports",
        component: ReportsIndex,
        render: props => <ReportsIndex {...props} />
    },
    { type: "route", path: "/reports/:section", component: ReportsIndex }
];
