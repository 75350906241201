import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";

import Navigation from "../components/molecules/Navigation";
import Footer from "../components/molecules/Footer";
import TopHeader from "../components/molecules/TopHeader";
import { correctHeight, detectBody } from "../utils/Helpers";
import Router from "../router/index";

import Spinner from "../components/atoms/Spinner";
import RelNotes from "../components/molecules/ReleaseNotes";
import NotificationBar from "../components/atoms/NotificationBar";
import { LastLocationProvider } from 'react-router-last-location';
import StreamSubExpiry from "../components/molecules/modals/StreamSubExpiry";
import { RouteComponentProps } from "react-router-dom";

import { doLogout, doCheckAuth, doClearCookie } from "../actions/session";
import SessionExpiryWarning from "../components/molecules/modals/SessionExpiryWarning";

interface IMainContainerProps {
    admin: Mongo.clientAdmin & { releaseNotes?: { releaseVersion: string; releaseUrl: string }; expiringStreams?: Mongo.IStream[]; expiredFeeds?: string[] };
    location: RouteComponentProps["location"];
}

// this function was obtained from W3Schools (gets the value of a cookie based on name)
const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

const MainContainer = (props: IMainContainerProps) => {
    const [admin, setAdmin] = useState(props.admin);
    const [location, setLocation] = useState(props.location);
    const [time, setTime] = useState(0);
    const [expiry, setExpiry] = useState("");
    const [prevUpdatedTime, setPrevUpdatedTime] = useState("");
    const [lastUpdated, setLastUpdated] = useState(getCookie("sessionAdminLastUpdated"));

    // checks every second to see if a server call took place which generated a new refresh token
    useEffect(() => {
        const resetTimeInterval = setInterval(() => {
            const expiryCookie = getCookie("sessionAdminExpiry");
            if (expiryCookie) {
                setExpiry(expiryCookie);
                doClearCookie("sessionAdminExpiry");
            }
            const cookie = getCookie("sessionAdminLastUpdated");
            if (cookie) {
                setLastUpdated(cookie);
                doClearCookie("sessionAdminLastUpdated");
                if (lastUpdated !== prevUpdatedTime) {
                    setTime(0);
                }
                setPrevUpdatedTime(lastUpdated);
            }
        }, 1000);

        return () => clearInterval(resetTimeInterval);
    }, [prevUpdatedTime, lastUpdated]);

    // updates timer every 60 seconds and checks if more time has elapsed than the expiry of the refresh token
    // signs user out and checks if the user is an SSO user if time has expired
    useEffect(() => {
        const addTimeInterval = setInterval(() => {
            setTime(time + 60000);
        }, 60000);

        const verifyTime = async () => {
            if (expiry && (time >= Number(expiry))) {
                localStorage.setItem("logoutMessage", "You have been signed out due to inactivity");
                await doLogout();
            }
        }

        verifyTime();
        return () => clearInterval(addTimeInterval);

    }, [time]);

    useEffect(() => {
        // Run correctHeight function on load and resize window event
        $(window).bind("load resize", () => {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        $(".metismenu a").click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300);
        });
    }, []);
    useEffect(() => {
        setAdmin(props.admin);
    }, [props.admin]);

    useEffect(() => {
        setLocation(props.location);
    }, [props.location]);

    if (!admin) {
        return (
            <div className="gray-bg loader">
                <Spinner />
            </div>
        );
    }

    return (
        <React.Fragment>
            {/* If the refresh token is expiring in the next 5 mins, a modal pops up to verify if the user is still there */}
            {expiry && (Number(expiry) - time <= 300000) && (
                <SessionExpiryWarning title="Session Expiry Warning" closeAction={() => {
                    doCheckAuth();
                    setTime(0);
                }} />
            )}
            <NotificationBar cid={props.admin ? props.admin.cid || "" : ""} />
            <div className="page-container">
                <LastLocationProvider><Navigation location={location} /></LastLocationProvider>
                {admin.releaseNotes && (
                    <RelNotes
                        admin={admin}
                        version={admin.releaseNotes.releaseVersion}
                        url={admin.releaseNotes.releaseUrl}
                    />
                )}
                <StreamSubExpiry
                    admin={admin}
                />
                <div id="page-wrapper" className={`page gray-bg ${(location.pathname || "").replace(/\//, " ")}`}>
                    <TopHeader />
                    <Router />
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    );
};

/**
 * Let's setup a wrapper so that we can set a private static variable to know what the component does
 */
class MainContainerWrapper extends Component<IMainContainerProps> {
    static isPrivate = true;
    render() {
        const { admin, location } = this.props;
        return <MainContainer admin={admin} location={location} />;
    }
}

const mapStateToProps = state => ({ admin: state.session.admin });

export default connect(mapStateToProps)(MainContainerWrapper);
