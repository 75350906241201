import BaseStream from "./BaseStream";
import { _ValueContainer, _hasMentionOrHashtag, _formatHashtagOrMention } from "../../../pages/articles/sharedArticle";

export default class CorporateStream extends BaseStream {
    getInitialState() {
        const props = super.getInitialState();
        return {
            ...props,
            ...(props.stream
                ? {
                      stream: {
                          ...props.stream,
                          searchType: "CORP" as "CORP",
                          code: "CORP",
                          contentSearch: false,
                          globalFilters: false
                      }
                  }
                : null),
            canPreview: true,
            canPreviewOnCreate: true
        };
    }

    getSettings() {
        const settings = super.getSettings();
        const { stream } = this.state;
        return {
            ...settings,
            active: {
                label: "Active",
                value: !!stream.active,
                description: "Enables the stream to be shown in the app",
                onChange: () =>
                    this.setState({ stream: { ...stream, active: !stream.active } })
            }
        };
    }
}