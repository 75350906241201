import React from "react";
import Modal from "react-bootstrap-modal";
import { connect } from "react-redux";

import { apiRequest, apiFormDataPost, sortObject, getMultiSelectValues } from "../../../utils/Helpers";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import Spinner from "../../atoms/Spinner";
import EditableInput from "../../atoms/EditableInput";
import DatePickerV2 from "../../atoms/DatePickerV2";
import Tooltip from "../../atoms/Tooltip";
import CopyLink from "../../atoms/CopyLink";
import Link from "../../atoms/Link";
import Button from "../../atoms/Button";
import { ConfirmModal } from "../../atoms/Modal";
import { preventDefault } from "../../../utils/Helpers";
import BeeEditorTakeoverModal from "./BeeEditorTakeoverModal";
import BeeEditorMetadataContentModal from "./BeeEditorMetadataContentModal";
import { doGetECommunicationSubscribables, doGetECommunicationCategories, doGetECommunicationSubscriptionMessages } from "../../../actions";
import { MESSAGE_STATUS , MESSAGE_FREQUENCY , MESSAGE_TYPE, EDITABLE_TEMPLATE_TYPE } from "../../../utils/ECommunicationHelpers";

import BeePlugin from "@mailupinc/bee-plugin";
import { doGetBeeEditorToken , doGetBeeEditorCustomRows , doSetBeeEditorCustomRow , EmailActions, UtilActions } from "../../../actions";
import SelectionOptionList from "../SelectionOptionList";


class AddECommunicationMessage extends React.Component {
    constructor(props) {
        super(props);
        this._onFieldChange = this._onFieldChange.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._submit = this._submit.bind(this);
        this._renderDetailsPanel = this._renderDetailsPanel.bind(this);
        this._deleteMessage = this._deleteMessage.bind(this);
        this._getRenderedImageUpload = this._getRenderedImageUpload.bind(this);
        this._openFileUpload = this._openFileUpload.bind(this);
        this._fileUpload = this._fileUpload.bind(this);
        this._getNearestDateTimeByQuarter = this._getNearestDateTimeByQuarter.bind(this);
        this._renderContentTags = this._renderContentTags.bind(this);

        this._onLaunchEditor = this._onLaunchEditor.bind(this);
        this._onLoadBeedEditor = this._onLoadBeedEditor.bind(this);
        this._deferredPromise = this._deferredPromise.bind(this);

        let inactiveDate = "", lang = "", multiLangSupport = false;
        // pad up 10 minutes
        const padUp = 10;
        if (props.client) {
            inactiveDate = this._getNearestDateTimeByQuarter(new Date(), padUp);
            if (props.client.expiryDefault){
                inactiveDate.setDate(inactiveDate.getDate() + parseInt(props.client.expiryDefault));
            } else {
                inactiveDate.setDate(inactiveDate.getDate() + 7);
            }
            inactiveDate = inactiveDate.toISOString();
            lang = props.client.lang;
            if(props.client.eCommunication?.langSupport){
                multiLangSupport = props.client.eCommunication?.langSupport.length === 2;
            };
        }
        this.state = {
            open: true,
            panel: 0,
            url_loader: "",
            submit_loading: false,
            save_protect: false,
            modal_title: "Add Message",
            modal_button: "Create Message",
            action: "CREATE",
            Title: "",
            Description: "",
            Image: "",
            Subject: "",
            Content: "",
            Preheader: "",
            emailPreheaderTextLen: 60,
            beeEditorContentJson:"",
            CreatedOn: new Date().toISOString(),
            scheduledDelivery: this._getNearestDateTimeByQuarter(new Date(), padUp).toISOString(),
            scheduledDelivered: false,
            activeDate: this._getNearestDateTimeByQuarter(new Date(), padUp).toISOString(),
            inActiveDate: inactiveDate,
            cid: props.cid,
            ecommid: "",
            complianceTrackingCode: "",
            complianceExpiryDate: null,
            tags: [""],
            subscribableid: "",
            categoryid: [""],
            status: "draft",
            frequency: "onetime",
            type: "standard",
            editableTemplateType: "fixed",
            lang: lang,
            multiLangSupport,
            customRowNames: [],
            bodyContentChanged: false,
            modals: {
                emailPreview: false,
                contentEditor: false,
                contentEditorRowName: false,
                contentEditorRowNameConfirm : false
            },
            whiteLabelURL: "",
            sURLDomain: "",
            emailAddress: "",
            trackingProductType: "",
            trackingProductName: "",
            advisorEditingFields : {
                editSalutationSelection: ['','',''],
                editHeadlineSelection: ['','',''],
                editSubheadlineSelection: ['','',''],
                editBodyParagraph1Selection: ['','',''],
                editBodyParagraph2Selection: ['','',''],
                editBodyParagraph2Selection: ['','',''],
                editClosingCommentSelection: ['','','']
            },

            hour: 12,
            minute: "00",
            mod: "am",
            today : new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate()),

            FBMAXLEN: 2000,
            IGMAXLEN: 2000,
            LIMAXLEN: 3000,
            TWMAXLEN: 256 // subtract the 24 chars for the link off the top
        };
        doGetECommunicationSubscribables(props.user.cid, null, null, 0, 1000, null, this.state.lang).then(data => {
            let state = {};
            if (data) {
                let subscribables = data.items;
                if (subscribables){
                    state.subscribables = subscribables;
                }
                this.setState(state);
            }
        })
        doGetECommunicationCategories(props.user.cid, null, 0, 1000, null,this.state.lang).then(data => {
            let state = {};
            if (data) {
                let categories = data.items;
                if (categories){
                    state.categories = categories;
                }
                this.setState(state);
            }
        })

        if (this.props.message) {
            this.state.panel = 1;
            this.state.action = "UPDATE";
            this.state.modal_title = "Edit Message";
            this.state.modal_button = "Update Message";
            this.state = Object.assign(this.state, this.props.message);
            let advisorEditingFields = {
                editSalutationSelection: ['','',''],
                editHeadlineSelection: ['','',''],
                editSubheadlineSelection: ['','',''],
                editBodyParagraph1Selection: ['','',''],
                editBodyParagraph2Selection: ['','',''],
                editBodyParagraph3Selection: ['','',''],
                editClosingCommentSelection: ['','','']
            };
            if (this.props.message.advisorEditingFields){
                advisorEditingFields = {...advisorEditingFields, ...JSON.parse(this.props.message.advisorEditingFields)};
            }
            this.state.advisorEditingFields = advisorEditingFields;
            // round the time to the nearest quarter
            if (this.state.scheduledDelivery){
                this.state.scheduledDelivery = this._getNearestDateTimeByQuarter(new Date(this.state.scheduledDelivery), 0).toISOString();
            }
            if (this.state.activeDate){
                this.state.activeDate = this._getNearestDateTimeByQuarter(new Date(this.state.activeDate), 0).toISOString();
            }
            if (this.state.inActiveDate){
                this.state.inActiveDate = this._getNearestDateTimeByQuarter(new Date(this.state.inActiveDate), 0).toISOString();
            }
        }
        if (this.props.messageType){
            this.state.type = this.props.messageType;
        }
        if (this.props.modal_button_label) {
            this.state.modal_button = this.props.modal_button_label;
            this.state.action = "CREATE";
        }

        window.eCommunicationMessage = this;
        this.promiseRowName = null;
        this.promiseConfirm = null;
        this.resolveConfirm = null;
    }
    _onFieldChange(field, value) {
        let newState = {
            [field]: value && value.target ? value.target.value : value
        };
        if (this.state.action == "CREATE") {
            newState["CreatedOn"] = new Date().toISOString();
        }
        if (field == "Image") {
            newState["uploadedFile"] = false;
            if (document) {
                $("#imageUpload").val("");
            }
            if (value) {
                newState["addedImage"] = true;
            } else {
                // delete the flag if the user erases the field
                newState["addedImage"] = undefined;
            }
        } else if (field == "inActiveDate") {
            if (newState.inActiveDate == null) {
                newState.inActiveDate = "";
            } else if (new Date(this.state.activeDate).getTime() >= new Date(newState.inActiveDate).getTime()) {
                createAlert("You cannot set an expiry date before the publish date", "error");
                return;
            }
        }
        if (field == "tags" || field == "categoryid"){
            if (value.includes("")){
                newState[field] = [""];
            }
        }
        if (field == "advisorEditingFields"){
            newState[field] = this.state.advisorEditingFields;
        }
        this.setState(newState);
        if (field == "frequency"){
            // clear the subscription and category selection when changing the frequency.
            this.setState({ subscribableid : "" });
            this.setState({ categoryid : []});
            // rebind the frequency will update the list here.
            if (value == "onetime"){
            } else {
            }
        }
    }

    async componentDidMount() {
        const { client } = this.props;
        if (client && client.configName){
            const whiteLabelURLs = await UtilActions.doGetWhiteLabelURL(client.configName || "none");
            this.setState({
                whiteLabelURL: whiteLabelURLs ? whiteLabelURLs.url : "",
                sURLDomain: whiteLabelURLs ? whiteLabelURLs.SURLDomain : ""
            });
        }
        return;
    }

    async componentDidUpdate(prevProps, prevState) {
        if((prevState.lang !== this.state.lang || prevState.frequency !== this.state.frequency)) {
            if(this.state.frequency != "onetime") {
                doGetECommunicationSubscribables(this.state.cid, null, null, 0, 1000, null, this.state.lang).then(data => {
                    let state = {};
                    if (data) {
                        let subscribables = data.items;
                        if (subscribables){
                            state.subscribables = subscribables;
                        }
                        this.setState(state);
                    }
                })
            };

            if(this.state.frequency == "onetime") {
                doGetECommunicationCategories(this.state.cid, null, 0, 1000, null, this.state.lang).then(data => {
                    let state = {};
                    if (data) {
                        let categories = data.items;
                        if (categories){
                            state.categories = categories;
                        }
                        this.setState(state);
                    }
                })
            };
        }
    }

    _deleteMessage() {
        if (this.state.artid && confirm("Are you sure you want to delete this Message?")) {
            apiRequest(
                `${appConfig.API_URL}/e-communication/message/delete?ecommid=${this.state.ecommid}`
            ).then(
                function(data) {
                    if (this.props.deleteAction) {
                        this.props.deleteAction();
                    }
                    this._closeModal();
                }.bind(this)
            );
        }
    }

    async _submit(event) {
        event.preventDefault();
        if (this.state.submit_loading) {
            return;
        }
        if (this.state.save_protect) {
            return;
        }
        let config = this.state;

        // validate the input data...
        if (!config.Title || config.Title.trim().length == 0){
            createAlert("Please enter a title.");
            return;
        } else if (config.type != "article" && (!config.Description || config.Description.trim().length == 0)){
            createAlert("Please enter a description.");
            return;
        } else if (!config.Subject || config.Subject.trim().length == 0){
            createAlert("Please enter a email subject.");
            return;
        } else if (!config.Content || config.Content.trim().length == 0){
            createAlert("Please enter an email body.");
            return;
        } else if (!config.status || config.status.length == 0){
            createAlert("Please select the status for this email.");
            return;
        } else if (config.type != "article" && (!config.frequency || config.frequency.length == 0)){
            createAlert("Please select the frequency for this email.");
            return;
        } else if (config.type != "article" && (config.frequency != "onetime") && (!config.subscribableid || config.subscribableid.length == 0)){
            createAlert("Please select the subscription category for this email.");
            return;
        } else if (config.Image && !config.Image.match(/\.(jpg|jpeg|png|gif|tiff)((\?|%3F).+)?$/i)) {
            createAlert("Please enter a valid image url.");
            return;
        } else if(config.Preheader && (config.Preheader.trim().length > config.emailPreheaderTextLen)) {
            createAlert(`Maxmimum characters allowed for Preheaders is ${config.emailPreheaderTextLen}`, "error");
            return;
        };

        // skip the following validations when template type is article type
        let activeDate = null;
        let inactiveDate = null;
        let scheduledDelivery = null;
        let complianceExpiryDate = null;
        if (config.type == "article"){
            config.frequency = null;
            // forcing article template to be active as status should always be active and can't be changed
            config.status = "active";
        } else {

            // active/inactive dates controls the visibility of messages in app
            activeDate = config.activeDate;
            if (activeDate) {
                activeDate = new Date(activeDate);
                activeDate = activeDate.toISOString();
            } else {
                createAlert("Please provide a publish date", "error");
                return;
            }

            // if create and active date before today, return alert
            if (config.action == "CREATE"){
                const _activeDate = new Date(activeDate);
                const _now = new Date();
                _now.setHours(0,0,0,0);
                if (_activeDate < _now){
                    createAlert("Please provide a publish date", "error");
                    return;
                }
            }

            inactiveDate = config.inActiveDate;
            if (inactiveDate) {
                inactiveDate = new Date(inactiveDate);
                inactiveDate = inactiveDate.toISOString();
            } else {
                createAlert("Please provide a expiry date", "error");
                return;
            }

            // make sure the active date is prior inactive date
            if (new Date(activeDate).getTime() >= new Date(inactiveDate).getTime()) {
                createAlert("You cannot set an expiry date before the publish date", "error");
                return;
            }

            // in the case of one time email. scheduled delivery date is irrelevant. set to null
            // otherwise confirm scheduled date is valid
            scheduledDelivery = config.scheduledDelivery;
            if (config.frequency != "onetime"){
                if (scheduledDelivery){
                    scheduledDelivery = new Date(scheduledDelivery);
                    scheduledDelivery = scheduledDelivery.toISOString();
                } else {
                    createAlert("Please provide a delivery date", "error");
                    return;
                }

                // can scheduled delivery date set prior to inactive date???
                // if not, should validate here
                if (new Date(scheduledDelivery).getTime() < new Date(inactiveDate).getTime()) {
                    createAlert("You cannot set an delivery date before the expiry date", "error");
                    return;
                }

                if (config.status == "active") {
                    // prevent same subscription type delivering messages on the same day
                    // subscribableid should not be null as earlier validating should check that already
                    const otherMessagesWithinSameDay = await doGetECommunicationSubscriptionMessages(config.cid, config.subscribableid,  scheduledDelivery, "active");
                    const conflictingMessages = otherMessagesWithinSameDay.items?.filter(message => message.ecommid != config.ecommid || config.action != "UPDATE");
                    if (conflictingMessages?.length) {
                        createAlert(`An existing message (${conflictingMessages[0].Title}) in this subscription is already scheduled to delivery within the 24 hours timespan`, "error");
                        return;
                    }
                }
            }

            // check the compliance data if message compliance is enabled and enforced
            if (this.props.client){
                const { client } = this.props;

                if (client.eCommunication && client.eCommunication.messageCompliance){

                    // in order for the message to be active...
                    complianceExpiryDate = config.complianceExpiryDate;
                    if (complianceExpiryDate) {
                        complianceExpiryDate = new Date(complianceExpiryDate);
                        complianceExpiryDate = complianceExpiryDate.toISOString();
                    }
                } else {
                    // if compliance is not available, ensure compliance expiry date to null and empty out compliance tracking code
                    complianceExpiryDate = null;
                    config.complianceTrackingCode = "";
                }
                if (client.eCommunication && client.eCommunication.messageComplianceEnforced){

                    if (config.status == "active"){
                        // compliance code and expiration need to be set.
                        if (config.complianceTrackingCode == null || config.complianceTrackingCode.trim().length == 0){
                            createAlert("Please provide compliance approval number before activate message ", "error");
                            return;
                        }
                        // compliance date must be set
                        if (complianceExpiryDate == null){
                            createAlert("Please provide compliance expiry date before activate message ", "error");
                            return;
                        }
                        // message expiration date must be set before the compliance expiration date
                        if (complianceExpiryDate < inactiveDate){
                            createAlert("Message expiration date can not be set after compliance expiration date", "error");
                            return;
                        }
                        // message scheduled delivery date must be set before the compliance expiration date
                        if (config.frequency != "onetime" && complianceExpiryDate < scheduledDelivery){
                            createAlert("Message scheduled delivery date can not be set after compliance expiration date", "error");
                            return;
                        }
                    }
                }
            }

            if (config.tags == null || (config.tags.length == 1 && config.tags[0] == "")) {
                config.tags = [];
            }
            if (config.categoryid == null || (config.categoryid.length == 1 && config.categoryid[0] == "")) {
                config.categoryid = [];
            }
        }

        // prep the submission object
        let postObj = {
            cid: config.cid,
            Title: config.Title,
            Description: config.Description,
            Image: config.Image,
            Subject: config.Subject,
            Content: config.Content,
            Preheader: config.Preheader,
            beeEditorContentJson: config.beeEditorContentJson,
            CreatedOn: config.CreatedOn,
            scheduledDelivery: scheduledDelivery,
            scheduledDelivered: config.scheduledDelivered,
            activeDate: activeDate,
            inActiveDate: inactiveDate,
            lang: config.lang,
            complianceTrackingCode : config.complianceTrackingCode,
            complianceExpiryDate : complianceExpiryDate,
            trackingProductType: config.trackingProductType,
            trackingProductName: config.trackingProductName,
            tags: config.tags,
            frequency: config.frequency,
            subscribableid: config.subscribableid,
            categoryid : config.categoryid,
            type: config.type ? config.type : "standard",
            editableTemplateType: config.editableTemplateType || "fixed",
            advisorEditingFields: JSON.stringify(config.advisorEditingFields),
            status: config.status,
            addedImage: config.addedImage
        };

        if (config.duplicate) {
            postObj.duplicate = config.duplicate;
        }

        var url = "e-communication/message/";
        var action;
        if (config.action == "UPDATE") {
            postObj.ecommid = config.ecommid;
            url += "update";
            action = "updated";
        } else {
            postObj.scheduledDelivered = false;
            url += "insert";
            action = "created";
        }

        var formData = new FormData();
        if (this.fileUpload && this.fileUpload.files && this.fileUpload.files.length > 0) {
            formData.append("file", this.fileUpload.files[0]);
            delete postObj["Image"];
        }
        Object.keys(postObj).forEach(function(key) {
            if (key == "tags"){
                formData.append("selectedTags", postObj[key]);
            } else if (key == "categoryid"){
                formData.append("selectedCategories", postObj[key]);
            } else if (key == "scheduledDelivered" || key == "addedImage"){
                formData.append(key, postObj[key] === "true");
            } else if (postObj[key] !== null && postObj[key] !== "") {
                formData.append(key, postObj[key]);
            } else if (key == "Preheader") {
                formData.append(key, postObj[key]);
            }
        });

        // all input data should be validated and ready to go at this point...
        this.setState({ submit_loading: true , save_protect: true }, function() {
            apiFormDataPost(`${appConfig.API_URL}/${url}`, formData)
                .then(function(data) {
                    if (data && data.valid) {
                        createAlert(`Message has been ${action}`, `success`);
                        this._closeModal();
                        if (this.props.updateAction) {
                            this.props.updateAction(config.action == "UPDATE");
                        }
                    } else {
                        this.setState({ submit_loading: false });
                        createAlert(data.error, `error`);
                    }
                    return data;
                }.bind(this))
                .then(function(data) {
                    this.setState({ save_protect: false });
                }.bind(this));
        }.bind(this));
    }
    async _sendEmail(emailAddress) {
        this.setState({
            modals: { ...this.state.modals, emailPreview: false }
        });

        let emailPreheader = this.state.Preheader !== "" ? this.state.Preheader : this.state.Description.substring(0, this.state.emailPreheaderTextLen);

        try {
            await EmailActions.previewECommunicationEmail(this.state.cid, emailAddress, this.state.Subject, this.state.Content, emailPreheader);
            createAlert("Please check your inbox", "success");
        } catch { }
    }

    _closeModal() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
        createAlert('The modal for adding a new communication message has been successfully closed.', 'success');
    }
    _renderDivider(type) {
        switch (type) {
            case "vertical":
                return (
                    <div
                        style={{
                            flexBasis: "10%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative"
                        }}
                    >
                        <span
                            style={{
                                background: "#fff",
                                zIndex: "2",
                                padding: "5px"
                            }}
                        >
                            OR
                        </span>
                        <hr
                            style={{
                                border: 0,
                                borderLeft: "1px solid #e5e5e5",
                                position: "absolute",
                                top: "0",
                                left: "50%",
                                margin: 0,
                                height: "100%",
                                zIndex: "1"
                            }}
                        />
                    </div>
                );
            default:
                return (
                    <div
                        style={{
                            flexBasis: "10%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative"
                        }}
                    >
                        <span
                            style={{
                                background: "#fff",
                                zIndex: "2",
                                padding: "5px"
                            }}
                        >
                            OR
                        </span>
                        <hr
                            style={{
                                border: 0,
                                borderTop: "1px solid #e5e5e5",
                                position: "absolute",
                                top: "50%",
                                left: "0",
                                margin: 0,
                                width: "100%",
                                zIndex: "1"
                            }}
                        />
                    </div>
                );
        }
    }
    _renderAlt() {
        if (this.state.submit_loading) {
            return (
                <div>
                    <h3
                        style={{
                            textAlign: "center"
                        }}
                    >
                        Submitting message...
                    </h3>
                    <Spinner />
                </div>
            );
        }
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flexBasis: "45%",
                    position: "relative",
                    justifyContent: "center",
                    zIndex: 0
                }}
            >
                <Button onClick={() => this.setState({ panel: 1, source: "user" })}>New Message</Button>
            </div>
        );
    }
    _openFileUpload(event) {
        if (document) {
            event.preventDefault();
            $("#imageUpload").trigger("click");
        }
    }
    _getRenderedImageUpload() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row"
                }}
            >
                <Button onClick={this._openFileUpload} className="btn">
                    Upload a Thumb
                </Button>
                {this._renderDivider("vertical")}
                <textarea
                    className="form__value"
                    onChange={event => this._onFieldChange("Image", event.target.value.trim())}
                    value={this.state.Image}
                />
            </div>
        );
    }
    _createStatusOptions() {
        const items = [];
        for (const item of MESSAGE_STATUS){
            items.push(<option key={item.value} value={item.value}>{item.name}</option>);
        }
        return items;
    }
    _createEditableTypes() {
        const items = [];
        for (const item of EDITABLE_TEMPLATE_TYPE){
            items.push(<option key={item.value} value={item.value}>{item.name}</option>);
        }
        return items;
    }
    _createFrequencyOptions(){
        const items = [];
        for (const item of MESSAGE_FREQUENCY){
            items.push(<option key={item.value} value={item.value}>{item.name}</option>);
        }
        return items;
    }
    _createSubscribableOptions(){
        if (this.state.subscribables){
            const items = [];
            for (const item of this.state.subscribables){
                if (item.frequency == this.state.frequency){
                    items.push(<option key={item.subscribableid} value={item.subscribableid}>{item.title}</option>);
                }
            }
            return items;
        }
    }
    _createCategoryOptions(){
        if (this.state.categories){
            const items = [];
            for (const item of this.state.categories){
                items.push(<option key={item.categoryid} value={item.categoryid}>{item.title}</option>);
            }
            return items;
        }
    }
    _validateEmail(email) {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(email);
    }
    _copyToClipboard(e) {
        if (!this.state.Content || this.state.Content.trim().length == 0){
            createAlert("Email body is empty. Please configure the email body and try again. ", `error`);
            return;
        }
        if (window.clipboardData){ // ie 11
            window.clipboardData.clearData();
            window.clipboardData.setData("text/html", this.state.Content);
        } else if (navigator && navigator.clipboard){ // everything else
            navigator.clipboard.writeText(this.state.Content)
                .then(() => {
                    createAlert("HTML to the message is now copied to the clipboard.", `success`);
                })
                .catch(err => {
                    createAlert("Copy to the clipboard failed. Please try again.", `error`);
                });
            return;
        }
        createAlert("Copy to the clipboard failed. Please try again.", `error`);
      };
    _createLanguageOptions() {
        let items = [];
        const langs = {en: "English", fr: "French"};
        for (let item in langs) {
            items.push(
                <option key={item} value={item}>
                    {langs[item]}
                </option>
            );
        }
        return items;
    }
    _getShareableLink(){
        const { type, frequency, ecommid, subscribables, subscribableid, whiteLabelURL } = this.state;
        let shareableLink = "";
        if (ecommid || (subscribableid && subscribables)){
            let valid = frequency == "onetime";
            if (frequency != "onetime" && subscribables){
                const subscribe = subscribables.filter(v => v.subscribableid == subscribableid);
                if (subscribe && subscribe.length == 1 && subscribe[0].status == "active"){
                    valid = true;
                }
            }
            if (valid){
                const val = `${encodeURIComponent(frequency == "onetime" ? ecommid : subscribableid)}`;
                if (val){
                    const key = frequency == "onetime" ? "ecommSch" : "ecommSub";
                    return `${whiteLabelURL}?${key}=${val}`;
                }
            }
        }
        return "";
    }

    _fileUpload(event) {
        this.setState({ Image: this.fileUpload.files[0].name, uploadedFile: true, addedImage: true });
    }
    _renderDetailsPanel() {
        const { client } = this.props;
        const { type, ecommid, whiteLabelURL, multiLangSupport } = this.state;
        const { messageCompliance , messageComplianceEnforced } = client.eCommunication;
        const todayAsMinDate = new Date();
        todayAsMinDate.setDate(todayAsMinDate.getDate() - 1);
        const shareableLink = this._getShareableLink();
        return (
            <form onSubmit={this._submit} onKeyPress={preventDefault}>
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label className="form__label">Title:&nbsp;
                            <Tooltip>The title of the email shown to the end user for their subscription/scheduling management</Tooltip>
                        </label>
                        <input
                            placeholder="Title"
                            type="text"
                            maxLength="160"
                            className="form__value"
                            onChange={event => this._onFieldChange("Title", event.target.value)}
                            value={this.state.Title}
                        />
                    </div>
                </div>
                {this.state.type == "standard" && (<div className="form__row">
                    <div className="form__group form__group--full">
                        <label className="form__label">Description:&nbsp;
                            <Tooltip>The description of the email shown to the end user for their subscription/scheduling management</Tooltip>
                        </label>
                        <textarea
                            placeholder="Description"
                            maxLength="450"
                            className="form__value"
                            onChange={event => this._onFieldChange("Description", event.target.value)}
                            style={{ minHeight: "50px" }}
                            value={ this.state.Description }
                        />
                    </div>
                </div>)}
                {this.state.type == "standard" && (<div className="form__row">
                    <div className="form__group form__group--full">
                        <label className="form__label">Thumbnail:&nbsp;
                            <Tooltip>The thumbnail image of the email shown to the end user for their subscription/scheduling management</Tooltip>
                        </label>
                        <input
                            id="imageUpload"
                            visibility="hidden"
                            style={{
                                display: "none"
                            }}
                            ref={input => {
                                this.fileUpload = input;
                            }}
                            type="file"
                            onChange={this._fileUpload}
                        />{" "}
                        {this.props.client && this.props.client.allowCustomEdit && (
                            <EditableInput
                                editing={this.state.uploadedFile ? false : null}
                                value={this.state.Image}
                                input={this._getRenderedImageUpload()}
                                formatted={
                                    this.state.uploadedFile ? (
                                        this.state.Image
                                    ) : (
                                        <Link href={this.state.Image} target="_blank">
                                            {this.state.Image}
                                        </Link>
                                    )
                                }
                            />
                        )}
                        {(!this.props.client || (this.props.client && !this.props.client.allowCustomEdit)) && (
                            <EditableInput
                                value={this.state.Image}
                                onChange={event => this._onFieldChange("Image", event.target.value)}
                                formatted={
                                    <Link href={this.state.Image} target="_blank">
                                        {this.state.Image}
                                    </Link>
                                }
                            />
                        )}
                    </div>
                </div>)}
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label htmlFor="emailSubject" className="form__label">Email Subject:</label>
                        <input
                            id="emailSubject"
                            placeholder="Subject"
                            type="text"
                            className="form__value"
                            onChange={event => this._onFieldChange("Subject", event.target.value)}
                            value={this.state.Subject}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label htmlFor="preheader" className="form__label">Preheader:</label>
                        <input
                            id="preheader"
                            placeholder="Email Preheader"
                            type="text"
                            className="form__value"
                            maxLength={this.state.emailPreheaderTextLen}
                            onChange={event => this._onFieldChange("Preheader", event.target.value)}
                            value={this.state.Preheader}
                        />
                    </div>
                </div>
                <BeeEditorTakeoverModal
                    parent={this}
                    cid={this.props.cid}
                    ecommid={this.state.ecommid}
                    className="modal-modal-background"
                    open={this.state.modals.contentEditor}
                    update={() => { this.setState({ contentReference: { ...this.state.contentReference } }); }}
                    close={() => { this.setState({ modals: { ...this.state.modals, contentEditor: false } }); }}
                />
                <BeeEditorMetadataContentModal
                    className="modal-modal-background"
                    parent={this}
                    open={this.state.modals.contentEditorRowName}
                    close={() => { this.setState({ modals: { ...this.state.modals, contentEditorRowName: false } }); }}
                />
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label className="form__label">Email Body: &nbsp;
                            <Tooltip>
                                Please ensure the CTA links in the email body are valid and avoid using unsafe characters like space, \ , &lt; , &gt; , &#123; , &#125; etc. <br />
                                These characters are not allowed to be placed directly within URLs.
                            </Tooltip>
                            &nbsp;&nbsp;&nbsp;
                            {   this.state.Content && this.state.Content.trim().length > 0 &&
                                this.state.Subject && this.state.Subject.trim().length > 0 && (
                                    <Button
                                        className="btn brandPrimary--bg  btn--sm"
                                        onClick={() => {
                                            if (!this.state.Subject || this.state.Subject.trim().length == 0){
                                                createAlert("Email subject is empty. Please enter the email subject and try again. ", `error`);
                                                return;
                                            }
                                            if (!this.state.Content || this.state.Content.trim().length == 0){
                                                createAlert("Email body is empty. Please configure the email body and try again. ", `error`);
                                                return;
                                            }
                                            this.setState({ modals: { ...this.state.modals, emailPreview: true }});
                                        }}>
                                            <i className={`fa fa-eye`} /> Preview
                                    </Button>
                                )
                            }
                            &nbsp;&nbsp;&nbsp;
                            { this.state.Content && this.state.Content.trim().length > 0 && (<Link style={{ textDecoration: "underline", fontWeight: "normal" }}
                                onClick={() => { this._copyToClipboard(event); }}
                            >Copy{client && client.eCommunication && client.eCommunication.beeEditorEnabled ? " HTML" : ""}</Link>)}

                        </label>
                        {client && client.eCommunication && client.eCommunication.beeEditorEnabled && (
                        <div className="form__label" style={{ paddingTop: "10px", fontWeight: "normal" }}>
                            To edit email body, click  <Link
                                style={{ textDecoration: "underline", fontWeight: "normal" }}
                                onClick={() => {
                                    this.setState({
                                        modals: { ...this.state.modals, contentEditor: true }
                                    });
                                    this._onLaunchEditor();
                                }}
                            >here</Link> to launch email content editor.
                        </div>
                        )}
                        {client && client.eCommunication && !client.eCommunication.beeEditorEnabled && (
                        <textarea
                            placeholder="Email Body"
                            className="form__value"
                            onChange={event => this._onFieldChange("Content", event.target.value)}
                            style={{ minHeight: "250px" }}
                            value={ this.state.Content }
                        />
                        )}
                    </div>
                    {this.state.modals.emailPreview && (
                            <ConfirmModal
                                title={"Send Email Preview"}
                                button={`Send`}
                                force={false}
                                open={true}
                                onConfirm={() => {
                                    if (!this.state.emailAddress || this.state.emailAddress.trim().length == 0) {
                                        createAlert("Please enter sender email address", `error`);
                                        return;
                                    } else {
                                        if (!this._validateEmail(this.state.emailAddress)) {
                                            createAlert("Sender Email format is invalid, please provide a proper email");
                                            return;
                                        }
                                        this._sendEmail(this.state.emailAddress, this.state.Preheader);
                                    }
                                }}
                                onCancel={() => {
                                    this.setState({
                                        modals: { ...this.state.modals, emailPreview: false }
                                    });
                                }}
                            >
                                <label className="form__label">
                                    A copy of this email will be sent to the following email address.
                                </label>{" "}
                                <br />
                                <br />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: "auto"
                                    }}
                                >
                                    <span style={{ marginRight: "10px", fontSize: "15px", verticalAlign: "sub" }}>
                                        Email Address:{" "}
                                    </span>
                                    <input
                                        type="text"
                                        className="form__value"
                                        onChange={event => this.setState({emailAddress: event.target.value})}
                                        onFocus={event => event.target.select()}
                                        value={this.state.emailAddress}
                                        style={{ maxWidth: "80%" }}
                                    />
                                </div>
                            </ConfirmModal>
                        )}
                </div>
                {type == "standard" && ecommid != "" && whiteLabelURL && shareableLink != "" && (
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <label className="form__label">Shareable link:&nbsp;
                            <Tooltip>
                                This shareable link will open the email message card within the app
                            </Tooltip>
                        </label>
                        <CopyLink textToCopy={shareableLink} />
                    </div>
                </div>)}
                {this.state.type == "standard" && (client.eCommunication && client.eCommunication.messageCompliance) && (<div className="form__row">
                    <div className="form__group form__group--full">
                    <label className="form__label" style={{ fontSize: "18px" }}>Compliance Settings</label>
                    </div>
                </div>)}
                {this.state.type == "standard" && (client.eCommunication && client.eCommunication.messageCompliance) && (<div className="form__row">
                    <div className="form__group">
                        <label className="form__label">Compliance Approval Number:&nbsp;
                            <Tooltip>Set the compliance code as a reference to the compliance record. This is required in order to publish the message and the code will be part of the email X-header.</Tooltip>
                        </label>
                        <input
                            placeholder="Compliance Approval Number"
                            type="text"
                            className="form__value"
                            onChange={event => this._onFieldChange("complianceTrackingCode", event.target.value)}
                            value={this.state.complianceTrackingCode}
                        />
                    </div>
                    <div className="form__group">
                        <label className="form__label">Expiration Date:&nbsp;
                            <Tooltip>Set the date when compliance Expires. This is required in order to publish the message and the message must be delivered before this date.</Tooltip>
                        </label>
                        <DatePickerV2
                            id="approveDate"
                            dateFormat="yyyy/MM/dd h:mm a z"
                            timeFormat="h:mm a"
                            showTimeSelect={true}
                            timeIntervals={15}
                            onChange={(value, formattedValue) => this._onFieldChange("complianceExpiryDate", value)}
                            onBlur={(value, formattedValue) => this._onFieldChange("complianceExpiryDate", null)}
                            calendarPlacement="bottom"
                            value={this.state.complianceExpiryDate}
                        />
                    </div>
                </div>)}
                {this.state.type == "standard" && (
                <div className="form__row">
                    <div className="form__group form__group--full">
                    <label className="form__label" style={{ fontSize: "18px" }}>Message Settings</label>
                    </div>
                </div>)}
                {this.state.type == "standard" && (
                <div className="form__row">
                    <div className="form__group">
                        {
                            multiLangSupport &&
                            <div style={{ marginBottom: "15px" }}>
                                <label htmlFor="language" className="form__label">Language:&nbsp;</label>
                                <div className="select__wrapper">
                                    <select value={this.state.lang}
                                        id="language"
                                        onChange={event => this._onFieldChange("lang", event)} >
                                        {this._createLanguageOptions()}
                                    </select>
                                </div>
                            </div>
                        }
                        <label className="form__label">Status:&nbsp;
                            <Tooltip>The status must be set to "Active" in order to visible to the end user and enables mail delivery.</Tooltip>
                        </label>
                        <div className="select__wrapper">
                            <select value={this.state.status}
                                onChange={event => this._onFieldChange("status", event)} >
                                <option value="">Select One</option>
                                {this._createStatusOptions()}
                            </select>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <label htmlFor="frequency" className="form__label">Frequency:&nbsp;
                                <Tooltip>The frequency defines whether the message is a one time message or a subscription message. <br/>
                                It also suggests how often the occurance of the subscription message but the recurrence is not enforced. <br/>
                                This provides filtering at the console level only.</Tooltip>
                            </label>
                            <div className="select__wrapper">
                                <select value={this.state.frequency}
                                    id="frequency"
                                    onChange={event => this._onFieldChange("frequency", event)} >
                                    <option value="">Select One</option>
                                    {this._createFrequencyOptions()}
                                </select>
                            </div>
                        </div>
                        {this.state.frequency != "onetime" && (
                        <div style={{ marginTop: "15px" }}>
                            <label htmlFor="scheduledDelivery">
                                Subscription Category:&nbsp;
                                <Tooltip>Select the subscription Category for this communications within the selected frequency. <br/>
                                This defines the type of subscription for the message.</Tooltip>
                            </label>
                            <div className="select__wrapper">
                                <select id="subscribableid" value={this.state.subscribableid}
                                    onChange={event => this._onFieldChange("subscribableid", event)} >
                                    <option value="">Select One</option>
                                    {this._createSubscribableOptions()}
                                </select>
                            </div>
                        </div>
                        )}
                        {this.state.frequency == "onetime" && (
                        <div style={{ marginTop: "15px" }}>
                            <label htmlFor="messageCategory">
                                Message Category:&nbsp;
                                <Tooltip>Select a message Category for this communications. <br/>
                                This defines the catgory for filtering one time messages.</Tooltip>
                            </label>
                            {this._renderCategories()}
                        </div>
                        )}
                    </div>
                    <div className="form__group">
                        <div>
                            <label htmlFor="approveDate">
                                Publish Date:&nbsp;
                                <Tooltip>Set the date you want your message to start appearing in the app</Tooltip>
                            </label>
                            <DatePickerV2
                                id="approveDate"
                                minDate={this.state.action == "CREATE" ? todayAsMinDate : undefined}
                                dateFormat="yyyy/MM/dd h:mm a z"
                                timeFormat="h:mm a"
                                showTimeSelect={true}
                                timeIntervals={15}
                                onChange={(value, formattedValue) => this._onFieldChange("activeDate", value)}
                                calendarPlacement="top"
                                value={this.state.activeDate}
                            />
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <label htmlFor="unapproveDate">
                                Expiry Date:&nbsp;
                                <Tooltip>Set the date you want your message to stop appearing in the app</Tooltip>
                            </label>
                            <DatePickerV2
                                id="unapproveDate"
                                minDate={this.state.action == "CREATE" ? todayAsMinDate : undefined}
                                dateFormat="yyyy/MM/dd h:mm a z"
                                timeFormat="h:mm a"
                                showTimeSelect={true}
                                timeIntervals={15}
                                maxDate={messageComplianceEnforced ? this.state.complianceExpiryDate : null}
                                onChange={(value, formattedValue) => this._onFieldChange("inActiveDate", value)}
                                calendarPlacement="top"
                                value={this.state.inActiveDate}
                            />
                        </div>
                    {this.state.frequency != "onetime" && (
                        <div style={{ marginTop: "15px" }}>
                            <label htmlFor="scheduledDelivery">
                                Scheduled Delivery Date:&nbsp;
                                <Tooltip>Set the date you want your message to queue up for scheduled delivery</Tooltip>
                            </label>
                            <DatePickerV2
                                id="scheduledDelivery"
                                dateFormat="yyyy/MM/dd h:mm a z"
                                timeFormat="h:mm a"
                                showTimeSelect={true}
                                timeIntervals={15}
                                minDate={this.state.today}
                                maxDate={messageComplianceEnforced ? this.state.complianceExpiryDate : null}
                                onChange={(value, formattedValue) => this._onFieldChange("scheduledDelivery", value)}
                                calendarPlacement="top"
                                value={this.state.scheduledDelivery}
                            />
                        </div>
                    )}
                    </div>
                    <div className="form__group">
                        {this.state.frequency == "onetime" && (client.eCommunication && client.eCommunication.enableOneTimeEditableType) && (
                        <div style={{ marginBottom: "15px" }}>
                            <label htmlFor="editableTemplateType">
                                Editable Type:&nbsp;
                                <Tooltip>Select an editable type applicable for this email template. <br />
                                <ul>
                                    <li key="editableTemplateTypeProvided">Provided - User have no ability to make any changes to the content</li>
                                    <li key="editableTemplateTypeCustomized">Customized - User will have the ability to add their commentary to the email. Review process will take place before sending</li>
                                </ul>
                                </Tooltip>
                            </label>
                            <div className="select__wrapper">
                                <select id="editableTemplateType" value={this.state.editableTemplateType}
                                    onChange={event => this._onFieldChange("editableTemplateType", event)} >
                                    {this._createEditableTypes()}
                                </select>
                            </div>
                        </div>
                        )}
                        <div>
                            <label className="form__label">Content Tags:&nbsp;
                                <Tooltip>The content tags allows the filtering on the display of the message. The message will only available to the user with matching content tags</Tooltip>
                            </label>
                            {this._renderContentTags()}
                        </div>
                    </div>
                </div>)}
                {this.state.type == "standard" && client.configName == "ubs" && (<div className="form__row">
                    <div className="form__group form__group--full">
                    <label className="form__label" style={{ fontSize: "18px" }}>Tracking Code Settings</label>
                    </div>
                </div>)}
                {this.state.type == "standard" && client.configName == "ubs" && (<div className="form__row">
                    <div className="form__group">
                        <label htmlFor="productType" className="form__label">Product Type :&nbsp;
                            <Tooltip>This is the user friendly name of the campaign (product type)</Tooltip>
                        </label>
                        <input
                            id="productType"
                            placeholder="eg. Top 5 Newsletter"
                            type="text"
                            className="form__value"
                            onChange={event => this._onFieldChange("trackingProductType", event.target.value)}
                            value={this.state.trackingProductType}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="productName" className="form__label">Product Name:&nbsp;
                            <Tooltip>This specifies the name of product email</Tooltip>
                        </label>
                        <input
                            id="productName"
                            placeholder="eg. Top 5 Subscription"
                            type="text"
                            className="form__value"
                            onChange={event => this._onFieldChange("trackingProductName", event.target.value)}
                            value={this.state.trackingProductName}
                        />
                    </div>
                </div>)}
                {this.state.frequency == "onetime" && this.state.editableTemplateType == "customizable" && client.eCommunication && client.eCommunication.enableOneTimeEditableType && this._containsAdvisorEditingMergeTags().length > 0 && (
                <>
                    <div className="form__row">
                        <div className="form__group form__group--full">
                        <label className="form__label" style={{ fontSize: "18px" }}>Merge Tag Selection configurations</label>
                        </div>
                    </div>
                    {this._renderMergeTagsPanel()}
                </>
                )}
                <div className="form__row">
                    <div
                        className="form__group form__group--full"
                        style={{
                            marginTop: "10px",
                            flexDirection: "row"
                        }}
                    >
                        <Link onClick={this._closeModal} style={{ textDecoration: "underline" }}>
                            Cancel
                        </Link>
                        <Button
                            style={{ marginLeft: this.state.artid ? "10px" : "auto" }}
                            type="submit"
                            disabled={this.state.save_protect}
                            value={this.state.modal_button}
                        />
                    </div>
                </div>
            </form>
        );
    }
    _renderCategories() {
        const { categories , categoryid } = this.state;
        if (!categories){
            return;
        }
        const selectedLength = categoryid && Array.isArray(categoryid) ? categoryid.length : 0;
        return (
            <div>
            <select id="categoryid"
                value={this.state.categoryid}
                onChange={e => this._onFieldChange("categoryid", getMultiSelectValues(e.target))}
                multiple="multiple"
            >
                <option value="" >None</option>
                {this._createCategoryOptions()}
            </select>
            <span style={{ fontSize: "smaller" }}>
                {" "}
                Selected Categories:&nbsp;{" "}
                {   categoryid && selectedLength > 0 ?
                    categoryid.map((item, i) => {
                        const hit = categories.filter( c => c.categoryid == item );
                        if (hit && hit.length == 1) {
                            const category = hit[0];
                            //this to ignores if all items are selected, because it also selects "No tag", which is undefined
                            return (
                                <span>
                                    {category.title}
                                    {i < selectedLength - 1 && ", "}
                                </span>
                            );
                        } else {
                            return ( <span>None</span> );
                        }
                    })
                    :
                    <span>None</span>
                }{" "}
            </span>
        </div>);
    }
    _renderContentTags() {
        const { client } = this.props;
        const { tags } = this.state;
        if (!client) {
            return;
        }
        let clientTags = sortObject(client.tags);
        return (
            <div>
                <select
                    value={this.state.tags}
                    onChange={e => this._onFieldChange("tags", getMultiSelectValues(e.target))}
                    multiple="multiple"
                >
                    <option value="" >No tag</option>
                    {clientTags &&
                        Object.keys(clientTags).length > 0 &&
                        Object.keys(clientTags).map(function(tag, i) {
                            return (
                                <option key={tag} value={tag}>
                                    {client.tags[tag]}
                                </option>
                            );
                        })}
                </select>
                <span style={{ fontSize: "smaller" }}>
                    {" "}
                    Selected Tags:&nbsp;{" "}
                    {   tags &&
                        tags.length > 0 ?
                        tags.map((item, i) => {
                            if (client.tags && client.tags[item]) {
                                //this to ignores if all items are selected, because it also selects "No tag", which is undefined
                                return (
                                    <span>
                                        {client.tags[item]}
                                        {i < tags.length - 1 && ", "}
                                    </span>
                                );
                            } else {
                                return <span>No tag</span>;
                            }
                        })
                        :
                        <span>No tag</span>
                    }{" "}
                </span>
            </div>
        );
    }

    _containsAdvisorEditingMergeTags(lookup){
        if (!this.state.Content) return [];
        const { eCommunication } = this.props.client;
        const tagsUsed = [];
        if (eCommunication.advisorEditing && eCommunication.customAdvisorEditMergeTags){
            for (var _tag of eCommunication.customAdvisorEditMergeTags){
                if (this.state.Content.indexOf(_tag.value) !== -1){
                    tagsUsed.push(_tag.value);
                    if (!!lookup && lookup == _tag.value){
                        return [lookup];
                    }
                }
            }
        }
        if (!!lookup)
            return [];
        return tagsUsed;
    }
    _renderMergeTagsPanel() {
        const { client } = this.props;
        return (
            <>
                <div className="form__row">
                    <SelectionOptionList
                        disabled = { this._containsAdvisorEditingMergeTags("[editSalutationSelection]").length == 0 }
                        title = "Salutation"
                        name = "editSalutationSelection"
                        placeholders={[
                            'Salutation Selection Option 1',
                            'Salutation Selection Option 2',
                            'Salutation Selection Option 3'
                        ]}
                        values={[
                            this.state.advisorEditingFields?.editSalutationSelection ? this.state.advisorEditingFields?.editSalutationSelection[0] : "",
                            this.state.advisorEditingFields?.editSalutationSelection ? this.state.advisorEditingFields?.editSalutationSelection[1] : "",
                            this.state.advisorEditingFields?.editSalutationSelection ? this.state.advisorEditingFields?.editSalutationSelection[2] : ""
                        ]}
                        onChange1 = { e => {
                            let options = this.state.advisorEditingFields.editSalutationSelection;
                            options[0] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editSalutationSelection: options
                        }) }}
                        onChange2 = { e => {
                            let options = this.state.advisorEditingFields.editSalutationSelection;
                            options[1] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editSalutationSelection: options
                        }) }}
                        onChange3 = { e => {
                            let options = this.state.advisorEditingFields.editSalutationSelection;
                            options[2] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editSalutationSelection: options
                        }) }}
                    />
                    <SelectionOptionList
                        disabled = { this._containsAdvisorEditingMergeTags("[editBodyParagraph1Selection]").length == 0 }
                        title = "Body Paragraph 1"
                        name = "editBodyParagraph1Selection"
                        placeholders={[
                            'Body Paragraph 1 Selection Option 1',
                            'Body Paragraph 1 Selection Option 2',
                            'Body Paragraph 1 Selection Option 3'
                        ]}
                        values={[
                            this.state.advisorEditingFields?.editBodyParagraph1Selection ? this.state.advisorEditingFields?.editBodyParagraph1Selection[0] : "",
                            this.state.advisorEditingFields?.editBodyParagraph1Selection ? this.state.advisorEditingFields?.editBodyParagraph1Selection[1] : "",
                            this.state.advisorEditingFields?.editBodyParagraph1Selection ? this.state.advisorEditingFields?.editBodyParagraph1Selection[2] : ""
                        ]}
                        onChange1 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph1Selection;
                            options[0] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph1Selection: options
                        }) }}
                        onChange2 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph1Selection;
                            options[1] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph1Selection: options
                        }) }}
                        onChange3 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph1Selection;
                            options[2] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph1Selection: options
                        }) }}
                    />
                </div>
                <div className="form__row">
                    <SelectionOptionList
                        disabled = { this._containsAdvisorEditingMergeTags("[editHeadlineSelection]").length == 0 }
                        title = "Headline"
                        name = "editHeadlineSelection"
                        advisorEditingFields= { this.state.advisorEditingFields }
                        placeholders={[
                            'Headline Selection Option 1',
                            'Headline Selection Option 2',
                            'Headline Selection Option 3'
                        ]}
                        values={[
                            this.state.advisorEditingFields?.editHeadlineSelection ? this.state.advisorEditingFields?.editHeadlineSelection[0] : "",
                            this.state.advisorEditingFields?.editHeadlineSelection ? this.state.advisorEditingFields?.editHeadlineSelection[1] : "",
                            this.state.advisorEditingFields?.editHeadlineSelection ? this.state.advisorEditingFields?.editHeadlineSelection[2] : ""
                        ]}
                        onChange1 = { e => {
                            let options = this.state.advisorEditingFields.editHeadlineSelection;
                            options[0] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editHeadlineSelection: options
                            })
                        }}
                        onChange2 = { e => {
                            let options = this.state.advisorEditingFields.editHeadlineSelection;
                            options[1] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editHeadlineSelection: options
                        }) }}
                        onChange3 = { e => {
                            let options = this.state.advisorEditingFields.editHeadlineSelection;
                            options[2] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editHeadlineSelection: options
                        }) }}
                    />
                    <SelectionOptionList
                        disabled = { this._containsAdvisorEditingMergeTags("[editBodyParagraph2Selection]").length == 0 }
                        title = "Body Paragraph 2"
                        name = "editBodyParagraph2Selection"
                        placeholders={[
                            'Body Paragraph 2 Selection Option 1',
                            'Body Paragraph 2 Selection Option 2',
                            'Body Paragraph 2 Selection Option 3'
                        ]}
                        values={[
                            this.state.advisorEditingFields?.editBodyParagraph2Selection ? this.state.advisorEditingFields?.editBodyParagraph2Selection[0] : "",
                            this.state.advisorEditingFields?.editBodyParagraph2Selection ? this.state.advisorEditingFields?.editBodyParagraph2Selection[1] : "",
                            this.state.advisorEditingFields?.editBodyParagraph2Selection ? this.state.advisorEditingFields?.editBodyParagraph2Selection[2] : ""
                        ]}
                        onChange1 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph2Selection;
                            options[0] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph2Selection: options
                        }) }}
                        onChange2 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph2Selection;
                            options[1] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph2Selection: options
                        }) }}
                        onChange3 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph2Selection;
                            options[2] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph2Selection: options
                        }) }}
                    />
                </div>
                <div className="form__row">
                    <SelectionOptionList
                        disabled = { this._containsAdvisorEditingMergeTags("[editSubheadlineSelection]").length == 0 }
                        title = "Subheadline"
                        name = "editSubheadlineSelection"
                        advisorEditingFields= { this.state.advisorEditingFields }
                        placeholders={[
                            'Subheadline Selection Option 1',
                            'Subheadline Selection Option 2',
                            'Subheadline Selection Option 3'
                        ]}
                        values={[
                            this.state.advisorEditingFields?.editSubheadlineSelection ? this.state.advisorEditingFields?.editSubheadlineSelection[0] : "",
                            this.state.advisorEditingFields?.editSubheadlineSelection ? this.state.advisorEditingFields?.editSubheadlineSelection[1] : "",
                            this.state.advisorEditingFields?.editSubheadlineSelection ? this.state.advisorEditingFields?.editSubheadlineSelection[2] : ""
                        ]}
                        onChange1 = { e => {
                            let options = this.state.advisorEditingFields.editSubheadlineSelection;
                            options[0] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editSubheadlineSelection: options
                            })
                        }}
                        onChange2 = { e => {
                            let options = this.state.advisorEditingFields.editSubheadlineSelection;
                            options[1] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editSubheadlineSelection: options
                        }) }}
                        onChange3 = { e => {
                            let options = this.state.advisorEditingFields.editSubheadlineSelection;
                            options[2] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editSubheadlineSelection: options
                        }) }}
                    />
                    <SelectionOptionList
                        disabled = { this._containsAdvisorEditingMergeTags("[editBodyParagraph3Selection]").length == 0 }
                        title = "Body Paragraph 3"
                        name = "editBodyParagraph3Selection"
                        placeholders={[
                            'Body Paragraph 3 Selection Option 1',
                            'Body Paragraph 3 Selection Option 2',
                            'Body Paragraph 3 Selection Option 3'
                        ]}
                        values={[
                            this.state.advisorEditingFields?.editBodyParagraph3Selection ? this.state.advisorEditingFields?.editBodyParagraph3Selection[0] : "",
                            this.state.advisorEditingFields?.editBodyParagraph3Selection ? this.state.advisorEditingFields?.editBodyParagraph3Selection[1] : "",
                            this.state.advisorEditingFields?.editBodyParagraph3Selection ? this.state.advisorEditingFields?.editBodyParagraph3Selection[2] : ""
                        ]}
                        onChange1 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph3Selection;
                            options[0] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph3Selection: options
                        }) }}
                        onChange2 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph3Selection;
                            options[1] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph3Selection: options
                        }) }}
                        onChange3 = { e => {
                            let options = this.state.advisorEditingFields.editBodyParagraph3Selection;
                            options[2] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editBodyParagraph3Selection: options
                        }) }}
                    />
                </div>
                <div className="form__row">
                    <SelectionOptionList
                        disabled = { this._containsAdvisorEditingMergeTags("[editClosingCommentSelection]").length == 0 }
                        title = "Closing Comment"
                        name = "editClosingCommentSelection"
                        placeholders={[
                            'Closing Comment Selection Option 1',
                            'Closing Comment Selection Option 2',
                            'Closing Comment Selection Option 3'
                        ]}
                        values={[
                            this.state.advisorEditingFields?.editClosingCommentSelection ? this.state.advisorEditingFields?.editClosingCommentSelection[0] : "",
                            this.state.advisorEditingFields?.editClosingCommentSelection ? this.state.advisorEditingFields?.editClosingCommentSelection[1] : "",
                            this.state.advisorEditingFields?.editClosingCommentSelection ? this.state.advisorEditingFields?.editClosingCommentSelection[2] : ""
                        ]}
                        onChange1 = { e => {
                            let options = this.state.advisorEditingFields.editClosingCommentSelection;
                            options[0] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editClosingCommentSelection: options
                        }) }}
                        onChange2 = { e => {
                            let options = this.state.advisorEditingFields.editClosingCommentSelection;
                            options[1] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editClosingCommentSelection: options
                        }) }}
                        onChange3 = { e => {
                            let options = this.state.advisorEditingFields.editClosingCommentSelection;
                            options[2] = e.target.value;
                            this._onFieldChange("advisorEditingFields", {
                                ...this.state.advisorEditingFields,
                                editClosingCommentSelection: options
                        }) }}
                    />
                    <div class="form__group"/>
                </div>
            </>
    );
    }

    // ********** bee editor start **********
    _onLaunchEditor(){
        var templateJson = this.state.beeEditorContentJson.length > 0 ? JSON.parse(this.state.beeEditorContentJson) : "";
        this.setState({bodyContentChanged : false});
        doGetBeeEditorToken(this.props.cid).then(data => {
            if (data && data.valid) {
                if (templateJson == ""){
                    // default JSON should be configured at client level...
                    if (!data.defaultJson || data.defaultJson == ""){
                        this._onFetchTemplate(data.defaultUrl).then((templateJson) => {
                            this._onLoadBeedEditor(templateJson, data.uid, data.token);
                        });
                        return;
                    } else {
                        templateJson = JSON.parse(data.defaultJson);
                    }
                }
                this._onLoadBeedEditor(templateJson, data.uid, data.token);
            } else {
                this.setState({
                    modals: { ...this.state.modals, contentEditor: false }
                });
                createAlert("Unable to launch Content Editor. ");
            }
        })
    }
    _onFetchTemplate(url) {
        return fetch(url).then((response) => response.json())
    }
    _deferredPromise(){
        var _resolve, _reject;

        var promise = new Promise(function(resolve, reject){
        _resolve = resolve;
        _reject = reject;
        })

        promise.resolve = _resolve;
        promise.reject = _reject;

        return promise;
    }
    _onLoadBeedEditor(templateJson, uid, token){
        doGetBeeEditorCustomRows(this.props.cid).then(result => {
            if (!result || !result.data){
                createAlert("Unable to launch Content Editor. ");
                return;
            }
            if (result.data.customRowNames){
                window.eCommunicationMessage.state.customRowNames = result.data.customRowNames;
            }
            const { eCommunication } = this.props.client;
            var externalContentURLs = [];
            result.data.externalContentURLs.forEach((e) => {
                externalContentURLs.push(e);
            });
            var rowsConfiguration = {
                emptyRows: true,
                defaultRows: true,
                externalContentURLs
            }
            var rowDisplayConditions = eCommunication.enableTeamSignature ? [{
                type: 'Signature Type',
                label: 'Personal Signature',
                description: 'Use advisor signature as email signature',
                before: '<% if (!useTeamSignature) { %>',
                after: '<% } %>',
              }, {
                type: 'Signature Type',
                label: 'Team Signature',
                description: 'Use team signature as email signature',
                before: '<% if (useTeamSignature) { %>',
                after: '<% } %>',
            }] : null;
            var mergeTags = [
                { name: 'Recipient first name' , value: '{contactfield=firstname}'},
                { name: 'Recipient last name' , value: '{contactfield=lastname}'},
                { name: 'Advisor first name' , value: '[firstName]'},
                { name: 'Advisor last name' , value: '[lastName]'},
                { name: 'Advisor preferred name' , value: '[preferredName]'},
                { name: 'Advisor job title' , value: '[jobTitle]'},
                { name: 'Advisor Corporate Title (Trade name)' , value: '[corpTitle]'},
                { name: 'Advisor profile photo' , value: '[headshot]'},
                { name: 'Advisor signature' , value: '[signature]'},
                { name: 'Advisor designations' , value: '[designations]'},
                { name: 'Advisor company logo' , value: '[logo]'},
                { name: 'Advisor phone numbers' , value: '[phone]'},
                { name: 'Advisor address' , value: '[address]'},
                { name: 'Advisor website URL' , value: '[web]'},
                { name: 'Advisor email address' , value: '[email]'},
                { name: 'Advisor LinkedIn footer icon' , value: '[linkedin]'},
                { name: 'Advisor X (Twitter) footer icon' , value: '[twitter]'},
                { name: 'Advisor Facebook footer icon' , value: '[facebook]'},
                { name: 'Advisor Instagram footer icon' , value: '[instagram]'}
            ];
            if (eCommunication.customSignatureMergeTags){
                for (var _tag of eCommunication.customSignatureMergeTags){
                    mergeTags.push(_tag);
                }
            }
            mergeTags.push({ name: 'Disclosure' , value: '[disclosure]'});
            mergeTags.push({ name: 'Designation Disclosure' , value: '[disclosureByDesignation]'});
            mergeTags.push({ name: 'Transitioning Advisor Disclosure' , value: '[disclosureTransitioning]'});
            if (eCommunication.enableTeamSignature){
                mergeTags.push({ name: 'Team name', value: '[teamName]'});
                mergeTags.push({ name: 'Team photo', value: '[teamPhoto]'});
                mergeTags.push({ name: 'Team members', value: '[teamMembers]'});
                mergeTags.push({ name: 'Team phone numbers' , value: '[teamPhone]'});
                mergeTags.push({ name: 'Team address' , value: '[teamAddress]'});
                mergeTags.push({ name: 'Team website URL' , value: '[teamWeb]'});
                mergeTags.push({ name: 'Team email address' , value: '[teamEmail]'});
            }
            if (eCommunication.advisorEditing && eCommunication.customAdvisorEditMergeTags){
                for (var _tag of eCommunication.customAdvisorEditMergeTags){
                    mergeTags.push(_tag);
                }
            }
            if (this.state.type == "article"){
                const articleMergeTags = [
                    { name: 'Content Comment' , value: '[contentComment]'},
                    { name: 'Content URL' , value: '[contentURL]'},
                    { name: 'Content Image' , value: '[contentImage]'},
                    { name: 'Content Title' , value: '[contentTitle]'}
                ];
                for (const tag of articleMergeTags){
                    mergeTags.splice(7, 0 , tag);
                }
            }
            var specialLinks = [
                {
                    type: 'Frequently used',
                    label: 'Unsubscribe link',
                    link: '{unsubscribe_url}'
                }
            ];

            let editorFonts = null;
            try{
                if (eCommunication.beeEditorFonts){
                    editorFonts = JSON.parse(eCommunication.beeEditorFonts);
                }
            } catch (e){
                createAlert(`Invalid editor fonts definition detected`);
            }

            var beeConfig = {
                uid: uid,
                language: 'en-US',
                autosave: true,
                container: 'bee-plugin-container',
                rowsConfiguration,
                rowDisplayConditions,
                mergeTags,
                specialLinks,
                editorFonts,
                contentDialog: {
                    saveRow: {
                        handler: function (resolve, reject, args) {
                            var currentMessage = window.eCommunicationMessage;
                            currentMessage.setState({
                                modals: { ...currentMessage.state.modals, contentEditorRowName: true }
                            });
                            currentMessage.promiseRowName = currentMessage._deferredPromise();
                            return currentMessage.promiseRowName
                            .then((name) => {
                                var customRowNames = window.eCommunicationMessage.state.customRowNames;
                                if (customRowNames && Array.isArray(customRowNames) && customRowNames.indexOf(name) == -1){
                                    customRowNames.push(name);
                                }
                                resolve({name})
                            })
                            .catch(() => {
                                reject()
                            })
                        }
                    }
                }
            }
            const beeFinalConfig = {...beeConfig
                , onSave : function(jsonFile, htmlFile){
                    // The following commentted code is to close the modal upon clicking on the save button.
                    // Left it here in case the client changed their mind or potentially add this as a feature in the client settings
                    // window.eCommunicationMessage.setState({
                    //     modals: { ...window.eCommunicationMessage.state.modals, contentEditor: false }
                    // });

                    // capture the changes
                    window.eCommunicationMessage._onFieldChange("beeEditorContentJson" , jsonFile);
                    window.eCommunicationMessage._onFieldChange("Content" , htmlFile);

                    // clear the content "dirty" flag as the data is now captured on the field.
                    window.eCommunicationMessage.state.bodyContentChanged = false;

                    // alert the user and remind the user they still need a final save to save the message and other attributes into the database.
                    createAlert(`Email body has been captured. <br/><br/> Don't forget to save the message after closing the Email Content Editor modal window`, `success`);
                    return;
                }
                , onChange : function(jsonFile, response){
                    window.eCommunicationMessage.state.bodyContentChanged = true;
                    return;
                }
                , onTogglePreview : function(){
                    return;
                }
                , onSaveRow: function (rowJSON, rowHTML, pageJSON) {
                    doSetBeeEditorCustomRow(window.eCommunicationMessage.props.cid, rowJSON).then(data => {
                        createAlert(`New row has been saved`, `success`);
                    });
                    return;
                }
                , onError : function(errorMessage){
                    createAlert(`Content Editor encountered an error: ${errorMessage}`);
                    return;
                }
            }
            var beeEditor = new BeePlugin();
            beeEditor.token = token;
            beeEditor.start(beeFinalConfig, templateJson);
        })
    }
    // ********** bee editor ends **********

    render() {
        const { panel, open, modal_title } = this.state;
        return (
            <Modal show={open} onHide={this._closeModal}
            backdrop='static'
            aria-labelledby="ModalHeader"
            className="modal-modal-background modal-ecommunication-message">
                <Modal.Header closeButton={true}>
                    <Modal.Title id="ModalHeader">{modal_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this._renderDetailsPanel()}
                </Modal.Body>
            </Modal>
        );
    }
    _getNearestDateTimeByQuarter(datetime, padUp){
        // add minutes to the next nearest 15 quarter
        var nearest = 15;
        var m = (nearest * Math.ceil((datetime.getMinutes() + padUp) / nearest));
        datetime.setSeconds(0);
        datetime.getMilliseconds(0);
        datetime.setMinutes(m);
        return datetime;
    }
}

const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(AddECommunicationMessage);
