import React, { Component } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import Spinner from '../atoms/Spinner';

export interface EventCalendarProps<T> {
    title: string;
    renderSidebar: (item: T) => JSX.Element;
    idField: keyof T;
    getTitle: (item: T) => string;
    getEventColour: (item: T) => { backgroundColor: string; borderColor: string; textColor: string;}
    refresh: () => Promise<{ items: T[]; total: number; }>
    itemName: string;
    allowCreateWithoutClient?: boolean;
    createLink?: string;
    cid?: string;
    defaultDate?: string;
}

interface EventCalendarState<T> {
    items: dynamic<T> | null;
    selected: T | null;
    filter: { cid?: string };
}

export default class EventCalendar<T> extends Component<EventCalendarProps<T>, EventCalendarState<T>> {
    constructor(props: EventCalendarProps<T>) {
        super(props);
        this.state = { selected: null, items: null, filter: {cid: props.cid} };
        this.props.refresh().then(results => this.setState({ items: Object.assign({}, ...results.items.map(item => ({[item[this.props.idField] as unknown as string]: item}))) }));
    }

    componentWillReceiveProps(newProps: EventCalendarProps<T>) {
        if (newProps.cid !== this.state.filter.cid) {
            this.setState({ filter: { cid: newProps.cid }});
            this.props.refresh().then(results => this.setState({ items: Object.assign({}, ...results.items.map(item => ({[item[this.props.idField] as unknown as string]: item}))) }));
        }
    }

    render() {
        if (!this.state.items) {
            return <Spinner/>
        }
        return (
            <div style={{marginTop: "10px"}}>
                <div className="elementListBox ibox" style={{ width: "69%", float: "left", clear: "none", paddingTop: "10px" }}>
                    <FullCalendar
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: ''
                        }}
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        dayMaxEvents={2}
                        displayEventTime={false}
                        buttonText={{
                            today: "Today"
                        }}
                        height="auto"
                        events={Object.values(this.state.items).map(item => ({
                            id: item![this.props.idField] as unknown as string,
                            start: new Date((item as unknown as { startDate: string | Date }).startDate),
                            end: new Date((item as unknown as { endDate: string | Date }).endDate), // the documentation says the end date is exclusive but they're filthy liars and you can't trust them
                            ...(this.props.getEventColour(item!)),
                            title: this.props.getTitle(item!),
                        }))}
                        eventClick={info => {
                            this.setState({ selected: this.state.items![info.event.id]! })
                        }}
                        eventInteractive={true}
                        initialDate={this.props.defaultDate}
                    />
                </div>
                <div className={"elementListBox ibox"} style={{ width: "30%", float: "right", clear: "none", padding: "5px" }}>
                    {this.state.selected ? this.props.renderSidebar(this.state.selected) : <div style={{display:"flex", height: "545px"}}>
                        <span style={{ display: "flex", alignItems: "center", textAlign: "center" }}>Click on a {this.props.itemName} in the calendar grid to see more details</span>
                    </div>}
                </div>
            </div>
        )
    }
}