import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
import { createAlert, sendActivationEmail, getActivationEmail, EmailActions } from "../../../actions";
import { expand, deepMerge } from "../../../utils/Helpers";
import Spinner from "../../atoms/Spinner";
import { ConfirmModal } from "../../atoms/Modal";

interface ActivationModalProps {
    open: boolean;
    user: Mongo.clientAdmin;
    uid: string;
    lang?: string;
    userEmail?: string;
    emails?: { [lang: string]: Mongo.ICustomEmail };
    cid?: string;
    closeAction?: () => void;
}
interface ActivationModalState {
    open: boolean;
    body: string;
    subject: string;
    uid: string;
    loading: boolean;
    previewModalOpen: boolean;
    emailAddress: string;
}

export default class ActivationEmailModal extends Component<ActivationModalProps, ActivationModalState> {
    constructor(props) {
        super(props);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._sendActivationEmail = this._sendActivationEmail.bind(this);
        this.state = {
            open: props.open || false,
            body: "",
            subject: "",
            uid: props.uid,
            loading: false,
            previewModalOpen: false,
            emailAddress: (props.user && props.user.email) || ""
        };
    }

    _onFieldUpdate(field: string, event) {
        const value = event.target ? event.target.value : event;
        let state = {};
        if (field.indexOf(".") > -1) {
            const obj = expand(field, value); // mutate key into object with value
            const firstKey = Object.keys(obj)[0]; // get the outer most property
            const origValue = { [firstKey]: this.state[firstKey] }; // current value in state
            state = deepMerge(origValue, obj); // merge both values together and push into state
        } else {
            state[field] = value;
        }

        this.setState(state);
    }

    componentDidMount() {
        this.getActivationEmail();
    }

    componentWillReceiveProps(newProps: ActivationModalProps) {
        const state = {
            ...(newProps.open !== this.props.open ? { open: newProps.open } : null),
            ...(newProps.user !== this.props.user ? { user: newProps.user } : null),
            ...(newProps.uid !== this.props.uid ? { uid: newProps.uid } : null)
        };
        const callback = state.open || (state.uid && state.uid.length) ? this.getActivationEmail : undefined;
        if (Object.keys(state).length) {
            this.setState(
                state as ActivationModalState,
                callback
            );
        }
    }

    async submit() {
        const { body, subject, uid, loading } = this.state;
        if (loading) {
            return;
        }
        if (!body || !subject) {
            createAlert("Please enter a email subject and body ", `error`);
            return;
        }
        const data = await sendActivationEmail(body, subject, uid);
        if ("valid" in data && data.valid) {
            createAlert(`Activation Email Sent`, `success`);
            this.close();
        }
    }

    __validateEmail(email: string) {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(email);
    }

    async _sendActivationEmail(emailAddress: string) {
        this._onFieldUpdate("previewModalOpen", false);
        const { body, subject } = this.state;
        const { user } = this.props;
        try {
            await EmailActions.previewActivationEmail(user.cid || "", emailAddress, subject, body);
            createAlert("Please check your inbox", "success");
        } catch {
            /**/
        }
    }

    async getActivationEmail() {
        const { uid, open, loading } = this.state;
        if (!open || loading) {
            return;
        }
        this.setState({ loading: true });
        const data = await getActivationEmail(uid);
        if (data && data.valid) {
            this.setState({
                open: true,
                body: data.body,
                subject: data.subject,
                loading: false
            });
        } else {
            this.setState({ loading: false });
        }
    }

    close() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
    }

    render() {
        const { open, body, subject, loading } = this.state;
        const blankEmail = subject && body ? false : true;
        const { user } = this.props;
        const link =
            user && (user.isSuper || user.isSuperClientAdmin)
                ? "/siteTemplates/img/emailGuideInternalV2.pdf"
                : "/siteTemplates/img/emailGuideClientV2.pdf";
        return (
            <div>
                <Modal
                    open={open}
                    title="Send Activation Email"
                    closeAction={() => this.close()}
                    link={link}
                    linkTitle={<Button className="btn brandPrimary--bg  btn--sm"><i className="fa fa-question-circle" /> Instructions</Button>}
                    footer={
                        loading ? (
                            undefined
                        ) : (
                                <div>
                                    {
                                        <Button
                                            className="btn brandPrimary--bg  btn--sm"
                                            style={{float: "left"}}
                                            disabled={(subject == "") || (body == "")}
                                            onClick={e => {
                                                    blankEmail ? e?.preventDefault() : this.setState({ previewModalOpen: true });
                                                }}>
                                                <i className={`fa fa-eye`} /> Preview
                                        </Button>
                                    }
                                    <Button onClick={() => this.submit()}>Send Email</Button>
                                </div>
                            )
                    }
                >
                    {loading ? (
                        <Spinner />
                    ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "wrap"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexBasis: "100%",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <label htmlFor="email">Email Address:</label>
                                    <input id="email" type="text" value={this.props.userEmail} disabled={true} />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexBasis: "100%",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <label htmlFor="subject">Subject:</label>
                                    <input
                                        id="subject"
                                        type="text"
                                        value={subject}
                                        onChange={event => this.setState({ subject: event.target.value })}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexBasis: "100%",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <label htmlFor="emailBody">Email Body:</label>
                                    <textarea
                                        style={{
                                            minHeight: "250px"
                                        }}
                                        id="emailBody"
                                        value={body}
                                        onChange={event => this.setState({ body: event.target.value })}
                                    />
                                </div>
                            </div>
                        )}
                </Modal>
                {this.state.previewModalOpen && (
                    <ConfirmModal
                        title={"Email Preview"}
                        button={`Send`}
                        force={false}
                        open={true}
                        onConfirm={() => {
                            if (this.state.emailAddress) {
                                if (!this.__validateEmail(this.state.emailAddress)) {
                                    createAlert("Email format is invalid, please provide a proper email");
                                    return;
                                }
                                this._sendActivationEmail(this.state.emailAddress);
                            } else {
                                createAlert("Please enter email address", `error`);
                            }
                        }}
                        onCancel={() => {
                            this.setState({ previewModalOpen: false });
                        }}
                    >
                        <label className="form__label">
                            A copy of this email will be sent to the following email address.
                        </label>{" "}
                        <br />
                        <br />
                        <div
                            style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "auto" }}
                        >
                            <span style={{ marginRight: "10px", fontSize: "15px", verticalAlign: "sub" }}>
                                Email Address:{" "}
                            </span>
                            <input
                                type="text"
                                className="form__value"
                                onChange={event => this._onFieldUpdate("emailAddress", event)}
                                onFocus={event => event.target.select()}
                                value={this.state.emailAddress}
                                style={{ maxWidth: "80%" }}
                            />
                        </div>
                    </ConfirmModal>
                )}
            </div>
        );
    }
}
