import React from "react";

interface ISearchPlaceHolderProps {
    style?: React.CSSProperties
    message: string
}

export default function SearchPlaceHolder(props: ISearchPlaceHolderProps) {

    let style = props.style || {};

    return (
        <div style={{ width: "100%", display: "block", marginTop: "4%", ...style }}>
            <p style={{ fontSize: 20, textAlign: "center", alignSelf: "end", fontWeight: "bold" }}>
                {props.message}
            </p>
            <p style={{ textAlign: "center", opacity: 0.5 }}>
                <img src="/img/Reports_Load.svg" style={{ color: "#A7B1C2", opacity: 0.5 }} aria-label="Loading reports" />
            </p>
        </div>
    );
}