import React from "react";
import AutoSuggestDomain from "./AutoSuggestDomain";
import Button from "../atoms/Button";

class DomainFilterActionableList extends React.Component {
    constructor(props) {
        super(props);

        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._removeOption = this._removeOption.bind(this);
        this._addOption = this._addOption.bind(this);
        this._updateItems = this._updateItems.bind(this);
        this._onSuggestionSelected = this._onSuggestionSelected.bind(this);

        let items = [];
        if (props.items && props.items.length > 0) {
            items = props.items;
        }
        this.state = {
            items: items
        };
    }

    componentWillReceiveProps(newProps) {
        if (this.props.items != newProps.items) {
            this._onFieldUpdate("items", newProps.items);
        }
    }
    _onAutoSuggestChange(id, newValue) {}
    _onSuggestionSelected(id, newVal) {
        let items = [];

        if (this.state.items) {
            this.state.items.forEach(item => {
                items.push(item);
            });
        }
        let hasNew = false;
        for (const newValue of newVal.split(",").map(i => i.trim())) {
            if (!newValue || !newValue.length) {
                continue;
            }
            if (items.indexOf(newValue) > -1) {
                continue;
            }
            hasNew = true;
            items.push(newValue);
        }
        if (!hasNew) {
            return;
        }
        if (this._updateItems(items)) {
            this._onFieldUpdate("items", items);
        }
    }

    _onFieldUpdate(keyName, event) {
        this.setState({
            [keyName]: event && event.target ? event.target.value : event
        });
    }
    _removeOption(i) {
        let { items } = this.state;
        items.splice(i, 1);
        if (this._updateItems(items)) {
            this._onFieldUpdate("items", items);
        }
    }

    _addOption() {
        let { items } = this.state;
        let item = (this.inputVal.value || "").trim();

        if (item.length > 0) {
            if (!items) {
                items = [];
            }

            items.push(item);
            if (this._updateItems(items)) {
                this._onFieldUpdate("items", items);
            }
        }
        this.inputVal.value = "";
    }

    _updateItems(items) {
        if (this.props.updateAction) {
            return this.props.updateAction(items);
        }
        return true;
    }

    render() {
        const { items } = this.state;
        return (
            <ul className="actionablelist form__value form__value--centered">
                {items &&
                    items.map(
                        function(val, i) {
                            if (this.props.disabled) {
                                return;
                            }
                            return (
                                <li key={`${val}-${i}`} ariaLabel= {val}>
                                    <input type="text" disabled={true} value={val} aria-label={val}/>
                                    <Button onClick={event => this._removeOption(i)} ariaLabel= "Remove">Remove</Button>
                                </li>
                            );
                        }.bind(this)
                    )}
                <li className="submit brandPrimary__light__important--bg">
                    <AutoSuggestDomain
                        id={this.props.id}
                        placeholder="Please enter a domain. i.e: domain.com"
                        onChange={this._onAutoSuggestChange}
                        onSuggestionSelected={this._onSuggestionSelected}
                        lang={this.props.lang}
                        disabled={this.props.disabled || this.props.filtersButNoStreams}
                    />
                </li>
            </ul>
        );
    }
}

export default DomainFilterActionableList;
