import BaseAction from "../utils/BaseAction";
import { IReportFilter } from "../components/pages/reports/Index";

export type IOfferActions = ClassInstance<typeof _OfferActions>;
class _OfferActions extends BaseAction {

    async doList(query: IReportFilter & {
        sort?: string;
        sortDirection?: number;
        skip?: number;
        limit?: number;
    }) {
        const data = await this.apiRequestWithErrorAlert<{
            items: ServerTypes.Console.IOfferReportItem[];
            count: number;
        }>("/reports/offer/offers/json", "POST", { ...query });
        if (data && data.items && data.items.length) {
            return { items: data.items, total: data.count };
        } else {
            return { items: [], total: 0 };
        }
    }


    async doDownload(query: IReportFilter & {
        sort?: string;
        sortDirection?: number;
        skip?: number;
        limit?: number;
    }) {
        const data = await this.apiRequest("/reports/offer/offers/csv", "POST", { ...query });
        if (data) {
            return this.downloadFile("offers", data);
        }
    }
}

export const OfferActions = new _OfferActions();
