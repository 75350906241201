import UsersList from "./List";
import UsersEdit from "./Edit";
import UsersUpload from "./Upload";
export default [
    { exact: true, path: "/users", component: UsersList },
    { path: "/users/c", component: UsersEdit },
    { path: "/users/c/:cid", component: UsersEdit },
    { path: "/users/e/:cid/:uid", component: UsersEdit },
    { path: "/users/u", component: UsersUpload }
];
