import React, { Component } from "react";
import { apiRequest, apiFormDataPost, sanitizeHtml, downloadFile as fileDownload } from "../../utils/Helpers";
import Spinner from "../atoms/Spinner";
import appConfig from "../../../config/config.dev";
import { createAlert } from "../../actions/utils";
import PageHeading from "./PageHeading";
import Button from "../atoms/Button";
import debounce from "lodash/debounce";
import { Wrapper, Row } from "../atoms/Layout/";
import { parse } from "csv-string";

interface ICSVUploadProps {
    /** the name of the item as a user understands it (ie. Admin, User) */
    itemName: string;
    /** the name of the item in our internal routes (ie. clientadmin, clientuser) */
    routeName: string;
    /** note that will be shown */
    note?: string | JSX.Element;
    /** if provided, the csv's lines will be processed in increments so that the progress can be shown */
    inc?: number;
}

interface ICSVUploadState {
    loading: boolean;
    errors: string;
    csv: string;
    processed?: number;
    total?: number;
}

export default class CSVUpload extends Component<ICSVUploadProps, ICSVUploadState> {

    readFileDebounce: Function;

    constructor(props) {
        super(props);
        this._readFile = this._readFile.bind(this);
        this.readFileDebounce = debounce(this._readFile, 1000);
        this.state = {
            csv: "",
            errors: "",
            loading: false
        };
    }

    componentDidMount() {
        apiRequest(`${appConfig.API_URL}/${this.props.routeName}/csv/example`).then(
            data => {
                this.setState({ csv: data });
            }
        );
    }

    _onSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        const file = (this as dynamic).fileUpload.files[0]; // TODO: bad
        if (!file) {
            createAlert("Please select a file to upload.", "error");
            return;
        }
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = this._readFile;
    }

    async _readFile(event) {
        let { inc, itemName, routeName } = this.props;
        const itemNameLower = itemName.toLowerCase();

        const csv: string[][] = parse(event.target.result);
        const header = csv[0];
        const lines = csv.splice(1);
        if (!lines.length) {
            createAlert("Your CSV is empty");
            return;
        }
        if (!inc) {
            inc = lines.length;
        }
        if (lines.length > 2000) {
            createAlert(`You are not allowed to upload ${lines.length} items. Please limit your rows in this upload to 2000`)
            return;
        }
        this.setState({
            errors: "",
            loading: true,
            processed: 0,
            total: lines.length,
        });
        let inserted = 0;
        let updated = 0;
        let notUpdated = 0;
        let errors = "";
        for (let i = 0; i < Math.ceil(lines.length / inc); i++) {
            const formData = new FormData();
            formData.append(
                "file",
                new Blob(
                    [
                        `${header}\n${lines
                            .slice(i * inc, (i + 1) * inc)
                            .map(d => d.map(dd => `"${sanitizeHtml(dd)}"`))
                            .join("\n")}`
                    ],
                    {
                        type: "text/csv"
                    }
                ),
                "upload.csv"
            );
            const data = await apiFormDataPost(`${appConfig.API_URL}/${routeName}/csv/import`, formData).catch(error => {
                const err = ((error || {}).response || {}).data || {};
                throw err.error || "An unexpected error occured while communicating with the server.";
            });
            if (data.error) throw data;

            const lineBreak = '<br style="margin: 0"/>';
            let userAddFailedMsg = (data.failed || [])
                .map(ii => `Row ${ii.row + i * inc!} - ${ii.error}`)
                .join(lineBreak);
            if (userAddFailedMsg) {
                userAddFailedMsg = `User creation error${lineBreak}${userAddFailedMsg}${lineBreak}`;
            }

            let delegateAddFailedMsg = (data.failedDelegates || [])
                .map(failed => {
                    const rowError = `Row ${failed.row}:<br style="margin: 0"/>`;
                    const delegateError = (failed.delegateAddResults || [])
                        .map(results => `&nbsp;&nbsp;&nbsp;&nbsp;${results.email} - ${results.reason}`)
                        .join(lineBreak);
                    return rowError + delegateError;
                })
                .join(lineBreak);
            if (delegateAddFailedMsg) {
                delegateAddFailedMsg = `User delegate assignment error, some delegate assignments failed${lineBreak}${delegateAddFailedMsg}`;
            }
            errors = errors.concat([userAddFailedMsg, delegateAddFailedMsg].filter(Boolean).join(lineBreak));

            inserted += data.inserted || 0;
            updated += data.updated || 0;
            notUpdated += data.notUpdated || 0;

            this.setState({
                processed: this.state.processed! + inc,
            });
        }

        const messages = [
            ...(inserted ? [`Inserted ${inserted} new ${itemNameLower}${inserted !== 1 ? 's' : ''}`] : []),
            ...(updated ? [`Updated ${updated} ${itemNameLower}${updated !== 1 ? 's' : ''}`] : []),
            ...(notUpdated ? [`${notUpdated} ${itemNameLower}${notUpdated !== 1 ? 's' : ''} left unchanged`] : [])
        ];
        this.setState({ errors });
        if (messages.length) {
            createAlert(`${itemName} CSV imported:<br />${messages.join("<br />")}`, "success");
        }
        if (errors) {
            createAlert("You have errors in your upload, please fix them and re-upload", "error");
        }

        this.setState({
            loading: false
        });
    }

    render() {
        const { errors, loading, csv, processed, total } = this.state;
        const { itemName, inc } = this.props;
        const itemNameLower = itemName.toLowerCase();
        if (!csv) {
            return <Spinner />;
        }
        return (
            <Wrapper>
                <PageHeading title={`Import ${itemName}s`} />
                <Wrapper>
                    <Row>
                        <div className="col-sm-12">
                            <div className="ibox ">
                                <form className="ibox-content form" onSubmit={event => this._onSubmit(event)}>
                                    <div className="form__row">
                                        <p>
                                            The uploaded file should be a CSV. The first line is the header
                                            line and is ignored during the upload process. Below is an example or{" "}
                                            <Button
                                                className="btn brandPrimary--bg  btn--sm"
                                                onClick={e => fileDownload(csv, `${itemNameLower}-import-template.csv`)}>Preview
                                            </Button>
                                            {" "}
                                            to download a template.
                                            <br />
                                        </p>
                                    </div>
                                    <div className="form__row">
                                        <div
                                            className="form__group form__group--full"
                                            style={{
                                                backgroundColor: "#f3f3f3",
                                                padding: "10px",
                                                overflow: "scroll",
                                                marginBottom: "10px",
                                                width: "500px"
                                            }}
                                            tabIndex={0}
                                        >
                                            <p
                                                style={{
                                                    whiteSpace: "nowrap"
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: csv.replace(/\n/g, "<br />")
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {!loading && (
                                        <div className="form__row">
                                            <div className={`form__group ${!errors && "form__group--full"}`}>
                                                <label htmlFor="uploadCSV" className="form__label">Upload CSV:</label>
                                                <input
                                                    id="uploadCSV"
                                                    type="file"
                                                    ref={input => {
                                                        (this as dynamic).fileUpload = input; // TODO: bad
                                                    }}
                                                    className="form__value"
                                                    accept=".csv"
                                                />
                                                {this.props.note && <p style={{ marginTop: "10px" }}><span style={{ fontWeight: "bold", marginRight: "0.5em" }}>
                                                    Notes:</span>{this.props.note}
                                                </p>}
                                            </div>

                                            {errors && (
                                                <div id="uploadUsrErrs"className={`form__group`}>
                                                    <label className="form__label">
                                                        Your spreadsheet has errors, please fix them before you upload:
                                                    </label>
                                                    <p
                                                        style={{
                                                            maxHeight: "250px",
                                                            overflow: "scroll",
                                                            padding: "10px",
                                                            background: "#f1f1f1"
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: errors
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {loading && (
                                        <div className="form__row">
                                            <div className={`form__group form__group--full`}>
                                                <Spinner />
                                                <h3
                                                    style={{
                                                        width: "100%",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    Import Processing
                                                    <br />
                                                    <br />
                                                    Please do not close this page!
                                                    {inc && <>
                                                        <br />
                                                        Total Imported: {processed}/{total}
                                                    </>}
                                                </h3>
                                            </div>
                                        </div>
                                    )}
                                    <Button type="submit" value={`${loading ? "Loading" : `Import ${itemName}s`}`} />
                                </form>
                            </div>
                        </div>
                    </Row>
                </Wrapper>
            </Wrapper>
        );
    }
}
