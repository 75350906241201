import React, { Component, ReactText, CSSProperties } from "react";
import Tooltip from "../atoms/Tooltip";
import { getLocalISODate } from "../../utils";
import { createAlert } from "../../actions";
import DatePickerV2 from "../atoms/DatePickerV2";
import Spinner from "../atoms/Spinner";
import Button from "../atoms/Button";

// tslint:disable-next-line:no-submodule-imports
import debounce from "lodash/debounce";

export interface NumberChartData {
    [label: string]: {
        value: JSX.Element | string | ReactText;
        append?: string;
        label?: string[];
        secondLineSize?: string;
    };
}
interface INumberChartProps {
    title: string;
    tooltip?: JSX.Element;
    tooltipOffset?: CSSProperties;
    dropdownPosition?: "top" | "bottom";

    loading?: boolean;
    data?: NumberChartData;
    noDataLabel?: string;

    refresh?: (startDate: string, endDate: string) => Promise<NumberChartData>;
    download?: (startDate: string, endDate: string) => Promise<void>;
    downloadLabel?: string;
    defaultFilters?: dynamic;
    downloadLabelUpfront?: boolean;

    dateFilters?: boolean;
    triggerRefresh?: boolean;
    showSearchResults?: boolean;
}

interface INumberChartState {
    filtersOpen: boolean;
    filtersAnimating: boolean;
    filters: { startDate: string; endDate: string };
    data: NumberChartData | null;
    defaultFilters: dynamic;
    loading: boolean;
}

export default class NumberChart extends Component<INumberChartProps, INumberChartState> {
    refresh: () => Promise<void>;
    constructor(props: INumberChartProps) {
        super(props);
        const now = new Date();
        now.setMonth(now.getMonth() - 1);
        this.refresh = debounce(this._refresh, 250);
        this.state = {
            filtersOpen: false,
            filtersAnimating: false,
            filters: {
                startDate: getLocalISODate(now),
                endDate: getLocalISODate(new Date())
            },
            data: props.data || null,
            loading: false,
            defaultFilters: props.defaultFilters || {}
        };
    }

    componentWillReceiveProps(newProps: INumberChartProps) {
        const newState: Partial<INumberChartState> = {
            ...("data" in newProps ? { data: newProps.data } : null),
            ...("loading" in newProps ? { loading: newProps.loading } : null),
            ...("defaultFilters" in newProps ? { defaultFilters: newProps.defaultFilters } : null)
        };
        const defaultFilters = this.state.defaultFilters;
        if (Object.keys(newState).length) {
            this.setState(newState as INumberChartState, () => {
                if (
                    "defaultFilters" in newState &&
                    JSON.stringify(Object.values(newState.defaultFilters!)) !==
                    JSON.stringify(Object.values(defaultFilters))
                ) {
                    this.refresh();
                }
            });
        }
    }

    componentDidMount() {
        this._refresh();
    }

    async _refresh() {
        const { loading, filters } = this.state;
        if (this.props.refresh && !loading) {
            this.setState({ loading: true }, async () => {
                this.setState({
                    data: await this.props.refresh!(filters.startDate, filters.endDate),
                    loading: false
                });
            });
        }
    }

    renderFilters() {
        const { dateFilters, dropdownPosition } = this.props;
        if (!dateFilters) {
            return;
        }
        const { filters } = this.state;
        const { startDate, endDate } = filters;
        let minDate: Date | string = startDate ? new Date(`${startDate}T00:00:00.000Z`) : new Date();
        minDate.setDate(minDate.getDate() + 2);
        minDate = getLocalISODate(minDate);

        return (
            <div className={`row dropdown`}>
                <div className="content">
                    <div className="date__wrapper">
                        <label className="control-label" htmlFor="startDate">Start Date</label>
                        <DatePickerV2
                            id="startDate"
                            dateFormat="yyyy/MM/dd"
                            maxDate={endDate ? endDate : getLocalISODate(new Date())}
                            value={startDate}
                            calendarPlacement={dropdownPosition || `top`}
                            onChange={value => {
                                const date = value ? new Date(value) : new Date();
                                if (!value) {
                                    date.setMonth(date.getMonth() - 1);
                                }
                                this.setState({ filters: { ...filters, startDate: getLocalISODate(date) } });
                            }}
                            onBlur={() => this.setState({ filters: { ...filters, startDate: "" } })}
                            enableTabLoop={false}
                        />
                    </div>
                    <div className="date__wrapper" style={{ marginLeft: "10px" }}>
                        <label className="control-label" htmlFor="endDate">End Date</label>
                        <DatePickerV2
                            id="endDate"
                            dateFormat="yyyy/MM/dd"
                            minDate={minDate}
                            value={endDate}
                            calendarPlacement={dropdownPosition || `top`}
                            onChange={value => {
                                const date = value ? new Date(value) : new Date();
                                this.setState({ filters: { ...filters, endDate: getLocalISODate(date) } });
                            }}
                            onBlur={() => this.setState({ filters: { ...filters, endDate: "" } })}
                            enableTabLoop={false}
                        />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                        <label className="">&nbsp;</label>
                        <div style={{ display: "flex" }}>
                            <button
                                type="button"
                                className="btn brandPrimary--bg btn brandPrimary--bg "
                                style={{ marginLeft: "auto", marginRight: "20px" }}
                                onClick={() => this.toggleDropdown(() => this.refresh())}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    toggleDropdown(
        finishedCallback = () => {
            return;
        }
    ) {
        const { filtersOpen } = this.state;
        this.setState({ filtersOpen: !filtersOpen, filtersAnimating: true }, () =>
          setTimeout(() => {
            this.setState({ filtersAnimating: false }, finishedCallback);
            createAlert(`Dropdown ${filtersOpen ? 'closed' : 'opened'}`, 'success');
          }, 200)
        );
    }
    handleKeyDown(event) {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          this.toggleDropdown();
        }
    }
    render() {
        const { filters, filtersOpen, filtersAnimating } = this.state;
        const noDataLabel = this.props.noDataLabel ? this.props.noDataLabel : "";
        return (
            <div
                id={`${this.props.title}NumberChart`}
                className={`number-chart ibox number${filtersAnimating ? " animating" : ""}${
                    filtersOpen ? " open" : ""
                    }`}
            >
                <div className="ibox-title">
                    <h5 tabIndex={0}>{this.props.title}</h5>
                    <div className="ibox-tools">
                        <div className="filters">
                            {noDataLabel.length == 0 && this.props.download && this.props.downloadLabelUpfront && (
                                <Button
                                    className="btn--sm"
                                    style={{ marginRight: "10px",cursor: "pointer" }}
                                    onClick={() => this.props.download!(filters.startDate, filters.endDate)}
                                >
                                    <i className="fa fa-download" /> {this.props.downloadLabel ? this.props.downloadLabel : "Download"}
                                </Button>
                            )}
                            {noDataLabel.length == 0 && this.props.dateFilters && (
                                <div className="filter">
                                     <a
                                        className="brandPrimary--color__important"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => this.toggleDropdown()}
                                        onKeyDown={(event) => this.handleKeyDown(event)}
                                        tabIndex={0}
                                        >
                                        <i className="fa fa-filter" />
                                        &nbsp;<span>Filter</span>
                                    </a>
                                </div>
                            )}
                            {noDataLabel.length == 0 && this.props.download && !this.props.downloadLabelUpfront && (
                                <Button
                                    className="btn--sm"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => this.props.download!(filters.startDate, filters.endDate)}
                                >
                                    <i className="fa fa-download" /> {this.props.downloadLabel ? this.props.downloadLabel : "Download"}
                                </Button>
                            )}
                            {this.props.tooltip != null && <Tooltip place="left" offset={this.props.tooltipOffset ? this.props.tooltipOffset : {}} >
                                {this.props.tooltip}
                            </Tooltip>}
                        </div>
                    </div>
                    <i className="filterIcon fa fa-caret-up" />
                </div>
                {this.renderFilters()}
                {this._renderData()}
            </div>
        );
    }
    renderLabel(key: string, label?: string[], secondLineSize?: string) {
        if (label && label.length) {
            return (
                <small tabIndex={0}>
                    {label[0]}
                    {label.length > 1 ? <br /> : ""}
                    {label.length > 1 && !secondLineSize ? label[1] : ""}
                    {label.length > 1 && secondLineSize && secondLineSize.length > 1 ? (
                        <span style={{ fontSize: secondLineSize }}>{label[1]}</span>
                    ) : (
                            ""
                        )}
                </small>
            );
        }
        return <small>{key}</small>;
    }

    _renderData() {
        const { data, loading, filters } = this.state;
        const { noDataLabel } = this.props;
        // check if everything is 0
        // assumption is that if a value is an element it must be a percentcircle
        const hasData = data && Object.values(data).map(value => React.isValidElement(value.value) ? (value.value.props as dynamic).percent : value.value).some(value => Number(value));
        return (
            <div className="ibox-content">
                {!loading && (noDataLabel || !hasData) ? (
                    <div style={{ minHeight: "400px" }}>
                        <i>{noDataLabel || "Could not find any data for this report"}</i>
                    </div>
                ) : this.props.showSearchResults === false ? (
                    <div style={{ minHeight: "400px" }}>
                        <i>To run this report, please adjust the filters above and click "Search"</i>
                    </div>
                ) : (
                            <div>
                                <div
                                    className="reportCard__summary"
                                    style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}
                                >
                                    {loading && <Spinner />}
                                    {!loading &&
                                        data &&
                                        Object.keys(data).length > 0 &&
                                        Object.keys(data).map((key, index) => (
                                            <div key={index} className="reportCard__summary__section">
                                                <h3 className="brandPrimary--color">
                                                    <div className="value" tabIndex={0}>{data[key].value}</div>
                                                    {data[key].append ? <sup>{data[key].append}</sup> : ""}
                                                    {this.renderLabel(
                                                        key,
                                                        data[key].label,
                                                        data[key].secondLineSize ? data[key].secondLineSize : undefined
                                                    )}
                                                </h3>
                                            </div>
                                        ))}
                                </div>
                                {this.props.dateFilters && (
                                    <div className="content-footer">
                                        <span>{`${filters.startDate} - ${filters.endDate}`}</span>
                                    </div>
                                )}
                            </div>
                        )}
            </div>
        );
    }
}
