import React, { Component } from "react";
import Modal from "./Modal";
import EditableInput from "../../atoms/EditableInput";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import SocialLimits from "../../atoms/SocialLimits";
import LIMentionTypeaheadOptionsList from "../../molecules/LIMentionTypeaheadOptionsList";
import LIMentionedList from "../../molecules/LIMentionedList";
import debounce from "lodash/debounce";
import { doGetMentionsTypeahead } from "../../../actions";
import { validateMention, getCommentWithFields } from "../../pages/articles/sharedArticle";

export default class MultipleCommentsModal extends Component<
    {
        className?: string;
        disableSuggestions?: boolean;
        suggestedComments: string[];
        url: string;
        lang: string;
        open?: boolean;
        client?: Mongo.IClient;
        facebookMaxLength: number;
        linkedInMaxLength: number;
        twitterMaxLength: number;
        instaMaxLength: number;
        update: (
            comments: {
                comment: string;
                index: number;
            }[],
            liMentions: {
                commentIndex?: number;
                currentMentionToFill?: string;
                typeaheadOptions?: dynamic[];
                mentionsToAdd?: {
                    mention: string,
                    id: string,
                    notCompany?: boolean,
                    vanityName?: string
                }[];
            }[]
        ) => void;
        close: () => void;
        comment: string;
        quotes: string[];
        addedImage?: boolean;
        image?: string;
        sURLDomain: string;
        commentURL: string;
        commentURLLength?: number;
        suggestedCommentMentions?: Array<{ index: number, mentions: Mongo.ILinkedinMention[] }> | string;
        mentionsToAdd?: {
            mention: string,
            id: string,
            notCompany?: boolean,
            vanityName?: string
        }[];
        hashtagsAndMentions: string[];
    },
    {
        open: boolean;
        comments: string[];
        quotes: string[];
        liMentions: {
            commentIndex?: number;
            currentMentionToFill?: string;
            typeaheadOptions?: dynamic[];
            mentionsToAdd?: {
                mention: string,
                id: string,
                notCompany?: boolean,
                vanityName?: string
            }[];
        }[];
    }
> {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.state = {
            open: props.open || false,
            comments: [
                ...(props.suggestedComments && props.suggestedComments.length
                    ? props.suggestedComments.filter(i => i.length)
                    : []),
                "",
                "",
                ""
            ].slice(0, 3),
            quotes: this.props.quotes,
            liMentions: (typeof this.props.suggestedCommentMentions !== "string" && this.props.suggestedCommentMentions?.length) ? this.props.suggestedCommentMentions.map(mention => {
                return {
                    commentIndex: mention.index,
                    mentionsToAdd: mention.mentions
                }
            }) : []
        };
        if (this.props.comment) {
            this.updateComment(0, this.props.comment);
        }
    }

    componentWillReceiveProps(newProps) {
        if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open, quotes: newProps.quotes });
            if (newProps.comment) {
                this.updateComment(0, newProps.comment);
            }
            if (newProps.mentionsToAdd?.length) {
                const { liMentions } = this.state;
                const liMentionsIndex = liMentions.findIndex(item => item.commentIndex === 0);
                if (liMentionsIndex !== -1) {
                    liMentions[liMentionsIndex].mentionsToAdd = newProps.mentionsToAdd;
                } else {
                    liMentions.push({ mentionsToAdd: newProps.mentionsToAdd, commentIndex: 0 });
                }
                this.setState({ liMentions });
            }
        }
    }

    close() {
        this.setState({ open: false });
        if (this.props.close) {
            this.props.close();
        }
    }

    selectMention = (option: dynamic, index?: number) => {
        const { liMentions, comments } = this.state;
        const mention = {
            mention: option.name,
            id: option.member.split(":").slice(-1)[0],
            ...(option.notCompany ? { notCompany : true } : {}),
            ...(option.vanityName ? { vanityName : option.vanityName } : {})
        }
        const liMentionsIndex = liMentions.findIndex(item => item.commentIndex === index);
        const currentMentionToFill = liMentions[liMentionsIndex]?.currentMentionToFill || "";
        const comment = comments[index!];
        if (currentMentionToFill.length && comment.includes(currentMentionToFill + " ")) {
            this.updateComment(index!, comment.replace(currentMentionToFill, `@${mention.mention}`));
        } else if (currentMentionToFill.length) {
            this.updateComment(index!, comment.replace(currentMentionToFill, `@${mention.mention} `));
        }
        liMentions[liMentionsIndex].currentMentionToFill = "";
        liMentions[liMentionsIndex].typeaheadOptions = [];
        liMentions[liMentionsIndex].mentionsToAdd = [...(liMentions[liMentionsIndex].mentionsToAdd || []), mention];
        this.setState({ liMentions });
    }

    async updateComment(index: number, comment: string, event?: React.ChangeEvent<HTMLTextAreaElement>) {
        const { comments, liMentions } = this.state;
        const { client } = this.props;
        const liMentionsIndex = liMentions.findIndex(item => item.commentIndex === index);
        if (client?.socialNetworks.LinkedIn.available && client?.enableLinkedInMentions) {
            let mentionsToAdd = liMentions[liMentionsIndex]?.mentionsToAdd ?? [];
            const oldMentionsToAdd = mentionsToAdd;
            mentionsToAdd = (mentionsToAdd ?? []).filter(mention => {
                return comment.includes(`@${mention.mention}`);
            });
            if (mentionsToAdd?.length !== oldMentionsToAdd?.length) {
                if (liMentionsIndex !== -1) {
                    liMentions[liMentionsIndex].mentionsToAdd = mentionsToAdd;
                } else {
                    liMentions.push({ mentionsToAdd, commentIndex: index });
                }
                this.setState({ liMentions });
            }
            const textarea = event && event.target as HTMLTextAreaElement;
            if (textarea) {
                let lastTypedCharIndex = textarea.selectionStart === 0 ? textarea.selectionStart : textarea.selectionStart - 1;
                let textSelection = "";
                if (lastTypedCharIndex >= 0 && lastTypedCharIndex < textarea.value.length) {
                    textSelection = `${textarea.value[lastTypedCharIndex]}`;
                }
                while (lastTypedCharIndex > 0 && textSelection.split(" ").length <= 2) {
                    lastTypedCharIndex--;
                    textSelection = `${textarea.value[lastTypedCharIndex]}${textSelection}`;
                    if (textSelection[0] === '@') {
                        break;
                    }
                }
                if (textSelection[0] === '@') {
                    const slicedSelection = textSelection.slice(1);
                    if (slicedSelection.match(/[a-zA-Z.`-]+\s?[a-zA-Z.`-]+/) && validateMention(slicedSelection)){
                        const debouncedMentionsTypeahead = debounce(async () => {
                            await doGetMentionsTypeahead(slicedSelection).then(result => {
                                if (result?.length) {
                                    if (liMentionsIndex !== -1) {
                                        liMentions[liMentionsIndex].currentMentionToFill = textSelection;
                                        liMentions[liMentionsIndex].typeaheadOptions = result;
                                    } else {
                                        liMentions.push({currentMentionToFill: textSelection, typeaheadOptions: result, commentIndex: index});
                                    }
                                } else {
                                    if (liMentionsIndex !== -1) {
                                        liMentions[liMentionsIndex].currentMentionToFill = "";
                                        liMentions[liMentionsIndex].typeaheadOptions = [];
                                    } else {
                                        liMentions.push({currentMentionToFill: "", typeaheadOptions: [], commentIndex: index});
                                    }
                                }
                                this.setState({ liMentions });
                            })
                        }, 500);
                        await debouncedMentionsTypeahead();
                    } else {
                        if (liMentionsIndex !== -1) {
                            liMentions[liMentionsIndex].currentMentionToFill = "";
                            liMentions[liMentionsIndex].typeaheadOptions = [];
                        } else {
                            liMentions.push({currentMentionToFill: "", typeaheadOptions: [], commentIndex: index});
                        }
                        this.setState({ liMentions });
                    }
                }
            }
        }
        if (comments.length < index + 1) {
            comments.push(comment);
        } else {
            comments[index] = comment;
        }
        this.setState({ comments });
    }

    renderComment(index: number, comment: string) {
        const { quotes, liMentions } = this.state;
        const { client, facebookMaxLength, linkedInMaxLength, twitterMaxLength, url, lang, disableSuggestions, addedImage, image, sURLDomain, commentURL, hashtagsAndMentions} = this.props;
        const liMentionsIndex = liMentions.findIndex(item => item.commentIndex === index);
        return (
            <div className="form__row">
                            <div className="form__group form__group--full">
                                <label htmlFor="commentSuggestion" className="form__label">Comment Suggestion {index + 1}:</label>{" "}
                                {client && client.aiGeneratedAdminSuggestions && lang === "en" && !disableSuggestions && (
                                    <div className="select__wrapper" style={{ marginBottom: "10px" }}>
                                        <select
                                            id="commentSuggestion"
                                            onChange={event => {
                                                const value = (event.target.value || "").trim();
                                                if (value === "starters") {
                                                    this.updateComment(index, "");
                                                } else if (value !== "suggested") {
                                                    this.updateComment(index, value);
                                                }
                                            }}
                                            value="starters"
                                        >
                                            <option value="starters">Starters</option>
                                            {quotes && quotes.length > 0 ? quotes.map((suggestedComment, i) => (
                                                <optgroup label={`${i == 0 ? "Suggested Comments" : "---"}`}>
                                                    <option value={suggestedComment}>
                                                        {suggestedComment.slice(0, 70)}
                                                        {suggestedComment.length > 70 ? "..." : ""}
                                                    </option>
                                                </optgroup>
                                            )) : <optgroup label="...we found no Suggested Comments" />}
                                        </select>
                                    </div>
                                )}
                                <EditableInput
                                    id={`ai_comment_${index}`}
                                    value={comment}
                                    onChange={async event => await this.updateComment(index, event.target.value, event)}
                                    style={{ height: "100px", overflowY: "scroll" }}
                                    onOutsideClick={(e: dynamic<any>, id: string) => {
                                        if (!e.target.closest(".option-item") && (liMentions[liMentionsIndex]?.typeaheadOptions?.length || liMentions[liMentionsIndex]?.currentMentionToFill?.length)) {
                                            liMentions[liMentionsIndex].currentMentionToFill = "";
                                            liMentions[liMentionsIndex].typeaheadOptions = [];
                                            this.setState({ liMentions });
                                        }
                                    }}
                                />
                                <div>
                                    {liMentions[liMentionsIndex]?.mentionsToAdd?.length ? (
                                        <LIMentionedList mentions={liMentions[liMentionsIndex].mentionsToAdd || []} />
                                    ) : ""}
                                    {liMentions[liMentionsIndex]?.typeaheadOptions?.length ? (
                                        <LIMentionTypeaheadOptionsList options={liMentions[liMentionsIndex].typeaheadOptions || []} selectMention={this.selectMention} index={index} />
                                    ) : ""}
                                    <div style={{ display: "flex" }}>
                                        <Link
                                            style={{ marginLeft: "10px", textDecoration: "underline" }}
                                            onClick={() => this.updateComment(index, "")}
                                        >
                                            Clear
                                        </Link>
                                        <SocialLimits
                                            client={client}
                                            facebookMaxLength={facebookMaxLength}
                                            linkedInMaxLength={linkedInMaxLength}
                                            twitterMaxLength={twitterMaxLength}
                                            url={url}
                                            comment={getCommentWithFields(comment, commentURL, hashtagsAndMentions)}
                                            addedImage={addedImage}
                                            image={image}
                                            sURLDomain={sURLDomain}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
        );
    }

    render() {
        const { comments, liMentions } = this.state;
        const commentsWithIndices = comments.map((comment, index) => ({ comment, index }));
        return (
                <Modal
                    open={this.state.open}
                    className={this.props.className || ""}
                    title={`Comment suggestions`}
                    closeAction={this.close}
                >
                    {
                        <div>
                          <div>
                        {comments && comments.map((cmt, index) => this.renderComment(index, cmt))}
                        <div className="form__row">
                            <div
                                className="form__group form__group--full"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row"
                                }}
                            >
                                <Button
                                    id="multiple_comments"
                                    style={{ marginLeft: "auto" }}
                                    onClick={() => {
                                        if (this.props.update) {
                                            this.props.update(commentsWithIndices, liMentions);
                                        }
                                        this.close();
                                    }}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                          </div>
                        </div>
                    }

                </Modal>
            );
     }
}
