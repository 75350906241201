import BaseAction from "../utils/BaseAction";

export interface IUtilActions {
    doGetCountries(language: string): Promise<Array<{ code: string; name: string }>>;
    doGetAlerts(): Promise<{ cids: string[]; message: string } | null>;
    doGetBrand(): Promise<ServerTypes.Console.Settings.IBrand>;
    doGetWhiteLabelURL(): Promise<ServerTypes.Console.Settings.IURL>;
    doGetWhiteLabelSettings(): Promise<IWhitelabelSettings>;
    doGetDeepLinkUrl(): Promise<{ data: string }>;
}

export interface IUtilActions extends ClassInstance<typeof _UtilActions> { }

interface IWhitelabelSettings {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    adobeClientId: string;
    emailFrom: string;
}

class _UtilActions extends BaseAction {
    async doGetCountries(language?: string) {
        const data = await this.apiRequest<ServerTypes.Console.Settings.ICountries>(
            "/availableCountriesByLanguage",
            "GET",
            {
                ...(language ? { lang: language } : null)
            }
        );
        if (!data) {
            return [];
        }
        return data;
    }
    async doGetAlerts() {
        return this.apiRequest<ServerTypes.Console.Settings.IConsoleAlert>(`/getConsoleAlert`).catch(err => null);
    }

    async doSetAlerts(alert: string, cids: string[]) {
        this.apiRequest("/setConsoleAlert", "POST", {
            alert,
            cids
        }).then(data => this.createAlert("Successfully updated settings", "success"));
    }

    async doGetBrand() {
        return this.apiRequest<ServerTypes.Console.Settings.IBrand>(`/getBrand`);
    }

    async doGetWhiteLabelURL(configName: string) {
        return this.apiRequest<ServerTypes.Console.Settings.IURL>(`/getWhiteLabelURL`, 'GET', { configName });
    }



    async doGetWhiteLabelSettings(configName: string) {
        return this.apiRequest<IWhitelabelSettings>(`/getWhiteLabelSettings`, 'GET', { configName });
    }

    async doGetBlocklistedCountries() {
        return this.apiRequest<string[]>(`/settings/blocklistedCountries/get`);
    }

    async doSetBlocklistedCountries(countries: string[]) {
        return this.apiRequest(`/settings/blocklistedCountries/update`, `POST`, { countries }).then(data =>
            this.createAlert("Updated block listed countries", "success")
        );
    }

    async doGetDeepLinkUrl(embeddedClientAppId: string, embeddedClientExtensionUniqueId: string, embeddedClientURL: string, incomingUrl?: string) {
        return this.apiRequest(`/getDeepLinkUrl`, 'GET', { embeddedClientAppId, embeddedClientExtensionUniqueId, embeddedClientURL, incomingUrl });
    }
}

export const UtilActions = new _UtilActions();
