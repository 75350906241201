import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../../utils/store";

import ElementEditCard from "../../../molecules/ElementEditCard";
import { OfferCampaignActions, ClientActions, StreamActions, createAlert } from "../../../../actions";
import Spinner from "../../../atoms/Spinner";
import { OfferCampaign } from "../../../../utils/Models";
import { RouteComponentProps } from "react-router-dom";

interface IEditOfferProps extends RouteComponentProps<{ cid: string; ocid: string; }> {
    user: Mongo.clientAdmin;
}

const EditOffers = (props: IEditOfferProps) => {
    const isSuper = props.user.isSuper;
    const cid = isSuper ? props.match.params.cid : props.user.cid || "";
    const ocid = props.match.params.ocid || "";
    const [loaded, setLoaded] = useState(false);
    const [clients, setClients] = useState<ServerTypes.Console.ISelectClient[]>([]);
    const [streams, setStreams] = useState<ServerTypes.Console.ISelectStream[]>([]);
    const [image, setImage] = useState<File[] | null | undefined>();
    const [usedStreams, setUsedStreams] = useState<string[]>([]);
    const [offerToEdit, setOfferToEdit] = useState<ServerTypes.Console.IOfferCampaign | undefined>();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        setLoaded(false);

        const clients = await ClientActions.doList({}, ["cid", "name"]);
        setClients(clients.items);

        if (cid) {
            await setClient(cid);
        }

        setLoaded(true);
    };

    async function setClient(cid: string) {
        const streams = await StreamActions.doList(cid, {}, [
            "sid",
            "title",
            "active",
            "isCustom"
        ]);
        setStreams(streams.items.filter(a => a.active && a.isCustom).sort((a, b) => {
            return a.title.localeCompare(b.title)
        }));

        const offerCampaigns = await OfferCampaignActions.doList({ cid });
        const activeOfferStreams = offerCampaigns.items.reduce((current, item) => {
            // do not include the offer to be edited
            if (!ocid || ocid != item.ocid) {
                current = current.concat(
                    (item.active && item?.sids) ? item.sids : []
                );
            }
            return current;
        }, [] as string[]);
        setUsedStreams(activeOfferStreams);
        if (ocid) {
            setOfferToEdit(offerCampaigns.items.find(item => item.ocid == ocid))
        }
    }

    const _renderPreview = async (item: ServerTypes.Console.IOfferCampaign | null) => {
        try {
            if (item) {
                let html = await OfferCampaignActions.doGetPreview(item);
                if (html) {
                    // remove clickable abilities
                    html = html.replace(`href="javascript:gotoLink(0); "`, "").replace(`onClick="javascript:subscribeToMailingList()"`, "");
                }
                const newWindow = window.open("", "_blank");
                if (newWindow) newWindow.document.write(String(html));
            }
        } catch (err) {
            createAlert("Error" + err);
        }
    }

    const submit = async (item: ServerTypes.Console.IOfferCampaign) => {
        item["backgroundImage"] = image ? image[0] : undefined
        try {
            const success = await OfferCampaignActions.doUpsert(item);
            if (success) {
                setTimeout(() => {
                    history.push("/campaign/offers");
                }, 0);
            }
        } catch (err) {
            //
        }
    };

    if (!loaded) {
        return <Spinner />;
    }

    return (
        <ElementEditCard<ServerTypes.Console.IOfferCampaign>
            getItem={async () => OfferCampaign({ cid, ...offerToEdit })}
            canUpdate={() => Promise.resolve(true)}
            update={item => submit(item)}
            breadcrumbs={[{ name: "Offer Campaigns", link: "/campaign/offers" }]}
            elementName="Offer Campaigns"
            buttons={item => [{
                text: "Preview",
                icon: "fa-eye",
                onClick: (item) => _renderPreview(item || null),
            }]}
            isInsert={item => !item || !item.ocid}
            rows={[
                {
                    columns: [
                        { label: "Details", items: ["active", "name", "cid", "sids", "type"], key: 'OfferEdit_R1C1' },
                        { label: "Offer Trigger Criteria", items: ["count", "downloadLink"], key: 'OfferEdit_R1C2' }
                    ],
                    key: 'OfferEdit_Row1'
                },
                {
                    fullWidth: true,
                    columns: [
                        {
                            label: "Offer Content",
                            items: ["title", "body", "accept", "cancel", "disclaimer", "privacy", "backgroundImage"],
                            key: 'OfferEdit_R2C1'
                        }
                    ],
                    key: 'OfferEdit_Row2'
                },
                {
                    columns: [
                        {
                            label: "Offer Fields",
                            // items: ["firstName", "lastName", "email", "fieldsTitle", "company", "jobFunction", "country"],
                            items: ["firstName", "lastName", "email", "field0", "field1", "field2", "field3"],
                            key: 'OfferEdit_R2C3'
                        }
                    ],
                    key: 'OfferEdit_Row3'
                }
            ]}
            mandatoryFieldNote="* = mandatory"
            idField="ocid"
            editableKeys={item => {
                return {
                    active: {
                        label: "Enabled",
                        type: "toggle"
                    },
                    name: {
                        label: "Name",
                        required: true,
                        type: "text"
                    },
                    cid: {
                        label: "Client",
                        required: true,
                        type: "select",
                        disabled: !isSuper,
                        values: (clients || []).map(client => ({ label: client.name, value: client.cid })),
                        mutateChange: async (key, value) => {
                            await setClient(value as string);
                            return { cid: value as string };
                        }
                    },
                    sids: {
                        label: "Stream",
                        required: true,
                        type: "multi-select",
                        value: item.sids?.length ? item.sids :[],
                        disabled: !item.cid,
                        values: [
                            { label: "All Streams", value: "__ALL__", optionDisabled: usedStreams.length > 0 },
                            ...(streams || [])
                                .map(stream =>
                                    ({
                                        label: stream.title,
                                        value: stream.sid,
                                        optionDisabled: usedStreams.includes(stream.sid) || usedStreams.includes("__ALL__")
                                    })
                                )
                        ],
                        mutateChange: async (key, value) => {
                            if (Array.isArray(value)) {
                                if (value[value.length - 1] == "__ALL__") {
                                    return { [key]: ["__ALL__"] }
                                } else {
                                    return { [key]: value.filter(item => item != "__ALL__") }
                                }
                            }
                            return { [key]: value } as Partial<ServerTypes.Console.IOfferCampaign>;
                        }
                    },
                    type: {
                        label: "Type",
                        required: true,
                        value: item.requirements.type,
                        type: "select",
                        values: [
                            {
                                label: "Mailing List",
                                value: "mailing_list"
                            },
                            { label: "Download", value: "download" }
                        ],
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                requirements: { ...item.requirements, type: value }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    count: {
                        label: "Count",
                        required: true,
                        value: `${item.requirements.trigger.count}`,
                        type: "select",
                        // tslint:disable-next-line:ban
                        values: [...Array(10).keys()].map(val => ({ label: `${val + 1}`, value: `${val + 1}` })),
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                requirements: {
                                    ...item.requirements,
                                    trigger: { ...item.requirements.trigger, count: Number(value) }
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    // terms: {
                    //     label: "Term(s)",
                    //     value: item.requirements.trigger.terms || [],
                    //     type: "creatable",
                    //     mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                    //         ({
                    //             requirements: {
                    //                 ...item.requirements,
                    //                 trigger: { ...item.requirements.trigger, terms: value }
                    //             }
                    //         } as Partial<ServerTypes.Console.IOfferCampaign>)
                    // },
                    downloadLink: {
                        label: "Download Link",
                        required: true,
                        type: "text",
                        hidden: item.requirements.type !== "download"
                    },
                    title: {
                        label: "Title text",
                        required: true,
                        value: item.ui.title,
                        type: "text",
                        max: 50,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    title: value
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    body: {
                        label: "Body text",
                        required: true,
                        value: item.ui.body,
                        type: "textarea",
                        max: 250,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    body: value
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    accept: {
                        label: "Accept Button Text",
                        required: true,
                        value: item.ui.buttons.accept,
                        type: "text",
                        max: 50,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    buttons: { ...item.ui.buttons, accept: value }
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    cancel: {
                        label: "Cancel Button Text",
                        required: true,
                        value: item.ui.buttons.cancel,
                        type: "text",
                        max: 50,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    buttons: { ...item.ui.buttons, cancel: value }
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    disclaimer: {
                        label: "Disclaimer Text",
                        required: true,
                        value: item.ui.disclaimer,
                        type: "textarea",
                        max: 300,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    disclaimer: value
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    privacy: {
                        label: "Privacy URL",
                        value: item.ui.privacy_url,
                        type: "text",
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    privacy_url: value
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    backgroundImage:  {
                        label: "Replace the Default Background Image",
                        value: item.ui.background_image,
                        type: "image",
                        description: "A single image can be added. File must be .png or .jpeg. Image must have a minimum resolution of 1200*800 px.",
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    background_image: value
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>),
                        imageChange: (image: File[] | null | undefined) => {
                            setImage(image)
                        }
                    },
                    firstName: {
                        label: "First Name",
                        type: "toggle",
                        required: true,
                        disabled: true,
                        value: true
                    },
                    lastName: {
                        label: "Last Name",
                        type: "toggle",
                        required: true,
                        disabled: true,
                        value: true
                    },
                    email: {
                        label: "Email",
                        type: "toggle",
                        required: true,
                        disabled: true,
                        value: true
                    },
                    field0: {
                        label: "Field 1",
                        required: false,
                        value: item.ui.fields?.field0,
                        type: "text",
                        max: 50,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    fields: { ...item.ui.fields, field0: value }
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    field1: {
                        label: "Field 2",
                        required: false,
                        value: item.ui.fields?.field1,
                        type: "text",
                        max: 50,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    fields: { ...item.ui.fields, field1: value }
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    field2: {
                        label: "Field 3",
                        required: false,
                        value: item.ui.fields?.field2,
                        type: "text",
                        max: 50,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    fields: { ...item.ui.fields, field2: value }
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    },
                    field3: {
                        label: "Field 4",
                        required: false,
                        value: item.ui.fields?.field3,
                        type: "text",
                        max: 50,
                        mutateChange: async (key: string, value: string | boolean | string[] | Date) =>
                            ({
                                ui: {
                                    ...item.ui,
                                    fields: { ...item.ui.fields, field3: value }
                                }
                            } as Partial<ServerTypes.Console.IOfferCampaign>)
                    }
                };
            }}
        />
    );
};

class EditOfferCampaign extends Component<IEditOfferProps> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = true;
    render = () => <EditOffers {...this.props} />;
}
const mapStateToProps = state => ({
    user: state.session.admin
});

export default connect(mapStateToProps)(EditOfferCampaign);
