import BaseAction from "../utils/BaseAction";

export type IGroupActions = ClassInstance<typeof _GroupActions>;

export interface IGroupMemberCount {
    gid : string;
    en : number;
    fr : number;
    enMobile : number;
    frMobile : number;
}

class _GroupActions extends BaseAction {
    async doList(
        search: string,
        cid: string,
        limit?: number,
        skip?: number,
        sort?: { [key: string]: number }
    ): Promise<{ items: ServerTypes.Console.IGroup[]; total: number }> {
        const data = await this.apiRequestWithErrorAlert<ServerTypes.Console.IListResponse<ServerTypes.Console.IGroup>>(
            `/client/group/list`,
            `POST`,
            { search, cid, limit, skip, sort }
        );
        return {
            items: [],
            total: 0,
            ...(data ? { items: data.items, total: data.count } : null)
        };
    }
    async doUpsert(data: Partial<Mongo.IClientGroup> & { cid: string }) {
        const result = await this.apiRequestWithErrorAlert<{ gid: string }>(`/client/group/upsert`, `POST`, data);
        if (result) this.createAlert(`Successfully ${data.gid ? "updated" : "created"} group ${data.name}`, `success`);
        return !!result;
    }
    async doDelete(cid: string, gid: string) {
        const result = await this.apiRequestWithErrorAlert(`/client/group/delete`, `GET`, { cid, gid });
        if (result) this.createAlert(`Successfully deleted the group.`, `success`);
        return !!result;
    }
    async doSetDefault(cid: string, gid: string) {
        const result = await this.apiRequestWithErrorAlert(`/client/group/setDefault`, `POST`, { cid, gid });
        if (result) this.createAlert(`Successfully ${gid ? "" : "un"}set the default group.`, `success`);
        return !!result;
    }

    async doGetGroupMemberCount(cid: string, gid: string){
        const data = await this.apiRequestWithErrorAlert(`/client/group/member-size`, `POST`, { cid, gid }) as IGroupMemberCount;
        return data ? data : null;
    }
}

export const GroupActions = new _GroupActions();
