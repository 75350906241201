import { apiRequest, apiRequestWithErrorAlert } from "../utils/Helpers";
import { history } from "../utils/store";
import { createAlert } from "./utils";
import appConfig from "../../config/config.dev";

export function doUpsertCompany(company: Mongo.company) {
    return apiRequest(`${appConfig.API_URL}/company/upsert`, "post", company).then(data => {
        if (data.valid) {
            createAlert(`Successfully ${company.companyId == "" ? "added" : "updated"} ${company.name}`, `success`);
            setTimeout(() => {
                history.push("/companies");
            }, 0);
        } else {
            createAlert(data.error, `error`);
        }
    });
}

export function doUpsertIndustry(industry: Mongo.clientIndustries) {
    return apiRequest(`${appConfig.API_URL}/industry/upsert`, "post", industry).then(data => {
        if (data.valid) {
            createAlert(`Successfully ${industry.iid == null ? "added" : "updated"} ${industry.industry}`, `success`);
            setTimeout(() => {
                history.push("/industries");
            }, 0);
        } else {
            createAlert(data.error, `error`);
        }
    });
}

// unused
export function doUpsertNotification(notification: Mongo.INotification) {
    return apiRequest(`${appConfig.API_URL}/notification/upsert`, "post", notification).then(data => {
        if (data.valid) {
            createAlert(
                `Successfully ${notification.nid == null ? "added" : "updated"} ${notification.name}`,
                `success`
            );
            setTimeout(() => {
                history.push("/notifications");
            }, 0);
        } else {
            createAlert(data.error, `error`);
        }
    });
}

export function doUpsertEmailDrip(emailDrip: Mongo.IEmailDripCampaign) {
    return apiRequest(`${appConfig.API_URL}/emailDrip/upsert`, "post", emailDrip).then(data => {
        if (data.valid) {
            createAlert(`Successfully ${emailDrip.eid == null ? "added" : "updated"}`, `success`);
            setTimeout(() => {
                history.push("/campaign/emailDrip");
            }, 0);
        } else {
            createAlert(data.error, `error`);
        }
    });
}

export function doInsertClient(client: Mongo.client,
    imgFiles: File[],
    imgWeeklyFiles: File[]) {
    const $client = { ...client };
    const formData = new FormData();
    if (imgFiles) {
        imgFiles.forEach(file => formData.append("file", file));
        formData.append('imageType', 'imgFiles');
    }
    if (imgWeeklyFiles) {
        imgWeeklyFiles.forEach(file => formData.append("file", file));
        formData.append('imageType', 'imgWeeklyFiles');
    }
    formData.append('payload', JSON.stringify($client));
    formData.append('cid', $client.cid);

    return apiRequestWithErrorAlert(`${appConfig.API_URL}/client/upsert`, "post", formData).then((data: dynamic) => {
        if (!data) {
            return null;
        }
        if ("cid" in data) {
            createAlert(`Successfully added ${client.name}`, `success`);
        }
        return data;
    });
}

export function doUpdateClient(
    client: Mongo.IClient & { tags?: { [tid: string]: string | string[] } },
    respond = true,
    deleteReadOnlyContent = false,
    imgFiles: File[],
    imgWeeklyFiles: File[]
) {
    const $client = { ...client };
    if (Object.keys($client.tags || {}).length > 0) {
        const $tags = {};
        Object.keys($client.tags!).forEach(key => {
            if (key == `0`) {
                ($client.tags![key] as string[]).forEach(newItem => {
                    $tags[newItem] = 0;
                });
            } else {
                $tags[$client.tags![key] as string] = key;
            }
        });
        $client.tags = $tags;
    }
    const formData = new FormData();
    if (imgFiles) {
        imgFiles.forEach(file => formData.append("file", file));
        formData.append('imageType', 'imgFiles');
    }
    if (imgWeeklyFiles) {
        imgWeeklyFiles.forEach(file => formData.append("file", file));
        formData.append('imageType', 'imgWeeklyFiles');
    }
    formData.append('payload', JSON.stringify($client));
    formData.append('cid', $client.cid);

    return apiRequest(`${appConfig.API_URL}/client/upsert`, "post", formData).then((data: dynamic) => {
        if (data.valid) {
            if (respond) {
                if (deleteReadOnlyContent) {
                    createAlert(`Successfully updated ${client.name} and deleted all read only content`, `success`);
                } else {
                    createAlert(`Successfully updated ${client.name}`, `success`);
                }
            }
        } else {
            createAlert(data.error, `error`);
        }
        return data;
    });
}

export function doUpsertOnboardingIntroVideo(video: FormData) {
    return apiRequestWithErrorAlert(
        `${appConfig.API_URL}/client/onboardingIntroVideo/upsert`, "post", video).then((data: dynamic) => {
            if (data.valid) {
                return data;
            }
            return null;
        });
}

export function doUpdateHashtags(client: Mongo.IClient, respond = true) {
    const { cid, hashtags } = client;
    return apiRequest(`${appConfig.API_URL}/client/hashtags/upsert`, "post", { cid, hashtags }).then(
        (data: dynamic) => {
            if (data.valid) {
                if (respond) {
                    createAlert(`Successfully updated hashtags for ${client.name}`, `success`);
                }
            } else {
                createAlert(data.error, `error`);
            }
            return data;
        }
    );
}

export function doUpdateMentions(client: Mongo.IClient, respond = true) {
    const { cid, mentions } = client;
    return apiRequest(`${appConfig.API_URL}/client/mentions/upsert`, "post", { cid, mentions }).then(
        (data: dynamic) => {
            if (data.valid) {
                if (respond) {
                    createAlert(`Successfully updated mentions for ${client.name}`, `success`);
                }
            } else {
                createAlert(data.error, `error`);
            }
            return data;
        }
    );
}

export function doUpdateTypeaheadCompanyPages(client: Mongo.IClient, respond = true) {
    const { cid, typeaheadCompanyPages } = client;
    return apiRequest(`${appConfig.API_URL}/client/typeaheadCompanyPages/upsert`, "post", { cid, typeaheadCompanyPages }).then(
        (data: dynamic) => {
            if (data.valid) {
                if (respond) {
                    createAlert(`Successfully updated typeahead company pages for ${client.name}`, `success`);
                }
            } else {
                createAlert(data.error, `error`);
            }
            return data;
        }
    );
}

export function doUpdateTerms(client: Mongo.IClient, respond = true) {
    const { cid, terms } = client;
    return apiRequest(`${appConfig.API_URL}/client/terms/upsert`, "post", { cid, terms }).then((data: dynamic) => {
        if (data.valid) {
            if (respond) {
                createAlert(`Successfully updated terms for ${client.name}`, `success`);
            }
        } else {
            createAlert(data.error, `error`);
        }
        return data;
    });
}

export function doUpdateBlocklistedUrls(client: Mongo.IClient, respond = true) {
    const { cid, blocklistedUrls } = client;
    return apiRequest(`${appConfig.API_URL}/client/blocklistedurls/upsert`, "post", { cid, blocklistedUrls }).then(
        (data: dynamic) => {
            if (data.valid) {
                if (respond) {
                    createAlert(`Successfully updated Blocklisted URLs for ${client.name}`, `success`);
                }
            } else {
                createAlert(data.error, `error`);
            }
            return data;
        }
    );
}

export function doInsertStream(cid: string, stream: Mongo.IStream & { tags?: string[] }) {
    const data = {
        cid,
        stream,
        streamTags: stream.tags && stream.tags.length > 0 && stream.tags[0] != "" ? stream.tags : []
    };
    if (data.stream.tags) {
        delete data.stream["tags"];
    }
    return apiRequest(`${appConfig.API_URL}/insertStream`, "post", data).then(data => {
        if (data.valid) {
            createAlert(`Successfully added ${stream.title}`, `success`);
        } else {
            createAlert(data.error, `error`);
        }
        return data;
    });
}
export function doUpdateStream(cid: string, streamT: ServerTypes.Console.IStream & { tags?: string[] }, silent = false) {
    const { tags, ...stream } = streamT;
    const data = {
        cid,
        stream,
        streamTags: tags && tags.length > 0 && tags[0] != "" ? tags : []
    };
    return apiRequest(`${appConfig.API_URL}/updateStream`, "post", data).then(result => {
        if (result.valid) {
            if (!silent) {
                createAlert(`Successfully updated ${stream.title}`, `success`);
            }
        } else {
            createAlert(result.error, `error`);
        }
        return result;
    });
}

export function doInsertArticle(article: Mongo.clientArticles) {
    return apiRequest(`${appConfig.API_URL}/insertArticleForClientStream`, "post", article).then(data => {
        if (data.valid) {
            createAlert(`Successfully added ${article.Title}`, `success`);
        } else {
            createAlert(data.error, `error`);
        }
    });
}

export function doUpdateUser(user: Partial<Mongo.IClientUser>, output = true) {
    return apiRequest(`${appConfig.API_URL}/clientuser/upsert`, "post", user).then((data: dynamic) => {
        if (data.valid) {
            if (output) {
                createAlert("User has been successfully edited", "success");
            }
        } else {
            createAlert(data.error);
        }
        return data;
    });
}

export function doBanUser(uuid: string) {
    return apiRequest(`${appConfig.API_URL}/banUser`, "POST", { uuid });
}

export function doUnbanUser(uuid: string) {
    return apiRequest(`${appConfig.API_URL}/unbanUser`, "POST", { uuid });
}

export function doUpgradeUserToExpert(uuid: string) {
    return apiRequest(`${appConfig.API_URL}/upgradeUserToExpert`, "POST", { uuid });
}

export function doDowngradeUserFromExpert(uuid: string) {
    return apiRequest(`${appConfig.API_URL}/downgradeUserFromExpert`, "POST", { uuid });
}

export function doDeleteUser(uuid: string) {
    return apiRequest(`${appConfig.API_URL}/deleteConsumer`, "POST", { uuid });
}
