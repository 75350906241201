import React, { Component } from "react";
import Spinner from "../../atoms/Spinner";
import Button from "../../atoms/Button";
import { doLoadArticle, createAlert, doGetQuotes } from "../../../actions";
import Link from "../../atoms/Link";

interface IFindArticleModalProps {
    client: Mongo.IClient;
    allowCustomEdit: Mongo.IClient["allowCustomEdit"];
    stream: Mongo.IStream;
    submit: (article: Partial<ServerTypes.Console.IArticle>) => void;
}

interface IFindArticleModalState {
    loading: boolean;
    url: string;
    article: Partial<ServerTypes.Console.IArticle> | null;
    suggestedLink: string;
    quotes?: string[];
}
export default class FindArticleModal extends Component<IFindArticleModalProps, IFindArticleModalState> {
    constructor(props: IFindArticleModalProps) {
        super(props);

        this.state = {
            loading: false,
            url: "",
            article: null,
            suggestedLink: ""
        };
    }

    async loadSuggestedComments(link: string, gpt: boolean, cid: string) {
        const { stream } = this.props;
        if (this.state.suggestedLink && this.state.suggestedLink === link) {
            return;
        }
        this.setState({ suggestedLink: link });
        const suggestedQuotes = await doGetQuotes(link, stream ? stream.lang : "en", gpt, cid);
        return suggestedQuotes && !("valid" in suggestedQuotes) && suggestedQuotes.numFound
            ? suggestedQuotes.items.map(i => i.quote)
            : [];
    }

    async load() {
        let { url } = this.state;
        if (!url) {
            return;
        }
        if (url.indexOf("http") === -1) {
            url = `http://${url.trim()}`;
        }
        this.setState({ loading: true });
        let analyzedArticle: dynamic | null = null;
        let duplicate = false;
        analyzedArticle = await doLoadArticle(url);
        if (!analyzedArticle) {
            this.setState({ loading: false });
            return;
        } else if (analyzedArticle.error === "DUPLICATE_ARTICLE_LINK") {
            if (
                    !confirm(
                        "This article already exists in your library, are you sure you want to re-add it?"
                    )
                ) {
                    this.setState({ loading: false });
                    return;
                }
                analyzedArticle = await doLoadArticle(url, true);
                if (!analyzedArticle) {
                    this.setState({ loading: false });
                    return;
                }
                duplicate = true;
                if (analyzedArticle.error) {
                    createAlert(analyzedArticle.error, `error`);
                    this.setState({ loading: false });
                    return;
                }
        }
        const article = analyzedArticle as Partial<ServerTypes.Console.IArticle>;
        if (article.Title && article.Title.length > 200) {
            article.Title = `${article.Title.substring(0, 200).trim()}...`;
        }
        const quotes = [];

        article.Terms =
            article.Content && article.Content.constructor === Array
                ? ((article.Content as unknown) as string[]) // typescript doesnt like checking the constructor to make sure its an array
                : (article.Content || "")
                      .split(",")
                      .map(item => item.substring(0, 50).trim())
                      .filter(i => i);

        this.setState({ article: { ...article, ...(duplicate ? { duplicate } : null) }, quotes }, () =>
            this.submitArticle()
        );
    }

    submitArticle() {
        this.props.submit(this.state.article!);
    }

    render() {
        const { loading, url } = this.state;
        const { allowCustomEdit } = this.props;
        if (loading) {
            return (
                <div>
                    <h3
                        style={{
                            textAlign: "center"
                        }}
                    >
                        Loading article from source...
                    </h3>
                    <Spinner />
                </div>
            );
        }

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flexBasis: "100%",
                        justifyContent: "center"
                    }}
                >
                    <label htmlFor="oldPass" style={{textAlign: "left"}}>Article URL</label>
                    <input
                        style={{
                            marginBottom: "10px"
                        }}
                        id="url"
                        type="text"
                        value={url}
                        onChange={event => this.setState({ url: event.target.value })}
                        placeholder="Paste article URL here"
                    />
                    <Button disabled={this.state.loading} onClick={() => this.load()}>
                        Load Article
                    </Button>
                    {allowCustomEdit &&
                        <Link
                            style={{ textDecoration: "underline", padding: "5px 0px" }}
                            to={`/content/articles/c/${this.props.client.cid}`}
                        >
                            Manually create an article
                        </Link>
                    }
                </div>
            </div>
        );
    }
}
