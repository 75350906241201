import BaseAction from "../utils/BaseAction";

export type IClientAdmin = Mongo.clientAdmin & { _id?: ObjectID; lastLogin: Date };
export interface IAdminActions {
    doGet(aid: string): Promise<IClientAdmin | null>;
    doList(query: {
        sort?: { [key: string]: number };
        skip?: number;
        limit?: number;
        search?: string;
        cid?: string;
        groups?: string[];
    }): Promise<{ items: IClientAdmin[]; total: number }>;
    doUpsert(admin: IClientAdmin): Promise<void>;
    doToggleDisabled(aid: string): Promise<void>;
    doExportCSV(query: { cid?: string | null; groups?: string[]; searchTerm?: string }): Promise<void>;
    doSaveAICommentPref(aiCommentPref: Mongo.ClientAdmin["aiCommentPref"]): Promise<void>;
}

class _AdminActions extends BaseAction implements IAdminActions {
    async doGet(aid: string) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; item: IClientAdmin }>(
            `/clientadmin/get`,
            "POST",
            { aid }
        );
        if (data && data.item) {
            return data.item;
        }
        return null;
    }

    async doList(query: {
        cid?: string | null;
        groups?: string[];
        search?: string;
        sort?: { [key: string]: number };
        skip?: number;
        limit?: number;
    }) {
        try {
            const data = await this.apiRequestWithErrorAlert<{ items: IClientAdmin[]; count: number }>(
                `/clientadmin/list`,
                `POST`,
                { ...query },
                undefined,
                true
            );
            if (data && data.items && data.items.length) {
                return { items: data.items, total: data.count };
            }
        } catch (error) {
            //
        }
        return { items: [], total: 0 } as { items: IClientAdmin[]; total: number };
    }

    async doUpsert(admin: Mongo.ClientAdmin) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean }>(
            `/clientadmin/upsert`,
            "post",
            admin,
            undefined,
            true
        );
        if (!data || !data.valid) {
            throw new Error("Upsert failed");
        }
    }

    async doToggleDisabled(aid: string) {
        await this.apiRequestWithErrorAlert<{ valid: boolean }>(`/clientadmin/toggleDisabled`, "POST", { aid }, undefined, true);
    }

    async doDelete(cid: string | undefined, aid: string) {
        return await this.apiRequestWithErrorAlert<{ valid: boolean }>(`/clientadmin/delete`, "POST", { cid, aid }, undefined, true);
    }

    async doExportCSV(query: { cid?: string | null; gids?: string[]; search?: string, permission?: boolean, isDeactivated?: boolean, gidsFilter?: string| string[] }) {
        const data = await this.apiRequest("/clientadmin/csv/export", "POST", { ...query });
        if (data) {
            return this.downloadFile("adminreport", data);
        }
    }

    async doGetOAuth(aid: string) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; oauth: IClientAdmin["oauth"] }>(`/clientadmin/getOAuth`, "GET");
        return data?.oauth;
    }

    async doSaveAICommentPref(aiCommentPref: Mongo.ClientAdmin["aiCommentPref"]) {
        await this.apiRequestWithErrorAlert<{ valid: boolean }>(`/saveAICommentPref`, "POST", { aiCommentPref }, undefined, true);
    }

    async doGetAICommentPref() {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; aiCommentPref: IClientAdmin["aiCommentPref"] }>(`/getAICommentPref`, "GET");
        return data?.aiCommentPref;
    }
}

export const AdminActions = new _AdminActions();
