import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from 'react-router-dom';

import { doLogout } from "../../actions/session";
import PasswordReset from "./PasswordReset";
import Link from "../atoms/Link";
import Logo from "../atoms/Logo";

interface INavigationProps {
    user: Mongo.clientAdmin;
    clients: dynamic<Mongo.client>;
    name: string;
    location: RouteComponentProps["location"]; // actually passed in as a prop
}

interface INavigationState {
    resetPassword: boolean;
    isSSO: boolean;
}

class Navigation extends Component<INavigationProps, INavigationState> {
    constructor(props: INavigationProps) {
        super(props);
        this._onLogout = this._onLogout.bind(this);
        this._openPasswordReset = this._openPasswordReset.bind(this);
        this._closePasswordReset = this._closePasswordReset.bind(this);
        this.state = {
            resetPassword: false,
            isSSO: !!localStorage.getItem("ssoClient"),
        };
    }

    componentDidMount() {
        if (this.props.user && "isInitial" in this.props.user && this.props.user.isInitial) {
            this._openPasswordReset();
        }
        const { menu } = this.refs;
        $(menu).metisMenu({ toggle: false, preventDefault: false });
    }

    _onLogout() {
        return doLogout();
    }

    _openPasswordReset() {
        this.setState({ resetPassword: true });
    }

    _closePasswordReset() {
        this.setState({ resetPassword: false });
    }

    activeRoute(routeName: string) {
        if (!(window.location.href.indexOf("reports") > -1)){
            localStorage.removeItem('reportQuery');
        }
        return `brandPrimary--border ${this.props.location.pathname.indexOf(routeName) === 0 ? "active" : ""}`;
    }

    compliancePartnerLink(compliancePartner: Mongo.IClientCompliance | undefined): string {
        if (compliancePartner?.vendor === "ng") {
            return "https://suite.nexgate.com"
        }

        return ""
    }

    secondLevelActive(routeName: string) {
        return this.props.location.pathname.indexOf(routeName) === 0
            ? "nav nav-second-level collapse in"
            : "nav nav-second-level collapse";
    }

    getPermission(admin: Mongo.clientAdmin) {
        if (admin.isSuper) {
            return "Super Admin";
        } else if (admin.isApiAdmin) {
            return "Feed Admin";
        } else if (admin.isSuperClientAdmin) {
            return "Super Client Admin";
        } else if (admin.isClientAdmin) {
            return "Client Admin";
        } else if (admin.isCurator && admin.isAdmin) {
            return "Curator Admin, User Admin";
        } else if (admin.isCurator && admin.isReports){
            return "Curator + Reports Admin"
        } else if (admin.isCurator) {
            return "Curator Admin";
        } else if (admin.isAdmin) {
            return "User Admin";
        } else if (admin.isReports) {
            return "Reports Admin";
        }
        return "N/A";
    }

    _leadsReportViewer(user: Mongo.clientAdmin, client?: Mongo.client) {
        if (
            //prospect leads and offer leads can be viewed by report admins and curator admins
            ((!user.isAdmin || (user.isCurator && user.isAdmin)) && (!client || (client && (client.allowOffers || client.allowProspect)))) ||
            //social interactions leads can be viewed by Report Admins, client admins and above
            ((user.isSuperClientAdmin || user.isClientAdmin || user.isReports) && client && client.allowSocialInteractionCapture)
        ) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { user, clients } = this.props;
        const client = clients[user.cid!];
        const streams = client && client.streams || null;
        let hasStreamSub = false;
        if(streams){
            for(const sid in streams){
                const stream = streams[sid];
                if(stream.allowAutopost){
                    hasStreamSub = stream.allowAutopost;
                }
            }
        }

        return (
            <nav className="navbar-default navbar-static-side" role="navigation" style={{ overflowY: "auto" }}>
                {this.state.resetPassword && (
                    <PasswordReset closeAction={this._closePasswordReset} aid={user.aid} cid={user.cid} />
                )}
                <ul className="nav metismenu" id="side-menu" ref="menu">
                    <li className="nav-header">
                        <div className="dropdown profile-element">
                            <div>
                                <Logo className="logo" />
                                <span className="text-white logo__text">&nbsp; Console</span>
                                {/* {this.props.name} */}
                            </div>
                            <br />
                            <Link data-toggle="dropdown" className="dropdown-toggle" href="#">
                                <span className="clear">
                                    <span className="block m-t-xs">
                                        {this.props.clients && this.props.clients[user.cid!] && (
                                            <span className="block clientName">{this.props.clients[user.cid!]!.name}</span>
                                        )}
                                        <strong className="font-bold adminName">{user.name}</strong>
                                    </span>
                                    <span className="text-muted text-xs block">
                                        {this.getPermission(user)}
                                        <b className="caret" />
                                    </span>
                                </span>
                            </Link>
                            <ul className="dropdown-menu animated fadeInRight m-t-xs">
                                {!this.state.isSSO && <li>
                                    <Link onClick={this._openPasswordReset}>Change Password</Link>
                                </li>}
                                <li>
                                    <Link onClick={this._onLogout}>Logout</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="logo-element">
                            <Logo className="logo" />
                        </div>
                    </li>
                    {!user.isApiAdmin && (
                        <li className={this.activeRoute("/reports")}>
                            <Link to="/reports/engagement">
                                <i className="fa fa-bar-chart-o" />
                                <span className="nav-label">Reports</span>
                                <span className="fa arrow" />
                            </Link>
                            <ul className={this.secondLevelActive("/reports/")}>
                                <li className={this.activeRoute("/reports/engagement")}>
                                    <Link to="/reports/engagement">Engagement</Link>
                                </li>
                                <li className={this.activeRoute("/reports/insights")}>
                                    <Link to="/reports/insights">Insights</Link>
                                </li>
                                <li className={this.activeRoute("/reports/networks")}>
                                    <Link to="/reports/networks">Networks &amp; Streams</Link>
                                </li>
                                <li className={this.activeRoute("/reports/articles")}>
                                    <Link to="/reports/articles">Article Performance</Link>
                                </li>
                                <li className={this.activeRoute("/reports/media")}>
                                    <Link to="/reports/media">Media Performance</Link>
                                </li>
                                <li className={this.activeRoute("/reports/user")}>
                                        <Link to="/reports/user">User Sharing</Link>
                                    </li>
                                {this._leadsReportViewer(user, client) && (
                                        <li className={this.activeRoute("/reports/leads")}>
                                            <Link to="/reports/leads">Leads</Link>
                                        </li>
                                    )}
                                {(user.isSuper || user.isSuperClientAdmin || user.isClientAdmin) && (
                                    <li className={this.activeRoute("/reports/notifications")}>
                                        <Link to="/reports/notifications">Communication Log</Link>
                                    </li>
                                )}
                                <li className={this.activeRoute("/reports/ssologs")}>
                                    <Link to="/reports/ssologs">SSO Log</Link>
                                </li>
                                {(user.isSuper || user.isSuperClientAdmin) && (
                                    <li className={this.activeRoute("/reports/adoption")}>
                                        <Link to="/reports/adoption">Adoption</Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {(user.isSuper || user.isClientAdmin || user.isSuperClientAdmin || user.isCurator) && (
                        <li className={this.activeRoute("/content")}>
                            <Link to="/content/l">
                                <i className="ion ion-android-list" />
                                <span className="nav-label">Content</span>
                                <span className="fa arrow" />
                            </Link>
                            <ul className={this.secondLevelActive("/content")}>
                                <li className={this.activeRoute("/content/l")}>
                                    <Link to="/content/l">Library</Link>
                                </li>
                                {(client && client.allowCustomEdit && (
                                    <li className={this.activeRoute("/content/articles/c")}>
                                        <Link to={`/content/articles/c/${client.cid}/options`}>Create Article</Link>
                                    </li>
                                ))}
                                {(client && client.allowCustomEdit && (
                                    <li className={this.activeRoute("/content/media/c")}>
                                        <Link to={`/content/media/c/${client.cid}`}>Create Media/Text</Link>
                                    </li>
                                ))}
                                {!user.isSuper && (
                                    <li className={this.activeRoute("/content/s")}>
                                        <Link to="/content/s">Content Search</Link>
                                    </li>
                                )}
                                {(user.isSuperClientAdmin || user.isClientAdmin) && (
                                    <li className={this.activeRoute("/content/c")}>
                                        <Link to="/content/c">Stream Configuration</Link>
                                    </li>
                                )}
                                {(user.isSuperClientAdmin || user.isClientAdmin) && (
                                    <li className={this.activeRoute("/content/df")}>
                                        <Link to="/content/df">Global Domain Filters</Link>
                                    </li>
                                )}
                                {!user.isCurator && (
                                    <li className={this.activeRoute("/content/hl")}>
                                        <Link to="/content/hl">Hashtags &amp; Mentions</Link>
                                    </li>
                                )}
                                {!user.isCurator && (
                                    <li className={this.activeRoute("/content/tl")}>
                                        <Link to="/content/tl">Terms</Link>
                                    </li>
                                )}
                                {(!user.isCurator && !user.isSuper) && (
                                    <li className={this.activeRoute(`/content/ts/${user.cid}`)}>
                                        <Link to={`/content/ts/${user.cid}`}>Content Tags</Link>
                                    </li>
                                )}
                                {client?.compliance?.vendor !== undefined && (
                                    <li>
                                        <a href={this.compliancePartnerLink(client?.compliance)} target="_blank">Compliance Partner <i className="fa fa-external-link"></i></a>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {(user.isSuper ||
                        ((user.isSuperClientAdmin || user.isClientAdmin || user.isCurator || user.isReports) &&
                            (client && client.allowECommunication))) && (
                        <li className={this.activeRoute("/ecommunication")}>
                            <Link to={!user.isReports ? "/ecommunication/messages" : "/ecommunication/dashboard"}>
                                <i className="ion ion-android-list" />
                                <span className="nav-label">e-Communication</span>
                                <span className="fa arrow" />
                            </Link>
                            <ul className={this.secondLevelActive("/ecommunication")}>
                                {
                                    !user.isReports && <>
                                        <li className={this.activeRoute("/ecommunication/messages")}>
                                            <Link to="/ecommunication/messages"> Messages</Link>
                                        </li>
                                        <li className={this.activeRoute("/ecommunication/subscribables")}>
                                            <Link to="/ecommunication/subscribables"> Subscriptions</Link>
                                        </li>
                                        <li className={this.activeRoute("/ecommunication/categories")}>
                                            <Link to="/ecommunication/categories"> Categories</Link>
                                        </li>
                                    </>
                                }
                                <li className={this.activeRoute("/ecommunication/dashboard")}>
                                    <Link to="/ecommunication/dashboard"> Dashboard</Link>
                                </li>
                                <li className={this.activeRoute("/ecommunication/subjectLinePerformance")}>
                                    <Link to="/ecommunication/subjectLinePerformance"> Subject Line Performance</Link>
                                </li>
                            </ul>
                        </li>
                    )}
                    {(user.isSuper ||
                        (user.isClientAdmin || user.isSuperClientAdmin) && client && client.smsProvider) && (
                        <li className={this.activeRoute("/sms")}>
                            <Link to={`/sms/reports`}>
                                <i className="ion ion-chatboxes" />
                                <span className="nav-label">SMS</span>
                                <span className="fa arrow" />
                            </Link>
                            <ul className={this.secondLevelActive("/sms")}>
                                <li className={this.activeRoute("sms/reports")}>
                                    <Link to="/sms/reports">Reports</Link>
                                </li>
                                {!user.isSuper && <>
                                    <li className={this.activeRoute("/ecommunication/categories")}>
                                        <Link to="/sms/contactImport"> Import / Export</Link>
                                    </li>
                                    </>
                                }
                            </ul>
                        </li>
                    )}
                    {(user.isSuper || (user.isSuperClientAdmin || user.isClientAdmin || user.isAdmin)) && (
                        <li className={this.activeRoute(`/roles`)}>
                            <Link to={`/roles`}>
                                <i className="fa fa-certificate" />
                                <span className="nav-label">Roles</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isClientAdmin || user.isSuperClientAdmin || user.isAdmin) && (
                        <li className={this.activeRoute("/users")}>
                            <Link to="/users">
                                <i className="fa fa-users" />
                                <span className="nav-label">Users</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isClientAdmin || user.isSuperClientAdmin) && (
                        <li className={this.activeRoute("/admins")}>
                            <Link to="/admins">
                                <i className="fa fa-unlock-alt" />
                                <span className="nav-label">Admins</span>
                            </Link>
                        </li>
                    )}
                    {(user.isClientAdmin || user.isSuperClientAdmin) && (
                        <li className={this.activeRoute("/groups")}>
                            <Link to="/groups">
                                <i className="fa fa-handshake-o" />
                                <span className="nav-label">Groups</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper ||
                        ((user.isClientAdmin || user.isSuperClientAdmin || user.isAdmin) && client && client.allowDelegation)) && (
                        <li className={this.activeRoute("/delegation")}>
                            <Link to={`/delegation/dashboard`}>
                                <i className="ion ion-android-contacts" />
                                <span className="nav-label">Delegation</span>
                                <span className="fa arrow" />
                            </Link>
                            <ul className={this.secondLevelActive("/delegation")}>
                                <li className={this.activeRoute("/delegation/dashboard")}>
                                    <Link to="/delegation/dashboard">Dashboard</Link>
                                </li>
                                <li className={this.activeRoute("/delegation/pending")}>
                                    <Link to="/delegation/pending">Pending Delegates</Link>
                                </li>
                                <li className={this.activeRoute("/delegation/approved")}>
                                    <Link to="/delegation/approved">Approved Delegates</Link>
                                </li>
                            </ul>
                        </li>
                    )}
                    {user.isReports && client && client.allowDelegation && (
                        <li className={this.activeRoute("/delegation")}>
                            <Link to="/delegation/dashboard">
                                <i className="ion ion-android-contacts" />
                                <span className="nav-label">Delegation</span>
                            </Link>
                        </li>
                    )}

                    {(user.isSuper || user.isSuperClientAdmin || user.isClientAdmin) &&
                        (!client || (client && client.allowNotifications)) &&
                        (
                            <li className={this.activeRoute("/notifications")}>
                                <Link to="/notifications">
                                    <i className="ion ion-android-notifications" />
                                    <span className="nav-label">Notifications</span>
                                </Link>
                            </li>
                        )}


                    {(user.isSuper || user.isReports ||
                        user.isSuperClientAdmin ||
                            user.isClientAdmin ||
                                (client && client.allowAutopost) && !user.isCurator) && (
                        <li className={this.activeRoute("/campaign/")}>
                            <Link
                                to={
                                    (user.isSuper || user.isSuperClientAdmin || user.isReports)
                                        ? "/campaign/emailDrip"
                                        : client && client.allowAutopost && user.isClientAdmin
                                        ? "/campaign/streamSubscription"
                                        : "/campaign"
                                }
                            >
                                <i className="ion ion-speakerphone" />
                                <span className="nav-label">Campaign</span>
                                <span className="fa arrow" />
                            </Link>
                            <ul className={this.secondLevelActive("/campaign/")}>
                                {(user.isSuper || ((user.isSuperClientAdmin || user.isClientAdmin || user.isReports) && client && client.allowOffers)) && (
                                    <li className={this.activeRoute("/campaign/offers")}>
                                        <Link to="/campaign/offers">Offers</Link>
                                    </li>
                                )}
                                {(user.isSuper || user.isSuperClientAdmin || user.isReports) && (
                                    <li className={this.activeRoute("/campaign/emailDrip")}>
                                        <Link to="/campaign/emailDrip">Email Drip</Link>
                                    </li>
                                )}
                                {(user.isSuper ||
                                    ((user.isSuperClientAdmin || user.isClientAdmin || user.isReports) &&
                                        client &&
                                        client.allowAutopost && hasStreamSub)) && (
                                    <li className={this.activeRoute("/campaign/streamSubscription")}>
                                        <Link to="/campaign/streamSubscription">Stream Subscription</Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {user.isSuper ||
                        (user.isSuperClientAdmin && (
                            <li>
                                <hr className="nav-hr" />
                            </li>
                        ))}
                    {user.isSuper && (
                        <li className={this.activeRoute("/clients")}>
                            <Link to="/clients">
                                <i className="fa fa-inbox" />
                                <span className="nav-label">Clients</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isApiAdmin) && (
                        <li className={this.activeRoute("/industries")}>
                            <Link to="/industries">
                                <i className="ion ion-android-home" />
                                <span className="nav-label">Industries</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isApiAdmin) && (
                        <li className={this.activeRoute("/companies")}>
                            <Link to="/companies">
                                <i className="ion ion-briefcase" />
                                <span className="nav-label">Companies</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isApiAdmin) && (
                        <li className={this.activeRoute("/custombrands")}>
                            <Link to="/custombrands">
                                <i className="ion ion-waterdrop" />
                                <span className="nav-label">Custom Branding</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isApiAdmin || user.isSuperClientAdmin) && (
                        <li className={this.activeRoute("/ci")}>
                            <Link to={user.isSuperClientAdmin ? `/ci/domaininfo` : `/ci/articlecount`}>
                                <i className="fa fa-book" />
                                <span className="nav-label">Content Index</span>
                                <span className="fa arrow" />
                            </Link>
                            <ul className={this.secondLevelActive("/ci")}>
                                {!user.isSuperClientAdmin && (
                                    <li className={this.activeRoute("/ci/articlecount")}>
                                        <Link to="/ci/articlecount">Article Count</Link>
                                    </li>
                                )}
                                {!user.isSuperClientAdmin && (
                                    <li className={this.activeRoute("/ci/domaincount")}>
                                        <Link to="/ci/domaincount">Domain Count</Link>
                                    </li>
                                )}
                                <li className={this.activeRoute("/ci/domaininfo")}>
                                    <Link to="/ci/domaininfo">Domain Info</Link>
                                </li>
                                {!user.isSuperClientAdmin && (
                                    <li className={this.activeRoute("/ci/domaintrending")}>
                                        <Link to="/ci/domaintrending">Domain Trending</Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {user.isSuperClientAdmin && (
                        <li className={this.activeRoute(`/clients/e/${user.cid}`)}>
                            <Link to={`/clients/e/${user.cid}`}>
                                <i className="fa fa-pencil" />
                                <span className="nav-label">Edit Client</span>
                            </Link>
                        </li>
                    )}
                    {user.isClientAdmin && user.readOnly && (
                        <li className={this.activeRoute(`/clients/v/${user.cid}`)}>
                            <Link to={`/clients/v/${user.cid}`}>
                                <i className="fa fa-cog" />
                                <span className="nav-label">Client Settings</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isApiAdmin) && (
                        <li className={this.activeRoute("/feeds")}>
                            <Link to="/feeds">
                                <i className="fa fa-rss" />
                                <span className="nav-label">Feeds</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isApiAdmin || user.isClientAdmin || user.isSuperClientAdmin) && (
                        <li className={this.activeRoute("/feeds-requests")}>
                            <Link to="/feeds-requests">
                            <i className="fa fa-rss"></i>
                                <span className="nav-label">Request/Review Feeds</span>
                            </Link>
                        </li>
                    )}
                    {(user.isSuper || user.isApiAdmin) && (
                        <li className={this.activeRoute("/docs")}>
                            <Link to={`/docs/releases`}>
                                <i className="ion ion-document-text" />
                                <span className="nav-label">Docs</span>
                                <span className="fa arrow" />
                            </Link>
                            <ul className={this.secondLevelActive("/docs")}>
                                <li className={this.activeRoute("/docs/releases")}>
                                    <Link to="/docs/releases">Release Notes</Link>
                                </li>
                                {user.isSuper && (
                                    <li className={this.activeRoute("/docs/api")}>
                                        <Link to="/docs/api">Internal API</Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {user.isSuper && (
                        <li className={this.activeRoute("/settings")}>
                            <Link to="/settings">
                                <i className="fa fa-cog" />
                                <span className="nav-label">Settings</span>
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients, name: state.utils.name });

export default connect(mapStateToProps)(Navigation);
