import { ACCOUNT_ADMIN, ACCESS_TOKEN, LOAD_PAGE, SET_PRIOR_PAGE, CLEAR_PAGE } from "../actions/session";
import { reduce } from "../utils/Helpers";

interface ISessionState {
    admin: dynamic;
    access_token: string;
    authenticated: boolean;
    page: string;
    priorPage: string;
    authenticating: boolean;
}
const initialState: ISessionState = {
    admin: {},
    access_token: "",
    authenticated: false,
    priorPage: "",
    page: "",
    authenticating: false
};

const handlers = {
    [ACCOUNT_ADMIN]: (_, action) => ({ admin: action.admin, authenticated: action.admin.aid, authenticating: false }),
    [ACCESS_TOKEN]: (_, action) => ({ access_token: action.token, authenticated: true, authenticating: false }),
    [SET_PRIOR_PAGE]: (_, action) => ({ priorPage: action.page }),
    [LOAD_PAGE]: (_, action) => ({ page: _.priorPage }),
    [CLEAR_PAGE]: (_, action) => ({ page: "" })
};
export default (state = initialState, action) => reduce<ISessionState>(handlers, state, action);
