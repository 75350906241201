import React, { Component } from "react";
import Button from "../../atoms/Button";
interface AddArticleOrMediaModalProps {
    cid: Mongo.IClient["cid"];
    submit: () => void;
}

interface AddArticleOrMediaModalState {
}

export default class AddArticleOrMediaModal extends Component<AddArticleOrMediaModalProps, AddArticleOrMediaModalState> {
    constructor(props: AddArticleOrMediaModalProps) {
        super(props);
    }

    render() {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Button style={{ width: '100%'}} onClick={() => this.props.submit()}>
                    Article
                </Button>
                <h5 style={{ padding: '0px 10px' }}>OR</h5>
                <Button style={{ width: '100%'}} to={`/content/media/c/${this.props.cid}`}>
                    Media/Text
                </Button>
            </div>
        );
    }
}