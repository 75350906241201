import React, { Component } from "react";
import Spinner from "../../atoms/Spinner";
import Button from "../../atoms/Button";
import { doLoadArticle, createAlert, doGetQuotes, CampaignNotificationActions } from "../../../actions";
import Modal from "./Modal";
import Link from "../../atoms/Link";

interface ILoadArticleModalProps {
    client: Mongo.IClient;
    lang?: string;
    stream?: Mongo.IStream;
    submit: (article: Partial<ServerTypes.Console.IArticle>, quotes?: string[] | undefined) => void;
    open?: boolean;
    closeAction: () => void;
}

interface ILoadArticleModalState {
    loading: boolean;
    url: string;
    article: Partial<ServerTypes.Console.IArticle> | null;
    suggestedLink: string;
    quotes?: string[];
    lang: string;
    open: boolean;
}
export default class LoadArticleModal extends Component<ILoadArticleModalProps, ILoadArticleModalState> {
    constructor(props: ILoadArticleModalProps) {
        super(props);

        this.state = {
            lang: (props.stream ? props.stream.lang : props.lang)||"en",
            loading: false,
            url: "",
            article: null,
            suggestedLink: "",
            open: false
        };
    }

    componentWillReceiveProps(newProps: ILoadArticleModalProps) {
        if (newProps.open !== this.state.open) {
            this.setState({open: !!newProps.open});
        }
    }

    async loadSuggestedComments(link: string, gpt: boolean, cid: string) {
        const { lang } = this.state;
        if (this.state.suggestedLink && this.state.suggestedLink === link) {
            return;
        }
        this.setState({ suggestedLink: link });
        const suggestedQuotes = await doGetQuotes(link, lang, gpt, cid);
        return suggestedQuotes && !("valid" in suggestedQuotes) && suggestedQuotes.numFound
            ? suggestedQuotes.items.map(i => i.quote)
            : [];
    }

    async load() {
        let { url } = this.state;
        if (!url) {
            return;
        }
        if (url.indexOf("http") === -1) {
            url = `http://${url.trim()}`;
        }
        this.setState({ loading: true });
        let analyzedArticle: dynamic | null = null;
        const existingContent = await CampaignNotificationActions.doGetExistingContent({
            ...(this.props.client ? { cid: this.props.client.cid } : { consumer: true }),
            Link: url
        });

        if (existingContent.total) {
            if (
                !confirm(
                    `A notification ${existingContent.items[0].name} with this piece of content already exists. Are you sure you want to create another one?`
                )
            ) {
                this.setState({ loading: false });
                return;
            }
        }
        let cid = this.props.client ? this.props.client.cid : undefined;
        analyzedArticle = await doLoadArticle(url, false, cid);
        if (!analyzedArticle) {
            this.setState({ loading: false });
            return;
        } else if (analyzedArticle.error === "DUPLICATE_ARTICLE_LINK") {
            if (
                !confirm(
                    "This article already exists in your library, are you sure you want to re-add it?"
                )
            ) {
                this.setState({ loading: false });
                return;
                }

                analyzedArticle = await doLoadArticle(url, true, cid);
                if (!analyzedArticle) {
                    this.setState({ loading: false });
                    return;
                }

                if (analyzedArticle.error) {
                    createAlert(analyzedArticle.error, `error`);
                    this.setState({ loading: false });
                    return;
                }
        }
        const article = analyzedArticle as Partial<ServerTypes.Console.IArticle>;
        if (article.Title && article.Title.length > 200) {
            article.Title = `${article.Title.substring(0, 200).trim()}...`;
        }

        const quotes = [];

        article.Terms =
            article.Content && article.Content.constructor === Array
                ? ((article.Content as unknown) as string[]) // typescript doesnt like checking the constructor to make sure its an array
                : (article.Content || "")
                      .split(",")
                      .map(item => item.substring(0, 50).trim())
                      .filter(i => i);

        this.setState({ article, quotes }, () =>
            this.submitArticle()
        );
    }

    submitArticle() {
        this.props.submit(this.state.article!, this.state.quotes);
    }

    render() {
        const { loading, url } = this.state;


        return (<Modal title="Load Article" open={this.state.open} closeAction={this.props.closeAction}>
            {loading &&
                    <div>
                        <h3
                            style={{
                                textAlign: "center"
                            }}
                        >
                            Loading article from source...
                        </h3>
                        <Spinner />
                    </div>
            }
            {!loading &&
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexBasis: "100%",
                            justifyContent: "center"
                        }}
                    >
                        <label htmlFor="oldPass" style={{textAlign: "left"}}>Article URL</label>
                        <input
                            style={{
                                marginBottom: "10px"
                            }}
                            id="url"
                            type="text"
                            value={url}
                            onChange={event => this.setState({ url: event.target.value })}
                            placeholder="Paste article URL here"
                        />
                        <Button disabled={this.state.loading} onClick={() => this.load()}>
                            Load Article
                        </Button>
                    </div>
                            <div
                                key={`new`}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexBasis: "45%",
                                    position: "relative",
                                    justifyContent: "center",
                                    zIndex: 0
                                }}
                            >
                                <Link
                                    style={{ textDecoration: "underline", padding: "5px 0px" }}
                                    onClick={() =>
                                        this.setState({ article: { source: "user" } }, () => this.submitArticle())
                                    }
                                >
                                        Manually create an article
                                </Link>
                            </div>
                </div>
            }
            </Modal>
        );
    }
}
