import React, { Component } from "react";
import ViewSDKClient from "./ViewSDKClient";

interface IViewPDFFileProps {
    url: string;
    clientId: string,
    fileName: string
}

interface IViewPDFFileProps {
    url: string;
    clientId: string,
    fileName: string
}

export default class ViewPDFFile extends Component<IViewPDFFileProps, {}> {
    constructor(props: {url:string, clientId: string, fileName: string}) {
        super(props);
    }

    componentDidMount() {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            /* Invoke file preview */
            viewSDKClient.previewFile(
                "pdf-div", {
                    /* Pass the embed mode option here */
                    embedMode: "FULL_WINDOW"
                },
                this.props.url,
                this.props.clientId,
                this.props.fileName
            );
        });
    }

    render() {
        return <> </>;
    }

}
