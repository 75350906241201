import React, { ReactNode } from "react";

interface IWrapperProps {
    children: ReactNode;
    id?: string;
}

export const Wrapper = (props: IWrapperProps) => {
    return (
        <div id={props.id || ""} className="wrapper wrapper-content">
            {props.id && (
                <button
                    className="btn brandPrimary--bg skip-to-content-button"
                    onClick={(e) => {
                        const mainContent = document.getElementById(props.id || "");
                        if (mainContent) {
                            mainContent.scrollIntoView({ behavior: 'smooth' });
                            mainContent.classList.add('focused-content');
                            mainContent.focus();
                            mainContent.addEventListener('blur', () => {
                                mainContent.classList.remove('focused-content');
                            }, { once: true });
                        }
                        (e.target as HTMLButtonElement).blur(); // This will remove focus from the button
                    }}
                    tabIndex={1}
                >
                    Skip to main content
                </button>
            )}
            {props.children}
        </div>
    );
};
export default Wrapper;
