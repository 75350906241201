import React from 'react';

import {apiRequest} from '../../utils/Helpers';
import appConfig from '../../../config/config.dev';
import Modal from 'react-bootstrap-modal';
import ArticleCard from './ArticleCard';
import {createAlert} from '../../actions/utils';
import Spinner from '../atoms/Spinner';
class MoreLikeThis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cid: this.props.client.cid,
            searchType: this.props.stream.searchType,
            lang: this.props.stream.lang,
            url: this.props.url,
            client: this.props.client,
            stream: this.props.stream
        };
    }
    componentDidMount() {
        apiRequest(`${appConfig.API_URL}/getMoreLikeThis`, `POST`, {
            url: this.state.url,
            lang: this.state.lang,
            cid: this.state.cid,
            sid: this.state.stream.sid,
            searchType: this.state.searchType
        }).then(function(data) {
            if (data && "valid" in data && !data.valid) {
                createAlert(data.error, `error`);
                this.props.hide();
            } else if (data && data.length == 0) {
                createAlert(`We could not find any articles in our archive that are similar to this one.`, `error`);
                this.props.hide();
            } else {
                this.setState({articles: data});
            }
        }.bind(this));
    }

    render() {
        if (!this.state.articles) {
            return (<div style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    zIndex: 9999,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "rgba(0, 0, 0, 0.45)"
                }}><Spinner/>
            </div>);
        }
        return (<Modal show={true} onHide={this.props.hide} aria-labelledby="ModalHeader">
            <Modal.Header closeButton="closeButton">
                <Modal.Title id='ModalHeader'>Related Articles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {
                        this.state.articles && this.state.articles.map(function(article, index) {
                            return (<ArticleCard key={article.artid} article={article} canEdit={false} editing={false} canApprove={this.props.canApprove} stream={this.props.stream} client={this.props.client} approveAction={this.props.approveAction}/>)
                        }.bind(this))
                    }
                </div>
            </Modal.Body>
        </Modal>);
    }
}

export default MoreLikeThis;
