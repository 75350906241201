import BaseAction from "../utils/BaseAction";

export interface IAuditLogActions {
    doGetSearchParams(): Promise<ServerTypes.Console.IAuditLogSearchParams | null>;
    doSearch(
        query: { search?: string; action?: string; object?: string; cid?: string },
        paginationData: { sort?: dynamic<number>; skip?: number; limit?: number }
    ): Promise<ServerTypes.Console.IAuditLogSearchResponse | null>;
}

class _AuditLogActions extends BaseAction implements IAuditLogActions {
    async doGetSearchParams() {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.IAuditLogSearchParams>(
            `/auditlog/searchParams`,
            "POST"
        );
    }

    async doSearch(
        query: {
            search?: string;
            action?: string;
            object?: string;
            cid?: string;
            startDate?: string;
            endDate?: string;
        },
        paginationData: { sort?: dynamic<number>; skip?: number; limit?: number }
    ) {
        return this.apiRequestWithErrorAlert<{ items: ServerTypes.Console.IAuditLog[]; count: number }>(`/auditlog/search`, "POST", {
            ...query,
            ...paginationData
        });
    }
}

export const AuditLogActions = new _AuditLogActions();
