import React, { useState, useEffect } from "react";
import { createAlert } from "../../actions";
import Button from "../atoms/Button";
import DatePickerV2 from "../atoms/DatePickerV2";
import Tooltip from "../atoms/Tooltip";

export interface IPeriod {
    index: number;
    minDate?: Date;
    startDate?: string;
    endDate?: string;
    removable?: boolean;
    add?: (startDate: string, endDate: string) => void;
    remove?: (index: number) => void;
}
export default (props: IPeriod) => {
    const { index, startDate: _startDate, endDate: _endDate, add, remove, removable } = props;
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [minDate, setMinDate] = useState(new Date());

    const updateDates = () => {
        let start = new Date();
        if (_startDate) {
            // we dont care about the times at all here, we pass in just the date and return just the dates so lets just set it to midday
            start = new Date(`${_startDate}T12:00:00.000Z`);
        }
        let end = new Date(start);
        if (_endDate) {
            // we dont care about the times at all here, we pass in just the date and return just the dates so lets just set it to midday
            end = new Date(`${_endDate}T12:00:00.000Z`);
        } else {
            end.setMonth(end.getMonth() + 12); // Allowing for one year periods
        }

        setMinDate(start);
        setStartDate(start);
        setEndDate(new Date(end));
        end.setDate(end.getDate() + 1);
        setMaxDate(end);
    };

    useEffect(() => {
        updateDates();
    }, []);

    useEffect(() => {
        updateDates();
    }, [_startDate, _endDate]);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: "10px"
            }}
        >
            <label className="form__label" style={{ marginRight: 50, marginTop: 25 }}>
                Period {index + 1}
            </label>
            <div className="date__wrapper">
                <label className="control-label" htmlFor="startDate">Start Date</label>
                <DatePickerV2
                    id="startDate"
                    dateFormat="yyyy/MM/dd"
                    minDate={minDate.toISOString()}
                    value={startDate.toISOString()}
                    onChange={value => {
                        const date = new Date(`${value}`);
                        const today = new Date();
                        today.setUTCHours(0, 0, 0, 0);

                        if (date.getTime() < today.getTime()) {
                            createAlert("You cannot create a subscription period in the past");
                            return;
                        }

                        setStartDate(date);
                        const end = new Date(date);
                        end.setMonth(end.getMonth() + 12);
                        if (endDate.getTime() > end.getTime()) {
                            setEndDate(new Date(end));
                        }

                        end.setDate(end.getDate() + 1);
                        setMaxDate(end);
                    }}
                    disabled={!!remove}
                    enableTabLoop={false}
                />
            </div>
            <div
                className="date__wrapper"
                style={{
                    marginLeft: "10px"
                }}
            >
                <label className="control-label" htmlFor="endDate">End Date</label>
                <DatePickerV2
                    id="endDate"
                    dateFormat="yyyy/MM/dd"
                    minDate={startDate.toISOString()}
                    maxDate={maxDate.toISOString()}
                    value={endDate.toISOString()}
                    onChange={value => {
                        const date = new Date(`${value}`);

                        const oneYear = new Date(startDate);
                        oneYear.setFullYear(oneYear.getFullYear() + 1);
                        if (date.getTime() > oneYear.getTime()) {
                            createAlert("You cannot create a subscription period longer than one year");
                            return;
                        }
                        const start = new Date(startDate);
                        start.setDate(start.getDate() - 1);
                        if (date.getTime() < start.getTime()) {
                            createAlert("You cannot set the end date before the start date");
                            return;

                        }
                        setEndDate(date);
                    }}
                    disabled={!!remove}
                    enableTabLoop={false}
                />
            </div>
            <div className="table__filters">
                <div className="table__filters__option">
                    <label className="control-label" style={{ width: "100%" }}>
                        &nbsp;
                    </label>
                    {add && (
                        <Button
                            onClick={() => add(startDate.toISOString(), endDate.toISOString())}
                            style={{
                                display: "inline-block",
                                marginLeft: "10px"
                            }}
                        >
                            Add
                        </Button>
                    )}
                    {remove && removable && (
                        <span
                            onClick={() => remove(index)}
                            className="fa fa-times"
                            style={{
                                display: "inline-block",
                                marginLeft: 10,
                                cursor: "pointer"
                            }}
                        />
                    )}
                    {remove && !removable && (
                        <Tooltip style={{ marginLeft: "10px" }}>You cannot remove non-expired periods</Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
};
