import React, { Component } from "react";
import { default as BootstrapModal } from "react-bootstrap-modal";
interface ModalProps {
    title: string | JSX.Element;
    backdrop?: "static" | boolean;
    open?: boolean;
    className?: string;
    footer?: JSX.Element;
    lang?: string;
    link?: string;
    linkTitle?: string | JSX.Element;
    closeAction?: () => void;
}
interface ModalState {
    open: boolean;
}
export default class Modal extends Component<ModalProps, ModalState> {
    constructor(props: ModalProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
            open: props.open || false
        };
    }

    componentWillReceiveProps(newProps: ModalProps) {
        if ("open" in newProps && newProps.open !== this.props.open) {
            this.setState({ open: newProps.open! });
        }
    }
    closeModal() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
    }
    render() {
        const { backdrop } = this.props;
        const { open } = this.state;

        return (
            <BootstrapModal
                className={this.props.className || ""}
                show={open}
                onHide={() => this.closeModal()}
                backdrop={backdrop || true}
            >
                {this.props.title && (
                    <BootstrapModal.Header closeButton={true}>
                        <BootstrapModal.Title id="ModalHeader">
                            {this.props.title}
                            {this.props.link && (
                                <a
                                    className="brandPrimary--color btn--sm"
                                    style={{ float: "right", marginRight: "10px", textDecoration: "underline" }}
                                    href={this.props.link}
                                    target="_blank"
                                >
                                    {this.props.linkTitle}
                                </a>
                            )}
                        </BootstrapModal.Title>
                    </BootstrapModal.Header>
                )}
                <BootstrapModal.Body>{this.props.children}</BootstrapModal.Body>
                {this.props.footer && <BootstrapModal.Footer>{this.props.footer}</BootstrapModal.Footer>}
            </BootstrapModal>
        );
    }
}
