import BaseAction from "../utils/BaseAction";

export type IDelegateActions = ClassInstance<typeof _DelegateActions>;

class _DelegateActions extends BaseAction {
    async doList(cid: string, gids: string[] | null, search: string, limit: number, skip: number, sort?: dynamic<number>) {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.IListResponse<ServerTypes.Console.IDelegate>>(
            `/delegates/list`,
            `POST`,
            { cid, ...(gids ? { grp: gids } : null), search, limit, skip, ...(sort ? { sort } : null) }
        );
    }

    async doGetPending(cid: string, search: string, limit: number, skip: number, sort?: dynamic<number>) {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.IListResponse<ServerTypes.Console.IPendingDelegate>>(
            `/delegates/pending`,
            "POST",
            {
                cid,
                search,
                limit,
                skip,
                ...(sort && Object.keys(sort).length ? { sort } : { requestedOn: -1 })
            }
        );
    }
    async doApprove(email: string, daCode: string, ssoId: string) {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.IDelegationResponse>(`/delegates/approve`, "POST", {
            email,
            daCode,
            ssoId
        });
    }

    async doReject(email: string, daCode: string) {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.IDelegationResponse>(`/delegates/reject`, "POST", {
            email,
            daCode
        });
    }

    async doAssignDelegate(
        cid: string,
        advisor: ServerTypes.Console.IDelegationAssignment,
        delegate: ServerTypes.Console.IDelegationAssignment,
        allowSuperPublisher: boolean
    ) {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.IDelegationResponse>(
            `/delegates/assignDelegate`,
            "POST",
            {
                cid,
                advisor,
                delegate,
                allowSuperPublisher
            }
        );
    }

    async doRemoveAdvisor(advisorUID: string, delegateUID: string, cid: string) {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.IDelegationResponse>(
            `/delegates/removeAdvisor`,
            "post",
            {
                advisorUID,
                delegateUID,
                cid
            }
        );
    }

    async doRemoveAllAdvisors(advisorUIDs: string[], delegateUID: string, cid: string) {
        for (let advisorUID of advisorUIDs) {
            await this.doRemoveAdvisor(advisorUID, delegateUID, cid);
        }
    }

    async doExportCSV(cid: string, gids: string[] | null) {
        const data = await this.apiRequest("/delegates/csv/export", "POST", { cid, ...(gids ? { grp: gids } : null) });
        if (data) {
            return this.downloadFile("approvedDelegates", data);
        }
    }
}

export const DelegateActions = new _DelegateActions();
