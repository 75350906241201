import React, { Component } from "react";
import { doSetFooterFunctions, createAlert } from "../../actions";
import Breadcrumbs from "./Breadcrumbs";
import Spinner from "../atoms/Spinner";
import Tooltip from "../atoms/Tooltip";
import PermissionSet, { IPermission, IPermissionType } from "./PermissionSet";
import { debounce, Cancelable } from "lodash";
import { Wrapper, Row } from "../atoms/Layout/";
import moment from "moment";
import Link from "../atoms/Link";
import Button from "../atoms/Button";
import { ITimeConstraints } from "../atoms/DatePicker";
import { sanitizeHtml } from "../../utils/Helpers";

export interface IEditableInput<T> {
    /** displayed label for field in edit page */
    label: string;
    /** whether field is required to be filled in. also adds an asterisk to the field name */
    required?: boolean;
    /** whether the field takes up the full width or is sorted into columns */
    fullWidth?: boolean;
    /** type of widget for field. defaults to toggle if not provided */
    type?: IPermissionType;
    /** value displayed in field; defaults to item[key] if not provided */
    value?: any;
    /** whether widget is hidden from the display */
    hidden?: boolean;
    /** list of options for dropdown, if applicable */
    values?: Array<{ label: string; value: any; selected?: boolean }>;
    /** whether the field can be edited */
    disabled?: boolean;
    /** callback to override default change behaviour */
    mutateChange?: (key: string, value: string | boolean | string[] | Date) => Promise<Partial<T>>;
    /** used to apply custom css to widget */
    customClasses?: string;
    /** max length of text fields */
    max?: number;
    /** min length of text fields */
    min?: number;
    /** earliest date selectable in datepicker */
    minDate?: string;
    /** latest date selectable in datepicker */
    maxDate?: string;
    /** tooltip text for field */
    description?: string | JSX.Element;
    /** used to display custom content (ie. a link) in the widget instead of the value in plaintext */
    formatted?: JSX.Element;
    /** editableinput only, seems to work similarly to onBlur. TODO: do we need this? */
    onOutsideClick?: (event: dynamic<any>, id: string) => Promise<void>;
    /** allows setting onBlur behaviour on widget */
    onBlur?: () => Promise<void>;
    /** used to render html above the widget */
    renderAbove?: JSX.Element;
    /** used to render html below the widget */
    renderBelow?: JSX.Element;
    /** used to render html to the right of the widget */
    renderRight?: JSX.Element;
    /** used to render html to the left of the widget */
    renderLeft?: JSX.Element;
    /** used to override default components for creatable input (see https://react-select.com/components) */
    components?: dynamic;
    /** used to create custom prompts for creatable input */
    promptTextCreator?: (userInput: string) => string;
    /** options list used for creatable input. TODO: can this be folded into the values field? */
    options?: Array<{ label: string; value: string; className?: string; }>;
    /** text displayed when no results are found in creatable input */
    noResultsText?: string;
    /** specifies custom keypress behaviour for creatable input. defaults to handling enter if not provided */
    onInputKeyDown?: (event: dynamic<any>) => void;
    /** specifies whether a given item is allowed to be added to a creatable input */
    canUpdate?:(item: string, terms: string[]) => boolean;
    /** formats current items in creatableinput. defaults to returning { value, label: value } if not provided */
    formatValue?: (value: string) => { label: string; value: string; className?: string; };
    /** placeholder text displayed when field is empty. defaults to `please select ${labelname}` if not provided */
    placeholder?: string;
    /** used for imposing constraints on timepickers (eg. steps of 15, min/max values) */
    timeConstraints?: ITimeConstraints;
    /** callback used to decide whether a date in a datepicker can be selected */
    validation?: (current: moment.Moment) => boolean;
    /** overrides the default input element for datepickers */
    renderInput?: (props: any, openCalendar: any) => JSX.Element;
    /** specifies time format for timepicker, prevents picking time if false */
    timeFormat?: boolean | string;
    /** editableinput only. used for adding clear buttons next to fields */
    clearField?: JSX.Element;
    /** plaintext only. displayed instead of actual value */
    displayValue?: string;
    /** used to hide character totals for text fields */
    hideTotals?: boolean;
    /** called when a file is uploaded */
    imageChange?: (image: File[] | null | undefined) => void;
    /** callback used to check if input value is properly formatted */
    validateInput?: (inputValue: string) => boolean | string;
    /** custom classes to be applied to entire ui element (widget, title, etc.) */
    customTopLevelClasses?: string;
}
interface IElementEditCardProps<T> {
    getItem: () => Promise<T | null>;

    onLoad?: (item: T) => void;
    canUpdate: (item: T) => Promise<boolean>;
    update: (item: T) => void;
    canRemove?: (item: T) => Promise<boolean>;
    remove?: (item: T) => void;
    canUnschedule?: (item: T) => Promise<boolean>;
    unschedule?: (item: T) => void;
    canApprove?: (item: T) => Promise<boolean>;
    approve?: (item: T) => void;
    canReject?: (item: T) => Promise<boolean>;
    reject?: (item: T) => void;
    fullWidthStyle?: boolean;

    breadcrumbs?: ((item: T | null) => Array<{ name: string; link?: string }>) | Array<{ name: string; link?: string }>;

    rows?: Array<{
        label?: string;
        fullWidth?: boolean;
        link?: {
            title: string;
            onClick: () => void;
            disabled?: boolean;
        }
        columns: Array<{
            label?: string;
            /** which items are present in the column. NOTE: the ordering does not have any effect here */
            items: string[];
            /** unique identifier, prevents crashes */
            key: string;
            /**  */
            custom?: (item: T, setState: Function) => JSX.Element
        }>,
        /** unique identifier, prevents crashes */
        key: string,
        /** no spacing between row and previous row */
        noSpacing?: boolean;
    }>;
    /** list of items in the card. NOTE: the ordering of the keys matters here */
    editableKeys: (item: T, getStateManual: () => T | null, setStateManual: (entries: Partial<T>) => void) => Partial<dynamic<IEditableInput<T>>>;
    elementName: string;
    instructions?: string;
    mandatoryFieldNote? : string;
    idField: keyof T;
    isInsert?: (item: T | null) => boolean;
    loading?: boolean;
    loadAuditTrail?: boolean;
    buttons?: (item: T | null) => Array<{
        text: string;
        icon?: string;
        onClick: (item?: T | null) => void;
        disabled?: boolean;
        title?: string;
        tooltip?: string;
    }>;
    skipSanitize?: boolean;
    /** padding between rows. defaults to 50px */
    rowPadding?: number;
    auditTrail?: JSX.Element;
    /** disables all fields */
    viewOnly?: boolean;
}
interface IElementEditCardState<T> {
    item: T | null;
    loading: boolean;
    uploadedFile: boolean | undefined;
    fileUpload: File[] | null | undefined;
    thumbnail: string | undefined;
    hasSubmitted?: boolean;
}

export default class ElementEditCard<T> extends Component<IElementEditCardProps<T>, IElementEditCardState<T>> {
    _mutateChange: ((
        item: T,
        key: string,
        value: string | boolean | string[] | Date,
        callback: (key: string, value: string | boolean | string[] | Date) => Promise<Partial<T>>
    ) => Promise<void>) &
        Cancelable;
    constructor(props: IElementEditCardProps<T>) {
        super(props);
        this._mutateChange = debounce(this.mutateChange.bind(this), 500);
        this.submit = this.submit.bind(this);
        this.remove = this.remove.bind(this);
        this.unschedule = this.unschedule.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.getStateManual = this.getStateManual.bind(this);
        this.setStateManual = this.setStateManual.bind(this);
        this.state = { item: null, loading: false, uploadedFile: undefined, fileUpload: undefined, thumbnail: undefined };
    }

    getStateManual() {
        return this.state.item;
    }

    setStateManual(entries: Partial<T>){
        const { item } = this.state;
        if (item) {
            this.setState({ item: { ...item, ...entries } });
        }
    }

    isInsert() {
        const { isInsert } = this.props;
        if (isInsert !== undefined) {
            return isInsert(this.state.item);
        }
        return !(this.state.item && this.state.item[this.props.idField]);
    }

    async componentDidMount() {
        const item = await this.props.getItem();
        this.setState({ loading: true, item }, () => this.setState({ loading: false }));
        if (item && this.props.onLoad) {
            this.props.onLoad(item);
        }
        doSetFooterFunctions(this.submit, this.props.canRemove ? this.remove : null, this.isInsert() ? "Create" : "Update", "Delete", this.props.canUnschedule ? this.unschedule : null, "Unschedule", this.props.canApprove ? this.approve : null, this.props.canReject ? this.reject : null);
    }

    componentWillUnmount() {
        doSetFooterFunctions(null, null);
    }

    async unschedule(event?: React.FormEvent<HTMLFormElement>) {
        if (event) {
            event.preventDefault();
        }
        const { canUnschedule, unschedule } = this.props;
        const { item, loading } = this.state;
        if (loading || !canUnschedule) {
            return false;
        }
        if (unschedule && item) {
            unschedule(item);
        }
        return true;
    }

    async approve(event?: React.FormEvent<HTMLButtonElement>) {
        if (event) {
            event.preventDefault();
        }
        const { canApprove, approve } = this.props;
        const { item, loading } = this.state;
        if (loading || !canApprove) {
            return false;
        }
        if (approve && item) {
            approve(item);
        }
        return true;
    }

    async reject(event?: React.FormEvent<HTMLButtonElement>) {
        if (event) {
            event.preventDefault();
        }
        const { canReject, reject } = this.props;
        const { item, loading } = this.state;
        if (loading || !canReject) {
            return false;
        }
        if (reject && item) {
            reject(item);
        }
        return true;
    }

    async submit(event?: React.FormEvent<HTMLFormElement>) {
        if (event) {
            event.preventDefault();
        }
        const { canUpdate, update, editableKeys, skipSanitize } = this.props;
        const { item, loading } = this.state;
        if (loading || !item || !canUpdate || !canUpdate(item)) {
            return false;
        }
        const fields = editableKeys(item, this.getStateManual, this.setStateManual);
        for (const key in fields) {
            if (fields[key]!.required && !fields[key]!.hidden && (!item[key] || item[key] instanceof Array && !item[key].length)) {
                if ((item[key] == null || item[key] == undefined) && fields[key]!.value) {
                    // some keys aren't actually keys of the item itself due to representing sub-keys etc.
                    // example: the ui_* fields on the notification edit page
                    // check the value field instead in these cases
                    continue;
                }
                createAlert("Please fill in all required fields");
                this.setState({ hasSubmitted: true });
                return false;
            }
            if (!skipSanitize) {
                if (item[key] && typeof item[key] == "string") {
                    const sanitizedString = item[key] = sanitizeHtml(item[key]);
                    if (!sanitizedString) {
                        createAlert(`No special characters please.`);
                        this.setState({ hasSubmitted: true });
                        return false;
                    }
                } else if (fields[key]?.value && typeof fields[key]?.value === "string") {
                    const sanitizedString = fields[key]!.value = sanitizeHtml(fields[key]?.value);
                    if (fields[key]!.required && !sanitizedString) {
                        createAlert(`Please remove special characters from ${key}`);
                        this.setState({ hasSubmitted: true });
                        return false;
                    }
                }
            }
        }
        const checkValidAlerts: string[] = []
        for (const key in fields) {
            if (fields[key]!.validateInput && item[key]) {
                const checkValid = fields[key]!.validateInput!(item[key]);
                if (typeof checkValid == "string") {
                    checkValidAlerts.unshift(checkValid);
                    this.setState({ hasSubmitted: true });
                }
            }
        }
        if(checkValidAlerts && checkValidAlerts.length > 0) {
            checkValidAlerts.forEach(alert => createAlert(alert));
            return false;
        }
        update(item);
        return true;
    }

    async remove(event?: React.FormEvent<HTMLFormElement>) {
        if (event) {
            event.preventDefault();
        }
        const { canRemove, remove } = this.props;
        const { item, loading } = this.state;
        if (loading || !item || !canRemove || !canRemove(item) || !remove) {
            return false;
        }
        remove(item);
        return true;
    }

    async mutateChange(
        item: T,
        key: string,
        value: string | boolean | string[] | Date,
        callback: (key: string, value: string | boolean | string[] | Date) => Promise<Partial<T>>
    ) {
        this.setState({ item: { ...item, ...(await callback(key, value)) } });
    }

    getSettings() {
        const { editableKeys } = this.props;
        const { item } = this.state;
        const returnItems: dynamic<IPermission> = {};
        if (!item) {
            return returnItems;
        }

        for (const [key, value] of Object.entries<IEditableInput<T>>(editableKeys(item, this.getStateManual, this.setStateManual) as dynamic)) {
            if (((key == null || key == undefined) && !("value" in value)) || value.fullWidth) {
                continue;
            }
            const { label, required, type, values, mutateChange, disabled, hidden, customClasses, customTopLevelClasses, max, min, displayValue, description, formatted, onOutsideClick, onBlur, renderAbove, renderBelow, renderRight, renderLeft, minDate, maxDate, components, promptTextCreator, options, noResultsText, onInputKeyDown, canUpdate, placeholder, formatValue, timeConstraints, validation, renderInput, timeFormat, clearField, hideTotals, validateInput } = value;
            const firstLetter = label.charAt(0).toLowerCase();
            const labelWithoutColon = label.replace(":", "");
            const startsWithVowel =
                firstLetter === "a" ||
                firstLetter === "e" ||
                firstLetter == "i" ||
                firstLetter == "o" ||
                firstLetter == "u";
            returnItems[key] = {
                label,
                value: value.value ? value.value : item[key],
                ...(type ? { type } : null),
                ...(type === "multi-select" ? { multi: true } : null),
                ...(type === "creatable" ? { multi: true, creatable: true } : null),
                ...(values ? { values } : null),
                ...(hidden ? { hidden } : null),
                ...(required ? { required } : null),
                ...(disabled ? { disabled: true } : null),
                ...(max ? { max } : null),
                ...(min ? { min } : null),
                ...(description ? { description } : null),
                ...(formatted ? { formatted } : null),
                ...(onOutsideClick ? { onOutsideClick } : null),
                ...(onBlur ? { onBlur } : null),
                ...(renderAbove ? { renderAbove } : null),
                ...(renderBelow ? { renderBelow } : null),
                ...(renderRight ? { renderRight } : null),
                ...(renderLeft ? { renderLeft } : null),
                ...(minDate ? { minDate } : null),
                ...(maxDate ? { maxDate } : null),
                ...(components ? { components } : null),
                ...(promptTextCreator ? { promptTextCreator } : null),
                ...(options ? { options } : null),
                ...(noResultsText ? { noResultsText } : null),
                ...(onInputKeyDown ? { onInputKeyDown } : null),
                ...(canUpdate ? { canUpdate } : null),
                ...(formatValue ? { formatValue } : null),
                ...(timeConstraints ? { timeConstraints } : null),
                ...(validation ? { validation } : null),
                ...(renderInput ? { renderInput } : null),
                ...(timeFormat ? { timeFormat } : null),
                ...(clearField ? { clearField } : null),
                ...(displayValue ? { displayValue } : null),
                ...(hideTotals ? { hideTotals }: null),
                ...(validateInput ? { validateInput }: null),
                placeholder: placeholder ? placeholder :
                    type === "multi-select"
                        ? `Please Select ${labelWithoutColon}${labelWithoutColon.charAt(labelWithoutColon.length - 1).toLowerCase() === "s" ? "" : "(s)"}`
                        :  type === "creatable"
                            ? `Please Add ${labelWithoutColon}${labelWithoutColon.charAt(labelWithoutColon.length - 1).toLowerCase() === "s" ? "" : "(s)"}`
                            : `Please Select a${startsWithVowel ? "n" : ""} ${labelWithoutColon}`,
                onChange: async value => {
                    if (mutateChange) {
                        this._mutateChange(item, key, value, mutateChange);
                    } else {
                        this.setState({
                            item: {
                                ...item,
                                [key]: value
                            }
                        });
                    }
                },
                ...(customClasses ? { customClasses } : null),
                ...(customTopLevelClasses ? { customTopLevelClasses } : null)
            };
        }
        return returnItems;
    }

    renderDefaultColumns() {
        const { mandatoryFieldNote, editableKeys } = this.props;
        const { item, fileUpload, hasSubmitted } = this.state;
        let imageUpload: (image: File[] | null | undefined) => void;

        if (item) {
            for (const [, value] of Object.entries<IEditableInput<T>>(editableKeys(item, this.getStateManual, this.setStateManual) as dynamic)) {
                const { imageChange } = value;
                if (imageChange) {
                    imageUpload = imageChange;
                    break;
                }
            }
        }

        return (
            <React.Fragment>
                { mandatoryFieldNote ? <div style={{paddingBottom:'10px'}} tabIndex={0}>{mandatoryFieldNote}</div> : "" }
                <div className="form__group">
                    {Object.keys(this.getSettings()).length > 0 && (
                        <PermissionSet
                            permissions={Object.assign(
                                {},
                                ...Object.entries(this.getSettings())
                                    .filter((item, index) => index % 2 === 0)
                                    .map(([k, v]) => ({ [k]: v }))
                            )}
                            _onChange={(key, value) => this.setState({ item: { ...item, [key]: value } as T })}
                            setNumber={"24"}
                            hasSubmitted={hasSubmitted}
                            onValidUpload={entries => {
                                const { fileUpload, uploadedFile, thumbnail } = entries;
                                if (imageUpload) {
                                    imageUpload(fileUpload);
                                }
                                this.setState({ uploadedFile, fileUpload, thumbnail });
                            }}
                            fileUploaded={fileUpload}
                        />
                    )}
                </div>
                <div className="form__group">
                    {Object.keys(this.getSettings()).length > 0 && (
                        <PermissionSet
                            permissions={Object.assign(
                                {},
                                ...Object.entries(this.getSettings())
                                    .filter((item, index) => index % 2 === 1)
                                    .map(([k, v]) => ({ [k]: v }))
                            )}
                            _onChange={(key, value) => this.setState({ item: { ...item, [key]: value } as T })}
                            setNumber={"8"}
                            hasSubmitted={hasSubmitted}
                            onValidUpload={entries => {
                                const { fileUpload, uploadedFile, thumbnail } = entries;
                                if (imageUpload) {
                                    imageUpload(fileUpload);
                                }
                                this.setState({ uploadedFile, fileUpload, thumbnail });
                            }}
                            fileUploaded={fileUpload}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }

    renderRows() {
        const { rows , mandatoryFieldNote, rowPadding, editableKeys} = this.props;
        const { item, fileUpload, hasSubmitted } = this.state;
        let imageUpload: (image: File[] | null | undefined) => void;

        if (item) {
            for (const [, value] of Object.entries<IEditableInput<T>>(editableKeys(item, this.getStateManual, this.setStateManual) as dynamic)) {
                const { imageChange } = value;
                if (imageChange) {
                    imageUpload = imageChange;
                    break;
                }
            }
        }

        if (!rows) {
            return this.renderDefaultColumns();
        }
        return (
            <React.Fragment>
                { mandatoryFieldNote ? <div style={{paddingBottom:'10px'}} tabIndex={0}>{mandatoryFieldNote}</div> : "" }
                {rows.map((row, i)=> (
                    <div key={row.key}>
                    <div style={{
                        display: "flex",
                        ...((i > 0 && !row.noSpacing) ? { paddingTop: `${rowPadding != null ? rowPadding : 50}px` } : undefined)
                    }}>
                        {row.label && (<h2>{row.label}</h2>)}
                        {row.link && (
                            <Link
                                style={{
                                    textDecoration: "underline",
                                    marginLeft: "auto",
                                    marginTop: "auto",
                                    marginBottom: "10px"
                                }}
                                disabled={row.link.disabled}
                                onClick={row.link.onClick}>
                                {row.link.title}
                            </Link>
                        )}
                    </div>
                    <div className="form__row">
                        {row.columns.map((column, index) => (
                            <div className={`form__group ${row.fullWidth ? "form__group--full" : ""}`} style={rows[i + 1]?.noSpacing ? { paddingBottom: "0px" } : {}}>
                                {column.label && (<h3>{column.label}</h3>)}
                                {Object.keys(this.getSettings()).length > 0 && !column.custom &&(
                                    <PermissionSet
                                        // key should be unique here, beware of react warning about any duplicated key
                                        key={column.key}
                                        permissions={Object.assign(
                                            {},
                                            ...Object.entries(this.getSettings())
                                                .filter(([k, v], index) => column.items.indexOf(k) !== -1)
                                                .map(([k, v]) => ({ [k]: v }))
                                        )}
                                        _onChange={(key, value) =>
                                            this.setState({ item: { ...item, [key]: value } as T })
                                        }
                                        setNumber={"25"}
                                        hasSubmitted={hasSubmitted}
                                        onValidUpload={entries => {
                                            const { fileUpload, uploadedFile, thumbnail } = entries;
                                            if (imageUpload) {
                                                imageUpload(fileUpload);
                                            }
                                            this.setState({ uploadedFile, fileUpload, thumbnail });
                                        }}
                                        fileUploaded={fileUpload}
                                    />
                                )}
                                {mandatoryFieldNote && row.columns.length == (index + 1) && !column.custom ? <div style={{ paddingTop: '10px' }}>{mandatoryFieldNote}</div> : "" }
                                {column.custom && item && column.custom(item, this.setState)}
                            </div>
                        ))}
                    </div></div>
                ))}
            </React.Fragment>
        );
    }

    getFullWidthSettings() {
        const { editableKeys } = this.props;
        const { item } = this.state;
        const items: IPermission[] = [];
        const returnItems: IPermission[][] = [];
        if (!item) {
            return returnItems;
        }

        for (const [key, value] of Object.entries<IEditableInput<T>>(editableKeys(item, this.getStateManual, this.setStateManual) as dynamic)) {
            if ((key == null || key == undefined) && (!("value" in value)) || !value.fullWidth) {
                continue;
            }
            const { mutateChange } = value;
            items.push({
                key,
                label: value.label,
                value: item[key],
                type: "text",
                required: value.required,
                onChange: async value => {
                    this.setState({
                        item: {
                            ...item,
                            [key]: value
                        }
                    });
                    if (mutateChange) {
                        this._mutateChange(item, key, value, mutateChange);
                    }
                }
            });
        }

        for (let i = 0; i < items.length; i++) {
            if (!returnItems[Math.floor(i / 2)]) {
                returnItems[Math.floor(i / 2)] = [];
            }
            returnItems[Math.floor(i / 2)][i % 2] = items[i];
        }

        return returnItems;
    }

    render() {
        const { elementName, breadcrumbs, instructions, buttons, viewOnly } = this.props;
        const loading = this.state.loading || this.props.loading;
        const { item, hasSubmitted } = this.state;
        return (
            <Wrapper>
                <Row>
                    <form>
                        <fieldset disabled={!!viewOnly}>
                            <div className="col-sm-12">
                                <div className="ibox">
                                    <div className="ibox-title">
                                        <h5>
                                            <Breadcrumbs
                                                items={[
                                                    ...(breadcrumbs instanceof Function ? breadcrumbs(this.state.item) : breadcrumbs || []),
                                                    { name: `${this.isInsert() ? "Create" : "Edit"} ${elementName}` }
                                                ]}
                                            />
                                        </h5>
                                        {buttons && <div style={{ marginLeft: "auto" }}>
                                            {...buttons(item).map(button => <Button
                                                className="btn--sm"
                                                style={{ marginLeft: "5px" }}
                                                onClick={() => button.onClick(item)}
                                                disabled={button.disabled}
                                                title={button.title}
                                            >
                                                {button.icon && <i className={`fa ${button.icon}`} />} {button.text}
                                                {button.tooltip && <Tooltip style={{ paddingLeft: "5px" }}>{button.tooltip}</Tooltip> }
                                            </Button>)}
                                        </div>}
                                        {instructions &&
                                            <div className="ibox-tools" style={{ marginLeft: '5px' }}>
                                                <a
                                                    className="btn brandPrimary--bg  btn--sm"
                                                    href={instructions}
                                                    target="_blank"
                                                >
                                                    {" "}
                                                    <i className="fa fa-question-circle" /> Instructions
                                                </a>
                                            </div>}
                                    </div>
                                    <div className={`ibox-content form element-edit ${elementName.toLowerCase().replace(/\ /, "")}`}>
                                        {loading ? <Spinner /> : <>
                                            {this.getFullWidthSettings().map((items, index) => (
                                                <div className="form__row" key={index}>
                                                    {items.map(_item => {
                                                        const showRedBackground = hasSubmitted && _item.required && (!_item.value || !(_item.value as string[]).length);
                                                        return <div className="form__group">
                                                            <span className="form__label">{_item.label} {_item.required ? "*" : ""}
                                                                {_item.description ? (
                                                                    <Tooltip
                                                                        style={{
                                                                            marginRight: "2px", marginLeft: "5px"
                                                                        }}
                                                                    >
                                                                        {_item.description}
                                                                    </Tooltip>
                                                                ) : null}
                                                            </span>
                                                            <input
                                                                id={`${_item.key!.charAt(0).toLowerCase()}${_item
                                                                    .key!.slice(1)
                                                                    .replace(/\s/g, "")}`}
                                                                className={`form__value${showRedBackground ? " red-outline" : ""}`}
                                                                type="text"
                                                                onChange={event => _item.onChange!(event.target.value)}
                                                                value={_item.value as string}
                                                                aria-label={typeof _item.label === 'string' ? _item.label : "Input field"} // Added aria-label for accessibility
                                                            />
                                                        </div>
                                                    })}
                                                    {items.length == 1 && <div className="form__group"></div>}
                                                </div>
                                            ))}
                                            {this.renderRows()}
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </Row>
                {this.props.auditTrail && (
                                <div className="audit-trail">
                                    <div className="ibox">
                                        <div className="ibox-content form element-edit">
                                            {/* Show loading spinner if loading is true */}
                                            {this.props.loadAuditTrail ? (
                                                <Spinner />
                                            ) : (
                                                // Show the actual content of auditTrail when not loading
                                                this.props.auditTrail
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
            </Wrapper>
        );
    }
}
