import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import { createAlert } from "../../../actions";
import Button from "../../atoms/Button";
import Tooltip from "../../atoms/Tooltip";
import Checkbox from "../../atoms/Checkbox";

import { doGetPotentialDelegates } from "../../../actions/lists";
import Pagination from "../../molecules/Pagination";
import { IDelegateRow } from "../../pages/users/Edit";
// tslint:disable-next-line:no-submodule-imports
import debounce from "lodash/debounce";

export default class DelegateUserSearchModal extends Component<
    {
        selectDelegate: (user: IDelegateRow) => void;
        clientUserId: string;
        allowContentDelegation: boolean;
        allowEcommDelegation: boolean;
        allowProfileDelegation: boolean;
        assignDelegates: (permissions: Mongo.IDelegatePermission) => Promise<boolean>;
        closeModal: () => void;
        isSelected: (uid: string) => boolean;
        cid: string;
        delegatesArray: IDelegateRow[];
    },
    {
        users: Array<IDelegateRow>
        pagination: { limit: number; skip: number; total: number };
        open: boolean;
        search: string;
        assignDelegatePermissions: Mongo.IDelegatePermission;
    }
> {
    refreshUsers: Function;
    _createAlert: Function;
    constructor(props) {
        super(props);

        this.refreshUsers = debounce(this._refreshUsers, 500);
        this._createAlert = debounce((message, type) => {
            createAlert(message, type);
        }, 500);
        this.state = {
            open: true,
            users: [],
            search: "",
            pagination: { limit: 20, skip: 0, total: 0 },
            assignDelegatePermissions: {
                content: 0,
                eCommunication: 0,
                profile: 0,
                activated: 1
            }
        };
    }

    componentDidMount() {
        this._refreshUsers();
    }

    async _refreshUsers() {
        const { clientUserId, cid, delegatesArray } = this.props;
        const { search, pagination } = this.state;
        try {
            const { items, count } = await doGetPotentialDelegates(
                clientUserId,
                cid,
                delegatesArray.map(delegate => delegate.uid),
                search,
                pagination.limit,
                pagination.skip * pagination.limit
            );
            this.setState({
                users: items || [],
                pagination: { ...pagination, total: count || 0 }
            });
        } catch (err) {
            this.setState({
                users: [],
                pagination: { total: 0, limit: 0, skip: 0 }
            });
        }
    }

    selectDelegate(user) {
        if (this.props.selectDelegate) {
            this.props.selectDelegate(user);
        }
    }

    async assignDelegates() {
        if (this.props.assignDelegates) {
            if (await this.props.assignDelegates(this.state.assignDelegatePermissions)) {
                this.closeModal();
            }
        }
    }

    toggleDelegateCheckbox(permission: keyof Mongo.IDelegatePermission) {
        const removeContent = permission === "content" && this.state.assignDelegatePermissions.content;
        this.setState({
            assignDelegatePermissions: {
                ...this.state.assignDelegatePermissions,
                activated: 1,
                [permission]: !this.state.assignDelegatePermissions[permission],
                ...(removeContent ? { eCommunication: 0 } : null)// If content permission is removed, remove email as well
            }
        });
    }

    closeModal() {
        if (this.props.closeModal) {
          this.props.closeModal();
        }
        this.setState({ open: false }, () => {
        });
        createAlert('The modal for delegate user search has been successfully closed.', 'success');
    }

    render() {
        const { open, pagination, assignDelegatePermissions, search, users } = this.state;
        return (
            <Modal show={open} onHide={() => this.closeModal()}>
                <Modal.Body>
                    <div className="table__filters" style={{ marginBottom: "10px" }}>
                        <div className="table__filters__option" style={{ width: "100%" }}>
                            <label htmlFor="search">Search:</label>
                            <Tooltip
                                style={{
                                    marginLeft: "5px",
                                    marginRight: "5px"
                                }}
                            >
                                Only existing users are available to be added as delegates here. Advisors can add delegates that are not yet users from the app.
                            </Tooltip>
                            <div className="search">
                                <input
                                    id="search"
                                    className="filter__search"
                                    style={{ width: "100%" }}
                                    type="text"
                                    placeholder="Search by name or email"
                                    onChange={event =>
                                        this.setState(
                                            {
                                                search: event.target.value,
                                                pagination: { ...pagination, skip: 0, total: 0 }
                                            },
                                            () => {
                                                this.refreshUsers();
                                                this._createAlert('Search filter updated', 'success');
                                            }
                                        )
                                    }
                                    value={search}
                                />
                                <i className="fa fa-search" />
                            </div>
                        </div>
                    </div>
                    <table className="role__users">
                        <thead>
                            <tr>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody style={{ maxHeight: "250px", overflowY: "scroll" }}>
                            {(users || []).map(i => {
                                return (
                                    <tr
                                        key={i.uid}
                                        onClick={e => this.selectDelegate(i)}
                                        className={`${this.props.isSelected(i.uid) ? "selected" : ""}`}
                                    >
                                        <td
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                flexBasis: "100%",
                                                height: "100%"
                                            }}
                                        >
                                            <Tooltip
                                                label={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            flexBasis: "100%"
                                                        }}
                                                    >
                                                        <span>{i.firstName} {i.lastName}</span>
                                                    </div>
                                                }
                                            >
                                                {`${i.email}`.trim()}
                                            </Tooltip>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div
                        style={{
                            width: "100%",
                            marginTop: "20px"
                        }}
                    >
                        <Pagination
                            style={{ marginLeft: "auto" }}
                            page={pagination.skip}
                            totalItems={pagination.total}
                            numPerPage={pagination.limit}
                            currItems={(users || []).length}
                            changePage={newPage =>
                                this.setState({ pagination: { ...pagination, skip: newPage } }, () =>
                                    this._refreshUsers()
                                )
                            }
                            showTotal={true}
                        />
                    </div>
                </Modal.Body>
                    <div className="checkbox-section">
                        <div>
                            <span className="checkbox-section__title">Permissions:</span>
                            <Tooltip
                                style={{
                                    marginLeft: "5px",
                                    marginRight: "5px"
                                }}
                            >
                                Content permission must be enabled first to allow email permission.
                            </Tooltip>
                        </div>
                        <div className="checkbox-section__row">
                            { this.props.allowContentDelegation &&
                                <div className="checkbox-section__option">
                                    <span  className="checkbox-section__label">Content</span>
                                    <Checkbox
                                        name="content"
                                        _onChange={e => this.toggleDelegateCheckbox("content")}
                                    />
                                </div>
                            }
                            { this.props.allowEcommDelegation &&
                                <div className="checkbox-section__option">
                                    <span className={`checkbox-section__label ${!assignDelegatePermissions.content ? "disabled" : null}`}>Email</span>
                                    <Checkbox
                                        disabled={!assignDelegatePermissions?.content}
                                        checked={!assignDelegatePermissions?.eCommunication ? false : true}
                                        name="eCommunication"
                                        _onChange={e => this.toggleDelegateCheckbox("eCommunication")}
                                    />
                                </div>
                            }
                            { this.props.allowProfileDelegation &&
                                <div className="checkbox-section__option">
                                    <span className="checkbox-section__label">Profile</span>
                                    <Checkbox
                                        name="profile"
                                        _onChange={e => this.toggleDelegateCheckbox("profile")}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                <Modal.Footer style={{ display: "flex" }}>
                    <Button onClick={e => this.closeModal()}>Cancel</Button>
                    <Button style={{ marginLeft: "auto" }} onClick={e => this.assignDelegates()}>
                        Assign Delegate to User
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
