import React from "react";
import { getRange } from "../../utils/Helpers";

export default class Spinner extends React.Component<{ initial?: boolean; style?: dynamic<any>; color?: string }> {
    render() {
        return (
            <div className="sk-circle" style={this.props.style}>
                {getRange(1, 12, 1).map(i => (
                    <div key={i} className={`sk-circle${i} sk-child`}>
                        <i
                            className={`brandPrimary--bg ${this.props.initial ? "initial" : ""}`}
                            style={{ ...(this.props.color ? { backgroundColor: this.props.color } : null) }}
                        />
                    </div>
                ))}
            </div>
        );
    }
}
