import React, { ChangeEvent, Component } from "react";
import { connect } from "react-redux";
import { doGetEmailDrip, doGetGroups } from "../../../../actions/lists";
import { doUpsertEmailDrip } from "../../../../actions/inserts";
import { expand, deepMerge, apiRequest } from "../../../../utils/Helpers";
import { EmailDrip } from "../../../../utils/Models";
import { doSetFooterFunctions, createAlert } from "../../../../actions/utils";
import appConfig from "../../../../../config/config.dev";
import Toggle from "../../../atoms/Toggle";
import Tooltip from "../../../atoms/Tooltip";
import Button from "../../../atoms/Button";
import { UtilActions } from "../../../../../client/actions";
import { ConfirmModal } from "../../../atoms/Modal";
import { Wrapper, Row } from "../../../atoms/Layout/";
import Multiselect from "react-bootstrap-multiselect";

interface ISelectOption {
    label: string;
    value?: string;
    selected?: boolean;
    children?: ISelectOption[];
}

class EditEmailDrip extends Component<
    { eid: string; clients: dynamic<Mongo.client>; user: Mongo.clientAdmin },
    Mongo.IEmailDripCampaign & {
        eid: string;
        filterLang: string;
        cid: string | null;
        insert: boolean;
        save_protect: boolean;
        previewModalOpen: boolean;
        emailAddress: string;
        emailToSend: string;
        roleOpts: ISelectOption[] | null;
        groupOpts: ISelectOption[] | null;
    }
> {
    static allowSuper = true;
    static allowClientAdmin = false;
    static allowSuperClientAdmin = true;
    static allowAdmin = true;
    static allowApiAdmin = false;
    static allowCurator = false;
    static allowReports = true;

    static uiShare = {
            en: {
                email1: {
                    subject: `3 easy steps to get started on [title]`,
                    body: `Hi [name],
                    <br><br>Thank you for activating your account on [title]. Now, you’re ready to start engaging your readers and building your brand on social media, so it\’s time to <a href=[website]#!/intro > log in</a> and share. It’s as easy as 1-2-3. 
                    <br><br>1 - Find an article you or your network would be interested in (we have hundreds of thousands!)
                    <br/>2 - Click “Share this Week” or “Edit & Share” to schedule and share the content 
                    <br/>3 - Wait a day and check your <a href=[website]#!/main/shares/history >History</a> to see who has liked or commented on your article. 
                    <br><br>Having trouble finding an article? You can see the most popular content shared by other users <a href=[website]#!/main/reports/topArticles> here</a>, or add your own article by clicking <a href=[website]#!/main/compose>“Compose”</a>.
                    <br><br><br/>Happy sharing!`
                },
                email2: {
                    subject: `Your colleagues are finding success with [title], and so can you.`,
                    body: `Hi [name],
                    <br><br>Your colleagues are engaging clients and building their digital brands by posting great articles to their social media channels. You can find success with social too, and it only takes a few clicks!
                    <br><br>Find the most popular content across the organization <a href=[website]#!/main/reports/topArticles>here</a> and share your favorite article with your networks by clicking "Share This Week" or "Edit and Share".
                    <br><br>There’s tons of content waiting for you in [title]. <a href=[website]#!/intro>Log in</a> and get started now to start conversations, build relationships, and generate leads online.
                    <br><br>Share on!`
                },
                email3: {
                    subject: `How can we help?`,
                    body: `Hi [name],
                    <br><br>We've noticed you haven't shared any content from [title], your new social sharing tool. We want to make sure you have everything you need to get started, so we put together a video that shows you how to use the tool and some best practices for finding success.
                    <br><br>It only takes a minute to start engaging your followers, watch the <a href=[website]#!/main/help>full video here.</a>
                    <br><br>When you’re ready, you can log into [title] <a href=[website]#!/intro>here</a> and start building your digital brand.
                    <br><br>Happy Sharing!`
                }
            },
            fr: {
                email1: {
                    subject: `Commencer en seulement 3 étapes avec [title]`,
                    body: `Salut [name],
                    <br><br>Merci d'avoir activé votre compte sur [title]. Maintenant, vous êtes prêt à commencer à engager vos lecteurs et à construire votre réputation digitale sur les résesociaux. Il est donc temps de vous <a href=[website]#!/intro > connecter </a> et de publier. C'est aussi simple que cela !
                    <br><br>1 - Trouvez un article intéressant pour vous-même ou votre communautés (nous avons des centaines de milliers d’articles !)
                    <br/>2 - Cliquez sur "Programmer" ou "Personnaliser" pour planifier et partager le contenu.
                    <br/>3 - Attendez au moins une journée avec de vérifiez votre <a href=[website]#!/main/shares/history> Historique </a> et voir qui a aimé ou a commenté votre publication.
                    <br><br>Vous avez du mal à trouver du contenu ? Vous pouvez voir les contenus les plus tendances partagés par d'autres utilisateurs <a href=[website]#!/main/reports/topArticles> ici</a>, ou ajoutez vos propre articles en cliquant sur <a href=[website]#!/main/compose>“Composer”</a>.
                    <br><br><br/>Bonne publication ! `
                },
                email2: {
                    subject: `Vos collègues ont beaucoup de succès avec [title], et vous pouvez en faire de même.`,
                    body: `Salut [name],
                    <br><br>Vos collègues engagent les clients et créent leur réputation numériques en publiant des contenus sur leurs réseaux sociaux. Vous pouvez atteindre le même succès que vos collègues sur les réseaux sociaux en seulement quelques clics !
                    <br><br>Trouvez les contenus les plus populaires de votre entreprise <a href=[website]#!/main/reports/toparticles> ici </a> et publier vos articles préférés sur vos réseaux sociaux en cliquant sur "Programmer" ou "Personnaliser".
                    <br><br>Il y a de nombreux contenus qui vous attend sur [titre]. <a href=[website]#!/Intro> Connectez-vous </a> et commencez maintenant à entamer la conversation, créer des nouvelles relations et de générer des prospects en ligne.
                    <br><br>Bonne publication !`
                },
                email3: {
                    subject: `Comment pouvons-nous vous aider ?`,
                    body: `Salut [name],
                    <br><br>Nous avons remarqué que vous n'avez partagé aucun contenu sur [title], votre nouvel outil de partage social. Nous voulons nous assurer que vous avez tout ce dont vous avez besoin pour commencer, donc nous vous proposons une vidéo qui vous montre comment utiliser l'outil et quelques bonnes pratiques.
                    <br><br>Il ne vous faut qu'une minute pour commencer à engager votre audience, regarder la <a href=[website]#!/main/help>video ici.</a>
                    <br><br>Lorsque vous êtes prêt, vous pouvez vous connecter à [title] <a href=[website]#!/intro>ici </a> et commenver à construire votre réputation en ligne.
                    <br><br>Bonne publication !`
                }
            }
        };
        static uiActivate = {
            en: {
                email1: {
                    subject: `Welcome to [title] - Activate your account`,
                    body: `Welcome [name]<br/><br/>

                    Congratulations! You've been given access to Grapevine6 Publish for Enterprise. To get started, download the apps here and login with your social accounts. <br/><br/>
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    If you are a current Grapevine6 user you can upgrade your account to Enterprise here: [website]#!/main/settings/account by clicking on the Upgrade button. <br/><br/>

                    Or to use the app in a browser, click <a href="[website]?accessCode=[code]&email=[email]">here</a> to activate your account or copy and paste this link in your browser -
                    <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>

                    Interested in learning more? Watch this 1-minute video to help get started: <a href="https://www.youtube.com/watch?v=L4lWyNyJF6E">https://www.youtube.com/watch?v=L4lWyNyJF6E</a><br/><br/>
                    <br/><br/>
                    Any questions, please let us know at <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Thanks!`
                },
                email2: {
                    subject: `Welcome to [title] - Activate your account`,
                    body: `Welcome [name]<br/><br/>

                    Congratulations! You've been given access to Grapevine6 Publish for Enterprise. To get started, download the apps here and login with your social accounts. <br/><br/>
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    If you are a current Grapevine6 user you can upgrade your account to Enterprise here: [website]#!/main/settings/account by clicking on the Upgrade button. <br/><br/>

                    Or to use the app in a browser, click <a href="[website]?accessCode=[code]&email=[email]">here</a> to activate your account or copy and paste this link in your browser -
                    <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>

                    Interested in learning more? Watch this 1-minute video to help get started: <a href="https://www.youtube.com/watch?v=L4lWyNyJF6E">https://www.youtube.com/watch?v=L4lWyNyJF6E</a><br/><br/>
                    <br/><br/>
                    Any questions, please let us know at <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Thanks!`
                },
                email3: {
                    subject: `Welcome to [title] - Activate your account`,
                    body: `Welcome [name]<br/><br/>

                    Congratulations! You've been given access to Grapevine6 Publish for Enterprise. To get started, download the apps here and login with your social accounts. <br/><br/>
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    If you are a current Grapevine6 user you can upgrade your account to Enterprise here: [website]#!/main/settings/account by clicking on the Upgrade button. <br/><br/>

                    Or to use the app in a browser, click <a href="[website]?accessCode=[code]&email=[email]">here</a> to activate your account or copy and paste this link in your browser -
                    <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>

                    Interested in learning more? Watch this 1-minute video to help get started: <a href="https://www.youtube.com/watch?v=L4lWyNyJF6E">https://www.youtube.com/watch?v=L4lWyNyJF6E</a><br/><br/>
                    <br/><br/>
                    Any questions, please let us know at <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Thanks!`
                }
            },
            fr: {
                email1: {
                    subject: `Bienvenue à [title] - Activez votre compte`,
                    body: `Bienvenue [name]<br/><br/>
                    Félicitations ! Vous avez maintenant accès à [title] pour entreprise.
                    Pour vous connecter à partir de l'application mobile :
                    Téléchargez l'application
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    Connectez-vous avec votre identifiant et mot de passe LinkedIn. <br/><br/>

                    Si vous êtes déjà un utilisateur de [title], vous pouvez mettre à niveau votre compte via une licence Entreprise ici : <a href="[website]#!/main/settings/account">[website]#!/main/settings/account</a>  en cliquant sur le bouton de mise à niveau. <br/><br/>
                    Pour vous connecter à partir d'un navigateur web (exemple : Google Chrome ou Explorer)
                    Cliquez ici pour activer votre compte ou copier et coller ce lien dans votre navigateur - <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>
                    <br/><br/>
                    Des questions ? Merci de communiquer avec nous à <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Merci !`
                },
                email2: {
                    subject: `Bienvenue à [title] - Activez votre compte`,
                    body: `Bienvenue [name]<br/><br/>
                    Félicitations ! Vous avez maintenant accès à [title] pour entreprise.
                    Pour vous connecter à partir de l'application mobile :
                    Téléchargez l'application
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    Connectez-vous avec votre identifiant et mot de passe LinkedIn. <br/><br/>

                    Si vous êtes déjà un utilisateur de [title], vous pouvez mettre à niveau votre compte via une licence Entreprise ici : <a href="[website]#!/main/settings/account">[website]#!/main/settings/account</a>  en cliquant sur le bouton de mise à niveau. <br/><br/>
                    Pour vous connecter à partir d'un navigateur web (exemple : Google Chrome ou Explorer)
                    Cliquez ici pour activer votre compte ou copier et coller ce lien dans votre navigateur - <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>
                    <br/><br/>
                    Des questions ? Merci de communiquer avec nous à <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Merci !`
                },
                email3: {
                    subject: `Bienvenue à [title] - Activez votre compte`,
                    body: `Bienvenue [name]<br/><br/>
                    Félicitations ! Vous avez maintenant accès à [title] pour entreprise.
                    Pour vous connecter à partir de l'application mobile :
                    Téléchargez l'application
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    Connectez-vous avec votre identifiant et mot de passe LinkedIn. <br/><br/>

                    Si vous êtes déjà un utilisateur de [title], vous pouvez mettre à niveau votre compte via une licence Entreprise ici : <a href="[website]#!/main/settings/account">[website]#!/main/settings/account</a>  en cliquant sur le bouton de mise à niveau. <br/><br/>
                    Pour vous connecter à partir d'un navigateur web (exemple : Google Chrome ou Explorer)
                    Cliquez ici pour activer votre compte ou copier et coller ce lien dans votre navigateur - <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>
                    <br/><br/>
                    Des questions ? Merci de communiquer avec nous à <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Merci !`
                }
            }
        };
        static uiExpiredLinkedIn = {
            en: {
                email1: {
                    subject: `Reminder to reauthorize social network connections`,
                    body: `Hello [name]<br/><br/>

                    We recently noticed in the past week that your social network connections require your attention <br/><br/>

                    Please visit <a href="[website]#!/main/settings/account">here</a> to review and refresh your connection <br/><br/>

                    Any questions, please let us know at <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Thanks!`
                },
                email2: {
                    subject: `Welcome to [title] - Activate your account`,
                    body: `Welcome [name]<br/><br/>

                    Congratulations! You've been given access to Grapevine6 Publish for Enterprise. To get started, download the apps here and login with your social accounts. <br/><br/>
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    If you are a current Grapevine6 user you can upgrade your account to Enterprise here: [website]#!/main/settings/account by clicking on the Upgrade button. <br/><br/>

                    Or to use the app in a browser, click <a href="[website]?accessCode=[code]&email=[email]">here</a> to activate your account or copy and paste this link in your browser -
                    <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>

                    Interested in learning more? Watch this 1-minute video to help get started: <a href="https://www.youtube.com/watch?v=L4lWyNyJF6E">https://www.youtube.com/watch?v=L4lWyNyJF6E</a><br/><br/>
                    <br/><br/>
                    Any questions, please let us know at <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Thanks!`
                },
                email3: {
                    subject: `Welcome to [title] - Activate your account`,
                    body: `Welcome [name]<br/><br/>

                    Congratulations! You've been given access to Grapevine6 Publish for Enterprise. To get started, download the apps here and login with your social accounts. <br/><br/>
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    If you are a current Grapevine6 user you can upgrade your account to Enterprise here: [website]#!/main/settings/account by clicking on the Upgrade button. <br/><br/>

                    Or to use the app in a browser, click <a href="[website]?accessCode=[code]&email=[email]">here</a> to activate your account or copy and paste this link in your browser -
                    <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>

                    Interested in learning more? Watch this 1-minute video to help get started: <a href="https://www.youtube.com/watch?v=L4lWyNyJF6E">https://www.youtube.com/watch?v=L4lWyNyJF6E</a><br/><br/>
                    <br/><br/>
                    Any questions, please let us know at <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Thanks!`
                }
            },
            fr: {
                email1: {
                    subject: `Rappel de réauthentification des connexions aux réseaux sociaux`,
                    body: `Bonjour [name]<br/><br/>

                    Nous avons récemment remarqué au cours de la semaine dernière que vos connexions aux réseaux sociaux nécessitent votre attention<br/><br/>

                    Veuillez visiter le [website]#!/main/settings/account pour vérifier et actualiser votre connexion <br/><br/>

                    Si vous avez des questions, veuillez nous en informer à <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Merci!`
                },
                email2: {
                    subject: `Welcome to [title] - Activate your account`,
                    body: `Welcome [name]<br/><br/>

                    Congratulations! You've been given access to Grapevine6 Publish for Enterprise. To get started, download the apps here and login with your social accounts. <br/><br/>
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    If you are a current Grapevine6 user you can upgrade your account to Enterprise here: [website]#!/main/settings/account by clicking on the Upgrade button. <br/><br/>

                    Or to use the app in a browser, click <a href="[website]?accessCode=[code]&email=[email]">here</a> to activate your account or copy and paste this link in your browser -
                    <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>

                    Interested in learning more? Watch this 1-minute video to help get started: <a href="https://www.youtube.com/watch?v=L4lWyNyJF6E">https://www.youtube.com/watch?v=L4lWyNyJF6E</a><br/><br/>
                    <br/><br/>
                    Any questions, please let us know at <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Thanks!`
                },
                email3: {
                    subject: `Welcome to [title] - Activate your account`,
                    body: `Welcome [name]<br/><br/>

                    Congratulations! You've been given access to Grapevine6 Publish for Enterprise. To get started, download the apps here and login with your social accounts. <br/><br/>
                    iOS:<br/>
                    https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>
                    Android:<br/>
                    https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                    If you are a current Grapevine6 user you can upgrade your account to Enterprise here: [website]#!/main/settings/account by clicking on the Upgrade button. <br/><br/>

                    Or to use the app in a browser, click <a href="[website]?accessCode=[code]&email=[email]">here</a> to activate your account or copy and paste this link in your browser -
                    <a href="[website]?accessCode=[code]&email=[email]">[website]?accessCode=[code]&email=[email]</a><br/><br/>

                    Interested in learning more? Watch this 1-minute video to help get started: <a href="https://www.youtube.com/watch?v=L4lWyNyJF6E">https://www.youtube.com/watch?v=L4lWyNyJF6E</a><br/><br/>
                    <br/><br/>
                    Any questions, please let us know at <a href="mailto:contact@grapevine6.com">contact@grapevine6.com</a><br/><br/>
                    Thanks!`
                }
            }
        };

    constructor(props) {
        super(props);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._sendDripEmail = this._sendDripEmail.bind(this);
        this._changeRoles = this._changeRoles.bind(this);
        this._changeGroups = this._changeGroups.bind(this);
        let eid = "";
        const insert = true;
        if (props.match.params.eid) {
            eid = props.match.params.eid;
        }
        let groups = [];
        let roles = [];
        let roleOpts = [];
        let groupOpts = [];
        this.state = {
            ...EmailDrip(),
            filterLang: "en",
            eid,
            cid: props.user.cid || "",
            insert,
            save_protect: false,
            previewModalOpen: false,
            emailAddress: props.user.email || "",
            emailToSend: "",
            activationWeeklyFrequency: 0,
            groups,
            roles,
            roleOpts,
            groupOpts,
        };
    }

    setRoleOptions() {
        const roleOpts: ISelectOption[] = [];
        if (this.props.user.cid && Object.keys(this.props.clients?.[this.props.user.cid]?.roles || {}).length > 0) {
            roleOpts.push({ value: "", label: "All", selected: this.state.roles.length > 0 ? false : true });
            roleOpts.push({ value: "None", label: "No Roles", selected: false });
            roleOpts.push({ value: "allGroup", label: "All Roles", selected: false });
            let options: ISelectOption[] = [];
            let selectedCount = 0;
            const roles = Object.values(this.props.clients![this.props.user.cid]!.roles);
            for (const role of roles) {
                let included = this.state.roles.includes(role.rid);
                if (included) selectedCount += 1;
                options.push({value: role.rid, label: role.name, selected: included})
            }
            roleOpts.push({
                label: `Select roles (${selectedCount} Selected)`,
                children: options
            });
        }
        this.setState({roleOpts: roleOpts});
    }

    async setGroupOptions() {
        const groupOpts: ISelectOption[] = [];
        if (this.props.user.cid) {
            let groups = await doGetGroups(this.props.user.cid);
            if (groups && Object.keys(groups).length) {
                groupOpts.push({ value: "", label: "All", selected: this.state.groups.length > 0 ? false : true });
                groupOpts.push({ value: "None", label: "No Group", selected: false });
                groupOpts.push({ value: "allGroup", label: "All Groups", selected: false });
                let options: ISelectOption[] = [];
                let selectedCount = 0;
                for (const gid in groups) {
                    let included = this.state.groups.includes(gid);
                    options.push({ value: gid, label: groups[gid]!, selected: included });
                    selectedCount += included? 1 : 0;
                }
                groupOpts.push({
                    label: `Select groups (${selectedCount} Selected)`,
                    children: options
                });
            }
        }
        this.setState({ groupOpts: groupOpts });
    }

    async _getFromEmail(cid: string) {
        const { clients } = this.props;
        const emailReg = /\b([^\s]+@[^\s]+)\b/g; // To Extract the email from the sendgrid from as it return name and email
        const client = cid && clients[cid] || null;
        const fromEmail = client
            ? client.fromEmail || (await UtilActions.doGetWhiteLabelSettings(client.configName))?.emailFrom || ""
            : "";
        return fromEmail.match(emailReg)?.[0] || "";
    }
    async componentDidMount() {
        if (this.state.cid) {
            const senderEmail = await this._getFromEmail(this.state.cid);
            this.setState({ senderEmail });
        }
        if (this.state.eid) {
            const emailDrip = await doGetEmailDrip(this.state.eid);
            if (emailDrip && emailDrip[this.state.eid]) {
                this.setState({ insert: false, ...emailDrip[this.state.eid] });
            }
        }
        if (this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin || this.props.user.isReports) {
            doSetFooterFunctions(this._onSubmit, null, this.state.insert ? "Create" : "Update");
        }
        this.setRoleOptions();
        await this.setGroupOptions();
    }

    componentWillUnmount() {
        doSetFooterFunctions(null, null);
    }

    _onFieldUpdate(field, event) {
        const value = event.target ? event.target.value : event;
        let state = {};
        if (field.indexOf(".") > -1) {
            const obj = expand(field, value); // mutate key into object with value
            const firstKey = Object.keys(obj)[0]; // get the outer most property
            const origValue = { [firstKey]: this.state[firstKey] }; // current value in state
            state = deepMerge(origValue, obj); // merge both values together and push into state
        } else {
            state[field] = value;
        }

        this.setState(state);
    }

    _changeRoles(event: ChangeEvent) {
        const roleOpts = JSON.parse(JSON.stringify(this.state.roleOpts!));
        const allOpt = roleOpts[0].value === "" ? roleOpts[0] : null;
        const noOpt = roleOpts[1] && roleOpts[1].value === "None" ? roleOpts[1] : null;
        const allGroups = roleOpts[2] && roleOpts[2].value === "allGroup" ? roleOpts[2] : null;
        const optionGroup = roleOpts.find(opt => opt.children)! as Require<ISelectOption, "children">;

        let selectedCount = optionGroup ? optionGroup.children.filter(opt => opt.selected).length : 0;
        // Deselect 'ALL' if it wasn't clicked otherwise always set (no toggle)
        if (allOpt) allOpt.selected = event[0].value === "";
        if (noOpt) noOpt.selected = event[0].value === "None";
        if (allGroups) allGroups.selected = event[0].value === "allGroup";
        // If ALL selected then nothing else should be selected
        if (allOpt && allOpt.selected) {
            // ALL
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (noOpt && noOpt.selected) {
            // No Group
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (allGroups && allGroups.selected) {
            // All Groups
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = true;
            }
            selectedCount = optionGroup.children.length;
        } else {
            optionGroup.children.find(opt => opt.value === event[0].value)!.selected = event[0].selected;
            selectedCount += event[0].selected ? 1 : -1;
            if (allGroups && selectedCount === optionGroup.children.length) allGroups.selected = true;
        }
        // If nothing selected then ALL should be selected (toggles ALL back on if someone deselected it)
        if (selectedCount === 0) {
            if (allOpt && allOpt.selected) {
                allOpt.selected = true;
            } else if (noOpt && noOpt.selected) {
                allOpt!.selected = false;
                noOpt.selected = true;
                allGroups!.selected = false;
            } else {
                optionGroup.children[0].selected = true;
                selectedCount = 1;
            }
        }
        optionGroup.label = `Select roles (${selectedCount} Selected)`;
        let rids = optionGroup.children
            .filter(opt => opt.selected)
            .map(opt => opt.value);
        let roles:string[] = [];
        for (const rid of rids) {
            if (rid) roles.push(rid);
        }
        if (!roles) {
            roles = [];
        }
        this.setState({roleOpts: roleOpts, roles: roles});
    }

    _changeGroups(event: ChangeEvent) {
        const groupOpts = JSON.parse(JSON.stringify(this.state.groupOpts!));

        const allOpt = groupOpts[0].value === "" ? groupOpts[0] : null;
        const noOpt = groupOpts[1] && groupOpts[1].value === "None" ? groupOpts[1] : null;
        const allGroups = groupOpts[2] && groupOpts[2].value === "allGroup" ? groupOpts[2] : null;
        const optionGroup = groupOpts.find(opt => opt.children)! as Require<ISelectOption, "children">;

        let selectedCount = optionGroup ? optionGroup.children.filter(opt => opt.selected).length : 0;
        // Deselect 'ALL' if it wasn't clicked otherwise always set (no toggle)
        if (allOpt) allOpt.selected = event[0].value === "";
        if (noOpt) noOpt.selected = event[0].value === "None";
        if (allGroups) allGroups.selected = event[0].value === "allGroup";
        // If ALL selected then nothing else should be selected
        if (allOpt && allOpt.selected) {
            // ALL
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (noOpt && noOpt.selected) {
            // No Group
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (allGroups && allGroups.selected) {
            // All Groups
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = true;
            }
            selectedCount = optionGroup.children.length;
        } else {
            optionGroup.children.find(opt => opt.value === event[0].value)!.selected = event[0].selected;
            selectedCount += event[0].selected ? 1 : -1;
            if (allGroups && selectedCount === optionGroup.children.length) allGroups.selected = true;
        }
        // If nothing selected then ALL should be selected (toggles ALL back on if someone deselected it)
        if (selectedCount === 0) {
            if (allOpt && allOpt.selected) {
                allOpt.selected = true;
            } else if (noOpt && noOpt.selected) {
                allOpt!.selected = false;
                noOpt.selected = true;
                allGroups!.selected = false;
            } else {
                optionGroup.children[0].selected = true;
                selectedCount = 1;
            }
        }
        optionGroup.label = `Select groups (${selectedCount} Selected)`;
        let gids = optionGroup.children
            .filter(opt => opt.selected)
            .map(opt => opt.value);
        let groups:string[] = [];
        for (const gid of gids) {
            if (gid) groups.push(gid);
        }
        if (!groups) {
            groups = [];
        }
        this.setState({ groupOpts, groups });
    }

    __validateEmail(email) {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(email);
    }

    _onSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        if (!this.state.cid && !this.state.consumer) {
            createAlert("Please select a client", "error");
            return;
        }
        if (!this.state.type) {
            createAlert("Please select a type", "error");
            return;
        }
        if (!this.state.senderEmail || !this.__validateEmail(this.state.senderEmail)) {
            createAlert("Please enter valid sender email");
            return;
        }
        if (!this.state.senderName) {
            createAlert("Please enter sender name");
            return;
        }
        const date_activated = !this.state.active ? null : this.state.date_activated || new Date();
        const emailDrip = EmailDrip({ ...this.state, date_activated });
        emailDrip.consumer = this.state.consumer || this.state.cid === "ALL_CONSUMERS";
        return doUpsertEmailDrip(emailDrip);
    }

    _sendDripEmail() {
        this._onFieldUpdate("previewModalOpen", false);
        apiRequest(`${appConfig.API_URL}/sendDripEmail`, "POST", {
            cid: this.state.cid,
            userObj: !this.state.previewModalOpen ? this.props.user : { name: "Johnny" },
            subject: this.state.ui[this.state.filterLang][this.state.emailToSend].subject,
            body: this.state.ui[this.state.filterLang][this.state.emailToSend].body,
            to: this.state.emailAddress,
            from: `${this.state.senderName}<${this.state.senderEmail}>`,
            emailNumber: `emailDrip${this.state.emailToSend.replace(/\D/g, "")}`
        }).then(data => {
            if (data.valid) {
                createAlert("Please check your inbox", "success");
            }
        });
    }

    _renderClientList() {
        const { clients, user } = this.props;
        if (!user) {
            return;
        }
        if (this.state.insert && user.isSuper) {
            return [
                <div className="form__label">Client:</div>,
                <div
                    className="select__wrapper"
                    style={{
                        maxWidth: "190px"
                    }}
                >
                    <select
                        onChange={async event => {
                            this._onFieldUpdate("cid", event);
                            if(event.target.value && event.target.value !== "ALL_CONSUMERS"){
                                const senderEmail = await this._getFromEmail(event.target.value);
                                this.setState({ senderEmail });
                            }
                        }}
                        value={this.state.cid}
                        style={{ minWidth: "170px" }}
                    >
                        <option value="">Please select a client</option>
                        <option value="ALL_CONSUMERS">All Consumers</option>
                        <optgroup label="Clients">
                            {clients &&
                                Object.values(clients).map(client => {
                                    return (
                                        <option key={client!.cid} value={client!.cid}>
                                            {client!.name}
                                        </option>
                                    );
                                })}
                        </optgroup>
                    </select>
                </div>
            ];
        } else {
            return [
                <div className="form__label">Client:</div>,
                <span
                    style={{
                        fontSize: "14px",
                        lineHeight: "18px"
                    }}
                >
                    <select disabled={true} style={{ minWidth: "170px" }}>
                        <option>
                            {clients[this.state.cid]
                                ? clients[this.state.cid]!.name
                                : this.state.consumer
                                ? "All Consumers"
                                : "---"}
                        </option>
                    </select>
                </span>,
                <br />
            ];
        }
    }

    renderEmailSection(title: string, emailValue: string) {
        const disabled = this.state.filterLang.length == 0 || !this.state.type;
        const blankEmail =
            disabled ||
            !this.state.ui[this.state.filterLang][emailValue] ||
            !this.state.ui[this.state.filterLang][emailValue].subject ||
            !this.state.ui[this.state.filterLang][emailValue].body;
        return (
            <Row>
                <div className="ibox">
                    <div className="ibox-title">
                        <h5>{title}</h5>
                        <div className="ibox-tools">
                            <Button
                                className="btn brandPrimary--bg  btn--sm"
                                disabled={(this.state.ui[this.state.filterLang][emailValue].subject == "") || (this.state.ui[this.state.filterLang][emailValue].body == "")}
                                onClick={e => {
                                    blankEmail
                                        ? e?.preventDefault()
                                        : this.setState({ previewModalOpen: true, emailToSend: emailValue });}}>
                                    <i className={`fa fa-eye`} /> Preview
                            </Button>
                            <Button
                                className="btn brandPrimary--bg  btn--sm"
                                onClick={e => {
                                    this.state.type && this.state.type == "Have Access But Not Activated"
                                        ? this._onFieldUpdate(`ui`, EditEmailDrip.uiActivate)
                                        : this.state.type == "Expired LinkedIn"
                                        ? this._onFieldUpdate(`ui`, EditEmailDrip.uiExpiredLinkedIn) :
                                        this._onFieldUpdate(`ui.${this.state.filterLang}.${emailValue}`, EditEmailDrip.uiShare[this.state.filterLang][emailValue]);}}>
                                    <i/> Use Default
                            </Button>
                        </div>
                    </div>
                    <div className="ibox-content">
                        {
                            this.state.type && (this.state.type == "Have Access But Not Activated" || this.state.type == "Expired LinkedIn") && emailValue == "email1"?
                                <div className="form__row">
                                    <div className="form__group form__group--full">
                                        <label htmlFor="weeksBetweenReminders" className="form__label">Weeks Between Each Reminder:
                                        <Tooltip
                                            className="tooltip__maxwidth"
                                            style={{
                                                marginLeft: "5px"
                                            }}
                                        >
                                            Enter a non-negative number indicating the number of weeks between each reminder email.
                                            A value of 0 indicates the emails to be sent daily.
                                            A value of 1 indicates the emails to be sent every week, 2 indicates the emails to be sent every 2 weeks, etc.
                                        </Tooltip>
                                        </label>
                                        <input
                                            id="weeksBetweenReminders"
                                            type="text"
                                            className="form__value"
                                            onChange={event =>
                                                this.setState({
                                                    activationWeeklyFrequency: parseInt(event.target.value.replace(/\D/, ''))
                                                        ? parseInt(event.target.value.replace(/\D/, '')) : 0
                                                })
                                            }
                                            onFocus={event => event.target.select()}
                                            disabled={disabled || (this.state.cid.length == 0 && !this.state.consumer)}
                                            value={
                                                this.state.activationWeeklyFrequency ? this.state.activationWeeklyFrequency : 0
                                            }
                                        />
                                    </div>
                                </div>
                                : <div></div>
                        }
                        {
                            this.state.cid &&
                            this.state.cid !== "ALL_CLIENTS" &&
                            emailValue == "email1" &&
                            Object.keys(this.props.clients?.[this.state.cid]?.roles || {}).length > 0 && (
                                <div
                                    style={{
                                        marginBottom: "30px"
                                    }}
                                >
                                    <label className="control-label">Roles:</label>
                                    <div
                                        className="select-wrapper"
                                    >
                                        <Multiselect
                                            data={this.state.roleOpts}
                                            multiple="multiple"
                                            autosize={true}
                                            onChange={this._changeRoles}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.cid &&
                            this.state.cid !== "ALL_CLIENTS" &&
                            this.state.type != "Have Access But Not Activated" &&
                            emailValue == "email1" &&
                            Object.keys(this.props.clients?.[this.state.cid]?.groups || {}).length > 0 && (
                                <div
                                    style={{
                                        marginBottom: "30px"
                                    }}
                                >
                                    <label className="control-label">Groups:</label>
                                    <div
                                        className="select-wrapper"
                                    >
                                        <Multiselect
                                            data={this.state.groupOpts}
                                            multiple="multiple"
                                            autosize={true}
                                            onChange={this._changeGroups}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <label htmlFor="emailSubject" className="form__label">Email Subject:</label>
                                <input
                                    id="emailSubject"
                                    type="text"
                                    className="form__value"
                                    onChange={event =>
                                        this._onFieldUpdate(`ui.${this.state.filterLang}.${emailValue}.subject`, event)
                                    }
                                    onFocus={event => event.target.select()}
                                    disabled={disabled || (this.state.cid.length == 0 && !this.state.consumer)}
                                    value={
                                        this.state.filterLang.length == 0
                                            ? ""
                                            : this.state.ui &&
                                              this.state.ui[this.state.filterLang] &&
                                              this.state.ui[this.state.filterLang][emailValue] &&
                                              this.state.ui[this.state.filterLang][emailValue].subject
                                            ? this.state.ui[this.state.filterLang][emailValue].subject
                                            : ""
                                    }
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <label htmlFor="emailBody" className="form__label">Email Body:</label>
                                <textarea
                                    id="emailBody"
                                    className="form__value"
                                    onChange={event =>
                                        this._onFieldUpdate(`ui.${this.state.filterLang}.${emailValue}.body`, event)
                                    }
                                    style={{ minHeight: "250px" }}
                                    disabled={disabled || (this.state.cid.length == 0 && !this.state.consumer)}
                                    value={
                                        this.state.filterLang.length == 0
                                            ? ""
                                            : this.state.ui &&
                                              this.state.ui[this.state.filterLang] &&
                                              this.state.ui[this.state.filterLang][emailValue] &&
                                              this.state.ui[this.state.filterLang][emailValue].body
                                            ? this.state.ui[this.state.filterLang][emailValue].body
                                            : ""
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        );
    }
    render() {
        return (
            <Wrapper>
                <Row>
                    <div className="ibox">
                        <div className="ibox-title">
                            <h5>{this.state.insert ? "Create" : "Edit"} Campaign</h5>
                            <div className="ibox-tools">
                                <a
                                    className="btn brandPrimary--bg  btn--sm"
                                    href="/siteTemplates/img/Help.pdf"
                                    target="_blank"
                                >
                                    {" "}
                                    <i className="fa fa-question-circle" /> Instructions
                                </a>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <div className="form__row">
                                <div className="form__group">
                                    {this.props.user.isSuper && (
                                        <div>
                                            {this._renderClientList()}
                                            <br />
                                        </div>
                                    )}
                                    <div className="form__label">Type:</div>
                                    <div className="select__wrapper">
                                        <select
                                            onChange={e => {
                                                this._onFieldUpdate("type", e);
                                                const value = e.target ? e.target.value : e;
                                                value && value == "Have Access But Not Activated"
                                                    ? this._onFieldUpdate(`ui`, EditEmailDrip.uiActivate)
                                                    : value == "Expired LinkedIn"
                                                        ? this._onFieldUpdate(`ui`, EditEmailDrip.uiExpiredLinkedIn) :
                                                        this._onFieldUpdate(`ui`, EditEmailDrip.uiShare);
                                            }
                                            }
                                            disabled={this.props.user.isClientAdmin}
                                            value={this.state.type}
                                            aria-label="Select type"
                                        >
                                            <option value="">Please select a type</option>
                                            <option value="Activated Without Shares">Activated Without Shares</option>
                                            <option value="Have Access But Not Activated">Have Access But Not Activated</option>
                                            <option value="Expired LinkedIn">Expired LinkedIn Token</option>
                                        </select>
                                        </div>
                                        <br />
                                        <div className="form__label">Language:</div>
                                        <div className="select__wrapper">
                                        <select
                                            onChange={e => this._onFieldUpdate("filterLang", e)}
                                            value={this.state.filterLang}
                                            aria-label={this.state.filterLang}
                                        >
                                            <option value="en">English</option>
                                            <option value="fr">French</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form__group">
                                    <div className="form__label">Sender Name: </div>
                                    <div>
                                        <input
                                            className="admins__search"
                                            type="text"
                                            disabled={this.props.user.isClientAdmin}
                                            onChange={event => this._onFieldUpdate("senderName", event)}
                                            onFocus={event => event.target.select()}
                                            value={this.state.senderName}
                                            aria-label="Sender Name"
                                        />
                                    </div>
                                    <br />
                                    <div className="form__label">
                                        Sender Address:
                                        <Tooltip
                                            className="tooltip__maxwidth"
                                            style={{
                                                marginLeft: "5px"
                                            }}
                                        >
                                            Please ensure this email address is not blocked on your company's email
                                            server, or these emails could automatically be deleted or sent to spam. We
                                            recommended using either a team member's corporate email address or the
                                            address used for all other LiveSocial communications with users (i.e.
                                                notifications@livesocial.seismic.com)
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <input
                                            className="admins__search"
                                            type="text"
                                            disabled={this.props.user.isClientAdmin}
                                            onChange={event => this._onFieldUpdate("senderEmail", event)}
                                            onFocus={event => event.target.select()}
                                            value={this.state.senderEmail}
                                            aria-label="Sender Email"
                                        />
                                    </div>
                                    <br />
                                    <Toggle
                                        label="Campaign Enabled"
                                        name="active"
                                        disabled={this.props.user.isClientAdmin}
                                        value={this.state.active}
                                        onToggle={event => this._onFieldUpdate("active", !this.state.active)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
                {this.renderEmailSection(
                    this.state.type && this.state.type == "Have Access But Not Activated" ?
                    "Email - Sent in intervals for accounts that have access but not activated" :
                    this.state.type && this.state.type == "Expired LinkedIn" ?
                    "Email - Sent in intervals for accounts that have expired LinkedIn token" :
                    "Email 1 - Sent 6 days after account activation if the user has not shared an article",
                    "email1"
                )}
                {this.state.type && this.state.type == "Activated Without Shares" ? this.renderEmailSection(
                    "Email 2 - Sent 12 days after account activation if the user has not shared an article" ,
                    "email2"
                ): <div></div>
                }
                {this.state.type && this.state.type == "Activated Without Shares" ? this.renderEmailSection(
                    "Email 3 - Sent 18 days after account activation if the user has not shared an article" ,
                    "email3"
                ): <div></div>}
                {this.state.previewModalOpen && (
                    <ConfirmModal
                        title={"Email Preview"}
                        button={`Send`}
                        force={false}
                        open={true}
                        onConfirm={() => {
                            if (!this.state.senderName) {
                                createAlert("Please enter sender name");
                                return;
                            }
                            if (this.state.senderEmail) {
                                if (!this.__validateEmail(this.state.senderEmail)) {
                                    createAlert("Sender Email format is invalid, please provide a proper email");
                                    return;
                                }
                                if (!this.state.emailAddress || !this.__validateEmail(this.state.emailAddress)) {
                                    createAlert("Please provide a proper email");
                                    return;
                                }
                                this._sendDripEmail();
                            } else {
                                createAlert("Please enter sender email address", `error`);
                            }
                        }}
                        onCancel={() => {
                            this._onFieldUpdate("previewModalOpen", false);
                        }}
                    >
                        <label className="form__label">
                            A copy of this email will be sent to the following email address.
                        </label>{" "}
                        <br />
                        <br />
                        <div
                            style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "auto" }}
                        >
                            <span style={{ marginRight: "10px", fontSize: "15px", verticalAlign: "sub" }}>
                                Email Address:{" "}
                            </span>
                            <input
                                type="text"
                                className="form__value"
                                onChange={event => this._onFieldUpdate("emailAddress", event)}
                                onFocus={event => event.target.select()}
                                value={this.state.emailAddress}
                                style={{ maxWidth: "80%" }}
                            />
                        </div>
                    </ConfirmModal>
                )}
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});
export default connect(mapStateToProps)(EditEmailDrip);
