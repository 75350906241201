import BaseAction from "../utils/BaseAction";

export type IEmailActions = ClassInstance<typeof _EmailActions>;

class _EmailActions extends BaseAction {
    async previewActivationEmail(cid: string, email: string, subject: string, body: string, lang?: string) {
        return this.apiRequestWithErrorAlert(`/previewEmail`, `POST`, {
            cid, email, subject, body, lang
        });
    }
    async previewECommunicationEmail(cid: string, email: string, subject: string, body: string, emailPreheader: string, lang?: string) {
        return this.apiRequestWithErrorAlert(`/previewECommunicationEmail`, `POST`, {
            cid, email, subject, body, lang, emailPreheader
        });
    }
    async previewNotSharedInThirtyDaysEmail(cid: string, userObj: dynamic, subject: string, body: string, lang: string) {
        return this.apiRequestWithErrorAlert(`/previewNotSharedInThirtyDaysEmail`, `POST`, {
            cid, userObj, subject, body, lang
        });
    }
}

export const EmailActions = new _EmailActions();
