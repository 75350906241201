import BaseAction from "../utils/BaseAction";

export type IStreamActions = ClassInstance<typeof _StreamActions>;

class _StreamActions extends BaseAction {
    async doList<K extends keyof ServerTypes.Console.IStream = keyof ServerTypes.Console.IStream>(
        cid: string,
        query: {
            search?: string;
            sort?: { [key: string]: number };
            skip?: number;
            limit?: number;
        },
        projectedFields?: K[]
    ) {
        const data = await this.apiRequestWithErrorAlert<{ items: Pick<ServerTypes.Console.IStream, K>[]; count: number }>(
            `/client/stream/list`,
            `POST`,
            { cid, ...query, ...(projectedFields ? { projectedFields } : null) },
        );
        if (data && data.items && data.items.length) {
            return { items: data.items, total: data.count };
        } else {
            return { items: [], total: 0 };
        }
    }
}

export const StreamActions = new _StreamActions();
