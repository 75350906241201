import React, { Component } from "react";
import { connect } from "react-redux";

class Logo extends Component<{ className: string; logo: string }> {
    render() {
        return <img src={this.props.logo} className={this.props.className} alt="Seismic logo" />;
    }
}

const mapStateToProps = state => ({ logo: state.utils.logo });

export default connect(mapStateToProps)(Logo);
