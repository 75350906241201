import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import AddECommunicationMessage from "./AddECommunicationMessage";

interface ModalProps {
    className: string;
    parent: AddECommunicationMessage;
    message: string;
    prompt: string;
    save: string;
    cancel: string;
    open: boolean;
    close: () => void;
}
interface ModalState {
    message: string;
    prompt: string;
    save: string;
    cancel: string;
    open: boolean;
}
export default class BeeEditorConfirmModal extends Component<ModalProps, ModalState>
{
    constructor(props) {
        super(props);
        this._closeModal = this._closeModal.bind(this);
        this._onConfirmed = this._onConfirmed.bind(this);
        this._onCancel = this._onCancel.bind(this);
        this.state = {
            message: props.message,
            prompt: props.prompt,
            save: props.save,
            cancel: props.cancel,
            open: props.open || false,
        };
    }

    componentWillReceiveProps(newProps) {
        if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open });
        }
    }

    _closeModal() {
        this.setState({ open: false });
        this.props.parent.setState({
            modals: { ...this.props.parent.state.modals, contentEditorRowNameConfirm: false }
        });
    }
    _onConfirmed(){
        this._closeModal();
        this.props.parent.resolveConfirm(true);
    }
    _onCancel(){
        this._closeModal();
        this.props.parent.resolveConfirm(false);
    }

    render() {
        return (
            <Modal
                title="Confirm"
                className="modal-modal-background modal-header-none modal-header-none"
                open={this.state.open}
                closeAction={this._closeModal}
                backdrop='static'>
                <form>
                    <div className="form__row">
                        <div className="form__group form__group--full" />
                    </div>
                    <div className="form__row">
                        <div className="form__group form__group--full">
                            <label className="form__label">{this.state.message}</label>
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__group form__group--full">
                            <label className="form__label">{this.state.prompt}</label>
                        </div>
                    </div>
                    <div className="form__row">
                        <div
                            className="form__group form__group--full"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row"
                            }}
                        >
                            <Link onClick={() => this._onCancel()} style={{ textDecoration: "underline" }}>
                            {this.state.cancel}
                            </Link>
                            <Button onClick={() => this._onConfirmed()} style={{ marginLeft: "auto" }} >
                            {this.state.save}
                            </Button>
                        </div>
                    </div>
                </form>

            </Modal>
    );
    }
}
