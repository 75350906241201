import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import { createAlert } from "../../../actions";

interface Props {
    className?: string;
    open?: boolean;
    update: (file: {
        thumbnailFile?: File | null;
        thumbnailUrl?: string;
    }) => void;
    close: () => void;
    defaultThumbnail: string | undefined;
    customThumbnailUrl?: string | undefined;
    customThumbnailFile?: File | null;
    item?: Mongo.IClientArticle;
    removeCustomThumbnail?: () => void;
}

interface State {
    open: boolean;
    url: string;
    fileName: string;
    enteredFile: boolean;
    enteredUrl: boolean;
    existingThumbnailUrl: string;
    removeExisingThumbnail: boolean;
}
export default class ThumbnailModal extends Component<Props, State> {
    thumbnailFile: HTMLInputElement | null = null;
    constructor(props) {
        super(props);
        this.state = {
            open: props.open || false,
            url: "",
            fileName: "",
            enteredFile: false,
            enteredUrl: false,
            existingThumbnailUrl: props.item.Image || "",
            removeExisingThumbnail: false
        };
    }

    componentWillReceiveProps(newProps) {
        if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open });
        }
    }

    _close = () => {
        const { enteredFile, enteredUrl, removeExisingThumbnail } = this.state;
        const { customThumbnailUrl, customThumbnailFile, item: article } = this.props;
        if (!customThumbnailFile && enteredFile) this.setState({ fileName: "" });
        if (!customThumbnailUrl && enteredUrl) this.setState({ url: "" });
        if (removeExisingThumbnail && article?.Image) this.setState({ removeExisingThumbnail: true, existingThumbnailUrl: article.Image });
        if (this.props.close) {
            this.props.close();
        }
    }

    _onChange = (e) => {
        e.preventDefault();
        this.setState({ url: e.target.value, ...(e.target.value.length ? { enteredUrl: true } : { enteredUrl: false }) });
    }

    _uploadThumbnail = () => {
        $("#thumbnail_file").trigger("click");
    };

    _handleThumbnailFileChange = () => {
        if (this.thumbnailFile && this.thumbnailFile.files) {
            const { name: fileName, type: fileType, size: fileSize } = this.thumbnailFile.files[0];
            const maxImgSize = 1024 * 1024 * 5;
            if (!/(jpg|jpeg|png)$/i.test(fileType)) {
                createAlert("Invalid file type, please upload a .png file");
                this._removeThumbnailFile();
                return;
            }
            if (fileSize > maxImgSize) {
                createAlert("Please upload an image under 5MB");
                this._removeThumbnailFile();
                return;
            }
            this.setState({ url: "", fileName, enteredFile: true });
        }
    }

    _removeThumbnailFile = () => {
        this.thumbnailFile = null;
        this.setState({ fileName: "", enteredFile: false });
    }

    _update = () => {
        const { url: thumbnailUrl, enteredFile, removeExisingThumbnail } = this.state;
        const thumbnailFile = enteredFile && this.thumbnailFile ? this.thumbnailFile!.files![0] : null;
        if (removeExisingThumbnail && this.props.removeCustomThumbnail) this.props.removeCustomThumbnail();
        if (this.props.update) {
            this.props.update({
                thumbnailUrl,
                thumbnailFile
            });
        }
    }
    _removeCustomThumbnail = () => {
        this.setState({ existingThumbnailUrl: "", removeExisingThumbnail: true});
    }

    render() {
        const { url, open, fileName, existingThumbnailUrl } = this.state;
        const { item: article } = this.props;
        return (
            <Modal
                open={open}
                className={this.props.className || ""}
                title="Custom Thumbnail"
                closeAction={this._close}
            >
                <div>
                    <div className="form__row">
                        <div
                            className="form__group form__group--full"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column"
                            }}
                        >
                            <strong>Add Image URL</strong>
                            <br />
                            <input
                                id="thumbnail_url"
                                type="text"
                                value={url}
                                onChange={e => this._onChange(e)}
                                style={{ width: "75%" }}
                                disabled={fileName.length >= 1}
                            />
                            <br />
                            <span>OR</span>
                            <br /><br />
                            <div style={{padding: "0 80px 0 80px"}}>
                                {!fileName && article && (!existingThumbnailUrl || existingThumbnailUrl == article.defaultThumbnail) && <Link onClick={() => this._uploadThumbnail()} style={{ textDecoration: "underline" }}>Upload an image</Link>}
                                {fileName && <span style={{ padding: "0 10px" }}>{fileName}</span>}
                                {fileName && fileName.length && <Link className="text" onClick={() => this._removeThumbnailFile()}><span className="brandPrimary--color" style={{ textDecoration: "underline" }}>Remove</span></Link>}
                                {!fileName && existingThumbnailUrl && existingThumbnailUrl.length > 0 && existingThumbnailUrl != article!.defaultThumbnail && <span style={{ padding: "0 10px" }}>{existingThumbnailUrl}</span>}
                                {!fileName && existingThumbnailUrl && existingThumbnailUrl.length > 0 && existingThumbnailUrl != article!.defaultThumbnail && <Link className="text" onClick={() => this._removeCustomThumbnail() }><span className="brandPrimary--color" style={{ textDecoration: "underline" }}>Remove</span></Link>}
                            </div>
                            <input id="thumbnail_file" type="file" accept=".jpg, .jpeg, .png" ref={input => this.thumbnailFile = input} style={{ visibility: "hidden" }} onChange={this._handleThumbnailFileChange} />
                            <br /><br />
                            <Button
                                style={{ marginLeft: "auto" }}
                                onClick={() => {
                                    let proceed = true;
                                    if (!this.state.enteredFile && !this.state.enteredUrl) proceed = confirm("A thumbnail will be automatically generated using the first frame of the video. Do you want to proceed?");
                                    if (proceed) {
                                        this._update();
                                        this.props.close();
                                    }
                                }}
                            >
                                Done
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
