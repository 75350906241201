import moment from "moment";
import React, { useState, useEffect } from "react";
import { createAlert } from "../../actions/utils";
import { getLocalizedDate, getOffset, parseDate } from "../../utils/Helpers";
import Period from "./Period";

interface IPeriods {
    periods?: ServerTypes.Console.ISubscriptionPeriod[];
    update: (periods?: ServerTypes.Console.ISubscriptionPeriod[]) => void;
}

export const validatePeriods = (currentDate: moment.Moment, periods: ServerTypes.Console.ISubscriptionPeriod[] | null) => {
    if (!periods || !periods.length) {
        return true;
    }
    const result = periods!.map(period => {
        const _minDate = period.startDate ? new Date(period.startDate) : null;
        const _maxDate = period.endDate ? new Date(period.endDate) : null;
        if(_maxDate){
            _maxDate.setDate(_maxDate.getDate() + 1);
        }
        return (
            (!_maxDate || (_maxDate && currentDate.isSameOrBefore(moment(_maxDate)))) &&
            (!_minDate || (_minDate && currentDate.isSameOrAfter(moment(_minDate))))
        );
    });
    return result.some(value => {
        return value === true;
    });
}

export const parsePeriod = (p: Partial<ServerTypes.Console.ISubscriptionPeriod>) => {
    let startDate = new Date();
    let endDate = new Date();
    let sdtz = `${startDate.getTimezoneOffset()}`;
    let edtz = `${endDate.getTimezoneOffset()}`;
    if (p.sdtz && p.startDate && p.startDate.length == 10) {
        startDate = parseDate(p.startDate, "00:00:00.000", p.sdtz).date;
        sdtz = p.sdtz;
    } else if (p.startDate) {
        startDate = new Date(p.startDate);
        if (p.sdtz) {
            // we got a ISO string date from the server as well as a timezone, lets re-localize it for the browser
            startDate = new Date(`${getLocalizedDate(startDate, getOffset(p.sdtz))}T00:00:00.000Z`);
        }
    }

    if (p.edtz && p.endDate && p.endDate.length == 10) {
        endDate = parseDate(p.endDate, "00:00:00.000", p.edtz).date;
        edtz = p.edtz;
    } else if (p.endDate) {
        endDate = new Date(p.endDate);
        if (p.edtz) {
            // we got a ISO string date from the server as well as a timezone, lets re-localize it for the browser
            endDate = new Date(`${getLocalizedDate(endDate, getOffset(p.edtz))}T00:00:00.000Z`);
        }
    } else {
        endDate.setMonth(endDate.getMonth() + 3);
    }

    return {
        startDate: `${startDate.toISOString().substr(0, 10)}`,
        sdtz: `${sdtz}`,
        endDate: `${endDate.toISOString().substr(0, 10)}`,
        edtz: `${edtz}`,
        subID: p.subID
    };
};

export default (props: IPeriods) => {
    const [periods, setPeriods] = useState((props.periods || []).map(p => parsePeriod(p)));
    const [minDate, setMinDate] = useState(new Date());
    const updateMinDate = (_periods: IPeriods["periods"]) => {
        const lastPeriod = _periods && _periods.length ? _periods[_periods.length -1] : null;
        if(lastPeriod && !moment(lastPeriod.endDate).isSame(moment(minDate), 'day')){
            const endDate = lastPeriod.endDate;
            const end = new Date(`${endDate}T23:59:59.999Z`);
            const min = new Date(end);
            setMinDate(min);
        }
        if (lastPeriod && moment(lastPeriod.endDate).isBefore(moment(new Date()))) {
            const min = new Date(moment().toISOString());
            min.setDate(min.getDate());
            setMinDate(min);
        }
        if(!lastPeriod){
            setMinDate(new Date());
        }
    };
    useEffect(() => {
        updateMinDate(periods);
    }, []);

    return (
        <>
            {...periods.map(({ startDate, endDate, subID }, index) => {
                return (
                    <Period
                        key={index}
                        index={index}
                        startDate={startDate}
                        endDate={endDate}
                        removable={new Date(`${endDate}T23:59:59.999Z`).getTime() < new Date().getTime() || !!!subID}
                        remove={index => {
                            const _periods = [...periods];
                            _periods.splice(index, 1);
                            setPeriods(_periods);
                            props.update(_periods);
                            updateMinDate(_periods);
                        }}
                    />
                );
            })}
            {periods.length < 4 && (
                <Period
                    key={"add"}
                    index={Math.min(periods.length, 3)}
                    startDate={minDate.toISOString().substr(0, 10)}
                    add={(startDate, endDate) => {
                        const period = parsePeriod({ startDate, endDate });
                        if(moment(startDate).isAfter(moment(endDate))){
                            createAlert("You cannot create start date greater than end date");
                            return;
                        }
                        for (const { startDate: _sd, endDate: _ed } of periods) {
                            if (
                                moment(startDate).isSameOrAfter(moment(_sd)) &&
                                moment(startDate).isSameOrBefore(moment(_ed))
                            ) {
                                createAlert("You cannot create overlapping subscription periods");
                                return;
                            }
                        }
                        const _periods = [...periods, period];
                        setPeriods(_periods);
                        props.update(_periods);
                        updateMinDate(_periods);
                    }}
                />
            )}
        </>
    );
};
