import React, { Component } from "react";
import Modal from "react-bootstrap-modal";
import { createAlert } from "../../../actions/utils";
import Button from "../../atoms/Button";
import Tooltip from "../../atoms/Tooltip";
import { ClientRoleActions } from "../../../actions/";
import Pagination from "../../molecules/Pagination";
// tslint:disable-next-line:no-submodule-imports
import debounce from "lodash/debounce";

export default class RoleUserSearchModal extends Component<
    {
        selectUser: (user: { cid: string; uid: string; name: string; email: string; rid?: string }) => void;
        selectAll?: (
            users: Array<
                Pick<Mongo.clientUsers, "cid" | "uid" | "rid" | "email" | "firstName" | "lastName"> & {
                    name: string;
                }
            >
        ) => JSX.Element;
        selectRole: () => Promise<boolean>;
        closeModal: () => void;
        isSelected: (uid: string) => boolean;
        cid: string;
        excludeRid: string;
        roles: { [rid: string]: Mongo.IUserRole };
    },
    {
        users: Array<
            Pick<Mongo.clientUsers, "cid" | "uid" | "rid" | "email" | "firstName" | "lastName"> & {
                name: string;
            }
        >;
        pagination: { limit: number; skip: number; total: number };
        open: boolean;
        search: string;
    }
> {
    refreshUsers: Function;
    _createAlert: Function;
    constructor(props) {
        super(props);

        this.refreshUsers = debounce(this._refreshUsers, 500);
        this._createAlert = debounce((message, type) => {
            createAlert(message, type);
        }, 500);
        this.state = {
            open: true,
            users: [],
            search: "",
            pagination: { limit: 20, skip: 0, total: 0 }
        };
    }

    componentDidMount() {
        this._refreshUsers();
    }

    async _refreshUsers() {
        try {
            const { items, count } = await ClientRoleActions.doGetUsers(
                this.props.cid,
                undefined,
                this.props.excludeRid,
                this.state.search,
                this.state.pagination.limit,
                this.state.pagination.skip * this.state.pagination.limit
            );
            this.setState({
                users: items,
                pagination: { ...this.state.pagination, total: count }
            });
        } catch (err) {
            this.setState({
                users: [],
                pagination: { total: 0, limit: 0, skip: 0 }
            });
        }
    }

    selectUser(user) {
        if (this.props.selectUser) {
            this.props.selectUser(user);
        }
    }

    selectAll() {
        const { users } = this.state;
        for (const user of users) {
            this.selectUser(user);
        }
    }
    async selectRole() {
        if (this.props.selectRole) {
            if (await this.props.selectRole()) {
                this.closeModal();
            }
        }
    }
    closeModal() {
        if (this.props.closeModal) {
            this.props.closeModal();
        }
        this.setState({ open: false });
        createAlert('The modal for role user search has been successfully closed.', 'success');
    }
    render() {
        const { open } = this.state;
        return (
            <Modal show={open} onHide={() => this.closeModal()}>
                <Modal.Body>
                    <div className="table__filters" style={{ marginBottom: "10px" }}>
                        <div className="table__filters__option" style={{ width: "100%" }}>
                            <label htmlFor="search">Search:</label>
                            <div className="search">
                                <input
                                    id="search"
                                    className="filter__search"
                                    style={{ width: "100%" }}
                                    type="text"
                                    placeholder="Search by name or email"
                                    onChange={event =>
                                        this.setState(
                                            {
                                                search: event.target.value,
                                                pagination: { ...this.state.pagination, skip: 0, total: 0 }
                                            },
                                            () => {
                                                this.refreshUsers();
                                                this._createAlert('Search filter updated', 'success');
                                            }
                                        )
                                    }
                                    value={this.state.search}
                                />
                                <i className="fa fa-search" />
                            </div>
                        </div>
                    </div>
                    <table className="role__users">
                        <thead>
                            <tr>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody style={{ maxHeight: "250px", overflowY: "scroll" }}>
                            {(this.state.users || []).map(i => {
                                return (
                                    <tr
                                        key={`${i.rid}-${i.uid}`}
                                        onClick={e => this.selectUser(i)}
                                        className={`${this.props.isSelected(i.uid) ? "selected" : ""}`}
                                    >
                                        <td
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                flexBasis: "100%",
                                                height: "100%"
                                            }}
                                        >
                                            <Tooltip
                                                label={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            flexBasis: "100%"
                                                        }}
                                                    >
                                                        <span>{i.name.trim()}</span>
                                                        <span
                                                            className={
                                                                "role__label brandPrimary__dark--border brandPrimary__light--bg"
                                                            }
                                                            style={{ marginLeft: "auto" }}
                                                        >
                                                            {(
                                                                (this.props.roles || {})[i.rid!] || {
                                                                    name: "None"
                                                                }
                                                            ).name.trim()}
                                                        </span>
                                                    </div>
                                                }
                                            >
                                                {`${i.email}`.trim()}
                                            </Tooltip>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div
                        style={{
                            width: "100%",
                            marginTop: "20px"
                        }}
                    >
                        {this.props.selectAll ? this.props.selectAll(this.state.users) : ""}
                        <Pagination
                            style={{ marginLeft: "auto" }}
                            page={this.state.pagination.skip}
                            totalItems={this.state.pagination.total}
                            numPerPage={this.state.pagination.limit}
                            currItems={(this.state.users || []).length}
                            changePage={newPage =>
                                this.setState({ pagination: { ...this.state.pagination, skip: newPage } }, () =>
                                    this._refreshUsers()
                                )
                            }
                            showTotal={true}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: "flex" }}>
                    <Button onClick={e => this.closeModal()}>Cancel</Button>
                    <Button style={{ marginLeft: "auto" }} onClick={e => this.selectRole()}>
                        Add Users to Role
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
