export const isIE11 = () => {
    if (!!window["MSInputMethodContext"] && !!document["documentMode"]) {
        return true;
    }
    return false;
};

export const updateIEWidth = () => {
    const width = window.innerWidth;
    let newWidth = "";
    if ($("body").hasClass("mini-navbar") || width <= 768) {
        newWidth = width.toString();
        newWidth += "px";
    } else {
        newWidth = (width - 260).toString();
        newWidth += "px";
    }
    return newWidth;
};
