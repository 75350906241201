import React from "react";
import { Row } from "../components/atoms/Layout/";

interface IErrorContainerProps {
    error: JSX.Element;
}
export default (props: IErrorContainerProps) => {
    const { error } = props;
    return (
        <Row>
            <div className="container">
                <h1>{error}</h1>
            </div>
        </Row>
    );
};
