import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeading from "../../molecules/PageHeading";
import { ReportActions } from "../../../actions/ReportActions";
import { doGetReduxClients } from "../../../actions/lists";
import Chart from "../../molecules/Chart";
import NumberChart from "../../molecules/NumberChart";
import PercentCircle from "../../atoms/PercentCircle";
import { Wrapper, Row } from "../../atoms/Layout/";

class DelegationDashboard extends Component<
    { user: Mongo.clientAdmin; clients: { [cid: string]: Mongo.IClient } },
    {
        cid: string;
        gids: string[] | null;
        summary: {
            publisherFunnel: {
                Publishers: number;
                "Allowed Delegates": number;
                "Appointed Delegates": number;
                Shared: number;
            };
            delegateFunnel: {
                Total: number;
                Approved: number;
                Pending: number;
                "Signed In": number;
                Recommended: number;
            };
        } | null;
        loading: boolean;
        startDate: string | null;
        endDate: string | null;
    }
    > {
    static allowSuper = true;
    static allowSuperClientAdmin = true;
    static allowClientAdmin = true;
    constructor(props) {
        super(props);
        this._refresh = this._refresh.bind(this);

        this.state = {
            cid: props.user.isSuper ? "" : props.user.cid,
            gids:
                !(props.user.isClientAdmin || props.user.isSuper || props.user.isSuperClientAdmin) &&
                    props.user.groups &&
                    props.user.groups.length > 0
                    ? props.user.groups
                    : null,
            loading: false,
            summary: null,
            startDate: null,
            endDate: null
        };
    }

    async componentDidMount() {
        doGetReduxClients();
        this._refresh();
    }

    async _refresh() {
        const { cid, gids } = this.state;
        if (cid == "") {
            return;
        }
        this.setState({ loading: true }, async () => {
            const reports = await ReportActions.doGetDelegationSummaryReport(cid, gids);
            this.setState({ summary: reports, loading: false });
        });
    }

    _renderPublishers() {
        const { summary, loading } = this.state;
        const publishers = summary ? summary.publisherFunnel : null;

        const labels: string[] = [];
        const data: number[] = [];
        if (!loading && publishers) {
            for (const k in publishers) {
                labels.push(k);
                data.push(publishers[k]);
            }
        }
        return (
            <div id="Publisher funnelHorizontalBar" className="col-lg-6">
                <Chart
                    height={250}
                    title={"Publisher funnel"}
                    loading={!data.length}
                    data={data}
                    labels={labels}
                    type="horizontal-bar"
                    tooltip={
                        <span>
                            This report shows the funnel of adoption for delegation by publishers as of yesterday.<br />
                            Only publishers that have activated their account will be included in this report.<br />
                            <br />
                            Publishers: Total number of publishers that have the ability to share content.<br />
                            Allowed delegates: Total number of publishers that have the ability to appoint delegates.<br />
                            Appointed delegates: Total number of publishers that have appointed delegates.<br />
                            Shared: Total number of publishers that have shared recommended content from their delegates.
                        </span>
                    }
                />
            </div>
        );
    }
    _renderPublisherAdoption() {
        const { loading, summary } = this.state;
        const publishers = summary ? summary.publisherFunnel : null;
        return (
            <div className="col-lg-6">
                <NumberChart
                    title="Publisher adoption"
                    tooltip={
                        <span>
                            This card shows the ratio for each stage of adoption, as a percentage of the previous stage.<br />
                            <br />
                            Note: this card does not include delegates, admins, publishers who have<br />
                            not activated their account or publishers that are excluded from reporting
                        </span>
                    }
                    data={{
                        Allowed: {
                            label: ["Allowed"],
                            value: (
                                <PercentCircle
                                    percent={
                                        publishers && publishers["Publishers"] > 0
                                            ? Number(
                                                Math.round(
                                                    (publishers["Allowed Delegates"] / publishers["Publishers"]) * 100
                                                ).toFixed(0)
                                            )
                                            : 0
                                    }
                                />
                            )
                        },
                        Appointed: {
                            label: ["Appointed"],
                            value: (
                                <PercentCircle
                                    percent={
                                        publishers && publishers["Allowed Delegates"] > 0
                                            ? Number(
                                                Math.round(
                                                    (publishers["Appointed Delegates"] /
                                                        publishers["Allowed Delegates"]) *
                                                    100
                                                ).toFixed(0)
                                            )
                                            : 0
                                    }
                                />
                            )
                        },
                        Shared: {
                            label: ["Shared"],
                            value: (
                                <PercentCircle
                                    percent={
                                        publishers && publishers["Appointed Delegates"] > 0
                                            ? Number(
                                                Math.round(
                                                    (publishers["Shared"] / publishers["Appointed Delegates"]) * 100
                                                ).toFixed(0)
                                            )
                                            : 0
                                    }
                                />
                            )
                        }
                    }}
                    loading={loading || publishers == null}
                />
            </div>
        );
    }

    _renderDelegates() {
        const { summary, loading } = this.state;
        const delegates = summary ? summary.delegateFunnel : null;
        const labels: string[] = [];
        const data: number[] = [];
        if (delegates && !loading) {
            for (const k in delegates) {
                if (k === "Pending") {
                    continue;
                }
                labels.push(k == "Total" ? "Delegates" : k);
                data.push(delegates[k]);
            }
        }
        return (
            <div id="Delegate funnelHorizontalBar" className="col-lg-6">
                <Chart
                    height={250}
                    title={"Delegate funnel"}
                    loading={!data.length}
                    data={data}
                    labels={labels}
                    type="horizontal-bar"
                    tooltip={
                        <span>
                            This report shows the funnel of adoption for delegates as of yesterday.<br />
                            <br />
                            Delegates: Total number of delegates, including those that are pending, approved or unactivated.<br />
                            Approved: Total number of delegates that have been approved, regardless whether they have activated their account.<br />
                            Signed in: Total number of delegates that have signed in.<br />
                            Recommended: Total number of delegates that have recommended content.
                        </span>
                    }
                />
            </div>
        );
    }
    _renderDelegateAdoption() {
        const { summary, loading } = this.state;
        const delegates = summary ? summary.delegateFunnel : null;
        return (
            <div className="col-lg-6">
                <NumberChart
                    title="Delegate adoption"
                    tooltip={
                        <span>This card shows the ratio for each stage of adoption, as a percentage of the previous stage.<br />
                        Note: this card only shows delegates</span>
                    }
                    data={{
                        Approved: {
                            label: ["Approved"],
                            value: (
                                <PercentCircle
                                    percent={
                                        delegates && delegates["Total"] > 0
                                            ? Number(
                                                Math.round(
                                                    (delegates["Approved"] / delegates["Total"]) * 100
                                                ).toFixed(0)
                                            )
                                            : 0
                                    }
                                />
                            )
                        },
                        "Signed In": {
                            label: ["Signed In"],
                            value: (
                                <PercentCircle
                                    percent={
                                        delegates && delegates["Approved"] > 0
                                            ? Number(
                                                Math.round(
                                                    (delegates["Signed In"] / delegates["Approved"]) * 100
                                                ).toFixed(0)
                                            )
                                            : 0
                                    }
                                />
                            )
                        },
                        Recommended: {
                            label: ["Recommended"],
                            value: (
                                <PercentCircle
                                    percent={
                                        delegates && delegates["Approved"] > 0
                                            ? Number(
                                                Math.round(
                                                    (delegates["Recommended"] / delegates["Approved"]) * 100
                                                ).toFixed(0)
                                            )
                                            : 0
                                    }
                                />
                            )
                        }
                    }}
                    loading={loading || delegates == null}
                />
            </div>
        );
    }

    async refreshArticlePerformance(startDate: string, endDate: string) {
        const { cid, gids } = this.state;
        this.setState({ startDate, endDate });
        const articlePerformance = await ReportActions.doGetDelegationArticlesReport(cid, startDate, endDate, gids);
        return {
            Recommended: {
                label: ["Recommended"],
                value: articlePerformance && articlePerformance.total > 0 ? `${articlePerformance.total.toFixed(0)}` : 0
            },
            Shared: {
                label: ["Shared"],
                value:
                    articlePerformance && articlePerformance.approved > 0
                        ? `${articlePerformance.approved.toFixed(0)}`
                        : 0
            },
            Accepted: {
                label: ["Shared"],
                value: (
                    <PercentCircle
                        percent={
                            articlePerformance && articlePerformance.total > 0
                                ? Number(
                                    Math.round(
                                        (articlePerformance.approved / articlePerformance.total) * 100
                                    ).toFixed(0)
                                )
                                : 0
                        }
                    />
                )
            }
        };
    }

    _renderArticlePerformance() {
        const { cid } = this.state;
        return (
            <div className="col-lg-6">
                <NumberChart
                    title="Content Performance"
                    tooltip={
                        <span>
                            An overview of how much of the content being recommended by Delegates is being shared by Publishers<br />
                            Recommended: The number of unique pieces of content that have been recommended by a delegate to their publisher<br />
                            Shared: The number and percentage of unique pieces of content recommended by delegates that have been shared by their publishers.
                        </span>
                    }
                    refresh={async (startDate: string, endDate: string) =>
                        this.refreshArticlePerformance(startDate, endDate)
                    }
                    defaultFilters={{ cid }}
                    dateFilters={true}
                    downloadLabel="Download"
                    download={() => this._downloadDelegationReport()}
                    downloadLabelUpfront={true}
                />
            </div>
        );
    }

    async _downloadDelegationReport() {
        const { cid, gids, startDate, endDate } = this.state;
        const filters = {
            clientCode: cid,
            gids,
            sd: startDate,
            ed: endDate
        }
        await ReportActions.downloadDelegateUserActivityReport(filters);
    }

    render() {
        return (
            <Wrapper id="dashboard">
                <PageHeading title={`Delegation Dashboard`} />
                <Row style={{ marginBottom: "20px" }}>
                    <div className="col-lg-12 table__filters">
                        {this.props.user.isSuper && (
                            <div className="table__filters__option">
                                <label htmlFor="client">Client:</label>
                                <div className="select__wrapper">
                                    <select
                                        id="client"
                                        onChange={e => this.setState({ cid: e.target.value }, () => this._refresh())}
                                    >
                                        <option value="">Select a client</option>
                                        <optgroup label="Clients">
                                            {this.props.clients &&
                                                Object.keys(this.props.clients).length &&
                                                Object.keys(this.props.clients).map(cid => {
                                                    return <option value={cid}>{this.props.clients[cid].name}</option>;
                                                })}
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>
                </Row>
                {this.state.cid && [
                    <Row>
                        {this._renderPublishers()}
                        {this._renderDelegates()}
                    </Row>,
                    <Row>
                        {this._renderPublisherAdoption()}
                        {this._renderDelegateAdoption()}
                    </Row>,
                    <Row>{this._renderArticlePerformance()}</Row>
                ]}
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients });

export default connect(mapStateToProps)(DelegationDashboard);
