import React, { Component } from "react";

import Autosuggest from "react-autosuggest";
import { apiRequest } from "../../utils/Helpers";
import appConfig from "../../../config/config.dev";
import Button from "../atoms/Button";

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function escapeSuggestion(value) {
    return value.trim();
}

function getSuggestionValue(suggestion) {
    return suggestion;
}

function renderSuggestion(suggestion) {
    return <span>{suggestion}</span>;
}

export default class AutoSuggestDomain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            suggestions: [],
            selected: false,
            error: ""
        };
        this._onChange = this._onChange.bind(this);
        this._onSuggestionsFetchRequested = this._onSuggestionsFetchRequested.bind(this);
        this._onSuggestionsClearRequested = this._onSuggestionsClearRequested.bind(this);
        this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
        this._onSuggestionBlur = this._onSuggestionBlur.bind(this);
        this._storeInputReference = autosuggest => {
            if (autosuggest !== null) {
                this.input = autosuggest.input;
            }
        };
    }

    _onChange(_, { newValue }) {
        const { id, onChange } = this.props;
        this.setState({ value: newValue });
        onChange(id, newValue);
    }

    _onSuggestionsFetchRequested({ value }) {
        const { suggestions } = this.state;
        if (suggestions.indexOf(value) > -1) {
            return;
        }
        apiRequest(`${appConfig.API_URL}/getDomains`, "POST", {
            lang: this.props.lang,
            domain: escapeSuggestion(value)
        }).then(
            function(data) {
                this.setState({ suggestions: data });
            }.bind(this)
        );
    }
    _onSuggestionSelected(_, { suggestionValue, suggestionIndex }) {
        const { id, onSuggestionSelected } = this.props;
        onSuggestionSelected(id, suggestionValue);
        this.setState({ selected: suggestionIndex, value: "", suggestions: [] });
    }
    _onSuggestionBlur(event) {
        const { id, onSuggestionSelected } = this.props;

        let value = event.target.value.trim();
        if (value.length == 0) {
            return;
        }
        onSuggestionSelected(id, value);
        this.setState({ suggestions: [], value: "" });
        this.input.focus();
    }
    _onSuggestionsClearRequested() {}
    _shouldRenderSuggestions(value) {
        return value.trim().length >= 3;
    }

    render() {
        const { id, placeholder } = this.props;
        const { value, suggestions, error } = this.state;
        const inputProps = {
            placeholder,
            value,
            onChange: this._onChange,
            onBlur: this._onSuggestionBlur,
            ...("disabled" in this.props ? { disabled: this.props.disabled } : null)
        };
        if (error) {
            return <input type="text" className="error" disabled={true} value={error} />;
        }
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Autosuggest
                    style={{ flexBasis: "80%" }}
                    id={id}
                    ref={this._storeInputReference}
                    suggestions={suggestions}
                    shouldRenderSuggestions={this._shouldRenderSuggestions}
                    onSuggestionSelected={this._onSuggestionSelected}
                    onSuggestionsFetchRequested={this._onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this._onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </div>
        );
    }
}
