import React from "react";
import BaseStream, { IBaseStreamState } from "./BaseStream";
import CreatableInput from "../../../atoms/CreatableInput";
import { createAlert, doGetArticles, doUpdateArticleSequentialOrder } from "../../../../actions";
import PermissionSet, { IPermission } from "../../../molecules/PermissionSet";
import Tooltip from "../../../atoms/Tooltip";
import Button from "../../../atoms/Button";
import { getIndustries } from "../../../../utils/Helpers";
import Periods from "../../../molecules/Periods";
import { _ValueContainer, _hasMentionOrHashtag, _formatHashtagOrMention } from "../../../pages/articles/sharedArticle";
import SortableTable from "../../../molecules/SortableTable";
import { SortableHandle } from "react-sortable-hoc";

export default class CuratedStream extends BaseStream {
    getInitialState() {
        const props = super.getInitialState();
        return {
            ...props,
            ...(props.stream
                ? {
                    stream: {
                        ...props.stream,
                        contentSearch: true,
                        ...(!props.stream.sid ? { searchType: "" as "" } : null)
                    }
                }
                : null),
            canPreview: true,
            canPreviewOnCreate: true
        };
    }

    canPreview() {
        return this.state.stream.isApprovalRequired && !super.isInsert();
    }

    getSettings() {
        const settings = super.getSettings();
        const { client } = this.props;
        const { stream } = this.state;

        // dont apply for curated streams
        for (const setting of ["filters", "locations"]) {
            delete settings[setting];
        }

        return {
            ...settings,
            active: {
                label: "Active",
                value: !!stream.active,
                description: "Enables the stream to be shown in the app",
                onChange: () =>
                    this.setState({ stream: { ...stream, active: !stream.active } })
            },
            isApprovalRequired: {
                label: "Automatic Curation",
                value: !stream.isApprovalRequired,
                disabled: client.allowAutopost && (stream.allowAutopost || stream.sequentialContent),
                description:
                    "Allows articles from a search to be curated into this stream automatically.\nIf disabled, articles will have to be manually curated. This feature is not\navailable if \"Allow Stream Subscription\" or \"Enable Sequential Content\" is enabled",
                onChange: (value) => {
                    this.setState({
                        stream: {
                            ...stream,
                            isApprovalRequired: !value,
                            ...(!client.allowAutopost ? { allowAutopost: false } : null)
                        }
                    });
                }
            },
            ...(client.allowAutopost
                ? {
                    allowAutopost: {
                        label: "Allow Stream Subscription",
                        value: !!stream.allowAutopost,
                        disabled: (!stream.isApprovalRequired || stream.sequentialContent),
                        description:
                            "Allows users to automatically post content from a stream. This feature\ndoes not operate retroactively. Only users who are subscribed to a campaign\nbefore an article enters the stream will have the article automatically\nplaced in their schedule queue. This feature is not available if Automatic Curation is enabled",
                        onChange: (value) =>
                            this.setState({
                                stream: {
                                    ...stream,
                                    allowAutopost: value,
                                    ...(!value ? { showAllPosts: false } : null)
                                }
                            })
                    },
                    sequentialContent: {
                        label: "Enable Sequential Content",
                        value: !!stream.sequentialContent,
                        disabled: (!stream.isApprovalRequired || stream.allowAutopost),
                        description:
                            "Allows content curators to sequence autopost stream content in a specific order.\nThe system will automatically share content in this order for new stream subscribers \nbetween 3:25 and 4:25 PM EST on chosen days based on the selected \"Sequential Cadence\".",
                        onChange: (async (value) => {
                            if (!!value && !this.state.sequentialArticles.length && stream.sid) {
                                const articles = await doGetArticles(client.cid, stream.sid, "approved", 0, 1000, null, null, null, null, null, null, true, false, 1);
                                if (articles?.items?.length) {
                                    this.setState({
                                        sequentialArticles: articles.items.map(article => ({
                                            artid: article.artid,
                                            title: article.Title || "",
                                            domain: article.Domain || "",
                                            sequentialOrder: article.sequentialOrder,
                                            hasScheduled: article.hasScheduled || false,
                                        })).sort((art1, art2) => art1.sequentialOrder - art2.sequentialOrder)
                                    });
                                }
                            }
                            this.setState({
                                stream: {
                                    ...stream,
                                    sequentialContent: value,
                                    ...(!value ? { showAllPosts: false } : null) }
                            });
                        })
                    },
                    showAllPosts: {
                        label: "Preview Content Before Active Date",
                        hidden: !(stream.allowAutopost || stream.sequentialContent),
                        value: !!stream.showAllPosts,
                        description: "Show all posts in the stream to users, including posts prior to their active date.",
                        onChange: (value) =>
                            this.setState({ stream: { ...stream, showAllPosts: value } })
                    }
                }
                : null),
            isEditable: {
                label: "Comments Editable",
                value: !!stream.isEditable,
                description:
                    "Lets the user edit comments before sharing articles.",
                onChange: (value) =>
                    this.setState({ stream: { ...stream, isEditable: value } })
            },
            ...(
                // render hashtags and mentions field for console curated streams by default
                this._renderHashtagsAndMentions(stream.readOnly)
            )
        };
    }

    async canSubmit(stream: ServerTypes.Console.IStream) {
        if (!stream.isApprovalRequired) {
            if (!stream.terms || stream.terms.length < 1) {
                createAlert("Please include terms");
                return false;
            }
            if (!stream.industry) {
                createAlert("Please select an industry");
                return false;
            }
            if (stream.categories && stream.categories.length < 1) {
                createAlert("Please select a publication category");
                return false;
            }
            if (!stream.searchType) {
                createAlert(`Please select a search type`);
                return false;
            }
        }

        if (stream.allowAutopost && stream.subscriptionPeriods && !stream.subscriptionPeriods.length) {
            createAlert(`Please add a period to the subscription`);
            return false;
        }
        return true;
    }

    copyToClipboard = async () => {
        const { stream, whiteLabelURL } = this.state;
        const shareableLink = `${whiteLabelURL || "https://livesocial.seismic.com/"}?sid=${encodeURIComponent(stream.sid)}&sSubModal=open`;

        try {
            await navigator.clipboard.writeText(shareableLink);
            createAlert(`The text has been successfully copied`, `success`);
        } catch (err) {
            createAlert(`${err}`, `error`);
        }
    };

    moveSequentialArticles = async (index: number, direction: "top" | "bottom") => {
        const articles = this.state.sequentialArticles;
        const firstUnscheduled = articles.findIndex(article => !article.hasScheduled);
        const newIndex = direction === "top" ? firstUnscheduled : articles.length - 1;
        if (index === newIndex) return;
        const [removed] = articles.splice(index, 1);
        articles.splice(newIndex, 0, removed);
        const indices = [index, newIndex].sort((a, b) => a - b);
        articles.map((article, _index) => {
            if (_index >= indices[0] && _index <= indices[1]) {
                article.sequentialOrder = _index + 1;
            }
        });
        this.setState({ sequentialArticles: articles });
        try {
            await Promise.all(articles.map((article, _index) => {
                if (_index >= indices[0] && _index <= indices[1]) {
                    doUpdateArticleSequentialOrder(article.artid, _index + 1);
                }
            }));
            createAlert("Sequential Article Order successfully updated", "success");
        } catch (err) {
            createAlert(`${err}`, "error");
        }
    }

    renderBelowPanel() {
        const { stream } = this.state;
        const { client } = this.props;
        const DragHandle = SortableHandle(() => <i className="fa fa-bars" />);
        return [
            ...(stream.allowAutopost && client.allowAutopost
                ? [
                    <div className="ibox">
                        <div className="ibox-title"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                            <h5>
                                Periods
                                <Tooltip
                                    className="tooltip__maxwidth"
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "5px"
                                    }}>
                                    Periods for stream subscriptions
                                </Tooltip>
                            </h5>
                            {stream.sid
                                ? (
                                    <div className="ibox-tools">
                                        <Button
                                            className="btn--sm"
                                            onClick={this.copyToClipboard}
                                        >
                                            Copy link for stream subscription options
                                            <Tooltip
                                                className="tooltip__maxwidth"
                                                style={{
                                                    paddingLeft: "5px"
                                                }}>
                                                This button will copy the link that directs to the stream's subscription modal in app
                                            </Tooltip>
                                        </Button>
                                    </div>
                                ) : null
                            }
                        </div>

                        <div className="ibox-content">
                            <div className="form__row">
                                <div className="form__group--full">
                                    <Periods
                                        periods={stream.subscriptionPeriods}
                                        update={subscriptionPeriods => {
                                            this.setState({ stream: { ...stream, subscriptionPeriods } });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ]
                : stream.sequentialContent && client.allowAutopost ? [
                    <div className="ibox">
                        <div className="ibox-title">
                            <h5>
                                Sequential Article Order
                                <Tooltip
                                    className="tooltip__maxwidth"
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "5px"
                                    }}>
                                    The articles in the stream and the sequential order in which they will be posted. The articles on top will be posted first. Articles without the drag handle on the left side have already been shared and cannot be reordered. If this was a pre-existing stream with articles, the previously curated articles will not be added to the sequence.
                                </Tooltip>
                            </h5>
                        </div>
                        <div className="ibox-content">
                            <div className="form__row">
                                <div className="form__group--full">
                                    <SortableTable
                                        name="Sequential Article Order"
                                        loading={false}
                                        keys={["", "Title", "Domain", "Move to Top/Bottom"]}
                                        // TODO - Share Date
                                        // keys={["", "Title", "Domain", "Share Date", "Move to Top/Bottom"]}
                                        items={this.state.sequentialArticles.map(article => [
                                            !article.hasScheduled ? <DragHandle /> : "",
                                            article.title || "",
                                            article.domain || "",
                                            !article.hasScheduled ?
                                                <div>
                                                    <Button
                                                        className="btn--sm"
                                                        disabled={this.state.sequentialArticles.findIndex(_article => !_article.hasScheduled) === article.sequentialOrder - 1}
                                                        onClick={async () => await this.moveSequentialArticles(article.sequentialOrder - 1, "top")}
                                                        style={{ marginRight: "5px" }}
                                                    >
                                                        <i className="fa fa-angle-double-up" />
                                                    </Button>
                                                    <Button
                                                        className="btn--sm"
                                                        disabled={this.state.sequentialArticles.length - 1 === article.sequentialOrder - 1}
                                                        onClick={async () => await this.moveSequentialArticles(article.sequentialOrder - 1, "bottom")}
                                                    >
                                                        <i className="fa fa-angle-double-down" />
                                                    </Button>
                                                </div> : ""
                                        ])}
                                        // TODO - Share Date
                                        // items={this.state.articles.map(article => [
                                        //     !article.hasScheduled ? <DragHandle /> : "",
                                        //     article.title || "",
                                        //     article.domain || "",
                                        //     !stream.hasSequentialShares && article.sequentialOrder === 1
                                        //         ? new Date(new Date().toISOString()).getTime() < (new Date(new Date().setUTCHours(19, 45, 0, 0)).getTime() - (1000 * 60 * 20)) // 20 minutes before 19:45 UTC (when the job would run)
                                        //             ? new Date().toDateString()
                                        //             : new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()
                                        //         : !stream.hasSequentialShares
                                        //         ? new Date(new Date().toISOString()).getTime() < (new Date(new Date().setUTCHours(19, 45, 0, 0)).getTime() - (1000 * 60 * 20)) // 20 minutes before 19:45 UTC (when the job would run)
                                        //             ? new Date(new Date().setDate(new Date().getDate() + (stream.sequentialCadence! * (article.sequentialOrder - 1)))).toDateString()
                                        //             : new Date(new Date().setDate(new Date().getDate() + 1 + (stream.sequentialCadence! * (article.sequentialOrder - 1)))).toDateString()
                                        //         : new Date(new Date(stream.lastSequentialShareDate!).setDate(new Date(stream.lastSequentialShareDate!).getDate() + (stream.sequentialCadence! * (article.sequentialOrder - 1)))).toDateString(),
                                        //     !article.hasScheduled ?
                                        //         <div>
                                        //             <Button
                                        //                 className="btn--sm"
                                        //                 disabled={this.state.articles.findIndex(_article => !_article.hasScheduled) === article.sequentialOrder - 1}
                                        //                 onClick={async () => await this.moveSequentialArticles(article.sequentialOrder - 1, "top")}
                                        //                 style={{ marginRight: "5px" }}
                                        //             >
                                        //                 <i className="fa fa-angle-double-up" />
                                        //             </Button>
                                        //             <Button
                                        //                 className="btn--sm"
                                        //                 disabled={this.state.articles.length - 1 === article.sequentialOrder - 1}
                                        //                 onClick={async () => await this.moveSequentialArticles(article.sequentialOrder - 1, "bottom")}
                                        //             >
                                        //                 <i className="fa fa-angle-double-down" />
                                        //             </Button>
                                        //         </div> : ""
                                        // ])}
                                        onSortEnd={async (oldIndex, newIndex) => {
                                            if (oldIndex === newIndex) return;
                                            const articles = this.state.sequentialArticles;
                                            const [removed] = articles.splice(oldIndex, 1);
                                            articles.splice(newIndex, 0, removed);
                                            const indices = [oldIndex, newIndex].sort((a, b) => a - b);
                                            articles.map((article, index) => {
                                                if (index >= indices[0] && index <= indices[1]) {
                                                    article.sequentialOrder = index + 1;
                                                }
                                            });
                                            this.setState({ sequentialArticles: articles });
                                            try {
                                                await Promise.all(articles.map((article, index) => {
                                                    if (index >= indices[0] && index <= indices[1]) {
                                                        doUpdateArticleSequentialOrder(article.artid, index + 1);
                                                    }
                                                }));
                                                createAlert("Sequential Article Order successfully updated", "success");
                                            } catch (err) {
                                                createAlert(`${err}`, "error");
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ] : []),
            ...(!stream.isApprovalRequired
                ? [
                    <div className="ibox">
                        <div
                            className="ibox-title"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <h5>
                                Curated content preferences
                                  <Tooltip
                                    className="tooltip__maxwidth"
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    Articles will be automatically populated into this stream based on terms set
                                    within the search field and the settings underneath. Select the preview button to
                                    see what articles will be shown in this stream.
                                  </Tooltip>
                            </h5>
                            <div className="ibox-tools">
                                <Button
                                    className="btn--sm"
                                    onClick={() => this.setState({ preview: true } as IBaseStreamState)}
                                    disabled={stream.terms && stream.terms.length > 0 ? false : true}
                                >
                                    <i className="fa fa-eye" /> Preview
                                  </Button>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <form className="form">
                                <div className="form__row">
                                    <div className="form__row">
                                        <div
                                            className="form__group form__group--full"
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <label htmlFor="terms" className="form__label">Terms: *</label>

                                            <CreatableInput
                                                id="terms"
                                                value={(stream.terms || []).map(term => term.term)}
                                                onUpdate={(items: string[]) => {
                                                    if (items.length > 10) {
                                                        createAlert(
                                                            "You have reached the limit of 10 terms maximum",
                                                            "error"
                                                        );
                                                        return;
                                                    }
                                                    this.setState({
                                                        stream: {
                                                            ...stream,
                                                            terms: items
                                                                .map(item => ({ term: item, checked: true }))
                                                                .slice(0, 10)
                                                        }
                                                    });
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="form__row">
                                <div className="form__group">
                                    <label className="form__label">Settings:</label>
                                    <PermissionSet
                                        permissions={this.getSortedSettings(this.getPreferencesCardSettings()[0])[1]}
                                        _onChange={(key, value) =>
                                            this.setState({ stream: { ...stream, [key]: value } })
                                        }
                                        setNumber={"18"}
                                    />
                                </div>
                                <div className="form__group">
                                    <label className="form__label">&nbsp;</label>
                                    <PermissionSet
                                        permissions={this.getSortedSettings(this.getPreferencesCardSettings()[1])[1]}
                                        _onChange={(key, value) =>
                                            this.setState({ stream: { ...stream, [key]: value } })
                                        }
                                        setNumber={"19"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>,
                ]
                : []),
            ...super.renderBelowPanel(),
        ];
    }

    getPreferencesCardSettings() {
        const { stream, countries } = this.state;
        const settings: Array<dynamic<IPermission>> = [
            {
                locations: {
                    label: "Default Locations",
                    description: "Select default locations that you would like this stream to pull from",
                    value: stream.locations || [],
                    type: "multi-select",
                    multi: true,
                    values: (countries || []).map(location => ({ value: location.code, label: location.name })),
                    onChange: value => {
                        this.setState({ stream: { ...stream, locations: value as string[] || [] } });
                    },
                    placeholder: "Please select a location"
                },
                filters: {
                    label: "Content Filters",
                    type: "select",
                    value: stream.globalFilters ? "GLOBAL" : stream.filters == null ? "NONE" : "CUSTOM",
                    values: [
                        { value: "GLOBAL", label: "Global" },
                        { value: "CUSTOM", label: "Custom" },
                        { value: "NONE", label: "None" }
                    ],
                    onChange: value => {
                        const obj: dynamic = {};
                        switch (value) {
                            case "GLOBAL":
                                obj.globalFilters = true;
                                obj.filters = null;
                                break;
                            case "CUSTOM":
                                obj.globalFilters = false;
                                obj.filters = {};
                                break;
                            default:
                            case "NONE":
                                obj.globalFilters = false;
                                obj.filters = null;
                                break;
                        }
                        if (value == "CUSTOM") {
                            createAlert("Turned on custom filters, please scroll down to configure them", "success");
                        }
                        this.setState({ stream: { ...stream, ...obj } });
                    }
                },
                categories: {
                    label: "Publication Categories *",
                    type: "select",
                    description: "Categories that the content will closely match",
                    value: (stream.categories && stream.categories.length ? stream.categories : [""] || [""])[0],
                    values: [
                        "Arts Entertainment",
                        "Business",
                        "Computer Internet",
                        "Health",
                        "Law",
                        "Lifestyle",
                        "Marketing",
                        "News Media",
                        "Politics",
                        "Recreation Sports",
                        "Science Technology",
                        "Society Culture"
                    ].map(item => ({ value: item, label: item })),
                    onChange: value => this.setState({ stream: { ...stream, categories: [value as string] } }),
                    placeholder: "Please select a category"
                }
            },
            {
                industry: {
                    label: "Industry *",
                    type: "select",
                    description: "Industry where content will be taken from",
                    value: stream.industry || "",
                    values: getIndustries().map(item => ({ value: item.value, label: item.name })),
                    placeholder: "Please select an industry"
                },
                searchType: {
                    label: "Search Type *",
                    type: "select",
                    description:
                        "Provides articles that are more relevant based on the context of the search type selected",
                    value: stream.searchType,
                    values: [{ value: "PERS", label: "Personal" }, { value: "PROF", label: "Professional" }],
                    placeholder: "Please select a type"
                }
            }
        ];

        return settings;
    }
}
