import React, { Component } from "react";
import { connect } from "react-redux";
import {
    apiRequest,
    getIndustries,
    checkForDuplicates,
    isHttpsURL
} from "../../../utils/Helpers";
import { UserActions, DelegateActions, doUpsertUserECommunicationProfile } from "../../../actions";
import {
    doGetEntityConfigs,
    doGetReduxClient
} from "../../../actions/lists";
import { User } from "../../../utils/Models";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import ActionableList from "../../molecules/ActionableList";
import ActivationEmailModal from "../../molecules/modals/ActivationEmailModal";
import DelegateUserSearchModal from "../../molecules/modals/DelegateUserSearchModal";
import Button from "../../atoms/Button";

import ElementEditCard from "../../molecules/ElementEditCard";

import { history } from "../../../utils/store";
import ExtraNetworkList from "../../molecules/ExtraNetworkList";
import { RouteComponentProps } from "react-router-dom";
import Spinner from "../../atoms/Spinner";
import Row from "../../atoms/Layout/Row"
import Link from "../../atoms/Link";
import Tooltip from "../../atoms/Tooltip";

export interface IDelegateRow {
    uid: string,
    firstName: string,
    lastName: string,
    email: string,
    permissions: Mongo.IDelegatePermission
    permissionString?: string,
}

interface IEditUserProps extends RouteComponentProps<{uid: string; cid: string;}> {
    user: Mongo.clientAdmin;
    client: Mongo.client;
    clients: dynamic<Mongo.client>;
}

interface IEditUserState extends Mongo.IClientUser {
    hasEntityConfig: boolean;
    entityConfigList: string[];
    insert: boolean;
    activationEmail: boolean;
    sendToOne: boolean;
    roleECommEnabled: boolean;
    jsonECommProfile: string;
    updatingECommProfile: boolean;
    warningOpen: boolean;
    networks: string[];
    client?: Mongo.client;
    editSSOId?: boolean;
    oldSSOId?: string | null;
    delegatesArray: IDelegateRow[];
    dbDelegatesArray: IDelegateRow[];
    dbAllowDelegates: boolean;
    selectedRows: IDelegateRow[];
    showDelegateManagement: boolean;
    showAddDelegateModal: boolean;
}

class EditUser extends Component<IEditUserProps, IEditUserState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;
    editStartTime = new Date();
    constructor(props: IEditUserProps) {
        super(props);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._refreshUser = this._refreshUser.bind(this);
        this.getEmails = this.getEmails.bind(this);
        this._onUpdateECommProfile = this._onUpdateECommProfile.bind(this);

        let uid = "";
        let cid = "";
        let insert = true;
        if (props.match.params.cid) {
            cid = props.match.params.cid;
        }
        if (props.match.params.uid) {
            uid = props.match.params.uid;
            insert = false;
        }

        if (!props.user.isSuper && (!cid || cid.length <= 0)) {
            cid = props.user.cid!;
        }

        this.state = {
            ...User({ cid: cid, uid: uid }),
            hasEntityConfig: false,
            entityConfigList: [],
            insert,
            activationEmail: false,
            sendToOne: true,
            roleECommEnabled: false,
            jsonECommProfile: "",
            updatingECommProfile: false,
            warningOpen: false,
            networks: [],
            sms: {} as Mongo.IClientUserSMS,
            editSSOId: false,
            delegatesArray: [],
            dbDelegatesArray: [],
            dbAllowDelegates: false,
            selectedRows: [],
            showDelegateManagement: false,
            showAddDelegateModal: false,
        };
        if (this.state.tags.length == 0) {
            this.state.tags.push("");
        }
    }

    componentWillReceiveProps(newProps: IEditUserProps) {
        if (
            newProps.match.params.uid != this.props.match.params.uid ||
            newProps.match.params.cid != this.props.match.params.cid
        ) {
            const newState: Partial<IEditUserState> = User({ cid: newProps.match.params.cid, uid: newProps.match.params.uid });
            newState.insert = false;
            this.setState(newState as IEditUserState, () => this._refreshUser());
        }
    }

    async componentDidMount() {
        await this._refreshUser();
        if (this.state.cid) {
            doGetReduxClient(this.state.cid);
        }
    }

    //TODO: clean up better
    async _refreshUser() {
        const { insert, cid, uid } = this.state;
        const { clients } = this.props;
        const client = clients[cid];

        let reqs: Array<Promise<any>> = [];
        if (!insert) {
            reqs.push(apiRequest(`${appConfig.API_URL}/getUser?cid=${cid}&uid=${uid}`));
        }
        let entityConfigList = [];
        const hasEntityConfig =
            !!client?.hasEntityConfig;
        if (hasEntityConfig) {
            entityConfigList = await doGetEntityConfigs(cid);
        }
        this.setState({ hasEntityConfig, entityConfigList });

        if (reqs.length > 0) {
            Promise.all(reqs).then(
                data => {
                    let user: Partial<IEditUserState> = User({ cid });
                    if (!insert) {
                        user = User(data[0].user);
                    }
                    if (user.cid) {
                        user.client = clients[user.cid];
                        if (!user.rid && user.client) {
                            const defaultRole = Object.values(user.client.roles).find(role => role.default);
                            if (defaultRole) {
                                user.rid = defaultRole.rid;
                            }
                        }
                        if (user.rid) {
                            if (user.client?.roles[user.rid].entityConfigLink) {
                                user.entityConfigLink = user.client.roles[user.rid].entityConfigLink;
                            }
                            if (user.client?.roles[user.rid].allowECommunication) {
                                user.roleECommEnabled = true;
                            }
                        }
                    }
                    user.extraNetworks!.sort(function(a, b) {
                        return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
                    });

                    if (user.tags!.length == 0) {
                        user.tags = [""];
                    }

                    if (user.client) {
                        user.insert = insert;
                        if (user.insert) {
                            user.allowProfSearches = user.client.allowProfSearches;
                            user.allowPersSearches = user.client.allowPersSearches;
                            let socialNetworks = {} as Mongo.clientUsers["socialNetworks"];
                            Object.keys(user.client.socialNetworks).forEach(key => {
                                if (
                                    user.client!.socialNetworks[key].available ||
                                    !("available" in user.client!.socialNetworks[key])
                                ) {
                                    let network = Object.assign({}, user.client!.socialNetworks[key]);
                                    delete network["available"];
                                    socialNetworks![key] = network;
                                }
                            });
                            user.socialNetworks = socialNetworks;

                            user.lang = user.lang || user.client.lang;
                        }
                    }
                    if (!user.profile!.terms) {
                        user.profile!.terms = {
                            professional: []
                        };
                    }
                    user.oldSSOId = user.ssoId;

                    //delegate management
                    user.showDelegateManagement = user.allowDelegates;
                    user.dbAllowDelegates = user.allowDelegates;
                    user.dbDelegatesArray = user.delegateMap ? this.getDelegatesArray(user.delegateMap) : [];
                    user.delegatesArray = user.dbDelegatesArray;

                    this.setState(user as IEditUserState);
                    return User(user);
                }
            );
        }
    }

    _onFieldUpdate(fieldName: keyof IEditUserState, event) {
        const value = event.target ? event.target.value : event;
        const newState: Partial<IEditUserState> = {};
        newState[fieldName] = value;

        this.setState(newState as IEditUserState);
    }

    getEmails(cid: string, emailType: string) {
        const { clients } = this.props;
        if (!clients || !cid || !clients[cid]) {
            return;
        }
        const emails = clients[cid]?.customEmails ? clients[cid]!.customEmails![emailType] : {};
        return emails;
    }

    __validateEmail(email: string) {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(email);
    }

    _stripSoftDeleted(text: string) {
        // remove softDeleted and partial uuid from text if it exists. Display original text
        const regex = /(softDeleted)\w{8}-\w{4}-\w{4}-\w{4}\+/i
        return text?.replace(regex, "");
    }

    closeModal() {
        this.setState({ warningOpen: false });
    }

    async _onSubmit(userObj: Mongo.IClientUser, warned: boolean) {
        userObj.profile!.title = userObj["profile.title"];
        userObj.profile!.department = userObj["profile.department"];
        userObj.profile!.industry = userObj["profile.industry"];
        userObj.profile!.subIndustries = userObj["profile.subIndustries"];
        userObj.profile!.webpage = userObj["profile.webpage"];
        userObj.profile!.terms!.professional = userObj["profile.terms.professional"];
        userObj.profile!.personalWebsitePath = userObj["profile.personalWebsitePath"];
        if (this.props.clients[userObj.cid]?.pumEnabled) {
            if (!userObj.pum) {
                userObj.pum = {};
            }
            userObj.pum!.groups = userObj["pum.groups"];
            userObj.pum!.activityReviewTeams = userObj["pum.activityReviewTeams"];
            userObj.pum!.profileReviewTeams = userObj["pum.profileReviewTeams"];
        } else {
            delete userObj.pum;
        }

        const user = User({ ...userObj });

        if (this.state.cid && user.uid) {
            const existingUser = await UserActions.doGet(this.state.cid, user.uid);
            if (existingUser && existingUser.item && new Date(existingUser.item.lastUpdatedOn) > this.editStartTime) {
                createAlert("This user's information has been updated since you started editing it. Please make note of your changes and refresh the page to try again.", "error");
                return;
            }
        }

        // trim firstname, lastname, ssoId and email before validation
        user.firstName = user.firstName?.trim();
        user.lastName = user.lastName?.trim();
        user.email = user.email?.trim();
        user.ssoId = user.ssoId?.trim();

        user.extraNetworks = this.state.extraNetworks;

        if (user.pum?.groups?.length && !/^([\w\.\- ]{1,255}:[\w\.\- ]{1,255},?)+$/.test(user.pum.groups.join(","))) {
            createAlert("Participant Groups must be in the format of \"key1:value1,key2:value2\" and each key and value must be less than 256 characters long and may only contain letters, numbers, periods, hyphens and underscores.", "error");
            return;
        }

        if (user.pum?.activityReviewTeams?.length && user.pum.activityReviewTeams.some(team => team.length > 32)) {
            createAlert("Activity Review Teams must not be more than 32 characters long", "error");
            return;
        }

        if (user.pum?.profileReviewTeams?.length && user.pum.profileReviewTeams.some(team => team.length > 32)) {
            createAlert("Profile Review Teams must not be more than 32 characters long", "error");
            return;
        }

        if (!user.firstName || !user.lastName || !this.props.clients[user.cid]) {
            createAlert("Please fill out the user credentials", "error");
            return;
        }
        if (!user.email) {
            createAlert("Please enter an email", "error");
            return;
        }

        if (user.email && !this.__validateEmail(user.email)) {
            createAlert("Email format is invalid, please provide a proper email");
            return;
        }

        if (user.profile!.personalWebsitePath && !isHttpsURL(user.profile!.personalWebsitePath) && !/[\w]+=[\w]+/gi.test(user.profile!.personalWebsitePath)) {
            createAlert("Please provide a valid Personal Website Path URL starting with \"https://\" and ending with a single \"/\" or provide a valid query string in the format of \"example=test\"");
            return;
        }

        if (checkForDuplicates(user.profile!.terms!.professional)) {
            createAlert("Cannot have duplicate suggested interests", "error");
            return;
        }
        const terms = {
            professional: [...new Set(user.profile!.terms!.professional.map(i => i.trim()).filter(i => i.length))]
        };
        user.profile!.terms = terms;
        if (!user.rid) {
            createAlert("Please assign this user a role", "error");
            return;
        }
        if (user.groups == null || (user.groups.length == 1 && user.groups[0] == "")) {
            user.groups = [];
        }
        if (user.tags == null || (user.tags.length == 1 && user.tags[0] == "")) {
            user.tags = [];
        }
        if (warned) {
            user.warned = true;
        }

        let confirmUpdateSSO = true;

        if (this.state.oldSSOId && user.ssoId !== this.state.oldSSOId) {
            confirmUpdateSSO = confirm(`You are about to change this user's SSO ID from '${this.state.oldSSOId}' to '${user.ssoId}'. Are you sure you want to proceed?`);
        }

        if (!user.ssoId?.length && this.state.oldSSOId?.length) {
            confirmUpdateSSO = confirm(`You are about to remove this user's SSO ID. Are you sure you want to proceed?`);
        }

        if (!confirmUpdateSSO) {
            return;
        }
        return UserActions.doUpsert(user).then( async data => {
            if (!data) {
                return;
            } else if (data.errorType == "missingNetworks") {
                this.setState({ warningOpen: true, networks: data.value });
                return;
            } else {
                if (data.key) {
                    return createAlert(data.key, "error");
                }
                const { added, removed } = this.getDelegationManagmentChanges();
                if (added.length || removed.length) {
                    if (data.uid) {
                        let addCount = 0;
                        let removeCount = 0;
                        if (removed.length) {
                            for (const delegate of removed) {
                                try {
                                    await DelegateActions.doRemoveAdvisor(data.uid, delegate.uid, user.cid);
                                    removeCount++;
                                } catch (err) {
                                    createAlert(`Failed to remove delegate ${delegate.email} to user: ${err}`, "error");
                                }
                            }
                            createAlert(`Successfully removed ${removeCount} delegate${removeCount !== 1 ? 's' : ''} from user`, "success");
                        }
                        if (added.length) {
                            const { firstName, lastName, email, rid, lang } = user;
                            const advisor = { uid: data.uid, firstName, lastName, email, rid, lang };
                            for (const delegate of added) {
                                try {
                                    await DelegateActions.doAssignDelegate(user.cid, advisor, delegate, this.state.allowSuperPublisher || false);
                                    addCount++;
                                } catch (err) {
                                    createAlert(`Failed to add delegate ${delegate.email} to user: ${err}`, "error");
                                }
                            }
                            createAlert(`Successfully added ${addCount} delegate${addCount !== 1 ? 's' : ''} to user`, "success");
                        }
                    } else {
                        return createAlert("Could not update delegates for user", "error");
                    }
                }
                createAlert(`User has been ${user.uid ? "updated" : "created"}`, "success");
                setTimeout(function() {
                    history.push("/users");
                }, 0);
            }
        });
    }

    // workaround to prevent issues with nested fields
    hack(item) {
        item["profile.title"] = item.profile?.title || "";
        item["profile.department"] = item.profile?.department || "";
        item["profile.industry"] = item.profile?.industry || "";
        item["profile.subIndustries"] = item.profile?.subIndustries || "";
        item["profile.webpage"] = item.profile?.webpage || "";
        item["profile.terms.professional"] = item.profile?.terms?.professional || [];
        item["profile.personalWebsitePath"] = item.profile?.personalWebsitePath || "";
        item["eCommunicationUserProfile.onBoarded"] = item.eCommunicationUserProfile?.onBoarded || false;
        item["eCommunicationUserProfile.showHotspots"] = item.eCommunicationUserProfile?.showHotspots || false;
        item["eCommunicationUserProfile.transitioningCompletionDate"] = item.eCommunicationUserProfile?.transitioningCompletionDate;
        item["pum.groups"] = item.pum?.groups || "";
        item["pum.activityReviewTeams"] = item.pum?.activityReviewTeams || "";
        item["pum.profileReviewTeams"] = item.pum?.profileReviewTeams || "";
        return item;
    }

    getDelegatesArray(delegateMap: Mongo.IClientUser["delegateMap"]) {
        const delegatesArray = [] as IDelegateRow[];
        Object.entries(delegateMap!).map(([delegateId, delegateObj]) => {
            if (delegateObj?.permissions) {
                delegatesArray.push({
                    uid: delegateId,
                    firstName: delegateObj.firstName || `Delegate-${delegateId}`,
                    lastName: delegateObj.lastName || "",
                    email: delegateObj.email || "",
                    permissions: delegateObj.permissions,
                    permissionString: this.displayPermissionStrings(delegateObj?.permissions)
                });
            }
        });
        return delegatesArray.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    getDelegationManagmentChanges() {
        const { delegatesArray, dbDelegatesArray } = this.state;
        const added = [] as IDelegateRow[];
        const removed = [] as IDelegateRow[];

        delegatesArray.forEach(delegate => {
            if (!dbDelegatesArray.map(dbD => dbD.uid).includes(delegate.uid)) {
                added.push(delegate);
            }
        });
        dbDelegatesArray.forEach(dbDelegate => {
            if (!delegatesArray.map(d => d.uid).includes(dbDelegate.uid)) {
                removed.push(dbDelegate);
            }
        });
        //remove and re-add delegates with updated permissions
        dbDelegatesArray.forEach(dbDelegate => {
            const updatedDelegate = delegatesArray.find(d => d.uid == dbDelegate.uid);
            if (updatedDelegate && (updatedDelegate.permissionString !== dbDelegate.permissionString)) {
                removed.push(dbDelegate);
                added.push(updatedDelegate);
            }
        });

        return { added, removed };
    }

    displayPermissionStrings(permissionObj: Mongo.IDelegatePermission) {
        let permissions: string[] = [];
        if (!permissionObj.activated) {
            permissions.push("Pending");
        } else {
            if (permissionObj.content) {
                permissions.push("Content");
            }
            if (permissionObj.profile) {
                permissions.push("Profile");
            }
            if (permissionObj.eCommunication) {
                permissions.push("Email");
            }
        }
        return permissions.join(", ");
    }

    toggleShowDelegateManagement(value: boolean) {
        if (value) {
            this.setState({ showDelegateManagement: true });
        } else {
            this.setState({ showDelegateManagement: false, selectedRows: [] });
        }
    }

    selectRow(delegate: IDelegateRow) {
        const selectedRows = this.state.selectedRows.filter(ii => ii.uid !== delegate.uid);
        if (!this.isSelected(delegate.uid)) {
            selectedRows.push(delegate);
        }
        this.setState({ selectedRows });
    }

    isSelected(uid: string) {
        return this.state.selectedRows.filter(i => i.uid == uid).length > 0;
    }

    async assignDelegates(permissions: Mongo.IDelegatePermission) {
        const { selectedRows, delegatesArray } = this.state;

        if (!selectedRows.length) {
            createAlert("Please select some delegates first", "error");
            this.setState({selectedRows: []});
            return false;
        }
        if (!permissions.content && !permissions.profile) {
            createAlert("Delegates must have at least one permission", "error");
            this.setState({selectedRows: []});
            return false;
        }

        let addDelegates: IDelegateRow[] = [];
        for (const row of selectedRows) {
            permissions.activated = 1; //pre-approve all assigned delegates
            addDelegates.push({
                ...row,
                permissions,
                permissionString: this.displayPermissionStrings(permissions)
            });
        }

        const updatedDelegates = [...delegatesArray, ...addDelegates].sort((a, b) => a.firstName.localeCompare(b.firstName));
        this.setState({ delegatesArray: updatedDelegates, selectedRows: [] });

        return true;
    }

    async removeDelegates() {
        const { delegatesArray, selectedRows } = this.state;

        if (!selectedRows.length) {
            createAlert("Please select some delegates first", "error");
            return false;
        }

        const updatedDelegates = delegatesArray.filter(i => !selectedRows.includes(i));
        this.setState({ delegatesArray: updatedDelegates, selectedRows: [] });

        return true;

    }

    render() {
        if ((this.props.clients && !Object.keys(this.props.clients).length) || (!this.state.insert && !this.state.email)) {
            return <Spinner/>
        }
        const { cid, insert, delegatesArray } = this.state;
        const client = this.props.clients[cid || ""];

        return (
            <div>
                <ElementEditCard<Mongo.IClientUser>
                    getItem={async () => this.hack(User({ ...this.state }))}
                    canUpdate={() => Promise.resolve(true)}
                    update={item => this._onSubmit(item, false)}
                    breadcrumbs={[{ name: "Users", link: "/users" }]}
                    elementName="User"
                    viewOnly={client?.appSSO?.SEISMIC?.userSyncMethod == "managed"}
                    buttons={item => {
                        let tooltipText = "";
                        if (!item?.isActive) {
                            tooltipText = "This user is inactive";
                        } else if (item?.hasActivated) {
                            tooltipText = "The user has already activated their account";
                        }

                        const skipActivationEmail = item?.rid && client!.roles[item.rid].skipActivationEmail;
                        if (skipActivationEmail) {
                            tooltipText = "The user has a role assigned that skips activation email"
                        }
                        return [{
                            text: "Copy Access Code",
                            onClick: () => { navigator.clipboard.writeText(item?.code!); createAlert("Copied", "success") },
                            disabled: !item?.code
                        }, {
                            text: "Send Activation Email",
                            onClick: () => this.setState({ activationEmail: true }),
                            disabled: !item || item.hasActivated || (item.rid && client?.roles[item.rid].skipActivationEmail) || !item.isActive || insert,
                            title: tooltipText
                        }]
                    }}
                    rows={[{
                        columns: [
                            { label: "", items: ["firstName"], key: "EditUser_R0C1" },
                            { label: "", items: ["lastName"], key: "EditUser_R0C2" }
                        ],
                        key: "EditUser_Row0"
                    },
                    {
                        columns: [
                            { label: "", items: ["externalAccountId", "cid", "email", "description", "lang", "excludeFromReports"], key: "EditUser_R1C1" },
                            { label: "", items: ["ssoId", "rid", "tags", "groups", "entityConfigLink"], key: "EditUser_R1C2" }
                        ],
                        key: "EditUser_Row1",
                        noSpacing: true
                    },
                    {
                        columns: [
                            { label: "", items: ["isActive", "allowDelegates", "profile.personalWebsitePath"], key: "EditUser_R2C1" },
                            { label: "", items: ["hasActivated", "allowSuperPublisher"], key: "EditUser_R2C2" }
                        ],
                        key: "EditUser_Row2",
                        noSpacing: true
                    },
                    ...(client?.smsProvider ? [{
                        label: "SMS",
                        columns: [{ label: "", items: ["sms.number"], key: "EditUser_R6C1" }],
                        fullWidth: true,
                        key: "EditUser_Row6"
                    }] : []),
                    ...(client?.hasExtraNetworks ? [{
                        label: "Extra Networks",
                        columns: [{
                            custom: (item, setState) => {
                                return <table className="form__value">
                                    <thead style={{ height: "30px" }}>
                                        <tr>
                                            <th />
                                            <th>Identifier</th>
                                            <th>Label</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <ExtraNetworkList
                                        items={this.state.extraNetworks || []}
                                        _update={this._onFieldUpdate}
                                    />
                                </table>
                            },
                            items: [],
                            key: "EditUser_R5C1"
                        }],
                        fullWidth: true,
                        key: "EditUser_Row5"
                    }] : []),
                    {
                        label: "Profile",
                        columns: [
                            { label: "", items: ["profile.title", "profile.department"], key: "EditUser_R3C1" },
                            { label: "", items: ["profile.industry", "profile.subIndustries"], key: "EditUser_R3C2" },
                            { label: "", items: ["profile.webpage"], key: "EditUser_R3C3" }
                        ],
                        key: "EditUser_Row3"
                    },
                    {
                        label: "Suggested Onboarding Interests",
                        columns: [
                            {
                                custom: (item, setState) => {
                                    return <>
                                        <span className="form__label" style={{ fontWeight: "bold" }}>Professional Interests</span>
                                        <ActionableList
                                            placeholder="Please enter a term"
                                            disabled={true}
                                            hideElement={(item["profile.terms.professional"] || []).length > 3 ? "none" : ""}
                                            items={item["profile.terms.professional"] || []}
                                            updateAction={items => {
                                                const terms = (items || [])
                                                    .map(i => i.trim())
                                                    .filter(i => i.length);
                                                setState({ "profile.terms.professional": terms });
                                            }}
                                        />
                                    </>
                                },
                                items: [],
                                key: "EditUser_R4C1"
                            }
                        ],
                        fullWidth: true,
                        key: "EditUser_Row4"
                    },
                    ...(client?.allowECommunication && client?.eCommunication?.engine && this.state.roleECommEnabled && !this.props.user.isAdmin ? [{
                        label: "e-Communication",
                        columns: [
                            { label: "", items: ["eCommunicationUserProfile.onBoarded"], key: "EditUser_R7C1"},
                            { label: "", items: ["eCommunicationUserProfile.showHotspots"], key: "EditUser_R7C2"},
                            { label: "", items: ["eCommunicationUserProfile.transitioningCompletionDate"], key: "EditUser_R7C3"}
                        ],
                        key: "EditUser_Row7"
                    },
                    {
                        columns: [
                            { label: "", items: ["jsonECommProfile"], key: "EditUser_R8C1" }
                        ],
                        fullWidth: true,
                        noSpacing: true,
                        key: "EditUser_Row8"
                    }] : []),
                    ...(client?.pumEnabled ? [{
                        label: "Proofpoint User Management",
                        columns: [{ label: "", items: ["pum.groups", "pum.activityReviewTeams", "pum.profileReviewTeams"], key: "EditUserR9C1" }],
                        fullWidth: true,
                        key: "EditUser_Row9"
                    }] : [])]}
                    idField="uid"
                    editableKeys={item => {
                        return {
                            firstName: {
                                label: "First Name",
                                type: "text",
                                placeholder: " ",
                                required: true
                            },
                            lastName: {
                                label: "Last Name",
                                type: "text",
                                placeholder: " ",
                                required: true
                            },
                            ssoId: {
                                label: "SSO ID",
                                placeholder: " ",
                                customTopLevelClasses: "fist-item", // typo in the original class, not fixing because it amuses me
                                type: "text",
                                disabled: item.hasActivated && !this.state.editSSOId,
                                ...(item.hasActivated && (this.props.user.isSuperClientAdmin || this.props.user.isSuper) ?
                                    {
                                        renderRight: <Button onClick={event => this.setState({ editSSOId: true })}>
                                            <i style={{ pointerEvents: "none" }} className="fa fa-edit" />
                                        </Button>
                                    } : {}
                                )
                            },
                            rid: {
                                label: "Role",
                                disabled: !client,
                                type: "select",
                                required: true,
                                values: Object.values(client?.roles || {}).map(role => ({ label: role.name, value: role.rid })),
                                mutateChange: (key, value) => {
                                    const newRole = client!.roles![value as string];
                                    if (client?.roles[value as string].allowECommunication) {
                                        this.setState({ roleECommEnabled: true });
                                    }
                                    return Promise.resolve({ [key]: value, ...(newRole.entityConfigLink ? { entityConfigLink: newRole.entityConfigLink } : {}) });
                                }
                            },
                            externalAccountId: {
                                label: "External Account ID",
                                placeholder: " ",
                                customTopLevelClasses: "fist-item", // typo in the original class, not fixing because it amuses me
                                type: "text"
                            },
                            cid: {
                                label: "Client",
                                type: "select",
                                required: true,
                                disabled: !this.props.user.isSuper || !this.state.insert,
                                values: (Object.values(this.props.clients || {})).map(c => ({ label: c!.name, value: c!.cid })),
                                mutateChange: (key, cid) => {
                                    this._onClientChange(cid as string);
                                    return Promise.resolve({ cid: cid as string, rid: undefined, groups: [] });
                                }
                            },
                            email: {
                                label: "Email",
                                placeholder: " ",
                                type: "text",
                                required: true,
                                mutateChange: (key, value) => {
                                    const email = (value as string).toLowerCase().trim();
                                    return Promise.resolve({ email, ...(/seismic\.com$/.test(email) ? { excludeFromReports: true } : {}) });
                                },
                                renderAbove: <ActivationEmailModal
                                    open={this.state.activationEmail}
                                    user={this.props.user}
                                    uid={item.uid}
                                    userEmail={item.email}
                                    emails={this.getEmails(item.cid, "activationEmail")}
                                    cid={item.cid}
                                    closeAction={() => this.setState({ activationEmail: false })}
                                />
                            },
                            tags: {
                                label: "Content Tags",
                                type: "multi-select",
                                disabled: !client,
                                values: Object.entries(client?.tags || {}).map(([tid, name]) => ({
                                    label: name as unknown as string,
                                    value: tid
                                }))
                            },
                            groups: {
                                label: "Group",
                                type: "select",
                                disabled: !client,
                                hidden: client && !Object.keys(client.groups).length,
                                value: item.groups[0],
                                values: [{ label: "No Group", value: "" }].concat(Object.keys(client?.groups || {})
                                    .filter(gid => this.props.user.isClientAdmin || this.props.user.isSuperClientAdmin || this.props.user.groups.includes(gid))
                                    .map(gid => ({
                                        label: client!.groups[gid]!.name,
                                        value: gid
                                    }))
                                    .sort((a, b) => {
                                        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0;
                                    })
                                ),
                                mutateChange: (key, group) => {
                                    return Promise.resolve({ groups: group ? [group as string] : [] });
                                }
                            },
                            description: {
                                label: "Description",
                                type: "textarea",
                                max: 500,
                                placeholder: " ",
                            },
                            lang: {
                                label: "Language",
                                type: "select",
                                values: [
                                    { label: "English", value: "en" },
                                    { label: "French", value: "fr" },
                                    { label: "Spanish", value: "es" },
                                    { label: "German", value: "de" },
                                    { label: "Portuguese", value: "pt" },
                                    { label: "Italian", value: "it" }
                                ]
                            },
                            excludeFromReports: {
                                label: "Exclude From Reporting",
                                type: "toggle"
                            },
                            entityConfigLink: {
                                label: "Entity Config",
                                type: "select",
                                hidden: !client?.hasEntityConfig,
                                values: this.state.entityConfigList.map(entityConfig => ({ label: entityConfig, value: entityConfig }))
                            },
                            isActive: {
                                label: "Has Access",
                                customTopLevelClasses: "fist-item", // typo in the original class, not fixing because it amuses me
                                type: "toggle"
                            },
                            hasActivated: {
                                label: "Activated",
                                type: "toggle",
                                disabled: true
                            },
                            allowDelegates: {
                                label: "Allow Delegates",
                                hidden: !client?.allowDelegation,
                                type: "toggle",
                                mutateChange: (key, value) => {
                                    this.toggleShowDelegateManagement(value as boolean);
                                    return Promise.resolve({ allowSuperPublisher: false, [key]: value })
                                }
                            },
                            allowSuperPublisher: {
                                label: "Super Publisher",
                                hidden: !item.allowDelegates,
                                type: "toggle"
                            },
                            "profile.personalWebsitePath": {
                                label: "Personal Website Path",
                                type: "text",
                                hidden: !client?.replaceCorporateArticlesWithUserWebsite,
                                placeholder: "A URL, ie: https://www.example.com/ or a query string, ie: example=test",
                                description: 'Content from a specific corporate URL will be swapped to this path on share to redirect to the user\'s personal website.\nMust start with "https://" and end with a single "/" or be a query string to be appended in the format of "example=test"'
                            },
                            "profile.title": {
                                label: "Title",
                                type: "text",
                                placeholder: " ",
                                customTopLevelClasses: "customBrandInput",
                            },
                            "profile.department": {
                                label: "Department",
                                type: "text",
                                placeholder: " ",
                                customTopLevelClasses: "customBrandInput",
                            },
                            "profile.industry": {
                                label: "Industry",
                                type: "select",
                                customTopLevelClasses: "customBrandInput",
                                values: getIndustries().map(industry => ({ label: industry.name, value: industry.value })),
                            },
                            "profile.subIndustries": {
                                label: "Sub-Industries",
                                type: "multi-select",
                                customTopLevelClasses: "customBrandInput",
                                disabled: !item["profile.industry"],
                                values: (getIndustries()
                                            .find(industry => industry.value === item["profile.industry"])?.subIndustries || [])
                                            .map(subIndustry => ({ label: subIndustry, value: subIndustry })),
                            },
                            "profile.webpage": {
                                label: "Professional page",
                                type: "text",
                                placeholder: " ",
                                customTopLevelClasses: "customBrandInput"
                            },
                            "sms.number": {
                                label: "SMS Number",
                                type: "text",
                                placeholder: " ",
                                value: item.sms?.number || "",
                                mutateChange: (key, number) => Promise.resolve({ sms: { ...item.sms!, number: number as string } })
                            },
                            "eCommunicationUserProfile.onBoarded": {
                                label: "User Onboarded",
                                type: "toggle"
                            },
                            "eCommunicationUserProfile.showHotspots": {
                                label: "Show Hotspots",
                                type: "toggle"
                            },
                            "eCommunicationUserProfile.transitioningCompletionDate": {
                                label: "Transitioning Completion Date",
                                type: "datetime",
                                description: "Set the completion date for the advisor transitioning period. This is used for the transitioning advisor disclosure.",
                                customTopLevelClasses: "customBrandInput"
                            },
                            "jsonECommProfile": {
                                label: "User Profile data in JSON string",
                                type: "textarea",
                                description: "The JSON string contains user profile information used in eCommunication.\n\nPlease note : After the JSON string is entered into the textarea,\nclick \"Update Profile Data\" to save the eCommunication profile data.",
                                customTopLevelClasses: "customBrandInput",
                                placeholder: 'eg: { "profiles": [ { ... } ], ... }',
                                value: (item as dynamic).jsonECommProfile || "",
                                renderBelow: <span style={{ marginLeft: "auto", marginTop: "5px" }}>
                                    <Button
                                        className="btn--sm m-l-sm"
                                        onClick={event => this._onUpdateECommProfile(item)}
                                        disabled={(this.state.updatingECommProfile)}
                                    >
                                        Update Profile Data
                                    </Button>
                                </span>
                            },
                            "pum.groups": {
                                label: "Participant Groups",
                                type: "creatable",
                                hidden: !client?.pumEnabled,
                                description: "Format: key:value\nFor multiple groups, follow the same format, but separate each entry with a comma.\nEach key and value must be less than 256 characters long and may only contain\nletters, numbers, periods, hyphens and underscores.",
                            },
                            "pum.activityReviewTeams": {
                                label: "Activity Review Teams",
                                hidden: !client?.pumEnabled,
                                type: "creatable"
                            },
                            "pum.profileReviewTeams": {
                                label: "Profile Review Teams",
                                hidden: !client?.pumEnabled,
                                type: "creatable"
                            }
                        };
                    }}
                />
                { this.state.showDelegateManagement &&
                    <Row>
                        <div className="col-sm-12">
                            <div className="ibox">
                                <div className="ibox-title" style={{ justifyContent: "flex-start" }}>
                                    <h2>Delegate Management</h2>
                                    <Tooltip
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "5px"
                                        }}
                                    >
                                        Only existing users are available to be added as delegates here. Advisors can add delegates that are not yet users from the app.
                                    </Tooltip>
                                </div>
                                <form className="ibox-content form roles">
                                        <div className="form__row">
                                            <div className="form__group" style={{ border: 0 }}>
                                                <table className="role__users">
                                                    <thead>
                                                        <tr>
                                                            <th>Current Delegates</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ maxHeight: "250px", overflowY: "scroll" }}>
                                                        { delegatesArray.length > 0 &&
                                                            delegatesArray.map(i => {
                                                                return (
                                                                    <tr
                                                                        key={i.uid}
                                                                        onClick={e => this.selectRow(i)}
                                                                        className={`${
                                                                            this.isSelected(i.uid) ? "selected" : ""
                                                                        }`}
                                                                    >
                                                                        <td
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                alignItems: "center",
                                                                                justifyContent: "space-between",
                                                                                flexBasis: "100%",
                                                                                height: "100%"
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <span>{`${i.firstName} ${i.lastName}`.trim()}</span>
                                                                                <span>({i.email.trim()})</span>
                                                                            </div>
                                                                            <div>
                                                                                <span style={{fontWeight: "bold"}}>Permissions:</span>
                                                                                <span>{i.permissionString?.trim()}</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                        {(!delegatesArray || delegatesArray.length < 1) &&
                                                            <tr className="clearfix">
                                                                <td>No Delegates Added</td>
                                                                <td />
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                <div
                                                    style={{ display: "flex", alignItems: "center", marginTop: "20px", paddingTop: "10px" }}
                                                >
                                                    {delegatesArray.length > 0 && (
                                                        <Link
                                                            style={{ marginRight: "auto" }}
                                                            onClick={() => {
                                                                let selectedRows = delegatesArray;
                                                                if (
                                                                    selectedRows.length ==
                                                                    this.state.selectedRows.length
                                                                ) {
                                                                    selectedRows = [];
                                                                }
                                                                this.setState({ selectedRows });
                                                            }}
                                                        >
                                                            Select All...
                                                        </Link>
                                                    )}
                                                    {delegatesArray.length > 0 && (
                                                        <Button
                                                            onClick={e => this.removeDelegates()}
                                                            disabled={
                                                                !this.state.dbAllowDelegates ||
                                                                this.state.selectedRows.length == 0
                                                            }
                                                        >
                                                            Remove Delegate
                                                        </Button>
                                                    )}
                                                    <Button
                                                        style={{ marginLeft: "10px" }}
                                                        disabled={
                                                            !this.state.dbAllowDelegates ||
                                                            this.state.selectedRows.length > 0
                                                        }
                                                        onClick={e => this.setState({ showAddDelegateModal: true })}
                                                    >
                                                        Add {delegatesArray.length > 0 ? `Other ` : null}Users as Delegates
                                                        {
                                                            !this.state.dbAllowDelegates && (
                                                                <Tooltip
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        marginRight: "5px"
                                                                    }}
                                                                    place="right"
                                                                >
                                                                    Delgates cannot be managed until the user has been successfully updated with the Allow Delegates toggle ON. Please update the user to manage delegates.
                                                                </Tooltip>
                                                            )
                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Row>
                    }
                    { this.state.showAddDelegateModal && (
                        <DelegateUserSearchModal
                            cid={this.state.cid}
                            clientUserId={this.state.uid}
                            allowContentDelegation={client?.allowContentDelegation || false}
                            allowProfileDelegation={client?.allowProfileDelegation || false}
                            allowEcommDelegation={(client?.allowECommunication && this.state.roleECommEnabled) || false}
                            isSelected={user => this.isSelected(user)}
                            selectDelegate={user => this.selectRow(user)}
                            assignDelegates={permissions => this.assignDelegates(permissions)}
                            closeModal={() => this.setState({ showAddDelegateModal: false, selectedRows: [] })}
                            delegatesArray={delegatesArray}
                        />
                    )}
            </div>
        )
    }

    async _onUpdateECommProfile(user: Mongo.IClientUser & { jsonECommProfile?: string }) {
        if (!user.hasActivated) {
            createAlert('The user is not yet activated and update cannot be completed at this time');
            return;
        }
        this.setState({ updatingECommProfile: true });
        const result = await doUpsertUserECommunicationProfile(user.cid, user.uid, user.jsonECommProfile!);
        if (result?.success){
            createAlert(`Profile info successfully updated`, `success`);
        } else {
            createAlert(`Updating profile info failed`);
        }
        this.setState({ updatingECommProfile: false });
    }

    _onClientChange(cid: string) {
        let client = this.props.clients[cid]!;
        let socialNetworks = {} as Mongo.clientUsers["socialNetworks"];
        Object.keys(client.socialNetworks).forEach(function(key) {
            if (
                ("available" in client.socialNetworks[key] && client.socialNetworks[key].available) ||
                !("available" in client.socialNetworks[key])
            ) {
                let network = Object.assign({}, client.socialNetworks[key]);
                delete network["available"];
                socialNetworks![key] = network;
            }
        });

        if (client.hasEntityConfig) {
            doGetEntityConfigs(cid).then(
                entityConfigList => {
                    this.setState({ entityConfigList });
                }
            );
        }

        this.setState({
            cid: cid,
            client: client,
            allowProfSearches: client.allowProfSearches,
            allowPersSearches: client.allowPersSearches,
            lang: client.lang,
            socialNetworks: socialNetworks,
            hasEntityConfig: !!client.hasEntityConfig,
            rid: "",
            groups: []
        });
    }
}

const mapStateToProps = state => ({ clients: state.lists.clients, user: state.session.admin });

export default connect(mapStateToProps)(EditUser);
