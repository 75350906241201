import BaseAction from "../utils/BaseAction";
import { IReportFilter } from "../components/pages/reports/Index";

export type ISocialInteractionsActions = ClassInstance<typeof _SocialInteractionsActions>;
class _SocialInteractionsActions extends BaseAction {

    async doList(query: IReportFilter & {
        sort?: string;
        sortDirection?: number;
        skip?: number;
        limit?: number;
    }) {
        const data = await this.apiRequestWithErrorAlert<{
            items: ServerTypes.Console.ISocialInteractionsItem[];
            count: number;
        }>("/reports/socialInteractions/json", "POST", { ...query });
        if (data && data.items && data.items.length) {
            return { items: data.items, total: data.count };
        } else {
            return { items: [], total: 0 };
        }
    }


    async doDownload(query: IReportFilter & {
        sort?: string;
        sortDirection?: number;
        skip?: number;
        limit?: number;
    }) {
        const data = await this.apiRequest("/reports/socialInteractions/csv", "POST", { ...query });
        if (data) {
            return this.downloadFile("socialInteractions", data);
        }
    }
}

export const SocialInteractionsActions = new _SocialInteractionsActions();
