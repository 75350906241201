import React, { MouseEvent, useState, ReactNode } from "react";
import Button from "./Button";
import Spinner from "./Spinner";

interface IDownloadButtonProps {
    onClick: (event?: MouseEvent) => Promise<any>;
    className?: string;
    style?: dynamic<any>;
    children?: ReactNode;
    hideStyle?: boolean;
}
export default (props: IDownloadButtonProps) => {
    const [loading, setLoading] = useState(false);
    const download = async (event: MouseEvent | undefined) => {
        setLoading(true);

        await props.onClick(event);
        setLoading(false);
    };

    const { style } = props;
    let { className, hideStyle } = props;
    if (!className) {
        className = "";
    }
    if (hideStyle) {
        return (
            <a
                style={style}
                onClick={download}
                className={`${className}`}
            >
                {loading ? (
                    <Spinner style={{ width: "12px", height: "12px", margin: "0 auto" }} color="#ffffff" />
                ) : (
                        props.children
                    )}
            </a>
        );
    }
    return (
        <Button
            type="button"
            style={style}
            onClick={download}
            className={`btn brandPrimary--bg ${className}`}
            disabled={loading}
        >
            {loading ? (
                <Spinner style={{ width: "12px", height: "12px", margin: "0 auto" }} color="#ffffff" />
            ) : (
                    props.children
                )}
        </Button>
    );
};
