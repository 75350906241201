import React, { Component } from "react";
import { connect } from "react-redux";

import { doGetEmailDrips } from "../../../../actions/lists";
import { doRemoveEmailDripCampaign } from "../../../../actions/deletes";
import { getBooleanIcon, getFriendlyDate } from "../../../../utils/Helpers";
import ElementListBox from "../../../molecules/ElementListBox";
import { Wrapper, Row } from "../../../atoms/Layout/";

class EmailDripView extends Component<{ clients: { [cid: string]: Mongo.IClient }; user: Mongo.clientAdmin }, {}> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = false;
    static allowSuperClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = true;

    constructor(props) {
        super(props);

        this._refresh = this._refresh.bind(this);
    }

    _refresh(search = "", cid = "", paginationData = {}) {
        return doGetEmailDrips(
            { ...(search ? { search } : null),
            ...(cid && cid !== "consumers" ? { cid } : null),
            ...(cid === "consumers" ? { consumer: true } : null),
            ...paginationData }
        );
    }

    async delete(emailDripId) {
        if (confirm("Are you sure you want to delete this email drip?")) {
            return doRemoveEmailDripCampaign(emailDripId);
        }
        return false;
    }

    render() {
        return (
            <Wrapper>
                <Row>
                    <ElementListBox<Mongo.IEmailDripCampaign>
                        title="Email Drip"
                        createLink={
                            this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isReports
                                ? "/campaign/emailDrip/c"
                                : undefined
                        }
                        editLink={this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isReports
                            ? "/campaign/emailDrip/e/:eid"
                            : undefined}
                        filterCid={this.props.user.isSuper}
                        refresh={({ search, cid }, paginationData) => this._refresh(search, cid, paginationData)}
                        keys={{
                            cid: "Client",
                            type: "Type",
                            active: "Enabled",
                            date_created: "Date Created",
                            groups: "Groups Filter",
                            roles: "Role Filter",
                            actions: "Actions",
                        }}
                        sortableKeys={["Enabled", "Date Created"]}
                        defaultSort={{ date_created: -1 }}
                        mutateItem={emailDrip => ({
                            cid: this.props.user.isSuper
                                ? emailDrip.consumer
                                    ? "All Consumers"
                                    : this.props.clients && this.props.clients[emailDrip.cid]
                                        ? this.props.clients[emailDrip.cid].name
                                        : "All Enterprise Users"
                                : "None",
                            type: emailDrip.type,
                            active: getBooleanIcon(emailDrip.active),
                            date_created: getFriendlyDate(emailDrip.date_created) || <span>N/A</span>,
                            groups: emailDrip.groups && emailDrip.groups.length && this.props.clients[emailDrip.cid] && this.props.clients[emailDrip.cid].groups ? <span>{emailDrip.groups.map(group => this.props.clients[emailDrip.cid].groups[group]?.name).join(',')}</span> : <span>All</span>,
                            roles: emailDrip.roles && emailDrip.roles.length && this.props.clients[emailDrip.cid] && this.props.clients[emailDrip.cid].roles ? <span>{emailDrip.roles.map(role => this.props.clients[emailDrip.cid].roles[role]?.name).join(',')}</span> : <span>All</span>,
                        })}
                        delete={
                            this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isReports
                                ? item => this.delete(item.eid)
                                : undefined
                        }
                        includeConsumers={true}
                    />
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    user: state.session.admin,
    clients: state.lists.clients
});

export default connect(mapStateToProps)(EmailDripView);
