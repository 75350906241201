import { apiRequest, apiRequestWithErrorAlert } from "../utils/Helpers";
import appConfig from "../../config/config.dev";

export function doGetClient(cid: string) {
    return apiRequestWithErrorAlert<ServerTypes.Console.IClient>(`${appConfig.API_URL}/client/get`, "POST", { cid });
}

export async function doGetClients(projectedFields?: string[]) {
    const data = await apiRequest<{ items: Mongo.IClient[] }>(`${appConfig.API_URL}/client/list`, "POST", {
        ...(projectedFields ? { projectedFields } : null)
    });

    return data && "items" in data && data.items! ? data.items! : [];
}

export async function doHasAppSSOEnabled(cid: string) {
    const response = await apiRequestWithErrorAlert<{ isEnabled: boolean }>(
        `${appConfig.API_URL}/client/sso/app`,
        "POST",
        { cid },
        "We're experiencing a technical difficulty while determining if SSO is enabled"
    );
    return response && response.isEnabled || false;
}

export async function doGetClientView(cid: string){
    const data = await apiRequestWithErrorAlert<{ valid: boolean; item: ServerTypes.Console.IClient }>(
        `${appConfig.API_URL}/client/settings`,
        "POST",
        { cid }
    );
    if (data && data.valid && "item" in data) {
        return data.item;
    }
    return null;
}