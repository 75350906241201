import BaseStream from "./BaseStream";

export default class HelpStream extends BaseStream {
    getInitialState() {
        const props = super.getInitialState();
        return {
            ...props,
            ...(props.stream
                ? {
                    stream: {
                        ...props.stream,
                        type: "HELP" as "HELP",
                        isApprovalRequired: true,
                        isMandatory: true,
                        readOnly: true,
                        defaultSharingPermissions: {
                            social: false,
                            email: false,
                            sms: false,
                            socialNetworkPermissions: {
                                twitter: false,
                                facebook: false,
                                linkedin: false,
                                instagram: false
                            }
                        }

                    }
                }
                : null),
            canPreviewOnCreate: false
        };
    }
    getSettings() {
        const settings = super.getSettings();
        const { stream } = this.state;
        delete settings.isWebShare
        delete settings.isEditable
        delete settings.isDefaultOn
        delete settings.adminContentTags
        delete settings.locations
        delete settings.filters
        delete settings.canATSCopyLink

        return {
            ...settings,
            active: {
                label: "Active",
                value: !!stream.active,
                description: "Enables the stream to be shown in the app",
                onChange: () =>
                    this.setState({ stream: { ...stream, active: !stream.active } })
            },
            isMandatory: {
                label: "Always enabled",
                value: true,
                hidden: true,
                description: "Stream cannot be turned off for user"
            },
            defaultSharingPermissions: {
                label: "default sharing permissions",
                value: false,
                hidden: true
            },
            readOnly: {
                label: "Set All Stream Content to Read-only",
                value: true,
                hidden: true,
                description: "Stream is set to read-only, and content can not be shared from this stream."
            }
        };
    }
}
