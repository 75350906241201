import React from "react";
import { createAlert } from "../../actions/utils";
import Button from "../atoms/Button";
class ActionableList extends React.Component {
    constructor(props) {
        super(props);

        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._removeOption = this._removeOption.bind(this);
        this._addOption = this._addOption.bind(this);
        this._updateItems = this._updateItems.bind(this);

        let items = [];
        if (props.items && props.items.length > 0) {
            items = props.items;
        }
        this.state = {
            items: items
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.items) {
            this._onFieldUpdate("items", newProps.items);
        }
    }

    _onFieldUpdate(keyName, event) {
        this.setState({
            [keyName]: event.target ? event.target.value : event
        });
    }
    _removeOption(i) {
        let { items } = this.state;
        items.splice(i, 1);
        this._onFieldUpdate("items", items);
        this._updateItems(items, true);
        createAlert('Option removed', 'success');
    }

    _addOption() {
        let { items } = this.state;
        let item = (this.inputVal.value || "").trim();

        if (item.length > 0) {
            if (!items) {
                items = [];
            }

            items.push(item);
            this._onFieldUpdate("items", items);
            this._updateItems(items);
        }
        this.inputVal.value = "";
        createAlert('Option added', 'success');
    }

    _updateItems(items, remove = false) {
        if (this.props.updateAction) {
            this.props.updateAction(items, remove);
        }
    }

    render() {
        const { items } = this.state;
        return (
            <ul className="actionablelist form__value form__value--centered">
                {items &&
                    items.map(
                        function(val, i) {
                            return (
                                <li key={`${val}-${i}`}>
                                    {this.props.preElement && (
                                        <div className="preElement">
                                            <div className="before">{this.props.preElement}</div>
                                            <input type="text" disabled={this.props.disabled} value={val} />
                                        </div>
                                    )}
                                    {!this.props.preElement && (
                                        <input type="text" disabled={this.props.disabled} value={val} />
                                    )}
                                    <Button disabled={this.props.inactive} onClick={event => this._removeOption(i)}>
                                        Remove
                                    </Button>
                                </li>
                            );
                        }.bind(this)
                    )}
                <li className="submit" style={{ borderTop: "0", display: this.props.hideElement }}>
                    {"input" in this.props && this.props.input}
                    {!("input" in this.props) && !("preElement" in this.props) && (
                        <input
                            type="text"
                            disabled={this.props.inactive}
                            placeholder={this.props.placeholder || `Please enter some text`}
                            ref={input => {
                                this.inputVal = input;
                            }}
                        />
                    )}
                    {!("input" in this.props) && "preElement" in this.props && (
                        <div className="preElement">
                            <div className="before">{this.props.preElement}</div>
                            <input
                                type="text"
                                disabled={this.props.inactive}
                                placeholder={this.props.placeholder || `Please enter some text`}
                                ref={input => {
                                    this.inputVal = input;
                                }}
                            />
                        </div>
                    )}
                    {!("input" in this.props) && (
                        <Button disabled={this.props.inactive || this.props.noUsername} onClick={event => this._addOption()}>
                            Add
                        </Button>
                    )}
                </li>
            </ul>
        );
    }
}

export default ActionableList;
