import React, { Component } from "react";
import { connect } from "react-redux";

import { ClientRoleActions, createAlert } from "../../../actions";
import { getFriendlyDate, getLocaleDate, downloadFile } from "../../../utils/Helpers";
import Link from "../../atoms/Link";
import ElementListBox, { Filter } from "../../molecules/ElementListBox";
import { Wrapper, Row } from "../../atoms/Layout/";
import DownloadButton from "../../atoms/DownloadButton";

class RoleView extends Component<{ user: Mongo.clientAdmin, clients: dynamic<Mongo.client> }, { cid: string, exporting: boolean }> {
    static allowSuper = true;
    static allowSuperClientAdmin = true;
    static allowClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;
    constructor(props) {
        super(props);
        this.state = { cid: props.user.cid || "", exporting: false };
    }
    async _refresh({ search, cid }, paginationData) {
        if (this.props.user.isSuper && !cid) {
            return { items: [] as Mongo.IUserRole[], total: 0, valid: false };
        }
        if (cid && !(this.props.user.isSuper && cid == this.state.cid)) {
            this.setState({ cid });
        }
        return ClientRoleActions.doList({
            ...(cid ? { cid } : null),
            ...(search ? { search } : null),
            ...paginationData
        });
    }

    async doRemoveRole(role: Mongo.IUserRole, cid: string) {
        if (!confirm(`Are you sure you want to delete this role?\n${role.name}`)) {
            return false;
        }

        try {
            await ClientRoleActions.doDelete(cid, role.rid);
            createAlert(`Successfully removed ${role.name}`, "success");
            return true;
        } catch (err) {
            // @ts-expect-error
            createAlert(err, "error");
            return false;
        }
    }

    async _doUpdateRole(role: Mongo.IUserRole) {
        const { cid } = this.state;
        if (!cid) {
            return false;
        }
        const upsert = await ClientRoleActions.doUpsert(this.state.cid, role);
        if (!upsert || !upsert.valid) {
            createAlert(`Update ${role.name} failed.`, `error`);
            return;
        }
        createAlert(`Successfully updated ${role.name}`, "success");
        return true;
    }

    async _downloadExport(filters: Filter) {
        if (this.state.exporting) {
            return;
        }
        this.setState({ exporting: true });
        const results = await ClientRoleActions.doExportRoles(filters.cid).catch(error => {
            createAlert(error, `error`);
        });
        if (results) {
            downloadFile(results, `role-export-${getLocaleDate(new Date())}.csv`);
        }
        this.setState({ exporting: false });
    }

    render() {
        const { cid } = this.state;
        return (
            <Wrapper id="roles">
                <Row>
                    <ElementListBox<Mongo.IUserRole>
                        title="Roles"
                        keys={{
                            default: "Default",
                            ...(cid && this.props.clients[cid]?.allowDelegation ? { defaultTrueDelegate: "Default Delegate" } : null),
                            name: "Role",
                            "dates.created": "Date Created",
                            "dates.updated": "Date Updated",
                            actions: "Actions"
                        }}
                        sortableKeys={["Role", "Language", "Last Updated"]}
                        defaultSort={{ name: 1 }}
                        defaultFilter={this.props.user.cid ? { cid: this.props.user.cid } : undefined}
                        filterCid={this.props.user.isSuper}
                        refresh={({ search, cid }, paginationData) => this._refresh({ search, cid }, paginationData)}
                        mutateItem={(role, index, items, baseRole, context) => ({
                            default: (
                                <input
                                    type="radio"
                                    name={`default-${index}`}
                                    checked={role.default}
                                    onChange={event => {
                                        if (role.default) return;
                                        const _default = !role.default;
                                        this._doUpdateRole({ ...baseRole, default: _default });
                                        const contextItems = context.state.items;
                                        if (_default) {
                                            for (const rid in contextItems) {
                                                contextItems[rid].default = false;
                                            }
                                        }
                                        contextItems[index].default = _default;
                                        context.setState({ items: contextItems });
                                        return true;
                                    }}
                                    aria-label={`Set ${role.name} as default`}
                            />
                            ),
                            defaultTrueDelegate: (
                                <input
                                    type="radio"
                                    name={`defaultDelegate-${index}`}
                                    checked={role.defaultTrueDelegate}
                                    onChange={() => {
                                        if (role.defaultTrueDelegate) return;
                                        const _defaultTrueDelegate = !role.defaultTrueDelegate;
                                        this._doUpdateRole({ ...baseRole, defaultTrueDelegate: _defaultTrueDelegate }).then((res) => {
                                            const contextItems = context.state.items;

                                            if (!res) {
                                                // handle case where delegate role cannot have sharing permission
                                                contextItems[index].defaultTrueDelegate = false;
                                                context.setState({ items: contextItems });
                                                return false;
                                            };

                                            if (_defaultTrueDelegate) {
                                                for (const rid in contextItems) {
                                                    contextItems[rid].defaultTrueDelegate = false;
                                                }
                                            }
                                            contextItems[index].defaultTrueDelegate = _defaultTrueDelegate;
                                            context.setState({ items: contextItems });
                                            return true;
                                        });
                                    }}
                                    aria-label={`Set ${role.name} as default true delegate`}
                                />
                            ),
                            name: <Link to={`/role/e/${cid}/${role.rid}`}>{role.name}</Link>,
                            "dates.created": role.dates.created ? getFriendlyDate(role.dates.created)! : "N/A",
                            "dates.updated": role.dates.updated ? getFriendlyDate(role.dates.updated)! : "N/A"
                        })}
                        actions={[{ link: "/role/c/:cid/:rid", text: "Duplicate", icon: "fa-copy" }]}
                        editLink={`/role/e/:cid/:rid`}
                        createLink={`/role/c/:cid/`}
                        allowCreateWithoutClient={false}
                        delete={(role, { cid }) => this.doRemoveRole(role, cid)}
                        buttons={filters => filters.cid ? [<DownloadButton className="btn--sm" onClick={() => this._downloadExport(filters)}>
                            <i className="fa fa-download" /> Download
                        </DownloadButton >] : []}
                    />
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(RoleView);
