import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";

interface IButtonProps {
    id?: string;
    to?: string;
    onClick?: (event?: MouseEvent) => void;
    className?: string;
    style?: object;
    type?: string;
    value?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    target?: string;
    title?: string;
    checked?: boolean;
    ariaLabel?: string;
    ariaExpanded?: boolean;
}

export default (props: IButtonProps) => {
    const { id, to, onClick, type, style, value, disabled, target, title, checked, ariaLabel, ariaExpanded } = props;
    let { className } = props;
    if (!className) {
        className = "";
    }
    if (to) {
        return (
            <Link
                id={id}
                style={style}
                to={to}
                onClick={onClick}
                aria-label={ariaLabel}
                className={`btn brandPrimary--bg ${className}`}
                {...(target ? { target } : null)}
                tabIndex={0}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        onClick;
                    }
                }}
            >
                {props.children}
            </Link>
        );
    } else if (type && type === "radio") {
            return (
                <input
                    id={id}
                    type="radio"
                    style={style}
                    className={`btn brandPrimary--bg ${className}`}
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    tabIndex={0}
                    onClick={onClick}
                    title={title}
                    aria-label={ariaLabel}
                />
            );
    } else if (type && type === "radioBox") {
        return (
            <input
                id={id}
                type="radio"
                style={style}
                className={`${className}`}
                value={value}
                checked={checked}
                disabled={disabled}
                tabIndex={0}
                onClick={onClick}
                title={title}
                aria-label={ariaLabel}
            />
        );
    }else if (onClick) {
        return (
            <button
                id={id}
                type="button"
                style={style}
                onClick={onClick}
                className={`btn brandPrimary--bg ${className}`}
                disabled={disabled}
                aria-label={ariaLabel}
                aria-expanded={ariaExpanded}
                tabIndex={0}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        onClick;
                    }
                }}
                {...(title ? { title } : null)}
            >
                {props.children}
            </button>
        );
    } else if (type && type === "submit") {
        return (
            <input
                id={id}
                type="submit"
                style={style}
                className={`btn brandPrimary--bg ${className}`}
                value={value}
                disabled={disabled}
                tabIndex={0}
                aria-label={ariaLabel}
            />
        );
    }
    return (
        <a id={id} style={style} className={`btn brandPrimary--bg ${className}`} tabIndex={0} aria-label={ariaLabel}>
            {props.children}
        </a>
    );
};
