import React, {Component} from 'react';
import {doLogout} from '../../../actions/session';

import Spinner from '../../atoms/Spinner';

class SignOut extends Component {

    async componentDidMount() {
        await doLogout();
    }

    render() {
        return (<div className="page logout"><Spinner/></div>);
    }
}
export default SignOut;
