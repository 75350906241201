export function getAllSocialNetworks(): string[] {
    return ["Facebook", "LinkedIn", "Twitter", "Instagram"];
}

export function getAllSharePermissions(): string[] {
    return ["Social", "Email", "SMS"];
}

export function getClientSharePermissions(client: Mongo.IClient): string[] {
    const sharePermissions: string[] = [];
    const availabilities = [
        client.socialNetworks.Facebook?.available,
        client.socialNetworks.LinkedIn?.available,
        client.socialNetworks.Twitter?.available,
        client.socialNetworks.IG?.available
    ];
    if (availabilities.some(available => available)) {
        sharePermissions.push("Social");
    }

    if (client.allowEmail || client.allowAppEmail) {
        sharePermissions.push("Email");
    }

    if (client.allowAppSMS || client.smsProvider) {
        sharePermissions.push("SMS");
    }
    return sharePermissions;
}

export function getClientNetworks(client: Mongo.IClient, allowInstagram: boolean): string[] {
    const socialNetworkPermissions: string[] = [];
    if (client.socialNetworks.Facebook?.available) socialNetworkPermissions.push("Facebook");
    if (client.socialNetworks.LinkedIn?.available) socialNetworkPermissions.push("LinkedIn");
    if (client.socialNetworks.Twitter?.available) socialNetworkPermissions.push("Twitter");
    if (client.socialNetworks.IG?.available && allowInstagram) socialNetworkPermissions.push("Instagram");
    return socialNetworkPermissions;
}
