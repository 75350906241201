import React, { Component } from "react";

interface IEllipsisProps<T> {
    dropdownOptions: string[];
    onSelect: (option: string) => void;
    className: string;
}

interface IEllipsisState{
    dropdownOpen: boolean;
}

export default class Ellipsis<T> extends Component<IEllipsisProps<T>, IEllipsisState> {
    constructor(props: IEllipsisProps<T>) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this._onSelect = this._onSelect.bind(this);
        this._renderDropdown = this._renderDropdown.bind(this);

        this.state = {
            dropdownOpen: false
        }
    }
    componentDidMount() {
        if (document) {
            document.addEventListener("mousedown", this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (document) {
            document.removeEventListener("mousedown", this.handleClickOutside);
        }
    }

    handleClickOutside(event) {
        if (this.state.dropdownOpen) {
            if (
                ((this as dynamic).dropdownWrapper && (this as dynamic).dropdownWrapper.contains(event.target)) ||
                ((this as dynamic).dropdownTools && (this as dynamic).dropdownTools.contains(event.target))
            ) {
                return;
            } else {
                this.setState({ dropdownOpen: false });
            }
        }
    }

    _onSelect(option: string) {
        this.props.onSelect(option);
        this.setState({ dropdownOpen: false });
    }

    _renderDropdown() {
        const { dropdownOpen } = this.state;
        const { className, dropdownOptions } = this.props;
        return (
            <div
                className={`${className}Dropdown ${dropdownOpen ? "open" : ""} brandPrimary--color`}
                ref={input => {
                    (this as dynamic).dropdownWrapper = input;
                }}
            >
                <ul>
                    {dropdownOptions.map(option => {
                        return (
                            <li onClick={() => this._onSelect(option)}>
                                {option.charAt(0).toUpperCase() + option.slice(1)}
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }
    render() {
        const { dropdownOpen } = this.state;
        const { className } = this.props;
        return(
            <div className="ellipsisWrapper"
                tabIndex={0}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        this.setState({dropdownOpen: !dropdownOpen});
                    }
                }}>
                <i
                    ref={input => {
                        (this as dynamic).dropdownTools = input;
                    }}
                    className={`${className}Ellipsis fa fa-ellipsis-v ${dropdownOpen ? "open" : ""}`}
                    aria-hidden="true"
                    onClick={() =>
                        this.setState({
                            dropdownOpen: !dropdownOpen
                        })}
                />
                <div className={`${className}DropdownWrapper`}>
                    {this._renderDropdown()}
                </div>
            </div>
        )
    }
}
