import React, { Component } from "react";
import { connect } from "react-redux";
import { createAlert } from "../../../../actions";
import PageHeading from "../../../molecules/PageHeading";
import Spinner from "../../../atoms/Spinner";
import Link from "../../../atoms/Link";
import debounce from "lodash/debounce";
import Pagination from "../../../molecules/Pagination";
import Button from "../../../atoms/Button";

import {
    doGetReduxClients,
    doGetReduxClient,
    doGetECommunicationSubscribables
} from "../../../../actions";
import AddECommunicationSubscribable from "../../../molecules/modals/AddECommunicationSubscribable";
import {
    SUBSCRIBABLE_STATUS,
    SUBSCRIBABLE_FREQUENCY,
    containValue,
    getNamebyValue
} from "../../../../utils/ECommunicationHelpers";
import { Wrapper, Row } from "../../../atoms/Layout";
import { RouteComponentProps } from "react-router-dom";

interface IECommunicationSubscribableProps extends RouteComponentProps<dynamic> {
    user: Mongo.clientAdmin;
    clients: dynamic<Mongo.client>;
}

interface IECommunicationSubscribableState {
    client: Mongo.client | null;
    messages: Mongo.clientECommunicationSubscribables[];
    view: string;
    filterStatus: string;
    filterFrequency: string;
    searchQuery: string;
    openModal: string;
    per_page: number;
    page: number;
    total: number;
    last_page: boolean;
    messagesLoading: boolean;
    addMessage?: boolean;
}

class ECommunicationSubscribable extends Component<IECommunicationSubscribableProps, IECommunicationSubscribableState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = true;
    static allowReports = false;

    constructor(props: IECommunicationSubscribableProps) {
        super(props);
        this._closeModal = this._closeModal.bind(this);
        this._getMessages = this._getMessages.bind(this);
        this._openAddMessage = this._openAddMessage.bind(this);
        this._closeAddMessage = this._closeAddMessage.bind(this);

        let status = props.match.params.status;
        if (!containValue(SUBSCRIBABLE_STATUS, status)) {
            status = "";
        }
        let frequency = props.match.params.frequency;
        if (!containValue(SUBSCRIBABLE_FREQUENCY, frequency)) {
            frequency = "";
        }

        this.state = {
            client: null,
            messages: [],
            view: "table",
            filterStatus: status,
            filterFrequency: frequency,
            searchQuery: "",
            openModal: "",
            per_page: 3 * 10,
            page: 0,
            total: 0,
            last_page: false,
            messagesLoading: false
        };
    }

    componentDidMount() {
        if (!this.props.user.isSuper) {
            doGetReduxClient(this.props.user.cid!);
        } else {
            doGetReduxClients();
        }
        this._getMessages();
    }

    componentWillReceiveProps(newProps: IECommunicationSubscribableProps) {
        const newState: Partial<IECommunicationSubscribableState> = {};
        if (!this.state.client && newProps.user.cid) {
            newState.client = newProps.clients[newProps.user.cid];
        }
        if (this.props.match.params.status != newProps.match.params.status) {
            let status = newProps.match.params.status;
            if (!containValue(SUBSCRIBABLE_STATUS, status)) {
                status = "";
            }
            newState.filterStatus = status;
            let frequency = newProps.match.params.frequency;
            if (!containValue(SUBSCRIBABLE_FREQUENCY, frequency)) {
                frequency = "";
            }
            newState.filterFrequency = frequency;
        }
        if (Object.keys(newState).length > 0) {
            this.setState(newState as IECommunicationSubscribableState);
        }
    }

    _getClientName(cid: string) {
        const { clients } = this.props;
        if (cid) {
            if (clients?.[cid]) {
                return clients[cid]!.name;
            } else if (clients && Object.keys(clients).length) {
                return "REMOVED";
            }
            return "Loading...";
        }
        return "None";
    }


    _getMessages() {
        const { client, filterStatus, filterFrequency, per_page, page, searchQuery } = this.state;

        this.setState(
            {
                messagesLoading: true
            },
            () =>
                doGetECommunicationSubscribables(
                    (client || { cid: this.props.user.cid }).cid,
                    filterStatus,
                    filterFrequency,
                    page * per_page,
                    per_page,
                    searchQuery
                ).then(data => {
                    const state: Partial<IECommunicationSubscribableState> = {
                        messagesLoading: false,
                        total: 0
                    };
                    if (data) {
                        const messages = data.items;
                        const total = data.count;
                        if (messages) {
                            state.messages = messages;
                            state.last_page = messages.length != per_page;
                            state.total = total;
                        }
                        this.setState(state as IECommunicationSubscribableState);
                    }
                })
        );
    }
    _onFilterChange(fieldName: keyof IECommunicationSubscribableState | "cid", event, shouldDebounce = false) {
        let val = event && event.target ? event.target.value : event ? event : "";
        if (fieldName === "cid") {
            fieldName = "client";
            val = (this.props.clients || {})[val];
        }
        const newState: Partial<IECommunicationSubscribableState> = {
            [fieldName]: val
        };

        this.setState(newState as IECommunicationSubscribableState, () => {
            createAlert(`${fieldName} updated`, 'success');
            if (shouldDebounce) {
                debounce(this._getMessages, 1000)();
            } else {
                this._getMessages();
            }
        });
    }
    _openAddMessage() {
        this.setState({ addMessage: true });
    }
    _closeAddMessage() {
        this.setState(
            {
                addMessage: false
            },
            this._getMessages
        );
    }

    _createStatusOptions() {
        return SUBSCRIBABLE_STATUS.map(item => <option key={item.value} value={item.value}>
            {item.name}
        </option>);
    }
    _createFrequencyOptions() {
        return SUBSCRIBABLE_FREQUENCY.map(item => <option key={item.value} value={item.value}>
            {item.name}
        </option>);
    }

    render() {
        const { searchQuery, client, filterStatus, filterFrequency, messages, messagesLoading } = this.state;

        const buttons: Array<{ title: string; on_click: () => void }> = [];
        if (!this.props.user.isSuper) {
            buttons.push({ title: "Create", on_click: this._openAddMessage });
        }

        return (
            <Wrapper id="ecomm-subs-category">
                {this.state.addMessage && (
                    <AddECommunicationSubscribable
                        cid={client!.cid}
                        client={client}
                        closeAction={this._closeAddMessage}
                    />
                )}
                <PageHeading title={`Subscription Categories`} buttons={buttons} />
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <div className="table__filters">
                                    <div className="table__filters__option">
                                        <label htmlFor="search">Search:</label>
                                        <div className="search">
                                            <input
                                                id="search"
                                                className="filter__search"
                                                type="text"
                                                onChange={event => this._onFilterChange("searchQuery", event, true)}
                                                value={searchQuery}
                                            />
                                            <i className="fa fa-search" />
                                        </div>
                                    </div>
                                    {this.props.user.isSuper && (
                                        <div className="table__filters__option">
                                            <label htmlFor="client">Client:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="client"
                                                    className="filters__search"
                                                    value={(client || {}).cid}
                                                    onChange={event => this._onFilterChange("cid", event)}
                                                >
                                                    <option value="">All</option>
                                                    <optgroup label="Clients">
                                                        {Object.values(this.props.clients).map(client => {
                                                            return (
                                                                <option key={client!.cid} value={client!.cid}>
                                                                    {client!.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    <div className="table__filters__option">
                                        <label htmlFor="filterStatus">Filter By Status:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="filterStatus"
                                                className="filters__search"
                                                value={filterStatus}
                                                onChange={event => this._onFilterChange("filterStatus", event)}
                                            >
                                                <option value="">All</option>
                                                {this._createStatusOptions()}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="table__filters__option">
                                        <label htmlFor="filterFrequency">Filter By Frequency:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="filterFrequency"
                                                className="filters__search"
                                                value={filterFrequency}
                                                onChange={event => this._onFilterChange("filterFrequency", event)}
                                            >
                                                <option value="">All</option>
                                                {this._createFrequencyOptions()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        page={this.state.page}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.per_page}
                                        changePage={newPage => this._onFilterChange("page", newPage)}
                                        disabled={messagesLoading}
                                        showTotal={false}
                                    />
                                </div>
                                {this._renderMessages()}
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        currItems={messages.length}
                                        page={this.state.page}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.per_page}
                                        changePage={newPage => this._onFilterChange("page", newPage)}
                                        disabled={messagesLoading}
                                        showTotal={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
    _renderMessages() {
        if (this.state.view === "table") {
            return this._renderTableView();
        } else {
            // TODO: either implement non-table views or get rid of the view field entirely
            console.info("non-table views not implemented, here's a table anyway")
            return this._renderTableView();
        }
    }

    _renderModal(message: Mongo.clientECommunicationSubscribables) {
        if (this.state.openModal && this.state.openModal == message.subscribableid) {
            return (
                <AddECommunicationSubscribable
                    cid={message.cid}
                    client={this.props.clients[message.cid]}
                    message={message}
                    updateAction={this._getMessages}
                    closeAction={this._closeModal}
                />
            );
        }
        return;
    }

    _closeModal() {
        this.setState(
            {
                openModal: ""
            },
            this._getMessages
        );
    }

    _openModal(subscribableid: string) {
        this.setState({ openModal: subscribableid });
    }

    _renderTableView() {
        const { messagesLoading, messages } = this.state;
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        {this.props.user.isSuper && [<th tabIndex={0}>Client</th>]}
                        <th tabIndex={0}>Title</th>
                        <th tabIndex={0}>Status</th>
                        <th tabIndex={0}>Frequency</th>
                        <th tabIndex={0} />
                    </tr>
                </thead>
                <tbody>
                    {!messagesLoading &&
                        messages.map(
                            (message) => {
                                return (
                                    <tr key={message.subscribableid}>
                                        {this._renderModal(message)}
                                        {this.props.user.isSuper && [
                                            <td tabIndex={0}>
                                                {
                                                    this.props.clients[message.cid] ?
                                                        <Link to={`/clients/e/${message.cid}`}>
                                                            {this._getClientName(message.cid) || "N/A"}
                                                        </Link> :
                                                        this._getClientName(message.cid) || "N/A"
                                                }
                                            </td>
                                        ]}
                                        <td tabIndex={0}>
                                            <Link onClick={() => this._openModal(message.subscribableid)}>
                                                {message.title || "N/A"}
                                            </Link>
                                        </td>
                                        <td tabIndex={0}>{getNamebyValue(SUBSCRIBABLE_STATUS, message.status) || "N/A"}</td>
                                        <td tabIndex={0}>{getNamebyValue(SUBSCRIBABLE_FREQUENCY, message.frequency) || "N/A"}</td>
                                        <td tabIndex={0}>
                                            <Button
                                                style={{ marginRight: "5px" }}
                                                className="btn--sm"
                                                onClick={() => this._openModal(message.subscribableid)}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            }
                        )}
                    {!messagesLoading && (!messages || messages.length == 0) && (
                        <tr>
                            <td tabIndex={0} colSpan={42}>No subscription categories matching your filters could be found</td>
                        </tr>
                    )}
                    {messagesLoading && (
                        <tr>
                            <td tabIndex={0} colSpan={42}>
                                <Spinner />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(ECommunicationSubscribable);
