import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
interface ModalStackProperties {
    modals: Array<{
        title: string;
        render?: JSX.Element;
        className?: string;
        closeAction?: () => void;
        nextLabel?: string;
        previousLabel?: string;
        canGoBack?: boolean;
        backdrop?: "static" | boolean;
        showButtons?: boolean;
    }>;
    open?: number;
    onClose?: () => void;
}
interface ModalStackState {
    open: { [id: number]: boolean };
    priorOpen: number;
    direction: number;
}

export default class ModalStack extends Component<ModalStackProperties, ModalStackState> {
    constructor(props: ModalStackProperties) {
        super(props);
        this.init();
    }

    init() {
        const state = {
            direction: 1,
            priorOpen: -1,
            open: Object.assign({}, ...this.props.modals.map((v, i) => ({ [i]: i == this.props.open })))
        };
        if (this.state) {
            this.setState(state);
        } else {
            this.state = state;
        }
    }

    componentDidUpdate(oldProps: ModalStackProperties) {
        const { open } = this.props;
        if (open !== undefined && open !== oldProps.open) {
            const currentOpen = Number(Object.keys(this.state.open).filter(i => this.state.open[i])[0]! || -1);
            const newOpen = open;
            this.changePage(currentOpen, newOpen - currentOpen > 0 ? +1 : -1);
        } else if (open == undefined && open !== oldProps.open) {
            this.close();
        }
    }

    closeAction(current: number) {
        if (this.props.modals[current] && this.props.modals[current].closeAction) {
            this.props.modals[current].closeAction!();
        }
    }

    close() {
        this.setState(
            {
                direction: 1,
                priorOpen: -1,
                open: Object.assign({}, ...this.props.modals.map((v, i) => ({ [i]: false })))
            },
            this.props.onClose ? () => this.props.onClose!() : undefined
        );
    }

    changePage(current: number, direction: number) {
        const { open } = this.state;
        open[current] = false;
        this.setState(
            {
                direction,
                open
            },
            () =>
                setTimeout(
                    () =>
                        this.setState(
                            {
                                priorOpen: current,
                                open: Object.assign(
                                    {},
                                    ...Object.keys(open).map(v => ({ [v]: false })),
                                    0 <= current + direction && current + direction < this.props.modals.length
                                        ? { [current + direction]: true }
                                        : {}
                                )
                            },
                            () => this.closeAction(current)
                        ),
                    0
                )
        );
    }

    getClassName(current: number) {
        const { priorOpen, direction } = this.state;
        return direction == -1 ? (current < priorOpen ? "left" : "right") : current <= priorOpen ? "left" : "right";
    }

    render() {
        const { modals } = this.props;
        return (
            <div>
                {modals.map((v, i) => (
                    <Modal
                        key={`${v.title}--${i}`}
                        className={`${this.getClassName(i)} ${v.className}`}
                        title={v.title}
                        backdrop={v.backdrop || true}
                        open={this.state.open[i]}
                        closeAction={() => this.close()}
                        {...(v.showButtons
                            ? {
                                  footer: (
                                      <div
                                          style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              justifyContent: "space-between"
                                          }}
                                      >
                                          {i == 0 && <span />}
                                          {(v.canGoBack || v.canGoBack === undefined) && i > 0 && (
                                              <Button onClick={() => this.changePage(i, -1)}>
                                                  {v.previousLabel || "Previous"}
                                              </Button>
                                          )}
                                          {i < modals.length - 1 && (
                                              <Button onClick={() => this.changePage(i, +1)}>
                                                  {v.nextLabel || "Next"}
                                              </Button>
                                          )}
                                      </div>
                                  )
                              }
                            : null)}
                    >
                        {v.render}
                    </Modal>
                ))}
            </div>
        );
    }
}
