import BaseAction from "../utils/BaseAction";

export type IUserActions = ClassInstance<typeof _UserActions>;
class _UserActions extends BaseAction {
    doGet(cid: string, uid: string) {
        return this.apiRequestWithErrorAlert<dynamic>(`/clientuser/get`, "POST", { cid, uid });
    }
    doUpsert(user: ServerTypes.Console.IClientUser) {
        if (!user.uid) {
            return this.apiRequestWithErrorAlert<dynamic>(`/insertUser`, "POST", user);
        }
        return this.apiRequestWithErrorAlert<dynamic>(`/updateUser`, "POST", user);
    }
    softDeleteUser(user: ServerTypes.Console.IClientUser) {
        return this.apiRequestWithErrorAlert(`/softDeleteUser`, "POST", user);
    }
    undoSoftDeleteUser(user: ServerTypes.Console.IClientUser) {
        return this.apiRequestWithErrorAlert(`/undoSoftDeleteUser`, "POST", user);
    }
}

export const UserActions = new _UserActions();
