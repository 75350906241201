import React, { Component } from "react";
import { connect } from "react-redux";

import { getBooleanIcon, getAdminDate } from "../../../utils/Helpers";
import { FeedActions, UtilActions, IFeedListParams, createAlert } from "../../../actions";
import ElementListBox, { PaginationData } from "../../molecules/ElementListBox";
import { CoreAPI } from "../../../../../../types/coreapi";
import Link from "../../atoms/Link";
import { Wrapper, Row } from "../../atoms/Layout/";

interface IFeedListProps {
    user: Mongo.clientAdmin;
}
interface IFeedListState {
    countries: Array<{ code: string; name: string }>;
}

interface ListFilters {
    active?: boolean;
    articleDomain?: string;
    region?: number;
    category?: string;
    subCategory?: string;
    location?: string;
    eventType?: string;
    type?: string;
    lang?: string;
}

class List extends Component<IFeedListProps, IFeedListState> {
    static allowSuper = true;
    static allowApiAdmin = true;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props: IFeedListProps) {
        super(props);

        this.state = {
            countries: []
        };
    }

    async componentDidMount() {
        this.setState({ countries: (await UtilActions.doGetCountries()) || [] });
    }

    async deleteFeed(id: number) {
        try {
            const data = await FeedActions.doRemove(id);
            if (data && data.valid) {
                createAlert("Feed has been successfully deleted", "success");
            }
            return true;
        } catch (err) {
            return false;
        }
    }

    refresh(filters: ListFilters, pageData: PaginationData) {
        return FeedActions.doList({ ...filters, start: pageData.skip, rows: pageData.limit });
    }

    translateRegion(region: number) {
        switch (region) {
            case 1:
                return "Australia & NZ";
            case 2:
                return "Far East";
            case 3:
                return "India";
            case 4:
                return "Middle East & Africa";
            case 5:
                return "Europe";
            case 6:
                return "South America";
            case 7:
                return "East - North America";
            case 8:
                return "Central - North America";
            case 9:
                return "West - North America";
            default:
            case 0:
                return "Other";
        }
    }
    _downloadExport(query: IFeedListParams) {
        FeedActions.doExportCSV(query);
    }

    render() {
        return (
            <Wrapper>
                <Row>
                    <ElementListBox<CoreAPI.IFeed>
                        title="Feeds"
                        filterSearch={false}
                        keys={{
                            active: "Active",
                            type: "Type",
                            articleDomain: "Domain",
                            lastUpdatedOn: "Last Updated On",
                            weight: "Weight",
                            category: "Category",
                            subCategory: "Sub-Category",
                            lang: "Lang",
                            location: "Loc",
                            actions: "Actions"
                        }}
                        listCount={50}
                        minified={true}
                        confirmDelete={true}
                        defaultSort={{ lastUpdatedOn: -1 }}
                        defaultFilter={{
                            search: ""
                        }}
                        refresh={(
                            { articleDomain, active, eventType, location, category, subCategory, region, lang, type },
                            paginationData: PaginationData
                        ) =>
                            this.refresh(
                                {
                                    ...(articleDomain ? { articleDomain } : null),
                                    ...(active !== undefined ? { active: active == "1" } : null),
                                    ...(eventType ? { eventType } : null),
                                    ...(location ? { location } : null),
                                    ...(category ? { category } : null),
                                    ...(subCategory ? { subCategory } : null),
                                    ...(region !== undefined ? { region: Number(region) } : null),
                                    ...(lang ? { lang } : null),
                                    ...(type ? { type } : null)
                                },
                                paginationData
                            )
                        }
                        download={(
                            { articleDomain, active, eventType, location, category, subCategory, region, lang, type },
                            { total }
                        ) =>
                            this._downloadExport({
                                ...(articleDomain ? { articleDomain } : null),
                                ...(active !== undefined ? { active: active == "1" } : null),
                                ...(eventType ? { eventType } : null),
                                ...(location ? { location } : null),
                                ...(category ? { category } : null),
                                ...(subCategory ? { subCategory } : null),
                                ...(region !== undefined ? { region: Number(region) } : null),
                                ...(lang ? { lang } : null),
                                ...(type ? { type } : null),
                                rows: total,
                                start: 0
                            })
                        }
                        mutateItem={feed => ({
                            id: `${feed.id}`,
                            active: getBooleanIcon(feed.active),
                            tcAllowed: getBooleanIcon(feed.tcAllowed),
                            type: feed.type,
                            url: (
                                <Link
                                    href={`${feed.url.indexOf("://") === -1 ? "http://" : ""}${feed.url}`}
                                    target={"_blank"}
                                >
                                    {feed.url}
                                </Link>
                            ),
                            feedDomain: (
                                <Link
                                    href={`${feed.feedDomain.indexOf("://") === -1 ? "http://" : ""}${feed.feedDomain}`}
                                    target={"_blank"}
                                >
                                    {feed.feedDomain}
                                </Link>
                            ),
                            articleDomain: (
                                <Link
                                    href={`${feed.url.indexOf("://") === -1 ? "http://" : ""}${feed.url}`}
                                    target={"_blank"}
                                >
                                    {feed.articleDomain}
                                </Link>
                            ),
                            lastUpdatedStatus: `${feed.lastUpdatedStatus}`,
                            lastUpdatedOn: feed.lastUpdatedOn ? getAdminDate(feed.lastUpdatedOn, false)! : "N/A",
                            lastUpdated: feed.lastUpdatedOn ? new Date(feed.lastUpdatedOn).toISOString() : "N/A",
                            weight: `${feed.weight}`,
                            category: feed.category,
                            subCategory: (feed.subCategory || []).join(", "),
                            lang: feed.lang.toLowerCase(),
                            location: feed.location,
                            eventType: feed.eventType || "",
                            region: this.translateRegion(feed.region)
                        })}
                        details={{
                            id: "ID",
                            type: "Type",

                            active: "Active",
                            tcAllowed: "T&C Allowed",

                            url: "URL",
                            feedDomain: "Feed Domain",

                            category: "Category",
                            subCategory: "Sub-Category",

                            weight: "Weight",
                            lang: "Language",

                            region: "Region",
                            location: "Location",

                            lastUpdated: "Last Updated On",
                            lastUpdatedStatus: "Last Updated Status"
                        }}
                        delete={
                            this.props.user.isSuper || this.props.user.isApiAdmin
                                ? item => this.deleteFeed(item.id)
                                : undefined
                        }
                        editLink={`/feeds/e/:id`}
                        createLink={`/feeds/c`}
                        filters={{
                            articleDomain: {
                                title: "Article Domain"
                            },
                            active: {
                                title: "Is Active",
                                options: [
                                    { key: "NONE", value: "" },
                                    { key: "Active", value: "1" },
                                    { key: "Inactive", value: "0" }
                                ]
                            },
                            eventType: {
                                title: "Event Type",
                                options: [
                                    { key: "NONE", value: "" },
                                    { value: "ALL", key: "ALL" },
                                    { value: "AGM", key: "AGM - Annual General Meeting" },
                                    { value: "BR", key: "BR - Bankruptcy" },
                                    { value: "ERN", key: "ERN - Earnings" },
                                    { value: "FIN", key: "FIN - Financing" },
                                    { value: "IPO", key: "IPO - Inital Public Offerings" },
                                    { value: "JV", key: "JV - Joint Ventures" },
                                    { value: "MC", key: "MC - Management Chnages" },
                                    { value: "MA", key: "MA - Mergers & Acquistions" }
                                ]
                            },
                            location: {
                                title: "Location",
                                options: [
                                    { key: "NONE", value: "" },
                                    ...(this.state.countries || []).map(country => ({
                                        key: country.name,
                                        value: country.code
                                    }))
                                ]
                            },
                            category: {
                                title: "Category",
                                options: [
                                    { key: "NONE", value: "" },
                                    { key: "Arts Entertainment", value: "Arts Entertainment" },
                                    { key: "Business", value: "Business" },
                                    { key: "Computer Internet", value: "Computer Internet" },
                                    { key: "Health", value: "Health" },
                                    { key: "Law", value: "Law" },
                                    { key: "Lifestyle", value: "Lifestyle" },
                                    { key: "Marketing", value: "Marketing" },
                                    { key: "News Media", value: "News Media" },
                                    { key: "Politics", value: "Politics" },
                                    { key: "Recreation Sports", value: "Recreation Sports" },
                                    { key: "Science Technology", value: "Science Technology" },
                                    { key: "Society Culture", value: "Society Culture" }
                                ]
                            },
                            subCategory: {
                                title: "Sub-Categories"
                            },
                            region: {
                                title: "Region",
                                options: [
                                    { key: "NONE", value: "" },
                                    { value: "1", key: "Australia & NZ" },
                                    { value: "2", key: "Far East" },
                                    { value: "3", key: "India" },
                                    { value: "4", key: "Middle East & Africa" },
                                    { value: "5", key: "Europe" },
                                    { value: "6", key: "South America" },
                                    { value: "7", key: "East - North America" },
                                    { value: "8", key: "Central - North America" },
                                    { value: "9", key: "West - North America" },
                                    { value: "0", key: "Other" }
                                ]
                            },
                            lang: {
                                title: "Language",
                                options: [
                                    { key: "NONE", value: "" },
                                    { value: "en", key: "English" },
                                    { value: "fr", key: "French" },
                                    { value: "es", key: "Spanish" },
                                    { value: "de", key: "German" },
                                    { value: "pt", key: "Portuguese" },
                                    { value: "it", key: "Italian" }
                                ]
                            },
                            type: {
                                title: "Type",
                                options: [
                                    { key: "NONE", value: "" },
                                    { key: "RSS", value: "RSS" },
                                    { key: "Sitemap", value: "SM" }
                                ]
                            }
                        }}
                    />
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(List);
