import BaseAction from "../utils/BaseAction";

export interface IFeedSourceRequestsActions {
    doList(params: IFeedSourceRequestsListParams): Promise<{ items: Mongo.FeedSourceRequests[]; total: number }>;
    doGet(_id: string): Promise<Mongo.FeedSourceRequests | null>;
    doRemove(_id: string): Promise<{ valid: boolean } | null>;
    doUpsert(feed: Mongo.FeedSourceRequests): Promise<void>;
    doValidate(url: string, type: string): Promise<{ valid: boolean } | null>;
    doExportCSV(params: IFeedSourceRequestsListParams): Promise<void>;
}

export interface IFeedSourceRequestsListParams {
    _id?: string;
    url?: string;
    type?: string;
    location?: string;
    weight?: number;
    category?: string;
    language?: string;
    region?: number;
    subCategory?: string;
    start?: number;
    rows?: number;
}

class _FeedSourceRequestsActions extends BaseAction implements IFeedSourceRequestsActions {
    async doList(params: IFeedSourceRequestsListParams) {
        let tempParams = { ...params } as dynamic;
        try {
            if(tempParams._id === "") { delete tempParams._id }
            if(tempParams.url === "") { delete tempParams.url }
            if(tempParams.type === "") { delete tempParams.type }
            if(tempParams.location === "") { delete tempParams.location }
            if(tempParams.weight === 0) { delete tempParams.weight }
            if(tempParams.category === "") { delete tempParams.category }
            if(tempParams.language === "") { delete tempParams.language }
            if(tempParams.region == 0) { delete tempParams.region }
            if(tempParams.rows) { tempParams.limit = tempParams.rows; delete tempParams.rows }

            const data = await this.apiRequestWithErrorAlert<{ items: Mongo.feedSourceRequests[]; numFound: number }>(
                `/feedSourceRequests/list`,
                "POST",
                tempParams
            );
            if (data && data.items && data.items.length) {
                return { items: data.items, total: data.numFound };
            }
        } catch (error) {
            //
            console.log('error');
        }
        return { items: [], total: 0 } as { items: Mongo.feedSourceRequests[]; total: number };
    }

    async doGet(_id: string) {
        const data = await this.apiRequestWithErrorAlert<any>(`/feedSourceRequests/get`, "POST", {
            _id
        });
        if (data?.item) {
            return data.item;
        }
        return null;
    }

    async doRemove(_id: string|ObjectID) {
        return this.apiRequestWithErrorAlert<any>(`/feedSourceRequests/delete`, "POST", { _id }, undefined, true);
    }

    async doUpsert(feed: dynamic) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean }>(
            `/feedSourceRequests/upsert`,
            "POST",
            feed,
            undefined,
            true
        );
        if (!data || !data.valid) {
            throw new Error("Upsert failed");
        }
    }

    async doValidate(url: string, type: string, _id?: string) {
        return this.apiRequest<{ valid: boolean, message: string, data: dynamic }>(`/feedSourceRequests/validate`, "POST", { url, type, _id });
    }

    async checkIfAlreadyApproved(url: string, type: string) {
        return this.apiRequest<{ valid: boolean, message: string, data: dynamic }>(`/feedSourceRequests/checkIfAlreadyApproved`, "POST", { url, type });
    }

    async doExportCSV(query: IFeedSourceRequestsListParams) {
        const data = await this.apiRequest("/feed/list/csv", "GET", { ...query });
        if (data) {
            return this.downloadFile("feeds", data);
        }
    }

    async doGetDomainInfo(url: string) {
        const data = await this.apiRequestWithErrorAlert<{ domain: string }>(`/domainInfo`, "POST", {
            url
        });
        return (data || { domain: "" }).domain;
    }

    async doApprove(_id: string | ObjectID) {
        return this.apiRequestWithErrorAlert<any>(`/feedSourceRequests/approve`, "POST", { _id });
    }

    async doReject(_id: string | ObjectID) {
        return this.apiRequestWithErrorAlert<any>(`/feedSourceRequests/reject`, "POST", { _id });
    }

}

export const FeedSourceRequestsActions = new _FeedSourceRequestsActions();
