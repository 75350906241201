import React, { Component } from "react";
import { connect } from "react-redux";
import CSVUpload from "../../molecules/CSVUpload";

class UploadAdmins extends Component {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);
    }

    render() {
        return <CSVUpload
            itemName="Admin"
            routeName="clientadmin"
            note={<span>
                <p style={{ paddingLeft: "50px", color: "#DD0000", fontWeight: "bold"  }}>The CSV template was updated on 2021-11-10. Please ensure you are using the latest CSV template, available above.</p>
            </span>}
        />
    }
}


const mapStateToProps = state => ({});

export default connect(mapStateToProps)(UploadAdmins);
