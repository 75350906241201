import React, { useRef } from "react";
import Button from "./Button";
import { createAlert } from "../../actions/utils";

interface ICopyLinkProps {
    textToCopy: string;
}

export const CopyLink = (props: ICopyLinkProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { textToCopy } = props;

    const copyToClipboard = async (e) => {
        const targetElement = e.target;
        try {
            if (inputRef && inputRef.current) {
                await navigator.clipboard.writeText(textToCopy);
                if (targetElement) {
                    targetElement.focus();
                }
                createAlert(`The text has been successfully copied`, `success`);
            }
        } catch (err) {
            console.log(err)
            createAlert(`${err}`, `error`);
        }

    }

    return (
        <div className="copy-link">
            <input
                readOnly
                ref={inputRef}
                type="text"
                className="form__value"
                id="copyLink"
                value={textToCopy}
                style={{ color: '#5c5f60', backgroundColor: '#dddddd' }}
            />
            {navigator.clipboard && (
                <div>
                    <Button onClick={(e) => copyToClipboard(e)} ariaLabel="Copy Link to Clipboard">
                        <i style={{ pointerEvents: "none" }} className="fa fa-copy" aria-hidden="true"></i>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CopyLink;
