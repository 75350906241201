import BaseAction from "../utils/BaseAction";
import { IReportFilter } from "../components/pages/reports/Index";

export type ISSOLogActions = ClassInstance<typeof _SSOLogActions>;

class _SSOLogActions extends BaseAction {
    async doList(query: IReportFilter & {
        sort?: string;
        sortDirection?: number;
        skip?: number;
        limit?: number;
    }) {

        const ssoQuery = {
            clientCode: query.clientCode ? query.clientCode.trim() : query.clientCode,
            nameId: query.ssoId ? query.ssoId.trim() : query.ssoId,
            log: query.ssoLog ? query.ssoLog.trim() : query.ssoLog,
            sd: query.sd,
            sdtz: query.sdtz,
            ed: query.ed,
            edtz: query.edtz,
            sort: query.sort,
            sortDirection: query.sortDirection,
            ...(query.skip ? {skip: query.skip} : null),
            limit: query.limit
        }


        const data = await this.apiRequestWithErrorAlert<{
            items: ServerTypes.Console.ISSOLog[];
            count: number;
        }>(`/reports/ssoLogs`, `GET`, ssoQuery);

        if (data && data.items && data.items.length) {
            return { items: data.items, total: data.count };
        } else {
            return { items: [], total: 0 };
        }
    }
}

export const SSOLogActions = new _SSOLogActions();