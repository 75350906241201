import React, { Component, ChangeEvent } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { doLogin, doSSOSignIn } from "../../../actions/session";
import Link from "../../atoms/Link";
import { createAlert } from "../../../actions/utils";
import { getHostName } from "../../../utils/Helpers";
import { ConfirmModal } from "../../atoms/Modal";
import Button from "../../atoms/Button";
import LogoLogin from "../../atoms/LogoLogin";

interface ISignInProps extends RouteComponentProps {

}
interface ISignInState {
    username: string;
    password: string;
    redirectUrl: string;
    sso: boolean;
}

class SignIn extends Component<ISignInProps, ISignInState> {
    private userInput: HTMLInputElement | null = null;
    constructor(props: ISignInProps) {
        super(props);

        this._onUsernameChange = this._onUsernameChange.bind(this);
        this._onPasswordChange = this._onPasswordChange.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this.state = {
            username: "",
            password: "",
            redirectUrl: "",
            sso: false,
        };
    }

    async componentDidMount() {
        const sso = await doSSOSignIn();

        this.setState({ sso }, () => {
            this.userInput?.focus();
        });
    }

    _onSubmit(values) {
        values.preventDefault();
        const { username, password } = this.state;

        username.trim();
        password.trim();
        if (!username || username.length == 0 || !password || password.length == 0) {
            createAlert("Please enter in a email and password before attempting to login");
            return;
        }
        doLogin(username, password).then(data => {
            localStorage.removeItem("logoutMessage");
            if (!data.valid) {
                if ("redirectUrl" in data) {
                    setTimeout(() => {
                        this.setState({ redirectUrl: data.redirectUrl });
                    }, 0);
                }
            }
        });
        return false;
    }

    _onUsernameChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({ username: event.target.value });
    }

    _onPasswordChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({ password: event.target.value });
    }

    render() {
        return !this.state.sso && (
            <div id = "login" className="page login">
                {this.state.redirectUrl && (
                    <ConfirmModal
                        title={"Your account is restricted from logging in from this domain"}
                        force={true}
                        button={`Take me there`}
                        open={true}
                        onConfirm={() => {
                            setTimeout(() => {
                                if (window) {
                                    window.location.replace(`${this.state.redirectUrl}`);
                                }
                            }, 0);
                        }}
                    >
                        <h2>
                            Please head over to{" "}
                            <a href={this.state.redirectUrl}>{getHostName(this.state.redirectUrl)}</a> to access the
                            console.
                        </h2>
                    </ConfirmModal>
                )}
                <div className="login__wrapper brandPrimary--border brandPrimary--color">
                    <div style={{textAlign:"center"}}>
                        <h1 className="page__title">
                            <LogoLogin className="login__logo"/>
                        </h1>
                        {/* if the user is signed out due to inactivity, show this message */}
                        <h3>{localStorage.getItem("logoutMessage")}</h3>
                    </div>
                    <form onSubmit={this._onSubmit} className="login__form">
                        <div className="login__email">
                            <label>
                                <span>Email or Username:</span>
                                <input
                                    id="username"
                                    className="login__input"
                                    ref={input => {
                                        this.userInput = input;
                                    }}
                                    type="text"
                                    onChange={this._onUsernameChange}
                                />
                            </label>
                        </div>
                        <div className="login__password">
                            <label>
                                <span>Password:</span>
                                <input type="password" id="password" onChange={this._onPasswordChange} />
                            </label>
                        </div>
                        <div className="login__btns">
                        <Button type="submit" className="login__btn" value="Login" />
                        </div>
                        <br />
                        <Link to="/login/recover-account" id="recover">Recover account...</Link>
                    </form>
                </div>
            </div>
        );
    }
}

export default SignIn;
