import React, { Component } from "react";
import { connect } from "react-redux";
import { getFriendlyDate, apiRequest, getAdminDate } from "../../utils/Helpers";
import Spinner from "../atoms/Spinner";
import appConfig from "../../../config/config.dev";
var striptags = require("striptags");
import Tooltip from "../atoms/Tooltip";
import { doDuplicateECommunicationMessage , doGetECommunicationSubscribables} from "../../actions";
import AddECommunicationMessage from "./modals/AddECommunicationMessage";
import Button from "../atoms/Button";
import { MESSAGE_FREQUENCY , getNamebyValue , containValue } from "../../utils/ECommunicationHelpers";
import { createAlert } from "../../actions/utils";
import ResizeImg from "./ResizeImg";

class ECommunicationMessageCard extends Component {
    constructor(props) {
        super(props);
        this._toggleFullContent = this._toggleFullContent.bind(this);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._onMessageFieldChange = this._onMessageFieldChange.bind(this);
        this._onUpdate = this._onUpdate.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this._messageUpdate = this._messageUpdate.bind(this);
        this._renderButtons = this._renderButtons.bind(this);
        this._runCloseActions = this._runCloseActions.bind(this);
        this._renderLastModifiedBy = this._renderLastModifiedBy.bind(this);
        this._renderCreatedBy = this._renderCreatedBy.bind(this);

        let message = props.message;
        if (message.Description) {
            let descriptionPreview = striptags(message.Description);
            if (descriptionPreview.length > 100) {
                descriptionPreview = descriptionPreview.substring(0, 100).trim() + " [...]";
            }
            message.DescriptionPreview = descriptionPreview;
        }
        this.state = {
            message: message,
            client: props.client,
            canEdit: props.canEdit || false,
            editing: props.editing || false,
            remove: false,
            saving: false,
            dropdownOpen: false,
            showMoreLikeThis: false
        };
        if (props.client){
            doGetECommunicationSubscribables(props.client.cid).then(data => {
                let state = { subscribable : "" };
                if (data) {
                    let subscribables = data.items;
                    if (subscribables){
                        state.subscribable = subscribables.find(v => v.subscribableid == message.subscribableid);
                    }
                    this.setState(state);
                }
            });
        }
    }

    _onFieldUpdate(fieldName, event) {
        if (fieldName.indexOf("editing") !== -1) {
            this.setState({ dropdownOpen: false });
        }
        if (fieldName.indexOf("editing") !== -1 && this.state.saving) {
            return;
        }
        this.setState({
            [fieldName]: event.target ? event.target.value : event
        });
    }
    componentWillReceiveProps(newProps) {
        if (newProps.message != this.state.message) {
            this.setState({ message: newProps.message });
        }
    }
    _onMessageFieldChange(fieldName, event) {
        let { message } = this.state;
        message[fieldName] = event.target ? event.target.value : event;
        this.setState({ message });
    }
    _toggleFullContent() {
        this.setState({
            fullContentOpen: !this.state.fullContentOpen
        });
    }
    _onUpdate() {
        this.setState({ saving: true });
        let message = Object.assign({}, this.state.message);
        apiRequest(`${appConfig.API_URL}e-communication/message/update`, `POST`, message)
            .then(
                function(data) {
                    this.setState({
                        saving: false,
                        dropdownOpen: false,
                        remove: this.state.message.sid != this.state.stream.sid
                    });
                }.bind(this)
            )
            .then(
                function(data) {
                    if (this.props.editAction) {
                        this.props.editAction();
                    }
                }.bind(this)
            );
    }
    _onDuplicateMessage(cid, ecommid){
        if (confirm("Are you sure you want to duplicate this message?")) {
            doDuplicateECommunicationMessage(cid, ecommid).then(
                function(data) {
                    createAlert(`Message Duplicated`, `success`);
                    this._runCloseActions();
                }.bind(this)
            )
        } else {
            this.setState({ dropdownOpen: false });
        }
    }

    _runCloseActions() {
        if (this.props.deleteAction) {
            this.props.deleteAction();
        }
        if (this.props.editAction) {
            this.props.editAction();
        }
    }

    _renderButtons() {
        let { dropdownOpen, editing, stream, client, approved, canApprove, canEdit, message } = this.state;
        return (
            <div className={`footer__buttons`}>
                <Button
                    className="btn"
                    onClick={() => this._onFieldUpdate("editing", true)}
                    disabled={this.state.save_protect}
                >
                    Update
                </Button>

                {( !message.type || message.type == "standard" ?
                <Button
                    className="btn"
                    onClick={() => this._onDuplicateMessage(message.cid, message.ecommid)}
                    disabled={this.state.save_protect}
                >
                    Duplicate
                </Button>
                : ""
                )}
            </div>
        );
    }

    async componentDidMount() {
        if (document) {
            document.addEventListener("mousedown", this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (document) {
            document.removeEventListener("mousedown", this.handleClickOutside);
        }
    }
    handleClickOutside(event) {
        if (this.state.dropdownOpen) {
            if (
                (this.dropdownWrapper && this.dropdownWrapper.contains(event.target)) ||
                (this.dropdownTools && this.dropdownTools.contains(event.target))
            ) {
                return;
            } else {
                this.setState({ dropdownOpen: false });
            }
        }
    }
    _messageUpdate(isEdit) {
        this._onFieldUpdate("editing", false);
        if (!isEdit) {
            let message = this.state.message;
            if (!message.status) {
                message.status = "approved";
            }
            this.setState({ message: message, approved: true });
        }
        if (this.props.editAction) {
            this.props.editAction();
        }
    }

    render() {
        const { client, editing, remove, message, creationInfo } = this.state;
        if (remove) {
            return <span />;
        }
        if (!message) {
            return <Spinner />;
        }
        if (editing && !message.Image) {
            message.Image = "";
        }
        return (
            <div className="article-card__container">
                {editing && (
                    <AddECommunicationMessage
                        cid={ this.state.client ? this.state.client.cid : this.state.message.cid }
                        client={ this.state.client }
                        message={this.state.message}
                        updateAction={this._messageUpdate}
                        deleteAction={this.props.deleteAction}
                        closeAction={() => this.setState({ editing: false })}
                        modal_button_label={this.props.modal_button_label}
                    />
                )}
                <div className="article-card">
                    <div className="article-card__header">
                    </div>
                    <div className="article-card__content">
                        <h4 className="article-card__title">
                            <span>{message.Title}</span>
                        </h4>
                        <div className="article-card__info">
                            <span>{message.Description}</span>
                        </div>
                        {message.Image && (<div className="article-card__image__wrapper"><ResizeImg className="article-card__image" src={message.Image}/></div>)}
                    </div>
                    <div className="article-card__footer">
                        {this._renderTags()}
                        <div className="article-card__info">
                            {( !message.type || message.type == "standard" ?
                                this._renderFrequency(message.frequency)
                            : "" )}
                            {( !message.type || message.type == "standard" ?
                                this._renderType()
                            : "" )}
                        </div>
                        {(message.activeDate || message.inActiveDate) && (
                            <div className="article-card__approved">
                                {this._renderApprovals(
                                    message.status,
                                    message.activeDate,
                                    message.inActiveDate,
                                    message.scheduledDelivery,
                                    message.frequency,
                                    message.lastUpdatedOn
                                )}
                            </div>
                        )}
                        <div className="article-card__createdOn">
                            {this._renderCreatedBy()}
                        </div>
                        {(message.lastUpdatedOn) && (
                            <div className="article-card__lastModified">
                                {this._renderLastModifiedBy()}
                            </div>
                        )}
                    </div>
                    {this._renderButtons()}
                </div>
            </div>
        );
    }
    _renderFrequency(frequency){
        let friendlyFrequency = "One Time";
        if (containValue(MESSAGE_FREQUENCY , frequency)){
            friendlyFrequency = getNamebyValue(MESSAGE_FREQUENCY , frequency);
        }
        return(
            <span>
                Type : {friendlyFrequency}
            </span>
        );
    }

    _renderType(){
        let friendlyType = "";
        if (this.state.subscribable && this.state.subscribable.title){
            friendlyType += " - " + this.state.subscribable.title;
        }
        return( <span>{friendlyType}</span> );
    }
    _renderCreatedBy() {
        return(
            <span>
                {"Created by " + this.state.message.CreatedBy + " "}
                <Tooltip label={getFriendlyDate(this.state.message.CreatedOn)}>
                    {getAdminDate(this.state.message.CreatedOn)}
                </Tooltip>
            </span>
        );
    }
    _renderLastModifiedBy() {
        return(
            <span>
                {"Last modified by " + this.state.message.lastUpdatedBy + " "}
                <Tooltip label={getFriendlyDate(this.state.message.lastUpdatedOn)}>
                    {getAdminDate(this.state.message.lastUpdatedOn)}
                </Tooltip>
            </span>
        );
    }
    _renderApprovals(status, publish, expiry, scheduled, frequency, lastUpdatedOn) {
        let friendlyStatus = `${status.charAt(0).toUpperCase()}${status.slice(1).toLowerCase()}`;
        const publishedFriendlyStatus = publish && new Date(publish).getTime() <= new Date().getTime() ? "Published" : "Publishing";
        const expiredFriendlyStatus = expiry && new Date(expiry).getTime() <= new Date().getTime() ? "Expired" : "Expires";
        const scheduledFriendlyStatus = scheduled && new Date(scheduled).getTime() <= new Date().getTime() ? "Delivered on" : "Scheduled to send";

        if (status == "inactive") {
            return (<span>{friendlyStatus}</span>);
        } else {
            switch (frequency){
                case "onetime":
                    if (publish && !expiry) {
                        return (
                            <span tabIndex={0}>
                                {friendlyStatus} - {publishedFriendlyStatus} {getFriendlyDate(publish)}
                            </span>
                        );
                    } else if (!publish && expiry) {
                        return (
                            <span tabIndex={0}>
                                {friendlyStatus} - {expiredFriendlyStatus} {getFriendlyDate(expiry)}
                            </span>
                        );
                    } else if (publish && expiry) {
                        return (
                            <span tabIndex={0}>
                                {friendlyStatus} - {publishedFriendlyStatus} {getFriendlyDate(publish)}&nbsp;and&nbsp;{expiredFriendlyStatus.toLowerCase()}{" "}
                                {getFriendlyDate(expiry)}
                            </span>
                        );
                    }
                    break;
                default:
                    if (publish && !scheduled) {
                        return (
                            <span tabIndex={0}>
                                {friendlyStatus} - {publishedFriendlyStatus} {getFriendlyDate(publish)}
                            </span>
                        );
                    } else if (!publish && scheduled) {
                        return (
                            <span tabIndex={0}>
                                {friendlyStatus} - {scheduledFriendlyStatus} {getFriendlyDate(scheduled)}
                            </span>
                        );
                    } else if (publish && scheduled) {
                        return (
                            <span tabIndex={0}>
                                {friendlyStatus} - {publishedFriendlyStatus} {getFriendlyDate(publish)}&nbsp;and&nbsp;{scheduledFriendlyStatus.toLowerCase()}{" "}
                                {getFriendlyDate(scheduled)}
                            </span>
                        );
                    }
                    break;
            }
            return (
                <span tabIndex={0}>
                    {friendlyStatus} - {getFriendlyDate(lastUpdatedOn)}
                </span>
            );
        }
    }
    _renderTags() {
        const { message, editing } = this.state;
        const { client }  = this.props;
        if (!message.tags || !client.tags) {
            return;
        }
        if (typeof message.tags === "string") {
            return (
                <div className="article-card__tags">
                    <span className="article-card__tag" tabIndex={0}>{client.tags[message.tags]}</span>
                </div>
            );
        }
        return (
            <div className="article-card__tags">
                {message.tags &&
                    message.tags.map(function(tag, index) {
                        return (
                            <span className="article-card__tag" key={tag} tabIndex={0}>
                                {client.tags[tag]}
                                {index < message.tags.length - 1 ? " , " : ""}
                            </span>
                        );
                    })}
            </div>
        );
    }
}
const mapStateToProps = state => ({ streams: state.lists.streams, user: state.session.admin });

export default connect(mapStateToProps)(ECommunicationMessageCard);
