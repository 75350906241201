import React, { Component } from "react";

import { getLanguages } from "../../../utils/Helpers";
import Modal from "./Modal";
import Tooltip from "../../atoms/Tooltip";
import Button from "../../atoms/Button";
import { doGetStreams } from "../../../actions";

interface ICreateStreamModalProps {
    client: Mongo.IClient;
    closeAction: () => void;
    open?: boolean;
}
interface ICreateStreamModalState {
    open: boolean;
    streams: ServerTypes.Console.IStream[];
    createProf: boolean;
    createPers: boolean;
    createTrending: boolean;
    createReco: boolean;
    createCompany: boolean;
    createHelp: boolean;
}
export default class CreateStreamModal extends Component<ICreateStreamModalProps, ICreateStreamModalState> {
    constructor(props: ICreateStreamModalProps) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.getAvailableStreams = this.getAvailableStreams.bind(this);

        this.state = {
            open: props.open || false,
            streams: [],
            createProf: true,
            createPers: true,
            createTrending: true,
            createReco: false,
            createCompany: false,
            createHelp: true

        };
    }
    async componentDidMount() {
        const { client } = this.props;
        const streams = (await doGetStreams(true, client.cid)).items;

        const langs = this.getAvailableStreams(streams);
        this.setState({
            streams,
            ...(langs && {
                createProf: langs.PROF.length > 0,
                createPers: langs.PERS.length > 0,
                createTrending: langs.TRENDING.length > 0,
                createReco: langs.RECO.length > 0,
                createCompany: false // client && client.indexedLibrary && client.indexedLibrary.enabled || false
            })
        });
    }

    componentWillReceiveProps(newProps: ICreateStreamModalProps) {
        if (newProps.open !== this.props.open) {
            this.setState({ open: newProps.open! });
        }
    }

    getAvailableStreams(streams: ServerTypes.Console.IStream[]) {
        type _Langs = Array<{ value: string; label: string }>;
        const langs = {
            PROF: [] as _Langs,
            PERS: [] as _Langs,
            TRENDING: [] as _Langs,
            RECO: [] as _Langs
        };

        const streamLangs = {
            PROF: [] as string[],
            PERS: [] as string[],
            TRENDING: [] as string[],
            RECO: [] as string[]
        };
        streams.forEach(stream => {
            if (stream.type == "TRENDING") {
                streamLangs.TRENDING.push(stream.lang);
            } else if (!stream.contentSearch && stream.searchType && stream.searchType !== "APPR" && stream.searchType !== "CORP") {
                streamLangs[stream.searchType].push(stream.lang);
            }
        });
        getLanguages().forEach(val => {
            if (streamLangs.PROF.indexOf(val.code) == -1) {
                langs.PROF.push({ value: val.code, label: val.name });
            }
            if (streamLangs.PERS.indexOf(val.code) == -1) {
                langs.PERS.push({ value: val.code, label: val.name });
            }
            if (streamLangs.TRENDING.indexOf(val.code) == -1) {
                langs.TRENDING.push({ value: val.code, label: val.name });
            }
            if (streamLangs.RECO.indexOf(val.code) == -1) {
                langs.RECO.push({ value: val.code, label: val.name });
            }
        });
        return langs;
    }
    closeModal() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
    }

    render() {
        const { open } = this.state;
        const { client } = this.props;
        const { cid } = client;
        return (
            <Modal open={open} title="Create a Stream" closeAction={() => this.closeModal()}>
                <form className="form">
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        {this.state.createProf && (
                            <Button
                                to={`/clients/s/${cid}/c/prof`}
                                className="btn"
                                style={{
                                    marginBottom: "20px",
                                    flexBasis: "47.5%"
                                }}
                            >
                                Professional Stream&nbsp;
                                <Tooltip>Create a basic professional content stream</Tooltip>
                            </Button>
                        )}
                        {this.state.createPers && (
                            <Button
                                to={`/clients/s/${cid}/c/pers`}
                                className="btn"
                                style={{
                                    marginBottom: "20px",
                                    flexBasis: "47.5%"
                                }}
                            >
                                Personal Stream&nbsp;
                                <Tooltip>Create a basic personal content stream</Tooltip>
                            </Button>
                        )}
                        <Button
                            to={`/clients/s/${cid}/c/corp`}
                            className="btn"
                            style={{
                                marginBottom: "20px",
                                flexBasis: "47.5%"
                            }}
                        >
                            Corporate Stream&nbsp;
                            <Tooltip>Create a Corporate content stream</Tooltip>
                        </Button>
                        <Button
                            to={`/clients/s/${cid}/c/curated`}
                            className="btn"
                            style={{
                                marginBottom: "20px",
                                flexBasis: "47.5%"
                            }}
                        >
                            Curated Stream&nbsp;
                            <Tooltip>This will create a stream that needs to have articles curated into it</Tooltip>
                        </Button>
                        {this.state.createTrending && (
                            <Button
                                to={`/clients/s/${cid}/c/trending`}
                                className="btn"
                                style={{
                                    marginBottom: "20px",
                                    flexBasis: "47.5%"
                                }}
                            >
                                Trending Stream&nbsp;
                            </Button>
                        )}
                        <Button
                            to={`/clients/s/${cid}/c/feed`}
                            className="btn"
                            style={{
                                marginBottom: "20px",
                                flexBasis: "47.5%"
                            }}
                        >
                            Sitemap/RSS
                            {client && client.allowSocialStreams ? "/Social" : ""}
                            &nbsp;Stream&nbsp;
                            <Tooltip>
                                Create a stream with content from an Sitemap or RSS Feed{" "}
                                {client && client.allowSocialStreams ? "or a X (Twitter) or Facebook page" : ""}
                            </Tooltip>
                        </Button>
                        {this.state.createReco && ( //experimental mode released in GV6-12109
                            <Button
                                to={`/clients/s/${cid}/c/recommended`}
                                className="btn"
                                style={{
                                    marginBottom: "20px",
                                    flexBasis: "47.5%"
                                }}
                            >
                                Recommended Stream&nbsp;
                                <Tooltip>Create a stream that recommends articles based on your profile</Tooltip>
                            </Button>
                        )}
                        {this.state.createHelp && (
                            <Button
                                to={`/clients/s/${cid}/c/help`}
                                className="btn"
                                style={{
                                    marginBottom: "20px",
                                    flexBasis: "47.5%"
                                }}
                            >
                                Help Stream&nbsp;
                            </Button>
                        )}
                        {this.state.createCompany && (
                            <Button
                                to={`/clients/s/${cid}/c/company`}
                                className="btn"
                                style={{
                                    marginBottom: "20px",
                                    flexBasis: "47.5%"
                                }}
                            >
                                Company Stream&nbsp;
                                <Tooltip>
                                    Create a stream that contains articles from the companies private index
                                </Tooltip>
                            </Button>
                        )}
                    </div>
                </form>
            </Modal>
        );
    }
}
