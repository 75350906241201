import React, { Component } from "react";
import SwaggerUI from "swagger-ui";
import Spinner from "../../atoms/Spinner";
import { apiRequest, apiFormDataPost } from "../../../utils/Helpers";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import { Wrapper, Row } from "../../atoms/Layout/";

import "swagger-ui/dist/swagger-ui.css";

class Swagger extends Component {
    constructor(props) {
        super(props);

        this.state = {
            specs: [],
            hardSpecs: {},
            selectedSpec: -1,
            swaggerSpec: null,
            swaggerSpecLoading: false,
            swaggerVisible: false
        };
    }

    componentDidMount() {
        this._refresh();
    }

    async _refresh(
        callback = () => {
            return;
        }
    ) {
        const routes = {
            console: `/api-docs/console.json`,
            app: `/api-docs/app.json`,
            api: `/api-docs/api.json`
        };
        for (const k in routes) {
            const data = await apiRequest(routes[k]);
            if (data && "basePath" in data && "info" in data) {
                this.setState({
                    hardSpecs: { ...this.state.hardSpecs, [k]: data },
                    specs: this.state.specs.concat({ ...data.info, basePath: data.basePath })
                });
            }
        }

        const swaggerData = await apiRequest(`${appConfig.API_URL}/swagger/get`, `POST`, { infoOnly: true });
        if (swaggerData) {
            this.setState({ specs: this.state.specs.concat(swaggerData) }, callback);
        }
    }

    submitSpec() {
        const formData = new FormData();
        formData.append("file", this.fileUpload.files[0]);
        apiFormDataPost(`${appConfig.API_URL}/swagger/upsert`, formData).then(data => {
            if ("valid" in data && data.valid) {
                this.fileUpload.value = "";
                if (data.method == "UPDATE") {
                    createAlert(`Successfully updated the swagger spec for ${data.title} v${data.version}`, "success");
                    this._refresh(() => {
                        this._selectSpec(this.state.selectedSpec);
                    });
                } else if (data.method == "INSERT") {
                    createAlert(`Successfully inserted a new swagger spec: ${data.title} v${data.version}`, "success");
                    this._refresh();
                }
            } else {
                this.fileUpload.value = "";
                createAlert("Unable to process the uploaded spec: " + data.error);
                this._refresh();
            }
        });
    }

    _selectSpec(index) {
        this.setState({ selectedSpec: index });
        const spec = this.state.specs[index];

        if (window) {
            $("#swaggerContainer").html("");
        }
        if (spec) {
            for (const k in this.state.hardSpecs) {
                if (
                    spec.basePath == this.state.hardSpecs[k].basePath &&
                    spec.version == this.state.hardSpecs[k].info.version
                ) {
                    this.setState({ swaggerSpecLoading: false, swaggerVisible: true }, () => {
                        SwaggerUI({
                            dom_id: "#swaggerContainer",
                            spec: this.state.hardSpecs[k]
                        });
                    });
                    return;
                }
            }
            this.setState({ swaggerSpecLoading: true });
            apiRequest(`${appConfig.API_URL}/swagger/get`, `POST`, {
                infoOnly: false,
                version: spec.version,
                host: spec.host,
                basePath: spec.basePath
            }).then(data => {
                if (data) {
                    this.setState({ swaggerSpecLoading: false, swaggerVisible: true }, () => {
                        SwaggerUI({
                            dom_id: "#swaggerContainer",
                            spec: data
                        });
                    });
                }
            });
        } else {
            this.setState({ swaggerSpecLoading: false, swaggerVisible: false });
        }
    }

    render() {
        const itemGroups = {};
        for (const i in this.state.specs) {
            const spec = this.state.specs[i];
            if (!itemGroups[`${spec.title}${spec.basePath}`]) {
                itemGroups[`${spec.title}${spec.basePath}`] = [];
            }
            itemGroups[`${spec.title}${spec.basePath}`].push({ title: spec.title, version: spec.version, index: i });
        }
        return (
            <Wrapper>
                <Row style={{ marginBottom: "20px" }}>
                    <div className="col-lg-12">
                        <div className="table__filters">
                            {Object.keys(itemGroups).map(group => {
                                const item = itemGroups[group];
                                if (!item && item.length > 0) {
                                    return;
                                }
                                return (
                                    <div className="table__filters__option">
                                        <h5>{item[0].title}</h5>
                                        <div className="select__wrapper">
                                            <select
                                                value={this.state.selectedSpec}
                                                onChange={e => {
                                                    this._selectSpec(e.target.value);
                                                }}
                                            >
                                                <option value="">Select a version</option>
                                                {item.length > 0 &&
                                                    item.map((itemVal, i) => {
                                                        return (
                                                            <option
                                                                key={`${itemVal.title}-${itemVal.version}`}
                                                                value={itemVal.index}
                                                            >{`v${itemVal.version}`}</option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="table__filters__option" style={{ marginLeft: "auto" }}>
                                <h5>Upload new document:</h5>
                                <input
                                    type="file"
                                    ref={input => {
                                        this.fileUpload = input;
                                    }}
                                    onChange={e => this.submitSpec()}
                                    style={{ paddingLeft: 0 }}
                                />
                            </div>
                        </div>
                    </div>
                </Row>
                {this._renderSwaggerSpec()}
            </Wrapper>
        );
    }

    _renderSwaggerSpec() {
        const { swaggerSpecLoading, swaggerVisible } = this.state;
        return (
            <Row>
                <div className="col-sm-12">
                    <div className={swaggerVisible ? `ibox float-e-margins` : ""}>
                        <div
                            className={swaggerVisible ? `ibox-content` : ""}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            {swaggerSpecLoading && <Spinner />}
                            <div key={Math.random() * 100} id="swaggerContainer"></div>
                        </div>
                    </div>
                </div>
            </Row>
        );
    }
}

Swagger.allowSuper = true;
Swagger.allowApiAdmin = false;
Swagger.allowClientAdmin = false;
Swagger.allowAdmin = false;
Swagger.allowCurator = false;
Swagger.allowReports = false;

export default Swagger;
