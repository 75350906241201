import React, { Component } from "react";
import { apiRequest, downloadFile as fileDownload, getLocaleDate, getPercent } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";
import { createAlert } from "../../../../actions/utils";
import { connect } from "react-redux";
import PercentCircle from "../../../atoms/PercentCircle";
import NumberChart from "../../../molecules/NumberChart";
import { Wrapper } from "../../../atoms/Layout";
import Chart from "../../../molecules/Chart";
import { IReportFilter } from "../Index";

interface IAdoptionProps {
    filters: IReportFilter;
    user: Mongo.clientAdmin;
    client?: Mongo.client;
    onLoaded: () => void;
    showSearchResults: boolean;
}

interface IAdoptionState {
    adoption: "loading" | {
        access: number;
        activated: number;
        active: number;
        shared: number;
        article: number;
        image: number;
        pdf: number;
        video: number;
        text: number;
        sessionCount: number;
        sessionTime: number;
        sessionUsers: number;
    };
    shareMaturity: "loading" | dynamic<number>;
    sharingChannels: "loading" | {
        socialShares: number;
        socialAdoption: number;
        webShares: number;
        webAdoption: number;
        emailShares: number;
        emailAdoption: number;
        deviceSMSShares: number;
        deviceSMSAdoption: number;
        copyLinkShares: number;
        copyLinkAdoption: number;
        smsMessages: number;
        socialPermissions: number;
        webPermissions: number;
        emailPermissions: number;
        deviceSMSPermissions: number;
        copyLinkPermissions: number;
        eCommPermissions: number;
        appSMSPermissions: number;
        socialEngagement: number;
        webEngagement: number;
        emailEngagement: number;
        deviceSMSEngagement: number;
        copyLinkEngagement: number;
        appSMSEngagement: number;
        appSMSAdoption: number;
    };
    filters?: dynamic;
}

class AdoptionSection extends Component<IAdoptionProps, IAdoptionState> {
    constructor(props: IAdoptionProps) {
        super(props);

        this.state = {
            adoption: "loading",
            shareMaturity: "loading",
            sharingChannels: "loading"
        };
    }

    componentDidMount() {
        if (!this.props.filters || !this.props.showSearchResults) return;
        if (
            this.state.adoption == "loading" ||
            this.state.shareMaturity == "loading"
        ) {
            this._fetchAll(this.props);
        }
    }

    componentDidUpdate(prevProps: IAdoptionProps) {
        if ((prevProps.filters != this.props.filters) || (this.props.showSearchResults && !prevProps.showSearchResults)) {
            this._fetchAll(this.props);
        }
    }

    _fetchAll(props: IAdoptionProps) {
        this.setState(
            {
                adoption: "loading",
                shareMaturity: "loading",
                filters: props.filters
            },
            () => {
                Promise.all([
                    this._fetchShareMaturity(props.filters),
                    this._fetchAdoptionSummary(props.filters),
                    this._fetchSharingChannels(props.filters)
                ]).finally(props.onLoaded);
            }
        );
    }

    _fetchShareMaturity(filters, format = "json") {
        return apiRequest(`${appConfig.API_URL}/reports/adoption/shareMaturity`, "Post", filters)
            .then(data => {
                if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                    throw data.error;
                }
                this.setState({
                    shareMaturity: data.shareMaturity
                });
            }).catch(error => {
                this.setState({ shareMaturity: { "0-4": 0, "5-10": 0, "11-19": 0, "20+": 0 } });
                createAlert(error, `error`);
            })
    }

    _fetchAdoptionSummary(filters, format = "json") {
        return apiRequest(`${appConfig.API_URL}/reports/adoption/summary`, "Post", {...filters, format})
            .then(data => {
                if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                    throw data.error;
                }
                if (format === "csv") {
                    fileDownload(data, `adoptionByContentType-${getLocaleDate(new Date())}.csv`);
                } else {
                    this.setState({ adoption: data });
                }
            })
            .catch(error => {
                if (format !== "csv") this.setState({ adoption: {
                    access: 0, activated: 0, active: 0, shared: 0, article: 0, image: 0, pdf: 0, video: 0, text: 0, sessionCount: 0, sessionTime: 0, sessionUsers: 0
                } });
                createAlert(error, `error`);
            })
    }

    _fetchSharingChannels(filters, format = "json") {
        return apiRequest(`${appConfig.API_URL}/reports/adoption/sharingChannels`, "Post", {...filters, format})
            .then(data => {
                if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                    throw data.error;
                }
                if (format === "csv") {
                    fileDownload(data, `sharingChannelAdoption-${getLocaleDate(new Date())}.csv`);
                } else {
                    this.setState({ sharingChannels: data });
                }
            })
            .catch(error => {
                if (format !== "csv") this.setState({
                    sharingChannels: {
                        socialShares: 0,
                        socialAdoption: 0,
                        webShares: 0,
                        webAdoption: 0,
                        emailShares: 0,
                        emailAdoption: 0,
                        deviceSMSShares: 0,
                        deviceSMSAdoption: 0,
                        copyLinkShares: 0,
                        copyLinkAdoption: 0,
                        smsMessages: 0,
                        socialPermissions: 0,
                        webPermissions: 0,
                        emailPermissions: 0,
                        deviceSMSPermissions: 0,
                        copyLinkPermissions: 0,
                        eCommPermissions: 0,
                        appSMSPermissions: 0,
                        appSMSAdoption: 0,
                        socialEngagement: 0,
                        webEngagement: 0,
                        emailEngagement: 0,
                        deviceSMSEngagement: 0,
                        copyLinkEngagement: 0,
                        appSMSEngagement: 0
                    }
                });
                createAlert(error, `error`);
            })
    }

    _evenPercent(values: dynamic<number>) {
        const total = Object.values(values).reduce((a, b) => a! + b!, 0);
        let remainder =
            100 -
            Object.values(values)
                .map(a => Math.floor((a! / total!) * 100))
                .reduce((a, b) => a + b);
        const percentages: [string, number][] = Object.keys(values).map((key, i) => [key, Math.floor((values[key]! / total!) * 100)]);
        percentages.sort((a, b) => b[1] - a[1]);
        return Object.assign(
            {},
            ...percentages.map(item => ({ [item[0]]: Number(item[1]) + Number(remainder-- > 0 ? 1 : 0) }))
        );
    }

    _renderAdoptionDetails() {
        const labels = [`Access`, `Activated`, `Signed In`, `Shared`];
        const data = typeof this.state.adoption === "string" ? [] : [
            this.state.adoption.access,
            this.state.adoption.activated,
            this.state.adoption.active,
            this.state.adoption.shared
        ];

        return <div className="col-xs-12 col-lg-6">
            <Chart
                title={"User Adoption"}
                tooltip={<span>
                    <p>A graph that shows the total adoption of publishers (users who have the </p>
                    <p>ability to share) and the various funnels that give insight on where the</p>
                    <p>program is in terms of full adoption. This card does not include delegates,</p>
                    <p>admins, users who are excluded from reporting, and users who do not have</p>
                    <p>the ability to share.</p>
                    <br />
                    <p>Each variable is defined as:</p>
                    <p><em>Access:</em> All users who have been created within the date range</p>
                    <p>that has been given access to the system. Users included in this funnel are</p>
                    <p>not disabled, deleted, excluded from reporting.</p>
                    <p><em>Activated:</em> Based on the above 'Access' funnel, have activated their account</p>
                    <p>either by connecting a social network, or by logging in through their SSO portal</p>
                    <p><em>Signed in:</em> Based on the above 'Activated' funnel, users who have recorded some</p>
                    <p>form of activity in the app (web or browser) after their first hour of their first login</p>
                    <p><em>Shared:</em> Based on the 'Activated' funnel, users who have shared content in the designated</p>
                    <p>date range, excluding stream subscription shares. Note: Shared numbers can be higher</p>
                    <p>than signed in numbers as users can schedule content in advance and share content but</p>
                    <p>do not sign in during the date range</p>
                </span>}
                data={data}
                labels={labels}
                type="horizontal-bar"
                loading={typeof this.state.adoption === "string"}
                showSearchResults={this.props.showSearchResults}
                height={300}
                customChartTooltips = {{
                    xAlign: "right",
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var tooltipValue =
                                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            return tooltipValue.toLocaleString();
                        }
                    }
                }}
            />
        </div>
    }

    _renderMaturityDetails() {
        const labels = [
            ["less than 5", "Content", "pieces", "per month"],
            ["5 - 10", "Content", "pieces", "per month"],
            ["11 - 19", "Content", "pieces", "per month"],
            ["20+", "Content", "pieces", "per month"]
        ];
        const percents = typeof this.state.shareMaturity === "string" ? {} : this._evenPercent(this.state.shareMaturity);

        const data = typeof this.state.shareMaturity === "string" ? [] : [
            this.state.shareMaturity["0-4"]!,
            this.state.shareMaturity["5-10"]!,
            this.state.shareMaturity["11-19"]!,
            this.state.shareMaturity["20+"]!
        ];
        const tooltipData = [percents["0-4"], percents["5-10"], percents["11-19"], percents["20+"]];

        return <div className="col-xs-12 col-lg-6">
            <Chart
                title={"Content Frequency"}
                tooltip={<span>
                    <p>This card shows the number of users who have shared</p>
                    <p>in four ranges based on the frequency of sharing articles.</p>
                    <p>To find out the percentage based on the number of total</p>
                    <p>users shared, hover-over each bar. While these ranges</p>
                    <p>are defined by the number of articles shared per month,</p>
                    <p>they are automatically prorated for different date ranges.</p>
                    <p> E.g., if the date range is one week, then 20+ articles</p>
                    <p>over a 28-day month becomes 5+ articles over a 7-day week.</p>
                </span>}
                data={data}
                labels={labels}
                type="bar"
                loading={typeof this.state.shareMaturity === "string"}
                showSearchResults={this.props.showSearchResults}
                height={300}
                customChartTooltips={{
                    callbacks: {
                        title: function (tooltipItem) {
                            const title = tooltipItem[0].xLabel;
                            return title.constructor === Array ? title.join(' ') : title;
                        },
                        label: function (tooltipItem) {
                            return tooltipData[tooltipItem.index] + "%";
                        }
                    }
                }}
            />
        </div>
    }

    _renderUserDetails() {
        return <NumberChart
            title="Users"
            tooltip={
                <span>
                    <p>The ratio for each stage of adoption, as a percentage of users that have the</p>
                    <p>ability to share with access.</p>
                    <p>Note: this card does not include delegates, admins, users who have been</p>
                    <p>excluded from reporting, and users who do not have the ability to share.</p>
                </span>
            }
            data={typeof this.state.adoption === "string" ? {} : {
                Activated: {
                    label: ["Activated"],
                    value: (
                        <PercentCircle
                            percent={
                                this.state.adoption.access > 0
                                    ? getPercent(
                                        this.state.adoption.access,
                                        this.state.adoption.activated,
                                        0
                                    )
                                    : 0
                            }
                        />
                    )
                },
                "Signed In": {
                    label: ["Signed In"],
                    value: (
                        <PercentCircle
                            percent={
                                this.state.adoption.activated > 0
                                    ? getPercent(
                                        this.state.adoption.activated,
                                        this.state.adoption.active,
                                        0
                                    )
                                    : 0
                            }
                        />
                    )
                },
                Shared: {
                    label: ["Shared"],
                    value: (
                        <PercentCircle
                            percent={
                                this.state.adoption.active > 0
                                    ? getPercent(
                                        this.state.adoption.active,
                                        this.state.adoption.shared,
                                        0
                                    )
                                    : 0
                            }
                        />
                    )
                }
            }}
            loading={typeof this.state.adoption === "string"}
            showSearchResults={this.props.showSearchResults}
        />
    }

    _renderSessionDetails() {
        return <NumberChart
            title="User Session Frequency"
            tooltip={
                <span>
                    <p>This card is an indicator of platform adoption by users, measured by</p>
                    <p>average session frequency for logged in users and average session time per session.</p>
                    <p>This card does not include users who have been excluded from reporting or admins.</p>
                </span>
            }
            data={typeof this.state.adoption === "string" ? {} : {
                "Total Sessions": {
                    label: ["Total Sessions"],
                    value:
                        this.state.adoption && this.state.adoption.sessionCount
                            ? this.state.adoption.sessionCount
                            : 0
                },
                "Average minutes per session": {
                    label: ["Average minutes per session"],
                    value:
                        this.state.adoption && this.state.adoption.sessionCount && this.state.adoption.sessionTime
                            ? Math.round(
                                (this.state.adoption.sessionTime / (1000 * 60) / this.state.adoption.sessionCount) * 10
                            ) / 10
                            : 0
                },
                "Average sessions per user": {
                    label: ["Average sessions per user"],
                    value:
                        this.state.adoption && this.state.adoption.sessionCount && this.state.adoption.sessionUsers
                            ? Math.round(
                                (this.state.adoption.sessionCount / this.state.adoption.sessionUsers) * 10
                            ) / 10
                            : 0
                }
            }}
            loading={typeof this.state.adoption === "string"}
            showSearchResults={this.props.showSearchResults}
        />
    }

    _renderContentTypeAdoptionDetails() {
        const hasNoPermissions = this.props.client && !this.props.client.allowPdfCuration && !this.props.client.allowFrontendPdfCompose
            && !this.props.client.allowCustomEdit && !this.props.client.allowFrontendImageOnly && !this.props.client.allowVideoCuration
            && !this.props.client.allowFrontendVideoCompose;
        if (hasNoPermissions || !(this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin || this.props.user.isReports)) return;

        const contentTypesShared = typeof this.state.adoption === "string" ? {} : {
            Article: this.state.adoption.article || 0,
            Pdf: this.state.adoption.pdf || 0,
            Image: this.state.adoption.image || 0,
            Video: this.state.adoption.video || 0,
            Text: this.state.adoption.text || 0
        };
        let labels: string[] = [];
        let data: number[] = [];
        const totalPublishers = typeof this.state.adoption === "string" ? 1 : this.state.adoption.shared;
        Object.keys(contentTypesShared).forEach(type => {
            const value = contentTypesShared[type];
            switch (type) {
                case "Article": {
                    if (value > 0) {
                        labels.push(type);
                        data.push(contentTypesShared[type]!);
                    }
                    break;
                }
                case "Pdf": {
                    if (value > 0 && (!this.props.client || (this.props.client && (this.props.client.allowPdfCuration || this.props.client.allowFrontendPdfCompose)))) {
                        labels.push(type.toUpperCase())
                        data.push(contentTypesShared[type]!);
                    }
                    break;
                }
                case "Image": {
                    if (value > 0 && (!this.props.client || (this.props.client && (this.props.client.allowCustomEdit || this.props.client.allowFrontendImageOnly)))) {
                        labels.push(type)
                        data.push(contentTypesShared[type]!);
                    }
                    break;
                }
                case "Video": {
                    if (value > 0 && (!this.props.client || (this.props.client && (this.props.client.allowVideoCuration || this.props.client.allowFrontendVideoCompose)))) {
                        labels.push(type)
                        data.push(contentTypesShared[type]!);
                    }
                    break;
                }
                case "Text": {
                    if (value > 0 && (!this.props.client || (this.props.client && this.props.client.allowCustomEdit))) {
                        labels.push(type)
                        data.push(contentTypesShared[type]!);
                    }
                    break;
                }
                default: break;
            }
        });
        const tooltipData = [...data];
        return <div className="col-xs-12 col-lg-6">
            <Chart
                title={"Content Type Adoption"}
                downloadAction={e => this._fetchAdoptionSummary(this.props.filters, "csv")}
                tooltip={<span>
                    <p>This report shows the number of users that have adopted each content type.</p>
                    <p>Adoption is defined as a user sharing the respective content type at least</p>
                    <p>once between the start and end date. This report does not include users </p>
                    <p>without sharing permissions or users excluded from reporting</p>
                    <br />
                    <p>Total Users:  Users that have shared at least once within the start and end date</p>
                    <p>Article: Publishers that have shared at least one article within the start and end date</p>
                    <p>PDF: Publishers that have shared at least one PDF within the start and end date</p>
                    <p>Image: Publishers that have shared at least one image within the start and end date</p>
                    <p>Video: Publishers that have shared at least one video within the start and end date</p>
                    <p>Text: Publishers that have shared at least one text only share within the start and end date</p>
                </span>}
                data={data.map(item => Math.ceil((item / totalPublishers) * 100))}
                labels={labels}
                type="bar"
                loading={typeof this.state.adoption === "string"}
                showSearchResults={this.props.showSearchResults}
                height={300}
                customChartTooltips={{
                    displayColors: false,
                    callbacks: {
                        label: function(tooltipItem) {
                            const percentage = Math.ceil((tooltipData[tooltipItem.index] / totalPublishers) * 100);
                            return [`Total Publishers: ${totalPublishers}`, `Adopted Publishers: ${tooltipData[tooltipItem.index]}`, `Percentage: ${percentage}%`]
                        }
                    }
                }}
            />
        </div>
    }

    _renderSharingChannelAdoption() {
        const labels: Array<string | string[]> = [];
        const data: number[] = [];
        const access: number[] = [];
        const adopted: number[] = [];

        if (typeof this.state.sharingChannels !== "string") {
            labels.push("Social");
            data.push(this.state.sharingChannels.socialAdoption);
            access.push(this.state.sharingChannels.socialPermissions);
            adopted.push(this.state.sharingChannels.socialShares);

            if (!this.props.client || this.props.client.hasExtraNetworks) {
                labels.push("Web");
                data.push(this.state.sharingChannels.webAdoption);
                access.push(this.state.sharingChannels.webPermissions);
                adopted.push(this.state.sharingChannels.webShares);
            }

            if (!this.props.client || this.props.client.allowEmail || this.props.client.allowAppEmail) {
                labels.push("Device Email");
                data.push(this.state.sharingChannels.emailAdoption);
                access.push(this.state.sharingChannels.emailPermissions);
                adopted.push(this.state.sharingChannels.emailShares);
            }

            if (!this.props.client || this.props.client.allowAppSMS) {
                labels.push("Device SMS");
                data.push(this.state.sharingChannels.deviceSMSAdoption);
                access.push(this.state.sharingChannels.deviceSMSPermissions);
                adopted.push(this.state.sharingChannels.deviceSMSShares);
            }

            if (!this.props.client || this.props.client.allowCopyLink) {
                labels.push("Short URL");
                data.push(this.state.sharingChannels.copyLinkAdoption);
                access.push(this.state.sharingChannels.copyLinkPermissions);
                adopted.push(this.state.sharingChannels.copyLinkShares);
            }

            if (!this.props.client || this.props.client.smsProvider) {
                labels.push(["LiveSocial", "SMS"]);
                data.push(this.state.sharingChannels.appSMSAdoption);
                access.push(this.state.sharingChannels.appSMSPermissions);
                adopted.push(this.state.sharingChannels.smsMessages);
            }
        }

        return <div className="col-xs-12 col-lg-6">
            <Chart
                title="Sharing Channel Adoption"
                downloadAction={e => this._fetchSharingChannels(this.props.filters, "csv")}
                tooltip={<span>
                    <p>This report shows the number of users that have adopted each sharing channel.</p>
                    <p>Adoption is defined as a user sharing by the respective channel at least once between the start and end date.</p>
                    <p>This report does not include users without sharing permissions or users excluded from reporting.</p>
                    <p>Allowed: Users that have access to the respective channel. This is usually based on their role's permissions. For Web and Livesocial Text, this is based on the user's permissions</p>
                    <p>Social: Publishers that have scheduled or shared content at least once to LinkedIn, X (Twitter), Facebook or Instagram within the start and end date</p>
                    <p>Web: Publishers that have shared at least once to an extra network within the start and end date</p>
                    <p>Device Email: Publishers that have shared at least once using Device Email within the start and end date</p>
                    <p>Device SMS: Publishers that have shared at least once using Device SMS within the start and end date</p>
                    <p>Short URL: Publishers that have copied a short URL to a piece of content at least once within the start and end date</p>
                    {/*<p>LiveSocial Email:Publishers that have scheduled or sent an email at least once using the LiveSocial Email product within the start and end date</p>*/}
                    <p>LiveSocial Text: Publishers that sent an SMS at least once using the LiveSocial Text product within the start and end date</p>
                </span>}
                data={data}
                labels={labels}
                type="bar"
                loading={typeof this.state.sharingChannels === "string"}
                showSearchResults={this.props.showSearchResults}
                height={300}
                max={100}
                stepSize={20}
                customChartTooltips={{
                    displayColors: false,
                    callbacks: {
                        title: function (tooltipItem) {
                            const title = tooltipItem[0].xLabel;
                            return title.constructor === Array ? title.join(' ') : title;
                        },
                        label: function (tooltipItem) {
                            return [
                                `Allowed: ${access[tooltipItem.index]}`,
                                `Adopted: ${adopted[tooltipItem.index]}`,
                                `Percentage: ${data[tooltipItem.index].toFixed(0)}%`
                            ];
                        }
                    }
                }}
            />
        </div>
    }

    render() {
        return (
            <Wrapper id="adoption">
                {(this.props.user.isSuper || this.props.user.isSuperClientAdmin) && this._renderAdoptionDetails()}
                {this._renderContentTypeAdoptionDetails()}
                {(this.props.user.isSuper || this.props.user.isSuperClientAdmin) && <div className="col-xs-12 col-lg-6">
                    {this._renderUserDetails()}
                    {this._renderSessionDetails()}
                </div>}
                {(this.props.user.isSuper || this.props.user.isSuperClientAdmin) && this._renderSharingChannelAdoption()}
                {(this.props.user.isSuper || this.props.user.isSuperClientAdmin) && this._renderMaturityDetails()}
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });
export default connect(mapStateToProps)(AdoptionSection);
