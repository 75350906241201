import React, { Component } from "react";
import { removeAlert } from "../../actions/utils";

interface IAlertProps {
    expiry: number;
    id: string;
    type: "success" | "warning" | "error";
    children?: string;
}

interface IAlertState {
    visible: boolean;
}

export default class Alert extends Component<IAlertProps, IAlertState> {
    constructor(props: IAlertProps) {
        super(props);
        this.state = { visible: true };
        this.hide = this.hide.bind(this);
    }
    componentDidMount() {
        setTimeout(() => {
            this.hide();
        }, this.props.expiry * 1000);
    }

    hide() {
        this.setState({ visible: false });
        setTimeout(() => {
            removeAlert(this.props.id);
        }, 200);
    }

    render() {
        if (!this.state.visible) return null;

        return (
            <div className={`alert ${this.props.type}`} role="alert" aria-live="assertive">
                <span
                    {...(this.props.children
                        ? {
                              dangerouslySetInnerHTML: {
                                  __html: this.props.children
                              }
                          }
                        : null
                    )}
                />
                <i
                    className="fa fa-close"
                    style={{
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        this.hide();
                    }}
                />
            </div>
        );
    }
}
