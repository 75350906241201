import React, { Component } from "react";
import { createAlert } from "../../../actions/utils";
import Button from "../../atoms/Button";
import LogoLogin from "../../atoms/LogoLogin";
import { doRecoverAccount } from "../../../actions/session";
import Recaptcha from "react-recaptcha-that-works";

interface IRecoverAccountProps {}
interface IRecoverAccountState {
    identity: string;
    recaptcha: string;
    sent: boolean;
}
export default class RecoverAccount extends Component<IRecoverAccountProps, IRecoverAccountState> {
    userInput: HTMLInputElement | null = null;
    constructor(props: IRecoverAccountProps) {
        super(props);

        this._onSubmit = this._onSubmit.bind(this);
        this.state = {
            identity: "",
            recaptcha: "",
            sent: false
        };
    }

    componentDidMount() {
        if (this.userInput) {
            this.userInput.focus();
        }
    }

    _onSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        const { identity, recaptcha } = this.state;
        if (!identity) {
            createAlert("Please enter in an email or username to recover your account.");
            return;
        }

        if (!recaptcha) {
            createAlert("Please check I’m not a robot box.");
            return;
        }

        doRecoverAccount(identity, recaptcha)
            .then(() => {
                this.setState({ sent: true });
            })
            .catch(error => {
                createAlert(error);
                createAlert(
                    "An error occurred while trying to send you the account recovery email.  Please try again."
                );
            });
    }

    renderRecaptcha() {
        return (
            <Recaptcha
                siteKey="6LfSp0MUAAAAAIDgomU-mA1-NNXsLwKO4YJsVrkf"
                onVerify={response => this.setState({ recaptcha: response })}
            />
        );
    }
    render() {
        return (
            <div className="page login">
                <div className="login__wrapper">
                    <h1 className="page__title">
                        <LogoLogin className="login__logo" /> Recover your account
                    </h1>
                    {!this.state.sent && (
                        <form onSubmit={this._onSubmit} className="login__form">
                            <div className="login__username">
                                Enter the email address or username you use to sign into the LiveSocial Console.
                            </div>
                            <div className="login__username">
                                <label>
                                    <span>Email or username&nbsp;</span>
                                    <input
                                        className="login__input"
                                        placeholder="Example: name@yourcompany.com"
                                        ref={input => {
                                            this.userInput = input;
                                        }}
                                        type="text"
                                        onChange={e => this.setState({ identity: e.target.value })}
                                    />
                                </label>
                            </div>
                            {this.renderRecaptcha()}
                            <div className="login__btns">
                                <Button type="submit" className="login__btn" value="Send Recover Email" />
                                <Button className="login__btn" to={`/login`}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    )}
                    {this.state.sent && (
                        <div className="login__form">
                            <p className="login__username">
                                An account recovery email has been sent to the email address we have on file for your account.
                            </p>
                            <p className="login__username">
                                If you do not receive the email, please wait 5 minutes and check your spam folder.
                                We do not send emails to email addresses
                                that are not registered in our system. We will also not send emails within 5 minutes of each other.
                                If you still haven't received your email, check the email/username you have entered and try again.
                            </p>
                            <div className="login__btns">
                                <Button className="login__btn" to={`/login`}>
                                    Back to Login
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
