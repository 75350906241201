import React, { useState, useEffect } from "react";
import { UtilActions } from "../../actions";
import { sanitizeHtml } from "../../utils/Helpers";

interface INotificationProps {
    cid?: string;
}

export const NotificationBar = (props: INotificationProps) => {
    const [consoleAlert, setConsoleAlert] = useState("");

    const fetchConsoleAlerts = async () => {
        const data = await UtilActions.doGetAlerts();

        if (!data) {
            return;
        }

        const cids = (data.cids || []).filter(cid => !!cid);
        if ((props.cid && cids.length && cids.indexOf(props.cid) !== -1) || !props.cid) {
            setConsoleAlert(data.message);
        }
    };

    useEffect(() => {
        if (consoleAlert) {
            setTimeout(() => {
                const height = $(".notification_bar").outerHeight();
                $("body .app > .page-container").attr("style", `margin-top: ${height}px`);
                $("body .app > .page-container .navbar-static-side").attr("style", `height: calc(100vh - ${height}px)`);
            }, 50);
        } else if ($("body").hasClass("notification--active")) {
            setTimeout(() => {
                $("body .app > .page-container").attr("style", "");
                $("body .app > .page-container .navbar-static-side").attr("style", ``);
            });
        }
    }, [consoleAlert]);

    useEffect(() => {
        fetchConsoleAlerts();
    }, []);

    if (!consoleAlert) {
        return <React.Fragment />;
    }
    const sanitize = sanitizeHtml(consoleAlert);
    return (
        <div
            className="notification_bar"
            dangerouslySetInnerHTML={{ __html: `<i class="fa fa-warning"></i> ${sanitize}` }}
        />
    );
};
export default NotificationBar;
