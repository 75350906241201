import React, { useState } from "react";
import { connect } from "react-redux";

import { DelegateActions, doHasAppSSOEnabled, createAlert } from "../../../actions/";
import ElementListBox, { PaginationData } from "../../molecules/ElementListBox";
import Tooltip from "../../atoms/Tooltip";
import { getBooleanIcon } from "../../../utils/Helpers";
import Spinner from "../../atoms/Spinner";
import { Wrapper, Row } from "../../atoms/Layout/";

interface IDelegatePendingProps {
    user: Mongo.clientAdmin;
}
const DelegatePending = (props: IDelegatePendingProps) => {
    const { cid } = props.user;
    const [loading, setLoading] = useState(false);

    const refresh = async (cid: string, search: string, paginationData: PaginationData) => {
        try{
            const data = await DelegateActions.doGetPending(
                cid,
                search,
                paginationData.limit,
                paginationData.skip,
                paginationData.sort
            );
            if (data && data.items && data.count) {
                return { total: data.count, items: data.items };
            }

        } catch (err) {
            //
        }
        return { items: [], total: 0 };
    };
    const approve = async (email: string, daCode: string, ssoId: string) => {
        try {
            const response = await DelegateActions.doApprove(email, daCode, ssoId);
            if (response && response.valid) {
                createAlert(response.message, "success");
            }
        } catch (error) {
            // @ts-expect-error
            createAlert(error.error, "error");
        }
    };

    const reject = async (email: string, daCode: string) => {
        try {
            const response = await DelegateActions.doReject(email, daCode);
            if (response && response.valid) {
                createAlert(response.message, "success");
            }
        } catch (error) {
            // @ts-expect-error
            createAlert(error.error, "error");
        }
    };
    if (loading) {
        return <Spinner />;
    }
    return (
        <Wrapper id = "pendingdelegates">
            <Row>
                <ElementListBox<ServerTypes.Console.IPendingDelegate>
                    title="Pending Delegates"
                    refresh={({ cid, search }, paginationData) => refresh(cid, search, paginationData)}
                    filterSearch={true}
                    filterCid={props.user.isSuper}
                    mutateItem={item => ({
                        firstName: item.firstName,
                        lastName: item.lastName,
                        directSharing:
                            item.advisorEmailMap &&
                            Object.values(item.advisorEmailMap).filter(v => v.allowSuperPublisher).length ? (
                                <div>
                                    Bypass Approval&nbsp;
                                    <Tooltip>
                                        This delegate has the ability to share directly to at least one of their
                                        publisher’s social networks.
                                    </Tooltip>
                                </div>
                            ) : (
                                <div></div>
                            ),
                        email: item.email,
                        requestedOn: `${new Date(item.requestedOn).getFullYear()}-${new Date(
                            item.requestedOn
                        ).getMonth() + 1}-${new Date(item.requestedOn).getDate()}`,
                        requestedBy: item.advisorEmailMap? Object.keys(item.advisorEmailMap)[0] +
                            ((Object.keys(item.advisorEmailMap).length > 1) ? "..." : "")
                            : ""
                    })}
                    keys={{
                        ...(props.user.isSuper ? { cid: "Client" } : null),
                        firstName: "First Name",
                        lastName: "Last Name",
                        directSharing: "",
                        email: "Email",
                        requestedOn: "Requested On",
                        requestedBy: "Requested By",
                        actions: "Actions"
                    }}
                    sortableKeys={["First Name", "Last Name", "Email", "Requested On", "Requested By"]}
                    defaultFilter={{ cid: cid || "" }}
                    buttonActions={[
                        {
                            onClick: async (item, filter) => {
                                setLoading(true);
                                const { cid } = item;
                                const hasSSO = await doHasAppSSOEnabled(cid);
                                let ssoId = "";
                                if (hasSSO) {
                                    setLoading(false);
                                    ssoId = prompt(`Please enter ${item.email}'s SSO ID`, "") || "";
                                    if (!ssoId) {
                                        createAlert("You need to provide an SSO ID", "error");
                                        return;
                                    }
                                }
                                await approve(item.email, item.daCode, ssoId);
                                setLoading(false);
                            },
                            text: "Approve",
                            icon: "fa-thumbs-up"
                        },
                        {
                            onClick: async (item, filter) => {
                                setLoading(true);
                                if (
                                    confirm(
                                        "Are you sure you want to reject?"
                                    )
                                ) {
                                    await reject(item.email, item.daCode);
                                    setLoading(false);
                                }
                            },
                            text: "Reject",
                            icon: "fa-thumbs-down"
                        }
                    ]}
                    detailContent={user => (
                        <table className="responsive" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td colSpan={3}>
                                        <b>
                                            <u>Requested by</u>
                                        </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <th style={{ textAlign: "center" }}>Bypass Approval</th>
                                    <th style={{ textAlign: "center" }}>Content</th>
                                    <th style={{ textAlign: "center" }}>Email</th>
                                    <th style={{ textAlign: "center" }}>Profile</th>
                                </tr>
                            </thead>
                            <tbody>
                                {user.advisorEmailMap &&
                                    Object.keys(user.advisorEmailMap).map(advisorEmail => {
                                        return (
                                            <tr key={advisorEmail} style={{ textAlign: "left" }}>
                                                <td>{advisorEmail}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].allowSuperPublisher
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].permissions!.content
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].permissions!.eCommunication
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].permissions!.profile
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    )}
                />
            </Row>
        </Wrapper>
    );
};
const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(DelegatePending);
