import React, { Component } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import CSVUpload from "../../molecules/CSVUpload";
import Modal from "../../molecules/modals/Modal";

interface UploadArticlesState {
    timeZoneList: { label: string }[];
    showTzModal: boolean;
}

class UploadArticles extends Component<{}, UploadArticlesState> {
    static allowSuper = false;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);
        this.state = {
            timeZoneList: [],
            showTzModal: false
        };
    }

    componentDidMount() {
        this._getTimeZoneList();
    }

    async _getTimeZoneList() {
        let tzOptions = await moment.tz
            .names()
            .map((name) => {
                const now = Date.now();
                const zone = moment.tz.zone(name);
                return { name, offset: zone !== null ? zone.utcOffset(now) : 0 };
            })
            .sort((a, b) =>
                a.offset === b.offset
                    ? a.name.localeCompare(b.name)
                    : b.offset - a.offset
            )
            .map((zone) => {
                const gmtOffset = `GMT${moment.tz(zone.name).format('Z')}`;
                const abbr = moment.tz(zone.name).format('z');
                return {
                    label: `(${gmtOffset}) ${zone.name} - ${abbr}`,
                };
            }).slice(39, 170);
        this.setState({ timeZoneList: tzOptions })
    }

    _close() {
        this.setState({ showTzModal: false })
    }

    render() {
        const { timeZoneList } = this.state;
        return <div>
            <CSVUpload
                itemName="Article"
                routeName="article"
                inc={20}
                note={<ul>
                    <li>If Allow Content Specific Sharing Permissions is disabled at the client level, Sharing Permissions will be ignored when importing articles</li>
                    <li>If a sharing permission (e.g. Email) is disabled at the client level, it will be ignored in the Sharing Permission column when importing articles</li>
                    <li>To create a read only article, the Sharing Permissions column should be "Read Only"</li>
                    <li>If the Sharing Permissions column is left blank, the article will be set to the default sharing permissions of the stream it is in.</li>
                    <li>The Force Schedule Date is an optional field. If this field is entered please make sure to include the time and timezone.
                        Please format the date as follows <span style={{ fontWeight: "bold" }}>yyyy-mm-dd hh:mm PM EST</span>.
                        Please note the spaces between date, time, AM/PM, and timezone abbreviation.
                    </li>
                    <li>If the file is not utf-8 encoded, special characters may not display as intended.</li>
                    <li>
                        <a
                            onClick={() => {
                                this.setState({ showTzModal: true })
                            }}
                            style={{ textDecoration: "underline" }}
                        >
                            Click here
                        </a>{" "} for a list of some time zone names and their abbreviations.
                    </li>
                </ul>}
            />
            <Modal
                open={this.state.showTzModal}
                title={"Time Zones"}
                closeAction={() => this._close()}
            >
                {
                    timeZoneList && timeZoneList.map((val, i) => {
                        return (
                            <li style={{ listStyleType: "none" }} key={val.label} value={val.label}>
                                {val.label}
                            </li>
                        )
                    })
                }
            </Modal>
        </div>
    }
}


const mapStateToProps = state => ({});

export default connect(mapStateToProps)(UploadArticles);
