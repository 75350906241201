import React, {Component} from 'react';
import qs from "query-string";
import LogoLogin from '../../atoms/LogoLogin';
import { setAuthToken } from '../../../utils/Helpers';
import { doCheckAuth, doSSOSignIn } from '../../../actions/session';
import Button from "../../atoms/Button";
import Spinner from '../../atoms/Spinner';

class SSOSignIn extends Component {

    constructor(props) {
        super(props);
        this._onSubmit = this._onSubmit.bind(this);
        this.state = {};
    }

    componentDidMount() {
        const { token } = this.props.match.params;
        const queryString = (this.props.location.search || "").substring(1);
        const { err } = qs.parse(queryString);

        if (token) {
            setAuthToken(token);
            doCheckAuth();
            this.setState({ checking: true });
        } else if (err) {
            let errMsg = "";
            // We have a few different error messages that can be returned,
            // but some have information that we don't want to display to the user, so we'll slightly modify them.
            if (err === "sunf") {
                errMsg = "User not found.";
            }
            else if (err === "iau") {
                errMsg = "Inactive User.";
            }
            else if (err.includes("Invalid SAML configuration")) {
                errMsg = "Invalid SAML configuration. Not configured for SAML authentication.";
            }
            else if (err.includes("SSO Mismatched login")) {
                errMsg = "SSO mismatched login. Client user is activated with user on different branding configuration.";
            }
            else errMsg = err;

            this.setState({ err: `We apologize for the inconvenience, but we encountered an error with your Single Sign-On (SSO) credentials. Please confirm your SSO details, or contact your support team for assistance. ${errMsg}` });
        }
    }

    _onSubmit(event) {
        if (event) { event.preventDefault(); }
        localStorage.removeItem("logoutMessage");
        doSSOSignIn();
    }

    render() {
        const { err, checking } = this.state;
        if (err) {
            return (<div className="page login">
                <div className="login__wrapper">
                    <h1 className="page__title"><LogoLogin className="login__logo"/></h1>
                    <form onSubmit={this._onSubmit} className="login__form">
                        <p className="login__username">{err}</p>
                        <div className="login__btns"><Button type="submit" label="Retry Login" value="Retry Login" className="login__btn"/></div>
                        <br/>
                    </form>
                </div>
            </div>);
        } else if (checking) {
            return <div className="page logout"><Spinner/></div>
        }
        return (<div className="page login">
            <div className="login__wrapper">
                <h1 className="page__title"><LogoLogin className="login__logo"/></h1>
                <form onSubmit={this._onSubmit} className="login__form">
                    <p className="login__username">{localStorage.getItem("logoutMessage") ? localStorage.getItem("logoutMessage") : "You have been logged out and your session has ended."}</p>
                    <div className="login__btns"><Button type="submit" label="Login Again" value="Login Again" className="login__btn"/></div>
                    <br/>
                </form>
            </div>
        </div>);
    }
}

export default SSOSignIn;
