import React, { Component } from "react";
import { connect } from "react-redux";

import { FeedSourceRequestsActions, UtilActions, IFeedSourceRequestsListParams, createAlert } from "../../../actions";

import Button from "../../atoms/Button";
import ElementListBox, { PaginationData } from "../../molecules/ElementListBox";
import { Wrapper, Row } from "../../atoms/Layout/";
interface IFeedSourceRequestsListProps {
    user: Mongo.clientAdmin;
    clients: dynamic<Mongo.client>;
}
interface IFeedSourceRequestListState {
    countries: Array<{ code: string; name: string }>;
    adminHasPerm: boolean;
}

interface ListFilters {
    _id: string;
    url: string;
    type: string;
    location: string;
    weight: number;
    category: string;
    language: string;
    region: number;
}

class List extends Component<IFeedSourceRequestsListProps, IFeedSourceRequestListState & { forceRefresh: boolean }> {
    static allowSuper = true;
    static allowApiAdmin = true;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props: IFeedSourceRequestsListProps) {
        super(props);

        this.state = {
            countries: [],
            adminHasPerm: this.props.user?.isSuper || this.props.user?.isApiAdmin,
            forceRefresh: false
        };
    }

    async componentDidMount() {
        this.setState({ countries: (await UtilActions.doGetCountries()) || [], forceRefresh: false });
    }

    async deleteFeed(_id: string | ObjectID) {
        if (confirm("Are you sure you want to delete this feed source?")) {
            try {
                const data = await FeedSourceRequestsActions.doRemove(_id);
                if (data && data.valid) {
                    createAlert("Feed has been successfully deleted", "success");
                }
                setTimeout(() => {
                    this.setState({ forceRefresh: true })
                }, 2000);
                return true;
            } catch (err) {
                return false;
            }
        } else {
            return false;
        }
    }

    refresh(filters: ListFilters, pageData: PaginationData) {
        return FeedSourceRequestsActions.doList({ ...filters, start: pageData.skip, rows: pageData.limit });
    }

    translateRegion(region: number) {
        switch (region) {
            case 1:
                return "Australia & NZ";
            case 2:
                return "Far East";
            case 3:
                return "India";
            case 4:
                return "Middle East & Africa";
            case 5:
                return "Europe";
            case 6:
                return "South America";
            case 7:
                return "East - North America";
            case 8:
                return "Central - North America";
            case 9:
                return "West - North America";
            case -1:
                return "Other";
            default:
                return "";

        }
    }
    _downloadExport(query: IFeedSourceRequestsListParams) {
        FeedSourceRequestsActions.doExportCSV(query);
    }

    render() {
        return (
            <Wrapper>
                <Row>
                    <ElementListBox<Mongo.feedSourceRequests>
                        title="Feeds-Requests"
                        filterSearch={true}
                        keys={{
                            url: 'URL',
                            type: 'Type',
                            clientName: 'Client Name',
                            location: 'Location',
                            weight: 'Weight',
                            category: 'Category',
                            language: 'Language',
                            region: 'Region',
                            actions: "Actions"
                        }}
                        forceRefresh={this.state.forceRefresh}
                        listCount={50}
                        confirmDelete={true}
                        defaultSort={{ lastUpdatedOn: -1 }}
                        defaultFilter={{
                            search: ""
                        }}
                        refresh={(
                            { _id, url, type, location, weight, category, language, region },
                            paginationData: PaginationData
                        ) =>
                            this.refresh(
                                {
                                    _id: _id || "",
                                    url: url || "",
                                    type: type || "",
                                    location: location || "",
                                    weight: typeof weight === 'number' ? weight : parseFloat(weight) || 0,
                                    category: category || "",
                                    language: language || "",
                                    region: Number(region) || 0
                                },
                                paginationData
                            )
                        }
                        mutateItem={feedSourceRequest => ({
                            url: `${feedSourceRequest.url || ""}`,
                            type: `${feedSourceRequest.type || ""}`,
                            location: `${feedSourceRequest.location || ""}`,
                            weight: `${feedSourceRequest.weight || 0}`,
                            category: `${feedSourceRequest.category || ""}`,
                            region: `${this.translateRegion(feedSourceRequest.region)}`,
                            language: `${feedSourceRequest.language || ""}`,
                            clientName: `${this.props.clients[feedSourceRequest.cid]?.name || "Loading..."}`
                        })}
                        details={{
                            id: "ID",
                            type: "Type",

                            active: "Active",
                            tcAllowed: "T&C Allowed",

                            url: "URL",
                            feedDomain: "Feed Domain",

                            category: "Category",
                            subCategory: "Sub-Category",

                            weight: "Weight",
                            language: "Language",

                            region: "Region",
                            location: "Location",

                            lastUpdated: "Last Updated On",
                            lastUpdatedStatus: "Last Updated Status"
                        }}

                        filters={{
                            url: {
                                title: "URL"
                            },
                            location: {
                                title: "Location",
                                options: [
                                    { key: "NONE", value: "" },
                                    ...(this.state.countries || []).map(country => ({
                                        key: country.name,
                                        value: country.name
                                    }))
                                ]
                            },
                            category: {
                                title: "Category",
                                options: [
                                    { key: "NONE", value: "" },
                                    { key: "Arts Entertainment", value: "Arts Entertainment" },
                                    { key: "Business", value: "Business" },
                                    { key: "Computer Internet", value: "Computer Internet" },
                                    { key: "Health", value: "Health" },
                                    { key: "Law", value: "Law" },
                                    { key: "Lifestyle", value: "Lifestyle" },
                                    { key: "Marketing", value: "Marketing" },
                                    { key: "News Media", value: "News Media" },
                                    { key: "Politics", value: "Politics" },
                                    { key: "Recreation Sports", value: "Recreation Sports" },
                                    { key: "Science Technology", value: "Science Technology" },
                                    { key: "Society Culture", value: "Society Culture" }
                                ]
                            },
                            region: {
                                title: "Region",
                                options: [
                                    { key: "NONE", value: "NONE" },
                                    { key: "Australia & NZ", value: "1" },
                                    { key: "Far East", value: "2" },
                                    { key: "India", value: "3" },
                                    { key: "Middle East & Africa", value: "4" },
                                    { key: "Europe", value: "5" },
                                    { key: "South America", value: "6" },
                                    { key: "East - North America", value: "7" },
                                    { key: "Central - North America", value: "8" },
                                    { key: "West - North America", value: "9" },
                                    { key: "Other", value: "0" }
                                ]
                            },
                            language: {
                                title: "Language",
                                options: [
                                    { key: "NONE", value: "" },
                                    { value: "English", key: "English" },
                                    { value: "French", key: "French" },
                                    { value: "Spanish", key: "Spanish" },
                                    { value: "German", key: "German" },
                                    { value: "Portuguese", key: "Portuguese" },
                                    { value: "Italian", key: "Italian" }
                                ]
                            },
                            type: {
                                title: "Type",
                                options: [
                                    { key: "NONE", value: "" },
                                    { key: "RSS", value: "RSS" },
                                    { key: "Sitemap", value: "SM" }
                                ]
                            },
                        }}
                        createLink={`/feeds-requests/c`}
                        buttonElements={[
                            (item) => (<Button
                                key={`link-edit--${item._id}`}
                                className="btn--sm"
                                to={`/feeds-requests/e/${item._id}`}
                                onClick={() => {
                                    createAlert(`Navigating to the edit page for  ${item.url}`,'success');
                                }}
                            >
                                <i className="fa fa-edit" /> {this.state.adminHasPerm ? "Edit/Review" : "Edit"}
                            </Button>),
                            (item) => (<Button
                                key={`link-delete--${item._id}`}
                                className="btn--sm"
                                onClick={() => this.deleteFeed(item._id)}
                            >
                                <i className="fa fa-trash" /> Delete
                            </Button>)
                        ]}
                    />
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients });

export default connect(mapStateToProps)(List);
