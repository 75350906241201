import { CoreAPI } from "../../../../types/coreapi";
import { deepMerge } from "./Helpers";

function copyKeys(keys: string[], obj: dynamic) {
    const returnedObject = {};
    keys.forEach(key => {
        if (key in obj) {
            returnedObject[key] = obj[key];
        }
    });
    return returnedObject;
}

export function Client(client: Partial<Mongo.IClient> = {}) {
    const defaults: Mongo.IClient = {
        cid: "",
        name: "",
        code: "",

        isActive: true,
        allowProspect: false,
        allowEngage: false,
        allowPublish: true,
        allowEmail: true,
        allowAppEmail: false,
        allowAppSMS: false,
        allowAppSearch: true,
        allowCopyLink: false,
        allowCustomEdit: false,
        allowAutopost: false,
        allowCustomEmailConfig: false,
        allowCustomContactConfig: false,
        allowMoreLikeThis: true,
        allowSuggest: false,
        allowDelegation: false,
        allowContentDelegation: false,
        allowProfileDelegation: false,
        allowNetworkRestrictions: false,
        restrictToSuperPublisher: false,
        autoDelegationApproval: false,
        allowSuperPublisher: false,
        delegationUserRestriction: false,
        smsProvider: undefined,
        allowOffers: false,
        experimentalMode: false,
        allowProfSearches: true,
        allowPersSearches: true,
        allowRss: false,
        defaultCompany: "",
        defaultUserIndustry: "",
        hasFilters: false,
        isTCChecked: false,
        strictExtraNetworks: false,
        hasExtraNetworks: false,
        complianceEnabled: false,
        hasEntityConfig: false,
        scanFullArticleText: false,
        profileApprovalRequired: false,
        excludeFromWeeklyEmails: false,
        excludeComposedFromTrendingContent: false,
        sendEngagementDataToEventBus: false,
        enableTwitterSocialMetrics: false,
        enableLinkedInMentions: false,
        deactivateComplianceAccount: false,
        childConfigsOnly: false,
        forceCompliance: false,
        oneApproval: false,
        allowComplianceNotes: false,
        allowFeedback: false,
        allowLeaderBoardGroupFilter: false,
        replaceCorporateArticlesWithUserWebsite: false,
        corporatePaths: null,
        actReminderEmail: null,
        tags: {},
        hashtags: {},
        terms: {},
        filters: {},
        streams: {},

        trackingCode: null,
        trackingCodeDomains: null,

        regDomains: [],
        blockDomains: [],
        delegateDomains: [],
        extractTextFilter: [],
        defaultGroup: null,
        groups: {},
        roles: {},
        blocklistedUrls: [],

        allowFrontendCompose: true,
        allowFrontendVideoCompose: false,
        allowFrontendImageOnly: true,
        allowFrontendPdfCompose: false,
        imageUploadConsent: false,
        allowYearInReview: false,
        allowSectionView: true,
        allowVideoCuration: false,
        allowPdfCuration: false,
        allowContentAnalysis: false,
        allowSocialStreams: false,
        allowLeaderboards: false,
        allowExtendedOnboarding: false,
        allowCommentsEditable: true,
        aiGeneratedUserComments: false,
        aiGeneratedUserCommentsWithChatGPT: false,
        allowNotifications: true,
        allowNetworkSuggestedComments: false,

        brandName: "",
        configName: "none",
        fromEmail: null,
        contactEmail: null,
        feedbackTo: "",
        lang: "en",
        origCard: "original",
        crmEnabled: false,
        trackingRedirect: null,

        articleExpiryDefault: 365,

        allowOnboardingIntro: false,
        onboardingIntro: {
            en: {
                body: `Let [title] take your digital sales and marketing to the next level.<br/>
<br/>
In only minutes a day, <br/>
Share personalized compliant content <br/>
Build your brand and presence online<br/>
Generate leads and drive website traffic<br/>
<br/>
Still not convinced? Watch this video to see [title] in action.`,
                videoName: "GettingStartedWithLiveSocial.mp4",
                videoUrl: "https://grapevinesix.s3.amazonaws.com/onboardingIntroVideos/WelcometoLiveSocial.mp4"
            },
            fr: {
                body: `Faites passer vos ventes et votre marketing digital au niveau supérieur avec [title]<br/>
<br/>
En quelques minutes par jour,<br/>
Partagez du contenu conforme personnalisé<br/>
Construisez votre marque et votre présence en ligne<br/>
Générez des prospects et générez du trafic sur votre site Web<br/>
<br/>
Toujours pas convaincu ? Regardez cette vidéo pour voir [title] en action.`,
                videoName: "GettingStartedWithLiveSocial.mp4",
                videoUrl: "https://grapevinesix.s3.amazonaws.com/onboardingIntroVideos/WelcometoLiveSocial.mp4"
            }
        },

        socialNetworks: {
            Facebook: {
                available: false
            },

            Twitter: {
                available: false
            },
            LinkedIn: {
                available: false
            },
            IG: {
                available: false
            }
        },
        maxShareLimitLinkedIn: 10,
        maxShareLimitFacebook: 10,
        maxShareLimitTwitter: 10,
        twilio: undefined,
        infobip: undefined,
        telnyx: undefined,
        configSettings: {
            customEmail: {
                logo: "",
                sideLogo: "",
                defaultImage: "newspaper.jpg",
                footerTxt: "",
                companyAddress: "",
                TWHandle: "",
                FBHandle: "",
                LIHandle: "",
                GPHandle: "",
                successMgr: "",
                primaryColor: "",
                secondaryColor: "",
                tertiaryColor: ""
            },
            contactInfo: {
                contactNumber: "",
                email: "",
                name: "",
                // used if whitelable client wants help to be a link to a form instead of an email
                link: "",
                // For customized help section in our app that can be configured based on white label
                customHelp: {
                    // the below is the format to use when using this in whitelabel files
                    // links should look like "<a href='https://www.google.ca' target='_blank'>test</a>"
                    en: "",
                    fr: ""
                }
            }
        },
        lastUpdatedOn: new Date(),
        socialLastUpdatedOn: new Date(),
        customEmails: {
            activationEmail: {
                en: {
                    subject: "Activate your account on [title], the number one tool in social selling.",
                    body: `Hi [name],<br/><br/>

Are you ready to amplify your social presence, build relationships with buyers, and generate qualified leads? Your administrator has just given you access to [title], your one-stop-shop for social selling. <br/><br/>

Click the button below and sign in with your social accounts to get started in <b>minutes.</b> <br/><br/>

<a href="[website]?accessCode=[code]&email=[email]"> Activate your account</a> <br/><br/>

Want to learn more? Watch this <a href= "http://bit.ly/GettingStartedWithLiveSocial">quick video</a> to see how you can use [title] to take your social media presence to the next level. <br/><br/>

If you have any questions, please reach out to [contact]</a><br/><br/>

Happy sharing!<br/><br/><br/><br/>



Want to access [title] from anywhere, anytime? Download the mobile app for <a href="https://itunes.apple.com/ca/app/typecast-enterprise/id997795425">iOS</a> or <a href="https://play.google.com/store/apps/details?id=co.typcast.enterprise">Android</a> <br/><br/>

Click <a href="[website]emailunsubscribe?uuid=[uuid]&type=reminder"> here</a> to unsubscribe to these emails.`
                },
                fr: {
                    subject: "Activez votre compte sur [title], l'outil numéro un du social selling.",
                    body: `Bonjour [name],<br/><br/>

Êtes-vous prêt à amplifier votre présence sociale, à établir des relations avec les acheteurs et à générer des prospects qualifiés ? Votre administrateur vient de vous donner accès à [title], votre application de vente sur les réseaux sociaux. <br/><br/>

Cliquez sur le bouton ci-dessous et connectez-vous avec vos comptes sociaux pour commencer <b>en quelques minutes.</b> <br/><br/>

<a href="[website]?accessCode=[code]&email=[email]"> Activez votre compte</a> <br/><br/>

Envie d'en savoir plus ? Regardez cette <a href= "http://bit.ly/GettingStartedWithLiveSocial">courte video</a> pour voir comment vous pouvez utiliser [title] et passer votre présence sur les réseaux sociaux à la vitesse supérieur.<br/><br/>

Si vous avez des questions, veuillez contacter [contact]</a><br/><br/>

Bon partage !<br/><br/><br/><br/>



Vous voulez accéder à [title] de n'importe où, n'importe quand ? Téléchargez l'application mobile pour <a href=" ">iOS</a> ou <a href=" ">Android</a> <br/><br/>

Cliquez <a href="[website]emailunsubscribe?uuid=[uuid]&type=reminder"> ici</a> pour vous désabonner de ces e-mails.`
                }
            },
            ReengagementEmail: {
                en: {
                    subject: "We've missed you on [title] – Start sharing again today.",
                    body: `Hi [name],<br/><br/>

                    You previously activated your [title] account, and it’s been more than 30 days since you’ve shared content.<br/><br/>

                    We’re here to help you get the most out of your account so you can build your brand and add credibility with your social media contacts. <br/><br/>

                    Looking for an easy way to get started? Share <a href="[website]">top trending content</a>. It only takes a few minutes each week to share valuable content and maximize your results. <br/><br/>

                    If you have any questions, please let us know at <a href="mailto:[contact]">[contact]</a>.<br/><br/>`
                },
                fr: {
                    subject: "Vous nous avez manqué sur [title] - Recommencez à partager dès aujourd'hui.",
                    body: `Bonjour [name],<br/><br/>

                    Vous avez précédemment activé votre compte [title], et cela fait plus de 30 jours que vous n'avez pas partagé de contenu.<br/><br/>

                    Nous sommes là pour vous aider à tirer le meilleur parti de votre compte afin que vous puissiez développer votre marque et ajouter de la crédibilité auprès de vos contacts sur les médias sociaux. <br/><br/>

                    Vous cherchez un moyen simple de commencer ? Partagez <a href="[website]">le contenu le plus tendance</a>. Il suffit de quelques minutes chaque semaine pour partager du contenu de valeur et maximiser vos résultats.<br/><br/>

                    Si vous avez des questions, faites-le nous savoir à <a href="mailto:[contact]">[contact]</a>.<br/><br/>`
                }
            },
            notifyNewDelegate: {
                en: {
                    subject: "You have been requested as a delegate on [title], please activate your account",
                    body: `[delegate.firstName] [delegate.lastName],<br /><br />
                        [advisor.firstName] [advisor.lastName] has submitted a request for you to become their delegate on [title] for [permission]. To accept or reject this delegation request, follow the instructions below:<br /><br />
                        Set up your own account on [title]<br />
                        To get started, download the apps here and login with your social accounts. <br/><br/>

                        iOS:<br/>
                        https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>

                        Android:<br/>
                        https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                        Or to use the app in a browser, click <a href=\"[website]?[linkParams]\">here</a> to activate your account or copy and paste this link in your browser -<a href=\"[website]?[linkParams]\">[website]?[linkParams]</a><br/><br/>

                        Interested in learning more? Watch this 1-minute video to help get started: <a href=\"https://www.youtube.com/watch?v=L4lWyNyJF6E\">https://www.youtube.com/watch?v=L4lWyNyJF6E</a><br/><br/><br/><br/>

                        `
                },
                fr: {
                    subject: "[title] - Demande de délégation de [advisor.firstName] [advisor.lastName]",
                    body: `[delegate.firstName] [delegate.lastName],<br /><br />
                        [advisor.firstName] [advisor.lastName] a soumis une demande pour que vous deveniez son délégué sur [title] pour [permission]. Pour accepter ou rejeter cette demande de délégation, suivez les instructions ci-dessous :<br/><br/>

                        <strong style="color:[PrimaryColor]">Configurez votre propre compte sur [title]</strong><br/>
                        <ul>
                        <li><strong>Accéder à la déléguation via l'outil [title] :</strong></li>
                        <li><strong>Téléchargez l'application ou connectez-vous via votre orinateur de bureau :</strong><br/><br/>

                        iOS :<br/>
                        https://itunes.apple.com/ca/app/typecast-enterprise/id997795425 <br/><br/>

                        Android :<br/>
                        https://play.google.com/store/apps/details?id=co.typcast.enterprise <br/><br/>

                        Ou pour utiliser l'application dans un navigateur, cliquez <a href="[website]?[linkParams]">ici</a> pour activer votre compte ou copier et coller ce lien dans votre navigateur -
                        <a href="[website]?[linkParams]">[website]?[linkParams]</a><br/><br/></li>

                        <li><strong>Connectez-vous à l'outil :</strong> Vous aurez besoin d'une adresse email valide et de votre propre compte sur LinkedIn pour vous connecter à l'outil. Vous vous connecterez toujours à [title] avec vos identifiants LinkedIn actuels.</li>
                        <li><strong>Acceptez ou refusez votre demande de délégation :</strong> Après avoir créé votre propre compte et vous être connecté, vous serez invité à accepter ou à refuser la demande de délégation reçue.</li>
                        </ul>
                    `
                }
            },
            profileApproved: {
                en: {
                    subject: "Congratulations! You’re ready to start posting on [title]",
                    body: `Great news [firstName]!<br/><br/>

                    Your social profile has been approved on [networks] and you are all set to take your social selling efforts to the next level with [title].<br/><br/>

                    Getting started is easy! You can access the tool on your desktop <a href=\"[Website]\">here</a>.<br/><br/>

                    If you have any questions, please contact <a href=\"mailto:[contact]\">[contact]</a>.<br/><br/>
                    `
                },
                fr: {
                    subject: "Félicitation ! Vous êtes prêt à publier sur [title]",
                    body: `Super nouvelles [firstName] !<br/><br/>

                    Votre profil a été approuvé sur [networks]. Vous êtes fin prêt à passer votre stratégie digitale à la vitesse supérieure grâce à [title].<br/><br/>

                    Pour commencer, rien de plus simple ! Vous pouvez accéder à l'application via votre post de travail <a href=\"[Website]\">ici</a>.<br/><br/>

                    Si vous avez des questions, n'hesitez pas à contacter <a href=\"mailto:[contact]\">[contact]</a>.<br/><br/>
                    `
                }
            },
            "notSharedInThirtyDays" : {
                "en" : {
                    "subject" : "We’ve missed you on [title] – Start sharing again today.",
                    "body" : `Hi [name],<br/><br/>

                    You previously activated your [title] account, and it’s been more than 30 days since you’ve shared content.<br/><br/>

                    We’re here to help you get the most out of your account so you can build your brand and add credibility with your social media contacts. <br/><br/>

                    Looking for an easy way to get started? Share <a href=\"[website]\">top trending content</a>. It only takes a few minutes each week to share valuable content and maximize your results. <br/><br/>

                    If you have any questions, please let us know at <a href=\"mailto:[contact]\">[contact]</a>.<br/><br/>
                    `
                },
                "fr" : {
                    "subject" : "Vous nous avez manqué sur [title] - Recommencez à partager dès aujourd'hui.",
                    "body" : `Bonjour [name],<br/><br/>

                    Vous avez précédemment activé votre compte [title], et cela fait plus de 30 jours que vous n'avez pas partagé de contenu.<br/><br/>

                    Nous sommes là pour vous aider à tirer le meilleur parti de votre compte afin que vous puissiez développer votre marque et ajouter de la crédibilité auprès de vos contacts sur les médias sociaux. <br/><br/>

                    Vous cherchez un moyen simple de commencer ? Partagez <a href=\"[website]\">le contenu le plus tendance</a>. Il suffit de quelques minutes chaque semaine pour partager du contenu de valeur et maximiser vos résultats.<br/><br/>

                    Si vous avez des questions, faites-le nous savoir à <a href=\"mailto:[contact]\">[contact]</a>.<br/><br/>
                    `
                }
            }
        },
        bio_email: null,
        frontendSessionTimeout: 525600,
        frontendSessionNonPersistent: false,
        consoleSessionTimeout: 20,
        autoActivationReminder: false,

        aiGeneratedAdminSuggestions: false,
        aiGeneratedAdminSuggestionsWithChatGPT: false,

        allowECommunication: false,
        eCommunication: {
            expiryDefault: 7,
            requiredUserSetup: false,
            syncSocialAccountToProfile: false,
            syncSocialAccountEntityConfig: false,
            advisorEditing: false,
            advisorEditingFields: [],
            showProfileSync: false,
            showContactSync: false,
            showDuplicateEmail: false,
            engineUser: "",
            enginePassword: "",
            engine: "",
            platformManageContacts: false,
            platformCreateContacts: false,
            platformUpdateContacts: false,
            platformImportContacts: false,
            platformManageMailingList: false,
            platformCreateMailingList: false,
            platformUpdateMailingList: false,
            platformImportMailingList: false,
            prependCommaDesignation: false,
            sfpAccessToContacts: false,
            beeEditorEnabled: false,
            beeAuthEndpoint: "",
            beeClientId: "",
            beeClientSecret: "",
            beeUserId: "",
            beeDefaultTemplateUrl: "",
            beeDefaultTemplateJson: "",
            beeCustomRowsDropdownTitle: "",
            beeCustomRowsDomain: "",
            beeCustomRowsUrlOverride: "",
            beeCustomRows: [],
            beeEditorFonts: "",
            messageCompliance: false,
            messageComplianceEnforced: false,
            messageComplianceWorkflow: false,
            enableOneTimeBookmarking: false,
            enableOneTimeEditableType: false,
            templateLinkedin: "",
            templateTwitter: "",
            templateFacebook: "",
            templateInstagram: "",
            enableTeamSignature: false,
            templateTeamMember: "",
            profileDataTransformMap: "",
            apiUrl: "",
            enabledArchive: false,
            archiveSettings: {
                inboxEmail: "",
                customHeaders: []
            },
            syncCRMContacts: false,
        },
        allowSeismicStreams: false,
        seismic: {
            tenant: "",
            tenantId: "",
            authEnv: "prod"
        },
        allowContentSpecificPermissions: false,
        composePermissions: {
            social: true,
            email: true,
            sms: true
        },
        smsArchiveEmail: "",
        pumEnabled: false,
        smsPPConfig: "",
        smsPPEntityConfig: "",
        smsOptDefault: "OUT",
        appSSO: undefined,
        adminSSO: undefined,
        isEmbeddedClient: false,
        embeddedClientURL: "",
        embeddedClientAppId: "fb126da6-4b97-49c8-a579-ad23a89522a7",
        embeddedClientExtensionUniqueId: "",
        allowSFContactAccess: false,
        allowSocialInteractionCapture: false,
        enableSocialInteractionLeads: false,
        systemEmails: {
            recommendNotify: 1,
            achievementsNotify: 1,
            welcomeNotify: 1,
            readNotify: 1,
            queueNotify: 1,
            readPushNotify: 1,
            queuePushNotify: 1,
            firstCardPushNotify: 1,
            errorPostingNotify: 1,
            reportNotify: 1,
            tokenErrorNotify: 1
        },
        linkedInCPClick: 5.3,
        linkedInCPLike: 5.4,
        linkedInCPComment: 5.7,
        facebookCPClick: 0.97,
        facebookCPLike: 1.03,
        facebookCPComment: 1.07,
        twitterCPClick: 0.38,
        twitterCPLike: 0.4,
        twitterCPComment: 0.42,
        instagramCPLike: 1.1,
        instagramCPComment: 1.16,
        composeBlockedTerms: false,
        allowedComposeTerms: [],
        appSettings: {},
        configUpdateLogs: []
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), client), ...(client.systemEmails ? { systemEmails: { ...defaults.systemEmails, ...client.systemEmails }} : null) };
}

export function Admin(admin: Partial<Mongo.ClientAdmin> = {}) {
    const defaults: Mongo.ClientAdmin = {
        aid: "",
        cid: "",
        name: "",
        email: "",
        password: "",
        ssoId: "",
        externalAccountId: "",

        isSuper: false,
        isApiAdmin: false,
        isSuperClientAdmin: false,
        isClientAdmin: false,
        isAdmin: false,
        isCurator: false,
        isReports: false,
        isInitial: false,

        username: "",
        lastUpdatedOn: new Date(),
        createdOn: new Date(),

        groups: [],
        roles: [],
        tags: [],
        readOnly: false,
        delegateRequestNotification: true,
        description: ""
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), admin || {}) } as Mongo.ClientAdmin;
}
export function Brand(brand: Partial<Mongo.IBrand> = {}) {
    const defaults = {
        _id: null,
        brandName: "",
        appTitle: "",
        appTitleFormatted: "",
        appIcon: "",
        videoTutorial: "",
        btnBgColor: "", // buttons, checkbox, toggle, accordion header,  sliders, tooltip
        btnTxtColor: "", // buttons, checkbox, toggle, accordion header , tool tip
        tabs: "", // tabs border and text
        composeBtn: "", // compose + specific
        txtColor: "", // links
        iconColor: "", // in app icons, side nav
        topMenuIcon: "", // top menu bar, hamburger, page title text
        sideMenuIcon: "", // no longer used
        loadingBg: "",
        headerBarColor: "",
        headerBarBorderColor: "",
        extBrowserBarColor: "",
        chartColors: [],
        primaryColor: "",
        tertiaryColor: "",
        secondaryColor: "",
        lastUpdatedOn: new Date()
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), brand) };
}
export function ContentStream(contentStream = {}) {
    const defaults = {
        uuid: "",
        name: "",
        industry: "",
        terms: [],
        location: [],
        cid: ""
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), contentStream) };
}
export function Search(searchStream: Partial<ServerTypes.Console.IStream> = {}) {
    const defaults: ServerTypes.Console.IStream = {
        active: true,
        sid: "",
        searchType: "",
        code: "",
        title: "",
        isApprovalRequired: false,
        industry: "",
        locations: [],
        location: "",
        defaultStream: "",
        categories: [],
        terms: [],
        tags: [],
        feeds: [],
        seismicFeeds: [],
        filters: {},
        lastUpdatedOn: new Date(),
        lastSequentialShareDate: new Date(),
        hasSequentialShares: false,
        lang: "en",
        since: 0,
        isDefaultOn: false,
        isMandatory: false,
        isCustom: false,
        count: 3,
        streamOrder: 0,
        isEditable: false,
        isCompliant: false,
        skipCompliance: false,
        skipAllCompliance: false,
        isConsoleCompliant: false,
        sendArticleExtract: false,
        isWebShare: false,
        readOnly: false,
        canATSCopyLink: false,
        contentSearch: false,
        globalFilters: true,
        allowAutopost: false,
        sequentialContent: false,
        adminContentTags: false,
        type: "DEFAULT",
        seismicUsername: "",
        twitterFeeds: { aids: [], screenNames: [] },
        facebookFeeds: { aids: [], pages: [] },
        instagramFeeds: { aids: [], igPages: [] },
        linkedInFeeds: { aids: [], companies: [] },
        subscriptionPeriods: [],
        defaultSharingPermissions: {
            social: true,
            email: true,
            sms: true,
            socialNetworkPermissions: {
                facebook: true,
                twitter: true,
                instagram: true,
                linkedin: true
            }
        },
        complianceConfigId: "",
        syncTargets: [],
        syncPullTargets: [],
        bgColor: "",
        Hashtags: [],
        Mentions: [],
        HashtagsAndMentions: [],
        LockHashtagsAndMentions: false,
        showRepostedLinkedInArticles: false,
        sequentialCadence: 0,
        sequenceStartDate: new Date(),
        sequenceEndDate: undefined,
        sequentialContentEnabledDate: new Date(),
        showAllPosts: false,
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), searchStream) } as ServerTypes.Console.IStream;
}

export function User(user: Partial<Mongo.IClientUser> = {}) {
    const defaults: Mongo.IClientUser = {
        uid: "",
        cid: "",
        ssoId: "",
        entityConfigLink: "",
        externalAccountId: "",
        email: "",
        code: "",
        description: "",
        firstName: "",
        lastName: "",
        isActive: true,
        approved: false,
        lastUpdatedOn: new Date(),
        allowDelegates: false,
        allowSuperPublisher: false,
        allowECommunication: false,
        eCommunicationUserProfile: {
            showHotspots: true,
            onBoarded: false,
            transitioningCompletionDate: new Date(),
            username: "",
            password: "",
            account: null,
            externalId: "",
            syncCRMContacts: false,
        },
        oneWeekReminder: null,
        monthReminder: null,
        excludeFromReports: false,
        hasActivated: false,
        createdOn: new Date(),
        extraNetworks: [],
        tags: [],
        groups: [],
        lang: "",
        allowProspect: false,
        allowEngage: false,
        allowPublish: true,
        allowSuggest: false,
        allowEmail: false,
        allowAppEmail: false,
        allowAppSMS: false,
        allowCopyLink: false,
        allowOffers: false,
        allowProfSearches: false,
        allowPersSearches: false,
        socialNetworks: {
            Facebook: {
                login: false,
                companyShare: false,
                share: false
            },
            Twitter: {
                login: false,
                companyShare: false,
                share: false
            },
            LinkedIn: {
                login: false,
                companyShare: false,
                share: false
            },
            IG: {
                login: false,
                companyShare: false,
                share: false
            }
        },
        sms: undefined,
        advisorMap: {},
        delegateMap: {},

        profile: {
            title: "",
            industry: "",
            subIndustries: [],
            department: "",
            webpage: "",
            personalWebsitePath: "",
            terms: {
                professional: [],
                personal: []
            }
        },
        rid: "",
        pum: {
            groups: [],
            activityReviewTeams: [],
            profileReviewTeams: [],
        }
    };
    // Overwrite only the properties that exist in the default object since we don't trust extra/computed properties coming back
    // Example: tags: {[tid]: {name: string}} to tags: {[name]: tid}
    return {
        ...defaults,
        ...copyKeys(Object.keys(defaults), { ...deepMerge({ ...defaults }, { ...user }) })
    } as Mongo.IClientUser;
}

export function Feed(feed: Partial<CoreAPI.IFeed> = {}) {
    const defaults: CoreAPI.IFeed = {
        id: -1,
        url: "",
        feedDomain: "",
        articleDomain: "",
        weight: 0.5,
        category: "Business",
        subCategory: [],
        location: "",
        region: 0,
        lastUpdatedStatus: 200,
        lastUpdatedOn: new Date(),
        lang: "en",
        type: "RSS",
        password: "",
        username: "",
        eventType: "",
        tcAllowed: true,
        active: true
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), feed) } as CoreAPI.IFeed;
}

export function FeedSourceRequests(feedSourceRequests: Partial<Mongo.FeedSourceRequests & { _id: string }> = {}) {
    return feedSourceRequests as Mongo.FeedSourceRequests & { _id: string };
}

export function Role(role = {}) {
    const defaults = {
        rid: "",
        name: "",
        default: false,
        defaultTrueDelegate: false,
        allowSuperCommenter: false,
        allowAIComments: false,
        allowAICustomize: false,
        aiCommentPref: undefined,
        allowECommunication: false,
        allowFrontendCompose: false,
        allowFrontendVideoCompose: false,
        allowDeviceContacts: false,
        allowPublish: true,
        allowProspect: false,
        allowEngage: false,
        allowSuggest: false,
        allowEmail: false,
        allowAppEmail: false,
        allowAppSMS: false,
        allowCopyLink: false,
        allowOffers: false,
        allowProfSearches: false,
        allowPersSearches: false,
        skipComplianceCurated: false,
        skipCompliance3rdParty: false,
        skipComplianceComposed: false,
        complianceExempt: false,
        entityConfigLink: "",
        smsEntityConfigLink: "",
        skipActivationEmail: false,
        socialNetworks: {
            Facebook: {
                login: false,
                companyShare: false,
                share: false
            },
            Twitter: {
                login: false,
                companyShare: false,
                share: false
            },
            LinkedIn: {
                login: false,
                companyShare: false,
                share: false
            },
            IG: {
                login: false,
                companyShare: false,
                share: false
            }
        },
        customSMSMessages: {
            optout_msg: {
                en: "[name] is using LiveSocial, a messaging platform, to send you SMS updates. Reply OPT OUT at any time to stop receiving messages from this number.",
                fr: "[name] utilise LiveSocial, une plate-forme de messagerie, pour vous envoyer des mises à jour par SMS. Répondez OPT OUT à tout moment pour ne plus recevoir de messages de ce numéro."
            },
            optin_msg: {
                en: "[name] is using LiveSocial, a messaging platform, to send you SMS updates. Reply OPT IN at any time to continue receiving messages from this number.",
                fr: "[name] utilise LiveSocial, une plate-forme de messagerie, pour vous envoyer des mises à jour par SMS. Répondez OPT IN à tout moment pour continuer à recevoir des messages de ce numéro."
            },
            optin_confirmation_msg: {
                en: "You have successfully opted in to receiving text messages from [name] via this messaging platform. Reply OPT OUT at any time to stop receiving messages from this number.",
                fr: "Vous avez accepté avec succès de recevoir des messages texte de [nom] via cette plate-forme de messagerie. Répondez OPT OUT à tout moment pour ne plus recevoir de messages de ce numéro."
            },
            optout_confirmation_msg: {
                en: "You have successfully opted out of receiving text messages from [name] via this messaging platform. Reply OPT IN to receive messages again.",
                fr: "Vous avez refusé de recevoir des messages texte de [name] via cette plate-forme de messagerie. Répondez OPT IN pour recevoir à nouveau des messages."
            }
        },
        dates: {
            created: new Date(),
            updated: new Date()
        },
        isNotEmbeddedRole: false,
        smsComplianceConfigId: ""
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), role) };
}

export function Company(company = {}) {
    const defaults = {
        companyId: "",
        name: "",
        summary: [],
        industry: [],
        subIndustry: [],
        domain: "",
        url: "",
        specialties: [],
        location: "",
        size: "",
        address: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        ticker: "",
        handles: {
            twitter: [],
            facebook: []
        }
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), company) };
}

export function Industry(industry = {}) {
    const defaults = {
        iid: "",
        industry: "",
        specialties: {
            en: [],
            fr: [],
            es: [],
            de: [],
            pt: [],
            it: []
        },
        handles: {
            twitter: [],
            facebook: []
        },
        subIndustries: []
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), industry) };
}

export function OfferCampaign(campaign = {}) {
    const defaults = {
        ocid: "",
        active: true,
        name: "",
        cid: "",
        sid: "",
        sids: [],
        downloadLink: "",
        requirements: {
            type: "",
            trigger: {
                terms: [],
                count: 2
            }
        },
        ui: {
            title: "",
            body: "",
            buttons: {
                accept: "",
                cancel: ""
            },
            fields: {
                firstName: true,
                lastName: true,
                email: true,
                field0: "Job Title",
                field1: "Company Name",
                field2: "Job Function",
                field3: "Country"
            },
            disclaimer: "",
            privacy_url: "",
            background_image: ""
        },
        date_created: new Date()
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), campaign) } as ServerTypes.Console.IOfferCampaign;
}

export function Article(article = {}, duplicate = false) {
    const defaults: ServerTypes.Console.IArticle = {
        Title: "",
        Image: "",
        Thumbnail: "",
        Link: "",
        Domain: "",
        comment: "",
        Content: "",

        artid: "",
        cid: "",
        sid: "",
        lang: "en",

        CreatedOn: new Date(),
        lastUpdatedOn: new Date(),
        activeDate: new Date(),
        inActiveDate: undefined,

        Terms: [],
        Hashtags: [],
        Mentions: [],
        HashtagsAndMentions: [],
        LockHashtagsAndMentions: false,

        ATSLink: "",

        suggestedComments: [],
        suggestedCommentMentions: [],
        source: "",
        status: "new",

        duplicate,
        hasScheduled: false,
        notes: "",
        displayAdvisorNote: false,
        sharingPermissions: {
            social: true,
            email: true,
            sms: true,
            socialNetworkPermissions: {
                facebook: true,
                twitter: true,
                linkedin: true,
                instagram: true
            }
        },
        video: false,
        videoUrl: "",
        videoDuration: "",
        videoFileSize: 0,
        commentURL: "",
        Images: [],
        defaultThumbnail: "",
        docId: undefined,
        forceScheduleDate: null,
        addedImage: false,
        syncSourceSID: "",
        syncUpdates: false,
        networkSuggestedComments: "",
        linkedinMentions:[]
    };

    return { ...defaults, ...copyKeys(Object.keys(defaults), article) };
}

export function Notification(notification = {}) {
    const defaults = {
        nid: "",
        active: true,
        consumer: false,
        name: "",
        cid: "",
        currentLang: "en",
        title: "",
        body: "",
        buttonLabel: "",
        buttonUrl: "",
        description: "",
        date: {
            created: new Date(),
            last_active: new Date()
        },
        startDate: new Date(),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7, 9),
        style: "",
        isPush: false,
        groups: [],
        roles: [],
        type: "",

        Title: "",
        Image: "",
        Link: "",
        Domain: "",
        comment: "",
        Content: "",

        Terms: [],
        Hashtags: [],
        Mentions: [],
        HashtagsAndMentions: [],

        suggestedComments: [],

        notes: "",
        sharingPermissions: {
            social: false,
            email: false,
            sms: false,
            socialNetworkPermissions: {
                twitter: false,
                facebook: false,
                linkedin: false,
                instagram: false
            }
        },
        CreatedOn: new Date(),
        status: "new",
        video: false,
        videoUrl: "",
        videoDuration: "",
        videoFileSize: 0,
        addedImage: false,
        hasAdminComment: false,
        Thumbnail: "",
        customThumbnailUrl: "",
        commentURL: "",
        pushNotifySent: false,
        isWeeklyEmail: false,
        Images: [],
        defaultThumbnail: "",
        expiryDate: null,
        artid: "",
        bottomHashtags: "",
        bottomMentions: "",
        template: "",
        ecommTitle: "",
        ecommDescription: "",
        ecommFrequency: "",
        ecommExpiryDate: "",
        ecommPublishDate: "",
        ecommSubscribeId: "",
        ecommThumbnail: ""
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), notification) };
}

export function EmailDrip(emailDrip = {}) {
    const defaults = {
        eid: "",
        active: false,
        consumer: false,
        type: "",
        cid: "",
        senderEmail: "",
        senderName: "",
        activationWeeklyFrequency: 0,
        ui: {
            en: {
                email1: {
                    subject: `3 easy steps to get started on [title]`,
                    body: `Hi [name],
                    <br><br>Thank you for activating your account on [title]. Now, you’re ready to start engaging your readers and building your brand on social media, so it\’s time to <a href=[website]#!/intro > log in</a> and share. It’s as easy as 1-2-3. 
                    <br><br>1 - Find an article you or your network would be interested in (we have hundreds of thousands!)
                    <br/>2 - Click “Share this Week” or “Edit & Share” to schedule and share the content 
                    <br/>3 - Wait a day and check your <a href=[website]#!/main/shares/history >History</a> to see who has liked or commented on your article. 
                    <br><br>Having trouble finding an article? You can see the most popular content shared by other users <a href=[website]#!/main/reports/topArticles> here</a>, or add your own article by clicking <a href=[website]#!/main/compose>“Compose”</a>.
                    <br><br><br/>Happy sharing!`
                },
                email2: {
                    subject: `Your colleagues are finding success with [title], and so can you.`,
                    body: `Hi [name],
                    <br><br>Your colleagues are engaging clients and building their digital brands by posting great articles to their social media channels. You can find success with social too, and it only takes a few clicks!
                    <br><br>Find the most popular content across the organization <a href=[website]#!/main/reports/topArticles>here</a> and share your favorite article with your networks by clicking "Share This Week" or "Edit and Share".
                    <br><br>There’s tons of content waiting for you in [title]. <a href=[website]#!/intro>Log in</a> and get started now to start conversations, build relationships, and generate leads online.
                    <br><br>Share on!`
                },
                email3: {
                    subject: `How can we help?`,
                    body: `Hi [name],
                    <br><br>We've noticed you haven't shared any content from [title], your new social sharing tool. We want to make sure you have everything you need to get started, so we put together a video that shows you how to use the tool and some best practices for finding success.
                    <br><br>It only takes a minute to start engaging your followers, watch the <a href=[website]#!/main/help>full video here.</a>
                    <br><br>When you’re ready, you can log into [title] <a href=[website]#!/intro>here</a> and start building your digital brand.
                    <br><br>Happy Sharing!`
                }
            },
            fr: {
                email1: {
                    subject: `Commencer en seulement 3 étapes avec [title]`,
                    body: `Salut [name],
                    <br><br>Merci d'avoir activé votre compte sur [title]. Maintenant, vous êtes prêt à commencer à engager vos lecteurs et à construire votre réputation digitale sur les résesociaux. Il est donc temps de vous <a href=[website]#!/intro > connecter </a> et de publier. C'est aussi simple que cela !
                    <br><br>1 - Trouvez un article intéressant pour vous-même ou votre communautés (nous avons des centaines de milliers d’articles !)
                    <br/>2 - Cliquez sur "Programmer" ou "Personnaliser" pour planifier et partager le contenu.
                    <br/>3 - Attendez au moins une journée avec de vérifiez votre <a href=[website]#!/main/shares/history> Historique </a> et voir qui a aimé ou a commenté votre publication.
                    <br><br>Vous avez du mal à trouver du contenu ? Vous pouvez voir les contenus les plus tendances partagés par d'autres utilisateurs <a href=[website]#!/main/reports/topArticles> ici</a>, ou ajoutez vos propre articles en cliquant sur <a href=[website]#!/main/compose>“Composer”</a>.
                    <br><br><br/>Bonne publication ! `
                },
                email2: {
                    subject: `Vos collègues ont beaucoup de succès avec [title], et vous pouvez en faire de même.`,
                    body: `Salut [name],
                    <br><br>Vos collègues engagent les clients et créent leur réputation numériques en publiant des contenus sur leurs réseaux sociaux. Vous pouvez atteindre le même succès que vos collègues sur les réseaux sociaux en seulement quelques clics !
                    <br><br>Trouvez les contenus les plus populaires de votre entreprise <a href=[website]#!/main/reports/toparticles> ici </a> et publier vos articles préférés sur vos réseaux sociaux en cliquant sur "Programmer" ou "Personnaliser".
                    <br><br>Il y a de nombreux contenus qui vous attend sur [titre]. <a href=[website]#!/Intro> Connectez-vous </a> et commencez maintenant à entamer la conversation, créer des nouvelles relations et de générer des prospects en ligne.
                    <br><br>Bonne publication !`
                },
                email3: {
                    subject: `Comment pouvons-nous vous aider ?`,
                    body: `Salut [name],
                    <br><br>Nous avons remarqué que vous n'avez partagé aucun contenu sur [title], votre nouvel outil de partage social. Nous voulons nous assurer que vous avez tout ce dont vous avez besoin pour commencer, donc nous vous proposons une vidéo qui vous montre comment utiliser l'outil et quelques bonnes pratiques.
                    <br><br>Il ne vous faut qu'une minute pour commencer à engager votre audience, regarder la <a href=[website]#!/main/help>video ici.</a>
                    <br><br>Lorsque vous êtes prêt, vous pouvez vous connecter à [title] <a href=[website]#!/intro>ici </a> et commenver à construire votre réputation en ligne.
                    <br><br>Bonne publication !`
                }
            }
        },
        date_created: new Date(),
        date_activated: null,
        roles: [],
        groups: []
    };
    return { ...defaults, ...copyKeys(Object.keys(defaults), emailDrip) };
}
