import ArticleCount from "./ArticleCount";
import DomainCount from "./DomainCount";
import DomainInfo from "./DomainInfo";
import DomainTrending from "./DomainTrending";
export default [
    { path: "/ci/domaincount", component: DomainCount },
    { path: "/ci/articlecount", component: ArticleCount },
    { path: "/ci/domaininfo", component: DomainInfo },
    { path: "/ci/domaintrending", component: DomainTrending }
];
