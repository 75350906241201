import React, { Component } from "react";
import { connect } from "react-redux";
import { getLocalISODate, apiRequest, downloadFile as fileDownload, getLocaleDate } from "../../../../utils/Helpers";
import { Wrapper, Row } from "../../../atoms/Layout";
import DatePickerV2 from "../../../atoms/DatePickerV2";
import Link from "../../../atoms/Link";
import appConfig from "../../../../../config/config.dev";
import { createAlert } from "../../../../actions/utils";
import Button from "../../../atoms/Button";
import NumberChart from "../../../molecules/NumberChart";
import PercentCircle from "../../../atoms/PercentCircle";
import Chart from "../../../molecules/Chart";

interface ECommunicationDashboardProps {
    clients: Mongo.client[];
    user: Mongo.clientAdmin;
}

interface ECommunicationDashboardState {
    clients: Mongo.client[];
    startDate: string;
    endDate: string;
    tomorrow: string;
    ovLoading?: boolean;
    subLoading?: boolean;
    clientCode: string;
    overview: "loading" | ECommunicationDashboardOverview;
    renderData: boolean;
    subscription: "loading" | {
        available: number;
        subscribed: number;
        adoption: number;
        subscriptionsByTitle: {
            [key: string]: {
                users: number;
                emails: string;
            }
        }
    };
}

interface ECommunicationDashboardOverview {
    sent: number;
    opened: number;
    clicks: number;
    bounced: number;
    unsubscribed: number;
    uniqueClicks: number;
}

class ECommunicationDashboard extends Component<ECommunicationDashboardProps, ECommunicationDashboardState> {
    constructor(props) {
        super(props);
        const end = new Date();
        const start = new Date();
        const tomorrow = new Date();
        start.setMonth(start.getMonth() - 1);
        tomorrow.setDate(tomorrow.getDate() + 2);
        this.state = {
            clients: props.user.isSuper ? Object.keys(props.clients || {}).map(cid => { if (props.clients[cid].allowECommunication) return props.clients[cid]; }).filter(client => client != null) : [],
            startDate: `${getLocalISODate(start)}`,
            endDate: `${getLocalISODate(end)}`,
            tomorrow: `${getLocalISODate(tomorrow)}`,
            ovLoading: false,
            subLoading: false,
            clientCode: props.user.cid || "",
            overview: {
                sent: 0,
                opened: 0,
                clicks: 0,
                bounced: 0,
                unsubscribed: 0,
                uniqueClicks: 0,
            },
            renderData: false,
            subscription: {
                available: 0,
                subscribed: 0,
                adoption: 0,
                subscriptionsByTitle: {}
            },
        };
        this._buildQueryFilters = this._buildQueryFilters.bind(this);
        this._onFieldChange = this._onFieldChange.bind(this);
        this._clearFilters = this._clearFilters.bind(this);
        this._fetchData = this._fetchData.bind(this);
        this._downloadClicksByURL = this._downloadClicksByURL.bind(this);
        this._downloadSubscriptionReport = this._downloadSubscriptionReport.bind(this);
        this._renderOverview = this._renderOverview.bind(this);
        this._renderHeader = this._renderHeader.bind(this);
        this._renderMessagePage = this._renderMessagePage.bind(this);
        this._renderCTR = this._renderCTR.bind(this);
        this._renderSubscription = this._renderSubscription.bind(this);
    }

    _buildQueryFilters(props) {
        const queryFilters = {} as dynamic;
        if (props.startDate) {
            queryFilters.sd = props.startDate.substr(0, 10);
            // Important to and Thh:mm:ss.SSS since without the time specified it will assume UTC midnight instead of local
            queryFilters.sdtz = new Date(queryFilters["sd"] + "T00:00:00.000").getTimezoneOffset();
        }
        if (props.endDate) {
            queryFilters.ed = props.endDate.substr(0, 10);
            // Important to set time to end of local day since days on timezone transitions will have different timezone at end of day vs beginning
            queryFilters.edtz = new Date(queryFilters["ed"] + "T23:59:59.999").getTimezoneOffset();
        }
        if (props.clientCode) {
            queryFilters.clientCode = props.clientCode;
        }
        return queryFilters;
    }

    _onFieldChange(field, val) {
        const value = val && val.target ? val.target.value : val;
        this.setState({ ...this.state, [field]: value });
    }

    _clearFilters() {
        const end = new Date();
        const start = new Date();
        const tomorrow = new Date();
        start.setMonth(start.getMonth() - 1);
        tomorrow.setDate(tomorrow.getDate() + 1);

        this.setState({
            startDate: `${getLocalISODate(start)}`,
            endDate: `${getLocalISODate(end)}`,
            tomorrow: `${getLocalISODate(tomorrow)}`,
            clientCode: this.props.user.cid || "",
            overview: {
                sent: 0,
                opened: 0,
                clicks: 0,
                bounced: 0,
                unsubscribed: 0,
                uniqueClicks: 0,
            },
            subscription: {
                available: 0,
                subscribed: 0,
                adoption: 0,
                subscriptionsByTitle: {}
            },
            renderData: false,
        });
        createAlert('Filters have been cleared', 'success')
    }

    async _fetchData() {
        const filters = this._buildQueryFilters(this.state);
        if (!this.state.clientCode) {
            this.setState({ ovLoading: false, subLoading: false });
            createAlert(`Please select a client!`, `error`);
            return;
        }
        this.setState(
            {
                ovLoading: true,
                subLoading: true,
                renderData: true,
                overview: "loading",
                subscription: "loading"
            },
            () => {
                Promise.all([
                    this._fetchOverview(filters),
                    this._fetchSubscription(filters.clientCode)
                ])
            }
        );
    }

    _fetchOverview(filters: dynamic) {
        return apiRequest(`${appConfig.API_URL}/e-communication/reports/dashboard`, "POST", filters)
            .then(data => {
                if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                    throw data.error;
                }
                this.setState({
                    overview: data,
                    ovLoading: false
                });
            }).catch(err => {
                if (err.error == "Report Service Error") {
                    createAlert("Data set too large", 'error');
                } else {
                    createAlert(err, 'error');
                }
                this.setState({
                    overview: { sent: 0, opened: 0, clicks: 0, bounced: 0, unsubscribed: 0, uniqueClicks: 0 },
                    ovLoading: false
                })
            })
    }

    _fetchSubscription(clientCode: string) {
        return apiRequest(`${appConfig.API_URL}/e-communication/reports/subscription`, "POST", { cid: clientCode })
        .then(data => {
            if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                throw data.error;
            }
            this.setState({
                subscription: data,
                subLoading: false
            });
        }).catch(error => {
            this.setState({
                subscription: { available: 0, subscribed: 0, adoption: 0, subscriptionsByTitle: {} },
                subLoading: false
            })
            createAlert(error, 'error');
        })
    }


    async _downloadSubscriptionReport(): Promise<void> {
        return new Promise((resolve, reject) => {
            const subscriptions = typeof this.state.subscription === "string" ? {} : this.state.subscription.subscriptionsByTitle;
            const d = Object.keys(subscriptions || {}).map(title => {
                const { users, emails } = subscriptions[title];
                return [title, users, emails];
            }) as Array<[string, number, string]>;
            d.sort((a, b) => b[1] - a[1]);
            if (!d.length) {
                createAlert("No data available", "error");
                resolve();
            }
            const headers = ["Subscription Name", "Subscribed Users", "User List"];
            let data = `"${headers.join("\",\"")}"`;
            d.forEach(record => data += `\n\"${record[0] || ""}\",\"${record[1] || 0}\",\"${record[2] || ""}\"`);
            fileDownload(data, `eCommSubscriptions-${getLocaleDate(new Date())}.csv`);
            resolve();
        });
    }

    async _downloadActivityByUserData(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const filters = this._buildQueryFilters(this.state);
            await apiRequest(`${appConfig.API_URL}/e-communication/reports/activityByUser`, "POST", filters).then(res => {
                if (res?.length == 0) {
                    createAlert("No data available", "error");
                    resolve();
                    return;
                }
                const headers = ["First Name", "Last Name", "Sends", "Opens", "Clicks", "Open Rate", "Click Rate"];
                let data = '"' + headers.join("\",\"") + '"';
                for (const record of res) {
                    let row = "\n\"" + record["First Name"] + "\"";
                    for (let idx = 1; idx < headers.length; idx++) {
                        switch (headers[idx]) {
                            case "Last Name":
                            case "Open Rate":
                            case "Click Rate":
                                row += ",\"" + record[headers[idx]] + "\"";
                                break;
                            default:
                                row += "," + record[headers[idx]];
                                break;
                        }
                    }
                    data += row;
                }
                fileDownload(data, `ecommPerformanceByUser-${getLocaleDate(new Date())}.csv`);
                resolve();
            }).catch(error => {
                createAlert(error, `error`);
                resolve();
            });
        });
    }

    async _downloadClicksByURL(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const filters = this._buildQueryFilters(this.state);
            await apiRequest(`${appConfig.API_URL}/e-communication/reports/clicksByURL`, "POST", filters).then(res => {
                if (res?.length == 0) {
                    createAlert("No data available", "error");
                    resolve();
                }
                const headers = ["URL", "Clicks"];
                let data = '"' + headers.join("\",\"") + '"';
                for (const record of res) {
                    let row = "\n\"" + record["URL"] + "\"";
                    for (let idx = 1; idx < headers.length; idx++) {
                        switch (headers[idx]) {
                            case "Clicks":
                                row += ",\"" + record[headers[idx]] + "\"";
                                break;
                            default:
                                row += "," + record[headers[idx]];
                                break;
                        }
                    }
                    data += row;
                }
                fileDownload(data, `clicksByURL-${getLocaleDate(new Date())}.csv`);
                resolve();
            }).catch(error => {
                createAlert(error, `error`);
                resolve();
            });
        });
    }

    _renderOverview() {
        const { ovLoading } = this.state;
        const sent = typeof this.state.overview === "string" ? 0 : this.state.overview?.sent;
        const bounced = typeof this.state.overview === "string" ? 0 : this.state.overview?.bounced;
        const opened = typeof this.state.overview === "string" ? 0 : this.state.overview?.opened;
        const clicks = typeof this.state.overview === "string" ? 0 : this.state.overview?.clicks;
        const unsubscribed = typeof this.state.overview === "string" ? 0 : this.state.overview?.unsubscribed;

        const labels = [[`Sends`], [`Opens`], [`Clicks`], [`Unsubscribes`]];
        const dataBase = [
            sent,
            opened,
            clicks,
            unsubscribed
        ];
        const dataContent = [
            bounced,
            null,
            null,
            null,
        ];
        const totalTooltip = `Total: ${sent + bounced}`;
        const tooltipData = [
            [
                `Delivered: ${sent}`,
                null, null, null
            ],
            [
                `Bounced: ${bounced}`,
                null, null, null
            ]
        ];
        return (
            <div className="col-lg-12">
                <Chart
                    title="Overview"
                    downloadAction={e => this._downloadActivityByUserData()}
                    downloadText="Download activity by user"
                    data={[dataBase, dataContent]}
                    labels={labels}
                    type="horizontal-bar"
                    loading={!!ovLoading}
                    tooltip={<span>
                        <p>
                            This card shows how your audience is engaging with the emails your users send. Emails that
                            <br />are scheduled but have not been sent yet will not be included in this report. Emails from users
                            <br />excluded from reporting will not be included in this report.
                        </p>
                        <br />
                        <p>Sends: The number of emails that have been sent within the start and end date </p>
                        <p>Delivered: The number of emails that have been successfully delivered within the start and end date  </p>
                        {/* <p>
                            Bounced: The number of emails that were unable to be delivered within the start and end date, due
                            <br />to reasons such as an invalid email address or the recipient’s mailbox being full

                            </p> */}
                        <p>
                            Opens: The number of times an email has been opened within the start and end date, regardless of
                            <br />when the email was sent.
                        </p>
                        <p>
                            Clicks: The total number of times a link within an email has been clicked within the start and end date,
                            <br />regardless of when the email was sent. If the same recipient clicks more than one link in the same
                            <br />email, or the same link more than once, this will be counted as multiple clicks.
                        </p>
                        <p>
                            Unsubscribes: The total number of unsubscribes within the time frame. When a recipient
                            <br />unsubscribes, they will no longer receive any emails from the respective sender.
                        </p>
                    </span>}
                    customChartTooltips={{
                        xAlign: "right",
                        callbacks: {
                            title: function (tooltipItem) {
                                const title = tooltipItem[0].yLabel;
                                return title.constructor === Array ? title.join(' ') : title;
                            },
                            label: function(tooltipItem, data) {
                                const tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                if (tooltipData[tooltipItem.datasetIndex][tooltipItem.index] != null) {
                                    return tooltipData[tooltipItem.datasetIndex][tooltipItem.index];
                                } else {
                                    return tooltipValue?.toLocaleString();
                                }
                            },
                            beforeBody: function(tooltipItems) {
                                if (tooltipItems[0].index === 0) {
                                    return totalTooltip;
                                } else {
                                    return null;
                                }
                            }
                        }
                    }}
                />
            </div>
        );
    }
    _renderHeader() {
        const { startDate, endDate, tomorrow, clients, clientCode } = this.state;
        return (
            <div
                className="col-lg-9"
            >
                <div className="form-group">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            marginBottom: "10px"
                        }}
                    >
                        <div className="date__wrapper">
                            <label className="control-label">Start Date</label>
                            <DatePickerV2
                                dateFormat="yyyy/MM/dd"
                                maxDate={endDate}
                                value={startDate}
                                onChange={value => {
                                    if (!value) {
                                        const start = new Date();
                                        start.setMonth(start.getMonth() - 1);
                                        value = `${getLocalISODate(start)}T00:00:00.000Z`;
                                    }
                                    this._onFieldChange("startDate", value);
                                }}
                                onBlur={() => this._onFieldChange("startDate", "")}
                                enableTabLoop={false}
                            />
                        </div>
                        <div
                            className="date__wrapper"
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            <label className="control-label">End Date</label>
                            <DatePickerV2
                                dateFormat="yyyy/MM/dd"
                                maxDate={tomorrow}
                                minDate={startDate}
                                value={endDate}
                                onChange={value => {
                                    if (!value) {
                                        value = `${getLocalISODate(new Date())}T00:00:00.000Z`;
                                    }
                                    this._onFieldChange("endDate", value);
                                }}
                                onBlur={() => this._onFieldChange("endDate", "")}
                                enableTabLoop={false}
                            />
                        </div>
                        {
                            this.props.user.isSuper && (
                                <div
                                    style={{
                                        marginLeft: "10px"
                                    }}
                                >
                                    <label className="control-label">Client</label>
                                    <div
                                        className="select__wrapper"
                                        style={{
                                            marginLeft: "0px"
                                        }}
                                    >
                                        <select
                                            value={clientCode}
                                            onChange={event =>
                                                this._onFieldChange("clientCode", event.target.value)
                                            }
                                        >
                                            <option value="">Select a client</option>
                                            {
                                                clients &&
                                                clients.map(
                                                    client => {
                                                        return (
                                                            <option key={client.cid} value={client.cid}>
                                                                {client.name}
                                                            </option>
                                                        );
                                                    }
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                            )
                        }
                        <div>
                            <Button
                                onClick={() => this._fetchData()}
                                style={{ margin: "10px", top: "10px" }}
                            >
                                Search
                            </Button>
                        </div>
                        <div>
                            <label className="" />
                            <div style={{ margin: "0 10px 0 10px" }}>
                                <Link style={{ textDecoration: "underline", color: "#152733" }}
                                    onClick={() => this._clearFilters()}
                                >Clear Filter</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    _renderMessagePage() {
        return (
            <div style={{ width: "100%", display: "block", marginTop: "14%" }}>
                <p style={{ fontSize: 20, textAlign: "center", alignSelf: "end", fontWeight: "bold" }}>To run this report, please adjust the filters above and click "Search"</p>
                <p style={{ textAlign: "center", opacity: 0.5 }}>
                    <img src="/img/Reports_Load.svg" style={{ color: "#A7B1C2", opacity: 0.5 }} />
                </p>
            </div>
        );
    }

    _renderSubscription() {
        const available = typeof this.state.subscription === "string" ? 0 : this.state.subscription?.available;
        const subscribed = typeof this.state.subscription === "string" ? 0 : this.state.subscription?.subscribed;
        const adoption = typeof this.state.subscription === "string" ? 0 : this.state.subscription?.adoption;
        const { subLoading } = this.state;

        return (
            <div className="col-xs-12 col-lg-6 hide-col" style={{ marginLeft: "-15px !important" }}>
                <NumberChart
                    downloadLabel="Download"
                    download={(startDate, endDate) => this._downloadSubscriptionReport()}
                    title="Subscriptions"
                    noDataLabel={!available && !subscribed && !adoption ? "Could not find any data for this report" : ""}
                    tooltip={
                        <span>
                            <p>
                                An overview of the performance of your email subscription campaigns. Note that this card is<br />
                                only able to report on metrics at the current date and time. Therefore, it will not take into account<br />
                                the Start and End date filters.
                            </p>
                            <br />
                            <p>Subscriptions available: the number of subscriptions that are available to users</p>
                            <p>Subscribed users: The number of users that have recipients subscribed to at least one subscription campaign</p>
                            <p>User adoption: The percentage of users that have recipients subscribed to at least one campaign</p>
                        </span>
                    }
                    data={{
                        available: {
                            label: ["Subscriptions available"],
                            value: Math.round(available).toLocaleString()
                        },
                        subscribed: {
                            label: ["Subscribed users"],
                            value: Math.round(subscribed).toLocaleString()
                        },
                        adoption: {
                            label: ["User adoption"],
                            value: <PercentCircle
                                percent={Math.round(adoption)}
                            />
                        },
                    }}
                    loading={subLoading}
                />

            </div>
        );
    }

    _renderCTR() {
        const sent = typeof this.state.overview === "string" ? 0 : this.state.overview?.sent;
        const uniqueClicks = typeof this.state.overview === "string" ? 0 : this.state.overview?.uniqueClicks;
        const { ovLoading } = this.state;
        return (
            <div className="col-xs-12 col-lg-6 hide-col" style={{ marginLeft: "-15px !important" }}>
                <NumberChart
                    downloadLabel="Download"
                    download={(startDate, endDate) => this._downloadClicksByURL()}
                    title="Click-through Rate"
                    noDataLabel={!sent && !uniqueClicks ? "Could not find any data for this report" : ""}
                    tooltip={
                        <span>
                            <p>An overview of the performance of the emails your users send.</p>
                            <br />
                            <p>
                                Delivered: The number of emails that have been successfully delivered within the start and end date.<br />
                                date. If the same recipient clicks more than one link in the email, or the same link more than once, this<br />
                                will be counted as single click.
                            </p>
                            <p>Click-through rate: The number of unique clicks divided by the number of delivered emails.</p>
                        </span>
                    }
                    data={{
                        Offers: {
                            label: ["Delivered"],
                            value: Math.round(sent).toLocaleString()
                        },
                        Leads: {
                            label: ["Unique clicks"],
                            value: Math.round(uniqueClicks).toLocaleString()
                        },
                        Conversion: {
                            label: ["Click-through rate"],
                            value: (
                                <PercentCircle
                                    percent={
                                        (sent && uniqueClicks
                                            ? Math.round(
                                                (uniqueClicks / sent) * 100 > 100 ? 100 : (uniqueClicks / sent) * 100 // If rate is greater than 100%, set to 100% instead
                                            ).toFixed(0)
                                            : 0) as number
                                    }
                                />
                            )
                        }
                    }}
                    loading={ovLoading}
                />
            </div>
        );
    }
    render() {
        const { ovLoading, subLoading, renderData } = this.state;
        return (
            <Wrapper id="ecomm-dashboard">
                <Row>
                    {this._renderHeader()}
                    {!(ovLoading && subLoading) && !renderData && this._renderMessagePage()}
                    {renderData && this._renderOverview()}
                    {renderData && this._renderCTR()}
                    {renderData && this._renderSubscription()}
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(ECommunicationDashboard);
