import React, { Component } from "react";
import { createAlert } from "../../actions";
import ConditionalWrapper from "../atoms/Layout/ConditionalWrapper";
import Link from "../atoms/Link";

interface uploadFile {
    uploadedImageURL?: ServerTypes.Console.IArticle["Image"];
    fileUpload?: File[] | null;
    addedImage?: ServerTypes.Console.IArticle["addedImage"];
    video?: ServerTypes.Console.IArticle["video"];
    uploadedFile?: boolean;
    Link?: ServerTypes.Console.IArticle["Link"];
    thumbnail?: string;
    customPdfFile?: File | null | undefined;
    videoDuration?: number;
    videoWidth?: number;
    videoHeight?: number;
    videoFileSize?: number;
}

interface ArticleFileUploadProps {
    /** *must* be provided if using multiple of this component on one page */
    id?: string;
    allowVideoCuration?: Mongo.IClient["allowVideoCuration"];
    onValidUpload: (file: uploadFile) => void;
    title?: string;
    style?: React.CSSProperties;
    uploadLinkStyle?: React.CSSProperties;
    disabled?: boolean;
    isMedia?: boolean;
    article?: ServerTypes.Console.IArticle;
    setStateManual?: (entries: Partial<ServerTypes.Console.IArticle>) => void;
    isPdf?: boolean;
    link?: string;
    hasInstagram?: boolean;
    singleImageOnly?: boolean;
}

interface ArticleFileUploadState {
    isVideo?: boolean;
    isGif?: boolean;
    images?: File[] | null;
    isPdf?: boolean;
    imagesWeekly?: File[] | null;
}

export default class ArticleFileUpload extends Component<ArticleFileUploadProps, ArticleFileUploadState> {
    inputFile: HTMLInputElement | null = null;
    constructor(props: ArticleFileUploadProps) {
        super(props);
        const { article } = props;
        this.state = {
            isVideo: article?.video,
            isGif: article?.Image?.includes("gif"),
            images: [],
            imagesWeekly: [],
            isPdf: props.isPdf
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { article, disabled } = this.props;
        const { article: newArticle, disabled: newDisabled } = nextProps;
        const { Title, comment, Hashtags, Mentions, activeDate, inActiveDate, sharingPermissions, notes } = article || {};
        const { Title: newTitle, comment: newComment, Hashtags: newHashtags, Mentions: newMentions, activeDate: newActiveDate, inActiveDate: newInactiveDate, sharingPermissions: newSharingPermissions, notes: newNotes } = newArticle || {};

        if (!!disabled !== !!newDisabled) return true;
        if ((Title || newTitle) && Title != newTitle) return false;
        if ((comment || newComment) && comment != newComment) return false;
        if ((Hashtags || newHashtags) && JSON.stringify(Hashtags || []) != JSON.stringify(newHashtags || [])) return false;
        if ((Mentions || newMentions) && JSON.stringify(Mentions || []) != JSON.stringify(newMentions || [])) return false;
        if ((sharingPermissions || newSharingPermissions) && JSON.stringify(sharingPermissions || {}) != JSON.stringify(newSharingPermissions || {})) return false;
        if ((inActiveDate || newInactiveDate) && inActiveDate != newInactiveDate) return false;
        if ((activeDate || newActiveDate) && activeDate != newActiveDate) return false;
        if ((notes || newNotes) && notes != newNotes) return false;
        return true;
    }

    _uploadImage() {
        $(`#${this.props.id || "imageUpload"}`).trigger("click");
    }

    _isMultiFormat(files: File[]) {
        let isGif = false;
        let isVid = false;
        let isImg = false;
        for (const file of files) {
            if (/(mp4|m4v)$/i.test(file.type)) isVid = true;
            if (/(gif)$/i.test(file.type)) isGif = true;
            if (/(jpg|jpeg|png)$/i.test(file.type)) isImg = true;
        }
        return isGif && isVid || isGif && isImg || isVid && isImg;
    }

    _onChange(e) {
        const { images, isVideo, isGif, isPdf } = this.state;
        const { article, allowVideoCuration, onValidUpload, isMedia, link, setStateManual, singleImageOnly } = this.props;
        const invalidErrMsg = allowVideoCuration ? "Invalid file type, please upload only .png, .jpeg, .gif, .mp4 or .m4v files." : "Invalid file type, please upload only .png, .jpeg, or .gif files.";
        if (!this.inputFile?.files?.length) {
            return;
        }
        if (isPdf) {
            const file = this.inputFile.files[0];
            if (!(/(pdf)$/i.test(file.type))) {
                this.inputFile = null;
                e.target.value = null;
                createAlert("Invalid file type, please upload a .pdf file.");
                return;
            }
            if (this.inputFile.files.length > 1) {
                this.inputFile = null;
                e.target.value = null;
                createAlert("Please upload a single PDF only.");
                return;
            }
            if (file.size > 60 * 1024 * 1024) {
                this.inputFile = null;
                e.target.value = null;
                createAlert(`PDF file size must be under 60MB.`);
                return;
            }
            onValidUpload({
                addedImage: false,
                customPdfFile: file
            });
            this.inputFile = null;
            e.target.value = null;
            return;
        }

        if (this._isMultiFormat([...(images || []), ...this.inputFile.files])) {
            this.inputFile = null;
            e.target.value = null;
            createAlert("Please select either 1-4 images, 1 GIF, or 1 video.");
            return;
        }

        if (
            (((article?.Images?.length || 0) + this.inputFile.files.length) > 4) ||
            ((images!.length + this.inputFile.files.length) > 4)
        ) {
            this.inputFile = null;
            e.target.value = null;
            createAlert("Cannot select more than 4 images.");
            return;
        }
        if ((link || isPdf) && (images!.length + this.inputFile.files.length) > 1) {
            this.inputFile = null;
            e.target.value = null;
            createAlert("Cannot select more than 1 image.");
            return;
        }
        if (!singleImageOnly) {
            if ([...(images || []), ...this.inputFile.files].filter((file, index, self) => self.findIndex(e => e.name === file.name) !== index).length) {
                this.inputFile = null;
                e.target.value = null;
                createAlert("Cannot select duplicate files.");
                return;
            }
        }

        for (const [index, file] of Object.entries(this.inputFile.files)) {
            const { type: fileType, size: fileSize, name: fileName } = file;
            if (fileType.includes("video")) {
                if (this.inputFile.files.length > 1) {
                    this.inputFile = null;
                    e.target.value = null;
                    createAlert("Cannot select more than one video.");
                    return;
                }
                if (!isMedia) {
                    createAlert(invalidErrMsg);
                    return;
                }
                if (!allowVideoCuration) {
                    createAlert("You don't have permission to upload video files.");
                    return;
                }
                if ((!["video/mp4", "video/m4v"].includes(fileType)) || (!["mp4", "m4v"].includes(fileName.split(".").pop()?.toLowerCase()!))) {
                    // fileType check doesn't catch m4v files.
                    createAlert(invalidErrMsg);
                    return;
                }
                if (fileSize > 650 * 1024 * 1024) {
                    createAlert(`Please upload a video file under 650MB.`);
                    return;
                }
                const videoReader = new FileReader();
                videoReader.onload = () => {
                    const MIN_VIDEO_DURATION = 3;
                    const MAX_VIDEO_DURATION = 600; //10mins
                    const url = URL.createObjectURL(file);
                    const video = document.createElement("video");
                    video.onloadeddata = async () => {
                        const videoHeight = video.videoHeight;
                        const videoWidth = video.videoWidth;
                        if (Math.round(video.duration) < MIN_VIDEO_DURATION) {
                            createAlert("Video duration must be at least 3 seconds.");
                            return;
                        }
                        if (Math.round(video.duration) > MAX_VIDEO_DURATION) {
                            createAlert("Video duration must be under 10 minutes.");
                            return;
                        };
                        if (videoHeight / videoWidth < (9 / 16)
                        || videoHeight / videoWidth > (16 / 9)) {
                            createAlert("Video aspect ratio must be between 16:9 and 9:16.");
                            return;
                        }
                        const source = await snapImage() as string;
                        if (images) images.push(file);
                        this.setState({ isVideo: true, images }, () => {
                            onValidUpload({
                                fileUpload: images!,
                                uploadedImageURL: images![0].name,
                                addedImage: true,
                                video: true,
                                Link: null,
                                uploadedFile: true,
                                thumbnail: source,
                                videoDuration: video.duration,
                                videoWidth: video.videoWidth,
                                videoHeight: video.videoHeight,
                                videoFileSize: images![0].size
                            });
                        });
                    };
                    const snapImage = async () => {
                        const canvas = document.createElement("canvas");
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        canvas.getContext("2d")!.drawImage(video, 0, 0, canvas.width, canvas.height);
                        const thumbnail = canvas.toDataURL();
                        URL.revokeObjectURL(url);
                        return thumbnail;
                    };
                    video.preload = "metadata";
                    video.src = url;
                    video.muted = true;
                    video.play();
                };
                videoReader.readAsArrayBuffer(file);
            } else if (fileType.includes("image")) {
                if (singleImageOnly) {
                    // TODO: currently works for our purposes
                    // but we might want a separate prop for pngs only in the future
                    if (!fileType.match(/.(png)$/i)) {
                        createAlert("Invalid file type, please upload a .png file");
                        return;
                    }
                }
                if (!fileType.match(/.(jpg|jpeg|png|gif)$/i)) {
                    createAlert(invalidErrMsg);
                    return;
                }
                if (fileSize > 5 * 1024 * 1024) {
                    // maximum file size supported by twitter
                    createAlert("Image file size must be under 5MB.");
                    return;
                }

                const imageReader = new FileReader();
                const url = URL.createObjectURL(file);
                imageReader.onload = event => {
                    const image = new Image();
                    image.onload = () => {
                        const ratio = Math.round(image.width / image.height * 100) / 100;
                        if (images && (ratio < 0.8 || ratio > 1.91) && this.props.hasInstagram && !/(gif)$/i.test(fileType) && !confirm("This image does not meet Instagram's specifications. This image will be automatically resized.\nDo you wish to continue?")) {
                            images.splice(Number(index), 1);
                            this.setState({ images, ...(isGif ? { isGif: false } : null), ...(isVideo ? { isVideo: false } : null) });
                            if (setStateManual && article?.Image && !article.Image.includes("grapevinesix")) {
                                const Image = images.length > 0 ? images[0].name : undefined;
                                setStateManual({
                                    Image
                                });
                            }
                        }
                        URL.revokeObjectURL(url);
                    }
                    image.src = url;
                }
                imageReader.readAsArrayBuffer(file);
                // Instagram only allows images between 1.91:1 and 4:

                if (/(gif)$/i.test(fileType)) {
                    if (this.inputFile.files.length > 1) {
                        this.inputFile = null;
                        e.target.value = null;
                        createAlert("Cannot select more than one gif.");
                        return;
                    }
                    this.setState({ isGif: true });
                }
                if (images) images.push(file);
            } else {
                createAlert(invalidErrMsg);
                return;
            }
        }

        if (!isVideo) {
            this.setState({ images });
            onValidUpload({
                fileUpload: images!,
                uploadedImageURL: article?.Image?.includes("grapevinesix") ? article.Image : images?.[0]?.name || "",
                addedImage: true,
                video: false,
                uploadedFile: true
            });
        }
        this.inputFile = null;
    }

    render() {
        const { allowVideoCuration, title, style, uploadLinkStyle, disabled, isMedia, article, setStateManual, isPdf, singleImageOnly } = this.props;
        const { isVideo, isGif, images } = this.state;
        const thumbnailCss = (source: File | string) => ({
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            border: "1px solid #ccc",
            borderRadius: "5px",
            verticalAlign: "top",
            backgroundImage: `url(${typeof source == "string" ? source : URL.createObjectURL(source)})`,
            width: "75px",
            height: "75px"
        });

        const existingImages = article?.Images?.length || (article?.Image?.includes("grapevinesix") ? 1 : 0);
        const uploadedFiles = images?.length || 0;
        const totalImages = existingImages + uploadedFiles;

        const hideUploadLink = !!(
            !isPdf &&
            isMedia &&
            !isVideo &&
            (
                article?.Images?.length ||
                (article && !article.Link && article.Image?.length) ||
                images?.length
            )
        );

        return (
            <>
                {
                    hideUploadLink && <>
                        {
                            !!article?.Images?.length && (article.Images as string[]).map((img, index) => {
                                return (
                                    <div
                                        style={{
                                            display: "inline-flex",
                                            flexDirection: "row",
                                            ...(isGif || isVideo ? { margin: "auto" } : { margin: "10px 5px" })
                                        }}
                                    >
                                        <span
                                            className="closeButton"
                                            onClick={() => {
                                                (article.Images as string[]).splice(index, 1);
                                                if (setStateManual) {
                                                    const Image = article?.Images?.[0];
                                                    setStateManual({
                                                        Image,
                                                        Images: article.Images
                                                    });
                                                }
                                            }}
                                        >x</span>
                                        <div style={thumbnailCss(img)} />
                                    </div>
                                );
                            })
                        }
                        {
                            (article && !article.Images?.length && article.Image?.includes("grapevinesix")) &&
                            <div
                                style={{
                                    display: "inline-flex",
                                    flexDirection: "row",
                                    ...(isGif || isVideo ? { margin: "auto" } : { margin: "10px 5px" })
                                }}
                            >
                                <span
                                    className="closeButton"
                                    onClick={() => {
                                        if (setStateManual) {
                                            setStateManual({
                                                Image: ""
                                            });
                                        }
                                    }}
                                >x</span>
                                <div style={thumbnailCss(article.Image)} />
                            </div>
                        }
                        {
                            images?.map((file, index) => {
                                return (
                                    <div
                                        style={{
                                            display: "inline-flex",
                                            flexDirection: "row",
                                            ...(isGif || isVideo ? { margin: "auto" } : { margin: "10px 5px" })
                                        }}
                                    >
                                        <span
                                            className="closeButton"
                                            onClick={() => {
                                                images.splice(index, 1);
                                                this.setState({ images, ...(isGif ? { isGif: false } : null), ...(isVideo ? { isVideo: false } : null) });
                                                if (setStateManual && article?.Image && !article.Image.includes("grapevinesix")) {
                                                    const Image = images.length > 0 ? images[0].name : undefined;
                                                    setStateManual({
                                                        Image
                                                    });
                                                }
                                            }}
                                        >x</span>
                                        <div style={thumbnailCss(file)} />
                                    </div>
                                );
                            })
                        }
                        {
                            (
                                !isGif &&
                                !isVideo &&
                                totalImages < 4 &&
                                (
                                    (!!images?.length && images.length < 4) ||
                                    !!article?.Images?.length ||
                                    (article?.Image && !article.Link)
                                )
                            ) && [...new Array(4 - totalImages)].map(() => {
                                return (
                                    <div
                                        style={{
                                            display: "inline-flex",
                                            flexDirection: "row",
                                            flexBasis: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "2px dashed #ccc",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            margin: "10px 5px",
                                            verticalAlign: "top",
                                            width: "75px",
                                            height: "75px"
                                        }}
                                        onClick={() => this._uploadImage()}
                                    >
                                        <i
                                            className="icon ion-plus"
                                            style={{
                                                color: "#57308A",
                                                fontSize: "50px"
                                            }}
                                        />
                                    </div>
                                );
                            })
                        }
                    </>
                }
                <ConditionalWrapper
                    condition={!hideUploadLink}
                    wrapper={children => <div style={{ textAlign: "center", ...style }}>{children}</div>}
                >
                    {
                        !hideUploadLink && <Link disabled={disabled} style={{ textDecoration: "underline", ...(disabled ? { cursor: "default" } : {}), ...uploadLinkStyle }} onClick={() => this._uploadImage()}>
                            {title || "Upload an Image"}
                        </Link>
                    }
                    <input
                        id={this.props.id || "imageUpload"}
                        style={{
                            display: "none"
                        }}
                        ref={input => {
                            this.inputFile = input;
                        }}
                        accept={isPdf ? "application/pdf" : (allowVideoCuration && !singleImageOnly) ? "image/*,video/mp4,video/m4v" : "image/*"}
                        type="file"
                        onChange={e => this._onChange(e)}
                        multiple={!singleImageOnly}
                    />
                </ConditionalWrapper>
            </>
        );
    }
}
