import React from "react";
import DomainFilters from "./DomainFilters";
import HashtagList from "./hashtag/List";
import TermsList from "./terms/List";
import ArticleUpload from "./Upload";
import LibraryList from "./List";
import Search from "./Search";
import ECommunication from "./eCommunication/List";
import ECommunicationSubscribable from "./eCommunication/Subscribable";
import ECommunicationCategory from "./eCommunication/Category";
import ECommunicationSubjectLinePerformanceView from "./eCommunication/SubjectLinePerformance";
import ECommunicationDashboard from "./eCommunication/Dashboard";
import ContentTags from "./contentTags/List";
export default [
    {
        exact: true,
        path: "/content/a",
        component: LibraryList,
        render: props => <LibraryList {...props} status="approved" />
    },
    { exact: true, path: "/content/s", component: Search },
    { path: "/content/s/:sid", component: Search },
    {
        exact: true,
        path: "/content/l",
        component: LibraryList,
        render: props => <LibraryList {...props} status="approved" />
    },
    { path: "/content/l/e/:artid", component: LibraryList },
    { path: "/content/l/:status", component: LibraryList },
    { path: "/content/u", component: ArticleUpload },
    { path: "/content/hl", component: HashtagList },
    { path: "/content/tl", component: TermsList },
    { path: "/ecommunication/messages", component: ECommunication },
    { path: "/ecommunication/subscribables", component: ECommunicationSubscribable },
    { path: "/ecommunication/categories", component: ECommunicationCategory },
    { path: "/ecommunication/dashboard", component: ECommunicationDashboard },
    { path: "/ecommunication/subjectLinePerformance", component: ECommunicationSubjectLinePerformanceView },
    { path: "/content/df", component: DomainFilters },
    { path: "/content/ts/:cid", component: ContentTags }
];
