import React, { Component } from "react";
import { connect } from "react-redux";
import { createAlert } from "../../../../actions/utils";
import { Wrapper, Row } from "../../../atoms/Layout";
import DatePickerV2 from "../../../atoms/DatePickerV2";
import Link from "../../../atoms/Link";
import Spinner from "../../../atoms/Spinner";
import DownloadButton from "../../../atoms/DownloadButton";
import Button from "../../../atoms/Button";
import Pagination from "../../../molecules/Pagination";

import { apiRequest, getLocaleDate, getLocalISODate, downloadFile as fileDownload } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";

interface ISubjectLinePerformanceProps {
    user: Mongo.clientAdmin;
    clients: Mongo.client[];
    client: Mongo.client;
}
interface ISubjectLinePerformanceState {
    clients: Mongo.client[];
    startDate: string;
    endDate: string;
    tomorrow: string;
    clientCode: string,
    rendering: boolean,
    rendered: boolean,
    subjectLinePerformanceSort: string;
    subjectLinePerformanceDirection: number;
    subjectLinePerformanceLimit: number;
    subjectLinePerformancePage: number;
    subjectLinePerformanceMaster: "loading" | dynamic[];
    subjectLinePerformanceTotal: number;
    subjectLinePerformance: "loading" | dynamic[];
}

class ECommunicationSubjectLinePerformanceView extends Component<ISubjectLinePerformanceProps, ISubjectLinePerformanceState> {
    constructor(props) {
        super(props);
        const end = new Date();
        const start = new Date();
        const tomorrow = new Date();
        start.setMonth(start.getMonth() - 1);
        tomorrow.setDate(tomorrow.getDate() + 2);
        this.state = {
            clients: props.user.isSuper ?
                Object.keys(props.clients || {}).map(cid => {
                    if (props.clients[cid].allowECommunication)
                        return props.clients[cid]
                }).filter(client => client != null) :
                [],
            startDate: `${getLocalISODate(start)}`,
            endDate: `${getLocalISODate(end)}`,
            tomorrow: `${getLocalISODate(tomorrow)}`,
            clientCode: props.user.cid || "",
            rendering: false,
            rendered: false,
            subjectLinePerformanceSort: "Sends",
            subjectLinePerformanceDirection: -1,
            subjectLinePerformanceLimit: 20,
            subjectLinePerformancePage: 0,
            subjectLinePerformanceMaster: "loading",
            subjectLinePerformanceTotal: 0,
            subjectLinePerformance: "loading"
        };
        this._fetchReport = this._fetchReport.bind(this);
        this._downloadData = this._downloadData.bind(this);
        this._renderGrid = this._renderGrid.bind(this);
    }

    _buildQueryFilters(props) {
        const queryFilters = {} as dynamic;
        if (props.startDate) {
            queryFilters.sd = props.startDate.substr(0, 10);
            // Important to and Thh:mm:ss.SSS since without the time specified it will assume UTC midnight instead of local
            queryFilters.sdtz = new Date(queryFilters["sd"] + "T00:00:00.000").getTimezoneOffset();
        }
        if (props.endDate) {
            queryFilters.ed = props.endDate.substr(0, 10);
            // Important to set time to end of local day since days on timezone transitions will have different timezone at end of day vs beginning
            queryFilters.edtz = new Date(queryFilters["ed"] + "T23:59:59.999").getTimezoneOffset();
        }
        if (props.clientCode) {
            queryFilters.clientCode = this.state.clientCode;
        }
        return queryFilters;
    }

    _onFieldChange(field, val) {
        const value = val && val.target ? val.target.value : val;
        this.setState({ ...this.state, [field]: value });
    }

    _clearFilters() {
        const end = new Date();
        const start = new Date();
        const tomorrow = new Date();
        start.setMonth(start.getMonth() - 1);
        tomorrow.setDate(tomorrow.getDate() + 1);

        this.setState({
            startDate: `${getLocalISODate(start)}`,
            endDate: `${getLocalISODate(end)}`,
            tomorrow: `${getLocalISODate(tomorrow)}`,
            clientCode: this.props.user.cid || "",
            rendered: false
        });
        createAlert('Filters have been cleared', 'success')
    }

    _fetchReport() {
        if (!this.state.clientCode) {
          createAlert(`Please select a client!`, `error`);
          return;
        }
        this.setState({ subjectLinePerformance: "loading", rendering: true }, () => {
          this._fetchData().then(data => {
            this.setState({
              subjectLinePerformanceMaster: data,
              subjectLinePerformanceTotal: data.length,
              rendering: false,
              rendered: true
            });
            createAlert('Report fetched successfully', 'success');
            this._sort(this.state.subjectLinePerformanceSort, this.state.subjectLinePerformanceDirection === 1 ? 1 : -1);
          }).catch(error => {
            createAlert('Failed to fetch report', 'error');
          });
        });
    }

    _fetchData(format = "json") {
        const filters = this._buildQueryFilters(this.state);
        return apiRequest(
            `${appConfig.API_URL}/e-communication/reports/subjectLinePerformance/${format}`, "Post", filters
        ).then(data => {
            if (data && typeof data === "object" && (data.error || ("valid" in data && !data.valid))) {
                throw data.error;
            }
            // tslint:disable-next-line: no-string-throw
            if (!data) throw "No data available for this report";
            return data;
        }).catch(error => {
            createAlert(error, `error`);
            return [];
        });
    }
    _downloadData() {
        return new Promise((resolve, reject) => {
            const headers = ["Subject Line", "Sends", "Opens", "Clicks", "Unsubscribes", "Open Rate", "Click Rate"];
            let data = '"' + headers.join("\",\"") + '"';
            for (const record of this.state.subjectLinePerformanceMaster) {
                let row = "\n\"" + record["Subject Line"] + "\"";
                for (let idx = 1; idx < headers.length; idx++) {
                    switch (headers[idx]) {
                        case "Open Rate":
                        case "Click Rate":
                            row += ",\"" + record[headers[idx]] + "\"";
                            break;
                        default:
                            row += "," + record[headers[idx]];
                            break;
                    }
                }
                data += row;
            }
            fileDownload(data, `subjectLinePerformance-${getLocaleDate(new Date())}.csv`);
            resolve(data);
        });
    }

    _sort(sortColumn: string, sortDirection: -1 | 1) {
        if (this.state.subjectLinePerformanceMaster && Array.isArray(this.state.subjectLinePerformanceMaster)) {
            const data = this.state.subjectLinePerformanceMaster.sort((a, b) => {
                let aVal = a[sortColumn];
                let bVal = b[sortColumn];
                switch (sortColumn.toLowerCase()) {
                    case "open rate":
                    case "click rate":
                        const aStr = aVal.slice(0, -1);
                        const bStr = bVal.slice(0, -1);
                        aVal = isNaN(parseInt(aStr, 10)) ? 0 : parseInt(aStr, 10);
                        bVal = isNaN(parseInt(bStr, 10)) ? 0 : parseInt(bStr, 10);
                        break;
                    default:
                        break;

                }
                return (aVal > bVal) ? (1 * sortDirection) : (-1 * sortDirection);
            });
            // restart page
            const subjectLinePerformancePage = 0;
            this.setState({
                subjectLinePerformance: data.slice(0, (this.state.subjectLinePerformanceLimit)),
                subjectLinePerformanceMaster: data,
                subjectLinePerformancePage,
                subjectLinePerformanceSort: sortColumn,
                subjectLinePerformanceDirection: sortDirection
            });
        }
    }
    _page(newPage = 0) {
        const start = this.state.subjectLinePerformanceLimit * newPage;
        const end = start + this.state.subjectLinePerformanceLimit;
        const data = this.state.subjectLinePerformanceMaster.slice(start, end);
        if (data && Array.isArray(data)) {
            this.setState({
                subjectLinePerformance: data,
                subjectLinePerformancePage: newPage
            });
        }
    }

    _renderSortIcons(column: string, defaultDirection: 1 | -1 = 1) {
        const sort = this.state.subjectLinePerformanceSort;
        const sortDirection = this.state.subjectLinePerformanceDirection;
        if (sort == column) {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={e => this._sort(column, sortDirection == 1 ? -1 : 1)}
                >
                    {column}
                    <i
                        className={`fa ${sortDirection == 1 ? "fa-sort-up" : "fa-sort-down"}`}
                        role="img"
                        tabIndex={0}
                        aria-label={`Sort ${sortDirection == 1 ? "ascending" : "descending"}`}
                    />
                </span>
            );
        }
        return (
            <span style={{ cursor: "pointer" }} onClick={e => this._sort(column, defaultDirection)}>
                {column}
                <i
                    className="fa fa-sort"
                    role="img"
                    tabIndex={0}
                    aria-label="Sort ascending or descending"
                />
            </span>
        );
    }

    _renderGrid() {
        const { rendering, rendered, subjectLinePerformance, subjectLinePerformancePage, subjectLinePerformanceTotal, subjectLinePerformanceLimit } = this.state;
        return (
            <div className="col-lg-12">
                <div className="ibox float-e-margins">
                    <div
                        className="ibox-title"
                        style={{
                            paddingTop: "7px",
                            display: "flex"
                        }}
                    >
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            Performance by Subject Line
                            {this.state.clientCode && rendered && (Array.isArray(subjectLinePerformance) && subjectLinePerformance.length > 0) && (
                                <span style={{ marginLeft: "auto" }}>
                                    <DownloadButton
                                        style={{
                                            marginLeft: "auto",
                                            marginBottom: "0",
                                            width: "auto",
                                            textAlign: "center"
                                        }}
                                        className="btn--sm"
                                        onClick={e => this._downloadData()}
                                    >
                                        <i className="fa fa-download" /> Download
                                    </DownloadButton>
                                </span>
                            )}
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ paddingTop: "0px" }}>
                        {rendering ? <div className="ibox-content"> <Spinner /> </div>
                            : !subjectLinePerformance || (Array.isArray(subjectLinePerformance) && subjectLinePerformance.length == 0)
                                ? <div style={{ marginTop: "20px" }}> <i>Could not find any data for this report</i> </div>
                                :
                                <div>
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}
                                    >
                                        <Pagination
                                            page={subjectLinePerformancePage}
                                            totalItems={subjectLinePerformanceTotal}
                                            numPerPage={subjectLinePerformanceLimit}
                                            changePage={newPage => this._page(newPage)}
                                            disabled={false}
                                            showTotal={false}
                                        />
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped responsive" data-type="responsive">
                                            <thead>
                                                <tr>
                                                    <th tabIndex={0}>Rank</th>
                                                    <th tabIndex={0}>Subject Line</th>
                                                    <th tabIndex={0}>{this._renderSortIcons("Sends", -1)}</th>
                                                    <th tabIndex={0}>{this._renderSortIcons("Opens", -1)}</th>
                                                    <th tabIndex={0}>{this._renderSortIcons("Clicks", -1)}</th>
                                                    {/* <th tabIndex={0}>{this._renderSortIcons("Bounces", -1)}</th> */}
                                                    <th tabIndex={0}>{this._renderSortIcons("Unsubscribes", -1)}</th>
                                                    <th tabIndex={0}>{this._renderSortIcons("Open Rate", -1)}</th>
                                                    <th tabIndex={0}>{this._renderSortIcons("Click Rate", -1)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subjectLinePerformance &&
                                                    Array.isArray(subjectLinePerformance) &&
                                                    subjectLinePerformance.length > 0 &&
                                                    subjectLinePerformance.map((template, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td tabIndex={0}>
                                                                    <span>{i + 1}</span>
                                                                </td>
                                                                <td tabIndex={0}>
                                                                    <span>{template["Subject Line"] || "N/A"}</span>
                                                                </td>
                                                                <td tabIndex={0}>
                                                                    <span>{template["Sends"] || "N/A"}</span>
                                                                </td>
                                                                <td tabIndex={0}>
                                                                    <span>{template["Opens"] || "N/A"}</span>
                                                                </td>
                                                                <td tabIndex={0}>
                                                                    <span>{template["Clicks"] || "N/A"}</span>
                                                                </td>
                                                                {/* <td tabIndex={0}>
                                                                    <span>{template["Bounces"] || "N/A"}</span>
                                                                </td> */}
                                                                <td tabIndex={0}>
                                                                    <span>{template["Unsubscribes"] || "N/A"}</span>
                                                                </td>
                                                                <td tabIndex={0}>
                                                                    <span>{template["Open Rate"] || "N/A"}</span>
                                                                </td>
                                                                <td tabIndex={0}>
                                                                    <span>{template["Click Rate"] || "N/A"}</span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}
                                    >
                                        <Pagination
                                            currItems={subjectLinePerformance ? subjectLinePerformance.length : 0}
                                            page={subjectLinePerformancePage}
                                            totalItems={subjectLinePerformanceTotal}
                                            numPerPage={subjectLinePerformanceLimit}
                                            changePage={newPage => this._page(newPage)}
                                            disabled={false}
                                            showTotal={true}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    _renderHeader() {
        const { startDate, endDate, tomorrow, clients, clientCode } = this.state;
        return (
            <div
                className="col-lg-9"
            >
                <div className="form-group">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            marginBottom: "10px"
                        }}
                    >
                        <div className="date__wrapper">
                            <label className="control-label">Start Date</label>
                            <DatePickerV2
                                dateFormat="yyyy/MM/dd"
                                maxDate={endDate}
                                value={startDate}
                                onChange={value => {
                                    if (!value) {
                                        const start = new Date();
                                        start.setMonth(start.getMonth() - 1);
                                        value = `${getLocalISODate(start)}T00:00:00.000Z`;
                                    }
                                    this._onFieldChange("startDate", value);
                                }}
                                onBlur={() => this._onFieldChange("startDate", "")}
                                enableTabLoop={false}
                            />
                        </div>
                        <div
                            className="date__wrapper"
                            style={{
                                marginLeft: "10px"
                            }}
                        >
                            <label className="control-label">End Date</label>
                            <DatePickerV2
                                dateFormat="yyyy/MM/dd"
                                maxDate={tomorrow}
                                minDate={startDate}
                                value={endDate}
                                onChange={value => {
                                    if (!value) {
                                        value = `${getLocalISODate(new Date())}T00:00:00.000Z`;
                                    }
                                    this._onFieldChange("endDate", value);
                                }}
                                onBlur={() => this._onFieldChange("endDate", "")}
                                enableTabLoop={false}
                            />
                        </div>
                        {
                            this.props.user.isSuper && (
                                <div
                                    style={{
                                        marginLeft: "10px"
                                    }}
                                >
                                    <label className="control-label">Client</label>
                                    <div
                                        className="select__wrapper"
                                        style={{
                                            marginLeft: "0px"
                                        }}
                                    >
                                        <select
                                            value={clientCode}
                                            onChange={event =>
                                                this._onFieldChange("clientCode", event.target.value)
                                            }
                                        >
                                            <option value="">Select a client</option>
                                            {
                                                clients &&
                                                clients.map(
                                                    client => {
                                                        return (
                                                            <option key={client.cid} value={client.cid}>
                                                                {client.name}
                                                            </option>
                                                        );
                                                    }
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                            )
                        }
                        <div>
                            <Button
                                onClick={async () => this._fetchReport()}
                                style={{ margin: "10px", top: "10px" }}
                            >
                                Search
                            </Button>
                        </div>
                        <div>
                            <label className="" />
                            <div style={{ margin: "0 10px 0 10px" }}>
                                <Link style={{ textDecoration: "underline", color: "#152733" }}
                                    onClick={() => this._clearFilters()}
                                >Clear Filter</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    _renderMessagePage() {
        return (
            <div style={{ width: "100%", display: "block", marginTop: "14%" }}>
                <p style={{ fontSize: 20, textAlign: "center", alignSelf: "end", fontWeight: "bold" }}>To run this report, please adjust the filters above and click "Search"</p>
                <p style={{ textAlign: "center", opacity: 0.5 }}>
                    <img src="/img/Reports_Load.svg" style={{ color: "#A7B1C2", opacity: 0.5 }} />
                </p>
            </div>
        );
    }


    render() {
        const { rendering, rendered } = this.state;
        return (
            <Wrapper id="subject-line-performance">
                <Row>{this._renderHeader()}</Row>
                <Row>{(!rendering && !rendered) && this._renderMessagePage()}</Row>
                <Row>{(rendering || rendered) && this._renderGrid()}</Row>
            </Wrapper>
        );
    }
}
const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});
export default connect(mapStateToProps)(ECommunicationSubjectLinePerformanceView);
