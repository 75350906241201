import React, { Component } from "react";
import { connect } from "react-redux";
import { createAlert } from "../../../actions";
import Multiselect from "react-bootstrap-multiselect";
import DatePickerV2 from "../../atoms/DatePickerV2";

import { getChartColours, getLocalISODate } from "../../../utils/Helpers";
import { doGetReduxClients, doGetGroups } from "../../../actions/lists";
import ContactsSection from "./sections/Contacts";
import SmsPerformanceReport from "./sections/SmsPerformanceReport";

import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import { Wrapper, Row } from "../../atoms/Layout";
import Spinner from "../../atoms/Spinner";

class SMSReportsView extends Component {
    constructor(props) {
        super(props);
        this._onFieldChange = this._onFieldChange.bind(this);
        this._renderReportSection = this._renderReportSection.bind(this);
        this.setGroupOptions = this.setGroupOptions.bind(this);
        this._changeGroups = this._changeGroups.bind(this);
        this.setAllowChange = this.setAllowChange.bind(this);
        this._submit = this._submit.bind(this);

        let end = new Date();
        let start = new Date();
        let tomorrow = new Date();
        start.setMonth(start.getMonth() - 1);
        tomorrow.setDate(tomorrow.getDate() + 2);

        this.state = JSON.parse(localStorage.getItem('smsQuery')) ? JSON.parse(localStorage.getItem('smsQuery')) : {
            startDate: `${getLocalISODate(start)}`,
            endDate: `${getLocalISODate(end)}`,
            tomorrow: `${getLocalISODate(tomorrow)}`,

            hasGroups: false,
            groupOpts: null,
            gids:
                !(props.user.isClientAdmin || props.user.isSuper || props.user.isSuperClientAdmin) &&
                    props.user.groups &&
                    props.user.groups.length > 0
                    ? props.user.groups
                    : "",
            rids: "ALL_ROLES",
            clientCode: props.user.cid || (props.user.isSuper ? "ALL_CLIENTS" : ""),
            submittedClientCode: props.user.cid || (props.user.isSuper ? "ALL_CLIENTS" : ""),
            allowChange: false,
            options: {
                maintainAspectRatio: false,
                responsive: true,
                height: 400
            },
            lineOptions: {
                maintainAspectRatio: false,
                responsive: true,
                height: 400,
                tooltips: {
                    mode: "index",
                    intersect: false,
                    callbacks: {
                        label: function (t, d) {
                            return t.yLabel.toString() + " " + d.datasets[t.datasetIndex].label.toString();
                        }
                    }
                }
            },
            reportSection: (props.match.params.section || "smsperformance").toLowerCase(),
            chartColors: getChartColours(),
            backgroundColors: getChartColours(true),
            showSearchResults: false
        };
        this.state.filters = this._buildQueryFilters(this.state);
        this.state.submittedFilters = this.state.filters;
    }

    componentDidMount() {
        if (this.state.clientCode === "ALL_CLIENTS") {
            doGetReduxClients();
        } else if (!this.state.groupOpts && this.state.clientCode) {
            doGetGroups(this.state.clientCode).then(this.setGroupOptions);
        }
    }
    setGroupOptions(groups) {
        let hasGroups = false;
        let hasAllGroups =
            this.props.user.isClientAdmin ||
            this.props.user.isSuperClientAdmin ||
            this.props.user.isSuper ||
            (this.props.user.groups || []).length == 0;
        let gids = this.state.gids || "";
        let groupOpts = [];
        if (Object.keys(groups).length) {
            hasGroups = true;
            if (hasAllGroups) {
                groupOpts.push({ value: "", label: "All", selected: true });
                groupOpts.push({ value: "None", label: "No Group", selected: false });
                groupOpts.push({ value: "allGroup", label: "All Groups", selected: false });
            } else {
                let userGroups = this.props.user.groups || [];
                // Filter for groups the user has access to
                for (let gid in groups) {
                    if (userGroups.indexOf(gid) === -1) {
                        delete groups[gid];
                    }
                }
            }
            let options = [];
            let selectedCount = 0;
            for (var gid in groups) {
                let selected = !hasAllGroups;
                options.push({ value: gid, label: groups[gid], selected: selected });
                selectedCount += selected ? 1 : 0;
            }
            gids = options
                .filter(opt => opt.selected)
                .map(opt => opt.value)
                .join(",");
            groupOpts.push({
                label: `Select groups (${selectedCount} Selected)`,
                children: options
            });
        }
        this.setState({ groupOpts: groupOpts, gids: gids, hasGroups: hasGroups });
    }
    componentWillReceiveProps(newProps) {
        if (newProps.match.params.section != this.state.reportSection) {
            let newSection = newProps.match.params.section;
            if (!newSection) {
                newSection = "smsperformance";
            }
            var filters = this._buildQueryFilters({ ...this.state, reportSection: newSection });
            this.setState({ filters, submittedFilters: filters, reportSection: newSection.toLowerCase(), queryLoading: false });
        }
    }

    _onFieldChange(field, val) {
        if (field == "clientCode") {
            doGetGroups(val).then(this.setGroupOptions);
        }
        const value = val && val.target ? val.target.value : val;
        const filters = this._buildQueryFilters({ ...this.state, [field]: value });
        this.setState({
            filters,
            [field]: value
        });
    }

    async _submit() {
        // force the load screen to show as soon as submit occurs
        this.setState({ showSearchResults: false });
        await this.setState({
          queryLoading: this.state.filters !== this.state.submittedFilters || !this.state.showSearchResults,
          submittedFilters: this.state.filters,
          submittedClientCode: this.state.clientCode,
          showSearchResults: true,
        }, () => {
          localStorage.setItem('smsQuery', JSON.stringify(this.state));
        });
        createAlert('Search results updated', 'success');
    }

    async _clearFilters() {
        let end = new Date();
        let start = new Date();
        let tomorrow = new Date();
        start.setMonth(start.getMonth() - 1);
        tomorrow.setDate(tomorrow.getDate() + 2);

        await this.setState({
            startDate: `${getLocalISODate(start)}`,
            endDate: `${getLocalISODate(end)}`,
            tomorrow: `${getLocalISODate(tomorrow)}`,
            hasGroups: false,
            groupOpts: null,
            gids:
                !(this.props.user.isClientAdmin || this.props.user.isSuper || this.props.user.isSuperClientAdmin) &&
                    this.props.user.groups &&
                    this.props.user.groups.length > 0
                    ? this.props.user.groups
                    : "",
            rids: "ALL_ROLES",
            clientCode: this.props.user.cid || (this.props.user.isSuper ? "ALL_CLIENTS" : ""),
            submittedClientCode: this.props.user.cid || (this.props.user.isSuper ? "ALL_CLIENTS" : ""),
            allowChange: false,
            showSearchResults: false,
        }, () => localStorage.removeItem('smsQuery'));
        this.state.filters = this._buildQueryFilters(this.state);
        this.state.submittedFilters = this.state.filters;
        if (this.userInput && this.userInput.value) {
            this.userInput.value = "";
        }
        // get the clients and groups
        if (this.state.clientCode === "ALL_CLIENTS") {
            doGetReduxClients();
        } else if (!this.state.groupOpts && this.state.clientCode) {
            doGetGroups(this.state.clientCode).then(this.setGroupOptions);
        }
         createAlert('Filters have been cleared', 'success');
    }

    _buildQueryFilters(props) {
        let queryFilters = {};
        if (props.startDate) {
            queryFilters.sd = props.startDate.substr(0, 10);
            // Important to and Thh:mm:ss.SSS since without the time specified it will assume UTC midnight instead of local
            queryFilters.sdtz = new Date(queryFilters["sd"] + "T00:00:00.000").getTimezoneOffset();
        }
        if (props.endDate) {
            queryFilters.ed = props.endDate.substr(0, 10);
            // Important to set time to end of local day since days on timezone transitions will have different timezone at end of day vs beginning
            queryFilters.edtz = new Date(queryFilters["ed"] + "T23:59:59.999").getTimezoneOffset();
        }
        if (props.clientCode) {
            queryFilters.clientCode = props.clientCode;
        }
        if (props.gids) {
            queryFilters.grp = props.gids;
        }
        if (props.rids) {
            queryFilters.rids = props.rids;
        }
        return queryFilters;
    }

    _changeGroups(event) {
        let groupOpts = this.state.groupOpts;

        var allOpt = groupOpts[0].value === "" ? groupOpts[0] : null;
        var noOpt = groupOpts[1] && groupOpts[1].value === "None" ? groupOpts[1] : null;
        var allGroups = groupOpts[2] && groupOpts[2].value === "allGroup" ? groupOpts[2] : null;
        var optionGroup = groupOpts.find(opt => opt.children);

        let selectedCount = optionGroup ? optionGroup.children.filter(opt => opt.selected).length : 0;
        // Deselect 'ALL' if it wasn't clicked otherwise always set (no toggle)
        if (allOpt) allOpt.selected = event[0].value === "";
        if (noOpt) noOpt.selected = event[0].value === "None";
        if (allGroups) allGroups.selected = event[0].value === "allGroup";
        // If ALL selected then nothing else should be selected
        if (allOpt && allOpt.selected) {
            // ALL
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (noOpt && noOpt.selected) {
            // No Group
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (allGroups && allGroups.selected) {
            // All Groups
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = true;
            }
            selectedCount = optionGroup.children.length;
        } else {
            optionGroup.children.find(opt => opt.value === event[0].value).selected = event[0].selected;
            selectedCount += event[0].selected ? 1 : -1;
            if (allGroups && selectedCount === optionGroup.children.length) allGroups.selected = true;
        }
        // If nothing selected then ALL should be selected (toggles ALL back on if someone deselected it)
        if (selectedCount === 0) {
            if (allOpt && allOpt.selected) {
                allOpt.selected = true;
            } else if (noOpt && noOpt.selected) {
                allOpt.selected = false;
                noOpt.selected = true;
                allGroups.selected = false;
            } else {
                optionGroup.children[0].selected = true;
                selectedCount = 1;
            }
        }
        optionGroup.label = `Select groups (${selectedCount} Selected)`;
        let gids = optionGroup.children
            .filter(opt => opt.selected)
            .map(opt => opt.value)
            .join(",");
        if (!gids) {
            if (noOpt && noOpt.selected) {
                gids = "none";
            }
        }
        const filters = this._buildQueryFilters({ ...this.state, gids: gids });
        this.setState({ groupOpts: groupOpts, gids, filters });
        this.refs.groups.syncData();
    }

    render() {
        let clients = Object.values(this.props.clients);

        return (
            <Wrapper>
                <Row style={{ position: "relative" }}>
                    {this.state.queryLoading && (
                        <div className="report-loading-backdrop">
                            <div className="report-loading-backdrop__inner">
                                <div className="report-loading-backdrop__spinner">
                                    <p>Please wait a moment while we process your request.</p>
                                    <p>If you leave this page, your request will be cancelled.</p>
                                    <Spinner />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-lg-12">
                        <div className="form-group">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    marginBottom: "10px"
                                }}
                            >
                               <div className="date__wrapper">
                                    <label className="control-label" htmlFor="startDate">Start Date</label>
                                    <DatePickerV2
                                        dateFormat="yyyy/MM/dd"
                                        maxDate={this.state.endDate}
                                        value={this.state.startDate}
                                        id="startDate"
                                        onChange={value => {
                                            if (!value) {
                                                let start = new Date();
                                                start.setMonth(start.getMonth() - 1);
                                                value = `${getLocalISODate(start)}T00:00:00.000Z`;
                                            }
                                            this._onFieldChange("startDate", value);
                                        }}
                                        disabled={this.state.allowChange}
                                        onBlur={() => this._onFieldChange("startDate", "")}
                                    />
                                </div>
                                <div
                                    className="date__wrapper"
                                    style={{
                                        marginLeft: "10px"
                                    }}
                                >
                                    <label className="control-label" htmlFor="endDate">End Date</label>
                                    <DatePickerV2
                                        dateFormat="yyyy/MM/dd"
                                        maxDate={this.state.tomorrow}
                                        minDate={this.state.startDate}
                                        value={this.state.endDate}
                                        id="endDate"
                                        onChange={value => {
                                            if (!value) {
                                                let end = new Date();
                                                value = `${getLocalISODate(end)}T00:00:00.000Z`;
                                            }
                                            this._onFieldChange("endDate", value);
                                        }}
                                        disabled={this.state.allowChange}
                                        onBlur={() => this._onFieldChange("endDate", "")}
                                    />
                                </div>
                                {this.props.user.isSuper && (
                                    <div
                                        style={{
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <label className="control-label">Client</label>
                                        <div
                                            className="select__wrapper"
                                            style={{
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <select
                                                id="clients"
                                                value={this.state.clientCode}
                                                onChange={event =>
                                                    this._onFieldChange("clientCode", event.target.value)
                                                }
                                                disabled={this.state.allowChange}
                                            >
                                                <option value="ALL_CLIENTS">All Clients</option>
                                                {this.state.reportSection !== "notifications" && (
                                                    <option value="ALL_CONSUMERS">All Consumers</option>
                                                )}
                                                <option disabled="disabled">-----------</option>
                                                {clients &&
                                                    clients.map(
                                                        function (client, i) {
                                                            return (
                                                                <option key={client.cid} value={client.cid}>
                                                                    {client.name}
                                                                </option>
                                                            );
                                                        }.bind(this)
                                                    )}
                                            </select>
                                        </div>
                                    </div>
                                )}
                                {this.state.hasGroups && (
                                    <div
                                        style={{
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <label className="control-label">Groups</label>
                                        <div
                                            className="date_multiselect_wrapper"
                                            style={{
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <Multiselect
                                                ref="groups"
                                                data={this.state.groupOpts}
                                                maxHeight={200}
                                                multiple="multiple"
                                                onChange={this._changeGroups}
                                                disabled={this.state.allowChange}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.clientCode &&
                                    this.state.clientCode !== "ALL_CLIENTS" &&
                                    this.props.clients &&
                                    this.props.clients[this.state.clientCode] &&
                                    this.props.clients[this.state.clientCode].roles &&
                                    Object.keys(this.props.clients[this.state.clientCode].roles).length && (
                                        <div
                                            style={{
                                                marginLeft: "10px"
                                            }}
                                        >
                                            <label className="control-label">Role</label>
                                            <div
                                                className="select__wrapper"
                                                style={{
                                                    marginLeft: "0px"
                                                }}
                                            >
                                                <select
                                                    onChange={e => this._onFieldChange("rids", e.target.value)}
                                                    value={this.state.rids}
                                                    aria-label="Role"
                                                >
                                                    <option value="ALL_ROLES">All</option>
                                                    <option disabled="disabled">-----------</option>
                                                    {Object.keys(this.props.clients[this.state.clientCode].roles).map(
                                                        rids => (
                                                            <option key={rids} value={rids}>
                                                                {
                                                                    this.props.clients[this.state.clientCode].roles[rids]
                                                                        .name
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                <div style={{ marginLeft: "10px" }}>
                                    <label className="" />
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            onClick={() => this._submit()}
                                            style={{ marginLeft: "auto", marginRight: "20px" }}
                                            disabled={this.state.queryLoading}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <label className="" />
                                    <div style={{ display: "flex" }}>
                                        <Link style={{ textDecoration: "underline", color: "#152733" }}
                                            onClick={() => this._clearFilters()}
                                        >Clear Filter</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this._renderReportSection()}
                </Row>
            </Wrapper>
        );
    }

    setAllowChange(val) {
        this.setState({ allowChange: !val });
    }

    renderMessagePage() {
        return (
            <div style={{ width: "100%", display: "block", marginTop: "14%" }}>
                <p style={{ fontSize: 20, textAlign: "center", alignSelf: "end", fontWeight: "bold" }}>To run this report, please adjust the filters above and click "Search"</p>
                <p style={{ textAlign: "center", opacity: "50%" }}>
                    <img src="/img/Reports_Load.svg" style={{ color: "#A7B1C2", opacity: "50%" }} />
                </p>
            </div>
        );
    }

    _renderReportSection() {
        const { reportSection, submittedFilters, options, lineOptions, chartColors, backgroundColors, showSearchResults } = this.state;
        const client = this.state.submittedClientCode ? this.props.clients[this.state.submittedClientCode] : null;
        if (!showSearchResults) {
            return this.renderMessagePage();
        }
        if (reportSection == "contacts") {
            return (
                <ContactsSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    lineOptions={lineOptions}
                    options={options}
                    altChartColors={chartColors}
                    chartColors={getChartColours(false, false)}
                    backgroundColors={backgroundColors}
                    client={client}
                    setAllowChange={this.setAllowChange}
                />
            );
        } else if (reportSection == "smsperformance") {
            return (
                <SmsPerformanceReport
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false , showSearchResults : true })}
                    client={client}
                />
            );
        }
        else {
            return (
                <Wrapper>
                    <Row style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap" }}>
                        <h4>You do not have permission to access this page</h4>
                    </Row>
                </Wrapper>
            );
        }
    }
}

const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients });

export default connect(mapStateToProps)(SMSReportsView);
