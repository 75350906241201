import React from "react";
import { connect } from "react-redux";
import Button from "../atoms/Button";
import Tooltip from "../atoms/Tooltip";
import { createAlert } from "../../actions/utils";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirming: false,
            cancelling: false,
            unscheduleing: false,
            approving: false,
            rejecting: false
        };
    }
    confirm() {
        if (this.props.confirmFunction) {
            this.setState({ confirming: true }, async () => {
                try {
                    await this.props.confirmFunction();
                    this.setState({ confirming: false });
                } catch (data) {
                    createAlert(data, "error");
                    this.setState({ confirming: false });
                }
            });
        }
    }
    cancel() {
        if (this.props.cancelFunction) {
            this.setState({ cancelling: true }, async () => {
                try {
                    await this.props.cancelFunction();
                    this.setState({ cancelling: false });
                } catch (data) {
                    createAlert(data, "error");
                    this.setState({ cancelling: false });
                }
            });
        }
    }
    unschedule() {
        if (this.props.unscheduleFunction) {
            this.setState({ unscheduleing: true }, async () => {
                try {
                    await this.props.unscheduleFunction();
                    this.setState({ unscheduleing: false });
                } catch (data) {
                    createAlert(data, "error");
                    this.setState({ unscheduleing: false });
                }
            });
        }
    }
    approve() {
        if (this.props.approveFunction) {
            this.setState({ approving: true }, async () => {
                try {
                    await this.props.approveFunction();
                    this.setState({ approving: false });
                } catch (data) {
                    createAlert(data, "error");
                    this.setState({ approving: false });
                }
            });
        }
    }
    reject() {
        if (this.props.rejectFunction) {
            this.setState({ rejecting: true }, async () => {
                try {
                    await this.props.rejectFunction();
                    this.setState({ rejecting: false });
                } catch (data) {
                    createAlert(data, "error");
                    this.setState({ rejecting: false });
                }
            });
        }
    }
    render() {
        return (
            <div className="footer fixed">
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <div>
                        <span>
                            <strong>Copyright</strong> | LiveSocial &copy;{new Date().getFullYear()}
                        </span>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        {this.props.rejectFunction && (
                            <Button
                                id ="rejectFeedRequest"
                                style={{ marginRight: "20px " }}
                                disabled={this.state.rejecting}
                                onClick={() => this.reject()}
                            >
                                Reject
                            </Button>
                        )}
                        {this.props.approveFunction && (
                            <Button
                                id ="approveFeedRequest"
                                style={{ marginRight: "20px " }}
                                disabled={this.state.approving}
                                onClick={() => this.approve()}
                            >
                                Approve
                            </Button>
                        )}
                        {this.props.unscheduleFunction && this.props.confirmText == "Update" && (
                            <Button
                                id ="unscheduleArticle"
                                style={{ marginRight: "20px " }}
                                disabled={this.state.unscheduleing}
                                onClick={() => this.unschedule()}
                            >
                                {this.props.unscheduleText}
                                <Tooltip style={{ paddingLeft: "5px" }} place="top">
                                    <p>By clicking this link, all currently scheduled shares of the selected article will be unscheduled.</p>
                                    <p>This article will be deleted from the library and will also be removed from the view of all users.</p>
                                    <p>Note that this will not remove shares that have already been posted on social networks.</p>
                                </Tooltip>
                            </Button>
                        )}
                        {this.props.cancelFunction && (
                            <Button
                                id ="deleteArticle"
                                style={{ marginRight: "20px " }}
                                onClick={() => this.cancel()}
                                disabled={this.state.cancelling}
                            >
                                {this.props.cancelText}
                            </Button>
                        )}
                        {this.props.confirmFunction && (
                            <Button id ="updateArticle" onClick={() => this.confirm()} disabled={this.state.confirming}>
                                {this.props.confirmText}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    confirmFunction: state.utils.confirmFunction,
    cancelFunction: state.utils.cancelFunction,
    confirmText: state.utils.confirmText,
    cancelText: state.utils.cancelText,
    unscheduleFunction: state.utils.unscheduleFunction,
    unscheduleText: state.utils.unscheduleText,
    approveFunction: state.utils.approveFunction,
    rejectFunction: state.utils.rejectFunction
});

export default connect(mapStateToProps)(Footer);
