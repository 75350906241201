import React, { Component } from "react";
import { createAlert } from "../../actions/utils";
import { doGetQuotes, AdminActions } from "../../actions";
import Modal from 'react-bootstrap-modal';
import Button from "../atoms/Button";

interface IaiGenCommentsRadioBtns {
    personality: { prof: boolean, serious: boolean, funny: boolean, enthus: boolean };
    hashtag: { none: boolean, some: boolean };
    length: { short: boolean, med: boolean, long: boolean };
    savePref?: boolean;
}
interface IQuotes {
    quote: string;
    hashtags: Array<string>;
}

interface IGenerateAICommentsProps {
    disabled?: boolean;
    loadingQuotes?: boolean;
    className: string;
    open: boolean;
    changePref?: boolean;
    close: () => void;
    client: Mongo.IClient;
    link: string,
    sid: string,
    setQuote: (quote: any) => void;
    quotes?: Array<IQuotes>;
    setComment: (comment: string) => void;
    checked?: boolean;
    aiCommentPref: Mongo.ClientAdmin["aiCommentPref"] | null;
    hasSavedAICmntPref?: boolean;
    saveAICommentPref?: boolean;
    radioBtns?: IaiGenCommentsRadioBtns;
    comment: string;
    selectedCmnt: number | undefined;
    hasComment: boolean,
    confirmReplace: boolean;
    noCommentAvail: boolean;
}
export default class GenerateAIComments extends Component<IGenerateAICommentsProps, { open: boolean; changePref: boolean, loadingQuotes: boolean; quotes: Array<string>, radioBtns: IaiGenCommentsRadioBtns, aiCommentPref: Mongo.ClientAdmin["aiCommentPref"] | null, saveAICommentPref: boolean, comment: string, selectedCmnt: number | undefined, hasComment: boolean, confirmReplace: boolean, noCommentAvail: boolean }> {

    constructor(props: IGenerateAICommentsProps
    ) {
        super(props);
        this.close = this.close.bind(this);
        this.generateComment = this.generateComment.bind(this);
        this.state = {
            open: props.open || false,
            changePref: props.changePref || true,
            loadingQuotes: false,
            quotes: [],
            radioBtns: {
                personality: {
                    prof: true,
                    serious: false,
                    funny: false,
                    enthus: false
                },
                hashtag: {
                    none: true,
                    some: false
                },
                length: {
                    short: true,
                    med: false,
                    long: false
                },
                savePref: false
            },
            aiCommentPref: props.aiCommentPref || null,
            saveAICommentPref: false,
            comment: "",
            selectedCmnt: undefined,
            hasComment: props.hasComment || false,
            confirmReplace: false,
            noCommentAvail: false
        };
    }

    async setCommentPref() {
        const { radioBtns } = this.state;

        const savePref = radioBtns.savePref;
        delete radioBtns.savePref;

        let tempObj = {} as Mongo.ClientAdmin["aiCommentPref"];
        for (const [category, type] of Object.entries(radioBtns)) {
            const selectedType = Object.keys(type).find(key => type[key]);
            if (selectedType) {
            tempObj![category] = selectedType;
            }
        }

        if (savePref) {
            await AdminActions.doSaveAICommentPref(tempObj);
        }

        this.setState({ aiCommentPref: tempObj });
    }

    async generateComment(regen: boolean) {
        this.setState({ loadingQuotes: true });
        if (!regen) await this.setCommentPref();
        const suggestedQuotes = await doGetQuotes(
            this.props.link,
            this.props.sid && this.props.client.streams && this.props.client.streams[this.props.sid] ? this.props.client.streams[this.props.sid].lang : "en",
            this.props.client.aiGeneratedAdminSuggestionsWithChatGPT,
            this.props.client.cid,
            this.state.aiCommentPref || undefined
        );
        const quotes = suggestedQuotes && !("valid" in suggestedQuotes) && suggestedQuotes.numFound
        ? suggestedQuotes.items.map(i => i.quote)
        : [];
        if (!quotes.length) {
            this.setState({ loadingQuotes: false, noCommentAvail: true });
        } else {
            this.props.setQuote(quotes);
            this.setState({ loadingQuotes: false, quotes })
        }
    }

    setAICommentPref() {
        let tempObj = this.state.radioBtns;
        for (const [category, type] of Object.entries(this.state.aiCommentPref!)) {
            Object.keys(this.state.radioBtns).forEach(set => {
                if (set == category) {
                    Object.keys(this.state.radioBtns[set]).forEach(key => {
                        if (type == key) {
                            tempObj[set][key] = true;
                        } else {
                            tempObj[set][key] = false;
                        }
                    })
                }
            })
        }
        this.setState({ radioBtns: tempObj })
    }

    componentWillReceiveProps(newProps) {
        if (("open" in newProps && newProps.open !== this.state.open) && ("changePref" in newProps && newProps.changePref !== this.state.changePref) && this.state.aiCommentPref) {
            this.generateComment(false);
            this.setState({ open: newProps.open, hasComment: newProps.hasComment });
        } else if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open, hasComment: newProps.hasComment });
        }
        if (this.state.aiCommentPref) this.setAICommentPref()
    }

    close() {
        this.setState({ open: false, quotes: [] });
        if (this.props.close) {
            this.props.close();
        }
    }

    onChange(ev: any) {
        const { title, value } = ev.target;
        let btnObj = this.state.radioBtns;
        let sPref = this.state.radioBtns.savePref;
        if (value != "savePref") {
            Object.keys(this.state.radioBtns[title]).forEach(key => {
                if (value == key) {
                    btnObj[title][key] = true;
                } else {
                    btnObj[title][key] = false;
                }
            })
            this.setState({ radioBtns: { ...this.state.radioBtns, ...btnObj }  })
        } else {
            sPref = !sPref;
            this.setState({radioBtns: { ...this.state.radioBtns, savePref: sPref } })
        }
        return;
    }

    setComment() {
        const { comment, hasComment, confirmReplace } = this.state;
        if (!comment) {
            createAlert("Please select a comment", `error`);
            return;
        }
        if (hasComment && !confirmReplace) {
            this.setState({ confirmReplace: true});
            return;
        } else {
            this.props.setComment(comment);
            this.close();
        }

    }

    setStateSetComment() {
        this.setState({ confirmReplace: false })
        this.setComment();
    }

    _renderQuotes() {
       const { quotes, selectedCmnt } = this.state;
        return (
            <div>
                {quotes.map((quote, index) => {
                    return (
                        <ul key={index} style={{ marginTop: "1em", border: "1px solid lightgrey", borderRadius: "10px", listStyleType: "none", paddingLeft: "15px" }}>
                            <div onClick={() => this.setState({ comment: quote, selectedCmnt: index })} style={{ cursor: "pointer" }}>
                                <div>
                                    <Button key={index} type="radio" title={`comment${index}`} value={index.toString()} checked={(selectedCmnt != undefined ? index == selectedCmnt : false)} style={{ float: 'left', marginTop: "20px", width: "15px", height: "15px" }}></Button>
                                </div>
                                <div style={{ paddingLeft: "10px" }}><li style={{ padding: "16px" }}>{quote}</li></div>
                            </div>
                        </ul>
                    )
                })}
                <div>
                    <span onClick={() => this.generateComment(true)} style={{ float: "right", padding: "0px 15px 15px 0", cursor: "pointer"}} ><img style={{ width: "20px", marginRight: "5px", transform: "scaleX(-1)" }} src="/img/Redo-loop.svg"></img>Regenerate</span>
                    <div style={{ backgroundColor: "lightgray", borderRadius: "5px", width: "50%"}} >Aura is AI powered. Please use with caution.</div>
                </div>
            </div>
        );
    }

    render() {
        const { open, loadingQuotes, radioBtns, quotes, confirmReplace, noCommentAvail } = this.state;
        if (noCommentAvail) {
            return (
                <Modal
                    show={open}
                    className={this.props.className}
                    onHide={this.close}
                    aria-labelledby="ModalHeader"
                >
                    <Modal.Header>
                        <Modal.Title id='ModalHeader'>Unable to generate comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div>We were unable to generate a comment from the link you provided. Try another link.</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.Dismiss className="btn brandPrimary--bg">Close</Modal.Dismiss>
                    </Modal.Footer>
                </Modal>
            )
        }
        if (confirmReplace) {
            return (
                <Modal
                    show={open}
                    className={this.props.className}
                    onHide={this.close}
                    aria-labelledby="ModalHeader"
                >
                    <Modal.Header closeButton="closeButton">
                        <Modal.Title id='ModalHeader'>Replace your current comment?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div>Saving this comment will replace the one you currently have.</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.Dismiss className="btn brandPrimary--bg" onClick={() => this.setState({ confirmReplace: false })}>Cancel</Modal.Dismiss>
                        {quotes.length > 0 && (<Button type="submit" onClick={() => this.setStateSetComment()}>Save</Button>)}
                    </Modal.Footer>

                </Modal>
            )
        }
        return (
            <Modal
                show={open}
                className={this.props.className}
                onHide={this.close}
                aria-labelledby="ModalHeader"
            >
                <Modal.Header closeButton="closeButton">
                    <Modal.Title id='ModalHeader'>Generate a comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div>
                            <div>
                                {loadingQuotes && (
                                    <div style={{ height: "50vh", textAlign: "center" }}>
                                        <span>
                                            <img
                                                className="loadingFadeInOut"
                                                style={{ width: "25%", marginTop: "25%" }}
                                                src="/img/stars.svg"
                                            ></img>
                                        </span>

                                    </div>
                                )}
                                {!loadingQuotes && quotes?.length > 0 && (
                                    this._renderQuotes()
                                )}
                                {!loadingQuotes && !quotes?.length && (
                                    <div>
                                        <div style={{ float: "left", clear: "none", marginBottom: "15px" }}>
                                            <p className="heading">Personality</p>
                                            <p className="subheading">Set the tone of your content.</p>
                                            <Button className="radioSelOption" type="radio" title="personality" value="prof" onClick={e => this.onChange(e)} checked={radioBtns.personality.prof} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">Professional</label>
                                            <Button className="radioSelOption" type="radio" title="personality" value="serious" onClick={e => this.onChange(e)} checked={radioBtns.personality.serious} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">Serious</label>
                                            <Button className="radioSelOption" type="radio" title="personality" value="funny" onClick={e => this.onChange(e)} checked={radioBtns.personality.funny} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">Funny</label>
                                            <Button className="radioSelOption" type="radio" title="personality" value="enthus" onClick={e => this.onChange(e)} checked={radioBtns.personality.enthus} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">Enthusiastic</label>
                                        </div>
                                        <div style={{ float: "left", clear: "both", marginBottom: "15px" }}>
                                            <p className="heading">Hashtag usage</p>
                                            <p className="subheading">Add hashtags to this content.</p>
                                            <Button className="radioSelOption" type="radio" title="hashtag" value="none" onClick={e => this.onChange(e)} checked={radioBtns.hashtag.none} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">No</label>
                                            <Button className="radioSelOption" type="radio" title="hashtag" value="some" onClick={e => this.onChange(e)} checked={radioBtns.hashtag.some} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">Yes</label>
                                        </div>
                                        <div style={{ float: "left", clear: "both" }}>
                                            <p className="heading">Length</p>
                                            <p className="subheading">Choose the length of this comment. This will be within the character restrictions of your selected network(s).</p>
                                            <Button className="radioSelOption" type="radio" title="length" value="short" onClick={e => this.onChange(e)} checked={radioBtns.length.short} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">Short</label>
                                            <Button className="radioSelOption" type="radio" title="length" value="med" onClick={e => this.onChange(e)} checked={radioBtns.length.med} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">Medium</label>
                                            <Button className="radioSelOption" type="radio" title="length" value="long" onClick={e => this.onChange(e)} checked={radioBtns.length.long} style={{ float: 'left' }}></Button>
                                            <label className="radioLabel">Long</label>
                                        </div>
                                        <div style={{ float: "left", clear: "both", width: "50%", margin: "30px 0px" }}>
                                            <Button type="radioBox" className="box" value="savePref" onClick={e => this.onChange(e)} checked={radioBtns.savePref} style={{ float: 'left', marginRight: "8px" }}></Button>
                                            <div style={{ fontSize: "14px", fontWeight: 400, lineHeight: "23px" }}>Save these preferences for next time</div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{ clear: "both" }}>
                    {!loadingQuotes && quotes?.length > 0 && !confirmReplace && (
                        <Button className="btn brandPrimary--bg" style={{ float: "left" }} onClick={() => this.setState({ quotes: [] })}>Back</Button>
                    )}
                   <Modal.Dismiss className="btn brandPrimary--bg">Cancel</Modal.Dismiss>
                    {!loadingQuotes && !quotes.length && (<Button type="submit" onClick={() => this.generateComment(false)}>
                        <img style={{ marginRight: "3px", filter: "brightness(0) invert(1)" }} src="/img/stars.svg"/>Generate</Button>)}
                    {loadingQuotes && !quotes.length && (<Button disabled type="submit" onClick={() => this.generateComment(false)}>
                        <img style={{ marginRight: "3px", filter: "brightness(0) invert(1)" }} src="/img/stars.svg"/>Generate</Button>)}
                    {!loadingQuotes && quotes.length > 0 && (<Button type="submit" onClick={() => this.setComment()}>Save</Button>)}
                </Modal.Footer>

            </Modal>
        );
    }
}