import React, { useState } from "react";
import { connect } from "react-redux";
import { DelegateActions } from "../../../actions";
import Tooltip from "../../atoms/Tooltip";
import Button from "../../atoms/Button";
import ElementListBox, { PaginationData } from "../../molecules/ElementListBox";
import { getBooleanIcon } from "../../../utils";
import Spinner from "../../atoms/Spinner";
import { Wrapper, Row } from "../../atoms/Layout/";
interface IDelegateListProps {
    user: Mongo.clientAdmin;
}
const DelegateApproved = (props: IDelegateListProps) => {
    const { cid } = props.user;
    const gids = !(props.user.isClientAdmin || props.user.isSuper || props.user.isSuperClientAdmin) &&
        props.user.groups && props.user.groups.length > 0
        ? props.user.groups : null;

    const [loading, setLoading] = useState(false);

    const pseudoRefreshPage = () => {
        setLoading(true);
        setTimeout(() => setLoading(false));
    };

    const refresh = async (cid: string, search: string, paginationData: PaginationData) => {
        try {
            const data = await DelegateActions.doList(
                cid,
                gids,
                search,
                paginationData.limit,
                paginationData.skip,
                paginationData.sort
            );
            if (data && data.count && data.items) {
                return { total: data.count, items: data.items };
            }
        } catch (err) {
            //
        }
        return { items: [], total: 0 };
    };

    if (loading) {
        return <Spinner />;
    }
    return (
        <Wrapper id="approveddelegates">
            <Row>
                <ElementListBox<ServerTypes.Console.IDelegate>
                    title="Approved Delegates"
                    refresh={({ cid, search }, paginationData) => refresh(cid, search, paginationData)}
                    filterSearch={true}
                    filterCid={props.user.isSuper}
                    download={({ cid }) => DelegateActions.doExportCSV(cid, gids)}
                    mutateItem={item => ({
                        firstName: item.firstName,
                        lastName: item.lastName,
                        directSharing:
                            item.advisorEmailMap &&
                            Object.values(item.advisorEmailMap).filter(v => v.allowSuperPublisher).length ? (
                                <div>
                                    Bypass Approval&nbsp;
                                    <Tooltip>
                                        This delegate has the ability to share directly to at least one of their
                                        publisher’s social networks.
                                    </Tooltip>
                                </div>
                            ) : (
                                <div></div>
                            ),
                        email: item.email,
                        createdOn: `${new Date(item.createdOn).getFullYear()}-${new Date(item.createdOn).getMonth() +
                            1}-${new Date(item.createdOn).getDate()}`
                    })}
                    keys={{
                        ...(props.user.isSuper ? { cid: "Client" } : null),
                        firstName: "First Name",
                        lastName: "Last Name",
                        directSharing: "",
                        email: "Email",
                        createdOn: "Created On",
                        actions: "Actions"
                    }}
                    sortableKeys={["First Name", "Last Name", "Email", "Created On"]}
                    defaultFilter={{ cid: cid || "" }}
                    defaultSort={{ createdOn: -1 }}
                    detailContent={user => (
                        <table className="responsive" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td colSpan={6}>
                                        <b>
                                            <u>Publishers</u>
                                        </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <th style={{ textAlign: "center" }}>Bypass Approval</th>
                                    <th style={{ textAlign: "center" }}>Accepted</th>
                                    <th style={{ textAlign: "center" }}>Content</th>
                                    <th style={{ textAlign: "center" }}>Email</th>
                                    <th style={{ textAlign: "center" }}>Profile</th>
                                    <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {user.advisorEmailMap &&
                                    Object.keys(user.advisorEmailMap).map(advisorEmail => {
                                        if (!user.advisorEmailMap[advisorEmail].permissions) {
                                            return null;
                                        }
                                        return (
                                            <tr key={advisorEmail} style={{ textAlign: "left" }}>
                                                <td style={{ width: "33%", wordBreak: "break-all" }}>{advisorEmail}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].allowSuperPublisher
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].permissions!.activated
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].permissions!.content
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].permissions!.eCommunication
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getBooleanIcon(
                                                        !!user.advisorEmailMap![advisorEmail].permissions!.profile
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Button
                                                        className="btn--sm"
                                                        onClick={async () => {
                                                            if (
                                                                confirm(
                                                                    "Are you sure you want to remove?"
                                                                )
                                                            ) {
                                                                await DelegateActions.doRemoveAdvisor(
                                                                    user.advisorEmailMap[advisorEmail]["uid"],
                                                                    user.uid,
                                                                    user.cid
                                                                );
                                                                pseudoRefreshPage();
                                                            }
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    {user.advisorEmailMap && Object.keys(user.advisorEmailMap).length > 1 &&
                                        <tr>
                                            <td colSpan={6}>&nbsp;</td>
                                        </tr>
                                    }
                                    {user.advisorEmailMap && Object.keys(user.advisorEmailMap).length > 1 &&
                                        <tr>
                                            <td></td><td></td><td></td><td></td><td></td><td></td>
                                            <td style={{ textAlign: "center" }}>
                                                <Button
                                                    className="btn--sm"
                                                    onClick={async () => {
                                                        if (
                                                            confirm(
                                                                "Are you sure you want to remove all?"
                                                            )
                                                        ) {
                                                            let advisorEmail = Object.keys(user.advisorEmailMap);
                                                            let advisorUIDs = advisorEmail.map(email => { return user.advisorEmailMap[email]['uid'] });
                                                            let delegateUID = user.uid;
                                                            let cid = user.cid;

                                                            await DelegateActions.doRemoveAllAdvisors(advisorUIDs, delegateUID, cid);
                                                            pseudoRefreshPage();
                                                        }
                                                    }}
                                                >
                                                    Remove All
                                                </Button>
                                            </td>
                                        </tr>
                                    }

                            </tbody>
                        </table>
                    )}
                />
            </Row>
        </Wrapper>
    );
};

const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(DelegateApproved);
