import React, { Component } from "react";
import { connect } from "react-redux";

import { CampaignNotificationActions } from "../../../../actions/";
import Link from "../../../atoms/Link";
import { Wrapper, Row } from "../../../atoms/Layout/";
import ElementListBox, { PaginationData } from "../../../molecules/ElementListBox";
import moment from "moment";
import { dateToUTCISOString, getComplianceMessage, getFriendlyDate, getBooleanIcon, getAdminDate } from "../../../../utils/Helpers";
import NotificationPreview from "../../../molecules/NotificationPreview";
import Button from "../../../atoms/Button";
import { history } from "../../../../utils/store";
import Ellipsis from "../../../atoms/Ellipsis";

interface ListFilters {
    active?: boolean;
    search?: string;
    cid?: string;
    groups?: string[];
    roles?: string[];
    type?: string;
    style?: string;
    startDate?: string;
    endDate?: string;
    allowEcomm?: boolean;
}

interface INotificationViewProps {
    clients: { [cid: string]: Mongo.client };
    user: Mongo.clientAdmin;
    location: {
        pathname: string;
        search: string;
    };
}
interface INotificationViewState {
    filters: ListFilters;
    view?: string;
    defaultDate?: string;
}
class NotificationView extends Component<INotificationViewProps, INotificationViewState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    userInput: HTMLInputElement | null = null;
    constructor(props: INotificationViewProps) {
        super(props);
        this._getClientName = this._getClientName.bind(this);
        this._refresh = this._refresh.bind(this);
        const stateUpdate: Partial<INotificationViewState> = {};
        if (this.props.location.search.indexOf("view") > -1) {
            history.push(this.props.location.pathname);
            const view = this.props.location.search.split("view=")[1].split("&")[0];
            stateUpdate.view = view;
        }
        if (this.props.location.search.indexOf("defaultDate") > -1) {
            history.push(this.props.location.pathname);
            const defaultDate = this.props.location.search.split("defaultDate=")[1].split("&")[0];
            stateUpdate.defaultDate = defaultDate;
        }

        this.state = {
            filters: {
                cid: props.user ? props.user.cid || "" : ""
            },
            ...stateUpdate
        };
    }

    async _refresh(filters: ListFilters, PaginationData: PaginationData) {
        this.setState({ filters });
        return CampaignNotificationActions.doList({ ...filters, ...PaginationData });
    }

    async _downloadCsv(filters: ListFilters, PaginationData: PaginationData) {
        return CampaignNotificationActions.doExportCSV({ ...filters, ...PaginationData });
    }

    _getClientName(notification: Mongo.INotification) {
        const { clients } = this.props;
        if (notification.cid) {
            if (clients && clients[notification.cid]) {
                return clients[notification.cid].name;
            } else if (clients && Object.keys(clients).length) {
                return "REMOVED";
            }
            return "Loading...";
        }
        return "None";
    }

    _getOptionGroups(cid: string) {
        const { clients } = this.props;
        if (!clients || !cid || !clients[cid]) {
            return null;
        }
        const { groups } = clients[cid];
        const _groups = Object.values(groups || {}).map(g => ({ key: g!.name, value: g!.gid }));
        if (this.props.user.groups && this.props.user.groups.length) {
            return _groups.filter(item => this.props.user.groups.indexOf(item.value) > -1);
        }
        return _groups;
    }

    _getGroups(notification: Mongo.INotification) {
        if (!notification.cid || notification.consumer) {
            return "N/A";
        }
        const groups = this._getOptionGroups(notification.cid) || [];
        const groupText = notification.groups && notification.groups.length ?
        groups.filter(group => notification.groups!.indexOf(group.value) > -1).map(group => group.key).join(", ") :
        "All groups";
        return groupText.length > 20 ? `${groupText.substr(0, 20)}...` : groupText;
    }

    _getOptionRoles(cid: string) {
        const { clients } = this.props;
        if (!clients || !cid || !clients[cid]) {
            return null;
        }
        const { roles } = clients[cid];
        const _roles = Object.values(roles || {}).map(r => ({ key: r!.name, value: r!.rid }));
        if (this.props.user.roles && this.props.user.roles.length) {
            return _roles.filter(item => this.props.user.roles.indexOf(item.value) > -1);
        }
        return _roles;
    }

    _getRoles(notification: Mongo.INotification) {
        if (!notification.cid || notification.consumer) {
            return "N/A";
        }
        const roles = this._getOptionRoles(notification.cid) || [];
        const roleText = notification.roles && notification.roles.length ?
        roles.filter(role => notification.roles!.indexOf(role.value) > -1).map(role => role.key).join(", ") :
        "All roles";
        return roleText.length > 20 ? `${roleText.substr(0, 20)}...` : roleText;
    }

    _checkIfActive(startDate: Mongo.INotification["startDate"], endDate: Mongo.INotification["endDate"]){
        const today = new Date();
        if (!startDate || !endDate) {
            return false;
        }
        return (moment((typeof startDate === "string" ? new Date(startDate) : startDate).toDateString()).isSameOrBefore(moment(today.toDateString())) && moment((typeof endDate === "string" ? new Date(endDate) : endDate).toDateString()).isSameOrAfter(moment(today.toDateString())));
    }

    _onDropdownSelect(option: string, notification: Mongo.INotification) {
        if (option == "duplicate") {
            // @ts-expect-error
            delete notification.name;
            // @ts-expect-error
            delete notification.nid;
            delete notification.startDate;
            delete notification.endDate;

            history.push({
                pathname: `/notifications/c/`,
                state: { ...notification, duplicate: true }
            });
        }

    }

    render() {
        const { clients } = this.props;
        const { filters } = this.state;
        let { cid } = filters;
        cid = Object.keys(clients).length === 1 ? Object.keys(clients)[0] : cid;
        const client = cid ? clients[cid] : null;
        const groups = this._getOptionGroups(cid || "") || [];
        const roles = this._getOptionRoles(cid || "") || [];
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        const endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 1);
        const allowECommunication = client && client.allowECommunication;
        const typeArr = ["All", "Article", "Media", "Text"];
        if(allowECommunication){
            typeArr.push("Email Template");
        }

        const styleObj = {
            modal: "Full screen",
            firstCard: "First Card",
            banner: "Banner"
        };

        const calendarProps = {
            title: "Notifications",
            allowCreateWithoutClient: true,
            createLink: "/notifications/c/",
            renderSidebar: notification => <div>
                <div style={{ fontSize: "20px" }}>{`${notification.name.substr(0, 30)}${notification.name.length > 30 ? "..." : ""}`}</div>
                <NotificationPreview
                    item={notification}
                    hideTitle={true}
                    customCardHeaderCss={{ backgroundSize: "297px 80px", width: "297px", height: "80px" }}
                    customCardCss={{ width: "297px" }}
                    hidePush={true}
                    hideWeeklyEmail={true}
                />
                <table className="notificationSidebarTable">
                    {this.props.user.isSuper && <tr>
                        <td>Client</td>
                        <td>{notification.cid ? clients[notification.cid].name : notification.consumer ? "All Consumers" : "All Clients"}</td>
                    </tr>}
                    <tr>
                        <td>Enabled</td>
                        <td>{getBooleanIcon(notification.active)}</td>
                    </tr>
                    {(!notification.consumer && (!notification.cid || clients[notification.cid]!.complianceEnabled)) && <tr>
                        <td>Compliance Status</td>
                        <td>{getComplianceMessage(notification as unknown as ServerTypes.Console.IArticle)}</td>
                    </tr>}
                    <tr>
                        <td>Start Date</td>
                        <td>{getAdminDate(notification.startDate)}</td>
                    </tr>
                    <tr>
                        <td>End Date</td>
                        <td>{getAdminDate(notification.endDate)}</td>
                    </tr>
                    <tr>
                        <td>Groups</td>
                        <td>{this._getGroups(notification)}</td>
                    </tr>
                    <tr>
                        <td>Roles</td>
                        <td>{this._getRoles(notification)}</td>
                    </tr>
                </table>
                <div className="notificationSidebarEditButtons">
                    <Button to={`/notification/e/${notification.nid}?view=calendar`}>Edit</Button>
                    <Ellipsis
                        dropdownOptions={["duplicate"]}
                        onSelect={(opt) => this._onDropdownSelect(opt, notification)}
                        className="notificationSidebar"
                    />
                </div>
            </div>,
            idField: "nid" as const,
            getTitle: notification => `${notification.name.replace(/(^.{30}).*/, "$1...")}\xA0\xA0\xA0\xA0\xA0\xA0${this._getGroups(notification)}\xA0\xA0\xA0\xA0\xA0\xA0${this._getRoles(notification)}`,
            itemName: "notification",
            getEventColour: notification => {
                switch (notification.status) {
                    case "approved":
                        return {
                            backgroundColor: "#152733",
                            borderColor: "#152733",
                            textColor: "#FFFFFF"
                        };
                    case "rejected":
                        return {
                            backgroundColor: "#676A6C",
                            borderColor: "#676A6C",
                            textColor: "#FFFFFF"
                        };
                    case "pending":
                    default:
                        return {
                            backgroundColor: "#FFFFFF",
                            borderColor: "#152733",
                            textColor: "#152733"
                        };
                }
            },
            ...(this.state.defaultDate ? { defaultDate: this.state.defaultDate } : null)
        };

        return (
            <Wrapper id="notifications">
                <Row>
                    <ElementListBox<Mongo.INotification>
                        title="Notifications"
                        keys={{
                            cid: "Client",
                            name: "Name",
                            active: "Enabled",
                            ...(!client || client.complianceEnabled ? { status: "Compliance Status" } : null),
                            groups: "Group",
                            roles: "Roles",
                            type: "Type",
                            style: "Appearance",
                            startDate: "Start Date",
                            endDate: "End Date",
                            lastUpdatedOn: "Last Updated",
                            actions: "Actions"
                        }}
                        sortableKeys={["Client", "Name", "Enabled", "Type", "Appearance", "Start Date", "End Date", "Last Updated"]}
                        defaultSort={{ lastUpdatedOn: -1 }}
                        filterCid={this.props.user.isSuper}
                        refresh={({ active, cid, search, type, groups, roles, style, startDate, endDate }, paginationData) =>
                            this._refresh(
                                {
                                    ...(active && active !== "All" ? { active: active === "true" } : null),
                                    ...(cid && cid !== "consumers" ? { cid } : null),
                                    ...(cid === "consumers" ? { consumer: true } : null),
                                    ...(search ? { search } : null),
                                    ...(type && type !== "All" ? { type } : null),
                                    ...(groups && groups !== "All" ? { groups: [groups] } : null),
                                    ...(roles && roles !== "All" ? { roles: [roles] } : null),
                                    ...(style && style !== "All" ? { style } : null),
                                    ...(startDate ? { startDate: dateToUTCISOString(startDate) } : null),
                                    ...(endDate ? { endDate: dateToUTCISOString(endDate, true) } : null),
                                    ...(allowECommunication ? {allowEcomm : true} : null)
                                },
                                paginationData
                            )
                        }
                        mutateItem={notification => ({
                            cid: notification.cid && !notification.consumer ?
                                <Link to={`/clients/e/${notification.cid}`}>{this._getClientName(notification)}</Link>
                                : notification.consumer ? "All Consumers" : "All Clients",
                            name: notification.name,
                            groups: this._getGroups(notification),
                            roles: this._getRoles(notification),
                            active: getBooleanIcon(notification.active),
                            status: getComplianceMessage(notification as unknown as ServerTypes.Console.IArticle) || "",
                            type: notification.type,
                            style: styleObj[notification.style || ""] || "",
                            startDate: notification.startDate ? getAdminDate(notification.startDate) : "",
                            endDate: notification.endDate ? getAdminDate(notification.endDate) : "",
                            lastUpdatedOn: notification.lastUpdatedOn ? getFriendlyDate(notification.lastUpdatedOn) : "",
                        })}
                        editLink={`/notification/e/:nid/?view=list`}
                        dropdownOptions={["duplicate"]}
                        onDropdownSelect={(opt, item) => this._onDropdownSelect(opt, item)}
                        createLink={`/notifications/c/`}
                        download={({ active, cid, search, type, groups, roles, style, startDate, endDate }, paginationData) =>
                        this._downloadCsv({
                                ...(active && active !== "All" ? { active: active === "true" } : null),
                                ...(cid && cid !== "consumers" ? { cid } : null),
                                ...(cid === "consumers" ? { consumer: true } : null),
                                ...(search ? { search } : null),
                                ...(type && type !== "All" ? { type } : null),
                                ...(groups && groups !== "All" ? { groups: [groups] } : null),
                                ...(roles && roles !== "All" ? { roles: [roles] } : null),
                                ...(style && style !== "All" ? { style } : null),
                                ...(startDate ? { startDate: dateToUTCISOString(startDate) } : null),
                                ...(endDate ? { endDate: dateToUTCISOString(endDate, true) } : null),
                            },
                            paginationData
                        )}
                        defaultFilter={{
                            active: "true",
                            search: "",
                            type: "",
                            groups: "",
                            roles: "",
                            style: "",
                            startDate: startDate.toDateString(),
                            endDate: endDate.toDateString(),
                        }}
                        filters={{
                            ...(cid && {
                                groups: {
                                    title: "Group",
                                    options: [
                                        { key: "All", value: "All" },
                                        ...groups
                                    ]
                                }
                            }),
                            ...(cid && {
                                roles: {
                                    title: "Role",
                                    options: [
                                        { key: "All", value: "All" },
                                        ...roles
                                    ]
                                }
                            }),
                            active: {
                                title: "Enabled",
                                options: [
                                    { key: "Yes", value: "true" },
                                    { key: "No", value: "false" },
                                    { key: "All", value: "All" }
                                ]
                            },
                            type: {
                                title: "Type",
                                options: [...typeArr]
                                    .filter(i => (!client || (i !== "Media") || client && client.allowCustomEdit && i === "Media"))
                                    .map(i => ({ key: i, value: i }))
                            },
                            style: {
                                title: "Appearance",
                                options: [
                                    { key: "All", value: "All" },
                                    { key: "Full screen", value: "modal" },
                                    { key: "First Card", value: "firstCard" },
                                    { key: "Banner", value: "banner" }
                                ]
                            },
                            startDate: {
                                title: "Start Date After",
                                defaultDate: startDate.toDateString()
                            },
                            endDate: {
                                title: "End Date Before",
                                defaultDate: endDate.toDateString()
                            }
                        }}
                        calendarProps={calendarProps}
                        defaultView={"calendar"}
                        includeConsumers={true}
                    />
                </Row>
            </Wrapper>
        );
    }
}
const mapStateToProps = state => ({
    user: state.session.admin,
    clients: state.lists.clients
});

export default connect(mapStateToProps)(NotificationView);
