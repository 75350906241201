import React, { Component } from "react";
import Spinner from "../../atoms/Spinner";
import { doGetArticlesForDomain } from "../../../actions/";
import { createAlert } from "../../../actions/";

import Pagination from "../../molecules/Pagination";
import Button from "../../atoms/Button";
import { Wrapper, Row } from "../../atoms/Layout/";

interface IDomainInfoProps { }
interface IDomainInfoState {
    domain: string;
    results: Array<{
        Link: string;
        SourceCategory: string;
        SourceWeight: number;
        Domain: string;
        Image: string;
        Title: string;
        Description: string;
        Content: string | null;
        CreatedOn: string
        Score: number;
    }>;
    since: number;
    currPage: number;
    rows: number;
    numFound?: number;
    lang: string;
    loading: boolean;
}
export default class DomainInfo extends Component<IDomainInfoProps, IDomainInfoState> {
    static allowSuper = true;
    static allowApiAdmin = true;
    static allowSuperClientAdmin = true;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props: IDomainInfoProps) {
        super(props);
        this._search = this._search.bind(this);
        this.state = {
            domain: "",
            since: 7,
            results: [],
            currPage: 0,
            rows: 30,
            numFound: undefined,
            lang: "en",
            loading: false
        };
    }

    async _search() {
        const domain = this.state.domain.replace(/^(https?:\/\/)?(www\.)?([^\/]+)\/?$/, "$3")
        this.setState(
            {
                domain,
                loading: true
            },
            async () => {
                const { items: results, numFound } = await doGetArticlesForDomain(
                    domain,
                    this.state.since,
                    this.state.currPage * this.state.rows,
                    this.state.lang,
                    this.state.rows
                );
                this.setState({ results, numFound, loading: false });
            }
        );
    }
    _onFieldChange(key: keyof IDomainInfoState, val: any) {
        if (key === "currPage") {
          this.setState({
            [key]: val
          } as IDomainInfoState, () => {
            this._search();
            createAlert(`${key} updated`, 'success');
          });
        } else {
          this.setState({ [key]: val } as IDomainInfoState, () => {
            createAlert(`${key} updated`, 'success');
          });
        }
    }
    render() {
        const { domain, since, results, lang, numFound, loading } = this.state;

        return (
            <Wrapper>
                <h1 aria-hidden="true">Domain Info</h1>
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <div className="form-group">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            marginBottom: "10px"
                                        }}
                                    >
                                        <div className="table__filters">
                                            <div className="table__filters__option">
                                                <label className="control-label">Domain</label>
                                                <input
                                                    type="text"
                                                    aria-label="Domain"
                                                    value={domain}
                                                    style={{ height: "38px" }}
                                                    onChange={event =>
                                                        this._onFieldChange("domain", event.target.value.trim())
                                                    }
                                                />
                                            </div>
                                            <div className="table__filters__option">
                                                <label className="control-label">Since</label>
                                                <div className="select__wrapper">
                                                    <select
                                                        value={since}
                                                        aria-label="Since"
                                                        onChange={event =>
                                                            this._onFieldChange("since", event.target.value)
                                                        }
                                                    >
                                                        <option value="1">1 day</option>
                                                        <option value="2">2 day</option>
                                                        <option value="3">3 day</option>
                                                        <option value="7">1 week</option>
                                                        <option value="14">2 weeks</option>
                                                        <option value="30">1 month</option>
                                                        <option value="60">2 months</option>
                                                        <option value="90">3 months</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="table__filters__option">
                                                <label className="control-label">Language</label>
                                                <div className="select__wrapper">
                                                    <select
                                                        aria-label={lang}
                                                        value={lang}
                                                        onChange={event =>
                                                            this._onFieldChange("lang", event.target.value)
                                                        }
                                                    >
                                                        <option value="en">English</option>
                                                        <option value="fr">French</option>
                                                        <option value="de">German</option>
                                                        <option value="it">Italian</option>
                                                        <option value="pt">Portuguese</option>
                                                        <option value="es">Spanish</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="table__filters__option">
                                                <label
                                                    className="control-label"
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                >
                                                    &nbsp;
                                                </label>
                                                <Button onClick={() => this._onFieldChange("currPage", 0)}>Search</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <div
                                        style={{
                                            float: "right"
                                        }}
                                    >
                                        <Pagination
                                            page={this.state.currPage}
                                            currItems={(results || []).length}
                                            totalItems={numFound}
                                            numPerPage={this.state.rows}
                                            changePage={newPage => this._onFieldChange("currPage", newPage)}
                                            disabled={this.state.loading}
                                            showTotal={true}
                                        />
                                    </div>

                                    <table className="table table-striped responsive" data-type="responsive">
                                        <thead>
                                            <tr>
                                                <th tabIndex={0}>Title</th>
                                                <th tabIndex={0}>Domain</th>
                                                <th tabIndex={0}>Date</th>
                                                <th tabIndex={0}>Category</th>
                                                <th tabIndex={0}>Weight</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading &&
                                                results &&
                                                results.map(res => (
                                                    <tr key={res.Link}>
                                                        <td tabIndex={0}>
                                                            <a href={res.Link} target="_blank">
                                                                {res.Title || "N/A"}
                                                            </a>
                                                        </td>
                                                        <td tabIndex={0}>{res.Domain || "N/A"}</td>
                                                        <td tabIndex={0}>{res.CreatedOn || "N/A"}</td>
                                                        <td tabIndex={0}>{res.SourceCategory || "N/A"}</td>
                                                        <td tabIndex={0}>{res.SourceWeight || "N/A"}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                    {loading && <Spinner />}
                                    <div
                                        style={{
                                            float: "right"
                                        }}
                                    >
                                        <Pagination
                                            page={this.state.currPage}
                                            currItems={(results || []).length}
                                            totalItems={numFound}
                                            numPerPage={this.state.rows}
                                            changePage={newPage => this._onFieldChange("currPage", newPage)}
                                            disabled={this.state.loading}
                                            showTotal={false}
                                        />
                                    </div>
                                    <div style={{ clear: "both", height: "6px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
}
