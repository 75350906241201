import React, { ChangeEvent, Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
import { createAlert, doGetClientWhitelabel, doGetGroups, doGetUsersV2, sendNewContentEmailUsers } from "../../../actions";
import { expand, deepMerge } from "../../../utils/Helpers";
import Spinner from "../../atoms/Spinner";
import { ConfirmModal } from "../../atoms/Modal";
import Multiselect from "react-bootstrap-multiselect";
import Tooltip from "../../atoms/Tooltip";
import Pagination from "../Pagination";
import { IRoleUser } from "../../../../client/components/pages/roles/Edit"
import { debounce } from "lodash";
import Toggle from "../../atoms/Toggle";
import DatePickerV2 from "../../atoms/DatePickerV2";

export type IEmailNewArticle = Pick<ServerTypes.Console.IArticle, "artid" | "sid" | "Title" | "Link" | "Image" | "Domain" | "CreatedOn" | "comment"> & { atsLink: string };

interface ISelectOption {
    label: string;
    value?: string;
    selected?: boolean;
    children?: ISelectOption[];
}

interface NewContentModalProps {
    uid: string;
    user: Mongo.clientAdmin;
    client: Mongo.IClient;
    clients: dynamic<Mongo.client>;
    article: ServerTypes.Console.IArticle;
    lang?: string;
    userEmail?: string;
    open: boolean;
    title: string;
    whiteLabelURL: string;
    emails?: { [lang: string]: Mongo.ICustomEmail };
    closeAction?: () => void;
}
interface NewContentModalState {
    open: boolean;
    subject: string;
    body: string;
    loading: boolean;
    loadingUsers: boolean;
    previewModalOpen: boolean;
    emails: string[];
    clientCode: string;
    hasGroups: boolean;
    groupOpts: ISelectOption[] | null;
    roleOpts: ISelectOption[] | null;
    queryLoading: boolean;
    // user filters
    search: string;
    rids: string[] | null | undefined;
    gids: string[] | null | undefined;
    pagination: {
        limit: number;
        skip: number;
        sort: { [key: string]: number };
        total: number;
    };
    users: IRoleUser[];
    uid: string;
    stream: Mongo.IStream;
    scheduleEmail: boolean;
    emailScheduledDate?: Date;
    invalidDate?: boolean;
}

export default class NewContentEmailModal extends Component<NewContentModalProps, NewContentModalState> {
    refreshUsers: Function;
    _createAlert: Function;
    constructor(props) {
        super(props);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._setGroupOptions = this._setGroupOptions.bind(this);
        this._setRoleOptions = this._setRoleOptions.bind(this);
        this._changeGroups = this._changeGroups.bind(this);
        this._changeRoles = this._changeRoles.bind(this);
        this.refreshUsers = debounce(this._refreshUsers, 500);
        this._createAlert = debounce((message, type) => {
            createAlert(message, type);
        }, 500);

        this.state = {
            open: props.open || false,
            subject: "",
            body: "",
            uid: props.uid,
            loading: false,
            loadingUsers: false,
            previewModalOpen: false,
            emails: [],
            clientCode: props.user.cid || (props.user.isSuper ? "ALL_Clients" : ""),
            hasGroups: false,
            groupOpts: null,
            roleOpts: null,
            queryLoading: false,
            search: "",
            rids: null,
            gids: null,
            pagination: { limit: 20, skip: 0, sort: { firstname: 1 }, total: 0 },
            users: [],
            stream: props.client.streams[props.article.sid],
            scheduleEmail: false
        };
    }

    componentDidMount() {
        this._setEmailContent();
        this._setRoleOptions();
        doGetGroups(this.state.clientCode).then(this._setGroupOptions);
    }

    componentWillReceiveProps(newProps: NewContentModalProps) {
        const state = {
            ...(newProps.open !== this.props.open ? { open: newProps.open } : null),
            ...(newProps.user !== this.props.user ? { user: newProps.user } : null),
            ...(newProps.uid !== this.props.uid ? { uid: newProps.uid } : null)
        };
        if (Object.keys(state).length) {
            this.setState(
                state as NewContentModalState,
            );
        }
    }

    _onFieldUpdate(field: string, event) {
        const value = event.target ? event.target.value : event;
        let state = {};
        if (field.indexOf(".") > -1) {
            const obj = expand(field, value); // mutate key into object with value
            const firstKey = Object.keys(obj)[0]; // get the outer most property
            const origValue = { [firstKey]: this.state[firstKey] }; // current value in state
            state = deepMerge(origValue, obj); // merge both values together and push into state
        } else {
            state[field] = value;
        }

        this.setState(state);
    }

    async _setEmailContent() {
        const { title, client } = this.props;
        const { stream: { title: streamTitle }} = this.state;
        const { title: appTitle } = await doGetClientWhitelabel(client.configName);
        const subject = `A new piece of Content is Available - ${title}`;
        const body = `Hi [UserName]!\n\nFresh content just dropped on ${appTitle} in ${streamTitle}!`
        this.setState({ subject, body });
    }

    _setRoleOptions() {
        const roleOpts: ISelectOption[] = [];
        if (this.state.clientCode &&
            this.state.clientCode !== "ALL_CLIENTS" &&
            Object.keys(this.props.clients?.[this.state.clientCode]?.roles || {}).length > 0
        ) {
                roleOpts.push({ value: "allRoles", label: "All Roles", selected: true });
                const roles = Object.values(this.props.clients![this.state.clientCode]!.roles);
                let options: ISelectOption[] = [];
                let selectedCount = roles.length;
                for (const role of roles) {
                    options.push({value: role.rid, label: role.name, selected: true})
                }
                roleOpts.push({
                    label: `Select roles (${selectedCount} Selected)`,
                    children: options
                });
            }
        this.setState({roleOpts: roleOpts});
    }

    _setGroupOptions(groups: dynamic<string>) {
        let hasGroups = false;
        const hasAllGroups =
            this.props.user.isClientAdmin ||
            this.props.user.isSuperClientAdmin ||
            this.props.user.isSuper ||
            (this.props.user.groups || []).length == 0;
        const groupOpts: ISelectOption[] = [];
        if (Object.keys(groups).length) {
            hasGroups = true;
            if (hasAllGroups) {
                groupOpts.push({ value: "All", label: "All", selected: true });
                groupOpts.push({ value: "None", label: "No Group", selected: false });
                groupOpts.push({ value: "allGroup", label: "All Groups", selected: false });
            } else {
                let userGroups = this.props.user.groups || [];
                // Filter for groups the user has access to
                for (let gid in groups) {
                    if (userGroups.indexOf(gid) === -1) {
                        delete groups[gid];
                    }
                }
            }
            let options: ISelectOption[] = [];
            let selectedCount = 0;
            for (const gid in groups) {
                let selected = !hasAllGroups;
                options.push({ value: gid, label: groups[gid]!, selected: selected });
                selectedCount += selected ? 1 : 0;
            }
            groupOpts.push({
                label: `Select groups (${selectedCount} Selected)`,
                children: options
            });
        }
        this.setState({ groupOpts, hasGroups });
    }

    __validateEmail(email: string) {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(email);
    }

    _onToggleChange() {
        this.setState(prevState => ({ scheduleEmail: !prevState.scheduleEmail }));
    }

    _isDateWithinRange(checkedDate: Date) {
        const minDate = new Date();
        const maxDate = new Date(minDate);
        maxDate.setDate(minDate.getDate() + 3);
        return checkedDate >= minDate && checkedDate <= maxDate;
    }

    _onDateChange(date: Date) {
        if (this._isDateWithinRange(date)) {
            this.setState({
                emailScheduledDate: date
            });
        } else {
            this.setState({
                invalidDate: true,
                emailScheduledDate: undefined
            });
            createAlert("Please select a date within 72 hours from now");
        }
    }

    close() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
    }

    _changeRoles(event: ChangeEvent) {
        const roleOpts = JSON.parse(JSON.stringify(this.state.roleOpts!));
        const allRoles = roleOpts[0] && roleOpts[0].value === "allRoles" ? roleOpts[0] : null;
        const optionRole = roleOpts.find(opt => opt.children)! as Require<ISelectOption, "children">;

        let selectedCount = optionRole ? optionRole.children.filter(opt => opt.selected).length : 0;
        const { value, selected } = event[0];
        if (value === "allRoles") {
            allRoles.selected = selected;
            for (let g = 0; g < optionRole.children.length; g++) {
                optionRole.children[g].selected = selected
            }
            selectedCount = selected ? optionRole.children.length : 0;
        } else {
            optionRole.children.find(opt => opt.value === value)!.selected = selected;
            selectedCount += selected ? 1 : -1;
            allRoles.selected = allRoles && selectedCount === optionRole.children.length;
        }

        optionRole.label = `Select roles (${selectedCount} Selected)`;
        if(!allRoles.selected && !selectedCount) {
            this.setState({ roleOpts, rids: undefined });
        } else {
            const rids = allRoles.selected
                            ? null
                            : optionRole.children
                                .filter(opt => opt.selected)
                                .map(opt => opt.value!);
            this.setState({ roleOpts, rids });
        }
    }

    _changeGroups(event: ChangeEvent) {
        const groupOpts = JSON.parse(JSON.stringify(this.state.groupOpts!));

        const allOpt = groupOpts[0].value === "All" ? groupOpts[0] : null;
        const noOpt = groupOpts[1] && groupOpts[1].value === "None" ? groupOpts[1] : null;
        const allGroups = groupOpts[2] && groupOpts[2].value === "allGroup" ? groupOpts[2] : null;
        const optionGroup = groupOpts.find(opt => opt.children)! as Require<ISelectOption, "children">;

        const { value, selected } = event[0];
        let selectedCount = optionGroup ? optionGroup.children.filter(opt => opt.selected).length : 0;

        // If ALL selected then nothing else should be selected
        if (value === "All") {
            allOpt.selected = selected;
            if (selected) {
                for (let g = 0; g < optionGroup.children.length; g++) {
                    optionGroup.children[g].selected = false;
                }
                noOpt.selected = false;
                allGroups.selected = false;
            }
            selectedCount = 0;
        } else if (value === "None") {
            noOpt.selected = selected;
            if (selected) {
                for (let g = 0; g < optionGroup.children.length; g++) {
                    optionGroup.children[g].selected = false;
                }
                allOpt.selected = false;
                allGroups.selected = false;
            }
            selectedCount = 0;
        } else if (value === "allGroup") {
            allGroups.selected = selected;
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = selected;
            }
            selectedCount = selected ? optionGroup.children.length : 0;
            if (selected) {
                allOpt.selected = false;
                noOpt.selected = false;
            }
        } else {
            optionGroup.children.find(opt => opt.value === event[0].value)!.selected = selected;
            selectedCount += selected ? 1 : -1;
            if (selected) {
                allOpt.selected = false;
                noOpt.selected = false;
                allGroups.selected = false;
            }
            allGroups.selected = allGroups && selectedCount === optionGroup.children.length;
        }

        optionGroup.label = `Select groups (${selectedCount} Selected)`;
        if (!allOpt.selected && !noOpt.selected && !allGroups.selected && !selectedCount) {
            this.setState({ groupOpts, gids: undefined });
        } else {
            const gids = allOpt.selected
                        ? null
                        : noOpt.selected
                        ? []
                        : optionGroup.children
                            .filter(opt => opt.selected)
                            .map(opt => opt.value!);
            this.setState({ groupOpts, gids });
        }
    }

    _checkFieldValidity() {
        const { rids, gids, subject, body } = this.state;
        if (rids === undefined) {
            createAlert("Please select at least one role");
            return false;
        } else if (gids === undefined) {
            createAlert("Please select at least one group");
            return false;
        } else if (subject == '' || body == '') {
            createAlert("Please fill in email subject and body");
            return false;
        }
        return true;
    }

    _isEmptyRecipients() {
        if (this.state.users.length === 0) {
            createAlert("Please select at least one user");
            return true;
        };
        return false;
    }

    _checkEmptyScheduleDate() {
        if (this.state.scheduleEmail && !this.state.emailScheduledDate) {
            createAlert("Please select a schedule date");
            return true;
        }
        return false;
    }

    async _refreshUsers() {
        const users: IRoleUser[] = [];
        const { user: { cid } } = this.props;
        const { search, rids, gids, pagination, stream: { tags } } = this.state;
        const { limit, skip, sort } = pagination;
        this.setState({ loadingUsers: true });
        try {
            const { items, count } = await doGetUsersV2(
                search,
                cid!,
                rids as string[] | null,
                gids as string[] | null,
                tags || [],
                limit,
                skip * limit,
                sort
            );
            users.push(...items.map(i => ({ ...i, name: `${i.firstName} ${i.lastName}`})));
            this.setState({
                loadingUsers: false,
                users,
                pagination: { ...pagination, total: count}
            })
        } catch (err) {
            console.error(err);
            this.setState({
                users: [],
                pagination: { limit: 0, skip: 0, sort: { firstname: 1 }, total: 0 }
            })
        }
    }

    async _previewRecipients() {
        if (!this._checkFieldValidity()) return;
        this.setState({ previewModalOpen: true });
        await this._refreshUsers();
    }

    async _sendNewContentEmail() {
        if (!this._checkFieldValidity()) return;
        await this._refreshUsers();
        if (this._isEmptyRecipients()) return;
        if (this._checkEmptyScheduleDate()) return;

        const { user: { cid }, article, whiteLabelURL } = this.props;
        const { subject, body, rids, gids, stream: { tags }, emailScheduledDate } = this.state;
        const { sid, artid, Title, Link, Image, Domain, CreatedOn, comment } = article;
        const atsLink = `${whiteLabelURL}?ats=${ Link ? encodeURIComponent(Link) : "" }&artid=${encodeURIComponent(artid || "")}`;

        const articleData = { artid, sid, Title, Link, Image, Domain, CreatedOn, comment, atsLink };
        const result = await sendNewContentEmailUsers(
            cid!,
            rids as string[] | null,
            gids as string[] | null,
            tags || [],
            articleData,
            subject,
            body,
            emailScheduledDate
        );
        if (result) {
            createAlert(`New content notification email sent to ${result.sent} user${result.sent != 1 ? "s" : ""}`, `success`);
            this.close();
        }
    }

    render() {
        const { open, subject, body, loading, queryLoading, loadingUsers, previewModalOpen, users, pagination, roleOpts, groupOpts, search, scheduleEmail, emailScheduledDate, invalidDate } = this.state;
        const { user } = this.props;
        const blankEmail = subject && body ? false : true;
        const link =
            user && (user.isSuper || user.isSuperClientAdmin)
                ? "/siteTemplates/img/emailGuideInternalV2.pdf"
                : "/siteTemplates/img/emailGuideClientV2.pdf";
        const minDate = new Date();
        const maxDate = new Date(minDate);
        maxDate.setDate(minDate.getDate() + 3);

        return (
            <div>
                <Modal
                    open={open}
                    title="Send New Content Email"
                    closeAction={() => this.close()}
                    link={link}
                    linkTitle={
                        <Button className="btn brandPrimary--bg  btn--sm">
                            <i className="fa fa-question-circle" /> Instructions
                        </Button>
                    }
                    footer={
                        loading ? (
                            undefined
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                {
                                    <Button
                                        className="btn brandPrimary--bg  btn--sm"
                                        style={{ float: "left", maxHeight: "25px" }}
                                        disabled={subject == "" || body == ""}
                                        onClick={e => {
                                            blankEmail ? e?.preventDefault() : this._previewRecipients();
                                        }}
                                    >
                                        <i className={`fa fa-eye`} /> Preview Recipients
                                        <Tooltip style={{ paddingLeft: "5px" }}>
                                            See users who will recieve the email notification
                                        </Tooltip>
                                    </Button>
                                }
                                <Button onClick={() => this._sendNewContentEmail()}>
                                    {scheduleEmail ? "Schedule Email" : "Send Email"}
                                </Button>
                            </div>
                        )
                    }
                >
                    {loading ? (
                        <Spinner />
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "10px"
                                }}
                            >
                                {true && (
                                    <div style={{}}>
                                        <label className="control-label">Role</label>
                                        <div
                                            style={{
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <Multiselect
                                                data={roleOpts}
                                                maxHeight={200}
                                                buttonWidth="210px"
                                                multiple="multiple"
                                                onChange={this._changeRoles}
                                                disabled={queryLoading}
                                                tabIndex={0}
                                            />
                                        </div>
                                    </div>
                                )}
                                {true && (
                                    <div
                                        style={{
                                            marginLeft: "30px"
                                        }}
                                    >
                                        <label className="control-label">Groups</label>
                                        <div>
                                            <Multiselect
                                                data={groupOpts}
                                                maxHeight={200}
                                                buttonWidth="210px"
                                                multiple="multiple"
                                                onChange={this._changeGroups}
                                                disabled={queryLoading}
                                                tabIndex={0}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexBasis: "100%",
                                    marginBottom: "10px"
                                }}
                            >
                                <label htmlFor="subject">Subject:</label>
                                <input
                                    id="subject"
                                    type="text"
                                    value={subject}
                                    onChange={event => this.setState({ subject: event.target.value })}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexBasis: "100%",
                                    marginBottom: "10px"
                                }}
                            >
                                <label htmlFor="emailBody">Email Body:</label>
                                <textarea
                                    style={{
                                        minHeight: "250px"
                                    }}
                                    id="emailBody"
                                    value={body}
                                    onChange={event => this.setState({ body: event.target.value })}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexBasis: "100%",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    alignItems: "center"
                                }}
                            >
                                <>
                                    <label htmlFor="schedule email">Schedule Email: </label>
                                    <Tooltip
                                        style={{
                                            marginLeft: "5px",
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Emails can be scheduled up to 72 hours in advance and will be sent at the
                                        selected date and time
                                    </Tooltip>
                                </>

                                <Toggle
                                    key={`scheduleEmail--input`}
                                    name="schedule email"
                                    onToggle={() => this._onToggleChange()}
                                    value={scheduleEmail}
                                />
                            </div>
                            {scheduleEmail && (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "50px"
                                    }}
                                >
                                    <label htmlFor="email schedule date">Email Schedule Date: </label>
                                    <DatePickerV2
                                        dateFormat="yyyy/MM/dd h:mm a"
                                        timeFormat="h:mm a"
                                        showTimeSelect={true}
                                        timeIntervals={15}
                                        onChange={date => this._onDateChange(new Date(date))}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        value={emailScheduledDate ?? undefined}
                                        calendarPlacement="top"
                                        className={invalidDate ? " red-outline-children" : ""}
                                        enableTabLoop={false}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </Modal>
                {previewModalOpen && (
                    <ConfirmModal
                        title={"Email Preview"}
                        button={`Send`}
                        force={false}
                        open={true}
                        onConfirm={() => {
                            this.setState({ previewModalOpen: false });
                            this._sendNewContentEmail();
                        }}
                        onCancel={() => {
                            this.setState({ previewModalOpen: false });
                        }}
                    >
                        {/* search box */}
                        <div className="table__filters" style={{ marginBottom: "10px" }}>
                            <div className="table__filters__option" style={{ width: "100%" }}>
                                <label htmlFor="search">Search:</label>
                                <Tooltip
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px"
                                    }}
                                >
                                    Only user who has access and has been activated can be sent new content email
                                    notification
                                </Tooltip>
                                <div className="search">
                                    <input
                                        id="search"
                                        className="filter__search"
                                        style={{ width: "100%" }}
                                        type="text"
                                        placeholder="Search by name or email"
                                        onChange={event =>
                                            this.setState(
                                                {
                                                    search: event.target.value,
                                                    pagination: { ...pagination, skip: 0, total: 0 }
                                                },
                                                () => {
                                                    this.refreshUsers();
                                                    this._createAlert('Search filter updated', 'success');
                                                }
                                            )
                                        }
                                        value={search}
                                    />
                                    <i className="fa fa-search" />
                                </div>
                            </div>
                        </div>
                        <label className="form__label">A copy of this email will be sent to the following users.</label>{" "}
                        <br />
                        <br />
                        {loadingUsers ? (
                            <Spinner />
                        ) : (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexBasis: "100%"
                                    }}
                                >
                                    <table className="role__users">
                                        <thead>
                                            <tr>
                                                <th>Users</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ maxHeight: "250px", overflowY: "scroll" }}>
                                            {users &&
                                                (users || []).map(i => {
                                                    return (
                                                        <tr key={`${i.rid}-${i.uid}`}>
                                                            <td
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    flexBasis: "100%",
                                                                    height: "100%"
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    label={
                                                                        <span>
                                                                            {`${i.name}`.trim() +
                                                                                ` (` +
                                                                                `${i.email}`.trim() +
                                                                                `)`}
                                                                        </span>
                                                                    }
                                                                ></Tooltip>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {(!users || !users.length) && (
                                                <tr className="clearfix">
                                                    <td>No Users found</td>
                                                    <td />
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "20px"
                                    }}
                                >
                                    <Pagination
                                        style={{ marginLeft: "auto" }}
                                        page={pagination.skip}
                                        totalItems={pagination.total}
                                        numPerPage={pagination.limit}
                                        currItems={(users || []).length}
                                        changePage={newPage =>
                                            this.setState({ pagination: { ...pagination, skip: newPage } }, () =>
                                                this._refreshUsers()
                                            )
                                        }
                                        showTotal={true}
                                    />
                                </div>
                            </>
                        )}
                    </ConfirmModal>
                )}
            </div>
        );
    }
}