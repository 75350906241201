import React from "react";
import BaseStream, { IBaseStreamState } from "./BaseStream";
import CreatableInput from "../../../atoms/CreatableInput";
import { createAlert } from "../../../../actions";

interface ICompanyStreamState extends IBaseStreamState {
    queryTerms: string[];
}
export default class CompanyStream extends BaseStream<ICompanyStreamState> {
    getInitialState() {
        const props = super.getInitialState();
        return {
            ...props,
            ...(props.stream
                ? {
                      stream: {
                          ...props.stream,
                          type: "COMPANY" as "COMPANY",
                      }
                  }
                : null),
            canPreview: true,
            canPreviewOnCreate: true,
            queryTerms: (props.stream && props.stream.terms || []).map(t => t.term)
        };
    }

    getSettings() {
        const { locations, ...settings } = super.getSettings();
        return settings;
    }

    async submit() {
        const { stream } = this.state;
        if (!stream.terms || !stream.terms.length) {
            createAlert(`You need to include at least one query term`, `error`);
            return;
        }
        return super.submit();
    }

    renderAboveSettingsRows() {
        const {  stream } = this.state;
        return [<div className="form__row">
            <div className="form__group form__group--full">
                <label htmlFor="query" className="form__label">Query:</label>
                <div
                    className="form__value"
                    style={{ display: "flex" }}>
                    <div style={{ width: "100%", marginRight: "20px" }}>
                        <CreatableInput
                            id="query"
                            onUpdate={items => {
                                stream.terms = items.map(t => ({ term: t, checked: true }));
                                this.setState({ stream });
                            }}
                            value={this.state.queryTerms}
                            placeholder={"Please enter your search terms"}
                        />
                    </div>
                </div>
            </div>
        </div>];
    }
}
