import BaseAction from "../utils/BaseAction";

export type IOfferCampaignActions = ClassInstance<typeof _OfferCampaignActions>;
class _OfferCampaignActions extends BaseAction {
    async doUpsert(campaign: ServerTypes.Console.IOfferCampaign) {
        const _campaign = { ...campaign };
        if (!_campaign.sids?.length) {
            this.createAlert("No stream selected for offer campaign", "error");
            return false;
        } else if (_campaign.sids.includes("__ALL__")) {
            _campaign.sids = [];
        }

        const formData = new FormData();
        if (_campaign["backgroundImage"]) {
            formData.append("file", _campaign["backgroundImage"]);
            delete _campaign["backgroundImage"]
        }

        Object.keys(_campaign).forEach(key => {
            if ((_campaign[key] != null) && (_campaign[key] != undefined)) {
                formData.append(key, JSON.stringify(_campaign[key]));
            }
        });

        const data = await this.apiRequestWithErrorAlert("/offercampaign/upsert", "POST", formData);
        if (data) {
            this.createAlert(
                `Successfully ${_campaign.ocid ? "updated" : "inserted"}  ${_campaign.name}`,
                "success"
            );
        }
        return !!data;
    }

    async doGet(ocid: string) {
        const data = await this.apiRequestWithErrorAlert<{ item: ServerTypes.Console.IOfferCampaign }>(
            "/offercampaign/get",
            "POST",
            { ocid }
        );
        if (data?.item && !data.item.sids?.length) {
            data.item.sids = ["__ALL__"];
        }
        return data ? data.item : null;
    }
    async doList(query: {
        cid?: string | null;
        search?: string;

        sort?: { [key: string]: number };
        skip?: number;
        limit?: number;
    }) {
        const data = await this.apiRequestWithErrorAlert<{
            items: ServerTypes.Console.IOfferCampaign[];
            count: number;
        }>("/offercampaign/list", "POST", { ...query });
        if (data?.items?.length) {
            return {
                items: data.items.map(item => {
                    if (item && !item.sids?.length) {
                        item.sids = ["__ALL__"];
                    }
                    return item;
                }),
                total: data.count
            };
        } else {
            return { items: [], total: 0 };
        }
    }

    async doDelete(ocid: string) {
        const data = await this.apiRequestWithErrorAlert("/offercampaign/delete", "POST", { ocid });
        if (data) {
            this.createAlert(`Successfully deleted`, "success");
        }
        return !!data;
    }

    doGetPreview(campaign: ServerTypes.Console.IOfferCampaign) {
        return this.apiRequest<string>(`/offercampaign/getPreview`, `GET`, { campaign: JSON.stringify(campaign) });
    }
}

export const OfferCampaignActions = new _OfferCampaignActions();
