import React from "react";
import { Creatable, components } from "react-select";
interface ICreatableLibraryProps {
    id?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange: (items: ICreatableLibraryValue[]) => void;
    value: ICreatableLibraryValue[];
}
export interface ICreatableLibraryValue {
    value: string;
    label: string;
    className?: string;
    checked?: boolean;
    added?: boolean;
}

export const CreatableLibrary = (props: ICreatableLibraryProps) => {
    const { placeholder, disabled, onChange, value, id } = props;

    const ValueContainer = (({ children, getValue, ...props }) =>{
        var updatedChildren = children;
        if(updatedChildren && updatedChildren.length && updatedChildren[0] && updatedChildren[0].entries){
            for (let [i] of updatedChildren[0].entries()) {
                updatedChildren[0][i].props.components.Container = (v) => {
                    v.innerProps.className = `${v.innerProps.className} ${v.data.className}`;
                    return <components.MultiValueContainer {...v} ></components.MultiValueContainer>;
                };
            }
        }
        return (
            <components.ValueContainer {...props}>{updatedChildren}</components.ValueContainer>
        );
    });

    return (
        <Creatable
            id={id}
            components={{
                DropdownIndicator:() => null,
                IndicatorSeparator:() => null,
                Menu: () => null,
                ValueContainer
            }}
            classNamePrefix="select"
            placeholder={placeholder || "Add an item..."}
            aria-label={placeholder || "Add an item..."}
            className={`list-of-tags ${disabled?'disabled':''}`}
            isMulti
            onChange={onChange}
            value={value}
            isClearable={false}
        />
    );
};
export default CreatableLibrary;