import React, { Component } from "react";

import { Route, Switch } from "react-router-dom";
import SignOut from "../components/pages/session/SignOut";

export default class SignOutContainer extends Component {
    static isPrivate = false;
    render() {
        return (
            <Switch>
                <Route exact={true} path="/logout" component={SignOut} />
            </Switch>
        );
    }
}
