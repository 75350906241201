import React, { Component } from "react";
import { connect } from "react-redux";

import ElementEditCard from "../../../molecules/ElementEditCard";

import {
    createAlert,
    doGetClients,
    CampaignNotificationActions,
    GroupActions,
    ClientActions,
    ClientRoleActions,
    IGroupMemberCount,
    IRoleMemberCount,
    IMemberCount,
    doGetECommunicationMessages
} from "../../../../actions";
import { Notification } from "../../../../utils/Models";
import { extractHostname, isURL } from "../../../../utils/Helpers";
import { history } from "../../../../utils/store";
import SharedArticle from "../../articles/sharedArticle";
import { canSubmitArticle } from "../../articles/submit";
import moment from "moment";
import Button from "../../../atoms/Button";
import Tooltip from "../../../atoms/Tooltip";
import Spinner from "../../../atoms/Spinner";
import LoadArticleModal from "../../../molecules/modals/LoadArticleModal";
import NotificationPreviewModal from "../../../molecules/modals/NotificationPreviewModal";
import {
    MESSAGE_FREQUENCY
} from "../../../../utils/ECommunicationHelpers";
import { RouteComponentProps } from "react-router-dom";

interface IEditNotificationProps extends RouteComponentProps<{ nid: string }, {}, Partial<ServerTypes.Console.IArticle>> {
    user: Mongo.clientAdmin;
    clients?: dynamic<Mongo.client>;
}
interface IEditNotificationState {
    nid: string;
    clients: Mongo.IClient[];
    quotes: string[];
    imageModal: boolean;
    multipleCommentsModal: boolean;
    networkCommentsModal: boolean;
    generateAICommentsModal: boolean;
    termsModal: boolean;
    hashtagsModal: boolean;
    loadArticleModal: boolean;
    cid: string | null;
    uploadedFile: boolean;
    fileUpload: File[] | null;
    totals: {
        hashtags: string[];
        mentions: string[];
        terms: string[];
    };
    isContent: boolean;
    isEcommTemplate: boolean;
    notificationType: Mongo.INotification["type"] | null;
    notificationStyle: Mongo.INotification["style"] | null;
    Link: Mongo.INotification["Link"] | null;
    Title: Mongo.INotification["Title"] | null;
    title: Mongo.INotification["title"] | null,
    body: Mongo.INotification["body"] | null,
    buttonLabel: Mongo.INotification["buttonLabel"] | null,
    buttonUrl: Mongo.INotification["buttonUrl"] | null,
    Image: Mongo.INotification["Image"] | null;
    currentLang: Mongo.INotification["currentLang"];
    notification: null | Mongo.INotification;
    loading: boolean;
    renderForceSchedule: boolean;
    lang: string | null;
    notificationPreviewModal: boolean;
    video?: Mongo.INotification["video"];
    thumbnail?: string | undefined;
    thumbnailModal?: boolean;
    customThumbnailUrl?: string | undefined;
    customThumbnailFile?: File | undefined;
    customPdfFile: File | null | undefined;
    clientMemberCount?: IMemberCount | null;
    groupMemberCount?: IGroupMemberCount[];
    roleMemberCount?: IRoleMemberCount[];
    view?: string;
    artid?: string;
    bottomHashtags?: string;
    bottomMentions?: string;
    template?: Mongo.INotification["template"];
    ecommTitle?: Mongo.INotification["ecommTitle"];
    ecommDescription?: Mongo.INotification["ecommDescription"];
    ecommThumbnail?: Mongo.INotification["ecommThumbnail"];
    ecommFrequency?: Mongo.INotification["ecommFrequency"];
    ecommPublishDate?: Mongo.INotification["ecommPublishDate"];
    ecommSubscribeId?: Mongo.INotification["ecommSubscribeId"];
    ecommExpiryDate?: Mongo.INotification["ecommExpiryDate"];
    ecommMessages: dynamic[];
    ecommTotal: number;
    notes?: string;
}
class EditNotification extends Component<IEditNotificationProps, IEditNotificationState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;
    facebookMaxLength = 3000;
    linkedInMaxLength = 3000;
    twitterMaxLength = 280;
    instaMaxLength = 3000;

    constructor(props: IEditNotificationProps) {
        super(props);
        this._getState = this._getState.bind(this);
        this._setState = this._setState.bind(this);
        let nid = "";

        if (props.match.params.nid) {
            nid = props.match.params.nid;
        }

        this.state = {
            nid,
            clients: [],
            quotes: [],
            imageModal: false,
            multipleCommentsModal: false,
            networkCommentsModal: false,
            generateAICommentsModal: false,
            termsModal: false,
            hashtagsModal: false,
            uploadedFile: false,
            fileUpload: null,
            totals: { hashtags: [], mentions: [], terms: [] },
            isContent: false,
            isEcommTemplate: false,
            notificationType: null,
            notificationStyle: null,
            Link: null,
            Title: "",
            Image: null,
            title: null,
            body: null,
            buttonLabel: null,
            buttonUrl: null,
            currentLang: "en",
            notification: null,
            loading: false,
            renderForceSchedule: false,
            loadArticleModal: false,
            cid: null,
            lang: null,
            notificationPreviewModal: false,
            video: false,
            thumbnail: "",
            thumbnailModal: false,
            customThumbnailUrl: "",
            customThumbnailFile: undefined,
            customPdfFile: null,
            clientMemberCount: null,
            groupMemberCount: [],
            roleMemberCount: [],
            artid: "",
            bottomHashtags: "",
            bottomMentions: "",
            template: "",
            ecommTitle: "",
            ecommDescription: "",
            ecommThumbnail: "",
            ecommFrequency: "",
            ecommPublishDate: "",
            ecommSubscribeId: "",
            ecommExpiryDate: "",
            ecommMessages: [],
            ecommTotal: 0
        };
    }

    async componentDidMount() {
        if (this.props.location.search.indexOf("view") > -1) {
            history.push(this.props.location.pathname);
            const view = this.props.location.search.split("view=")[1].split("&")[0];
            this.setState({ view });
        }
        const clients = await doGetClients();
        this.setState({ clients });
        if (clients && clients.length == 1) {
            const cid = clients[0].cid;
            const clientMemberCount = (await ClientActions.doGetMemberCount(
                cid,
                !!this.state.template
            )) as IMemberCount;
            this.setState({ clientMemberCount });
        }
        this._getMessages();
    }

    _hasLanguage(item: Mongo.INotification, lang: string) {
        // check the notification contains the content for the provided lang code
        switch (item.type.toLowerCase()) {
            case "text":
                if (!item.title && !item.body && !item.buttonLabel && !item.buttonUrl) {
                    return false;
                }
                return true;
            case "media":
            case "article":
            case "email template":
                // that's what we'll be using to detect language for now
                // as of 2021-02-11, article/media type notification only applicable to one language
                return item.currentLang && item.currentLang === lang;
            default:
                // must be one of the 4 types
                return false;
        }
    }
    async _getSubmitConfirmMessage(notification: Mongo.INotification) {
        const groupMemberCount = this.state.groupMemberCount;
        const roleMemberCount = this.state.roleMemberCount;
        const _campaignUserMessage = "This campaign will be visible in the app to potentially [numUsers] users. ";
        const _mobileUserMessage = "A push notification will be sent to approximately [numMobile] users. ";
        const noPushMessage = "No additional push notifications will be sent for this campaign. ";
        const notificationMessage = "\n\nYou have [numNotif] active notifications in a 7 day window. ";
        const emailWarningMessage =
            "This campaign will be displayed in the weekly email sent on [date] to [numEmail] users.\n\n";
        // get user count
        let numEnUsers = 0;
        let numFrUsers = 0;
        let mobileEnUsers = 0;
        let mobileFrUsers = 0;
        let weeklyEnUsers = 0;
        let weeklyFrUsers = 0;

        if ((notification && notification.cid && notification.groups && notification.groups.length > 0) || (notification && notification.cid && notification.roles && notification.roles.length > 0)) {
            if (notification && notification.cid && notification.groups && notification.groups.length > 0) {
                const cid = notification.cid;
                for (const gid of notification.groups) {
                    if (groupMemberCount) {
                        const group = groupMemberCount.filter(g => g.gid == gid);
                        if (group && group.length == 1) {
                            numEnUsers += group[0]["en"];
                            numFrUsers += group[0]["fr"];
                            mobileEnUsers += group[0]["en"];
                            mobileFrUsers += group[0]["fr"];
                            // weekly user doesn't apply to groups
                        } else {
                            try {
                                const info = (await GroupActions.doGetGroupMemberCount(cid, gid)) as IGroupMemberCount;
                                mobileEnUsers += info ? info["enMobile"] : 0;
                                mobileFrUsers += info ? info["frMobile"] : 0;
                            } catch (error) {
                                throw new Error("check user count failed");
                            }
                        }
                    }
                }
            }
            if (notification && notification.cid && notification.roles && notification.roles.length > 0) {
                const cid = notification.cid;
                for (const rid of notification.roles) {
                    if (roleMemberCount) {
                        const role = roleMemberCount.filter(r => r.rid == rid);
                        if (role && role.length == 1) {
                            numEnUsers += role[0]["en"];
                            numFrUsers += role[0]["fr"];
                            mobileEnUsers += role[0]["en"];
                            mobileFrUsers += role[0]["fr"];
                            // weekly user doesn't apply to groups
                        } else {
                            try {
                                const info = (await ClientRoleActions.doGetRoleMemberCount(cid, rid)) as IRoleMemberCount;
                                mobileEnUsers += info ? info["enMobile"] : 0;
                                mobileFrUsers += info ? info["frMobile"] : 0;
                            } catch (error) {
                                throw new Error("check user count failed");
                            }
                        }
                    }
                }
            }
        } else {
            let clientMemberCount = this.state.clientMemberCount;
            if (!clientMemberCount && notification && notification.cid) {
                try {
                    const cid = notification.cid;
                    clientMemberCount = (await ClientActions.doGetMemberCount(
                        cid,
                        !!this.state.template
                    )) as IMemberCount;
                } catch (error) {
                    throw new Error("check user count failed");
                }
            }
            numEnUsers = clientMemberCount ? clientMemberCount["en"] : 0;
            numFrUsers = clientMemberCount ? clientMemberCount["fr"] : 0;
            mobileEnUsers = clientMemberCount ? clientMemberCount["enMobile"] : 0;
            mobileFrUsers = clientMemberCount ? clientMemberCount["frMobile"] : 0;
            weeklyEnUsers = clientMemberCount ? clientMemberCount["enWeekly"] : 0;
            weeklyFrUsers = clientMemberCount ? clientMemberCount["frWeekly"] : 0;
        }

        const hasEnglish = this._hasLanguage(notification, "en");
        const hasFrench = this._hasLanguage(notification, "fr");

        let confirmationMessage = "";

        // compose the confirmation message for weekly email
        if (notification.isWeeklyEmail && notification.startDate) {
            const startDate = new Date(notification.startDate);
            const targetEmailSentString = moment(this._getUpcomingDeliveryDate(startDate)).format("MMM Do");
            // combine the user count depends on what languages the notification had configured
            const weeklyEmailUser = (hasEnglish ? weeklyEnUsers : 0) + (hasFrench ? weeklyFrUsers : 0);
            confirmationMessage += emailWarningMessage
                .replace("[date]", targetEmailSentString)
                .replace("[numEmail]", weeklyEmailUser.toString());
        }

        // confirmation message - user count
        const campaignUserCount = (hasEnglish ? numEnUsers : 0) + (hasFrench ? numFrUsers : 0);
        confirmationMessage += _campaignUserMessage.replace("[numUsers]", campaignUserCount.toString());
        if (notification.isPush) {
            // if push is on, create new notification (not yet sent) or updating notification that hasn't yet pushed
            if (notification.nid == "" || !notification.pushNotifySent) {
                const mobileUserCount = (hasEnglish ? mobileEnUsers : 0) + (hasFrench ? mobileFrUsers : 0);
                confirmationMessage += _mobileUserMessage.replace("[numMobile]", mobileUserCount.toString());
            } else {
                // otherwise, no push will take place.
                confirmationMessage += noPushMessage;
            }
        }
        // get active notifications
        // exclude the current one if update
        if (notification && notification.cid && notification.startDate) {
            const overallWindow = 6;
            const checkWindow = 6;
            const numNotificationsThreshold = 2;
            // set the UTC dates before and after the testing window as over test period
            const dateStart = new Date(notification.startDate);
            const periodStart = new Date(dateStart);
            periodStart.setDate(dateStart.getDate() - overallWindow);
            const periodStartUTC = new Date(
                Date.UTC(periodStart.getFullYear(), periodStart.getMonth(), periodStart.getDate())
            );
            const periodEnd = new Date(dateStart);
            periodEnd.setDate(dateStart.getDate() + overallWindow);
            const periodEndUTC = new Date(
                Date.UTC(periodEnd.getFullYear(), periodEnd.getMonth(), periodEnd.getDate(), 23, 59, 59)
            );
            let numNotifications = 0;
            try {
                // pull in ALL the notification in the testing period
                const activeNotifications = await CampaignNotificationActions.doGetActiveNotifications(
                    notification.cid,
                    notification.nid,
                    true,
                    periodStartUTC,
                    periodEndUTC
                );
                if (activeNotifications) {
                    // initialize the test window
                    const checkStartUTC = new Date(periodStartUTC);
                    const checkEndUTC = new Date(periodStartUTC);
                    do {
                        // set the test window with proper end date base on the start date
                        checkEndUTC.setDate(checkStartUTC.getDate() + checkWindow);
                        // check for notification with the test window
                        const active = activeNotifications.items.filter(notify => {
                            if (notify.startDate) {
                                const notifyDate = new Date(notify.startDate);
                                return notifyDate >= checkStartUTC && notifyDate < checkEndUTC;
                            }
                            return false;
                        });
                        // greater equal to 2 check here means at least 2 + the current 1 = 3 notification
                        if (active && active.length >= numNotificationsThreshold) {
                            // capture the count and break test as active notifications message should be display
                            numNotifications = active.length;
                            break;
                        }
                        // slide the start date 1 day for the next iteration
                        checkStartUTC.setDate(checkStartUTC.getDate() + 1);
                        // continue tests when end date not surpass the overall test period
                    } while (checkEndUTC < periodEndUTC);
                } else {
                    throw new Error("check active notifications failed");
                }
            } catch (error) {
                throw new Error("check active notifications failed");
            }
            // active notifications message
            if (numNotifications > 0) {
                confirmationMessage += notificationMessage.replace("[numNotif]", (numNotifications + 1).toString());
            }
        }
        // include the final message
        confirmationMessage += "\n\nDo you wish to continue?";
        return confirmationMessage;
    }

    async _getWeeklyEmailConfirmMessage(notification: Mongo.INotification) {
        const { type, startDate, endDate } = notification;
        if (startDate && endDate && notification.cid) {
            let message = "";
            // check for any notification that has weekly email turned on
            const activeNotifications = await CampaignNotificationActions.doGetActiveNotifications(
                notification.cid,
                notification.nid,
                false,
                null,
                null,
                true
            );
            if (activeNotifications && activeNotifications.items && activeNotifications.items.length > 0) {
                // if notifications with weekly turned on, pick out the title and use it in message
                const weeklyEmailNotifications = activeNotifications.items.filter(v => v.isWeeklyEmail == true);
                if (weeklyEmailNotifications.length > 0) {
                    const weeklyEmailNotification = weeklyEmailNotifications[0];
                    const title = weeklyEmailNotification.name;
                    message =
                        "Display in Upcoming Weekly Email is already enabled for [Notification Name].\n\nSince only one notification per week can be displayed in the upcoming weekly email, this toggle will be enabled for the current notification and automatically turned off for [Notification Name].";
                    message = message.replace(/\[Notification Name\]/g, title || "");
                }
            }
            // check if compliance is on and for article/media type
            const { clients } = this.state;
            const client = clients.find(c => c.cid === notification.cid);
            if (client && client.complianceEnabled && (type == "Article" || type == "Media")) {
                if (message != "") {
                    message += "\n\n";
                }
                message +=
                    "Note: If this notification is pending approval or rejected when the weekly email is sent, it will not appear in the email";
            }

            return { validData: true, message };
        }
        return { validData: false };
    }

    async _getMessages() {
        const { clients } = this.state;
        const client = clients.find(notification => notification.cid === notification.cid);
        await doGetECommunicationMessages(
            client?.cid || "",
            "standard",
            "active",
            null,
            undefined,
            undefined,
            null,
            null,
            null,
            true,
            [],
            null,
            'en'
        ).then(data => {
            const state = {
                ecommMessages: [],
                ecommTotal: 0
            };
            if (data) {
                if (data.items) {
                    state.ecommMessages = data.items;
                    state.ecommTotal = data.count;
                }
                this.setState(state);
            }
        });
    }

    validateSubmission(item: Mongo.INotification) {
        // the purpose of this method is to catch any missing values on the input and prevent the confirm modal from showing up
        // the actual validation and presenting the error messages is deferred to the upsert process.

        // all types must have the follow 3...
        if (!item.name || !item.style || !item.type) {
            return false;
        }

        switch (item.type.toLowerCase()) {
            case "text":
                let hasText = false;
                if (!item.title && !item.body && !item.buttonLabel && !item.buttonUrl) {
                    // this language is not used
                } else {
                    hasText = true;
                    if (!item.title || !item.body || !item.buttonLabel ||!item.buttonUrl) {
                        return false;
                    }
                }
                if (!hasText) {
                    return false;
                }
                break;
            case "media":
            case "article":
            case "email template":
                return true;
            default:
                // must be one of the 4 types
                return false;
        }
        return true;
    }

    async _onSubmit(notification: Mongo.INotification) {
        const { user } = this.props;
        const {
            isContent,
            fileUpload,
            customThumbnailUrl,
            customThumbnailFile,
            clients,
            customPdfFile
        } = this.state;
        const {
            nid,
            suggestedComments,
            Domain,
            comment,
            HashtagsAndMentions,
            sharingPermissions,
            title,
            body,
            buttonLabel,
            buttonUrl,
            date,
            video,
            Images,
            Image,
            type,
            template,
            ecommDescription,
            ecommTitle,
            ecommFrequency,
            ecommThumbnail,
            ecommSubscribeId
        } = notification;
        let {
            Link,
            startDate,
            endDate,
            Terms,
            CreatedOn,
            commentURL,
            expiryDate,
            ecommExpiryDate,
            ecommPublishDate
        } = notification;
        const oldSharingPermissions = this.state.notification?.sharingPermissions;
        const Hashtags = (HashtagsAndMentions || [])
            .filter(item => item.indexOf("#") == 0)
            .map(x => x.replace(/^#/, ""));
        const Mentions = (HashtagsAndMentions || [])
            .filter(item => item.indexOf("@") == 0)
            .map(x => x.replace(/^@/, ""));
        const hasAdminComment = !!((comment || "").length || (suggestedComments || []).length);
        const client = clients.find(notification => notification.cid === notification.cid);
        const daysApart = moment(endDate).diff(startDate, "days");

        const now = new Date();
        // can't force schedule if there are no permissions
        if (sharingPermissions && !Object.values(sharingPermissions).some(perm => perm)) {
            CreatedOn = now;
        }
        if (oldSharingPermissions && typeof oldSharingPermissions == "object" && sharingPermissions) {
            if (
                !Object.values(oldSharingPermissions).some(perm => perm) &&
                Object.values(sharingPermissions).some(perm => perm)
            ) {
                const newPermissionsArr = Object.keys(sharingPermissions).filter(key => sharingPermissions![key]);
                const newPermissions = newPermissionsArr
                    .map(key => {
                        if (key === "sms") {
                            return key.toUpperCase();
                        } else {
                            return key.charAt(0).toUpperCase() + key.slice(1);
                        }
                    })
                    .join(", ");
                if (
                    !confirm(
                        `This piece of content was previously set to read-only.\n\nAre you sure you want to change the Sharing Permissions to ${newPermissions}?`
                    )
                ) {
                    return;
                }
            }
        }

        notification.consumer = notification.cid === "ALL_CONSUMERS";
        if (!notification.cid && !notification.consumer) {
            createAlert("Please select a client from the dropdown");
            return false;
        }
        if (commentURL) {
            commentURL = commentURL.trim();
            if (commentURL.indexOf("http") === -1) {
                commentURL = "http://" + commentURL;
            }

            if (!isURL(commentURL)) {
                createAlert("Please enter a proper url", "error");
                return;
            }
        }
        if (Link && (customPdfFile === null || customPdfFile === undefined)) {
            Link = Link.trim();

            if (Link.indexOf("http") === -1) {
                Link = "http://" + Link;
            }

            if (!isURL(Link)) {
                createAlert("Please enter a proper url", "error");
                return false;
            }
        }
        // check if notification is active for more then 30 days
        if (daysApart > 30) {
            createAlert("You can not have a notification be active for more than 30 days. Please edit the start or end date", "error");
            return;
        }

        if (startDate && typeof startDate === "string") {
            startDate = new Date(startDate);
        }

        if (endDate && typeof endDate === "string") {
            endDate = new Date(endDate);
        }

        if (expiryDate && typeof expiryDate === "string") {
            expiryDate = new Date(expiryDate);
        }

        if (ecommPublishDate && typeof ecommPublishDate === "string") {
            ecommPublishDate = new Date(ecommPublishDate);
        }

        if (ecommExpiryDate && typeof ecommExpiryDate === "string") {
            ecommExpiryDate = new Date(ecommExpiryDate);
        }

        if (!nid && (startDate as Date).getTime() < (now as Date).getTime() - 60000) {
            createAlert("You cannot set a start date in the past", "error");
            return false;
        }
        if (startDate && endDate && (startDate as Date).getTime() >= (endDate as Date).getTime()) {
            createAlert("You cannot set an end date before the start date", "error");
            return false;
        }

        if (endDate && expiryDate && (endDate as Date).getTime() >= (expiryDate as Date).getTime()) {
            createAlert("Please set content's expiry date to a date after the end date", "error");
            return false;
        }

        if (isContent) {
            const valid = await canSubmitArticle(
                notification,
                notification.type === "Media",
                fileUpload,
                client,
                customPdfFile != null
            );
            if (!valid) {
                return false;
            }
        }
        if (CreatedOn && typeof CreatedOn === "string") {
            CreatedOn = new Date(CreatedOn);
        }

        if (
            startDate &&
            CreatedOn &&
            (CreatedOn as Date).getTime() > now.getTime() &&
            (startDate as Date).getTime() >= (CreatedOn as Date).getTime()
        ) {
            createAlert(
                'Please select a Scheduled Date (under "Sharing Permissions") that is after the notification\'s Start Date',
                "error"
            );
            return false;
        }

        if (Terms && typeof Terms === "string") {
            Terms = (Terms as string).split(",").map(item => item.trim());
        }
        if (type === "Email Template") {
            if (!ecommTitle) {
                createAlert("Please select an email template", "error");
                return false;
            }
            if (
                startDate &&
                ecommPublishDate &&
                (startDate as Date).getTime() < (ecommPublishDate as Date).getTime()
            ) {
                createAlert("Please choose a start date that is on or after the template’s Publish Date", "error");
                return false;
            }
            if (endDate && ecommExpiryDate && (endDate as Date).getTime() > (ecommExpiryDate as Date).getTime()) {
                createAlert("Please choose an end date that is before or on the template’s Expiry Date", "error");
                return false;
            }
        }

        delete notification.Content;

        const submitNotification: Mongo.INotification = Notification({
            ...notification,
            Terms,
            Hashtags,
            Mentions,
            suggestedComments,
            sharingPermissions,
            title,
            body,
            buttonLabel,
            buttonUrl,
            date,
            Link,
            Image,
            startDate: startDate ? (startDate as Date) : now,
            endDate: endDate ? (endDate as Date) : undefined,
            Domain: Domain || extractHostname(Link),
            hasAdminComment,
            ecommTitle,
            ecommDescription,
            ecommThumbnail,
            ecommExpiryDate,
            ecommFrequency,
            ecommPublishDate,
            ecommSubscribeId,
            template,
            CreatedOn: CreatedOn ? (CreatedOn as Date) : now,
            ...(user ? { email: user.email, firstName: user.name } : null),
            lastUpdatedOn: now,
            video: video || (fileUpload && fileUpload.length > 0 && fileUpload[0].type.includes("video")),
            ...(customThumbnailUrl ? { customThumbnailUrl } : null),
            ...(commentURL ? { commentURL } : null),
            ...(Images ? { Images: JSON.stringify(Images) } : null)
        });
        if (submitNotification.Thumbnail) delete submitNotification.Thumbnail;
        if (submitNotification.sharingPermissions === "{}") {
            delete submitNotification.sharingPermissions;
        }
        if (!submitNotification.video) {
            delete submitNotification.video;
        }

        submitNotification.shareFormat =
            customPdfFile || (Link && Link.match(/.(pdf)$/i)) ? "pdf" : submitNotification.type.toLowerCase();
        submitNotification.isUpload = customPdfFile ? true : false;

        if (submitNotification.cid === "All") {
            delete submitNotification.cid;
        }
        if (submitNotification.bottomHashtags) delete submitNotification.bottomHashtags;
        if (submitNotification.bottomMentions) delete submitNotification.bottomMentions;

        const renderForceSchedule =
            notification.CreatedOn && new Date(notification.CreatedOn) > new Date() ? true : false;
        if (renderForceSchedule && notification.CreatedOn) {
            const date = new Date();
            date.setHours(date.getHours() + (date.getMinutes() >= 40 ? 2 : 1), 0, 0, 0);
            if (moment(new Date(notification.CreatedOn).toDateString()).isBefore(new Date().toDateString())) {
                createAlert(`You cannot force schedule an article in the past`, `error`);
                return false;
            }
            if (moment(new Date(notification.CreatedOn)).isBefore(moment(date))) {
                createAlert(
                    `Please choose a time after ${date.getHours() % 12 == 0 ? 12 : date.getHours() % 12}:${(
                        "0" + date.getMinutes()
                    ).slice(-2)}${date.getHours() >= 12 ? "PM" : "AM"}`,
                    `error`
                );
                return false;
            }
        }

        try {
            // a few conditions here to show the confirm modal:
            // - skip on consumer or all clients
            // - only apply to active notifications
            // - ensure all required fields are filled
            if (
                notification.cid &&
                notification.cid.toLowerCase() != "all" &&
                (notification.consumer === false || notification.isWeeklyEmail) &&
                notification.active &&
                this.validateSubmission(notification)
            ) {
                const confirmationMessage = await this._getSubmitConfirmMessage(notification);
                if (!confirm(confirmationMessage)) {
                    return false;
                }
            }
        } catch (error) {
            createAlert("Please try again later.");
            this.setState({ loading: false });
            return false;
        }

        try {
            this.setState({ loading: true });
            await CampaignNotificationActions.doUpsert(
                submitNotification,
                fileUpload as File[],
                customThumbnailFile,
                customPdfFile
            );
            const action = notification.nid ? "updated" : "created";
            createAlert(`Successfully ${action} ${notification.name}`, `success`);

            this.setState({ loading: false, notification: null });
            // Redirect to homepage of notifications
            setTimeout(() => {
                history.push(
                    `/notifications${this.state.view ? `?view=${this.state.view}` : ""}${
                        this.state.view ? "&" : "?"
                    }defaultDate=${new Date(submitNotification.startDate!).toISOString()}`
                );
            }, 0);
            return true;
        } catch (error) {
            this.setState({ loading: false });
            return false;
        }
    }

    _getForceScheduleDefaultDate(startDate: Date) {
        const date = new Date(startDate);

        // startDate is today, lets set the default force schedule time to closest hour, if were past 40 minutes (when the cron runs, make it 2)
        if (new Date().toDateString() == date.toDateString()) {
            date.setHours(new Date().getHours() + (new Date().getMinutes() >= 40 ? 2 : 1), 0, 0, 0);
        } else {
            date.setHours(7, 0, 0, 0);
        }
        return date;
    }

    _dateHasPassed(item: Mongo.INotification) {
        const today = new Date();
        return item.startDate && moment(new Date(item.startDate).toDateString()).isBefore(moment(today.toDateString()));
    }

    _sameDay(d1: Date, d2: Date) {
        return (
            d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
        );
    }

    _getState() {
        return this.state;
    }

    _setState(item) {
        this.setState(item);
    }

    async _onLoad(notification: Mongo.INotification) {
        const renderForceSchedule =
            notification.CreatedOn && new Date(notification.CreatedOn) > new Date() ? true : false;
        this.setState({ renderForceSchedule });
    }

    async _getNotification(cid: string | null, nid: string | null) {
        if (this.state.notification) {
            return this.state.notification;
        }
        const propsArticle = this.props.location?.state;
        let tempNotification: Mongo.INotification;
        if (propsArticle?.duplicate) {
            delete propsArticle.duplicate;
            tempNotification = Notification({
                cid,
                ...propsArticle
            });
        } else {
            tempNotification = Notification({
                 cid,
                 ...(propsArticle
                     ? { ...propsArticle, type: propsArticle.shareFormat === "media" ? "Media" : "Article" }
                     : nid
                     ? await CampaignNotificationActions.doGet(nid)
                     : null)
             });
        }
        const Hashtags = ((tempNotification && (tempNotification.Hashtags as string[])) || []).map(i => "#" + i);
        const Mentions = ((tempNotification && (tempNotification.Mentions as string[])) || []).map(i => "@" + i);

        const notification = {
            ...tempNotification,
            cid: tempNotification.cid || (tempNotification.consumer ? "ALL_CONSUMERS" : "All"),
            HashtagsAndMentions: [...new Set(Hashtags.concat(Mentions))]
        };

        this.setState({
            notification,
            notificationType: notification.type || null,
            notificationStyle: notification.style || null,
            Link: notification.Link || null,
            Title: notification.Title || null,
            //notificationUI: notification.ui || null,
            title: notification.title || null,
            body: notification.body || null,
            buttonLabel: notification.buttonLabel || null,
            buttonUrl: notification.buttonUrl || null,
            Image: notification.Image || null,
            currentLang: notification.currentLang || "en",
            isContent: !!(notification.type === "Article" || notification.type === "Media"),
            isEcommTemplate: !!(notification.type === "Email Template"),
            video: notification.video,
            bottomHashtags: notification.bottomHashtags,
            bottomMentions: notification.bottomMentions,
            template: notification.template,
            notes: notification.notes
        });
        return notification;
    }

    _createSharePermissions(sharePermissions: string[], client?: Mongo.IClient, type?: Mongo.INotification["type"]) {
        let { notificationType } = this.state;
        notificationType = type || notificationType;
        sharePermissions = [];
        if (client) {
            if (
                client.socialNetworks.LinkedIn.available ||
                client.socialNetworks.Twitter.available ||
                client.socialNetworks.Facebook.available
            ) {
                sharePermissions.push("Social");
            }
            if (client.allowEmail || client.allowAppEmail) {
                sharePermissions.push("Email");
            }

            if (client.allowAppSMS || client.smsProvider) {
                sharePermissions.push("SMS");
            }
        }
        else{
            // when super admin wants to create an article for all consumers
            // they should get all these options as well.
            sharePermissions.push("Social");
            sharePermissions.push("Email");
            sharePermissions.push("SMS");
        }
        return sharePermissions;
    }

    _getTotals(client: Mongo.IClient, lang: string) {
        return {
            hashtags: client.hashtags && client.hashtags[lang] ? client.hashtags[lang].map(item => item.hashtag) : [],
            mentions: client.mentions && client.mentions[lang] ? client.mentions[lang].map(item => item.mention) : [],
            terms: client.terms && client.terms[lang] ? client.terms[lang].map(item => item.term) : []
        };
    }

    _getUpcomingDeliveryDate(target: Date) {
        const weekday = target.getDay(); // sun=0, mon=1, tues=2, wed=3, thurs=4, fri=5, sat=6
        // if we want Monday the number of days to next sent :     sun=1, mon=7, tues=6, wed=5, thurs=4, fri=3, sat=2
        // if we want Tuesday the number of days to next sent :    sun=2, mon=1, tues=7, wed=6, thurs=5, fri=4, sat=3
        const deliveryDay = 2; // currently set to Tuesday. going by sun=0, mon=1, tues=2, wed=3, thurs=4, fri=5, sat=6
        const daysAway = 7 + deliveryDay;
        const numDaysToNextDelivery = (daysAway - weekday) % 7; // moding 7 in case it will be next week
        const retval = new Date(target);
        retval.setHours(0, 0, 0, 0); // set midnight
        retval.setDate(retval.getDate() + numDaysToNextDelivery); // set it as target midnight
        return retval;
    }
    _canSendWeeklyEmail(
        active: boolean,
        groups: string[] | undefined,
        startDate: Date | string | undefined,
        endDate: Date | string | undefined
    ) {
        if (!active || (groups && groups.length > 0)) {
            return false;
        }
        const nextDeliveryStart = this._getUpcomingDeliveryDate(new Date());
        const nextDeliveryEnd = new Date(nextDeliveryStart);
        nextDeliveryEnd.setDate(nextDeliveryEnd.getDate() + 1); // set it as following day midnight
        if (startDate && endDate) {
            // confirm the date range is qualified for weekly email
            const start = startDate instanceof Date ? startDate : new Date(startDate);
            const end = endDate instanceof Date ? endDate : new Date(endDate);
            const qualifiedDates = !!(start <= nextDeliveryStart) && !!(end >= nextDeliveryEnd);
            return active && groups && groups.length == 0 && qualifiedDates;
        }
        return false;
    }

    _addDays(numDays, startDate) {
        let date = new Date(startDate);
        date.setDate(date.getDate() + numDays);
        return date;
    }

    render() {
        const {
            loading,
            nid,
            clients,
            quotes,
            imageModal,
            multipleCommentsModal,
            networkCommentsModal,
            generateAICommentsModal,
            termsModal,
            hashtagsModal,
            uploadedFile,
            isContent,
            isEcommTemplate,
            currentLang,
            notificationType,
            notificationStyle,
            Link,
            Title,
            title,
            body,
            buttonLabel,
            buttonUrl,
            renderForceSchedule,
            notificationPreviewModal,
            Image,
            fileUpload,
            thumbnail,
            thumbnailModal,
            customThumbnailUrl,
            customThumbnailFile,
            video,
            customPdfFile,
            ecommMessages
        } = this.state;
        let { totals } = this.state;
        let sharePermissions: string[] = [];

        // note for myself, in mongo, all users (system wide) notification does not have a cid attribute and all consumers notification has cid = ""
        const isAllUsersSelected =
            this.state.cid == null
                ? this.state.notification && this.state.notification.cid == "All"
                : this.state.cid == "All";
        const cid = clients.length === 1 ? clients[0].cid : null;
        const previewLinkEnabled =
            notificationStyle &&
                notificationType &&
                ((notificationType == "Article" &&
                (Link || customPdfFile) ||
                Title) || // uploading a PDF won't have a link yet but will have a customPdfFile.
            (notificationType == "Media" && (Image || uploadedFile || video)) ||
            (notificationType == "Text" && title && body && buttonLabel && buttonUrl) ||
                (notificationType === "Email Template" && this.state.notification?.ecommTitle));

        if ((clients || []).length == 0) {
            return <Spinner />;
        }
        return (
            <ElementEditCard<Mongo.INotification>
                loading={loading}
                onLoad={async item => this._onLoad(item)}
                getItem={() => this._getNotification(cid, nid)}
                canUpdate={() => Promise.resolve(true)}
                update={item => this._onSubmit(item)}
                breadcrumbs={item => [
                    {
                        name: "Notifications",
                        link: `/notifications${this.state.view ? `?view=${this.state.view}` : ""}`
                    }
                ]}
                elementName="Notification"
                buttons={item => [{
                    text: "Preview",
                    icon: "fa-eye",
                    disabled: (notificationType !== "Media") ? !previewLinkEnabled : false,
                    onClick: () => this.setState({ notificationPreviewModal: true })
                }]}
                rows={[
                    {
                        label: "Details",
                        columns: [
                            {
                                items: ["active", "name", "cid", "groups", "roles", "startDate", "endDate"],
                                key: "NotifDetails_R1C1"
                            },
                            {
                                items: ["type", "style", "currentLang", "isPush", "isWeeklyEmail"],
                                key: "NotifDetails_R1C2"
                            }
                            // just realize that both the order of listing of components here AND the order of how they are added in the editableKeys
                            // matter when it comes to the sequence of how the components are display
                        ],
                        key: "NotifDetails_Row1"
                    },
                    ...(notificationStyle === "banner" && (isContent || isEcommTemplate)
                        ? [
                              {
                                  label: "Banner Text",
                                  columns: [{ items: ["title2", "body2"], key: "NotifBanner_R2C1" }],
                                  key: "NotifBanner_Row3"
                              }
                          ]
                        : []),
                    ...(notificationType !== null
                        ? [
                              {
                                  label: "Content",
                                  columns: [
                                      {
                                          items: [
                                              "Title",
                                              "Link",
                                              "Image",
                                              "Thumbnail",
                                              "sharingPermissions",
                                              "expiryDate",
                                              "CreatedOn",
                                              "title",
                                              "body",
                                              "template",
                                              "ecommDescription",
                                              "ecommThumbnail",
                                              "ecommFrequency"
                                          ],
                                          key: "NotifContent_R3C1"
                                      },
                                      {
                                          items: [
                                              "comment",
                                              "commentURL",
                                              "Terms",
                                              "HashtagsAndMentions",
                                              "notes",
                                              "buttonLabel",
                                              "buttonUrl",
                                              "ecommPublishDate",
                                              "ecommExpiryDate"
                                          ],
                                          key: "NotifContent_R3C2"
                                      }
                                  ],
                                  key: "NotifContent_Row2"
                              }
                          ]
                        : [])
                ]}
                idField="nid"
                mandatoryFieldNote="* = mandatory"
                editableKeys={(item, getStateManual, setStateManual) => {
                    const client = clients.find(_item => _item.cid === item.cid);
                    const allowECommunication = client && client.allowECommunication;
                    const typeArr = ["Article", "Media", "Text"];
                    if(allowECommunication){
                        typeArr.push("Email Template");
                    }
                    if (client) {
                        totals = this._getTotals(client, currentLang || "en");
                    }
                    if (!item.title && !item.body && !item.buttonLabel && !item.buttonUrl) {
                        const { title, body, buttonLabel, buttonUrl } = Notification({});
                        item.title = title;
                        item.body = body;
                        item.buttonLabel = buttonLabel;
                        item.buttonUrl = buttonUrl;
                    }
                    const hideWeeklyEmail = !client && !!isAllUsersSelected;
                    sharePermissions = this._createSharePermissions(sharePermissions, client);
                    return {
                        active: {
                            label: "Enabled",
                            description:
                                "If toggle is ON, notification will be visible in the app between the Start Date and End Date. If toggle is OFF, notification will be hidden.",
                            mutateChange: async (key, value) => {
                                // flip weekly email to disabled when not active
                                if (!value) {
                                    return { [key]: value, isWeeklyEmail: false };
                                }
                                return { [key]: value };
                            }
                        },
                        name: {
                            label: "Name:",
                            type: "text",
                            required: true,
                            max: 30,
                            description:
                                "This field will be used by admins to label and identify this notification on the Notifications page. It will not be visible to your users",
                            // put the preview modal here since name is required so it will always load
                            renderAbove: (
                                <NotificationPreviewModal
                                    open={notificationPreviewModal}
                                    close={() => this.setState({ notificationPreviewModal: false })}
                                    item={item}
                                    fileUpload={fileUpload}
                                    uploadedFile={uploadedFile}
                                    thumbnail={thumbnail}
                                    customThumbnailUrl={customThumbnailUrl}
                                    customThumbnailFile={customThumbnailFile}
                                />
                            )
                        },
                        cid: {
                            hidden: !!cid,
                            label: "Client:",
                            type: "select",
                            values: [
                                { label: "All", value: "All" },
                                { label: "All Consumers", value: "ALL_CONSUMERS" },
                                ...(clients || [])
                                    .filter(c => c.allowNotifications)
                                    .map(c => ({ label: c.name, value: c.cid }))
                            ],
                            mutateChange: async (key, value) => {
                                // defer the clientMemberCount retrieval at submit time
                                this.setState({ cid: `${value}`, clientMemberCount: null });
                                return { [key]: value as string };
                            }
                        },
                        groups: {
                            disabled: !item.cid,
                            label: "Groups:",
                            type: "multi-select",
                            placeholder: "All Groups",
                            hidden: client == null || !client.groups,
                            values: Object.keys((client || { groups: {} }).groups).map(gid => ({
                                label: client!.groups[gid]!.name,
                                value: gid
                            })),
                            mutateChange: async (key, value) => {
                                if (client && value && Array.isArray(value)) {
                                    value.map(async val => {
                                        let groupMemberCount = this.state.groupMemberCount;
                                        if (groupMemberCount) {
                                            const group = groupMemberCount.filter(v => v.gid == val);
                                            if (group && group.length > 0) {
                                                return;
                                            }
                                        }
                                        const cid = client.cid;
                                        if (cid) {
                                            const group = (await GroupActions.doGetGroupMemberCount(
                                                cid,
                                                val
                                            )) as IGroupMemberCount;
                                            if (!groupMemberCount) {
                                                groupMemberCount = [];
                                            }
                                            groupMemberCount.push(group);
                                            this.setState({ groupMemberCount });
                                        }
                                    });
                                    // flip weekly email to disabled when any group is specified
                                    if (value && value.length > 0) {
                                        setStateManual({ isWeeklyEmail: false });
                                        return { [key]: value, isWeeklyEmail: false };
                                    }
                                }
                                return { [key]: value };
                            }
                        },
                        roles: {
                            disabled: !item.cid,
                            label: "Roles:",
                            type: "multi-select",
                            placeholder: "All roles",
                            hidden: client == null,
                            values: Object.keys((client || { roles: {} }).roles).map(rid => ({
                                label: client!.roles[rid]!.name,
                                value: rid
                            })),
                            mutateChange: async (key, value) => {
                                if (client && value && Array.isArray(value)) {
                                    value.map(async val => {
                                        let roleMemberCount = this.state.roleMemberCount;
                                        if (roleMemberCount) {
                                            const role = roleMemberCount.filter(v => v.rid == val);
                                            if (role && role.length > 0) {
                                                return;
                                            }
                                        }
                                        const cid = client.cid;
                                        if (cid) {
                                            const role = (await ClientRoleActions.doGetRoleMemberCount(
                                                cid,
                                                val
                                            )) as IRoleMemberCount;
                                            if (!roleMemberCount) {
                                                roleMemberCount = [];
                                            }
                                            roleMemberCount.push(role);
                                            this.setState({ roleMemberCount });
                                        }
                                    });
                                    // flip weekly email to disabled when any role is specified
                                    if (value && value.length > 0) {
                                        setStateManual({ isWeeklyEmail: false });
                                        return { [key]: value, isWeeklyEmail: false };
                                    }
                                }
                                return { [key]: value };
                            }
                        },
                        type: {
                            disabled: !item.cid,
                            label: "Type",
                            type: "select",
                            value: notificationType,
                            values: [...typeArr]
                                .filter(
                                    i =>
                                        item.cid === "ALL_CONSUMERS" ||
                                        !(
                                            (!client && (i === "Media" || i === "Article")) ||
                                            (client && !client.allowCustomEdit && i === "Media")
                                        )
                                )
                                .map(i => ({ label: i, value: i })),
                            mutateChange: async (key, value) => {
                                if (value === "Email Template") {
                                    await this._getMessages();
                                }
                                sharePermissions = this._createSharePermissions(sharePermissions, client, `${value}`);
                                this.setState({
                                    notificationType: `${value}`,
                                    isContent: !!(value === "Article" || value === "Media"),
                                    isEcommTemplate: !!(value === "Email Template"),
                                    loadArticleModal: !item.Link && value === "Article"
                                });
                                return { [key]: value as string };
                            },
                            required: true,
                            renderAbove: (
                                <LoadArticleModal
                                    client={client!}
                                    lang={item.currentLang || "en"}
                                    open={this.state.loadArticleModal}
                                    closeAction={() => this.setState({ loadArticleModal: false })}
                                    submit={(
                                        article: Partial<ServerTypes.Console.IArticle>,
                                        quotes: string[] | undefined
                                    ) => {
                                        setStateManual({ ...article });
                                        this.setState({
                                            loadArticleModal: false,
                                            quotes: quotes!,
                                            ...(article.Link ? { Link: article.Link } : { Link: "" }),
                                            ...(article.Title ? { Title: article.Title } : { Title: "" }),
                                            ...(article.Image ? { Image: article.Image } : { Image: "" }),
                                            ...(article.video ? { video: article.video } : { video: false })
                                        });
                                    }}
                                />
                            )
                        },
                        style: {
                            required: true,
                            disabled: !item.cid,
                            label: "Appearance",
                            type: "select",
                            value: notificationStyle,
                            values: [
                                { label: "Banner", value: "banner" },
                                { label: "Full screen", value: "modal" }
                            ],
                            mutateChange: async (key, value) => {
                                this.setState({ notificationStyle: `${value}` });
                                return { [key]: value as string };
                            }
                        },
                        currentLang: {
                            disabled: !item.cid,
                            label: "Language",
                            type: "select",
                            value: currentLang,
                            values: [
                                { label: "English", value: "en" },
                                { label: "French", value: "fr" }
                            ],
                            mutateChange: async (key, value) => {
                                this.setState({ currentLang: `${value}` });
                                return { [key]: value as string };
                            }
                        },
                        isPush: {
                            disabled: !item.cid || item.pushNotifySent,
                            label: "Send Push Notification:",
                            description:
                                "The push notification will be sent at the nearest 15 minute time interval to the start date and time. Note that a push notification can only be sent once. If the notification campaign is updated, another push notification will not be sent"
                        },
                        isWeeklyEmail: {
                            label: "Display in Upcoming Weekly Email",
                            description:
                                'Include this notification in the next weekly email sent to all users on Tuesdays. Only one notification per week can be included in the email.\nFor compliance clients, if the content is rejected or pending when the email is sent, it will not be included. This toggle can only be enabled if the following conditions are met:\n\n- The notification is Enabled\n- The Start Date must be before 12AM on the next Tuesday\n- The End Date must be after 11:59PM on the next Tuesday\n- Groups is set to "All users"\n',
                            hidden: hideWeeklyEmail,
                            value: item.isWeeklyEmail,
                            mutateChange: async (key, value) => {
                                if (value) {
                                    const canSendWeeklyEmail = this._canSendWeeklyEmail(
                                        item.active,
                                        item.groups,
                                        item.startDate,
                                        item.endDate
                                    );
                                    if (!canSendWeeklyEmail) {
                                        // can't send due to active; group; or dates
                                        return { [key]: false };
                                    } else {
                                        // get confirmation message
                                        const confirmation = await this._getWeeklyEmailConfirmMessage(item);
                                        if (confirmation.validData) {
                                            if (confirmation.message == "") {
                                                // no confirmation message required
                                                return { [key]: true };
                                            } else {
                                                if (!confirm(confirmation.message)) {
                                                    // also check show confirm message.
                                                    return { [key]: false };
                                                }
                                            }
                                        } else {
                                            // confirmation message is null means something went wrong.
                                            return { [key]: false };
                                        }
                                    }
                                }
                                return { [key]: value };
                            }
                        },
                        title: {
                            disabled: !item.cid,
                            required: true,
                            label: "Title",
                            type: "EditableInput",
                            max: 30,
                            hidden: isContent || !item.currentLang || isEcommTemplate,
                            value: `${item.title}`,
                            mutateChange: async (key, value) => {
                                this.setState({ title: `${value}` });
                                return { [key]: value as string };
                            },
                        },
                        body: {
                            disabled: !item.cid,
                            required: true,
                            label: "Body",
                            type: "EditableInput",
                            max: 170,
                            hidden: isContent || !item.currentLang || isEcommTemplate,
                            value: `${item.body}`,
                            customClasses: "editable-textarea",
                            mutateChange: async (key, value) => {
                                this.setState({ body: `${value}` });
                                return { [key]: value as string };
                            },
                        },
                        buttonLabel: {
                            disabled: !item.cid,
                            required: true,
                            label: "Button Label",
                            type: "EditableInput",
                            max: 22,
                            hidden: isContent || !item.currentLang || isEcommTemplate,
                            value: `${item.buttonLabel}`,
                            mutateChange: async (key, value) => {
                                this.setState({ buttonLabel: `${value}` });
                                return { [key]: value as string };
                            },
                        },
                        buttonUrl: {
                            disabled: !item.cid,
                            required: true,
                            label: "Button URL",
                            type: "EditableInput",
                            hidden: isContent || !item.currentLang || isEcommTemplate,
                            value: `${item.buttonUrl}`,
                            mutateChange: async (key, value) => {
                                this.setState({ buttonUrl: `${value}` });
                                return { [key]: value as string };
                            },
                            description: (
                                <div>
                                    <p>Links in the app are case sensitive</p>
                                    <br></br>
                                    <p>
                                        To redirect to a specific page in the app, set to the link after
                                        "livesocial.seismic.com/#/".
                                    </p>
                                    <br></br>
                                    <p>EX: main/reports/topArticles will open the Top Articles report page.</p>
                                    <br></br>
                                    <p>
                                        <b>loadStreamConfig:</b> opens the stream configuration.
                                    </p>
                                    <br></br>
                                    <p>
                                        <b>loadStreamConfig.professional:</b> opens the 'Adjust Interests' page for the
                                        main professional stream.
                                    </p>
                                    <br></br>
                                    <p>
                                        <b>main/articles/"stream Name":</b> will take the user directly to that stream.
                                    </p>
                                    <p>EX: main/articles/Professional or for ones with spaces, add the space</p>
                                    <p>EX: main/articles/covid 19</p>
                                    <br></br>
                                    <p>
                                        <b>loadStreamSub."Stream Name":</b> takes users directly to the stream
                                        subscription configuration screen.
                                    </p>
                                    <p>
                                        If the stream name does not match, it will automatically redirect users to
                                        loadStreamConfig
                                    </p>
                                    <br></br>
                                    <p>
                                        <b>close:</b> closes the notification when the CTA button is clicked
                                    </p>
                                </div>
                            ),
                        },
                        startDate: {
                            disabled: !item.cid,
                            required: true,
                            label: "Start Date:",
                            type: "datetime",
                            timeFormat: "h:mm a z",
                            timeConstraints: {
                                ...(this._sameDay(new Date(item.startDate || new Date()), new Date())
                                    ? { hours: { min: new Date().getHours() } }
                                    : null)
                            },
                            description:
                                "Date when the notification will become visible to your users.\nDisplayed Time is based on your device’s time zone. Users will see the notification according to their own time zones.\n(EX: Admin sets notification for 11am EST. Users living in PST will see it at 8am PST)\nStart date cannot be earlier than the current date and time.",
                            minDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                            mutateChange: async (key, value) => {
                                const renderForceSchedule =
                                    item.CreatedOn && new Date(item.CreatedOn) > new Date() ? true : false;
                                if (
                                    renderForceSchedule &&
                                    moment(new Date(value as string).toDateString()).isAfter(
                                        moment(new Date(item.CreatedOn).toDateString())
                                    )
                                ) {
                                    const CreatedOn = this._getForceScheduleDefaultDate(new Date(value as string));
                                    setStateManual({ CreatedOn });
                                }
                                // disable weekly email when changed to date no longer satisfy the pre-req condition
                                if (item.isWeeklyEmail) {
                                    const canSendWeeklyEmail = this._canSendWeeklyEmail(
                                        item.active,
                                        item.groups,
                                        value as string,
                                        item.endDate
                                    );
                                    if (!canSendWeeklyEmail) {
                                        setStateManual({ isWeeklyEmail: false });
                                        return { [key]: value as string, isWeeklyEmail: false };
                                    }
                                }
                                return { [key]: value as string };
                            }
                        },
                        endDate: {
                            disabled: !item.cid,
                            required: true,
                            label: "End Date:",
                            type: "datetime",
                            timeFormat: "h:mm a z",
                            description: "Date when the notification will no longer be visible",
                            minDate: item.startDate ? new Date(item.startDate).toISOString() : undefined,
                            maxDate: item.startDate ? this._addDays(30, item.startDate).toISOString() : undefined,
                            mutateChange: async (key, value) => {
                                // disable weekly email when changed to date no longer satisfy the pre-req condition
                                if (item.isWeeklyEmail) {
                                    const canSendWeeklyEmail = this._canSendWeeklyEmail(
                                        item.active,
                                        item.groups,
                                        item.startDate,
                                        value as string
                                    );
                                    if (!canSendWeeklyEmail) {
                                        setStateManual({ isWeeklyEmail: false });
                                        return { [key]: value as string, isWeeklyEmail: false };
                                    }
                                }
                                return { [key]: value as string };
                            }
                        },
                        ...SharedArticle({
                            getState: this._getState,
                            setState: this._setState,
                            getStateManual,
                            setStateManual,
                            item,
                            client,
                            uploadedFile,
                            imageModal,
                            totals,
                            termsModal,
                            hashtagsModal,
                            sharePermissions,
                            quotes,
                            multipleCommentsModal,
                            networkCommentsModal,
                            generateAICommentsModal,
                            facebookMaxLength: this.facebookMaxLength,
                            linkedInMaxLength: this.linkedInMaxLength,
                            twitterMaxLength: this.twitterMaxLength,
                            instaMaxLength: this.instaMaxLength,
                            isMedia: item.type === "Media",
                            hidden: !isContent || isEcommTemplate,
                            isNotification: true,
                            thumbnail,
                            thumbnailModal,
                            customThumbnailUrl,
                            customThumbnailFile,
                            fileUpload,
                            customPdfFile,
                        }),
                        expiryDate: {
                            label: "Expiry Date:",
                            type: "datetime",
                            hidden: !isContent || isEcommTemplate,  // experimental mode released in GV6-12109
                            timeFormat: "h:mm a z",
                            description:
                                "Date when the notification will no longer be visible in the notification history",
                            minDate: item.endDate ? new Date(item.endDate).toISOString() : undefined
                        },
                        CreatedOn: {
                            ...(!renderForceSchedule && {
                                renderAbove: (
                                    <div className="permission__column">
                                        <Button
                                            disabled={!item.cid}
                                            onClick={() => {
                                                const date = this._getForceScheduleDefaultDate(
                                                    new Date(item.startDate!)
                                                );
                                                this.setState({ renderForceSchedule: true });
                                                if (date) {
                                                    setStateManual({ CreatedOn: new Date(date.toISOString()) });
                                                }
                                            }}
                                        >
                                            {"Set Scheduled Date"}
                                            <Tooltip style={{ paddingLeft: "5px" }}>
                                                {
                                                    "Set the date when this post will be scheduled for users who choose to share it"
                                                }
                                            </Tooltip>
                                        </Button>
                                    </div>
                                )
                            }),
                            label: !renderForceSchedule
                                ? " "
                                : "Set the date when this post will be scheduled for users who choose to share it",
                            type: "datetime",
                            timeFormat: true,
                            hidden:
                                !client ||
                                !isContent ||
                                isEcommTemplate ||
                                (item.sharingPermissions && !Object.values(item.sharingPermissions).some(v => v)) ||
                                !!this._dateHasPassed(item),
                            minDate:
                                item.startDate && moment(new Date(item.startDate)).isSameOrBefore(moment(new Date()))
                                    ? new Date(item.startDate).toISOString()
                                    : new Date().toISOString(),
                            ...(item.endDate ? { maxDate: new Date(item.endDate).toISOString() } : null),
                            timeConstraints: { minutes: { step: 15 } },
                            validation: (current: moment.Moment) => {
                                const date = this._getForceScheduleDefaultDate(new Date(item.startDate!));
                                return (
                                    current.isSameOrAfter(moment(new Date())) &&
                                    current.isSameOrAfter(moment(date.toDateString())) &&
                                    (!item.endDate || current.isBefore(moment(new Date(item.endDate).toDateString())))
                                );
                            },
                            renderInput: (props, openCalendar) => {
                                if (!renderForceSchedule) {
                                    return <div></div>;
                                } else {
                                    return (
                                        <>
                                            <Button className="btn__width_90" onClick={openCalendar}>
                                                {moment(props.value)
                                                    .tz(moment.tz.guess())
                                                    .format("YYYY/MM/DD h:mm a z")}
                                            </Button>
                                            <Button
                                                className="btn__width_auto"
                                                style={{
                                                    marginLeft: "-5px",
                                                    borderLeft: "1px solid white",
                                                    borderBottomLeftRadius: "0",
                                                    borderTopLeftRadius: "0"
                                                }}
                                                onClick={() => {
                                                    this.setState({ renderForceSchedule: false });
                                                    setStateManual({
                                                        CreatedOn: new Date(),
                                                        startDate: new Date()
                                                    });
                                                }}
                                            >
                                                <span>x</span>
                                            </Button>
                                        </>
                                    );
                                }
                            }
                        },
                        currentLang2: {
                            disabled: !item.cid,
                            label: "Language",
                            type: "select",
                            hidden: false,
                            value: item.currentLang,
                            values: [
                                { label: "English", value: "en" },
                                { label: "French", value: "fr" }
                            ],
                            mutateChange: async (key, value) => {
                                this.setState({ currentLang: `${value}` });
                                return { currentLang: value as string };
                            }
                        },
                        title2: {
                            disabled: !item.cid,
                            required: true,
                            label: "Banner Title",
                            type: "EditableInput",
                            max: 30,
                            hidden:
                                !((isContent || isEcommTemplate) && notificationStyle === "banner") ||
                                !item.currentLang,
                            value: `${item.title}`,
                            mutateChange: async (key, value) => { return { title: value as string }; }
                        },
                        body2: {
                            disabled: !item.cid,
                            required: true,
                            label: "Banner Description",
                            type: "EditableInput",
                            max: 170,
                            hidden:
                                !((isContent || isEcommTemplate) && notificationStyle === "banner") ||
                                !item.currentLang,
                            value: `${item.body}`,
                            customClasses: "editable-textarea",
                            mutateChange: async (key, value) => { return { body: value as string }; }
                        },
                        template: {
                            hidden: !isEcommTemplate,
                            required: true,
                            label: "Template",
                            placeholder: "Please select a template",
                            type: "select",
                            values: [...(ecommMessages || []).map(c => ({ label: c.Title, value: c.ecommid }))],
                            mutateChange: async (key, value) => {
                                const result = ecommMessages.filter(c => {
                                    return c.ecommid === value;
                                });
                                const state = {
                                    template: `${value}` as string,
                                    ecommTitle: result[0].Title,
                                    ecommDescription: result[0].Description,
                                    ecommThumbnail: result[0].Image,
                                    ecommFrequency: result[0].frequency,
                                    ecommExpiryDate: result[0].inActiveDate,
                                    ecommPublishDate: result[0].activeDate,
                                    ecommSubscribeId: result[0].subscribableid
                                };
                                this.setState(state);

                                return state;
                            }
                        },
                        ecommDescription: {
                            disabled: true,
                            label: "Description",
                            type: "EditableInput",
                            max: 170,
                            hidden: (!allowECommunication && !isEcommTemplate) || !item.template,
                            value: item.ecommDescription,
                            customClasses: "editable-textarea"
                        },
                        ecommThumbnail: {
                            label: "Thumbnail",
                            disabled: true,
                            type: "EditableInput" as const,
                            value: item.ecommThumbnail,
                            placeholder: " ",
                            hidden: (!allowECommunication && !isEcommTemplate) || !item.template,
                            customClasses: "Select-arrow-zone",
                            renderAbove: (
                                <div id="video_thumbnail" style={{ alignSelf: "center", margin: "20px" }}>
                                    {item.ecommThumbnail && (
                                        <img
                                            src={item.ecommThumbnail}
                                            style={{
                                                maxWidth: "75px",
                                                maxHeight: "75px",
                                                padding: "5px",
                                                marginLeft: "35px"
                                            }}
                                        />
                                    )}
                                </div>
                            )
                        },
                        ecommFrequency: {
                            label: "Frequency",
                            hidden: (!allowECommunication && !isEcommTemplate) || !item.template,
                            disabled: true,
                            type: "EditableInput",
                            value: MESSAGE_FREQUENCY.find(result => result.value === item.ecommFrequency)?.name
                        },
                        ecommPublishDate: {
                            disabled: true,
                            hidden: (!allowECommunication && !isEcommTemplate) || !item.template,
                            label: "Publish Date",
                            type: "datetime",
                            timeFormat: "h:mm a z",
                            value: item.ecommPublishDate,
                            minDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
                        },
                        ecommExpiryDate: {
                            disabled: true,
                            hidden: (!allowECommunication && !isEcommTemplate) || !item.template,
                            label: "Expiry Date",
                            type: "datetime",
                            timeFormat: "h:mm a z",
                            value: item.ecommExpiryDate,
                            minDate: item.ecommExpiryDate ? new Date(item.ecommExpiryDate).toISOString() : undefined
                        }
                    };
                }}
            />
        );
    }
}
const mapStateToProps = state => ({
    user: state.session.admin,
    clients: state.lists.clients
} as Pick<IEditNotificationProps, "user" | "clients">);

export default connect(mapStateToProps)(EditNotification);
