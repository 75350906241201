import React, {Component} from 'react';
import Button from '../../atoms/Button';
import LogoLogin from "../../atoms/LogoLogin";
import Spinner from '../../atoms/Spinner';

import {createAlert} from '../../../actions/utils';
import {apiRequest} from '../../../utils/Helpers';
import appConfig from '../../../../config/config.dev';

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this._onSubmit = this._onSubmit.bind(this);
        this._onPasswordChange = this._onPasswordChange.bind(this);
        this._onConfirmedPasswordChange = this._onConfirmedPasswordChange.bind(this);
        this.state = {
            email: '',
            token: '',
            password: '',
            confirmedPassword: '',
            validation_status: 'validating',
            success: false,
            passwordLowerCase: null,
            passwordUpperCase: null,
            passwordNumber: null,
            passwordSpecialCharacter: null,
            passwordLength: null
        }
    }

    componentDidMount() {
        var _self = this;
        let params = {
            email: (this.props.match.params.email || '').trim(),
            token: (this.props.match.params.token || '').trim(),
        }
        this.setState(params);
        if (!params.email || ! params.token) {
            _self.setState({ validation_status: 'invalid' });
        } else {
            apiRequest(`${appConfig.API_URL}/session/validateAccountRecoveryToken`, "post", params)
                .then(function(result) {
                    _self.setState({ validation_status: 'valid' });
                })
                .catch(function(error) {
                    _self.setState({ validation_status: 'invalid' });
                });
        }
    }

    _onSubmit(event) {
        if (event) { event.preventDefault(); }

        var _self = this;
        let params = {
            email: this.state.email,
            token: this.state.token,
            password: this.state.password.trim(),
            confirmedPassword: this.state.confirmedPassword.trim(),
        }
        apiRequest(`${appConfig.API_URL}/session/resetPassword`, "post", params)
            .then(function(result) {
                _self.setState({ success: true });
            })
            .catch(function(error) {
                if (!(_self.state.passwordLowerCase != 0 || _self.state.passwordUpperCase != 0 || _self.state.passwordNumber != 0 || _self.state.passwordSpecialCharacter != 0 || _self.state.passwordLength != 0)) {
                    createAlert(error);
                }
            });
    }

    _onPasswordChange(event) {
        if ((/[a-z]/.test(event.target.value))) {
            this.setState({ passwordLowerCase: 0 });
        }else if(!(/[a-z]/.test(event.target.value))) {
            this.setState({ passwordLowerCase: 1 });
        }

        if ((/[A-Z]/.test(event.target.value))) {
            this.setState({ passwordUpperCase: 0 });
        }else if(!(/[A-Z]/.test(event.target.value))) {
            this.setState({ passwordUpperCase: 1 });
        }

        if ((/[0-9]/.test(event.target.value))) {
            this.setState({ passwordNumber: 0 });
        }else if(!(/[0-9]/.test(event.target.value))) {
            this.setState({ passwordNumber: 1 });
        }

        if ((/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(event.target.value))) {
            this.setState({ passwordSpecialCharacter: 0 });
        }else if(!(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(event.target.value))) {
            this.setState({ passwordSpecialCharacter: 1 });
        }

        if (event.target.value.length >= 8) {
            this.setState({ passwordLength: 0 });
        }else if(event.target.value.length < 8 && event.target.value.length > 0) {
            this.setState({ passwordLength: 1 });
        }else if(event.target.value.length == 0) {
            this.setState({ passwordLowerCase: null, passwordUpperCase: null, passwordNumber: null, passwordSpecialCharacter: null, passwordLength: null });
        }

        this.setState({password: event.target.value});
    }

    _onConfirmedPasswordChange(event) {
        this.setState({confirmedPassword: event.target.value});
    }

    render() {
        return (<div className="page login">
            <div className="login__wrapper">
                <h1 className="page__title"><LogoLogin className="login__logo"/>Reset Password</h1>
                {(() => {
                    if (this.state.success) {
                        return <div className="login__form">
                            <p className="login__username"><strong>Your password has been updated</strong>.  Please click the button below to login.</p>
                            <div className="login__btns">
                                <Button className="login__btn" to={`/login`}>Login</Button>
                            </div>
                        </div>
                    }
                    switch(this.state.validation_status) {
                        case "validating":
                            return <div><Spinner/><div>Validating Account Reset Token</div></div>;
                        case "valid":
                            return <form onSubmit={this._onSubmit} className="login__form">
                                <input type="hidden" value={this.state.token} />
                                <div className="login__password">
                                    <label>
                                        <span>New Password:&nbsp;</span>
                                        <input className="login__input" type="password" onChange={this._onPasswordChange}/>
                                    </label>
                                </div>
                                <div className="login__password">
                                    <label>
                                        <span>Confirm Password:&nbsp;</span>
                                        <input type="password" onChange={this._onConfirmedPasswordChange}/>
                                    </label>
                                </div>
                                <div className="login__criteria">
                                    <label>
                                        <span>Your password must contain:</span>
                                        <span className="criteria">
                                            <p className={`${this.state.passwordLowerCase == 0 ? "criteriaMet" : this.state.passwordLowerCase == 1 ? "criteriaNotMet" : ""}`}>
                                                {this.state.passwordLowerCase == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordLowerCase == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                                A lowercase letter
                                            </p>
                                            <p className={`${this.state.passwordUpperCase == 0 ? "criteriaMet" : this.state.passwordUpperCase == 1 ? "criteriaNotMet" : ""}`}>
                                                {this.state.passwordUpperCase == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordUpperCase == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                                An uppercase letter
                                            </p>
                                            <p className={`${this.state.passwordNumber == 0 ? "criteriaMet" : this.state.passwordNumber == 1 ? "criteriaNotMet" : ""}`}>
                                                {this.state.passwordNumber == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordNumber == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                                A number
                                            </p>
                                            <p className={`${this.state.passwordSpecialCharacter == 0 ? "criteriaMet" : this.state.passwordSpecialCharacter == 1 ? "criteriaNotMet" : ""}`}>
                                                {this.state.passwordSpecialCharacter == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordSpecialCharacter == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                                A special character
                                            </p>
                                            <p className={`${this.state.passwordLength == 0 ? "criteriaMet" : this.state.passwordLength == 1 ? "criteriaNotMet" : ""}`}>
                                                {this.state.passwordLength == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordLength == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                                At least 8 characters
                                            </p>
                                        </span>
                                    </label>
                                </div>
                                <div className="login__btns">
                                    <Button type="submit" value="Reset Password" className="login__btn"/>
                                    <Button className="login__btn" to={`/login`}>Cancel</Button>
                                </div>
                            </form>;
                        case "invalid":
                            return <div className="login__form">
                                <p className="login__username">The account recovery link you click has either expired or is invalid.  Account recover links are only valid for <strong>60 minutes</strong> and can <strong>only be used once</strong>.</p>
                            </div>;
                }})()}
            </div>
        </div>);
    }
}

export default ResetPassword;
