import React, { Component } from "react";
import { connect } from "react-redux";
import {
    doUpdateUser,
    ClientActions,
    ClientRoleActions,
    createAlert,
    doSetFooterFunctions
} from "../../../actions";
import { doGetEntityConfigs } from "../../../actions/lists";
import { preventDefault, expand, deepMerge, sanitizeHtml } from "../../../utils/Helpers";
import { Role } from "../../../utils/Models";
import { history } from "../../../utils/store";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import Tooltip from "../../atoms/Tooltip";
import Spinner from "../../atoms/Spinner";
import PermissionSet from "../../molecules/PermissionSet";
import RoleSelectionModal from "../../molecules/modals/RoleSelectionModal";
import RoleUserSearchModal from "../../molecules/modals/RoleUserSearchModal";
import Pagination from "../../molecules/Pagination";
// tslint:disable-next-line:no-submodule-imports
import debounce from "lodash/debounce";
import { Wrapper, Row } from "../../atoms/Layout/";
import { RouteComponentProps } from "react-router-dom";

export type IRoleUser = Pick<Mongo.IClientUser, "cid" | "rid" | "uid" | "email" | "firstName" | "lastName"> & {
    name: string;
};
interface ISelectedUser {
    uid: string;
    cid: string;
    name: string;
    rid?: string;
    email: string;
}

interface IEditRoleProperties extends RouteComponentProps<{ cid?: string; rid?: string; duplicateRid?: string; }> {
    user: Mongo.clientAdmin;
}
interface IEntityConfig {
    label: string;
    value: string
}

type IEditRoleState = Mongo.IUserRole & {
    filterLang: string;
    duplicateRid: string;
    cid: string;
    insert: boolean;
    save_protect: boolean;
    selectedRows: ISelectedUser[];
    usersUpdating: boolean;
    reassignRoleModalOpen: boolean;
    addUsersModalOpen: boolean;
    client: Mongo.IClient | null;
    SMSLang: string;
    search?: string;

    roles?: { [rid: string]: Mongo.IUserRole };
    users: IRoleUser[];
    pagination: { limit: number; skip: number; total: number };
    hasEntityConfig?: boolean;
    entityConfigLink?: string;
    smsEntityConfigLink?: string;
    entityConfigList?: IEntityConfig[];
    smsComplianceConfigId?: string;
    allowAICustomize: boolean,
    aiCommentPref: dynamic | null;
};
class EditRole extends Component<IEditRoleProperties, IEditRoleState> {
    static allowSuper = true;
    static allowApiAdmin = true;
    static allowSuperClientAdmin = true;
    static allowClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;
    editStartTime = new Date();

    nameInput: HTMLInputElement | null = null;
    userInput: HTMLInputElement | null = null;
    refreshUsers: Function;
    _createAlert: Function;

    constructor(props: IEditRoleProperties) {
        super(props);

        this.refreshUsers = debounce(this._refreshUsers, 500);
        this._createAlert = debounce((message, type) => {
            createAlert(message, type);
        }, 500);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        let roleId = "";
        if (props.match.params.rid) {
            roleId = props.match.params.rid;
        }

        this.state = {
            ...Role(),
            filterLang: "",
            rid: roleId,
            duplicateRid: props.match.params.duplicateRid || "",
            cid: props.match.params.cid || props.user.cid || "",
            insert: roleId === "",
            save_protect: false,
            selectedRows: [],
            usersUpdating: true,
            reassignRoleModalOpen: false,
            addUsersModalOpen: false,
            client: null,
            users: [],
            pagination: {
                limit: 20,
                skip: 0,
                total: 0
            },
            SMSLang: "en",
            hasEntityConfig: false,
            entityConfigList: [],
            entityConfigLink: "",
            smsEntityConfigLink: "",
            allowAICustomize: false,
            aiCommentPref: { personality:"prof",hashtag:"none",length: "long" }
        }
    }
    async componentDidMount() {
        if (this.nameInput) {
            this.nameInput.focus();
        }
        let roleStateUpdates: Partial<IEditRoleState> = {};
        const client = this.state.cid && await ClientActions.doGet(this.state.cid) || null;
        if (client) {
            let entityConfigList: IEntityConfig[] = [];
            const hasEntityConfig = client.hasEntityConfig;
            if (hasEntityConfig) {
                const entityConfigListRaw = await doGetEntityConfigs(this.state.cid, true);
                entityConfigList= Object.entries(entityConfigListRaw || {}).map(([k, v]) => {
                    return {
                        label: k,
                        value: entityConfigListRaw[k].split(/(?=INSTALL-)/g).pop()
                    }
                });
            }
            const roles = {};
            await (await ClientRoleActions.doList({ cid: this.state.cid })).items.forEach(r => (roles[r.rid] = r));
            this.setState({ client, roles, hasEntityConfig, entityConfigList });
            if (this.state.duplicateRid && roles && roles[this.state.duplicateRid]) {
                // duplicate + new created date
                const role = roles[this.state.duplicateRid];
                role.dates.created = new Date(),
                roleStateUpdates = {
                    ...roleStateUpdates,
                    insert: true,
                    ...role,
                    default: false,
                    defaultTrueDelegate: false,
                    name: `Copy of ${role.name}`
                };
                // after spreading the duplicated role, remove rid
                delete roleStateUpdates.rid;
            } else if (this.state.rid) {
                // update
                const role = await ClientRoleActions.doGet(this.state.cid, this.state.rid);
                roleStateUpdates = {
                    ...roleStateUpdates,
                    insert: false,
                    ...role
                };
            }
        }
        this.setState({ ...(roleStateUpdates as IEditRoleState) }, () => this.refreshUsers());
        doSetFooterFunctions(this._onSubmit, null, this.state.insert ? "Create" : "Update");
    }

    async _refreshUsers() {
        try {
            const { items, count } = await ClientRoleActions.doGetUsers(
                this.state.cid,
                this.state.rid,
                undefined,
                this.state.search,
                this.state.pagination.limit,
                this.state.pagination.skip * this.state.pagination.limit
            );
            this.setState({
                users: items,
                usersUpdating: false,
                selectedRows: [],
                pagination: { ...this.state.pagination, total: count }
            });
        } catch (err) {
            this.setState({
                users: [],
                usersUpdating: false,
                selectedRows: [],
                pagination: { total: 0, limit: 0, skip: 0 }
            });
        }
    }

    componentWillUnmount() {
        doSetFooterFunctions(null, null);
    }

    async _onFieldUpdate(key: string, event) {
        if (key == "socialNetworks") {
            if (!event["LinkedIn"]?.login) {
                event["LinkedIn"].share = false;
                event["LinkedIn"].companyShare = false;
            }
            if (!event["Twitter"]?.login) {
                event["Twitter"].share = false;
                event["Twitter"].companyShare = false;
            }
            // Instagram relies on Facebook
            if (!event["Facebook"]?.login) {
                event["Facebook"].companyShare = false;
                event["Facebook"].share = false;
                event["IG"].login = false;
                event["IG"].companyShare = false;
                event["IG"].share = false;
            } else if (!event["IG"]?.login) {
                event["IG"].companyShare = false;
                event["IG"].share = false;
            }
        }

        const val = event.target ? event.target.value : event;
        // Upload Video relies on Allow Compose
        if (key == "allowFrontendVideoCompose") {
            this.setState({
                allowFrontendVideoCompose: this.state.allowFrontendCompose && val == true
            })
        } else if (key == "allowFrontendCompose") {
            this.setState({
                allowFrontendCompose: val == true,
                allowFrontendVideoCompose: this.state.allowFrontendVideoCompose && val == true
            })
        } else if (key == "complianceExempt") {
            this.setState({
                complianceExempt: val,
                skipComplianceCurated: false,
                skipCompliance3rdParty: false,
                skipComplianceComposed: false,
                smsComplianceConfigId: "",
                entityConfigLink: "",
                smsEntityConfigLink: ""
            })
        } else {
            this.setState({ ...deepMerge(this.state, expand(key, val)) });
        }
    }

    async _onSubmit(event) {
        if (event) {
            event.preventDefault();
        }

        const role = Role({ ...this.state, dates: { ...this.state.dates, updated: new Date() } });
        if (!role.name) {
            createAlert(`You need to enter a name for the role`, `error`);
            return;
        } else {
            role.name = sanitizeHtml(role.name);
        }

        if (this.state.cid && role.rid) {
            const existingRole = await ClientRoleActions.doGet(this.state.cid, role.rid);
            if (existingRole && existingRole.dates && existingRole.dates.updated && new Date(existingRole.dates.updated) > this.editStartTime) {
                createAlert("This role has been updated since you started editing it. Please make note of your changes and refresh the page to try again.", "error");
                return;
            }
        }

        const upsert = await ClientRoleActions.doUpsert(this.state.cid, role);
        if (!upsert || !upsert.valid) {
            createAlert(`${this.state.insert ? "Create" : "Update"} ${role.name} failed.`, `error`);
            return;
        }

        createAlert(`Successfully ${this.state.insert ? "created" : "updated"} ${role.name}`, "success");
        setTimeout(() => {
            history.push("/roles");
        }, 0);
    }
    async removeRole() {
        if (this.state.usersUpdating) {
          return;
        }
        const client = await ClientActions.doGet(this.state.cid);
        if (!client) return;
        const roles = { ...client.roles };
        this.setState({ roles, reassignRoleModalOpen: true }, () => {
          createAlert('Roles updated', 'success');
        });
    }
    async reassignRole(rid: string) {
        if (this.state.usersUpdating) {
            return;
        }
        if (!this.state.selectedRows.length) {
            createAlert("Please select some users first", "error");
            return;
        }
        this.setState({ usersUpdating: true, reassignRoleModalOpen: false });
        for (const row of this.state.selectedRows) {
            await doUpdateUser({ ...row, rid }, false);
        }
        createAlert("Successfully reassigned all users from role", "success");
        this._refreshUsers();
    }
    async addRole() {
        if (this.state.usersUpdating) {
            return false;
        }
        if (!this.state.selectedRows.length) {
            createAlert("Please select some users first", "error");
            return false;
        }
        this.setState({ usersUpdating: true });

        for (const row of this.state.selectedRows) {
            await doUpdateUser({ ...row, rid: this.state.rid }, false);
        }
        createAlert("Successfully added all users from role", "success");

        this._refreshUsers();
        return true;
    }
    selectUser(user: ISelectedUser) {
        const selectedRows = this.state.selectedRows.filter(ii => ii.uid !== user.uid);
        if (!this.isSelected(user.uid)) {
            selectedRows.push(user);
        }
        this.setState({ selectedRows });
    }

    isSelected(uid: string) {
        return this.state.selectedRows.filter(i => i.uid == uid).length > 0;
    }

    render() {
        const { client, SMSLang, customSMSMessages } = this.state;
        const complianceVendor = client?.compliance?.vendor;
        return (
            <Wrapper>
                <Wrapper>
                    <Row>
                        <div className="col-sm-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>{this.state.insert ? "Create" : "Edit"} Role</h5>
                                </div>
                                <form
                                    className="ibox-content form"
                                    onSubmit={event => this._onSubmit(event)}
                                    onKeyPress={e => preventDefault(e)}
                                >
                                    <div className="form__row">
                                        <div className="form__group form__group--full">
                                            <label htmlFor="roleName" className="form__label">Role Name: *</label>
                                            <input
                                                id="roleName"
                                                type="text"
                                                className="form__value"
                                                ref={input => {
                                                    this.nameInput = input;
                                                }}
                                                onChange={event => this._onFieldUpdate("name", event)}
                                                value={this.state.name}
                                            />
                                        </div>
                                    </div>
                                    <div className="form__row">
                                    <div className="form__group">
                                            {client &&
                                                client.socialNetworks &&
                                                client.socialNetworks.LinkedIn &&
                                                client.socialNetworks.LinkedIn.available && [
                                                    <span
                                                        className="form__label"
                                                        style={{ lineHeight: "36px", marginBottom: 0 }}
                                                    >
                                                        LinkedIn
                                                    </span>,
                                                    <PermissionSet
                                                        permissions={{
                                                            login: {
                                                                label: "Connect to LiveSocial",
                                                                value: this.state.socialNetworks.LinkedIn.login,
                                                                description: `Allows users to connect to LiveSocial using LinkedIn`,
                                                            },
                                                            share: {
                                                                label: "Share to Personal Page",
                                                                value: this.state.socialNetworks.LinkedIn.share,
                                                                disabled: !this.state.socialNetworks.LinkedIn.login
                                                            },
                                                            companyShare: {
                                                                label: "Share to Company Page",
                                                                value: this.state.socialNetworks.LinkedIn.companyShare,
                                                                disabled: !this.state.socialNetworks.LinkedIn.login
                                                            }
                                                        }}
                                                        _onChange={(key, val) => {
                                                            this._onFieldUpdate(
                                                                "socialNetworks",
                                                                deepMerge(this.state.socialNetworks, {
                                                                    LinkedIn: { [key]: val }
                                                                })
                                                            );
                                                        }}
                                                        setNumber={"14"}
                                                    />
                                                ]}
                                            {client &&
                                                client.socialNetworks &&
                                                client.socialNetworks.Twitter &&
                                                client.socialNetworks.Twitter.available && [
                                                    <span
                                                        className="form__label"
                                                        style={{ lineHeight: "36px", marginBottom: 0 }}
                                                    >
                                                        X (Twitter)
                                                    </span>,
                                                    <PermissionSet
                                                        permissions={{
                                                            login: {
                                                                label: "Connect to LiveSocial",
                                                                value: this.state.socialNetworks.Twitter.login,
                                                                description: `Allows users to connect to LiveSocial using X (Twitter)`
                                                            },
                                                            share: {
                                                                label: "Share to Personal Page",
                                                                value: this.state.socialNetworks.Twitter.share,
                                                                disabled: !this.state.socialNetworks.Twitter.login
                                                            },
                                                            companyShare: {
                                                                label: "Additional X (Twitter) Accounts",
                                                                value: this.state.socialNetworks.Twitter.companyShare,
                                                                disabled: !this.state.socialNetworks.Twitter.login
                                                            }
                                                        }}
                                                        _onChange={(key, val) => {
                                                            this._onFieldUpdate(
                                                                "socialNetworks",
                                                                deepMerge(this.state.socialNetworks, {
                                                                    Twitter: { [key]: val }
                                                                })
                                                            );
                                                        }}
                                                        setNumber={"13"}
                                                    />
                                                ]}
                                            {client &&
                                                client.socialNetworks &&
                                                client.socialNetworks.Facebook &&
                                                client.socialNetworks.Facebook.available && [
                                                    <span
                                                        className="form__label"
                                                        style={{ lineHeight: "36px", marginBottom: 0 }}
                                                    >
                                                        Facebook
                                                    </span>,
                                                    <PermissionSet
                                                        permissions={{
                                                            login: {
                                                                label: "Connect to LiveSocial",
                                                                value: this.state.socialNetworks.Facebook.login,
                                                                description: `Allows users to connect to LiveSocial using Facebook`
                                                            },
                                                            companyShare: {
                                                                label: "Share to Company Page",
                                                                value: this.state.socialNetworks.Facebook.companyShare,
                                                                disabled: !this.state.socialNetworks.Facebook.login
                                                            }
                                                        }}
                                                        _onChange={(key, val) => {
                                                            this._onFieldUpdate(
                                                                "socialNetworks",
                                                                deepMerge(this.state.socialNetworks, {
                                                                    Facebook: { [key]: val }
                                                                })
                                                            );
                                                        }}
                                                        setNumber={"12"}
                                                    />
                                                ]}{" "}
                                            {client &&
                                                client.socialNetworks &&
                                                client.socialNetworks.Facebook &&
                                                client.socialNetworks.Facebook.available &&
                                                client.socialNetworks.IG &&
                                                client.socialNetworks.IG.available && [
                                                    <span
                                                        className="form__label"
                                                        style={{ lineHeight: "36px", marginBottom: 0 }}
                                                    >
                                                        Instagram
                                                    </span>,
                                                    <PermissionSet
                                                        permissions={{
                                                            login: {
                                                                label: "Connect to LiveSocial",
                                                                value: !!this.state.socialNetworks.IG?.login,
                                                                description: `Allow users to connect an Instagram account to LiveSocial. Connect to LiveSocial must be enabled for Facebook to turn this toggle on.`
                                                            },
                                                            companyShare: {
                                                                label: "Share to Business Page",
                                                                value: !!this.state.socialNetworks.IG?.companyShare,
                                                                description: `Allow users to share content to an Instagram Business Page. Connect to LiveSocial must be enabled for Facebook to turn this toggle on.`,
                                                                disabled: !this.state.socialNetworks.IG?.login
                                                            }
                                                        }}
                                                        _onChange={(key, val) => {
                                                            this._onFieldUpdate(
                                                                "socialNetworks",
                                                                deepMerge(this.state.socialNetworks, {
                                                                    IG: { [key]: val }
                                                                })
                                                            );
                                                        }}
                                                        setNumber={"12"}
                                                    />
                                                ]}{" "}
                                        </div>
                                        <div className="form__group">
                                            {client && (client.allowEmail || client.allowAppEmail || client.allowECommunication || client.allowAppSMS) && ([
                                                <span
                                                    className="form__label"
                                                    style={{ lineHeight: "36px", marginBottom: 0 }}
                                                >
                                                    Email &amp; SMS
                                                </span>,
                                                <PermissionSet
                                                    permissions={{
                                                        allowEmail: {
                                                            label: "Device Email (Desktop)",
                                                            hidden: !client.allowEmail,
                                                            value: this.state.allowEmail!,
                                                            description: "Enables users to share content by email on the web version of LiveSocial. This will open the desktop’s default mail program via a mailto link."
                                                        },
                                                        allowAppEmail: {
                                                            label: "Device Email (Mobile)",
                                                            hidden: !client.allowAppEmail,
                                                            value: this.state.allowAppEmail!,
                                                            description: "Enables users to share content by email on the app version of LiveSocial. This will open an app drawer where the user can choose from a list of apps to share to."
                                                        },
                                                        allowECommunication: {
                                                            label: "LiveSocial Email",
                                                            hidden: !client.allowECommunication,
                                                            value: this.state.allowECommunication!,
                                                            description: "Enables access with the LiveSocial Email product"
                                                        },
                                                        allowAppSMS: {
                                                            label: "Device SMS (Mobile)",
                                                            hidden: !client.allowAppSMS,
                                                            value: this.state.allowAppSMS!,
                                                            description: "Enables users to share content by SMS on the app version of LiveSocial. This will open the phone’s default SMS app."
                                                        },
                                                        allowDeviceContacts: {
                                                            label: "Device Contacts for SMS",
                                                            hidden: !client.smsProvider,
                                                            value: this.state.allowDeviceContacts!,
                                                            description: "Enables users to access the native contacts on their mobile device."
                                                        }
                                                    }}
                                                    _onChange={this._onFieldUpdate}
                                                    setNumber={"10"}
                                                />
                                            ])}
                                            <span
                                                className="form__label"
                                                style={{ lineHeight: "36px", marginBottom: 0 }}
                                            >
                                                Core Features
                                            </span>
                                            {client && (
                                                <PermissionSet
                                                    permissions={{
                                                        allowProfSearches: {
                                                            label: "Professional Searches",
                                                            hidden: !client.allowProfSearches,
                                                            value: this.state.allowProfSearches!,
                                                            description: "Enables professional searches and allows access to professional streams"
                                                        },
                                                        allowPersSearches: {
                                                            label: "Personal Searches",
                                                            hidden: !client.allowPersSearches,
                                                            value: this.state.allowPersSearches!,
                                                            description: "Enables personal searches and allows access to personal streams"
                                                        },
                                                        allowSuggest: {
                                                            label: "Suggested Articles",
                                                            hidden: !client.allowSuggest,
                                                            value: this.state.allowSuggest!,
                                                            description: "Enables users to suggest articles to share to other users"
                                                        },
                                                        allowFrontendCompose: {
                                                            label: "Allow Compose",
                                                            hidden: !client.allowFrontendCompose,
                                                            value: this.state.allowFrontendCompose!,
                                                            description: "Enables users to compose articles from the app"
                                                        },
                                                        allowFrontendVideoCompose: {
                                                            label: "Allow Users to Upload Video",
                                                            hidden: !client.allowFrontendVideoCompose,
                                                            value: this.state.allowFrontendVideoCompose!,
                                                            description: "Allows users to add an .mp4/m4v file when Composing\n\"Allow Compose\" must be enabled\nVideo maximum file size is 100MB to 650MB, depending on social network\nVideo maximum duration is 60 seconds to 10 minutes, depending on social network \nVideo content will not be scanned by compliance",
                                                        }
                                                    }}
                                                    _onChange={this._onFieldUpdate}
                                                    setNumber={"10"}
                                                />
                                            )}
                                            {client && client.complianceEnabled && (<>
                                                <span
                                                    className="form__label"
                                                    style={{ lineHeight: "36px", marginBottom: 0 }}
                                                >
                                                    Compliance
                                                </span>
                                                <PermissionSet
                                                    permissions={{
                                                        complianceExempt: {
                                                            label: "Non-Compliant",
                                                            value: !!this.state.complianceExempt,
                                                            description: "Users are exempt from compliance"
                                                        },
                                                        skipComplianceCurated: {
                                                            label: "Curated Content Post-Review Only",
                                                            value: !!this.state.skipComplianceCurated,
                                                            description: "Enables user to bypass pre-review when scheduling curated content",
                                                            disabled: !!this.state.complianceExempt
                                                        },
                                                        skipCompliance3rdParty: {
                                                            label: "3rd Party (PROF/PERS) Content Post-Review Only",
                                                            value: !!this.state.skipCompliance3rdParty,
                                                            description: "Enables user to bypass pre-review when scheduling 3rd party (PROF/PERS) content",
                                                            disabled: !!this.state.complianceExempt
                                                        },
                                                        skipComplianceComposed: {
                                                            label: "Composed Content Post-Review Only",
                                                            value: !!this.state.skipComplianceComposed,
                                                            description: "Enables user to bypass pre-review when scheduling composed content",
                                                            disabled: !!this.state.complianceExempt
                                                        },
                                                        smsComplianceConfigId: {
                                                            label: "SMS Compliance Policy ID",
                                                            hidden: !client.smsProvider || complianceVendor !== "ng",
                                                            value: this.state.smsComplianceConfigId || "",
                                                            type: "text",
                                                            description: "ID of the compliance policy that will be applied to SMS messages shared by users with this role",
                                                            disabled: !!this.state.complianceExempt
                                                        },
                                                    }}
                                                    _onChange={this._onFieldUpdate}
                                                    setNumber={"10"}
                                                />
                                            {this.state.hasEntityConfig && (
                                                <div>
                                                    <span
                                                        className="form__label"
                                                        style={{ lineHeight: "36px", marginBottom: 0 }}
                                                    >
                                                        Entity Config
                                                    </span>
                                                    {!!this.state.complianceExempt ? this._renderEntityConfigList(false, true) : this._renderEntityConfigList(false)}
                                                </div>
                                            )}
                                            </>)}
                                        </div>{" "}
                                        <div className="form__group">
                                            <span
                                                className="form__label"
                                                style={{ lineHeight: "36px", marginBottom: 0 }}
                                            >
                                                Additional Features
                                            </span>
                                            {client && (
                                                <PermissionSet
                                                    permissions={{
                                                        allowCopyLink: {
                                                            label: "Generate Short URL",
                                                            hidden: !client.allowCopyLink,
                                                            value: !!this.state.allowCopyLink,
                                                            description: "Enables users to copy an article link as a trackable URL"
                                                        },
                                                        allowPublish: {
                                                            label: "Allow Content",
                                                            hidden: !client.allowPublish,
                                                            value: !!this.state.allowPublish,
                                                            description: "Enables users to see and share content"
                                                        },
                                                        allowProspect: {
                                                            label: "Prospects Tool",
                                                            hidden: !client.allowProspect,
                                                            value: !!this.state.allowProspect,
                                                            description: "Enables users in this client to access our prospects lead generation"
                                                        },
                                                        allowEngage: {
                                                            label: "Engage Tool",
                                                            hidden: !client.allowEngage,
                                                            value: !!this.state.allowEngage,
                                                            description: "Enables users to use the chrome extension"
                                                        },
                                                        allowOffers: {
                                                            label: "Offers",
                                                            hidden: !client.allowOffers,
                                                            value: !!this.state.allowOffers,
                                                            description: "Enables offers for advisors of this client"
                                                        },
                                                        allowSuperCommenter: {
                                                            label: "Super Commenter",
                                                            hidden: !client.allowCommentsEditable,
                                                            value: !!this.state.allowSuperCommenter,
                                                            description: "Enables users to add or edit comments on articles from any stream."
                                                        },
                                                        allowAIComments: {
                                                            label: "AI Generated Comments for Users with Aura",
                                                            hidden: !client.aiGeneratedUserCommentsWithChatGPT,
                                                            value: !!this.state.allowAIComments,
                                                            description: "Suggests potential comments for the User in the App using Aura"
                                                        },
                                                        allowAICustomize: {
                                                            label: "Lock User's AI Comment Preferences",
                                                            hidden: !client.aiGeneratedUserCommentsWithChatGPT,
                                                            disabled: !this.state.allowAIComments,
                                                            value: !!this.state.allowAICustomize,
                                                            description: "This allows admin to define the AI comment settings for personality, hashtag usage, and length"
                                                        },
                                                        personality: {
                                                            label: "Personality",
                                                            type: "select",
                                                            hidden: !client.aiGeneratedUserCommentsWithChatGPT,
                                                            disabled: !this.state.allowAICustomize,
                                                            value: this.state.aiCommentPref?.personality || "Professional",
                                                            values: [
                                                                { value: "prof", label: "Professional" },
                                                                { value: "serious", label: "Serious" },
                                                                { value: "funny", label: "Funny" },
                                                                { value: "enthus", label: "Enthusiastic" }
                                                            ],
                                                            onChange: (value) => {
                                                                this.setState({ aiCommentPref: { ...this.state.aiCommentPref, personality: value as string } })
                                                            }
                                                        },
                                                        hastag: {
                                                            label: "Hashtag Usage",
                                                            type: "select",
                                                            hidden: !client.aiGeneratedUserCommentsWithChatGPT,
                                                            disabled: !this.state.allowAICustomize,
                                                            value: this.state.aiCommentPref?.hashtag || "No",
                                                            values: [
                                                                { value: "none", label: "No" },
                                                                { value: "little", label: "Yes" }
                                                            ],
                                                            onChange: (value) => {
                                                                this.setState({ aiCommentPref: { ...this.state.aiCommentPref, hashtag: value as string } })
                                                            }
                                                        },
                                                        length: {
                                                            label: "Length",
                                                            type: "select",
                                                            hidden: !client.aiGeneratedUserCommentsWithChatGPT,
                                                            disabled: !this.state.allowAICustomize,
                                                            value: this.state.aiCommentPref?.length || "Long",
                                                            values: [
                                                                { value: "short", label: "Short" },
                                                                { value: "med", label: "Medium" },
                                                                { value: "long", label: "Long" }
                                                            ],
                                                            onChange: (value) => {
                                                                this.setState({ aiCommentPref: { ...this.state.aiCommentPref, length: value as string } })
                                                            }
                                                        },
                                                        skipActivationEmail: {
                                                            label: "Exclude from Activation Email",
                                                            value: !!this.state.skipActivationEmail,
                                                            description: "Skips sending activation email to users assigned to this role"
                                                        },
                                                        isNotEmbeddedRole: {
                                                            label: "Exclude from Embedded Seismic App",
                                                            hidden: !client.isEmbeddedClient,
                                                            value: !!this.state.isNotEmbeddedRole,
                                                            description: "Users in this role will be provided links to access LiveSocial as a Standalone app"
                                                        }
                                                    }}
                                                    _onChange={this._onFieldUpdate}
                                                    setNumber={"11"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Row>

                    {this.state.rid && (
                        <Row>
                            <div className="col-sm-12">
                                <div className="ibox">
                                    <div className="ibox-title">
                                        <h5>Role Assignment</h5>
                                    </div>
                                    <form className="ibox-content form roles">
                                        <div className="form__row">
                                            <div className="form__group form__group--full" style={{ border: 0 }}>
                                                <div className="table__filters">
                                                    <div className="table__filters__option">
                                                        <label htmlFor="search">Search:</label>
                                                        <div className="search">
                                                            <input
                                                                id="search"
                                                                className="filter__search"
                                                                ref={input => {
                                                                    this.userInput = input;
                                                                }}
                                                                type="text"
                                                                placeholder="Search by name or email"
                                                                onChange={event =>
                                                                    this.setState(
                                                                        {
                                                                            search: event.target.value,
                                                                            pagination: {
                                                                                ...this.state.pagination,
                                                                                total: 0,
                                                                                skip: 0
                                                                            }
                                                                        },
                                                                        () => {
                                                                            this.refreshUsers();
                                                                            this._createAlert('Search filter updated', 'success');
                                                                        }
                                                                    )
                                                                }
                                                                value={this.state.search}
                                                            />
                                                            <i className="fa fa-search" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <table className="role__users">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ maxHeight: "250px", overflowY: "scroll" }}>
                                                        {this.state.usersUpdating && (
                                                            <tr>
                                                                <td className="full">
                                                                    <Spinner />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {!this.state.usersUpdating &&
                                                            !this.state.duplicateRid &&
                                                            this.state.users &&
                                                            this.state.users.map(i => {
                                                                return (
                                                                    <tr
                                                                        key={`${i.rid}-${i.uid}`}
                                                                        onClick={e => this.selectUser(i)}
                                                                        className={`${
                                                                            this.isSelected(i.uid) ? "selected" : ""
                                                                        }`}
                                                                    >
                                                                        <td
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                alignItems: "center",
                                                                                flexBasis: "100%",
                                                                                height: "100%"
                                                                            }}
                                                                        >
                                                                            <Tooltip
                                                                                label={
                                                                                    <span>
                                                                                        {`${i.name}`.trim() +
                                                                                            ` (` +
                                                                                            `${i.email}`.trim() +
                                                                                            `)`}
                                                                                    </span>
                                                                                }
                                                                            ></Tooltip>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        {!this.state.usersUpdating &&
                                                            (!this.state.users || !this.state.users.length) && (
                                                                <tr className="clearfix">
                                                                    <td>No Users found</td>
                                                                    <td />
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        justifyContent: "flex-end",
                                                        textAlign: "right",
                                                        marginTop: "20px",
                                                        height: "20px"
                                                    }}
                                                >
                                                    <Pagination
                                                        page={this.state.pagination.skip}
                                                        totalItems={this.state.pagination.total}
                                                        numPerPage={this.state.pagination.limit}
                                                        currItems={(this.state.users || []).length}
                                                        changePage={newPage =>
                                                            this.setState(
                                                                {
                                                                    pagination: {
                                                                        ...this.state.pagination,
                                                                        skip: newPage
                                                                    }
                                                                },
                                                                () => this._refreshUsers()
                                                            )
                                                        }
                                                        disabled={this.state.usersUpdating}
                                                        showTotal={true}
                                                    />
                                                </div>
                                                <div
                                                    style={{ display: "flex", alignItems: "center", marginTop: "20px", paddingTop: "10px" }}
                                                >
                                                    {this.state.users && this.state.users.length > 0 && (
                                                        <Link
                                                            style={{ marginRight: "auto" }}
                                                            onClick={() => {
                                                                let selectedRows = this.state.users;
                                                                if (
                                                                    selectedRows.length ==
                                                                    this.state.selectedRows.length
                                                                ) {
                                                                    selectedRows = [];
                                                                }
                                                                this.setState({ selectedRows });
                                                            }}
                                                        >
                                                            Select All...
                                                        </Link>
                                                    )}
                                                    {this.state.users && this.state.users.length > 0 && (
                                                        <Button
                                                            onClick={e => this.removeRole()}
                                                            disabled={
                                                                this.state.usersUpdating ||
                                                                this.state.selectedRows.length == 0
                                                            }
                                                        >
                                                            Remove from Role
                                                        </Button>
                                                    )}
                                                    <Button
                                                        style={{ marginLeft: "10px" }}
                                                        disabled={
                                                            this.state.usersUpdating ||
                                                            this.state.selectedRows.length > 0
                                                        }
                                                        onClick={e => this.setState({ addUsersModalOpen: true })}
                                                    >
                                                        Add Other Users to Role
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Row>
                    )}
                    {(client?.smsProvider && (client?.smsOptDefault == "IN" || client?.smsOptDefault == "OUT")) && (
                        <Row>
                            <div className="col-sm-12">
                                <div className="ibox">
                                    <div className="ibox-title">
                                        <h5>Custom SMS Messages</h5>
                                    </div>
                                    <form className="ibox-content form roles">
                                        <div className="form__row">
                                            <div
                                                className="select__wrapper"
                                                style={{
                                                    marginLeft: "auto",
                                                    minWidth: "150px",
                                                    maxWidth: "150px"
                                                }}
                                            >
                                                <select value={this.state.SMSLang} onChange={event => this.setState({SMSLang: event.target.value || "en"})}>
                                                    <option value="en">English</option>
                                                    <option value="fr">French</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form__row">
                                            <div className="form__group form__group--full" style={{ border: 0 }}>
                                            <label htmlFor="initialContactMessage" className="form__label">Initial Contact Message:
                                                <Tooltip
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "5px"
                                                    }}
                                                >
                                                Initial automated message with instructions on how to OPT IN or OPT OUT of further messages.<br />
                                                [name] will be replaced with the users first and last name when the message is sent.
                                                </Tooltip>
                                            </label>
                                            {client?.smsOptDefault == "OUT" &&
                                                <textarea
                                                id="initialContactMessage"
                                                className="form__value"
                                                onChange={event => this._onFieldUpdate(`customSMSMessages.optin_msg.${SMSLang}`, event)}
                                                maxLength={1600}
                                                style={{ minHeight: "100px" }}
                                                value={
                                                    customSMSMessages?.optin_msg[SMSLang]
                                                        ? customSMSMessages.optin_msg[SMSLang]
                                                        : ""
                                                }
                                                />
                                            }
                                            {client?.smsOptDefault == "IN" &&
                                                <textarea
                                                    id="initialContactMessage"
                                                    className="form__value"
                                                    onChange={event => this._onFieldUpdate(`customSMSMessages.optout_msg.${SMSLang}`, event)}
                                                    maxLength={1600}
                                                    style={{ minHeight: "100px" }}
                                                    value={
                                                        customSMSMessages?.optout_msg[SMSLang]
                                                            ? customSMSMessages.optout_msg[SMSLang]
                                                            : ""
                                                    }
                                                />
                                            }
                                            <label htmlFor="optInMessage" className="form__label" style={{ marginTop: "15px" }}>Opt In Confirmation Message:
                                                <Tooltip
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "5px"
                                                    }}
                                                >
                                                Automated message confirming the contact has opted IN to further messages.<br />
                                                [name] will be replaced with the users first and last name when the message is sent.
                                                </Tooltip>
                                            </label>
                                            <textarea
                                                id="optInMessage"
                                                className="form__value"
                                                onChange={event => this._onFieldUpdate(`customSMSMessages.optin_confirmation_msg.${SMSLang}`, event)}
                                                maxLength={1600}
                                                style={{ minHeight: "100px" }}
                                                value={
                                                    customSMSMessages?.optin_confirmation_msg[SMSLang]
                                                        ? customSMSMessages.optin_confirmation_msg[SMSLang]
                                                        : ""
                                                }
                                            />
                                            <label htmlFor="optOutMessage" className="form__label" style={{ marginTop: "15px" }}>Opt Out Confirmation Message:
                                            <Tooltip
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "5px"
                                                    }}
                                                >
                                                Automated message confirming the contact has opted OUT of receiving further messages.<br />
                                                [name] will be replaced with the users first and last name when the message is sent.
                                                </Tooltip>
                                            </label>
                                            <textarea
                                                id="optOutMessage"
                                                className="form__value"
                                                onChange={event => this._onFieldUpdate(`customSMSMessages.optout_confirmation_msg.${SMSLang}`, event)}
                                                maxLength={1600}
                                                style={{ minHeight: "100px" }}
                                                value={
                                                    customSMSMessages?.optout_confirmation_msg[SMSLang]
                                                        ? customSMSMessages.optout_confirmation_msg[SMSLang]
                                                        : ""
                                                }
                                            />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Row>
                    )}
                </Wrapper>
                {this.state.reassignRoleModalOpen && (
                    <RoleSelectionModal
                        roles={this.state.roles || {}}
                        userCount={(this.state.selectedRows || []).length}
                        selectRole={rid => this.reassignRole(rid)}
                        closeModal={() => this.setState({ reassignRoleModalOpen: false })}
                    />
                )}
                {this.state.addUsersModalOpen && (
                    <RoleUserSearchModal
                        cid={this.state.cid}
                        excludeRid={this.state.rid}
                        roles={this.state.roles || {}}
                        isSelected={user => this.isSelected(user)}
                        selectUser={user => this.selectUser(user)}
                        selectAll={users => (
                            <Link
                                onClick={() => {
                                    let selectedRows = users;
                                    if (selectedRows.length == this.state.selectedRows.length) {
                                        selectedRows = [];
                                    }
                                    this.setState({ selectedRows });
                                }}
                            >
                                Select All...
                            </Link>
                        )}
                        selectRole={() => this.addRole()}
                        closeModal={() => this.setState({ addUsersModalOpen: false })}
                    />
                )}
            </Wrapper>
        );
    }
    _renderEntityConfigList(sms: boolean, disabled = false) {
        const { entityConfigList, entityConfigLink, smsEntityConfigLink } = this.state;
        const defaultValue = sms ? smsEntityConfigLink : entityConfigLink;
        const key = sms ? "smsEntityConfigLink" : "entityConfigLink";
        const placeholder = `Select ${sms ? `SMS ` : ``}Entity Config`;

        //TODO: resolve inconsistency between value and label
        return (
            <div id="entityConfig" className="select__wrapper">
                <select value={defaultValue} onChange={e => this._onFieldUpdate(key, e)} disabled={disabled}>
                    <option value="">{placeholder}</option>
                    {(entityConfigList || []).map(option => (
                        <option value={option[sms ? "value" : "label"]}>
                            {option.label}
                        </option>
                        )
                    )}
                </select>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.session.admin
});

export default connect(mapStateToProps)(EditRole);
