import React, { useState, useEffect } from "react";
import Button from "./Button";
import { createAlert } from "../../actions/utils";

interface ICopyButtonProps {
    value: string;
}
export default (props: ICopyButtonProps) => {
    const [value, setValue] = useState(props.value || "");

    useEffect(() => {
        setValue(props.value);
    }, []);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const copyToClipboard = () => {
        // create temp input element
        const _input = document.createElement("input");
        _input.setAttribute("style", "position: absolute; left: -1000px; top: -1000px");
        _input.value = value;
        document.body.appendChild(_input);

        // select text
        _input.select();

        // copy
        document.execCommand("copy");

        // cleanup
        document.body.removeChild(_input);
        createAlert(`The text has been successfully copied`, `success`);
    };

    if (!document.queryCommandSupported("copy")) {
        return null;
    }
    return (
        <Button
            style={{ margin: "0" }}
            onClick={copyToClipboard}
            ariaLabel="Copy to clipboard"
        >
            <i style={{ pointerEvents: "none" }} className="fa fa-copy" aria-hidden="true"></i>
        </Button>
    );
};