import { store, history } from "../utils/store";
import appConfig from "../../config/config.dev";
import { doGetReduxClient, doGetReduxClients, doClear } from "./lists";
import { apiRequest, apiRequestWithErrorAlert } from "../utils/Helpers";
import { createAlert } from "./utils";

export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const ACCOUNT_ADMIN = "ACCOUNT_ADMIN";
export const LOAD_PAGE = "LOAD_PAGE";
export const CLEAR_PAGE = "CLEAR_PAGE";
export const SET_PRIOR_PAGE = "SET_PRIOR_PAGE";

function _priorPage(page: string) {
    return { type: SET_PRIOR_PAGE, page };
}
function _loadPage() {
    return { type: LOAD_PAGE };
}
function _clearPage() {
    return { type: CLEAR_PAGE };
}

function _accountAdmin(admin: Mongo.clientAdmin | {}) {
    return { type: ACCOUNT_ADMIN, admin };
}

export function doClearPage() {
    store.dispatch(_clearPage());
}
export function doLoadPage() {
    store.dispatch(_loadPage());
}
export function doSetPriorPage(page: string) {
    store.dispatch(_priorPage(page));
}

export function doClearReleaseNotes(admin: Mongo.clientAdmin) {
    delete admin["releaseNotes"];
    store.dispatch(_accountAdmin(admin));
}

export function doCheckAuth() {
    apiRequest(`${appConfig.API_URL}/session/isAuthenticated`).then(
        (data: { valid: boolean; admin: Mongo.clientAdmin | null }) => {
            if (data && data.valid && data.admin) {
                userLogin(data.admin);
            }
        }
    );
}

export function doClearCookie(cname: string) {
    apiRequest(`${appConfig.API_URL}/session/clearCookie`, "POST", { cname }).then(
        (data: { valid: boolean }) => {
            if (data && data.valid) {
                return;
            }
        }
    );
}

export async function doCheckSSOSigninEnabled() {
    const response = await apiRequestWithErrorAlert<{ isAvailable: boolean }>(
        `${appConfig.API_URL}/session/useAdminSSO`,
        "GET",
        undefined,
        "We're experiencing a technical difficulty while determining SSO availability"
    );
    return response && response.isAvailable || false;
}

export async function doSSOSignIn() {
    const logout = localStorage.getItem("logout");
    localStorage.removeItem("logout");
    const cid = localStorage.getItem("ssoClient");
    const isSSO = !!cid || await doCheckSSOSigninEnabled();
    if (!isSSO) return false;
    if (logout) {
        history.push("/login/sso");
    } else if (cid) {
        window.location.href = `${appConfig.API_URL}/${cid}`;
    } else {
        window.location.href = `${appConfig.API_URL}/SSO`;
    }
    return true;
}

function userLogin(admin: Mongo.clientAdmin) {
    localStorage.removeItem("logout");
    if (admin) {
        doLoadPage();
        store.dispatch(_accountAdmin(admin));
        if (admin.isSuper || admin.isApiAdmin) {
            doGetReduxClients();
        } else if (admin.cid) {
            doGetReduxClient(admin.cid);
        }
    }
}

export async function doLogout(error = "") {
    await apiRequest(`${appConfig.API_URL}/session/signOut`).catch();
    if (error && !localStorage.getItem("logout")) {
        createAlert(error, `error`);
    }
    localStorage.setItem("logout", "true");
    localStorage.removeItem('reportQuery');
    localStorage.removeItem("libraryPage");
    localStorage.removeItem("contentSearchPage");
    localStorage.removeItem("adminToken");
    sessionStorage.clear();
    doClear();
    doClearPage();
    doSetPriorPage("");
}

export function doLogin(username: string, password: string) {
    setTimeout(() => {
        localStorage.removeItem("adminToken");
        localStorage.removeItem('reportQuery');
    }, 0);
    return apiRequest(`${appConfig.API_URL}/session/signIn`, `POST`, {
        username,
        password
    })
        .then(data => {
            if (data.valid) {
                userLogin(data.admin);
            } else {
                if (data.error) {
                    createAlert(data.error, `error`);
                } else if (!("redirectUrl" in data)) {
                    createAlert("A secure connection could not be established", `error`);
                }
            }
            return data;
        })
        .catch(error => {
            createAlert(error.message, `error`);
        });
}

export function doPasswordReset(username: string, email: string) {
    return apiRequest(
        `${appConfig.API_URL}/session/resetPassword?username=${encodeURIComponent(username)}&email=${encodeURIComponent(email)}`
    )
        .then(data => {
            if (!data.valid) {
                createAlert(data.error, `error`);
            }
            return data;
        })
        .catch(error => {
            createAlert(error.message, `error`);
        });
}

export async function changeAdminPassword(oldPass: string, newPass: string) {
    const data = await apiRequestWithErrorAlert(`${appConfig.API_URL}/session/changeAdminPassword`, `POST`, { oldPass, newPass }).catch(() => null);
    if (data?.valid) {
        store.dispatch(_accountAdmin(data.admin));
        return true;
    }
    return false;
}

export async function doRecoverAccount(identity: string, recaptcha: string) {
    return apiRequest(`${appConfig.API_URL}/session/recoverAccount`, "GET", { identity: identity.trim(), recaptcha });
}