import BaseStream from "./BaseStream";
import { getLanguages } from "../../../../utils/Helpers";

export default class TrendingStream extends BaseStream {
    getInitialState() {
        const props = super.getInitialState();
        return {
            ...props,
            ...(props.stream
                ? {
                      stream: {
                          ...props.stream,
                          isApprovalRequired: true
                      }
                  }
                : null),
            canPreviewOnCreate: false
        };
    }

    getSettings() {
        const settings = super.getSettings();
        delete settings.adminContentTags;
        // delete settings.tags;
        delete settings.locations;
        delete settings.filters;

        const { stream } = this.state;
        return {
            ...settings,
            lang: {
                label: "Language",
                type: "select" as const,
                value: stream.lang,
                values: this.getLangs()
            },
            active: {
                label: "Active",
                value: !!stream.active,
                description: "Enables the stream to be shown in the app",
                onChange: () =>
                    this.setState({ stream: { ...stream, active: !stream.active } })
            },
        };
    }
    getLangs() {
        const { stream, streams } = this.state;
        const langs: Array<{ value: string; label: string }> = getLanguages()
            .map(lang => ({
                value: lang.code,
                label: lang.name
            }))
            .filter(
                lang =>
                    streams
                        .filter(s => !s.contentSearch && s.searchType == stream.searchType && s.type == stream.type)
                        .map(s => s.lang)
            );

        return langs;
    }
}
