import React, { Component } from "react";
import { clamp } from "lodash";

interface IPaginationProps {
    page: number;
    currItems?: number;
    totalPages?: number;
    totalItems?: number;
    numPerPage: number;
    disabled?: boolean;
    showTotal?: boolean;
    style?: dynamic;
    changePage: (newPage: number) => void;
}

interface IPaginationState {
    currPage: number;
    currItems: number;
    totalPages: number;
    totalItems: number;
    numPerPage: number;
    disabled: boolean;
    showTotal: boolean;
}
const PADDING = 3;
export default class Pagination extends Component<IPaginationProps, IPaginationState> {
    constructor(props: IPaginationProps) {
        super(props);

        const state = {
            currPage: props.page || 0,
            currItems: props.currItems || 0,
            totalPages: props.totalPages || 0,
            totalItems: props.totalItems || 0,
            numPerPage: props.numPerPage || 50,
            disabled: props.disabled || false,
            showTotal: props.showTotal || false
        };
        if (props.totalItems) {
            state.totalPages = Math.floor((props.totalItems - 1) / state.numPerPage);
        }
        this.state = state;
    }

    componentWillReceiveProps(newProps: IPaginationProps) {
        if (newProps != this.props) {
            const newState: dynamic = {
                ...("page" in newProps && newProps.page !== this.state.currPage ? { currPage: newProps.page } : null),
                ...("totalPages" in newProps && newProps.totalPages !== this.state.totalPages
                    ? { totalPages: newProps.totalPages }
                    : null),
                ...("numPerPage" in newProps && newProps.numPerPage !== this.state.numPerPage
                    ? { numPerPage: newProps.numPerPage }
                    : null),
                ...("totalItems" in newProps && newProps.totalItems !== this.state.totalItems
                    ? { totalItems: newProps.totalItems }
                    : null),
                ...("currItems" in newProps && newProps.currItems != this.state.currItems
                    ? { currItems: newProps.currItems }
                    : null),
                ...("disabled" in newProps && newProps.disabled !== this.state.disabled
                    ? { disabled: newProps.disabled }
                    : null),
                ...("showTotal" in newProps && newProps.showTotal != this.state.showTotal
                    ? { showTotal: newProps.showTotal }
                    : null)
            };
            if ("totalItems" in newState) {
                newState.totalPages = Math.floor(
                    (newProps.totalItems! - 1) / (newState.numPerPage || this.state.numPerPage)
                );
            }

            if (Object.keys(newState).length > 0) {
                this.setState(newState as IPaginationState);
            }
        }
    }
    changePage(newPage: number) {
        if (this.state.disabled || newPage === this.state.currPage) {
            return;
        }
        this.setState(
            {
                currPage: Number(newPage)
            },
            () => this.props.changePage(newPage)
        );
    }

    getDisplayPages() {
        const { currPage, totalPages } = this.state;
        const min = clamp(currPage - PADDING, 0, currPage);
        const max = clamp(currPage + PADDING, currPage, totalPages);
        return new Array(max - min + 1).fill(0).map((_, idx) => min + idx);
    }

    _renderSelect() {
        const pages: JSX.Element[] = [];
        if (this.state.currPage > 0) {
            pages.push(
                <li key="start">
                    <span
                        onClick={event => this.changePage(Number(this.state.currPage) - 1)}
                        tabIndex={0}
                        onKeyDown={event => {
                            if (event.which === 13 || event.which === 32) {
                                this.changePage(Number(this.state.currPage) - 1);
                            }
                        }}>
                        <i className="fa fa-caret-left" />
                    </span>
                </li>
            );
        } else {
            pages.push(
                <li key="start">
                    <span className="disabled">
                        <i className="fa fa-caret-left" />
                    </span>
                </li>
            );
        }
        const options: JSX.Element[] = [];
        for (let i = 0; i <= this.state.totalPages; i++) {
            options.push(<option key={`option-${i}`} value={i}>{`${i + 1} of ${this.state.totalPages + 1}`}</option>);
        }
        if (this.state.disabled) {
            pages.push(
                <li className="active" key="active">
                    <select value={this.state.currPage} disabled={true} aria-label="Select page" >
                        {options}
                    </select>
                </li>
            );
        } else {
            pages.push(
                <li className="active" key="active">
                    <select onChange={event => this.changePage(Number(event.target.value))} value={this.state.currPage} aria-label="Select page">
                        {options}
                    </select>
                </li>
            );
        }

        if (this.state.currPage <= this.state.totalPages - 1) {
            pages.push(
                <li key="end">
                    <span
                        onClick={event => this.changePage(Number(this.state.currPage) + 1)}
                        tabIndex={0}
                        onKeyDown={event => {
                            if (event.which === 13 || event.which === 32) {
                                this.changePage(Number(this.state.currPage) + 1);
                            }
                        }}>
                        <i className="fa fa-caret-right" />
                    </span>
                </li>
            );
        } else {
            pages.push(
                <li key="end">
                    <span className="disabled">
                        <i className="fa fa-caret-right" />
                    </span>
                </li>
            );
        }
        return pages;
    }

    render() {
        if (!this.state.totalItems || this.state.totalItems <= this.state.numPerPage) {
            return <div />;
        }
        return (
            <div className="pagination">
                <ul>{this._renderSelect()}</ul>
                {this.state.showTotal && this.state.totalItems > 0 && (
                    <div className="total" tabIndex={0}>{`Showing ${this.state.numPerPage * this.state.currPage + 1}-${
                        this.state.totalItems < this.state.numPerPage * (this.state.currPage + 1)
                            ? this.state.totalItems
                            : this.state.numPerPage * (this.state.currPage + 1)
                    }  of ${this.state.totalItems}`}</div>
                )}
            </div>
        );
    }
}
