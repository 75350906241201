import SMSReportsView from "./Reports";
import SMSContactImport from "./Import";
import SMSContactExport from "./Import";

export default [
    {exact: true, path: "/sms", component: SMSReportsView},
    { path: "/sms/reports", component: SMSReportsView },
    { path: "/sms/contactImport", component: SMSContactImport },
    { path: "/sms/contactExport", component: SMSContactExport }
];
