import React, { Component } from "react";
import { connect } from "react-redux";
import { doUpsertCompany } from "../../../actions/inserts";
import { doGetCompany } from "../../../actions/lists";
import Multiselect from "react-bootstrap-multiselect";
import { preventDefault, getMultiSelectValues, getIndustries, sanitizeHtml } from "../../../utils/Helpers";
import { Company } from "../../../utils/Models";
import { doSetFooterFunctions } from "../../../actions/utils";
import { UtilActions, createAlert } from "../../../actions";
import PageHeading from "../../molecules/PageHeading";
import ActionableList from "../../molecules/ActionableList";
import { Wrapper, Row } from "../../atoms/Layout/";

class EditCompany extends Component {
    constructor(props) {
        super(props);

        this._onFieldChange = this._onFieldChange.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        let companyId = "";
        let insert = true;
        if (props.match.params.companyId) {
            companyId = props.match.params.companyId;
        }

        this.state = {
            ...Company(),
            companyId,
            insert: insert,
            save_protect: false
        };
        if (props.companies[this.state.companyId]) {
            this.state = props.companies[this.state.companyId];
            this.state.insert = false;
        }
        this.state.filterLang = "";
    }
    componentWillReceiveProps(newProps) {
        if (newProps.companies !== this.props.companies) {
            this.setState({ ...newProps.companies[this.state.companyId], insert: false });
        }
    }
    async componentDidMount() {
        if (this.nameInput) {
            this.nameInput.focus();
        }
        if (this.state.companyId) {
            doGetCompany(this.state.companyId);
        }
        this.setState({ countries: await UtilActions.doGetCountries() });
        doSetFooterFunctions(this._onSubmit, null, this.state.insert ? "Create" : "Update");
    }

    componentWillUnmount() {
        doSetFooterFunctions(null, null);
    }

    async _onFieldChange(key, event) {
        let val = event.target ? event.target.value : event;
        const query = {};
        if (key == "summary") {
            let replSummaries = [];
            const oldSummary = this.state.summary;
            if (oldSummary.filter(i => i.language == this.state.filterLang).length == 0) {
                oldSummary.push({ language: this.state.filterLang, summary: "" });
            }

            for (let { language, summary } of oldSummary) {
                if (language == this.state.filterLang) {
                    summary = val;
                }
                replSummaries.push({ language, summary });
            }
            val = replSummaries;
        } else if (key == "specialties") {
            let replSpecialties = [];
            const oldSpecialties = this.state.specialties;
            if (oldSpecialties.filter(i => i.language == this.state.filterLang).length == 0) {
                oldSpecialties.push({ language: this.state.filterLang, specialties: [] });
            }

            for (let { language, specialties } of oldSpecialties) {
                if (language == this.state.filterLang) {
                    specialties = val;
                }
                replSpecialties.push({ language, specialties });
            }
            val = replSpecialties;
        } else if (key == "industry") {
            query["subIndustry"] = [];
        }
        query[key] = val;
        this.setState(query);
    }

    _onSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        let company = Company(this.state);
        if (!company.name) {
            createAlert(`Company name cannot be blank.`, `error`);
            return;
        } else {
            company.name = sanitizeHtml(company.name);
            if (!company.name) {
                createAlert(`Please remove special characters from Company name`, `error`);
            }
        }
        for (let [i, { specialties }] of company.specialties.entries()) {
            company.specialties[i].specialties = specialties.map(i => i.trim());
        }

        for (let [i, { summary }] of company.summary.entries()) {
            company.summary[i].summary.trim();
        }
        return doUpsertCompany(company);
    }
    getAvailableSubIndustries(selectedIndustry) {
        selectedIndustry = selectedIndustry || this.state.industry;
        const industry = getIndustries().filter(v => v.value == selectedIndustry);
        if (industry && industry.length && industry[0].subIndustries) {
            return industry[0].subIndustries;
        }
        return [];
    }
    render() {
        const { name } = this.state;
        let specialties = this.state.specialties.filter(i => i.language == this.state.filterLang);
        if (specialties.length == 0) {
            specialties = null;
        }
        return (
            <Wrapper>
                <PageHeading
                    title={`${this.state.insert ? "Create" : "Edit"} Company`}
                    buttons={[
                        {
                            title: "Back to company list",
                            link: "/companies/"
                        }
                    ]}
                />
                <Wrapper>
                    <Row>
                        <div className="col-sm-12">
                            <div className="ibox">
                                <form
                                    className="ibox-content form"
                                    onSubmit={event => this._onSubmit(event)}
                                    onKeyPress={preventDefault}
                                >
                                    <div className="form__row">
                                        <div className="form__group">
                                            <h3 className="form__label">Profile</h3>
                                            <br />
                                            <label htmlFor="name" className="form__label">
                                                Name<sup>*</sup>:
                                            </label>
                                            <input
                                                id="name"
                                                type="text"
                                                className="form__value"
                                                ref={input => {
                                                    this.nameInput = input;
                                                }}
                                                onChange={event => this._onFieldChange("name", event)}
                                                value={name}
                                            />
                                            <br />
                                            <label htmlFor="industry" className="form__label">
                                                Industry<sup>*</sup>:
                                            </label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="industry"
                                                    onChange={e => this._onFieldChange("industry", e.target.value)}
                                                    value={this.state.industry}
                                                >
                                                    <option value="">Please select an industry</option>
                                                    {getIndustries().map(i => (
                                                        <option key={i.value} value={i.value}>
                                                            {i.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <br />
                                            <label htmlFor="subindustries" className="form__label">Sub-Industry:</label>

                                            <div className="select-wrapper">
                                            <Multiselect
                                                id="subindustries"
                                                ref="groups"
                                                buttonText={() => {
                                                    return this.getAvailableSubIndustries().length == 0
                                                        ? "No Available Sub-Industries"
                                                        : `${this.state.subIndustry.length} selected`;
                                                }}
                                                data={this.getAvailableSubIndustries().map(i => ({
                                                    label: i,
                                                    title: i,
                                                    value: i,
                                                    selected: this.state.subIndustry.indexOf(i) > -1
                                                }))}
                                                maxHeight={200}
                                                multiple="multiple"
                                                onChange={e =>
                                                    this._onFieldChange(
                                                        "subIndustry",
                                                        getMultiSelectValues(e[0].parentElement)
                                                    )
                                                }
                                            />
                                            </div>
                                            <br />
                                            <label htmlFor="size" className="form__label">Size:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="size"
                                                    onChange={e => this._onFieldChange("size", e)}
                                                    value={this.state.size}
                                                >
                                                    <option value="">Please select a size</option>
                                                    <option>Self-employed</option>
                                                    <option>1 - 10 employees</option>
                                                    <option>11 - 50 employees</option>
                                                    <option>51 - 200 employees</option>
                                                    <option>201 - 500 employees</option>
                                                    <option>501 - 1,000 employees</option>
                                                    <option>1,001 - 5,000 employees</option>
                                                    <option>5,001 - 10,000 employees</option>
                                                    <option>10,001 - 50,000 employees</option>
                                                    <option>50,001 - 100,000 employees</option>
                                                    <option>100,001+ employees</option>
                                                </select>
                                            </div>
                                            <br />
                                            <label htmlFor="stockTicker" className="form__label">Stock Ticker:</label>
                                            <input
                                                id="stockTicker"
                                                type="text"
                                                className="form__value"
                                                onChange={event => this._onFieldChange("ticker", event)}
                                                value={this.state.ticker}
                                            />
                                            <br />
                                            <label htmlFor="domain" className="form__label">Domain:</label>
                                            <input
                                                id="domain"
                                                type="text"
                                                className="form__value"
                                                onChange={event => this._onFieldChange("domain", event.target.value)}
                                                value={this.state.domain}
                                            />
                                            <br />
                                            <label htmlFor="url" className="form__label">URL:</label>
                                            <input
                                                id="url"
                                                type="text"
                                                className="form__value"
                                                onChange={event => this._onFieldChange("url", event.target.value)}
                                                value={this.state.url}
                                            />
                                        </div>
                                        <div className="form__group">
                                            <h3 className="form__label">Address</h3>
                                            <br />
                                            <label htmlFor="country" className="form__label">Country:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="country"
                                                    onChange={e => this._onFieldChange("country", e)}
                                                    value={this.state.country}
                                                >
                                                    <option value="">Please select a country</option>
                                                    {this.state.countries &&
                                                        this.state.countries.map(category => {
                                                            return (
                                                                <option key={category.code} value={category.code}>
                                                                    {category.name}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                            <br />
                                            <label htmlFor="state" className="form__label">State:</label>
                                            <input
                                                id="state"
                                                type="text"
                                                className="form__value"
                                                onChange={event => this._onFieldChange("state", event)}
                                                value={this.state.state}
                                            />
                                            <br />
                                            <label htmlFor="city" className="form__label">City:</label>
                                            <input
                                                id="city"
                                                type="text"
                                                className="form__value"
                                                onChange={event => this._onFieldChange("city", event)}
                                                value={this.state.city}
                                            />
                                        </div>
                                        <div className="form__group">
                                            <h3 className="form__label">Details</h3>
                                            <br />
                                            <label htmlFor="language" className="form__label">Language:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="language"
                                                    onChange={e => this._onFieldChange("filterLang", e)}
                                                    value={this.state.filterLang}
                                                >
                                                    <option value="">Please select a language</option>
                                                    <option value="en">English</option>
                                                    <option value="fr">French</option>
                                                    <option value="es">Spanish</option>
                                                    <option value="de">German</option>
                                                    <option value="pt">Portuguese</option>
                                                    <option value="it">Italian</option>
                                                </select>
                                            </div>
                                            <br />
                                            <label className="form__label">Terms:</label>

                                            <ActionableList
                                                placeholder="Please enter an term"
                                                disabled={true}
                                                inactive={this.state.filterLang.length == 0}
                                                items={
                                                    this.state.filterLang.length == 0
                                                        ? []
                                                        : (specialties || [{ specialties: [] }])[0].specialties
                                                }
                                                updateAction={(items, remove) => {
                                                    const specialties = (items || [])
                                                        .map(i => i.trim())
                                                        .filter(i => i.length);
                                                    this._onFieldChange("specialties", specialties);
                                                }}
                                            />
                                            <br />
                                            <label htmlFor="description" className="form__label">Description:</label>
                                            <textarea
                                                id="description"
                                                className="form__value"
                                                onChange={event => this._onFieldChange("summary", event)}
                                                style={{ height: "113px" }}
                                                disabled={this.state.filterLang.length == 0}
                                                value={
                                                    (
                                                        this.state.summary.filter(
                                                            i => i.language == this.state.filterLang
                                                        )[0] || { summary: "" }
                                                    ).summary
                                                }
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Row>
                </Wrapper>
            </Wrapper>
        );
    }
}
EditCompany.allowSuper = true;
EditCompany.allowApiAdmin = true;
EditCompany.allowClientAdmin = false;
EditCompany.allowAdmin = false;
EditCompany.allowCurator = false;
EditCompany.allowReports = false;
const mapStateToProps = state => ({
    companies: state.lists.companies,
    user: state.session.admin
});

export default connect(mapStateToProps)(EditCompany);
