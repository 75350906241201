import React, { Component } from 'react';

interface IResizeImgProps {
    id?: string;
    src: string;
    className?: string;
    width?: string;
}

interface IResizeImgState {
    src: string;
    hasErrored: boolean;
}

export default class ResizeImg extends Component<IResizeImgProps, IResizeImgState> {
    constructor(props: IResizeImgProps) {
        super(props);
        this.state = {
            src: `https://imager.gv6.co/resize_image/${this.props.width || "300"}/${this.props.src}`,
            hasErrored: false
        };
    }

    onError() {
        if (this.state.hasErrored) return;
        this.setState({
            src: this.props.src,
            hasErrored: true
        })
    }

    render() {
        return <img
            id={this.props.id}
            className={this.props.className}
            src={this.state.src}
            onError={() => this.onError()}
            tabIndex={0}
            alt="Article image"
            />
    }
}
