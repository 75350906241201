import React, { Component } from "react";

import Tooltip from "../atoms/Tooltip";
import { Row } from "../atoms/Layout/";
import Button from "../atoms/Button";
import CreatableInput from "../atoms/CreatableInput";
import { apiRequest } from "../../utils/Helpers";
import appConfig from "../../../config/config.dev";
import { createAlert } from "../../actions/utils";
import { throttle, getFriendlyDate, getBooleanIcon, translateLanguage, translateSearchType } from "../../utils/Helpers";
import DomainFilterActionableList from "../molecules/DomainFilterActionableList";
import ActionableList from "../molecules/ActionableList";
import CopyButton from "../atoms/CopyButton";

export default class DomainFilter extends Component {
    constructor(props) {
        super(props);

        this._refresh = this._refresh.bind(this);
        this._saveFilters = this._saveFilters.bind(this);

        let filters = props.filters || {};
        let lang = props.lang || "en";
        // Only the stream update passes over the filters property, if its more than 2 characters, we have an instance of the filters and need to pull it out
        Object.keys(filters).some(filter => {
            if (filter.length > 2 && (filter == "wDomains" || filter == "bDomains" || filter == "bTerms")) {
                filters = { [lang]: filters };
                return true;
            }
            return false;
        });

        this.state = {
            cid: props.cid,
            headless: props.headless || false,
            filterDisplay: props.filterDisplay || false,
            filterType: props.filterType,
            filterAvailableLangs: [],
            initialLangs: [],
            filterLang: lang,
            filters: filters,
            loading: false,
            disabled: props.disabled
        };
    }

    componentDidMount() {
        this._refresh();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.cid != this.props.cid) {
            this.setState(
                {
                    cid: newProps.cid
                },
                this._refresh
            );
        } else if (newProps.lang != this.props.lang) {
            this.setState({ filterLang: newProps.lang }, this._refresh);
        } else if (newProps.filters && JSON.stringify(this.state.filters[newProps.lang || "en"]) != JSON.stringify(newProps.filters)) {
            this.setState({ filters: { [newProps.lang || "en"]: { ...newProps.filters } }, disabled: newProps.disabled || false }, this._refresh);
        }
    }
    _refresh() {
        if (this.state.cid && !this.props.filters) {
            apiRequest(
                `${appConfig.API_URL}/getStreamFilters?cid=${this.state.cid}&type=${this.state.filterType}`
            ).then(
                function (data) {
                    if (data && data.langs) {
                        let langs = [];
                        let initialLangs = [];
                        if (!data.filters) {
                            data.filters = {};
                        }
                        Object.keys(data.langs).forEach(function (key, i) {
                            if (data.langs[key]) {
                                langs.push(key);
                                initialLangs.push(key);
                                if (!data.filters[key]) {
                                    data.filters[key] = {};
                                }
                            }
                        });
                        this.setState({ initialLangs: initialLangs });
                        if (Object.keys(data.filters).length) {
                            Object.keys(data.filters).forEach(function (key, i) {
                                if (!langs.includes(key) && (data.filters[key].bDomains?.length || data.filters[key].wDomains?.length || data.filters[key].bTerms?.length)) {
                                    langs.push(key);
                                }
                            });
                        };
                        let setObj = { filters: data.filters, filterAvailableLangs: langs };
                        if (!this.props.lang) {
                            setObj.filterLang = langs[0];
                        }
                        this.setState(setObj);
                    }
                }.bind(this)
            );
        }
    }

    _update(key, domainArray) {
        if (key == "wDomains" || key == "bDomains") {
            if (domainArray && domainArray.length) {
                const newDomain = domainArray[domainArray.length - 1];
                // regex patterns
                const protocolPattern = /^\/\/|^.*?:(\/\/)?/;
                const domainPattern = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
                // check length
                if (newDomain.length > 50) {
                    createAlert(`Maximum allowed character limit is 50`, "error");
                    return false;
                }
                // check for protocols in domains
                if (protocolPattern.test(newDomain)) {
                    createAlert(`You cannot include protocols such as ${newDomain} in your filter`, "error");
                    return false;
                }
                // check for trailing slashes
                if (newDomain[newDomain.length - 1] == "/") {
                    createAlert(`You cannot include a domain ending in '/' such as ${newDomain}`, "error");
                    return false;
                }
                // check for valid domains (no subdomains)
                if (!domainPattern.test(newDomain)) {
                    createAlert(`Formatting error in domain filter, invalid domain: ${newDomain}`, "error");
                    return false;
                }
            }
        }
        let filters = Object.assign({}, this.state.filters);

        if (!filters[this.state.filterLang]) {
            filters[this.state.filterLang] = {};
        }
        if (!filters[this.state.filterLang][key]) {
            filters[this.state.filterLang][key] = [];
        }
        filters[this.state.filterLang][key] = domainArray;
        if (this.props.saveFilters) {
            this.setState(
                {
                    filters: filters
                },
                () => this.props.saveFilters(this.state.filters[this.state.filterLang])
            );
        } else {
            this.setState(
                {
                    filters: filters
                },
                () => this._saveFilters()
            );
        }
        return true;
    }

    _saveFilters() {
        apiRequest(`${appConfig.API_URL}/setGlobalFilters`, `POST`, {
            cid: this.state.cid,
            type: this.state.filterType,
            lang: this.state.filterLang,
            filters: this.state.filters[this.state.filterLang]
        }).then(
            function (data) {
                if ("valid" in data && !data.valid) {
                    createAlert(data.error, `error`);
                    this._refresh();
                    return;
                }
                createAlert(`Updated the filter settings for ${translateSearchType(this.state.filterType)}`, `success`);
            }.bind(this)
        );
    }
    render() {
        const { headless, filterDisplay, filters, filterLang, filterAvailableLangs, disabled, initialLangs } = this.state;
        if (filterDisplay) {
            return (
                <div className="table__filters">
                    <div className="table__filters__option">
                        <label htmlFor="wDomains" className="form__label">Allow List Domain Filters:</label>
                        <DomainFilterActionableList
                            updateAction={v => this._update("wDomains", v)}
                            id="wDomains"
                            lang={filterLang}
                            disabled={disabled}
                            items={
                                (
                                    filters[filterLang] || {
                                        wDomains: []
                                    }
                                ).wDomains
                            }
                        />
                    </div>
                    <div className="table__filters__option">
                        <label htmlFor="bDomains" className="form__label">Block List Domain Filters:</label>
                        <DomainFilterActionableList
                            updateAction={v => this._update("bDomains", v)}
                            id="bDomains"
                            lang={filterLang}
                            disabled={this.props.disabled}
                            items={
                                (
                                    filters[filterLang] || {
                                        bDomains: []
                                    }
                                ).bDomains
                            }
                        />
                    </div>
                    <div className="table__filters__option">
                        <label htmlFor="blocklistTerms" className="form__label">Block List Terms:</label>
                        <ActionableList
                            id="blocklistTerms"
                            updateAction={v => this._update("bTerms", v)}
                            items={
                                (
                                    filters[filterLang] || {
                                        bTerms: []
                                    }
                                ).bTerms
                            }
                            disabled={true}
                        />
                    </div>
                </div>
            );
        } else if (headless) {
            return (
                <div>
                    <label htmlFor="wDomains" className="form__label">Allow List Domain Filters:</label>
                    <DomainFilterActionableList
                        updateAction={v => this._update("wDomains", v)}
                        id="wDomains"
                        lang={filterLang}
                        items={
                            (
                                filters[filterLang] || {
                                    wDomains: []
                                }
                            ).wDomains
                        }
                    />
                    <label htmlFor="bDomains" className="form__label">Block List Domain Filters:</label>
                    <DomainFilterActionableList
                        updateAction={v => this._update("bDomains", v)}
                        id="bDomains"
                        lang={filterLang}
                        items={
                            (
                                filters[filterLang] || {
                                    bDomains: []
                                }
                            ).bDomains
                        }
                    />
                    <label htmlFor="blocklistTerms" className="form__label">Block List Terms:</label>
                    <ActionableList
                        id="blocklistTerms"
                        updateAction={v => this._update("bTerms", v)}
                        items={
                            (
                                filters[filterLang] || {
                                    bTerms: []
                                }
                            ).bTerms
                        }
                        disabled={true}
                    />
                </div>
            );
        }
        if (filterAvailableLangs.length == 0) {
            return (
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5 aria-label={`${translateSearchType(this.state.filterType)} Domain Filters`} tabIndex="-1">
                                    {`${translateSearchType(this.state.filterType)} Domain Filters`}{" "}
                                    <Tooltip icon="fa-question-circle">
                                        Provide a domain that you want to filter against.
                                        <br />
                                        <br />
                                        Valid:
                                        <br />
                                        "sub.domain.com"
                                        <br />
                                        "domain.com"
                                        <br />
                                        <br />
                                        Invalid:
                                        <br /> "https://sub.domain.com"
                                        <br /> "http://sub.domain.com"
                                        <br /> "https://sub.domain.com/"
                                        <br /> "sub.domain.com/"
                                    </Tooltip>
                                </h5>
                            </div>
                            <div className="ibox-content">
                                <h5>{`You need to setup some ${translateSearchType(
                                    this.state.filterType
                                ).toLowerCase()} streams before you can setup domain filters`}</h5>
                            </div>
                        </div>
                    </div>
                </Row>
            );
        }
        const filter =
            filters[filterLang] && Object.keys(filters[filterLang]).length
                ? filters[filterLang]
                : { wDomains: [], bDomains: [], bTerms: [] };
        return (
            <Row>
                <div className="col-lg-12">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5 aria-label={`${translateSearchType(this.state.filterType)} Domain Filters`} tabIndex="-1">
                                {`${translateSearchType(this.state.filterType)} Domain Filters`}{" "}
                                <Tooltip icon="fa-question-circle">
                                    Provide a domain that you want to filter against.
                                    <br />
                                    <br />
                                    Valid:
                                    <br />
                                    "sub.domain.com"
                                    <br />
                                    "domain.com"
                                    <br />
                                    <br />
                                    Invalid:
                                    <br /> "https://sub.domain.com"
                                    <br /> "http://sub.domain.com"
                                    <br /> "https://sub.domain.com/"
                                    <br /> "sub.domain.com/"
                                </Tooltip>
                            </h5>
                        </div>
                        <div className="ibox-content">
                            <div className="table__filters">
                                <div className="select__wrapper">
                                    <select
                                        className="filters__search"
                                        aria-label={filterLang}
                                        value={filterLang}
                                        onChange={event => this.setState({ filterLang: event.target.value })}
                                    >
                                        <option value="">Filter By Language</option>
                                        {filterAvailableLangs &&
                                            filterAvailableLangs.map(function (val, i) {
                                                return (
                                                    <option key={val} value={val}>
                                                        {translateLanguage(val)}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>
                            </div>
                            <hr
                                style={{
                                    marginBottom: "20px"
                                }}
                            />
                            <form
                                className="form"
                                onKeyPress={this._preventDefault}
                            >
                                <div className="form__row">
                                    <div className="form__group">
                                        <div style={{display: "flex"}}>
                                            <div style={{ width: "100%", marginRight: "20px"}}>
                                                <label htmlFor="wDomains" className="form__label">Allow List Domain Filters:</label>
                                            </div>
                                            <CopyButton value={(filter.wDomains || []).join(", ")} />
                                        </div>
                                        {!initialLangs.includes(filterLang) ? (
                                            <DomainFilterActionableList
                                                updateAction={v => this._update("wDomains", v)}
                                                id="wDomains"
                                                lang={filterLang}
                                                items={
                                                    filter.wDomains
                                                }
                                                filtersButNoStreams={true}
                                            />
                                        ) : (
                                            <DomainFilterActionableList
                                                updateAction={v => this._update("wDomains", v)}
                                                id="wDomains"
                                                lang={filterLang}
                                                items={
                                                    filter.wDomains
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="form__group">
                                        <div style={{display: "flex"}}>
                                            <div style={{ width: "100%", marginRight: "20px"}}>
                                                <label htmlFor="bDomains" className="form__label">Block List Domain Filters:</label>
                                            </div>
                                            <CopyButton value={(filter.bDomains || []).join(", ")} />
                                        </div>
                                        {!initialLangs.includes(filterLang) ? (
                                            <DomainFilterActionableList
                                                updateAction={v => this._update("bDomains", v)}
                                                id="bDomains"
                                                lang={filterLang}
                                                items={
                                                    filter.bDomains
                                                }
                                                filtersButNoStreams={true}
                                            />
                                        ) : (
                                            <DomainFilterActionableList
                                                updateAction={v => this._update("bDomains", v)}
                                                id="bDomains"
                                                lang={filterLang}
                                                items={
                                                    filter.bDomains
                                                }
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="form__row">
                                    <div className="form__group" style={{ borderRight: 0, paddingRight: 0 }}>
                                        <label htmlFor="blocklistTerms" className="form__label">Block List Terms:</label>
                                        <div
                                            className="form__value"
                                            style={{ display: "flex" }}
                                        >
                                            <div style={{ width: "100%", marginRight: "20px" }}>
                                                <CreatableInput
                                                    id="blocklistTerms"
                                                    onUpdate={items => this._update("bTerms", items)}
                                                    value={filter.bTerms || []}
                                                    placeholder={"Please enter some block list terms"}
                                                />
                                            </div>
                                            <CopyButton value={(filter.bTerms || []).join(", ")} />
                                        </div>
                                    </div>
                                    <div className="form__group"></div>
                                </div>
                                {!initialLangs.includes(filterLang) && (
                                    <div className="form__row">
                                        <h5>{`You need to setup some ${translateSearchType(
                                            this.state.filterType
                                        ).toLowerCase()} streams before you can setup domain filters`}</h5>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </Row>
        );
    }
}
