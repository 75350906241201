import React, { Component } from "react";
import { apiRequest } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";
import { createAlert } from "../../../../actions/utils";
import { connect } from "react-redux";
import { getLocaleDate, downloadFile as fileDownload, getPercent } from "../../../../utils/Helpers";
import PercentCircle from "../../../atoms/PercentCircle";
import NumberChart from "../../../molecules/NumberChart";
import { Wrapper, Row } from "../../../atoms/Layout";
import Chart from "../../../molecules/Chart";
import { IReportFilter } from "../Index";

interface IEngagementProps {
    filters: IReportFilter;
    user: Mongo.clientAdmin;
    client?: Mongo.client;
    onLoaded: () => void;
    showSearchResults: boolean;
}

interface IEngagementState {
    summary: "loading" | {
        articles: {
            shares: number;
            clicks: number;
            count: number;
        };
        media: {
            shares: number;
            clicks: number;
            count: number;
        };
        interactions: {
            likes: number;
            comments: number;
        }
    };
    readers: "loading" | {
        unique: number;
        repeat: number;
    };
    content: "loading" | {
        video: number;
        image: number;
        text: number;
        total: number;
    };
    contentCount: number;
    offerSummary: "loading" | {
        offers: number;
        leads: number;
    };
    commentAdoption: "loading" | {
        articlesWithComment: {
            articles: number;
            shares: number;
            clicks: number;
            likes: number;
            comments: number;
        };
        articlesWithoutComment: {
            articles: number;
            shares: number;
            clicks: number;
            likes: number;
            comments: number;
        };
    };
    filters?: dynamic;
}

class EngagementSection extends Component<IEngagementProps, IEngagementState> {
    constructor(props: IEngagementProps) {
        super(props);

        this.state = {
            summary: "loading",
            readers: "loading",
            content: "loading",
            contentCount: 0,
            offerSummary: "loading",
            commentAdoption: "loading"
        };
    }

    componentDidMount() {
        if (!this.props.filters || !this.props.showSearchResults) return;
        if (
            this.state.summary == "loading" ||
            this.state.readers == "loading" ||
            this.state.content == "loading" ||
            this.state.offerSummary == "loading" ||
            this.state.commentAdoption == "loading"
        ) {
            this._fetchAll(this.props);
        }
    }

    componentDidUpdate(prevProps: IEngagementProps) {
        if ((prevProps.filters != this.props.filters) || (this.props.showSearchResults && !prevProps.showSearchResults)) {
            this._fetchAll(this.props);
        }
    }

    _fetchAll(props: IEngagementProps) {
        this.setState(
            {
                summary: "loading",
                readers: "loading",
                offerSummary: "loading",
                commentAdoption: "loading",
                filters: props.filters
            },
            () => {
                const defaults = {
                    offerSummary: { offers: 0, leads: 0 },
                    readers: { unique: 0, repeat: 0 },
                    content: { video: 0, image: 0, text: 0, total: 0 },
                    summary: { articles: { shares: 0, clicks: 0, count: 0 }, media: { shares: 0, clicks: 0, count: 0 }, interactions: { likes: 0, comments: 0 } },
                    commentAdoption: { articles: 0, clicks: 0, shares: 0, likes:0, comments: 0, articlesWithComment: { shares: 0, clicks: 0, articles: 0, likes: 0, comments: 0 }, articlesWithoutComment: { shares: 0, clicks: 0, articles: 0, likes: 0, comments: 0 } }
                };
                Promise.all([
                    apiRequest(`${appConfig.API_URL}/reports/engagement/summary`, "Post", props.filters)
                        .then(data => {
                            if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                                throw data.error;
                            }
                            this.setState({
                                readers: data.readers || defaults.readers,
                                content: data.content || defaults.content,
                                summary: data.summary || defaults.summary
                            });
                        })
                        .catch(error => {
                            this.setState(defaults);
                            createAlert(error, `error`);
                        }),
                    apiRequest(`${appConfig.API_URL}/reports/engagement/offers`, "Post", props.filters)
                        .then(data => {
                            if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                                throw data.error;
                            }
                            this.setState({ offerSummary: data || defaults.offerSummary });
                        })
                        .catch(error => {
                            this.setState({ offerSummary: defaults.offerSummary });
                            createAlert(error, `error`);
                        }),
                    apiRequest(`${appConfig.API_URL}/reports/adoption/comments`, "Post", props.filters)
                        .then(data => {
                            if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                                throw data.error;
                            }
                            const newData = data;
                            if (!data.articlesWithComment) {
                                newData.articlesWithComment = defaults.commentAdoption;
                            }
                            if (!data.articlesWithoutComment) {
                                newData.articlesWithoutComment = defaults.commentAdoption;
                            }
                            this.setState({
                                commentAdoption: newData
                            })
                        })
                        .catch(error => {
                            this.setState({ commentAdoption: defaults.commentAdoption });
                            createAlert(error, `error`);
                        })
                ]).finally(props.onLoaded);
            }
        );
    }

    _getPercentDifference(num1, num2) {
        const diff = Math.abs(Number(num2) - Number(num1));
        let percentDiff;
        if (num1 <= 0 ){
            percentDiff = diff < 0.05 ? diff.toFixed(2) : (diff * 100).toFixed(0);
            return percentDiff;
        } else {
            percentDiff = diff / num1 < 0.05 ? (diff / num1).toFixed(2) : ((diff / num1) * 100).toFixed(0)
            return percentDiff;
        }
    }

    _renderEngagement() {
        const data: (number | null)[][] = [];
        const labels = [[`Content`], [`Shares`], [`Clicks`], [`Social`, `Interactions`], [`Readers`]];
        const tooltipData: (string | null)[][] = [];
        let totalContent = 0;

        if (typeof this.state.summary !== "string" && typeof this.state.readers !== "string" && typeof this.state.offerSummary !== "string") {
            const dataBase = [
                this.state.summary.articles.count,
                this.state.summary.articles.shares + this.state.summary.media.shares,
                this.state.summary.articles.clicks + this.state.summary.media.clicks,
                this.state.summary.interactions.likes + this.state.summary.interactions.comments,
                this.state.readers.unique
            ];
            const dataContent = [
                this.state.summary.media.count,
                null,
                null,
                null,
                this.state.readers.repeat
            ];

            tooltipData.push(
                [
                    `Articles: ${this.state.summary.articles.count}`,
                    null, null, null,
                    `One time readers: ${this.state.readers.unique - this.state.readers.repeat}`
                ],
                [
                    `Media: ${this.state.summary.media.count}`,
                    null, null, null,
                    `Repeat readers: ${this.state.readers.repeat}`
                ]
            );
            totalContent = this.state.summary.articles.count + this.state.summary.media.count;

            if ((this.props.client && this.props.client.allowOffers) || !this.props.client) {
                labels.push([`Offers`], [`Leads`]);
                dataBase.push(this.state.offerSummary.offers, this.state.offerSummary.leads);
            }
            data.push(dataBase, dataContent)
        }

        return <div id="EngagementHorizontalBar" className="col-lg-12" aria-label="Graph Chart">
            <Chart
                title={"Engagement"}
                tooltip={<span>
                    <p>This card shows how your audience is engaging with the content you share.</p>
                    <br />
                    <p>Content Piece: a unique piece of content </p>
                    <p>Article: a unique content piece with a clickable link </p>
                    <p>Media: a unique content piece without a clickable link (i.e. containing only image(s), video or text).</p>
                    <p>Share: number of networks and times the content piece was shared</p>
                    <p>Click: when a person clicks on an article link</p>
                    <p>Social Interactions: sum of likes and comments</p>
                    <p>Readers: the sum of one time readers and repeat readers</p>
                    <p>Repeat readers: the number of people who have clicked on 2 or more articles of the same user in the past</p>
                    <p>Offers: number of offers displayed to people who clicked article links</p>
                    <p>Leads: number of offer forms completed</p>
                </span>}
                data={data}
                labels={labels}
                type="horizontal-bar"
                loading={typeof this.state.summary === "string" || typeof this.state.readers === "string" || typeof this.state.offerSummary === "string"}
                showSearchResults={this.props.showSearchResults}
                customChartTooltips={{
                    xAlign: "right",
                    callbacks: {
                        title: function (tooltipItem) {
                            const title = tooltipItem[0].yLabel;
                            return title.constructor === Array ? title.join(' ') : title;
                        },
                        label: function(tooltipItem, data) {
                            const tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            if (tooltipData[tooltipItem.datasetIndex][tooltipItem.index] != null) {
                                return tooltipData[tooltipItem.datasetIndex][tooltipItem.index];
                            } else {
                                return tooltipValue?.toLocaleString() || null;
                            }
                        },
                        beforeBody: function(tooltipItems) {
                            if (tooltipItems[0].index === 0) {
                                return `Total: ${totalContent}`;
                            } else {
                                return null;
                            }
                        }
                    }
                }}
            />
        </div>
    }

    async _downloadAutopostReport() {
        const data = await apiRequest(`${appConfig.API_URL}/reports/autopost/users/csv`, "Post", this.props.filters)
            .then(data => {
                if (typeof data === "object" && (data.error || data.valid === false)) {
                    throw data.error;
                }
                return data;
            })
            .catch(error => {
                createAlert(error, `error`);
                return [];
            });
        fileDownload(data, `campaignSubscriptions-${getLocaleDate(new Date())}.csv`);
        return data;
    }

    _renderSummary() {
        return (
            <div className="col-lg-12">
                <NumberChart
                    title="Summary"
                    tooltip={
                        <span>
                            <p>Articles: a unique content piece with a clickable link</p>
                            <p>Media: a unique content piece without a clickable link (i.e. containing only image(s), video or text)</p>
                            <p>Shares: number of networks and times the content piece was shared (article and media both)</p>
                            <p>Clicks (excluding media): when a person clicks on an article link</p>
                            <p>Social Interactions: the total number of likes and comments on article and media</p>
                            <p>Engagement Ratio: the sum of clicks and social interactions (likes and comments) divided by the number of shares of articles and media</p>
                        </span>
                    }
                    data={typeof this.state.summary === "string" ? {} : {
                        Articles: {
                            label: ["Articles"],
                            value:
                                this.state.summary && this.state.summary.articles && this.state.summary.articles.count
                                    ? this.state.summary.articles.count.toLocaleString()
                                    : 0
                        },
                        Media: {
                            label: ["Media"],
                            value:
                                this.state.summary && this.state.summary.media && this.state.summary.media.count
                                    ? this.state.summary.media.count.toLocaleString()
                                    : 0
                        },
                        Shares: {
                            label: ["Shares"],
                            value: ((this.state.summary?.articles?.shares || 0) + (this.state.summary?.media?.shares || 0)).toLocaleString()
                        },
                        Clicks: {
                            label: ["Clicks"],
                            value:
                                this.state.summary && this.state.summary.articles && this.state.summary.articles.clicks
                                    ? this.state.summary.articles.clicks.toLocaleString()
                                    : 0
                        },
                        Interactions: {
                            label: ["Social", "Interactions"],
                            value: ((this.state.summary?.interactions?.likes || 0) + (this.state.summary?.interactions?.comments || 0)).toLocaleString()
                        },
                        Ratio: {
                            label: ["Engagement", "Ratio"],
                            value:
                                (this.state.summary?.articles?.shares || 0) + (this.state.summary?.media?.shares || 0)
                                    ? (
                                          Math.round(((this.state.summary?.articles?.clicks || 0) +
                                            (this.state.summary?.interactions?.likes || 0) +
                                            (this.state.summary?.interactions?.comments || 0)) /
                                            ((this.state.summary?.articles?.shares || 0) + (this.state.summary?.media?.shares || 0)) * 10) / 10
                                      ).toFixed(1)
                                    : 0
                        }
                    }}
                    loading={typeof this.state.summary === "string"}
                    showSearchResults={this.props.showSearchResults}
                />
            </div>
        );
    }

    _renderReaders() {
        return (
            <div className="col-xs-12 col-lg-6 hide-col">
                <NumberChart
                    title="Readers"
                    tooltip={
                        <span>
                            <p>This card shows accumulated repeat readers on articles shared during this period.</p>
                            <p>
                                Total readers: the number of unique people or devices that have clicked on your articles
                            </p>
                            <p>Repeat readers: the number of people who have clicked on 2 or more articles of the same user in the past</p>
                        </span>
                    }
                    data={typeof this.state.readers === "string" ? {} : {
                        "Total readers": {
                            label: ["Total", "readers"],
                            value:
                                this.state.readers && this.state.readers.unique
                                    ? this.state.readers.unique.toLocaleString()
                                    : 0
                        },
                        "Repeat readers": {
                            label: ["Repeat readers"],
                            value:
                                this.state.readers && this.state.readers.repeat
                                    ? this.state.readers.repeat.toLocaleString()
                                    : 0
                        },
                        Repeat: {
                            label: ["Repeat"],
                            value: (
                                <PercentCircle
                                    percent={
                                        this.state.readers && this.state.readers.unique > 0
                                            ? getPercent(this.state.readers.unique, this.state.readers.repeat, 0)
                                            : 0
                                    }
                                />
                            )
                        }
                    }}
                    loading={typeof this.state.readers === "string"}
                    showSearchResults={this.props.showSearchResults}
                />
            </div>
        );
    }

    _renderOffers() {
        return (
            <div className="col-xs-12 col-lg-6 hide-col">
                {((this.props.client && this.props.client.allowOffers) || !this.props.client) && (
                    <NumberChart
                        title="Offers"
                        tooltip={
                            <span>
                                <p>An overview of how many offers are converted to leads.</p>
                                <br />
                                <p>Offers: number of offers displayed to people who clicked your links</p>
                                <p>Leads: number of offer forms completed</p>
                                <p>Conversion: percentage of offers that became a lead</p>
                            </span>
                        }
                        data={typeof this.state.offerSummary === "string" ? {} : {
                            Offers: {
                                label: ["Offers"],
                                value:
                                    this.state.offerSummary && this.state.offerSummary.offers
                                        ? this.state.offerSummary.offers.toLocaleString()
                                        : 0
                            },
                            Leads: {
                                label: ["Leads"],
                                value:
                                    this.state.offerSummary && this.state.offerSummary.leads
                                        ? this.state.offerSummary.leads.toLocaleString()
                                        : 0
                            },
                            Conversion: {
                                label: ["Conversion"],
                                value: (
                                    <PercentCircle
                                        percent={
                                            this.state.offerSummary && this.state.offerSummary.offers > 0
                                            ? getPercent(this.state.offerSummary.offers, this.state.offerSummary.leads, 0)
                                            : 0
                                        }
                                    />
                                )
                            }
                        }}
                        loading={typeof this.state.offerSummary === "string"}
                        showSearchResults={this.props.showSearchResults}
                    />
                )}
            </div>
        );
    }

    _renderCommentAdoptionDetails() {
        return (
            <div className="col-xs-12 col-lg-6 hide-col">
                <NumberChart
                    title="Comment Adoption"
                    tooltip={
                        <span>
                            <p>The percentage of shared articles with a comment provided by the admin or the publisher.</p>
                            <p>This card does not include curated articles with no shares , users who do not have the ability to share,</p>
                            <p>and users who have been excluded from reporting.</p>
                        </span>
                    }
                    data={typeof this.state.commentAdoption === "string" ? {} : {
                        Articles: {
                            label: ["Total articles"],
                            value: ( this.state.commentAdoption && this.state.commentAdoption.articlesWithComment
                                    ? (this.state.commentAdoption.articlesWithComment.articles + this.state.commentAdoption.articlesWithoutComment.articles)
                                    : 0
                            ).toLocaleString()
                        },
                        ArticlesWithComment: {
                            label: ["Total articles with comments"],
                            value: ( this.state.commentAdoption && this.state.commentAdoption.articlesWithComment
                                ? this.state.commentAdoption.articlesWithComment.articles
                                : 0
                            ).toLocaleString()
                        },
                        withCommentPercent: {
                            label: ["Ratio"],
                            value: (
                                <PercentCircle
                                    percent={
                                        this.state.commentAdoption && this.state.commentAdoption.articlesWithComment
                                            ? getPercent(
                                                this.state.commentAdoption.articlesWithComment.articles + this.state.commentAdoption.articlesWithoutComment.articles,
                                                    this.state.commentAdoption.articlesWithComment.articles,
                                                    0
                                                )
                                            : 0
                                    }
                                />
                            )
                        }
                    }}
                    loading={typeof this.state.commentAdoption === "string"}
                    showSearchResults={this.props.showSearchResults}
                />
            </div>
        );
    }

    _renderCommentPerformanceDetails() {
        const { commentAdoption } = this.state;
        const percentageWithoutComment = typeof commentAdoption !== "string" && commentAdoption.articlesWithoutComment ?
            getPercent(
                commentAdoption.articlesWithoutComment.clicks + commentAdoption.articlesWithoutComment.likes + commentAdoption.articlesWithoutComment.comments,
                commentAdoption.articlesWithoutComment.shares,
                2
            ) : 0;
        const percentageWithComment = typeof commentAdoption !== "string" && commentAdoption.articlesWithComment ?
            getPercent(
                commentAdoption.articlesWithComment.clicks + commentAdoption.articlesWithComment.likes + commentAdoption.articlesWithComment.comments,
                commentAdoption.articlesWithComment.shares,
                2
            ) : 0;

        return (
            <div className="col-xs-12 col-lg-6 hide-col">
                <NumberChart
                    title="Comment Performance"
                    tooltip={
                        <span>
                            <p>The change (as a percentage) in click to share ratio of ‘article shares without comments’</p>
                            <p>and ‘articles shares with comments’. Comments could have been provided by the admin on curation</p>
                            <p>or by the publisher when scheduling. This card does not include media shares or shares from users</p>
                            <p>who have been excluded from reporting.</p>
                        </span>
                    }
                    data={typeof commentAdoption === "string" ? {} : {
                        ratioWithout: {
                            label: ["Engagement ratio without comments"],
                            value: ( percentageWithoutComment || "0" )
                        },
                        ratioWith: {
                            label: ["Engagement ratio with comments"],
                            value: ( percentageWithComment || "0" )
                        },
                        difference: {
                            label: [percentageWithComment >= percentageWithoutComment ? "Increase" : "Decrease"],
                            value: (
                                <PercentCircle
                                    percent={this._getPercentDifference(percentageWithoutComment, percentageWithComment)}
                                />
                            )
                        }
                    }}
                    loading={typeof commentAdoption === "string"}
                    showSearchResults={this.props.showSearchResults}
                />
            </div>
        );
    }

    render() {
        return (
            <Wrapper id="engagement">
                <Row>
                    {this._renderEngagement()}
                    {this._renderSummary()}
                    {this._renderOffers()}
                    {this._renderCommentAdoptionDetails()}
                    {this._renderReaders()}
                    {this._renderCommentPerformanceDetails()}
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });
export default connect(mapStateToProps)(EngagementSection);
