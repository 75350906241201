import React, { Component, useState, useEffect, useCallback } from "react";

import { UtilActions, ClientActions, createAlert } from "../../actions";
import { debounce } from "lodash";
import PermissionSet from "../molecules/PermissionSet";
import { preventDefault } from "../../utils";
import { sanitizeHtml } from "../../utils/Helpers";
import Button from "../atoms/Button";
import { Wrapper, Row } from "../atoms/Layout/";
type IClient = Pick<ServerTypes.Console.IClient, "cid" | "name">;

const SettingsPage = () => {
    const [loaded, setLoaded] = useState(false);
    const [alertMessage, setAlertMessage] = useState<ServerTypes.Console.Settings.IConsoleAlert["message"] | null>(
        null
    );
    const [alertCids, setAlertCids] = useState<ServerTypes.Console.Settings.IConsoleAlert["cids"] | null>(null);
    const [availableCountries, setAvailableCountries] = useState<ServerTypes.Console.Settings.ICountries | null>(null);
    const [countries, setCountries] = useState<string[] | null>(null);
    const [clients, setClients] = useState<IClient[]>([]);
    const _saveMaintenanceMessage = useCallback(debounce((message: string | null, cids: string[] | null) => save(message, cids), 1000), []);
    const _saveCountries = useCallback(debounce((countries: string[] | null) => saveCountries(countries), 1000), []);


    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if (!loaded) return;
        _saveMaintenanceMessage(alertMessage, alertCids);
    }, [alertMessage, alertCids]);

    useEffect(() => {
        if (!loaded) return;
        _saveCountries(countries);
    }, [countries]);

    const refresh = async () => {
        setLoaded(false);
        const availableCountries = await UtilActions.doGetCountries();
        setAvailableCountries(availableCountries);

        const alerts = await UtilActions.doGetAlerts();
        setAlertMessage(alerts ? alerts.message : null);
        setAlertCids(alerts ? alerts.cids : null);

        const countries = await UtilActions.doGetBlocklistedCountries();
        setCountries(countries);

        const clients = await ClientActions.doList({}, ["cid", "name"]);
        setClients(clients.items);

        setLoaded(true);
    };

    const save = async (message: string | null, cids: string[] | null) => {
        const sanitizeddMessage = message ? sanitizeHtml(message) : "";
        if (message && !sanitizeddMessage) {
            createAlert("Please remove special characters from message");
            return;
        }
        await UtilActions.doSetAlerts(sanitizeddMessage, cids || []);
    };

    const saveCountries = async (countries: string[] | null) => {
        await UtilActions.doSetBlocklistedCountries(countries || []);
    };

    return (
        <Wrapper>
            <Row>
                <div className="col-lg-12">
                    <div className="ibox float-e-margins maintenance">
                        <div className="ibox-title">
                            <h5 aria-hidden="true">Maintenance Settings</h5>
                            <Button
                                className="btn--sm"
                                style={{ marginLeft: "auto" }}
                                onClick={() => {
                                    setTimeout(() => {
                                        if (window && window.location) {
                                            window.location.reload();
                                        }
                                    }, 50);
                                }}
                            >
                                <i className={`fa fa-eye`} />&nbsp;Preview
                            </Button>
                        </div>
                        <div className="ibox-content">
                            <form
                                className="ibox-content form"
                                onSubmit={e => e.preventDefault()}
                                onKeyPress={e => preventDefault(e)}
                            >
                                <div className="form__row">
                                    <div className="form__group form__group--full">
                                        <PermissionSet
                                            permissions={{
                                                message: {
                                                    label: "Message",
                                                    value: alertMessage || "",
                                                    type: "text",
                                                    disabled: !loaded,
                                                    onChange: value => setAlertMessage((value as string) || "")
                                                },
                                                cids: {
                                                    label: (
                                                        <React.Fragment>
                                                            Clients
                                                            <Button
                                                                className="btn--sm"
                                                                style={{ marginLeft: "auto", marginBottom: "0" }}
                                                                onClick={() => {
                                                                    if ((alertCids || []).length >= clients.length) {
                                                                        setAlertCids(null);
                                                                    } else {
                                                                        setAlertCids(clients.map(({ cid }) => cid));
                                                                    }
                                                                }}
                                                            >
                                                                Toggle All
                                                            </Button>
                                                        </React.Fragment>
                                                    ),
                                                    multi: true,
                                                    disabled: !loaded,
                                                    value: alertCids || [],
                                                    values: [
                                                        ...(clients || []).map(({ cid, name }) => ({
                                                            label: name,
                                                            value: cid
                                                        }))
                                                    ],
                                                    onChange: value => {
                                                        setAlertCids((value as string[]) || []);
                                                    }
                                                }
                                            }}
                                            _onChange={() => null}
                                            setNumber={"22"}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-lg-12">
                    <div className="ibox float-e-margins maintenance">
                        <div className="ibox-title">
                            <h5 aria-hidden="true">Block List</h5>
                        </div>
                        <div className="ibox-content">
                            <form
                                className="ibox-content form"
                                onSubmit={e => e.preventDefault()}
                                onKeyPress={e => preventDefault(e)}
                            >
                                <div className="form__row">
                                    <div className="form__group form__group--full">
                                        <PermissionSet
                                            permissions={{
                                                blockList: {
                                                    label: "Block Listed Countries For Offers",
                                                    multi: true,
                                                    disabled: !loaded,
                                                    value: countries || [],
                                                    values: (availableCountries || []).map(i => ({
                                                        value: i.code,
                                                        label: i.name
                                                    })),
                                                    onChange: value => {
                                                        setCountries(value as string[]);
                                                    }
                                                }
                                            }}
                                            _onChange={() => null}
                                            setNumber={"23"}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Row>
        </Wrapper>
    );
};

export default class SettingsComponents extends Component {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    render = () => <SettingsPage />;
}
