import { apiRequestWithErrorAlert } from "../utils/Helpers";
import appConfig from "../../config/config.dev";

export async function doGetDomains(domain: string, lang: string) {
    return apiRequestWithErrorAlert<string[]>(`${appConfig.API_URL}/getDomains`, "POST", {
        lang,
        domain
    });
}

export async function doGetDomainCategories(domain: string, lang: string) {
    return apiRequestWithErrorAlert<string[]>(`${appConfig.API_URL}/getDomainCategories`, "POST", {
        lang,
        domain
    });
}
