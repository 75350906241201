import React, { Component } from "react";
import Link from "../atoms/Link";

export default class Breadcrumbs extends Component<
    { items: Array<{ link?: string; name: string }> },
    { items: Array<{ link?: string; name: string }> }
> {
    constructor(props) {
        super(props);
        this.state = { items: props.items || [] };
    }
    componentWillReceiveProps(newProps) {
        if (this.state.items !== newProps.items) {
            this.setState({ items: newProps.items });
        }
    }

    renderItem(item: { link?: string; name: string }, index: number) {
        if (index == this.state.items.length - 1) {
            return (
                <li key={index} className={`breadcrumb-item active`}>
                    <strong>{item.name}</strong>
                </li>
            );
        } else if (!item.link) {
            return (
                <li key={index} className={`breadcrumb-item`}>
                    {item.name}
                </li>
            );
        }
        return (
            <li key={index} className={`breadcrumb-item`}>
                <Link to={item.link}>{item.name}</Link>
            </li>
        );
    }
    render() {
        const { items } = this.state;
        return <ol className="breadcrumb">{items.map((item, i) => this.renderItem(item, i))}</ol>;
    }
}
