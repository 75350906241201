import React, { Component } from "react";
import { connect } from "react-redux";
import appConfig from "../../../../config/config.dev";
import { FeedSourceRequests, getLanguages, Feed } from "../../../utils";
import { createAlert, FeedSourceRequestsActions, FeedActions, doGetFeedArticles, UtilActions } from "../../../actions";
import Spinner from '../../atoms/Spinner';
import Modal from 'react-bootstrap-modal';
import ArticleCard from '../../molecules/ArticleCard';
import ElementEditCard from "../../molecules/ElementEditCard";
import { history } from "../../../utils/store";

interface IEditFeedSourceRequestsProps {
    user: Mongo.clientAdmin;
}
interface IEditFeedSourceRequestsState {
    _id: string;
    countries: Array<{ code: string; name: string }>;
    adminHasPerm: boolean;
    loading: boolean;
    previewFeed: boolean;
    articles: Array<dynamic>;
    env: string;
    cidByEnv: dynamic;
    feed: {
        type: string;
        language: string;
        location: string;
        region: number;
        url: string;
        weight: number;
        category: string;
        subCategory: string[] | null;
    }
}

class EditFeedSourceRequests extends Component<IEditFeedSourceRequestsProps, IEditFeedSourceRequestsState> {
    static allowSuper = true;
    static allowApiAdmin = true;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);

        let _id = "";
        if (props.match.params._id) {
            _id = props.match.params._id;
        }

        this.state = {
            _id,
            countries: [],
            adminHasPerm: this.props.user?.isSuper || this.props.user?.isApiAdmin,
            loading: false,
            previewFeed: false,
            articles: [],
            env: appConfig.env || "development",
            // cids for each environment, needed for feed preview. kbc/seismicUAT/Grapevine6
            cidByEnv: { development: "24d34fe1-e375-4305-a232-fb2430638b20", uat: "ab879998-8364-4315-8106-dd071d721d4b", prod: "6ccd0ac8-a364-4101-af5b-a0cfead09133"},
            feed: {
                type: "",
                language: "",
                location: "",
                region: 0,
                url: "",
                weight: 0.5,
                category: "",
                subCategory: []
            }

        };
    }

    componentDidMount() {
        this.loadCountries();
    }

    async loadCountries() {
        this.setState({ countries: await UtilActions.doGetCountries() });
    }

    async _onSubmit(item: Mongo.FeedSourceRequests & { _id?: string }) {
        if (item._id === "") {
            delete item._id;
        }

        if (item.region) {
            item.region = Number(item.region);
        }

        Object.keys(item).forEach(key => {
            if (item[key] == "") {
                item[key] = null;
            }
        });
        if (!item.url) {
            createAlert("Invalid url", "error");
            return;
        }else{
            item.url = item.url.toLowerCase();
        }

        try {

            let validationRes = await FeedSourceRequestsActions.doValidate(item.url, item.type, item._id) || { valid: false, data: null };

            let canProceed = validationRes?.data?.canProceed || false;
            let errorMessage = validationRes?.data?.errorMessage || null;
            item.url = validationRes?.data?.correctedUrl || item.url;

            let proceed = false;
            if(!canProceed && errorMessage){
                createAlert(errorMessage, "error");
            } else if(canProceed && errorMessage){
                proceed = confirm(`We cannot validate the ${item.type === "SM" ? "Site Map" : item.type}. Would you like to proceed?`);
            }

            if(canProceed && !errorMessage) proceed = true

            if(proceed){
                if (!item.weight) item.weight = 0.5

                await FeedSourceRequestsActions.doUpsert(item);
                if (item._id) {
                    createAlert(`Successfully updated`, `success`);
                } else {
                    createAlert(`Successfully created feed source request`, "success");
                }
                setTimeout(() => {
                    history.push("/feeds-requests");
                }, 0);
            }

        } catch (error) {
            console.log('error',error);
            createAlert('Some error occured', `error`);
        }
    }

    async _onApprove(id: string, item: Mongo.FeedSourceRequests & { _id?: string }) {
        const feed = Feed(item);
        feed.subCategory =
            feed.subCategory && !Array.isArray(feed.subCategory)
                ? (((feed.subCategory || "") as unknown) as string).split(",").map(i => i.trim())
                : feed.subCategory || [];

        Object.keys(feed).forEach(key => {
            if (feed[key] == "") {
                feed[key] = null;
            }
        });
        const protocolRegex = /^((http|https|ftp):\/\/)/;
        if (protocolRegex.test(feed.articleDomain) || protocolRegex.test(feed.feedDomain)) {
            createAlert("You cannot use http(s):// or ftp:// in the article domain or the feed domain", "error");
            return;
        }
        if (!feed.url) {
            createAlert("Invalid url", "error");
            return;
        }
        // if feed is active validate else set to feed to inactive
        // const validation = feed.active
        //     ? await FeedActions.doValidate(feed.url, feed.type).catch(() => ({ valid: false }))
        //     : { valid: false };

        if (!feed.active) {
            feed.lastUpdatedStatus = 905;
        }

        try {

            let validationRes = await FeedSourceRequestsActions.checkIfAlreadyApproved(item.url, item.type) || { valid: false, data: null };

            let canProceed = validationRes?.data?.canProceed || false;
            let errorMessage = validationRes?.data?.errorMessage || null;
            item.url = validationRes?.data?.correctedUrl || item.url;

            let proceed = false;
            if(!canProceed && errorMessage){
                createAlert(errorMessage, "error");
            } else if(canProceed && errorMessage){
                proceed = confirm(`We cannot validate the ${item.type === "SM" ? "Site Map" : item.type}. Would you like to proceed?`);
            }

            if(canProceed && !errorMessage) proceed = true

            if(proceed){
                if (!item.weight) item.weight = 0.5

                await FeedActions.doUpsert(feed);
                await FeedSourceRequestsActions.doApprove(item._id?.toString() || "");
                createAlert(`Feed approved successfully`, "success");
                setTimeout(() => {
                    history.push("/feeds-requests");
                }, 0);
            }

        } catch (error) {
            console.log('error',error);
            createAlert('Some error occured', `error`);
        }
    }

    async _onReject(_id: string) {
        debugger;
        try {
            const response = await FeedSourceRequestsActions.doReject(_id);
            if (response && response.valid) {
                createAlert(response.message, "success");
            }
            setTimeout(() => {
                history.push("/feeds-requests");
            }, 0);
        } catch (error) {
            console.log("feed reject error", error);
        }
    }

    async previewFeed(item: Mongo.FeedSourceRequests | null) {
        const { env, cidByEnv } = this.state;
        if (item) {
            this.setState({ loading: true, previewFeed: true });
            try {
                const articles = await doGetFeedArticles(item.url, item.language, cidByEnv[env]);
                console.log("articles", articles);
                this.setState({ articles, loading: false });
            } catch (e) {
                // @ts-expect-error
                createAlert(e.error);
            }

        }
    }

    closeModal() {
        console.log("close", this.state);
        this.setState({ previewFeed: false, articles: [] });
    }

    render() {
        const { _id, countries, adminHasPerm, previewFeed, loading, feed } = this.state;
        if (previewFeed) {
            return (<Modal show={true} onHide={this.closeModal}  aria-labelledby="ModalHeader">
                    <Modal.Header closeButton="true">
                        <Modal.Title id='ModalHeader'>Feed Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div>
                                {
                                    this.state.articles && this.state.articles.map(function(article, index) {
                                        return (<ArticleCard key={article.artid} article={article} canEdit={false} editing={false} canApprove={false} />)
                                    }.bind(this))
                                }
                            </div>
                        )}
                    </Modal.Body>
                </Modal>);
        }
        return (
            <ElementEditCard<Mongo.FeedSourceRequests & { _id?: string }>
                getItem={async () => ({
                    ...FeedSourceRequests({ _id, ...(_id == "" ? this.state.feed : { ...(await FeedSourceRequestsActions.doGet(_id)) }) })
                })}
                canUpdate={() => Promise.resolve(true)}
                update={item => this._onSubmit(item)}
                canApprove={this.state.adminHasPerm && _id ? () => Promise.resolve(true) : undefined}
                approve={item => this._onApprove(_id, item)}
                canReject={this.state.adminHasPerm && _id ? () => Promise.resolve(true) : undefined}
                reject={item => this._onReject(_id)}
                breadcrumbs={[{ name: "Feed Source Requests", link: "/feeds-requests" }]}
                elementName="Feed Source Request"
                idField="_id"
                isInsert={item => !!(item && item._id == "")}
                editableKeys={item => {
                    return {
                        type: {
                            label: "Type",
                            type: "select",
                            values: [{ label: "RSS", value: "RSS" }, { label: "SiteMap", value: "SiteMap" }],
                            mutateChange: async (key, value) => {
                                this.setState({ feed: {...feed, type: value.toString()} });
                                return { type: value as string};
                            }
                        },
                        language: {
                            label: "Language",
                            type: "select",
                            values: getLanguages().map(lang => ({ label: lang.name, value: lang.name })),
                            mutateChange: async (key, value) => {
                                this.setState({ feed: {...feed, language: value.toString()} });
                                return { language: value as string };
                            }
                        },
                        location: {
                            label: "Location",
                            type: "select",
                            values: [
                                { value: "", label: "NONE" },
                                ...countries.map(locations => ({
                                    label: locations.name,
                                    value: locations.name
                                }))
                            ],
                            mutateChange: async (key, value) => {
                                this.setState({ feed: {...feed, location: value.toString()} });
                                return { location: value as string };
                            }
                        },
                        region: {
                            label: "Region",
                            type: "select",
                            values: [
                                { value: "-2", label: "NONE" },
                                { value: "1", label: "Australia & NZ" },
                                { value: "2", label: "Far East" },
                                { value: "3", label: "India" },
                                { value: "4", label: "Middle East & Africa" },
                                { value: "5", label: "Europe" },
                                { value: "6", label: "South America" },
                                { value: "7", label: "East - North America" },
                                { value: "8", label: "Central - North America" },
                                { value: "9", label: "West - North America" },
                                { value: "-1", label: "Other" }
                            ],
                            mutateChange: async (key, value) => {
                                this.setState({ feed: {...feed, region: Number(value)} });
                                return { region: Number(value) as number };
                            }
                        },
                        url: {
                            label: "URL",
                            type: "text",
                            fullWidth: false,
                            mutateChange: async (key, value) => {
                                this.setState({ feed: {...feed, url: value.toString()} });
                                return { url: value as string };
                            }
                        },
                        weight: {
                            label: "Weight",
                            type: "select",
                            values: [
                                { value: 0.1, label: "0.10" },
                                { value: 0.2, label: "0.20" },
                                { value: 0.3, label: "0.30" },
                                { value: 0.4, label: "0.40" },
                                { value: 0.5, label: "0.50" },
                                { value: 0.6, label: "0.60" },
                                { value: 0.7, label: "0.70" },
                                { value: 0.8, label: "0.80" },
                                { value: 0.9, label: "0.90" },
                                { value: 1.0, label: "1.00" }
                            ],
                            mutateChange: async (key, value) => {
                                this.setState({ feed: {...feed, weight: Number(value)} });
                                return { weight: Number(value) as number };
                            }
                        },
                        category: {
                            label: "Category",
                            type: "select",
                            values: [
                                { label: "Arts Entertainment", value: "Arts Entertainment" },
                                { label: "Business", value: "Business" },
                                { label: "Computer Internet", value: "Computer Internet" },
                                { label: "Health", value: "Health" },
                                { label: "Law", value: "Law" },
                                { label: "Lifestyle", value: "Lifestyle" },
                                { label: "Marketing", value: "Marketing" },
                                { label: "News Media", value: "News Media" },
                                { label: "Politics", value: "Politics" },
                                { label: "Recreation Sports", value: "Recreation Sports" },
                                { label: "Science Technology", value: "Science Technology" },
                                { label: "Society Culture", value: "Society Culture" }
                            ],
                            mutateChange: async (key, value) => {
                                this.setState({ feed: {...feed, category: value.toString()} });
                                return { category: value as string };
                            }
                        },
                        subCategory: {
                            label: "Sub Category",
                            type: "text",
                            hidden: !this.state.adminHasPerm,
                            placeholder: "Please enter a sub category",
                            value: item.subCategory && Array.isArray(item.subCategory) && item.subCategory.length
                                ? item.subCategory.join(", ")
                                : item.subCategory || "",
                            mutateChange: async (key, value) => {
                                this.setState({ feed: { ...feed, subCategory: [value.toString()] } });
                                return { subCategory: value as string[] };
                            }
                        }
                    };
                }}
                buttons={item => item?.url ?
                    [
                        {
                            text: "Preview Feed",
                            disabled: !adminHasPerm,
                            onClick: (item) => this.previewFeed(item || null)
                        }
                    ] : []
                }
            />
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(EditFeedSourceRequests);
