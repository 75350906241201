import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeading from "../../../molecules/PageHeading";
import Spinner from "../../../atoms/Spinner";
import { createAlert } from "../../../../actions/utils";
import { getFriendlyDate, getAdminDate, getLocalISODate, formatDate } from "../../../../utils/Helpers";
import Link from "../../../atoms/Link";
import Tooltip from "../../../atoms/Tooltip";
import debounce from "lodash/debounce";
import DatePickerV2 from "../../../atoms/DatePickerV2";
import Pagination from "../../../molecules/Pagination";
import Button from "../../../atoms/Button";
import { isIE11 } from "../../../../utils/BrowserHelpers";

import {
    doGetReduxClients,
    doGetReduxClient,
    doGetECommunicationMessages,
    doGetECommunicationMessage,
    doDuplicateECommunicationMessage,
    doGetECommunicationCategories,
    doGetECommunicationSubscribables
} from "../../../../actions";
import ECommunicationMessageCard from "../../../molecules/ECommunicationMessageCard";
import AddECommunicationMessage from "../../../molecules/modals/AddECommunicationMessage";
import {
    MESSAGE_STATUS,
    MESSAGE_FREQUENCY,
    MESSAGE_TYPE,
    EMAIL_TEMPLATE_CATEGORY,
    EDITABLE_TEMPLATE_TYPE,
    containValue,
    getNamebyValue
} from "../../../../utils/ECommunicationHelpers";
import { Wrapper, Row } from "../../../atoms/Layout/";

var querystring = require("querystring");
class ECommunicationLibrary extends Component {
    constructor(props) {
        super(props);
        this._renderTableView = this._renderTableView.bind(this);
        this._renderCardView = this._renderCardView.bind(this);
        this._onFilterChange = this._onFilterChange.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._openModal = this._openModal.bind(this);
        this._renderModal = this._renderModal.bind(this);
        this.stripDuplicates = this.stripDuplicates.bind(this);
        this._deleteAction = this._deleteAction.bind(this);
        this._getClientName = this._getClientName.bind(this);

        this._getMessages = this._getMessages.bind(this);
        this._getCategories = this._getCategories.bind(this);
        this._openAddMessage = this._openAddMessage.bind(this);
        this._closeAddMessage = this._closeAddMessage.bind(this);
        this.debounce = debounce(this._getMessages, 1000);
        this._createAlert = debounce((message, type) => {
            createAlert(message, type);
        }, 500);

        let status = props.match.params.status;
        if (!containValue(MESSAGE_STATUS, status)) {
            status = "";
        }
        let frequency = props.match.params.frequency;
        if (!containValue(MESSAGE_FREQUENCY, frequency)) {
            frequency = "";
        }

        let start = "",
            end = "";
        start = new Date();
        start.setMonth(start.getMonth() - 1);

        this.state = {
            client: null,
            messages: [],
            view: "table", // use "card" here if default view is card view
            filterType: "",
            filterStatus: status,
            filteredCategory: "",
            categories: [],
            langs: { en: 'English', fr: 'French' },
            filteredLanguage: "",
            filterFrequency: frequency,
            searchQuery: "",
            openModal: "",
            startDate: start ? (isIE11() ? `${formatDate(start)}` : `${getLocalISODate(start)}`) : start,
            endDate: end ? (isIE11() ? `${formatDate(end)}` : `${getLocalISODate(end)}`) : end,
            per_page: 3 * 10,
            page: 0,
            total: 0,
            last_page: false,
            loading: false,
            subscribables: {}
        };
    }

    _getClientName(cid) {
        const { clients } = this.props;
        if (cid) {
            if (clients && clients[cid]) {
                return clients[cid].name;
            }
        }
        return "";
    }
    _formatTerms() {
        const { sid } = this.state;
        let terms = [];
        let search = this._getSearch(sid);
        if (search && search.terms) {
            search.terms.map(function (term, i) {
                terms.push({ value: term.term, selected: term.checked });
            });
        }
        return terms;
    }
    _deleteAction() {
        createAlert(`Message Deleted`, `success`);
        this._getMessages();
    }
    async componentDidMount() {
        const { cid } = this.props.user;
        if (!this.props.user.isSuper) {
            await doGetReduxClient(cid);
            const data = await doGetECommunicationSubscribables(cid);
            const subscribables = {};
            data.items.forEach(item => {
                subscribables[item.subscribableid] = item;
            });
            if (this.state.suggestedModal) {
                const data = await doGetECommunicationMessage(cid, this.state.suggestedModal);
                if (data.ecommid) {
                    this.setState({ suggestedModalOpen: true, suggestedModalMessage: data, subscribables });
                }
            }
            this.setState({ subscribables });
        } else {
            doGetReduxClients();
        }
        // // add bee editor for email content management
        // const script = document.createElement("script");
        // script.src = "https://app-rsrc.getbee.io/plugin/BeePlugin.js";
        // script.async = true;
        // document.head.appendChild(script);
        await this._getMessages();
        if (this.props.user.cid) {
            await this._getCategories();
        };
    }
    async componentWillReceiveProps(newProps) {
        let newState = {};
        if (!this.state.client && newProps.user.cid) {
            newState["client"] = newProps.clients[newProps.user.cid];
            if (this.props.user.cid != newState.client.cid) await this._getCategories();
        }
        if (this.props.match.params.status != newProps.match.params.status) {
            let status = newProps.match.params.status;
            if (!containValue(MESSAGE_STATUS, status)) {
                status = "";
            }
            newState["filterStatus"] = status;
            let frequency = newProps.match.params.frequency;
            if (!containValue(MESSAGE_FREQUENCY, frequency)) {
                frequency = "";
            }
            newState["filterFrequency"] = frequency;
            let category = newProps.match.params.category;
            if (!containValue(EMAIL_TEMPLATE_CATEGORY, category)) {
                category = "";
            }
            newState["filterCategory"] = category;
        }

        let isSuggested = false;
        if (this.props.match.params.ecommid != newProps.match.params.ecommid) {
            let ecommid = newProps.match.params.ecommid;
            isSuggested = true;
            doGetECommunicationMessage(this.props.user.cid, ecommid).then(data => {
                if (data.ecommid) {
                    this.setState(
                        Object.assign(newState, {
                            suggestedModalOpen: true,
                            suggestedModalMessage: data,
                            suggestedModal: ecommid
                        })
                    );
                }
            });
        }
        if (Object.keys(newState).length > 0 && !isSuggested) {
            this.setState(newState);
        }
    }
    async componentDidUpdate(prevProps, prevState) {
        // is called for admins on initial visit to page with no client selected
        if ((!prevState.client && this.state.client?.cid) || (prevState.client?.cid !== this.state.client.cid)) {
            await this._getCategories(this.state.client.cid);
        }
    };

    stripDuplicates(messages, allMessages) {
        let retMessages = {};

        allMessages.forEach(function (a) {
            retMessages[a.ecommid] = a;
        });

        messages.forEach(function (a) {
            retMessages[a.ecommid] = a;
        });

        return Object.values(retMessages);
    }

    async _getMessages() {
        const { client, filteredCategory, filterType, filterStatus, filteredLanguage, filterFrequency, per_page, page, searchQuery, startDate, endDate } = this.state;
        this.setState({ messagesLoading: true },
            async () => {
                const data = await doGetECommunicationMessages(
                    (client || { cid: this.props.user.cid }).cid,
                    filterType,
                    filterStatus,
                    filterFrequency,
                    page * per_page,
                    per_page,
                    searchQuery,
                    startDate,
                    endDate,
                    false,
                    null,
                    filteredCategory,
                    filteredLanguage
                );
                this.setState({
                    messagesLoading: false,
                    ...(data.items ? { messages: data.items, last_page: data.items.length != per_page, total: data.count || 0 } : null)
                });
            }
        );
    }
    async _getCategories(cid = (this.state.client || { cid: this.props.user.cid }).cid) {
        this.setState({
            loading: true
        }, async () => {
            const data = await doGetECommunicationCategories(cid, "active");
            this.setState({ loading: false, categories: data?.items || [] });
        });
    }
    _onDuplicateMessage(cid, ecommid) {
        doDuplicateECommunicationMessage(cid, ecommid).then(data => {
            if (data) {
                // reload the page to pull in the newly duplicated message
                window.location.reload(false);
            }
        });
    }
    async _onFilterChange(fieldName, event, shouldDebounce = false) {
        let val = event && event.target ? event.target.value : event ? event : "";
        if (fieldName == "cid") {
            fieldName = "client";
            val = (this.props.clients || {})[val];
        }
        let newState = {
            [fieldName]: val
        };

        if (this.props.user.isSuper && this.state?.client?.cid) {
            await this._getCategories(this.state.client.cid);
        };

        this.setState(newState, () => {
            if (shouldDebounce) {
                this.debounce();
                this._createAlert('Search filter updated', 'success');
            } else {
                this._getMessages();
                this._createAlert('Search filter updated', 'success');
            }
        });
    }
    _openAddMessage() {
        this.setState({ messageType: "standard", addMessage: true });
    }
    _closeAddMessage() {
        this.setState(
            {
                addMessage: false
            },
            this._getMessages
        );
    }

    _createStatusOptions() {
        let items = [];
        for (let item of MESSAGE_STATUS) {
            items.push(
                <option key={item.value} value={item.value}>
                    {item.name}
                </option>
            );
        }
        return items;
    }
    _createFrequencyOptions() {
        let items = [];
        for (let item of MESSAGE_FREQUENCY) {
            items.push(
                <option key={item.value} value={item.value}>
                    {item.name}
                </option>
            );
        }
        return items;
    }
    _createCategoryOptions() {
        let items = [];
        for (let item of this.state.categories) {
            items.push(
                <option key={item.categoryid} value={item.categoryid}>
                    {item.title}
                </option>
            );
        }
        return items;
    }
    _createLanguageOptions() {
        let items = [];
        for (let item in this.state.langs) {
            items.push(
                <option key={item} value={item}>
                    {this.state.langs[item]}
                </option>
            );
        }
        return items;
    }

    render() {
        const { searchQuery, categories, client, view, filteredCategory, filterType, filterStatus, filterFrequency, filteredLanguage, messages, messagesLoading } = this.state;

        const buttons = [];
        if (!this.props.user.isSuper) {
            buttons.push({ title: "Create Message", on_click: this._openAddMessage });
        }

        return (
            <Wrapper id="ecomm-Library">
                {this.state.addMessage && (
                    <AddECommunicationMessage cid={client.cid} client={client} closeAction={this._closeAddMessage} />
                )}
                {this.state.suggestedModalOpen && this._renderSuggestedModal()}
                <PageHeading title={`e-Communication Library`} buttons={buttons} />
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <div className="table__filters">
                                    <div className="table__filters__option">
                                        <label htmlFor="search">Search:</label>
                                        <div className="search">
                                            <input
                                                id="search"
                                                className="filter__search"
                                                ref={input => {
                                                    this.userInput = input;
                                                }}
                                                type="text"
                                                onChange={event => this._onFilterChange("searchQuery", event, true)}
                                                value={searchQuery}
                                            />
                                            <i className="fa fa-search" />
                                        </div>
                                    </div>
                                    {this.props.user.isSuper && (
                                        <div className="table__filters__option">
                                            <label htmlFor="client">Client:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="client"
                                                    className="filters__search"
                                                    value={(client || {}).cid}
                                                    onChange={event => this._onFilterChange("cid", event)}
                                                >
                                                    <option value="">All</option>
                                                    <optgroup label="Clients">
                                                        {Object.keys(this.props.clients).map((key, i) => {
                                                            let c = this.props.clients[key];
                                                            return (
                                                                <option key={c.cid} value={c.cid}>
                                                                    {c.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    <div className="table__filters__option date__wrapper">
                                        <label htmlFor="startDate">Published After:</label>
                                        <DatePickerV2
                                            dateFormat="yyyy/MM/dd"
                                            maxDate={this.state.endDate ? this.state.endDate : new Date().toISOString()}
                                            id="startDate"
                                            value={this.state.startDate}
                                            onChange={(value, formattedValue) =>
                                                this._onFilterChange("startDate", value)
                                            }
                                            onBlur={() => this._onFilterChange("startDate", "")}
                                        />
                                    </div>
                                    <div className="table__filters__option date__wrapper">
                                        <label htmlFor="endDate">Expires Before:</label>
                                        <DatePickerV2
                                            id="endDate"
                                            dateFormat="yyyy/MM/dd"
                                            minDate={this.state.endDate ? this.state.startDate : ""}
                                            value={this.state.endDate}
                                            onChange={(value, formattedValue) => this._onFilterChange("endDate", value)}
                                            onBlur={() => this._onFilterChange("endDate", "")}
                                        />
                                    </div>
                                    <div className="table__filters__option">
                                        <label htmlFor="displayView">Display View:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="displayView"
                                                className="filters__search"
                                                value={view}
                                                onChange={event => this._onFilterChange("view", event)}
                                            >
                                                <option value="card">Card</option>
                                                <option value="table">Condensed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.client && this.state.client.allowContentSpecificPermissions && (
                                        <div className="table__filters__option">
                                            <label htmlFor="filterType">Filter By Type:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="filterType"
                                                    className="filters__search"
                                                    value={filterType}
                                                    onChange={event => this._onFilterChange("filterType", event)}
                                                >
                                                    <option value="">All</option>
                                                    <option value="standard">Custom Template</option>
                                                    <option value="article">Article/Media Template</option>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    <div className="table__filters__option">
                                        <label htmlFor="filterStatus">Filter By Status:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="filterStatus"
                                                className="filters__search"
                                                value={filterStatus}
                                                onChange={event => this._onFilterChange("filterStatus", event)}
                                            >
                                                <option value="">All</option>
                                                {this._createStatusOptions()}
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.filterType != "article" && (
                                        <div className="table__filters__option">
                                            <label htmlFor="filterFrequency">Filter By Frequency:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="filterFrequency"
                                                    className="filters__search"
                                                    value={filterFrequency}
                                                    onChange={event => this._onFilterChange("filterFrequency", event)}
                                                >
                                                    <option value="">All</option>
                                                    {this._createFrequencyOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    {
                                        ((client && client.cid) && categories.length > 0) &&
                                        <div className="table__filters__option">
                                            <label htmlFor="filterCategory">Filter By Category:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="filterCategory"
                                                    className="filters__search"
                                                    value={filteredCategory}
                                                    onChange={event => this._onFilterChange("filteredCategory", event)}
                                                >
                                                    <option value="">All</option>
                                                    {this._createCategoryOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="table__filters__option">
                                        <label htmlFor="filterLanguage">Filter By Language:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="filterLanguage"
                                                className="filters__search"
                                                value={filteredLanguage}
                                                onChange={event => this._onFilterChange("filteredLanguage", event)}
                                            >
                                                <option value="">All</option>
                                                {this._createLanguageOptions()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        page={this.state.page}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.per_page}
                                        changePage={newPage => this._onFilterChange("page", newPage)}
                                        type="select"
                                        disabled={this.state.loading}
                                        showTotal={false}
                                    />
                                </div>
                                {this._renderMessages(view, messagesLoading, messages)}
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        currItems={messages.length}
                                        page={this.state.page}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.per_page}
                                        changePage={newPage => this._onFilterChange("page", newPage)}
                                        type="select"
                                        disabled={this.state.loading}
                                        showTotal={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
    _renderMessages(view, loading, messages) {
        if (view == "card") {
            return this._renderCardView(loading, messages);
        } else {
            return this._renderTableView(loading, messages);
        }
    }

    _renderCardView(loading, messages) {
        const { clients } = this.props;
        return (
            <div className="message-card__wrapper" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {clients &&
                    Object.keys(clients).length &&
                    !loading &&
                    messages &&
                    messages.map((message, index) => {
                        return (
                            <ECommunicationMessageCard
                                key={message.ecommid}
                                message={message}
                                client={clients[message.cid]}
                                canEdit={true}
                                deleteAction={this._deleteAction}
                                editAction={this._getMessages}
                                approveAction={() => this._getMessages()}
                            />
                        );
                    })}

                <div className="load-more">
                    {loading && <Spinner />}
                    {!loading && (!messages || messages.length == 0) && (
                        <div>No messages matching your filters could be found</div>
                    )}
                </div>
            </div>
        );
    }

    _renderModal(message) {
        const { client } = this.state;
        if (this.state.openModal && this.state.openModal == message.ecommid && this.props.clients && Object.keys(this.props.clients).length) {
            return (
                <AddECommunicationMessage
                    cid={message.cid}
                    client={this.props.clients[message.cid]}
                    message={message}
                    messageType={message.type}
                    updateAction={this._getMessages}
                    deleteAction={this._getMessages}
                    closeAction={this._closeModal}
                />
            );
        }
        return;
    }
    _closeModal() {
        this.setState(
            {
                openModal: ""
            },
            this._getMessages
        );
    }
    _openModal(ecommid) {
        this.setState({ openModal: ecommid });
    }
    _renderTableView(loading, messages) {
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        {this.props.user.isSuper && (<th tabIndex={0}>Client</th>)}
                        <th tabIndex={0}>Title</th>
                        <th tabIndex={0}>Status</th>
                        {this.state.client && this.state.client.allowContentSpecificPermissions && (<th tabIndex={0}>Type</th>)}
                        {this.state.client && this.state.client.eCommunication.enableOneTimeEditableType && (<th tabIndex={0}>Editable</th>)}
                        <th tabIndex={0}>Frequency</th>
                        <th tabIndex={0}>Published Date/Time</th>
                        <th tabIndex={0}>Expired Date/Time</th>
                        <th tabIndex={0}>Last Updated Date/Time</th>
                        <th tabIndex={0} />
                    </tr>
                </thead>
                <tbody>
                    {!loading &&
                        messages.map(
                            function (message, i) {
                                return (
                                    <tr key={`ecommid-${message.ecommid}`}>
                                        {this._renderModal(message)}
                                        {this.props.user.isSuper && (
                                            <td tabIndex={0}>
                                                <Link to={`/clients/e/${message.cid}`}>
                                                    {this._getClientName(message.cid) || "N/A"}
                                                </Link>
                                            </td>
                                        )}
                                        <td tabIndex={0}>
                                            <Link href={message.Link || ""}
                                                target="_blank"
                                                onClick={() => this._openModal(message.ecommid)} >
                                                {message.Title || "N/A"}
                                            </Link>
                                        </td>
                                        <td tabIndex={0}>{getNamebyValue(MESSAGE_STATUS, message.status) || "N/A"}</td>
                                        {this.state.client && this.state.client.allowContentSpecificPermissions && (
                                            <td tabIndex={0}>{getNamebyValue(MESSAGE_TYPE, (message.type ? message.type : "standard")) || "N/A"}</td>
                                        )}
                                        {this.state.client && this.state.client.eCommunication.enableOneTimeEditableType && (
                                            <td tabIndex={0}>
                                                {message.editableTemplateType ? getNamebyValue(EDITABLE_TEMPLATE_TYPE, message.editableTemplateType) :
                                                    message.frequency == "onetime" ? "Provided" : "N/A"}
                                            </td>
                                        )}
                                        <td tabIndex={0}>
                                            {!message.type || message.type == "standard" ? getNamebyValue(MESSAGE_FREQUENCY, message.frequency) : "N/A"}
                                            <br />
                                            {this.state.subscribables[message.subscribableid]?.title || ""}
                                        </td>
                                        <td tabIndex={0}>
                                            {!this.props.user.isSuper && (!message.type || message.type == "standard" ?
                                                <Tooltip label={getFriendlyDate(message.activeDate) || "N/A"}>
                                                    {getAdminDate(message.activeDate) || "N/A"}
                                                </Tooltip>
                                                : "N/A"
                                            )}
                                            {this.props.user.isSuper && (getAdminDate(message.activeDate) || "N/A")}
                                        </td>
                                        <td tabIndex={0}>
                                            {!this.props.user.isSuper && (!message.type || message.type == "standard" ?
                                                <Tooltip label={getFriendlyDate(message.inActiveDate) || "N/A"}>
                                                    {getAdminDate(message.inActiveDate) || "N/A"}
                                                </Tooltip>
                                                : "N/A"
                                            )}
                                            {this.props.user.isSuper && (getAdminDate(message.inActiveDate) || "N/A")}
                                        </td>
                                        <td tabIndex={0}>
                                            {!this.props.user.isSuper && (!message.type || message.type == "standard" ?
                                                <Tooltip label={getAdminDate(message.lastContentUpdatedOn || message.lastUpdatedOn) || "N/A"}>
                                                    {getAdminDate(message.lastContentUpdatedOn || message.lastUpdatedOn) || "N/A"}
                                                </Tooltip>
                                                : "N/A"
                                            )}
                                            {this.props.user.isSuper && (getAdminDate(message.lastContentUpdatedOn || message.lastUpdatedOn) || "N/A")}
                                        </td>
                                        <td tabIndex={0}>
                                            <Button style={{ marginRight: "5px" }}
                                                className="btn--sm"
                                                onClick={() => this._openModal(message.ecommid)}>
                                                Edit
                                            </Button>
                                            {(!message.type || message.type == "standard") && (
                                                <Button className="btn--sm" onClick={() => this._onDuplicateMessage(message.cid, message.ecommid)} >
                                                    Duplicate
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            }.bind(this)
                        )}
                    {!loading && (!messages || messages.length == 0) && (
                        <tr>
                            <td tabIndex={0} colSpan="42">No messages matching your filters could be found</td>
                        </tr>
                    )}
                    {loading && (
                        <tr>
                            <td tabIndex={0} colSpan="42">
                                <Spinner />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

// only allow : super, client admin and curator admin
ECommunicationLibrary.allowSuper = true;
ECommunicationLibrary.allowApiAdmin = false;
ECommunicationLibrary.allowSuperClientAdmin = true;
ECommunicationLibrary.allowClientAdmin = true;
ECommunicationLibrary.allowAdmin = true;
ECommunicationLibrary.allowCurator = true;
ECommunicationLibrary.allowReports = false;
const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(ECommunicationLibrary);
