import React, { Component } from "react";
import Chart from "../../molecules/Chart";
import DatePickerV2 from "../../atoms/DatePickerV2";

import { apiRequest } from "../../../utils/Helpers";
import appConfig from "../../../../config/config.dev";
import { createAlert } from "../../../actions/utils";
import { Wrapper, Row } from "../../atoms/Layout";

interface IArticleCountState {
    articleCount: "loading" | Array<{
        week: string;
        en: number;
        fr: number;
        es: number;
        de: number;
        pt: number;
        it: number;
        total: number;
    }>;
    startDate: string;
    endDate: string;
}

class ArticleCount extends Component<{}, IArticleCountState> {
    static allowSuper = true;
    static allowAPIAdmin = true;
    static allowSuperClientAdmin = false;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);

        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);

        this.state = {
            articleCount: "loading",
            startDate: startDate.toISOString(),
            endDate: new Date().toISOString()
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        this.setState({ articleCount: "loading" });
        apiRequest(
            `${appConfig.API_URL}/getReportArticles?startDate=${this.state.startDate}&endDate=${this.state.endDate}`
        ).then(data => {
                if (data && ("valid" in data && !data.valid)) {
                    createAlert(data.error, `error`);
                    return;
                }
                this.setState({ articleCount: data.items });
            }
        );
    }

    _onFieldChange(field: keyof IArticleCountState, val) {
        this.setState(
            {
                [field]: val?.target ? val.target.value : val
            } as IArticleCountState,
            () => {
                this.refreshData();
            }
        );
    }

    _renderArticleCount() {
        const { articleCount } = this.state;
        const labels: string[] = [];
        const data = [
            {
                label: "en",
                data: [] as number[]
            },
            {
                label: "fr",
                data: [] as number[]
            },
            {
                label: "es",
                data: [] as number[]
            },
            {
                label: "de",
                data: [] as number[]
            },
            {
                label: "pt",
                data: [] as number[]
            },
            {
                label: "it",
                data: [] as number[]
            },
            {
                label: "total",
                data: [] as number[]
            }
        ];

        if (articleCount && Array.isArray(articleCount)) {
            articleCount.forEach(week => {
                labels.push(week["week"]);
                data[0].data.push(week["en"]);
                data[1].data.push(week["fr"]);
                data[2].data.push(week["es"]);
                data[3].data.push(week["de"]);
                data[4].data.push(week["pt"]);
                data[5].data.push(week["it"]);
                data[6].data.push(week["total"]);
            });
        }

        return (
            <div className="col-lg-6">
                <Chart
                    title={"Total Article Count"}
                    data={data}
                    labels={labels}
                    type="line"
                    loading={typeof this.state.articleCount === "string"}
                />
            </div>
        );
    }

    _renderArticleAvg() {
        const { articleCount } = this.state;
        const labels: string[] = [];
        const data = [
            {
                label: "en",
                data: [] as number[]
            },
            {
                label: "fr",
                data: [] as number[]
            },
            {
                label: "es",
                data: [] as number[]
            },
            {
                label: "de",
                data: [] as number[]
            },
            {
                label: "pt",
                data: [] as number[]
            },
            {
                label: "it",
                data: [] as number[]
            },
            {
                label: "total",
                data: [] as number[]
            }
        ];

        if (articleCount && Array.isArray(articleCount)) {
            articleCount.forEach(week => {
                labels.push(week["week"]);
                data[0].data.push(Math.round(week["en"] / 7));
                data[1].data.push(Math.round(week["fr"] / 7));
                data[2].data.push(Math.round(week["es"] / 7));
                data[3].data.push(Math.round(week["de"] / 7));
                data[4].data.push(Math.round(week["pt"] / 7));
                data[5].data.push(Math.round(week["it"] / 7));
                data[6].data.push(Math.round(week["total"] / 7));
            });
        }

        return (
            <div className="col-lg-6">
                <Chart
                    title={"Average Article Count"}
                    data={data}
                    labels={labels}
                    type="line"
                    loading={typeof this.state.articleCount === "string"}
                />
            </div>
        );
    }

    render() {
        return (
            <Wrapper>
                <h1>Article Count</h1>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        marginBottom: "10px"
                    }}
                >
                    <div className="date__wrapper">
                        <label className="control-label">Start Date</label>
                        <DatePickerV2
                            dateFormat="yyyy/MM/dd"
                            value={this.state.startDate}
                            onChange={value => this._onFieldChange("startDate", value)}
                            enableTabLoop={false}
                        />
                    </div>
                    <div className="date__wrapper" style={{ marginLeft: "10px" }}>
                        <label className="control-label">End Date</label>
                        <DatePickerV2
                            dateFormat="yyyy/MM/dd"
                            value={this.state.endDate}
                            onChange={value => this._onFieldChange("endDate", value)}
                            enableTabLoop={false}
                        />
                    </div>
                </div>
                <Row>
                    {this._renderArticleCount()}
                    {this._renderArticleAvg()}
                </Row>
            </Wrapper>
        );
    }
}
export default ArticleCount;
