import React, { Component } from "react";
import { connect } from "react-redux";

import Link from "../../atoms/Link";
import ElementListBox from "../../molecules/ElementListBox";
import Button from "../../atoms/Button";
import { AdminActions, IClientAdmin, createAlert } from "../../../actions";
import { getFriendlyDate, getAdminDate, getBooleanIcon } from "../../../utils/Helpers";
import { Wrapper, Row } from "../../atoms/Layout/";

interface IAdminViewProps {
    clients: { [cid: string]: Mongo.IClient };
    user: Mongo.clientAdmin;
}
class AdminsView extends Component<IAdminViewProps, {cid: string, forceRefresh: boolean}> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;

    constructor(props: IAdminViewProps) {
        super(props);
        this.state = { cid: props.user.cid || "", forceRefresh: false };

        this._getClientName = this._getClientName.bind(this);
        this._toggleEnabled = this._toggleEnabled.bind(this);
        this._deleteAdmin = this._deleteAdmin.bind(this);
        this._refresh = this._refresh.bind(this);
        this._downloadExport = this._downloadExport.bind(this);
    }

    async _toggleEnabled(admin: IClientAdmin) {
        if (confirm(`Are you sure you would like to ${admin.isDeactivated ? "enable" : "disable"} ${admin.name}?`)) {
            try {
                await AdminActions.doToggleDisabled(admin.aid);
                admin.isDeactivated = !admin.isDeactivated;
                createAlert(`Successfully ${admin.isDeactivated ? "disabled" : "enabled"} ${admin.name}`, "success");
                return true;
            } catch (error) {
                return false;
            }
        }
        return false;
    }

    async _deleteAdmin(admin: IClientAdmin | null) {
        if (!admin) return false;
        if (confirm(`Are you sure you wish to delete ${admin.name}? This action is not reversible.`)) {
            try {
                const success = await AdminActions.doDelete(admin.cid, admin.aid);
                if (success) {
                    this.setState({ forceRefresh: true })
                    createAlert(`Successfully deleted ${admin.name}`, "success");
                    // reset force refresh in case we're deleting multiple admins
                    this.setState({ forceRefresh: false })
                    return true;
                }
            } catch (error) {
                return false;
            }
        }
        return false;
    }

    async _refresh(gidsFilter="", isDeactivated=" ", permission="", search = "", cid = "", paginationData = {}) {
        //resets group to "All" when client is changed
        if (this.props.user.isSuper && cid !== this.state.cid) {
            gidsFilter = ""
        }
        if (this.props.user.isSuper) {
            this.setState({cid : cid})
        }

        //get all possible group names
        var _queryAllGids :string[] = []
        if (cid != "" ) {
            const { clients } = this.props;
            const { groups } = clients[cid];
            _queryAllGids = (Object.keys(groups))
        }
        return AdminActions.doList({
            ...(this.props.user.isSuper ? (cid ? { cid } : null) : { cid: this.props.user.cid }),
            ...(search ? { search } : null),
            ...(isDeactivated === "0" ? { isDeactivated: true } : null),
            ...(isDeactivated === "1" ? { isDeactivated: false } : null),
            ...(permission == "isSuper" ? { isSuper : true } : null),
            ...(permission == "isApiAdmin" ? {isApiAdmin : true } : null),
            ...(permission == "isSuperClientAdmin" ? {isSuperClientAdmin : true } : null),
            ...(permission == "isClientAdmin" ? {isClientAdmin : true } : null),
            ...(permission == "isCuratorUserAdmin" ? {isAdmin : true, isCurator : true} : null),
            ...(permission == "isCuratorReportsAdmin" ? {isReports : true, isCurator : true, isAdmin : false} : null),
            ...(permission == "isCurator" ? {isCurator : true, isAdmin : false } : null),
            ...(permission == "isAdmin" ? {isAdmin : true, isCurator: false} : null),
            ...(permission == "isReports" ? {isReports : true, isCurator : false } : null),
            ...(gidsFilter ? { groups : [gidsFilter] } : null),
            ...(gidsFilter == "noGroup" ?  {groups : [] } :  null),
            ...(gidsFilter == "allGroups" ?  {groups : _queryAllGids } : null),
            ...paginationData
        });
    }

    _getClientName(admin: IClientAdmin) {
        const { clients } = this.props;
        if (admin.cid) {
            if (clients && clients[admin.cid]) {
                return clients[admin.cid].name;
            } else if (clients && Object.keys(clients).length) {
                return "REMOVED";
            }
            return "Loading...";
        }
        return "None";
    }
    getGroupNames(admin: IClientAdmin) {
        const { clients } = this.props;
        if (!clients || !admin.cid || !clients[admin.cid]) {
            return [<span key={`unknown`}>Unknown</span>];
        }
        const { groups } = clients[admin.cid];
        const displayGroups: string[] = [];

        if (typeof admin.groups === "string") {
            admin.groups = [admin.groups];
        }
        if (admin.groups && admin.groups.length > 0) {
            admin.groups.forEach(g => {
                if (g != "") {
                    displayGroups.push((groups[g] || {name : "No Group"}).name) ;
                }
            });
        }
        if (displayGroups.length) {
            return displayGroups.map((val, i) => {
                return (
                    <span key={val}>
                        {i > 0 && <span>, </span>}
                        {val}
                    </span>
                );
            });
        } else {
            return [<span key={`no-group`}>No Group</span>];
        }
    }

    displaySuperAndFeed(cid: string){
        if (!cid) {
            return[
                {key : "Super Admin", value : "isSuper"},
                {key :"Feed Admin", value : "isApiAdmin"}
            ]
    }
    return null
}

    getOptionGroups(cid: string) {
        const { clients } = this.props;
        if (!clients || !cid || !clients[cid]) {
            return null;
        }
        const { groups } = clients[cid];
        const _groups = Object.values(groups || {}).map(g => ({ key: g!.name, value: g!.gid })).sort((a, b) => a.key.localeCompare(b.key));
        if (this.props.user.groups && this.props.user.groups.length) {
            return _groups.filter(item => this.props.user.groups.indexOf(item.value) > -1);
        }
        if (_groups.length == 0) {
            return false
        }
        return _groups;
    }

    _downloadExport(search: string, cid: string, permission, isDeactivated, gidsFilter: string) {
        cid = this.state.cid
        var _queryAllGids:string[] = []
        if (cid != "") {
        const { clients } = this.props;
        const { groups } = clients[cid];
        _queryAllGids = (Object.keys(groups))
    }
        AdminActions.doExportCSV({
            ...(this.props.user.isSuper && cid ? { cid } : null),
            ...(this.props.user.isClientAdmin && (this.props.user.groups || []).length
                ? { gids: this.props.user.groups }
                : null),
            ...(isDeactivated ? {isDeactivated} : {isDeactivated : null}),
            ...(permission ? {permission} : null),
            ...(_queryAllGids ? {_queryAllgids : _queryAllGids} : null ),
            ...(gidsFilter ? {gidsFilter} : null),
            ...(search ? { search } : null)
        });
    }
    getShortenedDescription(admin: IClientAdmin){
        if( typeof(admin.description) == "string"){
            var output = (admin.description.length > 100 ) ? (admin.description.substring(0,99)).concat("…") : admin.description;
            return output
        }
        else{
            return ""
        }

    }

    getPermission(admin: IClientAdmin) {
        if (admin.isSuper) {
            return "Super Admin";
        } else if (admin.isApiAdmin) {
            return "Feed Admin";
        } else if (admin.isSuperClientAdmin) {
            return "Super Client Admin";
        } else if (admin.isClientAdmin) {
            return "Client Admin";
        } else if (admin.isCurator && admin.isAdmin) {
            return "Curator Admin, User Admin";
        } else if (admin.isCurator && admin.isReports){
            return "Curator + Reports Admin"
        } else if (admin.isCurator) {
            return "Curator Admin";
        } else if (admin.isAdmin) {
            return "User Admin";
        } else if (admin.isReports) {
            return "Reports Admin";
        }
        return "N/A";
    }

    render() {
        const { isSuper, isSuperClientAdmin, isClientAdmin } = this.props.user || {};
        const { clients } = this.props;
        return (
            <Wrapper id="admins">
                <Row>
                    <ElementListBox<IClientAdmin>
                        title="Admins"
                        keys={{
                            cid: "Client",
                            name: "Name",
                            email: "Email",
                            permission: "Admin Type",
                            groups: "Group",
                            description: "Description",
                            isDeactivated: "Has Access",
                            lastUpdatedOn: "Last Updated",
                            lastLogin: "Last Login",
                            actions: "Actions"
                        }}
                        sortableKeys={["Name", "Email", "Last Updated"]}
                        defaultSort={{ lastUpdatedOn: -1 }}
                        filterCid={isSuper}
                        filterSearch={isSuper || isSuperClientAdmin || isClientAdmin}
                        forceRefresh={this.state.forceRefresh}
                        refresh={({gidsFilter, isDeactivated, permission ,cid, search}, paginationData) => this._refresh(gidsFilter, isDeactivated, permission, search, cid, paginationData)}
                        mutateItem={admin => ({
                            cid: admin.cid && clients && clients[admin.cid] ? <Link to={`/clients/e/${admin.cid}`}>{this._getClientName(admin)}</Link> : this._getClientName(admin),
                            name: admin.name,
                            email: admin.email,
                            permission: this.getPermission(admin),
                            groups : <span>{this.getGroupNames(admin)}</span>,
                            description : this.getShortenedDescription(admin),
                            isDeactivated: getBooleanIcon(!admin.isDeactivated),
                            lastUpdatedOn: admin.lastUpdatedOn ? getFriendlyDate(admin.lastUpdatedOn)! : "N/A",
                            lastLogin: admin.lastLogin ? getAdminDate(admin.lastLogin)! : "N/A"
                        })}
                        download={
                            this.props.user.isSuper ||
                            this.props.user.isSuperClientAdmin ||
                            this.props.user.isClientAdmin
                                ? ({ cid, search, permission, isDeactivated, gidsFilter}) => this._downloadExport(search, cid, permission, isDeactivated, gidsFilter)
                                : undefined
                        }
                        buttonElements={[
                            (item) => (
                                <Button
                                    key={`link-edit--${item.aid}`}
                                    className="btn--sm"
                                    to={`/admins/e/${item.cid ? `${item.cid}/${item.aid}` : item.aid}`}
                                    onClick={() => {
                                        createAlert(`Navigating to the edit page for admin ${item.name}`,'success');
                                    }}
                                >
                                    <i className="fa fa-edit" /> Edit
                                </Button>
                            ),
                            ...(this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin)
                                ?  [(item) => (<Button
                                    key={`link-enable--${item.aid}`}
                                    className="btn--sm"
                                    onClick={() => this._toggleEnabled(item)}
                                >
                                    <i className={item.isDeactivated ? "fa fa-check" : "fa fa-ban"} /> {item.isDeactivated ? "Enable" : "Disable"}
                                </Button>)]
                                : [],
                            ...(this.props.user.isSuper || this.props.user.isSuperClientAdmin)
                                ?  [(item) => item.isSuper || (item.isSuperClientAdmin && !isSuper)
                                        ? <div></div>
                                        : (<Button
                                    key={`link-delete--${item.aid}`}
                                    className="btn--sm"
                                    onClick={() => this._deleteAdmin(item)}
                                >
                                    <i className="fa fa-trash" /> Delete
                                </Button>)]
                                : []
                        ]}
                        createLink={`/admins/c/:cid`}
                        importLink={
                            this.props.user.isSuperClientAdmin ||
                                this.props.user.isClientAdmin
                                ? `/admins/u`
                                : undefined
                        }
                        filters={{
                            permission: {
                                title: "Admin Type",
                                options: [
                                    { key: "All", value: "" },
                                    ...(this.displaySuperAndFeed(this.state.cid) || []),
                                    { key: "Super Client Admin", value: "isSuperClientAdmin" },
                                    { key: "Client Admin", value: "isClientAdmin" },
                                    { key: "Curator Admin + User Admin", value: "isCuratorUserAdmin" },
                                    { key: "Curator + Reports Admin", value: "isCuratorReportsAdmin" },
                                    { key: "Curator Admin", value: "isCurator" },
                                    { key: "User Admin", value: "isAdmin" },
                                    { key: "Reports Admin", value: "isReports" }
                                ]
                            },
                            ...( this.state.cid !="" && this.getOptionGroups(this.state.cid) ?
                                {
                                    gidsFilter: {
                                        title: "Groups",
                                        options: [
                                            { key: "All", value: "" },
                                            {key: "No Group", value: "noGroup"},
                                            {key: "All Groups", value: "allGroups"},
                                            ...(this.getOptionGroups(this.state.cid) || [])
                                        ]
                            }} : null),
                            isDeactivated: {
                                title: "Access State",
                                options: [
                                    { key: "All", value: "" },
                                    { key: "Has Access", value: "1" },
                                    { key: "No Access", value: "0" }
                                ]
                            } }

                        }
                    />
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(AdminsView);
