import React, { Component } from "react";
import { connect } from "react-redux";
import { createAlert } from "../../../../actions/utils";
import { ReportActions } from "../../../../actions/ReportActions";
import Chart from "../../../molecules/Chart";
import { getLocaleDate, lowerCaseFirstLetter, downloadFile as fileDownload } from "../../../../utils/Helpers";
import { Wrapper, Row } from "../../../atoms/Layout";
import NumberChart from "../../../molecules/NumberChart";
import PercentCircle from "../../../atoms/PercentCircle";
import { IReportFilter } from "../Index";

interface INetworksProps {
    filters: IReportFilter;
    client?: Mongo.client
    user: Mongo.clientAdmin;
    onLoaded: () => void;
    showSearchResults: boolean;
}

interface INetworksState {
    networkPerformance: "loading" | {
        LinkedIn: number;
        Facebook: number;
        Instagram: number;
        Twitter: number;
        ExtraNetworks: number;
        LinkedInShares: number;
        FacebookShares: number;
        TwitterShares: number;
        InstagramShares: number;
        ExtraNetworkShares: number;
        LinkedInClicks: number;
        FacebookClicks: number;
        TwitterClicks: number;
        ExtraNetworkClicks: number;
        LIInteractions: number;
        FBInteractions: number;
        TWInteractions: number;
        IGInteractions: number;
    };
    networkAdoption: "loading" | {
        linkedIn: number;
        twitter: number;
        facebook: number;
        instagram: number;
        web: number;
        total: number;
    };
    streamPerformance: "loading" | Array<{
        Type: string;
        Title: string;
        Clicks: number;
        Articles: number;
        Media: number;
        Interactions: number;
    }>;
    sharesByType: "loading" | Array<{
        LI: number;
        "LI Pages": number;
        TW: number;
        "FB Company Pages": number;
        "FB Personal Page": number;
        IG: number;
        "Email & SMS": number;
        "Extra Networks": number;
    }>;
    sharesByNetwork: "loading" | Array<{
        LI: number;
        "LI Pages": number;
        TW: number;
        "FB Pages": number;
        IG: number;
        "Email & SMS": number;
        "Extra Networks": number;
        Shares: number;
        Clicks: number;
    }>;
    activatedUsersByWeek: "loading" | Array<{
        Week: string;
        "Users Sharing": number;
        "Active Users": number;
    }>;
    complianceProfiles: "loading" | {
        networks: number;
        installed: number;
        approved: number;
    };
    filters?: dynamic;
}

class NetworksSection extends Component<INetworksProps, INetworksState> {
    constructor(props) {
        super(props);

        this.state = {
            networkPerformance: "loading",
            networkAdoption: "loading",
            streamPerformance: "loading",
            sharesByType: "loading",
            sharesByNetwork: "loading",
            activatedUsersByWeek: "loading",
            complianceProfiles: "loading"
        };
    }

    componentDidMount() {
        if (!this.props.filters || !this.props.showSearchResults) return;
        if (
            this.state.networkPerformance == "loading" ||
            this.state.networkAdoption == "loading" ||
            this.state.streamPerformance == "loading" ||
            this.state.sharesByType == "loading" ||
            this.state.sharesByNetwork == "loading" ||
            this.state.activatedUsersByWeek == "loading" ||
            this.state.complianceProfiles == "loading"
        ) {
            this._fetchAll(this.props);
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.filters != this.props.filters) || (this.props.showSearchResults && !prevProps.showSearchResults)) {
            this._fetchAll(this.props);
        }
    }

    _fetchAll(props) {
        this.setState(
            {
                networkPerformance: "loading",
                networkAdoption: "loading",
                streamPerformance: "loading",
                sharesByType: "loading",
                sharesByNetwork: "loading",
                complianceProfiles: "loading",
                filters: props.filters
            },
            () => {
                const defaults = {
                    streamPerformance: [],
                    sharesByType: [],
                    sharesByNetwork: [],
                    activatedUsersByWeek: [],
                    networkPerformance: {
                        LinkedIn: 0,
                        Facebook: 0,
                        Instagram: 0,
                        Twitter: 0,
                        ExtraNetworks: 0,
                        LinkedInShares: 0,
                        FacebookShares: 0,
                        TwitterShares: 0,
                        InstagramShares: 0,
                        ExtraNetworkShares: 0,
                        LinkedInClicks: 0,
                        FacebookClicks: 0,
                        TwitterClicks: 0,
                        ExtraNetworkClicks: 0,
                        LIInteractions: 0,
                        FBInteractions: 0,
                        TWInteractions: 0,
                        IGInteractions: 0
                    },
                    networkAdoption: {
                        linkedIn: 0,
                        facebook: 0,
                        instagram: 0,
                        twitter: 0,
                        web: 0,
                        total: 0
                    },
                    complianceProfiles: {
                        networks: 0,
                        installed: 0,
                        approved: 0
                    }
                };
                Promise.all([
                    ReportActions.doGetNetworkSummary(props.filters)
                        .then(data => {
                            if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                                throw data.error;
                            }
                            this.setState({
                                streamPerformance: data.streamPerformance || defaults.streamPerformance,
                                networkPerformance: data.networkPerformance || defaults.networkPerformance,
                                sharesByType: data.sharesByType || defaults.sharesByType,
                                sharesByNetwork: data.sharesByNetwork || defaults.sharesByNetwork
                            });
                        })
                        .catch(error => {
                            this.setState(defaults);
                            createAlert(error, `error`);
                        }),
                    ReportActions.doGetNetworkAdoption(props.filters)
                        .then(data => {
                            if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                                throw data.error;
                            }
                            this.setState({ networkAdoption: data || defaults.networkAdoption });
                        })
                        .catch(error => {
                            this.setState({ networkAdoption: defaults.networkAdoption });
                            createAlert(error, `error`);
                        }),
                    ReportActions.doGetNetworksReport(props.filters, "activatedUsersByWeek", "json")
                        .then(data => {
                            this.setState({ activatedUsersByWeek: data || defaults.activatedUsersByWeek });
                        })
                        .catch(error => {
                            this.setState({ activatedUsersByWeek: defaults.activatedUsersByWeek });
                            createAlert(error, `error`);
                        }),
                    ...(props.client &&
                    props.client.complianceEnabled &&
                    props.client.compliance &&
                    props.client.compliance.vendor === "ng"
                        ? [
                              ReportActions.doGetComplianceProfiles(props.filters)
                                  .then(data => {
                                      if (typeof data == "object" && (data.error || ("valid" in data && !data.valid))) {
                                          throw data.error;
                                      }
                                      this.setState({ complianceProfiles: data || defaults.complianceProfiles });
                                  })
                                  .catch(error => {
                                      this.setState({ complianceProfiles: defaults.complianceProfiles });
                                      createAlert(error, `error`);
                                  })
                          ]
                        : [])
                ]).finally(props.onLoaded);
            }
        );
    }

    _fetchData(type, props, format = "json") {
        return ReportActions.doGetNetworksReport(props.filters, type, format)
            .then(data => {
                if (typeof data === "object" && (data.error || data.valid === false)) {
                    throw data.error;
                }
                if (format == "csv") {
                    fileDownload(data, `${type}-${getLocaleDate(new Date())}.csv`);
                }
                return data;
            })
            .catch(error => {
                createAlert(error, `error`);
                return [];
            });
    }

    _renderNetworkUserAdoption() {
        const { networkAdoption } = this.state;
        const isNetworkAvailable = network => !this.props.client || this.props.client.socialNetworks?.[network]?.available;

        const availableNetworks = [
            ...["LinkedIn", "Twitter", "Facebook"].filter(isNetworkAvailable).map(lowerCaseFirstLetter),
            ...(isNetworkAvailable("IG") ? ["instagram"] : []),
            ...(!this.props.client || this.props.client.hasExtraNetworks ? ["web"] : [])
        ];
        const labelObj = {
            linkedIn: "LinkedIn",
            twitter: "X (Twitter)",
            facebook: "Facebook",
            instagram: "Instagram",
            web: "Extra Networks"
        };

        const labels = availableNetworks.map(network => labelObj[network]);
        const data = typeof networkAdoption === "string" ? [] : availableNetworks.map(network => networkAdoption.total ? Number(((networkAdoption[network] / networkAdoption.total) * 100).toFixed(0)) : 0)
        const tooltipData = typeof networkAdoption === "string" ? [] : availableNetworks.map((network, i) => [
            `Activated Users: ${networkAdoption.total}`,
            `Connected Users: ${networkAdoption[network]}`,
            `Percentage: ${data[i]}%`
        ]);

        return <div className="col-xs-12 col-lg-6">
            <Chart
                title="Network Connections"
                type="bar"
                data={data}
                labels={labels}
                loading={typeof networkAdoption === "string"}
                showSearchResults={this.props.showSearchResults}
                height={300}
                tooltip={<span>
                    <p>
                        This card shows the percentage of your users that have adopted each social
                        network.
                    </p>
                    <p>Only users that have the ability to share will be included in this report.</p>
                </span>}
                customChartTooltips={{
                    displayColors: false,
                    callbacks: {
                        label: function(tooltipItem) {
                            return tooltipData[tooltipItem.index];
                        }
                    }
                }}
                max={100}
                stepSize={20}
            />
        </div>;
    }

    _renderNetworkPerformance() {
        const { networkPerformance } = this.state;
        const isNetworkAvailable = network => !this.props.client || this.props.client.socialNetworks?.[network]?.available;

        const availableNetworks = [
            ...["LinkedIn", "Twitter", "Facebook"].filter(isNetworkAvailable),
            ...(isNetworkAvailable("IG") ? ["Instagram"] : []),
            ...(!this.props.client || this.props.client.hasExtraNetworks ? ["web"] : [])
        ];
        const labelObj = {
            LinkedIn: "LinkedIn",
            Twitter: "X (Twitter)",
            Facebook: "Facebook",
            Instagram: "Instagram",
            web: "Extra Networks"
        };
        const shortForms = {
            LinkedIn: "LI",
            Twitter: "X(TW)",
            Facebook: "FB",
            Instagram: "IG"
        };
        const labels = availableNetworks.map(network => labelObj[network]);
        const data = typeof networkPerformance === "string" ? [] : availableNetworks.map(network => networkPerformance[network.split(" ").join("")]);
        const tooltipData = typeof this.state.networkPerformance === "string" ? [] : availableNetworks.filter(network => network !== "web").map(network => [
            `Shares: ${networkPerformance[`${network}Shares`]}`,
            `Clicks: ${networkPerformance[`${network}Clicks`] == null ? "N/A" : networkPerformance[`${network}Clicks`]}`,
            `Interactions: ${this.state.networkPerformance[`${shortForms[network]}Interactions`]}`,
            `Engagement Ratio: ${(
                (networkPerformance[`${shortForms[network]}Interactions`] + (networkPerformance[`${network}Clicks`] || 0)) /
                networkPerformance[`${network}Shares`]
            ).toFixed(2)}`
        ]);
        if (!this.props.client || this.props.client.hasExtraNetworks) {
            tooltipData.push([
                `Shares: ${networkPerformance["ExtraNetworkShares"]}`,
                `Clicks: ${networkPerformance["ExtraNetworkClicks"]}`,
                `Engagement Ratio: ${(
                    networkPerformance["ExtraNetworkClicks"] /
                    networkPerformance["ExtraNetworkShares"]
                ).toFixed(2)}`
            ]);
        }

        return <div className="col-xs-12 col-lg-6">
            <Chart
                title="Network Share Performance"
                type="bar"
                data={data}
                labels={labels}
                loading={typeof this.state.networkPerformance === "string"}
                showSearchResults={this.props.showSearchResults}
                height={300}
                tooltip={<span>
                    <p>This card shows the engagement ratio per social network,</p>
                    <p>
                        which is calculated by taking the sum of the total clicks, likes and
                        comments,
                    </p>
                    <p>then dividing that sum by total number of shares.</p>
                </span>}
                customChartTooltips={{
                    displayColors: false,
                    callbacks: {
                        label: function(tooltipItem) {
                            return tooltipData[tooltipItem.index];
                        }
                    }
                }}
            />
        </div>;
    }

    _renderPostsByStream() {
        const { streamPerformance, filters } = this.state;
        const labels: string[] = [];
        const datasets = [
            {
                label: "Content",
                data: [] as number[]
            },
            {
                label: "Clicks",
                data: [] as number[]
            },
            {
                label: "Social Interactions",
                data: [] as number[]
            }
        ];

        const byType = filters?.clientCode?.indexOf("ALL_") == 0;
        let dynamicHeight = 0;
        let hasData = false;
        if (Array.isArray(streamPerformance)) {
            streamPerformance.forEach(item => {
                labels.push(byType ? item.Type : item.Title);
                const clicks = isNaN(Number(item["Clicks"])) ? 0 : Number(item["Clicks"]);
                const media = isNaN(Number(item["Media"])) ? 0 : Number(item["Media"]);
                const articles = isNaN(Number(item["Articles"])) ? 0 : Number(item["Articles"]);
                const interactions = isNaN(Number(item["Interactions"])) ? 0 : Number(item["Interactions"]);
                datasets[0].data.push(media + articles);
                datasets[1].data.push(clicks);
                datasets[2].data.push(interactions);
                if (!hasData) {
                    hasData = !!(clicks || interactions || articles || media);
                }
            });
            if (hasData) {
                dynamicHeight = streamPerformance.length * 50;
            }
        }

        return <div className="col-xs-12 col-lg-6">
            <Chart
                title="Stream Performance"
                type="horizontal-bar"
                data={datasets}
                labels={labels}
                height={Math.max(400, dynamicHeight)}
                downloadAction={e => this._fetchData("streamPerformance", this.props, "csv")}
                loading={typeof streamPerformance === "string"}
                showSearchResults={this.props.showSearchResults}
                tooltip={<span>
                    <p>This report outlines which streams are getting the most clicks.</p>
                    <br />
                    <p>Content: a unique piece of content</p>
                    <p>Click: when a person clicks on an article link</p>
                    <p>Social Interactions: the total number of likes and comments</p>
                    <p>Stream: a collection of content grouped together</p>
                    <p>
                        Other: Content that is not composed or from a configured stream. E.g. shared
                        from 'Leaders', 'Related Articles', a Search in the app, or from 'Top Articles'
                    </p>
                </span>}
                displayBarLegend={true}
            />
        </div>;
    }

    _renderSharesByNetwork() {
        const { sharesByNetwork } = this.state;
        let data: number[] = [];
        let labels: string[] = [];
        if (sharesByNetwork && Array.isArray(sharesByNetwork) && sharesByNetwork.length) {
            Object.keys(sharesByNetwork[0]).forEach(k => {
                if (k != "Clicks" && k != "Shares") {
                    if (k == "LI") {
                        labels.push("LinkedIn");
                    } else if (k == "LI Pages") {
                        labels.push("LinkedIn Pages");
                    } else if (k == "X(TW)") {
                        labels.push("X (Twitter)");
                    } else if (k == "FB Pages") {
                        labels.push("Facebook Pages");
                    } else if (k == "IG") {
                        labels.push("Instagram");
                    } else if (k == "Email & SMS") {
                        labels.push("Direct Sharing");
                    } else if (k == "Extra Networks") {
                        labels.push("Extra Networks");
                    }
                    data.push(sharesByNetwork[0][k]);
                }
            });
        }
        return <div className="col-xs-12 col-lg-6">
            <Chart
                title="Shares By Network"
                loading={typeof sharesByNetwork == "string"}
                showSearchResults={this.props.showSearchResults}
                data={data}
                labels={labels}
                type="pie"
                tooltip={<p>This card shows the proportion of shares on each permitted network</p>}
                downloadAction={() => this._fetchData("sharesByNetwork", this.props, "csv")}
            />
        </div>;
    }

    _renderNetworkSharesByType() {
        const { sharesByType } = this.state;
        const labels: string[] = [];
        const datasets: dynamic = {};
        const labelMapping = {
            LI: "LinkedIn",
            "LI Pages": "LinkedIn Pages",
            "X(TW)": "X (Twitter)",
            "FB Pages": "Facebook Pages",
            IG: "Instagram",
            "Email & SMS": "Direct Sharing",
            "Extra Networks": "Extra Networks"
        };

        (typeof sharesByType === "string" ? [] as string[] : sharesByType).forEach(type => {
            labels.push(type.Type);
            Object.keys(type).forEach(key => {
                if (labelMapping[key]) {
                    if (!datasets[key]) {
                        datasets[key] = {
                            label: labelMapping[key],
                            data: []
                        }
                    }
                    datasets[key].data.push(type[key] + ((key === "FB Company Pages" && type["FB Personal Page"]) || 0));
                }
            });
        });

        return <div className="col-xs-12 col-lg-6">
            <Chart
                title="Network Shares by Type"
                type="horizontal-bar"
                labels={labels}
                data={Object.values(datasets)}
                displayBarLegend={true}
                loading={typeof sharesByType === "string"}
                showSearchResults={this.props.showSearchResults}
                downloadAction={e => this._fetchData("sharesByType", this.props, "csv")}
                tooltip={<p>
                    Other: Content that is not composed or from a configured stream. E.g. shared
                    from 'Leaders', 'Related Articles', a Search in the app, or from 'Top Articles'
                </p>}
            />
        </div>;
    }

    _renderUsersSharingByWeek() {
        const { activatedUsersByWeek } = this.state;
        const data = [
            {
                label: "Users Sharing",
                data: [] as number[]
            },
            {
                label: "Total Active Users",
                data: [] as number[]
            }
        ];
        const labels: string[] = [];
        if (activatedUsersByWeek && Array.isArray(activatedUsersByWeek) && activatedUsersByWeek.length) {
            for (const week of activatedUsersByWeek) {
                labels.push(week["Week"]);
                data[0].data.push(week["Users Sharing"] ? week["Users Sharing"] : 0);
                data[1].data.push(week["Active Users"] ? week["Active Users"] : 0);
            }
        }

        return (
            <div className="col-xs-12 col-lg-6">
                <Chart
                    title={`Users Sharing by Week`}
                    loading={typeof activatedUsersByWeek == "string" && activatedUsersByWeek.indexOf("loading") !== -1}
                    showSearchResults={this.props.showSearchResults}
                    data={Object.values(data)}
                    labels={labels}
                    type="line"
                    tooltip={
                        <p>
                            This card shows the number of active users in relation to the number of activated accounts.
                        </p>
                    }
                    downloadAction={() => this._fetchData("activatedUsersByWeek", this.props, "csv")}
                />
            </div>
        );
    }

    _renderPPInstallation() {
        return (
            <div className="col-xs-12 col-lg-6">
                <NumberChart
                    title="Proofpoint Installation"
                    tooltip={
                        <span>
                            <p>
                                This report shows the number and percentage of social network accounts that have
                                installed Social Patrol.
                            </p>
                            <p>It is refreshed at 12AM and 12PM UTC every day.</p>
                            <p>
                                It does not include LinkedIn Company accounts, accounts from users without the ability
                                to share,
                                <br />
                                or accounts from users that are excluded from reporting.
                            </p>
                            <p>
                                Social accounts in LiveSocial: the total number of social accounts (e.g. LinkedIn,
                                Facebook, Twitter)
                                <br />
                                over all users that are connected to LiveSocial
                            </p>
                            <p>
                                Social accounts in Patrol: the total number of social accounts (e.g. LinkedIn, Facebook,
                                Twitter)
                                <br />
                                over all users that have installed Social Patrol
                            </p>
                            <p>
                                Ratio: the number of social accounts in Patrol divided by the number of social accounts
                                in LiveSocial
                            </p>
                        </span>
                    }
                    data={{
                        "Social accounts in Grapevine6": {
                            label: ["Social accounts in LiveSocial"],
                            value:
                                typeof this.state.complianceProfiles !== "string" && this.state.complianceProfiles.networks
                                    ? this.state.complianceProfiles.networks.toLocaleString()
                                    : 0
                        },
                        "Social accounts in Patrol": {
                            label: ["Social accounts in Patrol"],
                            value:
                                typeof this.state.complianceProfiles !== "string" && this.state.complianceProfiles.installed
                                    ? this.state.complianceProfiles.installed.toLocaleString()
                                    : 0
                        },
                        Ratio: {
                            label: ["Ratio"],
                            value: (
                                <PercentCircle
                                    percent={
                                        typeof this.state.complianceProfiles !== "string" && this.state.complianceProfiles.networks > 0
                                            ? Number(
                                                  Math.round(
                                                      (this.state.complianceProfiles.installed /
                                                          this.state.complianceProfiles.networks) *
                                                          100
                                                  ).toFixed(0)
                                              )
                                            : 0
                                    }
                                />
                            )
                        }
                    }}
                    loading={typeof this.state.complianceProfiles === "string"}
                    showSearchResults={this.props.showSearchResults}
                    noDataLabel={
                        typeof this.state.complianceProfiles !== "string" && this.state.complianceProfiles.networks ? "" : "Could not find any data for this report"
                    }
                />
            </div>
        );
    }

    _renderPPApproval() {
        return (
            <div className="col-xs-12 col-lg-6">
                <NumberChart
                    title="Proofpoint Profile Approval"
                    tooltip={
                        <span>
                            <p>
                                This report shows the number and percentage of social accounts that have been approved
                                in Proofpoint.
                            </p>
                            <p>It is refreshed at 12AM and 12PM UTC every day.</p>
                            <p>
                                It does not include LinkedIn Company accounts, accounts from users without the ability
                                to share,
                                <br />
                                or accounts from users that are excluded from reporting.
                            </p>
                            <p>
                                Social accounts in Patrol: the total number of social accounts (e.g. LinkedIn, Facebook,
                                X (Twitter))
                                <br />
                                over all users that have installed Social Patrol
                            </p>
                            <p>
                                Profiles approved: the total number of social accounts (e.g. LinkedIn, Facebook,
                                X (Twitter))
                                <br />
                                over all users whose profiles have been approved in Proofpoint
                            </p>
                            <p>
                                Ratio: the number of profiles approved divided by the number of social accounts in
                                Patrol
                            </p>
                        </span>
                    }
                    data={{
                        "Social accounts in Patrol": {
                            label: ["Social accounts in Patrol"],
                            value:
                                typeof this.state.complianceProfiles !== "string" && this.state.complianceProfiles.installed
                                    ? this.state.complianceProfiles.installed.toLocaleString()
                                    : 0
                        },
                        "Profiles approved": {
                            label: ["Profiles approved"],
                            value:
                                typeof this.state.complianceProfiles !== "string" && this.state.complianceProfiles.approved
                                    ? this.state.complianceProfiles.approved.toLocaleString()
                                    : 0
                        },
                        Ratio: {
                            label: ["Ratio"],
                            value: (
                                <PercentCircle
                                    percent={
                                        typeof this.state.complianceProfiles !== "string" && this.state.complianceProfiles.installed > 0
                                            ? Number(
                                                  Math.round(
                                                      (this.state.complianceProfiles.approved /
                                                          this.state.complianceProfiles.installed) *
                                                          100
                                                  ).toFixed(0)
                                              )
                                            : 0
                                    }
                                />
                            )
                        }
                    }}
                    loading={
                        !this.state.complianceProfiles ||
                        typeof this.state.complianceProfiles === "string"
                    }
                    showSearchResults={this.props.showSearchResults}
                    noDataLabel={
                        this.props.client && this.props.client.profileApprovalRequired && typeof this.state.complianceProfiles !== "string"
                            ? this.state.complianceProfiles.installed
                                ? ""
                                : "Could not find any data for this report"
                            : "Could not find any data for this report; feature not enabled"
                    }
                />
            </div>
        );
    }

    render() {
        return (
            <Wrapper id="networks">
                <Row>
                    {this.props.client &&
                        this.props.client.complianceEnabled &&
                        this.props.client.compliance &&
                        this.props.client.compliance.vendor === "ng" &&
                        this._renderPPInstallation()}
                    {this.props.client &&
                        this.props.client.complianceEnabled &&
                        this.props.client.compliance &&
                        this.props.client.compliance.vendor === "ng" &&
                        this._renderPPApproval()}
                    {this._renderNetworkUserAdoption()}
                    {this._renderNetworkPerformance()}
                    {this._renderSharesByNetwork()}
                    {this._renderNetworkSharesByType()}
                    {this._renderPostsByStream()}
                    {this._renderUsersSharingByWeek()}
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });
export default connect(mapStateToProps)(NetworksSection);
