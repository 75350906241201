import React, { Component } from "react";
import { connect } from "react-redux";

import PageHeading from "../../molecules/PageHeading";
import Spinner from "../../atoms/Spinner";
import Table from "../../molecules/Table";
import { UtilActions } from "../../../actions";
import { createAlert } from "../../../actions";
import { doGetCompanies } from "../../../actions/lists";
import { doRemoveCompany } from "../../../actions/deletes";
import Link from "../../atoms/Link";
import Button from "../../atoms/Button";
import { Wrapper, Row } from "../../atoms/Layout/";
import debounce from "lodash/debounce";
import Pagination from "../../molecules/Pagination";

class CompanyView extends Component {
    constructor(props) {
        super(props);

        this._onSearchChange = this._onSearchChange.bind(this);
        this._refresh = this._refresh.bind(this);
        this._renderTable = this._renderTable.bind(this);
        this.debounce = debounce(this._refresh, 1000);
        this._createAlert = debounce((message, type) => {
            createAlert(message, type);
        }, 500);

        this.state = {
            companies: "loading",
            searchQuery: "",
            countries: [],
            page: 0,
            limit: 20,
            total: 0
        };
        this.searchTimeout = null;
    }

    async componentDidMount() {
        await this._refresh();
        this.userInput.focus();
        this.setState({ countries: await UtilActions.doGetCountries() });
    }

    _onSearchChange(event) {
        let searchQuery = event.target.value;
        this.setState(
            {
                searchQuery: searchQuery
            },
            () => {
                this.debounce;
                this._createAlert('Search filter updated', 'success');
            }
        );
    }

    async _refresh(newPage = 0) {
        const { searchQuery: search, limit } = this.state;
        const data = { search, limit, skip: newPage > 0  ? newPage * limit : newPage}
        const {items, count} = await doGetCompanies(data);
        let companies = {};
        if (items) {
            items.forEach(item => {
                companies[item.companyId] = item;
            });
        }
        this.setState({ companies, total: count, page: newPage })
    }

    _renderTable() {
        const { companies } = this.state;

        let displayItems = [];
        let displayDescriptions = [];
        let displayKeys = ["Name", "Industry", "Sub-Industry", "Country", "State", "City", "Actions"];

        Object.keys(companies).map((companyId, i) => {
            var company = companies[companyId];
            displayDescriptions.push(
                <div
                    style={{
                        display: "flex"
                    }}
                />
            );
            const country = (this.state.countries || []).find(c => c.code == company.country);
            let item = [
                <Link to={`/company/e/${companyId}`}>{company.name}</Link>,
                company.industry,
                (company.subIndustry || []).join(", "),
                (country || { name: "" }).name,
                company.state,
                company.city,
                [
                    <Button
                        className="btn--sm"
                        onClick={async () => {
                            if(
                                confirm(`Are you sure you want to delete this company ${company.name}? `)
                            ) {
                                await doRemoveCompany(companyId);
                                await this._refresh();
                            }
                        }}
                    >
                        Delete
                    </Button>,
                    <Button className="btn--sm" to={`/company/e/${companyId}`}>
                        Edit
                    </Button>
                ]
            ];
            displayItems.push(item);
        });
        return (
            <Table
                name="Companies"
                keys={displayKeys}
                items={displayItems}
                descriptions={displayDescriptions}
            />
        );
    }

    render() {
        const { companies, page, limit, total } = this.state;
        let buttons = [
            {
                title: "Create Company",
                link: "/company/c"
            }
        ];
        if (companies == "loading") {
            return <Spinner />;
        }
        return (
            <Wrapper id="companies">
                <PageHeading title={`Companies`} buttons={buttons} />
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <div className="table__filters">
                                    <div className="table__filters__option">
                                        <label htmlFor="search">Search:</label>
                                        <div className="search">
                                            <input
                                                id="search"
                                                className="admins__search"
                                                ref={input => {
                                                    this.userInput = input;
                                                }}
                                                type="text"
                                                onChange={this._onSearchChange}
                                            />
                                            <i className="fa fa-search" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {this._renderTable()}
                                { total > limit && (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "20px",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Pagination
                                            style={{ marginLeft: "auto" }}
                                            page={page}
                                            totalItems={total}
                                            numPerPage={limit}
                                            currItems={(Object.keys(companies) || []).length}
                                            changePage={async newPage => await this._refresh(newPage)}
                                            type="select"
                                            disabled={false}
                                            showTotal={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
}

CompanyView.allowSuper = true;
CompanyView.allowApiAdmin = true;
CompanyView.allowClientAdmin = false;
CompanyView.allowAdmin = false;
CompanyView.allowCurator = false;
CompanyView.allowReports = false;
const mapStateToProps = state => ({
    companies: state.lists.companies,
    companyLoading: state.lists.companyLoading,
    user: state.session.admin
});

export default connect(mapStateToProps)(CompanyView);
