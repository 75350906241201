import React from 'react';
import Modal from 'react-bootstrap-modal';
import {Document, Page} from 'react-pdf';
import {doClearReleaseNotes} from '../../actions/session';
class ReleaseNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            numPages: 0
        };
        this._closeModal = this._closeModal.bind(this);
        this.onDocumentLoad = this.onDocumentLoad.bind(this);
        this.onDocumentFail = this.onDocumentFail.bind(this);
    }
    _closeModal() {
        this.setState({open: false});
        doClearReleaseNotes(this.props.admin);
    }

    onDocumentLoad({numPages}) {
        this.setState({numPages: numPages});
    }
    onDocumentFail(err) {
        this.setState({fail: true});
    }
    renderFile() {
        if (!this.state.fail) {
            let pages = [];
            for (let i = 1; i <= this.state.numPages; i++) {
                pages.push(<Page className="w-100" pageNumber={i}/>);
            }

            return (<div>
                <Document file={`${this.props.url}`} onLoadSuccess={this.onDocumentLoad} onLoadError={this.onDocumentFail}>
                    {pages}
                </Document>
            </div>);
        } else {
            return (<span>Please check out the changelog for this release
                <a href={this.props.url} target="_blank">&nbsp;</a>
            </span>);
        }
    }

    render() {
        if (this.state.open) {
            return (<Modal show={this.state.open} onHide={this._closeModal} aria-labelledby="ModalHeader">
                <Modal.Header closeButton="closeButton">
                    <Modal.Title id='ReleaseNotesModal'><a href={this.props.url} target="_blank">{`${this.props.version} - Click here to open in a new page`}</a></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderFile()}
                </Modal.Body>
            </Modal>)
        }
        return (<div/>);
    }
}

export default ReleaseNotes;
