import BaseStream, { IBaseStreamState } from "./BaseStream";
import { doGetStreamsOfType } from "../../../../actions";

export interface IDefaultStreamState extends IBaseStreamState {
    streamsOfType: ServerTypes.Console.IStream[];
}
export default class DefaultStream extends BaseStream<IDefaultStreamState> {
    getInitialState() {
        const props = super.getInitialState();
        return {
            ...props,
            ...(props.stream
                ? {
                      stream: {
                          ...props.stream,
                          ...(!props.stream.sid ? { lang: "" } : null),
                          code: props.stream.searchType
                      }
                  }
                : null),
            canPreview: false,
            streamsOfType: []
        };
    }
    async componentDidMount() {
        await super.componentDidMount();

        const { client } = this.props;
        const { stream } = this.state;

        this.setState({
            streamsOfType: (await doGetStreamsOfType(client.cid, stream.searchType, stream.code)).items

        });
    }

    getSettings() {
        const { client } = this.props;
        const settings = super.getSettings();

        const { tags } = settings;
            const { stream, streamsOfType } = this.state;

            let disabledTags: string[] = [];
            const streamsOfTypeAndLang = streamsOfType.filter(s => s.lang == stream.lang);
            streamsOfTypeAndLang.forEach(s => {
                if (s.tags && s.sid !== stream.sid) {
                    s.tags.forEach(tag => disabledTags.push(tag))
                }
            });

            return {
                ...settings,
                tags: {
                    ...tags!,
                    values: [
                        { value: "", label: "None" },
                        ...Object.keys(client.tags || {}).map((tag, i) => ({
                            value: tag,
                            label: client.tags![tag].name,
                            disabled: disabledTags.includes(tag)
                        }))
                    ]
                }
            };
    }
}
