import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeading from "../../../molecules/PageHeading";
import { CreatableLibrary } from "../../../atoms/CreatableLibrary";
import { createAlert } from "../../../../actions/utils";
import Spinner from "../../../atoms/Spinner";
import { doGetReduxClients, doGetReduxClient } from "../../../../actions/lists";
import { doUpdateTerms } from "../../../../actions/inserts";

import { getLanguages } from "../../../../utils/Helpers";
import Button from "../../../atoms/Button";
import { Wrapper, Row } from "../../../atoms/Layout/";

class Term extends Component {
    constructor(props) {
        super(props);
        this._onFilterChange = this._onFilterChange.bind(this);
        this._getClientName = this._getClientName.bind(this);

        this.state = {
            loading: true,
            client: null,
            lang: "en",
            disabled: "disabled",
            termToAdd: "",
            terms: []
        };
    }
    _getClientName(cid) {
        const { clients } = this.props;
        if (cid) {
            if (clients && clients[cid]) {
                return clients[cid].name;
            }
        }
        return "";
    }
    componentDidMount() {
        if (this.props.user.isSuper) {
            doGetReduxClients(true);
        } else {
            doGetReduxClient(this.props.user.cid).then(() => {
                let newState = {};
                newState["disabled"] = "";
                newState["client"] = this.props.clients[this.props.user.cid];
                newState["lang"] = this.props.clients[this.props.user.cid].lang;
                if (this.props.clients[this.props.user.cid].terms) {
                    newState["terms"] = this.props.clients[this.props.user.cid].terms[
                        this.props.clients[this.props.user.cid].lang
                    ];
                }
                newState["loading"] = false;
                this.setState(newState);
            });
        }
    }
    componentDidUpdate() {
        if (this.props.user.isSuper && this.state.loading) {
            if (Object.keys(this.props.clients).length) {
                let newState = {};
                newState["loading"] = false;
                this.setState(newState);
            }
        }
    }
    _makeTerm(v) {
        return v.replace(/,/gi, "").substr(0, 50);
    }
    _updateClient() {
        if (this.state.client && this.state.lang && this.state.terms) {
            const client = this.state.client;
            !client.terms && (client.terms = {});
            client.terms[this.state.lang] = this.state.terms;
            doUpdateTerms(client, false);
        }
    }
    _onFilterChange(fieldName, event) {
        let newState = {};
        let val = event.target ? event.target.value : event;
        if (fieldName == "cid") {
            fieldName = "client";
            val = (this.props.clients || {})[val];
            val ? (newState["disabled"] = "") : (newState["disabled"] = "disabled");
            !val.terms && (val.terms = {});
            newState["terms"] = val.terms[this.state.lang];
        }
        if (fieldName == "lang") {
            !this.state.client.terms && (this.state.client.terms = {});
            newState["terms"] = this.state.client.terms[val];
        }
        if (fieldName == "termToAdd") {
            val = this._makeTerm(val);
        }
        newState[fieldName] = val;
        if (Object.keys(newState).length > 0) {
            this.setState(newState, () => {
                fieldName == "terms" && this._updateClient();
            });
        }
    }
    _addToTerms() {
        if (this._makeTerm(this.state.termToAdd)) {
            let newState = {};
            if (this.state.terms) {
                if (this.state.terms.length >= 100) {
                    createAlert(
                        "You have reached the maximum amount of terms. Please delete some terms in order to add more",
                        `error`
                    );
                } else {
                    const checkTerm = obj =>
                        obj.term.toLowerCase() === this._makeTerm(this.state.termToAdd).toLowerCase();
                    if (this.state.terms.some(checkTerm)) {
                        createAlert("You already have this term in your library", `error`);
                    } else {
                        newState.terms = this.state.terms;
                        newState.terms.push({ term: this._makeTerm(this.state.termToAdd), checked: true });
                        newState.termToAdd = "";
                    }
                }
            } else {
                newState.terms = [{ term: this._makeTerm(this.state.termToAdd), checked: true }];
                newState.termToAdd = "";
            }
            if (Object.keys(newState).length > 0) {
                this.setState(newState, () => this._updateClient());
            }
        }
    }
    render() {
        const { termToAdd, client, lang, disabled, terms, loading } = this.state;

        let termOptions = [];

        if (terms) {
            terms.forEach(term => {
                termOptions.push({ value: term.term, label: term.term });
            });
            termOptions.sort((a, b) => a.value.localeCompare(b.value));
        }
        if (loading) {
            return (
                <Wrapper id="termPage">
                    <div className="row page-heading">
                        <Spinner />
                    </div>
                </Wrapper>
            );
        } else {
            return (
                <Wrapper id="termPage">
                    <PageHeading title={`Term Library`} />
                    <Row>
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-content">
                                    <div className="table__filters">
                                        {this.props.user.isSuper && (
                                            <div className="table__filters__option">
                                                <label htmlFor="clients">Client:</label>
                                                <div className="select__wrapper">
                                                    <select
                                                        id="clients"
                                                        className="filters__search"
                                                        value={(client || {}).cid}
                                                        onChange={event => this._onFilterChange("cid", event)}
                                                    >
                                                        <option value="">Select...</option>
                                                        {Object.keys(this.props.clients).map((key, i) => {
                                                            let c = this.props.clients[key];
                                                            return (
                                                                <option key={c.cid} value={c.cid}>
                                                                    {c.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        <div className="table__filters__option">
                                            <label htmlFor="langs">Language:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="langs"
                                                    className={`filters__search ${disabled}`}
                                                    value={lang || "en"}
                                                    onChange={event => this._onFilterChange("lang", event)}
                                                    disabled={disabled}
                                                >
                                                    {getLanguages("al").map((val, i) => {
                                                        return (
                                                            <option key={val.code} value={val.code}>
                                                                {val.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table__filters__option">
                                            <label htmlFor="add">Add Term:</label>
                                            <div className="term-field">
                                                <input
                                                    id="add"
                                                    className={disabled}
                                                    ref={input => {
                                                        this.userInput = input;
                                                    }}
                                                    type="text"
                                                    placeholder=""
                                                    maxLength="50"
                                                    onChange={event => this._onFilterChange("termToAdd", event, true)}
                                                    value={termToAdd}
                                                    disabled={disabled}
                                                    onKeyDown={e => {
                                                        e.key === "Enter" && this._addToTerms();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="table__filters__option">
                                            <label className="control-label" style={{ width: "100%" }}>
                                                &nbsp;
                                            </label>
                                            <Button
                                                id="submit"
                                                onClick={() => this._addToTerms()}
                                                className={disabled}
                                                disabled={disabled}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                    <label htmlFor="termCreatable">Selected Terms:</label>
                                    <CreatableLibrary
                                        id="termCreatable"
                                        placeholder="Add a term..."
                                        disabled={disabled ? true : false}
                                        onChange={val => {
                                            let terms = [];
                                            if (val) {
                                                val.forEach(v => {
                                                    if (v.value) {
                                                        terms.push({ term: this._makeTerm(v.value), checked: true });
                                                    }
                                                });
                                            }
                                            if (terms) {
                                                this._onFilterChange("terms", terms, () => {});
                                            }
                                        }}
                                        value={termOptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Wrapper>
            );
        }
    }
}
Term.allowSuper = true;
Term.allowApiAdmin = false;
Term.allowClientAdmin = true;
Term.allowSuperClientAdmin = true;
Term.allowAdmin = false;
Term.allowCurator = false;
Term.allowReports = false;
const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(Term);
