import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import ErrorContainer from "../containers/ErrorContainer";

const RoleRestrictedRoute = ({ component, render, ...props }: RouteProps & { admin: Mongo.clientAdmin }) => {
    const {
        allowSuper,
        allowApiAdmin,
        allowSuperClientAdmin,
        allowClientAdmin,
        allowAdmin,
        allowCurator,
        allowReports
    } = (component as dynamic);
    const { isSuper, isApiAdmin, isSuperClientAdmin, isClientAdmin, isAdmin, isCurator, isReports } = props.admin;

    if (
        (isSuper && (allowSuper || typeof allowSuper == "undefined")) ||
        (isApiAdmin && (allowApiAdmin || typeof allowApiAdmin == "undefined")) ||
        (isSuperClientAdmin &&
            (allowSuperClientAdmin ||
                typeof allowSuperClientAdmin == "undefined" ||
                (allowClientAdmin || typeof allowClientAdmin == "undefined"))) ||
        (isClientAdmin && (allowClientAdmin || typeof allowClientAdmin == "undefined")) ||
        (isAdmin && (allowAdmin || typeof allowAdmin == "undefined")) ||
        (isCurator && (allowCurator || typeof allowCurator == "undefined")) ||
        (isReports && (allowReports || typeof allowReports == "undefined"))
    ) {
        if (render) {
            return <Route {...props} render={render} />;
        } else if (component) {
            return <Route {...props} component={component} />;
        }
    }
    return (
        <Route
            {...props}
            render={props => <ErrorContainer error={<span>You do not have permission to access this page</span>} {...props} />}
        />
    );
};

const mapStateToProps = state => ({ admin: state.session.admin });

export default connect(mapStateToProps)(RoleRestrictedRoute);
