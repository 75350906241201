import React, { Component } from "react";
import { Search } from "../../../../utils/Models";
import PermissionSet, { IPermission } from "../../../molecules/PermissionSet";
import {
    doGetStreams,
    doUpsertStream,
    createAlert,
    doSetFooterFunctions,
    doDeleteStream,
    doPreviewStream,
    UtilActions,
    doGetArticles,
    doGetStream,
    doGetLinkedinCompanyPageMentions
} from "../../../../actions";
import { ArticleActions } from "../../../../actions/ArticleActions";
import Breadcrumbs from "../../../molecules/Breadcrumbs";
import { getLanguages, sanitizeHtml } from "../../../../utils/Helpers";
import DomainFilter from "../../../molecules/DomainFilter";
import { history } from "../../../../utils/store";
import Button from "../../../atoms/Button";
import StreamPreview from "../../../molecules/modals/StreamPreview";
import Spinner from "../../../atoms/Spinner";
import { Wrapper, Row } from "../../../atoms/Layout/";
import { parsePeriod } from "../../../molecules/Periods";
import Tooltip from "../../../atoms/Tooltip";
import Toggle from "../../../atoms/Toggle";
import { RouteComponentProps } from 'react-router-dom';
import { _ValueContainer, _hasMentionOrHashtag, _formatHashtagOrMention } from "../../../pages/articles/sharedArticle";
import { getClientSharePermissions, getAllSharePermissions, getClientNetworks } from '../../../common';

interface IBaseStreamProps {
    client: Mongo.client;
    stream: ServerTypes.Console.IStream;
    clients: dynamic<Mongo.client>;
    admin: Mongo.clientAdmin;
    location: RouteComponentProps["location"];
}

export interface IBaseStreamState {
    client: ServerTypes.Console.IClient;
    stream: ServerTypes.Console.IStream;
    streams: ServerTypes.Console.IStream[];
    sequentialArticles: {
        artid: string;
        title: string;
        domain: string | null;
        sequentialOrder: number;
        hasScheduled: boolean;
    }[];
    countries: Array<{ code: string; name: string }>;
    preview: boolean;
    canPreview: boolean;
    canPreviewOnCreate: boolean;
    cachePeriod?: ServerTypes.Console.ISubscriptionPeriod[];
    syncCid: string;
    syncSid: string;
    syncType: string;
    syncUpdates: boolean;
    bgColor: string;
    secondaryColor: string;
    primaryColor: string;
    isLockHashtagsBtnToggled: boolean;
    whiteLabelURL: string;
    editStartTime: Date;
}

export type IBaseStream = Component;
export default class BaseStream<V extends IBaseStreamState = IBaseStreamState> extends Component<IBaseStreamProps, V>
    implements IBaseStream {
    constructor(props: IBaseStreamProps) {
        super(props);
        this.state = this.getInitialState() as V;
    }

    getInitialState() {
        return {
            client: this.props.client,
            streams: [],
            stream: Search({
                ...this.props.stream,
                contentSearch: false,
                isCustom: true,
                ...(typeof this.props.stream.categories === "string"
                    ? { categories: [this.props.stream.categories as string] }
                    : null)
            }),
            sequentialArticles: [],
            countries: [],
            preview: false,
            canPreview: true,
            canPreviewOnCreate: true,
            syncCid: "",
            syncSid: "",
            syncType: "push",
            syncUpdates: false,
            bgColor: "",
            secondaryColor: "",
            primaryColor: "",
            isLockHashtagsBtnToggled: false,
            whiteLabelURL: "",
            editStartTime: new Date(),
        } as IBaseStreamState;
    }
    async componentDidMount() {
        const { client } = this.props;
        const { stream } = this.state;
        const sharePermissions = getClientSharePermissions(client).map(perm => perm.toLowerCase());
        Object.keys(stream.defaultSharingPermissions || {}).forEach(perm => {
            if (typeof stream.defaultSharingPermissions![perm] !== 'object' && sharePermissions.indexOf(perm) === -1) {
                stream.defaultSharingPermissions![perm] = false;
            }
        });
        const whiteLabelSettings = await UtilActions.doGetWhiteLabelSettings(client.configName || "none");
        let syncType = this.props.stream.syncPullTargets && this.props.stream.syncPullTargets.length > 0 ? "pull" : this.state.syncType;
        const whiteLabelURLs = await UtilActions.doGetWhiteLabelURL(client && client.configName || 'none');

        this.setState({
            stream,
            streams: (await doGetStreams(true, client.cid)).items,
            sequentialArticles: stream.sequentialContent ? (await doGetArticles(client.cid, stream.sid, "approved", 0, 1000, null, null, null, null, null, null, true, false, 1)).items?.map(article => ({
                artid: article.artid,
                title: article.Title || "",
                domain: article.Domain || "",
                sequentialOrder: article.sequentialOrder,
                hasScheduled: article.hasScheduled || false
            })).sort((art1, art2) => art1.sequentialOrder - art2.sequentialOrder) : [],
            countries: await UtilActions.doGetCountries(stream.lang || "en"),
            secondaryColor: whiteLabelSettings?.secondaryColor || "#FFFFFF",
            primaryColor: whiteLabelSettings?.primaryColor || "#FFFFFF",
            syncType,
            whiteLabelURL: whiteLabelURLs ? whiteLabelURLs.url : ""
        });
        doSetFooterFunctions(
            () => this.submit(),
            this.isInsert()
                ? undefined
                : () => {
                    if (confirm("Are you sure you want to delete this stream?")) {
                        this.delete();
                    }
                },
            this.isInsert() ? "Create" : "Update",
            "Delete"
        );
    }

    componentWillUnmount() {
        doSetFooterFunctions(null, null);
    }

    async delete() {
        const { client, admin } = this.props;
        const { stream } = this.state;
        await doDeleteStream(client.cid, stream.sid);
        createAlert(`Sucessfully removed the stream`, `success`);
        setTimeout(() => {
            history.push(`${admin.isSuper ? `/clients/s/${client.cid}` : "/content/c"}`);
        }, 0);
    }

    async submit() {
        const { client, admin } = this.props;
        const { stream, countries } = this.state;
        const oldSharingPermissions = this.props.stream.defaultSharingPermissions;
        const sharingPermissions = stream.defaultSharingPermissions;
        const reservedStreamNames = ["Bookmarks", "Previously Hidden Articles"];

        const isAnyNetworksSelected = stream.defaultSharingPermissions?.socialNetworkPermissions ?
            Object.values(stream.defaultSharingPermissions?.socialNetworkPermissions).some(Boolean) :
            true

        if (client.allowNetworkRestrictions && stream.defaultSharingPermissions?.social && !isAnyNetworksSelected) {
            createAlert(`You must select at least one social network`, `error`);
            return
        }

        if (!stream.title) {
            createAlert(`You need to include a title`, `error`);
            return;
        }
        if (reservedStreamNames.includes(stream.title)) {
            createAlert(`The stream title cannot be "${stream.title}" as it is a reserved name`);
            return;
        }
        if(!stream.readOnly && !stream.defaultSharingPermissions?.social && !stream.defaultSharingPermissions?.email && !stream.defaultSharingPermissions?.sms){
            createAlert(`You need to include at least one Default Sharing Permission`, `error`);
            return;
        }
        if (oldSharingPermissions && typeof oldSharingPermissions == "object" && sharingPermissions && typeof sharingPermissions == "object") {
            if (!Object.values(oldSharingPermissions).some(perm => perm) && Object.values(sharingPermissions).some(perm => perm)) {
                const newPermissionsArr = Object.keys(sharingPermissions).filter(key => sharingPermissions[key]);
                const newPermissions = newPermissionsArr.map(key => {
                    if (key === "sms") {
                        return key.toUpperCase();
                    } else {
                        return key.charAt(0).toUpperCase() + key.slice(1);
                    }
                }).join(', ');
                if (!confirm(`This stream's default sharing permissions were previously set to read-only.\n\nAre you sure you want to change the Default Sharing Permissions to ${newPermissions}?`)) {
                    return;
                }
            }
        }

        if (stream.seismicFeeds?.length && !stream.seismicUsername) {
            createAlert(`You cannot include Seismic Channels without providing a valid Seismic Username`, `error`);
            return;
        }

        // ensure no sharing permission is set to true if readOnly is true
        if(stream.readOnly) {
            for(let item in stream.defaultSharingPermissions) {
                stream.defaultSharingPermissions[item] = false;
            };
        };

        if (stream.sequentialContent && !stream.sequentialCadence) {
            createAlert(`You must specify a value for "Sequential Cadence" as "Enable Sequential Content" is toggled on.`, `error`);
            return;
        }


        const _stream: ServerTypes.Console.IStream = Search(stream);
        const countryArray = countries.map(x => x.code);
        if (!(await this.canSubmit(_stream))) {
            return;
        }

        if (!stream.sequentialContent) {
            delete _stream.sequentialCadence;
            delete _stream.sequenceStartDate;
            delete _stream.sequenceEndDate;
            delete _stream.sequentialContentEnabledDate
        }

        if (stream.sequentialContent && stream.sequenceStartDate) {
            if (stream.sequenceStartDate < new Date()) {
                createAlert(`Sequence Start Date must be in the future.`, `error`);
                return;
            }
            // default to 1 year in the future
            if (!_stream.sequenceEndDate) {
                _stream.sequenceEndDate = new Date(new Date(stream.sequenceStartDate).setFullYear(stream.sequenceStartDate.getFullYear() + 1));
            }
        }

        // If the user selects all the countries, clear them.
        if (stream.locations && countryArray.every(e => (stream.locations ? stream.locations.includes(e) : false))) {
            _stream.locations = [];
        }
        if (stream.subscriptionPeriods && stream.subscriptionPeriods.length) {
            _stream.subscriptionPeriods = stream.subscriptionPeriods.map(p => parsePeriod(p));
        }
        if (stream.type == "FEED") {
            // check if a once expired token from a social page has been removed
            if (stream.linkedInFeeds?.expiredFeedTokens?.length || stream.facebookFeeds?.expiredFeedTokens?.length || stream.twitterFeeds?.expiredFeedTokens?.length) {
                await this.checkExpiredFeedTokens()
            }
        }

        let newMentions = _stream.Mentions || [];
        let articlesToExpire: string[] = [];
        if (client.cid && stream.sid) {
            const existingStream = await doGetStream(client.cid, stream.sid);
            if (new Date(existingStream?.lastUpdatedOn) > this.state.editStartTime) {
                createAlert("This stream has been updated since you started editing it. Please make note of your changes and refresh the page to try again.", "error");
                return;
            }
            if (!existingStream?.sequentialContent && !!stream.sequentialContent) {
                const existingArticles = await doGetArticles(client.cid, stream.sid, "approved");
                if (existingArticles.items.length > 0) {
                    if (confirm("This stream has existing articles. Enabling sequential content will expire all existing articles. Would you like to continue?")) {
                        articlesToExpire = existingArticles.items.map(article => article.artid);
                    }
                }
            }
            newMentions = newMentions.filter(mention => !existingStream?.Mentions?.includes(mention));
        }

        await doUpsertStream(client.cid, {..._stream, allowSectionView: client && client.allowSectionView ? client.allowSectionView : false});

        // If a user enables the lock-hashtags toggle button from disabled on an existing stream, update articles in that stream
        // (check for sid to determine if we are editing an existing stream)
        if (_stream.LockHashtagsAndMentions && this.state.isLockHashtagsBtnToggled && this.props.stream.sid) {
            if (confirm("Locked Hashtags and Mentions will be applied retroactively to all previous content in this stream")){
                await this.updateLockHashtagsAndMentions(client.cid, _stream, newMentions);
            }
        }

        if (articlesToExpire.length) {
            await Promise.all(
                articlesToExpire.map((artid) => {
                    ArticleActions.doExpire(artid, client.cid);
                })
            )
        }

        createAlert(`Successfully ${this.isInsert() ? "created" : "updated"} the stream`, "success");
        setTimeout(() => {
            history.push(`${admin.isSuper ? `/clients/s/${client.cid}` : "/content/c"}`);
        }, 0);
    }

    async canSubmit(stream: ServerTypes.Console.IStream) {
        return true;
    }

    async updateLockHashtagsAndMentions(cid: string, stream: ServerTypes.Console.IStream, newMentions: string[]) {
        const { items: streamArticles } = await doGetArticles(cid, stream.sid, null, 0, 1000, "", stream.type, "", null, null, null, true, false, undefined)
        await Promise.all(streamArticles.map(async clientArticle => {
            if (!clientArticle.LockHashtagsAndMentions) {
                const { _id, Hashtags, Mentions, linkedinMentions } = clientArticle;
                const _hashtags = [...Hashtags, ...(stream.Hashtags ? stream.Hashtags : [])];
                const _mentions = [...Mentions, ...(stream.Mentions ? stream.Mentions : [])];
                let _linkedinMentions : Mongo.ILinkedinMention[] = [...(linkedinMentions || [])];
                if (newMentions?.length) {
                    for (const mention of newMentions) {
                        await doGetLinkedinCompanyPageMentions(mention).then((data) => {
                            for (const item of data) {
                                const linkedinMention = {
                                    mention: item.name,
                                    id: item.member.split(":").slice(-1)[0],
                                    ...(item.notCompany ? { notCompany : true } : {}),
                                    ...(item.vanityName ? { vanityName : item.vanityName } : {})
                                }
                                _linkedinMentions.push(linkedinMention);
                            }
                        });
                    }
                }
                _linkedinMentions = _linkedinMentions.filter((v,i,a) => a.findIndex(t => (t.id == v.id)) === i);
                await ArticleActions.doUpdateLockedHashtagsAndMentions(_id, _hashtags, _mentions, (stream.LockHashtagsAndMentions || false), _linkedinMentions);
            }
        }))
    }

    _addSyncedStream() {
        const { clients } = this.props;
        const { stream, syncCid, syncSid, syncType, syncUpdates } = this.state;
        const syncTargets = stream.syncTargets || [];
        const syncPullTargets = stream.syncPullTargets || [];

        if (syncCid && syncSid && syncType == "push" && !syncTargets.find(st => st.cid == syncCid && st.sid == syncSid)) {
            syncTargets.push({ cid: syncCid, sid: syncSid });
            stream.syncTargets = syncTargets;
        } else if (clients[syncCid] && syncCid && syncSid && syncType == "pull" && !syncPullTargets.find(st => st.cid == syncCid && st.sid == syncSid)) {
            syncPullTargets.push({ cid: syncCid, sid: syncSid, syncUpdates: syncUpdates, name: clients[syncCid]!.name, stream: clients[syncCid]!.streams[syncSid].title});
            stream.syncPullTargets = syncPullTargets;
        }
        this.setState({ stream, syncCid: "", syncSid: "", syncUpdates: false });
    }

    _removeSyncedStream(i: number) {
        const { stream, syncType } = this.state;
        if (syncType == "push") {
            stream.syncTargets!.splice(i, 1);
        } else {
            stream.syncPullTargets!.splice(i, 1);
        }
        this.setState({ stream });
        createAlert('Synced stream removed', 'success');
    }

    checkExpiredFeedTokens() {
        const { stream } = this.state;
        let feedNetworks = [
            {name: "linkedIn", objName: "companies"},
            {name: "facebook", objName: "pages"},
            {name: "twitter", objName: "screenNames"}
        ];

        feedNetworks.map(network => {
            let name = network.name;
            let obj = network.objName;
            if (stream[`${name}Feeds`][obj].length > 0) {
                let found = false;
                stream[`${name}Feeds`][`${obj}`].map(feed => {
                    stream[`${name}Feeds`].expiredFeedTokens?.map((expFeed, i) => {
                        if (feed.name == expFeed.name) {
                            found = true;
                            createAlert(`Please sign in to your ${name} account to refresh the expired social feed to token of ${expFeed.name}`);
                        }
                    })
                })
                if (!found && stream[`${name}Feeds`]?.expiredFeedTokens?.length) {
                    stream[`${name}Feeds`].expiredFeedTokens = [];
                }
            } else {
                // all feeds removed on network. Reset token arr.
                stream[`${name}Feeds`].expiredFeedTokens = [];
            }
        })

        this.setState({ stream });
    }

    hasPullSyncSource() {
        const { stream } = this.state;
        const syncTargets = stream.syncTargets || [];
        const streamHasSyncPullTargets = syncTargets.some(t => t.syncSource == "pull");
        return streamHasSyncPullTargets;
    }

    getSettings(): dynamic<IPermission> {
        const { stream, countries, syncType } = this.state;
        const { client, admin } = this.props;

        const clientAvailablePermissions = getClientSharePermissions(client);
        const sharePermissions = getAllSharePermissions().filter(permission => clientAvailablePermissions.includes(permission));
        const isAutoPostOrSequence = stream.allowAutopost || stream.sequentialContent;

        return {
            lang: {
                label: "Language",
                type: "select",
                value: stream.lang,
                values: this.getLangs(),
                onChange: (value) => {
                    UtilActions.doGetCountries(value as string || "en").then(countries => {
                        this.setState({ countries, stream: { ...stream, lang: value } });
                    });
                }
            },
            ...(client.allowAutopost ? {
                sequentialCadence: {
                    label: "Sequential Cadence",
                    type: "select",
                    hidden: !(stream.isApprovalRequired && stream.sequentialContent),
                    value: stream.sequentialCadence || 0,
                    values: this.getSequentialCadences(),
                    onChange: (value) => {
                        this.setState({ stream: { ...stream, sequentialCadence: Number(value) } })
                    },
                    description: "The frequency at which each article from the sequence will be shared."
                },
                sequenceStartDate: {
                    label: "Sequence Start Date",
                    type: "datetime",
                    hidden: !(stream.isApprovalRequired && stream.sequentialContent),
                    value: stream.sequenceStartDate || new Date(),
                    onChange: (value) => {
                        this.setState({ stream: { ...stream, sequenceStartDate: value } })
                    },
                    description: "The date from which sequenced articles will begin to be shared based on selected \"Sequential Cadence\"."
                }
            } : null),
            ...(client.allowSectionView ? {
                bgColor: {
                    label: "Stream Background Color",
                    type: "select",
                    value: stream.bgColor || "#FFFFFF",
                    values: this.getBgColors(),
                    onChange: (value) => {
                        this.setState({ stream: { ...stream, bgColor: value } });
                    },
                    description: "Set a stream background color to differentiate the content in this stream from other content streams that your users see in the App.\nPrimary and secondary color options refer to the main colors in your company’s logo."
                }
            } : null),
            ...(admin.isSuper && stream.type != "TRENDING" ? {
                syncType: {
                    label: "Stream Sync Type",
                    type: "select",
                    hidden: !stream.isApprovalRequired,
                    value: syncType || "push",
                    values: [
                        { value: "push", label: "Push" },
                        { value: "pull", label: "Pull" }
                    ],
                    onChange: (value) => {
                        if (value == "push") {
                            if (stream.syncPullTargets && stream.syncPullTargets.length > 0) {
                                if ( confirm("This will end any currently setup pull sync stream connections. Do you want to continue?")) {
                                    this.setState({ stream: { ...stream, syncPullTargets: [] }, syncType: value });
                                } else {
                                    this.setState({ syncType: "pull" })
                                }
                            } else {
                                this.setState({ syncType: value });
                            }
                        } else if (value == "pull") {
                            if (stream.syncTargets && stream.syncTargets.length > 0) {
                                if (confirm("This will end any currently setup push sync stream connections. Do you want to continue?")) {
                                    this.setState({ stream: { ...stream, syncTargets: [] }, syncType: value });
                                } else {
                                    this.setState({ syncType: "push" })
                                }
                            } else {
                                this.setState({ syncType: value });
                            }
                        }
                    },
                    description: "Synchronize content to/from other client stream"
                },
            } : null),
            isDefaultOn: {
                label: "Default Visibility",
                value: stream.isDefaultOn,
                description: "Stream is on by default"
            },
            isMandatory: {
                label: "Always Enabled",
                value: stream.isMandatory,
                description: "Stream cannot be turned off for user"
            },
            adminContentTags: {
                label: "Enterprise Visible Stream",
                value: !!stream.adminContentTags,
                description:
                    "Opens visibility of the stream allowing ALL users to see this stream. Selected content tag only applies to admins for curation",
                onChange: (value) => {
                    this.setState({ stream: { ...stream, adminContentTags: value, tags: [] } });
                }
            },
            filters: {
                label: "Content Filters",
                type: "select",
                value: (stream.type === "FEED" && !!stream.allowAutopost) ? "NONE" : stream.globalFilters ? "GLOBAL" : stream.filters == null ? "NONE" : "CUSTOM",
                values: [
                    { value: "GLOBAL", label: "Global" },
                    { value: "CUSTOM", label: "Custom" },
                    { value: "NONE", label: "None" }
                ],
                disabled: stream.type === "FEED" && !!stream.allowAutopost,
                onChange: (value) => {
                    const obj: dynamic = {};
                    switch (value) {
                        case "GLOBAL":
                            obj.globalFilters = true;
                            obj.filters = null;
                            break;
                        case "CUSTOM":
                            obj.globalFilters = false;
                            obj.filters = {};
                            break;
                        default:
                        case "NONE":
                            obj.globalFilters = false;
                            obj.filters = null;
                            break;
                    }
                    if (value == "CUSTOM") {
                        createAlert("Turned on custom filters, please scroll down to configure them", "success");
                    }
                    this.setState({ stream: { ...stream, ...obj } });
                }
            },
            locations: {
                label: "Default Locations",
                description: "Select default locations that you would like this stream to pull from",
                type: "multi-select",
                value: stream.locations || [],
                multi: true,
                values: (countries || []).map(location => ({ value: location.code, label: location.name })),
                onChange: (value) => {
                    this.setState({ stream: { ...stream, locations: value || [] } });
                }
            },
            tags: {
                label: "Content Tags",
                description: "Select a content tag to associate this stream with users that share the content tag",
                type: Object.keys(client.tags || {}).length ? "multi-select" : "select",
                value: stream.tags || [],
                ...(Object.keys(client.tags || {}).length ? { multi: true } : null),
                values: [
                    { value: "", label: "None" },
                    ...Object.keys(client.tags || {}).map((tag, i) => ({
                        value: tag,
                        label: client.tags![tag].name
                    }))
                ],
                placeholder: "Select...",
                onChange: (value) => {
                    if (typeof value === "string") {
                        return;
                    }
                    this.setState({ stream: { ...stream, tags: [...value as string[]] } });
                }
            },
            ...(client.allowContentSpecificPermissions
                ? {
                    readOnly: {
                        label: "Set All Stream Content to Read-only",
                        value: !!stream.readOnly,
                        description: "Stream is set to read-only, and content can not be shared from this stream.",
                        hidden: stream.type === "TRENDING" || stream.type === "HELP"
                    }
                  }
                : null),
            ...(stream.adminContentTags
                ? {
                    tags: {
                        label: "Admin-only Content Tags",
                        description:
                            "Select a content tag to associate this stream with admins that share the content tag",
                        type: "select",
                        value: (stream.tags || [])[0],
                        values: [
                            { value: "", label: "None" },
                            ...Object.keys(client.tags || {}).map((tag, i) => ({
                                value: tag,
                                label: client.tags![tag].name
                            }))
                        ],
                        onChange: (value) => {
                            this.setState({ stream: { ...stream, tags: value ? [value] : [] } });
                        },
                        placeholder: "Select..."
                    }
                }
                : null),
            isEditable: {
                label: "Comments Editable",
                hidden: stream.type === "TRENDING",
                value: !!stream.isEditable,
                description: "Lets the user edit comments before sharing articles"
            },
            isWebShare: {
                label: "Allow Extra Network Shares",
                hidden: !client.strictExtraNetworks,
                value: !!stream.isWebShare,
                description: "Allows users to share articles from this stream to their extra networks",
                onChange: (value) => {
                    this.setState({
                        stream: {
                            ...stream,
                            isWebShare: value
                        }
                    });
                }
            },
            canATSCopyLink: {
                label: "Enable Article Specific Link Sharing",
                value: !!stream.canATSCopyLink,
                hidden: stream.type === "TRENDING" || !stream.isApprovalRequired,
                description:
                    "Allow admins to copy a link from the Edit Article Page to share to users that will open a curated article in a customize modal"
            },
            active: {
                label: "Active",
                value: !!stream.active,
                hidden: stream.type !== "CORPORATE",
                description: "Enables the stream to be shown in the app",
                onChange: () =>
                    this.setState({ stream: { ...stream, active: !stream.active } })
            },
            ...(client.allowContentSpecificPermissions && stream.type != "TRENDING"
                ? {
                    defaultSharingPermissions: {
                        type: "multi-select",
                        multi: true,
                        disabled: stream.readOnly,
                        label: "Set Default Sharing Permissions",
                        value: (!stream.readOnly ? sharePermissions
                            .map(perm => perm.toLowerCase())
                            .filter(perm => stream.defaultSharingPermissions![perm]) : []),
                        values: (!stream.readOnly ? sharePermissions.map(perm => ({
                            label: perm,
                            value: perm.toLowerCase()
                        })) : []),
                        description: "Select channels that you would like content within stream to be shared",
                        onChange: (value) => {
                            value = value as string[]
                            const newSharingPermissions = {
                                ...stream.defaultSharingPermissions,
                                social: value.includes('social'),
                                email: value.includes('email'),
                                sms: value.includes('sms'),
                            }
                            // If social went from false to true, then all social networks are selected
                            if (!stream.defaultSharingPermissions?.social && newSharingPermissions.social) {
                                newSharingPermissions.socialNetworkPermissions = {
                                    facebook: true,
                                    twitter: true,
                                    linkedin: true,
                                    instagram: true
                                }
                            }
                            this.setState({
                                stream: {
                                    ...stream,
                                    defaultSharingPermissions: newSharingPermissions
                                }
                            });
                        }
                    },
                    socialNetworkPermissions: {
                        label: 'Social Network Permissions',
                        type: 'multi-select' as const,
                        multi: true,
                        description: 'Options selected will be the only social networks that can be shared to. If left empty, all social networks will be available.\n\nNot customizable when Stream Subscription or Sequential Content is enabled.',
                        disabled: stream.readOnly || isAutoPostOrSequence,
                        hidden: !stream.defaultSharingPermissions?.social || !client.allowNetworkRestrictions,
                        value: !stream.readOnly && !isAutoPostOrSequence ?
                            getClientNetworks(client, true)
                                .map(perm => perm.toLowerCase())
                                .filter(perm =>
                                    stream.defaultSharingPermissions?.socialNetworkPermissions ?
                                    stream.defaultSharingPermissions.socialNetworkPermissions[perm.toLowerCase()] :
                                    true
                                ) : [],
                        values: !stream.readOnly && !isAutoPostOrSequence && stream.defaultSharingPermissions?.social ?
                            getClientNetworks(client, true).map(perm => ({
                                label: perm, value: perm.toLowerCase()
                            })) : [],
                        onChange: async(value) => {
                            value = value as string[];
                            const newDefaultSharingPermissions = {
                                ...stream.defaultSharingPermissions,
                                socialNetworkPermissions: {
                                    facebook: value.includes('facebook'),
                                    twitter: value.includes('twitter'),
                                    linkedin: value.includes('linkedin'),
                                    instagram: value.includes('instagram')
                                }
                            }
                            this.setState({
                                stream: {
                                    ...stream,
                                    defaultSharingPermissions: newDefaultSharingPermissions
                                }
                            })
                        }
                    }
                }
                : null),
        };
    }

    getComplianceSettings(): dynamic<IPermission> {
        const { client } = this.props;
        const { stream } = this.state;

        return {
            isCompliant: {
                label: "From Compliant Source",
                value: !stream.isApprovalRequired ? true : stream.isCompliant,
                hidden: !client.complianceEnabled,
                description: "Content added to this stream will come from a source that is considered compliant",
                disabled: client.compliance?.vendor == "ng",
                onChange: value => {
                    this.setState({
                        stream: {
                            ...stream,
                            isCompliant: value
                        }
                    });
                }
            },
            skipCompliance: {
                label: "Skip Compliance for Unedited Shares",
                value: stream.skipCompliance,
                hidden: !client.complianceEnabled,
                description: "Content shared from this stream will not be submitted to compliance for pre-review unless edited by the user",
                disabled: stream.skipAllCompliance || (!stream.isApprovalRequired && stream.searchType !== "CORP"),
                onChange: value => {
                    this.setState({
                        stream: {
                            ...stream,
                            skipCompliance: value
                        }
                    });
                }
            },
            skipAllCompliance: {
                label: "Skip Compliance for All Shares",
                value: !!stream.skipAllCompliance,
                hidden: !client.complianceEnabled || client.compliance?.vendor !== "ng",
                description: "Content shared from this stream will not be submitted to compliance for pre-review",
                disabled: stream.skipCompliance || (!stream.isApprovalRequired && stream.searchType !== "CORP"),
                onChange: value => {
                    this.setState({
                        stream: {
                            ...stream,
                            skipAllCompliance: value
                        }
                    });
                }
            },
            isConsoleCompliant: {
                label: "Add to Syndicate Library",
                value: stream.isConsoleCompliant!,
                hidden: !client.complianceEnabled,
                description: "Send library content curated by admins to the compliance provider, where it may be routed through workflow if desired.\nContent routed through the workflow will not be available to users if it is rejected or still pending approval.",
                disabled: !stream.isApprovalRequired
            },
            complianceConfigId: {
                label: "Compliance Policy ID",
                hidden: !stream.isApprovalRequired || !stream.isConsoleCompliant || client.compliance?.vendor !== "ng",
                value: stream.complianceConfigId || "",
                type: "select",
                values: [
                    { value: "", label: "None" },
                    ...(stream.complianceConfigId && (client.compliance?.syndicateIds || []).findIndex(config => config.external_id === stream.complianceConfigId) === -1 ? [{ value: stream.complianceConfigId, label: stream.complianceConfigId }] : []),
                    ...(client.compliance?.syndicateIds || []).map(config => ({
                        value: config.external_id,
                        label: config.config_name
                    })),
                ],
                description: "ID of the compliance policy that will be applied to content in this stream"
            },
            sendArticleExtract: {
                label: "Send Article Content To Compliance",
                hidden: !stream.isApprovalRequired || !stream.isConsoleCompliant || client.compliance?.vendor !== "ng" || stream.type !== "FEED",
                value: stream.sendArticleExtract || false,
                description: "Send article extracted content to compliance"
            }
        };
    }

    renderBelowPanel() {
        const { admin, client, clients } = this.props;
        const { stream, syncCid, syncSid, syncType } = this.state;

        return [
            ...((!stream.isApprovalRequired || (stream.type == "FEED" && !stream.allowAutopost)) && !stream.globalFilters && stream.filters != null
                ? [<DomainFilter
                    lang={stream.lang}
                    stream={stream}
                    client={client}
                    user={admin}
                    onSave={(included, filters, tags, bTerms) => {
                        this.setState({
                            stream: {
                                ...stream,
                                filters: {
                                    ...(tags && tags.length ? { tags } : null),
                                    domains: filters,
                                    included,
                                    bTerms,
                                    lastUpdatedOn: new Date()
                                }
                            }
                        });
                    }}
                />]
                : []
            ),
            ...(admin.isSuper && stream.isApprovalRequired && stream.type != "TRENDING"
                ? [
                    <div className="ibox">
                        <div
                            className="ibox-title"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <h5>
                                {syncType == "pull" ? "Content Sync Pull" : "Content Sync Push"}
                                      <Tooltip
                                    className="tooltip__maxwidth"
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    Synchronize content to other client
                                      </Tooltip>
                            </h5>
                        </div>

                        <div className="ibox-content">
                            <div className="form__row sync_stream_add">
                                <div className="form__group--full">
                                    <div className="form__row ">
                                        <div className="form__group">
                                            <div className="form_wrapper">
                                                <label className="form__label">Client</label>
                                            </div>
                                        </div>
                                        <div className="form__group">
                                            <div className="form_wrapper">
                                                <label className="form__label">Stream</label>
                                            </div>
                                        </div>
                                        {syncType == "pull" && (
                                        <div className="form__group" style={{flexBasis: "20%"}}>
                                            <div className="form_wrapper">
                                                <label className="form__label">Sync Updates
                                                    <Tooltip>
                                                        Allows updates from synced stream
                                                    </Tooltip>
                                                </label>
                                            </div>
                                        </div>)}
                                        <div className="form__group">
                                            <label className="form__label"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form__row sync_stream_add">
                                <div className="form__group">
                                    <div className="select__wrapper">
                                        <select value={syncCid} onChange={(e) => this.setState({ syncCid: e.target.value })}>
                                            <option value="">Please select a client</option>
                                            {admin.isSuper && clients && Object.values(clients).map(
                                                (c) => {
                                                    if (!c || client.cid == c.cid || !client.isActive) return;
                                                    return (
                                                        <option key={c.cid} value={c.cid}>{c.name}</option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form__group">
                                    <div className="select__wrapper">
                                        <select value={syncSid} disabled={!syncCid || !clients[syncCid]} onChange={(e) => this.setState({ syncSid: e.target.value })}>
                                            <option value="">Please select a stream</option>
                                            {syncType == "pull" ?
                                            (admin.isSuper && clients && clients[syncCid] && Object.values(clients[syncCid]!.streams).filter(str => !str.syncPullTargets?.length).map(
                                                (s) => {
                                                    if (!s || !s.active || !s.isApprovalRequired || !s.isCustom || s.type == "TRENDING" || (stream.syncTargets || stream.syncPullTargets ||  []).find(st => st.cid == syncCid && st.sid == s.sid)) return;
                                                    return (
                                                        <option key={s.sid} value={s.sid}>{s.title}</option>
                                                    );
                                                }
                                            )) :
                                            (admin.isSuper && clients && clients[syncCid] && Object.values(clients[syncCid]!.streams).map(
                                                (s) => {
                                                    if (!s || !s.active || !s.isApprovalRequired || !s.isCustom || s.type == "TRENDING" || (stream.syncTargets || stream.syncPullTargets ||  []).find(st => st.cid == syncCid && st.sid == s.sid)) return;
                                                    return (
                                                        <option key={s.sid} value={s.sid}>{s.title}</option>
                                                    );
                                                }
                                            ))
                                        }
                                        </select>

                                    </div>
                                </div>
                                {syncType == "pull" && (

                                    <div className="form__group" style={{flexBasis: "20%"}}>
                                        {admin.isSuper && clients && clients[syncCid] && clients[syncCid]!.streams?.syncPullTargets && Object.values(clients[syncCid]!.streams.syncPullTargets).length > 0 ?
                                            (Object.values(clients[syncCid]!.streams.syncPullTargets).map(
                                                (spt) => {
                                                    return (
                                                                <div>
                                                                    <Toggle
                                                                        name="syncUpdates"
                                                                        value={spt.syncUpdates || this.state.syncUpdates}
                                                                        onToggle={event => { this.setState({ syncUpdates: !spt.syncUpdates }) } }
                                                                    />
                                                                </div>
                                                            );
                                                }
                                            )) : (
                                                                <div>
                                                                    <Toggle
                                                                        name="syncUpdates"
                                                                        value={this.state.syncUpdates}
                                                                        onToggle={event => { this.setState({ syncUpdates: !this.state.syncUpdates }) } }
                                                                    />
                                                                </div>
                                            )

                                        }
                                    </div>
                                )}

                                <div className="form__group">
                                    <Button
                                        disabled={!syncCid || !syncSid}
                                        onClick={() => this._addSyncedStream()}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>
                            {(syncType == "pull" && stream.syncPullTargets || syncType == "push" && stream.syncTargets || []).map((row, i) => (
                                <div className="form__row" key={`${row}-${i}`}>
                                    <div className="form__group">
                                        <input type="text" disabled={true} value={clients[row.cid] && clients[row.cid]!.name} />
                                    </div>
                                    <div className="form__group">
                                        <input type="text" disabled={true} value={clients[row.cid] && clients[row.cid]!.streams[row.sid] && clients[row.cid]!.streams[row.sid].title} />
                                    </div>
                                    {syncType == "pull" && (
                                    <div className="form__group" style={{flexBasis: "20%"}}>
                                        <div>
                                            <Toggle name="syncUpdates" value={row.syncUpdates || false} onToggle={()=>{}}/>
                                        </div>
                                    </div>)}
                                    <div className="form__group">
                                        <Button onClick={() => this._removeSyncedStream(i)}>Remove</Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ]
                : (!admin.isSuper && stream.isApprovalRequired && stream.type != "TRENDING" && (stream.syncTargets || stream.syncPullTargets)
                ? (stream.syncTargets && stream.syncTargets.length > 0 || stream.syncPullTargets && stream.syncPullTargets?.length > 0  ? [
                    <div className="ibox">
                        <div
                            className="ibox-title"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <h5>
                                {syncType == "pull" ? "Content Sync Pull" : "Content Sync Push"}
                                      <Tooltip
                                    className="tooltip__maxwidth"
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    Synchronize content to other client
                                      </Tooltip>
                            </h5>
                        </div>

                        <div className="ibox-content">
                            <div className="form__row">
                                <div className="form__group--full">
                                    <div className="form__row">
                                        <div className="form__group">
                                            <label className="form__label">Client</label>
                                        </div>
                                        <div className="form__group">
                                            <label className="form__label">Stream</label>
                                        </div>
                                        {(stream.syncPullTargets && stream.syncPullTargets.length > 0 ||
                                            this.hasPullSyncSource()) && (
                                            <div style={{"marginLeft": "10px", "flexBasis": "2%"}}>
                                                <div className="form__group">
                                                    <label className="form__label">Sync Updates</label>
                                                </div>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                        {/* <div className="form__row sync_stream_add"> */}
                        <div className="form__row">
                            <div className="form__group--full">
                                {(syncType == "pull" && stream.syncPullTargets || stream.syncTargets || []).map((row, i) => {
                                    if (row["name"] != null && stream.syncPullTargets?.length) {
                                        return (
                                                <div className="form__row" key={`${row}-${i}`}>
                                                    <div className="form__group" >
                                                        <input
                                                            style={{width: "95%"}}
                                                            type="text" disabled={true}
                                                            value={row["name"] ||
                                                                clients && clients[row.cid] &&
                                                                    clients[row.cid]!.name}
                                                        />
                                                    </div>
                                                    <div className="form__group" >
                                                        <input
                                                            style={{width: "95%"}}
                                                            type="text"
                                                            disabled={true}
                                                            value={row["stream"] ||
                                                                clients && clients[row.cid] &&
                                                                    clients[row.cid]!.streams[row.sid] &&
                                                                        clients[row.cid]!.streams[row.sid].title}
                                                            />
                                                    </div>
                                                    <div>
                                                        <div className="form__group">
                                                            <Toggle
                                                                style={{"marginLeft": "10px"}}
                                                                name="syncUpdates"
                                                                value={row["syncUpdates"] || false}
                                                                disabled={true}
                                                                onToggle={()=>{}}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    } else if (row["name"] != null && stream.syncTargets?.length) {
                                        return (
                                                <div className="form__row" key={`${row}-${i}`}>
                                                    <div className="form__group" >
                                                        <input
                                                            style={{width: "95%"}}
                                                            type="text"
                                                            disabled={true}
                                                            value={row["name"]}
                                                        />
                                                    </div>
                                                    <div className="form__group">
                                                        <input
                                                            style={{width: "95%"}}
                                                            type="text"
                                                            disabled={true}
                                                            value={row["stream"]}
                                                        />
                                                    </div>
                                                    {this.hasPullSyncSource() &&
                                                    (<div>
                                                        <div className="form__group">
                                                            <Toggle
                                                                style={{"marginLeft": "10px"}}
                                                                name="syncUpdates"
                                                                value={row["syncUpdates"] || false}
                                                                disabled={true}
                                                                onToggle={()=>{}}
                                                            />
                                                        </div>
                                                    </div>)}
                                                </div>
                                        )
                                    } else {
                                        return [];
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                ] : [])
                : [])),
        ];
    }

    getLangs() {
        const excludeCode = (this.state.stream.type === "FEED" || this.state.stream.type === "CURATED") ? "" : "al";
        let langs: Array<{ value: string; label: string }> = getLanguages(excludeCode).map(lang => {
            return {
                value: lang.code,
                label: lang.name
            };
        });

        return langs;
    }

    getSequentialCadences() {
        return [
            {
                value: "1",
                label: "Daily"
            },
            {
                value: "7",
                label: "Weekly"
            },
            {
                value: "14",
                label: "Biweekly"
            },
            {
                value: "30",
                label: "Monthly"
            }
        ]
    }

    getBgColors() {
        return [
            {
                value: this.state.primaryColor,
                label: "Primary"
            },
            {
                value: this.state.secondaryColor,
                label: "Secondary"
            },
            {
                value: "#FFFFFF",
                label: "White"
            },
            {
                value: "#EEEEEE",
                label: "Grey"
            }
        ];
    }

    renderAboveSettingsRows(): JSX.Element[] {
        return [];
    }
    renderBelowSettingsRows(): JSX.Element[] {
        return [];
    }

    isInsert() {
        const { stream } = this.state;
        const { client } = this.props;
        return !(client.cid && stream.sid);
    }

    getSortedSettings(settings: dynamic, separateColumns = true) {
        const sortable: string[][] = [];
        const secondarySortable: string[][] = [];
        for (const k in settings) {
            if (!separateColumns || !("values" in settings[k]) && k !== "readOnly") {
                sortable.push([k, settings[k].label]);
            } else {
                secondarySortable.push([k, settings[k].label]);
            };
        }

        sortable.sort((a, b) => a[1].localeCompare(b[1]));
        secondarySortable.sort((a, b) => a[1].localeCompare(b[1]));
        const sorted = Object.assign({}, ...sortable.map(v => ({ [v[0]]: settings[v[0]] })));
        const secondarySorted = Object.assign({}, ...secondarySortable.map(v => ({ [v[0]]: settings[v[0]] })));
        return [sorted, secondarySorted];
    }

    async previewStream(startDate?: Date) {
        const { client } = this.props;
        const { stream } = this.state;
        const data = await doPreviewStream(client.cid, stream, startDate);
        return data && data.valid ? data.articles : [];
    }

    renderComplianceSettings() {
        const { stream } = this.state;
        return (
            <>
                <label className="form__label" style={{ paddingTop: "40px" }}>
                    Compliance Settings:
                    <span
                        style={{
                            float: "right",
                            marginRight: "15px"
                        }}
                    >
                        Off/On
                    </span>
                </label>
                <PermissionSet
                    permissions={(this.getComplianceSettings())}
                    _onChange={(key, value) => this.setState({ stream: { ...stream, [key]: value } })}
                    setNumber={"15"}
                />
            </>
        );
    }

    _renderHashtagsAndMentions(hidden = false) {
        const { stream, client } = this.state;
        const lang = stream.lang || "en";
        const clientHashtagsAndMentions = {
            hashtags: client.hashtags && client.hashtags[lang] ? client.hashtags[lang].map(item => item.hashtag) : [],
            mentions: client.mentions && client.mentions[lang] ? client.mentions[lang].map(item => item.mention) : []
        };
        return {
            HashtagsAndMentions: {
                label: `Hashtags & Mentions (${(stream.HashtagsAndMentions || []).length}/10)`,
                value: stream.HashtagsAndMentions || [],
                hidden: !stream.isApprovalRequired, // The following components should be hidden when the "Automatic Curation" toggle is enabled.
                type: "creatable" as const,
                multi: true,
                creatable: true,
                placeholder: "Please start typing with a # or @",
                description: "The hashtags/mentions will be applied to all new content created within this stream",
                components: { ValueContainer: _ValueContainer },
                customClasses: "form__value list-of-tags select-auto-suggest",
                promptTextCreator: userInput => `Create hashtag/mention ${userInput}`,
                noResultsText: "This item has already been created",
                formatValue: value => ({
                    value,
                    label: value,
                    className: `select-${value.indexOf("#") == 0 ? "hashtag" : "mention"}`
                }),
                onChange: async (value) => {
                    this.setState({
                        stream: {
                            ...stream,
                            HashtagsAndMentions: value,
                            Hashtags: (value as string[]).filter(i => i.indexOf('#') == 0).map(x => x.replace(/^#/, "")),
                            Mentions: (value as string[]).filter(i => i.indexOf('@') == 0).map(x => x.replace(/^@/, ""))
                        }
                    });
                },
                options: ([] as Array<{ label: string; value: string; className?: string; }>).concat(
                    ...clientHashtagsAndMentions.hashtags.map(x => ({
                        value: `#${x}`,
                        label: `#${x}`,
                        className: "select-hashtag"
                    })),
                    ...clientHashtagsAndMentions.mentions.map(x => ({
                        value: `@${x}`,
                        label: `@${x}`,
                        className: "select-mentions"
                    }))
                ),
                onInputKeyDown: e => {
                    if (e.which == 13 || e.which == 9) {
                        if (
                            stream.HashtagsAndMentions &&
                            stream.HashtagsAndMentions.filter(
                                hashtag =>
                                    hashtag.toLowerCase() ===
                                    (e.target as EventTarget & HTMLInputElement).value.toLowerCase()
                            )
                        ) {
                            createAlert(
                                `This ${(e.target as EventTarget & HTMLInputElement).value.indexOf("#") === -1
                                    ? "mention"
                                    : "hashtag"
                                } has already been added`,
                                "error"
                            );
                        }
                    }
                },
                canUpdate: (userTerm, _values) => {
                    const sanitizedTerm = sanitizeHtml(userTerm);
                    if (!sanitizedTerm) {
                        return false;
                    }
                    if (_values.length >= 10) {
                        createAlert(
                            "You have reached the maximum amount of hashtags & mentions. Please delete some hashtags or mentions in order to add more",
                            "error"
                        );
                        return false;
                    }
                    if (sanitizedTerm.indexOf("#") === -1 && sanitizedTerm.indexOf("@") === -1) {
                        createAlert(`You must start your hashtag/mention with a '#' or '@'`, "error");
                        return false;
                    }
                    // +1 for # or @
                    if (sanitizedTerm.length > 33) {
                        createAlert(
                            `The ${sanitizedTerm.indexOf("#") == 0 ? "hashtag" : "mention"
                            } ${sanitizedTerm} is too long. Please limit them to 32 characters`,
                            "error"
                        );
                        return false;
                    }
                    if (_hasMentionOrHashtag(_formatHashtagOrMention(sanitizedTerm), stream.HashtagsAndMentions)) {
                        createAlert(
                            `The ${
                                sanitizedTerm.indexOf("#") ? "hashtag" : "mention"
                            } ${sanitizedTerm} has already been added`,
                            "error"
                        );
                        return false;
                    }
                    return true;
                }
            },
            LockHashtagsAndMentions: {
                label: "Lock Hashtags & Mentions",
                description: "Make Hashtags & Mentions not editable",
                value: stream?.LockHashtagsAndMentions || false,
                hidden: !stream.isApprovalRequired, // The following components should be hidden when the "Automatic Curation" toggle is enabled.
                onChange: () => {
                    this.setState({
                        stream: { ...stream, LockHashtagsAndMentions: !stream.LockHashtagsAndMentions },
                        isLockHashtagsBtnToggled: !this.state.isLockHashtagsBtnToggled
                    })
                }
            },
        }
    }

    canPreview() {
        return true;
    }

    render() {
        const { countries, stream, preview, canPreview, canPreviewOnCreate } = this.state;
        const { admin, client } = this.props;

        if ((countries || []).length == 0) {
            return <Spinner />;
        }
        const canPreviewContent =
            this.canPreview() && canPreview && ((this.isInsert() && canPreviewOnCreate) || !this.isInsert());
        return (
            <Wrapper>
                <StreamPreview
                    open={preview}
                    onClose={() => this.setState({ preview: false } as V & IBaseStreamState)}
                    getArticles={date => this.previewStream(date)}
                    client={client}
                    stream={stream}
                />
                <Row>
                    <div className="col-sm-12">
                        <div className="ibox">
                            <div
                                className="ibox-title"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <h5>
                                    <Breadcrumbs
                                        items={[
                                            {
                                                ...(admin.isSuper ? { link: `/clients/` } : null),
                                                name: "Clients"
                                            },
                                            {
                                                ...(admin.isSuper || admin.isSuperClientAdmin
                                                    ? { link: `/clients/e/${client.cid}` }
                                                    : null),
                                                name: client.name
                                            },
                                            {
                                                link: admin.isSuper ? `/clients/s/${client.cid}` : `/content/c`,
                                                name: "Streams"
                                            },
                                            { name: `${this.isInsert() ? "Create Stream" : "Edit " + stream.title}` }
                                        ]}
                                    />
                                </h5>
                                <div className="ibox-tools">
                                    {canPreviewContent && (
                                        <Button
                                            className="btn--sm"
                                            onClick={() => this.setState({ preview: true } as V & IBaseStreamState)}
                                        >
                                            <i className="fa fa-eye" /> Preview
                                        </Button>
                                    )}
                                </div>
                            </div>

                            <div className="ibox-content">
                                <form className="form">
                                    <div className="form__row">
                                        <div className="form__group form__group--full">
                                            <label htmlFor="title" className="form__label">Title:</label>
                                            <input
                                                id="title"
                                                type="text"
                                                className="form__value"
                                                value={stream.title}
                                                maxLength={100}
                                                onChange={event =>
                                                    this.setState({ stream: { ...stream, title: event.target.value } })
                                                }
                                            />
                                        </div>
                                    </div>
                                    {this.renderAboveSettingsRows().map((item, index) => (
                                        <div className="form__row" key={`form--row--${index}`}>
                                            {item}
                                        </div>
                                    ))}
                                    <div className="form__row">
                                        <div className="form__group">
                                            <label className="form__label">
                                                Settings:
                                                <span
                                                    style={{
                                                        float: "right",
                                                        marginRight: "15px"
                                                    }}
                                                >
                                                    Off/On
                                                </span>
                                            </label>
                                            <PermissionSet
                                                permissions={this.getSortedSettings(this.getSettings())[0]}
                                                _onChange={(key, value) =>
                                                    this.setState({ stream: { ...stream, [key]: value } })
                                                }
                                                setNumber={"16"}
                                            />
                                            {client.complianceEnabled && this.renderComplianceSettings()}
                                        </div>
                                        <div className="form__group">
                                            <label className="form__label">Advanced:</label>
                                            <PermissionSet
                                                permissions={this.getSortedSettings(this.getSettings())[1]}
                                                _onChange={(key, value) =>
                                                    this.setState({ stream: { ...stream, [key]: value } })
                                                }
                                                setNumber={"17"}
                                            />
                                        </div>
                                    </div>
                                    {this.renderBelowSettingsRows().map((item, index) => (
                                        <div className="form__row" key={`form--row--${index}`}>
                                            {item}
                                        </div>
                                    ))}
                                </form>
                            </div>
                        </div>
                    </div>
                </Row>
                {this.renderBelowPanel().map((item, index) => (
                    <Row key={`stream-below-${index}`}>
                        <div className="col-sm-12">{item}</div>
                    </Row>
                ))}
            </Wrapper>
        );
    }
}
