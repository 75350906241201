import React, { Component } from "react";
import Spinner from "../atoms/Spinner";
import { SortableElement, SortableContainer } from "react-sortable-hoc";

interface ISortableTableProps {
    keys: Array<string | Array<string|React.ReactElement>>;
    name: string;
    labels?: string[];
    items: Array<Array<JSX.Element | string>>;
    loading: boolean;
    onSortEnd: (oldIndex: number, newIndex: number) => void;
}

interface ISortableTableState {}

const SortableRow = SortableElement(({ keys, value }: dynamic) => (
    <tr>
        {value.map((item, ii) => (
            <td className={keys[ii]} key={`row--${ii}`}>
                <span style={{ display: "flex", marginLeft: keys[ii] === "Move to Top/Bottom" ? "50px" : "" }}>{item}</span>
            </td>
        ))}
    </tr>
));
const SortableBody = SortableContainer(({ keys, items }: dynamic) => (
    <tbody>
        {items.map((v, i) => {
            if (keys[0] === "" && v[0] === "") {
                return <SortableRow key={`sortable-row-${i}`} index={i} keys={keys} value={v} disabled={true} />
            }
            return <SortableRow key={`sortable-row-${i}`} index={i} keys={keys} value={v} />
        })}
    </tbody>
));
export default class SortableTable extends Component<ISortableTableProps, ISortableTableState> {
    constructor(props: ISortableTableProps) {
        super(props);
        this._reflow = this._reflow.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderBody = this.renderBody.bind(this);

        this.state = {};
    }
    _reflow() {
        if (window) {
            setTimeout(() => {
                window.dispatchEvent(new Event("table_reflow"));
            }, 0);
        }
    }
    componentDidMount() {
        this._reflow();
    }

    render() {
        return (
            <div className="table-responsive">
                <table className="table table-striped responsive" data-type="responsive">
                    {this.renderHeader()}
                    {this.renderBody()}
                </table>
            </div>
        );
    }

    renderHeader() {
        const { keys } = this.props;
        if (!keys) {
            return;
        }
        return (
            <thead>
                <tr>
                    {keys.map(key => {
                        if (typeof key == "string") return <th key={`${key}`} className={key}>{key}</th>;
                        return <th key={`${key[0]}`} className={key[0] as string}>{`${key[0]} ${key[1]}`}</th>;
                    })}
                </tr>
            </thead>
        );
    }

    renderBody() {
        const { items, loading } = this.props;
        if (loading) {
            return <tbody>{this.renderLoading()}</tbody>;
        }
        if (!items || (items && items.length == 0)) {
            return (
                <tbody>
                    <tr>
                        <td colSpan={42}>No records found</td>
                    </tr>
                </tbody>
            );
        }
        return this.renderRows();
    }

    renderRows() {
        const { items, keys } = this.props;
        return (
            <SortableBody
                axis="y"
                useDragHandle={true}
                items={items}
                keys={keys}
                onSortEnd={({ oldIndex, newIndex }) => this.props.onSortEnd(oldIndex, newIndex)}
            />
        );
    }

    renderLoading() {
        return (
            <tr>
                <td colSpan={42}>
                    <Spinner />
                </td>
            </tr>
        );
    }
}
