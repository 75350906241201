import { apiRequest, apiRequestWithErrorAlert } from "../utils/Helpers";
import appConfig from "../../config/config.dev";
import { IEmailNewArticle } from "../components/molecules/modals/NewContentEmailModal";

export async function getActivationEmail(uid?: string) {
    try {
        return await apiRequestWithErrorAlert<{ valid: boolean; body: string; subject: string }>(
            `${appConfig.API_URL}/getActivationEmail`,
            "GET",
            { uid },
            "Error loading Activation Email."
        );
    } catch {
        return null;
    }
}

function doGetEmailTemplate(template: string, language: string, cid?: string, gid?: string) {
    return apiRequestWithErrorAlert<{ body: string; subject: string; count?: number; unsubsribedCount?: number;}>(
        `${appConfig.API_URL}/getEmailTemplate`,
        "GET",
        { template, language, cid, gid },
        "Error loading email template."
    );
}

export async function doGetActivationEmail(language: string, cid?: string) {
    return doGetEmailTemplate("activationEmail", language, cid);
}

export async function sendActivationEmail(body: string, subject: string, uid: string) {
    return apiRequest(`${appConfig.API_URL}/sendActivationEmail`, `POST`, {
        body,
        subject,
        uid
    });
}

export async function sendActivationEmailAll(subject: string, body: string, lang: string) {
    return apiRequestWithErrorAlert<{ sent: number }>(
        `${appConfig.API_URL}/sendActivationEmailAllUsers`,
        `POST`,
        {
            subject,
            body,
            lang
        },
        "Error occurred while sending activation emails"
    );
}

export async function sendNewContentEmailUsers(
    cid: string,
    rids: string[] | null,
    gids: string[] | null,
    tags: string[],
    articleData: IEmailNewArticle,
    subject: string, body: string,
    emailScheduledDate?: Date
) {
    return apiRequestWithErrorAlert<{ sent: number }>(
        `${appConfig.API_URL}/sendNewContentEmailUsers`,
        `POST`,
        {
            cid,
            rids,
            gids,
            tags,
            articleData,
            subject,
            body,
            emailScheduledDate
        }
    )
}

export async function doGetNotSharedInThirtyDaysEmail(language: string, cid?: string, gid?: string) {
    return doGetEmailTemplate("ReengagementEmail", language, cid, gid);
}

export async function sendNotSharedInThirtyDaysEmail(subject: string, body: string, lang: string, gid?: string) {
    return apiRequestWithErrorAlert<{ sent: number }>(
        `${appConfig.API_URL}/sendNotSharedInThirtyDaysEmail`,
        `POST`,
        {
            subject,
            body,
            lang,
            gid
        },
        "Error occurred while sending the not shared in 30 days emails"
    );
}