import React, { Component } from "react";
import { connect } from "react-redux";
import { createAlert } from "../../../../actions";
import PageHeading from "../../../molecules/PageHeading";
import Spinner from "../../../atoms/Spinner";
import Link from "../../../atoms/Link";
import debounce from "lodash/debounce";
import Pagination from "../../../molecules/Pagination";
import Button from "../../../atoms/Button";

import {
    doGetReduxClients,
    doGetReduxClient,
    doGetECommunicationCategories
} from "../../../../actions";
import AddECommunicationCategory from "../../../molecules/modals/AddECommunicationCategory";
import {
    CATEGORY_STATUS,
    containValue,
    getNamebyValue
} from "../../../../utils/ECommunicationHelpers";
import { Wrapper, Row } from "../../../atoms/Layout/";

class ECommunicationCategory extends Component {
    constructor(props) {
        super(props);
        this._renderTableView = this._renderTableView.bind(this);
        this._onFilterChange = this._onFilterChange.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._openModal = this._openModal.bind(this);
        this._renderModal = this._renderModal.bind(this);
        this._getClientName = this._getClientName.bind(this);

        this._getItems = this._getItems.bind(this);
        this._openAddMessage = this._openAddMessage.bind(this);
        this._closeAddMessage = this._closeAddMessage.bind(this);
        this.debounce = debounce(this._getItems, 1000);
        this._createAlert = debounce((message, type) => {
            createAlert(message, type);
        }, 500);

        const categoryid = props.match.params.categoryid;
        const status = (containValue(CATEGORY_STATUS, props.match.params.status)) ? props.match.params.status : "";

        this.state = {
            client: null,
            items: [],
            view: "table",
            filterStatus: status,
            searchQuery: "",
            openModal: "",
            per_page: 3 * 10,
            page: 0,
            total: 0,
            last_page: false
        };
    }

    _getClientName(cid) {
        const { clients } = this.props;
        if (cid) {
            if (clients && clients[cid]) {
                return clients[cid].name;
            }
        }
        return "";
    }
    _formatTerms() {
        const { sid } = this.state;
        const terms = [];
        const search = this._getSearch(sid);
        if (search && search.terms) {
            search.terms.map(function (term, i) {
                terms.push({ value: term.term, selected: term.checked });
            });
        }
        return terms;
    }
    componentDidMount() {
        if (!this.props.user.isSuper) {
            doGetReduxClient(this.props.user.cid);
        } else {
            doGetReduxClients();
        }
        this._getItems();
    }
    componentWillReceiveProps(newProps) {
        const newState = {};
        if (!this.state.client && newProps.user.cid) {
            newState["client"] = newProps.clients[newProps.user.cid];
        }
        if (this.props.match.params.status != newProps.match.params.status) {
            const status = containValue(CATEGORY_STATUS, newProps.match.params.status) ? newProps.match.params.status : "";
            newState["filterStatus"] = status;
        }
        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }
    _getItems() {
        const { client, filterStatus, per_page, page, searchQuery, startDate, endDate } = this.state;

        const self = this;
        this.setState(
            {
                messagesLoading: true
            },
            () =>
                doGetECommunicationCategories(
                    (client || { cid: this.props.user.cid }).cid,
                    filterStatus,
                    page * per_page,
                    per_page,
                    searchQuery
                ).then(data => {
                    const state = {
                        messagesLoading: false,
                        total: 0
                    };
                    if (data) {
                        const items = data.items;
                        const total = data.count;
                        if (items) {
                            state.items = items;
                            state.last_page = items.length != per_page;
                            state.total = total;
                        }
                        self.setState(state);
                    }
                })
        );
    }
    _onFilterChange(fieldName, event, shouldDebounce = false) {
        let val = event && event.target ? event.target.value : event ? event : "";
        if (fieldName === "cid") {
          fieldName = "client";
          val = (this.props.clients || {})[val];
        }
        const newState = {
          [fieldName]: val
        };

        this.setState(newState, () => {
          if (shouldDebounce) {
            this.debounce();
            this._createAlert('Search filter updated', 'success');
          } else {
            this._getItems();
            this._createAlert('Search filter updated', 'success');
          }
        });
    }
    _openAddMessage() {
        this.setState({ addMessage: true });
    }
    _closeAddMessage() {
        this.setState(
            {
                addMessage: false
            },
            this._getItems
        );
    }

    _createStatusOptions() {
        const items = [];
        for (const item of CATEGORY_STATUS) {
            items.push(
                <option key={item.value} value={item.value}>
                    {item.name}
                </option>
            );
        }
        return items;
    }

    render() {
        const { searchQuery, client, view, filterStatus, items, messagesLoading } = this.state;

        const buttons = [];
        if (!this.props.user.isSuper) {
            buttons.push({ title: "Create", on_click: this._openAddMessage });
        }

        return (
            <Wrapper id="ecomm-msg-category">
                {this.state.addMessage && (
                    <AddECommunicationCategory
                        cid={client.cid}
                        client={client}
                        closeAction={this._closeAddMessage}
                    />
                )}
                <PageHeading title={`Message Categories`} buttons={buttons} />
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <div className="table__filters">
                                    <div className="table__filters__option">
                                        <label htmlFor="search">Search:</label>
                                        <div className="search">
                                            <input
                                                id="search"
                                                className="filter__search"
                                                ref={input => {
                                                    this.userInput = input;
                                                }}
                                                type="text"
                                                onChange={event => this._onFilterChange("searchQuery", event, true)}
                                                value={searchQuery}
                                            />
                                            <i className="fa fa-search" />
                                        </div>
                                    </div>
                                    {this.props.user.isSuper && (
                                        <div className="table__filters__option">
                                            <label htmlFor="client">Client:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="client"
                                                    className="filters__search"
                                                    value={(client || {}).cid}
                                                    onChange={event => this._onFilterChange("cid", event)}
                                                >
                                                    <option value="">All</option>
                                                    <optgroup label="Clients">
                                                        {Object.keys(this.props.clients).map((key, i) => {
                                                            const c = this.props.clients[key];
                                                            return (
                                                                <option key={c.cid} value={c.cid}>
                                                                    {c.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    <div className="table__filters__option">
                                        <label htmlFor="filterStatus">Filter By Status:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="filterStatus"
                                                className="filters__search"
                                                value={filterStatus}
                                                onChange={event => this._onFilterChange("filterStatus", event)}
                                            >
                                                <option value="">All</option>
                                                {this._createStatusOptions()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        page={this.state.page}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.per_page}
                                        changePage={newPage => this._onFilterChange("page", newPage)}
                                        type="select"
                                        disabled={this.state.loading}
                                        showTotal={false}
                                    />
                                </div>
                                {this._renderItems(view, messagesLoading, items)}
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        currItems={items.length}
                                        page={this.state.page}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.per_page}
                                        changePage={newPage => this._onFilterChange("page", newPage)}
                                        type="select"
                                        disabled={this.state.loading}
                                        showTotal={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
    _renderItems(view, loading, items) {
        return this._renderTableView(loading, items);
    }

    _renderModal(item) {
        const { client } = this.state;
        if (this.state.openModal && this.state.openModal == item.categoryid) {
            return (
                <AddECommunicationCategory
                    cid={item.cid}
                    client={this.props.clients[item.cid]}
                    item={item}
                    updateAction={this._getItems}
                    closeAction={this._closeModal}
                />
            );
        }
        return;
    }
    _closeModal() {
        this.setState({ openModal: "" }, this._getItems);
    }
    _openModal(categoryid) {
        this.setState({ openModal: categoryid });
    }

    _renderTableView(loading, items) {
        const { client } = this.state;
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        {this.props.user.isSuper && [<th tabIndex={0}>Client</th>]}
                        <th tabIndex={0}>Title</th>
                        <th tabIndex={0}>Status</th>
                        <th tabIndex={0} />
                    </tr>
                </thead>
                <tbody>
                    {!loading &&
                        items.map(
                            function (item, i) {
                                return (
                                    <tr key={`tr-${item.categoryid}`}>
                                        {this._renderModal(item)}
                                        {this.props.user.isSuper && [
                                            <td key={`client-${item.categoryid}`} tabIndex={0}>
                                                <Link to={`/clients/e/${item.cid}`}>
                                                    {this._getClientName(item.cid) || "N/A"}
                                                </Link>
                                            </td>
                                        ]}
                                        <td key={`title-${item.categoryid}`} tabIndex={0}>
                                            <Link
                                                href={item.Link || ""}
                                                target="_blank"
                                                onClick={() => this._openModal(item.categoryid)}
                                            >
                                                {item.title || "N/A"}
                                            </Link>
                                        </td>
                                        <td key={`status-${item.categoryid}`} tabIndex={0}>
                                            {getNamebyValue(CATEGORY_STATUS, item.status) || "N/A"}
                                        </td>
                                        <td key={`action-${item.categoryid}`} tabIndex={0}>
                                            <Button
                                                style={{ marginRight: "5px" }}
                                                className="btn--sm"
                                                onClick={() => this._openModal(item.categoryid)}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            }.bind(this)
                        )}
                    {!loading && (!items || items.length == 0) && (
                        <tr>
                            <td tabIndex={0} colSpan="42">No message categories matching your filters could be found</td>
                        </tr>
                    )}
                    {loading && (
                        <tr>
                            <td tabIndex={0} colSpan="42">
                                <Spinner />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

// only allow : super, client admin and curator admin
ECommunicationCategory.allowSuper = true;
ECommunicationCategory.allowApiAdmin = false;
ECommunicationCategory.allowClientAdmin = true;
ECommunicationCategory.allowAdmin = false;
ECommunicationCategory.allowCurator = true;
ECommunicationCategory.allowReports = false;
const mapStateToProps = state => ({
    clients: state.lists.clients,
    user: state.session.admin
});

export default connect(mapStateToProps)(ECommunicationCategory);
