import React, { Component } from "react";
import { connect } from "react-redux";

import { GroupActions } from "../../actions";
import Button from "../atoms/Button";
import { Wrapper, Row } from "../atoms/Layout";
import ElementListBox, { PaginationData, Filter } from "../molecules/ElementListBox";
import Checkbox from "../atoms/Checkbox";
import { getFriendlyDate, sanitizeHtml } from "../../utils/Helpers";

interface IGroupProps {
    user: Mongo.clientAdmin & { cid: string };
}

interface IGroupState {
    cid: string;
    editing: { [gid: string]: boolean };
    createName: string;
    forceRefresh: boolean;
}

class Groups extends Component<IGroupProps, IGroupState> {
    static allowSuper = false;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;

    constructor(props: IGroupProps) {
        super(props);
        this.state = {
            editing: {},
            cid: props.user.cid || "",
            createName: "",
            forceRefresh: false
        };
    }

    async upsertGroup(cid: string, group: Partial<ServerTypes.Console.IGroup>) {
        if (group && group.name) {
            await GroupActions.doUpsert({ ...group, cid });
        }
    }

    async refresh(filters: Filter, paginationData: PaginationData) {
        return GroupActions.doList(filters.search, filters.cid, paginationData.limit, paginationData.skip, paginationData.sort);
    }

    async deleteGroup(cid: string, gid: string) {
        return GroupActions.doDelete(cid, gid);
    }



    render() {
        return (
            <Wrapper id="groups">
                <Row>
                    <div className="groups-list">
                    <ElementListBox<ServerTypes.Console.IGroup>
                        title="Groups"
                        keys={{
                            default: "Default Group",
                            name: "Name",
                            lastUpdatedOn: "Last Updated",
                            actions: "Actions"
                        }}
                        sortableKeys={["Name", "Last Updated"]}
                        defaultSort={{ lastUpdatedOn: -1 }}
                        defaultFilter={{
                            ...(this.state.cid ? { cid: this.state.cid } : null)
                        }}
                        filterSearch={true}
                        forceRefresh={this.state.forceRefresh}
                        resetRefresh={() => this.setState({ forceRefresh: false })}
                        filterCid={false}
                        refresh={({ search, cid }, paginationData) => this.refresh({ search, cid }, paginationData)}
                        update={({ cid }, group) => this.upsertGroup(cid, group)}
                        createRow={{
                            name: (
                                <input
                                    type="text"
                                    placeholder="Create a new group"
                                    onChange={e => {
                                        const createName = sanitizeHtml(e.target.value) || "";
                                        this.setState({ createName });
                                    }}
                                />
                            ),
                            actions: (
                                <Button
                                    key={`group_create`}
                                    className="btn"
                                    onClick={async () => {
                                        await this.upsertGroup(this.state.cid, {
                                            gid: "",
                                            name: this.state.createName
                                        });
                                        this.setState({ createName: "", forceRefresh: true });
                                    }}
                                    disabled={!this.state.createName}
                                >
                                    <i className={`fa fa-plus`} /> Create
                                </Button>
                            )
                        }}
                        mutateItem={(group, index, items, originalItems, context) => ({
                            default: (
                                <div style={{ paddingLeft: "40px" }}>
                                    <Checkbox
                                        name={`defaultGroup-${index + 1}`}
                                        checked={group.default || false}
                                        _onChange={() => {
                                            const isDefault = group.default;
                                            GroupActions.doSetDefault(group.cid, isDefault ? "" : group.gid);
                                            const contextItems = context.state.items;
                                            for (const i in contextItems) {
                                                contextItems[i].default = false;
                                            }
                                            contextItems[index].default = !(group.default || false);
                                            context.setState({ items: contextItems });
                                        }}
                                    ></Checkbox>
                                </div>
                            ),
                            name: (
                                <input
                                    type="text"
                                    value={group.name}
                                    aria-label={group.name}
                                    disabled={!this.state.editing[group.gid]}
                                    onChange={e => {
                                        const value = e.target.value || "";
                                        const contextItems = context.state.items;
                                        contextItems[index].name = value;
                                        context.setState({ items: contextItems });
                                    }}
                                />
                            ),
                            lastUpdatedOn: group.lastUpdatedOn ? getFriendlyDate(group.lastUpdatedOn)! : ""
                        })}
                        buttonElements={[
                            item => (
                                <Button
                                    key={`${item.gid}--edit`}
                                    className="btn--sm"
                                    onClick={async () => {
                                        const { editing } = this.state;
                                        const isEditing = item.gid in editing && editing[item.gid];
                                        if (isEditing) {
                                            item.name = sanitizeHtml(item.name) || "";
                                            if (!(await GroupActions.doUpsert({ ...item }))) return;
                                        }
                                        editing[item.gid] = !isEditing;
                                        this.setState({ editing });
                                    }}
                                >
                                    <i className={`fa ${this.state.editing[item.gid] ? "fa-check" : "fa-edit"}`} />{" "}
                                    {this.state.editing[item.gid] ? "Save" : "Edit"}
                                </Button>
                            )
                        ]}
                        delete={({ gid }, { cid }) => this.deleteGroup(cid, gid)}
                        pagination={true}
                    />
                    </div>
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(Groups);
