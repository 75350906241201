import React from "react";
import BaseStream, { IBaseStreamState } from "./BaseStream";
import {
    doGetLinkedInCompanies,
    doGetFacebookPages,
    doGetTwitterFeedArticles,
    doGetFeedArticles,
    doGetSeismicFeedArticles,
    createAlert,
    doGetConnectedAccounts,
    AdminActions,
    ACCOUNT_ADMIN,
    doGetAdmins,
    doRefreshSocialFeeds
} from "../../../../actions";
import ActionableList from "../../../molecules/ActionableList";
import Button from "../../../atoms/Button";
import Tooltip from "../../../atoms/Tooltip";
import Link from "../../../atoms/Link"
import { getMultiSelectValues, history, store } from "../../../../utils";
import StreamPreview from "../../../molecules/modals/StreamPreview";
import Periods from "../../../molecules/Periods";
import { _ValueContainer, _hasMentionOrHashtag, _formatHashtagOrMention } from "../../../pages/articles/sharedArticle";

interface IFeedStreamState extends IBaseStreamState {
    facebookPages: Array<{ id: string; name: string; }>;
    instagramPages: Array<{ id: string; name: string; username: string; }>;
    linkedInCompanies: Array<{ id: string; name: string; }>;
    twitterPreview: false | string;
    feedPreview: false | string;
    channelPreview: false | string;
    connectedAccounts: { twitter: boolean; facebook: boolean; linkedIn: boolean; };
    showLiLogs: boolean;
    connectedAdminEmail: string;
    connectedAdminLiProfile: { firstName: string; lastName: string; url: string; authError?: boolean };
    sessionAdminLiProfile: { firstName: string; lastName: string; authError?: boolean };
    connectedAdminFbEmail: string;
    connectedAdminFbProfile: { firstName: string; lastName: string; url?: string; authError?: boolean };
    sessionAdminFbProfile: { firstName: string; lastName: string; authError?: boolean };
    refreshingFeeds: boolean;
    isCreate: boolean;
    hasClearedMetaFeeds: boolean;
    hasClearedLiFeeds: boolean;
}
export default class FeedStream extends BaseStream<IFeedStreamState> {
    constructor(props) {
        super(props);
        this.authTwitter = this.authTwitter.bind(this);
        this.authFacebook = this.authFacebook.bind(this);
        this.authLinkedIn = this.authLinkedIn.bind(this);
    }

    getInitialState() {
        const props = super.getInitialState();
        return {
            ...props,
            ...(props.stream
                ? {
                    stream: {
                        ...props.stream,
                        isApprovalRequired: true
                    }
                }
                : null),
            facebookPages: [],
            instagramPages: [],
            linkedInCompanies: [],
            showLiLogs: false,
            twitterPreview: false,
            feedPreview: false,
            canPreviewOnCreate: false,
            channelPreview: false,
            connectedAccounts: { twitter: false, facebook: false, linkedIn: false },
            connectedAdminEmail: "",
            connectedAdminLiProfile: { firstName: "", lastName: "", url: ""},
            sessionAdminLiProfile: { firstName: "", lastName: "" },
            connectedAdminFbEmail: "",
            connectedAdminFbProfile: { firstName: "", lastName: "", url: ""},
            sessionAdminFbProfile: { firstName: "", lastName: "" },
            refreshingFeeds: false,
            hasClearedMetaFeeds: false,
            hasClearedLiFeeds: false
        };
    }
    async componentDidMount() {
        await super.componentDidMount();

        const fetchLiQuery = this.props.location.search.indexOf("fetchli") > -1;
        const fetchFbQuery = this.props.location.search.indexOf("fetchfb") > -1;
        let oauth = this.props.admin.oauth;

        if (oauth?.linkedIn || oauth?.facebook || fetchLiQuery || fetchFbQuery) {
            if (fetchLiQuery || fetchFbQuery) {
                history.push(this.props.location.pathname);
                // add oauth to cached admin data in case the admin navigates to a feed stream again in the same session
                // this page is the worst
                oauth = await AdminActions.doGetOAuth(this.props.admin.aid).catch(err => this.props.admin.oauth);
                store.dispatch({ type: ACCOUNT_ADMIN, admin: { ...this.props.admin, oauth } });
            }

            if (oauth?.facebook) {
                this.getAdminFbProfile(this.props.admin.aid, "session");
                this.fetchFacebookPages();
            }
            if (oauth?.linkedIn) {
                this.getAdminLiProfile(this.props.admin.aid, "session");
                this.fetchLinkedInCompanies();
            }
        } else {
            const connectedAccounts = await doGetConnectedAccounts();
            if (connectedAccounts) {
                this.setState({ connectedAccounts });
                if (connectedAccounts.facebook) {
                    this.getAdminFbProfile(this.props.admin.aid, "session");
                    this.fetchFacebookPages();
                }
                if (connectedAccounts.linkedIn) {
                    this.getAdminLiProfile(this.props.admin.aid, "session");
                    this.fetchLinkedInCompanies();
                }

            }
        }
        if (this.props.stream.linkedInFeeds?.aids[0]) {
            await this.getAdminLiProfile(this.props.stream.linkedInFeeds?.aids[0], "connected");
        }
        if (this.props.stream.facebookFeeds?.aids[0]) {
            await this.getAdminFbProfile(this.props.stream.facebookFeeds?.aids[0], "connected");
        }
        if (this.props.location.pathname.endsWith("c/feed")) {
            this.setState({ isCreate: true })
        }
        if (await !this.expiredFeedTokens() && this.props.admin?.expiredFeeds?.length) {
            const expiredFeeds = [];
            store.dispatch({ type: ACCOUNT_ADMIN, admin: { ...this.props.admin, expiredFeeds } });
        }
        await this.loadStreamSettings();
    }

    loadStreamSettings() {
        const settings = localStorage.getItem("streamSettings");
        if (settings) {
            const parsedSettings = (JSON.parse(settings) as unknown) as ServerTypes.Console.IStream;
            if (parsedSettings.twitterFeeds?.screenNames?.length) {
                this.setState({ stream: parsedSettings }, () => this.checkExpTwitterFeeds());
            } else {
                this.setState({ stream: parsedSettings });
            }
            localStorage.removeItem("streamSettings");
        }
    }

    expiredFeedTokens() {
        const { stream } = this.state;
        if (stream?.facebookFeeds?.expiredFeedTokens?.length || stream?.linkedInFeeds?.expiredFeedTokens?.length || stream?.twitterFeeds?.expiredFeedTokens?.length) {
            return true;
        } else {
            return false;
        }
    }

    sessionAdminDidConnectStream(networkFeed: "linkedInFeeds" | "facebookFeeds" | "instagramFeeds" | "twitterFeeds") {
        const { admin } = this.props;
        const { stream } = this.state;
        return stream[networkFeed]?.aids?.indexOf(admin.aid) !== -1;
    }

    saveStreamSettings() {
        const { stream } = this.state;
        localStorage.setItem("streamSettings", JSON.stringify(stream));
    }

    getSettings() {
        const { client } = this.props;
        const { stream } = this.state;
        const settings = super.getSettings();
        delete settings.locations;
        return {
            ...settings,
            active: {
                label: "Active",
                value: !!stream.active,
                description: "Enables the stream to be shown in the app",
                onChange: () =>
                    this.setState({ stream: { ...stream, active: !stream.active } })
            },
            ...(client.allowAutopost
                ? {
                    allowAutopost: {
                        label: "Allow Stream Subscription",
                        value: !!stream.allowAutopost,
                        // we don't want to allow autopost for feed streams, so don't show this option for new ones and hide it if it gets disabled on existing ones
                        hidden: !stream.allowAutopost,
                        description:
                            "Allows users to automatically post content from a stream. This feature\ndoes not operate retroactively. Only users who are subscribed to a campaign\nbefore an article enters the stream will have the article automatically placed in their\nschedule queue. Content filters will not be applied if this is enabled.",
                        onChange: (value) =>
                            this.setState({
                                stream: { ...stream, allowAutopost: value, filters: null, globalFilters: false }
                            })
                    }
                }
                : null),
            isWebShare: {
                label: "Allow Extra Network Shares",
                hidden: !client.strictExtraNetworks,
                value: !!stream.isWebShare,
                description: "Allows users to share articles from this stream to their extra networks",
                onChange: (value) =>
                    this.setState({
                        stream: { ...stream, isWebShare: value }
                    })
            },
            isEditable: {
                label: "Comments Editable",
                value: !!stream.isEditable,
                description:
                    "Lets the user edit comments before sharing articles.",
                onChange: (value) =>
                    this.setState({ stream: { ...stream, isEditable: value } })
            },
            showRepostedLinkedInArticles: {
                label: "Show Reposted LinkedIn Articles",
                value: !!stream.showRepostedLinkedInArticles,
                description: "Pull in reposts of articles shared to LinkedIn Company Pages, from LinkedIn Company Pages.\n\nNOTE: The LinkedIn API currently does not support reposts from or by user profiles directly.",
                onChange: () =>
                    this.setState({ stream: { ...stream, showRepostedLinkedInArticles: !stream.showRepostedLinkedInArticles } })
            },
            ...(
                this._renderHashtagsAndMentions(stream.readOnly)
            )
        };
    }

    async canSubmit(stream: ServerTypes.Console.IStream) {
        if (stream.allowAutopost && stream.subscriptionPeriods && !stream.subscriptionPeriods.length) {
            createAlert(`Please add a period to the subscription`);
            return false;
        }
        return true;
    }

    async refreshFeed() {
        const { stream } = this.state;
        const data = await doRefreshSocialFeeds(this.props.client.cid, stream.sid);
        const crawlLog = data?.liCrawlLogs && data.liCrawlLogs[stream.sid] && data.liCrawlLogs[stream.sid].logs
        if (data?.finished) {
            this.setState({ refreshingFeeds: false });
            if (crawlLog) {
                this.setState({
                    stream : {
                        ...stream,
                        linkedInFeeds: {
                            ...stream.linkedInFeeds,
                            aids: stream.linkedInFeeds!.aids,
                            crawlLog
                        }
                    } });
            }
        }
    }

    isRefreshing() {
        return this.state.refreshingFeeds;
    }

    copyToClipboard = async () => {
        const { stream, whiteLabelURL } = this.state;
        const shareableLink = `${whiteLabelURL || "https://livesocial.seismic.com/"}?sid=${encodeURIComponent(stream.sid)}&sSubModal=open`;

        try {
            await navigator.clipboard.writeText(shareableLink);
            createAlert(`The text has been successfully copied`, `success`);
        } catch (err) {
            createAlert(`${err}`, `error`);
        }
    };

    renderBelowPanel() {
        const { client } = this.props;
        const { stream, feedPreview } = this.state;

        return [
            ...(stream.allowAutopost && client.allowAutopost
                ? [
                    <div className="ibox">
                        <div
                            className="ibox-title"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <h5>
                                Periods
                                <Tooltip
                                    className="tooltip__maxwidth"
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    Periods for stream subscriptions
                                </Tooltip>
                            </h5>
                            {stream.sid
                                ? (
                                    <div className="ibox-tools">
                                        <Button
                                            className="btn--sm"
                                            onClick={this.copyToClipboard}
                                        >
                                            Copy link for stream subscription options
                                            <Tooltip
                                                className="tooltip__maxwidth"
                                                style={{
                                                    paddingLeft: "5px"
                                                }}>
                                                This button will copy the link that directs to the stream's subscription modal in app
                                            </Tooltip>
                                        </Button>
                                    </div>
                                ) : null
                            }
                        </div>

                        <div className="ibox-content">
                            <div className="form__row">
                                <div className="form__group--full">
                                    <Periods
                                        periods={stream.subscriptionPeriods}
                                        update={subscriptionPeriods => {
                                            this.setState({ stream: { ...stream, subscriptionPeriods } });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ]
                : []),
            <div className="ibox">
                {feedPreview !== false && (
                    <StreamPreview
                        sourceUrl={feedPreview}
                        open={true}
                        addFeed={() => {
                            this.setState({
                                stream: {
                                    ...stream,
                                    feeds: (stream!.feeds || []).concat([{ url: feedPreview, headers: {} }])
                                }
                            });
                        }}
                        onClose={() => this.setState({ feedPreview: false })}
                        getArticles={() => doGetFeedArticles(feedPreview, stream.lang, client.cid)}
                        client={client}
                        stream={stream}
                    />
                )}
                <div className="ibox-title">
                    <h5>Feeds</h5>
                </div>
                <div className="ibox-content">
                    <form className="form">
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <label className="form__label">Sitemap and RSS Feeds</label>
                                <ActionableList
                                    updateAction={(items: string[], remove: boolean) => {
                                        if (!remove) {
                                            this.setState({ feedPreview: items[items.length - 1] });
                                            return;
                                        }
                                        this.setState({
                                            stream: {
                                                ...stream,
                                                feeds: items.map(val => ({ url: val, headers: {} }))
                                            }
                                        });
                                    }}
                                    disabled={true}
                                    placeholder="Please enter an RSS Feed URL or Sitemap URL"
                                    items={(stream.feeds || []).map(val => val.url)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>,
            ...(client.allowSocialStreams ? [this.renderLinkedInFeeds(), this.renderFacebookFeeds(), this.renderTwitterFeeds()] : []),
            ...(client.allowSeismicStreams ? [this.renderSeismicFeeds()] : []),
            ...super.renderBelowPanel(),
        ];
    }

    renderSeismicFeeds() {
        const { client } = this.props;
        const { stream, channelPreview } = this.state;
        return (
            <div className="ibox">
                {channelPreview !== false && stream.seismicUsername && (
                    <StreamPreview
                        sourceUrl={channelPreview}
                        open={true}
                        addFeed={() => {
                            this.setState({
                                stream: {
                                    ...stream,
                                    seismicFeeds: (stream!.seismicFeeds || []).concat([{ channel: channelPreview }])
                                }
                            });
                        }}
                        onClose={() => this.setState({ channelPreview: false })}
                        getArticles={() => doGetSeismicFeedArticles(client.cid, channelPreview, stream.lang, stream.seismicUsername!)}
                        client={client}
                        stream={stream}
                    />
                )}
                <div className="ibox-title">
                    <h5>Seismic Channels</h5>
                </div>
                <div className="ibox-content">
                    <form className="form">
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <label htmlFor="seismicUsername" className="form__label">Seismic Username</label>
                                <input
                                    id="seismicUsername"
                                    type="text"
                                    placeholder="Please enter your Seismic Username to add Channels"
                                    value={stream.seismicUsername || ""}
                                    onChange={event => this.setState({ stream: { ...stream, seismicUsername: event.target.value } })}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <label className="form__label">Content Channels</label>
                                <ActionableList
                                    updateAction={(items: string[], remove: boolean) => {
                                        if (!remove) {
                                            if (!stream.seismicUsername) {
                                                createAlert("You must enter a valid Seismic Username to add a Seismic Channel");
                                                return;
                                            }
                                            this.setState({ channelPreview: items[items.length - 1] });
                                            return;
                                        }
                                        this.setState({
                                            stream: {
                                                ...stream,
                                                seismicFeeds: items.map(val => ({ channel: val }))
                                            }
                                        });
                                    }}
                                    disabled={true}
                                    placeholder="Please enter a Channel"
                                    items={(stream.seismicFeeds || []).map(val => val.channel)}
                                    noUsername={!stream.seismicUsername ? true : false}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    async fetchFacebookPages() {
        const { stream, connectedAdminFbProfile, sessionAdminFbProfile } = this.state;
        const connectedAid = stream.facebookFeeds?.aids[0] || stream.instagramFeeds?.aids[0];

        const data = await doGetFacebookPages();

        if (data == "Invalid Facebook token") {
            if (connectedAid) {
                this.setState({
                    connectedAdminFbProfile: {
                        ...connectedAdminFbProfile,
                        authError: true
                    }
                });
            } else {
                this.setState({
                    sessionAdminFbProfile: {
                        ...sessionAdminFbProfile,
                        authError: true
                    }
                });
            }
        } else {
            if (data.facebookPages && data.facebookPages.length) {
                if (stream.facebookFeeds?.expiredFeedTokens?.length) {
                    const facebookFeeds = stream.facebookFeeds;
                    facebookFeeds?.expiredFeedTokens?.map((feed, i) => {
                        data.facebookPages.map(accnt => {
                            if (accnt.id == feed.id) {
                                facebookFeeds.expiredFeedTokens!.splice(i, 1);
                            }
                        })
                    })
                    this.setState({ facebookPages: data.facebookPages, stream: { ...stream, facebookFeeds } });
                } else {
                    this.setState({ facebookPages: data.facebookPages });
                }
            }
            if (data.instagramPages && data.instagramPages.length) {
                if (stream.instagramFeeds?.expiredFeedTokens?.length) {
                    const instagramFeeds = stream.instagramFeeds;
                    instagramFeeds?.expiredFeedTokens?.map((feed, i) => {
                        data.instagramPages.map(accnt => {
                            if (accnt.id == feed.id) {
                                instagramFeeds.expiredFeedTokens!.splice(i, 1);
                            }
                        })
                    })
                    this.setState({ instagramPages: data.instagramPages, stream: { ...stream, instagramFeeds } });
                } else {
                    this.setState({ instagramPages: data.instagramPages });
                }
            }
        }
    }

    async getAdminFbProfile(aid: string, type: "connected" | "session") {
        const admins = await doGetAdmins({ cid: this.props.client.cid });
        const admin = admins.items.find(a => a.aid == aid);

        if (admin?.facebookProfile) {
            if(type == "connected") {
                this.setState({
                    connectedAdminFbEmail: admin.email,
                    connectedAdminFbProfile: {
                        firstName: admin.facebookProfile.firstName || "",
                        lastName: admin.facebookProfile.lastName || "",
                        url: admin.facebookProfile.url || ""
                    }
                })
            }
            if(type == "session") {
                this.setState({
                    sessionAdminFbProfile: {
                        firstName: admin.facebookProfile.firstName || "",
                        lastName: admin.facebookProfile.lastName || ""
                    }
                })
            }
            this.setState({
                connectedAccounts: {
                        ...this.state.connectedAccounts,
                        facebook: true
                    }
            })
        }
    }

    async clearFacebookFeeds() {
        this.setState({
            stream: {
                ...this.state.stream,
                facebookFeeds: {
                    aids: [],
                    pages: []
                },
                instagramFeeds: {
                    aids: [],
                    igPages: []
                }
            },
            facebookPages: [],
            instagramPages: [],
            connectedAdminFbEmail: "",
            connectedAdminFbProfile: {
                firstName: "",
                lastName: "",
                url: ""
            },
            hasClearedMetaFeeds: true
        });
    }

    async fetchLinkedInCompanies() {
        const { stream, connectedAdminLiProfile, sessionAdminLiProfile } = this.state;
        const connectedAid = stream.linkedInFeeds?.aids[0];
        //if no connected aid, get companies from session admin
        const linkedInCompanies = await doGetLinkedInCompanies(connectedAid ? connectedAid : undefined);

        if (Array.isArray(linkedInCompanies) && linkedInCompanies?.length) {
            if (stream.linkedInFeeds?.expiredFeedTokens?.length) {
                const linkedInFeeds = stream.linkedInFeeds;
                linkedInFeeds.expiredFeedTokens?.map((feed, i) => {
                    linkedInCompanies.map(comp => {
                        if (comp.id == feed.id) {
                            linkedInFeeds!.expiredFeedTokens!.splice(i, 1);
                        }
                    })
                })
                this.setState({ linkedInCompanies, stream: { ...stream, linkedInFeeds } });
            } else {
                this.setState({ linkedInCompanies });
            }
        } else if (linkedInCompanies == "Invalid LinkedIn token"){
            if (connectedAid) {
                this.setState({
                    connectedAdminLiProfile: {
                        ...connectedAdminLiProfile,
                        authError: true
                    }
                });
            } else {
                this.setState({
                    sessionAdminLiProfile: {
                        ...sessionAdminLiProfile,
                        authError: true
                    }
                });
            }
        }
    }

    async getAdminLiProfile(aid: string, type: "connected" | "session") {
        const admins = await doGetAdmins({ cid: this.props.client.cid });
        const admin = admins.items.find(a => a.aid == aid);

        if (admin?.linkedInProfile) {
            if(type == "connected") {
                this.setState({
                    connectedAdminEmail: admin.email,
                    connectedAdminLiProfile: {
                        firstName: admin.linkedInProfile.firstName || "",
                        lastName: admin.linkedInProfile.lastName || "",
                        url: `https://www.linkedin.com/in/${admin.linkedInProfile.vanityName}` || ""
                    }
                })
            }
            if(type == "session") {
                this.setState({
                    sessionAdminLiProfile: {
                        firstName: admin.linkedInProfile.firstName || "",
                        lastName: admin.linkedInProfile.lastName || ""
                    }
                })
            }
            this.setState({
                connectedAccounts: {
                        ...this.state.connectedAccounts,
                        linkedIn: true
                    }
            })
        }
    }

    async clearLinkedInFeeds() {
        this.setState({
            stream: {
                ...this.state.stream,
                linkedInFeeds: {
                    aids: [],
                    companies: [],
                    //preserve last crawlLog
                    crawlLog: this.state.stream.linkedInFeeds?.crawlLog
                }
            },
            linkedInCompanies: [],
            connectedAdminEmail: "",
            connectedAdminLiProfile: {
                firstName: "",
                lastName: "",
                url: ""
            },
            hasClearedLiFeeds: true
        });
    }

    async authTwitter(event) {
        event.preventDefault();
        await this.saveStreamSettings();
        if (window) {
            window.location.href = `/auth/twitter?rp=${encodeURIComponent(window.location.href)}&src=console`;
        }
    }

    async authFacebook(event) {
        event.preventDefault();
        await this.saveStreamSettings();
        if (window) {
            window.location.href = `/auth/facebook?rp=${`${encodeURIComponent(window.location.href)}?fetchfb=t`}&src=console`;
        }
    }

    async authLinkedIn(event) {
        event.preventDefault();
        await this.saveStreamSettings();
        if (window) {
            window.location.href = `/auth/linkedin?rp=${`${encodeURIComponent(window.location.href)}?fetchli=t`}&src=console&aid=${this.props.admin.aid}`;
        }
    }

    checkExpTwitterFeeds() {
        const { twitterFeeds } = this.state.stream;
        if (twitterFeeds) {
            if (twitterFeeds?.expiredFeedTokens?.length) {
                let expiredFeedTokens = twitterFeeds?.expiredFeedTokens;
                twitterFeeds?.expiredFeedTokens.map((feed, i) => {
                    twitterFeeds.screenNames?.map(n => {
                        if (feed.name == n.name) {
                            expiredFeedTokens!.splice(i, 1);
                        }
                    })
                })
                let tFeeds = {
                    ...twitterFeeds,
                    expiredFeedTokens
                }
                this.setState({ stream: { ...this.state.stream, twitterFeeds: tFeeds } });
            }
        }
    }

    renderTwitterFeeds() {
        const { admin, client } = this.props;
        const { stream, twitterPreview, connectedAccounts } = this.state;
        let { twitterFeeds } = stream;
        if (!twitterFeeds) {
            twitterFeeds = {
                aids: [],
                screenNames: []
            };
        }
        const hasAttachedTwitter = twitterFeeds!.aids && twitterFeeds!.aids.indexOf(admin.aid) !== -1;

        return (
            <div className="ibox">
                {twitterPreview !== false && (
                    <StreamPreview
                        sourceUrl={`https://twitter.com/${twitterPreview}`}
                        open={true}
                        addFeed={() => {
                            this.setState({
                                stream: {
                                    ...stream,
                                    twitterFeeds: {
                                        aids: twitterFeeds!.aids,
                                        screenNames: (twitterFeeds!.screenNames || []).concat([
                                            { name: twitterPreview }
                                        ])
                                    }
                                }
                            }, () => this.checkExpTwitterFeeds());
                        }}
                        onClose={() => this.setState({ twitterPreview: false })}
                        getArticles={() => doGetTwitterFeedArticles(twitterPreview, admin.aid, client.cid)}
                        client={client}
                        stream={stream}
                    />
                )}
                <div className="ibox-title">
                    <h5>X (Twitter) Feeds&nbsp;</h5>
                    <Tooltip style={{ cursor: "pointer" }}>
                        {
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <span>
                                    Video and animated GIF posts from X (Twitter) are currently not supported and will not be pulled into this stream.
                                </span>
                            </div>
                        }
                    </Tooltip>
                    <div className="ibox-tools">
                        {!hasAttachedTwitter &&
                            connectedAccounts.twitter && (
                                <Button
                                    className="btn--sm"
                                    onClick={() =>
                                        this.setState({
                                            stream: {
                                                ...stream,
                                                twitterFeeds: { ...twitterFeeds, aids: [admin.aid] }
                                            }
                                        })
                                    }
                                >
                                    Attach your X (Twitter) account
                                </Button>
                            )}
                        <Button className="btn--sm" onClick={this.authTwitter}>
                            Sign In With {connectedAccounts.twitter ? "Another " : " "}X (Twitter)
                        </Button>
                    </div>
                </div>
                <div className="ibox-content">
                    <form className="form">
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <ActionableList
                                    disabled={true}
                                    inactive={!hasAttachedTwitter}
                                    updateAction={(items: string[], remove: boolean) => {
                                        if (!remove) {
                                            this.setState({
                                                twitterPreview: items[items.length - 1].replace(/^@/, "")
                                            });
                                            return;
                                        }
                                        const _twitterFeeds: Mongo.ISocialFeed = {
                                            ...twitterFeeds!,
                                            screenNames: (items || []).map(item => ({ name: item.replace(/^@/, "") }))
                                        };
                                        this.setState({ stream: { ...stream, twitterFeeds: _twitterFeeds } });
                                    }}
                                    items={(twitterFeeds!.screenNames || []).map(val => val.name)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    renderFacebookFeeds() {
        const { admin, stream: propsStream } = this.props;
        const {
            stream,
            facebookPages,
            instagramPages,
            connectedAccounts,
            connectedAdminFbEmail,
            connectedAdminFbProfile,
            sessionAdminFbProfile,
            isCreate,
            hasClearedMetaFeeds
        } = this.state;

        let { facebookFeeds, instagramFeeds } = stream;
        if (!facebookFeeds) {
            facebookFeeds = {
                aids: [],
                pages: []
            };
        }
        if (!instagramFeeds) {
            instagramFeeds = {
                aids: [],
                igPages: []
            };
        }

        //did the session admin connect li to this specific stream
        const sessionAdminDidConnectFb = this.sessionAdminDidConnectStream("facebookFeeds");
        const sessionAdminDidConnectIg = this.sessionAdminDidConnectStream("instagramFeeds");
        //does the session admin have an fb account attached to thier clientAdmin obj
        const sessionAdminHasFbOauth = (connectedAccounts.facebook && !sessionAdminFbProfile.authError) || ((sessionAdminDidConnectFb || sessionAdminDidConnectIg) && !connectedAdminFbProfile.authError);

         //the stream has fb attached to the state, but not necessarily updated to db
         const streamHasFbFeedsAdded = (facebookFeeds.pages?.length && facebookFeeds.pages?.length > 0) ? true : false;
         const stateFbPages = facebookFeeds?.pages?.map(c => c.id).toString();
         //the stream has fb feeds connected in the db
         const streamHasFbFeedsConnected = (propsStream.facebookFeeds?.pages?.length && propsStream.facebookFeeds.pages?.length > 0 && propsStream.facebookFeeds.aids.length) ? true : false;
         const propsFbPages = propsStream.facebookFeeds?.pages?.map(c => c.id).toString();
         //the stream has ig attached to the state, but not necessarily updated to db
         const streamHasIgFeedsAdded = (instagramFeeds.igPages?.length && instagramFeeds.igPages.length > 0) ? true : false;
         const stateIgPages = instagramFeeds?.igPages?.map(c => c.id).toString();
         //the stream has ig feeds connected in the db
         const streamHasIgFeedsConnected = (propsStream.instagramFeeds?.igPages?.length && propsStream.instagramFeeds.igPages?.length > 0 && propsStream.instagramFeeds.aids.length) ? true : false;
         const propsIgPages = propsStream.instagramFeeds?.igPages?.map(c => c.id).toString();

         const canRefresh =
            !isCreate &&
            !connectedAdminFbProfile.authError &&
            (streamHasFbFeedsConnected && (propsFbPages == stateFbPages)) ||
            (streamHasIgFeedsConnected && (propsIgPages == stateIgPages))
            ? true
            : false;

        const isExpiredFeedTokens = facebookFeeds!.expiredFeedTokens && facebookFeeds!.expiredFeedTokens.length > 0;

        if (sessionAdminDidConnectFb && facebookPages.length && !streamHasFbFeedsAdded) {
        // if (hasAttachedFacebook && facebookPages.length && !facebookFeeds.pages?.length) {
            this.setState({
                stream: {
                    ...stream,
                    facebookFeeds: {
                        ...facebookFeeds!,
                        pages: [{ name: facebookPages[0].name, id: Number(facebookPages[0].id) }]
                    }
                }
            });
        }
        if (sessionAdminDidConnectIg && instagramPages.length && !streamHasIgFeedsAdded) {
            this.setState({
                stream: {
                    ...stream,
                    instagramFeeds: {
                        ...instagramFeeds!,
                        igPages: [{ name: instagramPages[0].name, id: instagramPages[0].id, username: instagramPages[0].username }]
                    }
                }
            });
        }
        return (
            <div className="ibox">
                <div className="ibox-title">
                    <h5>Facebook / Instagram Feeds&nbsp;</h5>
                    <div className="ibox-tools">
                        {
                            connectedAdminFbProfile.authError || (isExpiredFeedTokens && facebookPages.length === 0 && instagramPages.length === 0)
                            ?
                            <div style={{
                                float: "left",
                                paddingTop: "2px",
                                color: "red"
                            }}>
                                <i>Authorization for this connection has expired and requires renewal</i>
                            </div>
                            :
                            null
                        }
                        {sessionAdminHasFbOauth &&
                            !hasClearedMetaFeeds &&
                            !streamHasFbFeedsAdded &&
                            !connectedAdminFbProfile.authError && (
                                <Button
                                    className="btn--sm"
                                    onClick={() => {
                                        this.setState(
                                            {
                                                stream: {
                                                    ...stream,
                                                    facebookFeeds: { ...facebookFeeds, aids: [admin.aid] }
                                                }
                                            },
                                            async () => {
                                                await this.getAdminFbProfile(admin.aid, "connected");
                                                await this.fetchFacebookPages()
                                            }
                                        );
                                    }}
                                >
                                    Attach {sessionAdminFbProfile.firstName ? sessionAdminFbProfile.firstName : "your"} {sessionAdminFbProfile.lastName ? sessionAdminFbProfile.lastName + "'s " : ""}Facebook pages&nbsp;
                                    <Tooltip style={{ cursor: "pointer" }}>
                                        {
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <span>
                                                    Attach the Facebook pages associated with your Facebook profile. The pages you have access to will appear below.
                                                </span>
                                                <br />
                                                <span>
                                                    Your profile needs the 'create content' permission for a Facebook page to connect it as a feed.
                                                </span>
                                            </div>
                                        }
                                    </Tooltip>
                                </Button>
                        )}
                        {sessionAdminHasFbOauth &&
                            !hasClearedMetaFeeds &&
                            !streamHasIgFeedsAdded &&
                            !connectedAdminFbProfile.authError && (
                                <Button
                                    className="btn--sm"
                                    onClick={() => {
                                        this.setState(
                                            {
                                                stream: {
                                                    ...stream,
                                                    instagramFeeds: { ...instagramFeeds, aids: [admin.aid] }
                                                }
                                            },
                                            async () => {
                                                await this.getAdminFbProfile(admin.aid, "connected");
                                                await this.fetchFacebookPages();
                                            }
                                        )
                                    }}
                                >
                                    Attach {sessionAdminFbProfile.firstName ? sessionAdminFbProfile.firstName : "your"} {sessionAdminFbProfile.lastName ? sessionAdminFbProfile.lastName + "'s " : ""}Instagram pages&nbsp;
                                    <Tooltip style={{ cursor: "pointer" }}>
                                        {
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <span>
                                                    Attach the Instagram pages associated with your Facebook profile. The pages you have access to will appear below.
                                                </span>
                                                <br />
                                                <span>
                                                    Your profile needs the 'create content' permission for an Instagram page to connect it as a feed.
                                                </span>
                                            </div>
                                        }
                                    </Tooltip>
                                </Button>
                        )}
                        {!streamHasFbFeedsAdded &&
                            !streamHasIgFeedsAdded && (
                            <Button className="btn--sm" onClick={this.authFacebook}>
                                Sign In With {sessionAdminHasFbOauth ? "Another " : " "}Facebook
                            </Button>
                        )}
                        {(streamHasFbFeedsAdded || streamHasIgFeedsAdded || connectedAdminFbProfile.authError) &&
                            <Button className="btn--sm" onClick={() => {
                                if (
                                    confirm(
                                        "This will clear all current Facebook / Instagram feeds and the Facebook profile associated with this stream. Click update to save these changes when you're finished editing the stream."
                                    )
                                ) {this.clearFacebookFeeds()}
                            }}>
                                Clear Facebook/Instagram Feeds&nbsp;
                                <Tooltip style={{ cursor: "pointer" }}>
                                        {
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <span>
                                                    Clear the attached feeds and the Facebook profile associated with this stream.
                                                </span>
                                            </div>
                                        }
                                    </Tooltip>
                            </Button>
                        }
                        {canRefresh &&
                            <Button className="btn--sm" disabled={this.isRefreshing()} onClick={() => {
                                if (
                                    confirm(
                                        "This will refresh all content from the social feeds on this stream for the last 30 days. This process can take a few minutes "
                                    )
                                ) {
                                    this.setState(
                                        { refreshingFeeds: true },
                                        () =>this.refreshFeed()
                                    );
                                }
                                }}>
                                Refresh Feeds&nbsp;
                                <Tooltip style={{ cursor: "pointer" }}>
                                        {
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <span>
                                                    Click here to manually fetch content from the social feeds attached to this stream for the last 30 days. This process can take a few minutes.
                                                </span>
                                            </div>
                                        }
                                    </Tooltip>
                            </Button>
                        }
                    </div>
                </div>
                <div style={{ paddingLeft: "15px"}} >
                    <div style={{ fontSize: "12px", display: "flex" }} >
                    {
                        connectedAdminFbEmail &&
                        <span style={{ paddingRight: "10px" }}> Admin: {connectedAdminFbEmail}</span>
                    }
                    {
                        connectedAdminFbProfile.firstName && connectedAdminFbProfile.url &&
                        <span>Facebook Profile: <a href={connectedAdminFbProfile.url}>{connectedAdminFbProfile.firstName} {connectedAdminFbProfile.lastName}</a></span>
                    }
                    {
                        connectedAdminFbProfile.firstName && !connectedAdminFbProfile.url &&
                        <span>Facebook Profile: {connectedAdminFbProfile.firstName} {connectedAdminFbProfile.lastName}</span>
                    }
                    </div>
                </div>
                <div className="ibox-content">
                    <div style={{ display: "flex" }}>
                        <h5>Facebook&nbsp;</h5>
                        <Tooltip style={{ cursor: "pointer" }}>
                            {
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }}
                                >
                                    <span>
                                        At least one Facebook feed must be selected from the list below.
                                    </span>
                                    <span>
                                        Multiple feeds can be selected by holding the Ctrl button and clicking multiple entries.
                                    </span>
                                </div>
                            }
                        </Tooltip>
                    </div>
                    <form className="form">
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <div>
                                    <select
                                        multiple={true}
                                        onChange={e => {
                                            if (sessionAdminDidConnectFb || streamHasFbFeedsAdded) {
                                                this.setState({
                                                    stream: {
                                                        ...stream,
                                                        facebookFeeds: {
                                                            ...facebookFeeds!,
                                                            pages: getMultiSelectValues(e.target).map(id => ({
                                                                name: this.state.facebookPages.filter(
                                                                    i => i.id == id
                                                                )[0].name,
                                                                id: Number(id)
                                                            }))
                                                        }
                                                    }
                                                });
                                            }
                                        }}
                                        value={(facebookFeeds!.pages || []).map(i => `${i.id}`)}
                                    >
                                        {((sessionAdminDidConnectFb ? facebookPages : streamHasFbFeedsAdded ? facebookFeeds.pages :
                                            []) as Array<{ id: string; name: string }>).map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="ibox-content">
                    <div style={{ display: "flex" }}>
                        <h5>Instagram&nbsp;</h5>
                        <Tooltip style={{ cursor: "pointer" }}>
                            {
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }}
                                >
                                    <span>
                                        At least one Instagram feed must be selected from the list below.
                                </span>
                                    <span>
                                        Multiple feeds can be selected by holding the Ctrl button and clicking multiple entries.
                                </span>
                                </div>
                            }
                        </Tooltip>
                    </div>
                    <form className="form">
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <div>
                                    <select
                                        multiple={true}
                                        onChange={e => {
                                            if (sessionAdminDidConnectIg || streamHasIgFeedsAdded) {
                                                this.setState({
                                                    stream: {
                                                        ...stream,
                                                        instagramFeeds: {
                                                            ...instagramFeeds!,
                                                            igPages: getMultiSelectValues(e.target).map(id => ({
                                                                name: this.state.instagramPages.filter(
                                                                    i => i.id == id
                                                                )[0].name,
                                                                id,
                                                                username: this.state.instagramPages.filter(
                                                                    i => i.id == id
                                                                )[0].username
                                                            }))
                                                        }
                                                    }
                                                });
                                            }
                                        }}
                                        value={(instagramFeeds!.igPages || []).map(i => `${i.id}`)}
                                    >
                                        {((sessionAdminDidConnectIg ? instagramPages : streamHasIgFeedsAdded ? instagramFeeds.igPages :
                                            []) as Array<{ id: string; name?: string; username?: string; }>).map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name || item.username}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
    renderLinkedInFeeds() {
        const { admin, stream: propsStream } = this.props;
        const {
            stream,
            linkedInCompanies,
            connectedAccounts,
            showLiLogs,
            connectedAdminEmail,
            connectedAdminLiProfile,
            sessionAdminLiProfile,
            isCreate,
            hasClearedLiFeeds
        } = this.state;

        let { linkedInFeeds } = stream;
        if (!linkedInFeeds) {
            linkedInFeeds = {
                aids: [],
                companies: []
            };
        }
        //did the session admin connect li to this specific stream
        const sessionAdminDidConnectLi = this.sessionAdminDidConnectStream("linkedInFeeds");
        //does the session admin have an li account attached to thier clientAdmin obj
        const sessionAdminHasLiOauth = (connectedAccounts.linkedIn && !sessionAdminLiProfile.authError) || (sessionAdminDidConnectLi && !connectedAdminLiProfile.authError);

        //the stream has li attached to the state, but not necessarily updated to db
        const streamHasLiFeedsAdded = (linkedInFeeds.companies?.length && linkedInFeeds.companies?.length > 0) ? true : false;
        const stateLiCompanies = linkedInFeeds?.companies?.map(c => c.id).toString();
        //the stream has li feeds connected in the db
        const streamHasLiFeedsConnected = (propsStream.linkedInFeeds?.companies?.length && propsStream.linkedInFeeds.companies?.length > 0 && propsStream.linkedInFeeds.aids.length) ? true : false;
        const propsLiCompanies = propsStream.linkedInFeeds?.companies?.map(c => c.id).toString();

        const canRefresh = !connectedAdminLiProfile.authError && streamHasLiFeedsConnected && (propsLiCompanies == stateLiCompanies) && !isCreate ? true : false;
        const isExpiredFeedTokens = linkedInFeeds!.expiredFeedTokens && linkedInFeeds!.expiredFeedTokens.length > 0;

        if (sessionAdminDidConnectLi && !streamHasLiFeedsAdded && linkedInCompanies.length) {
            this.setState({
                stream: {
                    ...stream,
                    linkedInFeeds: {
                        ...linkedInFeeds!,
                        companies: [{
                            name: linkedInCompanies[0].name,
                            id: Number(linkedInCompanies[0].id)
                        }]
                    }
                }
            });
        }
        return (
            <div className="ibox">
                <div className="ibox-title" style={{ marginBottom: "0" }}>
                    <h5>LinkedIn Feeds&nbsp;</h5>
                    <Tooltip style={{ cursor: "pointer" }}>
                        {
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <span>
                                    At least one LinkedIn feed must be selected from the list below.
                                </span>
                                <span>
                                    Multiple feeds can be selected by holding the Ctrl button and clicking multiple entries.
                                </span>
                            </div>
                        }
                    </Tooltip>
                    <div className="ibox-tools">
                        {
                            connectedAdminLiProfile.authError || (isExpiredFeedTokens && linkedInCompanies.length === 0)
                            ?
                            <div style={{
                                float: "left",
                                paddingTop: "2px",
                                color: "red"
                            }}>
                                <i>Authorization for this connection has expired and requires renewal</i>
                            </div>
                            :
                            null
                        }
                        {sessionAdminHasLiOauth &&
                            !hasClearedLiFeeds &&
                            !streamHasLiFeedsAdded &&
                            !connectedAdminLiProfile.authError && (
                                <Button
                                    className="btn--sm"
                                    onClick={() => {
                                            this.setState(
                                                {
                                                    stream: {
                                                        ...stream,
                                                        linkedInFeeds: { ...linkedInFeeds, aids: [admin.aid] }
                                                    }
                                                },
                                                async () => {
                                                    await this.getAdminLiProfile(admin.aid, "connected");
                                                    await this.fetchLinkedInCompanies();
                                                }
                                            );
                                    }}
                                >
                                    Attach {sessionAdminLiProfile.firstName ? sessionAdminLiProfile.firstName : "your"} {sessionAdminLiProfile.lastName ? sessionAdminLiProfile.lastName + "'s " : ""}Linkedin companies&nbsp;
                                    <Tooltip style={{ cursor: "pointer" }}>
                                        {
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <span>
                                                    Attach the companies associated with your LinkedIn profile. The companies you have access to will appear below.
                                            </span>
                                            </div>
                                        }
                                    </Tooltip>
                                </Button>
                        )}
                        {!streamHasLiFeedsAdded &&
                            <Button className="btn--sm" onClick={this.authLinkedIn}>
                                Sign In With {sessionAdminHasLiOauth ? "Another " : " "}LinkedIn
                            </Button>
                        }
                        {(streamHasLiFeedsAdded || connectedAdminLiProfile.authError) &&
                            <Button className="btn--sm" onClick={() => {
                                if (
                                    confirm(
                                        "This will clear all current LinkedIn feeds and the LinkedIn profile associated with this stream. Click update to save these changes when you're finished editing the stream."
                                    )
                                ) {this.clearLinkedInFeeds()}
                            }}>
                                Clear LinkedIn Feeds&nbsp;
                                <Tooltip style={{ cursor: "pointer" }}>
                                        {
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <span>
                                                    Clear the attached feeds and the LinkedIn profile associated with this stream.
                                            </span>
                                            </div>
                                        }
                                    </Tooltip>
                            </Button>
                        }
                        {canRefresh &&
                            <Button className="btn--sm" disabled={this.isRefreshing()} onClick={() => {
                                if (
                                    confirm(
                                        "This will refresh all content from the social feeds on this stream for the last 30 days. This process can take a few minutes "
                                    )
                                ) {
                                    this.setState(
                                        { refreshingFeeds: true },
                                        () =>this.refreshFeed()
                                    );
                                }
                                }}>
                                Refresh Feeds&nbsp;
                                <Tooltip style={{ cursor: "pointer" }}>
                                        {
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <span>
                                                    Click here to manually fetch content from the social feeds attached to this stream for the last 30 days. This process can take a few minutes.
                                                </span>
                                            </div>
                                        }
                                    </Tooltip>
                            </Button>
                        }
                    </div>
                </div>
                <div style={{ paddingLeft: "15px"}} >
                    <div style={{ fontSize: "12px", display: "flex" }} >
                    {
                        connectedAdminEmail &&
                        <span style={{ paddingRight: "10px" }}> Admin: {connectedAdminEmail}</span>
                    }
                    {
                        connectedAdminLiProfile.firstName && connectedAdminLiProfile.url &&
                        <span>LinkedIn Profile: <a href={connectedAdminLiProfile.url}>{connectedAdminLiProfile.firstName} {connectedAdminLiProfile.lastName}</a></span>
                    }
                    {
                        connectedAdminLiProfile.firstName && !connectedAdminLiProfile.url &&
                        <span>LinkedIn Profile: {connectedAdminLiProfile.firstName} {connectedAdminLiProfile.lastName}</span>
                    }
                    </div>
                </div>
                <div className="ibox-content">
                    <form className="form">
                        <div className="form__row">
                            <div className="form__group form__group--full">
                                <div>
                                    <select
                                        multiple={true}
                                        onChange={e => {
                                            if (sessionAdminDidConnectLi || streamHasLiFeedsAdded) {
                                                this.setState({
                                                    stream: {
                                                        ...stream,
                                                        linkedInFeeds: {
                                                            ...linkedInFeeds!,
                                                            companies: getMultiSelectValues(e.target).map(id => ({
                                                                name: linkedInCompanies.filter(
                                                                    i => i.id == id
                                                                )[0].name,
                                                                id: Number(id)
                                                            }))
                                                        }
                                                    }
                                                });
                                            }
                                        }}
                                        value={(linkedInFeeds!.companies || []).map(i => `${i.id}`)}
                                    >
                                        {((sessionAdminDidConnectLi ? linkedInCompanies : streamHasLiFeedsAdded ? linkedInFeeds.companies :
                                            []) as Array<{ id: string; name: string }>).map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {stream.linkedInFeeds?.crawlLog &&
                    <Link
                        style={{
                            paddingBottom: "15px",
                            paddingLeft: "15px",
                            textDecoration: "underline"
                        }}
                        onClick={() =>
                            this.setState({
                                showLiLogs: !this.state.showLiLogs
                            })
                        }
                    >{showLiLogs ? `Hide` : `Show`} Logs</Link>
                }
                <div className="ibox-content">
                    {stream.linkedInFeeds?.crawlLog && showLiLogs && (
                        <div id="uploadUsrErrs"className={`form__group`}>
                            <p
                                style={{
                                    maxHeight: "250px",
                                    overflow: "scroll",
                                    padding: "10px",
                                    background: "#f1f1f1"
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: stream.linkedInFeeds.crawlLog.join('<br style="margin: 0"/>')
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
