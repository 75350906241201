import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
import { expand, deepMerge } from "../../../utils/Helpers";
import { createAlert, sendNotSharedInThirtyDaysEmail, doGetNotSharedInThirtyDaysEmail } from "../../../actions";
import Spinner from "../../atoms/Spinner";
import { ConfirmModal } from "../../atoms/Modal";
import Tooltip from "../../atoms/Tooltip";
import { EmailActions } from "../../../actions/EmailActions";

interface ThirtyDaysModalProps {
    open: boolean;
    user: Mongo.clientAdmin;
    closeAction?: () => void;
    lang?: string;
    emails?: { [lang: string]: Mongo.ICustomEmail };
    cid?: string;
}
interface ThirtyDaysModalState {
    open: boolean;
    body: string;
    subject: string;
    loading: boolean;
    previewModalOpen: boolean;
    emailAddress: string;
    lang: string;
    count: number | undefined;
    unsubscribed: number | undefined;
}

export default class NotSharedInThirtyDaysModal extends Component<ThirtyDaysModalProps, ThirtyDaysModalState> {
    constructor(props: ThirtyDaysModalProps) {
        super(props);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._sendThirtyDaysEmail = this._sendThirtyDaysEmail.bind(this);
        this.state = {
            open: props.open || false,
            body: "",
            subject: "",
            loading: false,
            previewModalOpen: false,
            emailAddress: (props.user && props.user.email) || "",
            lang: props.lang || "en",
            count: undefined,
            unsubscribed: undefined
        }
    }

    _onFieldUpdate(field: string, event) {
        const value = event.target ? event.target.value : event;
        let state = {};
        if (field.indexOf(".") > -1) {
            const obj = expand(field, value); // mutate key into object with value
            const firstKey = Object.keys(obj)[0]; // get the outer most property
            const origValue = { [firstKey]: this.state[firstKey] }; // current value in state
            state = deepMerge(origValue, obj); // merge both values together and push into state
        } else {
            state[field] = value;
        }
        if (field == "lang") {
            this.setState(state, () => this.getNotSharedInThirtyDaysEmail());
        } else {
            this.setState(state);
        }
    }

    componentDidMount() {
        this.getNotSharedInThirtyDaysEmail();
    }

    componentWillReceiveProps(newProps: ThirtyDaysModalProps) {
        const state = {
            ...(newProps.open !== this.props.open ? { open: newProps.open } : null)
        };
        if (Object.keys(state).length) {
            this.setState(
                state as ThirtyDaysModalState,
                Object.keys(state).filter(i => i == "open" && state[i]).length ? this.getNotSharedInThirtyDaysEmail : undefined
            );
        }
    }

    async submit() {
        const { body, subject, loading, lang, count } = this.state;
        const { user } = this.props;
        const gid = user.groups[0];
        if (!subject) {
            createAlert("Please enter an email subject", "error");
            return;
        }
        if (!body) {
            createAlert("Please enter an email body ", "error");
            return;
        }
        if (loading || !confirm(`Are you sure you wish to send the have not shared in 30 days email to ${count} user${count != 1 ? "s" : ""}?`)) {
            return;
        }
        // send an email for each language in client
        const results = await sendNotSharedInThirtyDaysEmail(subject, body, lang, gid);
        if (results) {
            createAlert(`Without shares in the past 30 days email send to ${results.sent} user${results.sent != 1 ? "s" : ""}`, `success`);
            this.close();
        }
    }

    __validateEmail(email: string) {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(email);
    }

    async _sendThirtyDaysEmail(emailAddress: string) {
        this._onFieldUpdate("previewModalOpen", false);
        let { body, lang } = this.state;
        const { subject } = this.state;
        const { user } = this.props;

        try {
            await EmailActions.previewNotSharedInThirtyDaysEmail(user.cid || "", { email: user.email, firstName: user.name }, subject, body, lang);
            createAlert("Please check your inbox", "success");
        } catch {
            /**/
        }
    }

    async getNotSharedInThirtyDaysEmail() {
        const { open, loading, lang } = this.state;
        const { user } = this.props;
        const cid = user && user.cid ? user.cid : this.props.cid;
        const gid = user.groups[0];
        if (!open || loading) {
            return;
        }
        this.setState({ loading: true });
        const data = await doGetNotSharedInThirtyDaysEmail(lang, cid, gid);
        if (data) {
            this.setState({
                open: true,
                body: data.body,
                subject: data.subject,
                loading: false,
                count: data.count,
                unsubscribed: data.unsubsribedCount
            });
        } else {
            this.setState({ loading: false, lang: "en" });
        }
    }

    close() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
    }

    render() {
        const { open, body, subject, loading, lang, count, unsubscribed } = this.state;

        const blankEmail = subject && body ? false : true;
        return (
            <div>
                <Modal
                    open={open}
                    title="Send to all users that have not shared in the past 30 days"
                    closeAction={() => this.close()}
                    lang={lang}
                    footer={
                        loading ? (
                            undefined
                        ) : (
                                <div>
                                    <Button
                                        className="btn brandPrimary--bg  btn--sm"
                                        style={{float: "left"}}
                                        disabled={(subject == "") || (body == "")}
                                        onClick={e => {
                                                blankEmail ? e?.preventDefault() : this.setState({ previewModalOpen: true });
                                            }}>
                                                <i className={`fa fa-eye`} /> Preview
                                    </Button>
                                    {count != null && unsubscribed ? (
                                        <p style={{ display: "inline-block", paddingRight: "10px"}}>(Sending to {count.toLocaleString()} users ({unsubscribed.toLocaleString()} user{unsubscribed != 1 ? "s" : ""} have unsubscribed))</p>
                                    ) : count != null ? (
                                        <p style={{ display: "inline-block", paddingRight: "10px"}}>(Sending to {count.toLocaleString()} user{count != 1 ? "s" : ""} )</p>
                                    ) : undefined
                                    }
                                    <Button disabled={count== 0} onClick={() => this.submit()}>Send Emails</Button>
                                </div>
                            )
                    }
                >
                    {loading ? (
                        <Spinner />
                    ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "wrap"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexBasis: "100%",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <label htmlFor="instructions">Note:</label>
                                    <p>
                                        The text contained in square brackets (e.g. [name]) should not be removed. These
                                        codes are placeholders that will be replaced with user data when the email is sent.
                                </p>
                                    <p>
                                        If the subject or body text is edited in this modal, the updated text will only be
                                        sent to users who have access, have activated, and have shared at least once in the
                                        past but not shared again in the last 30 days.
                                </p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexBasis: "100%",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "40%",
                                            marginLeft: "0%",
                                            marginBottom: "10px"
                                        }}
                                        className="form__group"
                                    >
                                        <label className="form__label">
                                            Language:
                                        <Tooltip
                                                className="tooltip__maxwidth"
                                                style={{
                                                    marginLeft: "5px"
                                                }}
                                            >
                                                Emails will only be sent to users with the same language setting as this
                                                dropdown. If you would like to send this email to both English and French
                                                users, you must first select “Send Emails” with the dropdown set to English,
                                                then change the dropdown to French and select “Send Emails” again
                                        </Tooltip>
                                        </label>
                                        <div className="select__wrapper">
                                            <select onChange={e => this._onFieldUpdate("lang", e)} value={this.state.lang}>
                                                <option value="en">English</option>
                                                <option value="fr">French</option>
                                            </select>
                                        </div>
                                    </div>
                                    <label htmlFor="subject">Subject:</label>
                                    <input
                                        id="subject"
                                        type="text"
                                        value={subject}
                                        onChange={event => this.setState({ subject: event.target.value })}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexBasis: "100%",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <label htmlFor="emailBody">Email Body:</label>
                                    <textarea
                                        style={{
                                            minHeight: "250px"
                                        }}
                                        id="emailBody"
                                        value={body}
                                        onChange={event => this._onFieldUpdate("body", event.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                </Modal>
                {this.state.previewModalOpen && (
                    <ConfirmModal
                        title={"Email Preview"}
                        button={`Send`}
                        force={false}
                        open={true}
                        onConfirm={() => {
                            if (this.state.emailAddress) {
                                if (!this.__validateEmail(this.state.emailAddress)) {
                                    createAlert("Email format is invalid, please provide a proper email");
                                    return;
                                }
                                this._sendThirtyDaysEmail(this.state.emailAddress);
                            } else {
                                createAlert("Please enter email address", `error`);
                            }
                        }}
                        onCancel={() => {
                            this.setState({ previewModalOpen: false });
                        }}
                    >
                        <label className="form__label">
                            A copy of this email will be sent to the following email address.
                        </label>{" "}
                        <br />
                        <br />
                        <div
                            style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "auto" }}
                        >
                            <span style={{ marginRight: "10px", fontSize: "15px", verticalAlign: "sub" }}>
                                Email Address:{" "}
                            </span>
                            <input
                                type="text"
                                className="form__value"
                                onChange={event => this._onFieldUpdate("emailAddress", event)}
                                onFocus={event => event.target.select()}
                                value={this.state.emailAddress}
                                style={{ maxWidth: "80%" }}
                            />
                        </div>
                    </ConfirmModal>
                )}
            </div>
        );
    }


}