import { apiRequest, apiFormDataPost, apiRequestWithErrorAlert } from "../utils/Helpers";
import appConfig from "../../config/config.dev";
import { createAlert } from "./utils";
import { Search } from "../utils/Models";

export async function doValidateImageURL(url: string) {
    if (url.match(/\.(jpg|jpeg|png|gif|tiff)((\?|%3F).+)?$/i)) {
        return { valid: true };
    } else {
        const response = await apiRequest(`${appConfig.API_URL}/validateImageUrl`, `POST`, { url });
        return response;
    }
}

export async function doLoadArticle(url: string, force = false, cid?: string) {
    return apiRequest<Mongo.IAnalysedArticle & { valid: boolean; error?: string }>(
        `${appConfig.API_URL}/getArticleMeta`,
        `POST`,
        { url, force, ...(cid ? { cid } : null) }
    );
}

export async function doUpsertArticle(article: FormData, insert: boolean) {
    return apiFormDataPost(
        `${appConfig.API_URL}/${insert ? "insertArticleForClientStream" : "updateArticleForClientStream"}`,
        article
    ).then(data => {
        if (data.valid) {
            createAlert(`Article has been ${insert ? "created" : "updated"}`, `success`);
        } else {
            createAlert(data.error, `error`);
        }
        return data;
    });
}

export async function doUpdateArticleSequentialOrder(artid: string, sequentialOrder: number) {
    return apiRequest(`${appConfig.API_URL}/updateArticleSequentialOrder`, `POST`, { artid, sequentialOrder });
}

export async function doDeleteArticle(cid: string, artid: string) {
    return apiRequest(`${appConfig.API_URL}/deleteArticleForClient`, `GET`, { cid, artid });
}

export async function doGetTwitterFeedArticles(handle: string, aid: string, cid: string) {
    return getSocialFeedArticles("twitter", handle, aid, cid);
}

export async function doGetFacebookFeedArticles(handle: string, aid: string, cid: string) {
    return getSocialFeedArticles("facebook", handle, aid, cid);
}

async function getSocialFeedArticles(source: "twitter" | "facebook", handle: string, aid: string, cid: string) {
    return apiRequest<{ valid: boolean; articles: ServerTypes.Console.IArticle[] }>(
        `${appConfig.API_URL}/${source === "twitter" ? "previewTwitterFeed" : "previewFacebookFeed"}`,
        `POST`,
        {
            aid,
            cid,
            handle
        }
    )
        .then(data => (data && data.valid ? data.articles : []))
        .catch(error => [] as ServerTypes.Console.IArticle[]);
}

export async function doGetFeedArticles(url: string, lang: string, cid: string) {
    return apiRequest(`${appConfig.API_URL}/previewFeed`, `POST`, {
        url,
        lang,
        cid
    });
}

export async function doGetSeismicFeedArticles(cid: string, channel: string, lang: string, username: string) {
    try {
        return await apiRequest(`${appConfig.API_URL}/previewSeismicFeed`, `POST`, {
            cid,
            channel,
            lang,
            username
        });
    } catch (error) {
        return { error }
    }
}

export async function doGetArticlesForDomain(
    domain: string,
    since: number,
    start: number,
    lang: string,
    limit: number
) {
    const date = new Date();
    date.setDate(date.getDate() - since);
    return apiRequestWithErrorAlert(`${appConfig.API_URL}/article/indexSearch`, "POST", {
        ...Search({
            count: 1,
            isApprovalRequired: false,
            searchType: "CORP",
            globalFilters: false,
            lang,
            filters: { domains: [{ domain, tags: [], included: true }], included: true }
        }),
        start,
        since: date.toISOString(),
        limit
    });
}

export async function doGetMentionsTypeahead(search: string) {
    return apiRequest(`${appConfig.API_URL}/liMentionsTypeahead?search=${search}`, `GET`);
}

export async function doGetLinkedinCompanyPageMentions(search: string) {
    return apiRequest(`${appConfig.API_URL}/liCompanyPageMentions?search=${search}`, `GET`);
}