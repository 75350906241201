import React, { Component } from "react";
import moment from "moment";

import NumberChart from "../NumberChart";
import Pagination from "../Pagination";
import Modal from "./Modal";
import { ReportActions } from "../../../actions/ReportActions";
import Spinner from "../../atoms/Spinner";
import ViewPDFFile from "../ViewPDFFile";

import { UtilActions } from "../../../actions";

interface ITopArticles {
    Title: string;
    URL: string;
    isPDF: boolean;
    Domain: string;
    Composed: boolean;
    Terms: string;
    Clicks: number;
    Shares: number;
    Likes: number;
    Comments: number;
    Interactions: number;
}

interface IAdvancedPDFModalProps {
    closeAction: () => void;
    filters: dynamic;
    article: ITopArticles;
    client?: Mongo.IClient;
    open: boolean;
}

interface IAdvancedPDFModalState {
    open: boolean;
    metricsPage: number;
    numPages: number;
    fail: boolean;
    clientId: string;
    pdfData?: dynamic;
    pagination: { limit: number; skip: number; total: number };
}

export class AdvancedPDFModal extends Component<IAdvancedPDFModalProps, IAdvancedPDFModalState> {
    constructor(props: IAdvancedPDFModalProps) {
        super(props);
        this._fetchPdfPerformance = this._fetchPdfPerformance.bind(this);
        this.close = this.close.bind(this);
        this.state = {
            open: false,
            metricsPage: 0,
            numPages: 0,
            fail: false,
            clientId: "",
            pagination: { limit: 10, skip: 0, total: 0 }
        };
    }
    componentDidMount() {}
    componentWillReceiveProps(newProps: IAdvancedPDFModalProps) {
        if ("open" in newProps && newProps.open !== this.props.open) {
            const article = this.props.article;
            this._fetchPdfPerformance(article.URL).then(pdfData => {
                this.setState({ pdfData, open: newProps.open, pagination: { limit: 10, skip: 0, total: pdfData && pdfData.numViewPerPage.length || 0 } });
            });
        }
    }

    async _fetchPdfPerformance(link: string) {
        const filter = {
            ...this.props.filters,
            link
        };
        const result = await ReportActions.doGetPdfPerformanceReport(filter);
        const whitelabel = await UtilActions.doGetWhiteLabelSettings(this.props.client?.configName || "none");
        const clientId = whitelabel?.adobeClientId || "";
        this.setState({ clientId });
        return result;
    }

    close() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
    }

    _renderOverAllMetrics() {
        const { pdfData } = this.state;
        if (pdfData && (pdfData.totalReaders != 0 || pdfData.totalViews != 0 || pdfData.avgViewTime !== 0)) {
            return (
                <div style={{ width: "42%", justifyContent: "flex-start" }}>
                    <NumberChart
                        title="Overall Metrics "
                        data={{
                            TotalReaders: {
                                label: ["Total Readers"],
                                value: pdfData && pdfData.totalReaders ? pdfData.totalReaders : 0
                            },
                            TotalViews: {
                                label: ["Total Views"],
                                value: pdfData && pdfData.totalViews ? pdfData.totalViews : 0
                            },
                            AverageTime: {
                                label: ["Average View Time \n(mm:ss)"],
                                value:
                                    pdfData && pdfData.avgViewTime
                                    ? moment.utc(pdfData.avgViewTime * 1000).format("mm:ss") : `00:00`
                            }
                        }}
                        loading={!pdfData}
                    />
                    {this._renderPageMetrics()}
                </div>
            );
        }
        return (
            <div style={{ width: "42%", justifyContent: "flex-start" }}>
                {!pdfData && <Spinner />}
                {pdfData && (
                    <div className="text-center" style={{margin: "20px"}}>
                        There is no data to display at the moment.
                        <br />
                        <br />
                        This section will be updated when this piece of content has received more engagement.
                    </div>
                )}
            </div>
        );
    }

    _refresh(newPage = 0) {
        this.setState({ pagination: { ...this.state.pagination, skip: newPage } });
    }

    _renderPageMetrics() {
        const { pdfData } = this.state;
        if (
            pdfData &&
            pdfData.numViewPerPage &&
            pdfData.numViewPerPage.length &&
            pdfData.totalTimePerPage &&
            pdfData.totalTimePerPage.length
        ) {
            const { limit, skip } = this.state.pagination;
            return (
                <div>
                    <div className="ibox-title" style={{marginBottom: "0px"}}>
                        <h5 aria-hidden="true">Page Metrics</h5>
                    </div>
                    <div className="ibox-content">
                        <div>
                            <div className="table-responsive">
                            <table className="table table-striped responsive text-center" data-type="responsive">
                                <thead>
                                    <tr>
                                        <th className="text-center" tabIndex={0}>Page</th>
                                        <th className="text-center" tabIndex={0}>Views</th>
                                        <th className="text-center" tabIndex={0}>
                                            Average <br />
                                            View Time
                                            <br /> (mm:ss)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pdfData.numViewPerPage instanceof Array &&
                                        pdfData.numViewPerPage.slice(limit * skip, (limit * skip) + limit).map((art, i) => {
                                            return (
                                                <tr key={art.URL}>
                                                    <td tabIndex={0}>{i + 1 + limit * skip || "N/A"}</td>
                                                    <td tabIndex={0}>{pdfData.numViewPerPage[i + limit * skip] || "N/A"}</td>
                                                    <td tabIndex={0}>{pdfData.avgTimePerPage[i + limit * skip] ? moment.utc(pdfData.avgTimePerPage[i + limit * skip] * 1000).format("mm:ss") : "00:00"}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                            </div>
                            {
                            this.state.pagination.total > this.state.pagination.limit && <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        page={this.state.pagination.skip}
                                        totalItems={this.state.pagination.total}
                                        numPerPage={this.state.pagination.limit}
                                        changePage={newPage => this._refresh(newPage)}
                                        disabled={false}
                                        showTotal={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        }
        return;
    }

    render() {
        const { open } = this.state;
        const { article } = this.props;
        return (
            <Modal
                title={`Advanced Analytics`}
                className={"overall"}
                open={open}
                closeAction={this.close}
                backdrop={"static"}
            >
                <div style={{ width: "100%", display: "flex" }}>
                    {this._renderOverAllMetrics()}
                    <div style={{ width: "58%", justifyContent: "flex-end" }}>
                        <div id="pdf-div" />
                        <ViewPDFFile
                            url={article.URL}
                            clientId={this.state.clientId}
                            fileName={article.Title || article.URL}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default AdvancedPDFModal;
