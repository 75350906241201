import React, { ChangeEvent, Component } from "react";
import { connect } from "react-redux";
import { createAlert } from "../../../actions";
import Multiselect from "react-bootstrap-multiselect";
import DatePickerV2 from "../../atoms/DatePickerV2";
import { getLocalISODate } from "../../../utils/Helpers";
import { doGetReduxClients, doGetGroups } from "../../../actions/lists";
import EngagementSection from "./sections/Engagement";
import AdoptionSection from "./sections/Adoption";
import NetworksSection from "./sections/Networks";
import ArticlesSection from "./sections/Articles";
import MediaSection from "./sections/Media";
import InsightsSection from "./sections/Insights";
import LeadsSection from "./sections/OfferLeads";
import UserSection from "./sections/User";
import NotificationsSection from "./sections/Notifications";
import { SSOLogsSection, ISSOLogFilter } from "./sections/SSOLogs";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import { Wrapper, Row } from "../../atoms/Layout";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";

interface ISelectOption {
    label: string;
    value?: string;
    selected?: boolean;
    children?: ISelectOption[];
}

export interface IReportFilter {
    clientCode: string;
    ed: string;
    edtz: number;
    notifCategory: string;
    rids: string;
    sd: string;
    sdtz: number;
    sid: string;
    grp: string;
    email: string;
    userType: string;
    ssoId: string;
    ssoLog: string;
}

interface IReportsViewProps extends RouteComponentProps<{ section: string }> {
    user: Mongo.clientAdmin;
    clients: dynamic<Mongo.client>;
}

interface IReportsViewState {
    filters: IReportFilter;
    submittedFilters: IReportFilter;
    clientCode: string;
    submittedClientCode: string;
    reportSection: string;
    startDate: string;
    endDate: string;
    tomorrow: string;
    hasGroups: boolean;
    rids: string;
    sid: string;
    userType: string;
    queryLoading: boolean;
    showSearchResults: boolean;
    group: string;
    email: string;
    groupOpts: ISelectOption[] | null;
    roleOpts: ISelectOption[] | null;
    notifCategory: string;
    ssoId: string;
    ssoLog: string;
}

class ReportsView extends Component<IReportsViewProps, IReportsViewState> {
    constructor(props) {
        super(props);
        // these binds are necessary
        this.setGroupOptions = this.setGroupOptions.bind(this);
        this._changeGroups = this._changeGroups.bind(this);
        this._changeRoles = this._changeRoles.bind(this);

        let end = new Date();
        let start = new Date();
        let tomorrow = new Date();
        start.setMonth(start.getMonth() - 1);
        tomorrow.setDate(tomorrow.getDate() + 2);

        const state = JSON.parse(localStorage.getItem('reportQuery')!) || {
            startDate: moment(start).toISOString(),
            endDate: moment(end).toISOString(),
            tomorrow: moment(tomorrow).toISOString(),
            hasGroups: false,
            groupOpts: null,
            group:
                !(props.user.isClientAdmin || props.user.isSuper || props.user.isSuperClientAdmin) &&
                    props.user.groups &&
                    props.user.groups.length > 0
                    ? props.user.groups
                    : "",
            roleOpts: null,
            rids: "ALL_ROLES",
            sid: "ALL_STREAMS",
            userType: "user",
            clientCode: props.user.cid || (props.user.isSuper ? "ALL_CLIENTS" : ""),
            submittedClientCode: props.user.cid || (props.user.isSuper ? "ALL_CLIENTS" : ""),
            notifCategory: "all",
            allowChange: false,
            reportSection: (props.match.params.section || "engagement").toLowerCase(),
            showSearchResults: false,
            email: "",
            ssoId: "",
            ssoLog: ""
        };
        state.filters = this._buildQueryFilters(state);
        state.submittedFilters = state.filters;
        this.state = state;
    }

    componentDidMount() {
        if (this.state.clientCode === "ALL_CLIENTS") {
            doGetReduxClients();
        } else if (!this.state.groupOpts && this.state.clientCode) {
            doGetGroups(this.state.clientCode).then(this.setGroupOptions);
        }
        this.setRoleOptions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.clientCode !== this.state.clientCode) {
            this.setRoleOptions();
        }
    }

    setGroupOptions(groups: dynamic<string>) {
        let hasGroups = false;
        const hasAllGroups =
            this.props.user.isClientAdmin ||
            this.props.user.isSuperClientAdmin ||
            this.props.user.isSuper ||
            (this.props.user.groups || []).length == 0;
        let group = this.state.group || "";
        const groupOpts: ISelectOption[] = [];
        if (Object.keys(groups).length) {
            hasGroups = true;
            if (hasAllGroups) {
                groupOpts.push({ value: "", label: "All", selected: true });
                groupOpts.push({ value: "None", label: "No Group", selected: false });
                groupOpts.push({ value: "allGroup", label: "All Groups", selected: false });
            } else {
                let userGroups = this.props.user.groups || [];
                // Filter for groups the user has access to
                for (let gid in groups) {
                    if (userGroups.indexOf(gid) === -1) {
                        delete groups[gid];
                    }
                }
            }
            let options: ISelectOption[] = [];
            let selectedCount = 0;
            for (const gid in groups) {
                let selected = !hasAllGroups;
                options.push({ value: gid, label: groups[gid]!, selected: selected });
                selectedCount += selected ? 1 : 0;
            }
            group = options
                .filter(opt => opt.selected)
                .map(opt => opt.value)
                .join(",");
            groupOpts.push({
                label: `Select groups (${selectedCount} Selected)`,
                children: options
            });
        }
        this.setState({ groupOpts: groupOpts, group: group, hasGroups: hasGroups });
    }

    setRoleOptions() {
        const roleOpts: ISelectOption[] = [];
        if (this.state.clientCode &&
            this.state.clientCode !== "ALL_CLIENTS" &&
            Object.keys(this.props.clients?.[this.state.clientCode]?.roles || {}).length > 0 &&
            this.state.userType !== "admin" &&
            this.state.reportSection !== "emails") {
                roleOpts.push({ value: "", label: "All", selected: true });
                roleOpts.push({ value: "None", label: "No Group", selected: false });
                roleOpts.push({ value: "allGroup", label: "All Roles", selected: false });
                let options: ISelectOption[] = [];
                let selectedCount = 0;
                const roles = Object.values(this.props.clients![this.state.clientCode]!.roles);
                for (const role of roles) {
                    options.push({value: role.rid, label: role.name, selected: false})
                }
                roleOpts.push({
                    label: `Select roles (${selectedCount} Selected)`,
                    children: options
                });
            }
        this.setState({roleOpts: roleOpts});
    }

    componentWillReceiveProps(newProps: IReportsViewProps) {
        if (newProps.match.params.section != this.state.reportSection) {
            const newSection = newProps.match.params.section || "engagement";
            const filters = this._buildQueryFilters({ ...this.state, reportSection: newSection });
            this.setState({ filters, submittedFilters: filters, reportSection: newSection.toLowerCase(), showSearchResults: false, queryLoading: false });
        }
    }

    _onFieldChange(field: string, val) {
        if (field == "clientCode") {
            doGetGroups(val).then(this.setGroupOptions);
        }
        const value = val && val.target ? val.target.value : val;
        const filters = this._buildQueryFilters({ ...this.state, [field]: value });
        if (field == "clientCode") {
            this.setState({
                filters,
                [field]: value,
                "submittedClientCode": value
            } as unknown as IReportsViewState);
        } else {
            this.setState({
                filters,
                [field]: value
            } as unknown as IReportsViewState);
        }
    }

    async _submit() {
        await this.setState({
            queryLoading: this.state.filters !== this.state.submittedFilters || !this.state.showSearchResults,
            submittedFilters: this.state.filters,
            submittedClientCode: this.state.clientCode,
            showSearchResults: true,
        }, () => localStorage.setItem('reportQuery', JSON.stringify(this.state)));
        createAlert('Search results updated', 'success');
    }

    async _clearFilters() {
        const end = new Date();
        const start = new Date();
        const tomorrow = new Date();
        start.setMonth(start.getMonth() - 1);
        tomorrow.setDate(tomorrow.getDate() + 2);

        const state = {
            startDate:  moment(start).toISOString(),
            endDate:  moment(end).toISOString(),
            tomorrow:  moment(tomorrow).toISOString(),
            hasGroups: false,
            groupOpts: null,
            roleOpts: null,
            group:
                !(this.props.user.isClientAdmin || this.props.user.isSuper || this.props.user.isSuperClientAdmin)
                    ? (this.props.user.groups || []).join(",")
                    : "",
            rids: "ALL_ROLES",
            sid: "ALL_STREAMS",
            userType: "user",
            clientCode: this.props.user.cid || (this.props.user.isSuper ? "ALL_CLIENTS" : ""),
            submittedClientCode: this.props.user.cid || (this.props.user.isSuper ? "ALL_CLIENTS" : ""),
            notifCategory: "all",
            showSearchResults: false,
            email: ""
        } as IReportsViewState;
        state.filters = this._buildQueryFilters(state);
        state.submittedFilters = state.filters;

        await this.setState(state, () => localStorage.removeItem('reportQuery'));

        // get the clients and groups
        if (this.state.clientCode === "ALL_CLIENTS") {
            doGetReduxClients();
        } else if (!this.state.groupOpts && this.state.clientCode) {
            doGetGroups(this.state.clientCode).then(this.setGroupOptions);
        }
        this.setRoleOptions();
        createAlert('Filters have been cleared', 'success');
    }

    _buildQueryFilters(props: IReportsViewState) {
        const queryFilters = {} as IReportFilter;
        if (props.startDate) {
            queryFilters.sd = props.startDate.substr(0, 10);
            // Important to and Thh:mm:ss.SSS since without the time specified it will assume UTC midnight instead of local
            queryFilters.sdtz = new Date(queryFilters["sd"] + "T00:00:00.000").getTimezoneOffset();
        }
        if (props.endDate) {
            queryFilters.ed = props.endDate.substr(0, 10);
            // Important to set time to end of local day since days on timezone transitions will have different timezone at end of day vs beginning
            queryFilters.edtz = new Date(queryFilters["ed"] + "T23:59:59.999").getTimezoneOffset();
        }
        if (props.clientCode) {
            queryFilters.clientCode = props.clientCode;
        }
        if (props.group) {
            queryFilters.grp = props.group;
        }
        if (props.rids) {
            queryFilters.rids = props.rids;
        }
        if (props.reportSection === "articles" || props.reportSection === "media") {
            if (props.sid) {
                queryFilters.sid = props.sid;
            }
        }
        if (props.email) {
            queryFilters.email = props.email;
        }
        if (props.notifCategory) {
            queryFilters.notifCategory = props.notifCategory;
        }
        if (props.reportSection === "notifications" && props.userType) {
            queryFilters.userType = props.userType;
        }
        if (props.reportSection === "ssologs" && props.ssoId) {
            queryFilters.ssoId = props.ssoId;
        }
        if (props.reportSection === "ssologs" && props.ssoLog) {
            queryFilters.ssoLog = props.ssoLog;
        }

        return queryFilters;
    }

    _changeGroups(event: ChangeEvent) {
        const groupOpts = JSON.parse(JSON.stringify(this.state.groupOpts!));

        const allOpt = groupOpts[0].value === "" ? groupOpts[0] : null;
        const noOpt = groupOpts[1] && groupOpts[1].value === "None" ? groupOpts[1] : null;
        const allGroups = groupOpts[2] && groupOpts[2].value === "allGroup" ? groupOpts[2] : null;
        const optionGroup = groupOpts.find(opt => opt.children)! as Require<ISelectOption, "children">;

        let selectedCount = optionGroup ? optionGroup.children.filter(opt => opt.selected).length : 0;
        // Deselect 'ALL' if it wasn't clicked otherwise always set (no toggle)
        if (allOpt) allOpt.selected = event[0].value === "";
        if (noOpt) noOpt.selected = event[0].value === "None";
        if (allGroups) allGroups.selected = event[0].value === "allGroup";
        // If ALL selected then nothing else should be selected
        if (allOpt && allOpt.selected) {
            // ALL
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (noOpt && noOpt.selected) {
            // No Group
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (allGroups && allGroups.selected) {
            // All Groups
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = true;
            }
            selectedCount = optionGroup.children.length;
        } else {
            optionGroup.children.find(opt => opt.value === event[0].value)!.selected = event[0].selected;
            selectedCount += event[0].selected ? 1 : -1;
            if (allGroups && selectedCount === optionGroup.children.length) allGroups.selected = true;
        }
        // If nothing selected then ALL should be selected (toggles ALL back on if someone deselected it)
        if (selectedCount === 0) {
            if (allOpt && allOpt.selected) {
                allOpt.selected = true;
            } else if (noOpt && noOpt.selected) {
                allOpt!.selected = false;
                noOpt.selected = true;
                allGroups!.selected = false;
            } else {
                optionGroup.children[0].selected = true;
                selectedCount = 1;
            }
        }
        optionGroup.label = `Select groups (${selectedCount} Selected)`;
        let group = optionGroup.children
            .filter(opt => opt.selected)
            .map(opt => opt.value)
            .join(",");
        if (!group) {
            if (noOpt && noOpt.selected) {
                group = "none";
            }
        }
        const filters = this._buildQueryFilters({ ...this.state, group: group });
        this.setState({ groupOpts, group, filters });
    }

    _changeRoles(event: ChangeEvent) {
        const roleOpts = JSON.parse(JSON.stringify(this.state.roleOpts!));
        const allOpt = roleOpts[0].value === "" ? roleOpts[0] : null;
        const noOpt = roleOpts[1] && roleOpts[1].value === "None" ? roleOpts[1] : null;
        const allGroups = roleOpts[2] && roleOpts[2].value === "allGroup" ? roleOpts[2] : null;
        const optionGroup = roleOpts.find(opt => opt.children)! as Require<ISelectOption, "children">;

        let selectedCount = optionGroup ? optionGroup.children.filter(opt => opt.selected).length : 0;
        // Deselect 'ALL' if it wasn't clicked otherwise always set (no toggle)
        if (allOpt) allOpt.selected = event[0].value === "";
        if (noOpt) noOpt.selected = event[0].value === "None";
        if (allGroups) allGroups.selected = event[0].value === "allGroup";
        // If ALL selected then nothing else should be selected
        if (allOpt && allOpt.selected) {
            // ALL
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (noOpt && noOpt.selected) {
            // No Group
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = false;
            }
            selectedCount = 0;
        } else if (allGroups && allGroups.selected) {
            // All Groups
            for (let g = 0; g < optionGroup.children.length; g++) {
                optionGroup.children[g].selected = true;
            }
            selectedCount = optionGroup.children.length;
        } else {
            optionGroup.children.find(opt => opt.value === event[0].value)!.selected = event[0].selected;
            selectedCount += event[0].selected ? 1 : -1;
            if (allGroups && selectedCount === optionGroup.children.length) allGroups.selected = true;
        }
        // If nothing selected then ALL should be selected (toggles ALL back on if someone deselected it)
        if (selectedCount === 0) {
            if (allOpt && allOpt.selected) {
                allOpt.selected = true;
            } else if (noOpt && noOpt.selected) {
                allOpt!.selected = false;
                noOpt.selected = true;
                allGroups!.selected = false;
            } else {
                optionGroup.children[0].selected = true;
                selectedCount = 1;
            }
        }
        optionGroup.label = `Select roles (${selectedCount} Selected)`;
        let rids = optionGroup.children
            .filter(opt => opt.selected)
            .map(opt => opt.value)
            .join(",");
        if (!rids) {
            if (noOpt && noOpt.selected) {
                rids = "none";
            }
        }
        const filters = this._buildQueryFilters({ ...this.state, rids: rids });
        this.setState({ roleOpts, rids, filters });
    }

    render() {
        const clients = Object.values(this.props.clients) as Mongo.client[];
        const adminTags = this.props.user.tags;
        // pick out the streams only if a client is selected (superdev)
        const availableStreams = this.state.clientCode && this.state.clientCode !== "ALL_CLIENTS" && this.state.clientCode !== "ALL_CONSUMERS"
            && this.props.clients && this.props.clients[this.state.clientCode]
            ? Object.values(this.props.clients[this.state.clientCode]!.streams)
        .filter(stream => {
            // super (client) admin gets everything
            // adminTags should match tags defined in stream
            // adminTags is empty should only match streams that has no tags
            // stream.tags is empty = no restrictions on the stream
            return stream.isCustom && (this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin || (!stream.tags?.length || stream.tags?.some(tag => {
                return adminTags?.includes(tag);
            })));
        }).sort((a, b) => {
            return a.title.localeCompare(b.title);
        }) : [];
        return (
            <Wrapper>
                <Row style={{ position: "relative" }}>
                    <div
                        className={
                            !this.props.user.isSuper && this.state.reportSection == "leads" ? "col-lg-9" : "col-lg-12" + "gray-bg"
                        }
                    >
                        <div className="form-group">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    marginBottom: "10px"
                                }}
                            >
                                <div className="date__wrapper">
                                    <label className="control-label" htmlFor="startDate">Start Date</label>
                                    <DatePickerV2
                                        dateFormat="yyyy/MM/dd"
                                        className="form-control"
                                        maxDate={this.state.endDate}
                                        value={this.state.startDate}
                                        id="startDate"
                                        onChange={value => {
                                            if (!value) {
                                                let start = new Date();
                                                start.setMonth(start.getMonth() - 1);
                                                value = `${getLocalISODate(start)}T00:00:00.000Z`;
                                            }
                                            this._onFieldChange("startDate", value);
                                        }}
                                        disabled={this.state.queryLoading}
                                        enableTabLoop={false}
                                    />
                                </div>
                                <div
                                    className="date__wrapper"
                                    style={{
                                        marginLeft: "10px"
                                    }}
                                >
                                    <label className="control-label" htmlFor="endDate">End Date</label>
                                    <DatePickerV2
                                        dateFormat="yyyy/MM/dd"
                                        className="form-control"
                                        maxDate={this.state.tomorrow}
                                        minDate={this.state.startDate}
                                        value={this.state.endDate}
                                        id="endDate"
                                        onChange={value => {
                                            if (!value) {
                                                let end = new Date();
                                                value = `${getLocalISODate(end)}T00:00:00.000Z`;
                                            }
                                            this._onFieldChange("endDate", value);
                                        }}
                                        disabled={this.state.queryLoading}
                                        enableTabLoop={false}
                                    />
                                </div>
                                {this.props.user.isSuper && (
                                    <div
                                        style={{
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <label className="control-label">Client</label>
                                        <div
                                            className="select__wrapper"
                                            style={{
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <select
                                                id="clients"
                                                value={this.state.clientCode}
                                                onChange={event =>
                                                    this._onFieldChange("clientCode", event.target.value)
                                                }
                                                disabled={this.state.queryLoading}
                                            >
                                                <option value="ALL_CLIENTS">All Clients</option>
                                                {this.state.reportSection !== "notifications" && (
                                                    <option value="ALL_CONSUMERS">All Consumers</option>
                                                )}
                                                <option disabled={true}>-----------</option>
                                                {clients &&
                                                    clients.map(
                                                        (client, i) => {
                                                            return (
                                                                <option key={client.cid} value={client.cid}>
                                                                    {client.name}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                            </select>
                                        </div>
                                    </div>
                                )}
                                {this.state.hasGroups && this.state.reportSection !== "ssologs" && (
                                    <div
                                        style={{
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <label className="control-label">Groups</label>
                                        <div
                                            className="date_multiselect_wrapper"
                                            style={{
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <Multiselect
                                                data={this.state.groupOpts}
                                                maxHeight={200}
                                                multiple="multiple"
                                                onChange={this._changeGroups}
                                                disabled={this.state.queryLoading}
                                                tabIndex={0}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.clientCode &&
                                    this.state.clientCode !== "ALL_CLIENTS" &&
                                    Object.keys(this.props.clients?.[this.state.clientCode]?.roles || {}).length > 0 &&
                                    this.state.userType !== "admin" &&
                                    this.state.reportSection !== "emails" &&
                                    this.state.reportSection !== "ssologs" && (
                                        <div
                                            style={{
                                                marginLeft: "10px"
                                            }}
                                        >
                                            <label className="control-label">Role</label>
                                            <div
                                                className="date_multiselect_wrapper"
                                                style={{
                                                    marginLeft: "0px"
                                                }}
                                            >
                                                <Multiselect
                                                    data={this.state.roleOpts}
                                                    maxHeight={200}
                                                    multiple="multiple"
                                                    onChange={this._changeRoles}
                                                    disabled={this.state.queryLoading}
                                                    tabIndex={0}
                                                />
                                            </div>
                                        </div>
                                    )}
                                {this.state.clientCode &&
                                    this.state.clientCode !== "ALL_CLIENTS" &&
                                    Object.keys(this.props.clients?.[this.state.clientCode]?.streams || {}).length > 0 &&
                                    (this.state.reportSection === "articles" || this.state.reportSection === "media") && (
                                        <div
                                            style={{
                                                marginLeft: "10px"
                                            }}
                                        >
                                            <label className="control-label">Stream</label>
                                            <div
                                                className="select__wrapper"
                                                style={{
                                                    marginLeft: "0px"
                                                }}
                                            >
                                                <select
                                                    onChange={e => this._onFieldChange("sid", e.target.value)}
                                                    value={this.state.sid}
                                                    aria-label="Select  Stream"
                                                >
                                                    <option value="ALL_STREAMS">All</option>
                                                    <option value="NO_STREAM">No stream</option>
                                                    <option disabled={true}>-----------</option>
                                                    {

                                                    availableStreams
                                                    .map(
                                                        stream => <option key={stream.sid} value={stream.sid}>
                                                            {stream.title}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                {this.state.reportSection === "notifications" && (
                                    <div
                                        style={{
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <label className="control-label">User Type</label>
                                        <div
                                            className="select__wrapper"
                                            style={{
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <select
                                                value={this.state.userType}
                                                aria-label={this.state.userType}
                                                onChange={event =>
                                                    this._onFieldChange("userType", event.target.value)
                                                }
                                                disabled={this.state.queryLoading}
                                            >
                                                <option value="user">User</option>
                                                <option value="admin">Admin</option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                                {this.state.reportSection === "notifications" && (
                                    <div
                                        style={{
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <label className="control-label">Category</label>
                                        <div
                                            className="select__wrapper"
                                            style={{
                                                marginLeft: "0px"
                                            }}
                                        >
                                            <select
                                                value={this.state.userType == "admin" ? "email" : this.state.notifCategory}
                                                aria-label={this.state.userType == "admin" ? "email" : this.state.notifCategory}
                                                onChange={event =>
                                                    this._onFieldChange("notifCategory", event.target.value)
                                                }
                                                disabled={this.state.queryLoading || this.state.userType == "admin"}
                                            >
                                                <option value="all">All</option>
                                                <option value="email">Email</option>
                                                <option value="push">Push</option>
                                                <option value="system">System</option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                                {this.state.reportSection === "notifications" && (
                                    <div className="table__filters__option">
                                        <label className="control-label">Search:</label>
                                        <div className="search">
                                            <input
                                                className="admins__search"
                                                style={{ height: "38px" }}
                                                type="text"
                                                placeholder="Search by email"
                                                defaultValue={this.state.email}
                                                onChange={event => this._onFieldChange("email", event.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.reportSection === "ssologs" && (
                                    <div className="table__filters__option">
                                        <label className="control-label">Search SSO ID:</label>
                                        <div className="search">
                                            <input
                                                className="admins__search"
                                                style={{ height: "38px" }}
                                                type="text"
                                                placeholder="Search by SSO ID"
                                                defaultValue={this.state.ssoId}
                                                onChange={event => this._onFieldChange("ssoId", event.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.reportSection === "ssologs" && (
                                    <div className="table__filters__option">
                                        <label className="control-label">Search Logs:</label>
                                        <div className="search">
                                            <input
                                                className="admins__search"
                                                style={{ height: "38px" }}
                                                type="text"
                                                placeholder="Search by Logs"
                                                defaultValue={this.state.ssoLog}
                                                onChange={event => this._onFieldChange("ssoLog", event.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div style={{ marginLeft: "10px" }}>
                                    <label className="" />
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            onClick={() => this._submit()}
                                            style={{ marginLeft: "auto", marginRight: "20px", marginTop: "5px" }}
                                            disabled={this.state.queryLoading}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <label className="" />
                                    <div style={{ display: "flex" }}>
                                        <Link style={{ textDecoration: "underline", color: "#152733" }}
                                            onClick={()=> this._clearFilters()}
                                        >Clear Filter</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this._renderReportSection()}
                </Row>
            </Wrapper>
        );
    }

    _renderReportSection() {
        const { reportSection, submittedFilters, showSearchResults } = this.state;
        const client = this.state.submittedClientCode ? this.props.clients[this.state.submittedClientCode] : undefined;
        if (reportSection == "engagement") {
            return (
                <EngagementSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                />
            );
        } else if (reportSection == "insights") {
            return (
                <InsightsSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                />
            );
        } else if (reportSection == "networks") {
            return (
                <NetworksSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                />
            );
        } else if (reportSection == "adoption") {
            return (
                <AdoptionSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                />
            );
        } else if (reportSection == "articles") {
            return (
                <ArticlesSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                />
            );
        } else if (reportSection == "media") {
            return (
                <MediaSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    user={this.props.user}
                    showSearchResults={showSearchResults}
                />
            );
        } else if (
            reportSection == "user") {
            return (
                <UserSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                />
            );
        } else if (reportSection == "notifications") {
            return (
                <NotificationsSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                />
            );
        } else if (reportSection == "ssologs") {
            return (
                <SSOLogsSection
                    filters={submittedFilters as IReportFilter & ISSOLogFilter }
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                    user={this.props.user}
                />
            );
        } else if (reportSection == "leads") {
            return (
                <LeadsSection
                    filters={submittedFilters}
                    onLoaded={() => this.setState({ queryLoading: false })}
                    client={client}
                    showSearchResults={showSearchResults}
                    user={this.props.user}
                />
            );
        } else {
            return (
                <Wrapper>
                    <Row style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap" }}>
                        <h4>You do not have permission to access this page</h4>
                    </Row>
                </Wrapper>
            );
        }
    }
}

const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients });

export default connect(mapStateToProps)(ReportsView);
