import appConfig from "../../../../config/config.dev";
import React, { Component } from "react";
import { connect } from "react-redux";
import { apiRequest, getLocaleDate, stripGV6Query } from "../../../utils/Helpers";
import Modal from "react-bootstrap-modal";
import PageHeading from "../../molecules/PageHeading";

import Spinner from "../../atoms/Spinner";
import { doGetReduxClients, doGetArticles, doGetReduxStreams, doGetArticle } from "../../../actions/lists";
import { doPreviewLibrary } from "../../../actions/";
import { createAlert } from "../../../actions/utils";

import ArticleCard from "../../molecules/ArticleCard";
import StreamPreview from "../../molecules/modals/StreamPreview";
import BlockedContentModal from "../../molecules/modals/BlockedContentModal";
import {
    getFriendlyDate,
    getAdminDate,
    getLocalISODate,
    getComplianceMessage,
    dateToUTCISOString,
    downloadFile as fileDownload
} from "../../../utils/Helpers";
import Link from "../../atoms/Link";
import AddArticleStack from "../../molecules/modals/stacks/AddArticle";
import Tooltip from "../../atoms/Tooltip";
import debounce from "lodash/debounce";
import DatePickerV2 from "../../atoms/DatePickerV2";
import Pagination from "../../molecules/Pagination";
import Button from "../../atoms/Button";
import { Wrapper, Row } from "../../atoms/Layout/";
import { history } from "../../../utils/store";
import { withLastLocation } from 'react-router-last-location';
import Toggle from "../../atoms/Toggle";

var querystring = require("querystring");
class Library extends Component {
    constructor(props) {
        super(props);
        this._getArticles = this._getArticles.bind(this);
        this._renderTableView = this._renderTableView.bind(this);
        this._renderCardView = this._renderCardView.bind(this);
        this._onFilterChange = this._onFilterChange.bind(this);
        this._editArticle = this._editArticle.bind(this);
        this.stripDuplicates = this.stripDuplicates.bind(this);
        this._closeAddArticle = this._closeAddArticle.bind(this);
        this._deleteAction = this._deleteAction.bind(this);
        this._getClientName = this._getClientName.bind(this);
        this._getStreamName = this._getStreamName.bind(this);
        this._renderSuggestedModal = this._renderSuggestedModal.bind(this);
        this._resetSuggested = this._resetSuggested.bind(this);
        this._previewLibrary = this._previewLibrary.bind(this);

        this.debounce = debounce(this._getArticles, 1000);
        this._createAlert = debounce((message, type) => {
            createAlert(message, type);
        }, 500);
        this._download = debounce(this._download, 1000);

        let status = props.match.params.status;
        let artid = props.match.params.artid;
        if (
            status != "approved" &&
            status != "new" &&
            status != "pending" &&
            status != "rejected" &&
            status != "declined" &&
            status != "suggested"
        ) {
            status = "";
        }

        let start = new Date();
        start.setMonth(start.getMonth() - 1);

        this.baseState = {
            client: null,
            sid: "",
            streams: null,
            articles: [],
            view: "card",
            type: "",
            sharingPerm: "",
            sortBy: "date",
            currentPage: 1,
            approvedFilterStatus: status,
            searchQuery: "",
            suggestedModalOpen: false,
            suggestedModal: artid || "",
            startDate: `${getLocalISODate(start)}`,
            endDate: "",
            per_page: 20,
            page: 0,
            total: 0,
            last_page: false,
            downloading: false,
            skip: null,
            advFilters: false,
            canPreview: true,
            preview: false,
            hiddencontentPreview: false,
            sectionView: null,
            isTrending: false,
            includeExpiredContent: false
        };
        this.state = { ...this.baseState };
    }

    _getSearch() {
        const { sid, streams } = this.state;
        if (sid) {
            return streams[sid];
        }
        return null;
    }

    _getClientName(cid) {
        const { clients } = this.props;
        if (cid) {
            if (clients && clients[cid]) {
                return clients[cid].name;
            }
        }
        return "";
    }
    _getStreamName(cid, sid) {
        const { clients } = this.props;

        if (cid && sid) {
            if (clients && clients[cid] && clients[cid].streams && clients[cid].streams[sid]) {
                return clients[cid].streams[sid].title;
            }
        }
        return "";
    }
    _formatTerms() {
        const { sid } = this.state;
        let terms = [];
        let search = this._getSearch(sid);
        if (search && search.terms) {
            search.terms.map(function (term, i) {
                terms.push({ value: term.term, selected: term.checked });
            });
        }
        return terms;
    }
    componentDidMount() {
        if (this.props.lastLocation && this.props.lastLocation.pathname && this.props.lastLocation.pathname.indexOf("content") === -1) {
            localStorage.removeItem("libraryPage");
            localStorage.removeItem("contentSearchPage");
            this.setState(this.baseState);
        }
        const previousState = localStorage.getItem("libraryPage");
        if (previousState) {
            this.setState({ ...JSON.parse(previousState) });
        }
        if (!this.props.user.isSuper) {
            doGetReduxStreams(this.props.user.cid);
            if (this.state.suggestedModal) {
                doGetArticle(this.props.user.cid, this.state.suggestedModal).then(data => {
                    if (data.artid) {
                        this.setState({ suggestedModalOpen: true, suggestedModalArticle: data });
                    }
                });
            }
        }
        doGetReduxClients(true);
        setTimeout(() => {
            this._getArticles();
        }, 0);
    }
    _deleteAction() {
        createAlert(`Article Deleted`, `success`);
        this._getArticles();
    }
    componentWillReceiveProps(newProps) {
        let newState = {};
        if (newProps.user.cid && newProps.clients[newProps.user.cid]) {
            newState["client"] = newProps.clients[newProps.user.cid];
        }
        if ((!this.state.streams || Object.keys(this.state.streams).length == 0) && newProps.streams) {
            newState["streams"] = newProps.streams;
        }
        if (this.props.match.params.status != newProps.match.params.status) {
            let status = newProps.match.params.status;
            if (
                status != "approved" &&
                status != "new" &&
                status != "pending" &&
                status != "rejected" &&
                status != "declined" &&
                status != "suggested"
            ) {
                status = "";
            }
            newState["approvedFilterStatus"] = status;
        }

        let isSuggested = false;
        if (this.props.match.params.artid != newProps.match.params.artid) {
            let artid = newProps.match.params.artid;
            isSuggested = true;
            doGetArticle(this.props.user.cid, artid).then(data => {
                if (data.artid) {
                    this.setState(
                        Object.assign(newState, {
                            suggestedModalOpen: true,
                            suggestedModalArticle: data,
                            suggestedModal: artid
                        })
                    );
                }
            });
        }
        if (Object.keys(newState).length > 0 && !isSuggested) {
            this.setState(newState);
        }
    }
    stripDuplicates(articles, allArticles) {
        let retArticles = {};

        allArticles.forEach(function (a) {
            retArticles[a.artid] = a;
        });

        articles.forEach(function (a) {
            retArticles[a.artid] = a;
        });

        return Object.values(retArticles);
    }
    _getArticles() {
        const { client, sid, approvedFilterStatus, per_page, page, searchQuery, startDate, endDate, skip, type, sharingPerm, sortBy, streams, includeExpiredContent } = this.state;
        let self = this;

        if (this.state.streams && this.state.streams[sid] && this.state.streams[sid].type === "CORPORATE") {
            this.setState({ type: this.state.streams[sid].type });
        };

        this.setState(
            {
                articlesLoading: true
            },
            () => {
                doGetArticles(
                    (client || { cid: this.props.user.cid }).cid,
                    sid,
                    approvedFilterStatus,
                    skip && skip > 0 ? skip : page * per_page, // Use skip parameter if it's an index search
                    per_page,
                    searchQuery,
                    type,
                    sharingPerm,
                    sortBy,
                    getLocalISODate(new Date(startDate)),
                    getLocalISODate(new Date(endDate)),
                    true,
                    includeExpiredContent,
                    (this.state.streams && this.state.streams[sid] && this.state.streams[sid].type === "CORPORATE" ? this.state.streams[sid] : null)
                ).then(data => {
                    let state = {
                        articlesLoading: false
                    };
                    if (data) {
                        // filters articles by sortBy
                        if (sortBy == "date") {
                            data.items.sort((article1, article2) => new Date(article2.activeDate).getTime() - new Date(article1.activeDate).getTime())
                        } else {
                            data.items.sort((article1, article2) => article2.statistics[sortBy] - article1.statistics[sortBy]);
                        }

                        let articles = data.items;
                        let total = data.count;

                        // filter for sharingPerm here so articles w/o specific sharingPermissions can use stream defaultSharingPermissions
                        if (sharingPerm) {
                            articles.forEach(article => {
                                let artSid = article.sid
                                if (!article.sharingPermissions) {
                                    article.sharingPermissions = client.streams[artSid].defaultSharingPermissions
                                }
                            });
                            if (sharingPerm == "readOnly") {
                                articles = articles.filter((article) => {
                                    return (
                                        !article.sharingPermissions.email &&
                                        !article.sharingPermissions.sms &&
                                        !article.sharingPermissions.social
                                    )
                                });
                            } else {
                                articles = articles.filter(article => article.sharingPermissions[sharingPerm]);
                            }
                            total = articles.length;
                        };
                        state.articles = articles;
                        state.last_page = articles.length != per_page;
                        state.total = total;
                        if (data.skip && data.skip > 0) state.skip = data.skip; // Client with indexed library enabled will return an additional skip paramter
                        self.setState(state, () => {
                            localStorage.setItem("libraryPage", JSON.stringify(this.state));
                        });
                    }
                })
            }
        );
    }
    async _previewLibrary(startDate) {
        const { client } = this.state;
        let date = startDate;
        if (client.allowSectionView) {
            date = new Date();
            date.setDate(date.getDate() - 7);
        }

        const data = await doPreviewLibrary(client.cid, client.streams, getLocalISODate(new Date(date)));

        return data && data.valid ? data.articles : [];

    }
    _buildQuery() {
        const { type, sharingPerm } = this.state;
        return {
            ...(type == "pdf" || type == "article" ? { shareFormat: type } : null),
            ...(type == "article" ? { shareArticleFormat: true } : null),
            ...(type == "video" ? { video: true } : null),
            ...(type == "image" ? { shareImageFormat: true } : null),
            ...(type == "text" ? { shareTextFormat: true } : null),
            ...(sharingPerm == "social" ? { sharingPermissionsSOCIAL: true } : null),
            ...(sharingPerm == "email" ? { sharingPermissionsEMAIL: true } : null),
            ...(sharingPerm == "sms" ? { sharingPermissionsSMS: true } : null),
            ...(sharingPerm == "readOnly" ? { sharingPermissionsSOCIAL: false, sharingPermissionsEMAIL: false, sharingPermissionsSMS: false } : null)
        };
    }
    _download() {
        const { client, approvedFilterStatus, searchQuery, startDate, endDate, type, sharingPerm, streams } = this.state;
        this.setState({ downloading: true });

        var { sid } = this.state;
        if (!sid) {
            var streamsArr = streams ? Object.values(streams) : [];
            streamsArr = streamsArr.filter(function (str, i) {
                return (str.active && ((str.contentSearch && str.isCustom) || (!str.contentSearch && str.isApprovalRequired && str.searchType != "CORP")));
            });
            sid = streamsArr.map(function (str, i) {
                return str.sid
            });
        }

        const query = {
            ...(client ? { cid: client.cid } : null),
            ...(sid ? { sid } : null),
            ...(approvedFilterStatus ? { status: approvedFilterStatus.split(",") } : null),
            ...(searchQuery ? { searchTerm: searchQuery } : null),
            ...(startDate ? { startDate: getLocalISODate(new Date(startDate)) } : null),
            ...(endDate ? { endDate: getLocalISODate(new Date(endDate)) } : null),
            ...((type || sharingPerm) ? this._buildQuery() : null),
            ...(client && client.allowContentSpecificPermissions ? { sharingPerm: true } : { sharingPerm: false }),
            ...(this.props.user.isSuper ? { isSuperAdmin: true } : { isSuperAdmin: false })
        };
        apiRequest(`${appConfig.API_URL}/article/csv/export?${querystring.stringify(query)}`).then(data => {
            this.setState({ downloading: false });
            fileDownload(data, `library-${getLocaleDate(new Date())}.csv`);
        });
    }

    _onFilterChange(fieldName, event, shouldDebounce = false) {
        let val = event.target ? event.target.value : event;
        if (fieldName == "cid") {
            fieldName = "client";
            val = (this.props.clients || {})[val];
        }
        let newState = {
            [fieldName]: val,
            skip: null,
            type: this.state.streams[val] && this.state.streams[val].type
        };
        if (fieldName == "type") {
            fieldName = "type";
            newState[fieldName] = val;
        };
        if (fieldName != "page") newState.page = 0;
        if (fieldName == "sid") {
            this.setState(
                {
                    isTrending: this.state.streams[val]?.type == "TRENDING"
                }
            );
        }
        this.setState(newState, () => {
            if (shouldDebounce) {
                this.debounce();
                this._createAlert('Search filter updated', 'success');
            } else {
                this._getArticles();
                this._createAlert('Search filter updated', 'success');
            }
        });
    }
    _closeAddArticle() {
        this.setState(
            {
                addArticle: false
            },
            this._getArticles
        );
    }
    _resetSuggested() {
        this.setState(
            { suggestedModalOpen: false, suggestedModalArticle: null, suggestedModal: "" },
            this._getArticles
        );
    }
    _checkSharingSettings() {
        const { client } = this.state;
        if (
            client.socialNetworks.LinkedIn.share ||
            client.socialNetworks.LinkedIn.companyShare ||
            client.socialNetworks.Twitter.share ||
            client.socialNetworks.Twitter.companyShare ||
            client.socialNetworks.Facebook.companyShare
        ) {
            return true;
        } else {
            return false;
        }
    }
    _showPreview() {
        if (this.props.clients[this.props.user.cid] || this.state.client) {
            return true;
        }
        return null;
    }
    _showAdminAdvFilters() {
        const { user } = this.props;
        if (user.isSuper || user.isSuperClientAdmin || user.isClientAdmin || user.isCurator) {
            return true;
        } else {
            return false;
        }
    }
    _clearFilters() {
        if (localStorage.hasOwnProperty("libraryPage")) {
            localStorage.removeItem("libraryPage");
        };
        this.setState({ ...this.baseState }, () => { doGetReduxClients(true), this._getArticles() })
        createAlert('Filters have been cleared', 'success')
    }
    _renderSuggestedModal() {
        if (this.state.suggestedModalOpen) {
            return (
                <Modal show={this.state.suggestedModalOpen} onHide={() => this._resetSuggested()}>
                    <Modal.Body>
                        <ArticleCard
                            key={this.state.suggestedModalArticle.artid}
                            article={this.state.suggestedModalArticle}
                            stream={this.state.streams[this.state.suggestedModalArticle.sid]}
                            client={this.props.clients[this.state.suggestedModalArticle.cid]}
                            canEdit={false}
                            editAction={this._resetSuggested}
                        />
                    </Modal.Body>
                </Modal>
            );
        }
        return;
    }
    _renderAdvancedFilters() {
        const headingStyle = {
            maxWidth: '100px'
        }
        const {
            client,
            advFilters,
            view,
            type,
            sharingPerm,
            sortBy,
            page,
            currentPage
        } = this.state;

        if (!advFilters) {
            return;
        }
        return (
            <div>
                <div className="table__filters__option">
                    <label>Display View:</label>
                    <div className="select__wrapper">
                        <select
                            className="filters__search"
                            value={view}
                            onChange={event => this._onFilterChange("view", event)}
                        >
                            <option value="card">Article Card</option>
                            <option value="table">Condensed</option>
                        </select>
                    </div>
                </div>
                {(this.props.user.isSuper ||
                    (client && (client.allowPdfCuration || client.allowFrontendPdfCompose || client.allowCustomEdit))) &&
                    (<div className="table__filters__option">
                        <label>Type:</label>
                        <div className="select__wrapper">
                            <select
                                className="filters__search"
                                value={type}
                                onChange={event => this._onFilterChange("type", event)}
                            >
                                <option value="">All</option>
                                <option value="article">Article</option>
                                {(this.props.user.isSuper || (client && (client.allowPdfCuration || client.allowFrontendPdfCompose))) && (<option value="pdf">PDF</option>)}
                                {(this.props.user.isSuper || client && client.allowCustomEdit) && [<option value="image">Image/GIF</option>,
                                <option value="video">Video</option>,
                                <option value="text">Text</option>]}
                            </select>
                        </div>
                    </div>)}
                {(this.props.user.isSuper ||
                    (client && client.allowContentSpecificPermissions)) &&
                    (<div className="table__filters__option">
                        <label>Sharing Permissions:</label>
                        <div className="select__wrapper">
                            <select
                                className="filters__search"
                                value={sharingPerm}
                                onChange={event => this._onFilterChange("sharingPerm", event)}
                            >
                                <option value="">All</option>
                                {(this.props.user.isSuper || (client && this._checkSharingSettings())) && (<option value="social">Social</option>)}
                                {(this.props.user.isSuper || (client && (client.allowEmail || client.allowAppEmail))) && (<option value="email">Email</option>)}
                                {(this.props.user.isSuper || (client && (client.allowAppSMS || client.smsProvider))) && (<option value="sms">SMS</option>)}
                                <option value="readOnly">Read Only</option>
                            </select>
                        </div>
                    </div>)}
                <div className="table__filters__option">
                    <label>Sort By:</label>
                    <div className="select__wrapper">
                        <select
                            className="filters__search"
                            value={sortBy}
                            onChange={event => {
                                this.state.currentPage = this.state.page;
                                this._onFilterChange("sortBy", event)
                                this._onFilterChange("page", this.state.currentPage)
                            }}
                        >
                            <option value="date">Date</option>
                            <option value="clicks">Clicks</option>
                            <option value="likes">Likes</option>
                            <option value="comments">Comments</option>
                            <option value="shares">Shares</option>
                        </select>
                    </div>
                </div>
                <div className="table__filters__option">
                    <label style={headingStyle}> Include Expired Content:</label>
                    <Toggle
                        name="expired content-view"
                        value={this.state.includeExpiredContent}
                        onToggle={() => this._onFilterChange("includeExpiredContent", !this.state.includeExpiredContent)}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {
            searchQuery,
            sid,
            client,
            streams,
            view,
            approvedFilterStatus,
            articles,
            articlesLoading,
            downloading,
            advFilters,
            preview,
            hiddencontentPreview,
            isTrending
        } = this.state;

        const sortedStreamsArr = streams ? Object.values(streams).sort((a, b) => {
            return a.title.localeCompare(b.title);
        }) : [];

        const { streamsLoading } = this.props;
        if (!streams && !streamsLoading) {
            return (
                <Wrapper>
                    <div className="row page-heading">
                        <h1>
                            Could not find any searches for curation. Please create some in
                            <Link to={`/content/c`}>Stream Configuration</Link>
                        </h1>
                    </div>
                </Wrapper>
            );
        } else if (streamsLoading && !this.props.user.isSuper) {
            return (
                <Wrapper>
                    <div className="row page-heading">
                        <Spinner />
                    </div>
                </Wrapper>
            );
        }
        const buttons = [];
        if (this.props.user.isSuperClientAdmin) {
            buttons.push({ title: "Upload", link: "/content/u" });
        }
        if (!this.props.user.isSuper) {
            buttons.push({ title: "Create Content", on_click: () => this.setState({ addArticle: true }) });
        }
        return (
            <Wrapper id="LibraryPage">
                {preview && <StreamPreview
                    open={preview}
                    onClose={() => this.setState({ preview: false })}
                    getArticles={(date) => this._previewLibrary(date)}
                    client={client}
                    stream={client && client.streams}
                    sectionView={client && client.allowSectionView}
                />}
                {hiddencontentPreview && <BlockedContentModal
                    open={hiddencontentPreview}
                    onClose={() => this.setState({ hiddencontentPreview: false }, () => this._getArticles())}
                    client={client}
                />}
                {client &&
                    <AddArticleStack
                        open={this.state.addArticle}
                        client={client}
                        stream={streams ? streams[0] : ""}
                        streams={streams}
                        onClose={() => this.setState({ addArticle: false }, () => this._getArticles())}
                    />
                }
                {this.state.suggestedModalOpen && this._renderSuggestedModal()}
                <PageHeading title={`Library`} buttons={buttons} />
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <div className="table__filters">
                                    <div className="table__filters__option">
                                        <label htmlFor="search">Search:</label>
                                        <div className="search">
                                            <input
                                                id="search"
                                                className="filter__search"
                                                ref={input => {
                                                    this.userInput = input;
                                                }}
                                                type="text"
                                                onChange={event => this._onFilterChange("searchQuery", event, true)}
                                                value={searchQuery}
                                            />
                                            <i className="fa fa-search" />
                                        </div>
                                    </div>
                                    {this.props.user.isSuper && (
                                        <div className="table__filters__option">
                                            <label htmlFor="client">Client:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="client"
                                                    className="filters__search"
                                                    value={(client || {}).cid}
                                                    onChange={event => this._onFilterChange("cid", event)}
                                                >
                                                    <option value="">All</option>
                                                    <optgroup label="Clients">
                                                        {Object.keys(this.props.clients).map((key, i) => {
                                                            let c = this.props.clients[key];
                                                            return (
                                                                <option key={c.cid} value={c.cid}>
                                                                    {c.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    {!this.props.user.isSuper && (
                                        <div className="table__filters__option">
                                            <label htmlFor="stream">Stream:</label>
                                            <div className="select__wrapper">
                                                <select
                                                    id="stream"
                                                    className="filters__search"
                                                    value={sid}
                                                    onChange={event => this._onFilterChange("sid", event)}
                                                >
                                                    <option value="">All</option>
                                                    <optgroup label="Streams">
                                                        {sortedStreamsArr.map(function (str, i) {
                                                            if (
                                                                str.active &&
                                                                ((str.contentSearch && str.isCustom) ||
                                                                    (!str.contentSearch && str.isApprovalRequired) ||
                                                                    (!str.isApprovalRequired && str.searchType == "CORP"))
                                                            ) {
                                                                return (
                                                                    <option key={str.sid} value={str.sid}>
                                                                        {str.title}
                                                                    </option>
                                                                );
                                                            }
                                                        })}
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    {!isTrending && (<div className="table__filters__option date__wrapper">
                                        <label htmlFor="startDate">Approved After:</label>
                                        <DatePickerV2
                                            dateFormat="yyyy/MM/dd"
                                            maxDate={
                                                this.state.endDate
                                                    ? new Date(this.state.endDate).toISOString()
                                                    : new Date().toISOString()
                                            }
                                            id="startDate"
                                            value={this.state.startDate}
                                            onChange={(value, formattedValue) =>
                                                this._onFilterChange(
                                                    "startDate",
                                                    value ? dateToUTCISOString(value) : ""
                                                )
                                            }
                                            onBlur={() => this._onFilterChange("startDate", "")}
                                        />
                                    </div>)}
                                    {!isTrending && (<div className="table__filters__option date__wrapper">
                                        <label htmlFor="endDate">Expires Before:</label>
                                        <DatePickerV2
                                            id="endDate"
                                            dateFormat="yyyy/MM/dd"
                                            minDate={
                                                this.state.startDate ? new Date(this.state.startDate).toISOString() : ""
                                            }
                                            value={this.state.endDate}
                                            onChange={(value, formattedValue) =>
                                                this._onFilterChange(
                                                    "endDate",
                                                    value ? dateToUTCISOString(value) : ""
                                                )
                                            }
                                            onBlur={() => this._onFilterChange("endDate", "")}
                                        />
                                    </div>)}
                                    {!this._showAdminAdvFilters() && (<div className="table__filters__option">
                                        <label htmlFor="displayView">Display View:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="displayView"
                                                className="filters__search"
                                                value={view}
                                                onChange={event => this._onFilterChange("view", event)}
                                            >
                                                <option value="card">Article Card</option>
                                                <option value="table">Condensed</option>
                                            </select>
                                        </div>
                                    </div>)}
                                    {!isTrending && (<div className="table__filters__option">
                                        <label htmlFor="status">Status:</label>
                                        <div className="select__wrapper">
                                            <select
                                                id="status"
                                                className="filters__search"
                                                value={approvedFilterStatus}
                                                onChange={event => this._onFilterChange("approvedFilterStatus", event)}
                                            >
                                                <option value="">All</option>
                                                <option value="suggested">Suggested</option>
                                                {(this.props.user.isSuper ||
                                                    ((sid == "" && client && client.complianceEnabled) ||
                                                        (streams && streams[sid] && streams[sid].isConsoleCompliant))) && (
                                                        <option value="pending">Pending</option>
                                                    )}
                                                <option value="approved">Approved</option>
                                                <option value="declined">Declined</option>
                                                {(this.props.user.isSuper ||
                                                    ((sid == "" && client && client.complianceEnabled) ||
                                                        (streams && streams[sid] && streams[sid].isConsoleCompliant))) && (
                                                        <option value="new">New</option>
                                                    )}
                                                {(this.props.user.isSuper ||
                                                    ((sid == "" && client && client.complianceEnabled) ||
                                                        (streams && streams[sid] && streams[sid].isConsoleCompliant))) && (
                                                        <option value="rejected">Rejected</option>
                                                    )}
                                            </select>
                                        </div>
                                    </div>)}
                                    <div style={{ paddingLeft: "15px", paddingTop: "12px" }}>
                                        <label className="" />
                                        <div style={{ display: "flex" }}>
                                            <Link style={{ textDecoration: "underline", color: "#152733" }}
                                                onClick={() => this._clearFilters()}
                                            >Clear Filter</Link>
                                        </div>
                                    </div>
                                    {articles && articles.length > 0 && (
                                        <div
                                            style={{
                                                height: "100%",
                                                marginBottom: "auto",
                                                marginLeft: "auto"
                                            }}
                                        >
                                            {(this._showPreview() && (<Button
                                                style={{ marginLeft: "auto", marginBottom: "0", marginRight: "5px" }}
                                                className="btn--sm"
                                                onClick={() => this.setState({ preview: true })}
                                            >
                                                <i className={`fa fa-eye`} /> Preview
                                                <Tooltip style={{ paddingLeft: "5px" }}>
                                                    See how the content below will display to users in the app
                                                </Tooltip>
                                            </Button>))}
                                            <Button
                                                disabled={downloading}
                                                style={{ marginLeft: "auto", marginBottom: "0" }}
                                                className="btn--sm"
                                                onClick={() => this._download()}
                                            >
                                                {downloading ? (
                                                    <span>
                                                        Downloading <i className="fa fa-spin fa-spinner" />
                                                    </span>
                                                ) : (
                                                    <span><i className="fa fa-download" /> Download</span>
                                                )}
                                            </Button>&nbsp;&nbsp;
                                            {(this._showPreview() && (<Button className="btn--sm"
                                                style={{ marginLeft: "auto", marginBottom: "0", marginRight: "5px" }}
                                                onClick={() => this.setState({ hiddencontentPreview: true })}>
                                                <i className={`fa fa-eye`} /> Blocked Content
                                                <Tooltip style={{ paddingLeft: "5px" }}>
                                                    Click to see the list of hidden article urls in the app
                                                </Tooltip>
                                            </Button>))}
                                        </div>
                                    )}
                                </div>
                                {(this._showAdminAdvFilters() && !isTrending) && (<div>
                                    <div className="table__filters">
                                        <Link
                                            style={{
                                                paddingTop: "15px",
                                                textDecoration: "underline"
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    advFilters: !this.state.advFilters
                                                })
                                            }
                                        >Advanced <span className={advFilters ? "icon-up" : "icon-down"}></span></Link>
                                    </div>
                                    <div className="table__filters" style={{ paddingTop: "15px" }}>
                                        {this.state.advFilters && this._renderAdvancedFilters()}
                                    </div>
                                </div>)}

                                {streams && streams[sid] && !!streams[sid].sequentialContent && (
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            backgroundColor: "#d1e8fd",
                                            flexDirection: "column",
                                            paddingTop: "8px",
                                            paddingLeft: "8px",
                                            paddingRight: "8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: "black"
                                            }}
                                        >
                                            This stream is set to <span style={{ fontWeight: "bold" }}>sequentially auto post</span> content. You can view and modify the order of the sequence in <Link to={`/clients/s/${client.cid}/e/${sid}`} style={{ fontWeight: "bold", textDecoration: "underline" }}>stream configuration</Link>
                                        </p>
                                    </div>
                                )}

                                <hr />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        page={this.state.page}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.per_page}
                                        changePage={newPage => this._onFilterChange("page", newPage)}
                                        disabled={this.state.loading}
                                        showTotal={false}
                                    />
                                </div>
                                {this._renderArticles(view, articlesLoading, articles)}
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        currItems={articles.length}
                                        page={this.state.page}
                                        totalItems={this.state.total}
                                        numPerPage={this.state.per_page}
                                        changePage={newPage => this._onFilterChange("page", newPage)}
                                        disabled={this.state.loading}
                                        showTotal={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }

    _renderArticles(view, loading, articles) {
        if (view == "card") {
            return this._renderCardView(loading, articles);
        } else {
            return this._renderTableView(loading, articles);
        }
    }

    _renderCardView(loading, articles) {
        const { clients } = this.props;
        const { sid, streams, client } = this.state;
        return (
            <div className="article-card__wrapper">
                {!loading &&
                    articles &&
                    articles.map(article => {
                        let isBlocked = false;
                        const canEdit = !article.syncUpdates;
                        if (streams[sid] && streams[sid].type === "CORPORATE") {
                            article.status = !streams[sid].isApprovalRequired && "approved"; // set status to approved is approval isn't required.
                            article.cid = client.cid;
                        };
                        const blockedUrls = client?.blocklistedUrls;
                        if (blockedUrls && blockedUrls.length) {
                            blockedUrls.filter(blockedurl => blockedurl.url == article.Link).length ? isBlocked = true : isBlocked = false;
                        }

                        const isNextSequentialShare = Math.min(...articles.filter(_article => !_article.hasScheduled && !!streams[_article.sid]?.sequentialContent).map(_article => _article.sequentialOrder).filter(order => !!order)) === article.sequentialOrder;

                        return <ArticleCard
                            key={article.artid}
                            article={article}
                            stream={streams[article.sid] || (streams[sid] && streams[sid].type === "CORPORATE")}
                            client={clients[article.cid]}
                            canEdit={canEdit}
                            isBlocked={isBlocked}
                            deleteAction={this._deleteAction}
                            editAction={this._getArticles}
                            approveAction={() => this._getArticles()}
                            isNextSequentialShare={isNextSequentialShare || false}
                        />
                    })}

                <div className="load-more">
                    {loading && <Spinner />}
                    {!loading && (!articles || articles.length == 0) && (
                        <div>No articles matching your filters could be found</div>
                    )}
                </div>
            </div>
        );
    }

    _editArticle(article) {
        const client = this.props.clients[article.cid];
        const { artid, CreatedOn, activeDate, sid, ...articleItem } = article;
        const type = ((article.shareFormat && article.shareFormat == "media") || (!article.Link || article.Link == "")) ? "media" : "articles";
        history.push({
            pathname: `/content/${type}/${artid ? "e" : "c"}/${client.cid}${artid ? `/${artid}` : ""}`,
            ...((!artid) && { state: { ...articleItem } })
        });
    }
    _renderTableView(loading, articles) {
        const { sid, streams, client } = this.state;
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        {this.props.user.isSuper && [<th tabIndex={0}>Client</th>, <th tabIndex={0}>Stream</th>]}
                        <th tabIndex={0}>Title</th>
                        <th tabIndex={0}>Comment</th>
                        <th tabIndex={0}>Domain</th>
                        <th tabIndex={0}>Status</th>
                        <th tabIndex={0}>Time Approved</th>
                        {!this.props.user.isSuper && <th tabIndex={0} />}
                    </tr>
                </thead>
                <tbody>
                    {!loading &&
                        articles.map(
                            function (article, i) {
                                return (
                                    <tr key={article.artid}>
                                        {this.props.user.isSuper && [
                                            <td tabIndex={0}>
                                                <Link to={`/clients/e/${article.cid}`}>
                                                    {this._getClientName(article.cid) || "N/A"}
                                                </Link>
                                            </td>,
                                            <td tabIndex={0}>
                                                <Link to={`/clients/s/${article.cid}/e/${article.sid}`}>
                                                    {this._getStreamName(article.cid, article.sid) || "N/A"}
                                                </Link>
                                            </td>
                                        ]}
                                        <td tabIndex={0}>
                                            <Link href={stripGV6Query(article.Link || "")} target="_blank">
                                                {article.Title || "N/A"}
                                            </Link>
                                        </td>
                                        <td tabIndex={0}>{article.comment || "N/A"}</td>
                                        <td tabIndex={0}>{article.Domain || "N/A"}</td>
                                        <td tabIndex={0}>{getComplianceMessage(article) || "N/A"}</td>
                                        <td tabIndex={0}>
                                            {!this.props.user.isSuper && (
                                                <Tooltip label={getFriendlyDate(article.activeDate) || "N/A"}>
                                                    {getAdminDate(article.activeDate) || "N/A"}
                                                </Tooltip>
                                            )}
                                            {this.props.user.isSuper && (getAdminDate(article.activeDate) || "N/A")}
                                        </td>
                                        {!this.props.user.isSuper && (
                                            <td tabIndex={0}>
                                                <Button
                                                    onClick={() => this._editArticle(article)}
                                                    className="btn--sm"
                                                >
                                                    Edit
                                                </Button>
                                            </td>
                                        )}
                                    </tr>
                                );
                            }.bind(this)
                        )}
                    {!loading && (!articles || articles.length == 0) && (
                        <tr>
                            <td tabIndex={0} colSpan="42">No articles matching your filters could be found</td>
                        </tr>
                    )}
                    {loading && (
                        <tr>
                            <td tabIndex={0} colSpan="42">
                                <Spinner />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}
Library.allowSuper = true;
Library.allowApiAdmin = false;
Library.allowClientAdmin = true;
Library.allowAdmin = false;
Library.allowCurator = true;
Library.allowReports = false;
const mapStateToProps = state => ({
    clients: state.lists.clients,
    streams: state.lists.streams,
    streamsLoading: state.lists.streamsLoading,
    user: state.session.admin
});

export default connect(mapStateToProps)(withLastLocation(Library));
