import React, { useEffect, useState } from "react";
import ModalStack from "../../molecules/modals/ModalStack";
import FindArticleModal from "../../molecules/modals/FindArticleModal";

import { doGetClients } from "../../../actions";
import { Wrapper } from "../../atoms/Layout";
import { history } from "../../../utils/store";

interface ICreateOptionsProps {
    user: Mongo.clientAdmin;
    match: {
        params: {
            artid?: string;
            cid: string;
        };
    };
    location?: {
        state?: Partial<ServerTypes.Console.IArticle>;
    };
    isMedia?: boolean;
}

const CreateOptions = (props: ICreateOptionsProps) => {

    const [client, setClient] = useState<Mongo.IClient | null>(null);

    useEffect(() => {
        (async () => {
            const { cid } = props.match.params;
            const clients = await doGetClients();
            const client = cid && clients && clients.length ? clients.filter(c => c.cid == cid)[0] || null : null;
            setClient(client);
        })();
    }, []);

    return <Wrapper>
        {client && <ModalStack
            modals={[
                {
                    title: "Create Content",
                    render: (
                        <FindArticleModal
                            client={client}
                            allowCustomEdit={client.allowCustomEdit}
                            stream={Object.values(client.streams)[0]}
                            submit={article => {
                                history.push({
                                    pathname: `/content/articles/c/${client.cid}`,
                                    state: { ...article }
                                });
                            }}
                        />
                    ),
                    showButtons: false
                }
            ]}
            // open first modal in the stack
            open={0}
            onClose={() => { history.goBack(); }}
        />}
    </Wrapper>
}

export default CreateOptions;