import { store } from "../utils/store";
import { apiRequest, apiRequestWithErrorAlert } from "../utils/Helpers";
import appConfig from "../../config/config.dev";
import { Admin } from "../utils/Models";
import { CoreAPI } from "../../../../types/coreapi";

export const ADMINS_LIST = "ADMINS_LIST";
export const ADMINS_REQUEST = "ADMINS_REQUEST";

export const COMPANY_LIST = "COMPANY_LIST";
export const COMPANY_REQUEST = "COMPANY_REQUEST";

export const OFFER_CAMPAIGN_LIST = "OFFER_CAMPAIGN_LIST";
export const OFFER_CAMPAIGN_REQUEST = "OFFER_CAMPAIGN_REQUEST";

export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";

export const INDUSTRY_LIST = "INDUSTRY_LIST";
export const INDUSTRY_REQUEST = "INDUSTRY_REQUEST";

export const CLIENTS_LIST = "CLIENTS_LIST";
export const CLIENT_UPDATE = "CLIENT_UPDATE";
export const CLIENTS_REQUEST = "CLIENTS_REQUEST";

export const USERS_LIST = "USERS_LIST";
export const USERS_REQUEST = "USERS_REQUEST";

export const GROUPS_LIST = "GROUPS_LIST";
export const GROUPS_REQUEST = "GROUPS_REQUEST";

export const SEARCHES_LIST = "SEARCHES_LIST";
export const SEARCH_ARTICLE_LIST = "SEARCH_ARTICLE_LIST";
export const SEARCH_ARTICLE_REQUEST = "SEARCH_ARTICLE_REQUEST";
export const SEARCH_ARTICLE_LIST_MORE = "SEARCH_ARTICLE_LIST_MORE";
export const SEARCH_ARTICLE_MORE_REQUEST = "SEARCH_ARTICLE_MORE_REQUEST";
export const SEARCH_ARTICLE_FINAL_PAGE = "SEARCH_ARTICLE_FINAL_PAGE";
export const SEARCHES_REQUEST = "SEARCHES_REQUEST";

export const ARTICLE_REQUEST = "ARTICLE_REQUEST";
export const ARTICLE_LIST = "ARTICLE_LIST";

export const COMPLIANT_ARTICLE_REQUEST = "COMPLIANT_ARTICLE_REQUEST";
export const COMPLIANT_ARTICLE_LIST = "COMPLIANT_ARTICLE_LIST";
export const CLEAR = "CLEAR";

const PER_PAGE = 20;

function _adminslist(admins: { [aid: string]: Mongo.clientAdmin }) {
    return { type: ADMINS_LIST, admins };
}

function _adminsRequest() {
    return { type: ADMINS_REQUEST };
}

function _companyList(companies: { [companyId: string]: Mongo.company }) {
    return { type: COMPANY_LIST, companies };
}
function _companyRequest() {
    return { type: COMPANY_REQUEST };
}

function _industryList(industries: { [iid: string]: Mongo.clientIndustries }) {
    return { type: INDUSTRY_LIST, industries };
}

function _industryRequest() {
    return { type: INDUSTRY_REQUEST };
}

function _notificationList(notifications: { [nid: string]: Mongo.INotification }) {
    return { type: NOTIFICATION_LIST, notifications };
}

function _notificationRequest() {
    return { type: NOTIFICATION_REQUEST };
}

function _clientsRequest() {
    return { type: CLIENTS_REQUEST };
}

function _clientslist(clients: { [cid: string]: Mongo.IClient }) {
    return { type: CLIENTS_LIST, clients };
}

function _clientUpdate(client: Mongo.IClient) {
    return { type: CLIENT_UPDATE, client };
}

function _groupsList(groups) {
    return { type: GROUPS_LIST, groups };
}

function _searchesRequest() {
    return { type: SEARCHES_REQUEST };
}

function _searcheslist(streams: Mongo.IStream[]) {
    return { type: SEARCHES_LIST, streams };
}

function _searchArticleList(articles: Mongo.clientArticles[]) {
    return { type: SEARCH_ARTICLE_LIST, articles };
}

function _searchArticleListMore(articles: Mongo.clientArticles[]) {
    return { type: SEARCH_ARTICLE_LIST_MORE, articles };
}

function _searchArticleRequest() {
    return { type: SEARCH_ARTICLE_REQUEST };
}

function _searchArticleMoreRequest() {
    return { type: SEARCH_ARTICLE_MORE_REQUEST };
}

function _lastSearchArticlePageLoaded() {
    return { type: SEARCH_ARTICLE_FINAL_PAGE };
}

function _compliantArticleList(articles: Mongo.clientArticles[]) {
    return { type: COMPLIANT_ARTICLE_LIST, articles };
}
function _compliantArticleRequest() {
    return { type: COMPLIANT_ARTICLE_REQUEST };
}

function _clear() {
    return { type: CLEAR };
}

export function doClear() {
    store.dispatch(_clear());
}

export function doGetCompanies(data) {
    store.dispatch(_companyRequest());
    return apiRequest(`${appConfig.API_URL}/company/list`, `POST`, data).then(data => {
        const $companies = data.items || [];
        store.dispatch(_companyList($companies));
        return data;
    });
}

export function doGetCompany(companyId) {
    store.dispatch(_companyRequest());
    const data = {
        ...(companyId ? { companyId } : null)
    };

    apiRequest(`${appConfig.API_URL}/company/get`, `POST`, data).then(data => {
        const $companies = { [data.item.companyId]: data.item };

        store.dispatch(_companyList($companies));
    });
}

export function doGetCompanyForClient() {
    store.dispatch(_companyRequest());
    apiRequest(`${appConfig.API_URL}/client/company`, `GET`, undefined).then(data => {
        const $companies = data || [];

        store.dispatch(_companyList($companies));
    });
}

export function doGetIndustries(search = "") {
    store.dispatch(_industryRequest());
    const data = {
        ...(search ? { search } : null)
    };

    apiRequest(`${appConfig.API_URL}/industry/list`, `POST`, data).then(data => {
        const $industries = {};
        if (data.items) {
            data.items.forEach(item => {
                $industries[item.iid] = item;
            });
        }
        store.dispatch(_industryList($industries));
    });
}

export function doGetConnectedAccounts() {
    return apiRequest(`${appConfig.API_URL}/social/getConnectedAccounts`).then(data => data?.data || {});
}

export function doRefreshSocialFeeds(cid: string, sid: string, publishedAfter?: Date, count?: number) {
    const data = {
        cid,
        sid,
        ...(publishedAfter ? { publishedAfter }: null),
        ...(count ? { count }: null)
    };
    return apiRequestWithErrorAlert<{
        valid: boolean;
        data: {
            finished: boolean,
            liCrawlLogs: {
                [sid: string]: {
                    cid: string;
                    logs: string[];
                };
            }
        };
    }>(`${appConfig.API_URL}/social/refreshFeeds`, "GET", data).then(data => data?.data);
}

export function doGetFacebookPages(aid?: string) {
    return apiRequestWithErrorAlert<{
        valid: boolean;
        data: {
            facebookPages: Array<{
                name: string;
                id: string;
                permissions: string[];
            }>;
            instagramPages: Array<{
                name: string;
                username: string;
                id: string;
                permissions: string[];
            }>;
        }
    }>(`${appConfig.API_URL}/social/getFacebookPages`, "GET", aid ? { aid } : null, null, true)
        .then(data => data?.data || { facebookPages: [], instagramPages: [] })
        .catch(err => err == "Invalid Facebook token" ? err : []);
}

export function doGetLinkedInCompanies(aid?: string) {
    return apiRequestWithErrorAlert<{
        valid: boolean;
        data: Array<{
            name: string;
            id: string;
        }>;
    }>(`${appConfig.API_URL}/social/getLinkedInCompanies`, "GET", aid ?  { aid } : null , null, true)
        .then(data => data?.data || [])
        .catch(err => err == "Invalid LinkedIn token" ? err : []);
}

export function doGetIndustry(industryId) {
    store.dispatch(_industryRequest());
    const data = {
        ...(industryId ? { iid: industryId } : null)
    };

    return apiRequest(`${appConfig.API_URL}/industry/get`, `POST`, data).then(data => {
        const $industries = { [data.item.iid]: data.item };

        store.dispatch(_industryList($industries));
        return $industries;
    });
}

export function doGetOfferCampaigns(options, search = "") {
    const data = {
        ...(search ? { search } : null),
        options
    };
    return apiRequest(`${appConfig.API_URL}/offercampaign/list`, `POST`, data).then(data => {
        if (data && data.items && data.items.length) {
            return { items: data.items, total: data.count };
        }
        return { items: [], total: 0 };
    });
}

export async function doGetOfferCampaign(ocid) {
    const data = {
        ...(ocid ? { ocid } : null)
    };

    return apiRequest(`${appConfig.API_URL}/offercampaign/get`, `POST`, data).then(data => {
        return data.item;
    });
}

export function doGetNotification(nid) {
    store.dispatch(_notificationRequest());
    const data = {
        ...(nid ? { nid } : null)
    };

    return apiRequest(`${appConfig.API_URL}/notification/get`, `POST`, data).then(data => {
        const $notifications = { [data.item.nid]: data.item };

        store.dispatch(_notificationList($notifications));
        return $notifications;
    });
}

export function doGetEmailDrips(data: {
    sort?: { [key: string]: number };
    skip?: number;
    limit?: number;
    search?: string;
    cid?: string;
    consumer?: boolean;
}) {
    return apiRequest<{ items: Mongo.IEmailDripCampaign[]; count: number }>(
        `${appConfig.API_URL}/emailDrip/list`,
        `POST`,
        data
    ).then(data => {
        if (data && "items" in data) {
            return { items: data.items, total: data.count };
        }
        return { items: [], total: 0 };
    });
}

export function doGetEmailDrip(eid) {
    const data = {
        ...(eid ? { eid } : null)
    };

    return apiRequest(`${appConfig.API_URL}/emailDrip/get`, `POST`, data).then(data => {
        const $emailDrip = { [data.item.eid]: data.item as Mongo.IEmailDripCampaign };
        return $emailDrip;
    });
}

export function doGetAdmins(data: {
    sort?: { [key: string]: number };
    skip?: number;
    limit?: number;
    search?: string;
    cid?: string;
    groups?: string[];
}) {
    store.dispatch(_adminsRequest());

    return apiRequest(`${appConfig.API_URL}/clientadmin/list`, `POST`, { ...data }).then(data => {
        const $admins = {};
        if (data.items) {
            data.items.forEach(item => {
                $admins[item.aid] = Admin(item);
            });
        }
        store.dispatch(_adminslist($admins));
        return { items: data.items, total: data.count };
    });
}

export async function doGetUsers(data: {
    sort?: { [key: string]: number };
    skip: number;
    limit: number;
    search?: string;
    cid?: string;
    isActive?: boolean;
    softDeleted?: boolean;
    hasActivated?: boolean;
    completedOnboarding?: boolean;
    rid?: string;
    gids?: string[];
    createdOnAfter?: string;
    createdOnBefore?: string;
}) {
    const results = await apiRequestWithErrorAlert<{ items: Mongo.clientUsers[]; count: number }>(
        `${appConfig.API_URL}/clientuser/list`,
        "POST",
        data
    );
    return {
        items: results?.items || [],
        total: results?.count || 0
    };
}

export async function doGetUsersV2(
    search: string,
    cid: string,
    rids: string[] | null,
    gids: string[] | null,
    tags: string[],
    limit: number,
    skip: number,
    sort: { [key: string]: number } | undefined
) {
    const data = {
        search,
        cid,
        rids,
        gids,
        tags,
        limit,
        skip,
        sort,
        props: ["cid","uid","rid","email","firstName","lastName"]
    }
    const results = await apiRequestWithErrorAlert<{ items: ServerTypes.Console.IRoleUser[]; count: number }>(
        `${appConfig.API_URL}/clientuser/fetchByFilters`,
        "POST",
        data
    );
    return {
        items: results?.items || [],
        count: results?.count || 0
    };
}

export async function doGetGroups(cid: string) {
    store.dispatch(_groupsList(null));
    const data = await apiRequest<ServerTypes.Console.IListResponse<ServerTypes.Console.IGroup>>(
        `${appConfig.API_URL}/client/group/list`,
        `POST`,
        { cid }
    );
    if (!data || "valid" in data) return {};

    const sorted = data.items.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
    });
    const sortedObj = sorted.reduce((a, group) => {
        a[group.gid] = group.name;
        return a;
    }, {} as dynamic<string>);
    store.dispatch(_groupsList(sortedObj));
    return sortedObj;
}

export function doGetEntityConfigs(cid: string, raw?: boolean) {
    return apiRequest(`${appConfig.API_URL}/compliance/getEntityConfigs${cid ? `?cid=${cid}` : ""}`).then(data => {
        if (raw) return data;
        return Object.keys(data || {}).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    });
}

export function doGetQuotes(url: string, lang: string, hasChatGPT: boolean, cid: string, aiCommentPref?: dynamic) {
    return apiRequest<CoreAPI.IQuoteResponse>(`${appConfig.API_URL}/getQuotes`, "POST", { url, lang, hasChatGPT, cid, aiCommentPref}).catch(
        err => ({ numFound: 0, items: [] } as CoreAPI.IQuoteResponse)
    );
}

export function doGetWhitelabels() {
    return apiRequest(`${appConfig.API_URL}/getWhiteLabels`);
}

export async function doGetClientWhitelabel(configName: string) {
    return await apiRequest(`${appConfig.API_URL}/client/getWhiteLabel?clientConfigName=${configName}`);
}

export function doGetBlocklistedCountries() {
    return apiRequest(`${appConfig.API_URL}/settings/blocklistedCountries/get`);
}

export function doUpdateBlocklistedCountries(countries: string[]) {
    return apiRequest(`${appConfig.API_URL}/settings/blocklistedCountries/update`, `POST`, { countries });
}

export async function doGetReduxClient(cid: string) {
    const data = await apiRequest<ServerTypes.Console.IClient>(`${appConfig.API_URL}/client/get`, "POST", { cid });
    if (!data || (data && "valid" in data)) return null;

    const cTags = {};
    for (const tag in data.tags) {
        if (data.tags[tag]) {
            cTags[tag] = data.tags[tag].name;
        }
    }
    data.tags = cTags;
    store.dispatch(_clientUpdate(data));
    return data;
}

export function doGetClientList(data: {
    sort?: { [key: string]: number };
    skip?: number;
    limit?: number;
    search?: string;
}) {
    return apiRequest(`${appConfig.API_URL}/client/list`, `POST`, { ...data }).then(data => {
        if (!data.valid) {
            return { items: [] as Mongo.IClient[], total: 0, valid: false };
        }
        return { items: data.items as Mongo.IClient[], total: data.count as number, valid: true };
    });
}

export function doGetReduxClients() {
    store.dispatch(_clientsRequest());
    return apiRequest(`${appConfig.API_URL}/client/list`, "POST").then((response: dynamic) => {
        const data = response as { valid: boolean; items: Mongo.IClient[] };
        const $clients: { [cid: string]: Mongo.IClient } = {};
        if (!data) {
            store.dispatch(_clientslist($clients));
        }
        data.items.sort((a, b) => {
            return a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : b.name.toLowerCase() > a.name.toLowerCase()
                ? -1
                : 0;
        });
        if (data.items) {
            data.items.forEach(item => {
                const cTags = {};

                for (const tag in item.tags) {
                    if (item.tags[tag]) {
                        cTags[tag] = item.tags[tag].name;
                    }
                }
                item.tags = cTags;
                $clients[item.cid] = item;
            });
        }
        store.dispatch(_clientslist($clients));
        return Object.values($clients);
    });
}

export function doGetReduxStreams(cid: string, dispatchRequest = true) {
    if (dispatchRequest) {
        store.dispatch(_searchesRequest());
    }
    return apiRequest(`${appConfig.API_URL}/getAllStreams${typeof cid !== "undefined" ? `?cid=${cid}` : ""}`).then(
        (data: { streams: Mongo.IStream[]; streamTags: { [sid: string]: string[] } }) => {
            const $streamTags = data.streamTags;
            const $streams = [];
            if ($streamTags && Object.keys($streamTags).length > 0) {
                Object.keys($streamTags).forEach(sid => {
                    const $sTags: string[] = [];
                    $streamTags[sid].forEach(tid => {
                        $sTags.push(tid);
                    });

                    $streamTags[sid] = $sTags;
                });
            }
            data.streams.forEach((item: Mongo.IStream & { tags?: string[] }) => {
                const searchStream = item;
                if ($streamTags[searchStream.sid]) {
                    searchStream.tags = $streamTags[searchStream.sid];
                }
                $streams[searchStream.sid] = searchStream;
            });
            store.dispatch(_searcheslist($streams));
            return $streams;
        }
    );
}

export function doGetMoreSearchArticles(
    cid: string,
    sid: string,
    terms: string[],
    since: number,
    location: string,
    start: number
) {
    store.dispatch(_searchArticleMoreRequest());
    const data = {
        sid,
        cid,
        terms,
        since,
        start: start * PER_PAGE,
        location
    };
    apiRequest(`${appConfig.API_URL}/searchArticlesForClientStream`, "POST", data).then(data => {
        store.dispatch(_searchArticleListMore(data));
        if (data.length < PER_PAGE) {
            store.dispatch(_lastSearchArticlePageLoaded());
        }
    });
}

export function doSearchArticles(
    cid: string,
    searchType: string,
    lang: string,
    type: string,
    sharingPerm: string,
    location: string,
    industry: string,
    terms: Array<{ term: string; checked?: boolean }>,
    tcChecked: boolean,
    compliant: boolean,
    filterType: string,
    customFilters: { bDomains?: string[]; wDomains?: string[]; bTerms?: string[] },
    start: number,
    since: number,
    categories: string[]
) {
    return apiRequest(`${appConfig.API_URL}/searchArticlesForClient`, `POST`, {
        cid,
        searchType,
        lang,
        type,
        sharingPerm,
        location,
        industry,
        terms,
        tcChecked,
        compliant,
        filterType,
        customFilters,
        since,
        categories,
        start: start * PER_PAGE
    });
}

export function getArticlesForDomain(domain: string, since: number, start: number, lang: string, count: number) {
    return apiRequest(`${appConfig.API_URL}/loadArticlesForDomain`, `POST`, {
        domain,
        since,
        start: start * count,
        lang
    });
}

export function doGetSearchArticles(
    cid: string,
    sid: string,
    terms: Array<{ term: string; checked: boolean }>,
    since: number,
    location: string,
    start: number
) {
    store.dispatch(_searchArticleRequest());
    const data = {
        sid,
        cid,
        terms,
        since,
        start: start * PER_PAGE,
        location
    };

    apiRequest(`${appConfig.API_URL}/searchArticlesForClientStream`, "POST", data).then(data => {
        store.dispatch(_searchArticleList(data));
        if (data.length < PER_PAGE) {
            store.dispatch(_lastSearchArticlePageLoaded());
        }
    });
}

export function doGetNonCompliantArticles(cid: string, status: string) {
    store.dispatch(_compliantArticleRequest());
    return apiRequest<Mongo.clientArticles[]>(`${appConfig.API_URL}/getArticlesForClientByStatus`, `POST`, {
        cid,
        status
    }).then(data => {
        if (data && !("valid" in data)) {
            store.dispatch(_compliantArticleList(data));
            return data;
        } else {
            return [];
        }
    });
}

export function doGetArticle(cid: string, artid: string) {
    const data = {
        ...(artid ? { artid } : null)
    };

    return apiRequest(`${appConfig.API_URL}/article/get`, `POST`, data);
}

export function doGetArticles(
    cid: string,
    sid: string,
    status: string | null = null,
    start = 0,
    limit = 1000,
    search: string | null = null,
    type: string | null = null,
    sharingPerm: string | null = null,
    sortBy: string | null = null,
    startDate: string | null = null,
    endDate: string | null = null,
    log = true,
    includeExpiredContent: boolean = false,
    sequentialOrder: number | null = null,
    stream?: {}
) {
    const data = {
        cid,
        ...(sid ? { sid } : null),
        skip: start,
        ...(limit ? { limit } : null),
        ...(startDate ? { startDate } : null),
        ...(endDate ? { endDate } : null),
        ...(status ? { status } : null),
        ...(search ? { search } : null),
        ...(type ? { type } : null),
        ...(sharingPerm ? { sharingPerm } : null),
        ...(sortBy ? { sortBy } : null),
        ...(stream ? { stream } : null),
        includeExpiredContent,
        ...(sequentialOrder ? { sequentialOrder } : null)
    };

    return apiRequest(`${appConfig.API_URL}/article/list${log ? "" : "?log=f"}`, `POST`, data);
}

export function doGetClientService<T>(
    route: string,
    data: {
        sort?: { [key: string]: number };
        skip?: number;
        limit?: number;
        search?: string;
    }
) {
    return apiRequest(`${appConfig.API_URL}/${route}/list`, `POST`, { ...data }).then(data => {
        if (!data.valid) {
            return { items: [] as T[], total: 0, valid: false };
        }
        return { items: data.items as T[], total: data.count as number, valid: true };
    });
}

export function doGetPotentialDelegates(
    uid: string,
    cid: string,
    existingDelegateUIDs: string[],
    search: string,
    limit: number,
    skip: number,
    sort?: dynamic<number>
) {
    const data ={
        uid,
        cid,
        existingDelegateUIDs,
        search,
        limit,
        skip,
        ...(sort ? { sort } : null)
    }
    return apiRequest(`${appConfig.API_URL}/getPotentialDelegates`, `POST`, data);
}
