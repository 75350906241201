import React, { Component } from "react";
import { connect } from "react-redux";

import {
    getFriendlyTimestamp,
    getFriendlyDate,
    apiRequest,
    getComplianceMessage,
    getAdminDate
} from "../../utils/Helpers";
import { doInsertArticle } from "../../actions/inserts";
import Spinner from "../atoms/Spinner";
import MoreLikeThis from "./MoreLikeThis";
import appConfig from "../../../config/config.dev";
const striptags = require("striptags");
import Tooltip from "../atoms/Tooltip";
import ArticleContent from "./ArticleContent";
import { createAlert } from "../../actions/utils";
import Button from "../atoms/Button";
import { history } from "../../utils/store";
import { CampaignNotificationActions } from "../../actions";
import { ArticleActions } from "../../actions/ArticleActions";

interface ArticleCardProps {
    stream?: ServerTypes.Console.IStream;
    streams: dynamic<Mongo.IStream>;
    client?: Mongo.client;
    user: Mongo.clientAdmin;
    canApprove?: boolean;
    canEdit?: boolean;
    isBlocked?: boolean;
    editing?: boolean;
    article: ServerTypes.Console.IArticle;
    approveAction?: () => void;
    deleteAction?: () => void;
    editAction?: () => void;
    isNextSequentialShare?: boolean;
}

interface ArticleCardState {
    article: ServerTypes.Console.IArticle;
    stream?: ServerTypes.Console.IStream;
    client?: Mongo.client;
    approved: boolean;
    canApprove: boolean;
    canEdit: boolean;
    fullContentOpen: boolean;
    editing: boolean;
    duplicating: boolean;
    remove: boolean;
    saving: boolean;
    dropdownOpen: boolean;
    showMoreLikeThis: boolean;
    creationInfo?: dynamic;
    save_protect?: boolean;
    createNotification?: boolean;
    isBlocked: boolean;
}

class ArticleCard extends Component<ArticleCardProps, ArticleCardState> {
    constructor(props: ArticleCardProps) {
        super(props);
        this._toggleFullContent = this._toggleFullContent.bind(this);
        this._approveArticle = this._approveArticle.bind(this);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._onArticleFieldChange = this._onArticleFieldChange.bind(this);
        this._onUpdate = this._onUpdate.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this._articleUpdate = this._articleUpdate.bind(this);
        this._renderButtons = this._renderButtons.bind(this);
        this._deleteArticle = this._deleteArticle.bind(this);
        this._runCloseActions = this._runCloseActions.bind(this);
        this._getAutopostStatus = this._getAutopostStatus.bind(this);
        this._renderLastModifiedBy = this._renderLastModifiedBy.bind(this);
        this._renderCreatedBy = this._renderCreatedBy.bind(this);

        const article = props.article;
        if (article.Description) {
            let descriptionPreview = striptags(article.Description);
            if (descriptionPreview.length > 100) {
                descriptionPreview = descriptionPreview.substring(0, 100).trim() + " [...]";
            }
            // TODO: is this actually used?
            (article as dynamic).DescriptionPreview = descriptionPreview;
        }
        this.state = {
            article,
            stream: props.stream,
            client: props.client,
            approved: false,
            canApprove: props.canApprove || false,
            canEdit: props.canEdit || false,
            fullContentOpen: false,
            editing: props.editing || false,
            duplicating: false,
            remove: false,
            saving: false,
            dropdownOpen: false,
            showMoreLikeThis: false,
            creationInfo: undefined,
            isBlocked: props.isBlocked || false
        };
    }

    async _onFieldUpdate(fieldName: keyof ArticleCardState, event, duplicating?: boolean) {
        const { client } = this.props;
        const { article, saving } = this.state;
        if (fieldName.indexOf("editing") !== -1) {
            const { artid, CreatedOn, activeDate, sid, syncUpdates, ...articleItem } = article;
            // there are scenarios where the article.shareFormat is null.
            // In those cases, we're relying on the article.Link being null or empty to considered as a media item. (GV6-7108)
            const type = ((article.shareFormat === "article" && (article.commentURL && article.commentURL.length > 0)) || (article.shareFormat && article.shareFormat == "media") || (!article.Link || article.Link == "")) ? "media" : "articles";
            history.push({
                pathname: `/content/${type}/${artid && !duplicating ? "e" : "c"}/${client?.cid}${artid && !duplicating ? `/${artid}` : ""}`,
                ...((!artid || duplicating) && { state: { ...articleItem, forceScheduleDate: null, hasScheduled: false, isDuplicate: duplicating } })
            });
            this.setState({ dropdownOpen: false });
        }
        if (fieldName.indexOf("createNotification") !== -1) {
            const { artid, CreatedOn, activeDate, sid, ...articleItem } = article;
            const { cid, Link, Image, videoUrl } = articleItem;

            if (cid && (Link || Image || videoUrl)) {
                const existingContent = await CampaignNotificationActions.doGetExistingContent({
                    cid,
                    ...(Link ? { Link } : null),
                    ...(!Link && videoUrl ? { videoUrl } : null),
                    ...(!Link && !videoUrl && Image ? { Image } : null),
                });
                if (existingContent.total) {
                    if (
                        !confirm(
                            `A notification ${existingContent.items[0].name} with this piece of content already exists. Are you sure you want to create another one?`
                        )
                    ) {
                        this.setState({ dropdownOpen: false });
                        return;
                    }
                }
                history.push({
                    pathname: `/notifications/c/`,
                    state: { ...articleItem, artid }
                });
            }
        }
        if (fieldName.indexOf("editing") !== -1 && saving) {
            return;
        }
        this.setState({
            [fieldName]: event.target ? event.target.value : event,
            ...(duplicating ? { duplicating } : {})
        } as unknown as ArticleCardState);
    }
    componentWillReceiveProps(newProps: ArticleCardProps) {
        if (newProps.article != this.state.article) {
            this.setState({ article: newProps.article });
        }
    }
    _onArticleFieldChange(fieldName: keyof Mongo.clientArticles, event) {
        const { article } = this.state;
        let suggested: boolean | null = null;
        if (fieldName == "status" && article.status == "suggested") {
            suggested = true;
        }
        article[fieldName] = (event.target ? event.target.value : event) as never;

        if (fieldName == "status") {
            if ((article["status"] == "approved" || article["status"] == "declined") && suggested) {
                article.activeDate = new Date();
            }
            this.setState(
                {
                    article
                },
                () => this._onUpdate()
            );
        } else {
            this.setState({ article });
        }
    }
    _toggleFullContent() {
        this.setState({
            fullContentOpen: !this.state.fullContentOpen
        });
    }
    _onUpdate() {
        this.setState({ saving: true });
        const article = { ...this.state.article };
        apiRequest(`${appConfig.API_URL}/updateArticleForClientStream`, `POST`, article)
            .then(
                data => {
                    this.setState({
                        saving: false,
                        dropdownOpen: false,
                        remove: this.state.article.sid != this.state.stream?.sid
                    });
                }
            )
            .then(
                data => {
                    if (this.props.editAction) {
                        this.props.editAction();
                    }
                }
            );
    }
    _deleteArticle() {
        if (confirm("Are you sure you want to delete this article?")) {
            apiRequest(
                `${appConfig.API_URL}/deleteArticleForClient?cid=${this.state.article.cid}&artid=${this.state.article.artid}`
            )
                .then(
                    data => {
                        this.setState({ remove: true, dropdownOpen: false });
                    }
                )
                .then(
                    data => {
                        this._runCloseActions();
                    }
                );
        } else {
            this.setState({ dropdownOpen: false });
        }
    }

    _runCloseActions() {
        if (this.props.deleteAction) {
            this.props.deleteAction();
        }
        if (this.props.editAction) {
            this.props.editAction();
        }
    }

    _getAutopostStatus() {
        const { client, stream, article } = this.state;
        const { isNextSequentialShare } = this.props;
        if (
            client &&
            stream &&
            article &&
            client.allowAutopost &&
            stream.allowAutopost &&
            article.status == "approved"
        ) {
            const activeDate = article.activeDate ? new Date(article.activeDate).getTime() : null;
            if (activeDate) {
                if (article.hasScheduled) {
                    // hasScheduled flag means this was successfully scheduled by AP job
                    return (
                        <div
                            className="article-card__approved"
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            Automated user shares were scheduled {getFriendlyTimestamp(activeDate)}
                        </div>
                    );
                } else {
                    if (activeDate <= new Date().getTime() && activeDate >= new Date().getTime() - 30 * 60 * 1000) {
                        // share is going to be scheduled shortly
                        return (
                            <div
                                className="article-card__approved"
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Automated user shares are in the next queue
                            </div>
                        );
                    } else {
                        // this failed to be scheduled, no hasScheduled flag and date has passed
                        return (
                            <div
                                className="article-card__approved"
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                This content can be rescheduled for automated user shares
                            </div>
                        );
                    }
                }
            }
        } else if (
            client &&
            stream &&
            article &&
            client.allowAutopost &&
            stream.sequentialContent &&
            article.status == "approved"
        ) {
            if (article.sequentialOrder && article.hasScheduled) {
                // hasScheduled flag means this was successfully scheduled by AP job
                return (
                    <div
                        className="article-card__approved"
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        This article was automatically shared from position {article.sequentialOrder} in the sequence
                    </div>
                );
            } else if (isNextSequentialShare) {
                return (
                    <div
                        className="article-card__approved"
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        This article is next in the sequence for automated user sharing
                    </div>
                );
            } else if (article.sequentialOrder) {
                return (
                    <div
                        className="article-card__approved"
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        This article is in position {article.sequentialOrder} in the sequence for automated user sharing
                    </div>
                );
            } else {
                return (
                    <div
                        className="article-card__approved"
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        This article is not in the sequence for automated user sharing
                    </div>
                );
            }
        }
        return null;
    }

    _renderDropdown() {
        const { user } = this.props;
        const { dropdownOpen, stream, client, article } = this.state;
        return (
            <div
                className={`dropdown ${dropdownOpen ? "open" : ""} brandPrimary--color`}
                ref={input => {
                    (this as dynamic).dropdownWrapper = input;
                }}
            >
                <ul>
                    {stream && client && (
                        <li>
                            <a href="#" style={{color:"#152733"}} onClick={() => this._onFieldUpdate("showMoreLikeThis", !this.state.showMoreLikeThis)}>More Like This</a>
                        </li>
                    )}
                    {article.artid && (
                        <li>
                            <a href="#" style={{color:"#152733"}} onClick={() => this._onFieldUpdate("editing", true, true)}>Duplicate</a>
                        </li>
                    )}
                    {(user.isSuper || user.isSuperClientAdmin || user.isClientAdmin) && (!client || (client && client.allowNotifications)) && article.status === "approved" && (
                        <li>
                            <a href="#" style={{color:"#152733"}} onClick={() => this._onFieldUpdate("createNotification", true)}>Create Notification</a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
    _renderButtons() {
        const { editing, stream, approved, canApprove, canEdit, article, isBlocked } = this.state;
        const status = article.status;
        if (canApprove) {
            if (approved) {
                return (
                    <div className={`footer__buttons`}>
                        <Button
                            style={{
                                cursor: "default",
                                backgroundColor: "green"
                            }}
                        >
                            Approved&nbsp;
                            <i className="fa fa-check" />
                        </Button>
                    </div>
                );
            } else {
                if (stream && stream.sid && stream.isCustom) {
                    return [
                        <div className={`footer__buttons`}>
                            <Button
                                className="btn"
                                onClick={() => this._onFieldUpdate("editing", true)}
                                disabled={this.state.save_protect}
                            >
                                Customize
                            </Button>
                            <Button className="btn" onClick={this._approveArticle} disabled={this.state.save_protect}>
                                Approve
                            </Button>
                        </div>
                    ];
                } else {
                    return (
                        <div className={`footer__buttons`}>
                            <Button
                                className="btn"
                                onClick={() => this._onFieldUpdate("editing", true)}
                                disabled={this.state.save_protect}
                            >
                                Customize
                            </Button>
                        </div>
                    );
                }
            }
        }
        switch (status) {
            case "new":
            case "pending":
            case "declined":
            case "rejected":
            case "approved":
                if (!canEdit) return;
                if (stream && stream.sid && stream.type == "TRENDING") {
                    if (isBlocked) {
                        return this._renderBlockedButtons();
                    }
                    return [
                        <div className={`footer__buttons`}>
                            <Button
                                className="btn"
                                onClick={() => this._removeTrendingArticle(stream.sid)}
                                disabled={this.state.save_protect}
                            >
                                Remove
                            </Button>
                        </div>
                    ];
                }
                if (isBlocked) {
                    return this._renderBlockedButtons();
                }
                return (
                    <div className={`footer__buttons`}>
                        <Button
                            className="btn"
                            onClick={() => this._onFieldUpdate("editing", !editing)}
                            disabled={this.state.save_protect}
                        >
                            Customize
                        </Button>
                    </div>
                );
            case "suggested":
                return (
                    <div className={`footer__buttons`}>
                        <Button
                            className="btn"
                            onClick={() => this._onFieldUpdate("editing", true)}
                            disabled={this.state.save_protect}
                        >
                            Approve Article
                        </Button>
                        <Button
                            className="btn"
                            onClick={() => this._onArticleFieldChange("status", "declined")}
                            disabled={this.state.save_protect}
                        >
                            Decline Article
                        </Button>
                    </div>
                );
            default:
                return;
        }
    }

    _renderBlockedButtons() {
        return (
            <div className={`footer__buttons`}>
                <Button
                    className="btn"
                    style={{ cursor: "not-allowed", opacity: "0.70" }}
                >Blocked
                    <Tooltip place="top" style={{ paddingLeft: "5px" }}>
                        Please remove this article from the Blocked Content list to customize.
                    </Tooltip>
                </Button>
            </div>
        )
    }

    async componentDidMount() {
        if (document) {
            document.addEventListener("mousedown", this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (document) {
            document.removeEventListener("mousedown", this.handleClickOutside);
        }
    }
    handleClickOutside(event) {
        if (this.state.dropdownOpen) {
            if (
                ((this as dynamic).dropdownWrapper && (this as dynamic).dropdownWrapper.contains(event.target)) ||
                ((this as dynamic).dropdownTools && (this as dynamic).dropdownTools.contains(event.target))
            ) {
                return;
            } else {
                this.setState({ dropdownOpen: false });
            }
        }
    }
    _articleUpdate(isEdit: boolean) {
        this._onFieldUpdate("editing", false);
        if (!isEdit) {
            const article = this.state.article;
            if (!article.status) {
                article.status = "approved";
            }
            this.setState({ article, approved: true });
        }
        if (this.props.editAction) {
            this.props.editAction();
        }
    }

    render() {
        const {
            client,
            editing,
            stream,
            remove,
            showMoreLikeThis,
            article,
            creationInfo
        } = this.state;
        if (remove) {
            return <span />;
        }
        if (!article) {
            return <Spinner />;
        }
        if (editing && !article.Image) {
            article.Image = "";
        }
        return (
            <div className="article-card__container">
                {showMoreLikeThis && (
                    <MoreLikeThis
                        stream={stream}
                        client={client}
                        url={article.Link}
                        hide={() => this.setState({ showMoreLikeThis: false })}
                        canApprove={true}
                        approveAction={this.props.approveAction}
                    />
                )}
                <div className="article-card">
                    <div className="article-card__header">
                        {this._renderTools(article)}
                        {this._renderDropdown()}
                        {this._renderSource()}
                    </div>
                    {article.comment && this._renderComment()}
                    <ArticleContent article={article as Mongo.clientArticles} />
                    <div className="article-card__footer">
                        {this._renderSocialStats()}
                        {this._renderTags()}
                        {(article.activeDate || article.inActiveDate) && (
                            <div className="article-card__approved">
                                {this._renderApprovals(
                                    article.status,
                                    article.activeDate,
                                    article.inActiveDate,
                                    article.lastUpdatedOn
                                )}
                            </div>
                        )}
                        {(creationInfo || article.CreatedBy) && (
                            <div className="article-card__createdOn">{this._renderCreatedBy()}</div>
                        )}
                        {article.firstName && (
                            <div className="article-card__lastModified">{this._renderLastModifiedBy()}</div>
                        )}
                        {this._renderSharingPermissions()}
                        {this._getAutopostStatus()}
                    </div>
                    {this._renderCompliance()}
                    {this._renderButtons()}
                </div>
            </div>
        );
    }
    _renderSocialStats() {
        const { article } = this.state;
        const stats = article.statistics ? article.statistics : null;
        if (!stats) return;

        const statsObject = [
            { label: "likes", idx: 1, icon: "fa fa-heart", data: stats?.likes },
            { label: "comments", idx: 2, icon: "fa fa-comment", data: stats?.comments },
            { label: "clicks", idx: 0, icon: "fa fa-mouse-pointer", data: stats?.clicks },
            { label: "shares", idx: 3, icon: "fa fa-reply fa-flip-horizontal", data: stats?.shares }
        ];

        return (
            <ul className="engagement-stats">
                {
                    stats && statsObject.sort((a, b) => a.idx > b.idx ? 1 : -1).map((item) => {
                        return (
                            <li className="engagement-stats__item">
                                <span tabIndex={0}>{item.data}</span>
                                <Tooltip label={<i className={`${item.icon}`} />} style={{ whiteSpace: "normal" }}>
                                    {item.label}
                                </Tooltip>
                            </li>
                        )
                    })
                }
            </ul>
        );
    }
    _renderSharingPermissions() {
        const { sharingPermissions } = this.state.article;
        const { client }  = this.state;

        if (!sharingPermissions) return;

        const allSharingPermissions: string[] = [];

        if (typeof sharingPermissions === 'object') {
            if (sharingPermissions.social) {
                if (sharingPermissions.socialNetworkPermissions && client?.allowNetworkRestrictions) {
                    const allowedNetworks = Object.keys(sharingPermissions.socialNetworkPermissions)
                        .filter(social => sharingPermissions.socialNetworkPermissions![social])

                    const twitterIndex = allowedNetworks.findIndex(network => network  === 'twitter');
                    if (twitterIndex !== -1) {
                        allowedNetworks[twitterIndex] = 'X (Twitter)'
                    }
                    allSharingPermissions.push(`Social (${allowedNetworks.join(", ")})`);
                } else {
                    allSharingPermissions.push('Social');
                }
            }
            if (sharingPermissions.email) {
                allSharingPermissions.push('Email');
            }
            if (sharingPermissions.sms) {
                allSharingPermissions.push('SMS');
            }
        }
        return (
            <div className="article-card__sharing-permissions">
                <b>This content can be shared to: </b>
                <span className="article-card__sharing-permissions__text">{allSharingPermissions.join(", ")}</span>
            </div>
        )
    }
    _renderCreatedBy() {
        const creator = this.state.creationInfo ? this.state.creationInfo.creator : this.state.article.CreatedBy;
        const date = this.state.creationInfo ? this.state.creationInfo.time : this.state.article.CreatedOn;
        return (
            <span>
                {"Created by " + creator + " "}
                <Tooltip label={getFriendlyDate(date)} style={{ whiteSpace: "normal" }}>{getAdminDate(date)}</Tooltip>
            </span>
        );
    }
    _renderLastModifiedBy() {
        return (
            <span>
                {"Last modified by " + this.state.article.firstName + " "}
                <Tooltip label={getFriendlyDate(this.state.article.lastUpdatedOn!)} style={{ whiteSpace: "normal" }}>
                    {getAdminDate(this.state.article.lastUpdatedOn!)}
                </Tooltip>
            </span>
        );
    }
    _renderSource() {
        const { article } = this.state;
        if (article.source !== "rss" && article.source !== "facebook" && article.source !== "twitter" && article.source !== "linkedIn" && article.source !== "instagram") {
            return;
        }
        return (
            <Tooltip className={`articleBadge ${article.source.toLowerCase()}`} icon={`fa-${article.source.toLowerCase()}`}>
                {article.sourceUrl ? "Sourced from " + article.sourceUrl : ""}
            </Tooltip>
        );
    }
    _renderApprovals(status: string, approve?: string | Date, unapprove?: string | Date, lastUpdatedOn?: string | Date) {
        const friendlyStatus = `${status.charAt(0).toUpperCase()}${status.slice(1).toLowerCase()}`;
        const expiredFriendlyStatus =
            unapprove && new Date(unapprove).getTime() <= new Date().getTime() ? "Expired" : "Expires";

        if (status == "suggested") {
            return (
                <span tabIndex={0}>
                    {friendlyStatus} {getFriendlyDate(lastUpdatedOn!)}
                </span>
            );
        } else if (approve && !unapprove) {
            return (
                <span tabIndex={0}>
                    {friendlyStatus} {getFriendlyDate(approve)}
                </span>
            );
        } else if (!approve && unapprove) {
            return (
                <span tabIndex={0}>
                    {expiredFriendlyStatus} {getFriendlyDate(unapprove)}
                </span>
            );
        } else if (approve && unapprove) {
            return (
                <span tabIndex={0}>
                    {friendlyStatus} {getFriendlyDate(approve)}&nbsp;and {expiredFriendlyStatus.toLowerCase()}{" "}
                    {getFriendlyDate(unapprove)}
                </span>
            );
        } else {
            return <span></span>;
        }
    }
    _renderTools(article: ServerTypes.Console.IArticle) {
        const { stream } = this.state;
        return (
            <div className="article-card__tools">
                {stream && (
                    <span>
                        <b tabIndex={0}>{stream.title}</b>
                    </span>
                )}
                {/* TODO: is hitCount actually ever defined? */}
                {article.hasOwnProperty("hitCount") && <span>{(article as dynamic).hitCount} Clicks</span>}{" "}
                <i
                    ref={input => {
                        (this as dynamic).dropdownTools = input;
                    }}
                    className={`fa fa-ellipsis-v ${this.state.dropdownOpen ? "open" : ""}`}
                    tabIndex={0}
                    onClick={() =>
                        this.setState({
                            dropdownOpen: !this.state.dropdownOpen
                        })
                    }
                    onKeyDown={e => {
                        if (e.which === 13 || e.which === 32) {
                            this.setState({
                                dropdownOpen: !this.state.dropdownOpen
                            })
                        }
                    }}
                />
            </div>
        );
    }
    _renderTags() {
        const { article } = this.state;
        const { Content, Terms } = article;
        if (Terms && Array.isArray(Terms) && Terms.length > 0) {
            return (
                <p className="article-card__tags">
                    {Terms.map((tag, index) => (
                        <span className="article-card__tag" key={tag} tabIndex={0}>
                            {tag.length > 30 ? `${tag.substring(0, 30).trim()}...` : `${tag.trim()}`}
                            {index < Terms.length - 1 ? ", " : ""}
                        </span>
                    ))}
                </p>
            );
        } else if (Content && typeof Content === "string") {
            return (
                <p className="article-card__tags">
                    {Content.split(",")
                        .filter(i => i)
                        .map((tag, index) => (
                            <span className="article-card__tag" key={tag} tabIndex={0}>
                                {tag.trim()}
                                {index < Content.length - 1 ? ", " : ""}
                            </span>
                        ))}
                </p>
            );
        } else {
            return;
        }
    }
    async _removeTrendingArticle(trendingSid: string) {
        if (this.state.client) {
            if (confirm(`By removing this piece of content, it will no longer be visible to users on the trending stream, trending content report, or trending articles section in the weekly and article read emails. All scheduled shares of this piece of content from this stream will be deleted. \n\nNote that this will not remove\n \u2022 scheduled shares of this piece of content from a different stream\n \u2022 scheduled shares of this piece of content that were composed\n \u2022 shares of this piece of content that have already been published to social networks\n\nDo you wish to continue?`)) {
                try {
                    // unschedule all schedules for the article
                    // this.state.sid is the stream where the trending articles came from (not actual trending stream id)
                    // passing the trending stream id in order to unschedule content for users
                    // remove trending article by adding [excludeFromTrending] flag to articles
                    await ArticleActions.doRemoveTrendingArticleByLink(this.state.article.Link, this.state.client.cid, trendingSid, this.state.article.artid);
                    createAlert(`Removing trending article. This may take sometime to complete.`, `success`);
                    this.setState(
                        {
                            remove: true
                        }
                    );
                } catch (error) {
                    // @ts-expect-error
                    createAlert(error);
                }
            }
        }

    }
    _approveArticle() {
        const { stream, client, article } = this.state;
        if (this.state.save_protect) {
            return;
        }
        if (!stream || !stream.sid) {
            createAlert("Please select a stream to curate into");
            return;
        }
        let inactiveDate: string | Date = "";
        if (client) {
            inactiveDate = new Date();
            const articleExpiryDefault = Number(client.articleExpiryDefault);
            inactiveDate.setDate(inactiveDate.getDate() + articleExpiryDefault);
            inactiveDate = inactiveDate.toISOString();
        }
        // comment only is media shareFormat
        const sf = article.shareFormat ? article.shareFormat : article.Link && article.Link.match(/\.(pdf)$/i) ? "pdf" : article.Link ? "article" : "media";
        this.setState(
            {
                save_protect: true
            },
            () => {
                doInsertArticle({
                    cid: client?.cid || "",
                    sid: stream.sid,
                    Link: article.Link,
                    Title: article.Title,
                    Description: article.Description,
                    CreatedOn: article.CreatedOn,
                    Content: article.Content,
                    Image: article.Image,
                    Domain: article.Domain,
                    activeDate: new Date().toISOString() as unknown as Date,
                    inActiveDate: inactiveDate as unknown as Date,
                    comment: article.comment,
                    searchType: stream.searchType,
                    streamCode: stream.code,
                    lang: stream.lang,
                    status: article.status || "new",
                    email: this.props.user.email,
                    firstName: this.props.user.name,
                    shareFormat: sf
                } as Mongo.clientArticles).then(() => {
                    this.setState({ approved: true, dropdownOpen: false, save_protect: false });
                    if (this.props.approveAction) {
                        this.props.approveAction();
                    }
                });
            }
        );
    }
    _renderCompliance() {
        const { client, article } = this.state;
        const status = article.status;
        if (!status || !(client && client.complianceEnabled)) {
            return;
        }
        return (
            <div className={`compliance__ribbon ${status}`}>
                <b>Status:&nbsp;</b>
                {getComplianceMessage(article)}
            </div>
        );
    }

    _renderComment() {
        const { article } = this.state;

        return (
            <div
                className="article-card__comment brandPrimary--border"
                dangerouslySetInnerHTML={{
                    __html: striptags(article.comment)
                }}
            />
        )
    }
}
const mapStateToProps = state => ({ streams: state.lists.streams, user: state.session.admin });

export default connect(mapStateToProps)(ArticleCard);
