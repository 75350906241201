import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import { doSetPriorPage, doClearPage } from "../actions/session";

interface AuthRouteProps extends RouteProps {
    admin: Mongo.clientAdmin;
    authenticated: boolean; // TODO: not sure about this type
    clients?: dynamic<Mongo.client>;
    page: string;
    priorPage: string;
}

const AuthRoute = ({ component, ...props }: AuthRouteProps) => {;
    let isAuthenticated = props.authenticated && props.admin && props.admin.aid;

    const { isPrivate } = component as dynamic;

    // if adminToken has been removed do to logout or session expire, override the isAuthenticated
    if (!localStorage.getItem("adminToken")) {
        isAuthenticated = false;
    }
    if (isAuthenticated) {
        if (isPrivate && props.location!.pathname != "/") {
            if (
                props.location!.pathname != "/" &&
                props.location!.pathname &&
                props.priorPage != props.location!.pathname
            ) {
                setTimeout(() => {
                    doSetPriorPage(props.location!.pathname);
                }, 0);
            }
            return <Route {...props} component={component} />;
        } else {
            if (
                props.page &&
                props.page.trim() &&
                props.page.trim() != "/" &&
                props.location!.pathname != props.page.trim()
            ) {
                setTimeout(() => {
                    doClearPage();
                }, 0);
                return <Redirect to={props.page} />;
            }
            if (props.admin.isApiAdmin) {
                return <Redirect to={"/feeds"} />;
            }
            if (props.admin.isCurator || props.admin.isClientAdmin) {
                return <Redirect to={"/content/l"} />;
            }
            if (props.admin.isAdmin) {
                return <Redirect to={"/users"} />;
            }
            if (props.admin.isSuperClientAdmin) {
                return <Redirect to={`/clients/e/${props.admin.cid}`} />;
            }
            if (props.admin.isSuper) {
                return <Redirect to={"/clients"} />;
            }
            return <Redirect to={"/reports/engagement"} />;
        }
    } else {
        if (isPrivate === true) {
            return <Redirect to={"/login"} />;
        } else {
            return <Route {...props} component={component} />;
        }
    }
};

const mapStateToProps = state => ({
    authenticated: state.session.authenticated,
    page: state.session.page,
    priorPage: state.session.priorPage,
    admin: state.session.admin,
    clients: state.lists.clients
} as Pick<AuthRouteProps, "authenticated" | "page" | "priorPage" | "admin" | "clients">);

export default connect(mapStateToProps)(AuthRoute);
