import React, { Component } from "react";
import { Feed, getLanguages } from "../../../utils";
import { createAlert, FeedActions, UtilActions } from "../../../actions";
import ElementEditCard from "../../molecules/ElementEditCard";
import { history } from "../../../utils/store";

import { CoreAPI } from "../../../../../../types/coreapi";

interface IEditFeedProps {}
interface IEditFeedState {
    id: number;
    countries: Array<{ code: string; name: string }>;
}

export default class EditFeed extends Component<IEditFeedProps, IEditFeedState> {
    static allowSuper = true;
    static allowApiAdmin = true;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);

        let id = -1;
        if (props.match.params.id) {
            id = Number(props.match.params.id);
        }

        this.state = { id, countries: [] };
    }

    componentDidMount() {
        this.loadCountries();
    }

    async loadCountries() {
        this.setState({ countries: await UtilActions.doGetCountries() });
    }

    async _onSubmit(item: CoreAPI.IFeed & { sampleArticleDomain: string }) {
        const feed = Feed(item);
        if (item.id == -1) {
            // @ts-expect-error
            delete item.id;
        }
        feed.subCategory =
            feed.subCategory && !Array.isArray(feed.subCategory)
                ? (((feed.subCategory || "") as unknown) as string).split(",").map(i => i.trim())
                : feed.subCategory || [];

        Object.keys(feed).forEach(key => {
            if (feed[key] == "") {
                feed[key] = null;
            }
        });
        const protocolRegex = /^((http|https|ftp):\/\/)/;
        if (protocolRegex.test(feed.articleDomain) || protocolRegex.test(feed.feedDomain)) {
            createAlert("You cannot use http(s):// or ftp:// in the article domain or the feed domain", "error");
            return;
        }
        if (!feed.url) {
            createAlert("Invalid url", "error");
            return;
        }
        // if feed is active validate else set to feed to inactive
        const validation = feed.active
            ? await FeedActions.doValidate(feed.url, feed.type).catch(() => ({ valid: false }))
            : { valid: false };
        if (!feed.active) {
            feed.lastUpdatedStatus = 905;
        }

        let proceed = true;
        if (validation && ("valid" in validation && !validation.valid)) {
            proceed = confirm(
                `We cannot validate the ${feed.type === "SM" ? "Site Map" : feed.type}. Would you like to proceed?`
            );
        }

        if (proceed) {
            try {
                await FeedActions.doUpsert(feed);
                if (feed.id && feed.id >= 0) {
                    createAlert(`Successfully updated ${feed.id}`, `success`);
                } else {
                    createAlert(`Successfully created feed`, "success");
                }
                setTimeout(() => {
                    history.push("/feeds");
                }, 0);
            } catch (err) {
                //
            }
        }
    }

    render() {
        const { id, countries } = this.state;
        return (
            <ElementEditCard<CoreAPI.IFeed & { sampleArticleDomain: string }>
                getItem={async () => ({
                    sampleArticleDomain: "",
                    ...Feed({ id, ...(id > -1 ? { ...(await FeedActions.doGet(id)) } : null) })
                })}
                canUpdate={() => Promise.resolve(true)}
                update={item => this._onSubmit(item)}
                breadcrumbs={[{ name: "Feeds", link: "/feeds" }]}
                elementName="Feed"
                idField="id"
                isInsert={item => !!(item && item.id == -1)}
                editableKeys={item => {
                    return {
                        active: {
                            label: "Active",
                            type: "toggle"
                        },
                        tcAllowed: {
                            label: "T&C Allowed",
                            type: "toggle"
                        },
                        type: {
                            label: "Type",
                            type: "select",
                            values: [{ label: "RSS", value: "RSS" }, { label: "SiteMap", value: "SM" }]
                        },
                        lang: {
                            label: "Language",
                            type: "select",
                            values: getLanguages().map(lang => ({ label: lang.name, value: lang.code }))
                        },
                        location: {
                            label: "Location",
                            type: "select",
                            values: [
                                { value: "", label: "NONE" },
                                ...countries.map(locations => ({
                                    label: locations.name,
                                    value: locations.code
                                }))
                            ]
                        },
                        region: {
                            label: "Region",
                            type: "select",
                            values: [
                                { value: "", label: "NONE" },
                                { value: 1, label: "Australia & NZ" },
                                { value: 2, label: "Far East" },
                                { value: 3, label: "India" },
                                { value: 4, label: "Middle East & Africa" },
                                { value: 5, label: "Europe" },
                                { value: 6, label: "South America" },
                                { value: 7, label: "East - North America" },
                                { value: 8, label: "Central - North America" },
                                { value: 9, label: "West - North America" },
                                { value: 0, label: "Other" }
                            ]
                        },
                        eventType: {
                            label: "Event Type",
                            type: "select",
                            values: [
                                { value: "", label: "NONE" },
                                { value: "ALL", label: "ALL" },
                                { value: "AGM", label: "AGM - Annual General Meeting" },
                                { value: "BR", label: "BR - Bankruptcy" },
                                { value: "ERN", label: "ERN - Earnings" },
                                { value: "FIN", label: "FIN - Financing" },
                                { value: "IPO", label: "IPO - Inital Public Offerings" },
                                { value: "JV", label: "JV - Joint Ventures" },
                                { value: "MC", label: "MC - Management Chnages" },
                                { value: "MA", label: "MA - Mergers & Acquistions" }
                            ]
                        },
                        url: {
                            label: "URL",
                            type: "text",
                            fullWidth: true
                        },
                        articleDomain: {
                            label: "Article Domain",
                            type: "text",
                            disabled: true,
                            value: item.articleDomain
                        },
                        sampleArticleDomain: {
                            label: "Sample Article URL",
                            type: "text",
                            fullWidth: true,
                            value: item.sampleArticleDomain || "",
                            mutateChange: async (key: string, value: string | boolean | string[] | Date) => {
                                const url = await FeedActions.doGetDomainInfo(value as string);
                                return { feedDomain: url, articleDomain: url, sampleArticleDomain: "" } as Partial<
                                    CoreAPI.IFeed
                                >;
                            }
                        },
                        weight: {
                            label: "Weight",
                            type: "select",
                            values: [
                                { value: 0.1, label: "0.10" },
                                { value: 0.2, label: "0.20" },
                                { value: 0.3, label: "0.30" },
                                { value: 0.4, label: "0.40" },
                                { value: 0.5, label: "0.50" },
                                { value: 0.6, label: "0.60" },
                                { value: 0.7, label: "0.70" },
                                { value: 0.8, label: "0.80" },
                                { value: 0.9, label: "0.90" },
                                { value: 1.0, label: "1.00" }
                            ]
                        },
                        lastUpdatedStatus: {
                            label: "Last Updated Status",
                            type: "select",
                            values: [
                                { value: 200, label: "OK" },
                                { value: 900, label: "Site does not exist anymore" },
                                { value: 901, label: "No feeds/sitemap" },
                                { value: 902, label: "Aggregator" },
                                { value: 903, label: "Subscription/Paid" },
                                { value: 904, label: "No date/Invalid date format" },
                                { value: 905, label: "Inactive, no recent content" },
                                { value: 906, label: "Invalid feed" },
                                { value: 907, label: "Forbidden" },
                                { value: 999, label: "We do not want this" }
                            ]
                        },
                        category: {
                            label: "Category",
                            type: "select",
                            values: [
                                { label: "Arts Entertainment", value: "Arts Entertainment" },
                                { label: "Business", value: "Business" },
                                { label: "Computer Internet", value: "Computer Internet" },
                                { label: "Health", value: "Health" },
                                { label: "Law", value: "Law" },
                                { label: "Lifestyle", value: "Lifestyle" },
                                { label: "Marketing", value: "Marketing" },
                                { label: "News Media", value: "News Media" },
                                { label: "Politics", value: "Politics" },
                                { label: "Recreation Sports", value: "Recreation Sports" },
                                { label: "Science Technology", value: "Science Technology" },
                                { label: "Society Culture", value: "Society Culture" }
                            ]
                        },
                        subCategory: {
                            label: "Sub-Category",
                            type: "text",
                            value:
                                item.subCategory && Array.isArray(item.subCategory) && item.subCategory.length
                                    ? item.subCategory.join(", ")
                                    : item.subCategory || ""
                        },
                        username: {
                            label: "Username",
                            type: "text"
                        },
                        password: {
                            label: "Password",
                            type: "text"
                        }
                    };
                }}
            />
        );
    }
}
