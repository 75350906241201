import { createAlert, doValidateImageURL } from "../../../actions";
import { isURL } from "../../../utils";

export async function canSubmitArticle(
    item: ServerTypes.Console.IArticle | Mongo.INotification,
    isMedia: boolean | undefined,
    fileUpload: File[] | FileList | null,
    client: Mongo.IClient | null | undefined,
    isPdf?: boolean
) {
    const { Image, suggestedComments, comment, Title, video } = item;
    let { Link } = item;
    const hasAdminComment = !!((comment || "").length || (suggestedComments || []).length);

    if (!hasAdminComment && !Image && isMedia) {
        if(client && !client.allowVideoCuration) {
            createAlert(`Please include an image or comment`, `error`);
            return false;
        } else if (!video) {
            createAlert(`Please include an image, video or comment`, `error`);
            return false;
        }
    }

    if (!Link && !isMedia) {
        createAlert(`Please include an article URL`, `error`);
        return false;
    }

    if (!Title) {
        createAlert(`Please include a title`, `error`);
        return false;
    }

    if (Link && !isPdf) {
        Link = Link.trim();

        if (Link.indexOf("http") === -1) {
            Link = "http://" + Link;
        }

        if (!isURL(Link)) {
            createAlert("Please enter a proper url", "error");
            return false;
        }
    }

    if (Image && !fileUpload) {
        const data = await doValidateImageURL(Image);
        if (!data.valid) {
            createAlert("Please enter a valid image url.");
            return false;
        }
    }

    return true;
}