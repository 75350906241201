import React, { Component } from "react";
import { connect } from "react-redux";

import PageHeading from "../../molecules/PageHeading";
import Spinner from "../../atoms/Spinner";
import Table from "../../molecules/Table";
import { createAlert } from "../../../actions/utils";
import { doGetIndustries } from "../../../actions/lists";
import { doRemoveCompany } from "../../../actions/deletes";
import Link from "../../atoms/Link";
import { getIndustries } from "../../../utils/Helpers";
import Button from "../../atoms/Button";
import debounce from "lodash/debounce";
import { Wrapper, Row } from "../../atoms/Layout/";

class IndustryView extends Component {
    constructor(props) {
        super(props);

        this._onSearchChange = this._onSearchChange.bind(this);
        this._refresh = this._refresh.bind(this);
        this._renderTable = this._renderTable.bind(this);
        this.debounce = debounce(this._refresh, 1000);

        this.state = {
            searchQuery: ""
        };
        this.state.industries = getIndustries();
        this.searchTimeout = null;
    }

    componentDidMount() {
        this._refresh();
        this.userInput.focus();
    }

    _onSearchChange(event) {
        let searchQuery = event.target.value;
        this.setState({
            searchQuery
        });
        createAlert('Search query updated', 'success');
    }

    _refresh() {
        doGetIndustries(this.state.searchQuery);
    }

    _renderTable() {
        const { industries, industryLoading } = this.props;

        let displayItems = [];
        let displayDescriptions = [];
        let displayKeys = ["Industry", "Sub-Industry", "Specialities", "X (Twitter) Influencers", "Actions"];

        this.state.industries.map((industry, i) => {
            if (
                this.state.searchQuery &&
                industry.value.toLowerCase().indexOf(this.state.searchQuery.toLowerCase()) == -1
            ) {
                return;
            }
            displayDescriptions.push(
                <div
                    style={{
                        display: "flex"
                    }}
                />
            );
            const indus = Object.values(industries).find(x => x.industry == industry.value);
            if (indus) {
                let item = [
                    <Link to={`/industry/e/${indus.iid}`}>{industry.name}</Link>,
                    (indus.subIndustry || []).join(", "),
                    (indus.specialties.en ? indus.specialties.en : []).join(", "),
                    indus.handles.twitter.map(t => t.name).join(", "),
                    <Button className="btn--sm" to={`/industry/e/${indus.iid}`}>
                        Edit
                    </Button>
                ];
                displayItems.push(item);
            } else {
                let item = [
                    <Link to={`/industry/c/${encodeURIComponent(industry.value)}`}>{industry.name}</Link>,
                    "",
                    "",
                    "",
                    <Button className="btn--sm" to={`/industry/c/${encodeURIComponent(industry.value)}`}>
                        Edit
                    </Button>
                ];
                displayItems.push(item);
            }
        });
        return (
            <Table
                name="Industries"
                keys={displayKeys}
                items={displayItems}
                descriptions={displayDescriptions}
                loading={industryLoading}
            />
        );
    }

    render() {
        const { industries } = this.props;
        if (!industries) {
            return <Spinner />;
        }
        return (
            <Wrapper>
                <PageHeading title={`Industries`} />
                <Row>
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <div className="table__filters">
                                    <div className="table__filters__option">
                                        <label htmlFor="search">Search:</label>
                                        <div className="search">
                                            <input
                                                id="search"
                                                className="admins__search"
                                                ref={input => {
                                                    this.userInput = input;
                                                }}
                                                type="text"
                                                onChange={this._onSearchChange}
                                            />
                                            <i className="fa fa-search" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {this._renderTable()}
                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
}

IndustryView.allowSuper = true;
IndustryView.allowApiAdmin = true;
IndustryView.allowClientAdmin = false;
IndustryView.allowAdmin = false;
IndustryView.allowCurator = false;
IndustryView.allowReports = false;
const mapStateToProps = state => ({
    industries: state.lists.industries,
    industryLoading: state.lists.industryLoading,
    user: state.session.admin
});

export default connect(mapStateToProps)(IndustryView);
