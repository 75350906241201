import React from "react";
import * as twitter from "twitter-text";
interface ISocialLimitsProps {
    client?: Mongo.IClient;
    facebookMaxLength: number;
    linkedInMaxLength: number;
    twitterMaxLength: number;
    url?: string;
    comment?: string;
    addedImage?: boolean;
    image?: string;
    sURLDomain: string;
}
const SocialLimits = (props: ISocialLimitsProps) => {
    const { client, facebookMaxLength, linkedInMaxLength, twitterMaxLength, url, comment, addedImage, image, sURLDomain } = props;
    const renderSocialLimits = (social: "Facebook" | "LinkedIn" | "Twitter", maxLength: number, length: number) => {
        if (!checkSocialNetworks(social)) {
            return [];
        }
        var isExceeding = length > maxLength;
        return [
            <span style={{ color: isExceeding ? 'red' : 'inherit' }}>
                {social === 'Twitter' ? 'X (Twitter)' : social} (<span>{maxLength - length})</span>
            </span>
        ];
    };
    const checkSocialNetworks = (socialNetwork: "Facebook" | "LinkedIn" | "Twitter") => {
        if (client && client.socialNetworks[socialNetwork].available) {
            return true;
        }
        return false;
    };

    return (

        comment ? (
            <span
                style={{
                    marginLeft: "auto"
                }}
            >
                {[
                    ...(renderSocialLimits(
                        "Facebook",
                        facebookMaxLength,
                        comment.length
                    ) || []),
                    ...(checkSocialNetworks("Facebook") && checkSocialNetworks("LinkedIn")
                        ? " | "
                        : []),
                    ...(renderSocialLimits(
                        "LinkedIn",
                        linkedInMaxLength - (addedImage && image && url ? (sURLDomain.length + 7) : 0),
                        comment.length
                    ) || []),
                    ...((checkSocialNetworks("Facebook") ||
                        checkSocialNetworks("LinkedIn")) &&
                    checkSocialNetworks("Twitter")
                        ? " | "
                        : []),
                    ...(renderSocialLimits(
                        "Twitter",
                        twitterMaxLength,
                        twitter.parseTweet(comment).weightedLength
                    ) || [])
                ]}
            </span>
        ) : <span></span>
    );
};
export default SocialLimits;
