import React, { Component } from "react";
import { connect } from "react-redux";

import { getAdminDate, getBooleanIcon } from "../../../../utils/Helpers";
import ElementListBox from "../../../molecules/ElementListBox";
import { Wrapper, Row } from "../../../atoms/Layout";
import { OfferCampaignActions } from "../../../../actions";

interface IOfferListProps {
    user: Mongo.clientAdmin;
}

const OfferList = (props: IOfferListProps) => {
    const removeOffer = async (ocid: string) => {
        return OfferCampaignActions.doDelete(ocid);
    };

    const refresh = async (search: string, cid: string, paginationData) => {
        return OfferCampaignActions.doList({ search, cid, ...paginationData });
    };

    return (
        <Wrapper id="offerCampaigns">
            <Row>
                <ElementListBox<ServerTypes.Console.IOfferCampaign>
                    title="Offer Campaigns"
                    keys={{
                        active: "Active",
                        cid: "Client",
                        name: "Campaign Name",
                        sids: "Streams",
                        type: "Campaign Type",
                        // hide the terms until we have defined terms requriment
                        // terms: "Trigger term(s)",
                        created: "Date created",
                        actions: "Actions"
                    }}
                    defaultSort={{ created: -1 }}
                    filterCid={props.user.isSuper}
                    refresh={({ cid, search }, paginationData) => refresh(search, cid, paginationData)}
                    mutateItem={offerCampaign => ({
                        active: getBooleanIcon(offerCampaign.active),
                        sids: offerCampaign.streamNames?.length ? offerCampaign.streamNames.join(", ") : "All Streams",
                        name: offerCampaign.name,
                        type: offerCampaign.requirements.type,
                        // terms: (offerCampaign.requirements.trigger.terms || []).join(", "),
                        created: getAdminDate(offerCampaign.date_created)
                    })}
                    delete={item => removeOffer(item.ocid)}
                    editLink={`/campaign/offer/e/:cid/:ocid`}
                    createLink={`/campaign/offer/c/:cid`}
                />
            </Row>
        </Wrapper>
    );
};
class ListOfferCampaign extends Component<IOfferListProps> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = true;

    render = () => <OfferList {...this.props} />;
}
const mapStateToProps = state => ({
    user: state.session.admin
});

export default connect(mapStateToProps)(ListOfferCampaign);
