import React, { Component } from "react";
import { Creatable, OptionsType } from "react-select";
export default class CreatableInput extends Component<
    {
        id?: string;
        onUpdate: (items: string[]) => void;
        canUpdate?: (item: string, items: string[]) => boolean;
        promptTextCreator?: (userinput: string) => string;
        noResultsText?: string;
        onInputKeyDown?: (event: KeyboardEvent | React.KeyboardEvent) => void;
        options?: OptionsType;
        style?: dynamic;
        className?: string;
        disabled?: boolean;
        value?: string[];
        formatValue?: (value: string) => { value: string; label: string; className?: string };
        placeholder?: string;
        components?: dynamic;
        isValidNewOption?: () => boolean;
    },
    { items: string[] }
> {
    constructor(props) {
        super(props);
        this._change = this._change.bind(this);
        this._blur = this._blur.bind(this);
        this._onInputKeyDown = this._onInputKeyDown.bind(this);
        this.state = { items: props.value || [] };
    }
    componentWillReceiveProps(newProps) {
        if (this.props.value !== newProps.value) {
            this.setState({ items: newProps.value });
        }
    }
    getMutatedItems() {
        return this.state.items || [];
    }

    formatValue(item: string) {
        return this.props.formatValue ? this.props.formatValue(item) : { value: item, label: item };
    }

    onSave(value?: string) {
        if (!value) {
            return true;
        }
        const canUpdate = this.props.canUpdate
            ? this.props.canUpdate(value, this.state.items)
            : this.state.items.indexOf(value) === -1;
        if (value && canUpdate) {
            this.setState(
                {
                    items: this.state.items.concat(value)
                },
                () => this.props.onUpdate(this.getMutatedItems())
            );
        }
        return false;
    }
    _change(options) {
        const _items: string[] = [];
        if (options) {
            options.forEach(v => {
                if (v.value) {
                    _items.push(v.value);
                }
            });
        }
        const { items } = this.state;
        const newItems = _items.filter(i => items.indexOf(i) === -1);
        if (items.length < _items.length && newItems.length == 1) {
            const item = newItems[0];
            const possibleItems = item.split(",");
            for(const pItem of possibleItems){
                const canUpdate = this.props.canUpdate
                ? this.props.canUpdate(pItem, this.state.items)
                : this.state.items.indexOf(pItem) === -1;
                if (pItem.trim() && canUpdate) {
                    items.push(pItem.trim());
                }
            }
            this.setState({ items }, () => this.props.onUpdate(this.getMutatedItems()));
        } else if (items.length > _items.length) {
            this.setState({ items: _items }, () => this.props.onUpdate(this.getMutatedItems()));
        }
    }
    _blur(event) {
        if ($(event.target).val()) {
            this._change([...this.state.items, $(event.target).val()].map(v => ({ value: v })));
        }
    }
    _onInputKeyDown(event) {
        switch (event.keyCode) {
            case 13:
                if (event.target) {
                    if (this.onSave(($(event.target).val() || "") as string)) {
                        $(event.target).blur();
                    }
                }
                event.preventDefault();
                break;
            default:
                return;
        }
    }
    render() {
        return (
            <Creatable
                inputId={this.props.id}
                id={this.props.id}
                classNamePrefix="select"
                promptTextCreator={this.props.promptTextCreator}
                options={this.props.options}
                className={this.props.className || ""}
                isMulti={true}
                isDisabled={this.props.disabled}
                onChange={this._change}
                noResultsText={this.props.noResultsText}
                onCloseResetsInput={false}
                onBlurResetsInput={false}
                isValidNewOption={this.props.isValidNewOption}
                value={this.state.items && this.state.items.sort((a, b) => a.localeCompare(b)).map(item => this.formatValue(item))}
                onBlur={this._blur}
                placeholder={this.props.placeholder}
                aria-label={this.props.placeholder || {}}
                onInputKeyDown={this.props.onInputKeyDown || this._onInputKeyDown}
                style={this.props.style || {}}
                components={this.props.components}
            />
        );
    }
}
