import { createAlert as _createAlert, IAlertType } from "../actions/utils";
import { history as _history } from "../utils/store";
import appConfig from "../../config/config.dev";

import Axios, { AxiosRequestConfig } from "axios";
import * as querystring from "querystring";
import { getLocaleDate, downloadFile as fileDownload } from "../utils/Helpers";
import { doLogout } from "../actions/session";

export default abstract class BaseAction {
    /**
     * Makes a server request and returns the result.
     * Throws is HTTP error is encountered.  Soft errors from server and simply returned.
     * @param url API endpoint relative url
     * @param method HTTP method.  Default "GET"
     * @param data Data to send to the API endpoint.  Default null
     */
    protected async apiRequest<T = any>(
        url: string,
        method: AxiosRequestConfig["method"] = "GET",
        data: dynamic | null = null
    ) {
        return this._apiRequest<T>({
            method,
            url,
            data,
            headers: {
                Accept: "application/json"
            }
        });
    }

    /**
     * Makes a server request and returns the result.
     * Unlike apiRequest, this function checks the server response for an error.
     * Errors are responses other than http 200 OR if the returned object contains a property called "valid" that is falsey
     * If an error is encountered it will create an error allert for the user using the message returned from the server.
     * This method will return null if an error is encountered and a non falsey value if successful
     * @param url API endpoint relative url
     * @param method HTTP method.  Default "GET"
     * @param data Data to send to the API endpoint.  Default null
     * @param errorMessage Error message to use if a generic system error is encountered (usually http 500 errors).  Default is to show "System Error"
     * @param rethrowError Set to true if you need the error details.  Default false.
     */
    protected async apiRequestWithErrorAlert<T = boolean | null>(
        url: string,
        method: AxiosRequestConfig["method"] = "GET",
        data: dynamic | null = null,
        errorMessage: string | null = null,
        rethrowError = false
    ) {
        try {
            const result = await this._apiRequest<T>({
                method,
                url,
                data,
                headers: {
                    Accept: "application/json"
                }
            });
            const errObj = result as { valid?: false; error?: string } | null;
            if (errObj && "valid" in errObj && !errObj.valid) {
                const error = errObj.error || "System Error";
                throw error;
            }
            return (result || true) as T;
        } catch (error) {
            if (errorMessage && error == "System Error") {
                this.createAlert(errorMessage, "error");
            } else {
                this.createAlert(error, "error");
            }
            if (rethrowError) throw error;
        }
        return null;
    }

    protected async apiFormDataPost<T = any>(url: string, formData: FormData) {
        return this._apiRequest({
            method: "post",
            url,
            data: formData,
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data"
            }
        });
    }

    protected async downloadFile(fileName: string, data) {
        fileDownload(data, `${fileName}-${getLocaleDate(new Date())}.csv`);
    }

    protected createAlert(message: any, type?: IAlertType, expiry?: number) {
        setTimeout(() => {
            _createAlert(message, type, expiry);
        }, 250);
    }

    protected history() {
        return _history;
    }

    private _apiRequest<T = any>(config: AxiosRequestConfig) {
        return this._runRequest(config)
            .then(async response => {
                if (response.data) {
                    // If new auth token is provided by the server then store it in localStorage for subsequent requests
                    if (
                        response.headers &&
                        response.headers.authorization &&
                        typeof response.headers.authorization === "string"
                    ) {
                        const parts = response.headers.authorization.split(" ");
                        if (parts.length === 2) {
                            // const scheme = parts[0];
                            const credentials = parts[1];
                            localStorage.setItem("adminToken", credentials);
                        }
                    }

                    if (typeof response.data == "object" && "valid" in response.data && !response.data.valid) {
                        if ("sessionExpired" in response.data && response.data.sessionExpired) {
                            await doLogout(response.data.error || "Your session has expired. Please log in again.");
                            return null;
                        }
                        throw { response };
                    }
                    return response.data as T;
                }
                return null;
            })
            .catch(error => {
                const err = ((error || {}).response || {}).data || {};

                throw err.error || new Error("An unexpected error occured while communicating with the server.");
            });
    }
    private _runRequest(config: AxiosRequestConfig) {
        const token = localStorage.getItem("adminToken") || "";
        config.headers = config.headers || {};
        config.headers.Authorization = token;

        // let's make it easier to send get requests with parameters.
        // Include the data property (as you would with a post, and
        // it will turn that into the query string for you
        if (config.method && (config.method.toLowerCase() === "get" || config.method.toLowerCase() === "delete")) {
            if (config.data && Object.keys(config.data).length && config.url) {
                config.url = `${config.url}${config.url.indexOf("?") == -1 ? "?" : "&"}${querystring.stringify(
                    config.data
                )}`;
                config.data = undefined;
            }
        }

        if (config.url) {
            config.url = `${appConfig.API_URL}${config.url}`;
        }

        return Axios(config);
    }
}
