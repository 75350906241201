import React, { Component } from "react";
import { connect } from "react-redux";

import { AuditLogActions } from "../../actions";
import ElementListBox, { PaginationData } from "../molecules/ElementListBox";
import { getAdminDate, getFriendlyDate, dateToUTCISOString } from "../../utils";
import Tooltip from "../atoms/Tooltip";
import { Row, Wrapper } from "../atoms/Layout/";

interface IAuditProps {
    clients: { [cid: string]: Mongo.IClient };
    user: Mongo.clientAdmin;
}
interface IAuditState {
    searchParams: ServerTypes.Console.IAuditLogSearchParams | null;
    filters: {
        search?: string | undefined;
        action?: string | undefined;
        object?: string | undefined;
        startDate?: string;
        endDate?: string;
    };
    results: ServerTypes.Console.IAuditLogSearchResponse | null;
    loading: boolean;
}

class AuditLog extends Component<IAuditProps, IAuditState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowSuperClientAdmin = true;
    static allowClientAdmin = false;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;

    constructor(props: IAuditProps) {
        super(props);
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        this.state = {
            searchParams: null,
            filters: {
                startDate: startDate.toISOString(),
                endDate: new Date().toISOString()
            },
            results: { items: [], count: 0 },
            loading: false
        };
    }

    async componentDidMount() {
        this.setState({ loading: true, searchParams: await AuditLogActions.doGetSearchParams() }, () =>
            this.setState({ loading: false })
        );
    }

    async submit(
        search: string,
        cid: string,
        action: string,
        object: string,
        startDate: string,
        endDate: string,
        paginationData: PaginationData
    ) {
        const log = await AuditLogActions.doSearch(
            {
                ...(search ? { search } : null),
                ...(cid ? { cid } : null),
                ...(action ? { action } : null),
                ...(object ? { object } : null),
                startDate: dateToUTCISOString(startDate),
                endDate: dateToUTCISOString(endDate, true)
            },
            paginationData
        );
        if (!log) {
            return { items: [], total: 0 };
        }
        return { items: log.items, total: log.count };
    }

    render() {
        const { searchParams } = this.state;
        return (
            <Wrapper id="audit">
                <Row>
                    <ElementListBox<ServerTypes.Console.IAuditLog>
                        title="Audit Log"
                        filterCid={this.props.user.isSuper}
                        searchLabel="Admin"
                        refresh={({ search, cid, action, object, startDate, endDate }, paginationData) =>
                            this.submit(search, cid, action, object, startDate, endDate, paginationData)
                        }
                        listCount={50}
                        keys={{
                            ...(this.props.user.isSuper ? { cid: "Client" } : null),
                            email: ["Email", <Tooltip>If Email is empty, it is system updated</Tooltip>],
                            object: "Object",
                            action: "Action",
                            details: "Details",
                            date: "Date",
                            download: "Download"
                        }}
                        sortableKeys={["Date"]}
                        defaultSort={{ date: -1 }}
                        defaultFilter={{ startDate: this.state.filters.startDate, endDate: this.state.filters.endDate }}
                        filters={{
                            ...(searchParams && searchParams.objects
                                ? {
                                      object: {
                                          title: "Object",
                                          options: [
                                              { key: "Filter by objects", value: "" },
                                              ...(searchParams.objects || []).map(object => ({
                                                  key: object,
                                                  value: object
                                              }))
                                          ]
                                      }
                                  }
                                : null),
                            ...(searchParams && searchParams.actions
                                ? {
                                      action: {
                                          title: "Actions",
                                          options: [
                                              { key: "Filter by actions", value: "" },
                                              ...(searchParams.actions || []).map(action => ({
                                                  key: action,
                                                  value: action
                                              }))
                                          ]
                                      }
                                  }
                                : null),
                            startDate: {
                                title: "Start Date",
                                defaultDate: this.state.filters.startDate
                            },
                            endDate: {
                                title: "End Date",
                                defaultDate: this.state.filters.endDate
                            }
                        }}
                        mutateItem={log => ({
                            ...(this.props.user.isSuper
                                ? {
                                      cid: (
                                          <span>
                                              {log.cid && this.props.clients && this.props.clients[log.cid]
                                                  ? this.props.clients[log.cid].name
                                                  : !log.cid
                                                  ? "N/A"
                                                  : "Unknown"}
                                          </span>
                                      )
                                  }
                                : null),
                            email: log.email || "",
                            object: log.object,
                            action: log.action,
                            details: log.updated
                                ? JSON.stringify(log.updated)
                                : log.initial
                                ? JSON.stringify(log.initial)
                                : log.filters
                                ? JSON.stringify(log.filters)
                                : "",
                            date: <Tooltip label={getFriendlyDate(log.date)}>{getAdminDate(log.date)}</Tooltip>,
                            download:
                                log.updated && log.initial ? (
                                    // firefox supports navigating to data urls, which displays the data in a much more useful format
                                    // other browsers are stuck with a borderline useless wall of unformatted json
                                    navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ?
                                        <a
                                            className="btn btn--sm brandPrimary--bg"
                                            href={`data:application/json;charset=utf-8,${encodeURIComponent(
                                                JSON.stringify(log.initial)
                                            )}`}
                                            target="_blank"
                                        >
                                            Original
                                        </a> :
                                        <a
                                            className="btn btn--sm brandPrimary--bg"
                                            onClick={event => {
                                                let win = window.open();
                                                win?.document.write('<iframe src="' + `data:application/json;charset=utf-8,${encodeURIComponent(
                                                    JSON.stringify(log.initial)
                                                )}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
                                            }}
                                        >
                                            Original
                                        </a>
                                ) : (
                                    ""
                                )
                        })}
                    />
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    user: state.session.admin,
    clients: state.lists.clients
});

export default connect(mapStateToProps)(AuditLog);
