import React, { Component } from "react";
import { ACCOUNT_ADMIN , createAlert} from "../../../actions";
import { getFriendlyDate, store } from "../../../utils";
import Button from "../../atoms/Button";
import Modal from "./Modal";

interface IStreamSubExpiryProps {
    admin: Mongo.clientAdmin & { expiringStreams?: Mongo.IStream[], expiredFeeds?: string[] };
}

interface IStreamSubExpiryState {
    open: boolean;
    firstModalVisible: boolean,
    secondModalVisible: boolean,
    expiringStreams?: Mongo.IStream[],
    expiredFeeds?: string[]
}
export default class StreamSubExpiry extends Component<IStreamSubExpiryProps, IStreamSubExpiryState> {
    constructor(props: IStreamSubExpiryProps) {
        super(props);

        this.state = {
            open: true,
            expiringStreams: this.props.admin.expiringStreams || [],
            expiredFeeds: this.props.admin.expiredFeeds || [],
            firstModalVisible: true,
            secondModalVisible: !this.props.admin.expiringStreams?.length
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.open !== nextState.open) {
            return true;
        } else if (this.state.firstModalVisible !== nextState.firstModalVisible) {
            return true;
        } else if (this.state.secondModalVisible !== nextState.secondModalVisible) {
            return true;
        }
        return false;
    }

    render() {
        const { open, expiringStreams, expiredFeeds, firstModalVisible, secondModalVisible } = this.state;
        const { ...admin } = this.props.admin;

        // prevent this modal from flickering after refreshing
        store.dispatch({ type: ACCOUNT_ADMIN, admin });

        const now = new Date();
        if (open && firstModalVisible && expiringStreams?.length) {
        return (
            <Modal
                title="Stream subscription expiration notice"
                open={open && firstModalVisible}
                footer={(expiringStreams && expiredFeeds ? (
                    <Button onClick={() => this.setState({ firstModalVisible: false, secondModalVisible: true })}>Done</Button>
                    ) : (
                    <Button onClick={() => this.setState({ open: false })}>Done</Button>
                    )
                )
                }
            >
                The following streams' last periods are expired or about to expire. To add more periods, click the "Edit" button, which will open that stream's settings in a new tab.
                <div className="table-responsive">
                <table className="table table-striped responsive" data-type="responsive">
                    <thead>
                        <tr>
                            <th tabIndex={0}>Title</th>
                            <th tabIndex={0}>End Date</th>
                            <th tabIndex={0}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expiringStreams.map(stream => {
                            const endDate = new Date(stream.subscriptionPeriods![stream.subscriptionPeriods!.length - 1].endDate);
                            return (
                                <tr key={stream.sid}>
                                    <td tabIndex={0}>{stream.title || "N/A"}</td>
                                    <td tabIndex={0} style={endDate < now ? { color: "red" } : {}}>{getFriendlyDate(endDate) || "N/A"}</td>
                                    <td tabIndex={0}>
                                        <Button
                                            className="btn--sm"
                                            to={`/clients/s/${admin.cid}/e/${stream.sid}`}
                                            onClick={() => {
                                                createAlert(`Navigating to the edit page for admin ${stream.title}`,'success');
                                            }}
                                            style={{ width: "100%" }}
                                            target="_blank"
                                        >
                                            <i className="fa fa-edit" /> Edit
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                </div>
            </Modal>
        );
        }
        if (open && secondModalVisible && expiredFeeds && expiredFeeds?.length > 0) {
            return (
                <Modal
                    title="Stream social feed token expiration notice"
                    open={open && secondModalVisible}
                    footer={
                        <Button onClick={() => this.setState({ open: false })}>Done</Button>
                    }
                >
                    The following social feed(s) have an expired token. Please sign in again to the appropriate network to refresh the token. Click the "Edit" button. This will redirect you to the appropriate stream's edit page.
                    <div className="table-responsive">
                    <table className="table table-striped responsive" data-type="responsive">
                        <thead>
                            <tr>
                                <th tabIndex={0}>Network</th>
                                <th tabIndex={0}>Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            {expiredFeeds && (expiredFeeds.map(stream => {
                                const streamArr = stream.split("+");
                                return (
                                    <tr key={streamArr[1]}>
                                        <td tabIndex={0} style={{ textTransform: "capitalize", width: "20%" }}>{streamArr[2] || "N/A"}</td>
                                        <td tabIndex={0} style={{ width: "70%" }}>{streamArr[0] || "N/A"}</td>
                                        <td tabIndex={0}>
                                            <Button
                                                className="btn--sm"
                                                to={`/clients/s/${admin.cid}/e/${streamArr[1]}`}

                                                style={{ width: "100%" }}
                                                onClick={() => {
                                                    this.setState({ open: false });
                                                    createAlert(`Navigating to the edit page for admin ${stream}`,'success');
                                                }}
                                            >
                                                <i className="fa fa-edit" /> Edit
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            }))}
                        </tbody>
                    </table>
                </div>

                </Modal>
            );
        }

        return (<div/>)





    }
}
