import { createStore, combineReducers, applyMiddleware } from "redux";
import { createHashHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { loadState, saveState } from "./sessionStorage";
import { throttle } from "lodash-es";
import { composeWithDevTools } from "@redux-devtools/extension";

import utils from "../reducers/utils";
import session from "../reducers/session";
import lists from "../reducers/lists";

export const history = createHashHistory();
/*
 * Allows us to persist state across app refreshes
 */
const persistedState = loadState();
if (persistedState && "utils" in persistedState) {
    delete persistedState["utils"];
}

const composeEnhancer = composeWithDevTools({});

export const store = createStore(
    combineReducers({
        session,
        lists,
        utils,
        router: connectRouter(history)
    }),
    persistedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history)))
);

store.subscribe(
    throttle(() => {
        saveState(store.getState());
    }, 1000)
);
