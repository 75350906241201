import React, { Component } from "react";

import { doGetClientList } from "../../../actions/lists";
import { getFriendlyDate, getBooleanIcon, translateLanguage } from "../../../utils/Helpers";
import Link from "../../atoms/Link";
import ElementListBox from "../../molecules/ElementListBox";
import { Wrapper, Row } from "../../atoms/Layout/";

export default class Clients extends Component {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);
        this._refresh = this._refresh.bind(this);
    }

    async _refresh({ search, isActive, lang, complianceEnabled }, paginationData) {
        return doGetClientList({
            ...(search ? { search } : null),
            ...(isActive && isActive !== "" ? { isActive: isActive == 1 } : null),
            ...(complianceEnabled >= 0 && complianceEnabled != ""
                ? { complianceEnabled: complianceEnabled == 1 }
                : null),
            ...(lang ? { lang } : null),
            ...paginationData
        });
    }

    render() {
        return (
            <Wrapper id="client">
                <Row>
                    <ElementListBox<Mongo.IClient>
                        title="Clients"
                        keys={{
                            isActive: "Active",
                            name: "Name",
                            lang: "Language",
                            lastUpdatedOn: "Last Updated",
                            actions: "Actions"
                        }}
                        sortableKeys={["Name", "Language", "Last Updated"]}
                        defaultSort={{ lastUpdatedOn: -1 }}
                        refresh={({ search, isActive, lang, complianceEnabled }, paginationData) =>
                            this._refresh({ search, isActive, lang, complianceEnabled }, paginationData)
                        }
                        mutateItem={client => ({
                            isActive: getBooleanIcon(client.isActive),
                            name: <Link to={`/clients/e/${client.cid}`}>{client.name}</Link>,
                            lang: translateLanguage(client.lang),
                            lastUpdatedOn: client.lastUpdatedOn ? getFriendlyDate(client.lastUpdatedOn)! : "N/A"
                        })}
                        actions={[{ link: "/clients/s/:cid", text: "Stream Configuration", icon: "fa-th-list" }]}
                        editLink={`/clients/e/:cid`}
                        createLink={`/clients/c`}
                        filters={{
                            isActive: {
                                title: "Filter By Enabled",
                                options: [
                                    { key: "All", value: "" },
                                    { key: "Enabled", value: "1" },
                                    { key: "Disabled", value: "0" }
                                ]
                            },

                            lang: {
                                title: "Filter By Language",
                                options: [
                                    { key: "All", value: "" },
                                    { key: "English", value: "en" },
                                    { key: "French", value: "fr" },
                                    { key: "Spanish", value: "es" },
                                    { key: "German", value: "de" },
                                    { key: "Portuguese", value: "pt" },
                                    { key: "Italian", value: "it" }
                                ]
                            },
                            complianceEnabled: {
                                title: "Filter By Compliance Status",
                                options: [
                                    { key: "All", value: "" },
                                    { key: "Enabled", value: "1" },
                                    { key: "Disabled", value: "0" }
                                ]
                            }
                        }}
                    />
                </Row>
            </Wrapper>
        );
    }
}
