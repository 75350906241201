import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../../atoms/Spinner";
import DownloadButton from "../../../atoms/DownloadButton";
import Tooltip from "../../../atoms/Tooltip";

import { apiRequest, getLocaleDate, downloadFile as fileDownload, hasEmvPermission } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";

import { createAlert } from "../../../../actions/utils";
import Chart from "../../../molecules/Chart";
import { Wrapper, Row } from "../../../atoms/Layout";
import { IReportFilter } from "../Index";

interface IUserSharingProps {
    filters: IReportFilter;
    onLoaded: () => void;
    user: Mongo.clientAdmin;
    client?: Mongo.client;
    showSearchResults: boolean;
}
interface IUsersSharingState {
    userSharingSort: string;
    userSharingDirection: number;
    userSharing: "loading" | dynamic[];
    deviceType: "loading" | dynamic;
}

class UsersView extends Component<IUserSharingProps, IUsersSharingState> {
    constructor(props) {
        super(props);
        this._fetchAll = this._fetchAll.bind(this);
        this._renderUsers = this._renderUsers.bind(this);

        this.state = {
            userSharingSort: "Clicks",
            userSharingDirection: -1,
            userSharing: "loading",
            deviceType: "loading",
        };
    }

    componentDidMount() {
        if (!this.props.showSearchResults) return;
        this._fetchAll(this.props);
    }

    componentWillReceiveProps(newProps: IUserSharingProps) {
        if ((newProps.filters != this.props.filters) || (!this.props.showSearchResults && newProps.showSearchResults)) {
            this._fetchAll(newProps);
        }
    }

    _fetchAll(props) {
        this.setState({ userSharing: "loading", deviceType: "loading" }, () => {
            Promise.all([
                this._fetchData("userSharing", props).then(data => {
                    this.setState({ userSharing: data });
                }),
                this._fetchDeviceType(props).then(data => {
                    this.setState({ deviceType: data });
                })
            ]).finally(props.onLoaded);
        });
    }

    _fetchData(type, props, format = "json") {
        const sorting = {
            ...props.filters,
            sort: this.state[type + "Sort"],
            sortDirection: this.state[type + "Direction"]
        };
        return apiRequest(
            `${appConfig.API_URL}/reports/user/${type}/${format}`,
            "Post",
            sorting
        )
            .then(data => {
                if (data && typeof data === "object" && (data.error || ("valid" in data && !data.valid))) {
                    throw data.error;
                }
                // tslint:disable-next-line: no-string-throw
                if (!data) throw "No data available for this report";
                if (format == "csv") {
                    fileDownload(data, `usersharing-${getLocaleDate(new Date())}-${type == "articlesShared" ? "contentShared" : type}.csv`);
                }
                return data;
            })
            .catch(error => {
                createAlert(error, `error`);
                return [];
            });
    }

    _fetchDeviceType(props) {
        return apiRequest(`${appConfig.API_URL}/reports/deviceType`, "Post", props.filters)
            .then(data => {
                if (typeof data === "object" && (data.error || ("valid" in data && !data.valid))) {
                    throw data.error;
                }
                return data;
            })
            .catch(error => {
                createAlert(error, `error`);
                return [];
            });
    }

    _sort(sortColumn: string, sortDirection: -1 | 1) {
        this.setState(
            { userSharing: "loading", userSharingSort: sortColumn, userSharingDirection: sortDirection },
            () => this._fetchData("userSharing", this.props).then(data =>
                this.setState({ userSharing: data })
            )
        );
    }

    _renderSortIcons(column: string, defaultDirection: 1 | -1 = 1) {
        const sort = this.state.userSharingSort;
        const sortDirection = this.state.userSharingDirection;
        if (sort == column) {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={e => this._sort(column, sortDirection == 1 ? -1 : 1)}
                    onKeyDown={e => {
                        if (e.which === 13 || e.which === 32) {
                            this._sort(column, sortDirection == 1 ? -1 : 1)
                        }
                    }}
                >
                    {column}
                    <i
                        className={`fa ${sortDirection == 1 ? "fa-sort-up" : "fa-sort-down"}`}
                        tabIndex={0}
                        role="img"
                        aria-label={`Sort ${sortDirection == 1 ? "ascending" : "descending"}`}
                    />
                </span>
            );
        }
        return (
            <span
                style={{ cursor: "pointer" }}
                onClick={e => this._sort(column, defaultDirection)}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        this._sort(column, defaultDirection)
                    }
                }}
            >
                {column}
                <i
                    className="fa fa-sort"
                    tabIndex={0}
                    role="img"
                    aria-label="Sort ascending or descending"
                />
            </span>
        );
    }

    _renderLoadingCard(content: string | JSX.Element, loadingClass: string) {
        return (
            <div className={loadingClass}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            Top 20 Publishers
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderUsers() {
        const { userSharing } = this.state;
        if (!this.props.showSearchResults) {
            return this._renderLoadingCard(<i>To run this report, please adjust the filters above and click "Search"</i>, "col-lg-12");
        } else if (userSharing === "loading") {
            return this._renderLoadingCard(<Spinner />, "col-lg-12");
        }
        return (
            <div className="col-lg-12">
                <div className="ibox float-e-margins">
                    <div
                        className="ibox-title"
                        style={{
                            paddingTop: "7px",
                            display: "flex"
                        }}
                    >
                        <h5 >Top 20 Publishers</h5>
                        <span style={{ marginLeft: "auto" }}>
                            {!(this.props.filters.clientCode.indexOf("ALL_") == 0) && (
                                <DownloadButton
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "0",
                                        width: "175px"
                                    }}
                                    className="btn--sm"
                                    onClick={e => this._fetchData("articlesShared", this.props, "csv")}
                                >
                                    <i className="fa fa-download" /> Download content shared
                                </DownloadButton>
                            )}
                            {!(this.props.filters.clientCode.indexOf("ALL_") == 0) && (
                                <DownloadButton
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "0",
                                        width: "175px"
                                    }}
                                    className="btn--sm"
                                    onClick={e => this._fetchData("socialStats", this.props, "csv")}
                                >
                                    <i className="fa fa-download" /> Download social stats
                                </DownloadButton>
                            )}
                            <DownloadButton
                                style={{
                                    marginLeft: "5px",
                                    marginBottom: "0",
                                    width: "175px"
                                }}
                                className="btn--sm"
                                onClick={e => this._fetchData("userSharing", this.props, "csv")}
                            >
                                <i className="fa fa-download" /> Download user sharing
                            </DownloadButton>
                            <Tooltip style={{ marginLeft: "10px", verticalAlign: "middle" }}>
                                <p>
                                    A breakdown of sharing by user between the selected start and end dates. Results
                                    can be filtered by group. Note: only users with the ability to share will be
                                    seen on this page.
                                </p>
                                <p>
                                    Download social stats: A comparison between the size of users' social networks
                                    currently and when they first connected their social account to LiveSocial.
                                    Refreshed every day at 00:00UTC
                                </p>
                                <p>
                                    Download user sharing: Breaks user sharing down a step further by the networks
                                    to which articles were shared. Likes and comments are updated as of a day before
                                </p>
                                <p>
                                    Download content shared: downloads a list of all shares and the various
                                    attributes associated with these shares, performed by all users based on the set
                                    date/time range. This report is pulled upon request and based on the latest
                                    available data.
                                </p>
                                <p>Content: a single piece of content (i.e. text, video, image)</p>
                                <p>Share: a single article posted to a particular network</p>
                                <p>
                                    Social Interactions: total likes and comments across all shares done within the
                                    time period
                                </p>
                                <p>
                                    Video Views: LinkedIn Personal and X (Twitter) are not supported.
                                </p>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="ibox-content" style={{ paddingTop: "0px" }}>
                        <div style={{ backgroundColor: "#9d6a2c", color: "#fff", padding: "10px", marginBottom: "19px" }}><i className="fa fa-warning"></i> Only the Top 20 users for the selected period are shown on this dashboard. Click on the ‘Download User Sharing’ button to get the full report</div>
                        {!userSharing?.length
                            ? <div style={{ marginTop: "20px" }}> <i>Could not find any data for this report</i> </div>
                            :
                            <div>
                                <div className="table-responsive">
                                    <table className="table table-striped responsive" data-type="responsive">
                                        <thead>
                                            <tr>
                                                <th tabIndex={0}>{this._renderSortIcons("First name")}</th>
                                                <th tabIndex={0}>{this._renderSortIcons("Last name")}</th>
                                                <th tabIndex={0}>{this._renderSortIcons("Email address")}</th>
                                                {this.props.filters.clientCode &&
                                                    this.props.filters.clientCode == "ALL_CLIENTS" && (
                                                        <th>Client</th>
                                                    )}
                                                <th tabIndex={0}>Role</th>
                                                <th tabIndex={0}>Group</th>
                                                <th tabIndex={0}>{this._renderSortIcons("Content", -1)}</th>
                                                <th tabIndex={0}>{this._renderSortIcons("Shares", -1)}</th>
                                                <th tabIndex={0}>{this._renderSortIcons("Clicks", -1)}</th>
                                                <th tabIndex={0}>{this._renderSortIcons("Total readers", -1)}</th>
                                                <th tabIndex={0}>{this._renderSortIcons("Repeat readers", -1)}</th>
                                                <th tabIndex={0}>Click to content ratio</th>
                                                <th tabIndex={0}>{this._renderSortIcons("Social interactions", -1)}</th>
                                                <th tabIndex={0}>Logged In</th>
                                                {hasEmvPermission(this.props.user) && <th>Earned Media Value</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userSharing &&
                                                userSharing.length > 0 &&
                                                userSharing.map((user, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td tabIndex={0}>
                                                                <span>{user["First name"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Last name"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Email address"] || 'N/A'}</span>
                                                            </td>
                                                            {this.props.filters.clientCode == "ALL_CLIENTS" && (
                                                                <td tabIndex={0}>
                                                                    <span>{user["Client"] || 'N/A'}</span>
                                                                </td>
                                                            )}
                                                            <td tabIndex={0}>
                                                                <span>{user["Role"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Group"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Content"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Shares"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Clicks"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Total readers"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Repeat readers"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Click to content ratio"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Social interactions"] || 'N/A'}</span>
                                                            </td>
                                                            <td tabIndex={0}>
                                                                <span>{user["Logged In"] || 'N/A'}</span>
                                                            </td>
                                                            {hasEmvPermission(this.props.user) && (
                                                                <td tabIndex={0}>
                                                                    <span>{user["Earned Media Value"] ? `$${user["Earned Media Value"]}` : 'N/A'}</span>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    _renderDeviceType() {
        const { deviceType } = this.state;
        const data: number[] = [];
        const labels: string[] = [];
        if (deviceType && deviceType instanceof Object) {
            Object.keys(deviceType).forEach(k => {
                labels.push(k);
                data.push(deviceType[k]);
            });
        }
        return (
            <div className="col-lg-12">
                <Chart
                    title={"User Device Type"}
                    loading={typeof deviceType == "string" && deviceType.indexOf("loading") !== -1}
                    data={data}
                    labels={labels}
                    type="pie"
                    tooltip={
                        <span>
                            <p>
                                This graph shows the device(s) your users use, within the filtered date range. Refreshed every day at 00:00UTC.
                                <br />
                                Users who have only activated their account without any other activity will not be reported in this graph.
                            </p>
                            <p>
                                Note: the query only checks whether the user is using the website or the app.
                                <br />
                                If the publisher uses LiveSocial through their web browser on their phone, they are counted as a web publisher.
                            </p>
                            <p>
                                As LiveSocial is optimized for the mobile experience, ideally most of your publishers should be under the App or Both category.
                                <br />
                                If most of your publishers are Web, we recommend promoting the app so your publishers get the full experience of the platform
                            </p>
                        </span>
                    }
                    showSearchResults={this.props.showSearchResults}
                />
            </div>
        );
    }

    render() {
        return (
            <Wrapper id="user">
                <Row>{this._renderUsers()}</Row>
                <Row>{this._renderDeviceType()}</Row>
            </Wrapper>
        );
    }
}
const mapStateToProps = state => ({ user: state.session.admin });
export default connect(mapStateToProps)(UsersView);
