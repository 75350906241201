import React from "react";
import Modal from "react-bootstrap-modal";
import { connect } from "react-redux";
import { createAlert } from "../../actions/utils";
import { changeAdminPassword } from "../../actions/session";
import Button from "../atoms/Button";

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._submit = this._submit.bind(this);

        this.state = {
            open: true,
            oldPass: "",
            newPass: "",
            confirmPass: "",
            passwordLowerCase: null,
            passwordUpperCase: null,
            passwordNumber: null,
            passwordSpecialCharacter: null,
            passwordLength: null
        };
    }
    _onChange(field, value) {
        if (field == "newPass") {
            if ((/[a-z]/.test(value))) {
                this.setState({ passwordLowerCase: 0 });
            }else if(!(/[a-z]/.test(value))) {
                this.setState({ passwordLowerCase: 1 });
            }

            if ((/[A-Z]/.test(value))) {
                this.setState({ passwordUpperCase: 0 });
            }else if(!(/[A-Z]/.test(value))) {
                this.setState({ passwordUpperCase: 1 });
            }

            if ((/[0-9]/.test(value))) {
                this.setState({ passwordNumber: 0 });
            }else if(!(/[0-9]/.test(value))) {
                this.setState({ passwordNumber: 1 });
            }

            if ((/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value))) {
                this.setState({ passwordSpecialCharacter: 0 });
            }else if(!(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value))) {
                this.setState({ passwordSpecialCharacter: 1 });
            }

            if (value.length >= 8) {
                this.setState({ passwordLength: 0 });
            }else if(value.length < 8 && value.length > 0) {
                this.setState({ passwordLength: 1 });
            }else if(value.length == 0) {
                this.setState({ passwordLowerCase: null, passwordUpperCase: null, passwordNumber: null, passwordSpecialCharacter: null, passwordLength: null });
            }
        }
        this.setState({ [field]: value });
    }
    async _submit() {
        let { oldPass, newPass, confirmPass } = this.state;
        if (newPass != confirmPass) {
            createAlert("Your new passwords do not match with each other");
            return;
        }
        if (oldPass == newPass) {
            createAlert("The new password must be different than the previous password. Please enter a new password");
            return;
        }
        if (this.state.passwordLowerCase == 0 && this.state.passwordUpperCase == 0 && this.state.passwordNumber == 0 && this.state.passwordSpecialCharacter == 0 && this.state.passwordLength == 0) {
            const success = await changeAdminPassword(oldPass, newPass);
            if (success) {
                createAlert("Successfully changed your password", "success");
                this._closeModal();
            }
        }
    }
    _closeModal() {
        this.setState({ open: false });
        if (this.props.closeAction) {
            this.props.closeAction();
        }
    }
    render() {
        const { oldPass, newPass, confirmPass, open } = this.state;

        return (
            <Modal show={open} onHide={this._closeModal} aria-labelledby="ModalHeader">
                <Modal.Header
                    closeButton={this.props.user && "isInitial" in this.props.user && !this.props.user.isInitial}
                >
                    <Modal.Title id="ModalHeader">Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}
                    >
                        {!(this.props.user && "isInitial" in this.props.user && this.props.user.isInitial) && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexBasis: "100%",
                                    marginBottom: "10px"
                                }}
                            >
                                <label htmlFor="oldPass">Old Password:</label>
                                <input
                                    ref={input => {
                                        this.oldPass = input;
                                    }}
                                    id="oldPass"
                                    type="password"
                                    value={oldPass}
                                    onChange={event => this._onChange("oldPass", event.target.value)}
                                />
                            </div>
                        )}
                        {(this.props.user && "isInitial" in this.props.user && this.props.user.isInitial) && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexBasis: "100%",
                                    marginBottom: "10px"
                                }}
                            >
                                <p>Please enter your new Password.</p>
                            </div>
                        )}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexBasis: "47.5%",
                                marginBottom: "10px"
                            }}
                        >
                            <label htmlFor="newPass">New Password:</label>
                            <input
                                ref={input => {
                                    this.newPass = input;
                                }}
                                id="newPass"
                                type="password"
                                value={newPass}
                                onChange={event => this._onChange("newPass", event.target.value)}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexBasis: "47.5%",
                                marginLeft: "auto",
                                marginBottom: "10px"
                            }}
                        >
                            <label htmlFor="verifyPass">Verify New Password:</label>
                            <input
                                ref={input => {
                                    this.verifyPass = input;
                                }}
                                id="verifyPass"
                                type="password"
                                value={confirmPass}
                                onChange={event => this._onChange("confirmPass", event.target.value)}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexBasis: "100%"
                            }}
                        >
                            <label>
                                <span>Your password must contain:</span>
                                <span className="criteria">
                                    <div
                                        style={{
                                            marginTop: "6px",
                                            marginLeft: "9px"
                                        }}
                                    >
                                        <p className={`${this.state.passwordLowerCase == 0 ? "criteriaMet" : this.state.passwordLowerCase == 1 ? "criteriaNotMet" : ""}`}>
                                            {this.state.passwordLowerCase == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordLowerCase == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                            A lowercase letter
                                        </p>
                                        <p className={`${this.state.passwordUpperCase == 0 ? "criteriaMet" : this.state.passwordUpperCase == 1 ? "criteriaNotMet" : ""}`}>
                                            {this.state.passwordUpperCase == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordUpperCase == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                            An uppercase letter
                                        </p>
                                        <p className={`${this.state.passwordNumber == 0 ? "criteriaMet" : this.state.passwordNumber == 1 ? "criteriaNotMet" : ""}`}>
                                            {this.state.passwordNumber == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordNumber == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                            A number
                                        </p>
                                        <p className={`${this.state.passwordSpecialCharacter == 0 ? "criteriaMet" : this.state.passwordSpecialCharacter == 1 ? "criteriaNotMet" : ""}`}>
                                            {this.state.passwordSpecialCharacter == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordSpecialCharacter == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                            A special character
                                        </p>
                                        <p className={`${this.state.passwordLength == 0 ? "criteriaMet" : this.state.passwordLength == 1 ? "criteriaNotMet" : ""}`}>
                                            {this.state.passwordLength == 0 ? <i className="ion-checkmark-circled"/> : this.state.passwordLength == 1 ? <i className="ion-close-circled"/> : <b>&bull;</b>}&nbsp;&nbsp;
                                            At least 8 characters
                                        </p>
                                    </div>
                                </span>
                            </label>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexBasis: "20%",
                                marginTop: "10px",
                                marginLeft: "auto"
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this._submit()}>Submit</Button>
                    {this.props.user && "isInitial" in this.props.user && !this.props.user.isInitial && (
                        <Modal.Dismiss className="btn brandPrimary--bg">Cancel</Modal.Dismiss>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });

export default connect(mapStateToProps)(PasswordReset);
