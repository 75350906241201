import React, { Component } from "react";

import { apiRequest, getLocaleDate, downloadFile as fileDownload, hasEmvPermission } from "../../../../utils/Helpers";
import appConfig from "../../../../../config/config.dev";

import { createAlert } from "../../../../actions/utils";
import DownloadButton from "../../../atoms/DownloadButton";
import Link from "../../../atoms/Link";
import Pagination from "../../../molecules/Pagination";
import { Wrapper, Row } from "../../../atoms/Layout";
import { ReportActions } from "../../../../actions/ReportActions";
import { AdvancedPDFModal } from "../../../molecules/modals/AdvancedPDFModal";
import { connect } from "react-redux";
import { IReportFilter } from "../Index";
import Spinner from "../../../atoms/Spinner";
import Tooltip from "../../../atoms/Tooltip";

interface ITopArticles {
    notes?: string;
    Title: string;
    articleID: string;
    URL: string;
    isPDF: boolean;
    Domain: string;
    Composed: boolean;
    Terms: string;
    Clicks: number;
    Shares: number;
    Likes: number;
    Comments: number;
    streamTitle: string;
    CuratorName: string;
    CuratorEmail: string;
    Interactions: number;
}

interface IArticlesProps {
    filters: IReportFilter;
    onLoaded: () => void;
    client?: Mongo.client;
    user: Mongo.clientAdmin;
    showSearchResults: boolean;
}

interface IArticlesState {
    topArticles: "loading" | ITopArticles[];
    topArticlesSort: string;
    topArticlesDirection: number;
    topArticlesLimit: number;
    topArticlesPage: number;
    topArticlesTotal: number;
    open: boolean;
    openModal: string;
}

class ArticlesSection extends Component<IArticlesProps, IArticlesState> {
    constructor(props: IArticlesProps) {
        super(props);
        this._request = this._request.bind(this);
        this._fetchAll = this._fetchAll.bind(this);

        this._renderArticles = this._renderArticles.bind(this);
        this._renderAdvancedPDF = this._renderAdvancedPDF.bind(this);
        this._openModal = this._openModal.bind(this);

        this.state = {
            topArticles: "loading",
            topArticlesSort: "Clicks",
            topArticlesDirection: -1,
            topArticlesLimit: 20,
            topArticlesPage: 0,
            topArticlesTotal: 0,
            open: false,
            openModal: ""
        };
    }

    componentDidMount() {
        if (!this.props.showSearchResults) return;
        this._fetchAll(this.props);
    }

    componentWillReceiveProps(newProps: IArticlesProps) {
        if ((newProps.filters != this.props.filters) || (!this.props.showSearchResults && newProps.showSearchResults)) {
            this.setState({ topArticlesPage: 0 }, () => this._fetchAll(newProps));
        }
    }

    _fetchAll(props: IArticlesProps) {
        this._request("topArticles").finally(props.onLoaded);
    }

    _request(type: "topArticles") {
        return new Promise<void>(resolve =>
            this.setState({ [type]: "loading" }, () =>
                this._fetchData(type).then(data =>
                    this.setState({ [type]: data.items, [type + "Total"]: data.count } as Pick<
                        IArticlesState,
                        keyof IArticlesState
                    >)
                ).finally(resolve)
            )
        )
    }

    _refresh(newPage = 0) {
        this.setState({ topArticlesPage: newPage }, () => {
            this._fetchAll(this.props);
        });
    }

    _fetchData(type: "topArticles" | "sharesByMonth", format = "json") {
        const sorting = {
            ...this.props.filters,
            sort: this.state[type + "Sort"],
            sortDirection: this.state[type + "Direction"],
            limit: this.state[type + "Limit"],
            skip: this.state[type + "Limit"] * this.state[type + "Page"]
        };
        return apiRequest(`${appConfig.API_URL}/reports/articles/${type}/${format}`, "Post", sorting)
            .then(data => {
                if (typeof data === "object" && (data.error || data.valid === false)) {
                    throw data.error;
                }
                if (format == "csv") {
                    fileDownload(
                        data,
                        `${type === "topArticles" ? "articles" : type}-${getLocaleDate(new Date())}.csv`
                    );
                }
                return data;
            })
            .catch(error => {
                createAlert(error, `error`);
                return [];
            });
    }


    _sort(type: "topArticles", sortColumn: string, sortDirection: number) {
        this.setState(
            { [`${type}Page`]: 0, [`${type}Sort`]: sortColumn, [`${type}Direction`]: sortDirection } as unknown as Pick<
                IArticlesState,
                keyof IArticlesState
            >,
            () => {
                this._request(type);
            }
        );
    }

    _renderSortIcons(type: "topArticles", column: string, defaultDirection = 1) {
        const sort = this.state[type + "Sort"];
        const sortDirection = this.state[type + "Direction"];
        if (sort == column) {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={e => this._sort(type, column, sortDirection == 1 ? -1 : 1)}
                    onKeyDown={e => {
                        if (e.which === 13 || e.which === 32) {
                            this._sort(type, column, sortDirection == 1 ? -1 : 1)
                        }
                    }}
                >
                    {column}
                    <i
                        className={`fa ${sortDirection == 1 ? "fa-sort-up" : "fa-sort-down"}`}
                        tabIndex={0}
                        role="img"
                        aria-label={`Sort ${sortDirection == 1 ? "ascending" : "descending"}`}
                    />
                </span>
            );
        }
        return (
            <span
                style={{ cursor: "pointer" }}
                onClick={e => this._sort(type, column, defaultDirection)}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        this._sort(type, column, sortDirection == 1 ? -1 : 1)
                    }
                }}
            >
                {column}
                <i
                    className="fa fa-sort"
                    tabIndex={0}
                    role="img"
                    aria-label="Sort ascending or descending"
                />
            </span>
        );
    }

    _renderAdvancedPDF(art) {
        return (
            <AdvancedPDFModal
                client={this.props.client}
                article={art}
                closeAction={() =>
                    this.setState({ open: false, openModal: "" })
                }
                filters={this.props.filters}
                open={!!(this.state.openModal && (this.state.openModal == art.Title || this.state.openModal == art.URL))}
            />
        );
    }

    _openModal(title) {
        this.setState({ openModal: title });
    }

    _renderLoadingCard(content: string | JSX.Element, loadingClass: string) {
        return (
            <div className={loadingClass}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            Articles By {this.state.topArticlesSort}
                        </h5>
                        <Tooltip style={{ marginLeft: "10px", verticalAlign: "middle", fontWeight: "normal" }}>
                            {"If an article has been curated multiple times (meaning it uses the same link as others)," + "\n" +
                                "then the Article Performance report will display the stats of all of these articles under" + "\n" +
                                "the title of the article that was shared first within the filtered time period."}
                        </Tooltip>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderArticles() {
        const { topArticles, topArticlesSort, topArticlesLimit, topArticlesPage, topArticlesTotal } = this.state;
        const { client, showSearchResults, user } = this.props;
        if (!showSearchResults) {
            return this._renderLoadingCard(<i>To run this report, please adjust the filters above and click "Search"</i>, "col-lg-12");
        } else if (topArticles === "loading") {
            return this._renderLoadingCard(<Spinner />, "col-lg-12");
        }
        return (
            <div className="col-lg-12">
                <div className="ibox float-e-margins">
                    <div
                        className="ibox-title"
                        style={{
                            paddingTop: "7px",
                            display: "flex"
                        }}
                    >
                        <h5>Articles By {topArticlesSort}
                        </h5>
                        <span style={{ marginLeft: "auto" }}>
                            {this.props.filters.clientCode.indexOf("ALL_") != 0 && this.props.client && this.props.client.allowContentSpecificPermissions && <DownloadButton
                                style={{
                                    marginLeft: "5px",
                                    marginBottom: "0",
                                    width: "auto"
                                }}
                                className="btn--sm"
                                onClick={e => ReportActions.doDownloadArticleReadsReport({ ...this.props.filters })}
                            >
                                <i className="fa fa-download" /> Download read-only activity
                            </DownloadButton>}

                            {this.props.filters.clientCode.indexOf("ALL_") != 0 &&
                                this.props.filters.sid.indexOf("ALL_") != 0 && (
                                    <DownloadButton
                                        style={{
                                            marginLeft: "5px",
                                            marginBottom: "0",
                                            width: "auto"
                                        }}
                                        className="btn--sm"
                                        onClick={e => this._fetchData("sharesByMonth", "csv")}
                                    >
                                        <i className="fa fa-download" /> Download shares by month
                                    </DownloadButton>
                                )}
                            <DownloadButton
                                style={{
                                    marginLeft: "5px",
                                    marginBottom: "0",
                                    width: "auto"
                                }}
                                className="btn--sm"
                                onClick={e => this._fetchData("topArticles", "csv")}
                            >
                                <i className="fa fa-download" /> Download article performance
                            </DownloadButton>
                        </span>
                        <Tooltip style={{ marginLeft: "10px", verticalAlign: "middle", fontWeight: "normal" }}>
                            {"If an article has been curated multiple times (meaning it uses the same link as others)," + "\n" +
                                "then the Article Performance report will display the stats of all of these articles under" + "\n" +
                                "the title of the article that was shared first within the filtered time period."}
                        </Tooltip>
                    </div>
                    <div className="ibox-content">
                        <div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Pagination
                                    page={topArticlesPage}
                                    totalItems={topArticlesTotal}
                                    numPerPage={topArticlesLimit}
                                    changePage={newPage => this._refresh(newPage)}
                                    disabled={false}
                                    showTotal={false}
                                />
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped responsive" data-type="responsive">
                                    <thead>
                                        <tr>
                                            <th tabIndex={0}>Rank</th>
                                            <th tabIndex={0}>Title</th>
                                            <th tabIndex={0}>Content ID</th>
                                            <th tabIndex={0}>Domain</th>
                                            <th tabIndex={0}>Terms</th>
                                            <th tabIndex={0}>{this._renderSortIcons("topArticles", "Clicks", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("topArticles", "Shares", -1)}</th>
                                            <th tabIndex={0}>{this._renderSortIcons("topArticles", "Interactions", -1)}</th>
                                            {hasEmvPermission(user) && <th tabIndex={0}>Earned Media Value</th>}
                                            <th tabIndex={0}>Stream</th>
                                            <th tabIndex={0}>Curator Name</th>
                                            <th tabIndex={0}>Curator Email</th>
                                            <th tabIndex={0}>Note</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {topArticles &&
                                            topArticles.length > 0 &&
                                            topArticles instanceof Array &&
                                            topArticles.map((art, i) => {
                                                return (
                                                    <tr key={art.URL}>
                                                        {this._renderAdvancedPDF(art)}
                                                        <td tabIndex={0}>{i + 1 + topArticlesLimit * topArticlesPage || "N/A"}</td>
                                                        <td tabIndex={0}>
                                                            {art.isPDF &&
                                                                (art.URL.indexOf("grapevinesix.s3.amazonaws.com") > -1) && // check for the source of pdf and only show performance modal when pdf hosted in our s3
                                                                (client && client.allowContentAnalysis || this.props.user.isSuper) ?
                                                                <div style={{ textDecoration: "none", color: "#55059c", cursor: "pointer" }}
                                                                    onClick={e => this._openModal(art.URL || art.Title)}>
                                                                    {art.Title || art.URL || "N/A"}
                                                                </div>
                                                                :
                                                                <Link href={art.URL} target="_blank">
                                                                    {art.Title || art.URL || "N/A"}
                                                                </Link>
                                                            }
                                                        </td>
                                                        <td tabIndex={0}>{art["Content ID"] || "N/A"}</td>
                                                        <td tabIndex={0}>{art.Domain || "N/A"}</td>
                                                        <td tabIndex={0}>{art.Terms || "N/A"}</td>
                                                        <td tabIndex={0}>{art.Clicks || "N/A"}</td>
                                                        <td tabIndex={0}>{art.Shares || "N/A"}</td>
                                                        <td tabIndex={0}>{art.Interactions || "N/A"}</td>
                                                        {hasEmvPermission(user) && <td tabIndex={0}>${art["Earned Media Value"] || "N/A"}</td>}
                                                        <td tabIndex={0}>{art.streamTitle || "N/A"}</td>
                                                        <td tabIndex={0}>{art.CuratorName || "N/A"}</td>
                                                        <td tabIndex={0}>{art.CuratorEmail || "N/A"}</td>
                                                        <td tabIndex={0}>{art.notes || "N/A"}</td>
                                                    </tr>
                                                );
                                            })}
                                        {!topArticles?.length &&
                                            <tr>
                                                <td colSpan={42}>No records found</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                <Pagination
                                    currItems={topArticles ? topArticles.length : 0}
                                    page={topArticlesPage}
                                    totalItems={topArticlesTotal}
                                    numPerPage={topArticlesLimit}
                                    changePage={newPage => this._refresh(newPage)}
                                    disabled={false}
                                    showTotal={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Wrapper id="articles">
                <Row>{this._renderArticles()}</Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ user: state.session.admin });
export default connect(mapStateToProps)(ArticlesSection);
