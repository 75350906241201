import React, { Component, ChangeEvent } from "react";
import Tooltip from "./Tooltip";
import { uuid } from "../../utils/Helpers";

export default class Toggle extends Component<
    {
        name: string;
        className?: string;
        style?: dynamic<string>;
        description?: string;
        value: boolean;
        label?: string;
        disabled?: boolean;
        onToggle: (event: ChangeEvent<HTMLInputElement>) => void;
    },
    { key: string }
> {
    constructor(props) {
        super(props);
        this._onToggle = this._onToggle.bind(this);
        this.state = { key: uuid() };
    }
    render() {
        const { label, description } = this.props;
        if (label) {
            return (
                <div className="toggle__wrapper">
                    <h5>
                        {label}
                        {description && (
                            <Tooltip
                                style={{
                                    marginLeft: "5px"
                                }}
                            >
                                {description}
                            </Tooltip>
                        )}
                    </h5>
                    {this.renderInput()}
                </div>
            );
        }
        return this.renderInput();
    }
    _onToggle(event) {
        if (this.props.onToggle) {
            this.props.onToggle(event);
        }
    }
    renderInput() {
        const { name, value, disabled, className, style } = this.props;
        const { key } = this.state;
        return (
            <div
                className={`toggle ${className || ""}`}
                {...(style ? { style } : null)}
                tabIndex={0}
                role="switch"
                aria-checked={value}
                onKeyDown={e => {
                    if (e.which === 13 || e.which === 32) {
                        this._onToggle(e);
                    }
                }}
            >
                <input
                    type="checkbox"
                    id={`toggle--${name}--${key}`}
                    onChange={this._onToggle}
                    checked={value}
                    disabled={!!disabled}
                    aria-checked={value}
                />
                <label
                    className={value ? "brandPrimary--bg" : "inactive"}
                    htmlFor={`toggle--${name}--${key}`}
                >
                    Toggle {name}
                </label>
            </div>
        );
    }
}