import React, { Component, ChangeEvent } from "react";
const striptags = require("striptags");
interface IEditableInputProps {
    className?: string;
    editing?: boolean;
    formatted?: JSX.Element;
    style?: dynamic;
    input?: JSX.Element;
    onEditToggle?: (toggle: boolean) => void;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    onOutsideClick?: (event: ChangeEvent<HTMLTextAreaElement>, id: string) => void;
    id?: string;
    value: string;
    disabled?: boolean;
    maxLength?: number;
    minLength?: number;
    clearField?: JSX.Element;
    placeholder?: string;
}
interface IEditableInputState {
    editing: boolean;
}
interface ICursorPosition {
    selectionStart: number;
    selectionEnd: number;
}
export default class EditableInput extends Component<IEditableInputProps, IEditableInputState> {
    textAreaRef: React.RefObject<HTMLTextAreaElement>;
    cursorPosition: ICursorPosition | undefined;
    constructor(props: IEditableInputProps) {
        super(props);

        this._toggleEditing = this._toggleEditing.bind(this);
        this._getValue = this._getValue.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.textAreaRef = React.createRef();
        this.state = {
            editing: props.editing || false,
        };
    }

    componentDidMount() {
        if (document) {
            document.addEventListener("mousedown", this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (document) {
            document.removeEventListener("mousedown", this.handleClickOutside);
        }
        this.cursorPosition = undefined;
    }
    componentWillReceiveProps(newProps: IEditableInputProps) {
        const newState = {
            ...("editing" in newProps && newProps.editing != null && newProps.editing != this.state.editing
                ? { editing: newProps.editing }
                : null)
        };
        if (Object.keys(newState).length > 0) {
            this.setState(newState as IEditableInputState);
        }

    }

    _toggleEditing(event) {
        const { editing } = this.state;
        const { onEditToggle, disabled } = this.props;
        if (disabled) {
            return;
        }
        if ($(event.target).is("a")) {
            return true;
        }
        const focus = !editing;
        const target = $(event.target);
        this.setState(
            {
                editing: focus
            },
            () => {
                if (focus && target) {
                    target.children("input, textarea").focus();
                }
                if (onEditToggle) {
                    onEditToggle(focus);
                }
            }
        );
        return;
    }

    _getValue() {
        const { value } = this.props;
        return value || "";
    }

    handleClickOutside(event) {
        const { editing } = this.state;
        const { onOutsideClick, id } = this.props;
        if (editing) {
            const parent = $(event.target).closest(".editable__input");
            if (parent.hasClass("editable__input--edit")) {
                return;
            } else {
                if (onOutsideClick && id) {
                    onOutsideClick(event, id);
                }
                this.setState({ editing: false });
                this.cursorPosition = undefined;
            }
        }
    }

    render() {
        const { editing } = this.state;
        const { id, input, value, style, onChange, onBlur, formatted, maxLength, minLength, className, clearField, placeholder, disabled } = this.props;
        if (editing && input) {
            return (
                <div className={`editable__input editable__input--edit ${className}`}>
                    {input}
                    <i onClick={event => this._toggleEditing(event)} className="fa fa-check" />
                </div>
            );
        }
        const withClearButtonStyle = value && clearField ? { ...style, marginRight: "35px" } : style;
        return <div id={id} className="editable__input--wrapper" style={{ position: "relative" }}>
            {editing ? <div className={`editable__input editable__input--edit ${className}`}>
                <textarea
                    id={id}
                    className="form__value"
                    data-placeholder={placeholder || ""}
                    onChange={(event) => {
                        this.cursorPosition = {
                            selectionStart: event.target.selectionStart,
                            selectionEnd: event.target.selectionEnd
                        }
                        if (onChange) {
                            onChange(event);
                        }
                    }}
                    value={value}
                    style={style}
                    onBlur={event => {
                        this._toggleEditing(event);
                        if (onBlur) {
                            onBlur(event);
                        }
                    }}
                    disabled={disabled}
                    maxLength={maxLength}
                    minLength={minLength}
                    ref={this.textAreaRef}
                />
                <i onClick={event => this._toggleEditing(event)} className="fa fa-check" />
            </div> : (formatted && value) ?
                (<div
                    onFocus={event => this._toggleEditing(event)}
                    className={`editable__input ${className}`}
                    style={withClearButtonStyle}
                    contentEditable={!disabled}
                >
                    {formatted}
                </div>)
                :
                (<div
                    onFocus={event => this._toggleEditing(event)}
                    className={`editable__input ${className}`}
                    style={withClearButtonStyle}
                    contentEditable={!disabled}
                    data-placeholder={placeholder || ""}
                    dangerouslySetInnerHTML={{
                        __html: striptags(this._getValue()).replace(/\n/g, "<br style='margin-top: 0'/>")
                    }}>
                </div>)
            }
            {clearField && value && !editing && <span style={{ position: "absolute", top: "0px", right: "0px", height: "100%" }}>{clearField}
            </span>}
        </div>
    }
}
