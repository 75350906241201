import BaseAction from "../utils/BaseAction";

export interface IReportActions {
    // TODO: fix the return types
    doGetDelegationSummaryReport(clientCode: string, gids: string[] | null): Promise<any>;
    doDownloadAutopostReport(filters: {
        clientCode: string;
        sd: string;
        sdtz: number;
        ed: string;
        edtz: number;
    }): Promise<any>;
    doGetDelegationArticlesReport(
        clientCode: string,
        startDate: string,
        endDate: string,
        gids: string[] | null
    ): Promise<{ total: number; approved: number; rejected: number }>;
    doGetAutopostReportEnrolled(clientCode: string): Promise<any>;
    doGetAutopostReportPerformance(filters: {
        clientCode: string;
        sd: string;
        ed: string;
        sdtz: number;
        edtz: number;
    }): Promise<any>;
    doGetNetworkSummary(filters: {
        clientCode: string;
        sd: string;
        ed: string;
        sdtz: number;
        edtz: number;
        tags: string[];
    }): Promise<any>;

    doGetNetworkAdoption(filters: {
        clientCode: string;
        sd: string;
        ed: string;
        sdtz: number;
        edtz: number;
    }): Promise<any>;

    doGetComplianceProfiles(filters: {
        clientCode: string;
        sd: string;
        ed: string;
        sdtz: number;
        edtz: number;
    }): Promise<{ networks: number; installed: number; approved: number }>;

    doGetNetworksReport(
        filters: {
            clientCode: string;
            sd: string;
            ed: string;
            sdtz: number;
            edtz: number;
        },
        type: string,
        format: string
    ): Promise<any>;
}

class _ReportActions extends BaseAction implements IReportActions {
    doGetDelegationSummaryReport(clientCode: string, gids: string[] | null) {
        return this.apiRequest(`/reports/delegation/summary`, `POST`, {
            ...(clientCode ? { clientCode } : { clientCode: "ALL_CLIENTS" }),
            ...(gids ? { grp: gids } : null)
        }).catch(error => {
            this.createAlert(error, `error`);
            return error;
        });
    }

    doDownloadAutopostReport(filters: { clientCode: string; sd: string; sdtz: number; ed: string; edtz: number }) {
        return this.apiRequest(`/reports/autopost/users/csv`, "POST", filters)
        .catch(err => {
            this.createAlert(err, `error`);
            return "";
        });
    }

    doDownloadActiveAutopostStreamReport(filters: { clientCode: string | null }) {
        return this.apiRequest(`/reports/autopost/activeAutopostStreams`, "POST", filters)
        .catch(err => {
            this.createAlert(err, `error`);
            return "";
        });
    }

    async doDownloadArticleReadsReport(filters: { cid?: string; sid?: string }) {
        this.downloadFile(
            "readOnly",
            await this.apiRequest(`/reports/articles/topReads`, "POST", filters).catch(err => {
                this.createAlert(err, `error`);
                return "";
            })
        );
    }

    async downloadDelegateUserActivityReport(filters: { clientCode?: string; gids: string[] | null; sd: string | null; ed: string | null }) {
        this.downloadFile(
            "delegateUserActivity",
            await this.apiRequest(`/reports/articles/delegateUserActivity`, "POST", filters).catch(err => {
                this.createAlert(err, `error`);
                return "";
            })
        );
    }

    async doGetPdfPerformanceReport(filters) {
        return this.apiRequest(`/reports/article/pdfPerformance`, "POST", filters);
    }

    async doGetDelegationArticlesReport(clientCode: string, sd: string, ed: string, gids: string[] | null) {
        try {
            const data = await this.apiRequest<{ total: number; approved: number; rejected: number }>(
                `/reports/delegation/articles`,
                `POST`,
                { clientCode, sd, ed, ...(gids ? { grp: gids } : null) }
            );
            return {
                total: data?.total || 0,
                approved: data?.approved || 0,
                rejected: data?.rejected || 0
            };
        } catch (error) {
            this.createAlert(error, `error`);
            return { total: 0, approved: 0, rejected: 0 };
        }
    }

    doGetAutopostReportEnrolled(clientCode: string): Promise<any> {
        return this.apiRequestWithErrorAlert(`/reports/autopost/enrolled`, "POST", {
            clientCode
        });
    }

    doGetAutopostReportPerformance(filters: {
        clientCode: string;
        sd: string;
        ed: string;
        sdtz: number;
        edtz: number;
    }): Promise<any> {
        return this.apiRequestWithErrorAlert(`/reports/autopost/performance`, "POST", {
            ...filters
        });
    }

    doGetNetworkSummary(filters: { clientCode: string; sd: string; ed: string; sdtz: number; edtz: number; tags: string[]}) {
        return this.apiRequest(`/reports/networks/summary`, "POST", filters);
    }

    doGetNetworkAdoption(filters: { clientCode: string; sd: string; ed: string; sdtz: number; edtz: number }) {
        return this.apiRequest(`/reports/adoption/network`, "POST", filters);
    }

    doGetComplianceProfiles(filters: { clientCode: string; sd: string; ed: string; sdtz: number; edtz: number }) {
        return this.apiRequest(`/reports/complianceProfile`, "POST", filters);
    }

    doGetNetworksReport(
        filters: {
            clientCode: string;
            sd: string;
            ed: string;
            sdtz: number;
            edtz: number;
        },
        type: string,
        format: string
    ) {
        return this.apiRequest(`/reports/networks/${type}/${format}`, "POST", filters);
    }
}

export const ReportActions = new _ReportActions();
