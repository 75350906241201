import BaseAction from "../utils/BaseAction";

export type IClientRoleActions = ClassInstance<typeof _ClientRoleActions>;

export interface IRoleMemberCount {
    rid : string;
    en : number;
    fr : number;
    enMobile : number;
    frMobile : number;
}
class _ClientRoleActions extends BaseAction {
    async doGet(cid: string, rid: string) {
        return this.apiRequest<ServerTypes.Console.IUserRole>(`/client/role/get`, "POST", { cid, rid });
    }

    async doGetUsers(
        cid: string,
        rid: string | undefined,
        excludeRid: string | undefined,
        search: string | undefined,
        limit = 20,
        skip = 0
    ) {
        const users: ServerTypes.Console.IRoleUser[] = [];
        let count = 0;
        const response = await this.apiRequestWithErrorAlert<{ items: ServerTypes.Console.IRoleUser[]; count: number }>(
            `/clientuser/filteredList`,
            "POST",
            {
                cid,
                rid,
                excludeRid,
                search,
                limit,
                skip,
                sort: { firstName: 1 },
                props: ["cid","uid","rid","email","firstName","lastName"]
            },
            "Error loading users."
        );
        if (response) {
            users.push(...response.items.map(i => ({ ...i, name: `${i.firstName} ${i.lastName}` })));
            count = response.count;
        }
        return { items: users, count };
    }

    async doList(data: {
        sort?: { [key: string]: number };
        skip?: number;
        limit?: number;
        search?: string;
        cid?: string;
    }) {
        const results = await this.apiRequestWithErrorAlert<{ items: ServerTypes.Console.IUserRole[]; count: number }>(
            `/client/role/list`,
            `POST`,
            data,
            "Error loading roles."
        );
        if (results) {
            return { items: results.items, total: results.count };
        }
        return { items: [], total: 0 };
    }

    async doUpsert(cid: string, item: Partial<ServerTypes.Console.IUserRole>) {
        return this.apiRequestWithErrorAlert<{ valid: boolean; rid: string }>(`/client/role/upsert`, "POST", {
            cid,
            item
        });
    }

    async doDelete(cid: string, rid: string) {
        return this.apiRequest(`/client/role/delete`, `POST`, { cid, rid });
    }

    async doGetRoleMemberCount(cid: string, rid: string) {
        const data = await this.apiRequestWithErrorAlert(`/client/role/member-size`, `POST`, { cid, rid }) as IRoleMemberCount;
        return data ? data : null;
    }

    async doExportRoles(cid: string) {
        return this.apiRequest(`/client/role/csv/export`, "POST", { cid });
    }
}

export const ClientRoleActions = new _ClientRoleActions();
