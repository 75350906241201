import React, { Component } from "react";
import Modal from "./Modal";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import AddECommunicationMessage from "./AddECommunicationMessage";
import BeeEditorConfirmModal from "./BeeEditorConfirmModal";

interface ModalProps {
    parent: AddECommunicationMessage;
    confirm: BeeEditorConfirmModal;
    open: boolean;
    close: () => void;
}
interface ModalState {
    open: boolean;
    rowName: string;
}

export default class BeeEditorMetadataContentModal extends Component<ModalProps, ModalState>
{
    constructor(props) {
        super(props);
        this._onFieldChange = this._onFieldChange.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this._returnRowNamePromise = this._returnRowNamePromise.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._onSubmitRowName = this._onSubmitRowName.bind(this);
        this._onCancelSaveRowName = this._onCancelSaveRowName.bind(this);
        this.state = {
            rowName: "",
            open: props.open || false,
        };
    }

    componentWillReceiveProps(newProps) {
        if ("open" in newProps && newProps.open !== this.state.open) {
            this.setState({ open: newProps.open });
        }
    }

    _onFieldChange(field, value) {
        let newState = {};
        newState[field] = value && value.target ? value.target.value : value;
        this.setState(newState);
    }
    _handleKeyPress(event) {
        if(event.charCode == 13){
            if (this._validateRowName()){
                this._returnRowNamePromise();
            }
            event.preventDefault();
        }
    }
    _validateRowName(){
        const customRowNames = this.props.parent.state.customRowNames;
        if (customRowNames && Array.isArray(customRowNames) && customRowNames.indexOf(this.state.rowName) >= 0){
            this.props.parent.setState({
                modals: { ...this.props.parent.state.modals, contentEditorRowNameConfirm: true }
            });
            this.props.parent.promiseConfirm = new Promise<boolean>((resolve, reject) => {
                this.props.parent.resolveConfirm = resolve;
            });
            this.props.parent.promiseConfirm.then((update) => {
                if (update){
                    this._returnRowNamePromise();
                }
                return false;
            });
            this.props.parent.promiseConfirm.catch(() => {
                this.props.parent.promiseRowName.reject();
            });
            return false;
        }
        return true;
    }
    _returnRowNamePromise(){
        this._closeModal();
        this.props.parent.promiseRowName.resolve(this.state.rowName);
    }
    _onSubmitRowName(){
        if (this._validateRowName()){
            this._returnRowNamePromise();
        }
    }
    _onCancelSaveRowName(){
        this._closeModal();
        this.props.parent.promiseRowName.reject();
    }
    _closeModal() {
        this.setState({ open: false });
        this.props.parent.setState({
            modals: { ...this.props.parent.state.modals, contentEditorRowName: false }
        });
    }

    render() {
        return (
            <Modal
                title="Save custom row"
                className="modal-modal-background modal-header-none modal-bee-editor-custom-row-name-prompt"
                open={this.state.open}
                closeAction={this._closeModal}
                backdrop='static'>
                <BeeEditorConfirmModal
                    className="modal-modal-background"
                    parent={this.props.parent}
                    message="Row with the same name already exists."
                    prompt="Do you want to update?"
                    save="Save"
                    cancel="Cancel"
                    open={this.props.parent.state.modals.contentEditorRowNameConfirm}
                    close={() => { this.props.parent.setState({ modals: { ...this.props.parent.state.modals, contentEditorRowNameConfirm: false } }); }}
                />
                <form>
                    <div className="form__row">
                        <div className="form__group form__group--full" />
                    </div>
                    <div className="form__row">
                        <div className="form__group form__group--full">
                            <label className="form__label">Please provide a name to this saved row:</label>
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__group form__group--full">
                            <input
                                placeholder="eg. Header"
                                type="text"
                                className="form__value"
                                onKeyPress={this._handleKeyPress}
                                onChange={event => this._onFieldChange("rowName", event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div
                            className="form__group form__group--full"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row"
                            }}
                        >
                            <Link onClick={() => this._onCancelSaveRowName()} style={{ textDecoration: "underline" }}>
                                Cancel
                            </Link>
                            <Button onClick={() => this._onSubmitRowName()} style={{ marginLeft: "auto" }} >
                                Save
                            </Button>
                        </div>
                    </div>
                </form>

            </Modal>
    );
    }
}
