import { apiRequest } from "../utils/Helpers";
import { createAlert } from "./utils";
import appConfig from "../../config/config.dev";

export function doRemoveCompany(companyId: string) {
    return apiRequest(`${appConfig.API_URL}/company/delete`, `POST`, { companyId }).then(data => {
        if (data.valid) {
            createAlert(`Successfully removed company`, `success`);
        } else {
            createAlert(data.error, `error`);
        }
        return data;
    });
}

export function doRemoveIndustry(iid: string) {
    return apiRequest(`${appConfig.API_URL}/industry/delete`, `POST`, { iid }).then(data => {
        if (data.valid) {
            createAlert(`Successfully removed industry`, `success`);
        } else {
            createAlert(data.error, `error`);
        }
        return data;
    });
}

export function doRemoveOfferCampaign(ocid: string) {
    return apiRequest(`${appConfig.API_URL}/offercampaign/delete`, `POST`, { ocid }).then(data => {
        if (data.valid) {
            createAlert(`Successfully removed campaign`, `success`);
        } else {
            createAlert(data.error, `error`);
        }
        return data;
    });
}

// currently unused
export function doRemoveNotification(nid: string) {
    return apiRequest(`${appConfig.API_URL}/notification/delete`, `POST`, { nid }).then(data => {
        if (data.valid) {
            createAlert(`Successfully removed notification`, `success`);
        } else {
            createAlert(data.error, `error`);
        }
        return data;
    });
}

export function doRemoveEmailDripCampaign(eid: string) {
    return apiRequest(`${appConfig.API_URL}/emailDrip/delete`, `POST`, { eid }).then(data => {
        if (data.valid) {
            createAlert(`Successfully removed campaign`, `success`);
        } else {
            createAlert(data.error, `error`);
        }
        return data.valid;
    });
}
