import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IDatePickerProps {
    calendarPlacement?: "top" | "bottom" | "left" | "right";
    className?: string;
    dateFormat?: string;
    timeFormat?: string;
    showTimeSelect? : boolean;
    timeIntervals? : 15;
    disabled?: boolean;
    id?: string;
    maxDate?: Date | string;
    minDate?: Date | string;
    onBlur?: () => void;
    onChange?: (value: string, formattedValue: string) => void;
    value?: string | Date;
    enableTabLoop: boolean;
}


/**
 * This class is WIP. The main purpose of the class is to enable manual editing.
 * As we go forward we may activate more props and refine the UI.
 * TODO:
 * 1- enable correct placement of the calendar.
 * 2- enable validation.
 * @param props is an instance of IDatePickerProps
 */

/**
 * Custom input component for the DatePicker.
 * This component adds an aria-label for accessibility.
 *
 * @param props - The properties passed to the input element.
 * @param ref - The reference to the input element.
 */
const CustomInput = React.forwardRef((props: any, ref: any) => (
    <input
        {...props}
        ref={ref}
        aria-label={`Date picker input, current value is ${props.value || 'not set'}`}

    />
));

const DatePickerV2 = (props: IDatePickerProps) => {
    const _onSelect = (input) => {
        const { onChange, dateFormat } = props;
        if (!input || !onChange) {
            return;
        }
        if (isValidInput(input)) {
            const momentObj = moment(input);
            let date = momentObj.toISOString();
            let formattedDate = momentObj.format(dateFormat);
            onChange(date, formattedDate);
        }
    }

    const _onBlur = event => {
        const { onChange, onBlur, dateFormat } = props;
        if (!event?.target?.value || !onChange) {
            return;
        }
        const value = event.target.value;
        if (isValidInput(value)) {
            const momentObj = moment(value);
            let date = momentObj.toISOString();
            let formattedDate = momentObj.format(dateFormat);
            onChange(date, formattedDate);
        } else if (onBlur) {
            onBlur();
        }
    }

    const translateProps = () => {
        return {
            calendarClassName: "date-picker",
            className: props.className,
            dateFormat: props.dateFormat,
            timeFormat: props.timeFormat,
            showTimeSelect: props.showTimeSelect,
            timeIntervals: props.timeIntervals,
            disabled: props.disabled,
            disabledKeyboardNavigation: true,
            id: props.id,
            ...({ maxDate: props.maxDate && isValidInput(props.maxDate) ? new Date(props.maxDate!) : undefined }),
            ...({ minDate: props.minDate && isValidInput(props.minDate) ? new Date(props.minDate!) : undefined }),
            onChange: _onSelect,
            onBlur: _onBlur,
            popperPlacement: props.calendarPlacement,
            scrollableYearDropdown: true,
            selected: props.value ? new Date(props.value) : null,
            showDisabledMonthNavigation: true,
            showPopperArrow: false,
            showMonthDropdown: true,
            showYearDropdown: true,
            yearDropdownItemNumber: 20,
            enableTabLoop: false,
            customInput: <CustomInput />
        };
    };
    return <DatePicker {...translateProps()} />;

    //#region helpers
    /**
     * tests a string to see if it can represent a valid date.
     * @param input user input to examine.
     */
    function isValidInput(input: any): boolean {
        let dateObj = new Date(input.toString());
        return !isNaN(dateObj.getTime());
    }
    //#endregion
};

export default DatePickerV2;
