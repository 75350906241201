import React from "react";
import Select, { ActionTypes, components } from "react-select";
import ReactTooltip from "react-tooltip";

export interface ISelectOption<T> {
    label: string;
    value: T;
    selected?: boolean;
    disallowed?: boolean;
    optionDisabled?: boolean;
    showTooltip?: boolean;
    tooltip?: string;
}
export interface ISelectProps<T> {
    id?: string;
    options: Array<ISelectOption<T>>;
    multi?: boolean;
    className?: string;
    isSearchable?: boolean;
    onChange: (value: T | T[] | null) => void;
    onBlur?: () => void;
    disabled?: boolean;
    placeholder?: string;
    menuPortalTarget?: HTMLElement;
}

const _Select = <T,>(props: ISelectProps<T>) => {
    const { id, options, multi, className, isSearchable, onChange, onBlur, disabled, placeholder, menuPortalTarget } = props;
    const formatValues = (value: ISelectOption<T>) => ({
        label: value.label,
        value: value.value,
        optionDisabled: value.optionDisabled,
        disallowed: value.disallowed,
        tooltip: value.tooltip,
        showTooltip: value.showTooltip
    });

    const _onChange = (value: ISelectOption<T> | Array<ISelectOption<T>>, actionType: { action: ActionTypes }) => {
        onChange(
            !value
                ? null
                : multi
                ? (value as Array<ISelectOption<T>>).map(v => v.value)
                : (value as ISelectOption<T>).value
        );
    };

    const translateOptions = () => ({
        ...(id ? { id } : null),
        ...(options ? { options: options.map(i => formatValues(i)) } : null),
        ...(options && multi
            ? {
                  value: options
                      .filter(i => i.selected)
                      .filter(i => i.value)
                      .map(i => formatValues(i))
              }
            : null),
        ...(options && !multi
            ? {
                  value: options.filter(i => i.selected).filter(i => i.value).length
                      ? options
                            .filter(i => i.selected)
                            .filter(i => i.value)
                            .map(i => formatValues(i))[0]
                      : ""
              }
            : null),
        ...(multi ? { isMulti: true } : null),
        ...(isSearchable ? { isSearchable } : null),
        ...(className ? { className } : null),
        // @ts-expect-error
        ...(onChange ? { onChange: _onChange } : null),
        ...(onBlur ? { onBlur } : null),
        ...(disabled ? { isDisabled: true } : null),
        ...(placeholder ? { placeholder } : null),
        ...(menuPortalTarget ? { menuPortalTarget } : null),
        styles: {
            menu: (provided, state) => ({ ...provided, zIndex: "1001" }),
            ...(multi
                ? {
                    valueContainer: (provided, state) => ({ ...provided, margin: "4px 0 4px 5px"}),
                    placeholder: (provided, state) => ({ ...provided, lineHeight: "14px" }),
                    multiValue: (styles, { data }) => {
                        return data.disallowed ?  { ...styles, backgroundColor: '#FAD2D6' } : styles
                      },
                  }
                : null),
        },
        autosize: false,
        isOptionDisabled: (option) => (option.optionDisabled || false)
    });
    return <>
            <Select {...translateOptions()} classNamePrefix="select" aria-label={props.placeholder || {}}
                components={{
                    MultiValueContainer: (props) => {
                        const { data } = props; // Get the data from props
                        return (
                            <React.Fragment key={`${data.value}-${data.selected}`}>
                            {
                                data.showTooltip &&
                                <ReactTooltip
                                className='select__tooltip'
                                id={`tooltip-${data.value}`} place="top" effect="solid">
                                    {
                                        data.tooltip.split('\n').map(message => <div>{message}</div>)
                                    }
                                </ReactTooltip>
                            }
                            <div data-tip data-for={`tooltip-${data.value}`}>
                                <components.MultiValueContainer {...props} />
                            </div>
                        </React.Fragment>
                        );
                    }
                }}
            />
        </>
};

export default _Select;
