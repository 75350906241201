import React, { Component } from "react";
import { connect } from "react-redux";
import CSVUpload from "../../molecules/CSVUpload";
import { Wrapper, Row } from "../../atoms/Layout/";
import DownloadButton from "../../atoms/DownloadButton";
import { SmsActions } from "../../../actions";

interface ISMSContactImportProps {
    user: Mongo.clientAdmin;
}
class SMSContactImport extends Component<ISMSContactImportProps> {
    static allowSuper = false;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);
        this._download = this._download.bind(this);
    }

    async _download() {
        const { cid } = this.props.user;
        if (cid){
            return SmsActions.doExportCSV(cid);
        }
        return;
    }

    render() {
        return (
            <Wrapper>
                <Row>
                <div>
                    <div className="ibox-tools">
                        <DownloadButton className="btn--sm" onClick={() => this._download()}>
                            <i className="fa fa-download" /> Download
                        </DownloadButton>
                    </div>
                </div>
                </Row>
                <CSVUpload
                    itemName="SMS Contact"
                    routeName="sms"
                />
            </Wrapper>
        )
    }
}

const mapStateToProps = state => ({
    user: state.session.admin
});

export default connect(mapStateToProps)(SMSContactImport);
