import React, { Component } from "react";
import Modal from "./Modal";
import Link from "../../atoms/Link";
import Button from "../../atoms/Button";
import { createAlert } from "../../../actions";
import ArticleFileUpload from "../ArticleFileUpload";
interface ArticleImageModalProps {
    closeAction: () => void;
    updateImageURL: (
        entries: Partial<ServerTypes.Console.IArticle> & { uploadedFile: boolean; fileUpload: File[] | null }
    ) => void;
    open: boolean;
    imageURL: ServerTypes.Console.IArticle["Image"];
    allowVideoCuration: Mongo.IClient["allowVideoCuration"];
    addedImage: ServerTypes.Console.IArticle["addedImage"];
    video: ServerTypes.Console.IArticle["video"];
    Link: ServerTypes.Console.IArticle["Link"];
    uploadedFile: boolean;
    hasInstagram?: boolean;
}

interface ArticleImageModalState {
    open?: boolean;
    imageURL?: ServerTypes.Console.IArticle["Image"];
    uploadedImageURL: ServerTypes.Console.IArticle["Image"];
    fileUpload: File[] | null;
    addedImage: ServerTypes.Console.IArticle["addedImage"];
    video: ServerTypes.Console.IArticle["video"];
    Link?: ServerTypes.Console.IArticle["Link"];
    uploadedFile: boolean;
}

export default class ArticleImageModal extends Component<ArticleImageModalProps, ArticleImageModalState> {
    inputFile: HTMLInputElement | null = null;
    constructor(props: ArticleImageModalProps) {
        super(props);

        const {imageURL, video, addedImage, Link, uploadedFile} = props;
        this.state = {
            open: false,
            ...(addedImage ? {imageURL} : {imageURL: ""}),
            fileUpload: null,
            uploadedImageURL: "",
            video,
            addedImage,
            Link,
            uploadedFile
        };
    }
    componentWillReceiveProps(newProps: ArticleImageModalProps) {
        const { open, imageURL, addedImage, video, Link, uploadedFile } = newProps;
        if ("open" in newProps && newProps.open !== this.props.open) {
            this.setState({
                open,
                video,
                Link,
                uploadedFile,
                ...(addedImage ? {imageURL} : {imageURL: ""}),
                uploadedImageURL: uploadedFile ? imageURL : ""
            });
        }
    }

    close() {
        const { uploadedImageURL, imageURL, addedImage, video, Link, uploadedFile, fileUpload } = this.state;
        const Image = uploadedImageURL || imageURL;
        if (!Image && !confirm("Are you sure you want this article to have no image?")) {
            this.setState({ open: false });
            this.props.closeAction();
            return;
        }
        this.setState({ open: false });
        this.props.updateImageURL({ Image, addedImage, video, Link, uploadedFile, fileUpload });
        this.props.closeAction();
    }

    _removeUploadImage() {
        this.setState({
          uploadedImageURL: "",
          addedImage: false,
          video: false,
          uploadedFile: false,
          imageURL: "",
          fileUpload: null
        }, () => {
          createAlert('Uploaded image removed', 'success');
        });
    }

    _onChange(imageURL: string) {
        this.setState({ imageURL });
    }

    _submit() {
        this.close();
    }
    render() {
        const { allowVideoCuration, hasInstagram } = this.props;
        const { open, imageURL, uploadedFile, uploadedImageURL } = this.state;
        return (
            <Modal title={"Custom Article Image"} open={open} closeAction={this.props.closeAction} backdrop={"static"}>
                <div className="form__row">
                    <div className="form__group form__group--full">
                        <span className="form__label label-center" style={{paddingBottom:'10px'}}>Add Image URL</span>
                        <input
                            disabled={uploadedFile ? true : false}
                            type="text"
                            onChange={event => {
                                this._onChange(event.target.value);
                                if (event.target.value && event.target.value.length) this.setState({ addedImage: true });
                            }}
                            value={uploadedImageURL ? "" : imageURL}
                        />
                    </div>
                </div>
                <h5>
                    <div className="label-center">OR</div>
                </h5>
                {uploadedFile ? (
                    <div style={{ textAlign: "center" }}>
                        {uploadedImageURL}
                        <Link
                            style={{ textDecoration: "underline", marginLeft: "5px" }}
                            onClick={() => this._removeUploadImage()}
                        >
                            Remove
                        </Link>
                    </div>
                ) : (
                    <ArticleFileUpload
                        allowVideoCuration={allowVideoCuration}
                        onValidUpload={(file) => this.setState(file as ArticleImageModalState)}
                        link={this.state.Link || ""}
                        hasInstagram={hasInstagram}
                    />
                )}
                <div className="form__row">
                    <div
                        className="form__group form__group--full"
                        style={{
                            marginTop: "10px",
                            flexDirection: "row-reverse"
                        }}
                    >
                        <Button
                            style={{ marginLeft: "10px" }}
                            type="submit"
                            onClick={() => this._submit()}
                            value={"Done"}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}
