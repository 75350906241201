import BaseAction from "../utils/BaseAction";
import { IReportFilter } from "../components/pages/reports/Index";

export type ILeadActions = ClassInstance<typeof _LeadActions>;
class _LeadActions extends BaseAction {

    async doGet(query: IReportFilter & {
        sort?: string;
        sortDirection?: number;
        skip?: number;
        limit?: number;
    }) {
        return this.apiRequestWithErrorAlert<ServerTypes.Console.ILeadReportItem>("/reports/leads/summary/json", "POST", { ...query });
    }


    async doDownload(query: IReportFilter & {
        sort?: string;
        sortDirection?: number;
        skip?: number;
        limit?: number;
    }) {
        const data = await this.apiRequest("/reports/leads/summary/csv", "POST", { ...query });
        if (data) {
            return this.downloadFile("leads", data);
        }
    }
}

export const LeadActions = new _LeadActions();
