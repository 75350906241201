import React, { Component } from "react";

interface IPercentCircleProps {
    percent: number;
    color?: string;
    width?: string;
}

type IPercentCircleState = IPercentCircleProps;
export default class PercentCircle extends Component<IPercentCircleProps, IPercentCircleState> {
    constructor(props: IPercentCircleProps) {
        super(props);
        this.state = { ...props };
    }

    componentWillReceiveProps(newProps: IPercentCircleProps) {
        if (newProps !== this.props) {
            this.setState({ ...newProps });
        }
    }
    render() {
        const { percent } = this.props;
        return <span>{percent}%</span>;
    }

    renderCircle() {
        const { percent, color, width } = this.props;
        return (
            <div
                className="percent-circle"
                style={{ width: "60px", height: "60px", ...(width ? { width, height: width } : null) }}
            >
                <span>{percent}%</span>
                <svg viewBox="0 0 36 36">
                    <path
                        style={{ animation: "none", stroke: "rgba(0, 0, 0, 0.1)" }}
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#444"
                        strokeWidth="1"
                        strokeDasharray={`100, ${100 - percent}`}
                    />
                    <path
                        className="brandPrimary--stroke"
                        style={{ ...(color ? { stroke: color } : null) }}
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#444"
                        strokeWidth="1"
                        strokeDasharray={`${percent}, 100`}
                    />
                </svg>
            </div>
        );
    }
}
