import React, { Component } from "react";
import Button from "../atoms/Button";
import Spinner from "../atoms/Spinner";

interface IPageHeadingProps {
    title: string;
    buttons?: Array<{
        title: string;
        link?: string;
        on_click?: () => void
        disabled?: boolean;
        spinner?: boolean;
    }>
}
class PageHeading extends Component<IPageHeadingProps, {}> {
    render() {
        const { title, buttons } = this.props;

        return (
            <div className="row page-heading">
                <div className="title">
                    <h1>{title}</h1>
                </div>
                {this.renderButtons(buttons)}
            </div>
        );
    }
    renderButtons(buttons: IPageHeadingProps["buttons"]) {
        if (!buttons) {
            return;
        }
        return (
            <div className="buttons">
                {buttons.map((btn, i) => {
                    if (btn.link) {
                        return (
                            <Button to={btn.link} style={{ marginRight: "20px" }} key={`button__${i}`}>
                                {btn.spinner ? <Spinner style={{ width: "12px", height: "12px", margin: "0 auto" }} color="#ffffff" /> : btn.title}
                            </Button>
                        );
                    } else if (btn.on_click) {
                        return (
                            <Button onClick={btn.on_click} style={{ marginRight: "20px" }} key={`button__${i}`} disabled={btn.disabled || false}>
                                {btn.spinner ? <Spinner style={{ width: "12px", height: "12px", margin: "0 auto" }} color="#ffffff" /> : btn.title}
                            </Button>
                        );
                    } else {
                        console.error("Button is missing both link and on_click, skipping render")
                        return;
                    }
                })}
            </div>
        );
    }
}

export default PageHeading;
