import { store } from "../utils/store";
// Don't import any actual api routes into this file as it will cause a circular dependency with the Helpers file

export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CHANGE_LOGO = "CHANGE_LOGO";
export const FOOTER_FUNCTION = "FOOTER_FUNCTION";
export const REMOVE_ALL_ALERTS = "REMOVE_ALL_ALERT";
export type IAlertType = "warning" | "error" | "success";
export interface IAlert {
    message: string;
    type: IAlertType;
    expiry: number;
    id?: string;
}

function _addAlert(alert: IAlert) {
    return { type: ADD_ALERT, alert };
}

function _removeAlert(id: string) {
    return { type: REMOVE_ALERT, alert: { id } };
}

function _removeAllAlert() {
    return { type: REMOVE_ALL_ALERTS };
}

export function doRemoveAllAlerts() {
    return store.dispatch(_removeAllAlert());
}

export function doSetFooterFunctions(
    confirmFunction: Function | null = null,
    cancelFunction: Function | null = null,
    confirmText = "Submit",
    cancelText = "Cancel",
    unscheduleFunction: Function | null = null,
    unscheduleText = "Unschedule",
    approveFunction: Function | null = null,
    rejectFunction: Function | null = null
) {
    return store.dispatch({ type: FOOTER_FUNCTION, confirmFunction, cancelFunction, confirmText, cancelText, unscheduleFunction, unscheduleText, approveFunction, rejectFunction });
}

export async function doChangeLogo(logo: string, logoLogin: string, name: string) {
    store.dispatch({ type: CHANGE_LOGO, logo, logoLogin, name });
}

export function createAlert(message: string, type: IAlertType = `error`, expiry = 5) {
    const alert: IAlert = {
        message,
        type,
        expiry,
        id: `${Math.floor((1 + Math.random()) * 0x10000)}`
    };
    setTimeout(() => {
        store.dispatch(_removeAlert(alert.id!));
    }, 10000);
    store.dispatch(_addAlert(alert));
}

export function removeAlert(id: string) {
    store.dispatch(_removeAlert(id));
}
