import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getLocaleDate,
    getFriendlyDate,
    downloadFile as fileDownload,
    getAdminDate,
    getBooleanIcon
} from "../../../../utils/Helpers";

import StreamPreview from "../../../molecules/modals/StreamPreview";
import Button from "../../../atoms/Button";
import { Wrapper, Row } from "../../../atoms/Layout/";
import {
    doPreviewStream,
    doGetAutopostStreams,
    doGetAutopostEnrolledUserCount
} from "../../../../actions/";
import { ReportActions } from "../../../../actions/ReportActions";
import { createAlert } from "../../../../actions/";
import NumberChart from "../../../molecules/NumberChart";
import PercentCircle from "../../../atoms/PercentCircle";
import Pagination from "../../../molecules/Pagination";
// tslint:disable-next-line:no-submodule-imports
import debounce from "lodash/debounce";
import DownloadButton from "../../../atoms/DownloadButton";
import Tooltip from "../../../atoms/Tooltip";

interface IStreamSubscriptionViewProps {
    clients: dynamic<Mongo.client>;
    user: Mongo.clientAdmin;
}
interface IStreamSubscriptionViewState {
    cid: string;

    loading: boolean;
    enrolled: {
        streams: number;
        users: {
            total: number;
            enrolled: number;
            enrolledThisPeriod: number;
        };
    } | null;

    autopostStreams: Array<
        Pick<
            ServerTypes.Console.IStream,
            | "sid"
            | "searchType"
            | "title"
            | "type"
            | "lang"
            | "lastUpdatedOn"
            | "active"
            | "code"
            | "globalFilters"
            | "type"
            | "isApprovalRequired"
            | "isCompliant"
            | "skipCompliance"
            | "isDefaultOn"
            | "isMandatory"
            | "count"
            | "streamOrder"
            | "subscriptionPeriods"
        > & {
            cid: string;
            enrolled?: number;
            enrolledThisPeriod?: number;
        }
    >;
    preview: boolean;
    triggerRefresh: boolean;
    sid: string | null;
    pagination: { limit: number; skip: number; total: number };
}
class StreamSubscriptionView extends Component<IStreamSubscriptionViewProps, IStreamSubscriptionViewState> {
    static allowClientAdmin = true;
    static allowSuperClientAdmin = true;
    static allowSuper = true;
    static allowReports = true;

    refreshStreams: Function;

    constructor(props: IStreamSubscriptionViewProps) {
        super(props);
        this._renderSubscriptionAdoption = this._renderSubscriptionAdoption.bind(this);
        this._renderSubscriptionPerformance = this._renderSubscriptionPerformance.bind(this);

        this.refreshStreams = debounce(this.updateStreams, 500);

        this.state = {
            cid: props.user.cid || (props.user.isSuper ? "ALL_CLIENTS" : ""),
            enrolled: null,
            loading: false,
            autopostStreams: [],
            preview: false,
            triggerRefresh: false,
            sid: null,
            pagination: { limit: 20, skip: 0, total: 0 }
        };
    }

    componentDidMount() {
        this.updateStreams();
    }

    async updateStreams() {
        const { cid, pagination } = this.state;
        let { items, count } = await doGetAutopostStreams(
            cid !== "ALL_CLIENTS" ? cid : null,
            pagination.limit,
            pagination.skip * pagination.limit
        );
        if (items) {
            const sids = items.map(i => i.sid);
            const enrolled = await doGetAutopostEnrolledUserCount(sids);

            if (enrolled && Array.isArray(enrolled) && enrolled.length) {
                items.map((item, i) => {
                    enrolled.map(e => {
                        if (item.sid == e._id) {
                            items[i] = {
                                ...item,
                                enrolled: e.enrolled,
                                enrolledThisPeriod: e.enrolledThisPeriod
                            }
                        }
                    });
                })
            }

            this.setState({
                autopostStreams: items,
                pagination: { ...this.state.pagination, total: count }
            });
        }
        this._refresh();
    }

    async _refresh() {
        this.setState({ loading: true, triggerRefresh: true }, async () => {
            const enrolled = await ReportActions.doGetAutopostReportEnrolled(this.state.cid);
            this.setState({ enrolled: enrolled ? enrolled : null, loading: false });
        });
    }

    getClient() {
        const { clients } = this.props;
        const { cid } = this.state;
        return Object.values(clients || {}).find(client => client!.cid === cid);
    }

    getStream() {
        const { autopostStreams, sid } = this.state;
        return (autopostStreams || []).find(stream => stream.sid === sid);
    }

    getReportTimeFilters(startDate: string, endDate: string) {
        return {
            sd: startDate,
            sdtz: new Date(`${startDate}T00:00:00.000`).getTimezoneOffset(),
            ed: endDate,
            edtz: new Date(`${endDate}T00:00:00.000`).getTimezoneOffset()
        };
    }

    async _downloadAutopostReport(startDate: string, endDate: string) {
        const { cid } = this.state;
        const data = await ReportActions.doDownloadAutopostReport({
            clientCode: cid,
            ...this.getReportTimeFilters(startDate, endDate)
        });
        fileDownload(data, `campaignSubscriptions-${getLocaleDate(new Date())}.csv`);
    }

    async _downloadActiveAutopostReport() {
        const { cid } = this.state;
        const data = await ReportActions.doDownloadActiveAutopostStreamReport({ clientCode: cid });
        fileDownload(data, `activeStreamSubscriptions-${getLocaleDate(new Date())}.csv`);
    }

    async previewStream(since) {
        const { autopostStreams, cid, sid } = this.state;
        const stream = autopostStreams.find(stream => stream.sid === sid);
        if (!stream) {
            return [];
        }
        const data = await doPreviewStream(cid, stream, since);
        return data && data.valid ? data.articles : [];
    }

    _getClientName(cid: string) {
        const { clients } = this.props;
        if (cid) {
            if (clients?.[cid]) {
                return clients[cid]!.name;
            } else if (clients && Object.keys(clients).length) {
                return "REMOVED";
            }
            return "Loading...";
        }
        return "None";
    }

    _renderStreams() {
        const { autopostStreams, cid } = this.state;
        // experimental mode released in GV6-12109
        return (
            <Row>
                <div className="col-lg-12">
                    <div className="ibox">
                        <div className="ibox-title">
                            <h5 style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}>
                                Active Streams
                            </h5>
                            <span style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                                <DownloadButton
                                    className="btn--sm"
                                    onClick={e => this._downloadActiveAutopostReport()}
                                >
                                    <i className="fa fa-download" /> Download
                                </DownloadButton>
                                <Tooltip style={{ marginLeft: "10px", verticalAlign: "middle" }}>
                                    Download this report in CSV format, which includes information from all active Subscription Streams
                                </Tooltip>
                            </span>
                        </div>
                        <div className="ibox-content">
                            <div />
                            <div className="table-responsive">
                                <table className="table table-striped responsive" data-type="responsive">
                                    <thead>
                                        <tr>
                                            <th tabIndex={0}>Client</th>
                                            <th tabIndex={0}>Stream Type</th>
                                            <th tabIndex={0}>Language</th>
                                            <th tabIndex={0}>Title</th>
                                            <th tabIndex={0}># of Users Subscribed<br />(any period)</th>
                                            <th tabIndex={0}># of Users Subscribed<br />(current period)</th>
                                            <th tabIndex={0}>Last Updated</th>
                                            <th tabIndex={0}>Start Date</th>
                                            <th tabIndex={0}>End Date</th>
                                            <th tabIndex={0}>Enabled</th>
                                            <th tabIndex={0}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {autopostStreams &&
                                            autopostStreams.length > 0 &&
                                            autopostStreams.map(stream => {
                                                return (
                                                    <tr key={`${stream.sid}`}>
                                                        <td tabIndex={0}>{this._getClientName(stream.cid) || "N/A"}</td>
                                                        <td tabIndex={0}>{`${stream.type}` || "N/A"}</td>
                                                        <td tabIndex={0}>{stream.lang || "N/A"}</td>
                                                        <td tabIndex={0}>{stream.title || "N/A"}</td>
                                                        <td tabIndex={0}>{stream.enrolled || 0}</td>
                                                        <td tabIndex={0}>{stream.enrolledThisPeriod || 0}</td>
                                                        <td tabIndex={0}>{getFriendlyDate(stream.lastUpdatedOn) || "N/A"}</td>
                                                        <td tabIndex={0}>
                                                            {getAdminDate(
                                                                (stream.subscriptionPeriods &&
                                                                    stream.subscriptionPeriods.length &&
                                                                    stream.subscriptionPeriods[0].startDate) ||
                                                                "",
                                                                false
                                                            ) || "N/A"}
                                                        </td>
                                                        <td tabIndex={0}>
                                                            {getAdminDate(
                                                                (stream.subscriptionPeriods &&
                                                                    stream.subscriptionPeriods.length &&
                                                                    stream.subscriptionPeriods[
                                                                        stream.subscriptionPeriods.length - 1
                                                                    ].endDate) ||
                                                                "",
                                                                false
                                                            ) || "N/A"}
                                                        </td>
                                                        <td tabIndex={0}>{getBooleanIcon(stream.active || false)}</td>
                                                        <td
                                                            tabIndex={0}
                                                            style={{
                                                                minWidth: "150px",
                                                                display: "flex",
                                                                flexDirection: "column"
                                                            }}
                                                        >
                                                            <Button
                                                                className="btn--sm"
                                                                style={{ marginBottom: "5px" }}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        preview: true,
                                                                        sid: stream.sid,
                                                                        cid: stream.cid
                                                                    })
                                                                }
                                                            >
                                                                <i className="fa fa-eye" /> Preview
                                                            </Button>
                                                            <Button
                                                                className="btn--sm"
                                                                to={`/clients/s/${cid == "ALL_CLIENTS" ? stream.cid : cid
                                                                    }/e/${stream.sid}`}
                                                                onClick={() => {
                                                                    createAlert(`Navigating to the edit page for ${stream.title}`,'success');
                                                                }}
                                                            >
                                                                <i className="fa fa-edit" /> Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        {autopostStreams && autopostStreams.length == 0 && (
                                            <tr>
                                                <td tabIndex={0} colSpan={6}>You have no streams that allow subscription</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {this.state.pagination.total > this.state.pagination.limit && (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "20px",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Pagination
                                        style={{ marginLeft: "auto" }}
                                        page={this.state.pagination.skip}
                                        totalItems={this.state.pagination.total}
                                        numPerPage={this.state.pagination.limit}
                                        currItems={(this.state.autopostStreams || []).length}
                                        changePage={newPage =>
                                            this.setState(
                                                { pagination: { ...this.state.pagination, skip: newPage } },
                                                () => this.updateStreams()
                                            )
                                        }
                                        showTotal={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Row>
        );
    }

    _renderSubscriptionAdoption() {
        const { enrolled, loading } = this.state;
        return (
            <div className="col-lg-6">
                <NumberChart
                    title="Stream subscription adoption"
                    tooltip={
                        <span>
                            <p>This card shows the user adoption of stream subscription enabled streams as of</p>
                            <p>yesterday.</p>
                            <p> Note: Date and Time filters do not affect this card.</p>
                            <br />
                            <p>To calculate these numbers, we take all users that are not excluded from reporting</p>
                            <p>and are activated, get all the stream these users have access to in the app, and</p>
                            <p>match the streams against the stream subscription enabled streams to get the</p>
                            <p>number of streams with stream subscription enabled.</p>
                            <br />
                            <p>Streams available: the number of streams with stream subscription enabled</p>
                            <br />
                            <p>Users with access: the number of users who have access to streams that have</p>
                            <p>stream subscription enabled. This does not reflect users that have not logged in</p>
                            <p>since the stream was created.</p>
                            <br />
                            <p>Accepted: displays the percentage of users that have subscribed to</p>
                            <p>stream subscription streams</p>
                        </span>
                    }
                    data={{
                        "Streams available": {
                            label: ["Streams", "available"],
                            value: enrolled && enrolled.streams > 0 ? `${enrolled.streams.toFixed(0)}` : 0
                        },
                        "Users with access": {
                            label: ["Users", "with access"],
                            value: enrolled && enrolled.users.total > 0 ? `${enrolled.users.total.toFixed(0)}` : 0
                        },
                        "% Accepted": {
                            label: ["Accepted"],
                            value: (
                                <PercentCircle
                                    percent={
                                        enrolled && enrolled.users.enrolled > 0
                                            ? Number(
                                                Math.round(
                                                    (enrolled.users.enrolled / enrolled.users.total) * 100
                                                ).toFixed(0)
                                            )
                                            : 0
                                    }
                                />
                            )
                        }
                    }}
                    loading={loading || enrolled == null}
                />
            </div>
        );
    }
    async refreshStreamPerformance(startDate: string, endDate: string) {
        const { cid } = this.state;
        this.setState({ triggerRefresh: false });

        const performance = await ReportActions.doGetAutopostReportPerformance({
            clientCode: cid,
            ...this.getReportTimeFilters(startDate, endDate)
        });

        let contentAvailable = 0;
        let contentShared = 0;
        let contentTotal = 0;
        if (performance && performance.shares) {
            contentAvailable = performance.articles + performance.shares.content;
            contentShared = performance.shares.article + performance.shares.content;
            contentTotal = performance.shares.total;
        }

        return {
            "Content available": {
                label: ["Content", "available"],
                value: contentAvailable.toLocaleString()
            },
            "Content shared": {
                label: ["Content", "shared"],
                value: contentShared.toLocaleString()
            },
            "Total shares": {
                label: ["Total", "shares"],
                value: contentTotal.toLocaleString()
            }
        };
    }
    _renderSubscriptionPerformance() {
        const { cid } = this.state;
        return (
            <div className="col-lg-6">
                <NumberChart
                    title="Stream subscription performance"
                    tooltip={
                        <span>
                            <p>This card shows the performance of stream subscription.</p>
                            <p>To calculate these numbers, we look for any content within the library</p>
                            <p>that match the stream and date filters set. We then take the shares that match</p>
                            <p>
                                the stream and date filters, and match those by unique shares and total number of
                                shares.
                            </p>
                            <br />
                            <p>Content available: displays the total content that currently have been approved</p>
                            <p>across all streams with stream subscription enabled in the specified time range.</p>
                            <br />
                            <p>
                                Content shared: displays the total content that have been shared based on the content
                                that
                            </p>
                            <p>
                                have been approved across all streams with stream subscription enabled in the specified
                                time range
                            </p>
                            <br />
                            <p>
                                Total shares: displays the total number of shares based on the total number of content
                                shared
                            </p>
                        </span>
                    }
                    tooltipOffset={{ bottom: "121px" }}
                    download={(startDate: string, endDate: string) => this._downloadAutopostReport(startDate, endDate)}
                    refresh={(startDate: string, endDate: string) => this.refreshStreamPerformance(startDate, endDate)}
                    defaultFilters={{ cid }}
                    dropdownPosition="bottom"
                    dateFilters={true}
                />
            </div>
        );
    }
    renderFilters() {
        const { clients } = this.props;
        const { cid } = this.state;

        return (
            <Row>
                <div className="col-lg-12">
                    <div className="float-e-margins">
                        <div className="form-group">
                            <label className="control-label">Client</label>
                            <div
                                className="select__wrapper"
                                style={{
                                    marginLeft: "0px"
                                }}
                            >
                                <select
                                    id="client"
                                    value={cid}
                                    onChange={e => this.setState({ cid: e.target.value }, () => this.updateStreams())}
                                >
                                    <option value="ALL_CLIENTS">All Clients</option>
                                    <optgroup label="Clients">
                                        {Object.values(clients || {}).map(client => {
                                            return (
                                                <option key={client!.cid} value={client!.cid}>
                                                    {client!.name}
                                                </option>
                                            );
                                        })}
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        );
    }
    render() {
        const { user } = this.props;
        const { preview } = this.state;
        const client = this.getClient();
        const stream = this.getStream();
        return (
            <Wrapper id="streamSubscription">
                {client && stream && (
                    <StreamPreview
                        open={preview}
                        onClose={() => this.setState({ preview: false })}
                        getArticles={date => this.previewStream(date)}
                        client={client}
                        stream={stream}
                    />
                )}
                {user.isSuper && this.renderFilters()}
                <Row>
                    {this._renderSubscriptionAdoption()}
                    {this._renderSubscriptionPerformance()}
                </Row>
                {this._renderStreams()}
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    user: state.session.admin,
    clients: state.lists.clients
});
export default connect(mapStateToProps)(StreamSubscriptionView);
