import { ADD_ALERT, REMOVE_ALERT, REMOVE_ALL_ALERTS, CHANGE_LOGO, FOOTER_FUNCTION } from "../actions/utils";
import { reduce } from "../utils/Helpers";

interface IAlertState {
    alerts: { [id: string]: Function };
    logo: string;
    name: string;
    confirmFunction: Function | null;
    cancelFunction: Function | null;
    confirmText: string;
    cancelText: string;
    unscheduleFunction: Function | null;
    unscheduleText: string;
    approveFunction: Function | null;
    rejectFunction: Function | null;
}
const initialState: IAlertState = {
    alerts: {},
    logo: "/img/GV6.png",
    name: "G6 Console",
    confirmFunction: null,
    cancelFunction: null,
    confirmText: "",
    cancelText: "",
    unscheduleFunction: null,
    unscheduleText: "",
    approveFunction: null,
    rejectFunction: null
};

const handlers = {
    [ADD_ALERT]: (_, action) => {
        const alerts = { ..._.alerts };
        const duplicate = false;
        if (!duplicate) {
            alerts[action.alert.id] = action.alert;
        }
        return { alerts };
    },
    [REMOVE_ALERT]: (_, action) => {
        const alerts = { ..._.alerts };
        if (alerts[action.alert.id]) {
            delete alerts[action.alert.id];
        }
        return { alerts };
    },
    [REMOVE_ALL_ALERTS]: (_, action) => {
        return { alerts: {} };
    },
    [CHANGE_LOGO]: (_, action) => ({
        logo: action.logo,
        logoLogin: action.logoLogin,
        name: action.name
    }),
    [FOOTER_FUNCTION]: (_, action) => ({
        confirmFunction: action.confirmFunction,
        cancelFunction: action.cancelFunction,
        confirmText: action.confirmText,
        cancelText: action.cancelText,
        unscheduleFunction: action.unscheduleFunction,
        unscheduleText: action.unscheduleText,
        approveFunction: action.approveFunction,
        rejectFunction: action.rejectFunction
    })
};
export default (state = initialState, action) => reduce<IAlertState>(handlers, state, action);
