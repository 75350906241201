import React, { Component } from "react";
import Tooltip from "../atoms/Tooltip";

interface ISelectionOptionListProps {
    name: string;
    title: string;
    tooltipText: string;
    placeholders: string[];
    values: string[];
    disabled?: boolean;
    onChange1: (e: dynamic) => void;
    onChange2: (e: dynamic) => void;
    onChange3: (e: dynamic) => void;
}

interface ISelectionOptionListState {}

export default class SelectionOptionList extends Component<ISelectionOptionListProps, ISelectionOptionListState>{
    constructor(props: ISelectionOptionListProps) {
        super(props);
        this.state = {};
    }

    render(){
        return(
            <div className="form__group">
                <label className="form__label">{ this.props.title }&nbsp;
                    <Tooltip>{ this.props.tooltipText }</Tooltip>
                </label>
                <textarea
                    key={ `${this.props.name}-0` }
                    placeholder={ this.props.placeholders[0] }
                    maxLength={500}
                    className="form__value"
                    style={{ minHeight: "50px" }}
                    onChange={ this.props.onChange1 }
                    value={ this.props.values[0] || "" }
                    disabled={ this.props.disabled }
                />
                <span
                style={{
                    marginLeft: "auto"
                }}
                >
                    {this.props.values[0].length}/500
                </span>
                <textarea
                    key={ `${this.props.name}-1` }
                    placeholder={ this.props.placeholders[1] }
                    maxLength={500}
                    className="form__value"
                    style={{ marginTop: "10px", minHeight: "50px" }}
                    onChange={ this.props.onChange2 }
                    value={ this.props.values[1] || "" }
                    disabled={ this.props.disabled }
                />
                <span
                style={{
                    marginLeft: "auto"
                }}
                >
                    {this.props.values[1].length}/500
                </span>
                <textarea
                    key={ `${this.props.name}-2` }
                    placeholder={ this.props.placeholders[2] }
                    maxLength={500}
                    className="form__value"
                    style={{ marginTop: "10px", minHeight: "50px" }}
                    onChange={ this.props.onChange3 }
                    value={ this.props.values[2] || "" }
                    disabled={ this.props.disabled }
                />
                <span
                style={{
                    marginLeft: "auto"
                }}
                >
                    {this.props.values[2].length}/500
                </span>
            </div>
        )
    }
}