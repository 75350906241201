import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Route, Switch } from "react-router-dom";
import { doCheckAuth } from "../actions/session";
import { doChangeLogo, IAlert } from "../actions/utils";
import { generateBrandCSS, initResponsiveTables } from "../utils/Helpers";
import AuthRoute from "../utils/AuthRoute";
import Spinner from "../components/atoms/Spinner";

import ErrorContainer from "./ErrorContainer";
import SignInContainer from "./SignInContainer";
import SignOutContainer from "./SignOutContainer";
import MainContainer from "./MainContainer";
import Alert from "../components/molecules/Alert";
import { UtilActions } from "../actions";

interface IWrapperContainerProps {
    authenticating: boolean;
    alerts: { [key: string]: IAlert };
}

const WrapperContainer = (props: IWrapperContainerProps) => {
    const [loaded, setLoaded] = useState(false);
    const [authenticating, setAuth] = useState(props.authenticating);
    const [alerts, setAlerts] = useState(props.alerts);

    useEffect(() => {
        setAuth(props.authenticating);
    }, [props.authenticating]);
    useEffect(() => {
        setAlerts(props.alerts);
    }, [props.alerts]);

    useEffect(() => {
        loadBrand();
        if (window) {
            initResponsiveTables("table.responsive", true);
            window.addEventListener(
                "table_reflow",
                () => {
                    initResponsiveTables("table.responsive", true);
                },
                false
            );
            window.addEventListener(
                "resize",
                () => {
                    initResponsiveTables("table.responsive", true);
                },
                false
            );
        }
        doCheckAuth();
    }, []);

    const loadBrand = async () => {
        const data = await UtilActions.doGetBrand();
        if (!data) {
            return;
        }
        const css = generateBrandCSS(data.primaryColor);

        const $style = document.createElement("style");
        $style.setAttribute("type", "text/css");
        document.head.appendChild($style);
        $style.innerHTML = css;

        doChangeLogo(data.logo, data.logoLogin, data.name);
        setLoaded(true);
    };

    const renderAlerts = () => {
        return (
            <div className="alerts">
                {alerts &&
                    Object.keys(alerts).length > 0 &&
                    Object.entries(alerts).map(([key, alert], i) => {
                        // if the message property is an object instead of string, log error to the console.
                        alert.message && typeof alert.message === "object" ? console.log(`Invalid File Upload Error: `, alert) : null

                        // if the message property is a string
                        return (
                            <Alert key={`alert-${i}`} id={alert.id!} expiry={alert.expiry} type={alert.type}>
                                {alert.message}
                            </Alert>
                        );
                    })}
            </div>
        );
    };

    const renderPages = () => {
        if (authenticating) {
            return (
                <div className="gray-bg loader">
                    <Spinner />
                </div>
            );
        }
        return (
            <Switch>
                <AuthRoute path="/logout" component={SignOutContainer} />
                <AuthRoute path="/login" component={SignInContainer} />
                <AuthRoute path="/" component={MainContainer} />
                <Route render={props => <ErrorContainer error={<span>"404 Error! Page Not Found"</span>} {...props} />} />
            </Switch>
        );
    };
    if (!loaded) {
        return <Spinner initial={true} />;
    }
    return (
        <div className="app">
            {renderAlerts()}
            {renderPages()}
        </div>
    );
};

const mapStateToProps = state => ({
    authenticating: state.session.authenticating,
    alerts: state.utils.alerts
});

export default connect(mapStateToProps)(WrapperContainer);
