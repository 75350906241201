import React from "react";
import { createAlert } from "../../actions/utils";

interface uploadFile {
    uploadedImageURL?: ServerTypes.Console.IArticle["Image"];
    fileUpload?: File[] | null;
    addedImage?: ServerTypes.Console.IArticle["addedImage"];
    video?: ServerTypes.Console.IArticle["video"];
    uploadedFile?: boolean;
    Link?: ServerTypes.Console.IArticle["Link"];
    thumbnail?: string;
    customPdfFile?: File | null | undefined;
}

interface IBackgroundImageProps {
    name: string;
    onValidUpload?: (file: uploadFile) => void
    fileUploaded?: File[] | null | undefined | string;
    onChange: (permission: string, value: string | string[] | boolean | Date) => void;
}

export const BackgroundImage = (props: IBackgroundImageProps) => {
    const { name, fileUploaded, onValidUpload, onChange } = props;

    function _onChange(e) {
        const files = $("#" + e.target.id).prop("files")
        const fileSize = files[0].size;
        const fileType = files[0].type;
        var widthPixels;
        var heightPixels;

        var img = new Image();
        img.onload = function () {
            widthPixels = img.width;
            heightPixels = img.height;

            if (files.length > 1) {
                createAlert("Cannot add more than 1 image.");
                return;
            }
            if (!fileType.match(/.(png|jpeg)$/i)) {
                createAlert("Invalid file type. Please upload only a .png or .jpeg file.");
                return;
            }
            if (fileSize > 5 * 1200 * 800) {
                createAlert("Please upload an image file under 5MB.");
                return;
            }
            if (widthPixels < 1200 || heightPixels < 800) {
                createAlert("The image must have a minimum resolution of 1200 * 800px.");
                return;
            }

            if (onValidUpload) {
                onValidUpload({
                    fileUpload: files!,
                    uploadedImageURL: img.src || "",
                    uploadedFile: true
                });
                onChange(name, img.src || "");
            }
        };
        img.src = window.URL.createObjectURL(files[0]);
    }

    const thumbnailCss = (source: File[] | string) => {
        let backgroundImageSource = (typeof source === 'string' || source instanceof String) ? source : window.URL.createObjectURL(source[0])
        return {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        border: "1px solid #ccc",
        borderRadius: "5px",
        verticalAlign: "top",
        backgroundImage: `url(${backgroundImageSource})`,
        width: "75px",
        height: "75px"
    }};

    return (
        <div>
            { fileUploaded == null || fileUploaded == undefined
                ? (
                    <div style={{borderStyle: 'dashed', borderWidth: 1, borderRadius: 5, height: 78, display: 'flex', justifyContent: 'center'}} onClick={() =>  $("#imageUpload").trigger("click")}>
                        <span>
                            <i
                                className="icon ion-plus"
                                style={{
                                    color: "#152733",
                                    fontSize: "50px"
                                }} />
                        </span>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "inline-flex",
                            flexDirection: "row",
                            margin: "10px 5px"
                        }}
                    >
                        <span
                            className="closeButton"
                            onClick={() => {
                                onChange(name, "");
                                (document.getElementById('imageUpload') as HTMLInputElement).value = "";
                                if (onValidUpload) {
                                    onValidUpload({
                                        fileUpload: undefined,
                                        uploadedImageURL: undefined,
                                        uploadedFile: false
                                    });
                                }
                            }}
                        >x</span>
                        <div style={thumbnailCss(fileUploaded)} />
                    </div>
                )
            }
            {
                <input
                    id="imageUpload"
                    style={{
                        display: "none"
                    }}
                    type="file"
                    onChange={e => _onChange(e)} />
            }
        </div>
    )
};

export default BackgroundImage;
