import { apiRequest } from "../utils/Helpers";
import appConfig from "../../config/config.dev";

export interface IUserExportData {
    cid?: string;
    search?: string;
    isActive?: boolean;
    hasActivated?: boolean;
    gids?: string[];
    rid?: string;
    createdOnAfter?: string;
    createdOnBefore?: string;
    completedOnboarding?: boolean;
}

export async function doExportUsers(data: IUserExportData) {
    return apiRequest(`${appConfig.API_URL}/clientuser/csv/export`, "POST", data);
}
