import BaseAction from "../utils/BaseAction";

export interface IArticleActions {
    doGet(artid: ServerTypes.Console.IArticle["artid"], cid: ServerTypes.Console.IArticle["cid"]): Promise<ServerTypes.Console.IArticle | null>;
    doUpsert(article: ServerTypes.Console.IArticle, file: File[] | undefined): Promise<{ valid: boolean, error?: string }>;
    doRemove(artid: ServerTypes.Console.IArticle["artid"], cid: ServerTypes.Console.IArticle["cid"]): Promise<{ valid: boolean; } | null>;
    doLoad(url: string, lang: "en", force: false): Promise<{ valid: boolean }>;
}

class _ArticleActions extends BaseAction implements IArticleActions {
    async doGet(artid: ServerTypes.Console.IArticle["artid"], cid: ServerTypes.Console.IArticle["cid"]) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; item: ServerTypes.Console.IArticle }>(
            `/article/get`,
            "POST",
            { artid, cid }
        );
        if (data && data.item) {
            return data.item;
        }
        return null;
    }

    async doUpsert(article: ServerTypes.Console.IArticle, files: File[] | undefined = undefined, thumbnailFile: File | undefined = undefined, pdfFile?: File | null | undefined) {
        const formData = new FormData();
        if (pdfFile) formData.append("file", pdfFile);
        if (files) {
            files.forEach(file => formData.append("file", file));
            // @ts-expect-error
            delete article["Image"];
        }

        if (thumbnailFile) {
            formData.append("file", thumbnailFile);
        }

        Object.keys(article).forEach(key => {
            if ((article[key] != null || key === "forceScheduleDate" || key === "commentURL") && (article[key] != "" || ["displayAdvisorNote", "comment", "Image", "LockHashtagsAndMentions"].indexOf(key) > -1)) {
                formData.append(key, article[key]);
            }
        });

        const data = await this.apiRequest<{ valid: boolean, error?: string }>(
            `/article/upsert`,
            "post",
            formData,
        );
        if (!data || !data.valid) {
            throw new Error(data && data.error || "Upsert failed");
        }
        return data;
    }

    async doLoad(url: string, lang = "en", force = false) {
        const data = await this.apiRequest<{ valid: boolean }>(
            `/getArticleMeta`,
            "post",
            {
                url,
                lang,
                ...(force ? { force } : null)
            }
        );
        if (!data || !data.valid) {
            throw new Error("Load Article failed");
        }
        return data;
    }

    async doRemove(artid: ServerTypes.Console.IArticle["artid"], cid: ServerTypes.Console.IArticle["cid"]) {
        return this.apiRequestWithErrorAlert<{ valid: boolean }>(`/article/delete`, "POST", { artid, cid }, undefined, true);
    }

    // expires article
    async doExpire(artid: ServerTypes.Console.IArticle["artid"], cid: ServerTypes.Console.IArticle["cid"]) {
        return this.apiRequestWithErrorAlert<{ valid: boolean }>(`/article/expire`, "POST", { artid, cid }, undefined, true);
    }

    // unschedules already scheduled shares and deletes article
    async doUnscheduleAndRemove(sid: ServerTypes.Console.IArticle["sid"], artid: ServerTypes.Console.IArticle["artid"], artLink: ServerTypes.Console.IArticle["Link"], cid: ServerTypes.Console.IArticle["cid"]) {
        return this.apiRequestWithErrorAlert<{ valid: boolean }>(`/article/unschedule`, "POST", { sid, artid, artLink, cid }, undefined, true);
    }

    // unschedules and removes article from trending
    async doRemoveTrendingArticleByLink(link: ServerTypes.Console.IArticle["Link"], cid: string, sid: ServerTypes.Console.IArticle["sid"], artid: ServerTypes.Console.IArticle["artid"]) {
        return this.apiRequestWithErrorAlert<{ valid: boolean }>(`/article/removeTrendingArticle`, "POST", { link, cid, sid, artid }, undefined, true);
    }

    async doUpdateLockedHashtagsAndMentions(_id: string, Hashtags: string[], Mentions: string[], LockHashtagsAndMentions: boolean, linkedinMentions: Mongo.ILinkedinMention[]){
        return this.apiRequestWithErrorAlert<{ valid: boolean }>(`/article/updateLockedHashtagsAndMentions`, "POST", { _id, Hashtags, Mentions, LockHashtagsAndMentions, linkedinMentions } , undefined, true);
    }

    async doGetFutureSharesByArtid(artid: string, cid: string) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; shares: Mongo.shares[] }>(`/article/getFutureSharesByArtid`, "POST", { artid, cid });
        if (data && data.shares) {
            return data.shares.length;
        }
        return 0;
    }

    async doGetPostedSharesByArtid(artid: string, cid: string) {
        const data = await this.apiRequestWithErrorAlert<{ valid: boolean; shares: Mongo.shares[] }>(`/article/getPostedSharesByArtid`, "POST", { artid, cid });
        if (data && data.shares) {
            return data.shares.length;
        }
        return 0;
    }

    async doGetUnscheduledArticleAffectedUsers(filters: dynamic) {
        return this.apiRequest(`/reports/user/unscheduledArticleAffectedUsers/csv`, "POST", filters).catch(err => err);
    }
}

export const ArticleActions = new _ArticleActions();
