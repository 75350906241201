import React, { Component } from "react";

import ElementEditCard from "../../molecules/ElementEditCard";

import { createAlert, CustomBrandActions } from "../../../actions";
import { Brand } from "../../../utils/Models";
import { history } from "../../../utils/store";
import { isURL } from "../../../utils/Helpers";
import ArticleFileUpload from '.././../molecules/ArticleFileUpload';
import { RouteComponentProps } from "react-router-dom";

interface IEditCustomBrandProps extends RouteComponentProps<{ brandName: string }> {}

interface IEditCustomBrandState {
    brandName: string;
    chartColoursString: string;
    uploadedFile: boolean | undefined;
    fileUpload: File[] | null | undefined;
    uploadedImageURL?: string;
}

class EditCustomBrand extends Component<IEditCustomBrandProps, IEditCustomBrandState> {
    static allowSuper = true;
    static allowApiAdmin = true;
    static allowClientAdmin = false;
    static allowAdmin = false;
    static allowCurator = false;
    static allowReports = false;
    constructor(props: IEditCustomBrandProps) {
        super(props);
        let brandName = "";

        if (props.match.params.brandName) {
            brandName = props.match.params.brandName;
        }

        this.state = {
            brandName,
            uploadedFile: false,
            fileUpload: null,
            uploadedImageURL: "",
            chartColoursString: ""
        };
    }

    _validateHexCode(hexCode: string) {
        const re = /^(?:[0-9a-f]{3}){1,2}$/i;
        return re.test(hexCode) ? true : "Invalid formatting for App Colors. Must be a valid 3 or 6 character hex color code using characters 0-9 and a-f.";
    }

    _validateNoSpace(str: string) {
        const reNoSpace = /^\S*$/;
        return reNoSpace.test(str) ? true : "Branding Scheme Name must be one word. Please remove any spaces.";
    }

    _validateImgFile(fileName: string) {
        const reImgFile = /^.*\.(png|PNG)$/;
        return reImgFile.test(fileName) ? true : "Incorrect formatting for App Icon. Please ensure this field includes a .png file.";
    }

    _validateURL(url: string) {
        return isURL(url) ? true : "Incorrect formatting for Help Page Video. Please ensure a valid URL is used.";
    }

    async _onSubmit(item: Mongo.IBrand) {
        item.chartColors = this.state.chartColoursString.split(/,\s*/);
        const { fileUpload } = this.state;
        const brand = Brand(item);
        try {
            await CustomBrandActions.doUpsert({ ...brand }, fileUpload as File[]);
            const action = this.state.brandName ? "updated" : "created";
            createAlert(`Successfully ${action} ${brand.brandName}`, `success`);

            // Redirect to homepage of custombrands
            setTimeout(() => {
                history.push("/custombrands");
            }, 0);
        } catch (error) {
            //
        }
    }

    _onLoad(item: Mongo.IBrand) {
        if (Array.isArray(item.chartColors)) {
            this.setState({ chartColoursString: item.chartColors.join(", ") });
        } else {
            this.setState({ chartColoursString: item.chartColors as unknown as string });
        }
    }

    render() {
        const { brandName, uploadedImageURL } = this.state;
        return (
            <ElementEditCard<Mongo.IBrand>
                getItem={async () => Brand({ ...(await CustomBrandActions.doGet(brandName)) })}
                canUpdate={() => Promise.resolve(true)}
                update={item => this._onSubmit(item)}
                onLoad={item => this._onLoad(item)}
                breadcrumbs={[{ name: "Custom Branding", link: "/custombrands" }]}
                elementName="Custom Brand"
                skipSanitize={true}
                instructions="https://seismic-my.sharepoint.com/:b:/p/jodi_lee/EW7jTZiQcWpKko158_q9RDwBwp6Sq5C1DmlE6rlSocJwDw?e=LUVVzP"
                rows={[
                    {
                        fullWidth: true,
                        columns: [{ label: "", items: ["brandName"], key: "EditCustomBrand_R1C1" }],
                        key: "EditCustomBrand_Row1"
                    },
                    {
                        label: "App Details",
                        columns: [
                            { label: "", items: ["appTitle", "appIcon"], key: "EditCustomBrand_R2C1" },
                            { label: "", items: ["appTitleFormatted", "videoTutorial"], key: "EditCustomBrand_R2C2" }
                        ],
                        key: "EditCustomBrand_Row2"
                    },
                    {
                        label: "App Colors",
                        columns: [
                            {
                                label: "",
                                items: ["btnBgColor", "composeBtn", "headerBarColor", "topMenuIcon", "tabs", "extBrowserBarColor", "primaryColor"],
                                key: "EditCustomBrand_R3C1"
                            },
                            {
                                label: "",
                                items: ["btnTxtColor", "txtColor", "headerBarBorderColor", "iconColor", "loadingBg", "secondaryColor", "tertiaryColor"],
                                key: "EditCustomBrand_R3C2"
                            }
                        ],
                        key: "EditCustomBrand_Row3"
                    },
                    {
                        fullWidth: true,
                        columns: [{ label: "", items: ["chartColors"], key: "EditCustomBrand_R4C1" }],
                        key: "EditCustomBrand_Row4"
                    }
                ]}
                idField="brandName"
                rowPadding={0}
                editableKeys={(item, getStateManual, setStateManual) => {
                    return {
                        brandName: {
                            label: "Branding Scheme Name",
                            description:
                                "The name of the custom branding scheme, used internally in the console. Must be a single word (cannot not include any spaces). Note: this field cannot be modified after the brand is created.",
                            placeholder: "e.g. Seismic",
                            type: "text",
                            required: true,
                            validateInput: this._validateNoSpace,
                            disabled: this.state.brandName ? true : false,
                            customTopLevelClasses: "customBrandInput"
                        },
                        appTitle: {
                            label: "App Title",
                            description:
                                "The title of the app. Used when referring to the app by name both within the app itself (e.g. FAQ section) and in emails sent to users. ",
                            placeholder: "e.g. Seismic LiveSocial",
                            type: "text",
                            required: true,
                            customTopLevelClasses: "customBrandInput"
                        },
                        appTitleFormatted: {
                            label: "App Title with Formatting",
                            placeholder: 'e.g. <span style="color:#152733;font-weight:bold">SEISMIC | Live Social</span>',
                            description:
                                "The title of the app, with specific HTML formatting. Used on the login page and loading pages.",
                            type: "text",
                            required: false,
                            customTopLevelClasses: "customBrandInput"
                        },
                        appIcon: {
                            label: "App Icon",
                            placeholder: "e.g. imagename.png",
                            description:
                                "The icon of the app. Used on the login page and top navigation bar. The image should be a square, transparent .png with minimum dimensions of 512 x 512 px.",
                            type: "custom-link",
                            value: uploadedImageURL,
                            required: true,
                            validateInput: this._validateImgFile,
                            renderAbove: <ArticleFileUpload
                                uploadLinkStyle={{ float: "right", color: "#152733", fontWeight: "normal" }}
                                title="Upload Image"
                                onValidUpload={entries => {
                                    const { uploadedFile, fileUpload } = entries;
                                    const imgUrl = fileUpload ? fileUpload[0].name : "";
                                    if (fileUpload && fileUpload.length > 1) {
                                        fileUpload.splice(0, 1);
                                    }
                                    this.setState({ uploadedFile, fileUpload, uploadedImageURL: imgUrl });
                                    setStateManual({ appIcon: imgUrl })
                                }}
                                singleImageOnly={true}
                            />,
                            disabled: true,
                            customTopLevelClasses: "customBrandInput"
                        },
                        videoTutorial: {
                            label: "Help Page Video",
                            placeholder: "e.g. helpvideolink.com",
                            description: "The video displayed under \"Getting Started\" on the Help page. Must be a URL.",
                            type: "text",
                            required: false,
                            validateInput: this._validateURL,
                            customTopLevelClasses: "customBrandInput"
                        },
                        btnBgColor: {
                            label: "Primary Button Background",
                            placeholder: "e.g. 152733",
                            description:
                                "The background color of primary buttons in the app. If using Original Card styling, this will be the Primary Button Text color",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        btnTxtColor: {
                            label: "Primary Button Text",
                            placeholder: "e.g. FFFFFF",
                            description:
                                "The text color of primary buttons in the app. If using Original Card styling, this will be the Primary Button Background color.",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        composeBtn: {
                            label: "Compose Button Background",
                            placeholder: "e.g. 152733",
                            description: "The background color of the compose button in the app (desktop only).",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        txtColor: {
                            label: "Link Text",
                            placeholder: "e.g. 152733",
                            description: "The text color of links (i.e. secondary buttons) in the app.",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        headerBarColor: {
                            label: "Top Navigation Bar Background",
                            placeholder: "e.g. FFFFFF",
                            description: "The background color of the top navigation bar in the app.",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        primaryColor: {
                            label: "Brand Primary Color",
                            placeholder: "e.g. FFFFFF",
                            description: "Brand primary color.",
                            type: "text",
                            required: false,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        secondaryColor: {
                            label: "Brand Secondary Color",
                            placeholder: "e.g. FFFFFF",
                            description: "Brand secondary color.",
                            type: "text",
                            required: false,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        tertiaryColor: {
                            label: "Tertiary Color",
                            placeholder: "e.g. FFFFFF",
                            description: "Brand Alternate color.",
                            type: "text",
                            required: false,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        headerBarBorderColor: {
                            label: "Top Navigation Bar Border",
                            placeholder: "e.g. 152733",
                            description:
                                "The color of the line between the top navigation bar and the page's content in the app.",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        topMenuIcon: {
                            label: "Top Navigation Bar Text and Icons",
                            placeholder: "e.g. 152733",
                            description: "The text and icon colors of the top navigation bar in the app.",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        iconColor: {
                            label: "Side Navigation Bar Selected Icon",
                            placeholder: "e.g. 666666",
                            description:
                                "The color of icon for the selected page from the side navigation bar (desktop only)",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        tabs: {
                            label: "Tab Text",
                            placeholder: "e.g. 152733",
                            description: "The text color a selected tab on a page in the app (e.g. Schedule).",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        loadingBg: {
                            label: "Loading Screen Background",
                            placeholder: "e.g. F2F7F7",
                            description:
                                "The background color of the loading screen in the app (e.g. once the user has logged in and the app is initializing).",
                            type: "text",
                            required: true,
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        extBrowserBarColor: {
                            label: "Mobile Login Header",
                            placeholder: "e.g. F2F7F7",
                            description: "The color of the browser header when logging in through mobile",
                            type: "text",
                            validateInput: this._validateHexCode,
                            customTopLevelClasses: "customBrandInput"
                        },
                        chartColors: {
                            label: "Report Colors",
                            value: this.state.chartColoursString,
                            placeholder: "e.g. F1592A, 178AF7, 37A459, FFB83D, CD2F2F, 787982, B8D9E5, FABE8A",
                            description:
                                "The colors used for reports in the app. We recommend 8-10 colors, to ensure colors are not duplicated on a report.",
                            type: "text",
                            required: false,
                            mutateChange: async (k, v) => {
                                this.setState({ chartColoursString: v as string });
                                return { [k]: v };
                            },
                            customTopLevelClasses: "customBrandInput"
                        }
                    };
                }}
            />
        );
    }
}

export default EditCustomBrand;
