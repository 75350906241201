import React, { useState, useEffect } from "react";
import { getFriendlyDate, getAdminDate } from "../../../../utils/Helpers";
import { OfferActions } from "../../../../actions/OfferActions";
import { OfferLeadActions } from "../../../../actions/OfferLeadActions";
import { LeadActions } from "../../../../actions/LeadActions";
import { SocialInteractionsActions } from "../../../../actions/SocialInteractionsActions";
import { Wrapper, Row } from "../../../atoms/Layout";
import Tooltip from "../../../atoms/Tooltip";
import Chart from "../../../molecules/Chart";
import ElementListBox, { Filter, PaginationData } from "../../../molecules/ElementListBox";
import { IReportFilter } from "../Index";

export interface IOfferLeadsPageProps {
    filters: IReportFilter;
    client?: Mongo.client;
    user: Mongo.clientAdmin;
    onLoaded: () => void;
    showSearchResults: boolean;
}

export default (props: IOfferLeadsPageProps) => {
    const { client, user } = props;
    const showOffers = user.isSuper || client && client.allowOffers;
    const showLeads = user.isSuper || client && client.allowProspect;
    const showSocialInteractions  =
        ((user.isSuper || user.isSuperClientAdmin || user.isClientAdmin || user.isReports) && client && client.allowSocialInteractionCapture);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<IReportFilter>(props.filters);
    const [totalLeads, setTotalLeads] = useState(0);
    const [prospectLeads, setProspectLeads] = useState<Omit<ServerTypes.Console.ILeadReportItem, "locs" | "totalLeads"> | null>(null);
    const [offersLoaded, setOffersLoaded] = useState(!showOffers);
    const [offerLeadsLoaded, setOfferLeadsLoaded] = useState(!showOffers);
    const [leadsLoaded, setLeadsLoaded] = useState(!showLeads);
    const [socialInteractionsLoaded, setSocialInteractionsLoaded] = useState(!showSocialInteractions);

    const refreshProspects = async () => {
        setLeadsLoaded(false);
        setProspectLeads(null);
        setTotalLeads(0);
        const data = await LeadActions.doGet({ ...props.filters });
        if (data) {
            const { totalLeads, ..._prospectLeads } = data;

            setProspectLeads(_prospectLeads);
            setTotalLeads(totalLeads);
        }

        setLeadsLoaded(true);
    };

    const refreshSocialInteractions = async (_filters: Filter, pagination: PaginationData) => {
        setSocialInteractionsLoaded(false);
        const data = await SocialInteractionsActions.doList({
            ...filters,
            ...(pagination.limit ? { limit: pagination.limit } : {}),
            ...(Object.keys(pagination.sort || {}).length ? { sort: Object.keys(pagination.sort || {})[0], sortDirection: Object.values(pagination.sort || {})[0] } : {})
        });
        setSocialInteractionsLoaded(true);
        const displayData = {
            items: data.items.slice(0,20),
            total: 20
        }
        return displayData;
    }

    const trimEntry = (string: string, length: number) => {
        if (string.length > length) {
            return string.substring(0, length - 2) + "...";
        } else return string;
    }

    // If we change the filters lets set the cards telling them to update their data and refresh the prospects
    useEffect(() => {
        if (props.showSearchResults && (showOffers || showLeads || showSocialInteractions)) {
            setRefresh(true);
            setFilters(props.filters);
            if (showLeads) {
                refreshProspects();
            }
        }
    }, [props.filters, props.showSearchResults]);

    // If the loaded state changes on all available cards lets let the index report page know that we have loaded the reports and we can allow changing and clicking of the filters
    useEffect(() => {
        if (
            (!showOffers || (showOffers && offersLoaded)) &&
            (!showOffers || (showOffers && offerLeadsLoaded)) &&
            (!showLeads || (showLeads && leadsLoaded)) &&
            (!showSocialInteractions || (showSocialInteractions && socialInteractionsLoaded))
        ) {
            props.onLoaded();
        }
    }, [offersLoaded, offerLeadsLoaded, leadsLoaded, socialInteractionsLoaded, showOffers, showLeads, showSocialInteractions]);

    if (!showOffers && !showLeads && !showSocialInteractions) {
        return <Wrapper id="leads">
            <Row>
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>No Data</h5>
                    </div>
                    <div className="ibox-content">
                        <i>Could not find any reporting data</i>
                    </div>
                </div>
            </Row>
        </Wrapper>;
    }

    return <Wrapper id="leads">
        {showOffers && (<>
            {props.showSearchResults ? <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <ElementListBox<ServerTypes.Console.IOfferReportItem>
                    title="Offers"
                    filterSearch={false}
                    filterCid={false}
                    forceRefresh={refresh}
                    resetRefresh={() => setRefresh(false)}
                    sortableKeys={["User", "Country", "City", "Type", "Date/Time"]}
                    defaultSort={{ "date": -1 }}
                    listCount={10}
                    download={
                        (_filters: Filter, pagination: PaginationData) => OfferActions.doDownload({ ...filters })
                    }
                    refresh={async (_filters: Filter, pagination: PaginationData) => {
                        setOffersLoaded(false);
                        const data = await OfferActions.doList({
                            ...filters,
                            ...(pagination.limit ? { limit: pagination.limit } : {}),
                            ...(Object.keys(pagination.sort || {}).length ? { sort: Object.keys(pagination.sort || {})[0], sortDirection: Object.values(pagination.sort || {})[0] } : {})
                        });
                        setOffersLoaded(true);
                        return data;
                    }}
                    mutateItem={item => ({
                        user: item.user,
                        stream: item.stream || "All",
                        terms: item.terms.join(", "),
                        country: item.country || "Unknown",
                        city: item.city || "Unknown",
                        type: item.type.replace(/_/gi, " "),
                        date: <Tooltip label={getFriendlyDate(item.date)}>{getAdminDate(item.date)}</Tooltip>
                    })}
                    keys={{
                        user: "User",
                        stream: "Stream",
                        terms: "Terms",
                        country: "Country",
                        city: "City",
                        type: "Type",
                        date: "Date/Time"
                    }}
                />
            </Row> : <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            Offers
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            <i>To run this report, please adjust the filters above and click "Search"</i>
                        </div>
                    </div>
                </div>
            </Row>}
            {props.showSearchResults ? <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <ElementListBox<ServerTypes.Console.IOfferLeadReportItem>
                    title="Offer Leads"
                    filterSearch={false}
                    filterCid={false}
                    forceRefresh={refresh}
                    resetRefresh={() => setRefresh(false)}
                    sortableKeys={["User", "Email", "Name", "Country", "City", "Type", "Date/Time"]}
                    defaultSort={{ "date": -1 }}
                    listCount={10}
                    download={
                        (_filters: Filter, pagination: PaginationData) => OfferLeadActions.doDownload({ ...filters })
                    }
                    refresh={async (_filters: Filter, pagination: PaginationData) => {
                        setOfferLeadsLoaded(false);
                        const data = await OfferLeadActions.doList({
                            ...filters,
                            ...(pagination.limit ? { limit: pagination.limit } : {}),
                            ...(Object.keys(pagination.sort || {}).length ? { sort: Object.keys(pagination.sort || {})[0], sortDirection: Object.values(pagination.sort || {})[0] } : {})
                        });
                        setOfferLeadsLoaded(true);
                        return data;
                    }}
                    mutateItem={item => ({
                        user: item.user,
                        stream: item.stream || "All",
                        email: item.email,
                        name: item.name,
                        terms: item.terms.join(", "),
                        country: item.country || "Unknown",
                        city: item.city || "Unknown",
                        type: item.type.replace(/_/gi, " "),
                        date: <Tooltip label={getFriendlyDate(item.date)}>{getAdminDate(item.date)}</Tooltip>
                    })}
                    keys={{
                        user: "User",
                        stream: "Stream",
                        email: "Email",
                        name: "Name",
                        terms: "Terms",
                        country: "Country",
                        city: "City",
                        type: "Type",
                        date: "Date/Time"
                    }}
                />
            </Row> : <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            Offer Leads
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            <i>To run this report, please adjust the filters above and click "Search"</i>
                        </div>
                    </div>
                </div>
            </Row>}
        </>
        )}
        {showLeads && <Row style={{paddingLeft: "15px", paddingRight: "15px"}}>
            <Chart
                title={`Prospect Leads`}
                loading={!leadsLoaded}
                data={[...(prospectLeads ? [prospectLeads?.totalAccepted, prospectLeads?.totalRejected, prospectLeads?.totalNew, prospectLeads?.totalExpired, prospectLeads?.totalCompleted] : [])]}
                labels={["Accepted Leads", "Rejected Leads", "New Leads", "Expired Leads", "Completed Leads"]}
                {...leadsLoaded ? { description: `Total Leads - ${totalLeads}` } : {}}
                type="pie"
                downloadAction={totalLeads ? () => LeadActions.doDownload({ ...filters }) : undefined}
                showSearchResults={props.showSearchResults}
            />
        </Row>}
        {showSocialInteractions && (
            props.showSearchResults ?
            <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <ElementListBox<ServerTypes.Console.ISocialInteractionsItem>
                    title="Social Interactions"
                    filterSearch={false}
                    filterCid={false}
                    forceRefresh={refresh}
                    resetRefresh={() => setRefresh(false)}
                    sortableKeys={["User", "Email", "Content", "Type", "Engager name", "Date/time engaged"]}
                    defaultSort={{ "date": 1 }}
                    pagination={false}
                    download={(_filters: Filter, pagination: PaginationData) => SocialInteractionsActions.doDownload({ ...filters })}
                    refresh={refreshSocialInteractions}
                    mutateItem={item => ({
                        User: <Tooltip label={trimEntry(item.User, 27)}>{item.User}</Tooltip>,
                        Email: <Tooltip label={trimEntry(item.Email, 18)}>{item.Email}</Tooltip>,
                        Content:
                            item["Content URL"] ?
                            <a href={item["Content URL"]} target="_blank"><Tooltip label={trimEntry(item["Content"], 25)}>{item["Content"]}</Tooltip></a> :
                            <Tooltip label={trimEntry(item["Content"], 25)}>{item["Content"]}</Tooltip>,
                        Type: item.Type,
                        Comment: item.Comment ? <Tooltip label={trimEntry(item.Comment, 25)}>{item.Comment}</Tooltip> : "",
                        "Engager name": item["Engager profile URL"] ?
                            <a href={item["Engager profile URL"]} target="_blank">{item["Engager name"]}</a> :
                            item["Engager name"],
                        "Engager headline": item["Engager headline"] ? <Tooltip label={trimEntry(item["Engager headline"], 25)}>{item["Engager headline"]}</Tooltip> : "",
                        "Date/time engaged": <Tooltip label={getFriendlyDate(item["Date/time engaged"])}>{getAdminDate(item["Date/time engaged"])}</Tooltip>
                    })}
                    keys={{
                        User: "User",
                        Email: "Email",
                        Content: "Content",
                        Type: "Type",
                        Comment: "Comment",
                        "Engager name": "Engager name",
                        "Engager headline": "Engager headline",
                        "Date/time engaged": "Date/time engaged"
                    }}
                    isSocialInteractions={true}
                />
            </Row>
            :
            <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div className="ibox" style={{ flex: 1 }}>
                    <div className="ibox-title">
                        <h5
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            Social Interactions
                        </h5>
                    </div>
                    <div className="ibox-content" style={{ minHeight: "300px" }}>
                        <div>
                            <i>To run this report, please adjust the filters above and click "Search"</i>
                        </div>
                    </div>
                </div>
            </Row>
        )}
    </Wrapper>;
};