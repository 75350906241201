import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withLastLocation, WithLastLocationProps } from 'react-router-last-location';

import {
    getLocaleDate,
    dateToUTCISOString,
    getFriendlyDate,
    downloadFile as fileDownload
} from "../../../utils/Helpers";
import { doGetUsers, createAlert, doExportUsers, IUserExportData, doGetReduxClients, doGetReduxClient, ComplianceActions, IComplianceUserReportFilters } from "../../../actions";
import ElementListBox, { Filter, PaginationData } from "../../molecules/ElementListBox";
import ActivationEmailAllModal from "../../molecules/modals/ActivationEmailAllModal";
import NotSharedInThirtyDaysModal from "../../molecules/modals/NotSharedInThirtyDaysModal";
import { Wrapper, Row } from "../../atoms/Layout/";
import DownloadButton from "../../atoms/DownloadButton";
import { UserActions } from "../../../actions"

type ListFilters = {
    isActive?: string;
    hasActivated?: string;
    groups?: string;
    rid?: string;
    startDate?: string;
    endDate?: string;
} & Filter;

interface IUserListProps extends WithLastLocationProps {
    clients: dynamic<Mongo.client>;
    user: Mongo.clientAdmin
}

interface IUserListState {
    startDate: string;
    endDate: string;
    exportingUsers: boolean;
    exportingPP: boolean;
    experimentalMode: boolean;
    autoActivationReminder: boolean;

    modals: {
        activationEmail: boolean;
        notSharedEmail: boolean
    };

    filters: ListFilters;
    paginationData: PaginationData;
    lang: string;
    complianceVendors: { [cid: string]: string };
    isFilterChanged: boolean;
    isSoftDeletedUsers: boolean;
}

class Users extends Component<IUserListProps, IUserListState> {
    static allowSuper = true;
    static allowApiAdmin = false;
    static allowClientAdmin = true;
    static allowAdmin = true;
    static allowCurator = false;
    static allowReports = false;

    constructor(props) {
        super(props);
        this._refresh = this._refresh.bind(this);
        this._downloadExport = this._downloadExport.bind(this);

        const end = new Date();
        const start = new Date(2000, 0, 1);
        this.state = {
            startDate: start.toISOString(),
            endDate: end.toISOString(),
            exportingUsers: false,
            exportingPP: false,
            lang: props.lang || "en",
            experimentalMode: false,
            autoActivationReminder: false,
            modals: {
                activationEmail: false,
                notSharedEmail: false
            },
            filters: {
                search: "",
                cid: props.user ? props.user.cid || "" : "",
                isActive: "1",
                startDate: start.toISOString(),
                endDate: end.toISOString()
            },
            paginationData: {
                limit: 20,
                skip: 0
            },
            complianceVendors: {},
            isFilterChanged: false,
            isSoftDeletedUsers: false
        };
    }

    async componentDidMount() {
        if (this.props.user.isSuper) {
            await doGetReduxClients().then(async clients => {
                if (clients) {
                    const complianceVendors = {};
                    for (const client of clients) {
                        if (client.complianceEnabled) {
                            const vendor = await ComplianceActions.getComplianceVendor(client.cid);
                            if (vendor) {
                                complianceVendors[client.cid] = vendor;
                            }
                        }
                    }
                    this.setState({ complianceVendors });
                }
            });
        } else {
            await doGetReduxClient(this.state.filters.cid).then(async client => {
                const experimentalMode = client?.experimentalMode || false;
                const autoActivationReminder = client?.autoActivationReminder || false;
                if (client?.complianceEnabled) {
                    const vendor = await ComplianceActions.getComplianceVendor(client.cid);
                    if (vendor) {
                        this.setState({ complianceVendors: { [client.cid]: vendor }, experimentalMode, autoActivationReminder });
                    }
                }
                this.setState({ experimentalMode, autoActivationReminder });
            });
        }
    }

    _getClientName(user: Mongo.clientUsers) {
        const { clients } = this.props;
        if (user.cid) {
            if (clients?.[user.cid]) {
                return clients[user.cid]!.name;
            } else if (clients && Object.keys(clients).length) {
                return "REMOVED";
            }
            return "Loading...";
        }
        return "None";
    }

    async _softDeleteUser(user: Mongo.clientUsers) {
        if (confirm("Are you sure you want to delete this user? All user data will be permanently deleted")) {
            try {
                const data = await UserActions.softDeleteUser(user);
                if (data) {
                    createAlert("User has been successfully deleted", "success");
                }
                return true;
            } catch (err) {
                return false;
            }
        } else {
            return false;
        }
    }

    async _undoSoftDeleteUser(user: Mongo.clientUsers) {
        try {
            const data = await UserActions.undoSoftDeleteUser(user);
            if (data) {
                createAlert("User has been successfully activated again", "success");
            }
            return true;
        } catch (err) {
            return false
        }
    }

    _prepareFilterData(filters: ListFilters) {
        const { cid, search, isActive, hasActivated, groups, rid, startDate, endDate } = filters;
        // Date control always return local date at noon as a UTC ISO string.  Need to convert to local days start and end as UTC ISO strings.
        const createdOnAfter = startDate ? dateToUTCISOString(startDate) : null;
        const createdOnBefore = endDate ? dateToUTCISOString(endDate, true) : null;
        return {
            ...(cid ? { cid } : null),
            ...(search ? { search } : null),
            ...(isActive && isActive !== "" ? { isActive: isActive === "1" } : null),
            ...(isActive && isActive == "2" ? { softDeleted: true } : null),
            ...(hasActivated && hasActivated == "0" ? { completedOnboarding: false, hasActivated: false } : null),
            ...(hasActivated && hasActivated == "1" ? { completedOnboarding: true, hasActivated: true } : null),
            ...(hasActivated && hasActivated == "2" ? { completedOnboarding: false, hasActivated: true } : null),
            ...(groups ? { gids: [groups] } : null),
            ...(rid ? { rid } : null),
            ...(createdOnAfter ? { createdOnAfter } : null),
            ...(createdOnBefore ? { createdOnBefore } : null)
        } as IUserExportData;
    }

    _preparePPStatsFilterData(filters: ListFilters) {
        const { cid, groups, rid, startDate, endDate } = filters;
        const sd = startDate ? startDate.substr(0, 10) : "";
        const ed = endDate ? endDate.substr(0, 10) : "";
        return {
            ...(cid ? { clientCode: cid } : null),
            ...(groups ? { grp: [groups] } : null),
            ...(rid ? { rid } : null),
            ...(sd ? { sd, sdtz: new Date(`${sd}T00:00:00.000`).getTimezoneOffset() } : null),
            ...(ed ? { ed, edtz: new Date(`${ed}T23:59:59.999`).getTimezoneOffset() } : null)
        } as IComplianceUserReportFilters;
    }

    _refresh(filters: ListFilters, paginationData: PaginationData) {
        if (!filters) {
            filters = this.state.filters || {};
        }
        if (!this.props.user.isSuper) {
            filters.cid = this.props.user.cid!;
        }
        if (!paginationData) {
            paginationData = this.state.paginationData;
        }
        if (filters.isActive == "2") {
            this.setState({ isFilterChanged: true, isSoftDeletedUsers: true });
        } else {
            this.setState({ isFilterChanged: true, isSoftDeletedUsers: false });
        }
        localStorage.setItem("usersFilter", JSON.stringify(filters));

        return new Promise<{ items: Mongo.clientUsers[]; total: number }>(res => {
            this.setState({ filters, paginationData }, async () => {
                res(await doGetUsers({
                    ...this._prepareFilterData(filters),
                    ...paginationData
                }));
            });
        });
    }

    getRole(user: Mongo.clientUsers) {
        const { clients } = this.props;
        const role = user.rid ? clients?.[user.cid]?.roles?.[user.rid] : undefined;
        return (role || { name: "None" }).name;
    }

    getGroups(user: Mongo.clientUsers) {
        const { clients } = this.props;
        if (!clients || !user.cid || !clients[user.cid]) {
            return [<span key={`unknown`}>Unknown</span>];
        }
        const { groups } = clients[user.cid]!;
        const displayGroups: string[] = [];

        if (typeof user.groups === "string") {
            user.groups = [user.groups];
        }
        if (user.groups && user.groups.length > 0) {
            user.groups.forEach(g => {
                if (g != "") {
                    displayGroups.push((groups[g] || { name: "*Missing Group*" }).name);
                }
            });
        }
        if (displayGroups.length) {
            return displayGroups.map((val, i) => {
                return (
                    <span key={val}>
                        {i > 0 && <span>,</span>}
                        {val}
                    </span>
                );
            });
        } else {
            return [<span key={`no-group`}>No Group</span>];
        }
    }

    stripSoftDeleted(email: string) {
        // remove softDeleted and partial uuid from email if it exists. Display original
        const regex = /(softDeleted)\w{8}-\w{4}-\w{4}-\w{4}\+/i
        return email?.replace(regex, "");
    }

    getEmails(cid: string, emailType: string) {
        const { clients } = this.props;
        if (!clients || !cid || !clients[cid]) {
            return;
        }
        const emails = clients[cid]!.customEmails![emailType];
        return emails;
    }

    getOptionRoles(cid: string) {
        const { clients } = this.props;
        if (!clients || !cid || !clients[cid]) {
            return null;
        }
        const { roles } = clients[cid]!;
        return Object.values(roles || []).map(role => ({ key: role.name, value: role.rid })).sort((a, b) => a.key.localeCompare(b.key));
    }

    showDeletedOption() {
        if (this.props.user.isSuper
            || this.props.user.isSuperClientAdmin) {
                return [{ key: "Deleted", value: "2" }]
            } else {
                return null
            }
    }

    getOptionGroups(cid: string) {
        const { clients } = this.props;
        if (!clients || !cid || !clients[cid]) {
            return null;
        }
        const { groups } = clients[cid]!;
        const _groups = Object.values(groups || {}).map(g => ({ key: g!.name, value: g!.gid })).sort((a, b) => a.key.localeCompare(b.key));
        if (this.props.user.groups && this.props.user.groups.length) {
            return _groups.filter(item => this.props.user.groups.indexOf(item.value) > -1);
        }
        return _groups;
    }

    async _downloadExport(filters: ListFilters) {
        if (this.state.exportingUsers) {
            return;
        }
        this.setState({ exportingUsers: true });
        const data = this._prepareFilterData(filters);
        const results = await doExportUsers(data).catch(error => {
            createAlert(error, `error`);
        });
        if (results) {
            fileDownload(results, `user-export-${getLocaleDate(new Date())}.csv`);
        }
        this.setState({ exportingUsers: false });
    }

    async _downloadPPExport(filters: ListFilters) {
        if (this.state.exportingPP) {
            return;
        }
        this.setState({ exportingPP: true });
        const data = this._preparePPStatsFilterData(filters);
        const results = await ComplianceActions.doGetStatsReport(data).catch(error => {
            createAlert(error, `error`);
        });
        if (results) {
            fileDownload(results, `user-complianceStats-${getLocaleDate(new Date())}.csv`);
        }
        this.setState({ exportingPP: false });
    }

    getDownloadButtons(filters: Filter) {
        if (!filters.cid || !this.props.clients || !this.props.clients[filters.cid]) {
            return [];
        }
        const isPP = Object.keys(this.state.complianceVendors || {}).length ? this.state.complianceVendors[filters.cid] === "ng" : false;
        if (isPP) {
            return [<div className="dropdown" style={{
                width: "auto",
                display: "inline-block",
                marginLeft: "10px"
            }}>
                <a
                    className="dropdown-toggle btn btn--sm brandPrimary--bg"
                    data-toggle="dropdown"
                >
                    <i className="fa fa-download" /> Download
                   <i style={{ marginLeft: "10px" }} className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu dropdown-download" style={{ left: "auto", right: 0 }}>
                    <li>
                        <DownloadButton hideStyle={true} className="dropdown-item" onClick={() => this._downloadExport(filters)}>
                            User List
                        </DownloadButton>
                    </li>
                    <li>
                        <DownloadButton hideStyle={true} className="dropdown-item" onClick={() => this._downloadPPExport(filters)}>
                            Proofpoint Status Report
                        </DownloadButton>
                    </li>
                </ul>
            </div>];
        }
        return [<DownloadButton className="btn--sm" onClick={() => this._downloadExport(filters)}>
            <i className="fa fa-download" /> Download
        </DownloadButton >];
    }
    checkLocalStorage() {
        const previousFilterState = localStorage.getItem("usersFilter");
        return previousFilterState ? JSON.parse(previousFilterState) : this.state.filters;
    }
    render() {
        const { isFilterChanged, isSoftDeletedUsers } = this.state;
        if ((this.props.lastLocation === null) || (this.props.lastLocation.pathname.indexOf('users') === -1)) {
            !isFilterChanged ? localStorage.removeItem("usersFilter") : null;
        }

        return (
            <Wrapper id="user">
                <ActivationEmailAllModal
                    open={this.state.modals.activationEmail}
                    user={this.props.user}
                    emails={this.getEmails(this.state.filters.cid, "activationEmail")}
                    closeAction={() => this.setState({ modals: { ...this.state.modals, activationEmail: false } })}
                />
                <NotSharedInThirtyDaysModal
                    open={this.state.modals.notSharedEmail}
                    user={this.props.user}
                    emails={this.getEmails(this.state.filters.cid, "notSharedEmail")}
                    closeAction={() => this.setState({ modals: { ...this.state.modals, notSharedEmail: false } })}
                />
                <Row>
                    <ElementListBox<Mongo.clientUsers>
                        title="Users"
                        keys={{
                            name: "Name",
                            email: "Email",
                            ...(this.props.user.isSuper ? { cid: "Client" } : null),
                            rid: "Role",
                            group: "Groups",
                            description: "Description",
                            isActive: "Has Access",
                            hasActivated: "Activated State",
                            lastUpdatedOn: "Last Updated",
                            actions: "Actions"
                        }}
                        defaultFilter={this.checkLocalStorage()}
                        sortableKeys={["Email", "Role", "Description", "Has Access", "Activated State", "Last Updated"]}
                        defaultSort={{ lastUpdatedOn: -1 }}
                        filterCid={this.props.user.isSuper}
                        refresh={(filters, paginationData) => this._refresh(filters, paginationData)}
                        mutateItem={user => ({
                            ...(this.props.user.isSuper
                                ? { cid: <Link to={`/clients/e/${user.cid}`}>{this._getClientName(user)}</Link> }
                                : null),
                            name: `${user.firstName} ${user.lastName}`,
                            email: this.stripSoftDeleted(user.email),
                            rid: this.getRole(user),
                            group: <span>{this.getGroups(user)}</span>,
                            description: user.description || "",
                            isActive: user.isActive ? (
                                <i className="fa fa-check" style={{ color: "green" }} title="yes" aria-label="Active" />
                            ) : (
                                <i className="fa fa-times" style={{ color: "red" }} title="no" aria-label="Inactive" />
                            ),
                            hasActivated:
                                user.hasActivated && user.completedOnboarding ? (
                                    <i className="fa fa-check" style={{ color: "green" }} title="Completed Activation" aria-label="Completed Activation" />
                                ) :
                                user.hasActivated && !user.completedOnboarding ? (
                                    <i className="fa fa-exclamation" style={{ color: "red" }} title="Partial Activation" aria-label="Partial Activation" />
                                ) : (
                                    <i className="fa fa-times" style={{ color: "red" }} title="Activation not started" aria-label="Activation not started" />
                                )
                            ,
                            lastUpdatedOn: user.lastUpdatedOn ? getFriendlyDate(user.lastUpdatedOn)! : ""
                        })}
                        editLink={`/users/e/:cid/:uid`}
                        delete={!isSoftDeletedUsers
                            && (this.props.user.isSuper
                                || this.props.user.isSuperClientAdmin)
                            ? item => this._softDeleteUser(item)
                            : undefined}
                        undo={isSoftDeletedUsers
                            && (this.props.user.isSuper
                                || this.props.user.isSuperClientAdmin)
                            ? item => this._undoSoftDeleteUser(item)
                            : undefined}
                        createLink={`/users/c/:cid`}
                        importLink={
                            this.props.user.isSuperClientAdmin ||
                                this.props.user.isClientAdmin ||
                                this.props.user.isAdmin
                                ? `/users/u`
                                : undefined
                        }
                        buttons={
                            this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin || this.props.user.isAdmin || this.props.user.isCurator
                                ? filters => [
                                    ...(filters.cid ? [
                                        ...((this.props.user.isSuper || this.props.user.isSuperClientAdmin || this.props.user.isClientAdmin || this.props.user.isAdmin || this.props.user.isCurator)
                                            ? [<div className="dropdown" style={{
                                                width: "auto",
                                                display: "inline-block",
                                                marginLeft: "10px"
                                            }}>
                                                <a
                                                    className="dropdown-toggle btn btn--sm brandPrimary--bg"
                                                    data-toggle="dropdown"
                                                    tabIndex={0}
                                                >
                                                    <i className="fa fa-envelope" /> Emails
                                          <i style={{ marginLeft: "10px" }} className="fa fa-chevron-down" />
                                                </a>
                                                <ul className="dropdown-menu dropdown-email" role="menu">
                                                    {<li>
                                                        <a
                                                            href="#"
                                                            onClick={() =>
                                                                this.setState({
                                                                    modals: { ...this.state.modals, activationEmail: true }
                                                                })
                                                            }
                                                            className="dropdown-item"
                                                        >
                                                            Email All Unactivated
                                                    </a>
                                                        {this.state.autoActivationReminder &&
                                                            <a
                                                                href="#"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        modals: { ...this.state.modals, notSharedEmail: true }
                                                                    })
                                                                }
                                                                className="dropdown-item"
                                                            >
                                                                Email all without shares<br />
                                                        in the past 30 days
                                                    </a>
                                                        }
                                                    </li>}
                                                </ul>
                                            </div>] : []),
                                        ...this.getDownloadButtons(filters)] : [])
                                ]
                                : () => []
                        }
                        filters={{
                            isActive: {
                                title: "Filter By Access",
                                options: [
                                    { key: "All", value: "" },
                                    { key: "Has Access", value: "1" },
                                    { key: "No Access", value: "0" },
                                    ...(this.showDeletedOption() || [])
                                ]
                            },

                            hasActivated: {
                                title: "Filter By Activated State",
                                options: [
                                    { key: "All", value: "" },
                                    { key: "Activated", value: "1" },
                                    { key: "Partially Activated", value: "2" },
                                    { key: "Not Activated", value: "0" }
                                ]
                            },
                            ...(this.state.filters && this.state.filters.cid
                                ? {
                                    rid: {
                                        title: "Filter By Role",
                                        options: [
                                            { key: "All", value: "" },
                                            ...(this.getOptionRoles(this.state.filters.cid) || [])
                                        ]
                                    },
                                    groups: {
                                        title: "Filter By Group",
                                        options: [
                                            { key: "All", value: "" },
                                            ...(this.getOptionGroups(this.state.filters.cid) || [])
                                        ]
                                    }
                                }
                                : null),
                            startDate: {
                                title: "User Created After",
                                defaultDate: this.state.filters.startDate ? this.state.filters.startDate : this.state.startDate
                            },
                            endDate: {
                                title: "User Created Before",
                                defaultDate: this.state.filters.endDate ? this.state.filters.endDate : this.state.endDate
                            }
                        }}
                    />
                </Row>
            </Wrapper>
        );
    }

}
const mapStateToProps = state => ({ user: state.session.admin, clients: state.lists.clients });

export default connect(mapStateToProps)(withLastLocation(Users));
