import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../../../atoms/Button";
import { Wrapper, Row } from "../../../atoms/Layout";
import Tooltip from "../../../atoms/Tooltip";
import PageHeading from "../../../molecules/PageHeading";
import { Client } from "../../../../utils/Models";
import { doUpdateClient } from "../../../../actions/inserts";
import { doGetReduxClient } from "../../../../actions/lists";
import {
    sortObject,
    deepMerge,
    expand
} from "../../../../utils/Helpers";
import { createAlert,doSetFooterFunctions } from "../../../../actions/utils";

class ContentTags extends Component{
    constructor(props) {
        super(props);
        this._onFieldUpdate = this._onFieldUpdate.bind(this);
        this._onTagChange = this._onTagChange.bind(this);
        this.canRemoveTags = false;
        this.state = {
            ...Client({ cid: props.match.params.cid || "" })
        }
    }

    async componentDidMount() {
        const client = this.state.cid ? await doGetReduxClient(this.state.cid) : {};
        const state = Client(client);
        state.tags = sortObject(state.tags || []);
        this.setState({ ...state });
    }
    componentWillUnmount() {
        doSetFooterFunctions(null, null);
    }
    isOrphandedTag(tid) {
        let { streamTags, streams } = this.state;
        if (streams) {
            return Object.keys(streams).some(sid => {
                if (streamTags && streamTags[sid] && streamTags[sid].indexOf(tid) !== -1) {
                    return false;
                }
            });
        }
        return true;
    }
    _removeTag(event,tid,name) {
        if (event) {
            event.preventDefault();
        }

        let { tags } = this.state;
        if (tid == 0) {
           tags[0].splice(tags[0].indexOf(name), 1);
        } else {
            delete tags[tid];
        }
        this._onFieldUpdate("tags", tags);
        createAlert('Remove tag', 'success')
    }
    _onFieldUpdate(field, event) {
        let state = {};
        const value = event?.target ? event.target.value : event;
        if (field.indexOf(".") > -1) {
            const obj = expand(field, value); // mutate key into object with value
            const firstKey = Object.keys(obj)[0]; // get the outer most property
            const origValue = { [firstKey]: this.state[firstKey] }; // current value in state
            state = deepMerge(origValue, obj); // merge both values together and push into state
        } else {
            state[field] = value;
        }
        this.setState(state);
        this.updateTags();
     }
    _onTagChange(event, tid) {
        const { tags } = this.state;
        let value = event.target ? event.target.value : event;

        // trim off leading and trailing spaces before adding
        value = value.trim();

        // check existing for duplicate
        let filterStrings = [];
        const keys = Object.keys(tags);

        // put the tag values in an array
        for (const key of keys) {
            const tag = tags[key];
            if (Array.isArray(tag)) {
                filterStrings = filterStrings.concat(tag);
            } else if (tag) {
                filterStrings.push(tag);
            }
        }
        // find new value in the existing tag values matching lower case
        const dup = filterStrings.filter(val => {
            return val.toLowerCase() == value.toLowerCase()
        });
        // if dup found, alert the user and stop tag change
        if (dup && dup.length > 0) {
            createAlert(`Tag already exists.`, `error`);
            return;
        }

        if (tid) {
            tags[tid] = value;
        } else {
            if (!tags[0]) {
                tags[0] = [];
            }
            const existing = [
                ...tags[0],
                ...Object.values(tags)
            ]
            if (existing.indexOf(value) == -1) {
                tags[0].push(value);
                this.tagInput.value = "";
                this.tagInput?.focus();
                this.setState({ addTag: "" });
            }
        }
        this._onFieldUpdate("tags", tags);
    }
    updateTags(){
        const client = Client(this.state);
        return doUpdateClient(client, true, false, null, null).then(data => {
            if (data.valid ) {
              doGetReduxClient(data["cid"]);
             }
        });

    }

    render() {
        const { tags } = this.state;
        const { user } = this.props;
        const marginStyle ={
            marginBottom: '0px'
        }
        this.canRemoveTags = user.isSuperClientAdmin? true : false;
        return (
            <Wrapper id="contentTagsPage">
                <PageHeading title={`Content Tag Library`} />
                <Row>
                   <div className="col-sm-12">
                        <div className="ibox">
                          <div style={marginStyle} className="ibox-title"><h5 >Content Tags:</h5></div>
                            <div className="ibox-content form">
                                <div className="form__row">
                                    <div className="form__group">
                                        <ul id="ContentTags" className="form__value form__value--centered">
                                            {Object.keys(tags).map(
                                                 (tag, index) =>{
                                                    if (tag == 0) {
                                                        return tags[tag].map(
                                                             (t, i)=> {
                                                                return (
                                                                    <li key={t}>
                                                                        <input
                                                                            id={`tag-input-${t}`}
                                                                            aria-label={`Tag input ${t}`}
                                                                            type="text"
                                                                            value={t ? t : ""}
                                                                            disabled={true}
                                                                        />
                                                                        <Button
                                                                            style={this.canRemoveTags ? {} : { display: 'none' }}
                                                                            id={`tag-remove-${t}`}
                                                                            onClick={event =>
                                                                                this._removeTag(
                                                                                    event.target.value,
                                                                                    0,
                                                                                    t
                                                                                )
                                                                            }
                                                                            disabled={!this.canRemoveTags}
                                                                        >
                                                                            Remove
                                                                </Button>
                                                                    </li>
                                                                );
                                                            }
                                                        );
                                                    }
                                                    return (
                                                        <li key={`tag-${index}`}>
                                                            <input
                                                                id={`tag-input-${tags[tag]}`}
                                                                aria-label={`tag-input-${tags[tag]}`}
                                                                type="text"
                                                                value={tags[tag] ? tags[tag] : ""}
                                                                disabled={true}
                                                            />{" "}
                                                            {this.isOrphandedTag(tag) && (
                                                                <i className="fa fa-help" />
                                                            )}
                                                            <Button
                                                                style={this.canRemoveTags ? {} : { display: 'none' }}
                                                                id={`tag-remove-${tags[tag]}`}
                                                                onClick={event =>
                                                                    this._removeTag(event.target.value, tag)
                                                                }
                                                                disabled={!this.canRemoveTags}
                                                            >
                                                                Remove{" "}
                                                                {this.isOrphandedTag(tag) && (
                                                                    <Tooltip
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "-5px",
                                                                            right: "-5px",
                                                                            color: "red",
                                                                            backgroundColor: "white",
                                                                            borderRadius: "50%",
                                                                            width: "20px",
                                                                            height: "19.375px"
                                                                        }}
                                                                        icon="fa-exclamation-circle"
                                                                    >{`This tag is currently not associated to any streams`}</Tooltip>
                                                                )}
                                                            </Button>
                                                        </li>
                                                    );
                                                }
                                            )}
                                            <li className="form-li-style">
                                                <input
                                                    id="newtag-input"
                                                    aria-label="New tag input"
                                                    type="text"
                                                    ref={input => {
                                                        this.tagInput = input;
                                                    }}
                                                    onChange={event =>
                                                        this.setState({ addTag: event.target.value })
                                                    }
                                                    placeholder="Add new tag"
                                                />
                                                <Button
                                                    id="newtag-btn"
                                                    onClick={event => this._onTagChange(this.tagInput.value, 0)}
                                                    disabled={!this.state.addTag || !this.state.addTag.length}
                                                >
                                                    Add
                                        </Button>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Row>
            </Wrapper>
        );
    }
}

ContentTags.allowSuper = true;
ContentTags.allowApiAdmin = false;
ContentTags.allowClientAdmin = true;
ContentTags.allowAdmin = false;
ContentTags.allowCurator = false;
ContentTags.allowReports = false;

const mapStateToProps = state => ({
    admins: state.lists.admins,
    clients: state.lists.clients,
    user: state.session.admin
});
export default connect(mapStateToProps)(ContentTags);